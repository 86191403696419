import ActionBtn from 'components/ActionBtn';
import cashierActions from 'features/cashier/services/actions';
import { ITEM_HANDLE_MODE } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import SupplyFeeItemModal from '../../SupplyFee';

const FeesButton = ({ v2 }: { v2?: boolean }) => {
  const dispatch = useAppDispatch();
  const active = cashierSelectors.getItemModeVisible(ITEM_HANDLE_MODE.SUPPLY_FEE);
  return (
    <>
      <ActionBtn
        ntype={active ? 'PRIMARY' : 'LIGHT_BLUE'}
        icon="moneyExchange"
        label='Fees'
        onClick={() => dispatch(cashierActions.toggleVisibleSupplyFeeMode())}
      />
      <SupplyFeeItemModal v2={v2} />
    </>
  );
};

export default FeesButton;
