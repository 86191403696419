import CardBox from 'features/cashier/components/CardBox';
import CardBoxV2 from '../CardBoxV2';
type Props = {
  active?: boolean;
  disabled?: boolean;
  onRedeem: () => void;
  v2?: boolean;
};
function CreditCard({ v2, active, disabled, onRedeem }: Props) {
  // const shopSetting = shopSelectors.data.allSetting();
  return (
    v2 ? <CardBoxV2
      type='CREDIT_CARD'
      disabled={disabled}
      onClick={onRedeem}
    /> :
      <CardBox
        icon="cash"
        type={active ? 'ACTIVE' : 'DEFAUL'}
        disabled={disabled}
        onClick={onRedeem}
      >
        Credit Card
      </CardBox>
  );
}

export default CreditCard;
