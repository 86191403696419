import React from 'react';
import styled from 'styled-components';
import edit_icon from '../icons/edit.svg';
import remove_icon from '../icons/remove.svg';
import spent_icon from '../icons/spent-icon.svg';
import point_icon from '../icons/point-icon.svg';
import walk_in_icon from '../icons/walk-in-icon.svg';
import appt_icon from '../icons/appt-icon.svg';
import { ICustomer, IResSummaryReport } from 'features/customer/services/types/api';
import clsx from 'clsx';
import { maskPhone } from 'utils/formatPhone';
import moment from 'moment';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';

type Props = {
  data?: ICustomer;
  summaryReportData?: IResSummaryReport;
  onDelete?: () => void;
  onEdit?: () => void;
};
const CustomerDetailHeaderV2 = ({ data, onDelete, onEdit, summaryReportData }: Props) => {
  return (
    <Container>
      <Content>
        <DetailsContainer>
          <CustomerNameStyled>
            <div className="type-container">
              <div className={clsx('button-type-customer', data?.accountPackage)}><span>{data?.accountPackage || '--'}</span></div>
              <div className="customer-name-text">{data?.name || '--'}</div>
            </div>
            <div className="actions-container">
              <button type='button' className="action-item" onClick={onEdit}>
                <img src={edit_icon} alt="edit_icon" className="action-icon" />
              </button>
              <button type='button' className="action-item" onClick={onDelete}>
                <img src={remove_icon} alt="remove_icon" className="action-icon" />
              </button>
            </div>
          </CustomerNameStyled>
          <CustomerInfo>
            <div className="information-list">
              <div className="information-item">
                <span className="label">Phone:</span>
                <span className="value">{data?.phone ? maskPhone(data?.phone) : '--'}</span>
              </div>
              <div className="information-item">
                <span className="label">Email:</span>
                <span className="value">{data?.email || '--'}</span>
              </div>
              <div className="information-item">
                <span className="label">DOB:</span>
                <span className="value">{
                  data?.birthday
                    ? moment(data?.birthday).format('MM-DD-YYYY')
                    : '--'}</span>
              </div>
            </div>
            <div className="information-list">
              <div className="information-item">
                <span className="label date">Created Date:</span>
                <span className="value">{data?.createdDate
                  ? moment(data?.createdDate).format('MM-DD-YYYY')
                  : '--'}</span>
              </div>
              <div className="information-item">
                <span className="label date">Last Visit Date:</span>
                <span className="value">{data?.lastVisitedDate
                  ? moment(data?.lastVisitedDate).format('MM-DD-YYYY')
                  : '--'}</span>
              </div>
              <div className="information-item">
                <span className="label date">Visited:</span>
                <span className="value">{data?.numberVisit || '--'}</span>
              </div>
            </div>
            <StatisticStyled>
              <StatisticItem label='spent' value={formatCurrency(summaryReportData?.amountSpend)} icon={spent_icon} />
              <StatisticItem label='point' value={formatNumber(summaryReportData?.point)} icon={point_icon} />
              <StatisticItem label='walk-in' value={formatNumber(summaryReportData?.totalWalkIn)} icon={walk_in_icon} />
              <StatisticItem label='appT' value={formatNumber(summaryReportData?.totalAppointment)} icon={appt_icon} />
            </StatisticStyled>
          </CustomerInfo>
        </DetailsContainer>
      </Content>
    </Container>
  );
};

export default CustomerDetailHeaderV2;
const StatisticItem = ({ label, value, icon }: { label?: string, value?: string, icon?: any }) => {
  return (
    <div className='statistic-item'>
      <div className="statistic-item-content">
        <p className="label">{label}</p>
        <p className="value">{value}</p>
      </div>
      <div className="icon-container"><img src={icon} alt={label} style={{ width: 30, height: 30 }} /></div>
    </div>
  );
};
const StatisticStyled = styled.div`
display: flex;
align-items: center;
gap: 16px;
flex: 1 0 0;
align-self: stretch;
.statistic-item {
  display: flex;
  padding: 16px 8px;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  position: relative;
  background: #F6F7FC;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.15), 0px 2px 4px -3px rgba(45, 35, 66, 0.15), 0px -3px 0px 0px rgba(214, 214, 231, 0.15) inset;
  .statistic-item-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2px;
    p.label {
      color: #64748B;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: uppercase;
    }

    p.value {
      color: #2D6772;
      font-family: Poppins;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .icon-container {
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 0.5px;
    top:0;
    zoom: 1.2;
    border-radius: 4px;
    background: #C6E9EF;
  }
}
`;
const CustomerInfo = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
align-self: stretch;
  .information-list {
    display: flex;
    padding-right: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-right: 1px solid #CCD4DC;
    .information-item {
      display: flex;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      span.label {
        width: 60px;
        color: #64748B;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &.date {
          width: 130px;
        }
      }

      span.value {
        flex: 1;
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
`;

const Container = styled.div`
display: flex;
padding: 24px 16px;
align-items: center;
align-self: stretch;
background: #FFF;
box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
`;

const Content = styled.div`
display: flex;
align-items: flex-start;
gap: 16px;
flex: 1 0 0;
`;

const DetailsContainer = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
flex: 1 0 0;
align-self: stretch;
`;

const CustomerNameStyled = styled.div`
display: flex;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
.type-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  .button-type-customer {
    display: flex;
    height: 32px;
    padding: 0px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 100px;
    background: #C6E9EF;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.NEW {
      background: #feedbf;
    }
    &.VIP {
      background: #ffe5e7;
    }
    &.DELETE {
      background: #adadad;
    }
    &.REGULAR {
      background: #c6e9ef;
    }
  }
}

.customer-name-text {
  color: #1D2129;
  font-family: Poppins;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 100% */
}

.actions-container {
  display: flex;
  align-items: center;
  gap: 16px; 
  .action-item {
    display: flex;
    width: 80px;
    height: 80px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    background: #FCFCFD;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    img.action-icon {
      display: flex;
      width: 64px;
      height: 64px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }
  }
}
`;