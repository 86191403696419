import React, { useEffect, useRef } from 'react';
import ReceiptUI from './ReceiptUI';
import paymentSelectors from 'features/payment/services/selectors';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import paymentActions from 'features/payment/services/actions';

const DemoCheckInPrintPage = () => {
  const { check_in_id = '', type = '' } = useParams();
  const data = paymentSelectors.getInfoPrintWaiting();
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const info = { id: check_in_id, type };
    // @ts-ignore
    dispatch(paymentActions.getInfoPrintWaiting.fetch({ body: JSON.stringify(info) }));
  }, []);

  return (
    <ReceiptUI data={data} divRef={divRef} />
  );
};

export default DemoCheckInPrintPage;
