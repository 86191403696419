import { Col, Row } from 'antd';
import { typeIcon } from 'assets/Icons';
import IconAndPrice from 'components/IconAndPrice';
import colorTheme from 'constants/color';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
const labelStyle: CSSProperties = {
  fontSize: '1.5rem',
  fontWeight: 'bold'
};
const SummaryTickets = () => {
  const params = ticketListSelectors.ticketsTableData.params();
  const summaryData = ticketListSelectors.summaryTickets();
  if (params.status !== 'CLOSED') return null;
  return (
    <Row style={{ width: '100%', gap: '10%', margin: '1rem 0', padding: '0 1.5rem' }} justify={'space-between'} align={'bottom'}>
      <IconAndPriceContainerStyled style={{ display: 'flex', flex: 1 }}>
        <Row gutter={[24, 24]} align={'middle'} style={{ flex: 1 }}>
          <ItemSummary
            amount={summaryData?.subTotal || 0}
            label={'Total Sales'}
            icon={'moneyExchange'}
          />
          <ItemSummary
            amount={summaryData?.supply || 0}
            label={'Supply Fees'}
            icon={'moneyExchange'}
          />
          <ItemSummary
            amount={summaryData?.totalTax || 0}
            label={'Taxes'}
            icon={'moneyExchange'}
          />
          <ItemSummary
            amount={summaryData?.discount || 0}
            label={'Discounts'}
            icon={'moneyExchange'}
          />
          <ItemSummary
            amount={summaryData?.tip || 0}
            label={'Tips'}
            icon={'moneyExchange'}
          />
          <ItemSummary
            amount={summaryData?.feeCreditCard || 0}
            label={'Card Fees'}
            icon={'moneyExchange'}
          />
          <ItemSummary
            amount={summaryData?.total || 0}
            label={'Total'}
            icon={'moneyExchange'}
          />
        </Row>
      </IconAndPriceContainerStyled>
    </Row>
  );
};

export default SummaryTickets;

const IconAndPriceContainerStyled = styled.div`
  padding: 0rem 1rem;
  gap: 1rem;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: rgb(254, 237, 191);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .padding-vertical {
    padding: 15px 0px;
  }
  .item-ticket-price {
    border-right: 1px solid #c9cdd4;
  }
  .item-ticket-price:last-child {
    border-right: none;
  }
  .isVerticalBorder {
    border-right: none;
  }
`;

const ItemSummary = ({ label, amount, icon, currency }: { label: string, amount: number | null | undefined, icon: typeIcon, currency?: boolean }) => {
  return (
    <Col flex={1}>
      <div className={'item-ticket-price padding-vertical'}>
        <IconAndPrice
          amount={amount}
          currency={currency}
          title={label}
          icon={icon}
          labelStyle={labelStyle}
          background='rgb(254, 237, 191)'
        />
      </div>
    </Col>
  );
};