import { Avatar, Row, Table, TableColumnsType } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import IconAndPriceContainer from 'features/report/components/IconAndPriceContainer';
import ProcessReport, { IListTotalProcessReport } from 'features/report/components/ProcessReport';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import AppointmentDatePicker from '../components/DatePicker';
import SwitchCalendarType from '../components/SwitchCalendarType';
import { ReportType } from '../constants';
import ModalViewInfo from './components/ModalViewInfo';
import { TableGreenEvenStyled } from 'features/report/components/Component';
import SummarySalonReportV2 from './SummaryV2';

type ISalonReportProps = {};

const getColumns = (v2?: boolean) => [
  {
    title: 'Staffs',
    dataIndex: 'name',
    width: 150,
    key: 'name',
    render: (_, { name, urlImage }) => (
      <Row align={'middle'} justify={'start'} style={{ gap: '10px' }} >
        <Avatar size={'small'} src={urlImage} style={{ backgroundColor: '#f56a00' }}>{name?.[0] || 'A'}</Avatar>
        <Text variant="BODY_1" color="text_3" textAlign="left">
          {name}
        </Text>
      </Row>
    ),
  },
  {
    title: 'Services',
    dataIndex: 'service',
    key: 'service',
    render: (_, { service }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(service)}
      </Text>
    ),
  },
  {
    title: 'Products',
    dataIndex: 'product',
    key: 'product',
    render: (_, { product }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(product)}
      </Text>
    ),
  },
  {
    title: 'Supply Fees',
    dataIndex: 'supply_fee',
    key: 'supply_fee',
    render: (_, { supply_fee }) => (
      <Text variant="CONTENT_1" color="text_3">
        {formatCurrency(supply_fee)}
      </Text>
    ),
  },
  {
    title: 'Commissions',
    dataIndex: 'commissionValue',
    key: 'commissionValue',
    render: (commissionValue) => (
      <Text variant="CONTENT_1" color="info_3" style={{ fontWeight: '700' }}>
        {formatCurrency(commissionValue)}
      </Text>
    ),
  },
  {
    title: 'Discounts',
    dataIndex: 'discount',
    key: 'discount',
    render: (_, { discount }) => (
      <Text variant="CONTENT_1" color="info_3" style={{ fontWeight: '700' }}>
        {formatCurrency(discount)}
      </Text>
    ),
  },
  {
    title: 'Tips',
    dataIndex: 'tip',
    key: 'tip',
    render: (_, { tip }) => (
      <Text variant="CONTENT_1" color="info_3" style={{ fontWeight: '700' }}>
        {formatCurrency(tip)}
      </Text>
    ),
  },
  {
    title: 'Payroll',
    key: 'payroll',
    render: (_, { discount = 0, commissionValue = 0, tip = 0 }) => (
      <Text variant="CONTENT_1" color="info_3" style={{ fontWeight: '700' }}>
        {formatCurrency((+commissionValue) + (+tip) - (+discount))}
      </Text>
    ),
  },
  {
    title: <div style={{ textAlign: 'left' }}>Actions</div>,
    key: 'actions',
    render: () => v2 ? (
      <Button cardCashier ntype='WHITE_BLUE' width='100%' height={2.5}>Detail</Button>
    ) : (
      <Icon type={'next'}></Icon>
    ),
    align: 'center'
  },
] as TableColumnsType<DataType>;

interface DataType {
  key: string;
  name: string;
  service: string | number;
  product: string | number;
  supply_fee: string | number;
  discount: string | number;
  tip: string | number;
  urlImage: string;
  commissionValue?: number;
}

const defaultListTotalProgressReport: IListTotalProcessReport = {
  totalSaleService: 0,
  totalSaleProduct: 0,
  totalSaleVIP: 0,
  totalSaleGiftCard: 0,
  totalCash: 0,
  totalCreditCard: 0,
  totalCheckOther: 0,
  totalGiftCard: 0,
  totalRewardPoint: 0,
  totalRedeemPoint: 0,
};

const SalonReport: React.FC<ISalonReportProps> = () => {
  const versionUI = shopSelectors.data.versionUI();
  const v2 = versionUI === 'V2';
  const [listTotalProgressReport, setListTotalProgressReport] = useState<IListTotalProcessReport>(defaultListTotalProgressReport);
  const [dataTable, setDataTable] = useState<DataType[]>([]);
  const [isShowInfoModal, setIsShowInfoModal] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const summarySalon = reportSelectors.getSummarySalon();
  const summaryTicket = reportSelectors.getSummaryTicket();
  const tableSummarySalon = reportSelectors.getSummarySalarySalon();
  const dataAppointment = reportSelectors.getParams()?.[ReportType.SALON];
  const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody);
  const viewType = reportSelectors.getViewType()?.[ReportType.SALON];

  const columns: TableColumnsType<DataType> = useMemo(() => getColumns(versionUI === 'V2'), [versionUI]);
  const dataProgressList = useMemo(() => {
    const data = [
      {
        title: 'TRANSACTIONS',
        price: formatNumber(summaryTicket?.closeTicket ?? 0),
        progress: 0,
        v2: true,
        isHideCurrency: true,
      },
      {
        title: 'SERVICES',
        price: summarySalon?.totalSaleService ?? 0,
        progress: summarySalon?.saleServicePercent,
      },
      {
        title: 'PRODUCTS',
        price: summarySalon?.totalSaleProduct ?? 0,
        progress: summarySalon?.saleProductPercent,
      },
      {
        title: 'VIP',
        price: summarySalon?.totalSaleVIP ?? 0,
        progress: summarySalon?.saleVIPPercent,
      },
      {
        title: 'GIFT CARDS',
        price: summarySalon?.totalSaleGiftCard ?? 0,
        progress: summarySalon?.saleGiftCardPercent,
      },
    ];
    if (v2) return data;
    return data.filter(o => !o.v2);
  }, [v2, summarySalon, summaryTicket]);

  useEffect(() => {
    if (staffData.length == 0) {
      dispatch(shopActions.get.staffs.fetch());
    }
    dispatch(reportActions.setParams({
      [ReportType.SALON]: {
        startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
        endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
      }
    }));
  }, []);


  useEffect(
    () => {
      if (dataAppointment?.startTime && dataAppointment?.endTime) {
        dispatch(reportActions.getSummarySalon.fetch({
          startDate: dataAppointment?.startTime,
          endDate: dataAppointment?.endTime
        }));
        dispatch(reportActions.getSummaryTicket.fetch({
          startDate: dataAppointment?.startTime,
          endDate: dataAppointment?.endTime
        }));
        dispatch(reportActions.getSummarySalarySalon.fetch({
          startDate: dataAppointment?.startTime,
          endDate: dataAppointment?.endTime
        }));
      }
    }, [dataAppointment]
  );
  console.log('summarySalon', summarySalon);
  console.log('summaryTicket', summaryTicket);


  useEffect(
    () => {
      setListTotalProgressReport(summarySalon);
    }, [summarySalon]
  );

  useEffect(
    () => {
      setDataTable(tableSummarySalon.map(d => {
        return {
          key: d.staffId,
          name: d.staffName,
          service: d.totalSaleService,
          product: d.totalSaleProduct,
          supply_fee: d.supplyFee,
          discount: d.discount,
          tip: d.tip,
          urlImage: d.avatar,
          commissionValue: d.commissionValue,
        };
      }));
    }, [tableSummarySalon]
  );

  const onRow = (data: DataType) => {
    return {
      onClick: () => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalaryStaffDetailOneDay.fetch({
          startDate: dataAppointment?.startTime || moment().format(DATE_FORMAT),
          endDate: dataAppointment?.endTime || moment().format(DATE_FORMAT),
          staffId: data.key
        }));
        setIsShowInfoModal(true);
      }, // click row
    };

  };

  const onClose = () => setIsShowInfoModal(false);

  const handlePrint = () => {
    navigate(`/store/${storage.shop_id.get()}/report/print`);
  };
  return (
    <SalonReportStyled className={v2 ? 'v2' : ''}>
      {/* header */}
      <HeaderBox>
        <Box>
          <Text variant="H5" className='title-page'>INCOME REPORTS</Text>
        </Box>
        <HeaderBox className={v2 ? 'v2' : ''}>
          {v2 ? <>
            <SwitchCalendarType v2 twoWeeks actions={reportActions} selectors={reportSelectors} type={ReportType.SALON} />
            <AppointmentDatePicker v2 size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.SALON} />
            <Button width='9rem' cardCashier ntype='SECONDARY' className='btn-print' icon="print" onClick={handlePrint}>
              Preview
            </Button>
          </> : <>
            <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.SALON} />
            <SwitchCalendarType twoWeeks actions={reportActions} selectors={reportSelectors} type={ReportType.SALON} />
            <Button icon="print" onClick={handlePrint}>
              Preview
            </Button>
          </>}
        </HeaderBox>
      </HeaderBox>
      {v2 ?
        <ContainerContent>
          <SummarySalonReportV2
            dataSale={summarySalon}
            dataTicket={summaryTicket}
            listTotal={listTotalProgressReport}
            dataProgressList={dataProgressList}
          />
          <TableBox className={'v2'}>
            <Table
              loading={false}
              dataSource={dataTable}
              columns={columns}
              pagination={{ pageSize: 50 }}
              onRow={onRow}
              scroll={{ y: '70vh' }}
            />
          </TableBox>
        </ContainerContent>
        : <>
          {/* ticket icon + price */}
          <IconAndPriceContainer
            dataSale={summarySalon}
            dataTicket={summaryTicket}
          />
          <ProcessReport list={dataProgressList} listTotal={listTotalProgressReport} />
          {/* table */}
          <TableBox>
            <Table
              loading={false}
              dataSource={dataTable}
              columns={columns}
              pagination={{ pageSize: 50 }}
              onRow={onRow}
            />
          </TableBox>
        </>
      }
      <ModalViewInfo v2={v2} viewType={viewType} key={Math.random()} isShow={isShowInfoModal} onClose={onClose} />
    </SalonReportStyled>
  );
};

export default SalonReport;
const SalonReportStyled = styled.div`
  min-height: 100vh;
  background-color: ${colorTheme.fill_3};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  &.v2 {
    gap: 16px;
    background: #F5F5F5;
    padding: 16px;
    min-height: unset;
  }
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;

  &.v2 {
    .btn-print {
      .CONTENT_2 {
        color: #1D2129;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

`;
const TableBox = styled(TableGreenEvenStyled)`
&.v2 {
  flex: 1;

  .ant-table-wrapper .ant-table {
    .ant-table-thead th.ant-table-cell {
      height: 48px;
      padding: 0px 8px;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #CCD4DC;
      background: #6FABB6;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: left !important;
      &::before {
        width: 1.5px !important;
        height: 100% !important;
      }
    }

    .ant-table-tbody td.ant-table-cell {
      .BODY_1 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
      background: #E4F4F6;
      border-bottom: 1px solid #E4F4F6;
    }
  }
}
`;

const ContainerContent = styled.div`
display: flex;
align-items: flex-start;
align-self: stretch;
flex-direction: column;
gap: 16px;
`;
