import React from 'react';
import { Tabs, TabsProps } from 'antd';
import ticketListActions from 'features/ticketList/services/actions';
import {
  IApiGetTicketsParam,
  ParamsStatus,
} from 'features/ticketList/services/types/api';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import TabTicketByParams from './Tabs/TabTicketByParams';
type ITicketOnTabsProps = {};
const TabLabelStyled = styled.div`
  font-weight: 600;
`;

const items: TabsProps['items'] = [
  {
    key: 'OPEN_TICKET',
    label: <TabLabelStyled>OPEN TICKETS</TabLabelStyled>,
    children: <TabTicketByParams tableKey={'OPEN_TICKET'} />,
  },
  {
    key: 'CLOSED',
    label: <TabLabelStyled>CLOSED TICKETS</TabLabelStyled>,
    children: <TabTicketByParams tableKey={'CLOSED'} />,
  },
  {
    key: 'VOID',
    label: <TabLabelStyled>VOIDED</TabLabelStyled>,
    children: <TabTicketByParams tableKey={'VOID'} />,
  },
  {
    key: 'REFUND',
    label: <TabLabelStyled>REFUNDED</TabLabelStyled>,
    children: <TabTicketByParams tableKey={'REFUND'} />,
  },
  {
    key: 'DELETE',
    label: <TabLabelStyled>DELETED</TabLabelStyled>,
    children: <TabTicketByParams tableKey={'DELETE'} />,
  },
];

const TicketOnTabs: React.FC<ITicketOnTabsProps> = () => {
  const dispatch = useAppDispatch();

  const onChange = (key: string) => {
    const params: IApiGetTicketsParam = {
      status: key as keyof typeof ParamsStatus,
      page: 1,
      staffId: '',
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  return (
    <TicketOnTabsStyled>
      <Tabs items={items} defaultActiveKey="CLOSED" onChange={onChange} />
    </TicketOnTabsStyled>
  );
};

export default TicketOnTabs;
const TicketOnTabsStyled = styled.div`
  margin-top: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  .ant-tabs {
    flex: 1;
    .ant-tabs-content-holder {
      flex: 1;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
          position: relative;
        }
      }
    }
  }
`;
