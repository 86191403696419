import { Col, Input, InputNumber, InputRef, Row } from 'antd';
import { IMask } from 'antd-mask-input';
import { ButtonSize } from 'antd/es/button';
import Icon, { typeIcon } from 'assets/Icons';
import Button from 'components/Button';
import InputPhone from 'components/InputPhone';
import Text from 'components/Text';
import { BUTTON_SIZES } from 'constants/size';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';
import { PHONE_MASKED } from 'utils/mask';
import { ButtonNumberPad } from './NumberPad';
const FORMAT = '(000) 000-0000';
enum TYPE_KEYBOARD {
  PIN_PASSWORD = 'PIN-PASSWORD',
  PHONE = 'PHONE',
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
}
type IKeyboardType = 'PIN-PASSWORD' | 'PHONE' | 'CURRENCY' | 'NUMBER';
export const PIN_LENGTH = 4;
const masked = IMask.createMask({
  mask: FORMAT,
  // ...and other options
});

type InputViewProps = {
  className?: string;
  size?: ButtonSize;
  leading?: {
    icon: typeIcon;
    onClick: () => void;
  };
  trailing?: {
    icon: typeIcon;
    onClick: () => void;
  };
  value?: string;
  type?: IKeyboardType;
  placeholder?: string;
};
interface INumberKeyBoard {
  value?: string;
  onChange?: (value: string) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
  inputPlaceholder?: string;
  type?: IKeyboardType;
  spacingKeyboard?: number;
  scanQRPassword?: boolean
}
const buttonNames = [
  {
    key: '1',
    value: '1',
    get: (text: string) => text + '1',
    acceptAll: true,
  },
  {
    key: '2',
    value: '2',
    get: (text: string) => text + '2',
    acceptAll: true,
  },
  {
    key: '3',
    value: '3',
    get: (text: string) => text + '3',
    acceptAll: true,
  },
  {
    key: '4',
    value: '4',
    get: (text: string) => text + '4',
    acceptAll: true,
  },
  {
    key: '5',
    value: '5',
    get: (text: string) => text + '5',
    acceptAll: true,
  },
  {
    key: '6',
    value: '6',
    get: (text: string) => text + '6',
    acceptAll: true,
  },
  {
    key: '7',
    value: '7',
    get: (text: string) => text + '7',
    acceptAll: true,
  },
  {
    key: '8',
    value: '8',
    get: (text: string) => text + '8',
    acceptAll: true,
  },
  {
    key: '9',
    value: '9',
    get: (text: string) => text + '9',
    acceptAll: true,
  },
  {
    key: 'removeAll',
    value: <Icon type="removeAll"></Icon>,
    get: () => '',
    accept: [
      TYPE_KEYBOARD.PHONE,
      TYPE_KEYBOARD.PIN_PASSWORD,
      TYPE_KEYBOARD.NUMBER,
    ],
  },
  {
    key: 'dot',
    value: '.',
    get: (text: string) => {
      if (!text) return '0.';
      return text.includes('.') ? text : text + '.';
    },
    accept: [TYPE_KEYBOARD.CURRENCY],
  },
  {
    key: '0',
    value: '0',
    get: (text: string) => text + '0',
    acceptAll: true,
  },
  {
    key: 'backSpace',
    value: <Icon type="backSpace"></Icon>,
    get: (text: string) => text?.slice(0, text.length - 1),
    acceptAll: true,
  },
];

export const useNumberKeyBoardRef = () => useRef<NumberKeyBoardRef>(null);

export type NumberKeyBoardRef = {
  reset: () => void;
  showError: (text: string) => void;
  hideError: () => void;
  focusInput: () => void;
};

const NumberKeyBoard = forwardRef<NumberKeyBoardRef, INumberKeyBoard>(
  (
    {
      disableInput,
      value: _defaultValue,
      onChange = () => undefined,
      inputViewProps = {},
      inputClassName = '',
      type = 'PHONE',
      inputPlaceholder = '',
      spacingKeyboard = 1.5,
      scanQRPassword,
    },
    ref
  ) => {
    const inputRef = useRef<InputRef>(null);
    const [value, setText] = useState<string | undefined>();
    const [error, setError] = useState<string | undefined>();

    useImperativeHandle(ref, () => ({
      reset: () => setText(''),
      showError: (text: string) => setError(text),
      hideError: () => setError(''),
      focusInput: () => setTimeout(() => inputRef.current?.focus(), 100),
    }));

    const _onChange = (text: string) => {
      setError('');
      switch (type) {
        case 'PIN-PASSWORD': {
          if (text.length > PIN_LENGTH) return;
          onChange(text);
          setText(text);
          break;
        }
        case 'CURRENCY': {
          onChange(text !== '0' ? text : '');
          setText(text);
          break;
        }
        case 'PHONE': {
          onChange(text);
          setText(text);
          break;
        }
        case 'NUMBER': {
          onChange(text);
          setText(text);
          break;
        }
      }
    };
    useEffect(() => {
      setText(_defaultValue);
    }, [_defaultValue]);

    const InputValueView = () => {
      const { trailing } = inputViewProps;

      if (disableInput) return null;
      if (isMobileBrowser()) {
        return (
          <InputView
            className={`${inputClassName ?? ''}`}
            value={value}
            {...inputViewProps}
            type={type}
            placeholder={inputPlaceholder}
          />
        );
      }

      switch (type) {
        case 'PHONE':
          return (
            <InputWrapStyled>
              <InputPhone
                className={`${inputClassName ?? ''}`}
                value={value}
                autoFocus
                placeholder={inputPlaceholder || '(000) 000-0000'}
                onChange={(e) =>
                  _onChange(PHONE_MASKED.resolve(e.target.value))
                }
                suffix={
                  <Button ntype="SECONDARY" icon={'QR'} className="suffixEnd" />
                }
              />
            </InputWrapStyled>
          );
        case 'PIN-PASSWORD':
          return (
            <InputWrapStyled>
              <Input
                ref={inputRef}
                name="input_password"
                value={value}
                autoFocus
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className={`password-input ${scanQRPassword ? 'scanQR' : ''}`}
                placeholder={inputPlaceholder}
                onChange={(e) => {
                  _onChange(e.target.value);
                }}
                addonAfter={scanQRPassword && <div><Icon type={'QR'} /></div>}
                maxLength={PIN_LENGTH}
                autoComplete="off"
              />
            </InputWrapStyled>
          );
        case 'NUMBER':
          return (
            <InputWrapStyled>
              <InputNumber
                size="middle"
                autoFocus
                placeholder={inputPlaceholder}
                value={value}
                onChange={(e) => {
                  _onChange(e || '');
                }}
                addonAfter={trailing?.icon ?
                  <button onClick={trailing?.onClick}>
                    {<Icon type={trailing?.icon} />}
                  </button>
                  : null}
              />
            </InputWrapStyled>
          );
        case 'CURRENCY':
          return (
            <InputWrapStyled className="currency">
              <InputNumber
                size="middle"
                autoFocus
                placeholder={inputPlaceholder}
                value={value}
                onChange={(e) => {
                  _onChange(e || '');
                }}
              />
            </InputWrapStyled>
          );
        default:
          return null;
      }
    };

    return (
      <NumberKeyBoardStyled>
        <InputValueView />
        {error && (
          <Text textAlign="center" mt={0.5} style={{ color: '#f53f3f' }}>
            {error}
          </Text>
        )}
        <Text mb={spacingKeyboard} />
        <Row gutter={[24, 15]} className='number-pad' >
          {buttonNames
            .filter((o) => {
              if (o.acceptAll) return true;
              return o.accept?.includes(type as any);
            })
            .map((item) => (
              <Col key={item.key} span={8}>
                <ButtonNumberPad
                  className='number-pad-btn'
                  onClick={() => {
                    switch (type) {
                      case 'PHONE': {
                        PHONE_MASKED.resolve(value || '');
                        const text = item.get(PHONE_MASKED.unmaskedValue || '');
                        _onChange(PHONE_MASKED.resolve(text));
                        break;
                      }
                      default: {
                        const text = item.get(value?.toString() || '');
                        _onChange(text);
                      }
                    }
                  }}
                >
                  {typeof item.value === 'string' ? (
                    <Text variant="H2">{item.value}</Text>
                  ) : (
                    item.value
                  )}
                </ButtonNumberPad>
              </Col>
            ))}
        </Row>
      </NumberKeyBoardStyled>
    );
  }
);
NumberKeyBoard.displayName = 'NumberKeyBoard';
export default NumberKeyBoard;

const NumberKeyBoardStyled = styled.div``;

const InputView = (props: InputViewProps) => {
  const { trailing, value = '', leading, className, type, placeholder } = props;
  const boxInput = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    boxInput.current?.scrollTo({ left: boxInput.current?.scrollWidth });
  }, [props.value]);

  const inputValueView = useMemo(() => {
    switch (type) {
      case 'PIN-PASSWORD':
        return value ? ''.padStart(value.length, '*') : placeholder;
      case 'CURRENCY':
        return `$${value ? value : ''}`;
      case 'NUMBER':
        return value ? value : '0';
      case 'PHONE':
      default:
        return value ? masked.resolve(value) : FORMAT;
    }
  }, [value, type, placeholder]);

  return (
    <InputStyled className={`${className ?? ''}`} {...props}>
      {leading && (
        <Button
          ntype="SECONDARY"
          icon={leading.icon}
          className="suffixEnd"
          onClick={leading.onClick}
        />
      )}
      <div className="wrap-box" ref={boxInput}>
        <div className={`box input-number-pad ${!value ? 'placeholder' : ''}`}>
          {inputValueView}
        </div>
      </div>
      {trailing && (
        <Button
          ntype="SECONDARY"
          icon={trailing.icon}
          className="suffixEnd"
          onClick={trailing.onClick}
        />
      )}
    </InputStyled>
  );
};

const InputStyled = styled.div.withConfig({
  shouldForwardProp: (prop) => !['leading', 'trailing'].includes(prop),
}) <InputViewProps>`
  display: flex;
  height: ${(props) =>
    props.size ? BUTTON_SIZES[props.size] : BUTTON_SIZES.middle};
  overflow: hidden;
  align-items: center;
  margin-bottom: 1rem;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--primary-primary-hover, #f5767f);
  background: var(--fill-fill-0, #fff);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .wrap-box {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .box {
    margin: 8px 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    &:after {
      opacity: 0;
      content: "";
      border-radius: 5px;
      border: 1px solid var(--text-text-3, #1d2129);
      animation: fadeInOut 1.25s infinite;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      align-self: stretch;
    }
    &.placeholder {
      opacity: 0.5;
      &:after {
        opacity: 0;
      }
    }
  }

  button.suffixEnd {
    border: 1px solid #e5e6eb;
    background: var(--fill-fill-5, #e5e6eb);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    border-radius: 0px;
    gap: 0;
  }
`;

const InputWrapStyled = styled.div`
  .ant-input-affix-wrapper {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  border-radius: 5px;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: hidden;
  input {
    text-align: center;
    color: var(--text-text-3, #1d2129);
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
  .ant-input-password .ant-input-suffix {
    display: none;
  }
  .ant-input-number {
    width: 100%;
  }
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
      text-align: left;
      color: var(--text-text-3, #1d2129);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &.currency {
    input {
      height: 100%;
      text-align: center;
      color: var(--text-text-3, #1d2129);
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .password-input {
    -webkit-text-security: disc;
    text-security: disc;
    &.scanQR {
      input {
        text-align: center;
        padding-left: 50px;
      }
    }
  }
`;
export const NumberKeyBoardStoryboard = () => {
  const [text, setText] = useState('');
  return (
    <div style={{ width: '25vw' }}>
      <Text variant="H3">NumberKeyBoard</Text>
      <NumberKeyBoard value={text} onChange={setText} />
    </div>
  );
};
