import Icon from 'assets/Icons';
import Text from 'components/Text';
import { getServiceColorSet } from 'features/settingService/pages/SettingPage/components/ServicesSettingV2/ServicePart/PopoverChangeColors';
import { FC } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

const SIZE_SERVICE_ITEM = {
  SMALL: 'small',
  LARGE: 'large',
};
interface ServiceItemProps {
  service: string;
  price: number;
  selected: boolean;
  size: keyof typeof SIZE_SERVICE_ITEM;
  onClick?: () => void
  count?: number;
  addOn?: boolean;
  product?: boolean;
  unit?: number;
  colorCode?: string;
}
interface ServiceItemStyledProps {
  selected: boolean;
  size?: keyof typeof SIZE_SERVICE_ITEM;
  addOn?: boolean;
}

const ServiceItemWrapper = styled.button`
  width: 100%;
  position: relative;
`;

export const IconBadge = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;

const ServiceItemStyled = styled.div<ServiceItemStyledProps>`
  box-sizing: border-box;
  background: ${({ selected, size }) =>
    selected ? '#F6F7FC' : size === 'SMALL' ? '#F6F7FC' : '#E5E6EB'};
  border: 1px solid ${({ selected }) => (selected ? '#FF8890' : '#86909c')};
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: ${({ size }) => (size === 'LARGE' ? '4.875rem' : '3.5rem')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
`;

const ServiceItem: FC<ServiceItemProps> = ({
  service,
  price,
  selected,
  size,
  onClick,
  count,
  addOn,
  colorCode,
}) => {
  const colorSet = getServiceColorSet(colorCode);
  return (
    <ServiceItemWrapper onClick={onClick}>
      {selected && (
        <IconBadge>
          {count ? (
            <IconCount>
              <Text className='text'>{count}</Text>
            </IconCount>
          ) : <Icon type="tick" />}
        </IconBadge>
      )}
      <ServiceItemStyled selected={selected} size={size} style={{ background: colorSet.bg, border: '1px solid ' + colorSet.stroke }}>
        {addOn ? <Text variant='CONTENT_1' color='text_3'>{service}</Text> : <Text variant='CONTENT_1' color='text_3' style={{ textAlign: 'left' }}>{service}</Text>}
        <Text variant="H9">{formatCurrency(price)}</Text>
      </ServiceItemStyled>
    </ServiceItemWrapper>
  );
};

export default ServiceItem;

export const IconCount = styled.div`
  background: var(--primary-primary-active, #FF8890);
  width: 28px;
  height: 28px;
  border-radius:28px;
  display:flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #FFF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;