import React, { useMemo } from 'react';
import styled from 'styled-components';
import RefundButton from './ModalButton/RefundButton';
import VoidButton from './ModalButton/VoidButton';
import ButtonSMS from 'widgets/Receipt/ButtonSMS';
import ButtonEmail from 'widgets/Receipt/ButtonEmail';
import Button from 'components/Button';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { Row } from 'antd';
import Text from 'components/Text';
import { formatCurrency } from 'utils/formatCurrency';
import ButtonModifyTipCash from './ModifyTipCash';
import ButtonSplitTicket from './SplitTicket';
type Props = {
  doPrint: () => void;
};
const Actions = ({ doPrint = () => undefined }: Props) => {
  const details = ticketListSelectors.ticketDetailsData.data();
  const isVoided = details?.billStatus === 'VOID';

  const { isCreditCard, isIncludeCashPayment } = useMemo(() => {
    const isCreditCard = !!details?.isMultiplePayCard || !!details?.payments.find(
      (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
    );
    const isIncludeCashPayment = details?.payments?.find(o => o.paymentType === PAYMENT_TYPE.CASH);
    return ({
      isCreditCard, isIncludeCashPayment
    });
  }, [details]);

  return (
    <Row align={'middle'} style={{ flex: 1 }}>
      <ButtonGroup>
        <Button ntype="SECONDARY" icon="print" onClick={doPrint}>
          Reprint
        </Button>
        {!details?.void || isVoided ?
          <Button
            disabled
            ntype="YELLOW"
            icon="voidList">
            Voided
          </Button>
          : <VoidButton />}


        {isCreditCard && <RefundButton disabled={!details?.refund || isVoided} maxAmount={details.total} />}
        <ButtonEmail billId={details?.billId} />
        <ButtonSMS billId={details?.billId} defaultSMSPhone={details?.customerPhone} />
        {isIncludeCashPayment && <ButtonModifyTipCash />}
        {details?.billStatus === 'CLOSED' && <ButtonSplitTicket />}
      </ButtonGroup>
      <Text variant="H2" color="text_3" textAlign='right' style={{ fontWeight: 'bolder', flex: 1 }}>
        {details?.billStatus === 'VOID' ? 'VOIDED' : 'TOTAL'}: {formatCurrency(details?.total)}
      </Text>
    </Row>
  );
};

export default Actions;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;