import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { IGiftCardDetail } from './types/reducer';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['giftCards']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['giftCards']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));
const getViewType = () => selector('viewType') as MyState['viewType'];

const getParams = () => selector('params') as MyState['params'];
const getCurrentPage = () => selector('currentPage') as MyState['currentPage'];
const getTotalPages = () => selector('totalPages') as MyState['totalPages'];
const getTotalElements = () => selector('totalElements') as MyState['totalElements'];
const getListGiftCard = () => selector('listGiftCard') as MyState['listGiftCard'];
const getGiftCardDetail = () => selector('giftCardDetail') as IGiftCardDetail;
const getResetTimeGiftCardStatus = () => selector('resetTimeGiftCardStatus') as MyState['resetTimeGiftCardStatus'];
const getGiftCardSummary = () => selector('giftCardSummary') as MyState['giftCardSummary'];

const giftCardsSelectors = {
    getViewType,
    getParams,
    getListGiftCard,
    getCurrentPage,
    getTotalPages,
    getTotalElements,
    getGiftCardDetail,
    getResetTimeGiftCardStatus,
    getGiftCardSummary,
};
export default giftCardsSelectors;
