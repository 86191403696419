import { Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { IMultiCardResDataItem } from '../../services/types/card';
import IconClock from './IconClock';
import imgBox from './bg.png';
type IPaymentDetailProps = {
  remaining?: number;
  cards: IMultiCardResDataItem[];
  total: number;
};
const PaymentDetail = ({ cards = [], total, remaining = 0 }: IPaymentDetailProps) => {

  const paymentDetail = [
    { label: 'Loyalty', value: 0, },
    { label: 'Gift Card', value: 0, },
    { label: 'Check / Other(s)', value: 0, },
    { label: 'Cash', value: 0, },
    { label: 'Credit Card', value: 0, }
  ];

  const PaymentItems = () => <>{paymentDetail.map((o, i) => (
    <React.Fragment key={i}>
      <Row align={'middle'} justify={'space-between'}>
        <Text variant='H5'>{o.label}</Text>
        <Text variant='H5'>{formatCurrency(o.value)}</Text>
      </Row>
      {i !== (paymentDetail.length - 1) && <Text my={1} className="border-dotted" />}
    </React.Fragment>
  ))}</>;

  return (
    <PaymentDetailStyled>
      <div className="box-total">
        <Text variant='H3' mb={1.5}>Total:</Text>
        <Text variant='H1'>{formatCurrency(total)}</Text>
      </div>
      <div className="container">
        <Row className="box-money">
          <div className="icon-clock">
            <IconClock />
          </div>
          <Row justify={'space-between'} className='row-info'>
            <Col>
              <Text mb={1} variant='H4' color='text_2'>Remain:</Text>
              <Text variant='H5'>{formatCurrency(remaining)}</Text>
            </Col>
            <div className="vertical-divide" />
            <Col>
              <Text mb={1} variant='H4' color='text_2'>Chance:</Text>
              <Text variant='H5'>$0.00</Text>
            </Col>
          </Row>
        </Row>
      </div>
      <div className='container payment-detail'>
        {!cards?.length ? <PaymentItems /> : cards.map((item, i) => (
          <React.Fragment key={i}>
            <Row align={'middle'} justify={'space-between'}>
              <Box className="center" gap="1">
                <Icon type="card" />
                <Text variant="H5" color="text_3">
                  {item.last4 || '--'}
                </Text>
              </Box>
              <Text variant='H5'>{formatCurrency(item.amount)}</Text>
            </Row>
            {i !== (cards.length - 1) && <Text my={1} className="border-dotted" />}
          </React.Fragment>
        ))}
      </div>
    </PaymentDetailStyled>
  );
};

export default PaymentDetail;
const PaymentDetailStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 45.75rem;
  border-radius: 10px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-5, #E5E6EB);

  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .box-total {
    background: url(${imgBox});
    height: 19rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .box-money {
    height: 8.5rem;
    transform: translateY(-4.25rem);
    border-radius: 10px;
    background: #FFF;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    padding:0 1.5rem;
    .vertical-divide {
      background: #C9CDD4;
      width: 1px;
    }
    .row-info {
      flex:1;
      text-align: left;
      padding-right: 1.5rem;
    }
  }
  .icon-clock {
    border-radius: 20px;
    background: var(--fill-fill-5, #E5E6EB);
    height:6.8rem;
    width:6.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
  }
  .payment-detail {
    transform: translateY(-2rem);
  }
  .border-dotted {
    border: 1px dotted #86909C;
  }
`;

