import { AutoComplete, Input, InputRef, Row } from 'antd';
import { AxiosResponse } from 'axios';
import Box from 'components/Box';
import Spin from 'components/Spin';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { getCustomersFilter } from 'services/shop/apis';
import shopSelectors from 'services/shop/selectors';
import { ICustomerItemData } from 'services/shop/types/customer';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import ButtonAddCustomer, { useButtonAddCustomerRef } from './AddCustomer';
import NotFoundCustomerBox from './NotFoundCustomerBox';
export const maskPhone = (val: string) => {
  if (!val) return '';
  return '(***) ***-' + val.slice(val.length - 4, val.length);
};

// @ts-ignore
export const unknownCustomer: ICustomerItemData = { email: '', id: 'unknownCustomer', name: 'Unknown Customer', point: 0, phone: '' };

type CustomerSearchInputProps = {
  value?: ICustomerItemData;
  onChange?: (data: ICustomerItemData) => void;
  inputRef: React.RefObject<InputRef>;
};
const CustomerSearchInputForm = ({ onChange = () => undefined, value, inputRef }: CustomerSearchInputProps) => {
  const [open, setOpen] = useState(false);
  const buttonAddRef = useButtonAddCustomerRef();
  const _customerStore = shopSelectors.data.customers();
  const [customersFilter, setCustomersFilter] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const customerStore = useMemo(() => {
    return [unknownCustomer].concat(_customerStore);
  }, [_customerStore]);

  const initialOptions = useMemo(() => {
    return customerStore.map(o => {
      const point = +o?.point || 0;
      const phone = maskPhone(o?.phone || '');
      return ({
        value: o.id,
        name: o.name || '',
        keywords: [o.name?.toLocaleLowerCase(), phone],
        label: renderLabel(o.name || '', point > 1 ? `${point} points` : point > 0 ? `${point} point` : '', phone),
      });
    });
  }, [customerStore]);

  const [searchText, setSearchText] = useState<string>('');

  const optionsFilter = useMemo(() => customersFilter.map(o => {
    const point = +o?.point || 0;
    const phone = maskPhone(o?.phone || '');
    return ({
      value: o.id,
      name: o.name,
      label: renderLabel(o.name, point > 1 ? `${point} points` : point > 0 ? `${point} point` : '', phone),
    });
  }), [customersFilter]);

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return optionsFilter;
  }, [initialOptions, searchText, optionsFilter]);

  const handleSwitchCustomer = (data: string) => {
    inputRef?.current?.blur();
    setSearchText('');
    const result = (searchText ? customersFilter : customerStore)?.find((item) => item.id === data);
    if (!result) return;
    onChange(result);
  };

  const getList = useCallback(debounce(async (search: string) => {
    try {
      const res: AxiosResponse<{ data: { content: ICustomerItemData[] } }> = await getCustomersFilter(search);
      if (res.data.data.content) {
        setCustomersFilter(res.data.data.content);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 500), []);

  const _onOpenAddNew = (value: { name?: string, phone?: string }) => {
    buttonAddRef?.current?.open({ phone: value.phone ? formatPhone(value.phone) : '', name: value.name || '' });
    setSearchText('');
  };

  return (
    <Container display='flex' className='CustomerSearchInputForm'>
      <AutoComplete
        style={{ width: '100%' }}
        options={options}
        dropdownRender={(menu) => (<>
          {searchText && loading ? <Row align={'middle'} justify={'center'}><Text py={1.5}><Spin /></Text></Row> : menu}
        </>)}
        notFoundContent={<NotFoundCustomerBox searchText={searchText} onOpenAddNew={_onOpenAddNew} />}
        onSelect={handleSwitchCustomer}
        value={searchText}
        maxLength={11}
        open={open}
        onDropdownVisibleChange={setOpen}
        onChange={text => {
          if (text) setLoading(true);
          setSearchText(text);
          getList(text);
        }}
      >
        <Input
          ref={inputRef}
          bordered={false}
          placeholder={value?.name ? (`${value?.name}${value?.phone ? ` - ${maskPhone(value?.phone)}` : ' - (000) 000-0000'}`) : 'Enter customer name/phone'}
          // onBlur={() => setOpen(false)}
          onFocus={() => setOpen(true)}
        />
      </AutoComplete>

      <ButtonAddCustomer ref={buttonAddRef} model='add-close-ticket' onChooseCustomer={onChange} />
    </Container>
  );
};

export default CustomerSearchInputForm;

const Container = styled(Box)`
  height: 3.5rem;
    border-width: 1px;
    border-style: solid;
    border-color: #86909C;
    border-radius: 5px;
  .ant-select {
    .ant-select-selector {
      height: 100%;
      .ant-input {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        &::placeholder {
          color: #1D2129;
          opacity: 1;
        }

        &::-ms-input-placeholder {
          color: #1D2129;
        }
      }
    }
  }
`;

const renderLabel = (name: string, point: string, tel: string) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Text variant="CONTENT_2" color="text_3">
        {name}
      </Text>
      <Text variant="BODY_1" color="text_2">
        {point}
      </Text>
    </div>
    <div>
      <Text variant="BODY_1" color="text_3">
        {tel}
      </Text>
    </div>
  </div>
);
