import { createSlice } from '@reduxjs/toolkit';
import { merge, remove } from 'lodash';
import moment from 'moment';
import actions from './actions';
import { EMainLayout, NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IHistoryClockInOutResData } from './types/clock-in-out';
import shopActions from 'services/shop/actions';
import { HOME_SORT_BY } from 'services/shop/constants';

const initialState: IState = {
  filterParams: {
    startDate: moment().format('MM-DD-YYYY'),
    endDate: moment().format('MM-DD-YYYY'),
  },
  waitingListData: [],
  summaryData: {
    totalBooking: 0,
    totalWalkIn: 0,
    totalCustomer: 0,
    averageTickets: 0,
  },
  billWaitingTickets: [],
  staff: {
    clockIn: true,
    detail: null,
  },
  history: {
    data: [],
    total: null,
    params: { page: 1, size: 10 },
  },
  activeRemoveTicket: false,
  activeRemoveWaiting: false,
  listAvailableWorking: [],
  homeLayout: EMainLayout.GENERAL,
  enableMergeTicket: false,
  mergeTicketsList: [],
  homeSort: HOME_SORT_BY.AVAILABLE,
  appointmentToday: [],
  enableChooseEmployeeQueue: null
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    setHomeSort: (state, { payload }: { payload: HOME_SORT_BY }) => {
      state.homeSort = payload;
    },
    enableChooseEmployeeQueue: (state, { payload }: { payload: string }) => {
      if (state.enableChooseEmployeeQueue === payload) {
        state.enableChooseEmployeeQueue = null;
        return;
      }
      state.enableChooseEmployeeQueue = payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(actions.syncWaitingToTicket, state => {
        state.enableChooseEmployeeQueue = null;
      })
      .addCase(actions.getAppointmentToday.success, (state, { payload }) => {
        state.appointmentToday = payload;
      })
      .addCase(actions.selectMergeTicket, (state, { payload }) => {
        const exist = state.mergeTicketsList.indexOf(payload);
        if (exist !== -1) {
          remove(state.mergeTicketsList, o => o === payload);
        } else {
          state.mergeTicketsList.push(payload);
        }
      })
      .addCase(actions.activeMergeTicket, (state, { payload }) => {
        state.enableMergeTicket = payload;
      })
      .addCase(actions.refreshMergeTicket, (state) => {
        state.enableMergeTicket = false;
        state.mergeTicketsList = [];
      })
      .addCase(actions.changeHomeLayout, (state, { payload }) => {
        state.homeLayout = payload;
      })
      .addCase(shopActions.get.allSetting.success, (state, { payload }) => {
        state.homeLayout = payload.initUIHomeDashboard;
        state.homeSort = payload?.defaultSortHome || HOME_SORT_BY.AVAILABLE;
      })
      .addCase(actions.getListAvailableWorking.fetch, (state) => {
        state.listAvailableWorking = [];
      })
      .addCase(actions.getListAvailableWorking.success, (state, { payload }) => {
        state.listAvailableWorking = payload;
      })
      .addCase(actions.activeRemoveTicket, (state, { payload }) => {
        state.activeRemoveTicket = payload;
      })
      .addCase(actions.activeRemoveWaiting, (state, { payload }) => {
        state.activeRemoveWaiting = payload;
      })
      .addCase(actions.getHistoryClockInOut.success, (state, { payload }) => {
        const data = payload as IHistoryClockInOutResData;
        state.history.data = data.content;
        state.history.total = data.totalElements;
      })
      .addCase(actions.setHistoryClockInOutParams, (state, { payload }) => {
        const _params = {
          ...state.history.params ?? {},
          ...payload,
        };
        state.history.params = _params;
      })
      .addCase(actions.doClockOut.success, (state) => {
        // state.staff.clockIn = false;
        state.staff.detail = null;
      })
      .addCase(actions.doClockIn.success, (state, { payload }) => {
        // state.staff.clockIn = true;
        state.staff.detail = payload;
      })
      .addCase(actions.doFilterData, (state, { payload }) => {
        const _params = {
          ...state.filterParams ?? {},
          ...payload ?? {},
        };
        state.filterParams = _params;
      })
      .addCase(actions.getBillWaitingTicket.success, (state, { payload }) => {
        state.billWaitingTickets = payload;
      })
      .addCase(actions.getSummaryData.success, (state, { payload }) => {
        merge(state.summaryData, payload);
      })
      .addCase(actions.getWaitingList.success, (state, { payload }) => {
        state.waitingListData = payload;
      })
      ;
  },
});
export const userUIActions = Slice.actions;
const userServiceReducer = Slice.reducer;
export default userServiceReducer;
