import clsx from 'clsx';
import Box from 'components/Box';
import Text from 'components/Text';
import { ITopRankingService } from 'features/report/services/types/reportStaff';
import { sumBy } from 'lodash';
import React from 'react';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import prize_icon from '../../icons/prize.svg';

interface ITopServicesSale {
  topRankingService: ITopRankingService[];
}
const TopServicesSaleV2: React.FC<ITopServicesSale> = ({ topRankingService = [] }) => {
  return (
    <Container>
      <StyledCard>
        <TopTotal>
          <Text className='title-top'>Top 5 Ranking Sales</Text>
          <Box display='flex' gap='1' alignSelf='stretch'>
            <span className="total-value">{formatCurrency(sumBy(topRankingService, o => o?.totalSale || 0))}</span>
            <Box display='flex' flexDirection='column' gap='1'>
              <span className="total-label">total gross sale</span>
              <span className="total-label">of all salon</span>
            </Box>
          </Box>
        </TopTotal>
        {topRankingService.map((item, index) => {
          return <ServiceItem
            key={item.serviceId}
            index={index}
            best={index === 0}
            couponUsed={item?.couponUsed}
            name={item?.serviceName}
            price={formatCurrency(item?.totalSale)}
            times={item?.serviceTimes}
          />;
        })}
      </StyledCard>
    </Container>
  );
};

export default TopServicesSaleV2;

const TopTotal = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  background: #D2464F;
  .title-top {
    align-self: stretch;
    color: #FFF;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  span.total-value {
    flex: 1;
    color: #FFF;
    font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span.total-label {
    color: #FFF1F6;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
        text-transform: capitalize;
  }

`;
const Container = styled.div`
display: flex;
min-width: 431px;
padding: 24px 16px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
border-right: 1px solid #CCD4DC;
background: #FFF;
align-self: stretch;
`;
const StyledCard = styled.div`
    border-radius: 5px;
    background: #FFF;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    gap: 8px;
`;
type ServiceItemProps = {
  index: number;
  name: string;
  price: string;
  times: number;
  couponUsed: number;
  best?: boolean;
};
const ServiceItem = ({ best, index, name, couponUsed, times, price }: ServiceItemProps) => {
  return (
    <ServiceItemStyled className={best ? 'best' : ''}>
      <div className="service-first">
        <div className={clsx('index-icon', best && 'best')}>{best ? <img style={{ width: 44, height: 44 }} src={prize_icon} alt='prize_icon' /> : <span>{index + 1}</span>}</div>
        <div className="service-name-info">
          <span className="service-name">{name}</span>
          <Box display='flex' flexDirection='column'>
            <span className="service-info">Service: {times} times</span>
            <span className="service-info">Coupon code used: {couponUsed}</span>
          </Box>
        </div>
      </div>
      <div className="service-price">
        <span>{price}</span>
      </div>
    </ServiceItemStyled>
  );
};
const ServiceItemStyled = styled.div`
display: flex;
padding: 8px 16px;
align-items: flex-start;
align-self: stretch;
border-bottom: 1px solid #E9EDF2;
background: #FFF;
box-shadow: 0px 4px 8px -4px rgba(109, 43, 243, 0.10);
.service-first {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  .index-icon {
    display: flex;
    width: 44px;
    height: 44px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    background: #F6F7FC;
    span {
      color: #D2464F;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.best {
      background: transparent;
    }
  }

  .service-name-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    flex: 1 0 0;
    span.service-name {
      align-self: stretch;
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
    }
    span.service-info {
      align-self: stretch;
      color: #505050;
      text-transform: capitalize;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.service-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
  flex: 1 0 0;
  span {
    align-self: stretch;
    color: #1D2129;
    text-align: right;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

&.best {
  border-bottom: 1px solid #E9EDF2;
  background: #FFE5E7;
  box-shadow: 0px 4px 8px -4px rgba(109, 43, 243, 0.10);
}
`;