import React from 'react';
import styled from 'styled-components';
import { getSideBarConfig, SideBarConfigKeys } from './helper';
import clsx from 'clsx';
type Props = {
  type: SideBarConfigKeys;
  active?: boolean;
  onClick?: () => void;
};
const SideBarMenuItem = ({ type, active, onClick }: Props) => {
  const config = getSideBarConfig(type);
  return (
    <Container className={clsx(active && 'active')} type='button' onClick={onClick}>
      <img src={active ? config.iconActive : config.icon} className='menu-icon' />
      <p className='menu-label'>{config.label}</p>
    </Container>
  );
};

export default SideBarMenuItem;

const Container = styled.button`
  display: flex;
  padding: 12px 0px;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  align-self: stretch;
  background: #FCFCFD;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;

  img.menu-icon {
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .menu-label {
    align-self: stretch;
    color: #505050;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  &:hover {
    background: #eee;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -6px 0px 0px rgba(187, 74, 2, 0.50) inset;
  }
  &.active  {
    border-radius: 4px;
    background: #DC5500;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -6px 0px 0px rgba(187, 74, 2, 0.50) inset;
    .menu-label {
      color: #fff;
    }
  }
`;
