import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IStaffItemData } from 'services/shop/types/staff';

export const ParamsStatus = {
  OPEN_TICKET: 'OPEN_TICKET',
  CLOSED: 'CLOSED',
  VOID: 'VOID',
  REFUND: 'REFUND',
  DELETE: 'DELETE',
  CLEAR: 'CLEAR',
  MERGED: 'MERGED',
};

export const TRANSACTION_STATUS = ParamsStatus;

export type IApiGetTicketsParam = {
  status?: keyof typeof ParamsStatus | (keyof typeof ParamsStatus)[];
  startDate?: string;
  endDate?: string;
  date?: string;
  page?: number;
  size?: number;
  paymentType?: PAYMENT_TYPE;
  ticketNumber?: string;
  staffId?: string;
};
export type IResponseTicket = {
  content: Bill[];
  totalPages: number;
  totalElements: number;
};
export interface Bill {
  billId: string;
  billStatus: keyof typeof ParamsStatus;
  customerName: string;
  customerPhone: string;
  customerPoint?: number;
  availableLoyaltyPoint?: number;
  availableGiftCard?: number;
  checkInType: string;
  discountItem: number;
  discountTicket: number;
  tip: number;
  saleTax: number;
  useTax: number;
  total: number;
  subTotal: number;
  supplyFee?: number | null;
  createdDate: null | string;
  ticketNumber: number;
  startTime: string;
  endTime: string;
  items: StaffItem[];
  payments: Payment[];
  refund: boolean;
  void: boolean;
  reason: string;
  signature: string | null;
  chance?: number;
  isMultiplePayCard?: boolean;
  paymentMultipleCard?: IPaymentMultipleCardItem[]
}

export interface IPaymentMultipleCardItem {
  paymentId: string;
  paymentType: string;
  amount: number;
  tip: number;
  feeCreditCard: number;
  total: number;
  authCode: string;
  cardNumber: null;
  seriesNumber: string;
  transactionId: string;
  cardHolder: string;
  expireDate: null;
  last4: string;
  first4: null;
  cardType: string;
  transNumber: string;
  entryType: string;
  createDated: string;
  signature: string;
}

export interface StaffItem {
  staffName: string;
  staffId: string;
  services: Service[];
  subTotal: number | null;
  supplyFee?: number | null;
  avatar?: string | null;
  tip: number | null;
  cashAmount?: number;
  checkAmount?: number;
  creditAmount?: number;
  giftCardAmount?: number;
  loyaltyAmount?: number;
  otherAmount?: number;
}
export interface StaffItemForm {
  staffName: string;
  staffId: string;
  tip?: number;
  percent?: number;
}
export interface Payment {
  paymentId: string;
  paymentType: PAYMENT_TYPE;
  amount: number;
  total: number;
  authCode: string;
  cardNumber: string | null;
  transactionId: string;
  cardHolder: string;
  expireDate: string | null;
  last4: string;
  first4: string;
  cardType: string;
  createDated: string;
  transNumber: string;
  otherMethod?: string;
  feeCreditCard?: number;
}
export interface Service {
  id: string;
  itemId: string;
  itemName: string;
  itemType: string;
  price: number;
  duration: number;
  discount: number;
  commission: null | string;
  discountSetting: null | string;
  uuid_local?: string;
}

export interface IEditStaffByBillId {
  billId: string;
  staffIdOld: string;
  staffIdNew: string;
  pinCode?: string;
}
export interface IEditStaffPayload {
  billId: string;
  existStaffId: string;
  newStaff: IStaffItemData;
  pinCode?: string;
}
export interface IRefundBody {
  billId: string;
  amount: number;
  pinCode?: string;
}
export type AddTipParams = {
  billId: string;
  tip: number;
};

export interface VoidByBillIdParams {
  id: string;
  reason: string;
}
export interface IAdjustTip {
  billId: string;
  tip: number;
}

export interface IPayloadAptConfirm {
  billId: string;
  items: IPayloadAptConfirmItem[];
}

export interface IPayloadAptConfirmItem {
  detailIds: string[];
  staffId: string;
}

export interface IParamSummaryCreditCards {
  startDate: string;
  endDate: string;
}


export interface IApiModifyTipCashBody {
  billId: string;
  totalTip: number;
  items: {
    staffId: string;
    tip: number;
  }[];
}
