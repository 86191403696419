import cashierSelectors from 'features/cashier/services/selectors';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
import DiscountItemModal from '../Discount/DiscountItem';
import BillSelectedItems from './BillSelectedItems';
import CustomerInfo from './Customer/CustomerInfo';
import GroupActions from './GroupActions';
import UpdateQuickPay from './UpdateQuickPay';
import shopSelectors from 'services/shop/selectors';
import MergeTicketButton from '../Button/MergeTicketButton';
import SplitButton from '../Button/SplitButton';
import ButtonAddCustomer from './Customer/AddCustomer';
import Customer from './Customer';
type IComponentProps = {};
const useRightMainBody = () => {
  const ticketDetails = cashierSelectors.getTicketDetails();
  const allSetting = shopSelectors.data.allSetting();
  const createdDate = useMemo(() => ticketDetails?.ticketInfo?.startTime ? momentTimezone(ticketDetails?.ticketInfo?.startTime) : null, [ticketDetails]);
  return ({
    ticketDetails, allSetting, createdDate
  });
};
const RightMainBody: React.FC<IComponentProps> = () => {
  const { ticketDetails, createdDate } = useRightMainBody();
  return (
    <RightMainStyled>
      <ContainerStyled>
        <ButtonContainerStyled>
          <div className='ticket-info'>
            <div className="ticket-number"><p>#{ticketDetails?.ticketInfo.ticketNumber}</p></div>
            <div>
              <p className='bold-date'>{createdDate?.format('MM-DD-YYYY')}</p>
              <p className='bold-date'>{createdDate?.format('LT')}</p>
            </div>
          </div>
          <GroupActions />
        </ButtonContainerStyled>
        <CustomerInfo />
        <BillSelectedItems />
        <DiscountItemModal />
        <UpdateQuickPay />
      </ContainerStyled>
    </RightMainStyled>
  );
};

export const RightMainBodyV2 = () => {
  const { ticketDetails, createdDate } = useRightMainBody();
  return (
    <RightMainStyledV2>
      <Header>
        <ButtonContainerStyled className='ButtonContainerStyled'>
          <div className='ticket-info'>
            <div className="ticket-number"><p>#{ticketDetails?.ticketInfo.ticketNumber}</p></div>
            <div>
              <p className='bold-date'>{createdDate?.format('MM-DD-YYYY')}</p>
              <p className='bold-date'>{createdDate?.format('LT')}</p>
            </div>
          </div>
        </ButtonContainerStyled>
        <GroupActions v2 />
        <GroupActionContainer>
          <div className='action-item action-item-left'>
            <MergeTicketButton v2 />
            <SplitButton v2 />
          </div>
          <div className='action-item'>
            <ButtonAddCustomer v2 />
          </div>
        </GroupActionContainer>
      </Header>
      <ContainerStyledV2>
        <div>
          <Customer v2 />
          <CustomerInfo v2 />
        </div>
        <BillSelectedItems v2 />
        <DiscountItemModal v2 />
        <UpdateQuickPay v2 />
      </ContainerStyledV2>
    </RightMainStyledV2>
  );
};
export default RightMainBody;
const RightMainStyledV2 = styled.div`
flex: 1;
display: flex;
flex-direction: column;
`;
const GroupActionContainer = styled.div`
display: flex;
align-self: stretch;
gap: 4px;
.action-item {
  flex: 1;
  display: flex;
  &.action-item-left {
    display: flex;
    flex-direction: column;
  }
}
`;
const RightMainStyled = styled.div<{ v2?: boolean }>`
  height: calc(100vh - 6.375rem);
  overflow: auto;
  background-color: #e5e5e5;
  display: flex;
  ${({ v2 }) => v2 && `
      height: 100%;
  `}
`;
const ContainerStyled = styled.div`
  padding: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;
const ContainerStyledV2 = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 1.5rem;
`;
const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 0.75rem;
  .ticket-info {
    display: flex;  
    gap: 16px;
    white-space: nowrap;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    .bold-date {
      font-weight: bolder;
      font-size: 18px;
    }
  }
  .ticket-number {
    white-space: nowrap;
    border-radius: 5px;
    background: #1D2129;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    padding: 0 0.5rem;
    margin-bottom: 1rem;

    p {
      color: var(--text-text-1, #FFF);
      text-align: center;
      /* Title/H5 - 24 semibold */
      font-family: Open Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

const Header = styled.div`
  max-height: 126px;
  height: 126px;
  background: #fff;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0rem 1.125rem;
  align-self: stretch;
  padding-right: 8px;
  gap: 8px;
  .ButtonContainerStyled {
    flex: 1;
    .bold-date {
      font-size: 20px;
    }
  }
  .ticket-number {
    height: 54px;
    margin-bottom: 0;
    p {
      font-size: 40px;
    }
  }
  
`;