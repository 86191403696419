import { get } from 'lodash';
import shopSelectors from 'services/shop/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';

type MyState = RootState['multiCard'];
const getCurrentState = (state: RootState): MyState => state['multiCard'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const detail = () => selector('detail') as MyState['detail'];

const visibleTip = () => {
  const valid = shopSelectors.data.showModalTip();
  const visibleTip = selector('visibleTip');
  if (valid === false) return false;
  return visibleTip as MyState['visibleTip'];
};

const getCards = () => selector('cards') as MyState['cards'];

const couponPrint = () => selector('couponPrint') as MyState['couponPrint'];
const detailPrint = () => selector('detailPrint') as MyState['detailPrint'];
const cardsPrint = () => selector('cardsPrint') as MyState['cardsPrint'];

const multiCardSelectors = {
  detail,
  visibleTip,
  getCards,
  couponPrint,
  detailPrint,
  cardsPrint,
};
export default multiCardSelectors;
