import { Tabs, TabsProps } from 'antd';
import clsx from 'clsx';
import CategoryItem from 'components/Category';
import Spin from 'components/Spin';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { ICategory, IServiceCategory } from 'features/cashier/services/types/api';
import React, { useCallback, useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const useRegular = () => {
  const categories = cashierSelectors.getCategories();
  const categoryActive = cashierSelectors.getActiveCategory();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();
  const dispatch = useAppDispatch();

  const handleClickCategory = (item: ICategory) => {
    dispatch(cashierActions.selectCategory(item));
  };

  return ({
    categories, categoryActive,
    serviceIdsSelected,
    handleClickCategory
  });
};
const REGULAR = ({ showUIStaffCashier }: { showUIStaffCashier?: boolean }) => {
  const { categories, categoryActive, handleClickCategory, serviceIdsSelected } = useRegular();
  const renderItem = useCallback((item: ICategory) => {
    return (
      <CategoryItem
        onClick={() => handleClickCategory(item)}
        selected={item.services.some(o => !!serviceIdsSelected.find(s => s.id === o.id))}
        isActive={categoryActive?.id === item.id}
      >
        <Text variant='CONTENT_2' color='text_3' textTransform='uppercase'>{item.categoryName}</Text>
      </CategoryItem>
    );
  }, [categories, categoryActive, serviceIdsSelected]);

  return (
    <div className="scroll-div">
      <div className="children-scroll">
        <CategoriesListStyled style={{ gridTemplateColumns: showUIStaffCashier ? '1fr' : '1fr 1fr' }}>{categories.map(renderItem)}</CategoriesListStyled>
      </div>
    </div >
  );
};
export const RegularCategories = () => {
  const { categories, categoryActive, handleClickCategory } = useRegular();
  return (
    <RegularContainer>
      {categories.map(o => (
        <RegularContainerItem key={o.id} onClick={() => handleClickCategory(o)} className={clsx(categoryActive?.id === o.id && 'active')}>
          <span>{o.categoryName}</span>
        </RegularContainerItem>
      ))}
    </RegularContainer>
  );
};
export const RegularContainer = styled.div`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
`;
export const RegularContainerItem = styled.button`
background: #FCFCFD;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
display: flex;
height: 70px;
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
padding: 0 8px;
span {
  color: #505050;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 166.667% */
  text-transform: capitalize;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}
&.active {
background: #2D6772;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #123F47 inset;
span {
  color: #fff;
}
}
`;
const useSimpleMenu = () => {
  const categoryActive = cashierSelectors.getActiveCategory();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();
  const dispatch = useAppDispatch();
  const simpleMenu = shopSelectors.data.simpleMenu();

  const categories = useMemo(() => {
    return simpleMenu.map(o => ({
      categoryName: o.categoryName,
      id: o.categoryId,
      services: o.services.map(s => ({
        catId: o.categoryId,
        commission: 0,
        duration: 0,
        id: s.id,
        priceCost: s.price,
        priceSell: s.price,
        serviceName: s.serviceName,
        supplyFee: s.supplyFee,
        backgroundColor: s.backgroundColor,
      }) as IServiceCategory)
    }) as ICategory);
  }, [simpleMenu]);

  const handleClickCategory = (item: ICategory) => {
    dispatch(cashierActions.selectCategory(item));
  };

  return ({
    categoryActive, serviceIdsSelected, categories, handleClickCategory,
  });
};

const SIMPLE_MENU = ({ showUIStaffCashier }: { showUIStaffCashier?: boolean }) => {
  const { categoryActive, serviceIdsSelected, categories, handleClickCategory } = useSimpleMenu();
  const renderItem = useCallback((item: ICategory) => {
    return (
      <CategoryItem
        onClick={() => handleClickCategory(item)}
        selected={item.services.some(o => !!serviceIdsSelected.find(s => s.id === o.id))}
        isActive={categoryActive?.id === item.id}
      >
        <Text variant='CONTENT_2' color='text_3' textTransform='uppercase'>{item.categoryName}</Text>
      </CategoryItem>
    );
  }, [categories, categoryActive, serviceIdsSelected]);

  return (
    <div className="scroll-div">
      <div className="children-scroll">
        <CategoriesListStyled style={{ gridTemplateColumns: showUIStaffCashier ? '1fr' : '1fr 1fr' }}>{categories.map(renderItem)}</CategoriesListStyled>
      </div>
    </div>
  );
};

export const SimpleCategories = () => {
  const { categoryActive, categories, handleClickCategory } = useSimpleMenu();
  return (
    <RegularContainer>
      {categories.map(o => (
        <RegularContainerItem key={o.id} onClick={() => handleClickCategory(o)} className={clsx(categoryActive?.id === o.id && 'active')}>
          <span>{o.categoryName}</span>
        </RegularContainerItem>
      ))}
    </RegularContainer>
  );
};

const Category = () => {
  const loading = cashierSelectors.loading.getCategories();
  const allSetting = shopSelectors.data.allSetting();

  const items: TabsProps['items'] = useMemo(() => [
    {
      key: 'regular',
      label: allSetting?.showUIStaffCashier ? 'REGULAR' : 'REGULAR MENU',
      children: <REGULAR showUIStaffCashier={allSetting?.showUIStaffCashier} />,
    },
    {
      key: 'simple',
      label: allSetting?.showUIStaffCashier ? 'SIMPLE' : 'SIMPLE MENU',
      children: <SIMPLE_MENU showUIStaffCashier={allSetting?.showUIStaffCashier} />,
    },
  ], [allSetting?.showUIStaffCashier]);

  return (
    <ScrollDivStyled>
      <AntTabs defaultActiveKey="regular" items={items} type="card" />
      {loading && <Spin />}
    </ScrollDivStyled>
  );
};

export default Category;
const AntTabs = styled(Tabs)`
  flex: 1;
  .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-nav-list {
    flex: 1;
    padding: 0 16px;
    .ant-tabs-tab {
      flex: 1;
      justify-content: center;
      background: transparent;
      border: none;
      border-bottom: 2px solid transparent;
      &.ant-tabs-tab-active {
      border-bottom: 2px solid #F5767F;
      }
      .ant-tabs-tab-btn {
        font-weight: 600;
        font-size: 20px;
      }

    }
  }
  .ant-tabs-content {
    height: 100%;
    .ant-tabs-tabpane {
      height: 100%;
    }
  }
`;
export const ScrollDivStyled = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  .scroll-div {
    flex: 1;
    height: 100%;
    position: relative;
  }
  .children-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
    padding: 1rem 1.5rem;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,0.5);
      &:hover {
        background-color: rgba(0,0,0,0.6);
      }
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #F5F5F5;
    }
  }
`;

export const CategoriesListStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  
`;