import { Col, Row } from 'antd';
import Empty from 'components/Empty';
import ServiceItem, { IconBadge, IconCount } from 'components/ServiceItem';
import Spin from 'components/Spin';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useCallback } from 'react';
import { IProductItemData } from 'services/shop/types/product';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { ActiveServicesV2Container, ActiveServicesV2Item } from './ActiveServices';
import clsx from 'clsx';
import { formatCurrency } from 'utils/formatCurrency';
import Icon from 'assets/Icons';
const gutter = 24;
const useActiveProducts = () => {
  const loading = cashierSelectors.loading.getCategories();
  const dispatch = useAppDispatch();
  const cateActive = cashierSelectors.getProductsByProductCateActive();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();

  const handleClickService = useCallback((serviceItem: IProductItemData) => () => {
    dispatch(cashierActions.selectProductItem(serviceItem));
  }, []);

  const listDisplay = cateActive?.products || [];

  return ({
    loading, serviceIdsSelected, handleClickService, listDisplay,
  });
};
const ActiveProducts = () => {
  const { loading, serviceIdsSelected, handleClickService, listDisplay } = useActiveProducts();
  const renderItem = useCallback((item: IProductItemData) => {
    const itemSelected = serviceIdsSelected.find(o => o.id === item.id);
    return (
      <ServiceItem
        size="LARGE"
        price={item.priceSell}
        service={item.productName}
        product
        unit={item.unit}
        onClick={handleClickService(item)}
        selected={!!itemSelected}
        count={itemSelected?.count}
      />
    );
  }, [handleClickService, serviceIdsSelected]);

  return (
    <>
      <ComponentStyled className='container-scroll-div'>
        <ContentContainer className='ContentContainer'>
          <Row justify='space-between' align={'middle'} style={{ flex: 1 }}>
            <Col style={{ flex: 1 }}>
              <Text variant="H6" color="text_2">
                SERVICES
              </Text>
            </Col>
          </Row>
        </ContentContainer>
        <div className='scroll-div'>
          <div className='children-scroll' style={{ marginRight: -gutter / 2, marginLeft: -gutter / 2 }}>
            <Row gutter={[gutter, gutter]} style={{ margin: 0 }}>
              {listDisplay.length > 0 ? listDisplay?.map((item: any, index: number) => {
                return (
                  <Col key={item.id + index} span={12}>
                    {renderItem(item)}
                  </Col>
                );
              }) : <Col flex={'auto'}><Empty /></Col>}
            </Row>
          </div>
        </div>
      </ComponentStyled>
      {loading && <Spin />}
    </>
  );
};

export const ActiveProductsV2 = () => {
  const { loading, serviceIdsSelected, handleClickService, listDisplay } = useActiveProducts();
  if (loading) return <Spin />;
  return <ActiveServicesV2Container>
    <div className="list-scroll">
      <div className="services-v2-container">
        {listDisplay.map(o => {
          const itemSelected = serviceIdsSelected.find(s => s.id === o.id);
          return (
            <ActiveServicesV2Item key={o.id} className={clsx(!!itemSelected && 'active')} onClick={handleClickService(o)}>
              <span style={{ textAlign: 'left' }}>{o.productName}</span>
              <span className="price">{formatCurrency(o.priceSell)}</span>
              {!!itemSelected && (
                <IconBadge>
                  {itemSelected.count ? (
                    <IconCount>
                      <Text className='text'>{itemSelected.count}</Text>
                    </IconCount>
                  ) : <Icon type="tick" />}
                </IconBadge>
              )}
            </ActiveServicesV2Item>
          );
        })}
      </div>
    </div>
  </ActiveServicesV2Container>;
};

export default ActiveProducts;
const ComponentStyled = styled.div`
  width:100%;
  &.container-scroll-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    .ContentContainer {
      margin-bottom: 4px;
    }
  }
  .scroll-div {
    flex: 1;
    position: relative;
  }
  .children-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    padding-right: 4px;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,0.5);
      &:hover {
        background-color: rgba(0,0,0,0.6);
      }
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #F5F5F5;
    }
  }
`;

const ContentContainer = styled.div`
  margin-bottom: 1rem;
`;