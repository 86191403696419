import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
type Props = {
  value?: string;
  onChange?: (value: string) => void;
};
const InputNote = ({ value, onChange = () => undefined }: Props) => {
  return (

    <Container>
      <div className="form-label">Note</div>
      <Input.TextArea
        value={value}
        onChange={e => onChange(e.target.value)}
        rows={5}
        placeholder='Add an appointment note'
      />
    </Container>
  );
};

export default InputNote;

const Container = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-bottom: 1px solid #86909C;
.form-label {
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-input {
  display: flex;
  padding: 7px 13px;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`;
