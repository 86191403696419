import React from 'react';

const CashBtnV2 = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.33338 13.4355L6.34069 13.4337L6.34796 13.4317C6.77837 13.313 7.24028 13.3032 7.79617 13.2997C17.9654 13.3093 28.1323 13.3076 38.2987 13.306C49.8208 13.3041 61.3425 13.3023 72.8666 13.3167L72.8769 13.3166C74.611 13.292 76.1083 14.9692 75.9654 16.7209L75.9632 16.7481L75.9632 16.7753C75.964 19.6487 75.9655 22.5219 75.9671 25.395C75.9721 34.7277 75.9771 44.0593 75.9564 53.3912L75.9564 53.4191L75.9586 53.4469C76.1083 55.2817 74.4351 56.9646 72.5956 56.8259L72.5705 56.824H72.5454C66.5111 56.824 60.4768 56.8234 54.4424 56.8229C39.3552 56.8215 24.2678 56.8201 9.18046 56.8274C8.91274 56.822 8.63846 56.8255 8.37555 56.8288C8.30527 56.8297 8.2358 56.8306 8.16749 56.8313C7.83365 56.8347 7.51669 56.8341 7.20957 56.814C6.59575 56.7738 6.0668 56.658 5.6095 56.3792L5.59966 56.3731L5.58961 56.3675C4.57985 55.7986 3.9897 54.6065 4.04709 53.4041L4.04788 53.3876L4.04785 53.371C4.02412 41.1696 4.0309 28.9646 4.04446 16.7623L4.04448 16.7408L4.04313 16.7194C3.94958 15.2426 4.93594 13.7869 6.33338 13.4355ZM20.3071 17.1681L19.8828 17.2016L19.7346 17.6006C18.0179 22.2234 13.5196 25.5427 8.6253 26.1156L8.03214 26.185L8.03616 26.7822C8.05945 30.2355 8.05628 33.6854 8.0531 37.139C8.05119 39.2133 8.04927 41.289 8.0531 43.3675L8.05419 43.9652L8.6484 44.0292C13.5382 44.5556 18.0166 47.9286 19.742 52.5351L19.9036 52.9665L20.3643 52.9679C33.4563 53.0086 46.5481 53.0052 59.6396 52.9713L60.1013 52.9701L60.2626 52.5375C61.9836 47.9213 66.4706 44.5744 71.364 44.0117L71.9556 43.9436L71.9545 43.3481C71.9539 42.9792 71.9532 42.6103 71.9525 42.2414C71.9428 37.0854 71.9331 31.9352 71.9647 26.7852L71.9684 26.1891L71.3764 26.1191C66.4784 25.5396 61.9864 22.2203 60.2662 17.5936L60.1069 17.165L59.6497 17.1593C52.2466 17.0666 44.8343 17.0802 37.4271 17.0938C32.639 17.1026 27.853 17.1114 23.0729 17.0914C22.1613 17.0812 21.2353 17.0948 20.3071 17.1681Z" fill="#1D2129" stroke="#1D2129" strokeWidth="1.33333" />
      <path d="M38.7552 21.0084C42.0499 20.7237 45.4361 21.6117 48.1308 23.537C51.1712 25.6623 53.3168 29.0417 53.8998 32.7092C54.5778 36.5699 53.5406 40.7018 51.0933 43.7694C48.2189 47.5081 43.3617 49.5859 38.6705 49.0944C34.5589 48.7419 30.6643 46.4505 28.3594 43.0271C25.8986 39.4883 25.2342 34.7904 26.5799 30.6992C28.2306 25.425 33.237 21.4185 38.7552 21.0084ZM38.6163 26.6249C38.5688 27.0554 38.5417 27.4825 38.5315 27.9163C37.3994 28.1739 36.2368 28.5943 35.4368 29.4789C33.9861 31.0144 34.1522 33.8413 35.9385 35.0548C37.5994 36.2716 39.8467 36.1225 41.5347 37.2546C42.5244 38.1122 41.555 39.7968 40.328 39.6375C39.0908 39.9154 38.3519 38.8613 37.7418 37.9901C36.7418 38.0173 35.7453 38.0139 34.7488 38.0003C34.1929 40.2645 36.6368 41.8441 38.579 42.1932C38.5688 42.6745 38.562 43.1559 38.5553 43.6372C39.2501 43.9321 40.0162 44.0439 40.7585 43.8914C41.5889 43.8202 41.294 42.7898 41.4364 42.2237C42.5787 41.9559 43.7752 41.5458 44.5582 40.6205C45.8496 39.1765 45.9038 36.6818 44.4057 35.3632C42.86 34.0243 40.6805 34.0108 38.9145 33.1126C38.1248 32.7838 37.857 31.7398 38.3248 31.0449C38.8671 30.367 39.8772 30.4009 40.6365 30.6178C41.3957 30.828 41.5008 31.9635 42.338 31.9737C43.2837 31.9533 44.4023 32.2143 45.2395 31.7499C45.2293 29.6925 43.3142 28.2519 41.4296 27.9265C41.4059 27.3198 41.3449 26.713 41.0703 26.1639C40.2704 26.33 39.1586 25.8419 38.6163 26.6249Z" fill="#1D2129" />
      <path d="M3.32011 59.9142C27.7623 59.9007 52.2079 59.9007 76.6501 59.9142C76.8264 61.5039 75.5722 63.1004 73.8978 62.9411C51.2995 62.9343 28.7012 62.9445 6.10295 62.9377C4.44884 63.0631 3.2591 61.4633 3.32011 59.9142Z" fill="#1D2129" />
      <path d="M3.32939 65.3276C27.775 65.3208 52.2206 65.3276 76.6662 65.3208C76.5848 66.1106 76.5679 67.0122 75.9239 67.5816C75.0731 68.5375 73.6969 68.3579 72.5512 68.3646C50.4004 68.3477 28.2529 68.368 6.10206 68.3545C4.45473 68.4562 3.25143 66.8834 3.32939 65.3276Z" fill="#1D2129" />
    </svg>
  );
};

export const CashBtnNormal = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.33338 13.4356L6.34069 13.4337L6.34796 13.4317C6.77837 13.3131 7.24028 13.3033 7.79617 13.2998C17.9654 13.3093 28.1323 13.3077 38.2987 13.306C49.8208 13.3042 61.3425 13.3023 72.8666 13.3168L72.8769 13.3167C74.611 13.2921 76.1083 14.9692 75.9654 16.721L75.9632 16.7481L75.9632 16.7754C75.964 19.6487 75.9655 22.5219 75.9671 25.3951C75.9721 34.7277 75.9771 44.0594 75.9564 53.3912L75.9564 53.4191L75.9586 53.4469C76.1083 55.2817 74.4351 56.9647 72.5956 56.8259L72.5705 56.8241H72.5454C66.5111 56.8241 60.4768 56.8235 54.4424 56.8229C39.3552 56.8216 24.2678 56.8202 9.18046 56.8274C8.91274 56.8221 8.63846 56.8255 8.37555 56.8289C8.30527 56.8298 8.2358 56.8307 8.16749 56.8313C7.83365 56.8348 7.51669 56.8341 7.20957 56.814C6.59575 56.7739 6.0668 56.6581 5.6095 56.3792L5.59966 56.3732L5.58961 56.3675C4.57985 55.7987 3.9897 54.6065 4.04709 53.4042L4.04788 53.3876L4.04785 53.3711C4.02412 41.1697 4.0309 28.9646 4.04446 16.7624L4.04448 16.7409L4.04313 16.7195C3.94958 15.2426 4.93594 13.787 6.33338 13.4356ZM20.3071 17.1681L19.8828 17.2016L19.7346 17.6006C18.0179 22.2234 13.5196 25.5427 8.6253 26.1157L8.03214 26.1851L8.03616 26.7823C8.05945 30.2356 8.05628 33.6854 8.0531 37.1391C8.05119 39.2134 8.04927 41.289 8.0531 43.3676L8.05419 43.9652L8.6484 44.0292C13.5382 44.5557 18.0166 47.9287 19.742 52.5351L19.9036 52.9665L20.3643 52.968C33.4563 53.0086 46.5481 53.0052 59.6396 52.9713L60.1013 52.9701L60.2626 52.5376C61.9836 47.9213 66.4706 44.5745 71.364 44.0117L71.9556 43.9437L71.9545 43.3482C71.9539 42.9793 71.9532 42.6104 71.9525 42.2415C71.9428 37.0854 71.9331 31.9352 71.9647 26.7853L71.9684 26.1892L71.3764 26.1191C66.4784 25.5397 61.9864 22.2204 60.2662 17.5936L60.1069 17.1651L59.6497 17.1593C52.2466 17.0667 44.8343 17.0803 37.4271 17.0939C32.639 17.1026 27.853 17.1114 23.0729 17.0915C22.1613 17.0813 21.2353 17.0948 20.3071 17.1681Z" fill="#B67650" stroke="#B67650" strokeWidth="1.33333" />
      <path d="M38.7552 21.0086C42.0499 20.7238 45.4361 21.6119 48.1308 23.5372C51.1712 25.6624 53.3168 29.0418 53.8998 32.7094C54.5778 36.5701 53.5406 40.702 51.0933 43.7695C48.2189 47.5082 43.3617 49.586 38.6705 49.0946C34.5589 48.742 30.6643 46.4507 28.3594 43.0272C25.8986 39.4885 25.2342 34.7906 26.5799 30.6993C28.2306 25.4252 33.237 21.4187 38.7552 21.0086ZM38.6163 26.6251C38.5688 27.0556 38.5417 27.4826 38.5315 27.9165C37.3994 28.1741 36.2368 28.5944 35.4368 29.4791C33.9861 31.0146 34.1522 33.8415 35.9385 35.0549C37.5994 36.2718 39.8467 36.1227 41.5347 37.2548C42.5244 38.1123 41.555 39.797 40.328 39.6376C39.0908 39.9156 38.3519 38.8614 37.7418 37.9903C36.7418 38.0174 35.7453 38.014 34.7488 38.0005C34.1929 40.2647 36.6368 41.8443 38.579 42.1934C38.5688 42.6747 38.562 43.156 38.5553 43.6373C39.2501 43.9322 40.0162 44.0441 40.7585 43.8916C41.5889 43.8204 41.294 42.7899 41.4364 42.2239C42.5787 41.9561 43.7752 41.546 44.5582 40.6206C45.8496 39.1767 45.9038 36.6819 44.4057 35.3634C42.86 34.0245 40.6805 34.011 38.9145 33.1127C38.1248 32.7839 37.857 31.7399 38.3248 31.0451C38.8671 30.3672 39.8772 30.4011 40.6365 30.618C41.3957 30.8281 41.5008 31.9637 42.338 31.9738C43.2837 31.9535 44.4023 32.2145 45.2395 31.7501C45.2293 29.6926 43.3142 28.2521 41.4296 27.9267C41.4059 27.3199 41.3449 26.7132 41.0703 26.1641C40.2704 26.3302 39.1586 25.8421 38.6163 26.6251Z" fill="#B67650" />
      <path d="M3.32011 59.9143C27.7623 59.9008 52.2079 59.9008 76.6501 59.9143C76.8264 61.504 75.5722 63.1005 73.8978 62.9412C51.2995 62.9344 28.7012 62.9446 6.10295 62.9378C4.44884 63.0632 3.2591 61.4633 3.32011 59.9143Z" fill="#B67650" />
      <path d="M3.32951 65.3277C27.7751 65.321 52.2207 65.3277 76.6663 65.321C76.5849 66.1107 76.568 67.0124 75.924 67.5818C75.0732 68.5377 73.697 68.358 72.5513 68.3648C50.4005 68.3479 28.253 68.3682 6.10218 68.3546C4.45485 68.4563 3.25155 66.8836 3.32951 65.3277Z" fill="#B67650" />
    </svg>
  );
};

export default CashBtnV2;
