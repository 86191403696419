
import Box from 'components/Box';
import { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import multiplePaymentSelectors from 'features/payment/pages/PaymentPage/services/selectors';
import { MultipleCardTypePassData, PaymentSocketData, TypePaymentActors } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import { useSocketContext } from 'hooks/useSocket';
import { set, sumBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { uiLoadingBasicRef } from 'services/UI/LoadingPage/UILoadingRef';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import AddATipMultiCard, { useAddATipMultiCardRef } from '../../components/AddATip';
import useUpdateTip from '../../hooks/useUpdateTip';
import multiCardActions from '../../services/actions';
import multiCardSelectors from '../../services/selectors';
import DescribePayment from './DescribePayment';
import DetailItems from './DetailItems';
import HeadPayment from './HeadPayment';
import PaymentKeyboard from './PaymentKeyboard';
import DetailPayment from './DetailPayment';
import { IPayMultiCardResData } from '../../services/types/card';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import multiCardApis from '../../services/apis';
import { IResponseDataBody } from 'services/response';
import { IApiBodyPayMultipleCard } from '../../services/types/api';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import Signature, { ISignatureRef } from '../../components/CustomerSignature';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import CreditCardLoading, { ICreditPaymentLoadingRef } from 'features/payment/components/PaymentLoading/CreditCardLoading';
import clsx from 'clsx';
import shopSelectors from 'services/shop/selectors';
import { useDollarAmountInputRef } from 'components/DollarAmount/Input';
type Props = {
  billId?: string;
  v2?: boolean;
  onClose?: () => void;
};
const CashierMultipleCard = ({ billId, v2, onClose }: Props) => {
  const { id: _id = '' } = useParams();
  const id = billId ?? _id;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const socketContext = useSocketContext();
  const ticketContext = useTicketWrapper();

  const detail = multiCardSelectors.detail();

  const updateTip = useUpdateTip({ actor: TypePaymentActors.CASHIER });

  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const creditCardLoadingRef = useRef<ICreditPaymentLoadingRef>(null);
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const dollarRef = useDollarAmountInputRef();
  const triggerClear = useRef(false);
  const signatureRef = useRef<ISignatureRef>(null);
  const paymentItemInfoRef = useRef<{ id: string, isDone: boolean }>({ id: '', isDone: false });
  const tipRef = useAddATipMultiCardRef();

  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();
  const cards = multiCardSelectors.getCards();
  const completedTicketModalRef = useConfirmTipTicketRef();
  const allSetting = shopSelectors.data.allSetting();
  const [amountKB, setAmountKB] = useState<number | null>();

  const onCompleted = () => {
    if (!detail?.billId) return;
    dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${detail?.billId}`);
    const callback = () => ticketContext.completedMultiCards(detail?.billId, true);
    if (!completedTicketModalRef.current) callback();
    else completedTicketModalRef.current.open(detail?.billId, callback);
  };


  const remaining = useMemo(() => {
    const totalPayment = sumBy(Object.values(cards), o => (o?.amount ?? 0));
    const value = (detail?.total ?? 0) - totalPayment;
    return value > 0 ? +(value?.toFixed(2)) : 0;
  }, [cards, detail]);

  useEffect(() => {
    if (triggerClear.current) {
      triggerClear.current = false;
      return;
    }
    const amountStr = remaining?.toFixed(2) || '';
    currencyRef.current?.setValue(amountStr);
    dollarRef.current?.init(remaining ? amountStr : '');
  }, [cards, detail]);

  useEffect(() => {
    dispatch(multiCardActions.getTransactionDetail.fetch(id));
  }, []);

  useEffect(() => {
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  const sendingData = (type: string, data?: any) => {
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detail?.billId || '',
      actor: TypePaymentActors.CASHIER,
      data,
    };
    switch (type) {
      case MultipleCardTypePassData.REQUEST_TIP: {
        set(body, 'action', MultipleCardTypePassData.REQUEST_TIP);
        break;
      }
      case MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING: {
        set(body, 'action', MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING);
        break;
      }
      case MultipleCardTypePassData.PAYMENT_PROCESSING: {
        set(body, 'action', MultipleCardTypePassData.PAYMENT_PROCESSING);
        break;
      }
      case MultipleCardTypePassData.PAYMENT_PROCESSING_DONE: {
        set(body, 'action', MultipleCardTypePassData.PAYMENT_PROCESSING_DONE);
        break;
      }
      case MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED: {
        set(body, 'action', MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED);
        break;
      }
      case MultipleCardTypePassData.REQUEST_SIGNATURE: {
        set(body, 'action', MultipleCardTypePassData.REQUEST_SIGNATURE);
        break;
      }
      default:
        break;
    }

    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData<string> = JSON.parse(message.body);
    if (!payment) return;
    if (payment.actor !== TypePaymentActors.CUSTOMER) return;

    switch (payment.action) {
      case MultipleCardTypePassData.REFRESH_DETAIL: {
        tipRef.current?.close();
        uiLoadingBasicRef.current?.setVisible(false);
        dispatch(multiCardActions.refreshDetail.fetch(detail?.billId || ''));
        break;
      }
      case MultipleCardTypePassData.ON_TIP: {
        tipRef.current?.close();
        uiLoadingBasicRef.current?.openTimeout(15000);
        break;
      }
      case MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING: {
        tipRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED: {
        dispatch(multiCardActions.getListMultipleCards.fetch(detail?.billId || ''));
        loadingRef.current?.setVisible(false);
        signatureRef.current?.close();
        break;
      }
      case MultipleCardTypePassData.PAYMENT_COMPLETED: {
        loadingRef.current?.setVisible(false);
        onCompleted();
        break;
      }
      case MultipleCardTypePassData.ON_CUSTOMER_SIGNATURE_CHANGE: {
        signatureRef.current?.setValue(payment.data);
        break;
      }
      default:
        break;
    }
  };

  const onRequestTip = () => {
    tipRef.current?.open();
    sendingData(MultipleCardTypePassData.REQUEST_TIP);
  };


  const onAddATip = (val: number) => {
    if (!detail?.billId) return;
    tipRef.current?.close();
    updateTip(detail?.billId, val);
  };

  const onChangeTip = (val: number) => {
    sendingData(MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING, { tip: val });
  };

  const onBack = () => {
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/check-in/sign-in`);
    if (onClose) return onClose();
    navigate(-1);
  };



  const onPostPayment = async () => {
    const isDone = (remaining - (amountKB || 0)) <= 0;
    if (!detail?.billId) return;
    creditCardLoadingRef.current?.open(amountKB || 0, 120000);
    sendingData(MultipleCardTypePassData.PAYMENT_PROCESSING, { amount: amountKB || 0 });
    const body: IApiBodyPayMultipleCard = { billId: detail?.billId, amount: amountKB || 0, done: isDone };
    try {
      const res: IResponseDataBody<IPayMultiCardResData> = await multiCardApis.payMultipleCard(body);
      const resData = res?.data?.data;
      if (resData) {
        const socketPayload = { id: resData.id, isDone: isDone };
        paymentItemInfoRef.current.id = socketPayload.id;
        paymentItemInfoRef.current.isDone = socketPayload.isDone;
        sendingData(MultipleCardTypePassData.REQUEST_SIGNATURE, socketPayload);
        dispatch(multiCardActions.getListMultipleCards.fetch(detail.billId));
        signatureRef.current?.open();
      } else {
        sendingData(MultipleCardTypePassData.PAYMENT_PROCESSING_DONE);
      }
    } catch (error) {
      sendingData(MultipleCardTypePassData.PAYMENT_PROCESSING_DONE);
    } finally {
      creditCardLoadingRef.current?.setVisible(false);
    }
  };

  const handleClear = () => {
    triggerClear.current = true;
    currencyRef.current?.clear();
    dollarRef.current?.focus('');
  };

  const onSignatureDone = async (signatureValue: string) => {
    const idCreditCard = paymentItemInfoRef?.current?.id;
    if (!idCreditCard) return;
    signatureRef.current?.close();
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<boolean> = await multiCardApis.updateSignatureForCreditCard(idCreditCard || '', removeBase64Prefix(signatureValue));
      if (res.data.data) {
        if (!paymentItemInfoRef?.current?.isDone) {
          sendingData(MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED, {});
          dispatch(multiCardActions.getListMultipleCards.fetch(detail?.billId || ''));
        } else {
          onCompleted();
        }
        return;
      }
    } catch (error) { }
    finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };

  if (!detail) return null;
  return (
    <PageStyled v2={v2}>
      {!v2 && <HeadPayment onRequestTip={onRequestTip} disabledClose={!!cards?.length} />}
      <PaymentFormStyled className={clsx(v2 && 'v2')}>
        <PaymentInfoBoxStyled>
          <Box display={'flex'} className='box-payment'>
            <Box width={v2 ? '700px' : '35rem'} overflow="auto" display='flex' flexDirection='column'>
              {v2 ?
                <Box style={{ background: '#232F3E' }} className="center" height={'4rem'}>
                  <Text variant="H5" style={{ color: '#FFF', fontSize: 30 }}>
                    MULTI-CARDS
                  </Text>
                </Box>
                :
                <Box bgColor="primary_button" className="center" height={'3.5rem'}>
                  <Text variant="H5" color="text_3">
                    Multiple Cards
                  </Text>
                </Box>}
              <PaymentKeyboard
                v2={v2}
                disabledClose={!!cards?.length}
                keyboardRef={currencyRef}
                inputRefV2={dollarRef}
                onBack={onBack}
                onDone={onCompleted}
                onPay={onPostPayment}
                onClear={handleClear}
                value={amountKB}
                remaining={remaining}
                onChange={setAmountKB}
                allSetting={allSetting}
              />
            </Box>
            <RightPayment className={v2 ? 'v2' : ''}>
              {!v2 && <DetailItems v2={v2} data={detail} />}
              <DescribePayment v2={v2} data={detail} />
              <DetailPayment v2={v2} cards={cards} remaining={remaining} />
            </RightPayment>
          </Box>
        </PaymentInfoBoxStyled>
        <PaymentLoading ref={loadingRef} />
        <AddATipMultiCard ref={tipRef} onAddATip={onAddATip} onChangeTip={onChangeTip} />
        <Signature ref={signatureRef} onAgree={onSignatureDone} />
        <ConfirmTipTicket ref={completedTicketModalRef} />
        <CreditCardLoading onCancel={onBack} ref={creditCardLoadingRef} />
      </PaymentFormStyled>
    </PageStyled>
  );
};

export default CashierMultipleCard;

const RightPayment = styled.div`
display: flex;
flex-direction: column;
width: 19rem;
overflow: auto;
&.v2 {
  width: 450px;
  background: #f5f5f5; 
  border-left: 1px solid #CCD4DC;
}
`;

const PageStyled = styled.div<{ v2?: boolean }>`
    min-height: 100vh;
    background: var(--fill-fill-3, #E5E5E5);
    display: flex;
    flex-direction: column;

    ${({ v2 }) => v2 && `
      min-height: unset;
      background: #E5E5E5;
    `}
`;

export const PaymentFormStyled = styled.div`
    display: flex; 
    justify-content: center;
    align-items: start;
    flex-grow:1;
    padding: 2rem;

    &.v2 {
      padding: 0;
    }
`;


const PaymentInfoBoxStyled = styled.div`
  .box-payment {
    background: #fff;
    border-radius: 5px;
    opacity: 0.9;
    background: var(--fill-fill-0, #FFF);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  display: flex;
  .divider {
    background: #86909C;
    width: 100%;
    height: 1px;
  }
`;