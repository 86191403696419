import Button from 'components/Button';
import Text from 'components/Text';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { debounce, set } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import TicketDetail from '../PaymentPage/CashierSide/BillPaymentConfirmModal/TicketDetail';
import AddATip, { ModalAddTipRef } from '../PaymentPage/CustomerSide/components/AddATip';
import multiplePaymentSelectors from '../PaymentPage/services/selectors';
import { PaymentSocketData, TypePaymentActors, TypePaymentPassData } from '../PaymentPage/services/types/socketPayment';

type Props = {
  isCustomer?: boolean;
};
const QRPaymentPage = ({ isCustomer }: Props) => {
  const dispatch = useAppDispatch();
  const payments = shopSelectors.data.orderPaymentMethods();
  const { id = '', type = '' } = useParams();
  const [detailTicket, setDetailTicket] = useState<IBillDetailData | null>(null);
  const setLoadingPage = useSetLoadingPage();
  const socketContext = useSocketContext();
  const ticketContext = useTicketWrapper();
  const completedTicketModalRef = useConfirmTipTicketRef();
  const tipRef = useRef<ModalAddTipRef>(null);
  const customerMessage = multiplePaymentSelectors.customerSide.getMessage();
  const cashierMsg = multiplePaymentSelectors.getCashierSocketMsg();
  const isFirstTip = useRef(false);
  const navigate = useNavigate();

  const activePayment = useMemo(() => {
    return payments.find(o => o.methodType === type);
  }, [type, payments]);

  const geDetailAsync = async () => {
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(id);
      const detail = res?.data?.data;
      if (detail) {
        setDetailTicket(detail);
        return detail;
      }
      return null;
    } catch (error) {
      return null;
    }
  };

  const getDetailTicket = useCallback(debounce(async () => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(id);
      const detail = res?.data?.data;
      if (detail) {
        setDetailTicket(detail);
        tipRef.current?.open(detail.tip || 0);
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  }, 1000), []);

  useEffect(() => {
    if (detailTicket) return;
    isFirstTip.current = false;
    dispatch(shopActions.get.otherPaymentMethod.fetch());
    getDetailTicket();
  }, []);

  const sendingData = (type: TypePaymentPassData, data?: any) => {
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: isCustomer ? TypePaymentActors.CUSTOMER : TypePaymentActors.CASHIER,
      data,
    };
    set(body, 'action', type);
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;

    const actor = isCustomer ? TypePaymentActors.CASHIER : TypePaymentActors.CUSTOMER;
    if (payment.shopId !== storage.shop_id.get()) return;

    if (payment.actor !== actor) return;

    switch (payment.action) {

      case TypePaymentPassData.PAYMENT_QR_SEND_TIP_AFTER_TYPING: {
        tipRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case TypePaymentPassData.REFRESH_DETAIL_DATA_AFTER_UPDATE_TIP: {
        isFirstTip.current = true;
        tipRef.current?.close();
        geDetailAsync();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (isCustomer) return;
    if (!cashierMsg) return;
    listeningData(cashierMsg);
  }, [cashierMsg]);

  useEffect(() => {
    if (!isCustomer) return;
    if (!customerMessage) return;
    listeningData(customerMessage);
  }, [customerMessage]);


  const handleChangeTip = (val: number) => {
    sendingData(TypePaymentPassData.PAYMENT_QR_SEND_TIP_AFTER_TYPING, { tip: val });
  };

  const onCancel = () => {
    if (isCustomer) return;
    socketContext.backToCheckIn();
    tipRef.current?.close();
    completedTicketModalRef.current?.close();
    navigate(`/store/${storage.shop_id.get()}`);
  };

  const handleAddATip = async (val: number) => {
    if (!detailTicket) return;
    setLoadingPage(true);
    isFirstTip.current = true;
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.updatePaymentTip({
        billId: detailTicket?.billId,
        tip: val,
      });
      if (res?.data?.data) {
        await geDetailAsync();
        sendingData(TypePaymentPassData.REFRESH_DETAIL_DATA_AFTER_UPDATE_TIP);
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const onSkipAddATip = () => tipRef.current?.close();

  const onDone = async () => {
    const billId = detailTicket?.billId || '';
    if (!billId) return;

    const body: IBodyAPIPayment = {
      billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.CHECK,
          amount: detailTicket?.total || 0,
          otherMethod: type,
        },
      ]
    };

    setLoadingPage(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${detailTicket?.billId}`);
        const callback = async () => await ticketContext.completedTicket(billId);
        if (!completedTicketModalRef.current) return await callback();
        completedTicketModalRef.current.open(billId, callback);
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return (
    <QRPaymentPageStyled>
      <div className='detail-ticket'>
        <div className="detail-ticket-box">
          <TicketDetail
            disableBack
            onBack={() => undefined}
            detailTicket={detailTicket}
            serviceCharge={0}
            total={detailTicket?.total || 0}
            discountTicket={detailTicket?.discountTicket || 0}
          />
        </div>
      </div>
      <div className='QR-container' style={{ paddingTop: isCustomer ? '5%' : '16px' }}>
        <div className='wrapper-QR'>
          <Text className="price" >{formatCurrency(detailTicket?.total)}</Text>
          <Text className="title" >Scan to pay with Cash App</Text>
          <BoxQR>
            <img
              src={activePayment?.imageQrCode}
              className='imageQR'
            />
          </BoxQR>
          {!isCustomer && <div className='qr-actions'>
            <Button onClick={onCancel} width="8.25rem" ntype="DEFAULT" >
              Cancel
            </Button>
            <Button onClick={onDone} width="8.25rem" ntype="PRIMARY" >
              Done
            </Button>
          </div>}
        </div>
      </div>
      <ConfirmTipTicket ref={completedTicketModalRef} />
      <AddATip total={detailTicket?.total} onChangeTip={handleChangeTip} isTipBefore isCustomerSide={isCustomer} ref={tipRef} onAddATip={handleAddATip} onCancel={onCancel} onSkipAddATip={onSkipAddATip} />
    </QRPaymentPageStyled>
  );
};

export default QRPaymentPage;

const BoxQR = styled.div`
  border-radius: 15.37px;
  background: #FFF;
  box-shadow: -0.76851px 0.76851px 3.07404px 0px rgba(0, 0, 0, 0.10) inset, 0px 3.07404px 3.07404px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // width: 50vw;
  padding: 1.5rem;
  max-width: 36.5rem;
  margin-bottom: 1rem;
  .imageQR {
    height: 35rem;
    width: 35rem;
    border-radius: 16px;
  }
  .imageIcon {
    width: 11rem;
    height:3rem;
    display: flex;
    justify-content: center;
    img {
      width: auto;
      height: 100%;
    }
  }
`;

const QRPaymentPageStyled = styled.div`
  background: #E5E5E5;
  display: flex;
  flex-direction: row;
  height: 100vh;

  .title { 
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .price {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 3.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 24px;
  }
  .detail-ticket {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 24px;
    .detail-ticket-box {
      width: 100%;
      padding-left: 5%;
      .ticket-detail-box {
        width: 100%;
        zoom: 1.2;
        .list-services-container {
          max-height: 250px;
        }
      }
    }
  }
  .QR-container {
    flex: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    .wrapper-QR {
      display: flex;
      flex-direction: column;
      align-items: center;
      
    }
    .qr-actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }
`;