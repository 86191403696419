import { Divider, Form, Input, Select } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import UploadQRImage from './components/UploadQRImage';

type ICheckProps = {};
const Check: React.FC<ICheckProps> = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const dataLoading = settingServiceSelectors.dataLoading.otherPaymentMethod();
  const otherStore =
    settingServiceSelectors.paymentSetting.otherPaymentMethod();

  const onFinish = (values: any) => {
    const others = values?.others?.map((o: any) => ({
      id: o.id || '',
      name: o.name,
      type: o.type,
      link: o.link,
      base64: o.base64 ? (o.base64?.startsWith('https://') ? o.base64 : removeBase64Prefix(o.base64)) : null,
    }));
    dispatch(settingServiceActions.postOtherPaymentMethod.fetch(others));
  };

  useEffect(() => {
    if (otherStore && otherStore.length > 0) {
      form.setFieldsValue({
        others: otherStore.map(o => ({
          id: o.id || '',
          name: o.name,
          type: o.methodType,
          link: o.linnkUrl,
          base64: o.imageQrCode,
        }))
      });
    }
  }, [otherStore]);

  useEffect(() => {
    dispatch(settingServiceActions.getOtherPaymentMethod.fetch());
  }, []);

  if (dataLoading) return null;

  return (
    <CheckStyled>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Box bgColor="fill_1" p="3" borderRadius="5px">
          <Text variant="H9" color="text_3">
            Check
          </Text>
          <Form.Item label="Line" name="line">
            <Input placeholder="* There Will Be A $25 Service Charge On All Returned Checks" />
          </Form.Item>
          <Divider />
          <Text variant="H9" color="text_3">
            Others
          </Text>

          <Form.List name="others">
            {(fields, { add, remove }) => (
              <OtherPaymentGroupContainer>
                <OtherPaymentGroup>
                  {fields.map(({ key, name, ...restField }, index) => {
                    return (
                      <OtherPaymentItem key={key}>
                        <OtherPaymentItemImage>
                          <Form.Item {...restField} name={[name, 'base64']} noStyle>
                            <UploadQRImage />
                          </Form.Item>
                          <button className="btn-delete" onClick={() => remove(index)}>
                            Delete
                          </button>
                        </OtherPaymentItemImage>
                        <OtherPaymentItemContent>
                          <Form.Item {...restField} name={[name, 'name']} noStyle>
                            <Input placeholder='Name' />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, 'type']} noStyle>
                            <Select
                              style={{ width: '100%', height: '3rem' }}
                              options={[
                                { value: 'CHECK', label: 'CHECK' },
                                { value: 'VENMO', label: 'VENMO' },
                                { value: 'CASH_APPS', label: 'CASH_APPS' },
                                { value: 'ZELLE', label: 'ZELLE' },
                                { value: 'PAYPAL', label: 'PAYPAL' },
                              ]}
                              placeholder='Payment type'
                            />
                          </Form.Item>
                          <Form.Item {...restField} name={[name, 'link']} noStyle>
                            <Input placeholder='Enter link' />
                          </Form.Item>
                        </OtherPaymentItemContent>
                      </OtherPaymentItem>
                    );
                  })}
                </OtherPaymentGroup>
                <Box mt="3">
                  <Button
                    ntype="LIGHT_BLUE"
                    icon="addPlus"
                    onClick={() => add()}
                  >
                    Add New Payment Type
                  </Button>
                </Box>
              </OtherPaymentGroupContainer>
            )}
          </Form.List>
        </Box>
        <Box display="flex" gap="3" mt="4" justifyContent="end">
          <Button width="10rem" ntype="SECONDARY" htmlType="reset">
            Reset
          </Button>
          <Button width="10rem" ntype="PRIMARY" htmlType="submit">
            Save
          </Button>
        </Box>
      </Form>
    </CheckStyled>
  );
};

export default Check;
const CheckStyled = styled.div`
  flex-grow: 1;
  background: var(--fill-fill-3, #e5e5e5);
  border-radius: 5px;
`;

const OtherPaymentGroupContainer = styled.div``;


const OtherPaymentGroup = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
grid-gap: 16px;
`;

const OtherPaymentItem = styled.div`
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 5px;
  background: #E5E6EB;
`;

const OtherPaymentItemImage = styled.div`
  display: flex;
  flex-direction: column;

  .btn-delete {
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    color: #F53F3F;
    text-align: center;

    /* Content/Content 2 - 16 semibold */
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const OtherPaymentItemContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  align-self: flex-start;
`;