import { Col, Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useMemo } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
type Props = {
  onBack: () => void;
  serviceCharge?: number;
  total: number;
  discountTicket: number;
  detailTicket: IBillDetailData | null;
  disableBack?: boolean;
};
const TicketDetail = ({ onBack, serviceCharge, total, discountTicket, detailTicket, disableBack }: Props) => {
  const calc = useMemo(() => ({
    serviceCharge: formatCurrency(serviceCharge),
    discByItem: formatCurrency(-(detailTicket?.discountItem || 0)),
    discByTicket: formatCurrency(-discountTicket),
    subTotal: formatCurrency((detailTicket?.subTotal || 0) - ((detailTicket?.discountItem || 0) + (detailTicket?.discountTicket || 0))),
    balance: formatCurrency(detailTicket?.subTotal),
    saleTax: formatCurrency(detailTicket?.saleTax),
    useTax: formatCurrency(detailTicket?.useTax),
    tip: formatCurrency(detailTicket?.tip),
    total: formatCurrency(total),
  }), [detailTicket, serviceCharge]);

  return (
    <TicketDetailStyled className='ticket-detail-box'>
      {!disableBack && <Button icon='back' size='middle' className='btn-back' onClick={onBack}>Back</Button>}
      <Text variant='H5' mt={1} style={{ marginBottom: '3vw' }}>Payment Details</Text>
      <div className="box-info">
        <Box display='flex'>
          <Box style={{ marginBottom: '0.5rem', background: '#1D2129', borderRadius: 4, padding: '0 8px' }} ><Text variant='H5' color='fill_1' >#{detailTicket?.ticketNumber}</Text></Box>
        </Box>
        <Text variant='H6' mb={0.25} >{detailTicket?.customerName}</Text>
        <Text variant='H8' color='text_2'>{detailTicket?.startTime ? momentTimezone(detailTicket?.startTime).format('MM-DD-YYYY LT') : null}</Text>
      </div>
      <div className="items list-services-container">
        {detailTicket?.items.map(o => (
          <div key={o.staffId} className='item'>
            <Text variant='H9'>{o.staffName}</Text>
            <Text className='border-dotted' mb={0.5} />
            {o.services.map((s, i) => (
              <Row key={i} align={'middle'} justify={'space-between'} className='service'>
                <Text variant='CONTENT_1'>{s.itemName}</Text>
                <Text variant='CONTENT_2'>{formatCurrency(s.price || 0)}</Text>
              </Row>
            ))}
          </div>
        ))}
      </div>
      <Text className="border" mb={2} />
      <Row>
        <Col span={14} style={{ display: 'flex' }}>
          <Box display='flex' flexDirection='column' pb='4' alignSelf='stretch' justifyContent='center' alignItems='center' style={{ flex: 1 }}>
            <Text variant='H3' color='info_3' style={{ color: '#0a5194' }}>Total Savings</Text>
            <Text variant='H3' color='info_3' style={{ fontSize: '2.5rem', fontWeight: 'bolder', color: '#0a5194' }}>{formatCurrency(-(detailTicket?.discountItem || 0) - (discountTicket))}</Text>
          </Box>
        </Col>
        <Col span={10}>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between' highlight>
            <Text variant='CONTENT_2' className='title-highlight'>Balance:</Text>
            <Text variant='H5' className='title-highlight'>{calc.balance}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Processing Fee:</Text>
            <Text variant='CONTENT_2'>{calc.serviceCharge}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Disc. By Item:</Text>
            <Text variant='CONTENT_2'>{calc.discByItem}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Disc. By Ticket:</Text>
            <Text variant='CONTENT_2'>{calc.discByTicket}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Subtotal:</Text>
            <Text variant='CONTENT_2'>{calc.subTotal}</Text>
          </Box>
          <Text className="border" />
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Sale Tax:</Text>
            <Text variant='CONTENT_2'>{calc.saleTax}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Use Tax:</Text>
            <Text variant='CONTENT_2'>{calc.useTax}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Tip:</Text>
            <Text variant='CONTENT_2'>{calc.tip}</Text>
          </Box>
          <Text className="border" />
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='H5' mr={2}>Total:</Text>
            <Text variant='H3' color='primary_active'>{calc.total}</Text>
          </Box>
        </Col>
      </Row>
    </TicketDetailStyled>
  );
};

export default TicketDetail;
const TicketDetailStyled = styled.div`
.btn-back {
  position: fixed;
  top: 0.5rem;
  right: 2.5rem;
}
  padding-right: 5vw;
  .items {
    max-height: 35vh;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
    }
    .border-dotted {
      border: 1px dotted #86909C;
    }
    .item {
      margin-bottom: 1rem;
    }
    .service {
      margin-bottom: 0.5rem;
    }
  }
  .border {
    border: 0.75px solid #86909C;
  }
  .box-info {
    margin-bottom: 2rem;
  }
  .group-info {
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
  }
`;
