import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { CSSProperties } from 'react';
import { styled } from 'styled-components';
import { signal } from '@preact/signals-react';
export const COL_CALENDAR_TIME_WIDTH = 82;
export const COL_CALENDAR_HEIGHT = 52;
export const PIXEL_PER_MINUTE = (COL_CALENDAR_HEIGHT) / 15;
export const fixedStyle: CSSProperties = { maxWidth: COL_CALENDAR_TIME_WIDTH, minWidth: COL_CALENDAR_TIME_WIDTH, width: COL_CALENDAR_TIME_WIDTH, padding: 0 };

export const isZoomIn = signal(false);

type TableStyledProps = {
    typeView: CALENDAR_VIEW_TYPE;
}
const Table = styled.table<TableStyledProps>`
    border-collapse: collapse;
    min-width: 100%;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  
    td, th {
        height: ${COL_CALENDAR_HEIGHT}px;
        ${({ typeView }) => {
        if (typeView === CALENDAR_VIEW_TYPE.WEEK_VIEW) return `
                min-width: auto;
                max-width: auto;
            `;

        if (typeView === CALENDAR_VIEW_TYPE.DAY_VIEW) return `
                min-width: 15rem;
                max-width: 15rem;
            `;
    }}
        ${() => isZoomIn.value ? `
            min-width: 12rem;
            max-width: 12rem;
        `: ''}
    }
    background: var(--fill-fill-0, #FFF);

`;

type HeadStyledProps = {}
const THead = styled.thead<HeadStyledProps>``;

type BodyStyledProps = {}
const TBody = styled.tbody<BodyStyledProps>`
    position: relative;
`;

type RowStyledProps = {
}
const TRow = styled.tr<RowStyledProps>`
    &.first-row {
        .box-time {
            .text-time {
                transform: unset;
            }
        }
    }
`;

type THStyledProps = {
    fixed?: boolean;
    fixedHigh?: boolean;
}
const TH = styled.th<THStyledProps>`
    border: 1.5px solid var(--grey-grey-300, #EBEBEB);
    ${({ fixed }) => fixed ? `
        position: sticky;
        top: 0;
        z-index: 11;
        background: var(--blue-headline, #C6E9EF);
        border:none;
    `: ''}
    ${({ fixedHigh }) => fixedHigh ? `
        position: sticky;
        top: 0;
        left:0;
        z-index: 12;
        background: var(--fill-fill-3, #E5E5E5);
        border:none;
        .actions {
            padding: 0 6px;
        }
    `: ''}
`;

type TDStyledProps = {
    fixed?: boolean;
    time?: boolean;
    disable?: boolean;
}
const TD = styled.td<TDStyledProps>`
    position: relative;
    ${({ fixed }) => fixed ? `
        position: sticky;
        left:0;
        z-index: 11;
        border:none;
        border-bottom: 1.5px solid var(--grey-grey-300, #EBEBEB);
        background: var(--fill-fill-0, #FFF);
    `: ''}

    ${({ time }) => time ? `
        .box-time {
            height: 100%;
            border-right: 1.5px solid var(--grey-grey-300, #EBEBEB);
            .text-time {
                background: var(--fill-fill-0, #FFF);
                transform: translateY(-10px);
                margin-right: 10px;
                color:#767676;
            }
        }
    `: ''}
    &.first-col {
        border-left: none;
    }

    ${({ disable }) => disable && `
        background: #e5e5e5;
    `}
`;

const CalendarStyled = {
    Table,
    THead,
    TBody,
    TRow,
    TH,
    TD,
};

export default CalendarStyled;