import React, { useEffect } from 'react';
import ModalHelpers from '../CheckInInputPage/components/ModalHelpers';
import styled from 'styled-components';
import RightContent from './RightContent';
import LeftContent from './LeftContent';
import { useAppDispatch } from 'store/hooks';
import checkInActions from 'features/checkIn/services/actions';

const CheckInGoogleNoReviewPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkInActions.getGoogleReview.fetch());
  }, []);
  return (
    <PageStyled>
      <ModalHelpers />
      <LeftContent />
      <RightContent />
    </PageStyled>
  );
};

export default CheckInGoogleNoReviewPage;
const PageStyled = styled.div`
    display: flex;
    background-color: white;
    img {
      pointer-events: none;
      -webkit-user-select: none; /* Safari */
      -ms-user-select: none; /* IE 10 and IE 11 */
      user-select: none; /* Standard syntax */
    }
`;
