import React from 'react';
import styled from 'styled-components';
import DetailInfo from './Detail';
import GiftCardStatistics from './Statistics';
import { IGiftCardDetail } from 'features/giftCards/services/types/reducer';
type Props = {
  detail?: IGiftCardDetail | null;
  onReset: () => void;
};
const GiftCardDetailInfo = ({ detail, onReset }: Props) => {
  return (
    <Container>
      <DetailInfo detail={detail} onReset={onReset} />
      <GiftCardStatistics detail={detail} />
    </Container>
  );
};

export default GiftCardDetailInfo;
const Container = styled.div`
display: flex;
padding: 16px 24px;
align-items: center;
align-self: stretch;
border-radius: 5px;
background: #232F3E;
box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
`;
