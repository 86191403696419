import { Col, Row } from 'antd';
import Text from 'components/Text';
import { OTHER_PAYMENT, PAYMENT_TYPE } from 'features/payment/services/constants';
import { IPaymentCreditCardInfo } from 'features/payment/services/types/payment';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { PHONE_MASKED } from 'utils/mask';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import IconLogo from './logo';
import { find, get } from 'lodash';
import Box from 'components/Box';

type IPrintBodyProps = {
  isPrinter?: boolean;
  billRef?: React.RefObject<HTMLDivElement>
};
const VoidPrintBody: React.FC<IPrintBodyProps> = ({ billRef, isPrinter }) => {
  const data = ticketListSelectors.ticketDetailsData.data();
  const shopInfo = shopSelectors.data.shopInfo();

  const describePayment = useMemo(() => {
    const creditPayment = find(data?.payments, o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
    return [
      creditPayment ? { name: 'Credit Fee', price: get(creditPayment, 'feeCreditCard', 0) } : null,
      { name: 'Sale Tax', price: data?.saleTax },
      { name: 'Use Tax', price: data?.useTax },
      { name: 'Disc. By Item', price: data?.discountItem },
      { name: 'Disc. By Ticket', price: data?.discountTicket },
    ].filter(o => !!o);
  }, [data]);

  const havePayments = useMemo(() => {
    let loyalty = false;
    let giftCard = false;
    let creditCard = false;
    let check = false;
    data?.payments.map(o => {
      if (o.paymentType === PAYMENT_TYPE.LOYALTY_POINT) {
        loyalty = true;
      }
      if (o.paymentType === PAYMENT_TYPE.GIFT_CARD) {
        giftCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CREDIT_CARD) {
        creditCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.CHECK) {
        check = true;
      }
    });

    return ({
      loyalty,
      giftCard,
      isHaveSignature: creditCard || check,
    });

  }, [data]);

  const moneyCard = useMemo(() => {
    return [
      { name: 'Loyalty Program', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.LOYALTY_POINT)?.amount },
      { name: 'Gift Card', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.GIFT_CARD)?.amount },
      { name: 'Cash', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CASH)?.amount },
      { name: 'Change Amount', price: data?.chance ? -data?.chance : null },
      { name: 'Check', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK)?.amount },
      { name: 'Cash pay', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.CASH_APPS)?.amount },
      { name: 'Zelle', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.ZELLE)?.amount },
      { name: 'Paypal', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.PAYPAL)?.amount },
      { name: 'Venmo', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.VENMO)?.amount },
    ].filter(o => !!o.price);
  }, [data]);

  const creditCard = useMemo(() => {
    const creditData = data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD) as (IPaymentCreditCardInfo | undefined);
    if (!creditData) return null;
    return {
      endCode: creditData.last4,
      cardType: creditData.cardType,
      authCode: creditData.authCode,
      trans: creditData.transNumber,
      entryType: creditData.entryType,
      amount: creditData?.amount,
    };
  }, [data]);


  if (!data) return <PrintBodyStyled />;

  return (
    <>
      <PrintBodyStyled id='receipt' >
        <TicketBox ref={billRef} >
          <CountingBox>
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <IconLogo />
                <TicketCodeBox>
                  #{data.ticketNumber}
                </TicketCodeBox>
              </Col>
              <Col>
                <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '1.5rem' }}>{shopInfo?.shopName || ''}</ShopText>
                <ShopText printMode variant="CONTENT_2" className={isPrinter ? 'bold' : ''} style={{ fontSize: '0.75rem' }}>
                  {shopInfo?.address || ''}{', ' + shopInfo?.city || ''}
                </ShopText>
                <ShopText printMode variant="CONTENT_2" style={{ fontSize: '0.75rem' }}>
                  {PHONE_MASKED.resolve(shopInfo?.phone || '')}
                </ShopText>
              </Col>
            </Row>
            <Text mt={0.5} />
            <Row justify={'center'} align={'middle'}>
              <TicketCodeBox>
                VOIDED
              </TicketCodeBox>
            </Row>
            <Text mt={0.5} />
            <Row justify={'center'} align={'middle'}>
              <Text printMode variant="CONTENT_2">
                Reason: {data?.reason}
              </Text>
            </Row>
            <Text mt={0.5} />
            <Text printMode mb={1} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2" className='customer_name'>{data.customerName}</Text>
              <Text printMode variant="CONTENT_1" color="text_2">
                {data?.customerPoint || 0} Point{(data?.customerPoint || 0) > 1 ? 's' : ''}
              </Text>
            </SpaceBetweenBox>
            <Text printMode variant="CONTENT_1" color="text_2">
              {momentTimezone(data.startTime).format('MM-DD-YYYY LT')} - {momentTimezone(data.endTime).format('LT')}
            </Text>
            <Text printMode mb={1} />

            {data?.items?.map((item) => (
              <div key={item.staffId + data?.billId}>
                <StaffHeaderBox>{item.staffName}</StaffHeaderBox>
                <ServiceBoxStyled>
                  {item.services.map((service) => (
                    <div key={service.itemId}>
                      <SpaceBetweenBox>
                        <Text printMode variant="CONTENT_2">
                          {service.itemName}
                        </Text>
                        <PriceBoxStyled>
                          <Text printMode variant="CONTENT_2">
                            {formatCurrency(service.price)}
                          </Text>
                        </PriceBoxStyled>
                      </SpaceBetweenBox>
                    </div>
                  ))}
                </ServiceBoxStyled>
              </div>
            ))}

            <StyledDivider my={0.5} />
            <PaddingBox>
              {describePayment.map((item) => (
                <SpaceBetweenBox key={item?.name}>
                  <Text printMode variant="CONTENT_2">
                    {item?.name}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(item?.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </PaddingBox>
            <StyledDivider mt={0.5} mb={1} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2">
                Subtotal
              </Text>

              <Text printMode variant="CONTENT_2">
                {formatCurrency(data?.subTotal)}
              </Text>
            </SpaceBetweenBox>
            <StyledDivider mt={0.5} mb={1} />

            <SpaceBetweenBox style={creditCard ? { height: '4rem', alignItems: 'unset', paddingBottom: '1rem' } : {}}>
              <Text printMode variant="CONTENT_2">
                Tip
              </Text>
              {creditCard ?
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
                  <Text printMode variant="H7">
                    {formatCurrency(data?.tip)}
                  </Text>
                  <LineDot style={{ transform: 'translateY(10px)' }} />
                </div> :
                <Text printMode variant="H7">
                  {formatCurrency(data?.tip)}
                </Text>
              }
            </SpaceBetweenBox>
          </CountingBox>
          <TotalBox>
            <SpaceBetweenBox style={creditCard ? { height: '4rem', alignItems: 'unset', paddingBottom: '1rem' } : {}}>
              <Text printMode variant="CONTENT_2">
                Total
              </Text>
              {creditCard ?
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
                  <Text printMode variant="H7">
                    {formatCurrency(data?.total)}
                  </Text>
                  <LineDot style={{ transform: 'translateY(10px)' }} />
                </div> :
                <Text printMode variant="H7">
                  {formatCurrency(data?.total)}
                </Text>
              }
            </SpaceBetweenBox>
          </TotalBox>
          <CardBox>
            {moneyCard.length > 0 && <MoneyBox>
              {moneyCard.map((item) => (
                <SpaceBetweenBox key={item.name}>
                  <Text printMode variant="CONTENT_2">
                    {item.name}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(item.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </MoneyBox>}

            {(havePayments.loyalty || havePayments.giftCard) &&
              <>
                {havePayments.loyalty &&
                  <SpaceBetweenBox my={0.5}>
                    <Text printMode variant="CONTENT_2">
                      Available Loyalty Points
                    </Text>
                    <Text printMode variant="CONTENT_2">
                      {(data?.availableLoyaltyPoint)} point
                    </Text>
                  </SpaceBetweenBox>}

                {havePayments.giftCard &&
                  <SpaceBetweenBox mb={0.5}>
                    <Text printMode variant="CONTENT_2">
                      Gift Card Balance
                    </Text>

                    <Text printMode variant="CONTENT_2">
                      {formatCurrency(data?.availableGiftCard)}
                    </Text>
                  </SpaceBetweenBox>}
              </>}

            {creditCard && <>
              <PaddingBox>
                <SpaceBetweenBox>
                  <Box display='flex' alignItems='center' gap='1'>
                    <Text printMode variant="CONTENT_2">
                      Credit Card
                    </Text>
                    <Box highlight><Text printMode variant='CONTENT_2' className='title-highlight'>{creditCard?.endCode || ''}</Text></Box>
                  </Box>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(creditCard?.amount)}
                  </Text>
                </SpaceBetweenBox>
                <Text my={0.5} printMode variant="CONTENT_2">
                  Card Type: {creditCard?.cardType}
                </Text>
                <Text mb={0.5} printMode variant="CONTENT_2">
                  authCode: {creditCard?.authCode}
                </Text>
                <Box display='flex' alignItems='center' gap='1'>
                  <Text printMode variant="CONTENT_2">
                    Trans Number #:
                  </Text>
                  <Box highlight><Text printMode variant='CONTENT_2' className='title-highlight'>{creditCard?.trans}</Text></Box>
                </Box>
                <Text my={0.5} printMode variant="CONTENT_2">
                  Entry Type: {creditCard?.entryType}
                </Text>
              </PaddingBox>
            </>}
          </CardBox>
          <FinalBox border={!havePayments.isHaveSignature}>
            <Text printMode variant='CONTENT_2'>CUSTOMER COPY</Text>
            <Text printMode variant="CONTENT_2" >Thank you for using our service</Text>
          </FinalBox>
          {/* ----------------Coupon */}
          <Row align={'middle'} justify={'center'} style={{ marginBottom: '1rem' }}>
            <TicketCodeBox>
              POS Stations #{storage.station_number.get()}
            </TicketCodeBox>
          </Row>
        </TicketBox>
      </PrintBodyStyled>
    </>
  );
};

export default VoidPrintBody;
const PrintBodyStyled = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  // justify-content: center;
  flex-grow: 1;
  .shop_name {
    color: black;
    text-align: right;
    font-family: 'Roboto', serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
  }
  .shop_address, .shop_phone {
    color: black;
    font-family: 'Roboto', serif;
    text-align: right;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .customer_name {
    color: black;
    font-family: 'Roboto', serif;
    font-size: 1.25rem;
  }
`;
const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 25.5rem;
  height: fit-content;
`;

const CountingBox = styled.div`
  padding: 1rem;
  border-bottom: 1px solid black;
`;

const TicketCodeBox = styled.div`
  border-radius: 5px;
  background: black;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const SpaceBetweenBox = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StaffHeaderBox = styled.div`
  border-bottom: 1px solid black;
  color: black;
  font-family: 'Roboto', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
const ShopText = styled(Text)`
  color: black;
  font-style: normal;
  font-family: 'Roboto', serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: right;

  &.bold {
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const ServiceBoxStyled = styled.div`
  padding: 0.5rem 0;
`;
const StyledDivider = styled(Text)`
  border-bottom: 1px solid black;
`;
const LineDot = styled(Text)`
border-bottom: 1px solid black;
/* border-bottom-style: dotted; */
width:50%;
`;

const PaddingBox = styled.div<{ borderDot?: boolean }>`
  padding: 0.5rem 0;
  ${({ borderDot }) => borderDot && `
    border-bottom: 1px solid black;
    // border-bottom-style: dotted;
  `}
`;
const TotalBox = styled.div`
  padding: 1rem;
  border-bottom: 1px solid black;
  /* border-bottom-style: dotted; */
`;

const CardBox = styled.div`
  padding: 0 1rem;
`;
const MoneyBox = styled.div`
  padding: 0.5rem 0;
  // border-bottom: 1px solid black;
  // border-bottom-style: dotted;
`;

const FinalBox = styled.div<{ border?: boolean }>`
  padding: 1rem 0 2rem;
  text-align: center;
  font-weight: 400;
  .bold {
    font-weight: 600;
  }
  ${({ border }) => border && `
    margin-top: 1rem;
    padding-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    border-top: 1px solid black;
  `}
`;
