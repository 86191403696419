import { find } from 'lodash';
import { ITurnManualSettingColorItem } from './types/turn';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR FEATURE NAME HERE
 * ex:
 *  NAME_REDUCER = 'NAME_REDUCER'
 *   => 
 *  NAME_REDUCER = 'booking'
 */
export const NAME_REDUCER = 'turn';

/**
 * NOTICE:
 *  PLEASE CHANGE ABSOLUTELY YOUR PREFIX ACTION NAME HERE
 * ex:
 *  PREFIX_ACTIONS = 'PREFIX_ACTIONS';
 *   => 
 *  PREFIX_ACTIONS = 'booking_feature_';
 */
export const PREFIX_ACTIONS = 'turn_feature_';


export const PATH_LOADING = {
  getTurn: `loading.${NAME_REDUCER}.getTurn`,
  getTurnDetail: `loading.${NAME_REDUCER}.getTurnDetail`,
  getTurnStaffsAvailable: `loading.${NAME_REDUCER}.getTurnStaffsAvailable`,
  updateTurnStaffsAvailable: `loading.${NAME_REDUCER}.updateTurnStaffsAvailable`,
};


export enum TURN_SORT_BY {
  CLOCK_IN = 'CLOCK_IN',
  TURN = 'TURN',
}

export enum TURN_ITEM_TYPE {
  WALK_IN = 'WALK_IN',
  APPOINTMENT = 'APPOINTMENT',
  BONUS = 'BONUS',
  REQUEST = 'REQUEST',
}

export enum TURN_ITEM_STATUS {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  FINISH_TURN = 'FINISH_TURN',
  VOIDED = 'VOIDED',
  MINUS_TURN = 'MINUS_TURN',
}

export const turnTypeColorSets = {
  [TURN_ITEM_TYPE.WALK_IN]: {
    bg: '#FFEAF6',
    stroke: '#C52884',
  },
  [TURN_ITEM_TYPE.APPOINTMENT]: {
    bg: '#E6FAE2',
    stroke: '#00AD45',
  },
  [TURN_ITEM_TYPE.REQUEST]: {
    bg: '#C6E9EF',
    stroke: '#4384FF',
  },
  [TURN_ITEM_TYPE.BONUS]: {
    bg: '#EEF4FF',
    stroke: '#4E268F',
  },
};

export const colorSetCodes: Record<string, {
  bg: string;
  shadow: string;
  stroke: string;
}> = {
  // default colors
  'red': {
    bg: '#F76C82',
    shadow: '#D94452',
    stroke: '#D94452',
  },
  'green': {
    bg: '#61DDBC',
    shadow: '#35BA9B',
    stroke: '#35BA9B',
  },
  'purple': {
    bg: '#B3A4EE',
    shadow: '#9579DA',
    stroke: '#9579DA',
  },
  'pink': {
    bg: '#D2649A',
    shadow: '#8E3E63',
    stroke: '#8E3E63',
  },
  'blue': {
    bg: '#66D4F1',
    shadow: '#3AADD9',
    stroke: '#3AADD9',
  },
  'white': {
    bg: '#fff',
    shadow: '#CCD4DC',
    stroke: '#CCD4DC',
  },


  // color set 1
  'FF6969': {
    bg: '#FF6969',
    shadow: '#C80036',
    stroke: '#C80036',
  },

  '80AF81': {
    bg: '#80AF81',
    shadow: '#508D4E',
    stroke: '#508D4E',
  },
  'C8A1E0': {
    bg: '#D875C7',
    shadow: '#674188',
    stroke: '#674188',
  },
  'E8C5E5': {
    bg: '#F075AA',
    shadow: '#BC5A94',
    stroke: '#BC5A94',
  },
  '96C9F4': {
    bg: '#96C9F4',
    shadow: '#0F67B1',
    stroke: '#0F67B1',
  },
  'FEFAF6': {
    bg: '#FEFAF6',
    shadow: '#EADBC8',
    stroke: '#EADBC8',
  },


  // // color set 2
  'C70039': {
    bg: '#F05941',
    shadow: '#872341',
    stroke: '#872341',
  },
  '4F6F52': {
    bg: '#4F6F52',
    shadow: '#1A4D2E',
    stroke: '#1A4D2E',
  },
  '864AF9': {
    bg: '#864AF9',
    shadow: '#3B3486',
    stroke: '#3B3486',
  },
  'CB1C8D': {
    bg: '#CB1C8D',
    shadow: '#7F167F',
    stroke: '#7F167F',
  },
  '227B94': {
    bg: '#227B94',
    shadow: '#16325B',
    stroke: '#16325B',
  },
  'EBF4F6': {
    bg: '#EBF4F6',
    shadow: '#088395',
    stroke: '#088395',
  },


};
export const turnLabels = {
  'WALK_IN': 'Walk-In',
  'APPOINTMENT': 'APPT.',
  'IN_PROGRESS': 'Active',
  'MINUS': 'Sub-Turn',
  'BONUS': 'Bonus',
  'REQUEST': 'Request',
  'CLEAR': 'Clear',
  'NEXT': 'Next',
};
export const turnBoxColorDataDefault: ITurnManualSettingColorItem[] = [
  {
    turnStatus: 'WALK_IN',
    color: 'red',
  },
  {
    turnStatus: 'APPOINTMENT',
    color: 'green',
  },
  {
    turnStatus: 'BONUS',
    color: 'purple',
  },
  {
    turnStatus: 'REQUEST',
    color: 'pink',
  },
  {
    turnStatus: 'IN_PROGRESS',
    color: 'blue',
  },
  {
    turnStatus: 'MINUS',
    color: 'white',
  },
  {
    turnStatus: 'NEXT',
    color: 'white',
  },
];

export const getTurnManualColorSettings = (settingColors: ITurnManualSettingColorItem[]) => {
  return turnBoxColorDataDefault.map(o => {
    const item = find(settingColors, s => s.turnStatus === o.turnStatus);
    return ({
      ...o,
      ...item || {},
    });
  });
};

export const turnBoxStyleStr = `
&.WALK_IN {
  background: #F76C82;
  box-shadow: 4px 4px 0px 0px #D94452;
}
&.APPOINTMENT {
  background: #61DDBC;
  box-shadow: 4px 4px 0px 0px #35BA9B;
}
&.BONUS {
  background: #B3A4EE;
  box-shadow: 4px 4px 0px 0px #9579DA;
}
&.REQUEST {
  background: #F199CE;
  box-shadow: 4px 4px 0px 0px #D56FAC;
}
&.MINUS {
  background: #fff;
  box-shadow: 4px 4px 0px 0px #CCD4DC;
  border: 1px solid #CCD4DC;
  .label-type, .service-text {
    color: #1D2129;
  }
}
&.CLEAR {
  background: #fff;
  box-shadow: 4px 4px 0px 0px #CCD4DC;
  border: 1px solid #CCD4DC;
}

&.DONE {
  background: #B4DF80;
  box-shadow: 4px 4px 0px 0px #89C053;
}
  
&.IN_PROGRESS {
  background: #66D4F1;
  box-shadow: 4px 4px 0px 0px #3AADD9;
}

&.NEXT {
  background: #fff;
}

`;

export const turnCount = 25;

export const getTurnAmountString = (turn: number) => {
  if (turn == 1) return 'FULL';
  if (turn == 0.5) return 'HALF';
  if (turn == 0) return 'NO_TURN';
  return '';
};
