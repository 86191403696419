
import { IBillDetailData } from 'features/payment/services/types/bill';
import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import actions from './actions';
import apis from './apis';
import { IMultiCardResDataItem } from './types/card';

const getTransactionDetail: ISagaFunc<string> = function* ({ payload }) {
  setLoadingPage(true);
  yield delay(200);
  try {
    const [res, resCard]: [IResponseDataBody<IBillDetailData>, IResponseDataBody<IMultiCardResDataItem[]>] = yield all([call(apis.getTransactionDetail, payload), call(apis.getListMultipleCards, payload)]);

    const resCardData = resCard?.data?.data;
    if (resCardData) {
      yield put(actions.getListMultipleCards.success(resCardData));
    }

    const detail = res?.data?.data;
    if (detail) {
      yield put(actions.getTransactionDetail.success(detail));
    }
  } catch (error) {
  } finally {
    setLoadingPage(false);
  }
};

const refreshDetail: ISagaFunc<string> = function* ({ payload }) {
  setLoadingPage(true);
  try {
    const res: IResponseDataBody<IBillDetailData> = yield call(apis.getTransactionDetail, payload);
    const detail = res?.data?.data;
    if (detail) {
      yield put(actions.refreshDetail.success(detail));
    }
  } catch (error) {
  } finally {
    setLoadingPage(false);
  }
};

const getListMultipleCards: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<IMultiCardResDataItem[]> = yield call(apis.getListMultipleCards, payload);
    const detail = res?.data?.data;
    if (detail) {
      yield put(actions.getListMultipleCards.success(detail));
    }
  } catch (error) {
  } finally {
    setLoadingPage(false);
  }
};

export default function* multiCardServiceSagas() {
  yield takeLatest(actions.getTransactionDetail.fetch, getTransactionDetail);
  yield takeLatest(actions.refreshDetail.fetch, refreshDetail);
  yield takeLatest(actions.getListMultipleCards.fetch, getListMultipleCards);
}
