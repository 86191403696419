import { Col, Row } from 'antd';
import authSelectors from 'features/auth/services/selectors';
import { forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ShopItem from './ShopItem';
import storage from 'utils/sessionStorage';
import { FeatureVerify, OTPVerificationRef } from 'widgets/OTPVerification';

type IShopsProps = {};
export type IShopsRef = {
  setActiveShopId: (val: string) => void;
};
const Shops = forwardRef<IShopsRef, IShopsProps>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const shops = authSelectors.owner.getShops();
  const chooseShopDevices = (shopId: string) => {
    const shopInfo = shops.find(o => o.id === shopId);
    if (!shopInfo) return;
    const callback = () => {
      storage.shop_timezone.set(shopInfo.timeZone);
      navigate(`/owner/devices/${shopId}`);
      dispatch(shopActions.get.allSetting.fetch(shopId));
      dispatch(shopActions.get.totalStation.fetch(shopId));
    };
    if (OTPVerificationRef.current)
      OTPVerificationRef.current?.open(callback, FeatureVerify.LOGIN, { shopId, phone: shopInfo.phone });
    else callback();
  };

  useImperativeHandle(ref, () => ({
    setActiveShopId: (val: string) => chooseShopDevices(val)
  }));

  return (
    <>
      <ShopsStyled gutter={[24, 24]}>
        {shops.map((o, i) => (
          <Col key={i} span={8}>
            <ShopItem data={o} onClick={() => chooseShopDevices(o.id)} />
          </Col>
        ))}
      </ShopsStyled>
    </>
  );
});
Shops.displayName = 'Shops';
export default Shops;
type ShopsStyledProps = {}
const ShopsStyled = styled(Row) <ShopsStyledProps>`
`;
