import { Row } from 'antd';
import styled from 'styled-components';
import StaffPicker from './StaffPicker';
import SwitchCalendarType from './SwitchCalendarType';
import TypeItemPicker from './TypeItemPicker';
type Props = {
  v2?: boolean;
};
const TableFilter = ({ v2 }: Props) => {
  if (v2) {
    return (
      <>
        <TypeItemPicker v2 />
        <StaffPicker v2 />
        <SwitchCalendarType v2 />
      </>
    );
  }
  return (
    <CalendarFilterStyled align={'middle'}>
      <TypeItemPicker />
      <StaffPicker />
      <SwitchCalendarType />
    </CalendarFilterStyled>
  );
};

export default TableFilter;

const CalendarFilterStyled = styled(Row)`
  gap: 12px;
`;
