import React from 'react';

const DiscountBtnV2 = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.1136 10.7488C38.6924 10.4124 41.3114 10.4199 43.8902 10.7544C48.898 11.4131 53.7364 13.3546 57.7903 16.3745C60.8867 18.6589 63.5329 21.5523 65.5463 24.8328C67.6168 28.2125 68.9956 32.0082 69.6059 35.9239C69.7922 37.0277 69.8053 38.1521 70 39.2531V42.3021C69.9279 42.4857 69.8512 42.6731 69.8502 42.8746C69.3289 50.0913 66.0312 57.0522 60.8222 62.0614C55.98 66.7829 49.4914 69.7635 42.7604 70.3707C38.0718 70.8082 33.2783 70.1336 28.9051 68.3767C22.9351 66.0427 17.8075 61.6763 14.4396 56.2277C12.1791 52.5462 10.7189 48.3719 10.22 44.0786C10.1067 43.4293 10.1329 42.7659 10 42.1194V38.8099C10.0655 38.4548 10.1179 38.0968 10.131 37.7352C10.6187 32.2753 12.6537 26.9597 15.9401 22.5737C17.0025 21.185 18.1276 19.8301 19.4409 18.6692C20.6914 17.4333 22.0992 16.3708 23.5594 15.3972C27.3232 12.9282 31.6505 11.325 36.1136 10.7488ZM38.4106 14.4546C32.1813 14.8238 26.0998 17.5186 21.6864 21.9459C17.8758 25.662 15.2783 30.5915 14.3226 35.8274C14.0137 37.5909 13.8499 39.3786 13.8967 41.1692C13.981 43.4836 14.3376 45.798 15.0162 48.0168C16.7788 53.9442 20.7504 59.1736 25.9641 62.4849C30.1061 65.161 35.0315 66.5843 39.9532 66.6039C44.9207 66.5946 49.8949 65.1535 54.0658 62.4353C59.4022 59.0218 63.4206 53.5928 65.0933 47.4752C65.9133 44.5855 66.1866 41.5534 65.9704 38.5606C65.5398 32.3624 62.7579 26.3722 58.3454 22.0077C55.7039 19.3888 52.5101 17.3134 49.0131 16.0391C45.6387 14.776 42.0031 14.24 38.4106 14.4546Z" fill="#1D2129" />
      <path d="M46.8062 22.4932C47.3959 22.4183 48.0249 22.4117 48.5632 22.7022C49.4739 23.0601 50.1058 24.044 49.9934 25.0259C49.8877 25.5825 49.5881 26.0744 49.3457 26.5795C44.3622 36.8049 39.3675 47.0237 34.385 57.2501C34.166 57.7195 33.7972 58.129 33.3217 58.3454C32.8611 58.5956 32.3276 58.5806 31.8203 58.545C30.9376 58.3792 30.1504 57.7158 29.9061 56.8387C29.6955 56.3468 29.8265 55.8024 30.0558 55.3433C35.2003 44.7778 40.3317 34.2047 45.4789 23.6411C45.7279 23.0817 46.2165 22.6591 46.8062 22.4932Z" fill="#1D2129" />
      <path d="M28.3005 24.3047C29.4659 24.2147 30.6612 24.1773 31.8069 24.4509C32.8927 24.6842 33.9823 25.1236 34.7667 25.9369C35.8347 26.9573 36.2793 28.4809 36.2718 29.9276C36.2821 32.1754 36.2793 34.4233 36.2727 36.6711C36.2961 38.1919 35.8094 39.7932 34.6693 40.8454C33.7033 41.7778 32.3629 42.1741 31.0637 42.3521C29.303 42.5414 27.4468 42.4318 25.8284 41.6559C24.6359 41.0881 23.7336 39.9872 23.3667 38.7222C22.9726 37.581 23.0587 36.3601 23.0503 35.1729C23.0531 33.4544 23.0447 31.7369 23.055 30.0185C23.0578 28.5052 23.5155 26.9048 24.6462 25.846C25.6263 24.9034 26.9882 24.5061 28.3005 24.3047ZM29.0437 28.1286C28.7507 28.2017 28.4559 28.3019 28.2116 28.4856C27.7426 28.8369 27.5217 29.4469 27.5376 30.0204C27.5302 32.2054 27.533 34.3914 27.5367 36.5765C27.5096 37.1378 27.6921 37.7318 28.117 38.115C28.5916 38.5208 29.2431 38.6323 29.8497 38.5985C30.5498 38.5929 31.2921 38.2725 31.6309 37.6316C31.9174 37.1424 31.8734 36.5596 31.8771 36.0152C31.8724 34.0157 31.8846 32.0152 31.8706 30.0147C31.8799 29.3326 31.5224 28.6064 30.8765 28.3272C30.3139 28.0339 29.655 28.0536 29.0437 28.1286Z" fill="#1D2129" />
      <path d="M48.9581 38.514C50.4464 38.4456 51.9937 38.4859 53.3819 39.0884C54.3441 39.452 55.1893 40.135 55.7266 41.0158C56.3585 42.0371 56.5953 43.258 56.5803 44.4461C56.5822 46.5413 56.5831 48.6354 56.5803 50.7296C56.5897 52.1932 56.2106 53.7327 55.2015 54.8346C54.1026 56.0115 52.4683 56.524 50.8995 56.6121C50.0318 56.7508 49.1538 56.6402 48.2889 56.5474C46.9962 56.3582 45.6811 55.8709 44.7684 54.9002C43.8717 54.0016 43.4645 52.7189 43.3663 51.4774C43.3372 49.103 43.3578 46.7287 43.3541 44.3543C43.3335 43.3789 43.4926 42.3848 43.9148 41.5002C44.3051 40.6316 44.9903 39.9074 45.8037 39.422C46.7659 38.8692 47.863 38.6255 48.9581 38.514ZM49.3363 42.366C48.9329 42.441 48.536 42.6143 48.2692 42.9348C47.8639 43.3611 47.79 43.9795 47.7825 44.5417C47.7825 46.4148 47.7834 48.2888 47.7825 50.1628C47.7797 50.7531 47.7619 51.3827 48.0521 51.9177C48.3226 52.4218 48.8674 52.7123 49.4196 52.7901C50.0851 52.8782 50.8209 52.8379 51.3947 52.4528C51.9535 52.077 52.1659 51.3696 52.1631 50.7268C52.1631 48.6008 52.1659 46.4757 52.1622 44.3506C52.1379 43.8052 51.9984 43.214 51.5688 42.843C50.9856 42.2705 50.0926 42.2854 49.3363 42.366Z" fill="#1D2129" />
    </svg>
  );
};
export const DiscountBtnNormal = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M36.1136 10.7488C38.6924 10.4124 41.3114 10.4199 43.8902 10.7544C48.898 11.4131 53.7363 13.3546 57.7903 16.3745C60.8867 18.6589 63.5329 21.5523 65.5463 24.8328C67.6168 28.2125 68.9956 32.0082 69.6059 35.9239C69.7922 37.0277 69.8053 38.1521 70 39.2531V42.3021C69.9279 42.4857 69.8512 42.6731 69.8502 42.8746C69.3289 50.0913 66.0312 57.0522 60.8222 62.0614C55.98 66.7829 49.4914 69.7635 42.7604 70.3707C38.0718 70.8082 33.2783 70.1336 28.9051 68.3767C22.9351 66.0427 17.8075 61.6763 14.4396 56.2277C12.1791 52.5462 10.7189 48.3719 10.22 44.0786C10.1067 43.4293 10.1329 42.7659 10 42.1194V38.8099C10.0655 38.4548 10.1179 38.0968 10.131 37.7352C10.6187 32.2753 12.6537 26.9597 15.9401 22.5737C17.0025 21.185 18.1276 19.8301 19.4409 18.6692C20.6914 17.4333 22.0992 16.3708 23.5594 15.3972C27.3232 12.9282 31.6505 11.325 36.1136 10.7488ZM38.4106 14.4546C32.1813 14.8238 26.0998 17.5186 21.6864 21.9459C17.8758 25.662 15.2783 30.5915 14.3226 35.8274C14.0137 37.5909 13.8499 39.3786 13.8967 41.1692C13.981 43.4836 14.3376 45.798 15.0162 48.0168C16.7788 53.9442 20.7504 59.1736 25.9641 62.4849C30.1061 65.161 35.0315 66.5843 39.9532 66.6039C44.9207 66.5946 49.8949 65.1535 54.0658 62.4353C59.4022 59.0218 63.4206 53.5928 65.0933 47.4752C65.9133 44.5855 66.1866 41.5534 65.9704 38.5606C65.5398 32.3624 62.7579 26.3722 58.3454 22.0077C55.7039 19.3888 52.5101 17.3134 49.0131 16.0391C45.6387 14.776 42.0031 14.24 38.4106 14.4546Z" fill="#B67650" />
      <path d="M46.8062 22.4932C47.3959 22.4183 48.0249 22.4117 48.5632 22.7022C49.4739 23.0601 50.1058 24.044 49.9934 25.0259C49.8877 25.5825 49.5881 26.0744 49.3457 26.5795C44.3622 36.8049 39.3675 47.0237 34.385 57.2501C34.166 57.7195 33.7972 58.129 33.3217 58.3454C32.8611 58.5956 32.3276 58.5806 31.8203 58.545C30.9376 58.3792 30.1504 57.7158 29.9061 56.8387C29.6955 56.3468 29.8265 55.8024 30.0558 55.3433C35.2003 44.7778 40.3317 34.2047 45.4789 23.6411C45.7279 23.0817 46.2165 22.6591 46.8062 22.4932Z" fill="#B67650" />
      <path d="M28.3006 24.3046C29.466 24.2147 30.6613 24.1772 31.807 24.4508C32.8928 24.6841 33.9824 25.1236 34.7668 25.9369C35.8348 26.9573 36.2794 28.4808 36.2719 29.9275C36.2822 32.1754 36.2794 34.4232 36.2729 36.6711C36.2963 38.1918 35.8095 39.7932 34.6694 40.8454C33.7034 41.7777 32.363 42.1741 31.0638 42.3521C29.3031 42.5414 27.447 42.4317 25.8286 41.6559C24.636 41.0881 23.7337 39.9871 23.3668 38.7222C22.9727 37.5809 23.0588 36.36 23.0504 35.1728C23.0532 33.4544 23.0448 31.7369 23.0551 30.0184C23.0579 28.5052 23.5156 26.9048 24.6463 25.846C25.6264 24.9034 26.9883 24.5061 28.3006 24.3046ZM29.0438 28.1285C28.7509 28.2016 28.456 28.3019 28.2117 28.4855C27.7428 28.8369 27.5218 29.4469 27.5378 30.0203C27.5303 32.2054 27.5331 34.3914 27.5368 36.5765C27.5097 37.1377 27.6922 37.7318 28.1172 38.115C28.5917 38.5207 29.2432 38.6322 29.8498 38.5985C30.5499 38.5929 31.2922 38.2724 31.6311 37.6315C31.9175 37.1424 31.8735 36.5596 31.8772 36.0152C31.8726 34.0157 31.8847 32.0152 31.8707 30.0147C31.88 29.3326 31.5225 28.6064 30.8766 28.3272C30.314 28.0339 29.6551 28.0536 29.0438 28.1285Z" fill="#B67650" />
      <path d="M48.9583 38.514C50.4466 38.4456 51.9938 38.4859 53.382 39.0884C54.3442 39.452 55.1895 40.135 55.7268 41.0158C56.3586 42.0371 56.5954 43.258 56.5804 44.4462C56.5823 46.5413 56.5832 48.6354 56.5804 50.7296C56.5898 52.1932 56.2107 53.7327 55.2016 54.8346C54.1027 56.0115 52.4684 56.524 50.8996 56.6121C50.0319 56.7508 49.1539 56.6402 48.289 56.5474C46.9963 56.3582 45.6812 55.8709 44.7686 54.9002C43.8718 54.0016 43.4647 52.7189 43.3664 51.4774C43.3374 49.103 43.358 46.7287 43.3542 44.3543C43.3336 43.3789 43.4927 42.3848 43.9149 41.5002C44.3052 40.6316 44.9904 39.9074 45.8038 39.422C46.7661 38.8692 47.8631 38.6255 48.9583 38.514ZM49.3364 42.366C48.933 42.441 48.5361 42.6143 48.2693 42.9348C47.864 43.3611 47.7901 43.9795 47.7826 44.5417C47.7826 46.4148 47.7835 48.2888 47.7826 50.1628C47.7798 50.7531 47.762 51.3827 48.0522 51.9177C48.3227 52.4218 48.8675 52.7123 49.4197 52.7901C50.0853 52.8782 50.821 52.8379 51.3948 52.4528C51.9536 52.077 52.1661 51.3696 52.1633 50.7268C52.1633 48.6008 52.1661 46.4757 52.1623 44.3506C52.138 43.8052 51.9985 43.214 51.5689 42.843C50.9857 42.2705 50.0927 42.2854 49.3364 42.366Z" fill="#B67650" />
    </svg>
  );
};

export default DiscountBtnV2;
