import { TURN_ITEM_TYPE, turnBoxStyleStr } from 'features/turn/services/constants';
import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import Box from 'components/Box';

type Props = {
  half?: boolean;
  full?: boolean;
  completed?: boolean;
  type?: keyof typeof TURN_ITEM_TYPE;
  serName?: string;
  onClick?: () => void;
  selected?: boolean;
};
const TurnServiceItem = ({ selected, onClick = () => undefined, serName, type, completed = false, full = false, half = false }: Props) => {
  return (
    <Container className='TurnServiceItem'>
      <button onClick={onClick} className={clsx('box-item', type, half && 'HALF_TURN', full && 'FULL_TURN', selected && 'selected')}>
        {half && <>
          <div className="container-half">
            <div className="half-bg">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                  <path d="M0.5 60V0H80.5L40.5 30L0.5 60Z" fill="#1D2129" />
                </svg>
              </div>
            </div>
            <div className="service-text">{serName}</div>
          </div>
        </>}
        {full && <>
          <div className="container-half container-full">
            <div className="service-text">{serName}</div>
          </div>
        </>}
        {completed && <div className='icon-completed'>{iconCheck}</div>}
      </button>
    </Container>
  );
};

export default TurnServiceItem;

const Container = styled.div`
.box-item {
  width: 62px;
  height: 62px;
  border-radius: 5px;
  border: 2px solid #CCD4DC;
  background: #F0F0F0;
  position: relative;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  ${turnBoxStyleStr}
  .icon-completed {
    position: absolute;
    bottom: 2px;
    right: 4px;
  }


  .half-bg {
    position: absolute;
    inset: 0;
  }

  .container-half {
    flex: 1;
    height: 40px;
  }

  .service-text {
    white-space: nowrap;
    position: relative;
    color: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  &.HALF_TURN {
   .service-text {
      position: absolute;
      top: 2px;
      white-space: nowrap;
      left: 4px;
    }
  }
  &.FULL_TURN {
    .service-text {
      text-align: center;
    }
  }

  &.selected {
    box-shadow: 0px 0px 0px 3px #F53F3F;
  }
}
.container-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
}

`;

const iconCheck = <Box display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 20, width: 20, height: 20, background: '#00AD45' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6.48494 12C6.2715 12 6.06873 11.9107 5.91932 11.7544L2.89911 8.59574C2.58962 8.27206 2.58962 7.73631 2.89911 7.41263C3.20859 7.08894 3.72085 7.08894 4.03033 7.41263L6.48494 9.97977L11.9703 4.24276C12.2798 3.91908 12.7921 3.91908 13.1015 4.24276C13.411 4.56645 13.411 5.1022 13.1015 5.42588L7.05055 11.7544C6.90114 11.9107 6.69838 12 6.48494 12Z" fill="white" />
  </svg>
</Box>;
