import { AutoComplete, Input, InputRef, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import { AxiosResponse } from 'axios';
import Spin from 'components/Spin';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { memo, useCallback, useMemo, useState } from 'react';
import { getCustomersFilter } from 'services/shop/apis';
import shopSelectors from 'services/shop/selectors';
import { ICustomerItemData } from 'services/shop/types/customer';
import NotFoundCustomerBox from './NotFoundCustomerBox';
export const maskPhone = (val: string) => {
  if (!val) return '';
  return '(***) ***-' + val.slice(val.length - 4, val.length);
};
type CustomerSearchInputProps = {
  onSelect: (data: ICustomerItemData) => void;
  onOpenAddNew?: (value: { name?: string, phone?: string }) => void;
  inputRef: React.RefObject<InputRef>;
  v2?: boolean;
};
const CustomerSearchInput = ({ onSelect, onOpenAddNew, inputRef, v2 }: CustomerSearchInputProps) => {
  const customerStore = shopSelectors.data.customers();
  const [customersFilter, setCustomersFilter] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const initialOptions = useMemo(() => {
    return customerStore.map(o => {
      const point = +o?.point || 0;
      const phone = maskPhone(o?.phone || '');
      return ({
        value: o.id,
        name: o.name || '',
        keywords: [o.name?.toLocaleLowerCase(), phone],
        label: renderLabel(o.name || '', point > 1 ? `${point} points` : point > 0 ? `${point} point` : '', phone),
      });
    });
  }, [customerStore]);

  const [searchText, setSearchText] = useState<string>('');

  const optionsFilter = useMemo(() => customersFilter.map(o => {
    const point = +o?.point || 0;
    const phone = maskPhone(o?.phone || '');
    return ({
      value: o.id,
      name: o.name,
      label: renderLabel(o.name, point > 1 ? `${point} points` : point > 0 ? `${point} point` : '', phone),
    });
  }), [customersFilter]);

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return optionsFilter;
  }, [initialOptions, searchText, optionsFilter]);

  const handleSwitchCustomer = (data: string) => {
    inputRef.current?.blur();
    setSearchText('');
    const result = (searchText ? customersFilter : customerStore)?.find((item) => item.id === data);
    if (!result) return;
    onSelect(result);
  };

  const getList = useCallback(debounce(async (search: string) => {
    try {
      const res: AxiosResponse<{ data: { content: ICustomerItemData[] } }> = await getCustomersFilter(search);
      if (res.data.data.content) {
        setCustomersFilter(res.data.data.content);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  }, 500), []);

  const _onOpenAddNew = (value: { name?: string, phone?: string }) => {
    if (onOpenAddNew) onOpenAddNew(value);
    setSearchText('');
  };

  return (
    <AutoComplete
      className='CustomerSearchInput'
      style={{ width: '100%' }}
      options={options}
      dropdownRender={(menu) => (<>
        {searchText && loading ? <Row align={'middle'} justify={'center'}><Text py={1.5}><Spin /></Text></Row> : menu}
      </>)}
      notFoundContent={<NotFoundCustomerBox searchText={searchText} onOpenAddNew={_onOpenAddNew} />}
      onSelect={handleSwitchCustomer}
      value={searchText}
      maxLength={11}
      onChange={text => {
        if (text) setLoading(true);
        setSearchText(text);
        getList(text);
      }}
    >
      <Input
        ref={inputRef}
        suffix={<SearchIcon fill={v2 ? '#fff' : undefined} />}
        placeholder='Last 4 number / Name'
      />
    </AutoComplete>
  );
};

export default memo(CustomerSearchInput);

const renderLabel = (name: string, point: string, tel: string) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
      }}
    >
      <Text variant="CONTENT_2" color="text_3">
        {name}
      </Text>
      <Text variant="BODY_1" color="text_2">
        {point}
      </Text>
    </div>
    <div>
      <Text variant="BODY_1" color="text_3">
        {tel}
      </Text>
    </div>
  </div>
);
