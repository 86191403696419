import React, { CSSProperties } from 'react';
import colorTheme from 'constants/color';
import styled from 'styled-components';

const sizeValues = [4, 8, 16, 24, 28, 32, 36, 40, 48, 56, 64, 80];
const BOX_SHADOW_STYLES = {
  ACTIVE: '0px 0px 20px 0px rgba(95, 186, 203, 0.15)',
  DEFAULT:
    '-1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
} as const;
type BoxShadowStyles = keyof typeof BOX_SHADOW_STYLES;

type SizeValueKeys =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12'; // Update this based on the size of your sizeValues array

type ColorKeys = keyof typeof colorTheme;
type FlexDirection = 'row' | 'row-reverse' | 'column' | 'column-reverse';
type Display = 'flex' | 'block' | 'inline' | 'inline-block' | 'none';
type JustifyContent =
  | 'center'
  | 'start'
  | 'end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';
type AlignItems = 'center' | 'start' | 'end' | 'stretch' | 'baseline';
type Position = 'static' | 'relative' | 'absolute' | 'fixed' | 'sticky';
type Overflow = 'visible' | 'hidden' | 'scroll' | 'auto';
type BorderStyles = 'dotted' | 'dashed' | 'solid' | 'none' | 'hidden';
type FlexWrap = 'wrap' | 'nowrap' | 'wrap-reverse';

type IBoxProps = {
  children?: React.ReactNode;
  className?: string;
  p?: SizeValueKeys;
  px?: SizeValueKeys;
  py?: SizeValueKeys;
  pt?: SizeValueKeys;
  pr?: SizeValueKeys;
  pb?: SizeValueKeys;
  pl?: SizeValueKeys;

  m?: SizeValueKeys;
  mx?: SizeValueKeys;
  my?: SizeValueKeys;
  mt?: SizeValueKeys;
  mr?: SizeValueKeys;
  mb?: SizeValueKeys;
  ml?: SizeValueKeys;

  b?: ColorKeys;
  bl?: ColorKeys;
  bt?: ColorKeys;
  br?: ColorKeys;
  bb?: ColorKeys;
  bStyle?: BorderStyles;
  btStyle?: BorderStyles;
  bbStyle?: BorderStyles;
  blStyle?: BorderStyles;
  brStyle?: BorderStyles;

  display?: Display;
  justifyContent?: JustifyContent;
  alignItems?: AlignItems;
  alignSelf?: CSSProperties['alignSelf'];
  flexDirection?: FlexDirection;
  gap?: SizeValueKeys;

  borderRadius?: string;
  boxShadow?: BoxShadowStyles;
  bgColor?: ColorKeys;

  width?: string | number;
  height?: string | number;
  position?: Position;
  overflow?: Overflow;
  opacity?: CSSProperties['opacity'];

  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
  flexWrap?: FlexWrap;

  onClick?: () => void;
  style?: CSSProperties,
  highlight?: boolean;
};

const Box: React.FC<IBoxProps> = ({
  children,
  className,
  onClick,
  ...rest
}) => {
  return (
    <BoxStyled onClick={onClick} className={className} {...rest}>
      {children}
    </BoxStyled>
  );
};
export default Box;

const BoxStyled = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'flexDirection',
      'bgColor',
      'borderRadius',
      'alignItems',
      'flexWrap',
      'justifyContent',
      'bStyle',
    ].includes(prop),
}) <IBoxProps>`
  ${({ p }) => p && `padding: ${getPaddingValue(p)};`}
  ${({ pt }) => pt && `padding-top: ${getPaddingValue(pt)};`}
${({ pr }) => pr && `padding-right: ${getPaddingValue(pr)};`}
${({ pl }) => pl && `padding-left: ${getPaddingValue(pl)};`}
${({ pb }) => pb && `padding-bottom: ${getPaddingValue(pb)};`}
${({ px }) =>
    px &&
    `padding-left: ${getPaddingValue(px)}; padding-right: ${getPaddingValue(
      px
    )};`}
${({ py }) =>
    py &&
    `padding-top: ${getPaddingValue(py)}; padding-bottom: ${getPaddingValue(
      py
    )};`}

${({ m }) => m !== undefined && `margin: ${sizeValues[m]}px;`}
${({ ml, mx }) =>
    ml !== undefined
      ? `margin-left: ${sizeValues[ml]}px;`
      : mx !== undefined && `margin-left: ${sizeValues[mx]}px;`}
${({ mr, mx }) =>
    mr !== undefined
      ? `margin-right: ${sizeValues[mr]}px;`
      : mx !== undefined && `margin-right: ${sizeValues[mx]}px;`}
${({ mt, my }) =>
    mt !== undefined
      ? `margin-top: ${sizeValues[mt]}px;`
      : my !== undefined && `margin-top: ${sizeValues[my]}px;`}
${({ mb, my }) =>
    mb !== undefined
      ? `margin-bottom: ${sizeValues[mb]}px;`
      : my !== undefined && `margin-bottom: ${sizeValues[my]}px;`}

  ${({ display }) => display && `display: ${display};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content: ${justifyContent};`}
  ${({ alignItems }) => alignItems && `align-items: ${alignItems};`}
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf};`}
  ${({ flexDirection }) => flexDirection && `flex-direction: ${flexDirection};`}
  ${({ gap }) => gap && `gap: ${sizeValues[parseInt(gap) - 1]}px;`}

  ${({ b, bStyle }) =>
    b && `border: 1px ${getBorderStyle(bStyle)} ${colorTheme[b]};`}
  ${({ bl, bStyle }) =>
    bl && `border-left: 1px ${getBorderStyle(bStyle)} ${colorTheme[bl]};`}
  ${({ bt, bStyle }) =>
    bt && `border-top: 1px ${getBorderStyle(bStyle)} ${colorTheme[bt]};`}
  ${({ br, bStyle }) =>
    br && `border-right: 1px ${getBorderStyle(bStyle)} ${colorTheme[br]};`}
  ${({ bb, bStyle }) =>
    bb && `border-bottom: 1px ${getBorderStyle(bStyle)} ${colorTheme[bb]};`}

  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
  ${({ position }) => position && `position: ${position};`}
  ${({ top }) => top && `top: ${top};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ left }) => left && `left: ${left};`}

  ${({ bgColor }) => bgColor && `background-color: ${getColor(bgColor)};`}
  ${({ boxShadow }) =>
    boxShadow && ` box-shadow: ${BOX_SHADOW_STYLES[boxShadow]}; `}
  ${({ width }) =>
    width !== undefined &&
    ` width: ${typeof width === 'number' ? `${width}` : width}; `}
  ${({ height }) =>
    height !== undefined &&
    ` height: ${typeof height === 'number' ? `${height}` : height}; `}
  ${({ overflow }) => overflow && ` overflow: ${overflow}; `}
  ${({ flexWrap }) => flexWrap && `flex-wrap: ${flexWrap};`}
  ${({ opacity }) => opacity !== undefined && `opacity: ${opacity};`}

  &.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  ${({ highlight }) => highlight && `
    background: black;

    .title-highlight {
      text-transform: uppercase;
      font-weight: 600;
    }
    .title-highlight, .text-highlight {
      color: white !important;
      &.fs-lg {
        font-size: 1.25rem;
      }
    }
  `}
`;

const getColor = (
  colorKeyOrValue: keyof typeof colorTheme | string
): string => {
  return (
    colorTheme[colorKeyOrValue as keyof typeof colorTheme] || colorKeyOrValue
  );
};

const getPaddingValue = (key: string) => {
  const index = parseInt(key, 10);
  return `${sizeValues[index - 1] || 0}px`;
};
const getBorderStyle = (value?: BorderStyles) => (value ? value : 'solid');
