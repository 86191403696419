
import { AxiosResponse } from 'axios';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import uiActions from 'services/UI/actions';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { IApiGetDashboardTimeClockReportParams, IApiGetDemoDataParam, IApiGetPrintSalaryStaffDetailParams, IApiGetPrintSalarySummaryStaffOneDayParams, IApiGetPrintSalarySummaryStaffRangeDateParams, IApiGetPrintSummarySalonParam, IApiGetPrintSummaryServiceCategoryParam, IApiGetSalaryStaffDetailParam, IApiGetSummarySalaryStaffParam, IApiGetSummarySalonParam, IApiGetSummaryServiceCategoryParam, IApiGetSummaryTicketParam, IApiGetTimeClockReportHistoryParams, IApiGetTopRankingServiceParam } from './types/api';
import { IDEMOModelResDataItem } from './types/demoData';
import { IDashboardTimeClockReport, IPrintSalaryStaffDetailOneDay, IPrintSalaryStaffDetailRangeDate, IPrintSummarySalon, IPrintSummaryServiceCategory, ISalarySalonInfo, ISalaryStaffDetail, ISalaryStaffInfo, IStaffReportPaymentTypeItem, ISummaryDashboardSalaryStaff, ISummarySalon, ISummaryServiceCategory, ISummaryTicket, ITimeClockReportHistory, ITopRankingService } from './types/reportStaff';
import { IResponseDataBody } from 'services/response';
import { IParamsReviewReport, IReviewReportItem, IReviewReportSummary } from './types/review';
import moment from 'moment';
import reportSelectors from './selectors';

const getDemoData: ISagaFunc<IApiGetDemoDataParam> = function* () {
  yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: true }));
  try {
    const resData: AxiosResponse<{ data: IDEMOModelResDataItem[] }> = yield call(apis.getDemoData);
    if (resData?.data?.data) {
      yield put(actions.getDemoData.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getDemoData.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getData, result: false }));
  }
};

/** TODO:
 * please replace report => your feature name
 * ex:
 *      my feature is booking 
 *  name sagas like
 *      reportServiceSagas => bookingServiceSagas
 * 
 * ---- TODO:
 * Move to rootSagas (src/store/root/sagas.ts) and import your feature sagas
 */

const getPrintSummarySalon: ISagaFunc<IApiGetPrintSummarySalonParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getPrintSummarySalon, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetPrintSummarySalonParam = payload;
  try {
    const resData: AxiosResponse<{ data: IPrintSummarySalon }> = yield call(apis.getPrintSummarySalon, params);
    if (resData?.data?.data) {
      yield put(actions.getPrintSummarySalon.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getPrintSummarySalon.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getPrintSummarySalon, result: false }));
    yield setLoadingPage(false);
  }
};

const getSummarySalon: ISagaFunc<IApiGetSummarySalonParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getSummarySalon, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetSummarySalonParam = payload;
  try {
    const resData: AxiosResponse<{ data: ISummarySalon }> = yield call(apis.getSummarySalon, params);
    if (resData?.data?.data) {
      yield put(actions.getSummarySalon.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSummarySalon.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getSummarySalon, result: false }));
    yield setLoadingPage(false);
  }
};

const getSummaryServiceCategory: ISagaFunc<IApiGetSummaryServiceCategoryParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getSummaryServiceCategory, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetSummaryServiceCategoryParam = payload;
  try {
    const resData: AxiosResponse<{ data: ISummaryServiceCategory }> = yield call(apis.getSummaryServiceCategory, params);
    if (resData?.data?.data) {
      yield put(actions.getSummaryServiceCategory.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSummaryServiceCategory.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getSummaryServiceCategory, result: false }));
    yield setLoadingPage(false);
  }
};

const getSummaryTicket: ISagaFunc<IApiGetSummaryTicketParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getSummaryTicket, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetSummaryTicketParam = payload;
  try {
    const resData: AxiosResponse<{ data: ISummaryTicket }> = yield call(apis.getSummaryTicket, params);
    if (resData?.data?.data) {
      yield put(actions.getSummaryTicket.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSummaryTicket.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getSummaryTicket, result: false }));
    yield setLoadingPage(false);
  }
};

const getTopRankingService: ISagaFunc<IApiGetTopRankingServiceParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getTopRankingService, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetTopRankingServiceParam = payload;
  try {
    const resData: AxiosResponse<{ data: ITopRankingService[] }> = yield call(apis.getTopRankingService, params);
    if (resData?.data?.data) {
      yield put(actions.getTopRankingService.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTopRankingService.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getTopRankingService, result: false }));
    yield setLoadingPage(false);
  }
};

const getSummarySalaryStaff: ISagaFunc<IApiGetSummarySalaryStaffParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getSummarySalaryStaff, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetSummarySalaryStaffParam = payload;
  try {
    const resData: AxiosResponse<{ data: ISalaryStaffInfo[] }> = yield call(apis.getSummarySalaryStaff, params);
    if (resData?.data?.data) {
      yield put(actions.getSummarySalaryStaff.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSummarySalaryStaff.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getSummarySalaryStaff, result: false }));
    yield setLoadingPage(false);
  }
};

const getSummarySalarySalon: ISagaFunc<IApiGetSummarySalaryStaffParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getSummarySalarySalon, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetSummarySalaryStaffParam = payload;
  try {
    const resData: AxiosResponse<{ data: ISalarySalonInfo[] }> = yield call(apis.getSummarySalaryStaff, params);
    if (resData?.data?.data) {
      yield put(actions.getSummarySalarySalon.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSummarySalarySalon.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getSummarySalarySalon, result: false }));
    yield setLoadingPage(false);
  }
};

const getSummaryDashboardSalaryStaff: ISagaFunc<IApiGetSummarySalaryStaffParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getSummaryDashboardSalaryStaff, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetSummarySalaryStaffParam = payload;
  try {
    const resData: AxiosResponse<{ data: ISummaryDashboardSalaryStaff }> = yield call(apis.getSummaryDashboardSalaryStaff, params);
    if (resData?.data?.data) {
      yield put(actions.getSummaryDashboardSalaryStaff.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSummaryDashboardSalaryStaff.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getSummaryDashboardSalaryStaff, result: false }));
    yield setLoadingPage(false);
  }
};

const getSalaryStaffDetail: ISagaFunc<IApiGetSalaryStaffDetailParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getSalaryStaffDetail, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetSalaryStaffDetailParam = payload;
  try {
    const resData: AxiosResponse<{ data: ISalaryStaffDetail }> = yield call(apis.getSalaryStaffDetail, params);
    if (resData?.data?.data) {
      yield put(actions.getSalaryStaffDetail.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getSalaryStaffDetail.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getSalaryStaffDetail, result: false }));
    yield setLoadingPage(false);
  }
};

const getPrintSummaryServiceCategory: ISagaFunc<IApiGetPrintSummaryServiceCategoryParam> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getPrintSummarySalon, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetPrintSummaryServiceCategoryParam = payload;
  try {
    const resData: AxiosResponse<{ data: IPrintSummaryServiceCategory }> = yield call(apis.getPrintSummaryServiceCategory, params);
    if (resData?.data?.data) {
      yield put(actions.getPrintSummaryServiceCategory.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getPrintSummaryServiceCategory.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getPrintSummarySalon, result: false }));
    yield setLoadingPage(false);
  }
};

const getDashboardTimeClockReport: ISagaFunc<IApiGetDashboardTimeClockReportParams> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getDashboardTimeClockReport, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetDashboardTimeClockReportParams = payload;
  try {
    const resData: AxiosResponse<{ data: IDashboardTimeClockReport }> = yield call(apis.getDashboardTimeClockReport, params);
    if (resData?.data?.data) {
      yield put(actions.getDashboardTimeClockReport.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getDashboardTimeClockReport.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getDashboardTimeClockReport, result: false }));
    yield setLoadingPage(false);
  }
};

const getTimeClockReportHistory: ISagaFunc<IApiGetTimeClockReportHistoryParams> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getTimeClockReportHistory, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetTimeClockReportHistoryParams = payload;
  try {
    const resData: AxiosResponse<{ data: ITimeClockReportHistory }> = yield call(apis.getTimeClockReportHistory, params);
    if (resData?.data?.data) {
      yield put(actions.getTimeClockReportHistory.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTimeClockReportHistory.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getTimeClockReportHistory, result: false }));
    yield setLoadingPage(false);
  }
};

const getPrintSalaryStaffDetailOneDay: ISagaFunc<IApiGetPrintSalaryStaffDetailParams> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getPrintSalaryStaffDetailOneDay, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetPrintSalaryStaffDetailParams = payload;
  try {
    //TODO: Update
    const resData: AxiosResponse<{ data: IPrintSalaryStaffDetailOneDay }> = yield call(apis.getPrintSalaryStaffDetailOneDay, params);
    if (resData?.data?.data) {
      yield put(actions.getPrintSalaryStaffDetailOneDay.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getPrintSalaryStaffDetailOneDay.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getPrintSalaryStaffDetailOneDay, result: false }));
    yield setLoadingPage(false);
  }
};

const getPrintSalaryStaffDetailRangeDate: ISagaFunc<IApiGetPrintSalaryStaffDetailParams> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getPrintSalaryStaffDetailRangeDate, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetPrintSalaryStaffDetailParams = payload;
  try {
    const resData: AxiosResponse<{ data: IPrintSalaryStaffDetailRangeDate }> = yield call(apis.getPrintSalaryStaffDetailRangeDate, params);
    if (resData?.data?.data) {
      yield put(actions.getPrintSalaryStaffDetailRangeDate.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getPrintSalaryStaffDetailRangeDate.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getPrintSalaryStaffDetailRangeDate, result: false }));
    yield setLoadingPage(false);
  }
};

const getPrintSalarySummaryStaffOneDay: ISagaFunc<IApiGetPrintSalarySummaryStaffOneDayParams> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getPrintSalarySummaryStaffOneDay, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetPrintSalarySummaryStaffOneDayParams = payload;
  try {
    const resData: AxiosResponse<{ data: IPrintSalaryStaffDetailOneDay }> = yield call(apis.getPrintSalarySummaryStaffOneDay, params);
    if (resData?.data?.data) {
      yield put(actions.getPrintSalarySummaryStaffOneDay.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getPrintSalarySummaryStaffOneDay.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getPrintSalarySummaryStaffOneDay, result: false }));
    yield setLoadingPage(false);
  }
};

const getPrintSalarySummaryStaffRangeDate: ISagaFunc<IApiGetPrintSalarySummaryStaffRangeDateParams> = function* ({ payload }) {
  // Rieng
  // yield put(uiActions.setLoading({path: PATH_LOADING.getPrintSalarySummaryStaffRangeDate, result: true}));
  yield setLoadingPage(true); // Chung
  yield delay(200);
  const params: IApiGetPrintSalarySummaryStaffRangeDateParams = payload;
  try {
    const resData: AxiosResponse<{ data: IPrintSalaryStaffDetailOneDay }> = yield call(apis.getPrintSalarySummaryStaffRangeDate, params);
    if (resData?.data?.data) {
      yield put(actions.getPrintSalarySummaryStaffRangeDate.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getPrintSalarySummaryStaffRangeDate.fail({}));
  } finally {
    // yield put(uiActions.setLoading({ path: PATH_LOADING.getPrintSalarySummaryStaffRangeDate, result: false }));
    yield setLoadingPage(false);
  }
};

const getPrintSummaryPaymentTypes: ISagaFunc<IApiGetSummarySalonParam> = function* ({ payload }) {
  yield setLoadingPage(true); // Chung
  const params: IApiGetSummarySalonParam = payload;
  try {
    const resData: AxiosResponse<{ data: IStaffReportPaymentTypeItem[] }> = yield call(apis.getPrintSummaryPaymentTypes, params);
    if (resData?.data?.data) {
      yield put(actions.getPrintSummaryPaymentTypes.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getPrintSummaryPaymentTypes.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const setReviewReportParams: ISagaFunc<IParamsReviewReport> = function* ({ payload }) {
  const storeParams = yield select(reportSelectors.getPureReviewReportParams);
  const _params: IParamsReviewReport = {
    ...storeParams || {},
    ...payload || {},
  };

  if (_params.staffId === undefined) {
    delete _params.staffId;
  }

  if (_params.stars === undefined) {
    delete _params.stars;
  }

  if (_params.customerName === undefined) {
    delete _params.customerName;
  }

  yield put(actions.getReviewReport.fetch(_params));
  yield put(actions.getReviewReportSummary.fetch(_params));
};


const initReviewReportParams = function* () {
  const today = moment().format('MM-DD-YYYY');
  const _params: IParamsReviewReport = {
    endDate: today,
    startDate: today,
    page: 1,
    size: 20,
  };
  yield put(actions.getReviewReport.fetch(_params));
  yield put(actions.getReviewReportSummary.fetch(_params));
};

const getReviewReportSummary: ISagaFunc<IParamsReviewReport> = function* ({ payload }) {
  yield delay(200);
  try {
    const res: IResponseDataBody<IReviewReportSummary> = yield call(apis.getReviewReportSummary, payload);
    if (res.data.data) {
      yield put(actions.getReviewReportSummary.success(res.data.data));
    }
  } catch (error) { }
};

const getReviewReport: ISagaFunc<IParamsReviewReport> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  try {
    const res: IResponseDataBody<IReviewReportItem[]> = yield call(apis.getReviewReport, payload);
    if (res.data) {
      yield put(actions.getReviewReport.success(res.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

export default function* reportServiceSagas() {
  yield takeLatest(actions.getDemoData.fetch, getDemoData);
  yield takeLatest(actions.getPrintSummarySalon.fetch, getPrintSummarySalon);
  yield takeLatest(actions.getSummarySalon.fetch, getSummarySalon);
  yield takeLatest(actions.getSummaryServiceCategory.fetch, getSummaryServiceCategory);
  yield takeLatest(actions.getSummaryTicket.fetch, getSummaryTicket);
  yield takeLatest(actions.getTopRankingService.fetch, getTopRankingService);
  yield takeLatest(actions.getSummarySalaryStaff.fetch, getSummarySalaryStaff);
  yield takeLatest(actions.getSummarySalarySalon.fetch, getSummarySalarySalon);
  yield takeLatest(actions.getSummaryDashboardSalaryStaff.fetch, getSummaryDashboardSalaryStaff);
  yield takeLatest(actions.getSalaryStaffDetail.fetch, getSalaryStaffDetail);
  yield takeLatest(actions.getPrintSummaryServiceCategory.fetch, getPrintSummaryServiceCategory);
  yield takeLatest(actions.getDashboardTimeClockReport.fetch, getDashboardTimeClockReport);
  yield takeLatest(actions.getTimeClockReportHistory.fetch, getTimeClockReportHistory);
  yield takeLatest(actions.getPrintSalaryStaffDetailOneDay.fetch, getPrintSalaryStaffDetailOneDay);
  yield takeLatest(actions.getPrintSalaryStaffDetailRangeDate.fetch, getPrintSalaryStaffDetailRangeDate);
  yield takeLatest(actions.getPrintSalarySummaryStaffOneDay.fetch, getPrintSalarySummaryStaffOneDay);
  yield takeLatest(actions.getPrintSalarySummaryStaffRangeDate.fetch, getPrintSalarySummaryStaffRangeDate);
  yield takeLatest(actions.getReviewReport.fetch, getReviewReport);
  yield takeLatest(actions.getReviewReportSummary.fetch, getReviewReportSummary);
  yield takeLatest(actions.setReviewReportParams, setReviewReportParams);
  yield takeLatest(actions.initReviewReportParams, initReviewReportParams);
  yield takeLatest(actions.getPrintSummaryPaymentTypes.fetch, getPrintSummaryPaymentTypes);
}
