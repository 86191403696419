import { Row } from 'antd';
import Icon from 'assets/Icons';
import Button from 'components/Button';
import Text from 'components/Text';
import { FC } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

const SIZE_SERVICE_ITEM = {
  SMALL: 'small',
  LARGE: 'large',
};
interface ServiceItemProps {
  service: string;
  price: number;
  selected?: boolean;
  size: keyof typeof SIZE_SERVICE_ITEM;
  onClick?: () => void
  onMinus?: () => void
  onPlus?: () => void
  count?: number;
  addOn?: boolean;
  amount?: number;
  enablePriceService?: boolean;
}
interface ServiceItemStyledProps {
  selected: boolean;
  size?: keyof typeof SIZE_SERVICE_ITEM;
  addOn?: boolean;
}

const ServiceItemWrapper = styled.button`
  width: 100%;
  position: relative;
`;

const IconBadge = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;

const ServiceItemStyled = styled.div<ServiceItemStyledProps>`
  box-sizing: border-box;
  background: ${({ selected, size }) =>
    selected ? '#F6F7FC' : size === 'SMALL' ? '#F6F7FC' : '#E5E6EB'};
  border: 1px solid ${({ selected }) => (selected ? '#FF8890' : '#86909c')};
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: ${({ size }) => (size === 'LARGE' ? '4.875rem' : '3.5rem')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1rem;
  .amount-box {
    button {
      width: 40px;
      padding: 0;
      height: 40px !important;
      font-size: 25px;
      line-height: 1;
      font-weight: 600;
      display: flex;
      justify-content: center;
      align-items: center;
      .CONTENT_2 {
        font-size: 20px;
      }
      span {
        display: flex;
      }
    }
  }

  .service-amount {
    width: 35px;
    display: flex;
    justify-content: center;
  }

  .service-price {
    font-size: 1.25rem;
  }
`;

const CheckInServiceItem: FC<ServiceItemProps> = ({
  service,
  price,
  selected = false,
  size,
  onClick,
  onPlus = () => undefined,
  onMinus = () => undefined,
  amount = 0,
  count,
  addOn,
  enablePriceService,
}) => {
  return (
    <ServiceItemWrapper onClick={onClick}>
      {selected && (
        <IconBadge>
          {count ? (
            <IconCount>
              <Text className='text'>{count}</Text>
            </IconCount>
          ) : <Icon type="tick" />}
        </IconBadge>
      )}
      <ServiceItemStyled selected={selected} size={size}>
        <Row align={'middle'}>
          {addOn ? <Text variant='CONTENT_1' color='text_3'>{service}</Text> : <Text variant='CONTENT_1' color='text_3'>{service}</Text>}

        </Row>
        <Row align={'middle'} className='amount-box'>
          <Text variant="H9" mr={1} style={{ opacity: enablePriceService ? 1 : 0 }}>{formatCurrency(price)}</Text>
          <Button size='middle' onClick={e => [e.stopPropagation(), e.preventDefault(), onMinus()]}>-</Button>
          <Text variant='BODY_1' className='service-price service-amount' style={{ fontWeight: '600' }}>{amount}</Text>
          <Button size='middle' onClick={e => [e.stopPropagation(), e.preventDefault(), onPlus()]}>+</Button>
        </Row>
      </ServiceItemStyled>
    </ServiceItemWrapper>
  );
};

export default CheckInServiceItem;

const IconCount = styled.div`
  background: var(--primary-primary-active, #FF8890);
  width: 28px;
  height: 28px;
  border-radius:28px;
  display:flex;
  align-items: center;
  justify-content: center;
  .text {
    color: #FFF;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;