import { Input } from 'antd';
import Icon from 'assets/Icons';
import Button from 'components/Button';
import TableGiftCards from 'features/giftCards/components/TableGiftCards';
import giftCardsActions from 'features/giftCards/services/actions';
import giftCardsSelectors from 'features/giftCards/services/selectors';
import AppointmentDatePicker from 'features/report/pages/ReportPage/components/DatePicker';
import SwitchCalendarType from 'features/report/pages/ReportPage/components/SwitchCalendarType';
import { DebouncedFunc } from 'lodash';
import React, { useState } from 'react';
import styled from 'styled-components';
import refresh_icon from '../icons/change.svg';
import Box from 'components/Box';
import ButtonScanQRModal, { useButtonScanQRModalRef } from 'widgets/ButtonScanQR/ComponentRef';

export type ITableGiftCardsProps = {
  PAGE_SIZE?: number;
  resetTime?: number | undefined;
  searchText?: string | undefined;
  pageSize?: number | undefined;
  resetTimeChecked: string[];
  setResetTimeChecked: (value: any) => void;
  handleChangeResetTime: (value: number) => void;
  onReset: () => void;
  handleSearch: DebouncedFunc<(event: any) => void>;
  setSearchText?: (value: string) => void;
};
const GiftCardTable = ({ handleSearch, onReset, setSearchText = () => undefined, PAGE_SIZE, resetTimeChecked, setResetTimeChecked, resetTime, searchText }: ITableGiftCardsProps) => {
  return (
    <Container>
      <HeaderBox>
        <Box display='flex' style={{ flex: 1, position: 'relative' }}>
          <InputSearch searchText={searchText} handleSearch={handleSearch} setSearchText={setSearchText} />
        </Box>
        <div className='DatePickerStyled'>
          <AppointmentDatePicker popupClassName='zoom-big-v2' size='middle' actions={giftCardsActions} selectors={giftCardsSelectors} type='GIFT_CARDS' />
        </div>
        <SwitchCalendarType actions={giftCardsActions} selectors={giftCardsSelectors} type='GIFT_CARDS' />
        <Button cardCashier disabled={!resetTimeChecked.length} iconElement={<img src={refresh_icon} alt='refresh_icon' style={{ width: 24, height: 24 }} />} ntype='LIGHT_BLUE' onClick={onReset}>
          Reset Gift Card
        </Button>
      </HeaderBox>
      <TableGiftCards
        v2
        resetTime={resetTime}
        searchText={searchText}
        pageSize={PAGE_SIZE}
        resetTimeChecked={resetTimeChecked}
        setResetTimeChecked={setResetTimeChecked}
      />
    </Container>
  );
};

export default GiftCardTable;

const InputSearch = ({ searchText, setSearchText, handleSearch }: { setSearchText: (value: string) => void, searchText?: string; handleSearch: (value: string) => void }) => {
  const qrRef = useButtonScanQRModalRef();
  const [text, setText] = useState(searchText);
  const onChange = (e: any) => {
    handleSearch(e);
    setText(e.target.value);
  };

  const onScanQrValue = (value: string) => {
    setText(value);
    setSearchText(value);
  };

  return (
    <>
      <InputStyled
        placeholder="Search by staff, customer, card number"
        value={text}
        onChange={onChange} />
      <ButtonScanQRStyled onClick={() => qrRef.current?.scan()} type='button'><Icon type='QR' style={{ zoom: 1.5 }} /></ButtonScanQRStyled>
      <ButtonScanQRModal ref={qrRef} onScan={onScanQrValue} />
    </>
  );
};
const ButtonScanQRStyled = styled.button`
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  border: 1px solid #86909C;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &:hover {
    background: #eee;
  }
`;
const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  padding: 0 1rem;
  .ant-select .ant-select-selection-item {
    height: auto;
  }
  .ant-select .ant-select-arrow {
    color: #1D2129;
    font-size: 18px;
    transform: scaleX(1.1) scaleY(0.9);
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-item-content {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
  .ant-select .ant-select-selector {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-select-selection-placeholder, .ant-select-selection-search-input, .ant-select-selection-item {
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .ant-input-affix-wrapper {
    background: #ffffff;
    border: 1px solid #86909C;
    .ant-input {
      background: transparent;
      color: rgb(29, 33, 41);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .DatePickerStyled .ant-picker {
    min-width: 25rem;
    &.ant-picker-range {
      justify-content: end;
    }
  }
  .DatePickerStyled .CONTENT_2, .DatePickerStyled .ant-picker-input input {
    background: transparent;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  .SwitchCalendarTypeStyled {
    .GroupButtons {
      background: #E5E6EB;
      border: 1px solid #E5E6EB;
    }
    button {
      padding-right: 16px;
      padding-left: 16px;
      width: auto;
      .BODY_1 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
      }
    }
    button.active {
      border-radius: 4px;
      background: #232F3E;
      .BODY_1 {
        color: #FFF;
      }
    }
  }
`;

const InputStyled = styled(Input)`
    height: 3.5rem;
    width:100%;
    display: flex;
    flex:1;
`;
const Container = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
gap: 16px;
border-radius: 8px;
border: 1px solid #CCD4DC;
background: #FFF;
zoom: 1.3;
.gift-card-title {
  align-self: stretch;
  color: #1D2129;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
  .TableGiftCardsStyled {
    align-self: stretch;
    .card-number, .BODY_1 {
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .ant-table-thead>tr>th {
      background : #6FABB6;
      border-radius: 0;
      padding-left: 4px;
      padding-right: 4px;
      border-start-start-radius: 0 !important;
      border-start-end-radius: 0 !important;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: left !important;

      &::before {
        height: 100% !important;
        z-index: 1;
      }
    }
  }
`;
