import paymentActions from 'features/payment/services/actions';
import paymentSelectors from 'features/payment/services/selectors';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ReceiptUI from '../CheckInPrinter/ReceiptUI';

const CheckInGroupPrinter = () => {
  const setting = shopSelectors.data.allSetting();
  const data = paymentSelectors.getInfoGroupPrintWaiting();
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const doPrint = useReactToPrint({
    content: () => divRef.current, onAfterPrint() {
      dispatch(paymentActions.getInfoPrintWaiting.success(null));
    },
  });

  useEffect(() => {
    if (!setting?.enablePrintCheckIn) return;

    if (!data) return;

    setTimeout(() => doPrint(), 10);

  }, [data]);

  if (storage.device_type.get() !== 'POS') return null;

  if (!setting?.enablePrintCheckIn) return null;


  return (
    <CheckInPrinterStyled style={{ display: 'none' }}>
      <div ref={divRef}>
        {data?.map((item, index) => (
          <ReceiptUI data={item} key={index} />
        ))}
      </div>

    </CheckInPrinterStyled>
  );
};

export default CheckInGroupPrinter;

const CheckInPrinterStyled = styled.div`
  // position: fixed;
  // inset: 0;
  // index: 999999;
  // background: red;

  .container {
    padding:0 1rem;
  }
`;