import { Row } from 'antd';
import Empty from 'components/Empty';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Spin from 'components/Spin';
import Text from 'components/Text';
import userActions from 'features/user/services/actions';
import userSelectors from 'features/user/services/selectors';
import { IBillWaitingTicketResItem } from 'features/user/services/types/ticket';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import ButtonMergeTicket from './ButtonMergeTicket';
import ButtonRemoveTicket from './ButtonRemoveTicket';
import TicketItem from './Item';

type Props = {
  mode: 'staff' | 'ticket' | 'default';
  v2?: boolean;
};
const TicketWaitingList = ({ v2 }: Props) => {
  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const data = userSelectors.getBillWaitingTickets();
  const loading = userSelectors.getBillWaitingTicketLoading();
  const isClockIn = userSelectors.staff.isClockIn();
  const activeRemoveTicket = userSelectors.activeRemoveTicket();
  const enableMergeTicket = userSelectors.enableMergeTicket();
  const mergeTicketsList = userSelectors.mergeTicketsList();
  const onToggle = () => {
    dispatch(userActions.activeRemoveTicket(!activeRemoveTicket));
  };

  const onRemoveTicket = (record: IBillWaitingTicketResItem) => () => {
    modalConfirm.current?.open(`Do you like to delete the ticket #${record.ticketNumber}?`, () => {
      dispatch(userActions.removePendingTicket(record.billId));
    });
  };

  const onSelectMergeTicket = (o: IBillWaitingTicketResItem) => () => {
    dispatch(userActions.selectMergeTicket(o.billId));
  };

  const RowData = () => {
    if (loading) return <Spin />;

    return (
      data.length > 0 ?
        <div style={{ display: 'grid', gap: '8px', gridTemplateColumns: 'repeat(auto-fill, minmax(140px, 1fr))' }}>
          {
            data.map(o => (
              <div key={o.billId}>
                <TicketItem
                  data={o} viewOnly={!isClockIn}
                  removeMode={activeRemoveTicket} onRemoveTicket={onRemoveTicket(o)}
                  mergeTicketsList={mergeTicketsList} enableMergeTicket={enableMergeTicket}
                  onSelectMergeTicket={onSelectMergeTicket(o)}
                />
              </div>
            ))
          }
        </div>
        : v2 ? null : <Empty description='Empty ticket' centerFloating />
    );
  };
  if (v2) {
    return (
      <Styled className='ticket-label-section'>
        <Row className='top-title' align={'middle'} style={{ gap: '8px' }}>
          <Text style={{ flex: 1, fontWeight: '700' }} className='label-text' variant='H7' color='text_5' >Working On...</Text>
          {data.length > 0 ? <ButtonMergeTicket v2 /> : null}
        </Row>
        <div className='box-div-scroll' style={{ marginTop: '0.5rem' }}>
          <RowData />
        </div>
        <ModalConfirm ref={modalConfirm} />
      </Styled >
    );
  }
  return (
    <Styled>
      <Row align={'middle'} style={{ gap: '8px', marginBottom: '0.5rem' }}>
        <Text variant='H7' >In The Progress...</Text>
        <Text style={{ flex: 1 }} />

        {data.length > 0 ? <>
          <ButtonMergeTicket />
          <ButtonRemoveTicket type='ticket' active={activeRemoveTicket} onToggle={onToggle} />
        </> : <div style={{ height: '2.5rem' }}></div>}
      </Row>
      <div className='box-div-scroll'>
        <RowData />
      </div>
      <ModalConfirm ref={modalConfirm} />
    </Styled >
  );
};

export default TicketWaitingList;

const Styled = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
  background: #8defab4d;
  .box-div-scroll {
    flex: 1;
    margin: 0 -1rem;
    padding: 0 1rem;
    background:#fff;
    padding-right: 1rem;
    padding-top: 1rem;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  &.ticket-label-section {
    padding-top: 0;
    background: #F5F5F5 !important;
    .top-title {
      background: #fff;
      margin: 0 -1rem;
      padding: 0 1rem;
      height: 64px;
      min-height: 64px;
      max-height: 64px;
      overflow: hidden;
      .label-text {
        font-size: 22px;
        text-transform: uppercase;
      }
    }
  }
`;
