import Button from 'components/Button';
import React from 'react';
import useModalSplitTicket from './ModalSplitTicket';

const ButtonSplitTicket = () => {
  const [ref, context] = useModalSplitTicket();
  const onOpen = () => ref.current?.open();
  return (
    <>
      <Button icon='split' ntype='YELLOW' onClick={onOpen} >Split Master</Button>
      {context}
    </>
  );
};

export default ButtonSplitTicket;
