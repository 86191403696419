import { Select, SelectProps } from 'antd';
import React from 'react';
export enum PeriodValues {
  NO_REPEAT = 0,
  WEEK_1 = 7,
  WEEK_2 = 14,
  WEEK_4 = 28,
  WEEK_6 = 42,
  WEEK_8 = 56,
  WEEK_12 = 84,
}
export const periodOptions = [
  { label: 'No Repeat', value: PeriodValues.NO_REPEAT },
  { label: '1 Week', value: PeriodValues.WEEK_1, type: 'W' },
  { label: '2 Week', value: PeriodValues.WEEK_2, type: 'W' },
  { label: '4 Week', value: PeriodValues.WEEK_4, type: 'W' },
  { label: '6 Week', value: PeriodValues.WEEK_6, type: 'W' },
  { label: '8 Week', value: PeriodValues.WEEK_8, type: 'W' },
  { label: '12 Week', value: PeriodValues.WEEK_12, type: 'W' },
];
type Props = SelectProps<PeriodValues>;
const InputPeriod = ({ ...props }: Props) => {
  return (
    <div>
      <Select
        style={{ width: '100%' }}
        {...props}
        options={periodOptions}
        defaultValue={PeriodValues.NO_REPEAT}
      />
    </div>
  );
};

export default InputPeriod;
