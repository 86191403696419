/* eslint-disable no-constant-condition */
import { Col, Layout, Popover, Row } from 'antd';
import Icon from 'assets/Icons';
import IconLogo from 'assets/Icons/logo';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Text from 'components/Text';
import authActions from 'features/auth/services/actions';
import cashierActions from 'features/cashier/services/actions';
import { useMemo, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import storage from 'utils/sessionStorage';
import ButtonScanQR, { useButtonScanQRRef } from 'widgets/ButtonScanQR';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import SidebarV2 from './SidebarV2';

export const Sidebar = () => {
  const dispatch = useAppDispatch();
  const modal = useModalConfirmRef();
  const qrScanRef = useButtonScanQRRef();
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const doPrint = useReactToPrint({
    content: () => divRef.current,
  });
  const allSetting = shopSelectors.data.allSetting();
  const [visiblePW, setVisiblePW] = useState<string | null>(null);

  const routes = useMemo(() => [
    {
      path: '/',
      icon: <Icon type="category" />,
      iconActive: <Icon type="categoryActive" />,
      label: 'Home',
      disabled: false,
    },
    {
      path: '/tickets',
      icon: <Icon type="ticket" />,
      iconActive: <Icon type="ticketActive" />,
      label: 'Tickets',
      requirePW: true,
    },
    {
      path: '/quick-view-tickets',
      icon: <Icon type="search" />,
      iconActive: <Icon type="search" />,
      label: 'Quick View',
      requirePW: false,
    },
    {
      isScan: true,
      icon: <Icon type="QR" />,
      iconActive: <Icon type="QR" />,
      label: 'Scan',
    },
    {
      path: '/credit-cards',
      icon: <Icon type="giftCard" fill={'black'} width={24} height={24} />,
      iconActive: <Icon type="giftCard" width={24} height={24} />,
      label: 'Credit Cards',
      requirePW: !allSetting?.ignorePassAdminDiscount,
    },
    {
      path: '/turn',
      icon: <Icon type="turn" />,
      iconActive: <Icon type="turnActive" />,
      label: 'Turn',
    },
    {
      path: '/appointments',
      icon: <Icon type="calendar" />,
      iconActive: <Icon type="calendarActive" />,
      label: 'Appointment',
    },
    {
      path: '/report',
      icon: <Icon type="report" />,
      iconActive: <Icon type="reportActive" />,
      label: 'Report',
      requirePW: true,
    },
    {
      isCashierDrawer: true,
      icon: <Icon type="cashDrawer" />,
      iconActive: <Icon type="cashDrawerActive" />,
      label: 'Cash Drawer',
    },
    {
      isRefreshPage: true,
      icon: <Icon type="refresh" />,
      iconActive: <Icon type="refresh" />,
      label: 'Refresh',
    },
  ], [allSetting]);

  const onVerifyAdminSuccess = () => {
    if (visiblePW) navigate(visiblePW);
    setVisiblePW(null);
  };

  return (
    <>
      <Layout.Sider theme="light" collapsed collapsedWidth={'6.5rem'}>
        <SidebarStyled>
          <Row
            className="ant-layout-header-cus header"
            align={'middle'}
            justify={'center'}
          >
            <button type="button" >
              <IconLogo />
            </button>
          </Row>
          <div className="main">
            <Row className="routes" wrap justify={'center'}>
              {routes.map((o, i) => {
                const masterPath =
                  location?.origin + '/store/' + storage.shop_id.get() + '';
                const isSelected =
                  location.href?.replace(masterPath, '') === o.path;
                return (
                  <CenteredCol key={i} span={24}>
                    <NavLinkItem
                      to={(
                        o.requirePW ||
                        o.isCashierDrawer ||
                        o.isRefreshPage ||
                        o.isScan
                      ) ? '' : masterPath + o.path}
                      active={isSelected}
                      disabled={!!o.disabled}
                      onClick={() => {
                        dispatch(cashierActions.checkCashierWillUnmount());
                        if (o.isScan) return qrScanRef.current?.scan();

                        if (o.requirePW) {
                          setVisiblePW('/store/' + storage.shop_id.get() + o.path);
                          return;
                        }

                        if (o.isRefreshPage) return window.location.href = `${location.origin}/store/${storage.shop_id.get()}`;

                        if (!o.isCashierDrawer) return;

                        doPrint();
                      }}
                    >
                      {isSelected ? o.iconActive : o.icon}
                    </NavLinkItem>
                    <Text variant="BODY_1" textAlign='center'>{o.label}</Text>
                  </CenteredCol>
                );
              })}
            </Row>
          </div>
          <SettingItem />
        </SidebarStyled>
      </Layout.Sider>
      <div ref={divRef} style={{ height: '50px', width: '100%', display: 'none' }} />
      <ModalConfirm ref={modal} okTitle='Yes' cancelTitle='No' />
      {!!visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setVisiblePW(null)}
        />
      )}
      <ButtonScanQR ref={qrScanRef} hook />
    </>
  );
};
const UISideBar = () => {
  const versionUI = shopSelectors.data.versionUI();
  if (versionUI === 'V2') return <SidebarV2 />;
  return <Sidebar />;
};
export default UISideBar;
enum SettingType {
  STAFF = 'STAFF',
  GIFT_CARD = 'GIFT_CARD',
  SETTINGS = 'SETTINGS',
  CUSTOMERS = 'CUSTOMERS',
  LOGOUT = 'LOGOUT',
}
const SettingItem = () => {
  const modalConfirm = useModalConfirmRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [visiblePW, setVisiblePW] = useState<SettingType | null>(null);
  const handleClose = () => {
    setVisiblePW(null);
  };
  const onVerifyAdminSuccess = () => {
    if (visiblePW) onClickSettingItem(visiblePW)();
    setVisiblePW(null);
  };

  const onClickSettingItem = (type: SettingType) => () => {
    setOpen(false);
    if (!visiblePW && type !== SettingType.LOGOUT) {
      setVisiblePW(type);
      return;
    }

    if (type !== SettingType.LOGOUT) {
      dispatch(cashierActions.checkCashierWillUnmount());
    }


    switch (type) {
      case SettingType.LOGOUT: {
        modalConfirm.current?.open('Are you about to logout the system?', () => {
          dispatch(authActions.ownerLogout());
          navigate('/sign-in');
        });
        break;
      }
      case SettingType.GIFT_CARD:
        navigate(`/store/${storage.shop_id.get()}/gift-cards`);
        break;
      case SettingType.SETTINGS:
        navigate(`/store/${storage.shop_id.get()}/setting-service`);
        break;
      case SettingType.STAFF:
        navigate(`/store/${storage.shop_id.get()}/setting-staff`);
        break;
      case SettingType.CUSTOMERS:
        navigate(`/store/${storage.shop_id.get()}/customers`);
        break;
      default:
        break;
    }
  };

  const content = useMemo(() => {
    return (
      <Buttons>
        <button onClick={onClickSettingItem(SettingType.CUSTOMERS)}>Customer</button>
        <button onClick={onClickSettingItem(SettingType.STAFF)}>Staff</button>
        <button onClick={onClickSettingItem(SettingType.GIFT_CARD)}>Gift Cards</button>
        <button onClick={onClickSettingItem(SettingType.SETTINGS)}>Settings</button>
        <button onClick={onClickSettingItem(SettingType.LOGOUT)}>Log out system</button>
      </Buttons>
    );
  }, []);
  return (
    <>
      <PopoverSetting
        open={open}
        onOpenChange={setOpen}
        trigger={['click']}
        placement='right'
        align={{ offset: [10, -30] }}
        overlayClassName='setting-popover'
        content={content}>
        <button
          onClick={() => setOpen(true)}
          className="ant-layout-footer-cus footer"
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
        >
          <Col>
            <Icon type="settingLight" />
          </Col>
        </button>
      </PopoverSetting>
      {!!visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={handleClose}
        />
      )}
      <ModalConfirm ref={modalConfirm} />
    </>
  );
};
const PopoverSetting = styled(Popover)`
  .ant-popover-inner {
    padding: 0;
  }
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  button {
    display: flex;
    padding: 10px 16px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: var(--button-bg-5, #FFF);
    color: var(--text-text-2, #767676);
    &:hover {
      background: var(--primary-button, #FFE5E7);
      color: var(--primary-primary-hover, #F5767F);
    }
  }
`;

const NavLinkItem = styled(NavLink).withConfig({
  shouldForwardProp: (prop) => !['active', 'disabled'].includes(prop),
}) <{ active: boolean; disabled: boolean }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 12px 16px;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #fff !important;
  background: #fff;
  box-shadow: none;
  &:hover {
    border: 1px solid #f5767f !important;
    background-color: #ffe5e7 !important;
  }
  &:disabled {
    background-color: #feedbf !important;
  }
  ${({ disabled }) =>
    disabled &&
    `
    cursor: default;
    pointer-events: none;
  `}
  ${({ active }) =>
    active
      ? `
      background: #ff8890;
      border: 1px solid #f5767f;
      &:hover {
        border: 1px solid #f5767f !important;
        background-color: #f5767f !important;
      }
  `
      : ''}
`;

const CenteredCol = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const SidebarStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  flex: 1;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.15);
  .main {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,0.5);
      &:hover {
        background-color: rgba(0,0,0,0.6);
      }
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #F5F5F5;
    }
    overflow: auto;
    flex: 1;
    .routes {
      gap: 1rem;
      .ant-col {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .ant-btn {
        height: 3.5rem;
        width: 3.5rem;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .route {
    }
  }
  .footer {
    background: #adadad;
  }
`;
