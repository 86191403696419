import { IBillDetailData } from 'features/payment/services/types/bill';
import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IMultiCardResDataItem } from './types/card';
import { ICouponPrintData } from 'services/shop/types/coupon';

const getTransactionDetail = createAsyncAction<string, IBillDetailData>(PREFIX_ACTIONS + 'getTransactionDetail');
const setVisibleTip = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleTip');
const refreshDetail = createAsyncAction<string, IBillDetailData>(PREFIX_ACTIONS + 'refreshDetail');

const getListMultipleCards = createAsyncAction<string, IMultiCardResDataItem[]>(PREFIX_ACTIONS + 'getListMultipleCards');

const setPrintData = createAction<{ coupon: ICouponPrintData | null, detail: IBillDetailData | null, cards: IMultiCardResDataItem[] }>(PREFIX_ACTIONS + 'setPrintData');

const multiCardActions = {
  getTransactionDetail,
  setVisibleTip,
  refreshDetail,
  getListMultipleCards,
  setPrintData,
};

export default multiCardActions;

