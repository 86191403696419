import React from 'react';
import CardTicketItem from '../CardTicketItem';
import colorTheme from 'constants/color';
import { useAppDispatch } from 'store/hooks';
import cashierSelectors from 'features/cashier/services/selectors';
import cashierActions from 'features/cashier/services/actions';
import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
type Props = {
  v2?: boolean;
};
const Product = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  const setFeatureProduct = () => {
    dispatch(cashierActions.setFeatureProductMode(FEATURE_PRODUCT_MODE.PRODUCT));
  };

  const active = featureProductMode === FEATURE_PRODUCT_MODE.PRODUCT;
  if (v2)
    return (
      <CardTicketItem
        icon={active ? 'cardProductV2Active' : 'cardProductV2'}
        content={'Product'}
        nbgcolor={'#4F1787'}
        textColor={colorTheme.text_1}
        border_color='#4F1787'
        border_color2='#4F1787'
        active={active}
        v2
        onClick={setFeatureProduct}
      />
    );
  return (
    <CardTicketItem
      icon={active ? 'cardProductActive' : 'cardProduct'}
      content={'Product'}
      nbgcolor={colorTheme.info_bg_orange}
      textColor={colorTheme.text_1}
      border_color={colorTheme.info_orange}
      active={active}
      onClick={setFeatureProduct}
    />
  );
};

export default Product;
