import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IEditCateBodyType } from './types/api';
import { IState } from './types/reducer';
import shopActions from 'services/shop/actions';
import { first, remove } from 'lodash';

const initialState: IState = {
  cateSelected: undefined,
  addOnServiceSelected: undefined,
  errorMessage: '',
  categories: [],
  reloadCategory: false,
  reloadProduct: false,
  products: [],
  dayOffList: [],
  productsPageable: {
    pageNumber: 0,
    pageSize: 6,
    totalPages: 0,
    totalElements: 0,
  },
  cateForProductSelected: undefined,
  listVIP: [],
  reloadVIPs: false,
  turnSetting: {},
  reloadTurnSetting: false,
  lateForWork: {},
  quickPayList: [],
  quickPayTurnList: [],
  paymentSetting: {
    tax: undefined,
    discount: undefined,
    specialDiscount: [],
    loyaltyReward: [],
    otherPaymentMethod: [],
    policyTerm: undefined,
    giftCard: undefined,
  },
  notificationSetting: {
    notification: [],
    scheduler: undefined,
  },
  serviceTab: {
    activeCate: null,
    selectedServiceIds: [],
  },
  VIPProductList: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.getQuickPayTurnList.success, (state, { payload }) => {
        state.quickPayTurnList = payload;
      })
      .addCase(actions.getVIPProductList.success, (state, { payload }) => {
        state.VIPProductList = payload;
      })
      .addCase(actions.serviceActs.selectedServiceId, (state, { payload }) => {
        if (state.serviceTab.selectedServiceIds.indexOf(payload) === -1) {
          state.serviceTab.selectedServiceIds.push(payload);
        } else {
          remove(state.serviceTab.selectedServiceIds, o => o === payload);
        }
      })
      .addCase(actions.serviceActs.activeCate, (state, { payload }) => {
        state.serviceTab.activeCate = payload;
        state.serviceTab.selectedServiceIds = [];
      })
      .addCase(shopActions.get.categories.success, (state, { payload }) => {
        state.serviceTab.selectedServiceIds = [];
        if (!state.serviceTab.activeCate)
          state.serviceTab.activeCate = first(payload)?.id || null;
        else {
          const id = state.serviceTab.activeCate;
          const cate = payload.find(o => o.id === id);
          state.serviceTab.activeCate = null;
          state.serviceTab.activeCate = cate?.id || first(payload)?.id || null;
        }
      })
      .addCase(actions.clickCate, (state, { payload }) => {
        state.cateSelected = payload;
        state.addOnServiceSelected = undefined;
      })
      .addCase(actions.clickAddOnService, (state, { payload }) => {
        state.addOnServiceSelected = payload;
        state.cateSelected = undefined;
      })
      .addCase(actions.editCategory.fail, (state, { payload }) => {
        state.errorMessage = payload;
      })
      .addCase(actions.createCategory.fail, (state, { payload }) => {
        state.errorMessage = payload;
      })
      .addCase(
        actions.createOrUpdateServiceAddOn.fail,
        (state, { payload }) => {
          state.errorMessage = payload;
        }
      )
      .addCase(shopActions.getCategoriesWithoutBE.success, (state, { payload }) => {
        const newCate = payload as ICategoryItemData[];
        if (state.cateSelected) {
          const newSelected = newCate?.find(o => o.id === state.cateSelected?.id);
          if (newSelected) {
            state.cateSelected = newSelected;
          }
          state.addOnServiceSelected = undefined;
        }
      })
      .addCase(
        actions.addMoreServiceByCate.success,
        (state, { payload }: PayloadAction<IEditCateBodyType>) => {
          const newServices: IServiceItemData[] = payload.services.map(
            (item) => ({
              id: item.serviceId || '',
              catId: item.categoryId,
              duration: Number(item.duration) || 0,
              serviceName: item.serviceName,
              priceSell: Number(item.priceSell) || 0,
              commission: Number(item.commission) || 0,
              supplyFee: Number(item.supplyFee) || 0,
              orderNumber: Number(item.orderNumber) || 0,
              addOn: item.addOn,
              priceCost: 0, // Assuming you want to default this to 0
            })
          );
          if (state.cateSelected) {
            if (state.cateSelected.services) {
              state.cateSelected.services = [
                ...state.cateSelected.services,
                ...newServices,
              ];
            } else {
              state.cateSelected.services = newServices; // Initialize services if it doesn't exist
            }
          }
        }
      )
      .addCase(
        actions.getListCategoriesForProduct.success,
        (state, { payload }) => {
          state.categories = payload;
        }
      )
      .addCase(actions.reloadCategory, (state, { payload }) => {
        state.reloadCategory = payload;
      })
      .addCase(
        actions.getProductsByCategoryId.success,
        (state, { payload }) => {
          state.products = payload;
        }
      )
      .addCase(actions.getProductsPageable.success, (state, { payload }) => {
        state.productsPageable = payload;
      })
      .addCase(actions.cateForProductSelected, (state, { payload }) => {
        state.cateForProductSelected = payload;
      })
      .addCase(actions.reloadProduct, (state, { payload }) => {
        state.reloadProduct = payload;
      })
      .addCase(actions.getVIP.success, (state, { payload }) => {
        state.listVIP = payload;
      })
      .addCase(actions.reloadVIPs, (state, { payload }) => {
        state.reloadVIPs = payload;
      })
      .addCase(
        actions.updateNameCategoryForProduct.success,
        (state, { payload }) => {
          state.reloadCategory = true;
          if (state.cateForProductSelected)
            state.cateForProductSelected = {
              ...state.cateForProductSelected,
              categoryName: payload,
            };
        }
      )
      .addCase(actions.deleteCategoryForProduct.success, (state) => {
        state.reloadCategory = true;
        state.products = [];
        state.productsPageable = {
          pageNumber: 0,
          pageSize: 6,
          totalPages: 0,
          totalElements: 0,
        };
        state.cateForProductSelected = undefined;
      })
      .addCase(actions.getQuickPayList.success, (state, { payload }) => {
        state.quickPayList = payload;
      })

      .addCase(actions.getTurnSetting.success, (state, { payload }) => {
        state.turnSetting = payload;
      })
      .addCase(actions.reloadTurnSetting, (state, { payload }) => {
        state.reloadTurnSetting = payload;
      })
      .addCase(actions.getLateForWork.success, (state, { payload }) => {
        state.lateForWork = payload;
      })
      .addCase(actions.deleteCategoriesTabService.success, (state) => {
        state.cateSelected = undefined;
      })
      // start case of Payment Setting Tab
      .addCase(actions.getTax.success, (state, { payload }) => {
        state.paymentSetting.tax = payload;
      })
      .addCase(actions.getDiscount.success, (state, { payload }) => {
        state.paymentSetting.discount = payload;
      })
      .addCase(actions.getSpecialDiscount.success, (state, { payload }) => {
        state.paymentSetting.specialDiscount = payload;
      })
      .addCase(actions.getLoyaltyReward.success, (state, { payload }) => {
        state.paymentSetting.loyaltyReward = payload;
      })
      .addCase(actions.getOtherPaymentMethod.success, (state, { payload }) => {
        state.paymentSetting.otherPaymentMethod = payload;
      })
      .addCase(actions.getPolicyTerm.success, (state, { payload }) => {
        state.paymentSetting.policyTerm = payload;
      })
      .addCase(actions.getGiftCard.success, (state, { payload }) => {
        state.paymentSetting.giftCard = payload;
      })
      .addCase(actions.getNotification.success, (state, { payload }) => {
        state.notificationSetting.notification = payload;
      })
      .addCase(actions.getScheduler.success, (state, { payload }) => {
        state.notificationSetting.scheduler = payload;
      })
      .addCase(actions.deleteAddOnService.success, (state) => {
        state.addOnServiceSelected = undefined;
      })
      .addCase(actions.resetServiceSetting, (state) => {
        state.addOnServiceSelected = undefined;
        state.cateSelected = undefined;
      })
      .addCase(actions.getDayOffList.success, (state, { payload }) => {
        state.dayOffList = payload;
      })
      .addCase(
        actions.deleteMultiple.success,
        (state, { payload }: PayloadAction<string[]>) => {
          if (state.cateSelected) {
            const newServices = state.cateSelected.services.filter((service) =>
              !payload.includes(service.id)
            );
            state.cateSelected.services = newServices;
          }
        }
      );
    // end case of Payment Setting Tab
  },
});

const settingServiceReducer = Slice.reducer;
export default settingServiceReducer;
