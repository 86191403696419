import React from 'react';
import { TYPE_BLOCK_RADIO } from './BlockHourType';
import { Form } from 'antd';
import RangeCalendarPicker from './RangeCalendarPicker';
import moment, { Moment } from 'moment';
import CalendarPicker from '../QuickBooking/CalendarPicker';
import StartTimes from './StartTimes';
import Text from 'components/Text';
import getTimeLines, { setTime } from 'features/appointment/utils/getTimeLines';
import styled from 'styled-components';
import clock_icon from './clock.svg';

const timeOptions = getTimeLines(setTime(9, 0), setTime(21, 0), 15, 'hh:mm A');
type Props = {
  value?: TYPE_BLOCK_RADIO;
};
const TimeDateBlockHour = ({ value }: Props) => {
  const form = Form.useFormInstance();
  if (value === TYPE_BLOCK_RADIO.DAY_OFF) return (
    <>
      <Form.Item noStyle name={'dateOffs'} rules={[{ required: true }]}>
        <RangeCalendarPicker />
      </Form.Item>
      <Form.Item noStyle shouldUpdate>
        {({ getFieldValue }) => {
          const dates: { startTime: Moment, endTime: Moment | null } = getFieldValue('dateOffs');
          return <DateOffsContainer>
            <div className="date-off-label">DATE OFFS</div>
            <div className="date-off-container">
              <div className="date-off-item border-right">
                <img src={clock_icon} alt="clock_icon" style={{ height: 32, width: 32 }} />
                <span>Start date:</span>
                <span className='value'>{dates?.startTime?.format('MM-DD-YYYY')}</span>
              </div>
              <div className="date-off-item" style={{ justifyContent: 'end' }}>
                <span>End date:</span>
                <span className='value'>{dates?.endTime ? dates?.endTime?.format('MM-DD-YYYY') : '--/--/----'}</span>
              </div>
            </div>
          </DateOffsContainer>;
        }}
      </Form.Item>
    </>
  );
  if (value === TYPE_BLOCK_RADIO.BLOCK_HOURS) return (
    <>
      <Form.Item noStyle name={'date'} rules={[{ required: true }]}>
        <CalendarPicker />
      </Form.Item>
      <Text variant='H9' color='text_3' className='text-lg'>Start Time</Text>
      <ContainerTimes>
        <div className="children-content">
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const date = getFieldValue('date');
              const now = moment();
              const times = date?.isSame(now, 'day') ? timeOptions.filter(o => o.value > now) : timeOptions;
              return <>
                <Form.Item noStyle name={'startTime'} rules={[{ required: true }]}>
                  <StartTimes options={times} onChange={() => form.setFieldValue('endTime', null)} />
                </Form.Item>
              </>;
            }}
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const startTime = getFieldValue('startTime');
              if (!startTime) return null;
              const times = timeOptions.filter(o => o.value > startTime?.value);
              return <>
                <Text variant='H9' color='text_3' className='text-lg'>End Time</Text>
                <Form.Item noStyle name={'endTime'} rules={[{ required: true }]}>
                  <StartTimes options={times} />
                </Form.Item>
              </>;
            }}
          </Form.Item>

        </div>
      </ContainerTimes>
    </>
  );
  return null;
};

export default TimeDateBlockHour;

const DateOffsContainer = styled.div`
display: flex;
padding: 16px 0px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-top: 1px solid #86909C;
.date-off-label {
  color: #505050;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 111.111% */
  text-transform: capitalize;
}
.date-off-container {
  display: flex;
  height: 56px;
  align-items: center;
  align-self: stretch;
  gap:32px;
}
.date-off-item {
  display: flex;
  align-items: center;
  gap: 8px;
  &.border-right {
  padding-right: 32px;
    border-right: 1px solid #86909C;
  }
  span {
    color: #505050;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.value {
      color: #2D6772;
      font-size: 26px;
      font-weight: 500;
    }
  }
}
`;

const ContainerTimes = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  .children-content {
    position: absolute;
    inset: 0;
    overflow: auto;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &::-webkit-scrollbar {
      width: 0px;
    }

  }
`;