import { Row } from 'antd';
import clsx from 'clsx';
import Text from 'components/Text';
import { BUTTON_SIZES } from 'constants/size';
import { styled } from 'styled-components';
export type IGroupButtonOptionItem = {
  label?: string;
  value: string;
};
type GroupButtonsProps = {
  options?: IGroupButtonOptionItem[];
  value: string;
  onChange?: (val: string) => void;
  buttonSize?: keyof typeof BUTTON_SIZES;
  className?: string;
  v2?: boolean;
};
const GroupButtons = ({
  options = [],
  value,
  onChange = () => undefined,
  buttonSize = 'small',
  className,
  v2,
}: GroupButtonsProps) => {
  return (
    <Styled className={clsx('GroupButtons', v2 && 'v2', className)} buttonSize={BUTTON_SIZES[buttonSize as keyof typeof BUTTON_SIZES]}>
      {options.map((o) => (
        <button
          key={o.value}
          className={value === o.value ? 'active' : ''}
          onClick={() => {
            if (value === o.value) return;
            onChange(o.value);
          }}
        >
          <Text color="text_3">{o.label}</Text>
        </button>
      ))}
    </Styled>
  );
};

export default GroupButtons;

const Styled = styled(Row).withConfig({
  shouldForwardProp: (prop) => !['buttonSize'].includes(prop),
}) <{ buttonSize: string }>`
  display: flex;
  align-items: center;
  border: 1px solid var(--line-line-3, #86909c);
  background: var(--fill-fill-5, #e5e6eb);
  border-radius: 4px;
  height: ${(props) => props.buttonSize};
  height: 3.5rem;
  button {
    display: flex;
    padding: 0;
    height: 100%;
    justify-content: center;
    align-items: center;
    width: 4rem;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    &:hover {
      background: #adadad;
    }
    &.active {
      border-radius: 4px;
      background: var(--fill-fill-0, #fff);
    }
    &:disabled {
      &:hover {
        cursor: not-allowed;
      }
    }
  }

&.v2.GroupButtons {
  border-radius: 4px;
  background: #E5E6EB;
  border: 1px solid #E5E6EB;
  button {
    width: 6rem;
    .BODY_1 {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
    }

    &.active {
      border-radius: 4px;
      background: #232F3E;
      .BODY_1 {
          color: #fff;
      }
    }
  }
}
`;
