import { Card, Carousel, Col, Row } from 'antd';
import { CarouselRef } from 'antd/es/carousel';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import turnActions from 'features/turn/services/actions';
import turnSelectors from 'features/turn/services/selectors';
import userSelectors from 'features/user/services/selectors';
import { IWaitingListResItem } from 'features/user/services/types/waiting';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Box from 'components/Box';
type ICarouselProps = {
};
const WIDTH_ITEM_CAROUSEL = 140;
const StaffCarousel: React.FC<ICarouselProps> = ({
}) => {
  const dispatch = useDispatch();
  const carouselRef = React.createRef<CarouselRef>();
  const [totalPage, setTotalPage] = useState<number>(0);
  const [arrayByPage, setArrayByPage] = useState<number[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [widthItem, setWidthItem] = useState<number>(WIDTH_ITEM_CAROUSEL);
  const [lstCustomer, setLstCustomer] = useState<any[]>([]);
  const waitingList = userSelectors.getWaitingListData();
  const selectWaitingListResItem = turnSelectors.selectWaitingListResItem();
  useEffect(() => {
    setLstCustomer(waitingList);
  }, [waitingList]);
  useEffect(() => {
    reCalcTotalPage();
  }, [lstCustomer]);

  const reCalcTotalPage = () => {
    const currWidth = window.screen.availWidth;
    const widthCarousel = currWidth - 172 - 40;
    const maxPageSize = Math.floor(widthCarousel / WIDTH_ITEM_CAROUSEL);
    const remainWidthCarousel = widthCarousel - maxPageSize * WIDTH_ITEM_CAROUSEL;
    const newWidthItem = WIDTH_ITEM_CAROUSEL + (remainWidthCarousel) / maxPageSize;
    const _totalPage = Math.ceil(lstCustomer.length / maxPageSize);
    const _arrayByPage = Array.from({ length: _totalPage }, (_, i) => i + 1);
    setTotalPage(_totalPage);
    setPageSize(maxPageSize);
    setArrayByPage(_arrayByPage);
    setWidthItem(newWidthItem);
  };
  // const onCloseModalDetail = () => setSelected(undefined);

  return (
    <CarouselContainerStyled>
      <Carousel ref={carouselRef} dots={false}>
        {arrayByPage.map((page) => (
          <div key={page}>
            <Row gutter={[12, 12]} style={{ padding: '20px 0px', overflowY: 'auto' }}>
              {lstCustomer.slice((page - 1) * pageSize, page * pageSize).map((customer: IWaitingListResItem) => {
                return (
                  <ColBox key={customer.checkInId}
                    width={widthItem}
                  >
                    <CardCus
                      className={customer.checkInId == selectWaitingListResItem?.checkInId ? 'selected' : ''}
                      onClick={() => {
                        if (customer.checkInId == selectWaitingListResItem?.checkInId) {
                          return dispatch(turnActions.selectWaitingListResItem(undefined));
                        }
                        dispatch(turnActions.selectWaitingListResItem(customer));
                      }}
                    >
                      <Box display='flex' flexDirection='column'>
                        <Text className='customer-name'>{customer.customerName}</Text>
                        <div className='customer-services'>
                          <div className='wrapper-customer-services text-overflow'>
                            <span className=''> {customer.serviceInfo.map(o => o.itemName).join(', ')}</span>
                          </div>
                        </div>
                        <Box display='flex' justifyContent='space-between'>
                          <div className='customer-time'><span>{customer.time}</span></div>
                          <Box highlight className='customer-ticket-number'><span className='title-highlight'>#{customer.ticketNumber}</span></Box>
                        </Box>
                      </Box>
                    </CardCus>
                  </ColBox>
                );
              })}
            </Row>
          </div>
        ))}
      </Carousel>
      {totalPage > 1 && <NextButton
        onClick={() => {
          carouselRef.current?.next();
          reCalcTotalPage();
        }}
      >
        <Icon type="nextCarousel"></Icon>
      </NextButton>}
      {/* <ModalWaitingListDetail key={uniqueId()} isShow={!!selected} data={selected} onClose={onCloseModalDetail}/> */}
    </CarouselContainerStyled>
  );
};

export default StaffCarousel;
const CarouselContainerStyled = styled.div`
  position: relative;
  width: calc(100vw - 104px);
  padding-left: 20px;
  background: #E5E5E5;
`;
const NextButton = styled.div`
  position: absolute;
  top: 50px;
  right: 35px;
  bottom: 0;
  z-index: 1;
  text-align: center;
`;
const ColBox = styled(Col) <{ width?: number }>`
 width: ${props => props.width ? props.width + 'px' : 'undefined'};
`;
const CardCus = styled(Card) <{ isselected?: string }>`
    border-radius: 5px;
    margin: 0px 2px;
    min-width: 8.5rem;
    min-height: 5.5rem;
    padding: 0 8px;
    border: 2px dashed transparent;
    border-radius: 10px;
    background: #F6F7FC;
    display: flex;
    height: 100%;
    width: calc(100% - 30px);
    &:active {
      box-shadow: 0px 0px 0px 3px rgb(255 136 144 / 25%);
    }
    &:hover {
      box-shadow: 0px 0px 0px 3px rgb(255 136 144 / 25%);
      cursor: pointer;
    }

    &.selected {
      &:active {
        box-shadow: 0px 0px 0px 3px transparent;
      }
      box-shadow: 0px 0px 0px 3px transparent;
      border: 2px dashed #FF8890;
    }

    .ant-card-body{
      padding: 3px;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
    }
    > div {
      margin: auto;
    }

    .customer-name {
      overflow: hidden;
      color: #1D2129;
      text-align: center;
      text-overflow: ellipsis;
      align-self: stretch;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }

    .customer-services {
      display: flex;
      align-items: center;
      gap: 4px;
      align-self: stretch;
      border-top: 0.5px solid #DDD;
      border-bottom: 0.5px solid #DDD;
      background: #FFF;
      align-self: stretch;
      position: relative;
      height: 24px;
      margin-bottom: 4px;
      .wrapper-customer-services {
        max-width: 100%;
        position: absolute;
        inset: 0;
      }
      span {
        color: #505050;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .customer-time {
      display: flex;
      align-self: stretch;
      padding: 0px 4px;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      background: #FEEDBF;
      span {
        color: #B28300;
        text-align: center;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.24px;
      }
    }

    .customer-ticket-number {
      border-radius: 2px;
      padding: 0 2px;
      .title-highlight {
        color: #FFF;
        text-align: center;
        text-overflow: ellipsis;
        font-family: "Open Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.24px;
      }
    }
`;
