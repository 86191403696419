import React from 'react';
import emptyIcon from './empty.png';
import Text from 'components/Text';
import styled from 'styled-components';

const EmptyTicketV2 = ({ title }: { title?: string }) => {
  return (
    <ContainerEmpty>
      <img src={emptyIcon} alt="emptyIcon" style={{ width: 180, height: 180 }} />
      <Text variant='CONTENT_2'>{title}</Text>
    </ContainerEmpty>
  );
};

export default EmptyTicketV2;

const ContainerEmpty = styled.div`
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .CONTENT_2 {
    font-size: 20px;
  }
`;