
import Button from 'components/Button';
import QuickBooking, { useQuickBookingRef } from 'features/appointment/pages/AppointmentsPage/components/Calendar/TableView/Body/Modal/QuickBooking';
import { aptUIActions } from 'features/appointment/services/reducers';
import moment from 'moment';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
type Props = {
  render?: (onPress: () => void) => any;
};
const ButtonQuickBooking = ({ render }: Props) => {
  const { shop_id = '' } = useParams();
  const quickBookingRef = useQuickBookingRef();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const colTime = () => {
    const now = moment();
    const nowTime = [0, 15, 30, 45, 60].map(o => ({ key: o, value: (now.get('minute') - o) })).filter(o => o.value >= 0);
    const minute = nowTime.find(o => o.value === Math.min(...nowTime.map(o => o.value)))?.key;
    const startTime = moment().set({
      hour: now.get('hour'),
      minute: minute ?? now.get('minute'),
      second: 0,
    });
    return startTime.format();
  };

  const newQuickBooking = () => {
    quickBookingRef.current?.open(colTime() || '');
  };

  const handleDone = (staffId: string) => {
    dispatch(aptUIActions.syncStaffPage(staffId));
    navigate(`/store/${shop_id}/appointments`);
  };

  return (
    <>
      {render ? render(newQuickBooking) : <Button ntype="LIGHT_BLUE" size='middle' icon='addPlus' onClick={newQuickBooking} >Quick Booking</Button>}
      <QuickBooking nonOpacity ref={quickBookingRef} onDone={handleDone} />
    </>
  );
};

export default ButtonQuickBooking;
