import { Col, Modal, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import reportSelectors from 'features/report/services/selectors';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import { renderTime } from '../../utils';
import { ReportType } from '../../constants';
import EmployeeTickets from 'features/report/components/EmployeeTickets';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import reportActions from 'features/report/services/actions';
import moment from 'moment';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { AxiosResponse } from 'axios';
import { ISalaryStaffInfo } from 'features/report/services/types/reportStaff';
import reportApis from 'features/report/services/apis';

interface IModalViewInfo {
  isShow: boolean;
  onClose: () => void;
  viewType?: CALENDAR_VIEW_TYPE;
  v2?: boolean;
}

const ModalViewInfo: React.FC<IModalViewInfo> = ({ v2, isShow, onClose, viewType }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const dataPrintSalaryStaffDetail =
    reportSelectors.getPrintSalaryStaffDetailOneDay();
  const dataAppointment = reportSelectors.getParams()?.[ReportType.SALON];
  const divRef = useRef<HTMLDivElement>(null);
  const allSetting = shopSelectors.data.allSetting();

  const onPrint = useReactToPrint({
    content: () => divRef.current,
    copyStyles: true,
  });

  const onViewDetail = async () => {
    onClose();
    dispatch(reportActions.setParams({
      [ReportType.STAFF_SALARY_DETAIL]: {
        startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
        endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
      }
    }));
    if (viewType) {
      const payload = {
        [ReportType.STAFF_SALARY_DETAIL]: viewType,
      };
      dispatch(reportActions.setCalendarViewType(payload));
    }
    const params = {
      startDate: dataAppointment?.startTime || moment().format(DATE_FORMAT),
      endDate: dataAppointment?.endTime || moment().format(DATE_FORMAT),
      salaryType: 'ALL',
    };
    try {
      const resData: AxiosResponse<{ data: ISalaryStaffInfo[] }> = await reportApis.getSummarySalaryStaff(params);
      if (resData?.data?.data) {
        dispatch(reportActions.getSummarySalaryStaff.success(resData.data.data));
        setTimeout(() => {
          navigate(
            `/store/${storage.shop_id.get()}/report/employee-salary/staff/${dataPrintSalaryStaffDetail.staffId
            }`
          );
        }, 100);
      }
    } catch (error) {
      dispatch(reportActions.getSummarySalaryStaff.fail({}));
    }
  };

  return (
    <Modal
      open={isShow}
      footer={<>
        <Button size='small' ntype="DEFAULT" icon="print" onClick={onPrint}>
          Print
        </Button>
        <Button size='small' ntype="LIGHT_BLUE" onClick={onViewDetail}>
          View Detail
        </Button>
      </>}
      onCancel={onClose}
      className='modal-max-height-unset'
      wrapClassName={v2 ? 'v2' : ''}
      closeIcon={null}
      width={350}
      bodyStyle={{
        margin: 0,
        padding: 0,
        borderRadius: 25,
        minHeight: 300,
      }}
    >
      <Box className="center" overflow="auto">
        <TicketBox>
          <PrintArea ref={divRef}>
            <Box
              className="center"
              overflow="auto"
              display="flex"
              flexDirection="column"
            >
              <LogoBox>
                <IconLogo />
              </LogoBox>
              <Box className="center" width={'100%'}>
                <TextDate variant="CONTENT_2" printMode >
                  {renderTime(dataAppointment)}
                </TextDate>
              </Box>
              <Box className="center" width={'100%'} highlight >
                <Text
                  variant="CAPTION_1"
                  printMode
                  style={{ paddingTop: '2.5px' }}
                  className='title-highlight'

                >
                  {dataPrintSalaryStaffDetail.staffName}
                </Text>
              </Box>
              <Box width={'100%'}>
                <EmployeeTickets data={dataPrintSalaryStaffDetail?.details} />
                <Discount>
                  <ColHeader span={16}><Text printMode variant='CAPTION_2' >TOTAL SALES</Text></ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(dataPrintSalaryStaffDetail.totalSales)}</Text></ColHeader>
                </Discount>
                <Discount>
                  <ColHeader span={16}><Text printMode variant='CAPTION_2' >SUPPLY FEE</Text></ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(dataPrintSalaryStaffDetail?.supplyFee)}</Text></ColHeader>
                </Discount>
                <Box className="center" highlight display='flex' justifyContent='space-between' style={{ padding: 4 }} >
                  <Text variant='CAPTION_1' className='title-highlight' color='text_1' textAlign='left' style={{ flex: 1, fontWeight: 'bolder' }} >TOTAL</Text>
                  <Text variant='CAPTION_1' className='title-highlight' color='text_1' textAlign='right' style={{ fontWeight: 'bolder' }}>{formatCurrency((dataPrintSalaryStaffDetail.totalSales || 0) - (dataPrintSalaryStaffDetail?.supplyFee || 0))}</Text>
                </Box>
                <Discount>
                  <ColHeader span={16}>
                    <Text variant="CAPTION_2" printMode>
                      COMMISSION
                    </Text>
                  </ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}>
                    <Text variant="CAPTION_2" printMode>
                      {formatCurrency(dataPrintSalaryStaffDetail.totalCommission)}
                    </Text>
                  </ColHeader>
                </Discount>
                {!!dataPrintSalaryStaffDetail.staffDiscount && <Discount>
                  <ColHeader span={16}>
                    <Text variant="CAPTION_2" printMode>
                      DISCOUNT
                    </Text>
                  </ColHeader>
                  <ColHeader span={8} style={{ textAlign: 'end' }}>
                    <Text variant="CAPTION_2" printMode>
                      {formatCurrency(dataPrintSalaryStaffDetail.staffDiscount)}
                    </Text>
                  </ColHeader>
                </Discount>}
                <>
                  {!!dataPrintSalaryStaffDetail?.tipCollected && <Discount>
                    <ColHeader flex={1}>
                      <Text variant="CAPTION_2" printMode>
                        COLLECTED TIPS
                      </Text>
                    </ColHeader>

                    <ColHeader span={6} style={{ textAlign: 'end' }}>
                      <Text variant="CAPTION_2" printMode>
                        {formatCurrency(dataPrintSalaryStaffDetail?.tipCollected)}
                      </Text>
                    </ColHeader>
                  </Discount>}
                  {!!dataPrintSalaryStaffDetail?.tipFee && <>
                    <Discount>
                      <ColHeader flex={1}>
                        <Text variant="CAPTION_2" printMode>
                          TIP FEE
                        </Text>
                      </ColHeader>

                      <ColHeader span={10}>
                        <Text variant="CAPTION_2" printMode textAlign='right'>
                          {formatCurrency(dataPrintSalaryStaffDetail?.tipFee) + ' '}
                          {(dataPrintSalaryStaffDetail?.tipFeeType === 'PERCENT' && `(${dataPrintSalaryStaffDetail?.tipFeeValue || 0}%)`)}
                        </Text>
                      </ColHeader>
                    </Discount>
                    <Discount>
                      <ColHeader flex={1}>
                        <Text variant="CAPTION_2" printMode>
                          TOTAL TIP
                        </Text>
                      </ColHeader>

                      <ColHeader span={6} style={{ textAlign: 'end' }}>
                        <Text variant="CAPTION_2" printMode>
                          {formatCurrency(dataPrintSalaryStaffDetail?.totalTip)}
                        </Text>
                      </ColHeader>
                    </Discount>
                  </>}
                </>
                <Box className="center" highlight display='flex' justifyContent='space-between' style={{ padding: 4 }} >
                  <Text variant='CAPTION_1' className='title-highlight' color='text_1' textAlign='left' style={{ flex: 1, fontWeight: 'bolder' }} >PAYROLL</Text>
                  <Text variant='CAPTION_1' className='title-highlight' color='text_1' textAlign='right' style={{ fontWeight: 'bolder' }}>{formatCurrency(dataPrintSalaryStaffDetail?.totalSalary)}</Text>
                </Box>
                <BoxInfo >
                  <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                    {allSetting?.labelSalaryCashOrBonus || 'Cash'} {dataPrintSalaryStaffDetail?.cashPercent}%
                  </Text>
                  <Text printMode variant='CAPTION_3' color="text_3" >
                    {formatCurrency(dataPrintSalaryStaffDetail?.totalPayCash)}
                  </Text>
                </BoxInfo>
                <BoxInfo>
                  <Text printMode variant='CAPTION_3' color="text_3" >
                    Check {dataPrintSalaryStaffDetail?.checkPercent}%
                  </Text>
                  <Text printMode variant='CAPTION_3' color="text_3" >
                    {formatCurrency(dataPrintSalaryStaffDetail?.totalPayCheck)}
                  </Text>
                </BoxInfo>
                {!!dataPrintSalaryStaffDetail?.tipToCash &&
                  <BoxInfo>
                    <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                      Tip To {allSetting?.labelSalaryCashOrBonus || 'Cash'} {dataPrintSalaryStaffDetail?.tipCashPercent ? ` (${dataPrintSalaryStaffDetail?.tipCashPercent}%)` : ''}
                    </Text>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                      {formatCurrency(dataPrintSalaryStaffDetail?.tipToCash)}
                    </Text>
                  </BoxInfo>}
                <BoxInfo>
                  <Text printMode variant='CAPTION_3' color="text_3" >
                    Tip To Check {dataPrintSalaryStaffDetail?.tipCheckPercent ? ` (${dataPrintSalaryStaffDetail?.tipCheckPercent}%)` : ''}
                  </Text>
                  <Text printMode variant='CAPTION_3' color="text_3" >
                    {formatCurrency(dataPrintSalaryStaffDetail?.tipToCheck)}
                  </Text>
                </BoxInfo>
                <Box bt='print_line'>
                  <BoxInfo>
                    <Text textTransform='uppercase' printMode variant='CAPTION_2' color="text_3" >
                      TOTAL {allSetting?.labelSalaryCashOrBonus || 'Cash'}
                    </Text>
                    <Text printMode variant='CAPTION_2' color="text_3" >
                      {formatCurrency(dataPrintSalaryStaffDetail?.totalCash)}
                    </Text>
                  </BoxInfo>
                  <BoxInfo>
                    <Text printMode variant='CAPTION_2' color="text_3">
                      TOTAL CHECK
                    </Text>
                    <Text printMode variant='CAPTION_2' color="text_3">
                      {formatCurrency(dataPrintSalaryStaffDetail?.totalCheck)}
                    </Text>
                  </BoxInfo>
                </Box>
              </Box>
            </Box>
          </PrintArea>
        </TicketBox>
      </Box>
    </Modal>
  );
};
export default ModalViewInfo;

const TicketBox = styled.div`
  width: 100%;
  margin-top: 1.5rem;
  position: relative;
`;
const PrintArea = styled.div`
  background: #fff;
  height: fit-content;
  width: 72mm;
  padding: 0px 5px;
  margin: 0 auto;
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32.292px;
  gap: 4.348px;
  flex-shrink: 0;
`;
const Header = styled(Row)`
  border-top: 1px solid black;
  padding: 2.5px 0px;
  border-bottom: 1px dashed black;
`;

const ColHeader = styled(Col)`
  color: #767676;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const TextDate = styled(Text)`
  color: var(--text-text-3, black);
  text-align: center;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 800 !important;
  line-height: normal !important;
  letter-spacing: 1px !important;
`;

const BoxInfo = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;