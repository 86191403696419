import Icon from 'assets/Icons';
import React from 'react';
import { ISimpleMenuItem, ISimpleMenuServiceItem } from 'services/shop/types/simpleMenu';
import styled from 'styled-components';
type Props = {
  data: ISimpleMenuItem;
  serviceIdsSelected: {
    id: string;
    count: number;
  }[];
  onClickService: (serviceItem: ISimpleMenuServiceItem) => void;
};
const CategoryItem = ({ data, serviceIdsSelected, onClickService }: Props) => {
  const selected = data?.services?.some(o => !!serviceIdsSelected.find(s => s.id === o.id));
  return (
    <CategoryItemStyled className='prevent-select'>
      <div className="card-header">
        <IconCate />
        <span>{data.categoryName || '--'}</span>
        {!!selected && <Icon type="tickSM" />}
      </div>
      <div className='card-services'>
        {data.services.map((o) => <div key={o.id} className='service-item' onClick={() => onClickService(o)}><ServiceItem count={serviceIdsSelected.find(s => s.id === o.id)?.count || 0} data={o} /></div>)}
      </div>
    </CategoryItemStyled>
  );
};

export default CategoryItem;

const CategoryItemStyled = styled.div`
  .card-header {
    display: flex;
    align-items: center;
    gap: 4px;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 2px;
    border: 1px solid #86909C;
    background: #E5E5E5;
    span {
      color: #555;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.7px;
      text-transform: uppercase;
    }
  }
  .card-services {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    .service-item {
      width: calc(50% - 4px);
    }
    padding-bottom: 8px;
  }
`;
type IServiceProps = {
  data: ISimpleMenuServiceItem;
  count?: number;
};
const ServiceItem = ({ data, count }: IServiceProps) => {
  return (
    <ServiceItemStyled className='prevent-select'>
      {!count ? <div className='service-icon'><IconPlus /></div> : <div className='service-count'><span>{count}</span></div>}
      <div className='service-name'>{data.serviceName}</div>
    </ServiceItemStyled>
  );
};
const ServiceItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0 8px;
  min-height: 45px;
  &:hover {
    cursor: pointer;
  }
  .service-icon {
    width: 18px;
    height: 16px;
  }
  .service-count {
    display: flex;
    min-width: 18px;
    height: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background: #FF8890;
    span {
      color: #FFF;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .service-name {
    color: #1D2129;
    font-family: "Open Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.28px;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconCate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
    <rect x="1" y="1.5" width="2" height="2" fill="#1D2129" />
    <rect x="1" y="5.5" width="2" height="2" fill="#1D2129" />
    <rect x="5" y="1.5" width="2" height="2" fill="#1D2129" />
    <rect x="5" y="5.5" width="2" height="2" fill="#1D2129" />
  </svg>
);

const IconPlus = () => (
  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="1" width="15" height="15" rx="7.5" fill="#E5E5E5" />
    <rect x="0.5" y="1" width="15" height="15" rx="7.5" stroke="#86909C" />
    <path d="M8.09375 8.32812H11.3125V9.39062H8.09375V12.7031H7.02344V9.39062H3.80469V8.32812H7.02344V5H8.09375V8.32812Z" fill="#86909C" />
  </svg>
);
