import Modal from 'components/Modal';
import { memo } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import ServicePickerComponent from '../../AppointmentsPage/components/Calendar/TableView/Body/Modal/NewAppointmentV2/ServicesTab/ServicePickerComponent';
type Props = {
  visible?: boolean;
  onClose?: () => void;
  chooseService: (val: IServiceItemData) => void;
};

const ModalChooseService = ({ chooseService, onClose = () => undefined, visible }: Props) => {
  const handleChooseServices = (val: IServiceItemData) => {
    chooseService(val);
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      modalTitle='Choose Service'
      hiddenFooter
      closable
      noneBodyStyle
      noneFooterStyle
      width={'70vw'}
      className='modal-overflow-unset modal-max-height-unset'
      containerPadding={1}
      showClose
    >
      <ServicePickerComponent
        single
        selected={[]}
        handleChooseServices={handleChooseServices}
      />
    </Modal>
  );
};

export default memo(ModalChooseService);
