import { TRANSACTION_STATUS } from 'features/ticketList/services/types/api';
import { get } from 'lodash';
import styled from 'styled-components';
import bg_tab_active_icon from '../../icons/bg-tab-active.svg';
import bg_tab_icon from '../../icons/bg-tab.svg';
import closed_ticket_active_icon from '../../icons/closed-ticket-active.svg';
import closed_ticket_icon from '../../icons/closed-ticket.svg';
import delete_ticket_active_icon from '../../icons/delete-ticket-active.svg';
import delete_ticket_icon from '../../icons/delete-ticket.svg';
import open_ticket_active_icon from '../../icons/open-ticket-active.png';
import open_ticket_icon from '../../icons/open-ticket.svg';
import refund_ticket_active_icon from '../../icons/refund-ticket-active.svg';
import refund_ticket_icon from '../../icons/refund-ticket.svg';
import voided_ticket_active_icon from '../../icons/voided-ticket-active.svg';
import voided_ticket_icon from '../../icons/voided-ticket.svg';

const tabConfigs = {
  [TRANSACTION_STATUS.OPEN_TICKET]: {
    label: 'OPEN TICKETS',
    icon: open_ticket_icon,
    icon_active: open_ticket_active_icon,
  },
  [TRANSACTION_STATUS.CLOSED]: {
    label: 'CLOSE TICKETS',
    icon: closed_ticket_icon,
    icon_active: closed_ticket_active_icon,
  },
  [TRANSACTION_STATUS.VOID]: {
    label: 'VOIDED',
    icon: voided_ticket_icon,
    icon_active: voided_ticket_active_icon,
  },
  [TRANSACTION_STATUS.REFUND]: {
    label: 'REFUNDED',
    icon: refund_ticket_icon,
    icon_active: refund_ticket_active_icon,
  },
  [TRANSACTION_STATUS.DELETE]: {
    label: 'DELETED',
    icon: delete_ticket_icon,
    icon_active: delete_ticket_active_icon,
  },
};
type TabV2Props = {
  type: keyof typeof TRANSACTION_STATUS,
  tab: string;
  onChange: (tab: string) => void;
  zIndex?: number;
  first?: boolean;
};
const TabUI = ({ first, zIndex, type, tab, onChange }: TabV2Props) => {
  const config = get(tabConfigs, type || '');
  const active = tab === type;
  if (!config) return null;

  return <TabV2Styled style={{ zIndex, marginLeft: first ? 0 : '-50px' }} type='button' onClick={() => onChange(type)} className={active ? 'active' : ''}>
    <img src={active ? bg_tab_active_icon : bg_tab_icon} alt="bg_tab_icon" className='svg-bg' style={{ width: '100%' }} />
    <div className="tab-item-container">
      <img src={active ? config.icon_active : config.icon} style={{ height: 32, width: 32 }} />
      <span>{config.label}</span>
    </div>
  </TabV2Styled>;
};

export default TabUI;

const TabV2Styled = styled.button`
display: flex;
height: 56px;
align-items: end;
gap: 16px;
border-radius: 24px 24px 0px 0px;
position: relative;
width: 320px;
margin-left: -50px;
span {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.tab-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  z-index: 2;
  position: relative;
  padding-bottom: 6px;
  zoom: 0.9;
}
.svg-bg {
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
  width: 100%;
  // filter: drop-shadow(4px 0px 8px rgba(0, 0, 0, 0.15)) drop-shadow(-4px 0px 8px rgba(0, 0, 0, 0.15));
}

&.active {
  span {
    color: #FFF;
  }
}
`;
export const TabUIContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  zoom: 1.2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;