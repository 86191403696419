import { Form, Input } from 'antd';
import IconLogoAuthLogin from 'assets/Icons/logoAuthLogin';
import Button from 'components/Button';
import InputEmail from 'components/InputEmail';
import Text from 'components/Text';
import AnimatedBG from 'features/auth/components/Animated';
import ImageBackground from 'features/auth/components/ImageBackground';
import authActions from 'features/auth/services/actions';
import authApis from 'features/auth/services/apis';
import { IOwnerLoginResData } from 'features/auth/services/types/owner';
import React, { CSSProperties, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type IFormOwnerValues = {
  email: string;
  password: string;
};
type IFormOwnerLoginProps = {};
const FormOwnerLogin: React.FC<IFormOwnerLoginProps> = () => {
  const [isForgotPass, setForgotPass] = useState(false);
  const dispatch = useAppDispatch();
  const setPageLoading = useSetLoadingPage();
  const navigate = useNavigate();

  const login = async (values: IFormOwnerValues) => {
    const body = { email: values.email, password: values.password };
    const res: IResponseDataBody<IOwnerLoginResData> = await authApis.ownerLogin(body);
    if (res.data.data) {
      dispatch(authActions.ownerLogin.success(res.data.data));
      document.body.requestFullscreen();
      navigate('/owner/dashboard');
    }
  };

  const forgotPass = async (values: IFormOwnerValues) => {
    const body = { email: values.email };
    const res: IResponseDataBody<any> = await authApis.ownerForgotPass(body);
    if (res.data.data) {
      setForgotPass(false);
    }
  };

  const onFinish = async (values: IFormOwnerValues) => {
    setPageLoading(true);
    try {
      if (!isForgotPass) {
        await login(values);
        return;
      }

      await forgotPass(values);
    } catch (error) {

    } finally {
      setPageLoading(false);
    }
  };

  const onFinishFailed = () => undefined;

  type FieldType = {
    email?: string;
    password?: string;
  };

  return (
    <ImageBackground>
      <FormOwnerLoginStyled>
        <div className='logo'><IconLogoAuthLogin /></div>
        <div className="form-box">
          <Text variant='H3' color='text_3' textAlign='center' mb={3}>{isForgotPass ? 'FORGOT PASSWORD' : 'ADMIN LOG IN'}</Text>
          <Form
            name="basic"
            // initialValues={{ email: 'khahamaspa@gmail.com', password: '123456789' }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
            validateTrigger={['onSubmit']}
          >
            <InputEmail name={'email'} label="Email" rules={[
              { type: 'email' },
              { required: true, message: 'Please input your email!' }
            ]}
              autoFocus
              tabIndex={1}
            />

            {!isForgotPass && <>
              <Form.Item<FieldType>
                label="Password"
                name="password"
                className='mb-0'
                rules={[{ required: true, message: 'Please input your password!' }]}
              >
                <Input.Password placeholder='Password' tabIndex={2} />
              </Form.Item>
              <div className='forgot-pass'>
                <button onClick={() => setForgotPass(true)} type='button'>
                  <Text variant='CONTENT_2' color='info_3' pt={0.5} >Forgot Password?</Text>
                </button>
              </div>
            </>}

            <div className='actions'>
              {isForgotPass ?
                (
                  <>
                    <Button onClick={(e) => { e.preventDefault(); setForgotPass(false); }}
                      ntype='SECONDARY'
                      htmlType="button"
                      icon='back'
                      style={buttonSubmitStyle}
                    >
                      Back
                    </Button>
                    <Button ntype='PRIMARY' htmlType="submit" style={buttonSubmitStyle}>
                      Reset
                    </Button>
                  </>
                )
                : <Button ntype='PRIMARY' htmlType="submit" style={buttonSubmitStyle}>
                  Log In
                </Button>
              }
            </div>
          </Form>
        </div>
      </FormOwnerLoginStyled>
      <AnimatedBG />
    </ImageBackground>
  );
};

const buttonSubmitStyle = { width: '100%' } as CSSProperties;

export default FormOwnerLogin;
const FormOwnerLoginStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 3;
  .logo {
    position: absolute;
    z-index: 3;
    top: -7rem;
  }
  .forgot-pass {
    text-align: right;
    margin-bottom: 1.5rem;
  }
  .actions {
    display: flex;
    width:100%;
    gap:24px;
  }
`;
