import { CheckOutlined } from '@ant-design/icons';
import { Form, InputNumber, InputRef, Select } from 'antd';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import Text from 'components/Text';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';
import { useEffect, useRef } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import PopoverChangeColors from './PopoverChangeColors';

type Props = {
  value?: IServiceItemData | null;
  index?: number;
  name?: any;
  selectedIds?: string[];
  onChooseService: (id: string) => void;
  onEditName: (value: IServiceItemData) => void;
};
const Service = ({ value, index = 0, name, selectedIds = [], onChooseService, onEditName }: Props) => {
  const inputPerRef = useRef<InputRef>(null);
  const inputDolRef = useRef<InputRef>(null);

  const form = Form.useFormInstance();
  const discountType = Form.useWatch(['services', name, 'discountType'], form);
  const selected = selectedIds.indexOf(value?.id || '') !== -1;
  const handleEditName = () => {
    if (!value) return;
    onEditName(value);
  };
  const handleChooseService = () => onChooseService(value?.id || '');
  const handleChangeType = (e: DISCOUNT_VALUE_TYPE) => {
    form.setFieldValue(['services', name, 'discountValue'], '0.00');
    setTimeout(() => {
      if (e === DISCOUNT_VALUE_TYPE.DOLLAR) {
        inputDolRef.current?.focus();
      } else {
        inputPerRef.current?.focus();
      }
    }, 100);
  };

  useEffect(() => {
    if (discountType) return;
    form.setFieldValue(['services', name, 'discountType'], DISCOUNT_VALUE_TYPE.PERCENT);
  }, [discountType]);

  return (
    <Styled>
      <div className="service-card-header">
        <Button onClick={handleChooseService} size='small' ntype='GHOST' className={`checkbox ${selected ? 'active' : ''}`}>
          {!!selected && <CheckOutlined style={{ fontSize: '20px', color: '#fff' }} />}
        </Button>
        <button type='button' onClick={handleEditName} style={{ flex: 1 }}>
          <Text className="service-name">{index + 1}. {value?.serviceName || '--'}</Text>
        </button>
        <Button onClick={handleEditName} size='small' ntype='GHOST' icon='editService' />
        <Form.Item noStyle name={[name, 'backgroundColor']}>
          <PopoverChangeColors />
        </Form.Item>
      </div>
      <div className="service-card-body">
        <div className="service-card-content-item">
          <span>Price</span>
          <Form.Item noStyle name={[name, 'priceSell']}>
            <CurrencyInputPrecision width={'5rem'} height={'38px'} />
          </Form.Item>
        </div>
        <div className="service-card-content-item">
          <span>Duration</span>
          <Form.Item noStyle name={[name, 'duration']}>
            <InputNumber<number>
              style={{ width: '5rem', height: '38px' }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="service-card-content-item">
          <span>Supply Fee</span>
          <Form.Item noStyle name={[name, 'supplyFee']}>
            <CurrencyInputPrecision width={'5rem'} height={'38px'} />
          </Form.Item>
        </div>
        <div className="service-card-content-item" style={{ gap: '4px' }}>
          <div style={{ flex: 1 }} />
          <Form.Item noStyle name={[name, 'discountType']}>
            <Select placeholder='%' options={[
              { label: '%', value: DISCOUNT_VALUE_TYPE.PERCENT },
              { label: '$', value: DISCOUNT_VALUE_TYPE.DOLLAR },
            ]}
              onChange={handleChangeType}
            />
          </Form.Item>
          <Form.Item noStyle name={[name, 'discountValue']}>
            {(discountType === DISCOUNT_VALUE_TYPE.PERCENT) ?
              <PercentDecimalInputPrecision inputRef={inputPerRef} width={'6rem'} height={'38px'} />
              : <CurrencyInputPrecision inputRef={inputDolRef} width={'6rem'} height={'38px'} />}
          </Form.Item>
        </div>
      </div>
    </Styled>
  );
};

export default Service;

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #DDD;
  padding-bottom: 16px;
  border-right: 0.5px solid #DDD;
  padding-right: 16px;
  .service-card-body {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
    .service-card-content-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        color: #1D2129;
        font-family: "Open Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: capitalize;
      }
      .ant-input-number {
        .ant-input-number-handler-wrap {
          display: none;
        }
        .ant-input-number-input-wrap {
          height: 100%;
          input {height: 100%;}
        }
      }
      input, .ant-input-suffix {
        color: #1D2129;
        text-align: right;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
      }
      .ant-select {
        height: 38px;
        width: 4rem;
      }
    }
  }
  .service-card-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    .service-name {
      text-align: left;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      flex: 1 0 0;
      overflow: hidden;
      color: #1D2129;
      text-overflow: ellipsis;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  .checkbox {
    border-radius: 4px;
    border: 1.5px solid #86909C;
    background: #E5E5E5;
    margin-right: 8px;
    &:hover {
      border: 1.5px solid #86909C;
      background: #E5E5E5;
      opacity: 0.8;
    }
    &.active {
      background: #FF8890;
      border: 1.5px solid #FF8890;
      &:hover {
        border: 1.5px solid #FF8890;
        background: #FF8890;
        opacity: 0.8;
      }
    }
    height: 32px !important;
    width: 32px !important;
    padding: 0;
  }
`;
