import { createSlice } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  detail: null,
  visibleTip: false,
  cards: [],
  couponPrint: null,
  detailPrint: null,
  cardsPrint: [],
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.setPrintData, (state, { payload }) => {
        state.detailPrint = payload.detail;
        state.couponPrint = payload.coupon;
        state.cardsPrint = payload.cards;
      })
      .addCase(actions.setVisibleTip, (state, { payload }) => {
        state.visibleTip = payload;
      })
      .addCase(actions.refreshDetail.success, (state, { payload }) => {
        state.detail = payload;
        state.visibleTip = false;
      })
      .addCase(actions.getTransactionDetail.success, (state, { payload }) => {
        state.detail = payload;
        state.visibleTip = true;
      })
      .addCase(actions.getListMultipleCards.success, (state, { payload }) => {
        state.cards = payload;
      })
      .addCase(actions.getTransactionDetail.fetch, (state) => {
        state.detail = null;
        state.visibleTip = false;
        state.cards = [];
      })

      ;
  },
});

const multiCardServiceReducer = Slice.reducer;
export default multiCardServiceReducer;
