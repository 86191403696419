import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import CashierActionButton from '../CashierActionBtn';
import { ISubmitModalDiscount } from '../Discount/DiscountMethodModal';
import DiscountTicket from '../Discount/DiscountTicket';
import ModalExistDiscount, { ModalExistDiscountRefs } from '../Discount/ModalExistDiscount';
type Props = {
  v2?: boolean;
};
const ButtonDiscountTicket = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const visibleExistRef = useRef<ModalExistDiscountRefs>(null);
  const open = () => setVisible(true);
  const close = () => setVisible(false);
  const ticketInfo = cashierSelectors.getTicketTotalInfo();
  const discountTicket = cashierSelectors.getDiscountTicket();

  const handleSubmit: ISubmitModalDiscount = (discount) => {
    if (!discountTicket?.discount) {
      dispatch(cashierActions.setDiscountTicket(discount));
      return;
    }
    const callback = () => dispatch(cashierActions.setDiscountTicket(discount));
    visibleExistRef.current?.open(callback);
  };

  return (
    <>
      <CashierActionButton
        label='Disc. Ticket'
        ntype="LIGHT_BLUE"
        feature='DISC'
        onClick={open}
        v2UI={v2}
      />
      {visible &&
        <DiscountTicket
          v2={v2}
          visible
          onClose={close}
          onSubmit={handleSubmit}
          initialAmount={ticketInfo.subtotal ?? 0}
        />}
      {/* {visible &&
        <DiscountMethodModal
          visible={visible}
          onClose={close}
          onSubmit={handleSubmit}
          initialAmount={ticketInfo.subtotal ?? 0}
        />
      } */}
      <ModalExistDiscount ref={visibleExistRef} target='ticket' />
    </>
  );
};

export default ButtonDiscountTicket;
