import { ITimelineItem } from 'features/appointment/utils/getTimeLines';
import { useRef } from 'react';
import styled from 'styled-components';

type Ref = {
  setLoading: (value: boolean) => void;
};
type Props = {
  value?: ITimelineItem;
  onChange?: (value?: ITimelineItem) => void;
  options?: ITimelineItem[];
};
export const useStartTimesRef = () => useRef<Ref>(null);
const StartTimes = ({ value, onChange = () => undefined, options = [] }: Props) => {
  return (
    <Container className='TimeSlots'>
      {options.map(o => (
        <button type='button' onClick={() => onChange(o)} key={o.id} className={`start-time-item ${value?.id === o.id ? 'active' : ''}`}>
          <span>{o.id}</span>
        </button>
      ))}
    </Container>
  );
};

StartTimes.displayName = 'StartTimes';
export default StartTimes;

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(140px, 1fr));
  gap: 8px;
  .start-time-item {
    display: flex;
    padding: 16px 0px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border: 1px solid #CCD4DC;
    background: #FFF;
    span {
      color: #505050;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    &.active {
      border: 1px solid #2D6772;
      background: #2D6772;
      span {
        color: #FFF;
      }
    }
  }
`;