import { Form, Input } from 'antd';
import styled from 'styled-components';
import BlockHourType from './BlockHourType';
import StaffListChooseUI from './StaffListChooseUI';
import TimeDateBlockHour from './TimeDateBlockHour';
type Props = {
  breakTime?: boolean;
};
const BlockHourFormV2 = ({ breakTime }: Props) => {
  return (
    <Container>
      <BlockHourInfo>
        <Form.Item noStyle name={'blockHourType'} hidden={breakTime}>
          <BlockHourType />
        </Form.Item>
        <Form.Item noStyle name={'staffId'} rules={[{ required: true, message: 'Please choose staff' }]}>
          <StaffListChooseUI />
        </Form.Item>
        <ReasonContainer>
          <Form.Item noStyle name={'note'}>
            <Input.TextArea rows={5} placeholder='Reason' />
          </Form.Item>
        </ReasonContainer>
      </BlockHourInfo>
      <RightContainer>
        <Form.Item noStyle name={'blockHourType'}>
          <TimeDateBlockHour />
        </Form.Item>
      </RightContainer>
    </Container>
  );
};

export default BlockHourFormV2;
const BlockHourInfo = styled.div`
display: flex;
flex: 1;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-right: 1px solid #86909C;
background: #FFF;
`;
const Container = styled.div`
display: flex;
align-items: flex-start;
align-self: stretch;
height: 1200px;
`;
const RightContainer = styled.div`
width: 800px;
align-self: stretch;
display: flex;
flex-direction: column;
padding: 16px;
.TimeSlots {
  grid-template-columns: repeat(auto-fill,minmax(100px, 1fr));
  .start-time-item span {
    font-size: 20px;
  }
}
`;
const ReasonContainer = styled.div`
  padding: 16px;
  align-self: stretch;
  textarea {
    width: 100%;
    display: flex;
    height: 120px;
    padding: 7px 13px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #CCD4DC;
    background: #F6F7FC;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;