import React from 'react';
import TabTicketByParams from '../Tabs/TabTicketByParams';
import styled from 'styled-components';
import TicketFilterV2 from './Filter';
import CloseTicketSummary from './CloseTicketSummary';

const CloseTickets = () => {
  return (
    <Container>
      <TicketFilterV2 type='CLOSED' />
      <ContentContainer>
        <CloseTicketSummary />
        <div className="tickets">
          <TabTicketByParams v2 tableKey={'CLOSED'} />
        </div>
      </ContentContainer>
    </Container>
  );
};

export default CloseTickets;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .tickets {
    flex: 1;
    position: relative;
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 16px;
  flex-direction: column;
`;
