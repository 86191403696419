import { Form, FormInstance } from 'antd';
import React from 'react';
import styled from 'styled-components';
import StaffListChooseUI from './StaffListChooseUI';
import Box from 'components/Box';
import CalendarPicker from './CalendarPicker';
import StartTimes from './StartTimes';
import getTimeLines, { setTime } from 'features/appointment/utils/getTimeLines';
import Text from 'components/Text';
import moment from 'moment';
import { first } from 'lodash';

const timeOptions = getTimeLines(setTime(9, 0), setTime(21, 0), 15, 'hh:mm A');
export const getCurrentStartDate = () => {
  const now = moment();
  const times = timeOptions.filter(o => o.value > now);
  return first(times);
};

type Props = {
  form: FormInstance;
};
const AddTimeInOutV2 = ({ form }: Props) => {
  return (
    <Container>
      <Box style={{ flex: 1 }} display='flex' flexDirection='column'>
        <Form.Item noStyle name={'staff'}><StaffListChooseUI /></Form.Item>
      </Box>
      <Box style={{ width: '800px' }} display='flex' flexDirection='column'>
        <>
          <Form.Item noStyle name={'date'} rules={[{ required: true }]}>
            <CalendarPicker />
          </Form.Item>
          <ContainerTimes>
            <div className="children-content">
              <Text variant='H9' color='text_3' className='text-lg'>Start Time</Text>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const date = getFieldValue('date');
                  const now = moment();
                  const times = date?.isSame(now, 'day') ? timeOptions.filter(o => o.value > now) : timeOptions;
                  return <>
                    <Form.Item noStyle name={'startTime'} rules={[{ required: true }]}>
                      <StartTimes options={times} onChange={() => form.setFieldValue('endTime', null)} />
                    </Form.Item>
                  </>;
                }}
              </Form.Item>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const startTime = getFieldValue('startTime');
                  if (!startTime) return null;
                  const times = timeOptions.filter(o => o.value > startTime?.value);
                  return <>
                    <Text variant='H9' color='text_3' className='text-lg'>End Time</Text>
                    <Form.Item noStyle name={'endTime'} rules={[{ required: true }]}>
                      <StartTimes options={times} />
                    </Form.Item>
                  </>;
                }}
              </Form.Item>

            </div>
          </ContainerTimes>
        </>
      </Box>
    </Container>
  );
};

export default AddTimeInOutV2;
const Container = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;
  zoom: 0.85;
  .ant-form-item-label label {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 166.667%;
    text-transform: capitalize;
    color: #1D2129;
  }
    .ant-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
`;

const ContainerTimes = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  .children-content {
    position: absolute;
    inset: 0;
    overflow: auto;
    scrollbar-width: none;
    display: flex;
    flex-direction: column;
    gap: 8px;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
`;