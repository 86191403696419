import settingServiceActions from 'features/settingService/services/actions';
import settingApis from 'features/settingService/services/apis';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';
import { ICategoryUpdateInfoServiceItem, ICreateMultipleAddOnItem, IEditCateBodyType } from 'features/settingService/services/types/api';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import { ICategoryItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';

const useSettingService = () => {
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();

  const setActiveCate = (_activeCate: ICategoryItemData | null) => {
    dispatch(settingServiceActions.serviceActs.activeCate(_activeCate?.id || null));
  };

  const boundaryFuncWrapper = async (asyncFunc: () => Promise<any>, isAddOn?: boolean) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await asyncFunc();
      if (res.data.data) {
        if (isAddOn) {
          dispatch(shopActions.getNewAddOnList.fetch());
        } else {
          dispatch(shopActions.getNewCategories.fetch());
        }
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const deleteServices = (ids: string[]) => boundaryFuncWrapper(
    () => settingApis.deleteMultiple(ids)
  );

  const deleteServicesAddOn = (ids: string[]) => boundaryFuncWrapper(
    () => settingApis.deleteMultiple(ids), true
  );

  const applySupplyFee = (cateId: string, value: number) => boundaryFuncWrapper(
    () => settingApis.categoryApplySupplyFee(cateId, value)
  );

  const applySupplyFeeAddon = (value: number) => boundaryFuncWrapper(
    () => settingApis.categoryApplySupplyFee(null, value), true,
  );

  const editNameCate = (cateId: string, value: string) => boundaryFuncWrapper(
    () => settingApis.categoryEditName(cateId, value),
  );

  const editNameService = async (id: string, value: string) => boundaryFuncWrapper(
    () => settingApis.serviceEditName(id, value),
  );
  const editNameServiceAddOn = async (id: string, value: string) => boundaryFuncWrapper(
    () => settingApis.serviceEditName(id, value), true
  );

  const updateInfoServices = async (body: ICategoryUpdateInfoServiceItem[]) => boundaryFuncWrapper(
    () => settingApis.categoryUpdateInfoServices(body),
  );
  const updateInfoServicesAddOn = async (body: ICategoryUpdateInfoServiceItem[]) => boundaryFuncWrapper(
    () => settingApis.categoryUpdateInfoServices(body), true
  );

  const deleteCategory = async (id: string) => boundaryFuncWrapper(
    () => settingApis.categoryDelete(id),
  );

  const preOrderServices = async (ids: string[]) => boundaryFuncWrapper(
    () => settingApis.sortServices(ids)
  );

  const preOrderServicesAddOn = async (ids: string[]) => boundaryFuncWrapper(
    () => settingApis.sortServices(ids), true
  );

  const addCategory = async (body: IEditCateBodyType) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<ICategoryItemData> = await settingApis.createCategory(body);
      if (res.data.data) {
        dispatch(settingServiceActions.serviceActs.activeCate(res.data.data.id));
        dispatch(shopActions.getNewCategories.fetch());
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const addMoreServiceForCate = async (body: IEditCateBodyType) => boundaryFuncWrapper(
    () => settingApis.addMoreServiceByCate(body)
  );

  const addMoreServiceForAddOn = async (body: ICreateMultipleAddOnItem[]) => boundaryFuncWrapper(
    () => settingApis.createMultipleAddOn(body), true
  );

  const applyDiscountItemsCategory = (cateId: string, type: DISCOUNT_VALUE_TYPE, value: number) => boundaryFuncWrapper(
    () => settingApis.applyDiscountItemsForCate(cateId, value, type),
  );

  const applyDiscountItemsCateAddon = (type: DISCOUNT_VALUE_TYPE, value: number) => boundaryFuncWrapper(
    () => settingApis.applyDiscountItemsForCate(null, value, type), true
  );
  return ({
    setActiveCate,
    deleteServices,
    applySupplyFee,
    editNameCate,
    editNameService,
    editNameServiceAddOn,
    updateInfoServices,
    deleteCategory,
    preOrderServices,
    preOrderServicesAddOn,
    addCategory,
    addMoreServiceForCate,
    applySupplyFeeAddon,
    deleteServicesAddOn,
    addMoreServiceForAddOn,
    updateInfoServicesAddOn,
    applyDiscountItemsCategory,
    applyDiscountItemsCateAddon,
  });
};

export default useSettingService;
