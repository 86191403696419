import { Input, InputRef } from 'antd';
import { FormItem, useFormInstance } from 'components/Form';
import InputDOB from 'components/InputDOB';
import InputEmail from 'components/InputEmail';
import InputPhone from 'components/InputPhone';
import { ICustomerFormValues } from 'features/cashier/services/types/customer';
import React, { useEffect } from 'react';
import styled from 'styled-components';
type IFormCustomerProps = {
  value?: ICustomerFormValues;
  inputRef?: React.RefObject<InputRef>;
};
const FormCustomer: React.FC<IFormCustomerProps> = ({ value, inputRef }) => {
  const form = useFormInstance();
  useEffect(() => {
    form.setFieldsValue(value);
  }, [value]);


  return (
    <FormCustomerStyled className='FormAddCustomerStyled'>
      <FormItem name='name' label={'Full name'} rules={[{ required: true, message: 'Please input your name!' }]}>
        <Input ref={inputRef} />
      </FormItem>
      <FormItem name='phone' label={<>Phone<span className='required'>(*)</span></>} rules={[{ required: true, message: 'Please input your phone!' }]}>
        <InputPhone />
      </FormItem>
      <FormItem name='birthday' label={'DOB'}>
        <InputDOB inputReadOnly={false} />
      </FormItem>
      <InputEmail
        name={'email'} label="Email" rules={[{ type: 'email' }]}
      />
    </FormCustomerStyled>
  );
};

export default FormCustomer;
type FormCustomerStyledProps = {}
const FormCustomerStyled = styled.div<FormCustomerStyledProps>`
  .required {
    color: var(--primary-primary-active, #FF8890);
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .input-email {
    height: 3rem
  }
`;
