
import { Divider, Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib';
import Box from 'components/Box';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import { StaffWorkItem } from 'features/payment/services/types/api';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IApiModifyTipCashBody } from 'features/ticketList/services/types/api';
import { cloneDeep, last, set, sumBy } from 'lodash';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Ref = {
  open: () => void;
};

const enum ETipMethod {
  percent = 'Percent',
  even = 'Even',
  manual = 'Manual',
}

const ModalModifyTipCash = forwardRef<Ref>(({ }, ref) => {
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const details = ticketListSelectors.ticketDetailsData.data();
  const [visible, setVisible] = useState(false);
  const [method, setMethod] = useState<ETipMethod>(ETipMethod.percent);
  const [staffWorkList, setListStaffWork] = useState<StaffWorkItem[]>([]);
  const [primevalStaffWorkList, setListPrimevalStaffWork] = useState<StaffWorkItem[]>([]);
  const [modifyTip, setModifyTip] = useState<number | undefined | null>(0);
  const dispatch = useAppDispatch();

  const handleClose = () => setVisible(false);
  const totalTip = modifyTip ?? 0;
  const staffTips = sumBy(staffWorkList, o => (o.tip || 0));

  const handleApply = () => {
    const body: IApiModifyTipCashBody = {
      billId: details.billId,
      totalTip: totalTip,
      items: staffWorkList.map(o => ({ staffId: o.staffId, tip: o.tip })),
    };
    setVisible(false);
    dispatch(ticketListActions.modifyTipCash(body));
  };

  const getDisabled = () => {
    if (totalTip > details.subTotal) return true;
    if (!staffTips || staffTips > totalTip) return true;
    return false;
  };

  const sumPrice = useMemo(() => {
    return staffWorkList.reduce(
      (previousSum, item) => item.price + previousSum,
      0
    );
  }, [staffWorkList]);

  const computeTip = useCallback(() => {
    let target: StaffWorkItem[] = [];
    switch (method) {
      case ETipMethod.even: {
        let remainingTip = totalTip;

        target = staffWorkList.map((item) => {
          let staffTip = +(totalTip / staffWorkList.length).toFixed(2);

          if (remainingTip - staffTip <= 0) {
            staffTip = remainingTip;
          }

          remainingTip = +(remainingTip - staffTip).toFixed(2);

          return {
            ...item,
            tip: staffTip,
            isInput: false,
          };
        });

        if (remainingTip > 0) {
          const lastItem = last(target);
          if (lastItem)
            set(lastItem, 'tip', lastItem.tip + remainingTip);
        }

        break;
      }

      case ETipMethod.manual:
        target = staffWorkList.map((item) => {
          return {
            ...item,
            tip: 0
          };
        });
        break;
      case ETipMethod.percent: {
        let remainingTip = totalTip;
        target = staffWorkList.map((item) => {
          let staffTip = +((item.price / sumPrice) * totalTip).toFixed(2);

          if (remainingTip - staffTip <= 0) {
            staffTip = remainingTip;
          }

          remainingTip = +(remainingTip - staffTip).toFixed(2);
          return {
            ...item,
            tip: staffTip,
            isInput: false,
          };
        });
        if (remainingTip > 0) {
          const lastItem = last(target);
          if (lastItem)
            set(lastItem, 'tip', lastItem.tip + remainingTip);
        }
      }
    }
    setListStaffWork(target);
  }, [method, primevalStaffWorkList, modifyTip]);
  useEffect(() => {
    computeTip();
  }, [method, primevalStaffWorkList, modifyTip]);

  const handleChangeValue = (staffId: string, value: number) => {
    const cloneStaffWorkList = cloneDeep(staffWorkList);

    if (
      cloneStaffWorkList.filter((item) => item.isInput).length ===
      cloneStaffWorkList.length
    ) {
      for (let i = 0; i < cloneStaffWorkList.length; i++) {
        cloneStaffWorkList[i].isInput = false;
      }
    }
    const targetChange = cloneStaffWorkList.find(
      (item) => item.staffId === staffId
    );

    if (targetChange) {
      targetChange.tip = +value;
      targetChange.isInput = true;
      const sumAllInput: number = cloneStaffWorkList
        .filter((item) => item.isInput)
        .reduce((sum, item) => sum + item.tip, 0);
      const theRestTip = totalTip - sumAllInput;

      const sumPriceWithoutInput: number = cloneStaffWorkList
        .filter((item) => !item.isInput)
        .reduce((sum, item) => sum + item.price, 0);

      setListStaffWork(
        cloneStaffWorkList.map((item) => {
          if (item.staffId !== staffId && !item.isInput) {
            const tipValue = ((item.price / sumPriceWithoutInput) * theRestTip);
            return {
              ...item,
              tip: tipValue > 0 ? tipValue : 0,
            };
          }
          return item;
        })
      );
    }
  };


  const isDisableInput = useMemo(() => {
    return method !== ETipMethod.manual;
  }, [method]);

  const shareTipItem = (item: StaffWorkItem, index: number) => {
    return (
      <div className="share-tip-item-container" key={item.staffId}>
        <div className="share-tip-item">
          <div className="name-price-box">
            <Text variant="H9" className='fs-md' style={{ whiteSpace: 'nowrap' }}>{item.name}</Text>
            <Text variant="CONTENT_1" className='fs-md'>{`${formatCurrency(item.price)}`}</Text>
          </div>
          <CurrencyInputPrecision
            onChange={val => {
              handleChangeValue(item.staffId, val);
            }}
            value={item.tip?.toFixed(2)}
            disabled={isDisableInput}
          />
        </div>
        {index != staffWorkList.length - 1 && <Divider></Divider>}
      </div>
    );
  };
  const renderItem = useMemo(
    () => staffWorkList.map((item, index) => shareTipItem(item, index)),
    [staffWorkList]
  );

  const handleChangeMethod = (e: RadioChangeEvent) => {
    setMethod(e.target.value);
  };
  useImperativeHandle(ref, () => ({
    open() {
      const dataList = (details?.items || []).map(o => ({
        staffId: o.staffId,
        name: o.staffName,
        price: sumBy(o.services, a => a.price),
        tip: 0,
        isInput: false,
      }) as StaffWorkItem);
      setListStaffWork(dataList);
      setListPrimevalStaffWork(dataList);
      setVisible(true);
      setModifyTip(details?.tip || 0);
      setTimeout(() => {
        currencyRef.current?.setValue(formatCurrency(details?.tip || 0));
      }, 100);
    },
  }));

  return (
    <TipMethodSysModalStyled
      modalTitle="TIP METHOD SYSTEM"
      okTitle={'Modify'}
      closable={false}
      visible={visible}
      onClose={handleClose}
      width={'auto'}
      disableOk={getDisabled()}
      noneBodyStyle
      noneFooterStyle
      containerPadding={1}
      wrapClassName='modal-modify-tip'
      centered
      onSubmit={handleApply}
    >
      <Container>
        <div className="currency-container">
          <CurrencyKeyBoard ref={currencyRef} value={modifyTip} onChange={setModifyTip} />
        </div>
        <div className="tip-method-container">
          <Box display='flex' justifyContent='space-between' width={'100%'}>
            <div id="total-tip">
              <Text className="alignment fs-lg" variant="BODY_1">
                Total Sales:
              </Text>
              <Text variant="H9" className='fs-lg'>{`${formatCurrency(details.subTotal)}`}</Text>
            </div>
            <div id="total-tip">
              <Text className="alignment fs-lg" variant="BODY_1">
                Total Tip:
              </Text>
              <Text variant="H9" className='fs-lg'>{`${formatCurrency(totalTip)}`}</Text>
            </div>
          </Box>
          <Radio.Group onChange={handleChangeMethod} value={method}>
            <Radio className='radio-text' value={ETipMethod.percent}>Percent</Radio>
            <Radio className='radio-text' value={ETipMethod.even}>Even</Radio>
            <Radio className='radio-text' value={ETipMethod.manual}>Manual</Radio>
          </Radio.Group>
          <WrapStaffList>
            <div className="scroll-container">
              {renderItem}
            </div>
          </WrapStaffList>
        </div>
      </Container>
    </TipMethodSysModalStyled>
  );
});
ModalModifyTipCash.displayName = 'ModalModifyTipCash';
const useModalModifyTipCash = (): [React.RefObject<Ref>, JSX.Element] => {
  const ref = useRef<Ref>(null);
  const component = <ModalModifyTipCash ref={ref} />;

  return [ref, component];
};
export default useModalModifyTipCash;
const WrapStaffList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  position: relative;
  .scroll-container {
    position: absolute;
    inset: 0;
    overflow-y: auto;
    right: -16px;
    padding-right: 16px;
  }
`;
const Container = styled.div`
  display: flex;
  flex:1;
  padding: 1rem;
  .currency-container {
    width: 500px;
  }
  .tip-method-container {
    width: 700px;
    margin-left: 2rem;
  }
  .InputViewStyled {
    justify-content: center;
    input {
      width: 80%;
      flex: unset;
    }
  }
`;

const TipMethodSysModalStyled = styled(Modal)`
    #status {
        width: 100%;
        display: flex;
        justify-content: flex-start
        flex-direction: row;
    }
  #total-tip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    column-gap: 0.75rem;
    margin-bottom: 16px;
  }
  .alignment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .tip-method-container {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }
  .share-tip-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .share-tip-item .name-price-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.25rem;
    margin-right: 1rem;
  }

  .share-tip-item .ant-input {
    width: 7.625rem;
    color: var(--text-text-3, #1D2129);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &.ant-input-disabled {
      color: var(--text-text-3, #1D2129);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .share-tip-item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .share-tip-item-container .ant-divider {
    margin: 1rem 0 0.5rem 0;
  }
  
  .tip-method-container .ant-radio-group {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }

  .radio-text {
    font-size: 1.5rem;
  }

  .fs-lg {
    font-size: 2rem;
  }

  .fs-md {
    font-size: 1.5rem;
  }
  .share-tip-item .ant-input {
    font-size: 1.5rem !important;
  }
`;
