import { Col, Form, Input, Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import InputDOB from 'components/InputDOB';
import InputEmail from 'components/InputEmail';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { CustomerDetailsForm } from 'features/customer/services/types/customer';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import ButtonGroup from './ButtonGroup';
import InputPhone from 'components/InputPhone';
type IFormEditCustomerProps = {};
const FormEditCustomer: React.FC<IFormEditCustomerProps> = () => {
  const navigate = useNavigate();
  const customerDetails = customerSelectors.getCustomerDetails();
  const [form] = Form.useForm<CustomerDetailsForm>();
  const dispatch = useAppDispatch();
  const onBack = () => {
    navigate(-1);
  };

  useEffect(()=>{
    if(!customerDetails) return;
    form.setFieldsValue({
      point: customerDetails?.point || 0,
      fullName: customerDetails?.name,
      dob: customerDetails?.birthday
        ? moment(customerDetails?.birthday).format('MM-DD-YYYY')
        : null,
      phone: formatPhone(customerDetails?.phone),
      mail: customerDetails?.email,
      street: customerDetails?.street || '',
      city: customerDetails?.city || '',
      state: customerDetails?.state || '',
      zipCode: customerDetails?.zipCode || '',
    });
  },[customerDetails]);

  const onFinish = async (values: CustomerDetailsForm) => {
    const body: CustomerDetailsForm = {
      customerId: customerDetails?.id || '',
      point: values?.point || 0,
      fullName: values?.fullName || '',
      dob: values.dob ? moment(values.dob).format('MM-DD-YYYY') : '',
      phone: values?.phone || '',
      mail: values?.mail || '',
      street: values?.street || '',
      city: values?.city || '',
      state: values?.state || '',
      zipCode: values?.zipCode || '',
    };
    dispatch(customerActions.editCustomer.fetch(body));
    navigate(-1);
  };

  const defaultValues: CustomerDetailsForm = {
    point: customerDetails?.point || 0,
    fullName: customerDetails?.name,
    dob: customerDetails?.birthday
      ? moment(customerDetails?.birthday).format('MM-DD-YYYY')
      : null,
    phone: formatPhone(customerDetails?.phone),
    mail: customerDetails?.email,
    street: customerDetails?.street || '',
    city: customerDetails?.city || '',
    state: customerDetails?.state || '',
    zipCode: customerDetails?.zipCode || '',
  };

  return (
    <FormEditCustomerStyled>
      <Button ntype="DEFAULT" icon="back" onClick={onBack}>
        Back
      </Button>
      <Box mt="4">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFinish}
          initialValues={defaultValues}
        >
          <InformationStyled>
            <div className="gradient-box"></div>
            <Box>
              <Row gutter={24}>
                <Col span={12}>
                  <Form.Item
                    name="fullName"
                    label="Full Name"
                    rules={[
                      { required: true, message: 'Please input Full Name!' },
                    ]}
                  >
                    <Input placeholder="Enter your Full Name" />
                  </Form.Item>
                  <Form.Item
                    name="dob"
                    label={'DOB'}
                  >
                    <InputDOB />
                  </Form.Item>
                  <Form.Item
                    name="point"
                    label="Point"
                    rules={[{ required: true, message: 'Please input Point!' }]}
                  >
                    <Input placeholder="Enter your Point" type="number" />
                  </Form.Item>

                  <Form.Item
                    name="phone"
                    label="Phone"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone number!',
                      }
                    ]}
                  >
                    <InputPhone
                      placeholder="Enter your phone number"
                    />
                  </Form.Item>

                  <InputEmail
                    name={'mail'}
                    label="Email (Optional)"
                    rules={[
                      { type: 'email' },
                    ]}
                  />
                </Col>
                <Col span={12}>
                  <AddressStyled>
                    <AddressHeaderStyled>
                      <Text variant="H9">Address</Text>
                    </AddressHeaderStyled>
                    <Box px="3" py="3">
                      <Form.Item name="street" label="Street">
                        <Input placeholder="Enter your Street" />
                      </Form.Item>

                      <Form.Item name="city" label="City">
                        <Input placeholder="Enter your City" />
                      </Form.Item>

                      <Form.Item name="state" label="State">
                        <Input placeholder="Enter your State" />
                      </Form.Item>

                      <Form.Item name="zipCode" label="Zip Code">
                        <Input placeholder="Enter your Zip Code" />
                      </Form.Item>
                    </Box>
                  </AddressStyled>
                </Col>
              </Row>
            </Box>
            <ButtonGroup />
          </InformationStyled>
        </Form>
      </Box>
    </FormEditCustomerStyled>
  );
};

export default FormEditCustomer;
const FormEditCustomerStyled = styled.div`
  width: 100%;
`;
const InformationStyled = styled.div`
  padding: 1.5rem 4rem;
`;
const AddressStyled = styled.div`
  margin-top: 1.5rem;
  background: #f6f7fc;

  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;
const AddressHeaderStyled = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  height: 3rem;
  padding: 0 1rem;
  background-color: #adadad;
`;
