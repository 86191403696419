import { get } from 'lodash';
import moment from 'moment';

export const NAME_REDUCER = 'appointment';

export const PREFIX_ACTIONS = 'appointment_feature_';


export const PATH_LOADING = {
  getAppointments: `loading.${NAME_REDUCER}.getAppointments`,
  getTableAppointments: `loading.${NAME_REDUCER}.getTableAppointments`,
  getStaffs: `loading.${NAME_REDUCER}.getStaffs`,
  getLockBreakTimes: `loading.${NAME_REDUCER}.getLockBreakTimes`,
  initServices: `loading.${NAME_REDUCER}.initServices`,
  listStaffsOffLoading: `loading.${NAME_REDUCER}.listStaffsOffLoading`,
};

export const CALENDAR_DISTANCE_TIMELINE = {
  ONE_HOUR: 60,
  HAlF_HOUR: 30,
  QUARTER_HOUR: 15,
};

export enum CALENDAR_VIEW_TYPE {
  DAY_VIEW = 'DAY_VIEW',
  WEEK_VIEW = 'WEEK_VIEW',
  TWO_WEEKS = '2_WEEKS',
  MONTH_VIEW = 'MONTH_VIEW',
}

export enum STAFF_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}
export enum STAFF_TYPE {
  ADMIN = 'ADMIN',
  OTHER = 'OTHER',
  TECHNICAL = 'TECHNICAL',
  CASHIER = 'CASHIER',
}

export enum APPOINTMENT_LAYOUT {
  CALENDAR = 'CALENDAR',
  LIST = 'LIST',
}

export enum CALENDAR_TIME_TYPE {
  BLOCK = 'BLOCK',
  BREAK = 'BREAK',
}

export enum CALENDAR_ITEM_TYPES {
  APPOINTMENT = 'APPOINTMENT',
  // MULTIPLE_APPOINTMENT = 'MULTIPLE_APPOINTMENT',
  APPOINTMENT_CANCEL = 'APPOINTMENT_CANCEL',
  // APPOINTMENT_NO_SHOW = 'APPOINTMENT_NO_SHOW',
  BREAK_STAFF = 'BREAK_STAFF',
  BLOCK_HOURS = 'BLOCK_HOURS',
}

export const daysOfWeek = Array.from(Array(7).keys()).map(o => {
  const day = moment().set('day', o + 1);
  return ({
    label: day.format('dddd'),
    value: day.isoWeekday(),
  });
});

export enum APPOINTMENT_SOURCE_TYPE {
  ONLINE = 'ONLINE',
  POS = 'CALL_IN',
  APP = 'APP'
}

export const CALENDAR_TIME_SET = {
  open: moment().set({ hour: 8, minute: 0, second: 0 }),
  close: moment().set({ hour: 22, minute: 0, second: 0 }),
};

export enum PROLONGED_TIME_STATUS {
  ADD = 'ADD',
  MINUS = 'MINUS',
}

export enum APPOINTMENT_STATUS {
  NEW = 0,
  CONFIRM = 1,
  CANCEL = 2,
  NO_SHOW = 3,
  DELETE = 4,
}

export const AllAppointmentStatus = [
  APPOINTMENT_STATUS.NEW,
  APPOINTMENT_STATUS.CONFIRM,
  APPOINTMENT_STATUS.CANCEL,
  APPOINTMENT_STATUS.NO_SHOW,
];

export const appointmentStatusObject = {
  [APPOINTMENT_STATUS.NEW]: 'New',
  [APPOINTMENT_STATUS.CONFIRM]: 'Confirmed',
  [APPOINTMENT_STATUS.CANCEL]: 'Cancel',
  [APPOINTMENT_STATUS.NO_SHOW]: 'No show',
  [APPOINTMENT_STATUS.DELETE]: 'Delete',
};

// #CHANGE_BG_APPOINTMENT
export const getAptStatusTheme = (status?: APPOINTMENT_STATUS) => {
  switch (status) {
    case APPOINTMENT_STATUS.NO_SHOW:
      return ({
        fill: '#F6F7FC',
        stroke: '#E5E5E5',
      });
    case APPOINTMENT_STATUS.DELETE:
    case APPOINTMENT_STATUS.CANCEL:
      return ({
        fill: '#FFE5E7',
        stroke: '#FF8890',
      });
    case APPOINTMENT_STATUS.CONFIRM:
      return ({
        fill: '#BCEBCB',
        stroke: '#87D68D',
      });
    case APPOINTMENT_STATUS.NEW:
    default:
      return ({
        fill: '#C6E9EF',
        stroke: '#6FABB6',
      });
  }
};
export const listCalendarItemTypes = [
  {
    label: 'Appointment',
    value: APPOINTMENT_STATUS.NEW,
    fill: '#C6E9EF',
    stroke: '#6FABB6',
  },
  {
    label: 'Confirm',
    value: APPOINTMENT_STATUS.CONFIRM,
    fill: '#BCEBCB',
    stroke: '#87D68D',
  },
  {
    label: 'Cancel',
    value: APPOINTMENT_STATUS.CANCEL,
    fill: '#FFE5E7',
    stroke: '#FF8890',
  },
  {
    label: 'No show',
    value: APPOINTMENT_STATUS.NO_SHOW,
    fill: '#F6F7FC',
    stroke: '#E5E5E5',
  },
  {
    label: 'On Break',
    value: CALENDAR_ITEM_TYPES.BREAK_STAFF,
    fill: '#FEEDBF',
    stroke: '#FFDC7C',

  },
  {
    label: 'Block Hours',
    value: CALENDAR_ITEM_TYPES.BLOCK_HOURS,
    fill: '#ADADAD',
    stroke: '#ADADAD',
  },
];

export const appointmentStatusItemTypes = AllAppointmentStatus.map(o => {
  const theme = getAptStatusTheme(o);
  return ({
    label: get({
      [APPOINTMENT_STATUS.NEW]: 'Appointment',
      [APPOINTMENT_STATUS.CONFIRM]: 'Confirmed',
      [APPOINTMENT_STATUS.CANCEL]: 'Cancel',
      [APPOINTMENT_STATUS.NO_SHOW]: 'No show',
    }, [o], '') || '',
    value: o,
    fill: theme.fill,
    stroke: theme.stroke,
  });
});


export const listAppointmentStatus = Object.entries(appointmentStatusObject).map(([value, label]) => ({
  label,
  value
}));

