import { Button, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IApiGetTicketsParam } from 'features/ticketList/services/types/api';
import { ICreditCardFilterFunction } from 'features/ticketList/services/types/creditCard';
import { remove } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import HeaderTickets from './components/HeaderTickets';
import SummaryCreditCards from './components/SummaryCreditCards';
import TabTicketByParams from './components/Tabs/TabTicketByParams';
import ListTicketPageStyled from './styles';
import shopSelectors from 'services/shop/selectors';
import TicketFilterV2 from './components/TabsV2/Filter';
type IListTicketPageProps = any;
const CreditCardPage: React.FC<IListTicketPageProps> = () => {
  const versionUI = shopSelectors.data.versionUI();
  const v2 = versionUI === 'V2';
  const dispatch = useAppDispatch();
  const adjustTipStore = ticketListSelectors.adjustTip();
  const params = ticketListSelectors.ticketsTableData.params();
  useEffect(() => {
    dispatch(ticketListActions.doInit());
    const _params: IApiGetTicketsParam = {
      status: ['CLOSED', 'REFUND', 'VOID'],
      page: 1,
      size: 30,
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
      paymentType: PAYMENT_TYPE.CREDIT_CARD,
      staffId: '',
    };
    dispatch(ticketListActions.setVisibleCusPhone(true));
    dispatch(ticketListActions.getSummaryCreditCards.fetch({ startDate: params.startDate || '', endDate: params.endDate || '' }));
    dispatch(ticketListActions.setTicketsParams(_params));
  }, []);

  const disableAdjustTip = useMemo(() => {
    return !adjustTipStore.some(o => !!o.tip);
  }, [adjustTipStore]);

  const creditCardFilter: ICreditCardFilterFunction = ({ endDate, startDate }) => {
    dispatch(ticketListActions.getSummaryCreditCards.fetch({ startDate, endDate }));
  };

  const handleParentButtonClick = () => {
    // call action in store list
    dispatch(ticketListActions.adjustTip.fetch({}));
  };

  const handleFilterStatus = (_status: TicketStatus) => () => {
    const statusArr = [...params.status || []];
    if (statusArr?.indexOf(_status) !== -1) {
      remove(statusArr, o => o === _status);
    } else {
      // @ts-ignore
      statusArr.push(_status);
    }
    const _params: IApiGetTicketsParam = {
      // @ts-ignore
      status: statusArr,
      page: 1,
    };
    dispatch(ticketListActions.setTicketsParams(_params));
  };

  return (
    <>
      <ListTicketPageStyled>
        <Sidebar />
        <BodyStyled>
          <Row align={'middle'} style={{ gap: '0.5rem', marginBottom: '1rem', marginTop: v2 ? 16 : 0 }}>
            <Box mr="2">
              <Text variant="H5">Credit Cards</Text>
            </Box>
            <div style={{ flex: 1 }} />
            {v2 ? <TicketFilterV2 type='CLOSED' isCreditPage creditCardFilter={creditCardFilter} /> : <HeaderTickets isCreditPage creditCardFilter={creditCardFilter} />}
            <AdjustButtonStyled
              icon={<Icon type="adjustTipAll" />}
              onClick={handleParentButtonClick}
              disabled={disableAdjustTip}
              className={v2 ? 'v2' : ''}
            >
              Adjust Tip All
            </AdjustButtonStyled>
          </Row>
          <Row style={{ width: '100%', gap: '10%', marginBottom: '1rem' }} justify={'space-between'} align={'bottom'}>
            <SummaryCreditCards v2={v2} />
          </Row>
          <Row style={{ gap: '0.5rem', margin: '0', marginBottom: '0.5rem' }} justify={'end'}>
            <TicketStatusCheckBox
              status='CLOSED'
              selected={params.status?.includes('CLOSED')}
              onClick={handleFilterStatus('CLOSED')}
            />
            <TicketStatusCheckBox
              status='VOID'
              selected={params.status?.includes('VOID')}
              onClick={handleFilterStatus('VOID')}
            />
            <TicketStatusCheckBox
              status='REFUND'
              selected={params.status?.includes('REFUND')}
              onClick={handleFilterStatus('REFUND')}
            />
          </Row>
          <div className="container-ticket">
            <TabTicketByParams v2={v2} tableKey={'CLOSED'} isCreditPage />
          </div>
        </BodyStyled>
      </ListTicketPageStyled>
    </>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  .circle-status {
    width: 25px;
     height: 25px; 
     background: #DDDDDD; 
     border-radius: 5px; 
     border: 1px solid #777D7C; 
     margin-right: 4px
  }

  .container-ticket {
    flex: 1;
    position: relative;
    margin-bottom: 12px;
  }
`;

export default CreditCardPage;

const AdjustButtonStyled = styled(Button)`
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  gap: 0.175rem;
  padding: 0 1rem;
  background-color: #6fabb6;
  border-color: #6fabb6 !important;
  box-shadow: -1px 1px 4px 0px #0000001a inset, 0px 4px 4px 0px #00000040;
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: white !important;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.3s; // smooth transition for the hover effect
  }
  &:disabled {
    background-color: #6fabb6 !important;
    border-color: #6fabb6!important;
    opacity: 0.7;
  }

  &.v2 {
    border-radius: 4px;
    background: #C6E9EF;
    border: none !important;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
    span {
      color: #1D2129;
    }
    svg path {
      fill: #1D2129;
    }

    &:disabled {
      background-color: #C6E9EF !important;
      border-color: none !important;
      opacity: 0.7;
    }
  }
`;

type TicketStatus = 'CLOSED' | 'REFUND' | 'VOID';

const getColors = (status: TicketStatus) => {
  switch (status) {
    case 'CLOSED': return '#FFF';
    case 'VOID': return '#feedbf6e';
    case 'REFUND': return '#acc3c854';
    default: return '#FFF';
  }
};
const getLabel = (status: TicketStatus) => {
  switch (status) {
    case 'CLOSED': return ' Closed';
    case 'VOID': return ' Voided';
    case 'REFUND': return ' Refunded';
    default: return '';
  }
};
type ITicketStatusCheckBoxProps = {
  status: TicketStatus;
  selected?: boolean;
  onClick?: () => void;
};
const TicketStatusCheckBox = ({ status, selected, onClick }: ITicketStatusCheckBoxProps) => {
  return (
    <button onClick={onClick}>
      <Row align={'middle'}>
        <Row align={'middle'} justify={'center'} className='circle-status' style={{ background: getColors(status), }} >
          {selected ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
            <path d="M6.35192 11.7369C6.03176 11.7369 5.72761 11.6088 5.5035 11.3847L0.973172 6.85445C0.508943 6.39022 0.508943 5.62184 0.973172 5.15761C1.4374 4.69338 2.20578 4.69338 2.67001 5.15761L6.35192 8.83943L14.58 0.611356C15.0442 0.147126 15.8126 0.147126 16.2768 0.611356C16.7411 1.07559 16.7411 1.84397 16.2768 2.30819L7.20034 11.3847C6.97622 11.6088 6.67207 11.7369 6.35192 11.7369Z" fill="#1D2129" />
          </svg> : null}
        </Row>
        <Text variant='CONTENT_2' color='text_3'>{getLabel(status)}</Text>
      </Row>
    </button>
  );
};

