import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { Popover } from 'antd';
import classNames from 'classnames';
import Text from 'components/Text';
import appointmentActions from 'features/appointment/services/actions';
import { ICalendarDndData, ICalendarHeader } from 'features/appointment/services/types/calendar';
import moment from 'moment';
import React, { CSSProperties, memo, useMemo, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { calendarElement } from '../../ZoomConfig';
import { quickBookingRef } from '../Modal/QuickBooking';

interface Props {
  children?: React.ReactNode;
  dragging?: boolean;
  id: UniqueIdentifier;
  className?: string,
  data: ICalendarDndData;
  distance?: number;
  rowSpan?: number;
  disable?: boolean;
  blockDrop?: boolean;
  headerData?: ICalendarHeader;
}

const overlayInnerStyle: CSSProperties = { borderRadius: 0, boxShadow: 'none', padding: 0, background: 'transparent' };

const Droppable = ({ id, dragging, className, data, distance = 0, rowSpan = 1, children, disable, blockDrop = false, headerData }: Props) => {
  const [open, setOpen] = useState(false);
  const ChildTimes = useMemo(() => {
    const [rowTime = '', col_id = ''] = id?.toString().split('/') ?? [];
    const [hour = 0, minute = 0] = rowTime?.split(':') ?? [];
    const date = data.colData.rowTime?.clone().set({
      hour: +hour,
      minute: +minute,
    });

    const result: ICalendarDndData[] = [];
    Array.from(Array(rowSpan).keys()).forEach(() => {
      result.push(
        {
          id: date?.format('HH:mm') + '/' + col_id,
          rowId: date?.format('HH:mm'),
          colData: data.colData,
          rowTime: date?.clone(),
          blockDrop,
        }
      );
      date?.add(15, 'minute');
    });
    return result.map(o => <DropChild id={o?.id ?? ''} key={o.id} data={o} dragging={!!dragging} />);
  }, [distance, rowSpan, data, id, blockDrop]);

  const onDoubleClick = () => {
    if (headerData?.modal) return;
    if (disable) return;
    if (data?.colData?.rowTime?.isBefore(moment(), 'date')) return;
    if (children) return;
    setOpen(true);
  };

  return (
    <DroppableStyled
      className={className}
      aria-label="Droppable region"
      active={open}
    >
      <>
        <div className='children'
          onDoubleClick={onDoubleClick}
        // onTouchEnd={onDoubleClick}
        >{children}</div>
        <div className='absolute'>
          {ChildTimes}
        </div>
        {!disable && <PopoverContent
          open={open}
          setOpen={setOpen}
          data={data}
        />}
      </>
    </DroppableStyled>
  );
};


export default memo(Droppable);

type PopoverContentProps = {
  open: boolean;
  setOpen: (val: boolean) => void;
  data: ICalendarDndData;
};
const PopoverContent = ({ open, setOpen, data }: PopoverContentProps) => {
  const dispatch = useAppDispatch();
  const closeDropDown = () => setOpen(false);

  const onPressAction = (type: 'new-appointment' | 'block-hour' | 'break-time' | 'quick-booking') => () => {
    closeDropDown();
    const payload = {
      colTime: data.colData.rowTime.format(),
      staff: data.colData.headerData.type === 'staff' ? {
        id: data.colData.headerData.id ?? '',
        name: data.colData.headerData.label ?? '',
      } : null,
    };
    switch (type) {
      case 'new-appointment':
        dispatch(appointmentActions.setNewAppointmentDraftData(payload));
        break;
      case 'block-hour':
        dispatch(appointmentActions.setNewBlockHourDraftData(payload));
        break;
      case 'break-time':
        dispatch(appointmentActions.setNewBreakTimeDraftData(payload));
        break;
      case 'quick-booking':
        quickBookingRef.current?.open(payload.colTime, payload.staff?.id || '');
        break;
      default:
        break;
    }
  };

  const content = useMemo(() => {
    return (
      <PopoverContentStyled>
        <button className='box' onClick={onPressAction('block-hour')}>
          <Text variant='CONTENT_1' color='text_3' >Block Hours</Text>
        </button>
        <button className='box' onClick={onPressAction('break-time')}>
          <Text variant='CONTENT_1' color='text_3' >Break Time</Text>
        </button>
        <button className='box' onClick={onPressAction('new-appointment')}>
          <Text variant='CONTENT_1' color='text_3' >New Appointment</Text>
        </button>
        <button className='box' onClick={onPressAction('quick-booking')}>
          <Text variant='CONTENT_1' color='text_3' >Quick Booking</Text>
        </button>
      </PopoverContentStyled>
    );
  }, [data]);

  if (!open) return null;

  return (
    <>
      <Popover
        open={open}
        onOpenChange={setOpen}
        arrow={false}
        placement="right"
        content={content}
        trigger="click"
        align={{ offset: ['50%'] }}
        overlayInnerStyle={overlayInnerStyle}
        // @ts-ignore
        getPopupContainer={() => calendarElement.value}
      />
    </>
  );
};

PopoverContent.displayName = 'PopoverContent';
const PopoverContentStyled = styled.div`
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-0, #FFF);
  width: 15rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  /* box */
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .box {
    padding: 0.5rem 1rem;
    border-radius: 4px;
    text-align: left;
    &:hover {
      background:rgba(201, 211, 219, 0.7)
    }
  }
`;


const DropChild = ({ id, dragging, data, children }: { id: string, data: ICalendarDndData, dragging: boolean; children?: React.ReactNode }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data
  });
  return (
    <DropChildStyled
      ref={setNodeRef}
      key={id}
      className={classNames(
        isOver && 'over',
        dragging && 'dragging',
        children && 'dropped',
      )}
      aria-label="Droppable region"
    >{children}
    </DropChildStyled>
  );
};

const DropChildStyled = styled.div`
  flex: 1;
  border: 0.5px solid var(--grey-grey-300, #EBEBEB);
  &.dragging {
    > svg {
      opacity: 0.8;
    }
  }
  &.over {
    box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
    &.dropped {
      box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,
        rgba(201, 211, 219, 0.5) 20px 14px 24px;
    }
  }
  &.dropped {
    > svg {
      opacity: 0.2;
      transform: translate3d(-50%, 100%, 0) scale(0.8);
    }
  }
`;
type DroppableStyledProps = { active?: boolean };
const DroppableStyled = styled.div<DroppableStyledProps>`
  position: absolute;
  z-index: 1;
  inset: 0;
  .children {
    height:100%;
    width:100%;
    position: relative;
    z-index:99;
    
  }

  ${({ active }) => active ? `
  box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
  `: ''}

  &.dragging {
    > svg {
      opacity: 0.8;
    }
  }
  &.over {
    box-shadow: inset #1eb99d 0 0 0 3px, rgba(201, 211, 219, 0.5) 20px 14px 24px;
    &.dropped {
      box-shadow: inset rgba(201, 211, 219, 0.7) 0 0 0 3px,
        rgba(201, 211, 219, 0.5) 20px 14px 24px;
    }
  }
  &.dropped {
    > svg {
      opacity: 0.2;
      transform: translate3d(-50%, 100%, 0) scale(0.8);
    }
  }
  .absolute {
    position: absolute;
    inset:0;
    display: flex;
    flex-direction: column;
  }
`;