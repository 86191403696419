import { Col, Layout, Row } from 'antd';
import TicketWaitingList from 'features/user/widgets/WaitingList/Ticket';
import WalkInWaitingList from 'features/user/widgets/WaitingList/WalkIn';
import React, { useState } from 'react';
import styled from 'styled-components';
import GroupLayoutButton from './GroupLayoutButton';
import Header from './Header';
import StaffAvailable from './StaffAvailable';
import Summary from './Summary';
import shopSelectors from 'services/shop/selectors';
import HomeMainV2 from './MainV2';


type IMainProps = {};
const Main: React.FC<IMainProps> = () => {
  const versionUI = shopSelectors.data.versionUI();
  const [model, setModel] = useState<'staff' | 'ticket' | 'default'>('default');
  if (versionUI === 'V2') return <HomeMainV2 model={model} setModel={setModel} />;
  return (
    <MainStyled>
      <Header />
      <Layout.Content>
        <Layout hasSider className='container' style={{ overflow: 'hidden' }}>
          <Layout.Sider translate='no' collapsed={model === 'ticket'} reverseArrow width={model === 'default' ? '50%' : '100%'} collapsedWidth={0} className='ant-content-overflow sider' style={{ background: '#E5E5E5' }}>
            <StaffAvailable mode={model} />
          </Layout.Sider>
          {model === 'default' && <GroupLayoutButton mode={model} setModel={setModel} />}
          <Layout.Sider collapsed={model === 'staff'} translate='yes' reverseArrow={false} width={model === 'default' ? '50%' : '100%'} collapsedWidth={-1} className='sider'>
            <Summary />
            <div className='wrap-waiting-ticket'>
              <Row className='wrap-tickets-container' style={{ margin: 0 }}>
                <Col span={model === 'ticket' ? 12 : 10} className='waiting-ticket'>
                  <WalkInWaitingList mode={model} />
                </Col>
                <Col span={model === 'ticket' ? 12 : 14} className='waiting-ticket'>
                  <TicketWaitingList mode={model} />
                </Col>
              </Row>
            </div>
          </Layout.Sider>
        </Layout>
      </Layout.Content>
      {model !== 'default' && <GroupLayoutButton mode={model} setModel={setModel} />}
    </MainStyled>
  );
};

export default Main;
const MainStyled = styled(Layout)`
border-left: 1px solid black;
  .container {
    height:100%;
    background: #fff;
  }
  .ant-content-overflow {
    max-height: 100%;
  }
  .children-container {
    padding: 1.125rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .sider {
    background:#fff;
    min-height: 40vw;
    transition: all 0.5s, background 0s;
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  .footer {
    position: sticky;
    bottom: 0;
    background:red;
  }
  .wrap-waiting-ticket {
    flex: 1;
    display: flex;
    .wrap-tickets-container {
      flex: 1;
    }
  }
  .waiting-ticket {
    display: flex;
    flex:1;
    min-height:50vh;
    gap:14px;
    position: relative;
    .divider {
      width:1px;
      background: #86909C;
    }
  }
`;

