import { Dropdown } from 'antd';
import { MenuProps } from 'antd/lib';
import Icon from 'assets/Icons';
import { TURN_SORT_BY } from 'features/turn/services/constants';
import { turnManualActions } from 'features/turn/services/reducers';
import { turnManualSelectors } from 'features/turn/services/selectors';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const TurnSort = () => {
  const allSetting = shopSelectors.data.allSetting();
  const dispatch = useAppDispatch();
  const sort = turnManualSelectors.sort();
  const setSort = (value: string) => dispatch(turnManualActions.setSort(value as TURN_SORT_BY));
  const onClickMenuItem = (key: string) => {
    if (sort == key) {
      return;
    }
    setSort(key);
  };

  const filterItems: MenuProps['items'] = [
    {
      key: TURN_SORT_BY.CLOCK_IN,
      label: (<a onClick={(e) => {
        e.preventDefault();
        onClickMenuItem(TURN_SORT_BY.CLOCK_IN);
      }}>Clock In</a>),
    },
    {
      key: TURN_SORT_BY.TURN,
      label: (<a onClick={(e) => {
        e.preventDefault();
        onClickMenuItem(TURN_SORT_BY.TURN);
      }}>Turn</a>),
    },
  ];

  return (
    <Dropdown
      menu={{
        items: filterItems,
        selectable: true,
        selectedKeys: sort ? [sort] : [],
      }}
      trigger={['click']}
    >
      <a onClick={(e) => {
        e.preventDefault();
      }}>
        <Filter style={{ width: allSetting?.voiceCallStaff ? '240px' : '165px' }}><Icon type='filter' fill={'#f5767f'} /><span style={{ color: '#f5767f' }}>Turn By</span></Filter>
      </a>
    </Dropdown>
  );
};

export default TurnSort;

const Filter = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    span {
       padding-left: 4px;
    }
`;
