import { Form, Input } from 'antd';
import Modal from 'components/Modal';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import TurnAmount from './TurnAmount';
type Props = {
  onAddNew: (value: { itemName: string, turn: number }) => void;
};
type Ref = {
  open: (turn: number) => void;
  close: () => void;
};
export const useModalServiceTurnAddNewRef = () => useRef<Ref>(null);
const ModalServiceTurnAddNew = forwardRef<Ref, Props>(({ onAddNew }, ref) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const handleOpen: Ref['open'] = (turn) => {
    setOpen(true);
    form.setFieldsValue({
      itemName: '',
      turn,
    });
  };

  const handleClose: Ref['close'] = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleFinish = async (values: any) => {
    onAddNew(values);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      width={900}
      visible={open} onClose={handleClose}
      modalTitle='ADD NEW'
      headerBgColor='#FFEAF6'
      noneBodyStyle noneFooterStyle
      footer={null}
    >
      <Container form={form} onFinish={handleFinish}>
        <Content>
          <div className="content-item">
            <div className="title-section">NAME</div>
            <Form.Item noStyle name={'itemName'}>
              <Input />
            </Form.Item>
          </div>
          <div className="content-item">
            <div className="title-section">TURN</div>
            <Form.Item noStyle name={'turn'}>
              <TurnAmount />
            </Form.Item>
          </div>
        </Content>
        <Footer>
          <button onClick={handleClose} type='button' className="btn"><span>Close</span></button>
          <button onClick={() => form.submit()} type='button' className="btn btn-submit"><span>Add</span></button>
        </Footer>
      </Container>
    </Modal>
  );
});
ModalServiceTurnAddNew.displayName = 'ModalServiceTurnAddNew';
export default ModalServiceTurnAddNew;

const Container = styled(Form)`
display: flex;
padding-top: 12px;
flex-direction: column;
align-items: flex-start;
gap: 40px;
align-self: stretch;
background: #FFF;

`;

const Content = styled.div`
display: flex;
padding: 0px 24px;
flex-direction: column;
align-items: center;
gap: 32px;
align-self: stretch;
.content-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 12px;
  .title-section {
    color: #505050;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
}
`;

const Footer = styled.div`
display: flex;
padding: 24px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.25);
button.btn {
  display: flex;
  height: 56px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #86909C;
  background: #E5E6EB;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: uppercase;
  }

  &.btn-submit {
    border-radius: 5px;
    background: #C52884;
    border: 1px solid #C52884;
    span {
      color: #fff;
    }
  }
}
`;
