import { Tabs, TabsProps } from 'antd';
import Box from 'components/Box';
import Sidebar from 'components/layout/Sidebar';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ClockReport from './ClockReport';
import EmployeeSalary from './EmployeeSalary';
import SalonReport from './SalonReport';
import ServicesReport from './ServicesReport';
import ReportPageStyled from './styles';
import ReviewReport from './ReviewReport';
import shopSelectors from 'services/shop/selectors';
import income_icon from './icons/income.svg';
import income_active_icon from './icons/income-active.svg';
import service_icon from './icons/service.svg';
import service_active_icon from './icons/service-active.svg';
import payroll_icon from './icons/payroll.svg';
import payroll_active_icon from './icons/payroll-active.svg';
import review_icon from './icons/review.svg';
import review_active_icon from './icons/review-active.svg';
import bg_tab_icon from './icons/bg-tab.svg';
import bg_tab_active_icon from './icons/bg-tab-active.svg';
import { get } from 'lodash';

type IReportPageProps = any;
const items: TabsProps['items'] = [
  {
    key: 'salon-report',
    label: <Box pl="4">INCOME REPORTS</Box>,
    children: <SalonReport />,
  },
  {
    key: 'services-report',
    label: 'Services Reports',
    children: <ServicesReport />,
  },
  {
    key: 'employee-salary',
    label: 'PAYROLL REPORTS',
    children: <EmployeeSalary />,
  },
  {
    key: 'clock-report',
    label: 'TIME CLOCK REPORTS',
    children: <ClockReport />,
  },
  {
    key: 'review-report',
    label: 'REVIEW REPORTS',
    children: <ReviewReport />,
  }
];
const renderTabBarV2: TabsProps['renderTabBar'] = () => <></>;
const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
  <StickyTabStyled>
    <DefaultTabBar {...props} className='tab-no-margin' />
  </StickyTabStyled>
);

const ReportPage: React.FC<IReportPageProps> = () => {
  const navigate = useNavigate();
  const versionUI = shopSelectors.data.versionUI();
  const v2 = versionUI === 'V2';
  const { tab = 'salon-report' } = useParams<{ tab: string }>();
  const onChange = (key: string) => navigate(`/store/${storage.shop_id.get()}/report/${key}`);

  return (
    <ReportPageStyled>
      <Sidebar />
      <BodyStyled className={v2 ? 'v2' : ''}>
        {v2 && <TabV2Container>
          <TabV2 first zIndex={5} tab={tab} onChange={onChange} type='salon-report' />
          <TabV2 zIndex={4} tab={tab} onChange={onChange} type='services-report' />
          <TabV2 zIndex={3} tab={tab} onChange={onChange} type='employee-salary' />
          <TabV2 zIndex={2} tab={tab} onChange={onChange} type='clock-report' />
          <TabV2 zIndex={1} tab={tab} onChange={onChange} type='review-report' />
        </TabV2Container>}
        <Tabs
          activeKey={tab}
          renderTabBar={v2 ? renderTabBarV2 : renderTabBar}
          items={items}
          onChange={onChange}
          defaultActiveKey='salon-report'
          style={{ fontWeight: 600, textTransform: 'uppercase', fontSize: 18 }}
        />
      </BodyStyled>
    </ReportPageStyled>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: #E5E5E5;
  &.v2 {
    background-color: #F5F5F5;
    .title-page {
      color: #1D2129;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  
  }
`;
const StickyTabStyled = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  .ant-tabs-nav {
    margin: 0 !important;
  }
`;

export default ReportPage;
const tabConfigs = {
  ['salon-report']: {
    label: 'INCOME REPORTS',
    icon: income_icon,
    icon_active: income_active_icon,
  },
  ['services-report']: {
    label: 'Services Reports',
    icon: service_icon,
    icon_active: service_active_icon,
  },
  ['employee-salary']: {
    label: 'PAYROLL REPORTS',
    icon: payroll_icon,
    icon_active: payroll_active_icon,
  },
  ['clock-report']: {
    label: 'TIME CLOCK REPORTS',
    icon: payroll_icon,
    icon_active: payroll_active_icon,
  },
  ['review-report']: {
    label: 'REVIEW REPORTS',
    icon: review_icon,
    icon_active: review_active_icon,
  },
};
const TabV2Container = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  zoom: 1.2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
`;
type TabV2Props = {
  type: 'salon-report' | 'services-report' | 'employee-salary' | 'clock-report' | 'review-report',
  tab: string;
  onChange: (tab: string) => void;
  zIndex?: number;
  first?: boolean;
};
const TabV2 = ({ first, zIndex, type, tab, onChange }: TabV2Props) => {
  const config = get(tabConfigs, type || '');
  const active = tab === type;
  if (!config) return null;

  return <TabV2Styled style={{ zIndex, marginLeft: first ? 0 : '-50px' }} type='button' onClick={() => onChange(type)} className={active ? 'active' : ''}>
    <img src={active ? bg_tab_active_icon : bg_tab_icon} alt="bg_tab_icon" className='svg-bg' style={{ width: '100%' }} />
    <div className="tab-item-container">
      <img src={active ? config.icon_active : config.icon} style={{ height: 32, width: 32 }} />
      <span>{config.label}</span>
    </div>
  </TabV2Styled>;
};
const TabV2Styled = styled.button`
display: flex;
height: 56px;
align-items: end;
gap: 16px;
border-radius: 24px 24px 0px 0px;
position: relative;
width: 320px;
margin-left: -50px;
span {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.tab-item-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  z-index: 2;
  position: relative;
  padding-bottom: 6px;
  zoom: 0.9;
}
.svg-bg {
  position: absolute;
  left: 0;
  bottom: 0px;
  right: 0;
  width: 100%;
  // filter: drop-shadow(4px 0px 8px rgba(0, 0, 0, 0.15)) drop-shadow(-4px 0px 8px rgba(0, 0, 0, 0.15));
}

&.active {
  span {
    color: #FFF;
  }
}
`;