import React from 'react';
import CardTicketItem from './CardTicketItem';
import colorTheme from 'constants/color';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';
import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
type Props = {
  v2?: boolean;
};
function Services({ v2 }: Props) {
  const dispatch = useAppDispatch();
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  const setFeatureProduct = () => {
    dispatch(cashierActions.setFeatureProductMode(FEATURE_PRODUCT_MODE.SERVICE));
  };

  const active = featureProductMode === FEATURE_PRODUCT_MODE.SERVICE;

  if (v2) {
    return (
      <CardTicketItem
        icon={active ? 'cardServiceIconV2Active' : 'cardServiceIconV2'}
        content={'Service'}
        v2
        nbgcolor={'#808836'}
        border_color={'#808836'}
        border_color2={'#808836'}
        active={active}
        onClick={setFeatureProduct}
      />
    );
  }

  return (
    <CardTicketItem
      icon={active ? 'cardServiceIconActive' : 'cardServiceIcon'}
      content={'Services'}
      nbgcolor={colorTheme.primary_bg}
      textColor={colorTheme.text_3}
      border_color={colorTheme.error_3}
      active={active}
      onClick={setFeatureProduct}
    />
  );
}

export default Services;