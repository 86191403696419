import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import React from 'react';
import { ISimpleMenuItem, ISimpleMenuServiceItem } from 'services/shop/types/simpleMenu';
import styled from 'styled-components';
type Props = {
  data: ISimpleMenuItem;
  serviceIdsSelected: string[];
  onClickService: (serviceItem: ISimpleMenuServiceItem) => void;
  onRemoveService: (serviceItem: ISimpleMenuServiceItem) => void;
};
const CategoryItem = ({ data, serviceIdsSelected, onClickService, onRemoveService }: Props) => {
  const selected = data?.services?.some(o => !!serviceIdsSelected.find(s => s === o.id));
  return (
    <CategoryItemStyled className='prevent-select'>
      <div className="card-header">
        <IconCate />
        <span>{data.categoryName || '--'}</span>
        {!!selected && <Icon type="tickSM" />}
      </div>
      <div className='card-services'>
        {data.services.map((o) =>
          <div key={o.id} className='service-item'>
            <ServiceItem
              data={o}
              onClickAddService={() => onClickService(o)}
              count={serviceIdsSelected.filter(s => s === o.id)?.length || 0}
              onRemoveService={() => onRemoveService(o)}
            />
          </div>)}
      </div>
    </CategoryItemStyled>
  );
};

export default CategoryItem;

const CategoryItemStyled = styled.div`
  .card-header {
    display: flex;
    align-items: center;
    gap: 4px;
    display: flex;
    padding: 8px 12px;
    align-items: center;
    gap: 4px;
    border-radius: 2px;
    border: 1px solid #86909C;
    background: #E5E5E5;
    span {
      color: #555;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
  .card-services {
    gap: 8px;
    .service-item {
    }
    padding-bottom: 8px;
  }
`;
type IServiceProps = {
  data: ISimpleMenuServiceItem;
  count?: number;
  onRemoveService: () => void;
  onClickAddService: () => void;
};
const ServiceItem = ({ data, count, onRemoveService, onClickAddService }: IServiceProps) => {
  return (
    <ServiceItemStyled className='prevent-select'>
      <div className='service-name-group' onClick={onClickAddService}>
        <div className='service-name'>{data.serviceName}</div>
        {!!count && <div className='service-count'><span>{count}</span></div>}
      </div>
      <Box display='flex' alignItems='center' gap={'3'}>
        <Button ntype='DEFAULT' height={2.5} width={'2.5rem'} className='service-icon' onClick={onClickAddService}>
          <div className='service-icon'><span>+</span></div>
        </Button>
        <Button ntype='DEFAULT' height={2.5} width={'2.5rem'} className='service-icon' onClick={e => [e.stopPropagation(), onRemoveService()]}>
          <span>-</span>
        </Button>
      </Box>
    </ServiceItemStyled>
  );
};
const ServiceItemStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  min-height: 45px;
  &:hover {
    cursor: pointer;
  }
  .service-icon {
    display: flex;
    width: 40px;
    height: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: none;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #86909C;
    background: #E5E5E5;
    span {
      color: #86909C;
      font-family: "Open Sans";
      font-size: 30px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .service-count {
    display: flex;
    min-width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #FF8890;
    span {
      color: #FFF;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .service-name-group {
    display: flex;
    flex: 1;
    gap: 4px;
    align-items: center;
    text-align: left;
  }
  .service-name {
    color: #1D2129;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.36px;
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const IconCate = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="9" viewBox="0 0 8 9" fill="none">
    <rect x="1" y="1.5" width="2" height="2" fill="#1D2129" />
    <rect x="1" y="5.5" width="2" height="2" fill="#1D2129" />
    <rect x="5" y="1.5" width="2" height="2" fill="#1D2129" />
    <rect x="5" y="5.5" width="2" height="2" fill="#1D2129" />
  </svg>
);
