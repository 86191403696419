import moment from 'moment';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Alarm = () => {
  const [hour, setHour] = React.useState(0);
  const [minute, setMinute] = useState(0);
  const [pm, setPm] = useState(false);
  useEffect(() => {

    const update = () => {
      const date = new Date();
      let hour = date.getHours();
      hour = (hour % 12) || 12;
      setHour(hour);
      setMinute(date.getMinutes());
      setPm(date.getHours() >= 12);
    };

    update();

    const interval = setInterval(() => {
      update();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Container>
      <div className="today digital">{moment().format('ddd, MMM D, YYYY')}</div>
      <div className="time-container">
        <span className='digital'>{String(hour).padStart(2, '0')}:{String(minute).padStart(2, '0')}</span>
        <span className='digital apm'>{pm ? 'PM' : 'AM'}</span>
      </div>
    </Container>
  );
};

export default Alarm;

const Container = styled.div`
display: flex;
gap: 32px;
flex: 1;
justify-content: space-between;
.digital {
    line-height: 55px;
    height: 48px;
    color: #FBE696;
    font-family: 'Digital-7';
    font-size: 50px;
    font-style: normal;
    font-weight: 400;
    word-spacing: -15px;
}
.time-container {
  display: flex;
  gap: 8px;
  align-items: flex-end;
  .digital {
    font-weight: 600;
    word-spacing: unset;
  }

  .apm {
    font-size: 36px;
    line-height: 28px;
    height: 31px;
  }
}

`;
