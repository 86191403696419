import Sidebar from 'components/layout/Sidebar';
import customerActions from 'features/customer/services/actions';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import LeftCustomerDetails from './components/LeftCustomerDetails';
import RightCustomerDetails from './components/RightCustomerDetails';
import shopSelectors from 'services/shop/selectors';
type ICustomerDetailsProps = {};
const CustomerDetails: React.FC<ICustomerDetailsProps> = () => {
  const allSetting = shopSelectors.data.allSetting();
  const v2 = allSetting?.versionUI === 'V2';
  const { id } = useParams();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(customerActions.getCustomerDetails.fetch(id || ''));
    dispatch(customerActions.getSummaryReport.fetch(id || ''));
  }, []);

  return (
    <CustomerDetailsStyled>
      <Sidebar />
      <BodyStyled className={v2 ? 'v2' : ''}>
        <LeftCustomerDetails v2={v2} />
        <RightCustomerDetails v2={v2} />
      </BodyStyled>
    </CustomerDetailsStyled>
  );
};

export default CustomerDetails;
const CustomerDetailsStyled = styled.div`
  display: flex;
`;
const BodyStyled = styled.div`
  flex-grow: 1;
  display: flex;

  &.v2 {
    background: #f5f5f5;
  }
`;
