import Button from 'components/Button';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useState } from 'react';
import ReAdjustTipModal, { useReAdjustTipModalRef } from './ReAdjustTipModal';
import ticketListActions from 'features/ticketList/services/actions';
import { useAppDispatch } from 'store/hooks';

const ReAdjustTip = () => {
  const dispatch = useAppDispatch();
  const details = ticketListSelectors.ticketDetailsData.data();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const onCompleted = () => {
    dispatch(ticketListActions.getTicketDetails.fetch(details?.billId || ''));
  };

  const modalRef = useReAdjustTipModalRef();

  if (details?.items?.length <= 1) return null;

  return (
    <>
      <Button
        ntype='YELLOW'
        icon='refundMoney'
        onClick={() => {
          modalRef.current?.open(details);
          setOpen(true);
        }}
      >Re-Adjust tip</Button>
      <ReAdjustTipModal ref={modalRef} visible={open} onClose={handleClose} onCompleted={onCompleted} />
    </>
  );
};

export default ReAdjustTip;
