import { Form, Input, Spin } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import appointmentApis from 'features/appointment/services/apis';
import { IBodyApiQuickBooking } from 'features/appointment/services/types/appointment';
import { first } from 'lodash';
import moment, { Moment } from 'moment';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import shopSelectors from 'services/shop/selectors';
import { ICustomerItemData } from 'services/shop/types/customer';
import styled from 'styled-components';
import ModalChooseStaffRef, { useModalChooseStaffRef } from 'widgets/Staff/ModalChooseStaffRef';
import CalendarPicker from './CalendarPicker';
import CustomerSearchInput, { unknownCustomerId, unknownCustomerItem } from './CustomerSearchInput';
import Durations from './Durations';
import StaffQuickBookingSection from './Staff';
import StartTimes, { useStartTimesRef } from './StartTimes';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import StaffListChooseUI from './StaffListChooseUI';
import { IStaffItemData } from 'services/shop/types/staff';
import appointmentSelectors from 'features/appointment/services/selectors';
import BlockHourStaffs from './BlockHourStaffs';
import { useAppDispatch } from 'store/hooks';
import appointmentActions from 'features/appointment/services/actions';

export interface IFormValueItem {
  staffId: string;
  services: IFormValueServiceItem[];
}

export interface IFormValueServiceItem {
  label: string;
  value: string;
  id: string;
}

interface IFormValues {
  customer: ICustomerItemData;
  startTime: string | null;
  date: Moment,
  duration: number;
  note: string;
  items: IFormValueItem[];
}

type Props = {
  nonOpacity?: boolean;
  onDone?: (staffId: string) => void;
};
type Ref = {
  open: (startTime: string, staffId?: string | null) => void;
  close: () => void;
};
export const useQuickBookingRef = () => useRef<Ref>(null);
export const quickBookingRef = React.createRef<Ref>();
const QuickBooking = forwardRef<Ref, Props>(({ nonOpacity, onDone = () => undefined }, ref) => {
  const dispatch = useAppDispatch();
  const startTimesRef = useStartTimesRef();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const services = shopSelectors.data.quickBookingService();
  const _staffs = shopSelectors.data.staffs();
  const addRef = useModalChooseStaffRef();
  const blockHours = appointmentSelectors.getLockBreakTimesForQuickBooking();

  const [form] = Form.useForm<IFormValues>();

  const staffs = useMemo(() => _staffs.filter(o => !o.anyBody), [_staffs]);

  const handleClose = () => setOpen(false);
  const handleOpen: Ref['open'] = (startTime, staffId) => {
    setOpen(true);
    form.resetFields();
    const date = startTime ? moment(startTime) : moment();
    form.setFieldValue('date', date);
    dispatch(appointmentActions.getLockBreakTimesForQuickBooking.fetch({ startTime: date?.format(DATE_FORMAT), endTime: date?.format(DATE_FORMAT) }));
    form.setFieldValue('startTime', date.format('hh:mm A'));
    if (staffId) {
      form.setFieldValue('items', [
        {
          staffId,
          services: [],
        }
      ]);
      setTimeout(() => {
        onPickDate(date);
      }, 10);
    } else {
      form.setFieldValue('items', []);
      setTimeout(() => onPickDate(date), 10);
    }
  };

  const handleFinish = async (values: any) => {
    // IFormValues
    const data = values as IFormValues;
    const body: IBodyApiQuickBooking = {
      customerId: data.customer?.id !== unknownCustomerId ? data.customer.id : '',
      duration: data.duration,
      note: data.note,
      startTime: moment(`${data.date.format('MM/DD/YYYY')} ${data.startTime}`, 'MM/DD/YYYY HH:mm A').format('MM/DD/YYYY HH:mm'),
      items: data.items.map(o => (
        {
          services: o.services.map(ser => ({
            serviceId: ser.id,
            serviceName: ser.label,
          })),
          staffId: o.staffId,
        }
      )),
    };

    setLoading(true);
    try {
      const res: IResponseDataBody<any> = await appointmentApis.makeQuickBooking(body);
      if (res.data.data) {
        handleClose();
        onDone(first(body.items)?.staffId || '');
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  const onPickStaff = (item: IStaffItemData) => {
    const staffs: IFormValueItem[] = form.getFieldValue('items');
    const isFirst = staffs.length === 0;
    staffs.push({
      staffId: item.id,
      services: [],
    });
    form.setFieldValue('items', staffs);
    if (isFirst) setTimeout(() => onPickDate(form.getFieldValue('date')), 100);
  };

  const handleChangeStaff = (first: boolean) => (_: string, onChange: (value: string) => void) => {
    const staffs = form.getFieldValue('items');
    addRef.current?.open((staff) => {
      onChange(staff.id);
      if (first) {
        setTimeout(() => {
          onPickDate(form.getFieldValue('date'));
        }, 10);
      }
    }, staffs?.map((o: IFormValueItem) => o.staffId));
  };

  const onPickDate = async (value: Moment) => {
    if (!value) return;
    const staffId = (first(form.getFieldValue('items')) as (IFormValueItem | undefined))?.staffId;
    dispatch(appointmentActions.getLockBreakTimesForQuickBooking.fetch({ startTime: value?.format(DATE_FORMAT), endTime: value?.format(DATE_FORMAT) }));
    startTimesRef.current?.setLoading(true);
    try {
      const resData: IResponseDataBody<string[]> = await appointmentApis.getStaffTimeSlot(staffId || '', value?.format(DATE_FORMAT) || '');
      const list = resData.data.data;

      if (list) {
        const data: string[] = list.map(d => moment(d, 'HH:mm').format('hh:mm A'));
        form.setFieldValue('time-slots', data);
      } else {
        form.setFieldValue('time-slots', []);
      }
    } catch (error) { }
    finally {
      startTimesRef.current?.setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      headerBgColor={colorTheme.info_bg_3}
      visible={open}
      noneBodyStyle
      noneFooterStyle
      containerPadding={0}
      modalTitle='QUICK BOOKING'
      className={`modal-add-new-appointment modal-max-height-unset ${nonOpacity ? 'modal-non-opacity' : ''}`}
      width={'auto'}
      footer={<></>}
      hiddenFooter
      hiddenHeader
      onClose={handleClose}
      showClose
    >
      <Spin spinning={loading}>
        <QuickBookingForm style={{ display: 'flex', flexDirection: 'column' }} layout='vertical' form={form} onFinish={handleFinish} initialValues={{ customer: unknownCustomerItem, duration: 30, date: moment() }}>
          <Box display='flex' alignItems='center'>
            <Box display='flex' alignSelf='stretch'>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => {
                  const items: IFormValueItem[] = getFieldValue('items') || [];
                  const staffIds = items?.map(o => o?.staffId);
                  return <StaffListChooseUI onPickStaff={onPickStaff} selectedStaffs={staffIds} />;
                }}
              </Form.Item>
            </Box>
            <Container >
              <HeaderQuickBooking><span>Quick Booking</span></HeaderQuickBooking>
              <Content style={{ padding: '1.5rem' }}>
                <LeftContainer>
                  <div className='quick-booking-info'>
                    <Box display='flex' flexDirection='column' style={{ flex: 3 }}>
                      <Text variant='H9' color='text_3' className='text-lg'>Customer</Text>
                      <Form.Item noStyle name={'customer'}>
                        <CustomerSearchInput />
                      </Form.Item>
                    </Box>
                  </div>
                  <ContainerStaffs>
                    <div className="children-staff-scroll">
                      <Form.List name={'items'}>
                        {(fields, { remove }) => (
                          <div>
                            <Box display='flex' flexDirection='column' gap='3'>
                              {fields.map((o, i) => (
                                <Form.Item noStyle key={o.key} name={[o.name, 'staffId']} rules={[{ required: true }]}>
                                  <StaffQuickBookingSection
                                    staffs={staffs}
                                    name={o.name}
                                    services={services}
                                    disabledRemove={fields?.length === 1}
                                    onRemoveStaff={() => remove(o.name)}
                                    onChangeStaff={handleChangeStaff(i === 0)}
                                  />
                                </Form.Item>
                              ))}
                            </Box>
                          </div>
                        )}
                      </Form.List>
                      <Box display='flex' flexDirection='column' mb='2'>
                        <Text variant='H9' color='text_3' className='text-lg'>Duration</Text>
                        <Form.Item noStyle name={'duration'} rules={[{ required: true }]}>
                          <Durations />
                        </Form.Item>
                      </Box>
                      <Text mt={1} variant='H9' color='text_3' className='text-lg'>Note</Text>
                      <Form.Item noStyle name={'note'}>
                        <Input.TextArea placeholder='note' rows={3} />
                      </Form.Item>
                    </div>
                  </ContainerStaffs>
                </LeftContainer>
                <div className="divider"></div>
                <RightContainer>
                  <Form.Item noStyle name={'date'} rules={[{ required: true }]}>
                    <CalendarPicker onPickDate={onPickDate} />
                  </Form.Item>
                  <Text variant='H9' color='text_3' className='text-lg'>Start Time</Text>
                  <Form.Item noStyle name={'startTime'} rules={[{ required: true }]}>
                    <StartTimes ref={startTimesRef} />
                  </Form.Item>
                  <Text mb={1} />
                  <Form.Item shouldUpdate noStyle>
                    {({ getFieldValue }) => {
                      const items: IFormValueItem[] = getFieldValue('items') || [];
                      const staffIds = items?.map(o => o?.staffId);
                      const staffBlockHours = blockHours.filter(o => staffIds.includes(o.staffId));
                      const date = getFieldValue('date');
                      return <BlockHourStaffs date={date?.format('MM/DD/YYYY')} staffs={staffs} staffBlockHours={staffBlockHours} />;
                    }}
                  </Form.Item>
                </RightContainer>
              </Content>
              <Text mt={1} />
              <div className="button-container" style={{ padding: '1rem' }}>
                <Button height={4.5} cardCashier block onClick={handleClose}><span>Close</span></Button>
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const items: IFormValueItem[] = getFieldValue('items') || [];
                    return <Button height={4.5} cardCashier disabled={!items.length} block ntype='PRIMARY' onClick={() => form.submit()} ><span>Book</span></Button>;
                  }}
                </Form.Item>
              </div>
            </Container>
          </Box>
        </QuickBookingForm>
      </Spin>
      <ModalChooseStaffRef anybody ref={addRef} />
    </Modal>
  );
});

export default QuickBooking;
QuickBooking.displayName = 'QuickBooking';
const QuickBookingForm = styled(Form)`
zoom: 1;
@media (min-resolution: 0.8dppx) {
  zoom: 0.85;
}
`;
const HeaderQuickBooking = styled.div`
background: rgb(35, 47, 62);
height: 4rem;
display: flex;
align-items: center;
justify-content: center;
span {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%;
}
`;
const ContainerStaffs = styled.div`
  flex: 1;
  position: relative;
  align-self: stretch;
  .children-staff-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,0.5);
      &:hover {
        background-color: rgba(0,0,0,0.6);
      }
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #F5F5F5;
    }
  }
`;
const LeftContainer = styled.div`
width: 900px;
align-self: stretch;
display: flex;
flex-direction: column;
.CustomerSearchInput .ant-input-prefix {
  margin-inline-end: 8px;
}
.CustomerSearchInput input {
  font-size: 20px;
}
.Durations {
  .check-box-blank, .checked-box {
    width: 40px;
    height: 40px;
  }
  .label {
    font-size: 22px;
  }
}
`;
const RightContainer = styled.div`
width: 800px;
align-self: stretch;
display: flex;
flex-direction: column;
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  gap: 16px;
  .divider {
    width: 1px;
    align-self: stretch;
    background: #CCD4DC;
  }
`;
const Container = styled.div`
min-height: 90vh;
display: flex;
flex-direction: column;
.quick-booking-info {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}

.ant-form-item {
  margin-bottom: 8px;
}

.button-container {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  button span {
  font-size: 1.75rem;
  }
}

.text-lg {
  font-size: 1.25rem;
}
`;