import { Row } from 'antd';
import React, { memo } from 'react';
import styled from 'styled-components';
import Arrows from './Arrows';
import Actions from '../../Actions';
import ZoomConfig from './ZoomConfig';
import StatusBar from './StatusBar';
 
type IHeadContentProps = any;
const HeadContent: React.FC<IHeadContentProps> = () => {
  return (
    <HeadContentStyled>
      <Row align={'middle'} justify={'space-between'}>
        <StatusBar />
        <Row style={{ gap: '1rem' }}>
          <Actions />
          <ZoomConfig />
          <Arrows />
        </Row>
      </Row>
    </HeadContentStyled>
  );
};

export default memo(HeadContent);
type HeadContentStyledProps = {}
const HeadContentStyled = styled.div<HeadContentStyledProps>`
  display:flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  .list-type {
    gap:1rem;
  }
  .percent-bar {
    .progress {
      height: 0.5rem;
      .stroke {
        height: 100%;
        width: 100%;
      }
    }
  }
`;
