import React, { useCallback, useMemo } from 'react';
import { ICouponPrintDiscount } from 'services/shop/types/coupon';
import styled from 'styled-components';
import CouponContainer from '../CouponContainer';
import { QRCode, Row } from 'antd';
import moment from 'moment';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import Percent from './Percent';
import Money from './Money';
type Props = {
  data: ICouponPrintDiscount,
  isLarge?: boolean;
};
const CouponUI = ({ data }: Props) => {
  const isLarge = useMemo(() => {
    if (data.discountValue > 9) return true;
    return false;
  }, [data]);

  const BottomContent = useCallback(() => {
    return (
      <BottomContentStyled isLarge={isLarge}>
        <Row align={'middle'} justify={'space-between'}>
          <div className='list-description'>
            <ul style={{ listStyleType: 'none' }}>
              {
                data?.itemDesc?.map((text, i) => (
                  <li key={i} >{text?.trim()}</li>
                ))
              }
            </ul>
          </div>
          <QRCode color='black' bordered={false} size={90} value={data?.couponCode} />
        </Row>
        <Row className='expire-group' align={'middle'} justify={'space-between'}>
          <p className='expire_date'>Expires Until:  <b>{data.endTime ? moment(data.endTime).format(DATE_FORMAT) : '--'}</b></p>
          <div className='couponCode'>{data.couponCode}</div>
        </Row>
      </BottomContentStyled>
    );
  }, [data]);

  if (data.urlImage) return <CouponImageContainer src={data.urlImage} alt={data.couponName} />;

  if (data.discountType === 'PERCENT') {
    return (
      <CouponContainer>
        <CouponContainerWrapStyled isLarge={isLarge}>
          <CouponUIStyled>
            <Percent data={data} isLarge={isLarge} />
            <TitleStyled>{data.title}</TitleStyled>
          </CouponUIStyled>
          <BottomContent />
        </CouponContainerWrapStyled>
      </CouponContainer>
    );
  }

  return (
    <CouponContainer>
      <CouponContainerWrapStyled isLarge={isLarge}>
        <CouponUIStyled>
          <Money data={data} isLarge={isLarge} />
          <TitleStyled>{data.title}</TitleStyled>
        </CouponUIStyled>
        <BottomContent />
      </CouponContainerWrapStyled>
    </CouponContainer>
  );
};

export default CouponUI;
const CouponImageContainer = styled.img`
width: 100%;
`;
const CouponContainerWrapStyled = styled.div<{ isLarge?: boolean }>`
padding: 1rem;
${({ isLarge }) => isLarge && `
    padding-top: 0rem;
  `}
`;

const CouponUIStyled = styled.div<{ isLarge?: boolean }>`
.row {
  display: flex;
}
font-family: Archivo Black;
`;

const TitleStyled = styled.div`
  color: black;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  text-align: center;
`;

const BottomContentStyled = styled.div<{ isLarge?: boolean }>`
  .expire_date {
    color: black;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    margin-left: 14px;
    font-weight: bold;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
    b {
      color: black;
      font-size: 16px;
      font-style: normal;
      line-height: normal;
      font-weight: 900;
      font-family: 'Roboto', sans-serif;
      
    }
  }
  .title {
    color: black;
    font-size: 1.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .list-description {
    flex:1;
    padding-right:10px;
  }
  ul {
    margin:0;
    padding-left: 15px;
    li, p {
      color: black;
      font-size: 14px;
      font-style: normal;
      line-height: 15px;
      font-weight: 900;
      font-family: 'Roboto', sans-serif;
      b {
        font-weight: 700;
      }
    } 
  }
  .expire-group {
    transform: translateY(-5px);
  }
  .couponCode {
    color: black;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    width: 85px;
    font-weight: 900;
    font-family: 'Roboto', sans-serif;
  }

`;