import Modal from 'components/Modal';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import ServicePickerComponent from '../NewAppointmentV2/ServicesTab/ServicePickerComponent';
type Props = {
  v2?: boolean;
};
type Ref = {
  chooseService: (cb: (val: IServiceItemData) => void) => void;
};
export const useModalChooseServiceRef = () => useRef<Ref>(null);
const ModalChooseService = forwardRef<Ref, Props>(({ v2 }, ref) => {
  const [visible, setVisible] = useState(false);

  const callbackRef = useRef<(val: IServiceItemData) => void>(() => undefined);

  const onClose = () => {
    setVisible(false);
    callbackRef.current = () => undefined;
  };

  const handleChooseServices = (val: IServiceItemData) => {
    callbackRef.current(val);
    onClose();
  };

  useImperativeHandle(ref, () => ({
    chooseService(cb) {
      callbackRef.current = cb;
      setVisible(true);
    },
  }));

  if (v2) {
    return (
      <Modal
        v2
        visible={visible}
        modalTitle='CHOOSE SERVICE'
        noneBodyStyle
        hiddenOkButton
        onClose={onClose}
        width={'70vw'}
        containerPadding={1}
        className={'modal-add-new-appointment modal-max-height-unset modal-non-opacity'}
      >
        <ServicePickerComponent
          single
          v2={v2}
          selected={[]}
          handleChooseServices={handleChooseServices}
        />
      </Modal>
    );
  }

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      modalTitle='Choose Service'
      hiddenFooter
      closable
      noneBodyStyle
      noneFooterStyle
      width={'70vw'}
      className='modal-overflow-unset modal-max-height-unset'
      containerPadding={1}
      showClose
    >
      <ServicePickerComponent
        single
        selected={[]}
        handleChooseServices={handleChooseServices}
      />
    </Modal>
  );
});

ModalChooseService.displayName = 'ModalChooseService';

export default ModalChooseService;
