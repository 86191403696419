import React from 'react';
import styled from 'styled-components';
import GiftCardOverviews from './Overviews';
import GiftCardTable, { ITableGiftCardsProps } from './GiftCardTable';
interface Props extends ITableGiftCardsProps { }
const GiftCardsV2 = ({setSearchText ,handleSearch, handleChangeResetTime, onReset, resetTimeChecked, setResetTimeChecked, PAGE_SIZE, resetTime, searchText }: Props) => {
  return (
    <Container>
      <GiftCardOverviews />
      <GiftCardTable
        resetTime={resetTime}
        searchText={searchText}
        pageSize={PAGE_SIZE}
        resetTimeChecked={resetTimeChecked}
        setResetTimeChecked={setResetTimeChecked}
        handleChangeResetTime={handleChangeResetTime}
        onReset={onReset}
        handleSearch={handleSearch}
        setSearchText={setSearchText}
      />
    </Container>
  );
};

export default GiftCardsV2;
const Container = styled.div`
display: flex;
flex: 1;
flex-direction: column;
align-items: flex-start;
gap: 16px;
padding: 16px;
background: #F6F7FC;
`;