import { DndContext, DragEndEvent, DragOverlay } from '@dnd-kit/core';
import ActionBtn from 'components/ActionBtn';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import cashierApis from 'features/cashier/services/apis';
import cashierSelectors from 'features/cashier/services/selectors';
import { IApiBodySplitTicket, IApiBodySplitTicketNewBill, IApiBodySplitTicketNewItem, IApiBodySplitTicketService, IService } from 'features/cashier/services/types/api';
import { ISplitTicketItem, IStaffTicketResItem } from 'features/user/services/types/ticket';
import { first, last, remove, sumBy } from 'lodash';
import React, { useCallback, useRef, useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { v4 as uuid } from 'uuid';
import { Draggable } from '../DragAndDrop/Draggable';
import { Droppable } from '../DragAndDrop/Droppable';
import useRefreshScreen from 'hooks/useRefreshPage';
import { ButtonMergeTicketV2Styled } from './MergeTicketButton';
import splitIcon from './split.svg';
import clsx from 'clsx';
import { Avatar } from 'antd';
type DraggableData = {
  service: IService;
  staff: IStaffTicketResItem;
};

const geneNewTicket = () => {
  const result: ISplitTicketItem = {
    billId: uuid(),
    customerName: 'New Ticket',
    totalPrice: 0,
    customerPhone: '',
    staffs: [],
    ticketNumber: '',
    isDraft: true,
  };
  return result;
};

const useSplitTicket = ({ v2 }: { v2?: boolean }) => {
  const setPageLoading = useSetLoadingPage();
  const scrollRef = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const detail = cashierSelectors.getTicketDetails();
  const ticketDetailStore = cashierSelectors.getInfoBillTicketDetail();
  const [ticketDetail, setTicketDetail] = useState<ISplitTicketItem | null>(null);
  const [, startTransition] = useTransition();
  const refreshScreen = useRefreshScreen();
  const [newTickets, setNewTickets] = useState<ISplitTicketItem[]>([geneNewTicket(), geneNewTicket()]);

  const handleShowModal = () => startTransition(() => {
    setOpen(true);
    setTicketDetail({ ...ticketDetailStore });
    if (v2) {
      setNewTickets([geneNewTicket(), geneNewTicket(), geneNewTicket()]);
    } else {
      setNewTickets([geneNewTicket(), geneNewTicket()]);
    }
  });

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    if (ticketDetail?.staffs?.length === 1 && first(ticketDetail.staffs)?.services?.length === 1) return;

    const { over, active } = event;
    const activeData = active.data.current || {};
    if (!activeData || !over) return;
    const { service, staff } = activeData as DraggableData;
    setNewTickets(newTickets.map(o => {
      if (o.billId === over?.id) {
        const staffs = [...o.staffs ?? []];
        const exist = staffs.find(s => s.staffId === staff.staffId);
        if (exist) {
          exist.services.push(service);
        } else {
          staffs.push({
            avatar: staff.avatar,
            services: [service],
            staffId: staff.staffId,
            staffName: staff.staffName,
          });
        }
        return ({
          ...o,
          staffs,
        });
      }
      return o;
    }));

    const staffs = [...ticketDetail?.staffs || []];

    const exist = staffs.find(_staff => _staff.staffId === staff.staffId);
    if (exist) {
      const serFilters = exist.services.filter(ser => ser.uuid_local !== service.uuid_local);
      if (serFilters.length === 0) {
        remove(staffs, sta => sta.staffId === staff.staffId);
      } else {
        exist.services = [...serFilters];
      }
    }

    setTicketDetail({
      ...ticketDetail,
      staffs,
    } as ISplitTicketItem);
  };

  const handleAddNewTicket = () => {
    if (last(newTickets)?.staffs.length === 0) return;
    const _newTickets = [...newTickets];
    _newTickets.push(geneNewTicket());
    setNewTickets(_newTickets);
    setTimeout(() => {
      scrollRef.current?.scrollBy({ behavior: 'smooth', left: -Number.MIN_SAFE_INTEGER });
    }, 0);
  };

  const handleSubmitModal = async () => {
    setOpen(false);
    setPageLoading(true);
    const payload: IApiBodySplitTicket = {
      billCurrent: {
        billId: ticketDetail?.billId || '',
        items: ticketDetail?.staffs.map(staff => ({
          staffId: staff.staffId,
          service: staff?.services?.map(o => ({
            itemName: o.itemName,
            price: o.price,
            serviceId: o.itemId,
          }) as IApiBodySplitTicketService) || []
        }) as IApiBodySplitTicketNewBill) || [],
      },
      newBills: newTickets.filter(o => o.staffs.length).map(o => ({
        items: o.staffs.map(sta => ({
          staffId: sta.staffId,
          service: sta.services.map(ser => ({
            itemName: ser.itemName,
            price: ser.price,
            serviceId: ser.itemId,
          }) as IApiBodySplitTicketService)
        }) as IApiBodySplitTicketNewBill)
      }) as IApiBodySplitTicketNewItem)
    };
    try {
      const res: IResponseDataBody<boolean> = await cashierApis.splitTicket(payload);
      if (res.data.data) refreshScreen();
    } catch (error) {

    } finally {
      setPageLoading(false);
    }
  };

  return ({
    open, detail, handleShowModal, handleCloseModal, handleDragEnd, handleAddNewTicket, handleSubmitModal, ticketDetail,
    scrollRef, newTickets,
  });
};

type ISplitButtonProps = {
  v2?: boolean;
};
const SplitButton: React.FC<ISplitButtonProps> = ({ v2 }) => {
  const { open, detail, handleShowModal, handleCloseModal, handleDragEnd, handleAddNewTicket, handleSubmitModal, ticketDetail,
    scrollRef, newTickets
  } = useSplitTicket({ v2 });
  return (
    <>
      {!v2 ? <ActionBtn
        ntype="SECONDARY"
        icon="split"
        label='Split Ticket'
        onClick={handleShowModal}
        disabled={!!detail?.checkInId || !detail?.items?.length}
      /> : <ButtonMergeTicketV2Styled type='button' onClick={handleShowModal}
        disabled={!!detail?.checkInId || !detail?.items?.length}
      >
        <img src={splitIcon} alt="splitIcon" className="button-icon" />
        <span>Split Ticket</span>
      </ButtonMergeTicketV2Styled>}
      <Modal
        v2={v2}
        width={v2 ? '1690px' : 847}
        modalTitle="Split Ticket"
        className='modal-split-ticket modal-non-opacity'
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        noneBodyStyle
        noneFooterStyle
        footer={v2 ? null : undefined}
        containerPadding={v2 ? 0 : 2}
        footerPadding={v2 ? 0 : 2}
        okTitle="Done"
      >
        <Box style={v2 ? { background: '#F5F5F5', padding: '1rem' } : undefined}>
          <DndContext onDragEnd={handleDragEnd}>
            <TicketContainerStyled className={v2 ? 'v2' : ''} >
              <TicketDisplay v2={v2} item={ticketDetail} />
              <div className='flex-grow' ref={scrollRef}>
                {newTickets.map(o => (<TicketDisplay v2={v2} key={o.billId} item={o} />))}
              </div>
            </TicketContainerStyled>
            <DragOverlay />
          </DndContext>
          {v2 ?
            <FooterActionsV2 display='flex' style={{ gap: '1rem' }}>
              <Button block onClick={handleCloseModal}>
                Close
              </Button>
              <Button block ntype="LIGHT_BLUE" onClick={handleAddNewTicket}>
                Add More Ticket
              </Button>
              <Button block ntype="PRIMARY" onClick={handleSubmitModal}>
                OK
              </Button>
            </FooterActionsV2>
            :
            <Box>
              <Button ntype="LIGHT_BLUE" width="100%" onClick={handleAddNewTicket}>
                + Add New Ticket
              </Button>
            </Box>
          }
        </Box>
      </Modal>
    </>
  );
};

export default SplitButton;

const FooterActionsV2 = styled(Box)`
  button {
  display: flex;
  height: 70px !important;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  background: #E5E6EB;
    .CONTENT_2 {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;

const TicketContainerStyled = styled.div`
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  display: flex;
  position: relative;
  .ticket-item {
    width: 33.33%;
    min-width: 33.33%;
    height: 21.5rem;
    position:relative;
    z-index:2;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
  }

  .flex-grow {
    overflow: auto;
    flex-grow: 1;
    display: flex;
    flex-wrap: nowrap;
    .ticket-item {
      width: 50%;
      min-width: 50%;
      position:relative;
      z-index:1;
    }  
  }
  
  .ticket-item:last-child {
    .ticket-wrapper {
      margin-right: 0;
    }
  }
  .ticket-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    border-radius: 5px;
  }

  &.v2 {
    padding-top: 0px;
   .ticket-item {
      width: 25%;
      min-width: 25%;
      height: unset;
      min-height: 40rem;
      padding-bottom: 8px;
   }

   .flex-grow {
      .ticket-item {
        width: 33.3%;
        min-width: 33.3%;
      }  
    }
  }
`;

const TicketStyled = styled.div<{ overflow: boolean }>`
  margin-right: 1rem;
  background-color: ${colorTheme.fill_3};
  min-height: calc(100% - 1rem);
  // overflow-y:${({ overflow }) => overflow ? 'auto' : 'unset'}

  &.ticket-wrapper.v2 {
    border-radius: 4px;
    background: #F6F7FC;
    height: 100%;
    min-height: 40rem;
    padding: 0;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    .ticket-header {
      background: #FCFCFD;
      box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
      display: flex;
      padding: 12px;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .ticket-number {
        display: flex;
        padding: 0px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 5px;
        background: #232F3E;
        height: 42px;
        &.H9 {
          color: #fff;
          font-size: 28px;
        }
      }
      .H9 {
        height: 42px;
        color: #1D2129;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: flex;
        align-items: center;
      }
    }

    .service-item {
      margin: 0 16px;
      .BODY_1 {
        font-size: 18px;
      }
    }
  }
`;

const TicketDisplay = ({ item, v2 }: { item: ISplitTicketItem | null, v2?: boolean; }) => {
  const TicketContent = useCallback(() => {
    if (!item) return null;
    const total = sumBy(item.staffs, o => sumBy(o.services, s => s.price) || 0) || 0;
    return (
      <TicketStyled className={clsx('ticket-wrapper', v2 && 'v2')} overflow={!!item.isDraft}>
        <Box className="space-between ticket-header">
          {!item.isDraft ? <Text variant="H9" color="text_3" className='ticket-number'>
            #{item.ticketNumber} {v2 ? '' : `- ${item.customerName}`}
          </Text> : <Text variant="H9" color="text_3">
            New Ticket
          </Text>}
          <Text variant="H9" color="text_3">
            {formatCurrency(total)}
          </Text>
        </Box>
        {/* mapping here */}
        {item.staffs.map((staff) => (
          <Box key={staff.staffId}>
            {v2 ? (
              <Box display='flex' alignItems='center' gap='2' style={{ padding: '0 16px' }}>
                <Avatar size={48} src={staff.avatar}>{staff.staffName?.[0] || 'A'}</Avatar>
                <Text variant="CONTENT_2" color="text_3" style={{ fontSize: 24 }}>
                  {staff.staffName}
                </Text>
              </Box>
            ) :
              <Box bb="line_3">
                <Text variant="CONTENT_2" color="text_3">
                  {staff.staffName}
                </Text>
              </Box>
            }
            {staff.services.map((service, index) => {
              const col: DraggableData = { service, staff };
              return (
                item.isDraft ? <Box key={service.uuid_local} className={clsx('service-item space-between', v2 && 'v2')} px="2" py="3">
                  <Text variant="BODY_1" color="text_3">
                    {service.itemName}
                  </Text>
                  <Box display="flex" gap="2" alignItems="center">
                    <Text variant="BODY_1" color="text_3">
                      {formatCurrency(service.price)}
                    </Text>
                  </Box>
                </Box> :
                  <Draggable key={service.uuid_local} id={`${staff.staffId}_${service.itemId}_${index}`} data={col}>
                    <Box className={clsx('service-item space-between', v2 && 'v2')} px="2" py="3">
                      {v2 && (
                        <svg viewBox="0 0 20 20" width="12" >
                          <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"
                            // @ts-ignore
                            fill={'#2D3043'}
                          ></path>
                        </svg>
                      )}
                      <Text variant="BODY_1" color="text_3" style={{ flex: 1, marginLeft: 12 }}>
                        {service.itemName}
                      </Text>
                      <Box display="flex" gap="2" alignItems="center">
                        <Text variant="BODY_1" color="text_3">
                          {formatCurrency(service.price)}
                        </Text>
                      </Box>
                    </Box>
                  </Draggable>
              );
            })}
            {v2 && <Box bb="line_3" style={{ margin: '0 16px' }} />}
          </Box>
        ))}
      </TicketStyled>
    );
  }, [item]);

  if (!item?.isDraft) return <div className='ticket-item'>
    <TicketContent />
  </div>;

  return (
    <Droppable
      id={item.billId}
      className="ticket-item"
    >
      <TicketContent />
    </Droppable>
  );
};
