import { Input, InputRef, Spin } from 'antd';
import Modal from 'components/Modal';
import NumberKeyBoard, { NumberKeyBoardRef, PIN_LENGTH } from 'components/NumberKeyBoard';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';

type Props = {
  onClose?: () => void;
  onCorrect: (pin?: string) => void;
  visible: boolean;
  onlyAdmin?: boolean;
  v2?: boolean;
};

const RequestAdminLocalPW = ({ visible, onCorrect, onClose = () => undefined, onlyAdmin = false, v2 }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const kbRef = useRef<NumberKeyBoardRef>(null);
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleClose = () => {
    kbRef.current?.reset();
    onClose();
  };

  const showError = (text: string) => {
    if (v2) return setError(text);
    return kbRef.current?.showError(text);
  };

  const handle = useCallback(async (_pin: string) => {
    if (_pin.length < 4) return;
    setLoading(true);
    try {
      const res: IResponseDataBody<IStaffItemData> = await shopApis.verifyPinAdmin(_pin);
      const resData = res.data.data;
      if (resData) {
        return onCorrect(_pin);
      }
      return showError('Password incorrect!');

    } catch (error) {
      showError('Password incorrect!');
    } finally {
      setLoading(false);
    }
  }, [onlyAdmin]);

  const _debounce = useCallback(debounce((_pin) => handle(_pin), 500), []);

  useEffect(() => {
    _debounce(pin);
  }, [pin]);

  useEffect(() => {
    if (!visible) return;
    inputRef.current?.focus();
  }, [visible]);

  if (v2) {
    const _onChange = (text: string) => {
      if (text.length > PIN_LENGTH) return;
      setPin(text);
    };

    return (
      <Modal
        modalTitle='ADMIN PASSWORD'
        hiddenOkButton
        v2
        noneBodyStyle
        width={'800px'}
        visible={visible} onClose={handleClose}>
        <Spin spinning={loading}>
          <InputUIV2>
            <Input
              ref={inputRef}
              name="input_password"
              value={pin}
              autoFocus
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputMode='none'
              className={'password-input'}
              onChange={(e) => {
                _onChange(e.target.value);
              }}
              maxLength={PIN_LENGTH}
              autoComplete="off"
            />
          </InputUIV2>
          {error && (
            <Text textAlign="center" mt={0.5} style={{ color: '#f53f3f', fontSize: 18 }}>
              {error}
            </Text>
          )}
          <NumberPadV2
            value={pin} onChange={(val) => _onChange(val || '')}
            type='PIN-PASSWORD'
          />

        </Spin>
      </Modal>
    );
  }

  return (
    <Modal
      modalTitle='ADMIN PASSWORD'
      hiddenOkButton
      visible={visible} onClose={handleClose}>
      <Spin spinning={loading}>
        <NumberKeyBoard
          ref={kbRef}
          type='PIN-PASSWORD'
          value={pin} onChange={(val) => setPin(val || '')}
        />
      </Spin>
    </Modal>
  );
};

export default memo(RequestAdminLocalPW);

export const InputUIV2 = styled.div`
align-self: stretch;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px 0;
  .password-input {
    -webkit-text-security: disc;
    text-security: disc;
    &.scanQR {
      input {
        text-align: center;
        padding-left: 50px;
      }
    }
  }
input {
  color: #D2464F;
  flex: 1;
  text-align: center;
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
`;