import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import CategoryDetailHeader from './CategoryDetailHeader';
import Service from './Service';
import Button from 'components/Button';
import { Form } from 'antd';
import React, { useEffect } from 'react';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { useAppDispatch } from 'store/hooks';
import settingServiceActions from 'features/settingService/services/actions';
import Empty from 'components/Empty';
import ModalEditName, { useModalEditNameRef } from '../ModalEditName';
import { ICategoryUpdateInfoServiceItem, ICreateMultipleAddOnItem, IEditCateBodyType } from 'features/settingService/services/types/api';
import { ADD_ON_CATE_ID } from 'services/shop/constants';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';

type Props = {
  data: ICategoryItemData | null;
  onDeleteServices: (ids: string[]) => void;
  onDeleteServicesAddOn: (ids: string[]) => void;
  onApplySupplyFee: (cateId: string, value: number) => void;
  onEditNameCate: (cateId: string, value: string) => void;
  onEditNameService: (id: string, value: string) => void;
  onEditNameServiceAddOn: (id: string, value: string) => void;
  onUpdateInfoService: (data: ICategoryUpdateInfoServiceItem[]) => void;
  onUpdateInfoServicesAddOn: (data: ICategoryUpdateInfoServiceItem[]) => void;
  onDeleteCategory: (id: string) => void;
  onPreOrderServices: (ids: string[]) => void;
  onPreOrderServicesAddOn: (ids: string[]) => void;
  onAddMoreServiceForCate: (body: IEditCateBodyType) => void;
  onApplySupplyFeeAddOn: (value: number) => void;
  onAddMoreServiceForAddOn: (body: ICreateMultipleAddOnItem[]) => void;
  onApplyDiscountItemsCate: (cateId: string, type: DISCOUNT_VALUE_TYPE, value: number) => void;
  onApplyDiscountItemsCateAddon: (type: DISCOUNT_VALUE_TYPE, value: number) => void;
};
const ServicePart = ({
  data,
  onDeleteServices, onApplySupplyFee, onEditNameCate,
  onEditNameService, onUpdateInfoService, onDeleteCategory,
  onPreOrderServices, onAddMoreServiceForCate, onApplySupplyFeeAddOn,
  onDeleteServicesAddOn, onPreOrderServicesAddOn, onAddMoreServiceForAddOn,
  onEditNameServiceAddOn, onUpdateInfoServicesAddOn, onApplyDiscountItemsCate,
  onApplyDiscountItemsCateAddon,
}: Props) => {
  const modalEditNameRef = useModalEditNameRef();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const selectedServiceIds = settingServiceSelectors.serviceTab.selectedServiceIds();

  const handleChooseService = (id: string) => {
    dispatch(settingServiceActions.serviceActs.selectedServiceId(id));
  };

  useEffect(() => {
    if (!data) return;
    form.setFieldValue('services', data.services);
  }, [data]);

  const handleFinish = (values: any) => {
    const services = values?.services?.map((o: IServiceItemData) => ({
      id: o.id,
      duration: o.duration,
      price: o.priceSell,
      supplyFee: o.supplyFee,
      discountType: o.discountType,
      discountValue: +(o.discountValue || 0),
      backgroundColor: o.backgroundColor,
    }) as ICategoryUpdateInfoServiceItem);

    if (data?.id === ADD_ON_CATE_ID) return onUpdateInfoServicesAddOn(services);
    onUpdateInfoService(services);
  };

  const onReset = () => {
    form.resetFields();
    if (!data) return;
    form.setFieldValue('services', data.services);
  };

  const onSubmit = () => form.submit();

  const onOpenEditNameService = (value: IServiceItemData) => {
    modalEditNameRef.current?.open(value.serviceName, value);
  };
  const _onEditNameService = (newValue: string, extraData?: IServiceItemData) => {
    if (!extraData) return;
    if (data?.id === ADD_ON_CATE_ID) return onEditNameServiceAddOn(extraData.id, newValue);
    onEditNameService(extraData.id, newValue);
  };

  return (
    <Container>
      <Styled className='category-detail-card'>
        <CategoryDetailHeader activeCate={data} className="card-header"
          onDeleteServices={onDeleteServices}
          onDeleteServicesAddOn={onDeleteServicesAddOn}
          onApplySupplyFee={onApplySupplyFee}
          onEditNameCate={onEditNameCate}
          onDeleteCategory={onDeleteCategory}
          onPreOrderServices={onPreOrderServices}
          onPreOrderServicesAddOn={onPreOrderServicesAddOn}
          onAddMoreServiceForCate={onAddMoreServiceForCate}
          onAddMoreServiceForAddOn={onAddMoreServiceForAddOn}
          onApplySupplyFeeAddOn={onApplySupplyFeeAddOn}
          onApplyDiscountItemsCate={onApplyDiscountItemsCate}
          onApplyDiscountItemsCateAddon={onApplyDiscountItemsCateAddon}
        />
        <Form className="card-body" form={form} onFinish={handleFinish}>
          <div className="children-scroll">
            {!data?.services?.length && <div className="children-container-empty">
              <Empty description='Empty service' />
            </div>}
            <Form.List name={'services'}>
              {(fields) => {
                return (
                  <div className="children-container">
                    {fields.map(({ key, name }, i) => (
                      <React.Fragment key={key}>
                        <Form.Item name={name} noStyle>
                          <Service
                            index={i} name={name}
                            selectedIds={selectedServiceIds}
                            onChooseService={handleChooseService}
                            onEditName={onOpenEditNameService}
                          />
                        </Form.Item>
                      </React.Fragment>
                    ))}
                  </div>
                );
              }}
            </Form.List>
          </div>
        </Form>
        <div className="card-footer">
          <Button width='10rem' height={3} ntype='SECONDARY' onClick={onReset}>Reset</Button>
          <Button width='10rem' height={3} ntype='PRIMARY' onClick={onSubmit}>Save</Button>
        </div>
      </Styled>
      <ModalEditName ref={modalEditNameRef} onSubmit={_onEditNameService} />
    </Container >
  );
};

export default ServicePart;

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;  
  
  .category-detail-card {
    flex: 1;
  }
`;

const Styled = styled.div`
  display: flex;
  flex-direction: column;  
  background: #fff;
  .card-header {}
  .card-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16px 24px;
    background: #FFF;
    box-shadow: 6px 0px 12px -4px rgba(0, 0, 0, 0.20);
    gap: 24px;
  }
  .card-body {
    flex: 1;
    position: relative;
    z-index: 1;
    .children-scroll {
      position: absolute;
      inset: 0;
      right: 4px;
      overflow-y: scroll;
      .children-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
        padding-left: 24px;
        padding-top: 24px;
        padding-right: 12px;
        padding-bottom: 24px;
      }
      .children-container-empty {
        display: flex;
        flex:1;
        align-items: center;
        justify-content: center;
        padding-top: 10vh;
      }
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
        background-color: #F6F7FC;
      }
    
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: #ADADAD;
        &:hover {
          background-color: rgba(0,0,0,0.6);
        }
      }
    
      &::-webkit-scrollbar-track {
        border-radius: 4px;
        background-color: #F6F7FC;
      }

    }
  }
  .card-footer {
    height: 46px;
    background: #fff;
  }
`;
