import { Input, InputRef, Spin } from 'antd';
import Modal from 'components/Modal';
import NumberKeyBoard, { NumberKeyBoardRef, PIN_LENGTH } from 'components/NumberKeyBoard';
import { STAFF_TYPE } from 'features/cashier/services/types/constant';
import { debounce } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import { IStaffItemData } from 'services/shop/types/staff';
import { InputUIV2 } from '.';
import Text from 'components/Text';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';

type Props = {
  onlyAdmin?: boolean;
  v2?: boolean;
};

type RequestAdminPWCallbackRef = {
  open: (success: (pin?: string) => void) => void;
  close: () => void;
};
export const useRequestAdminPWCallbackRef = () => useRef<RequestAdminPWCallbackRef>(null);
const RequestAdminPWCallback = forwardRef<RequestAdminPWCallbackRef, Props>(({ v2, onlyAdmin = false }, ref) => {
  const inputRef = useRef<InputRef>(null);
  const [visible, setVisible] = useState(false);
  const kbRef = useRef<NumberKeyBoardRef>(null);
  const [pin, setPin] = useState('');
  const [loading, setLoading] = useState(false);
  const callbackRef = useRef<(pin?: string) => void>(() => undefined);
  const [error, setError] = useState<string | undefined>();

  const handleClose = () => {
    kbRef.current?.reset();
    setVisible(false);
  };

  const showError = (text: string) => {
    if (v2) return setError(text);
    return kbRef.current?.showError(text);
  };

  const handle = useCallback(async (_pin: string) => {
    const onCorrect = callbackRef.current;
    if (_pin.length < 4) return;
    setLoading(true);
    try {
      const res: IResponseDataBody<IStaffItemData> = await shopApis.verifyPinAdmin(_pin);
      const resData = res.data.data;
      if (resData) {
        setVisible(false);
        if (onlyAdmin) {
          if (resData.staffType === STAFF_TYPE.ADMIN) {
            return onCorrect(_pin);
          }
        } else {
          return onCorrect(_pin);
        }
      }
      return showError('Password incorrect!');

    } catch (error) {
      showError('Password incorrect!');
    } finally {
      setLoading(false);
    }
  }, [onlyAdmin, callbackRef]);

  const _debounce = useCallback(debounce((_pin) => handle(_pin), 500), []);

  useEffect(() => {
    _debounce(pin);
  }, [pin]);
 
  useImperativeHandle(ref, () => ({
    open: (callback) => {
      callbackRef.current = callback;
      setVisible(true);
      setPin('');
      kbRef.current?.reset();
      setTimeout(() => {
        kbRef.current?.focusInput();
        inputRef.current?.focus();
      }, 100);
    },
    close: () => handleClose(),
  }));
  if (v2) {
    const _onChange = (text: string) => {
      if (text.length > PIN_LENGTH) return;
      setPin(text);
    };

    return (
      <Modal
        modalTitle='ADMIN PASSWORD'
        hiddenOkButton
        v2
        noneBodyStyle
        width={'800px'}
        visible={visible} onClose={handleClose}>
        <Spin spinning={loading}>
          <InputUIV2>
            <Input
              ref={inputRef}
              name="input_password"
              value={pin}
              autoFocus
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputMode='none'
              className={'password-input'}
              onChange={(e) => {
                _onChange(e.target.value);
              }}
              maxLength={PIN_LENGTH}
              autoComplete="off"
            />
          </InputUIV2>
          {error && (
            <Text textAlign="center" mt={0.5} style={{ color: '#f53f3f', fontSize: 18 }}>
              {error}
            </Text>
          )}
          <NumberPadV2
            value={pin} onChange={(val) => _onChange(val || '')}
            type='PIN-PASSWORD'
          />

        </Spin>
      </Modal>
    );
  }
  return (
    <Modal
      modalTitle='ADMIN PASSWORD'
      hiddenOkButton
      visible={visible} onClose={handleClose}>
      <Spin spinning={loading}>
        <NumberKeyBoard
          ref={kbRef}
          type='PIN-PASSWORD'
          value={pin} onChange={(val) => setPin(val || '')}
        />
      </Spin>
    </Modal>
  );
});

RequestAdminPWCallback.displayName = 'RequestAdminPWCallback';
export default RequestAdminPWCallback;
