import { Avatar, Row, Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import Button from 'components/Button';
import Text from 'components/Text';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import reportActions from 'features/report/services/actions';
import { IParamItemType, ISalaryStaffInfo } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { ReportType } from '../../constants';
import { tableGreenStrStyle } from 'features/report/components/Component';

interface DataType extends ISalaryStaffInfo {
    key: string;
    staffId: string;
    name: string;
    service: any;
    product: any;
    hourly: any;
    type: any;
    discount: any;
    paidOut: number;
    tip: any;
    cashbonus: any;
    check: any;
    urlImage: any;
}
interface ITableEmployeeSalary {
    data: ISalaryStaffInfo[];
    dataAppointment?: IParamItemType | undefined;
    viewType?: CALENDAR_VIEW_TYPE;
    v2?: boolean;
}

const TableEmployeeSalary: React.FC<ITableEmployeeSalary> = ({ data, dataAppointment, viewType, v2 }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const { staff_id, tab } = useParams();
    const [dataTable, setDataTable] = useState<DataType[]>();
    useEffect(() => {
        setDataTable(data.map(d => {
            return {
                key: d.staffId,
                name: d.staffName,
                service: {
                    total: d.numberService,
                    price: d.totalSaleService
                },
                product: {
                    total: d.numberProduct,
                    price: d.totalSaleProduct
                },
                hourly: d.hourlyValue,
                type: {
                    name: d.salaryType,
                    price: d.commissionValue
                },
                cashbonus: d.payInCash,
                check: d.payInCheck,
                urlImage: d.avatar,
                paidOut: d.salaryAmount,
                ...d
            };
        }));
    }, [data]);

    const columns: ColumnsType<DataType> = [
        {
            title: 'NAME',
            width: 95,
            dataIndex: 'name',
            key: '1.name',
            render: (_, { name, avatar }) => (
                <NameBox>
                    <ImageContainer>
                        <Avatar
                            src={avatar}
                        >{name?.charAt(0)}</Avatar>
                    </ImageContainer>
                    <Text variant="CONTENT_1" color="text_3">
                        {name}
                    </Text>
                </NameBox>
            ),
        },
        {
            title: 'TYPE',
            dataIndex: 'type',
            key: '6.type',
            width: 100,
            render: (type: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_1" color="text_3">{type.name}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'SERVICES',
            dataIndex: 'service',
            key: '2.service',
            width: 105,
            render: (service: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_1" color="text_3">{formatCurrency(service.price)}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'PRODUCTS',
            dataIndex: 'product',
            key: '3.product',
            width: 120,
            render: (product: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_1" color="text_3">{formatCurrency(product.price)}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'SUPPLY',
            dataIndex: 'supplyFee',
            key: '5.supplyFee',
            width: 110,
            render: (supplyFee) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(supplyFee)}</Text>,
            align: 'center'
        },
        {
            title: 'Commission',
            dataIndex: 'commissionValue',
            key: '5.supplyFee',
            width: 110,
            render: (commissionValue) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(commissionValue)}</Text>,
            align: 'center'
        },

        {
            title: 'TIPS',
            dataIndex: 'tip',
            key: '8.tip',
            width: 95,
            render: (_, record) => <TipRow justify={'center'}>
                <Text variant="CAPTION_1" color="text_3" textAlign='center'>{formatCurrency(record.tip)}</Text>
            </TipRow>,
            align: 'center'
        },
        {
            title: 'DISCOUNTS',
            dataIndex: 'discount',
            key: '7.discount',
            width: 120,
            render: (data: any) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(data)}</Text>,
            align: 'center'
        },
        {
            title: 'PAID OUT',
            dataIndex: 'paidOut',
            key: 'paidOut',
            width: 100,
            render: (paidOut: any) => (
                <ServiceBox>
                    <Text variant="CAPTION_2" color="text_3">{formatCurrency(paidOut)}</Text>
                </ServiceBox>),
            align: 'center'
        },
        {
            title: 'CASH /BONUS',
            dataIndex: 'cashbonus',
            key: '9.cashBonus',
            width: 110,
            render: (data: any) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(data)}</Text>,
            align: 'center'
        },
        {
            title: 'CHECK',
            dataIndex: 'check',
            key: '10.check',
            width: 95,
            render: (data: any) => <Text variant="CAPTION_1" color="text_3">{formatCurrency(data)}</Text>,
            align: 'center'
        },
        {
            title: ' ',
            width: 95,
            fixed: 'right',
            render: (data: any) => <Button size='small' ntype="LIGHT_BLUE" onClick={() => onViewDetail(data)} >DETAIL</Button>,
        }
    ];
    const onViewDetail = (data: any) => {
        dispatch(reportActions.setParams({
            [ReportType.STAFF_SALARY_DETAIL]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
        if (viewType) {
            const payload = {
                [ReportType.STAFF_SALARY_DETAIL]: viewType,
            };
            dispatch(reportActions.setCalendarViewType(payload));
        }
        navigate(
            `staff/${data.key}`
        );
    };
    return (
        <StyledTableEmployeeSalary className={v2 ? 'v2' : ''}>
            <Table
                columns={columns}
                dataSource={dataTable}
                style={v2 ? { width: '100%' } : { width: '100%', padding: '1rem' }}
                rowClassName={'row-table'}
                pagination={{ pageSize: 50 }}
            />
        </StyledTableEmployeeSalary>
    );
};

export default TableEmployeeSalary;

const StyledTableEmployeeSalary = styled(Row)`
    flex: 1;
    border-radius: 5px;
    border: 1px solid #86909C;
    background: #FFF;

    /* box */
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    ${tableGreenStrStyle}

&.v2 {
    flex: 1;
    box-shadow: none;
    border: none;
    border-radius: none;
    .ant-table-wrapper .ant-table {
        .ant-table-thead th.ant-table-cell {
            height: 48px;
            padding: 0px 8px;
            gap: 10px;
            align-self: stretch;
            border-bottom: 1px solid #CCD4DC;
            background: #6FABB6;
            color: #fff;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
            text-align: left !important;
            &::before {
            width: 1.5px !important;
            height: 100% !important;
            }
        }

        .ant-table-tbody td.ant-table-cell {
            .BODY_1 , .CAPTION_1 {
            color: #1D2129;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            }
        }

        .ant-table-tbody .ant-table-row:nth-child(even) td {
            background: #E4F4F6;
            border-bottom: 1px solid #E4F4F6;
        }
    }
}
`;

const ImageContainer = styled.div`
  min-width: 40px;
  margin-right: 0.5rem;
`;

const NameBox = styled(Row)`
  align-items: center;
  flex-wrap: nowrap;
`;

const ServiceBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;
const TipRow = styled(Row)`
    align-items: center;
`;
