import React from 'react';
import styled from 'styled-components';
import walk_in_icon from './icons/walk-in.svg';
import calendar_icon from './icons/calendar.svg';
import average_icon from './icons/average.svg';
import userSelectors from 'features/user/services/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import shopSelectors from 'services/shop/selectors';

const HomeV2Footer = () => {
  const { totalBooking = 0, averageTickets = 0, totalWalkIn = 0 } = userSelectors.getSummaryData() ?? {};
  const allSetting = shopSelectors.data.allSetting();
  const showAverageTicket = allSetting?.showAverageTicket;
  
  return (
    <Container>
      <NotesStaffs >
        <div className="appointment-item">
          <div className="symbol">
            <span>A</span>
          </div>
          <span className="label-note">
            Number Appointment
          </span>
        </div>
        <div className="appointment-item">
          <div className="symbol turn">
            <span>T</span>
          </div>
          <span className="label-note">
            Number Turn
          </span>
        </div>
        <div className="appointment-item">
          <div className="symbol clock-time">
            <span>C</span>
          </div>
          <span className="label-note">
            Time: Clock-In
          </span>
        </div>
        <div className="appointment-item">
          <div className="symbol lst">
            <span>L</span>
          </div>
          <span className="label-note">
            Last Time Service
          </span>
        </div>
      </NotesStaffs>
      <Summary>
        <div className="summary-item">
          <div className="summary-icon-wrapper walk-in">
            <img src={walk_in_icon} alt="walk_in_icon" />
          </div>
          <div className="summary-amount-container">
            <div className="amount-value">{totalWalkIn}</div>
            <div className="amount-labels">
              <span className="top-label">Customer</span>
              <span className="bottom-label">WALK-IN</span>
            </div>
          </div>
        </div>
        <div className="summary-item">
          <div className="summary-icon-wrapper appointment">
            <img src={calendar_icon} alt="calendar_icon" />
          </div>
          <div className="summary-amount-container">
            <div className="amount-value">{totalBooking}</div>
            <div className="amount-labels">
              <span className="top-label">Customer</span>
              <span className="bottom-label">APPOINTMENT</span>
            </div>
          </div>
        </div>
        {showAverageTicket && <div className="summary-item">
          <div className="summary-icon-wrapper ticket">
            <img src={average_icon} alt="average_icon" />
          </div>
          <div className="summary-amount-container">
            <div className="amount-value">{formatCurrency(averageTickets)}</div>
            <div className="amount-labels">
              <span className="top-label">Average</span>
              <span className="bottom-label">TICKETS</span>
            </div>
          </div>
        </div>}
      </Summary>
    </Container>
  );
};

export default HomeV2Footer;

const Container = styled.div`
display: flex;
align-self: stretch;
background: #FFF;
justify-content: space-between;
position: relative;
height: 100px;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.15);
`;

const NotesStaffs = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 16px 48px;
padding-right: 16px;
min-width: 800px;
gap: 24px;
border-right: 1px solid #CCD4DC;
.appointment-item {
  display: flex;
  padding-right: 32px;
  align-items: center;
  gap: 12px;
  border-right: 1px solid #CCD4DC;
  &:last-child {
  border-right: 1px solid transparent;
  }
  .symbol {
    display: flex;
    width: 68px;
    height: 68px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #F5F5F5;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.turn {
      background: #6FE7BF;
    }
    &.clock-time {
      background: #D7EDFF;
    }
    &.lst {
      background: #FFFDED;
    }
  }

  span.label-note {
    color: #1D2129;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;

const Summary = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
flex-shrink: 0;
.summary-item {
  display: flex;
  border-right: 1px solid #CCD4DC;
  padding: 0px 48px;
  justify-content: center;
  align-items: center;
  gap: 24px;

  &:last-child {
    border-right: 1px solid transparent;
  }

  .summary-icon-wrapper {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;

    img {
      display: flex;
      width: 58px;
      height: 58px;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
    }

    &.walk-in {
      border: 1px solid #A95A23;
      background: #FFFDED;
    }
    &.appointment {
      border: 1px solid #2D6772;
      background: #C6E9EF;
    }
    &.ticket {
      border: 1px solid #C84B31;
      background: #FFF1E3;
    }
  }

  .summary-amount-container {
    display: flex;
    align-items: center;
    gap: 16px;
    .amount-value {
      color: #1D2129;
      font-family: Poppins;
      font-size: 60px;
      font-style: normal;
      font-weight: 400;
      line-height: 48px;
    }
    .amount-labels {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 2px;
      span.top-label {
        color: #505050;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      span.bottom-label {
        color: #505050;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
`;