import { Row, Switch } from 'antd';
import Text from 'components/Text';
import { parseDecimal } from 'features/cashier/services/constants';
import { IBillDetailData } from 'features/payment/services/types/bill';
import React, { useCallback } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import multiplePaymentSelectors from '../../../services/selectors';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  v2?: boolean;
  data: IBillDetailData | null;
  enableDiscountIncentive?: boolean;
  enableCardFee?: boolean;
  onChangeSwitchCardFee: (value: boolean) => void;
  onEnableCashIncentive: (value: boolean) => void;
};
const CardFeeDiscount = ({ v2, enableCardFee, enableDiscountIncentive, onChangeSwitchCardFee, onEnableCashIncentive }: Props) => {
  const detailPayment = multiplePaymentSelectors.getDetailPayment();
  const discountSetting = shopSelectors.data.discountSetting();
  const feeCreditCard = shopSelectors.data.feeCreditCard();

  const getFeeCreditValue = (subTotal: number) => {
    if (!enableCardFee) return 0;
    if (!subTotal) return 0;
    if (!feeCreditCard) return 0;
    if (feeCreditCard?.feeType === 'PERCENT')
      return Math.round(subTotal * (feeCreditCard?.feeValue / 100));
    if (feeCreditCard?.feeType === 'MONEY')
      return feeCreditCard?.feeValue || 0;
    return 0;
  };
  const getCashIncentiveDiscount = (subTotal: number) => {
    if (!enableDiscountIncentive) return 0;
    if (!subTotal) return 0;
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return Math.round(subTotal * (discountValue / 100));
    }
    if (discountType === 'MONEY')
      return parseDecimal(discountValue);
    return 0;
  };

  const labelFee = useCallback(() => {
    if (!feeCreditCard) return '';
    if (feeCreditCard?.feeType === 'PERCENT')
      return `${feeCreditCard?.feeValue}% (${formatCurrency(getFeeCreditValue(detailPayment.CREDIT_CARD?.amount || 0))})`;
    if (feeCreditCard?.feeType === 'MONEY')
      return formatCurrency(feeCreditCard?.feeValue);
  }, [detailPayment, feeCreditCard]);

  const labelCashDiscount = useCallback(() => {
    if (!discountSetting) return '';
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      const amount = getCashIncentiveDiscount((detailPayment.CASH?.amount || 0) + (detailPayment.CHECK?.amount || 0));
      return `${discountValue}% ${amount ? `(${formatCurrency(amount)})` : ''}`;
    }
    if (discountType === 'MONEY')
      return formatCurrency(discountValue);
  }, [discountSetting, detailPayment]);

  return (
    <Container style={v2 ? { height: 64 } : {}}>
      {discountSetting?.enableCashIncentive ?
        <Row style={{ flex: 1, gap: 16 }} align={'middle'} justify={'center'}>
          {feeCreditCard?.enable && <Row align={'middle'}>
            <div style={v2 ? { transform: 'scale(1.5)', marginRight: 16 } : {}}><Switch checked={enableCardFee} onChange={onChangeSwitchCardFee} /></div>
            <Text ml={0.5} variant="H6" color="text_3" style={{ fontWeight: 'bolder', fontSize: v2 ? 25 : 20 }}>
              Card Fee: {labelFee() || ''}
            </Text>
          </Row>}
          <Row align={'middle'}>
            <div style={v2 ? { transform: 'scale(1.5)', marginRight: 16, marginLeft: 16 } : {}}>
              <Switch checked={enableDiscountIncentive} onChange={onEnableCashIncentive} />
            </div>
            <Text ml={0.5} variant="H6" color="text_3" style={{ fontWeight: 'bolder', fontSize: v2 ? 25 : 20 }}>
              Cash/Check Incentive: {labelCashDiscount()}
            </Text>
          </Row>
        </Row> :
        <>
          {feeCreditCard?.enable && <Row align={'middle'} style={{ flex: 1, alignSelf: 'stretch' }}>
            <div style={v2 ? { transform: 'scale(1.5)', marginRight: 16, marginLeft: 16 } : {}}>
              <Switch checked={enableCardFee} onChange={onChangeSwitchCardFee} />
            </div>
            <Text ml={0.5} variant="H6" color="text_3" style={{ fontWeight: 'bolder', fontSize: v2 ? 25 : 20 }}>
              Card Fee: {labelFee() || ''}
            </Text>
          </Row>}
        </>
      }
    </Container>
  );
};

export default CardFeeDiscount;
const Container = styled.div`
 display: flex;
  height: 49px;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  background: #FFE5E7;
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled), .ant-switch.ant-switch-checked {
    background: #6FABB6;
  }
`;