import { Input, Select } from 'antd';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import useDebounce from 'hooks/useDebounce';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import search_icon from '../../../icons/search.svg';
import DatePicker from './DatePicker';
import DateType from './DateType';
import { CSSProperties, useMemo, useState } from 'react';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { TRANSACTION_STATUS } from 'features/ticketList/services/types/api';
import DropDownSelectStaff from 'widgets/Staff/DropDown';
import { IStaffItemData } from 'services/shop/types/staff';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { ALL_PAYMENT, paymentTypes } from '../../HeaderTickets';
import AddCloseTicket from 'features/ticketList/widgets/AddCloseTicket';
import moment, { Moment } from 'moment';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { ICreditCardFilterFunction } from 'features/ticketList/services/types/creditCard';

type Props = {
  type?: keyof typeof TRANSACTION_STATUS;
  quickView?: boolean;
  creditCardFilter?: ICreditCardFilterFunction;
  isCreditPage?: boolean;
};
const TicketFilterV2 = ({ isCreditPage, quickView, type = 'OPEN_TICKET', creditCardFilter = () => undefined }: Props) => {
  const dispatch = useAppDispatch();
  const ticketParam = ticketListSelectors.ticketsTableData.params();
  const [viewType, setViewType] = useState<CALENDAR_VIEW_TYPE>(CALENDAR_VIEW_TYPE.DAY_VIEW);
  const onSearchNo = (value: string) => {
    const params = {
      ticketNumber: value,
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  const date = useMemo(() => ({ startTime: ticketParam.startDate || '', endTime: ticketParam.endDate || '' }), [ticketParam]);

  const onChangeDate = (data: { startTime: string; endTime: string }) => {
    const params = {
      startDate: data.startTime,
      endDate: data.endTime,
      date: data.startTime,
    };
    creditCardFilter({ startDate: params.startDate, endDate: params.endDate });
    dispatch(ticketListActions.setTicketsParams(params));
  };

  const handleChangeStaff = (staff: IStaffItemData) => {
    const params = {
      staffId: staff.id,
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  const onChooseAllStaff = () => {
    const params = {
      staffId: '',
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  const handleChangePaymentType = (id: PAYMENT_TYPE) => {
    const params = {
      // @ts-ignore
      paymentType: id === ALL_PAYMENT ? undefined : id,
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  const onAddCloseTicketMutate = (date: Moment) => {
    const type = CALENDAR_VIEW_TYPE.DAY_VIEW;
    setViewType(type);
    const startDate = moment(date).format(DATE_FORMAT);
    const endDate = moment(date).format(DATE_FORMAT);
    const params = {
      startDate, endDate, date: date.format('MM-DD-YYYY'), page: 1,
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  switch (type) {
    case TRANSACTION_STATUS.DELETE:
    case TRANSACTION_STATUS.REFUND:
    case TRANSACTION_STATUS.VOID:
      return (
        <Container>
          <TicketNoInput text={ticketParam?.ticketNumber} onChangeText={onSearchNo} />
          <Select
            style={{ width: '100%' }}
            className='paymentTypeSelect'
            placeholder='All Payments'
            onChange={handleChangePaymentType}
            options={paymentTypes}
          />
          <DateType v2 twoWeeks viewType={viewType} onChangeValue={onChangeDate} onChangeViewType={setViewType} />
          <DatePicker v2 value={date} onChangeValue={onChangeDate} viewType={viewType} />
        </Container>
      );
    case TRANSACTION_STATUS.CLOSED:
      return (
        <Container>
          <TicketNoInput style={quickView ? { width: '20rem' } : undefined} text={ticketParam?.ticketNumber} onChangeText={onSearchNo} />
          {(!quickView && !isCreditPage) && <DropDownSelectStaff
            v2
            onChooseAllStaff={onChooseAllStaff}
            value={ticketParam.staffId}
            allStaff
            onChange={handleChangeStaff}
          />}
          {(!quickView && !isCreditPage) && <Select
            style={{ width: '100%' }}
            className='paymentTypeSelect'
            placeholder='All Payments'
            onChange={handleChangePaymentType}
            options={paymentTypes}
          />}
          <DateType twoWeeks={!quickView} v2 viewType={viewType} onChangeValue={onChangeDate} onChangeViewType={setViewType} />
          <DatePicker v2 value={date} onChangeValue={onChangeDate} viewType={viewType} />
          {!isCreditPage && <AddCloseTicket v2 mutate={onAddCloseTicketMutate} />}
        </Container>
      );
    default:
      return (
        <Container>
          <TicketNoInput text={ticketParam?.ticketNumber} onChangeText={onSearchNo} />
          <DateType twoWeeks v2 viewType={viewType} onChangeValue={onChangeDate} onChangeViewType={setViewType} />
          <DatePicker v2 value={date} onChangeValue={onChangeDate} viewType={viewType} />
        </Container>
      );
  }
};

export default TicketFilterV2;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;
  .ant-select .ant-select-selector .ant-select-selection-placeholder {
    color: #1D2129;
  }
  .ant-select .ant-select-selector .ant-select-selection-placeholder, .ant-select-selection-item, .ant-input-affix-wrapper >input.ant-input {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

const searchSuffix = <img src={search_icon} alt="search_icon" style={{ height: 24, width: 24 }} />;
const TicketNoInput = (props: { style?: CSSProperties, text?: string, onChangeText?: (val: string) => void }) => {
  const { text, setText } = useDebounce({ value: props?.text, onDebounce: props?.onChangeText });
  return (
    <Input
      className='ticketNumberInput'
      placeholder='Ticket No.'
      type='number'
      value={text}
      onChange={e => setText(e.target.value)}
      suffix={searchSuffix}
      style={props.style}
    />
  );
};
