import { Card, Col, Row } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import IconAndPrice, { IIconAndPriceProps } from 'features/report/components/IconAndPrice';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useAppDispatch } from 'store/hooks';
import reportSelectors from 'features/report/services/selectors';
import reportActions from 'features/report/services/actions';
import { ReportType } from '../../constants';
import clsx from 'clsx';
import { formatCurrency } from 'utils/formatCurrency';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface ICategoriesChart {
    v2?: boolean;
}
interface IListCategoriesItem extends IIconAndPriceProps { }
const defaultListCategoriesItem: IListCategoriesItem[] = [
    {
        title: 'TOTAL SALES',
        icon: 'moneyExchange',
        percent: 0,
        price: 0,
        increase: false,
        decrease: false,
        isPercentageInTittle: true,
    },
    {
        title: 'AVERAGE TICKET',
        icon: 'moneyExchange',
        percent: 0,
        price: 0,
        increase: false,
        decrease: false,
        isPercentageInTittle: true
    },
    {
        title: 'TOTAL SERVICES',
        percent: 0,
        price: 0,
        increase: false,
        decrease: false,
        isPercentageInTittle: true,
        isHidePercentage: true,
        isHideCurrency: true
    }
];

interface IDataChart {
    labels: string[],
    datasets: IDataset[]
}
interface IDataset {
    data: number[],
    backgroundColor: string,
    barThickness?: number,
    [key: string]: any
}
const CategoriesChart: React.FC<ICategoriesChart> = ({ v2 }) => {

    const [listCategoriesItem, setListCategoriesItem] = useState<IListCategoriesItem[]>(defaultListCategoriesItem);

    const [dataChart, setDataChart] = useState<IDataChart>({
        labels: [''],
        datasets: [{ data: [0], backgroundColor: 'red', barThickness: 60 }]
    });
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false
            },
            tooltips: {
                callbacks: {
                    label: () => undefined,
                }
            },
        }
    };
    const dispatch = useAppDispatch();
    const summaryServiceCategory = reportSelectors.getSummaryServiceCategory();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.SERVICES];
    useEffect(
        () => {
            if (dataAppointment?.startTime && dataAppointment?.endTime) {
                dispatch(reportActions.getSummaryServiceCategory.fetch({
                    startDate: dataAppointment?.startTime,
                    endDate: dataAppointment?.endTime
                }));
            }
        }, [dataAppointment]
    );

    useEffect(
        () => {
            setListCategoriesItem([
                {
                    title: 'NET SALES',
                    icon: 'moneyExchange',
                    percent: summaryServiceCategory?.netSalePercent ?? 0,
                    price: summaryServiceCategory?.netSale ?? 0,
                    increase: summaryServiceCategory?.netSalePercent >= 0,
                    decrease: summaryServiceCategory?.netSalePercent < 0,
                    isHidePercentage: (summaryServiceCategory?.netSalePercent ?? 0) == 0,
                },
                {
                    title: 'AVERAGE TICKET',
                    icon: 'ticketReport',
                    percent: summaryServiceCategory?.averagePercent ?? 0,
                    price: summaryServiceCategory?.averageTicket ?? 0,
                    increase: summaryServiceCategory?.averagePercent >= 0,
                    decrease: summaryServiceCategory?.averagePercent < 0,
                    isHidePercentage: (summaryServiceCategory?.averagePercent ?? 0) == 0,
                },
                {
                    title: 'TOTAL SERVICES',
                    icon: 'glass',
                    percent: summaryServiceCategory?.servicePercent ?? 0,
                    price: summaryServiceCategory?.totalService ?? 0,
                    increase: summaryServiceCategory?.servicePercent >= 0,
                    decrease: summaryServiceCategory?.servicePercent < 0,
                    isHidePercentage: (summaryServiceCategory?.servicePercent ?? 0) == 0,
                }
            ]);
            setDataChart({
                labels: summaryServiceCategory?.chartData?.map(item => item.categoryName),
                datasets: [
                    {
                        data: summaryServiceCategory?.chartData?.map(item => item.totalSale),
                        backgroundColor: v2 ? '#F5767F' : 'rgba(255, 136, 144, 1)',
                    }
                ]
            });
        }
        , [summaryServiceCategory]);

    return (
        <StyledCard className={v2 ? 'v2' : ''}>
            {!v2 && <Box>
                <Text variant="H5">CATEGORIES</Text>
            </Box>}
            <Chart>
                <Bar options={options} data={dataChart} style={{ width: '100%', height: 'auto' }} />
            </Chart>
            {v2 && (
                <StatisticV2>
                    {
                        listCategoriesItem.map(o => (
                            <StatisticItem key={o.title} label={o.title} value={formatCurrency(o.price)} percent={o.percent} increase={o.increase} decrease={o.decrease} />
                        ))
                    }
                </StatisticV2>
            )}
            {!v2 && (
                <Row gutter={[24, 24]}>
                    {
                        listCategoriesItem.map((item, index) => {
                            return (
                                <Col
                                    key={index}
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 12 }}
                                    lg={{ span: index == listCategoriesItem.length - 1 ? 6 : 9 }}
                                >
                                    <IconAndPrice
                                        title={item?.title}
                                        icon={item?.icon}
                                        percent={item?.percent}
                                        price={item?.price}
                                        increase={item?.increase}
                                        decrease={item?.decrease}
                                        isPercentageInTittle={item?.isPercentageInTittle}
                                        isHidePercentage={item?.isHidePercentage}
                                        isHideCurrency={item?.isHideCurrency}
                                        background={'#E5E6EB'}
                                    />
                                </Col>
                            );
                        })
                    }
                </Row>
            )}
        </StyledCard>
    );
};

export default CategoriesChart;

const StyledCard = styled(Card)`
    flex: 1;
    border-radius: 3.358px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0.672px solid #86909C;
    background: #FFF;
    &.v2 {
        border: none;
        box-shadow: none;
        padding-right: 16px;
        .ant-card-body {
            padding: 0;
        }
    }
`;

const Chart = styled.div`
    margin: 1rem 0rem;
    min-height: 400px;
    background: #F6F7FC;
`;

const StatisticV2 = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;
type StatisticItemProps = {
    value: string;
    label: string;
    increase?: boolean;
    decrease?: boolean;
    percent?: number;
}
const StatisticItem = ({ value, label, decrease, increase, percent }: StatisticItemProps) => {
    return <StatisticItemStyled>
        <div className="statistic-content">
            <div className="statistic-amount">
                <span className="amount">{value}</span>
                <span className="label">{label}</span>
            </div>
            <div className={clsx('statistic-percent', increase && 'increase', decrease && 'decrease')}>
                <span>{decrease ? '-' : '+'}{percent}%</span>
            </div>
        </div>
    </StatisticItemStyled>;
};

const StatisticItemStyled = styled.div`
display: flex;
padding: 0px 16px 0px 24px;
align-items: flex-start;
gap: 16px;
flex: 1 0 0;
border-radius: 4px;
.statistic-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
}

.statistic-amount {
    display: flex;
    flex-direction: column;
    gap: 0px;
    align-self: stretch;
    span.amount {
        flex: 1 0 0;
        color: #1D2129;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    span.label {
        align-self: stretch;
        color: #505050;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;
    }
}

.statistic-percent {
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    background: #E5E6EB;

    span {
        color: #64748B;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;

        &.increase {
            color: #2D6772;
        }
        &.decrease {
            color: #D2464F;
        }
    }
}
`;