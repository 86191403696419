import paymentActions from 'features/payment/services/actions';
import paymentSelectors from 'features/payment/services/selectors';
import { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ReceiptUI from './ReceiptUI';

const CheckInPrinter = () => {
  const data = paymentSelectors.getInfoPrintWaiting();
  const divRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const doPrint = useReactToPrint({
    content: () => divRef.current, onAfterPrint() {
      dispatch(paymentActions.getInfoPrintWaiting.success(null));
    },
  });

  useEffect(() => {
    if (!data) return;

    setTimeout(() => doPrint(), 10);

  }, [data]);

  if (storage.device_type.get() !== 'POS') return null;


  return (
    <CheckInPrinterStyled style={{ display: 'none' }}>
      <ReceiptUI data={data} divRef={divRef} />
    </CheckInPrinterStyled>
  );
};

export default CheckInPrinter;

const CheckInPrinterStyled = styled.div`
  // position: fixed;
  // inset: 0;
  // index: 999999;
  // background: red;

  .container {
    padding:0 1rem;
  }
`;
