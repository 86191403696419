import React from 'react';
import styled from 'styled-components';
type IComponentProps = {};
const Component: React.FC<IComponentProps> = () => {
  return (
    <ComponentStyled>
      Component Content
    </ComponentStyled>
  );
};

export default Component;
const ComponentStyled = styled.div``;

export const tableGreenStrStyle = `
.ant-table-thead > tr > th {
  background: #adadad;
  color: white;
}
.ant-table-tbody .ant-table-row {
  background: #fff;
  td {
    background: #fff;
    border-bottom: 1px solid #C9CDD4;
    &:hover {
      background: #fff;
    }
  }
  &:hover td {
    background: #fff;
  }
  
  &:nth-child(even) {
    background: #EAF5E8;
    td {
      background: #EAF5E8;
      border-bottom: 1px solid #C9CDD4;
      &:hover {
        background: #EAF5E8;
      }
    }
    &:hover td {
      background: #EAF5E8;
    }
  }
}

&.v2 {
  .ant-table-tbody .ant-table-row:nth-child(even) {
    background: #EEF4FF;
    td {
      background: #EEF4FF;
      border-bottom: 1px solid #C9CDD4;
      &:hover {
        background: #EEF4FF;
      }
    }
    &:hover td {
      background: #EEF4FF;
    }
  }
}

`;

export const TableGreenEvenStyled = styled.div`${tableGreenStrStyle}`;