import React from 'react';
import styled from 'styled-components';
const listTypes = [
  {
    label: 'Appointment',
    fill: '#C6E9EF',
    stroke: '#6FABB6',
    percent: 0.5,
  },
  {
    label: 'Confirm',
    fill: '#BCEBCB',
    stroke: '#87D68D',
    percent: 0.1,
  },
  {
    label: 'Cancel',
    fill: '#FFE5E7',
    stroke: '#FF8890',
    percent: 0.1,
  },
  {
    label: 'No show',
    fill: '#F6F7FC',
    stroke: '#E5E5E5',
    percent: 0.1,
  },
  {
    label: 'On Break',
    fill: '#FEEDBF',
    stroke: '#FFDC7C',
    percent: 0.1,

  },
  {
    label: 'Block Hours',
    fill: '#ADADAD',
    stroke: '#ADADAD',
    percent: 0.1,
  },
];

const StatusBar = () => {
  return (
    <StatusBarStyled>
      {listTypes.map((o, i) => (
        <div key={i} className="status-box-item" >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="12" cy="12" r="12" fill={o.stroke} />
          </svg>
          <span>{o.label}</span>
        </div>
      ))}
    </StatusBarStyled>
  );
};

export default StatusBar;
const StatusBarStyled = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    .status-box-item {
      height: 3.5rem;
      background: #fff;
      display: flex;
      align-items: center;
      border: 1.5px solid #1D2129;
      border-radius: 3.5rem;
      gap: 8px;
      padding: 0 8px;
      padding-right: 16px;
      span {
        color: #1D2129;
        text-align: center;
        text-transform: uppercase;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
`;
