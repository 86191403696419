import { Form, Input, Rate, Row } from 'antd';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { IReviewReportItem } from 'features/report/services/types/review';
import { forwardRef, useImperativeHandle, useRef, useState, useTransition } from 'react';
import styled from 'styled-components';
export type ModalEditNoteProps = {
  onSubmit?: (detail: IReviewReportItem, data: { review: string, stars: number }) => void;
  v2?: boolean;
};
type ModalEditNoteRef = {
  open: (data: IReviewReportItem) => void;
  close: () => void;
};

export const useModalEditNoteRef = () => useRef<ModalEditNoteRef>(null);

const ModalEditNote = forwardRef<ModalEditNoteRef, ModalEditNoteProps>(({ v2, onSubmit = () => undefined }, ref) => {
  const [data, setData] = useState<IReviewReportItem | null>(null);
  const [open, setOpen] = useState(false);
  const [stars, setStars] = useState(0);
  const [review, setReview] = useState('');
  const [, startTransition] = useTransition();

  const handleClose = () => startTransition(() => {
    setData(null);
    setOpen(false);
    setStars(0);
    setReview('');
  });

  const handleOpen: ModalEditNoteRef['open'] = (_data) => startTransition(() => {
    if (!_data) return;
    setData(_data);
    setReview(_data.reason || '');
    setStars(_data.stars);
    setOpen(true);
  });

  const handleSubmit = () => {
    if (!data) return;
    onSubmit(data, { review, stars });
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    open: (_data) => handleOpen(_data),
    close: () => handleClose(),
  }));

  if (v2) {
    return (
      <Modal
        modalTitle="Note"
        v2
        visible={open}
        onClose={handleClose}
        noneBodyStyle
        containerPadding={0}
        okTitle={'Done'}
        onSubmit={handleSubmit}
        className={'modal-add-new-appointment modal-non-opacity'}
        width={'800px'}
        showClose={false}
      >
        <ContainerFormV2>
          <div className="header">
            <span className='title'>Customer review</span>
            <Rate value={stars} onChange={setStars} style={{ fontSize: 32 }} />
          </div>
          <Input.TextArea rows={7} placeholder='Enter customer review ...'
            value={review}
            onChange={e => setReview(e.target.value || '')}
          />
        </ContainerFormV2>
      </Modal>
    );
  }

  return (
    <Modal
      visible={open}
      modalTitle='Note'
      okTitle='Done'
      onClose={handleClose}
      onSubmit={handleSubmit}
      containerPadding={1}
      noneBodyStyle
      disableOk={Boolean(stars === data?.stars) && Boolean(review === (data?.reason || ''))}
    >
      <Form layout='vertical'>
        <Row align={'middle'} justify={'space-between'} style={{ gap: '8px' }}>
          <Text variant='CONTENT_1' style={{ marginTop: '2px' }}>Review star:</Text>
          <Rate value={stars} onChange={setStars} />
        </Row>
        <Form.Item label='Note:'>
          <Input.TextArea
            rows={4}
            value={review}
            onChange={e => setReview(e.target.value || '')}
            placeholder='Please note ...'
            maxLength={159}
            showCount
          />
        </Form.Item>
      </Form>

    </Modal>
  );
});
ModalEditNote.displayName = 'ModalEditNote';
export default ModalEditNote;

const ContainerFormV2 = styled.div`
  padding: 16px;
  .header {
    display: flex;
    padding: 16px 0px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    align-self: stretch;
    padding-top: 0;
    span.title {
      color: #64748B;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .ant-input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;