import { Switch } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import ButtonApplySupplyPrice from './ButtonApplySupplyPrice';
import ButtonNewCategory from './ButtonNewCategory';
import { IBodyCreateSimpleMenu } from 'features/settingService/services/types/simpleMenu';
import shopSelectors from 'services/shop/selectors';
import ButtonReOrderCategories from './ButtonReOrderCategories';
type Props = {
  loading?: boolean;
  empty?: boolean;
  onApplySupplyFee?: (value: number) => void;
  onCreateNewCate?: (body: IBodyCreateSimpleMenu) => void;
  onEnableCheckIn?: (value: boolean) => void;
  onReOrderCate?: (ids: string[]) => void;
};
const HeaderActions = ({ loading, empty, onApplySupplyFee, onCreateNewCate, onEnableCheckIn = () => undefined, onReOrderCate = () => undefined }: Props) => {
  const enableCheckIn = shopSelectors.data.simpleMenuConfigCheckIn();
  const [isChecked, setIsChecked] = useState(false);

  const handleEnableCheckIn = (value: boolean) => {
    setIsChecked(value);
    onEnableCheckIn(value);
  };

  useEffect(() => {
    setIsChecked(enableCheckIn);
  }, [enableCheckIn]);

  if (loading) return (
    <HeaderActionsStyled>
      <div className="section-title">Simple Menu</div>
    </HeaderActionsStyled>
  );

  if (empty) return (
    <HeaderActionsStyled>
      <div className="section-title">Simple Menu</div>
      <div style={{ flex: 1 }} />
      <ButtonNewCategory onSubmit={onCreateNewCate} />
    </HeaderActionsStyled>
  );

  return (
    <HeaderActionsStyled>
      <div className="section-title">Simple Menu</div>
      <div style={{ flex: 1 }} />
      <button className='switch-button' onClick={() => handleEnableCheckIn(!enableCheckIn)}>
        <Switch defaultChecked={enableCheckIn} checked={isChecked} onChange={handleEnableCheckIn} />
        <span>Check in</span>
      </button>
      <div className="divider" />
      <ButtonApplySupplyPrice onSubmit={onApplySupplyFee} />
      <div className="divider" />
      <ButtonNewCategory onSubmit={onCreateNewCate} />
      <ButtonReOrderCategories onSubmit={onReOrderCate} />
    </HeaderActionsStyled>
  );
};

export default HeaderActions;
const HeaderActionsStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
  padding-right: 24px;
  .divider {
    background: #C9CDD4;
    width: 1px;
    height: 80%;
  }
  .section-title {
    color: #1D2129;
    font-family: "Open Sans";
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .switch-button {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      text-transform: capitalize;
      color: #1D2129;
      text-align: right;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .custom-button {
    box-shadow: none;
  }
`;