import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import styled from 'styled-components';
import colorTheme from 'constants/color';
import Box from 'components/Box';

function ItemsDraggable(props: {
  id: any;
  children:
  | string
  | number
  | boolean
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | Iterable<React.ReactNode>
  | React.ReactPortal
  | null
  | undefined;
  data: any,
  highlightItemId?: boolean,
  active?: boolean;
  enableSwitchStaff?: boolean,
  className?: string;
  enableDrag?: boolean,
}) {
  const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef } = useDraggable({
    id: props.id,
    data: props.data,
  });
  const styles = transform
    ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      borderRadius: '5px',
      backgroundColor: '#2D3043',
    }
    : undefined;
  return (
    <>
      <div {...props} ref={setActivatorNodeRef} className={`${props.className} ${transform ? 'transform' : ''}`} style={{ display: 'flex', alignItems: 'center', ...styles }}>
        <DraggableStyled
          {...listeners}
          {...attributes} ref={setNodeRef}
        >
          {props.enableDrag && <DragIconContainer>
            <svg viewBox="0 0 20 20" width="12" >
              <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"
                // @ts-ignore
                fill={transform ? '#fff' : (props?.highlightItemId ? '#fff' : '#2D3043')}
              ></path>
            </svg>
          </DragIconContainer>}
        </DraggableStyled>
        <Box display='flex' alignSelf='stretch' style={{ flex: 1 }}>
          {props.children}
        </Box>
      </div >
    </>
  );
}
export default ItemsDraggable;

const DraggableStyled = styled.div`
  .service-item {
    border: 1px solid ${colorTheme.line_2};
  }
  .service-item:last-child {
    border: none;
  }
`;

const DragIconContainer = styled.button`
display: flex;
align-items: center;
justify-content: center;
align-self: stretch;
width: 40px;
height: 40px;
svg {
transform: scale(1.5);
}
`;