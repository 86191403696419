import clsx from 'clsx';
import { TURN_ITEM_STATUS, turnCount } from 'features/turn/services/constants';
import { turnManualActions } from 'features/turn/services/reducers';
import { turnManualSelectors } from 'features/turn/services/selectors';
import { ITurnManualItem } from 'features/turn/services/types/turn';
import { get } from 'lodash';
import React from 'react';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import { TurnStatus } from '../../constanst';
import TurnBodyItem from './TurnBodyItem';
import TurnStaffTopRow, { IStaffTurnItem } from './TurnStaffTopRow';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import userApis from 'features/user/services/apis';
import shopSelectors from 'services/shop/selectors';
type Props = {
  staffs: IStaffTurnItem[];
  nextStaffId?: string;
};
const TurnTableData = ({ staffs = [], nextStaffId }: Props) => {
  const setLoading = useSetLoadingPage();
  const modal = useModalConfirmRef();
  const visibleItemName = turnManualSelectors.visibleItemName();
  const staffActiveId = turnManualSelectors.staffActiveId();
  const activeTurnManual = turnManualSelectors.activeTurnManual();
  const allSetting = shopSelectors.data.allSetting();

  const dispatch = useAppDispatch();
  const onClickStaff = (o: IStaffTurnItem) => () => {
    dispatch(turnManualActions.setStaffActive(o.staffId));
  };

  const handleChooseTurn = (o: IStaffAvailableItem, index: number) => () => {
    const key = `${index}/${o.staffId}`;
    dispatch(turnManualActions.setStaffKeyActive(key));
  };

  const onClickEdit = (existTurn: ITurnManualItem) => () => {
    dispatch(turnManualActions.setActiveTurnManual(existTurn));
  };
  const onVoiceCall = (staff: IStaffTurnItem) => () => {
    modal.current?.open(`Do you want to call ${staff.staffName}?`, async () => {
      setLoading(true);
      try {
        await userApis.callVoiceNotification(staff.staffId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    });
  };

  return (
    <>{
      staffs.map((staff: IStaffTurnItem) => {
        return (
          <React.Fragment key={staff.staffId}>
            <tr>
              <td className={clsx('staff-top-row-sticky', staff.staffId === staffActiveId && 'active')}>
                <TurnStaffTopRow
                  data={staff}
                  onClickStaff={onClickStaff(staff)}
                  appointments={staff.appointments}
                  onVoiceCall={onVoiceCall(staff)}
                  voiceCallStaff={allSetting?.voiceCallStaff}
                />
              </td>
              {Array.from(Array(turnCount).keys()).map((o, i) => {
                const existTurn = get(staff.turns, [i]);
                const key = `${i}/${staff.staffId}`;
                const isStaffNext = staff.staffId === nextStaffId;
                return (
                  <TurnBodyItem
                    active={activeTurnManual ? (
                      typeof activeTurnManual === 'object' ? (activeTurnManual?.turnId === existTurn?.turnId) : key === (activeTurnManual as string)
                    ) : false}
                    key={existTurn?.turnId || o}
                    turnId={existTurn?.turnId}
                    full={existTurn?.turn === 1}
                    half={existTurn?.turn == 0.5}
                    minus={existTurn?.status === TURN_ITEM_STATUS.MINUS_TURN}
                    work={existTurn?.status === TURN_ITEM_STATUS.IN_PROGRESS}
                    next={isStaffNext && staff.turns.length === i}
                    type={existTurn?.type?.replace('-', '_') as any}
                    serName={existTurn?.itemName}
                    completed={existTurn?.status === TurnStatus.DONE}
                    onClickNew={handleChooseTurn(staff, i)}
                    onClickEdit={onClickEdit(existTurn)}
                    visibleItemName={visibleItemName}
                  />
                );
              })}
              <td style={{ width: '100%' }}></td>
            </tr>
            <tr>
              <td className='staff-top-row-sticky' style={{ padding: '0', background: '#fff' }}><div style={{ borderBottom: '1px solid #DDD' }}></div></td>
              <td colSpan={turnCount} style={{ padding: '8px 0', background: '#fff' }}><div style={{ borderBottom: '1px solid #DDD' }}></div></td>
              <td style={{ width: '100%', background: '#fff' }}></td>
            </tr>
          </React.Fragment>
        );
      })
    }
      <ModalConfirm ref={modal} okTitle='Yes' cancelTitle='No' />
    </>
  );
};

export default TurnTableData;
