import customerSelectors from 'features/customer/services/selectors';
import { get } from 'lodash';
import styled from 'styled-components';
import { formatNumber } from 'utils/formatCurrency';
import customer_birthday_icon from '../icons/customer-birthday.svg';
import customer_not_return_icon from '../icons/customer-not-return.svg';
import customer_return_icon from '../icons/customer-return.svg';
import total_customer_icon from '../icons/total-customer.svg';

const DashboardSalaryTypes = {
  TOTAL_CUSTOMER: 'TOTAL_CUSTOMER',
  CUSTOMER_RETURN: 'CUSTOMER_RETURN',
  CUSTOMER_NOT_RETURN: 'CUSTOMER_NOT_RETURN',
  BIRTHDAY_COMING: 'BIRTHDAY_COMING',
};
const configs = {
  [DashboardSalaryTypes.TOTAL_CUSTOMER]: {
    label: 'Total Customers',
    icon: total_customer_icon,
  },
  [DashboardSalaryTypes.CUSTOMER_RETURN]: {
    label: 'Customers Return',
    icon: customer_return_icon,
  },
  [DashboardSalaryTypes.CUSTOMER_NOT_RETURN]: {
    label: 'Customers  Not Return',
    icon: customer_not_return_icon,
  },
  [DashboardSalaryTypes.BIRTHDAY_COMING]: {
    label: 'Birthday Coming',
    icon: customer_birthday_icon,
  },

};

const CustomerOverviews = () => {
  const data = customerSelectors.customerSummary();
  return (
    <>
      <StatisticsContainer>
        <DashboardItem type='TOTAL_CUSTOMER' value={formatNumber(data?.totalCustomer)} />
        <DashboardItem type='CUSTOMER_RETURN' value={formatNumber(data?.customerReturn)} />
        <DashboardItem type='CUSTOMER_NOT_RETURN' value={formatNumber(data?.customerNotReturn)} />
        <DashboardItem type='BIRTHDAY_COMING' value={formatNumber(data?.birthdayComing)} />
      </StatisticsContainer>
    </>
  );
};

export default CustomerOverviews;

const StatisticsContainer = styled.div`
display: flex;
padding: 24px 16px;
justify-content: center;
align-items: center;
gap: 16px;
align-self: stretch;
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
`;

type DashboardItemProps = {
  type: keyof typeof DashboardSalaryTypes;
  value: string;
};
export const DashboardItem = ({ type, value }: DashboardItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return <DashboardItemStyled>
    <div className="content">
      <p className="label">{config.label}</p>
      <p className="value">{value}</p>
    </div>

    <div className="icon-button">
      <img src={config.icon} alt={config.label} />
    </div>

  </DashboardItemStyled>;
};
const DashboardItemStyled = styled.div`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid #CCD4DC;
background: #FFF;
position: relative;

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    p.label {
        align-self: stretch;
        color: #505050;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;
    }
    p.value {
        align-self: stretch;
        color: #1D2129;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 16px;
  border-radius: 100px;
  border: 1px solid #FFF;
  img {
    height: 72px;
    width: 72px;
  }
}
`;
