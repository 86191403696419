import { Input, InputRef } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modal';
import NumberKeyBoard, { PIN_LENGTH, useNumberKeyBoardRef } from 'components/NumberKeyBoard';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import userActions from 'features/user/services/actions';
import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef, useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const ButtonClockIn = ({ isClockIn, render, v2 }: { v2?: boolean, isClockIn: boolean, render?: (onPress: () => void) => any }) => {
  const inputRef = useRef<InputRef>(null);
  const kbRef = useNumberKeyBoardRef();
  const [visible, setVisible] = useState(false);
  const onClockIn = () => {
    setVisible(true);
    kbRef.current?.focusInput();
    setTimeout(() => inputRef.current?.focus(), 100);
  };
  const [pin, setPin] = useState('');
  const dispatch = useAppDispatch();

  const [, startTransition] = useTransition();
  const handleClose = () => startTransition(() => {
    setPin('');
    kbRef.current?.reset();
    setVisible(false);
  });

  const handle = useCallback((_pin: string) => {
    const newPin = _pin.replace(/[^0-9]/g, '');
    if (newPin.length < 4) return;
    handleClose();
    if (isClockIn) {
      dispatch(userActions.doClockIn.fetch(newPin));
      return;
    }

    dispatch(userActions.doClockOut.fetch(newPin));
  }, [isClockIn]);

  const _debounce = useCallback(
    debounce((_pin) => {
      handle(_pin);
    }, 500),
    [isClockIn]
  );

  useEffect(() => _debounce(pin), [pin]);

  if (v2) {
    const _onChange = (text: string) => {
      if (text.length > PIN_LENGTH) return;
      setPin(text);
    };

    return (
      <>
        {render ? render(onClockIn) : (
          <Button
            ntype={'SECONDARY'} icon={isClockIn ? 'clockIn' : 'clockOut'}
            onClick={onClockIn}
          >
            {isClockIn ? 'Check In' : 'Check Out'}
          </Button>
        )}
        <Modal
          modalTitle='PASSWORD'
          hiddenOkButton
          v2
          noneBodyStyle
          width={'800px'}
          visible={visible} onClose={handleClose}>
          <InputUIV2>
            <Input
              ref={inputRef}
              name="input_password"
              value={pin}
              autoFocus
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              inputMode='none'
              className={'password-input'}
              onChange={(e) => {
                _onChange(e.target.value);
              }}
              maxLength={PIN_LENGTH}
              autoComplete="off"
            />
          </InputUIV2>
          <NumberPadV2
            value={pin} onChange={(val) => _onChange(val || '')}
            type='PIN-PASSWORD'
          />
        </Modal>
      </>
    );
  }

  return (
    <>
      {render ? render(onClockIn) : (
        <Button
          ntype={'SECONDARY'} icon={isClockIn ? 'clockIn' : 'clockOut'}
          onClick={onClockIn}
        >
          {isClockIn ? 'Check In' : 'Check Out'}
        </Button>
      )}
      <Modal
        modalTitle='password'
        hiddenOkButton
        visible={visible} onClose={handleClose}>
        <NumberKeyBoard
          ref={kbRef}
          type='PIN-PASSWORD'
          value={pin} onChange={(val) => setPin(val || '')}
          scanQRPassword
        />
      </Modal>
    </>
  );
};

export default ButtonClockIn;

const InputUIV2 = styled.div`
align-self: stretch;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px 0;
  .password-input {
    -webkit-text-security: disc;
    text-security: disc;
    &.scanQR {
      input {
        text-align: center;
        padding-left: 50px;
      }
    }
  }
input {
  color: #D2464F;
  flex: 1;
  text-align: center;
  font-family: Poppins;
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
}
`;