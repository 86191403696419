import { Avatar, Form } from 'antd';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import anybodyImg from 'assets/icon-anybody.png';
import ServiceHandler from './ServiceHandler';
type Props = {
  value?: IStaffItemData;
};
const StaffHandler = ({ value }: Props) => {
  if (!value) return null;
  return (
    <Container>
      <Content>
        <div className="staff-info">
          {value.anyBody ? <img src={anybodyImg} alt="anybodyImg" style={{ width: 64, height: 64, borderRadius: 64 }} /> : <Avatar size={64} src={value.urlImage || 'fail'}>{value.firstName?.[0] || 'A'}</Avatar>}
          <span>{value?.firstName || '--'}</span>
        </div>
        <Form.Item noStyle name={'services'}>
          <ServiceHandler />
        </Form.Item>
      </Content>
    </Container>
  );
};

export default StaffHandler;

const Content = styled.div`
display: flex;
padding-bottom: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-radius: 4px;
border-bottom: 1px solid #DDD;
.staff-info {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}
`;
const Container = styled.div`
flex: 1;
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
background: #F6F7FC;
`;