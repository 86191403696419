import { Col, Row } from 'antd';
import { typeIcon } from 'assets/Icons';
import IconAndPrice from 'components/IconAndPrice';
import colorTheme from 'constants/color';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { DashboardItem } from './TabsV2/CloseTicketSummary';
import { formatCurrency, formatNumber } from 'utils/formatCurrency';
const labelStyle: CSSProperties = {
  fontSize: '1.5rem',
};
type Props = {
  v2?: boolean;
};
const SummaryCreditCards = ({ v2 }: Props) => {
  const summaryData = ticketListSelectors.summaryCreditCards();
  if (v2) {
    return (
      <ContainerV2>
        <DashboardItem type='TOTAL_TRANS' value={formatNumber(summaryData?.totalTrans)} />
        <DashboardItem type='SALES' value={formatCurrency(summaryData?.totalSale)} />
        <DashboardItem type='TIP' value={formatCurrency(summaryData?.totalTip)} />
        <DashboardItem type='FEES' value={formatCurrency(summaryData?.totalFeeCredit)} />
        <DashboardItem type='VOIDED_TRANS' value={formatCurrency(summaryData?.totalVoid)} />
        <DashboardItem type='REFUNDED_TRANS' value={formatCurrency(summaryData?.totalRefund)} />
      </ContainerV2>
    );
  }
  return (
    <IconAndPriceContainerStyled style={{ display: 'flex', flex: 1 }}>
      <Row gutter={[24, 24]} align={'middle'} style={{ flex: 1 }}>
        <ItemSummary
          amount={summaryData?.totalTrans || 0}
          currency={false}
          label={'Total Transactions'}
          icon={'ticket'}
        />
        <ItemSummary
          amount={summaryData?.totalSale || 0}
          label={'Sales'}
          icon={'moneyExchange'}
        />
        <ItemSummary
          amount={summaryData?.totalTip || 0}
          label={'Tips'}
          icon={'moneyExchange'}
        />
        <ItemSummary
          amount={summaryData?.totalFeeCredit || 0}
          label={'Fees'}
          icon={'moneyExchange'}
        />
        <ItemSummary
          amount={summaryData?.totalVoid || 0}
          label={'Voided Transactions'}
          icon={'moneyExchange'}
        />
        <ItemSummary
          amount={summaryData?.totalRefund || 0}
          label={'Refunded Transactions'}
          icon={'moneyExchange'}
        />
      </Row>
    </IconAndPriceContainerStyled>
  );
};

export default SummaryCreditCards;

const IconAndPriceContainerStyled = styled.div`
  padding: 0rem 1rem;
  gap: 1rem;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: white;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .padding-vertical {
    padding: 15px 0px;
  }
  .item-ticket-price {
    border-right: 1px solid #c9cdd4;
  }
  .item-ticket-price:last-child {
    border-right: none;
  }
  .isVerticalBorder {
    border-right: none;
  }
`;

const ItemSummary = ({ label, amount, icon, currency }: { label: string, amount: number | null | undefined, icon: typeIcon, currency?: boolean }) => {
  return (
    <Col flex={1}>
      <div className={'item-ticket-price padding-vertical'}>
        <IconAndPrice
          amount={amount}
          currency={currency}
          title={label}
          icon={icon}
          labelStyle={labelStyle}
        />
      </div>
    </Col>
  );
};

const ContainerV2 = styled.div`
display: flex;
padding: 24px 16px;
justify-content: center;
align-items: center;
gap: 16px;
align-self: stretch;
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
flex: 1;
`;