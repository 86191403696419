import React, { useRef } from 'react';
import cashierSelectors from 'features/cashier/services/selectors';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';
import ModalExistDiscount, { ModalExistDiscountRefs } from './ModalExistDiscount';
import DiscountMethodModal, { ISubmitModalDiscount } from './DiscountMethodModal';

const DiscountItemModal = ({ v2 }: { v2?: boolean }) => {
  const visibleExistRef = useRef<ModalExistDiscountRefs>(null);
  const dispatch = useAppDispatch();
  const draftData = cashierSelectors.getDraftDiscountItemModal();
  const close = () => {
    dispatch(cashierActions.setDraftDiscountItemModal(null));
  };

  const handleSubmit: ISubmitModalDiscount = (discount) => {
    if (!draftData) return;
    const callback = () => dispatch(cashierActions.setDiscountItem({
      masterData: draftData,
      discountValue: discount,
    }));

    if (!draftData?.discount) {
      callback();
      return;
    }

    visibleExistRef.current?.open(callback);
  };

  return (
    <>
      {!!draftData && <DiscountMethodModal
        v2={v2}
        visible
        onClose={close}
        onSubmit={handleSubmit}
        initialAmount={draftData?.amount ?? 0}
        isItemDiscount
      />}
      <ModalExistDiscount ref={visibleExistRef} target='item' />
    </>
  );
};

export default DiscountItemModal;
