import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import CurrencyInputMasked from './Input';
import { toCurrency } from './currency-conversion.js';

export const unmaskedCurrency = (value: string) => {
  return +(value?.replaceAll('_', '')?.replaceAll('$', '')?.replaceAll(',', '') ?? '');
};

type Props = {
  maxValue?: number;
  defaultValue?: number | null;
  onChange?: (value: Props['defaultValue'], val: string) => void;
  onChangeDebounce?: (value: Props['defaultValue'], val: string) => void;
  setTextStr?: (text: string) => void;
};

export const useCurrencyInputRef = () => useRef<CurrencyInputRef>(null);

export type CurrencyInputRef = {
  setValue: (val: string, unFocus?: boolean) => void;
  setSoftValue: (val: string) => void;
};
const CurrencyTipInput = forwardRef<CurrencyInputRef, Props>(({ maxValue, defaultValue, setTextStr = () => undefined }, ref) => {
  const inputRef = useRef<{ inputRef: HTMLInputElement }>(null);
  const [text, setText] = useState<string>();

  useImperativeHandle(ref, () => ({
    setValue: (val: string, unFocus = false) => {
      if (!unFocus) inputRef.current?.inputRef?.focus();
      if (maxValue) {
        const raw = unmaskedCurrency(toCurrency(val));
        if (raw > maxValue) return;
      }
      setText(toCurrency(val));
      setTextStr(toCurrency(val));
    },
    setSoftValue: (val: string) => {
      if (maxValue) {
        const raw = unmaskedCurrency(toCurrency(val));
        if (raw > maxValue) return;
      }
      setText(toCurrency(val));
    }
  }));

  return (
    <CurrencyInputMasked
      // @ts-ignore
      ref={inputRef}
      defaultValue={defaultValue}
      value={text}
      onChange={(event: any, formatStr: any) => {
        if (maxValue) {
          const raw = unmaskedCurrency(formatStr);
          if (raw > maxValue) return;
        }
        setText(formatStr);
        setTextStr(formatStr);
      }}
    />
  );
});
CurrencyTipInput.displayName = 'CurrencyTipInput';
export default CurrencyTipInput;


export const CurrencyInputWrapStyled = styled.div`
  input {
    box-sizing: border-box;
    margin: 0;
    padding: 3px 11px;
    color: #1D2129;
    font-size: 16px;
    line-height: 1.5;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #86909C;
    border-radius: 5px;
    transition: all 0.2s;

    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    margin: 0;
    height: 3rem;
    &:hover, &:focus, &:focus-visible {
      outline: none;
      border-color: #ffb0b3;
      border-inline-end-width: 1px;
    }
  }

  input {
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
  }
`;


export const CurrencyInputFormStyled = styled.div`
  input{
    
    box-sizing: border-box;
    margin: 0;
    padding: 3px 11px;
    color: #1D2129;
    font-size: 16px;
    line-height: 1.5;
    list-style: none;
    font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-color: #ffffff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #86909C;
    border-radius: 5px;
    transition: all 0.2s;
    margin:0;
    height: 3rem;
    width: 100%;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    outline: none;
    &:hover, &:focus {
      border-color: #ffb0b3;
      border-inline-end-width: 1px;
    }
  }
`;