import { Avatar, Dropdown, Space } from 'antd';
import Icon from 'assets/Icons';
import { ITurns } from 'features/turn/services/types';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { FULL_TURN_WITDH, HALF_TURN_WITDH, MIN_TURN_BOX_WITDH_COL_SPAN, TURN_BOX_HEIGHT, TURN_BOX_WITDH, TurnStatus, TurnType, TurnTypeSortName } from '../constanst';
import ModalDetail from './ModalDetail';
import TurnTableHooks, { IItemDataTable } from './hooks/TurnTableHooks';
import Text from 'components/Text';
import Box from 'components/Box';
const colGroupItemStyle: CSSProperties = { minWidth: MIN_TURN_BOX_WITDH_COL_SPAN, width: MIN_TURN_BOX_WITDH_COL_SPAN, maxWidth: MIN_TURN_BOX_WITDH_COL_SPAN };
const TurnTable: React.FC<{ search: string }> = ({ search }) => {
  const {
    header,
    dataTable,
    showModalTurnDetail,
    loadingTable,
    scaleView,
    staffAvailableData,
    ColArray,
    items,
    sort,
    getBlockStatus,
    getBlockType,
    onClodeModalDetail,
    checkIsHalfTurn,
    onPressBlock
  } = TurnTableHooks({ search });

  return (
    <TurnTableComponent>
      {staffAvailableData?.length > 0 && !loadingTable && <TurnTableContainer id="turn-table-container">
        <TableBox className="table-box" scale={scaleView}>
          <table>
            <colgroup>
              <col />
              <col span={(header.length ?? 0) * 2} style={colGroupItemStyle} />;
            </colgroup>
            <thead>
              <tr>
                <th key='header-name'>
                  <Dropdown
                    menu={{
                      items,
                      selectable: true,
                      selectedKeys: sort ? [sort] : [],
                    }}
                    trigger={['click']}
                  >
                    <a onClick={(e) => {
                      e.preventDefault();
                    }}>
                      <Space>
                        <Filter scale={scaleView}><Icon type='filter' fill={sort ? '#f5767f' : undefined} /><span style={sort ? { color: '#f5767f' } : undefined}>Turn By</span></Filter>
                      </Space>
                    </a>
                  </Dropdown>
                </th>
                {header.map((_: any, index: number) => {
                  return <th key={index} className="header-col" colSpan={2}><div style={{ borderRight: '1px solid #DDD' }}><span>{index + 1}</span></div></th>;
                })}
              </tr>
            </thead>
            <tbody>
              {dataTable?.map((record: IItemDataTable) => {
                let isShowedBefore = false;
                return (
                  <tr key={record.staffId}>
                    <th key={record.staffId + '_' + record.firstName}>
                      <NameColumn>
                        <ImageContainer scale={scaleView}>
                          <Avatar
                            src={record?.urlImage || 'fail'}
                            size={70}
                          >{record.firstName?.charAt(0)}</Avatar>
                        </ImageContainer>
                        <Text variant='CONTENT_2' className='text-overflow' style={{ textAlign: 'left', maxWidth: 80, minWidth: 80 }}>{record.firstName}</Text>
                      </NameColumn>
                    </th>
                    {ColArray(record?.data || []).map((turn: ITurns[], indexTurn: number, arr: ITurns[][]) => {
                      if (turn && turn.length > 0) {
                        const isShowTime = [TurnStatus.FINISH_TURN, TurnStatus.DONE].includes(turn[0]?.status);
                        return <><TurnBox key={turn[0].staffId + '_' + turn[0].turnId} scale={scaleView} colSpan={(turn[0]?.turn ?? 1) * 2} ishalf={checkIsHalfTurn(turn[0].turn)}>
                          <TurnBlock
                            scale={scaleView}
                            className={`${getBlockStatus(turn[0].status)} turn-block-ui ${turn[0].type}`}
                            onClick={(event: any) => onPressBlock(event, turn, record.staffId)}
                          >
                            <BlockTagNum scale={scaleView} className={`tb-turn-tag-num ${getBlockType(turn[0].type)}`}>#{turn[0].billNumber}</BlockTagNum>
                            <Box display='flex' flexDirection='column' alignItems='center' style={{ flex: 1, marginTop: isShowTime ? 0 : 6 }}>
                              <BlockStatusName scale={scaleView} ishalf={checkIsHalfTurn(turn[0].turn)}>{TurnTypeSortName?.[turn[0].type]} </BlockStatusName>
                              {isShowTime && <Text style={{ fontWeight: '800', textAlign: 'center' }} variant='CAPTION_2' color='text_3'>{turn[0]?.finishTime}</Text>}
                            </Box>
                          </TurnBlock>
                        </TurnBox>
                        </>
                          ;
                      }
                      if (isShowedBefore) {
                        isShowedBefore = false;
                        return <></>;
                      } else {
                        if (!arr?.[indexTurn]?.[0].turn) isShowedBefore = true;
                        const calColSpan = () => {
                          const isNextTurnEmpty = !arr?.[indexTurn + 1]?.[0]?.turn;
                          const isPreviousTurnEmpty = !arr?.[indexTurn - 1]?.[0]?.turn;
                          const totalTurnIsOdd = () => arr.reduce((accumulator, item) => accumulator + (item?.[0]?.turn ?? 0) * 2, 0) % 2 != 0;
                          if (isNextTurnEmpty && totalTurnIsOdd()) {
                            if (isPreviousTurnEmpty) {
                              return 2;
                            }
                            return 1;
                          }
                          return 2;
                        };
                        const colSpan = calColSpan();
                        const isHalf = checkIsHalfTurn(colSpan / 2);
                        return <TurnBox key={record.staffId + '_' + indexTurn}
                          ishalf={isHalf}
                          scale={scaleView}
                          colSpan={colSpan}
                          onClick={(event: any) => onPressBlock(event, turn, record.staffId)}>
                          <TurnBlock scale={scaleView} />
                        </TurnBox>;
                      }
                    }
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <ModalDetail key={Math.random()} isShow={showModalTurnDetail} onClose={onClodeModalDetail} />
        </TableBox>
      </TurnTableContainer>}
    </TurnTableComponent>
  );
};

export default TurnTable;
const TURN_TABLE_STATUS_SIZE = 16;
const TurnTableComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  flex-shrink: 0;
`;

const TurnTableContainer = styled.div`
  position: relative;
  max-height: 100%;
  height: 100%;
  z-index: 1;
  max-width: 100%;
  overflow: scroll;
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  @keyframes fadeInAnimation {
      0% {
          opacity: 0;
      }
      100% {
          opacity: 1;
      }
  }
`;

const TableBox = styled.div<{ scale: number }>`
    position: absolute;
    inset: 0;

    table {
    /* width: 100%; */
    position: relative;
    border-collapse: collapse;
    user-select:none;
    -webkit-user-select:none;
    }
    thead th {
    position: -webkit-sticky; /* for Safari */
    position: sticky;
    top: 0;
    z-index: 2;
    /* max-width: ${props => (TURN_BOX_WITDH) * (props.scale / 100)}px; */
    /* min-width: ${props => (TURN_BOX_WITDH) * (props.scale / 100)}px; */
    padding:  ${props => 4 * (props.scale / 100)}px 0px;
    border-left: unset !important;
    border-right: unset !important;
    height: ${props => 3.5 * (props.scale / 100)}rem;
    background: var(--fill-fill-0, #fff);
    box-shadow:
    inset 0 1px 0 #86909c,
    inset 0 -1px 0 #86909c;
        .ant-dropdown-trigger {
            color: unset;
        }
    }
    thead th.header-col {
      text-align: center;
    }
    thead th.header-col  div {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: center;
    }
    thead th.header-col  div  span {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #e5e6eb;
      width: ${props => 40 * (props.scale / 100)}px;
      height: ${props => 40 * (props.scale / 100)}px;
      font-size: ${props => 1 * (props.scale / 100)}rem;
      flex-shrink: 0;
      border-radius: 50px;
      border: 1px solid #86909C;
    }
    thead th:first-child {
      left: 0;
      z-index: 3;
      color: var(--text-text-2, #767676);
      font-family: Open Sans;
      font-size: ${props => 16 * (props.scale / 100)}px;
      min-width:  ${props => (TURN_BOX_WITDH) * (props.scale / 100)}px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    
    tbody {
      background: var(--fill-fill-1, #f6f7fc);
    }
    tbody th {
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: 0;
      z-index: 2;
      background: #f6f7fc;
    }
    
    .tb-turn-status-bar {
      display: flex;
    }
    
    .tb-selected {
      border: 1px dashed #f5767f;
    }

    .tb-turn-status--walkin {
      background: #000;
      font-size:  ${props => TURN_TABLE_STATUS_SIZE * (props.scale / 100)}px;
    }
    .tb-turn-status--appointment {
      background: #000;
      font-size:  ${props => TURN_TABLE_STATUS_SIZE * (props.scale / 100)}px;
    }
    .tb-turn-status--request {
      background: #000;
      font-size:  ${props => TURN_TABLE_STATUS_SIZE * (props.scale / 100)}px;
    }
    .tb-turn-status--bonus {
      background: #000;
      font-size:  ${props => TURN_TABLE_STATUS_SIZE * (props.scale / 100)}px;
    }
    .turn-block-ui {
      background: #FFF;
      padding: 2px 0px;
      &.${TurnType.WALK_IN} {
        border: 2px solid #4E268F;
      }
      &.${TurnType.APPOINTMENT} {
        border: 2px solid #75C569;
      }
      &.${TurnType.BONUS} {
        border: 2px solid #C52884;
      }
      &.${TurnType.REQUEST} {
        border: 2px solid #4384FF;
      }

      &.tb-turn-block--in-progress {
       background: #FDFEDB;
      }
      &.tb-turn-block--finish {
        cursor: pointer;
        background: #EEF4FF;
      }
      &.tb-turn-block--voided {
        cursor: pointer;
        border: 1px solid #86909C;
        background: #FFEAF6;
      }
      &.tb-turn-block--done {
        cursor: pointer;
        background: #E6FAE2;
      }

    }
    
    .tb-turn-block--late {
      background: var(--blue-headline, #86909C);
      border: 1px solid var(--info-infor-3, #adadad);
    }
`;
const TurnBox = styled.td<{ ishalf?: boolean, scale: number }>`
  flex-wrap: nowrap;
  margin:  ${props => 5 * (props.scale / 100)}px 0px 0px;
  width: ${props => (props.ishalf ? HALF_TURN_WITDH : FULL_TURN_WITDH) * (props.scale / 100)}px;
`;
const TurnBlock = styled.div<{ scale: number }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${props => 8 * (props.scale / 100)}px 0px;
  justify-content: center;
  align-items: center;
  height: ${props => (TURN_BOX_HEIGHT - 5) * (props.scale / 100)}px;
  background: #e5e6eb;
  border-radius: 5px;
  border: 1px solid #86909C;
  width: 100%;
  position: relative;
  z-index: 1;
`;


const BlockStatusName = styled.div<{ ishalf?: boolean, scale: number }>`
    color: var(--text-text-3, #1d2129);
    text-align: center;
    font-family: "Open Sans";
    font-size: ${props => 1 * (props.scale / 100)}rem;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    overflow: hidden;
    text-overflow: clip;
    text-transform: uppercase;
    width: ${props => 0.5 * TURN_BOX_WITDH * (props.scale / 100) + 'px'};
`;

const BlockTagNum = styled.div<{ scale: number }>`
    border-radius: 0.25rem;
    background: #000;
    padding: 0 ${props => 0.25 * (props.scale / 100)}rem;
    text-align: center;
    align-self: flex-end;
    margin-right: 2px;
    font-size:  ${props => TURN_TABLE_STATUS_SIZE * (props.scale / 100)}px;
    color: #FFF;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`;
const Filter = styled.div<{ scale: number }>`
    display: flex;
    align-items: center;
    justify-content: center;
    flex:1;
    span {
       padding-left: ${props => 4 * (props.scale / 100)}px;
    }
`;
const NameColumn = styled.div`
    display: flex;
    align-items: center;
    .circle-avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    .span {

    }
`;
const ImageContainer = styled.div<{ scale: number }>`
    min-width: ${props => 40 * (props.scale / 100)}px;
    padding: 0 ${props => 10 * (props.scale / 100)}px;
    span {
        width: ${props => 40 * (props.scale / 100)}px;
        height:${props => 40 * (props.scale / 100)}px;
        line-height: ${props => 38 * (props.scale / 100)}px;;
    }
`;
