import Spin from 'components/Spin';
import appointmentSelectors from 'features/appointment/services/selectors';
import React, { memo } from 'react';
import { styled } from 'styled-components';

const LoadingCalendar = () => {
    const loading = appointmentSelectors.getCalendarLoading();
    if (!loading) return null;

    return (
        <LoadingStyled ><Spin /></LoadingStyled>
    );
};

export default memo(LoadingCalendar);

const LoadingStyled = styled.div`
position: absolute;
inset: 0;
z-index: 99;
display: flex;
align-items: center;
background: rgba(255, 255, 255, 0.6);
justify-content: center;
`;