import Modal from 'components/Modal';
import { Moment } from 'moment';
import TimeSlotPicker, { TimeSlotPickerRef } from './TimeSlotPicker';
import colorTheme from 'constants/color';
type Props = {
  visible?: boolean;
  onClose?: () => void;
  staffId: string;
  timeSlot: Moment | null;
  setTimeSlot: (dat: Moment) => void;
  timePickerRef: React.RefObject<TimeSlotPickerRef>;
};
const ModalChooseTime = ({ onClose, visible, timeSlot, staffId, setTimeSlot, timePickerRef }: Props) => {

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      modalTitle='Change Time'
      hiddenFooter
      closable
      showClose
      noneBodyStyle
      noneFooterStyle
      width={'70vw'}
      className='modal-overflow-unset modal-max-height-unset'
      headerBgColor={colorTheme.info_bg_3}
      hiddenOkButton
      containerPadding={1.5}
      footer={<></>}
    >
      <TimeSlotPicker
        ref={timePickerRef}
        staffId={staffId}
        timeSlot={timeSlot}
        onChooseTimeSlot={setTimeSlot}
      />
    </Modal>
  );
};

export default ModalChooseTime;
