import { Avatar, Row } from 'antd';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { get } from 'lodash';
import { useState } from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { styled } from 'styled-components';
import DatePickerLabel, { DatePickerLabelProps } from '../DatePickerLabel';
import StaffForm from './StaffForm';
import AddStaffFormV2 from 'widgets/Staff/AddStaffFormV2';
type Props = {
  staff?: IStaffItemData | null;
  onAddStaff?: (val: IStaffItemData) => void,
  onClose?: () => void;
  v2?: boolean;
};

const ModalChooseStaff = ({ v2, staff, onAddStaff = () => undefined }: Props) => {
  const [visible, setVisible] = useState(false);
  const onClose = () => setVisible(false);
  const handleAddStaff = (data: IStaffItemData) => {
    onAddStaff(data);
    setVisible(false);
  };

  if (v2) {
    return (
      <>
        <div className="row-staff">
          {staff ?
            <Row align={'middle'}>
              <Avatar size={'small'} src={staff.urlImage}>{get(staff.firstName, [0], '')}</Avatar>
              <ButtonLink onClick={() => setVisible(true)}>
                <Text ml={0.5} variant='TEXT_LINK'>{staff.firstName}</Text>
              </ButtonLink>
            </Row>
            :
            <ButtonLink onClick={() => setVisible(true)}>
              <Text ml={0.5} variant='TEXT_LINK'>{'Add staff'}</Text>
            </ButtonLink>}
        </div>
        <Modal
          v2
          visible={!!visible}
          modalTitle='SELECT STAFF'
          noneBodyStyle
          hiddenOkButton
          onClose={onClose}
          width={1600}
        >
          <AddStaffFormV2
            onPickStaff={handleAddStaff}
          />
        </Modal>
      </>
    );
  }

  return (
    <>
      <div className="row-staff">
        {staff ?
          <Row align={'middle'}>
            <Avatar size={'small'} src={staff.urlImage}>{get(staff.firstName, [0], '')}</Avatar>
            <ButtonLink onClick={() => setVisible(true)}>
              <Text ml={0.5} variant='TEXT_LINK'>{staff.firstName}</Text>
            </ButtonLink>
          </Row>
          :
          <ButtonLink onClick={() => setVisible(true)}>
            <Text ml={0.5} variant='TEXT_LINK'>{'Add staff'}</Text>
          </ButtonLink>}
      </div>
      <Modal
        visible={visible}
        modalTitle={'SELECT STAFF'}
        hiddenOkButton={true}
        footer={<></>}
        noneBodyStyle
        noneFooterStyle={true}
        containerPadding={1.5}
        footerPadding={0.5}
        showClose
        onClose={onClose}
        width={'60rem'}
      >
        <StaffForm
          onPickStaff={handleAddStaff}
        />
      </Modal>
    </>
  );
};

export default ModalChooseStaff;
type ModalForceChooseStaffProps = {
  onPickStaff: (val: IStaffItemData) => void,
  onClose?: () => void;
  date?: DatePickerLabelProps['value'],
  onChangeDate: DatePickerLabelProps['onChange'],
};
export const ModalForceChooseStaff = ({ onPickStaff, onClose, date, onChangeDate }: ModalForceChooseStaffProps) => {
  return (
    <Modal
      visible
      modalTitle={'SELECT STAFF'}
      hiddenOkButton={true}
      footer={<></>}
      noneBodyStyle
      noneFooterStyle={true}
      containerPadding={1.5}
      footerPadding={0.5}
      showClose
      onClose={onClose}
      width={'60rem'}
    >
      <DatePickerLabel
        value={date}
        onChange={onChangeDate}
      />
      <StaffForm
        onPickStaff={onPickStaff}
      />
    </Modal>
  );
};

const ButtonLink = styled.button`
`;