import clsx from 'clsx';
import Box from 'components/Box';
import Button from 'components/Button';
import { DollarAmountInputRef } from 'components/DollarAmount/helpers';
import { InputWithKeyboardStyled } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import CurrencyKeyBoard, { CurrencyKeyBoardRef, ICurrencyKeyBoard } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import { IShopAllSetting } from 'services/shop/types/setting';
import { styled } from 'styled-components';
type Props = {
  onBack: () => void;
  onDone: () => void;
  onPay: () => void;
  onClear: () => void;
  remaining: number;
  value?: ICurrencyKeyBoard['value'];
  onChange?: ICurrencyKeyBoard['onChange'];
  keyboardRef: React.RefObject<CurrencyKeyBoardRef>;
  inputRefV2?: React.RefObject<DollarAmountInputRef>;
  disabledClose?: boolean;
  v2?: boolean;
  allSetting?: IShopAllSetting | null;
};
const PaymentKeyboard = ({
  onBack,
  keyboardRef,
  inputRefV2,
  value,
  onClear,
  onChange,
  onDone,
  onPay,
  remaining,
  disabledClose,
  v2,
  allSetting,
}: Props) => {
  return (
    <PaymentKeyboardStyled className={clsx(v2 && 'v2')}>
      <div className='keyboard'>
        {v2 && allSetting?.activeFormatMoney ? <>
          <Box display='flex' flexDirection='column' gap='2'>
            <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
              Enter Amount:
            </Text>
            <InputWithKeyboardStyled
              ref={inputRefV2}
              value={value}
              onChange={onChange}
              inputMode='none'
            />
            <DollarAmountKeyboard
              controlRef={inputRefV2}
              value={value}
              onChange={onChange}
            />
          </Box>
        </> : (
          <CurrencyKeyBoard v2={v2} ref={keyboardRef} value={value} onChange={onChange} />
        )}
      </div>
      {v2 ? <>
        <ButtonsV2>
          <button onClick={onBack} type="button" className="btn btn-close"><span>Close</span></button>
          <button onClick={onClear} type="button" className="btn btn-clear"><span>Clear</span></button>
          <button onClick={remaining > 0 ? onPay : onDone} type="button" className="btn btn-done"><span>{remaining > 0 ? 'Pay' : 'Done'}</span></button>
        </ButtonsV2>
      </> : (
        <Box className="center" mt='2'>
          <Box display="flex" gap="2">
            {!disabledClose && <Button
              width="8.25rem"
              ntype="SECONDARY"
              onClick={onBack}
            >
              Close
            </Button>}
            <Button onClick={onClear} width="8.25rem" ntype="DEFAULT">
              Clear
            </Button>
            {remaining > 0 ? <Button onClick={onPay} width="8.25rem" ntype="PRIMARY">
              Pay
            </Button> : <Button onClick={onDone} width="8.25rem" ntype="PRIMARY">
              Done
            </Button>}
          </Box>
        </Box>)}
    </PaymentKeyboardStyled>
  );
};

export default PaymentKeyboard;

const PaymentKeyboardStyled = styled.div`
  flex-grow:1;
  display:flex;
  flex-direction: column;
  padding: 1rem 0;
  .keyboard {
    flex: 1;
    padding: 0 1.5rem;
  }

   &.v2 {
    background: #F5F5F5;
    padding: 0;
    .keyboard {
      flex: unset;
      padding: 0;
    }
  }
`;

const ButtonsV2 = styled.div`
display: flex;
align-self: stretch;
padding: 0 16px;
gap: 16px;
padding-bottom: 16px;
.btn {
  display: flex;
  height: 68px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  background: #E5E6EB;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.btn-clear {
    background: #C6E9EF;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
  }
  &.btn-done {
    background: #FF8890;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
  }
}
`;