import { Space } from 'antd';
import Icon from 'assets/Icons';
import Spin from 'components/Spin';
import Text from 'components/Text';
import userSelectors from 'features/user/services/selectors';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

const Summary = () => {
  const { totalBooking = 0, averageTickets = 0, totalWalkIn = 0 } = userSelectors.getSummaryData() ?? {};
  const loading = userSelectors.getSummaryDataLoading();
  return (
    <ContainerStyled style={{ opacity: loading ? 0.5 : 1 }}>
      {loading && <div className='spin'><Spin /></div>}

      <Styled className='compact' style={{ width: '100%' }}>
        <Space className='box  yellow-headline' direction="horizontal" align='center'>
          <div className="icon-block">
            <Icon type='customerInfo' />
          </div>
          <div>
            <Text variant='CONTENT_2'> {totalWalkIn} Customers</Text>
            <Text variant='BODY_1'>Walk in</Text>
          </div>
        </Space>
        <div className="divider" />
        <Space className='box blue-headline' direction="horizontal" align='center'>
          <div className="icon-block">
            <Icon type='calendar' />
          </div>
          <div>
            <Text variant='CONTENT_2'>{totalBooking} Customers</Text>
            <Text variant='BODY_1'>Appointments</Text>
          </div>
        </Space>
        <div className="divider" />
        <Space className='box primary-button' direction="horizontal" align='center'>
          <div className="icon-block">
            <Icon type='totalIcon' />
          </div>
          <div>
            <Text variant='CONTENT_2'>{formatCurrency(averageTickets)}</Text>
            <Text variant='BODY_1'>Average Tickets</Text>
          </div>
        </Space>
      </Styled>
    </ContainerStyled>
  );
};

export default Summary;
const ContainerStyled = styled.div`
.spin {
  position: absolute;
  inset: 0;  
  display: flex;
  align-items: center;
  justify-content: center;
}
position: relative;
.box  {
  background: var(--fill-fill-0, #FFF);
  padding: 1rem 1.5rem;
  flex:1;
  .icon-block {
    margin-right: 1rem;
    padding:0.5rem;
    border-radius: 10px;
    background: #FFF;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
}
`;
const Styled = styled(Space.Compact)`
&.compact {
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-0, #FFF);
  overflow: hidden;
}
  
  .divider {
    background: #C9CDD4;
    width: 1px;
  }

  .blue-headline {
    background: var(--blue-headline, #C6E9EF);
  }

  .yellow-headline {
    background: var(--yellow-headline, #FEEDBF);
  }

  .primary-button {
    background: var(--primary-button, #FFE5E7);
  }
`;
