import { Col, Form, Input, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { IEditCateBodyType } from 'features/settingService/services/types/api';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { ADD_ON_CATE_ID } from 'services/shop/constants';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import AddServiceButton from '../../ButtonModal/AddServiceButton';

type IFormData = {
  id: string;
  categoryName: string;
  sub_catagories: number;
  supplyFee: number;
  services: IServiceItemData[],
  new_services: IServiceItemData[],
};

type IAddServiceCatesButtonProps = {
  cateSelected: ICategoryItemData | null;
  onAddMoreServiceForCate: (body: IEditCateBodyType) => void;
};
const AddServiceCatesButton: React.FC<IAddServiceCatesButtonProps> = ({ cateSelected, onAddMoreServiceForCate }) => {
  const [form] = Form.useForm<IFormData>();
  const [open, setOpen] = useState(false);
  const sub_catagories = Form.useWatch('sub_catagories', form);
  const inputRef = useRef<InputRef>(null);
  const [errorIndex, setErrorIndex] = useState<string[]>([]);

  const clearErrorByIndex = (index: string) => {
    const newErrors = errorIndex.filter(
      (item) => String(item) !== String(index)
    );
    setErrorIndex(newErrors);
  };

  const handleShowModal = () => {
    setOpen(true);
    form.resetFields();
    if (!cateSelected) return;
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields(['new_services', 'sub_catagories']);
  };

  const handleFinish = async (values: IFormData) => {
    if (!cateSelected) return;
    const body: IEditCateBodyType = {
      categoryId: cateSelected?.id || '',
      services:
        values.new_services?.map((item) => ({
          serviceName: item.serviceName || '',
          priceSell: Number(item.priceSell) || 0,
          duration: Number(item.duration) || 0,
          supplyFee: Number(item.supplyFee) || 0,
          categoryId: cateSelected?.id,
          addOn: cateSelected?.id === ADD_ON_CATE_ID,
        })) || [],
    };
    onAddMoreServiceForCate(body);
    handleCloseModal();
  };

  useEffect(() => {
    if (sub_catagories < 100 && sub_catagories > 0) {
      const newService: IServiceItemData[] = Array.from({
        length: sub_catagories,
      }).map((_item, index) => ({
        orderNumber: index + 1,
        id: '',
        catId: '',
        serviceName: '',
        duration: 15,
        priceSell: 0,
        priceCost: 0,
        commission: 0,
        supplyFee: cateSelected?.supplyFee || 0,
      }));

      form.setFieldsValue({ new_services: newService });
    } else {
      form.setFieldsValue({ new_services: [] });
    }
  }, [sub_catagories]);
  const focusInput = debounce(() => {
    inputRef.current?.focus();
  }, 100);

  useEffect(() => {
    if (open && inputRef.current) {
      focusInput();
    }
  }, [open, inputRef]);

  return (
    <>
      <Button onClick={handleShowModal} ntype='GHOST' size='small' className="btn-action"><Icon type='addService' /></Button>
      <Modal
        width={'62rem'}
        modalTitle={!cateSelected?.services?.length ? 'ADD SERVICES' : 'ADD MORE SERVICES'}
        visible={open}
        onClose={handleCloseModal}
        onSubmit={() => form.submit()}
        destroyOnClose={true}
        okTitle="Save"
        cancelTitle="Close"
      >
        <Form form={form} onFinish={handleFinish}>
          <Box p="4">
            <Row gutter={[24, 24]}>
              <Col span={8}>
                <Text color="text_3" variant="CONTENT_1">
                  Catagory Name
                </Text>
                <Text color="text_3" variant="H9">
                  {cateSelected?.categoryName}
                </Text>
              </Col>

              <Col span={8}>
                <Text color="text_3" variant="CONTENT_1">
                  Supply Fee
                </Text>
                <Text color="text_3" variant="H9">
                  {formatCurrency(cateSelected?.supplyFee || 0)}
                </Text>
              </Col>
              <Col span={8}>
                <Text color="text_3" variant="CONTENT_1">
                  Sub-Catagories Available
                </Text>
                <Text color="text_3" variant="H9">
                  {cateSelected?.services?.length || 0}
                </Text>
              </Col>

              <Col span={24}>
                <Box className="center">
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="2"
                    mb="3"
                    width={'50%'}
                  >
                    <Text color="text_3" variant="CONTENT_1">
                      Add More Sub-Catagories
                    </Text>
                    <FormItemNoMargin name="sub_catagories">
                      <Input type={'number'} ref={inputRef} />
                    </FormItemNoMargin>
                  </Box>
                </Box>
              </Col>
            </Row>
            <Form.List name="new_services">
              {(fields) => {
                return (
                  <Row gutter={[12, 12]}>
                    {fields?.map(({ key, name, ...restField }, index) => {
                      const isHaveError = errorIndex.some(
                        (item) => Number(item) === index
                      );
                      return (
                        <Col span={6} key={key}>
                          <AddServiceButton
                            name={name}
                            nameField="new_services"
                            restField={restField}
                            index={index}
                            clearErrorByIndex={clearErrorByIndex}
                          />
                          {isHaveError && (
                            <Text variant="CAPTION_1" color="error_4">
                              Check Validate
                            </Text>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                );
              }}
            </Form.List>
          </Box>
        </Form>
      </Modal>
    </>
  );
};

export default AddServiceCatesButton;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0;
`;
