import MultipleSelect from 'components/MultipleSelect';
import appointmentActions from 'features/appointment/services/actions';
import { listAppointmentStatus } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
type ITypeItemPickerProps = {
  v2?: boolean;
};

const allTypes = listAppointmentStatus.map(o => o.value);

const TypeItemPicker: React.FC<ITypeItemPickerProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const param = appointmentSelectors.table.getParamKey<string[]>('status');

  const values = useMemo(() => {
    if (!param?.length) return allTypes;
    return param;
  }, [param]);

  const handleChange = (status: string[]) => {
    const isSelectAll = status.length === allTypes.length;
    dispatch(appointmentActions.setTableParams({ status: isSelectAll ? [] : status, page: 1 }));
  };

  return (
    <MultipleSelect
      className='AptStatusFilter'
      width={'10rem'}
      style={v2 ? { flex: 1 } : undefined}
      value={values}
      onChange={handleChange}
      options={listAppointmentStatus}
    />
  );
};

export default TypeItemPicker;
