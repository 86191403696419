import appointmentActions from 'features/appointment/services/actions';
import appointmentSelectors from 'features/appointment/services/selectors';
import React from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import { useAppDispatch } from 'store/hooks';
import { ButtonActions } from '../helpers';
import ServicePicker from './ServicePicker';
type IServicesTabProps = {
  next: () => void;
  back: () => void;
};
const ServicesTab: React.FC<IServicesTabProps> = ({ next, back }) => {
  const dispatch = useAppDispatch();
  const selectedList = appointmentSelectors.newAptState.services();

  const handleAddService = (s: IServiceItemData) => {
    dispatch(appointmentActions.newApt.addService(s));
  };
  const handleRemoveService = (s: IServiceItemData) => {
    dispatch(appointmentActions.newApt.removeService(s));
  };

  return (
    <>
      <ServicePicker
        selected={selectedList}
        onAddService={handleAddService}
        onRemoveService={handleRemoveService}
      />
      <ButtonActions
        onBack={back}
        onOk={next}
        disabledOk={!selectedList.length}
      />
    </>
  );
};

export default ServicesTab;
