import { Form } from 'antd';
import { ITurnServiceItem } from 'features/turn/services/types/service';
import React from 'react';
import styled from 'styled-components';
import TurnAmount from '../TurnAmount';
type Props = {
  name?: any;
  value?: ITurnServiceItem;
  onChange?: (value: ITurnServiceItem) => void;
  onRemove?: (value?: ITurnServiceItem) => void;
  onEditName: (serviceName: string, onChange: (value: string) => void) => void;
};
const TurnServiceItem = ({ value, name, onRemove = () => undefined, onEditName, onChange = () => undefined }: Props) => {
  const handleEditName = () => {
    if (!value) return;
    const onChangeName = (itemName: string) => onChange({ ...value, itemName });
    onEditName(value.itemName, onChangeName);
  };
  const handleRemove = () => onRemove(value);

  return (
    <Container>
      <div className="turn-name-container">
        <span onClick={handleEditName}>{value?.itemName || ''}</span>
        <div className="turn-name-actions">
          <button onClick={handleRemove} type='button' className="btn btn-delete">{iconDelete}</button>
          <button onClick={handleEditName} type='button' className="btn btn-edit">{iconEdit}</button>
        </div>
      </div>
      <Form.Item name={[name, 'turn']}>
        <TurnAmount />
      </Form.Item>
    </Container>
  );
};

export default TurnServiceItem;

const Container = styled.div`
display: flex;
padding: 16px 12px;
flex-direction: column;
align-items: flex-start;
gap: 24px;
flex: 1 0 0;
border-radius: 5px;
border: 1px solid #DDD;
background: #FFF;
box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);

.turn-name-container {
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  span {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}

.turn-name-actions {
  display: flex;
  align-items: center;
  gap: 16px;

  button.btn {
    width: 40px;
    height: 40px;
  }
}

.turn-checkbox-container {
  .turn-checkbox {
    flex: 1;
    .label-checkbox {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
`;

const iconDelete = <svg xmlns="http://www.w3.org/2000/svg" width="41" height="41" viewBox="0 0 41 41" fill="none">
  <g clipPath="url(#clip0_11089_13003)">
    <path fillRule="evenodd" clipRule="evenodd" d="M30.8087 40.5L32.1826 13.6061H7.46091L8.83482 40.5H30.8087ZM17.3166 3.65826L8.49047 0.5L7.31656 3.65826L32.3166 12.1667L33.4905 9.00841L25.0609 6.41652L26 3.88957L18.2557 1.12957L17.3166 3.65826ZM14.0492 17.8914L11.3205 18.034L12.3205 37.0218L15.0509 36.8792L14.0492 17.8914ZM21.3813 17.9488H18.6509V36.9644H21.3813V17.9488ZM25.9831 17.8914L28.7118 18.034L27.7118 37.0218L24.9813 36.8792L25.9831 17.8914Z" fill="#DA072D" />
  </g>
  <defs>
    <clipPath id="clip0_11089_13003">
      <rect width="40" height="40" fill="white" transform="translate(0.649902 0.5)" />
    </clipPath>
  </defs>
</svg>;

const iconEdit = <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_11089_13007)">
    <path d="M26.3618 0.675529C27.5924 0.505331 28.8733 0.72123 29.9729 1.31219C30.5916 1.63053 31.1158 2.09778 31.6718 2.51225C32.2797 2.98974 32.9372 3.41997 33.4358 4.02354C34.298 5.02109 34.8331 6.30703 34.9207 7.63236C35.0238 9.09402 34.5896 10.5911 33.7149 11.7581C28.688 18.3619 23.6534 24.9594 18.6249 31.5617C18.4946 31.7429 18.3194 31.8895 18.1124 31.9714C14.6766 33.396 11.2439 34.8293 7.80808 36.2547C7.33974 36.4604 6.75586 36.2469 6.50541 35.8009C6.29372 35.4605 6.37824 35.0437 6.39452 34.6662C6.59225 31.1417 6.79153 27.618 6.98848 24.0935C7.01484 23.6932 6.97917 23.2433 7.25444 22.9179C12.165 16.4606 17.0772 10.0025 21.9878 3.54446C22.2825 3.16388 22.5562 2.7636 22.9004 2.42557C23.8216 1.47373 25.0607 0.852818 26.3618 0.675529ZM25.1631 3.33329C24.2124 3.88171 23.6906 4.89817 23.002 5.71842C25.6492 7.77735 28.3112 9.81499 30.963 11.8668C31.3081 11.4082 31.657 10.9536 32.0044 10.4966C32.5542 9.77481 32.8496 8.85684 32.8209 7.94439C32.7953 6.75852 32.2014 5.60811 31.2724 4.89502C30.6482 4.41752 30.0295 3.93371 29.4014 3.4633C28.1716 2.58631 26.4448 2.54298 25.1631 3.33329ZM9.53489 23.4355C12.1945 25.4598 14.8557 27.4825 17.5083 29.5154C21.559 24.1951 25.6221 18.8843 29.6643 13.557C27.0101 11.5154 24.359 9.46908 21.711 7.4204C17.6533 12.7596 13.5895 18.0948 9.53489 23.4355ZM8.57184 33.619C10.9011 32.6483 13.232 31.6791 15.5628 30.7115C13.3801 29.0458 11.1942 27.3832 9.0115 25.7175C8.86805 28.3516 8.71219 30.9849 8.57184 33.619Z" fill="#2D6772" />
    <path d="M1.32927 38.998C1.56251 38.9389 1.81403 38.9551 2.05794 38.9535C14.5809 38.9551 27.1039 38.9525 39.6269 38.9551C40.165 38.9499 40.6482 39.2767 40.6482 39.6458C40.6841 40.0495 40.1383 40.4087 39.5529 40.3795C26.9545 40.3836 14.3561 40.3789 1.75763 40.3795C1.29955 40.3972 0.846797 40.1954 0.707314 39.8937C0.517526 39.5423 0.817071 39.1203 1.32927 38.998Z" fill="#2D6772" />
  </g>
  <defs>
    <clipPath id="clip0_11089_13007">
      <rect width="40" height="40" fill="white" transform="translate(0.649902 0.5)" />
    </clipPath>
  </defs>
</svg>
  ;