import { Form } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import React from 'react';
import { ISimpleMenuItem, ISimpleMenuServiceItem } from 'services/shop/types/simpleMenu';
import styled from 'styled-components';
import PopoverChangeColors from '../ServicesSettingV2/ServicePart/PopoverChangeColors';

type Props = {
  value?: ISimpleMenuItem;
  name?: any;
  onOpenEditName?: (cate: ISimpleMenuItem | null) => void
  onDeleteCategory: (value: ISimpleMenuItem | null) => void;
};
const CategoryItem = ({ value: data, name, onOpenEditName = () => undefined, onDeleteCategory }: Props) => {
  return (
    <CategoryItemStyled>
      <div className="card-header">
        <span>{data?.categoryName}</span>
        <Button onClick={() => onDeleteCategory(data || null)} ntype='GHOST' size='small' className="btn-action"><Icon type='deleteService' /></Button>
        <Button onClick={() => onOpenEditName(data || null)} ntype='GHOST' size='small' className="btn-action"><Icon type='editName' /></Button>
      </div>
      <div className="card-body">
        <Form.List name={[name, 'services']}>
          {(fields) => (
            <>
              {fields.map(({ name: serviceName, key }) => (
                <React.Fragment key={key}>
                  <Form.Item name={serviceName}>
                    <ServiceItem name={serviceName} />
                  </Form.Item>
                </React.Fragment>
              ))}
            </>
          )}
        </Form.List>

      </div>
    </CategoryItemStyled>
  );
};

export default CategoryItem;

const CategoryItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  .card-header {
    display: flex;
    height: 48px;
    padding: 4px 8px;
    align-items: center;
    align-self: stretch;
    border-radius: 2px;
    border: 1px solid #86909C;
    background: #E5E5E5;
    margin-bottom: 12px;
    span {
      flex: 1;
      color: #555;
      font-family: "Open Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    .btn-edit {
      // background: red;
      width: 52px;
    }
  }

  .card-body {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  }
`;
type ServiceItemProps = {
  value?: ISimpleMenuServiceItem,
  name?: any;
};
const ServiceItem = ({ value, name }: ServiceItemProps) => {
  return (
    <ServiceItemStyled>
      <Box display='flex' alignItems='center' justifyContent='space-between'>
        <p className="service-name">{value?.serviceName}</p>
        <Form.Item noStyle name={[name, 'backgroundColor']}>
          <PopoverChangeColors />
        </Form.Item>
      </Box>
      <div className="service-price-box">
        <span>Price</span>
        <Form.Item name={[name, 'price']} noStyle>
          <CurrencyInputPrecision width={95} size='small' height={38} />
        </Form.Item>
      </div>
      <div className="service-price-box">
        <span>Supply Fee</span>
        <Form.Item name={[name, 'supplyFee']} noStyle>
          <CurrencyInputPrecision width={95} size='small' height={38} />
        </Form.Item>
      </div>
    </ServiceItemStyled>
  );
};

const ServiceItemStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  border-bottom: 1px solid #DDD;
  padding-bottom: 16px;
  border-right: 0.5px solid #DDD;
  padding-right: 16px;
  .service-name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    color: #1D2129;
    text-overflow: ellipsis;
    font-family: "Open Sans";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  .service-price-box {
    display: flex;
    align-items: center;
    width: 100%;
    span {
      flex: 1;
      color: #1D2129;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;