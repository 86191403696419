import React, { useCallback } from 'react';
import { Input, InputRef } from 'antd';
import { debounce } from 'lodash';

interface CurrencyInputProps {
  value?: string;
  onChange?: (value: number) => void;
  onChangeDebounce?: (value: number) => void;
  bordered?: boolean;
  disabled?: boolean;
  size?: 'large' | 'medium' | 'small';
  className?: string;
  width?: React.CSSProperties['width'];
  height?: React.CSSProperties['height'];
  textAlign?: React.CSSProperties['textAlign'];
  tabIndex?: number;
  maxValue?: number;
  inputRef?: React.Ref<InputRef>;
  onFocus?: () => void;
  inputMode?: 'search' | 'text' | 'none' | 'email' | 'url' | 'tel' | 'numeric' | 'decimal' | undefined;
}

const PercentInput: React.FC<CurrencyInputProps> = ({
  value = '0.00',
  onChange,
  onChangeDebounce,
  bordered = true,
  size = 'medium',
  className = '',
  width,
  height,
  textAlign,
  disabled,
  tabIndex,
  inputRef,
  onFocus,
  inputMode,
  maxValue,
}) => {
  const _debounce = useCallback(
    debounce(
      (inputValue: number) =>
        typeof onChangeDebounce === 'function' && onChangeDebounce(inputValue),
      200
    ),
    []
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value.replace(/[^0-9]/g, ''); // Strip non-numeric characters
    let numericValue = 0.0;

    if (rawValue) {
      const intValue = parseInt(rawValue);
      numericValue = intValue;
    }
    if (maxValue && numericValue > maxValue) return;
    if (onChange) {
      onChange(numericValue);
    }
    _debounce(numericValue);
  };

  const displayValue = `${parseInt(value)}%`;

  // Determine the height based on the size prop
  let heightBySize = '3.5rem'; // default for 'medium'
  switch (size) {
    case 'large':
      heightBySize = '4rem';
      break;
    case 'small':
      heightBySize = '3rem';
      break;
  }

  return (
    <Input
      ref={inputRef}
      className={className}
      value={displayValue}
      onChange={handleInputChange}
      style={{ width: width || '100%', height: height || heightBySize, textAlign }}
      bordered={bordered}
      disabled={disabled}
      tabIndex={tabIndex}
      onFocus={onFocus}
      inputMode={inputMode}
    />
  );
};

export default PercentInput;
