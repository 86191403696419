import React from 'react';
import styled from 'styled-components';
type IStatusBoxProps = {
  status: string;
};
const StatusBox: React.FC<IStatusBoxProps> = ({ status }) => {
  return (
    <StatusBoxContainerStyled className='StatusBoxContainerStyled'>
      <StatusBoxStyled className={status}>{status}</StatusBoxStyled>
    </StatusBoxContainerStyled>
  );
};

export default StatusBox;
export const StatusBoxContainerStyled = styled.div`
  .NEW {
    background: var(--yellow-headline, #feedbf);
  }
  .VIP {
    background: var(--primary-button, #ffe5e7);
  }
  .DELETE {
    background: var(--fill-fill-2, #adadad);
  }
  .REGULAR {
    background: var(--blue-headline, #c6e9ef);
  }
`;
const StatusBoxStyled = styled.div`
  width: 7rem;
  margin: auto;
  display: flex;
  padding: 8px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  border-radius: 50px;
`;
