import { Button, Col, Row } from 'antd';
import Icon from 'assets/Icons';
import Empty from 'components/Empty';
import Text, { ITextProps } from 'components/Text';
import React, { useState } from 'react';
import styled from 'styled-components';
type IComponentProps = {
  className?: string;
  content: string;
  contentProps?: ITextProps;
  // children?: React.ReactNode;
  rowPerPage: number;
  list: any[];
  colSpan: number;
  itemContent: (item: any) => JSX.Element;
  lg?: number;
  md?: number;
  sm?: number;
  itemEmptyMessage?: string;
  gutter?: number;
  autoScroll?: boolean;
};

const CarouserBox: React.FC<IComponentProps> = ({
  className,
  content,
  rowPerPage,
  list,
  colSpan,
  itemContent,
  lg,
  md,
  sm,
  contentProps = {},
  itemEmptyMessage,
  gutter = 24,
  autoScroll = false,
}) => {
  const [page, setPage] = useState(1);

  const listDisplay = list.slice((page - 1) * rowPerPage, page * rowPerPage);
  const totalPage = Math.ceil(list.length / rowPerPage);

  const handlePrev = () => {
    setPage((prevPage) => prevPage - 1);
  };
  const handleNext = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <ComponentStyled className={`${className ?? ''} ComponentStyled  ${autoScroll ? 'container-scroll-div' : ''}`}>
      <ContentContainer className='ContentContainer'>
        <Row justify='space-between' align={'middle'} style={{ flex: 1 }}>
          <Col style={{ flex: 1 }}>
            <Text variant="H6" color="text_2" {...contentProps}>
              {content}
            </Text>
          </Col>
          <Col>
            <IconButtonContainer>
              <IconWrapper>
                <IconButton className='arrow-btn' disabled={page === 1} onClick={handlePrev}>
                  <Icon type="back" width="32" height="32"></Icon>
                </IconButton>
                <IconButton className='arrow-btn' disabled={totalPage === page} onClick={handleNext}>
                  <Icon type="next" width="32" height="32"></Icon>
                </IconButton>
              </IconWrapper>
            </IconButtonContainer>
          </Col>
        </Row>
      </ContentContainer>
      {
        autoScroll ? <div className='scroll-div'>
          <div className='children-scroll' style={{ marginRight: -gutter / 2, marginLeft: -gutter / 2 }}>
            <Row gutter={[gutter, gutter]} style={{ margin: 0 }}>
              {listDisplay.length > 0 ? listDisplay?.map((item: any, index: number) => {
                return (
                  <Col
                    key={item.id + index}
                    span={colSpan}
                    lg={{ span: lg }}
                    md={{ span: md }}
                    sm={{ span: sm }}
                  >
                    {itemContent(item)}
                  </Col>
                );
              }) : <Col flex={'auto'}><Empty description={itemEmptyMessage} /></Col>}
            </Row>
          </div>
        </div> : <>
          <Row gutter={[gutter, gutter]}>
            {listDisplay.length > 0 ? listDisplay?.map((item: any, index: number) => {
              return (
                <Col
                  key={item.id + index}
                  span={colSpan}
                  lg={{ span: lg }}
                  md={{ span: md }}
                  sm={{ span: sm }}
                >
                  {itemContent(item)}
                </Col>
              );
            }) : <Col flex={'auto'}><Empty description={itemEmptyMessage} /></Col>}
          </Row>
        </>
      }
    </ComponentStyled>
  );
};

export default CarouserBox;
const ComponentStyled = styled.div`
  width:100%;
  &.container-scroll-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    .ContentContainer {
      margin-bottom: 4px;
    }
  }
  .scroll-div {
    flex: 1;
    position: relative;
  }
  .children-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    padding-right: 4px;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,0.5);
      &:hover {
        background-color: rgba(0,0,0,0.6);
      }
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #F5F5F5;
    }
  }
`;

const ContentContainer = styled.div`
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  margin-bottom: 1rem;
`;
const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; // Default for small screens
  @media (min-width: 1200px) {
    // This is typically the breakpoint for large screens, but you can adjust.
    justify-content: flex-end;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const IconButton = styled(Button)`
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
