import React from 'react';
import styled from 'styled-components';
import googleLogo from './googleLogo.png';
import nfcLogo from '../../CheckInGoogleNoReviewPage/RightContent/nfc.png';
import starLine from './starLine.png';
import { QRCode } from 'antd';
import checkInSelectors from 'features/checkIn/services/selectors';

const RightContent = () => {
  const googleReview = checkInSelectors.googleReview();

  return (
    <RightContainer>
      <img src={nfcLogo} alt="nfc-logo" className="nfc-logo" />
      <QRCode
        size={330}
        value={googleReview?.urlReview || 'https://fozito.com/'}
      />
      <p className='title-review'>REVIEW US ON</p>
      <img src={googleLogo} alt="google-logo" className="google-logo" />
      <img src={starLine} alt="star-line" className="star-line" />
    </RightContainer>
  );
};

export default RightContent;
const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    height: 100vh;
    border-radius: 70px 0px 0px 70px;
    background: #FFF;
    box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.10) inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.25);
    
    .nfc-logo {
      width: 148px;
      height: 148px;
      margin-bottom: 3rem;
    }
    .title-review {
      color: #000;
      text-align: center;
      font-family: "Open Sans";
      font-size: 40px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.8px;
      margin-top: 1.5rem;
    }
    .google-logo {
      width: 325px;
      height: 110px;
      margin-top: 12px;
      margin-bottom:12px;
    }
    .star-line {
      width: 146px;
      height: 32px;
    }
`;
