import Modal from 'components/Modal';
import { get } from 'lodash';
import React from 'react';
import { IProductVIPItemData } from 'services/shop/types/vip';
import styled from 'styled-components';
import VIP_Platinum from './icons/VIP_Platinum.svg';
import VIP_Gold from './icons/VIP_Gold.svg';
import VIP_Diamond from './icons/VIP_Diamond.svg';
import BG_Discount from './icons/bg-discount.svg';
import clsx from 'clsx';
import { formatCurrency } from 'utils/formatCurrency';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment from 'moment';

type Props = {
  visible?: boolean;
  onClose: () => void;
  data?: IProductVIPItemData[];
  onBuy?: (o: IProductVIPItemData) => void;
};
const ModalVipV2 = ({ onClose, visible, data = [], onBuy = () => undefined }: Props) => {
  return (
    <Modal
      v2
      modalTitle='VIP'
      visible={visible}
      noneBodyStyle
      containerPadding={0}
      hiddenOkButton
      width={'auto'}
      onClose={onClose}
      footerBgColor='#F5F5F5'
      className='modal-non-opacity'
    >
      <Container>
        {data.map(o => {
          return (
            <CardVipItem
              key={o.id}
              data={o}
              onBuy={onBuy}
            />
          );
        })}
      </Container>
    </Modal>
  );
};

export default ModalVipV2;
const Container = styled.div`
display: flex;
padding: 0px 40px;
align-items: center;
gap: 48px;
align-self: stretch;
background: #F5F5F5;
padding-top: 40px;
`;
const images = {
  ['PLATINUM']: VIP_Platinum,
  ['GOLD']: VIP_Gold,
  ['DIAMOND']: VIP_Diamond,
};
const labels = {
  ['PLATINUM']: 'VIP Platinum',
  ['GOLD']: 'VIP Gold',
  ['DIAMOND']: 'VIP Diamond',
};
const CardVipItem = ({ data, onBuy }: { data: IProductVIPItemData, onBuy: (o: IProductVIPItemData) => void }) => {
  const discountValue = () => {
    if (data.discountType === 'PERCENT') {
      return data.discountValue + '%';
    }
    return `$${data.discountValue}`;
  };

  return (
    <CardVipItemStyled>
      <div className={clsx('vip-container', data.vipPackage)}>
        <img className='card-image' src={get(images, [data.vipPackage]) || ''} alt={data.vipPackage} />
        <p className="title-vip">{get(labels, [data.vipPackage]) || '-'}</p>
        <div className="price-container">
          <div className="price-value">{formatCurrency(data.yearlyPrice)}</div>
          <div className="price-label">For Yearly</div>
        </div>
        <div className="expire-date-container">
          <div className="expire-label">Expires Date:</div>
          <div className="expire-value">{data.expiresDate ? moment().add(data.expiresDate || 0, 'day').format(DATE_FORMAT) : '--.--.----'}</div>
        </div>
        <button onClick={() => onBuy(data)} type='button' className={clsx('button-get-gift-card', data.vipPackage)}><span>Get VIP Card</span></button>
        <div className="discount-container" style={{ backgroundImage: `url(${BG_Discount})` }}>
          <div className="discount-label">Disc</div>
          <div className="discount-label">Ticket</div>
          <div className="discount-value">-{discountValue()}</div>
        </div>
      </div>
    </CardVipItemStyled>
  );
};


const CardVipItemStyled = styled.div`
display: flex;
padding-bottom: 48px;
align-items: center;
gap: 10px;
flex: 1 0 0;
border-radius: 12px;
background: #FFF;
.vip-container {
  display: flex;
  padding: 24px 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  background: linear-gradient(159deg, #171618 0%, #3B3B3B 100%);
  border-radius: 12px 12px 0px 0px;
  position: relative;
  
  img.card-image {
    width: 300px;
  }

  .title-vip {
    align-self: stretch;
    text-align: center;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: linear-gradient(93deg, #D5D2DD 0.52%, #FEFCFF 97.59%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    .price-value {
      text-align: center;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background: linear-gradient(93deg, #D5D2DD 0.52%, #FEFCFF 97.59%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .price-label {
      color: #CBD5E1;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .expire-date-container {
    display: flex;
    padding: 0px 8px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.15);

    .expire-label {
      color: #CBD5E1;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .expire-value {
      color: #CBD5E1;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .button-get-gift-card {
    display: flex;
    height: 52px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;
    border-radius: 4px;
    background: linear-gradient(93deg, #D5D2DD 0.52%, #FEFCFF 97.59%);
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    position: absolute;
    left: 37px;
    right: 37px;
    bottom: -32px;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    &.PLATINUM {
      background: linear-gradient(93deg, #D5D2DD 0.52%, #FEFCFF 97.59%);
    }
    &.GOLD {
      background: linear-gradient(118deg, #FEFACB 0%, #D4A84C 100%);
    }
    &.DIAMOND {
      background: linear-gradient(118deg, #62CAFF 0%, #2C95EE 100%);
    }
  }

  &.PLATINUM .title-vip, &.PLATINUM .price-container .price-value {
    background: linear-gradient(93deg, #D5D2DD 0.52%, #FEFCFF 97.59%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.GOLD .title-vip, &.GOLD .price-container .price-value {
    background: linear-gradient(118deg, #FEFACB 0%, #D4A84C 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &.DIAMOND .title-vip, &.DIAMOND .price-container .price-value {
    background: linear-gradient(118deg, #62CAFF 0%, #2C95EE 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
 
  .discount-container {
    width: 85px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    right: -29.667px;
    top: -29px;
    .discount-label {
      color: #FFF;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .discount-value {
      color: #F6D465;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
`;