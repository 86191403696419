import { Form } from 'antd';
import { IAppointmentServiceItem } from 'features/appointment/services/types/appointment';
import React, { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import StaffInfo from './StaffInfo';
import Button from 'components/Button';
import Icon from 'assets/Icons';
import Text from 'components/Text';
type Props = {
  name?: any;
  viewOnly?: boolean;
  onChangeStaff: (currentStaffId: string, onChange: (staffId: string) => void) => void;
  onAddService: (add: (value?: IAppointmentServiceItem) => void) => void;
};
const ServicesStaff = ({ viewOnly, name, onChangeStaff, onAddService }: Props) => {
  const staffs = shopSelectors.data.staffs();
  return (
    <Form.List name={name ? name : 'services'}>
      {(fields, { add, remove }) => {
        return <Container>
          <Services>
            {fields.map(o => (
              <Form.Item key={o.key} noStyle name={o.name}>
                <ServiceItem
                  staffs={staffs}
                  name={o.name}
                  remove={() => remove(o.name)}
                  onChangeStaff={onChangeStaff}
                  viewOnly={viewOnly}
                />
              </Form.Item>
            ))}
          </Services>
          {!viewOnly && <ButtonAdd onClick={() => onAddService(add)} className='btn-add-service' ntype='GHOST'>{addIcon} <Text variant='CONTENT_1'>Add Service</Text></ButtonAdd>}
        </Container>;
      }}
    </Form.List>
  );
};

export default ServicesStaff;
const ButtonAdd = styled(Button)`
align-self: center;
  .CONTENT_2 {
    display: flex;
    align-items: center;
    gap: 8px;
    svg {
      transform: scale(1.5);
    }
    .CONTENT_1 {
      color: #2D6772;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;
const Container = styled.div`
display: flex;
padding: 16px 0px;
flex-direction: column;
align-items: center;
gap: 16px;
align-self: stretch;
border-bottom: 1px solid #86909C;
background: #FFF;
`;
const Services = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 4px;
align-self: stretch;
`;
type ServiceItemProps = {
  viewOnly?: boolean;
  value?: IAppointmentServiceItem;
  name?: any;
  staffs?: IStaffItemData[];
  remove?: () => void;
  onChangeStaff: (currentStaffId: string, onChange: (staffId: string) => void) => void;
};
const ServiceItem = ({ viewOnly, staffs, value, name, onChangeStaff = () => undefined, remove = () => undefined }: ServiceItemProps) => {
  const staffInfo = useMemo(() => staffs?.find(o => o.id === value?.staffId), [staffs, value?.staffId]);
  const handleOnChangeStaff = (change: (value: string) => void) => {
    if (!value?.staffId) return;
    onChangeStaff(value.staffId, change);
  };

  return (
    <ServiceItemContainer>
      <div className="service-info">
        <span>{value?.serviceName || ''}</span>
        <span className='price'>{formatCurrency(value?.price)}</span>
      </div>
      <Form.Item noStyle name={[name, 'staffId']}>
        <Staff viewOnly={viewOnly} staffInfo={staffInfo} onChangeStaff={handleOnChangeStaff} />
      </Form.Item>
      {!viewOnly && <Button ntype='GHOST' size='small' width='40px' style={{ transform: 'scale(1.5)' }} onClick={remove} ><Icon type='deleteIconCashier' fill='#F53F3F' /></Button>}
    </ServiceItemContainer>
  );
};
const ServiceItemContainer = styled.div`
display: flex;
height: 80px;
padding: 8px 16px;
align-items: center;
gap: 24px;
align-self: stretch;
background: #F6F7FC;
.service-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  span {
    align-self: stretch;
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &.price {
      font-weight: 400;
    }
  }
}
`;
type StaffProps = {
  viewOnly?: boolean;
  staffInfo?: IStaffItemData | null | undefined;
  onChange?: (value: string) => void;
  onChangeStaff: (onChange: (value: string) => void) => void;
};
const Staff = ({ viewOnly, onChangeStaff = () => undefined, onChange = () => undefined, staffInfo }: StaffProps) => {
  return (
    <button disabled={viewOnly} type='button' onClick={() => onChangeStaff(onChange)}>
      <StaffInfo isItem value={staffInfo} />
    </button>
  );
};

const addIcon = <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M12.5 4.5C12.9142 4.5 13.25 4.83579 13.25 5.25V18.75C13.25 19.1642 12.9142 19.5 12.5 19.5C12.0858 19.5 11.75 19.1642 11.75 18.75V5.25C11.75 4.83579 12.0858 4.5 12.5 4.5Z" fill="#2D6772" />
  <path fillRule="evenodd" clipRule="evenodd" d="M5 12C5 11.5858 5.33579 11.25 5.75 11.25H19.25C19.6642 11.25 20 11.5858 20 12C20 12.4142 19.6642 12.75 19.25 12.75H5.75C5.33579 12.75 5 12.4142 5 12Z" fill="#2D6772" />
  <path fillRule="evenodd" clipRule="evenodd" d="M12.5 4C11.8096 4 11.25 4.55964 11.25 5.25V10.75H5.75C5.05964 10.75 4.5 11.3096 4.5 12C4.5 12.6904 5.05964 13.25 5.75 13.25H11.25V18.75C11.25 19.4404 11.8096 20 12.5 20C13.1904 20 13.75 19.4404 13.75 18.75V13.25H19.25C19.9404 13.25 20.5 12.6904 20.5 12C20.5 11.3096 19.9404 10.75 19.25 10.75H13.75V5.25C13.75 4.55964 13.1904 4 12.5 4ZM12.75 10.75V5.25C12.75 5.11193 12.6381 5 12.5 5C12.3619 5 12.25 5.11193 12.25 5.25V10.75H12.75ZM12.25 11.75H12.75V12.25H12.25V11.75ZM11.25 11.75H5.75C5.61193 11.75 5.5 11.8619 5.5 12C5.5 12.1381 5.61193 12.25 5.75 12.25H11.25V11.75ZM12.25 13.25V18.75C12.25 18.8881 12.3619 19 12.5 19C12.6381 19 12.75 18.8881 12.75 18.75V13.25H12.25ZM13.75 12.25V11.75H19.25C19.3881 11.75 19.5 11.8619 19.5 12C19.5 12.1381 19.3881 12.25 19.25 12.25H13.75Z" fill="#2D6772" />
</svg>;
