import { Form, Input } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import Box from 'components/Box';
import { debounce, find, first } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import Text from 'components/Text';
import AvatarBox from './AvatarBox';

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};
const StaffListChooseUI = ({ value, onChange = () => undefined }: Props) => {
  const [searchText, setSearchText] = useState('');
  const _staffList = shopSelectors.data.staffs();

  const staffList = useMemo(() => {
    const data = _staffList.filter(o => !o.anyBody) || [];
    const _searchStr = searchText.trim().toLocaleLowerCase();
    if (!_searchStr) return data;
    return data.filter(o => o.firstName?.toLocaleLowerCase().includes(_searchStr));
  }, [searchText, _staffList]);

  return (
    <Container>
      <Box display='flex'>
        <InputSearch onSearch={setSearchText} />
      </Box>
      <div className="scroll-wrapper">
        <div className={'scroll-container'} >
          <div className="staff-list-container">
            {staffList.map((item) => {
              return (
                <AvatarBox
                  key={item.id}
                  anybody={item.anyBody}
                  onClick={() => onChange(item.id)}
                  src={item.urlImage}
                  name={item.firstName}
                  selected={item.id === value}
                  cashier
                  className={'cashier-staff-box'}
                />
              );
            })}
          </div>
        </div>
      </div>
      <Form.Item shouldUpdate noStyle>{({ getFieldsError }) => {
        const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
        const error = first(find(errors, o => o.name === 'staffId')?.errors) || '';
        if (!error) return null;
        return <Text my={0.5} style={{ opacity: !error ? 0 : 1, alignSelf: 'stretch', textAlign: 'center', fontSize: 24 }} variant='ERROR'>{error || '--'}</Text>;
      }}</Form.Item>
    </Container>
  );
};

export default StaffListChooseUI;
type InputSearchProps = {
  onSearch: (value: string) => void;
};
const InputSearch = ({ onSearch }: InputSearchProps) => {
  const [text, setText] = useState('');
  const _debounce = useCallback(debounce((_text: string) => onSearch(_text.trim()), 200), []);
  const onChange = (e: any) => {
    const searchText = e.target.value.trim();
    setText(searchText);
    _debounce(searchText);
  };
  return (
    <WrapperInputSearch>
      <Input className='search-input' placeholder="Search by Staff's Name" value={text} onChange={onChange}
        suffix={<SearchIcon fill={'#fff'} />}
      />
    </WrapperInputSearch>
  );
};
const WrapperInputSearch = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  .search-input.ant-input-affix-wrapper {
    border-radius: 100px;
    padding: 0px;
    .ant-input-suffix {
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0px 100px 100px 0px;
      background: #2D6772;
    }
    input {
      border-radius: 100px; 
      padding: 24px;
      height: 56px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      align-self: center;
    }
    border: 3px solid #2D6772;
    background: #FFF;
  }
`;
const Container = styled.div`
    flex: 1;
    background-color: #fff;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    min-height: 75vh;

    .group-zoom {
      display: flex;
      align-self: stretch;
      gap: 16px;
      justify-content: flex-end;
      padding: 8px 16px;
      padding-right: 0;
    }
    .scroll-wrapper {
      flex: 1;
      position: relative;
      .scroll-container {
        position: absolute;
        inset: 0;
        overflow: auto;
        scrollbar-width: none; /* Firefox 64 */
        &::-webkit-scrollbar {
          width: 0px;
          height: 8px;
          background-color: #F5F5F5;
        }
      }
    }
    .staff-list-container {
      padding: 24px 16px;
      padding-top: 16px;
      display: grid;
      gap: 16px;
      grid-template-columns: repeat(auto-fill,minmax(140px, 1fr));
      .cashier-staff-box {
        width: 100%;
        padding: 0.5rem 0;
         .AnyBodyImage {
            width: 7.5rem !important;
            min-height: 7.5rem;
          }
        &.row-5 {
          .circle-avatar {
            width: 7rem;
            min-height: 7rem;
          }
          .AnyBodyImage {
            width: 6.5rem !important;
            min-height: 6.5rem;
          }
        }
      }
    }
`;
