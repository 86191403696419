import { Col, Row } from 'antd';
import React from 'react';
import styled from 'styled-components';
import CenterMainBody from './CenterMainBody';
import LeftMainBody from './LeftMainBody';
import RightMainBody, { RightMainBodyV2 } from './RightMainBody';
import shopSelectors from 'services/shop/selectors';
import StaffListChooseUI from './StaffListChooseUI';
import CenterMainBodyV2 from './CenterMainBody/indexV2';
type IComponentProps = {};
const MainBody: React.FC<IComponentProps> = () => {
  const allSetting = shopSelectors.data.allSetting();
  if (allSetting?.showUIStaffCashier) {
    return (
      <MainBodyStyledV2>
        <Col className='flex-box'>
          <StaffListChooseUI />
        </Col>
        <Col flex={'auto'} style={{ display: 'flex', paddingLeft: '16px' }}>
          <CenterMainBodyV2 />
        </Col>
        <Col className='right-box'>
          <RightMainBodyV2 />
        </Col>
      </MainBodyStyledV2>
    );
  }
  return (
    <MainBodyStyled>
      <Row wrap={false}>
        <Col className={'left-box'}>
          <LeftMainBody />
        </Col>
        <Col flex={'auto'}>
          <CenterMainBody />
        </Col>
        <Col className='right-box'>
          <RightMainBody />
        </Col>
      </Row>
    </MainBodyStyled>
  );
};

export default MainBody;
const MainBodyStyledV2 = styled.div`
  height: calc(100vh - 140px);
  background-color: #F5F5F5;
  display: flex;
  .flex-box {
    display: flex;
    flex: 0 0 750px;
    max-width: 750px;
  }
  .right-box {
    display: flex;
    flex: 0 0 800px;
    max-width: 800px;
  }
`;
const MainBodyStyled = styled.div`
  height: calc(100vh - 6.375rem);
  background-color: #fff;
  .left-box {
    display: block;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .left-box-sm {
    display: block;
    flex: 0 0 350px;
    max-width: 350px;
  }
  .center-box {
  }
  .right-box {
    display: block;
    flex: 0 0 40%;
    max-width: 40%;
  }
`;
