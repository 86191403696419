import { Avatar, QRCode } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import online_icon from '../icons/online.svg';
import in_store_icon from '../icons/in-store.svg';
import copy_icon from '../icons/copy.svg';
import refresh_icon from '../icons/change.svg';
import Box from 'components/Box';
import Button from 'components/Button';
import { IGiftCardDetail } from 'features/giftCards/services/types/reducer';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'utils/toast';
import { formatPhone } from 'utils/formatPhone';
import shopSelectors from 'services/shop/selectors';

type Props = {
  detail?: IGiftCardDetail | null;
  onReset: () => void;
};
const DetailInfo = ({ detail, onReset }: Props) => {
  const staffs = shopSelectors.data.staffs();
  const staff = useMemo(() => staffs.find(o => o.id === detail?.giftCard.staffId), [detail?.giftCard.staffId, staffs]);
  console.log('detail', detail);

  return (
    <Container>
      <GiftCardQR isOnline={detail?.giftCard?.status} code={detail?.giftCard.giftCode || '--'} />
      <DetailContainer>
        <div className="gift-card-code-container">
          <div className="gift-card-code-label">{detail?.giftCard.giftCode || '--'}</div>
          {detail?.giftCard.giftCode &&
            <CopyToClipboard text={detail?.giftCard.giftCode} onCopy={(text, result) => { if (!result) return; toast.success('Copied to clipboard'); }}>
              <button type='button' className="btn-copy"><img src={copy_icon} alt="copy_icon" style={{ width: 48, height: 48 }} /></button>
            </CopyToClipboard>}
        </div>
        <div className="gift-info-container">
          <div className="gift-info-item">
            <span className='label'>Customer:</span>
            <Box display='flex' flexDirection='column'>
              <span className="value">{detail?.giftCard?.customerName || '--'}</span>
              <span className="value">{detail?.giftCard?.customerPhone ? formatPhone(detail?.giftCard?.customerPhone ?? '') : '--'}</span>
            </Box>
          </div>
          <div className="gift-info-item">
            <span className='label'>Staff:</span>
            <Box display='flex' alignItems='center' gap='2'>
              {staff && <Avatar src={staff?.urlImage || 'fail'} style={{ backgroundColor: '#f56a00' }} size={32}>{staff?.firstName?.[0] || 'A'}</Avatar>}
              <span className="value">{staff?.firstName || '--'}</span>
            </Box>
          </div>
        </div>
        <Button onClick={onReset} cardCashier iconElement={<img src={refresh_icon} alt='refresh_icon' style={{ width: 32, height: 32 }} />} ntype='LIGHT_BLUE'>
          <span style={{ fontSize: 20 }}>Reset Gift Card</span>
        </Button>
      </DetailContainer>
    </Container>
  );
};

export default DetailInfo;

const Container = styled.div`
display: flex;
padding-right: 48px;
align-items: flex-start;
gap: 16px;

.gift-card-code-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  .gift-card-code-label {
    color: #FFF;
    font-family: Poppins;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  } 
}

.gift-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  .gift-info-item {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;

    span {
      color: #CBD5E1;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      &.label {
        width: 100px;
      }
      &.value {
        color: #FFF;
        font-size: 20px;
        font-weight: 500;
      }
    }
  }
}
`;

const DetailContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
`;

type GiftCardQRProps = {
  code?: string;
  isOnline?: boolean;
};
const GiftCardQR = ({ code = '', isOnline = false }: GiftCardQRProps) => {
  return (
    <GiftCardQRStyled>
      <QRCode bgColor='#fff' bordered={false} size={200} value={code} />
      <div className="gift-card-type-container">
        <img src={isOnline ? online_icon : in_store_icon} alt="status_icon" style={{ width: 24, height: 24 }} />
        <div className="gift-card-type-label">{isOnline ? 'Online' : 'In Store'}</div>
      </div>
    </GiftCardQRStyled>
  );
};

const GiftCardQRStyled = styled.div`
display: flex;
flex-direction: column;
align-items: center;
.ant-qrcode {
  border-radius: 0;
}
.gift-card-type-container {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  background: rgba(229, 230, 235, 0.20);
  .gift-card-type-label {
    color: #C6E9EF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
`;