import Link from 'components/Link';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
type IComponentProps = {};
const PolicyBox: React.FC<IComponentProps> = () => {
  return (
    <BackgroundStyled>
      <Link className="link" color="info_3" url="https://www.w3schools.com/">
        <Text variant="LINK" color="info_3">
          Privacy Policy
        </Text>
      </Link>
      <Link color="info_3" url="https://www.w3schools.com/">
        <Text variant="LINK" color="info_3">
          Term of Use
        </Text>
      </Link>
    </BackgroundStyled>
  );
};

export default PolicyBox;
const BackgroundStyled = styled.div`
  // position: absolute;
  height: 4.93rem;
  left: 3.5rem;
  display: flex;
  flex-direction: row;
  z-index: 2;
  align-items: center;
  .link {
    margin: 0 2rem 0 2rem;
  }
`;
