import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IParams, IViewType } from 'features/report/services/types/reportStaff';

const setParams = createAction<IParams | undefined>(PREFIX_ACTIONS + 'setParams');

const setCalendarViewType = createAction<IViewType>(PREFIX_ACTIONS + 'setCalendarViewType');

const getListGiftCard = createAsyncAction<any>(PREFIX_ACTIONS + 'getListGiftCard');

const getGiftCardDetail = createAsyncAction<any>(PREFIX_ACTIONS + 'getGiftCardDetail');

const resetGiftCardDetail = createAsyncAction<any>(PREFIX_ACTIONS + 'resetGiftCardDetail');

const getSummaryGiftCards = createAsyncAction(PREFIX_ACTIONS + 'getSummaryGiftCards');

/**
 * TODO:
 * please replace FEATURE_NAME to your feature name
 * ex:
 * FEATURE_NAMEActions => bookingActions
 */
const giftCardsActions = {
    setParams,
    setCalendarViewType,
    getListGiftCard,
    getGiftCardDetail,
    resetGiftCardDetail,
    getSummaryGiftCards,
};

export default giftCardsActions;

