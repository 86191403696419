import CalculatorModal from 'components/CalculatorModal';
import { useState } from 'react';
import CashierActionButton from '../CashierActionBtn';
type Props = {
  v2?: boolean;
  v2BG?: boolean;
};
const ButtonCalculator = ({ v2 }: Props) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      <CashierActionButton
        label='Calculator'
        ntype="LIGHT_BLUE"
        feature='CALC'
        v2UI={v2}
        onClick={() => setVisible(true)}
      />
      <CalculatorModal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};

export default ButtonCalculator;