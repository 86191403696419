import React from 'react';
import styled from 'styled-components';
import { IPrintSalaryStaffDetailOneDay_Detail } from '../services/types/reportStaff';
import { Row } from 'antd';
import Text from 'components/Text';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IPrintSalaryStaffDetailOneDay_Detail[];
};
const EmployeeTickets = ({ data }: Props) => {
  return (
    <TableStyled>
      <thead>
        <tr>
          <th style={{ textAlign: 'start' }}>No</th>
          <th>ITEMS</th>
          <th style={{ textAlign: 'end', paddingRight: '8px' }}>PRICE</th>
          <th style={{ textAlign: 'end' }}>TIP</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((item, i) => (
          <tr key={i}>
            <td style={{ whiteSpace: 'nowrap', textAlign: 'start' }}><span className='highlight'>#{item?.ticketNumber}</span> - {item?.time}</td>
            <td>
              <div style={{ display: 'flex',  width: '100%', paddingRight: '2px', paddingLeft: '2px' }}>
                <div>
                  {item?.itemNames.map((itemName, index) => {
                    return (
                      <Row
                        key={index + '_' + itemName.toString()}
                        style={{ justifyContent: 'start' }}
                      >
                        <Text variant='CAPTION_3' printMode textAlign='left' style={{ fontSize: '11px' }}>{itemName}</Text>
                      </Row>
                    );
                  })}
                </div>

              </div>
            </td>
            <td style={{ textAlign: 'end', paddingRight: '8px' }}>{formatCurrency(item?.price)}</td>
            <td style={{ textAlign: 'end' }}>{formatCurrency(item?.tip)}</td>
          </tr>
        ))}
      </tbody>
    </TableStyled>
  );
};

export default EmployeeTickets;

const TableStyled = styled.table`
  width: 100%;
  border-top: 1px solid black;
  thead {
    th {
      color: #767676;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      border-bottom: 1px dashed black;
    }
  }
  tbody {
    td {
      font-family: 'Roboto', serif !important;
      color: black;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      vertical-align: unset;
    }
    .highlight {
      font-size: 0.8125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%;
    }
  }
`;