import VoidButtonHandler, { IVoidFuncType } from 'features/ticketList/components/VoidButton';
import ticketListActions from 'features/ticketList/services/actions';
import { Bill } from 'features/ticketList/services/types/api';
import React from 'react';
import { useAppDispatch } from 'store/hooks';

type IVoidModalButtonProps = {
  billDetail?: Bill;
  v2?: boolean;
};
const VoidModalButton: React.FC<IVoidModalButtonProps> = ({ billDetail, v2 }) => {
  const dispatch = useAppDispatch();
  const handleVoid: IVoidFuncType = (payload) => {
    dispatch(ticketListActions.voidTicketByBillId.fetch(payload));
  };

  return <VoidButtonHandler v2={v2} ticketDetails={billDetail} onVoid={handleVoid} />;
};

export default VoidModalButton;
