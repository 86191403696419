import React from 'react';
import styled from 'styled-components';
type Props = {
  mode: 'staff' | 'ticket' | 'default';
  setModel: (mode: 'staff' | 'ticket' | 'default') => void;
  v2?: boolean;
};
const GroupLayoutButton = ({ v2, mode, setModel }: Props) => {
  if (mode === 'staff') {
    return (
      <ButtonSide className={v2 ? 'v2' : ''}>
        <button onClick={() => setModel('default')}>
          <div className="round-btn">{v2 ? arrowLeftV2 : arrowLeft}</div>
          <span>Ticket</span>
        </button>
      </ButtonSide>
    );
  }
  if (mode === 'ticket') {
    return (
      <ButtonSide className={v2 ? 'v2' : ''}>
        <button onClick={() => setModel('default')}>
          <div className="round-btn">{v2 ? arrowLeftV2 : arrowLeft}</div>
          <span>Staff</span>
        </button>
      </ButtonSide>
    );
  }

  return (
    <Container className={v2 ? 'v2' : ''}>
      <div className="container-nav">
        <button onClick={() => setModel('staff')}>{v2 ? arrowRightV2 : arrowRight}</button>
        <button onClick={() => setModel('ticket')}>{v2 ? arrowLeftV2 : arrowLeft}</button>
      </div>
    </Container>
  );
};

export default GroupLayoutButton;
const ButtonSide = styled.div`
position: fixed;
bottom: 16px;
z-index: 9;
right: 0;
button {  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-right: 8px;
  flex-shrink: 0;
  background: #E5E6EB;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  border: 1px solid #86909C;
  .round-btn {
    display: flex;
    width: 48px;
    height: 48px;
    padding: 0px 4px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 100px;
    border: 1px solid #86909C;
    background: #E5E6EB;
    box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
  }
  span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
&.v2 {
  bottom: 116px;
  button {
    background: #346751;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #1D3D2F inset;
    .round-btn {
      height: 54px;
      width: 54px;
      background: #346751;
      border: 2px solid #fff;
      svg {
        transform: scale(1.3);
      }
    }
    span {
      color: #fff;
      font-size: 22px;
    }
  }
}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 0px;
  position: relative;
  .container-nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 16px;
    gap: 16px;
    z-index: 9;
    left: 50%;
    transform: translateX(-50%);

    &.nav-side {
    left: 48px;
    transform: unset;
    }

    button {  
      display: flex;
      width: 48px;
      height: 48px;
      padding: 0px 4px;
      justify-content: center;
      align-items: center;
      gap: 12px;
      flex-shrink: 0;
      border-radius: 100px;
      border: 1px solid #86909C;
      background: #E5E6EB;
      box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
    }
  }

  &.v2 .container-nav button {
    display: flex;
    height: 54px;
    width: 54px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
    border-radius: 100px;
    background: #346751;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #1D3D2F inset;
    svg {
      transform: scale(1.3);
    }
  }
`;

const arrowRight = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"> <g clipPath="url(#clip0_10909_34010)"> <path d="M12 24L20 16L12 8" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_10909_34010"> <rect width="32" height="32" fill="white" /> </clipPath> </defs> </svg>;
const arrowLeft = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"> <path d="M20 8L12 16L20 24" stroke="#1D2129" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>;
const arrowRightV2 = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"> <g clipPath="url(#clip0_10909_34010)"> <path d="M12 24L20 16L12 8" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </g> <defs> <clipPath id="clip0_10909_34010"> <rect width="32" height="32" fill="white" /> </clipPath> </defs> </svg>;
const arrowLeftV2 = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none"> <path d="M20 8L12 16L20 24" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" /> </svg>;