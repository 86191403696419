import ReceiptMultipleCardsPayment from 'features/payment/multipleCard/components/Receipt';
import multiCardActions from 'features/payment/multipleCard/services/actions';
import multiCardApis from 'features/payment/multipleCard/services/apis';
import { IMultiCardResDataItem } from 'features/payment/multipleCard/services/types/card';
import { IBillDetailData } from 'features/payment/services/types/bill';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { uiLoadingBasicRef } from 'services/UI/LoadingPage/UILoadingRef';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import PrintFooter from './components/PrintFooter';
import PrintHeader from './components/PrintHeader';
import PrintPageStyled from './styles';

type IPrintPageProps = any;
const PrintMultipleCardPage: React.FC<IPrintPageProps> = () => {
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const navigate = useNavigate();
  const divRef = useRef<HTMLDivElement>(null);
  const doPrint = useReactToPrint({
    content: () => divRef.current,
  });

  const onBack = () => navigate('/store/' + storage.shop_id.get());

  const fetchData = async (billId: string) => {
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const response = await Promise.all([
        multiCardApis.getTransactionDetail(billId),
        multiCardApis.getListMultipleCards(billId),
      ]);

      const res: IResponseDataBody<IBillDetailData> = response[0];
      const resCards: IResponseDataBody<IMultiCardResDataItem[]> = response[1];

      dispatch(multiCardActions.setPrintData({ detail: res.data.data || null, coupon: null, cards: resCards.data.data || [] }));
    } catch (error) { } finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };

  useEffect(() => {
    fetchData(id);
  }, []);

  return (
    <PrintPageStyled>
      <PrintHeader />
      <ReceiptMultipleCardsPayment billRef={divRef} />
      <PrintFooter onPrint={doPrint} onBack={onBack} />
    </PrintPageStyled>
  );
};

export default PrintMultipleCardPage;
