import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import Icon from 'assets/Icons';
import React from 'react';
import { styled } from 'styled-components';

interface ISearchBox {
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    placeholder?: string,
    size?: SizeType
    value?: string;
}
const SearchBox: React.FC<ISearchBox> = ({ value, onChange, placeholder, size = 'small' }) => {
    return (
        <SearchBoxStyled>
            <Input
                value={value}
                size={size}
                onChange={onChange}
                placeholder={placeholder ? placeholder : 'Staff name'}
                suffix={<Icon type="search"></Icon>}
                className="search-staff"
            />
        </SearchBoxStyled>
    );
};

export default SearchBox;
const SearchBoxStyled = styled.div`
  flex:1;
 .search-staff{
    min-width: 20rem;
    width: 100%;
 }
`;