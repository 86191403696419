import { get, unionBy } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ISignUpForm } from './types/api';
import { ICheckedInRes, ISignUpRes, IStaff, IWalkInData } from './types/demoData';
import shopSelectors from 'services/shop/selectors';
import { ICategoryItemData } from 'services/shop/types/categories';
import { ADD_ON_CATE_ID } from 'services/shop/constants';
import { IGoogleReviewResData } from './types/review';
import { ThemeHomeCheckIn } from './constants';

type MyState = RootState['checkIn'];
const getCurrentState = (state: RootState): MyState => state['checkIn'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getCheckedInData = () => selector('checkedIn') as ICheckedInRes;

const getPhoneNumber = () => selector('phoneNumber') as string;

const getSignUpFormValues = () => selector('formSignUp') as ISignUpForm;

const getStaffList = () => selector('staffList') as IStaff[];

const getStaffListCondition = () => selector('staffListCondition') as IStaff[];

const getSignUpData = () => selector('signedUp') as ISignUpRes;

const getWalkInData = () => selector('walkIn') as IWalkInData;

const getConfirmedCheckIn = () => selector('isConfirmWalkIn') as boolean;

const getActiveCategory = () => selector('activeCategory') as MyState['activeCategory'];

const getSelectedServices = () => selector('selectedServices') as MyState['selectedServices'];

const getSelectedStaff = () => selector('selectedStaff') as MyState['selectedStaff'];

const getStaffsAvailable = () => {
  const staffsAvailable = shopSelectors.data.staffsAvailable();
  return staffsAvailable;
};

const getAppointmentDetail = () => selector('appointmentDetail') as MyState['appointmentDetail'];

const activeSimpleMenu = () => selector('activeSimpleMenu') as MyState['activeSimpleMenu'];

const isModelFullSimpleMenu = () => selector('isModelFullSimpleMenu') as MyState['isModelFullSimpleMenu'];

const categories = () => {
  const _categoriesStore = shopSelectors.data.categories();
  const addonServices = shopSelectors.data.addonServices();
  const _categories = _categoriesStore.filter(o => !!o.enableCheckIn);
  if (!_categories?.length) return [];
  const categoryAddon: ICategoryItemData = {
    categoryName: 'ADD-ON',
    id: ADD_ON_CATE_ID,
    services: addonServices.map(o => ({
      ...o,
      catId: ADD_ON_CATE_ID
    })),
  };
  return unionBy(_categories, [categoryAddon], 'id') ?? [];

};

const warningBanCustomerMessage = () => selector('warningBanCustomerMessage') as string | null;
const googleReview = () => selector('googleReview') as IGoogleReviewResData | null;
const homeTheme = () => selector('homeTheme') as ThemeHomeCheckIn;

const checkInSelectors = {
  getCheckedInData,
  getPhoneNumber,
  getSignUpFormValues,
  getStaffList,
  getStaffListCondition,
  getSignUpData,
  getWalkInData,
  getConfirmedCheckIn,
  getActiveCategory,
  getSelectedServices,
  getSelectedStaff,
  getAppointmentDetail,
  getStaffsAvailable,
  activeSimpleMenu,
  isModelFullSimpleMenu,
  categories,
  warningBanCustomerMessage,
  googleReview,
  homeTheme,
};
export default checkInSelectors;
