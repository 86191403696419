import { Input } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import AvatarBox from 'components/AvatarBox';
import Box from 'components/Box';
import cashierSelectors from 'features/cashier/services/selectors';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
type Props = {
  onPickStaff: (item: IStaffItemData) => void;
  selectedStaffs?: string[];
  anybody?: boolean;
};
const StaffListChooseUI = ({ anybody = false, onPickStaff, selectedStaffs = [] }: Props) => {
  const [searchText, setSearchText] = useState('');
  const _staffList = shopSelectors.data.staffs();
  const countStaffInRowUI = cashierSelectors.countStaffInRowUI();

  const staffList = useMemo(() => {
    const data = (anybody ? _staffList : _staffList.filter(o => !o.anyBody)) || [];
    const _searchStr = searchText.trim().toLocaleLowerCase();
    if (!_searchStr) return data;
    return data.filter(o => o.firstName?.toLocaleLowerCase().includes(_searchStr));
  }, [searchText, _staffList, anybody]);

  const gridTemplateColumns = React.useMemo(() => {
    return Array.from(Array(countStaffInRowUI).keys()).map(() => '1fr').join(' ');
  }, [countStaffInRowUI]);

  return (
    <Container>
      <Box display='flex' pt='4' pr='2'>
        <InputSearch onSearch={setSearchText} />
      </Box>
      <div className="scroll-wrapper">
        <div className={'scroll-container'} >
          <div className="staff-list-container" style={{ gridTemplateColumns }}>
            {staffList.map((item) => {
              const selected = !!selectedStaffs.includes(item.id);
              return (
                <AvatarBox
                  key={item.id}
                  anybody={item.anyBody}
                  onClick={() => !selected && onPickStaff(item)}
                  src={item.urlImage}
                  name={item.firstName}
                  selected={selected}
                  cashier
                  className={'cashier-staff-box'}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StaffListChooseUI;
type InputSearchProps = {
  onSearch: (value: string) => void;
};
const InputSearch = ({ onSearch }: InputSearchProps) => {
  const [text, setText] = useState('');
  const _debounce = useCallback(debounce((_text: string) => onSearch(_text.trim()), 200), []);
  const onChange = (e: any) => {
    const searchText = e.target.value.trim();
    setText(searchText);
    _debounce(searchText);
  };
  return (
    <WrapperInputSearch>
      <Input className='search-input' placeholder="Search by Staff's Name" value={text} onChange={onChange}
        suffix={<SearchIcon fill={'#fff'} />}
      />
    </WrapperInputSearch>
  );
};
const WrapperInputSearch = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 16px;
  .search-input.ant-input-affix-wrapper {
    border-radius: 100px;
    padding: 0px;
    .ant-input-suffix {
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0px 100px 100px 0px;
      background: #2D6772;
    }
    input {
      border-radius: 100px; 
      padding: 24px;
      height: 56px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      align-self: center;
    }
    border: 3px solid #2D6772;
    background: #FFF;
  }
`;
const Container = styled.div`
    background-color: #F5F5F5;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    width: 750px;
    min-height: 60vh;
    .group-zoom {
      display: flex;
      align-self: stretch;
      gap: 16px;
      justify-content: flex-end;
      padding: 8px 16px;
      padding-right: 0;
    }
    .scroll-wrapper {
      flex: 1;
      position: relative;
      .scroll-container {
        position: absolute;
        inset: 0;
        overflow: auto;
        scrollbar-width: none; /* Firefox 64 */
        &::-webkit-scrollbar {
          width: 0px;
          height: 8px;
          background-color: #F5F5F5;
        }
      }
    }
    .staff-list-container {
      padding: 24px 16px;
      padding-top: 16px;
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      .cashier-staff-box {
        width: 100%;
        padding: 0.5rem 0;
        .circle-avatar {
          width: 6rem;
          min-height: 6rem;
        }
        .AnyBodyImage {
          width: 5.5rem !important;
          min-height: 5.5rem;
        }
      }
    }
`; 
