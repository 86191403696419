import { Form, FormInstance, Input, InputRef, Spin } from 'antd';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import StaffOwnerPercent from './StaffOwnerPercent';
import { GiftCardNumberContainer } from 'features/cashier/components/CenterMainBody/ExtendFeatures/GiftCard/v2/styles';
import Text from 'components/Text';
import qrScanIcon from './icons/qr-scan-v2.svg';
import { IDiscountSettingData } from 'services/shop/types/discount';
import { debounce, find, first, get } from 'lodash';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import NumberPadV2, { NumberPadIntV2 } from 'components/NumberKeyBoard/Currency/NumberPadV2';
import { ICouponItemData } from 'services/shop/types/coupon';
import CouponTicket from 'components/CouponTicket';
const MIN_LENGTH = 8;
type Ref = {
  onActive: () => void;
};
export const couponDiscountV2TabRef = React.createRef<Ref>();
type Props = {
  active: boolean;
  form: FormInstance<any>;
  discountSetting: IDiscountSettingData | null;
};
const CouponDiscountTab = forwardRef<Ref, Props>(({ active, form, discountSetting }, ref) => {
  const inputCodeRef = useRef<InputRef>(null);
  const inputStaffRef = useRef<InputRef>(null);
  const inputOwnerRef = useRef<InputRef>(null);
  const [loading, setLoading] = useState(false);
  const [keyboardTargetFor, setKeyboardTargetFor] = React.useState<'COUPON' | 'STAFF_PERCENT' | 'OWNER_PERCENT'>('COUPON');
  useImperativeHandle(ref, () => ({
    onActive: () => {
      form.resetFields(['couponCode', 'couponCodeError', 'couponCodeValid', 'coupon']);
      setTimeout(() => inputCodeRef.current?.focus(), 100);
    },
  }));

  const _debounce = useCallback(debounce(async (inputValue: string) => {
    if ((inputValue?.length ?? 0) < MIN_LENGTH) return;
    setLoading(true);
    try {
      const resData: IResponseDataBody<ICouponItemData> = await shopApis.getCouponByCode(inputValue);
      const _result = resData.data.data;
      if (_result) {
        form.setFieldsValue({
          coupon: _result,
          couponId: _result.id,
          discountType: _result.discountType,
          discountValue: _result.discountValue,
          staffPaidPercent: _result.staffDiscountPercent || discountSetting?.discountStaff || 0,
          ownerPaidPercent: _result.ownerDiscountPercent || discountSetting?.discountOwner || 0,
        });
      } else {
        form.setFieldValue('couponCodeError', 'No corresponding coupon found!');
      }
    } catch (error) {
      const msg = get(error, 'response.data.message');
      form.setFieldValue('couponCodeError', msg || 'No corresponding coupon found!');
    } finally {
      setLoading(false);
    }
  }, 1000), [discountSetting]);

  const onScan = () => {
    form.resetFields(['couponCode', 'couponCodeError', 'couponCodeValid', 'coupon']);
    inputCodeRef.current?.focus();
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const code = form.getFieldValue('giftNumber');
      try {
        const resData: IResponseDataBody<ICouponItemData> = await shopApis.getCouponByCode(code);
        const _result = resData.data.data;
        if (_result) {
          form.setFieldsValue({
            staffPaidPercent: _result.staffDiscountPercent || discountSetting?.discountStaff || 0,
            ownerPaidPercent: _result.ownerDiscountPercent || discountSetting?.discountOwner || 0,
          });
        } else {
          form.setFieldValue('couponCodeError', 'No corresponding coupon found!');
        }
      } catch (error) {
        const msg = get(error, 'response.data.message');
        form.setFieldValue('couponCodeError', msg || 'No corresponding coupon found!');
      }
      setTimeout(() => inputCodeRef.current?.blur(), 100);
      return;
    }
  };


  return (
    <Spin spinning={loading}>
      <Form.Item name={'couponId'} hidden />
      <Form.Item name={'discountType'} hidden />
      <Form.Item name={'discountValue'} hidden />
      <Container>
        <ContainerDiscount style={{ marginTop: 0, }}>
          <ControlGroup style={{ margin: 0, alignSelf: 'stretch', display: 'flex', width: '100%' }}>
            <div className="input-selector-container" style={{ height: '100%', alignSelf: 'stretch', margin: 0 }}>
              <Form.Item noStyle name={'couponCode'} rules={active ? [{
                validator(_, value) {
                  if (!value) return Promise.reject('Please enter coupon code');
                  if (value?.length < MIN_LENGTH) return Promise.reject('Gift number code is at least ' + MIN_LENGTH + ' letters long');
                  return Promise.resolve();
                },
              }] : undefined}>
                <Input
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  placeholder='Enter coupon code'
                  autoFocus ref={inputCodeRef} inputMode='none' className='gift-card-number-input fs-big fs-medium' size='small'
                  onKeyDown={handleKeyDown}
                  onChange={e => {
                    _debounce(e.target.value);
                    form.resetFields(['couponCodeError', 'couponCodeValid', 'coupon']);
                  }}
                />
                {/* 53192826 */}
              </Form.Item>
              <button type='button' className='btn-scan' onClick={onScan}><img src={qrScanIcon} alt="qrScanIcon-v2" style={{ height: '78px', width: '78px' }} /></button>
            </div>
          </ControlGroup>
        </ContainerDiscount>
        <StaffOwnerPercent
          inputOwnerRef={inputOwnerRef}
          inputStaffRef={inputStaffRef}
          setKeyboardTargetFor={setKeyboardTargetFor}
        />
        <Form.Item noStyle name={'couponCodeValid'} rules={active ? [
          ({ getFieldValue }) => ({
            async validator() {
              const code = getFieldValue('couponCode');
              const value = code?.trim() || '';
              if (!value) return Promise.reject('Please enter coupon code');
              if (value?.length < MIN_LENGTH) return Promise.reject('Gift number code is at least ' + MIN_LENGTH + ' letters long');
              try {
                const resData: IResponseDataBody<ICouponItemData> = await shopApis.getCouponByCode(value);
                const _result = resData.data.data;
                if (!_result) return Promise.reject('No corresponding coupon found!');
              } catch (error) {
                const msg = get(error, 'response.data.message');
                return Promise.reject(msg || 'No corresponding coupon found!');
              }
              return Promise.resolve();
            }
          }),
        ] : undefined} />
        <Form.Item name={'couponCodeError'} noStyle />
        <Form.Item shouldUpdate noStyle>{({ getFieldsError, getFieldValue }) => {
          const errors = getFieldsError().filter(o => o.errors.length > 0).map(o => ({ errors: o.errors, name: first(o.name) }));
          const errorText = getFieldValue('couponCodeError');
          const _error = errorText || first(find(errors, o => o.name === 'couponCode' || o.name === 'couponCodeValid')?.errors) || '';
          return <Text mt={0.5} style={{ opacity: !_error ? 0 : 1, transform: 'translateY(-32px)', alignSelf: 'stretch', textAlign: 'center' }} variant='ERROR'>{_error || '--'}</Text>;
        }}</Form.Item>
        <Form.Item noStyle name={'coupon'}><CouponUIData /></Form.Item>
        <Form.Item hidden={keyboardTargetFor !== 'COUPON'} noStyle name={'couponCode'}>
          <NumberPadV2
            type={'NUMBER'}
            controlRef={inputCodeRef}
            onChange={(val) => {
              form.validateFields(['couponCode']);
              _debounce(val.toString());
              form.resetFields(['couponCodeError', 'couponCodeValid', 'coupon']);
            }}
          />
        </Form.Item>
        <Form.Item hidden={keyboardTargetFor !== 'STAFF_PERCENT'} noStyle name={'staffPaidPercent'}>
          <NumberPadIntV2
            controlRef={inputStaffRef}
            max={100}
            onChange={(staff) => {
              form.validateFields(['staffPaidPercent']);
              const owner = 100 - staff;
              form.setFieldValue('ownerPaidPercent', owner);
            }}
          />
        </Form.Item>
        <Form.Item hidden={keyboardTargetFor !== 'OWNER_PERCENT'} noStyle name={'ownerPaidPercent'}>
          <NumberPadIntV2
            controlRef={inputOwnerRef}
            max={100}
            onChange={(owner) => {
              form.validateFields(['ownerPaidPercent']);
              const staff = 100 - owner;
              form.setFieldValue('staffPaidPercent', staff);
            }}
          />
        </Form.Item>
      </Container>
    </Spin>
  );
});

CouponDiscountTab.displayName = 'CouponDiscountTab';

export default CouponDiscountTab;
const ContainerDiscount = styled.div`
display: flex;
align-self: stretch;
align-items: center;
margin: 0 1rem;
margin-bottom: 0rem;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
border: 1px solid rgba(44, 35, 66, 0.40);
border-radius: 6px;
overflow: hidden;
height: 78px;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  background: #fff;
  .NumberPadStyled {
    background: #f5f5f5;
  }
`;
const ControlGroup = styled(GiftCardNumberContainer)`
  .gift-card-number-input {
     background: transparent;
     &.fs-medium {
      font-size: 36px;
     }
  }
  margin-bottom: 0;
`;

const CouponUIData = ({ value }: { value?: ICouponItemData | null }) => {
  if (!value) return null;
  return (
    <div style={{ width: '25.5rem', alignSelf: 'center', transform: 'translateY(-32px)' }}>
      <CouponTicket data={value} />
    </div>
  );
};