import { Row } from 'antd';
import { ICalendarSchema } from 'features/appointment/services/types/calendar';
import { memo } from 'react';
import CalendarStyled, { fixedStyle } from './styles';
type Props = {
  schema: ICalendarSchema;
};
const CalendarViewHeader = ({ schema }: Props) => {
  return (
    <CalendarStyled.THead>
      <CalendarStyled.TRow>
        <CalendarStyled.TH fixedHigh className='fixedHigh' style={fixedStyle}>
          <Row className='actions' justify={'space-between'} />
        </CalendarStyled.TH>
        {schema.headers.length > 0 ? schema.headers.map((item) => {
          return (
            <CalendarStyled.TH
              key={item.id}
              fixed
            >
              <div>
                {schema.renderHeader(item)}
              </div>
            </CalendarStyled.TH>
          );
        }) : <>
          <CalendarStyled.TH fixed />
        </>}
      </CalendarStyled.TRow>
    </CalendarStyled.THead>
  );
};

export default memo(CalendarViewHeader);
