import React from 'react';
import { APPOINTMENT_LAYOUT } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import styled from 'styled-components';
import CalendarFilter from '../Filter';
import TableFilter from '../TableView/Filter';

const AppointmentFilterV2 = () => {
  const layout = appointmentSelectors.getAppointmentLayout();
  return (
    <Container className={layout}>
      {layout === APPOINTMENT_LAYOUT.CALENDAR ? <CalendarFilter v2 /> : <TableFilter v2 />}
    </Container>
  );
};

export default AppointmentFilterV2;

const Container = styled.div`
display: flex;
align-items: center;
background: #FFF;
padding: 16px;
padding-top: 8px;
gap: 48px;
&.LIST {
  gap: 16px;
}
.AptStatusFilter.ant-select-multiple.ant-select-sm {
  height: 100%;
  width: 320px !important;
  .ant-select-selection-item {
    height: auto;
  }
  .ant-select-arrow {
    color: #1D2129;
    font-size: 18px;
    transform: scaleX(1.1) scaleY(0.9);
  }
  .ant-select-selector {
    background: #E5E6EB;
    border: 1px solid #E5E6EB;
    .ant-select-selection-item-content {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.SwitchCalendarTypeStyled {
  .GroupButtons {
    background: #E5E6EB;
    border: 1px solid #E5E6EB;
  }
  button {
    padding-right: 16px;
    padding-left: 16px;
    width: auto;
    .BODY_1 {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
    }
  }
  button.active {
    border-radius: 4px;
    background: #232F3E;
    .BODY_1 {
      color: #FFF;
    }
  }
}
`;
