import Box from 'components/Box';
import Form from 'components/Form';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import CardBox from 'features/cashier/components/CardBox';
import { IStateDetailPayment } from 'features/payment/services/types/payment';
import { useEffect, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import multiplePaymentActions from '../../../services/actions';
import multiplePaymentSelectors from '../../../services/selectors';
import CountingFooter from './CountingFooter';
import CardBoxV2 from '../CardBoxV2';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from 'components/DollarAmount/Input';
import { ContainerAmountLoyaltyPoint } from 'features/cashier/components/TickerFooter/ButtonLoyaltyPoint';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
type Props = {
  active?: boolean;
  amountKB?: number;
  disabled?: boolean;
  onRedeem: (_detailPayment: IStateDetailPayment) => Promise<void>;
  v2?: boolean;
};
const LoyaltyPoint = ({ active = false, disabled, amountKB, v2 }: Props) => {
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [amount, setAmount] = useState<number | null>(null);
  const dollarRef = useDollarAmountInputRef();
  const dispatch = useAppDispatch();
  const detail = multiplePaymentSelectors.getDetail();
  const customer = multiplePaymentSelectors.getCustomerInfo();
  const allSetting = shopSelectors.data.allSetting();
  const visible = multiplePaymentSelectors.getVisibleModalPoint();
  const setVisible = (value: boolean) => {
    dispatch(multiplePaymentActions.setVisibleModalPoint(value));
  };
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();
  const customerInfo = multiplePaymentSelectors.getCustomerInfo();

  const exchangeDollar = (point: number) => {
    if (!loyaltySetting) return 0;

    return +(point) / loyaltySetting?.oneDollarToPoint;
  };


  useEffect(() => {
    if (!visible) return;
    const _amountKB = amountKB || 0;
    const availablePointEx = exchangeDollar(+(customerInfo?.point || ''));
    const amountVal = availablePointEx - _amountKB < 0 ? availablePointEx : _amountKB;

    setTimeout(() => currencyRef.current?.setValue(amountVal?.toFixed(2) || ''), 100);
  }, [visible, detail]);

  const handleOpen = () => {
    setVisible(true);
  };

  const _amount = amount || 0;
  const redeemPoint = loyaltySetting ? (+_amount) * (loyaltySetting?.oneDollarToPoint || 0) : 0;
  const balancePoint = +(customer?.point || '') - redeemPoint;

  const AvailablePoint = () => {
    return (
      <>
        {customerInfo?.point} point = {formatCurrency(exchangeDollar(+(customerInfo?.point || '')))}
      </>
    );
  };


  const BalancePoint = () => {
    if (!loyaltySetting) return <>{('0 point = $0')}</>;
    const balancePoint = +(customerInfo?.point || '') - redeemPoint;

    if (balancePoint <= 0) return <>0 point = $0</>;

    return (
      <>
        {balancePoint.toFixed(2)} point = {formatCurrency(exchangeDollar(+(balancePoint || '')))}
      </>
    );
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleSubmit = () => {
    onClose();
    const loyaltyPointVal = {
      amount: _amount || 0,
      point: redeemPoint,
    };

    dispatch(multiplePaymentActions.setLoyaltyPointPayment(loyaltyPointVal));

    /**
     * AUTO COMPLETE PAYMENT
    */
    // if ((remaining - _amount) <= 0) {
    //   onRedeem({
    //     ...detailPayment,
    //     [PAYMENT_TYPE.LOYALTY_POINT]: loyaltyPointVal
    //   });
    // }
  };

  const FooterContent = () => {
    return <ContainerAmountLoyaltyPoint>
      <div className="amount-item">
        <div className="amount-label">Available:</div>
        <div className="amount-value"><AvailablePoint /></div>
      </div>
      <div className="amount-item">
        <div className="amount-label">Balance:</div>
        <div className="amount-value"><BalancePoint /></div>
      </div>
    </ContainerAmountLoyaltyPoint>;
  };
  return (
    <>

      {v2 ? <CardBoxV2
        type='POINT'
        disabled={!customer?.point || disabled}
        onClick={handleOpen}
      /> : <CardBox
        type={active ? 'ACTIVE' : 'DEFAUL'}
        icon="loyaltyPoint"
        disabled={!customer?.point || disabled}
        onClick={handleOpen}
      >
        Point
      </CardBox>}
      {v2 ? (
        <Modal
          visible={visible}
          modalTitle='POINTS'
          showClose
          okTitle={'Redeem'}
          noneFooterStyle
          className='modal-overflow-unset modal-non-opacity'
          containerPadding={0}
          disableOk={!(_amount > 0 && !(balancePoint < 0))}
          onClose={onClose}
          onSubmit={handleSubmit}
          v2
          noneBodyStyle
          width={'1000px'}
        >
          {
            allSetting?.activeFormatMoney ?
              <Box display='flex' flexDirection='column' gap='2'>
                <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
                  Enter Amount:
                </Text>
                <InputWithKeyboardStyled
                  ref={dollarRef}
                  value={amount}
                  onChange={setAmount}
                  inputMode='none'
                  autoFocus
                />
                <FooterContent />
                <DollarAmountKeyboard
                  controlRef={dollarRef}
                  value={amount}
                  onChange={setAmount}
                />
              </Box>
              : <>
                <CurrencyKeyBoard v2
                  ref={currencyRef}
                  value={amount} onChange={val => setAmount(val || 0)}
                  FooterContent={FooterContent}
                />
              </>
          }
        </Modal>
      ) : (
        <Modal
          // width="auto"
          footerBgColor={colorTheme.fill_5}
          headerBgColor={colorTheme.info_bg_3}
          visible={visible}
          onClose={onClose}
          onSubmit={onClose}
          modalTitle="POINTS"
          width={800}
          noneBodyStyle
          footer={
            <CountingFooter
              submitButtonTitle="Redeem"
              isValid={_amount > 0}
              onSubmit={handleSubmit}
              handleCloseModal={onClose}
              style={{ padding: '1.5rem', paddingTop: '1rem' }}
            >
              <Box className="center">
                <Box display="flex" alignItems="center" gap="4">
                  <Text variant="CONTENT_1" color="text_3">
                    Balance point:
                  </Text>
                  <Text variant="H5" color="text_3" whiteSpace='nowrap' >
                    <BalancePoint />
                  </Text>
                </Box>
              </Box>
              <Text mb={1} />
            </CountingFooter>
          }
        >
          <BodyFormStyled>
            <Box >
              <Box className="space-between" mb="5">
                <Text variant="CONTENT_1" color="text_3">
                  Available Point:
                </Text>
                <Text variant="H9" color="text_3">
                  <AvailablePoint />
                </Text>
              </Box>
              <Text variant="H6" color="text_3">
                Redeem
              </Text>
              <CurrencyKeyBoard ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
            </Box>
          </BodyFormStyled>
        </Modal>
      )}
    </>
  );
};

export default LoyaltyPoint;
const BodyFormStyled = styled(Form)`
padding: 1rem 3rem;
`;