import { createAsyncAction, createAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import {
  IActiveCouponsParams,
  ICouponsParams,
  IGetCustomersParam,
  IReferralsParams,
} from './types/api';
import { IAppointmentParams } from './types/appointment';
import { CustomerDetailsForm } from './types/customer';
import { ITransactionsParams } from './types/reducer';

const getCustomers = createAsyncAction<IGetCustomersParam>(
  PREFIX_ACTIONS + 'getCustomers'
);
const setCustomerParam = createAction<IGetCustomersParam>(
  PREFIX_ACTIONS + 'setCustomerParam'
);
const handleChangeDisplayType = createAction(
  PREFIX_ACTIONS + 'handleChangeDisplayType'
);
const getCustomerDetails = createAsyncAction<string>(
  PREFIX_ACTIONS + 'getCustomerDetails'
);

const setAppointmentParams = createAction<IAppointmentParams>(
  PREFIX_ACTIONS + 'setAppointmentParams'
);
const getAppointmentList = createAsyncAction<IAppointmentParams>(
  PREFIX_ACTIONS + 'getAppointmentList'
);
const setTransactionsParams = createAction<ITransactionsParams>(
  PREFIX_ACTIONS + 'setTransactionsParams'
);
const getTransactionsList = createAsyncAction<ITransactionsParams>(
  PREFIX_ACTIONS + 'getTransactionsList'
);

const setReferralsParams = createAction<IReferralsParams>(
  PREFIX_ACTIONS + 'setReferralsParams'
);
const getReferrals = createAsyncAction<IReferralsParams>(
  PREFIX_ACTIONS + 'getReferrals'
);

const getSummaryReport = createAsyncAction<string>(
  PREFIX_ACTIONS + 'getSummaryReport'
);
const getCustomersActive = createAsyncAction<{
  lastPhoneNumber?: number | string;
}>(PREFIX_ACTIONS + 'getCustomersActive');

const deleteCustomer = createAsyncAction<string>(
  PREFIX_ACTIONS + 'deleteCustomer'
);
const editCustomer = createAsyncAction<CustomerDetailsForm>(
  PREFIX_ACTIONS + 'editCustomer'
);
const setCouponsParams = createAction<ICouponsParams>(
  PREFIX_ACTIONS + 'setCouponsParams'
);
const getCoupons = createAsyncAction<ICouponsParams>(
  PREFIX_ACTIONS + 'getCoupons'
);
const setActiveCouponsParams = createAction<IActiveCouponsParams>(
  PREFIX_ACTIONS + 'setActiveCouponsParams'
);
const getActiveCoupons = createAsyncAction<IActiveCouponsParams>(
  PREFIX_ACTIONS + 'getActiveCoupons'
);
const deleteActiveCoupon = createAction<string>(PREFIX_ACTIONS + 'deleteActiveCoupon');

const getCustomerSummary = createAsyncAction(PREFIX_ACTIONS + 'getCustomerSummary');
const doInit = createAction(PREFIX_ACTIONS + 'init');

const customerActions = {
  doInit,
  getCustomerSummary,
  getCustomers,
  setCustomerParam,
  handleChangeDisplayType,
  getCustomerDetails,
  getAppointmentList,
  setAppointmentParams,
  setTransactionsParams,
  getTransactionsList,
  getSummaryReport,
  getCustomersActive,
  deleteCustomer,
  setReferralsParams,
  getReferrals,
  editCustomer,
  setCouponsParams,
  getCoupons,
  setActiveCouponsParams,
  getActiveCoupons,
  deleteActiveCoupon,
};

export default customerActions;
