import { Popover } from 'antd';
import Text from 'components/Text';
import { get } from 'lodash';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import defaultColor from './colorSet.jpeg';
export const servicesColorSets = {
  '#F6F7FC': { bg: '#F6F7FC', stroke: '#86909C' },
  '#FDF4FF': { bg: '#FDF4FF', stroke: '#8468BB' },
  '#FEFFED': { bg: '#FEFFED', stroke: '#1D2129' },
  '#F4FFF2': { bg: '#F4FFF2', stroke: '#6AC45F' },
  '#F2F6FE': { bg: '#F2F6FE', stroke: '#809BCE' },
  '#FDF6F4': { bg: '#FDF6F4', stroke: '#F05326' },
};

export const getServiceColorSet = (value?: string | null) => {
  const colorSet: { bg: string; stroke: string; } = get(servicesColorSets, [value || ''], servicesColorSets['#F6F7FC']);
  return colorSet;
};

const listServicesColorSets = Object.entries(servicesColorSets).map(([key, value]) => ({ style: value, value: key }));

type Props = {
  value?: string;
  onChange?: (value: string) => void;
};
const PopoverChangeColors = ({ value, onChange = () => undefined }: Props) => {
  const [open, setOpen] = useState(false);
  const colorSet: { bg: string; stroke: string; } = get(servicesColorSets, [value || ''], servicesColorSets['#F6F7FC']);
  const onChooseColorSet = (code: string) => {
    onChange(code);
    setOpen(false);
  };
  const content = useMemo(() => {
    return <Content>
      <Text variant='CONTENT_2'>Change Color</Text>
      <div className="color_set">
        {listServicesColorSets.map(o => (
          <button onClick={() => onChooseColorSet(o.value)} type='button' key={o.value} className="grid-item" style={{ background: o.style.bg, borderColor: o.style.stroke }}></button>
        ))}
      </div>
    </Content>;
  }, [value, onChange]);
  return (
    <>
      <Popover onOpenChange={setOpen} open={open} trigger={['click']} content={content}>
        {
          value ?
            <ButtonStyled type='button' style={{ background: colorSet.bg === '#FEFFED' ? '#F4F439' : colorSet.stroke, borderColor: colorSet.bg === '#FEFFED' ? '#F4F439' : colorSet.stroke }} >{colorSet.bg !== '#FEFFED' ? colorIcon : colorIconBlack}</ButtonStyled>
            : <ImageDefault src={defaultColor} />
        }
      </Popover>
    </>
  );
};

export default PopoverChangeColors;

const ImageDefault = styled.img`
  width: 40px;
  height: 32px;
  border-radius: 4px;
`;

const ButtonStyled = styled.button`
  width: 40px;
  height: 32px;
  background: #F6F7FC;
  border-radius: 4px;
  border-width: 2px;
  border-style: solid;
`;
const Content = styled.div`
display: flex;
flex-direction: column;
gap: 16px;
.color_set {
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 8px;
  }
  button.grid-item {
    width: 70px;
    height: 60px;
    border-radius: 4px;
    border-width: 3px;
    border-style: solid;
    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }
`;

const colorIcon = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M5.91919 0.300949C6.01415 0.174333 6.20408 0.0398035 6.35443 0.182246C9.24286 3.04693 12.1155 5.93536 14.996 8.80795C15.1068 8.92666 15.2571 9.02953 15.2809 9.20363C15.3283 9.39355 15.2334 9.58348 15.0909 9.70218C12.8831 11.9021 10.6831 14.11 8.47525 16.3099C8.29324 16.5078 7.96879 16.5157 7.78677 16.3179C5.571 14.1021 3.35522 11.8863 1.13944 9.67052C0.925777 9.49643 0.917863 9.12449 1.12361 8.94248C2.92789 7.13821 4.73217 5.32602 6.54436 3.52965C5.99041 2.99154 5.44438 2.42968 4.89044 1.88365C4.80339 1.80451 4.73217 1.70164 4.67677 1.59876C5.03288 1.12395 5.50769 0.736191 5.91919 0.300949ZM7.99253 4.72459C6.48896 6.24398 4.96957 7.75546 3.45809 9.26694C6.5681 9.26694 9.6781 9.27485 12.7881 9.25902C11.2371 7.70798 9.68601 6.16485 8.14288 4.60589C8.0954 4.64546 8.04001 4.68502 7.99253 4.72459Z" fill="#FDF4FF" />
  <path d="M16.2225 13.5958C16.634 12.9153 17.1167 12.2822 17.6469 11.6966C18.3512 12.5196 19.0239 13.3901 19.467 14.3872C19.6807 14.862 19.7994 15.408 19.6411 15.9145C19.4196 16.7929 18.5807 17.4893 17.6628 17.4735C16.6815 17.513 15.7556 16.7375 15.6053 15.7721C15.447 14.9965 15.8506 14.2527 16.2225 13.5958Z" fill="#FDF4FF" />
  <path d="M0.545908 19.5781C0.537995 19.4278 0.688351 19.3328 0.822881 19.3565C7.60474 19.3565 14.3866 19.3565 21.1685 19.3565C21.2713 19.3407 21.3663 19.404 21.4534 19.4594C21.4613 20.2033 21.4613 20.9471 21.4534 21.691C21.4613 21.8414 21.2872 21.8809 21.1764 21.873C14.3945 21.873 7.61266 21.873 0.830794 21.873C0.672524 21.9047 0.537995 21.7781 0.553822 21.6198C0.537995 20.9313 0.537995 20.2508 0.545908 19.5781Z" fill="#FDF4FF" />
</svg>;

const colorIconBlack = <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
  <path d="M5.91919 0.300949C6.01415 0.174333 6.20408 0.0398035 6.35443 0.182246C9.24286 3.04693 12.1155 5.93536 14.996 8.80795C15.1068 8.92666 15.2571 9.02953 15.2809 9.20363C15.3283 9.39355 15.2334 9.58348 15.0909 9.70218C12.8831 11.9021 10.6831 14.11 8.47525 16.3099C8.29324 16.5078 7.96879 16.5157 7.78677 16.3179C5.571 14.1021 3.35522 11.8863 1.13944 9.67052C0.925777 9.49643 0.917863 9.12449 1.12361 8.94248C2.92789 7.13821 4.73217 5.32602 6.54436 3.52965C5.99041 2.99154 5.44438 2.42968 4.89044 1.88365C4.80339 1.80451 4.73217 1.70164 4.67677 1.59876C5.03288 1.12395 5.50769 0.736191 5.91919 0.300949ZM7.99253 4.72459C6.48896 6.24398 4.96957 7.75546 3.45809 9.26694C6.5681 9.26694 9.6781 9.27485 12.7881 9.25902C11.2371 7.70798 9.68601 6.16485 8.14288 4.60589C8.0954 4.64546 8.04001 4.68502 7.99253 4.72459Z" fill="#1D2129" />
  <path d="M16.2225 13.5958C16.634 12.9153 17.1167 12.2822 17.6469 11.6966C18.3512 12.5196 19.0239 13.3901 19.467 14.3872C19.6807 14.862 19.7994 15.408 19.6411 15.9145C19.4196 16.7929 18.5807 17.4893 17.6628 17.4735C16.6815 17.513 15.7556 16.7375 15.6053 15.7721C15.447 14.9965 15.8506 14.2527 16.2225 13.5958Z" fill="#1D2129" />
  <path d="M0.545908 19.5781C0.537995 19.4278 0.688351 19.3328 0.822881 19.3565C7.60474 19.3565 14.3866 19.3565 21.1685 19.3565C21.2713 19.3407 21.3663 19.404 21.4534 19.4594C21.4613 20.2033 21.4613 20.9471 21.4534 21.691C21.4613 21.8414 21.2872 21.8809 21.1764 21.873C14.3945 21.873 7.61266 21.873 0.830794 21.873C0.672524 21.9047 0.537995 21.7781 0.553822 21.6198C0.537995 20.9313 0.537995 20.2508 0.545908 19.5781Z" fill="#1D2129" />
</svg>;
