import Button, { ButtonProps } from 'components/Button';
import Modal from 'components/Modal';
import React, { useRef, useState } from 'react';
import CurrencyKeyBoard, { CurrencyKeyBoardRef, ICurrencyKeyBoard } from './NumberKeyBoard/Currency';
import CashierActionButton from 'features/cashier/components/CashierActionBtn';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from './DollarAmount/Input';
import DollarAmountKeyboard from './DollarAmount/Keyboard';
import Text from './Text';
interface INumberKeyBoardButtonProps extends Omit<ButtonProps, 'onSubmit'> {
  btnTitle: string
  modalTitle?: string;
  defaultValue?: number;
  onSubmit?: (value: number) => Promise<boolean>;
  verticalButton?: boolean;
  v2?: boolean;
  activeFormatMoney?: boolean;
}
const NumberKeyBoardButton: React.FC<INumberKeyBoardButtonProps> = (

  { activeFormatMoney, verticalButton, btnTitle, modalTitle = 'TIP', onSubmit = () => undefined, v2, ...props },
) => {
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const inputRefV2 = useDollarAmountInputRef();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<number | null>(null);

  // useEffect(() => {
  //   if (props.defaultValue && !text) {
  //     setText(String(props.defaultValue));
  //   }
  // }, [props.defaultValue]);

  const handleShowModal = () => {
    setOpen(true);
    setTimeout(() => {
      currencyRef.current?.setValue('');
      inputRefV2.current?.init('');
    }, 100);
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmitModal = async () => {
    const isClose = await onSubmit(value || 0);
    if (isClose) handleCloseModal();
  };
  const setAmountValue: ICurrencyKeyBoard['onChange'] = (value: ICurrencyKeyBoard['value']) => {
    setValue(value || null);
  };

  return (
    <>
      {!verticalButton ?
        <Button {...props} onClick={handleShowModal}>
          {btnTitle}
        </Button> :
        <CashierActionButton
          {...props}
          v2UI={v2} onClick={handleShowModal} label={btnTitle}
          feature='TIP'
        />
      }

      <Modal
        modalTitle={modalTitle}
        visible={open}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        destroyOnClose={true}
        v2={v2}
        width={v2 ? '800px' : undefined}
        noneBodyStyle={v2}
        okTitle={'Ok'}
        noneFooterStyle={v2}
      >
        {activeFormatMoney && v2 ?
          <>
            <Text mt={2} />
            <InputWithKeyboardStyled
              ref={inputRefV2}
              value={value}
              onChange={setAmountValue}
              inputMode='none'
              autoFocus
              color='#D2464F'
              maxValue={1000}
            />
            <DollarAmountKeyboard
              controlRef={inputRefV2}
              value={value}
              maxValue={1000}
              onChange={setAmountValue}
            />
          </>
          :
          <CurrencyKeyBoard tipV2 v2={v2} ref={currencyRef} value={value} onChange={setAmountValue} />
        }

      </Modal>
    </>
  );
};

export default NumberKeyBoardButton;
