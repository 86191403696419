import { PAYMENT_TYPE } from './../../payment/services/constants';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { findIndex, set } from 'lodash';
import moment from 'moment';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IEditStaffPayload, IResponseTicket } from './types/api';
import { IState } from './types/reducer';

const initialState: IState = {
  ticketList: [],
  total: 0,
  params: {
    status: 'OPEN_TICKET',
    date: moment().format('MM-DD-YYYY'),
    page: 1,
    size: 10,
  },
  ticketDetailsById: null,
  adjustTipList: [],
  visibleCusPhone: false,
  printVoidTicketData: null,
  summaryCreditCards: null,
  summaryTickets: null,
  isVerifyKeyIn: false,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    setIsVerifyKeyIn: (state, { payload }: { payload: boolean }) => {
      state.isVerifyKeyIn = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(actions.getSummaryTickets.success, (state, { payload }) => {
        state.summaryTickets = payload;
      })
      .addCase(actions.getSummaryCreditCards.success, (state, { payload }) => {
        state.summaryCreditCards = payload;
      })
      .addCase(actions.setVisibleCusPhone, (state, { payload }) => {
        state.visibleCusPhone = payload;
      })
      .addCase(
        actions.getTickets.success,
        (state, { payload }: PayloadAction<IResponseTicket>) => {
          state.ticketList = payload.content;
          state.total = payload.totalElements;
        }
      )
      .addCase(actions.setTicketsParams, (state, { payload }) => {
        const _params = {
          ...(state.params ?? {}),
          ...payload,
        };
        state.ticketList = [];
        state.params = _params;
      })
      .addCase(actions.addTip, (state, { payload }) => {
        if (state.ticketDetailsById) {
          set(state.ticketDetailsById, 'tip', payload);
        }
      })
      .addCase(
        actions.editStaff.success,
        (state, { payload }: PayloadAction<IEditStaffPayload>) => {
          if (state.ticketDetailsById) {
            const index = findIndex(state.ticketDetailsById.items, {
              staffId: payload.existStaffId,
            });
            set(
              state.ticketDetailsById,
              `items[${index}].staffName`,
              payload.newStaff.firstName + ' ' + payload.newStaff.lastName
            );
            set(
              state.ticketDetailsById,
              `items[${index}].staffId`,
              payload.newStaff.id
            );
          }
        }
      )

      .addCase(actions.getTicketDetails.success, (state, { payload }) => {
        state.ticketDetailsById = payload;
      })
      .addCase(actions.doPrintVoidTicket.success, (state, { payload }) => {
        state.printVoidTicketData = payload;
      })
      .addCase(actions.doPrintVoidTicket.fetch, (state) => {
        state.printVoidTicketData = null;
      })
      .addCase(actions.refundTicketByBillId.success, () => {
        // state.ticketDetailsById = payload;
        // do sth with ticketDetailsById
      })
      .addCase(actions.voidTicketByBillId.success, () => {
        // do sth with ticketDetailsById
      })
      .addCase(actions.adjustTip.success, (state, { payload }) => {
        state.adjustTipList = payload;
      })
      .addCase(actions.handleChangeAdjustTip, (state, { payload }) => {
        const index = state.adjustTipList.findIndex(
          (item) => item?.billId === payload.billId
        );
        if (index !== -1) {
          if (payload?.tip !== 0) {
            state.adjustTipList[index].tip = payload.tip ?? 0;
          } else {
            state.adjustTipList.splice(index, 1);
          }
        } else {
          state.adjustTipList.push(payload);
        }
      })
      .addCase(
        actions.editCashByBillId.success,
        (
          state,
          { payload }: PayloadAction<{ amount: number; billId: string }>
        ) => {
          if (state.ticketDetailsById) {
            state.ticketDetailsById.payments =
              state.ticketDetailsById.payments.map((item) => {
                if (item.paymentType === PAYMENT_TYPE.CASH) {
                  return { ...item, amount: payload.amount };
                }
                return item;
              });
          }
        }
      )
      .addCase(actions.doInit, (state) => {
        state.params = {
          status: 'OPEN_TICKET',
          date: moment().format('MM-DD-YYYY'),
          page: 1,
          size: 10,
        };
        state.ticketList = [];
        state.total = 0;
        state.isVerifyKeyIn = false;
        state.ticketDetailsById = null;
        state.adjustTipList = [];
        state.visibleCusPhone = false;
      })
      ;
  },
});
export const ticketListUIActions = Slice.actions;
const ticketListServiceReducer = Slice.reducer;
export default ticketListServiceReducer;
