import { Col, Radio, RadioChangeEvent, Row } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modal';
import NumberKeyBoard from 'components/NumberKeyBoard';
import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import { IWalkInData } from 'features/checkIn/services/types/demoData';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
export enum TYPE_WALK_IN {
  Individual = 'Individual',
  Group = 'Group',
}
type IModalChooseGroupRef = {
  open: () => void;
  close: () => void;
};

export const useModalChooseGroupRef = () => useRef<IModalChooseGroupRef>(null);

const ModalChooseGroup = forwardRef<IModalChooseGroupRef>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [type, setType] = useState<TYPE_WALK_IN>(TYPE_WALK_IN.Individual);
  const navigate = useNavigate();
  const { shop_id = '' } = useParams();

  const onChange = (e: RadioChangeEvent) => setType(e.target.value);
  const handleSubmitTypeWalkIn = () => {
    const walkInData: IWalkInData = {
      type,
      numOfMembers: 0,
    };
    if (type === TYPE_WALK_IN.Individual) {
      walkInData.numOfMembers = 0;
    } else {
      walkInData.numOfMembers = quantity;
    }

    dispatch(checkInActions.setWalkInData(walkInData));
    navigate(`/store/${shop_id}/check-in/services`);
    setVisible(false);
  };
  const isDisableOk = type === TYPE_WALK_IN.Group && quantity <= 0;

  useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
  }));

  return (
    <>
      <Button mt={1} ntype='PRIMARY' width='15rem' onClick={() => setVisible(true)}>Next</Button>
      <Modal
        modalTitle="Walk-in"
        okTitle={'Next'}
        visible={visible}
        onClose={() => setVisible(false)}
        onSubmit={() => {
          handleSubmitTypeWalkIn();
        }}
        disableOk={isDisableOk}
      >
        <Radio.Group onChange={onChange} value={type} style={{ width: '100%' }}>
          <Row gutter={[16, 16]} style={{ width: '100%' }}>
            <Col span={12}>
              <Radio value={TYPE_WALK_IN.Individual}>Individual</Radio>
            </Col>
            <Col span={12}>
              <Radio value={TYPE_WALK_IN.Group}>Group</Radio>
            </Col>
          </Row>
        </Radio.Group>
        {type === TYPE_WALK_IN.Group && (
          <>
            <Text mt={1.5} mb={0.5} variant='CONTENT_1' color='text_3'>Quantity</Text>
            <NumberKeyBoard type='NUMBER' onChange={e => setQuantity(+e)} value={quantity?.toString()} />
          </>
        )}
      </Modal>
    </>
  );
});

ModalChooseGroup.displayName = 'ModalChooseGroup';

export default ModalChooseGroup;
