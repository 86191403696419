const Voided = () => (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10898_32935)">
            <path d="M39.9017 20.9886C39.6069 17.0351 36.7568 15.1572 35.4791 14.4653V6.06416C35.4791 4.38392 34.1032 2.90137 32.3342 2.90137H3.14496C1.37592 3.0002 0 4.38393 0 6.16299V25.6339C0 27.3142 1.37592 28.6979 3.14496 28.6979H16.7076C16.4128 28.9944 16.2162 29.2909 16.0197 29.4886C15.7248 29.7851 15.7248 30.2793 16.0197 30.5758C19.2629 34.5293 23.5872 36.7037 23.7838 36.8025C23.8821 36.9014 24.0786 36.9014 24.1769 36.9014C24.3735 36.9014 24.4717 36.9014 24.6683 36.8025C24.9631 36.6049 25.0614 36.4072 25.0614 36.0118V34.6281C27.1253 34.6281 32.0393 34.4304 34.8894 32.3549C36.9533 31.07 40.3931 27.7095 39.9017 20.9886ZM23.4889 33.2444C23.2924 33.4421 23.1941 33.6397 23.1941 33.8374V34.6281C21.8182 33.8374 19.656 32.256 17.6904 30.0816C19.5577 27.9072 21.8182 26.4246 23.1941 25.5351V26.3258C23.1941 26.82 23.5872 27.2153 24.0786 27.2153C24.1769 27.2153 27.1253 27.2153 29.484 26.0293C34.8894 23.4595 35.4791 19.2095 35.4791 19.0118V16.4421C36.5602 17.1339 38.0344 18.6165 38.231 21.0874C38.6241 26.82 35.6757 29.7851 33.9066 31.07C30.7617 33.2444 24.2752 32.9479 24.1769 32.9479C23.8821 33.0467 23.6855 33.1456 23.4889 33.2444ZM3.14496 4.77927H32.3342C33.1204 4.77927 33.7101 5.3723 33.7101 6.16299V15.0583V15.1572V19.0118C33.7101 19.1107 33.1204 22.4711 28.6978 24.5467C27.4201 25.1397 25.8477 25.4363 24.9631 25.4363V24.0525C24.9631 23.756 24.7666 23.4595 24.57 23.2618C24.2752 23.0642 23.9803 23.0642 23.6855 23.2618C23.3907 23.3607 20.9337 24.6456 18.3784 26.9188H3.14496C2.35872 26.9188 1.76904 26.3258 1.76904 25.5351V6.16299C1.76904 5.3723 2.35872 4.77927 3.14496 4.77927Z" fill="#1D2129" />
            <path d="M15.5282 16.4421C15.9213 16.6398 16.511 16.8374 17.199 17.0351V20.8898C16.9041 20.7909 16.7076 20.5933 16.511 20.3956C16.2162 20.0991 16.1179 19.7037 16.0196 19.2095V18.913H13.6609V19.3084C13.6609 20.3956 14.054 21.2851 14.742 21.977C15.4299 22.57 16.2162 22.9654 17.199 23.0642V24.5467H18.7715V23.0642C19.4594 22.9654 20.0491 22.7677 20.5405 22.4712C21.1302 22.0758 21.5233 21.5816 21.8181 21.0874C22.113 20.4944 22.3095 19.9014 22.3095 19.2095C22.3095 18.32 22.113 17.6281 21.7199 17.134C21.3267 16.6398 20.9336 16.2444 20.3439 16.0467C19.9508 15.8491 19.3611 15.6514 18.6732 15.4537V11.5991C18.8697 11.6979 19.0663 11.8956 19.2629 12.0933C19.5577 12.3898 19.656 12.6863 19.7543 13.0816V13.3781H22.113V12.9828C22.0147 11.9944 21.6216 11.1049 21.0319 10.5119C20.4422 9.91884 19.656 9.62233 18.7715 9.42466V7.84326H17.199V9.42466C16.2162 9.52349 15.3317 9.91884 14.742 10.6107C14.054 11.3026 13.6609 12.1921 13.6609 13.2793C13.6609 14.1688 13.8574 14.8607 14.2506 15.3549C14.5454 15.8491 15.0368 16.1456 15.5282 16.4421ZM20.0491 19.2095C20.0491 19.7037 19.9508 20.0991 19.5577 20.3956C19.3611 20.6921 19.0663 20.7909 18.7715 20.8898V17.4305C19.1646 17.6281 19.4594 17.727 19.656 18.0235C19.9508 18.32 20.0491 18.7154 20.0491 19.2095ZM17.199 14.7619C16.8059 14.5642 16.511 14.4654 16.3145 14.1688C16.0196 13.8723 15.9213 13.5758 15.9213 13.0816C15.9213 12.5874 16.0196 12.1921 16.3145 11.8956C16.511 11.6979 16.8059 11.5002 17.1007 11.4014V14.7619H17.199Z" fill="#1D2129" />
        </g>
        <defs>
            <clipPath id="clip0_10898_32935">
                <rect width="40" height="34" fill="white" transform="translate(0 3)" />
            </clipPath>
        </defs>
    </svg>

);

export default Voided;
