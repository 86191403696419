import Button from 'components/Button';
import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import { PHONE_MASKED } from 'utils/mask';
import FormAddCustomer from './Form';
import shopActions from 'services/shop/actions';
import {
  ICreateCustomerBody,
  ICustomerFormValues,
  ICustomerItemData,
} from 'services/shop/types/customer';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import styled from 'styled-components';
type Props = {
  model?: 'default' | 'new-ticket' | 'icon' | 'add-close-ticket' | 'new-apt-v2';
  onChooseCustomer?: (val: ICustomerItemData) => void;
  v2?: boolean;
};
type IButtonAddCustomerRef = {
  open: ({ phone, name }: { phone?: string, name?: string }) => void;
};

export const useButtonAddCustomerRef = () => useRef<IButtonAddCustomerRef>(null);

const ButtonAddCustomer = forwardRef<IButtonAddCustomerRef, Props>(({ v2, model = 'default', onChooseCustomer = () => undefined }, ref) => {
  const [visible, setVisible] = useState(false);
  const [form] = useForm();
  const dispatch = useAppDispatch();
  const onOpen = () => setVisible(true);
  const setLoadingPage = useSetLoadingPage();

  const onClose = () => {
    form.resetFields();
    setVisible(false);
  };
  const valid = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const onSubmit = async () => {
    if (!(await valid())) return;
    form.submit();
  };

  const handleFinish = async (values: ICustomerFormValues) => {
    onClose();
    PHONE_MASKED.resolve(values?.phone);
    const phone = PHONE_MASKED.unmaskedValue;
    const payload: ICreateCustomerBody = {
      phone: phone || null,
      name: values.name || null,
      email: values.email || null,
      birthday: values.birthday || null,
    };
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<ICustomerItemData | null> = await shopApis.addNewCustomer(payload);
      if (res.data.data) {
        dispatch(shopActions.get.customers.fetch());
        onChooseCustomer(res.data.data);
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const ButtonView = () => {
    if (model === 'new-ticket') {
      if (v2) {
        return (
          <Button htmlType='button' cardCashier={v2} onClick={onOpen} icon="addPlus" ntype="LIGHT_BLUE">
            <Text color="text_3" variant="CONTENT_2">
              New Customer
            </Text>
          </Button>
        );
      }
      return (
        <Button onClick={onOpen} icon="addPlus" ntype="PRIMARY">
          <Text color="text_3" variant="CONTENT_2">
            ADD NEW CUSTOMER
          </Text>
        </Button>
      );
    }

    if (model === 'icon') {
      return (
        <Button onClick={onOpen} icon="addPlus" ntype="PRIMARY" />
      );
    }
    if (model === 'add-close-ticket') {
      return (
        <Button className='ButtonAddCustomer' style={{ alignSelf: 'stretch', height: '100%', border: 'none', borderRadius: 0, boxShadow: 'none' }} width='54px' ntype='DARK_BLUE' onClick={onOpen}>{IconPlus}</Button>
      );
    }

    if (model === 'new-apt-v2') {
      return (
        <Button onClick={onOpen} className='ButtonAddCustomer' ntype="PRIMARY" >{IconPlusApt}</Button>
      );
    }

    return (
      <Button icon={'addNewPlus'} ntype={'TEXT'} onClick={onOpen}>
        <Text color="info_3" variant="CONTENT_2">
          Add New Customer
        </Text>
      </Button>
    );
  };

  useImperativeHandle(ref, () => ({
    open({ phone, name }) {
      form.setFieldsValue({ name, phone });
      setVisible(true);
    },
  }));

  if (model === 'new-apt-v2') {
    return (
      <>
        <Button onClick={onOpen} className='ButtonAddCustomer' ntype="PRIMARY" >{IconPlusApt}</Button>
        <Modal
          v2
          width={880}
          noneBodyStyle
          visible={visible}
          modalTitle="ADD NEW CUSTOMER"
          onClose={onClose}
          onSubmit={onSubmit}
          cancelTitle="Close"
          okTitle="Done"
          className='modal-bg-v2 modal-non-opacity'
        >
          <ContainerFormNewAptV2 form={form} onFinish={handleFinish}>
            <FormAddCustomer />
          </ContainerFormNewAptV2>
        </Modal>
      </>
    );
  }

  return (
    <>
      <ButtonView />
      <Modal
        visible={visible}
        modalTitle="ADD NEW CUSTOMER"
        onClose={onClose}
        onSubmit={onSubmit}
        cancelTitle="Close"
        okTitle="Done"

        v2={v2}
        containerPadding={v2 ? 0 : undefined}
        noneBodyStyle={v2}
        width={v2 ? '880px' : undefined}
      >
        <FormStyled form={form} className={v2 ? 'v2' : ''} onFinish={handleFinish}>
          <FormAddCustomer />
        </FormStyled>
      </Modal>
    </>
  );
});
ButtonAddCustomer.displayName = 'ButtonAddCustomer';
export default ButtonAddCustomer;

const ContainerFormNewAptV2 = styled(Form)`
  padding: 16px;
  .ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-input, .ant-input-suffix, .InputDOB {
    display: flex;
    height: 56px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid #CCD4DC;
    background: #FFF;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .suggestion {
    .box-item {
      display: flex;
      padding: 8px 24px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      flex: 1 0 0;
      border-radius: 100px;
      border: 1px solid #4384FF;
      background: #FFF;
      .CONTENT_1 {
        color: #4384FF;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;

const FormStyled = styled(Form)`
&.v2 .FormAddCustomerStyled {
padding: 16px;
padding-bottom: 0;
 .ant-form-item .ant-form-item-label label {
  color: #1D2129;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
 } 

 .ant-form-item .ant-form-item-control-input input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
 }

 .InputEmailStyled .suggestion {
  .box-item {
    flex: 1;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 100px;
    border: 1px solid #4384FF;
    background: #FFF;
    .CONTENT_1 {
      color: #4384FF;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 22px;
    }
  }
 }
}
`;

const IconPlus = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M16 6C16.5523 6 17 6.44772 17 7V25C17 25.5523 16.5523 26 16 26C15.4477 26 15 25.5523 15 25V7C15 6.44772 15.4477 6 16 6Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M6 16C6 15.4477 6.44772 15 7 15H25C25.5523 15 26 15.4477 26 16C26 16.5523 25.5523 17 25 17H7C6.44772 17 6 16.5523 6 16Z" fill="white" />
</svg>;


const IconPlusApt = <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M16 6C16.5523 6 17 6.44772 17 7V25C17 25.5523 16.5523 26 16 26C15.4477 26 15 25.5523 15 25V7C15 6.44772 15.4477 6 16 6Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M6 16C6 15.4477 6.44772 15 7 15H25C25.5523 15 26 15.4477 26 16C26 16.5523 25.5523 17 25 17H7C6.44772 17 6 16.5523 6 16Z" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M15.9997 5.33301C15.0792 5.33301 14.333 6.0792 14.333 6.99967V14.333H6.99967C6.0792 14.333 5.33301 15.0792 5.33301 15.9997C5.33301 16.9201 6.0792 17.6663 6.99967 17.6663H14.333V24.9997C14.333 25.9201 15.0792 26.6663 15.9997 26.6663C16.9201 26.6663 17.6663 25.9201 17.6663 24.9997V17.6663H24.9997C25.9201 17.6663 26.6663 16.9201 26.6663 15.9997C26.6663 15.0792 25.9201 14.333 24.9997 14.333H17.6663V6.99967C17.6663 6.0792 16.9201 5.33301 15.9997 5.33301ZM16.333 14.333V6.99967C16.333 6.81558 16.1838 6.66634 15.9997 6.66634C15.8156 6.66634 15.6663 6.81558 15.6663 6.99967V14.333H16.333ZM15.6663 15.6663H16.333V16.333H15.6663V15.6663ZM14.333 15.6663H6.99967C6.81558 15.6663 6.66634 15.8156 6.66634 15.9997C6.66634 16.1838 6.81558 16.333 6.99967 16.333H14.333V15.6663ZM15.6663 17.6663V24.9997C15.6663 25.1838 15.8156 25.333 15.9997 25.333C16.1838 25.333 16.333 25.1838 16.333 24.9997V17.6663H15.6663ZM17.6663 16.333V15.6663H24.9997C25.1838 15.6663 25.333 15.8156 25.333 15.9997C25.333 16.1838 25.1838 16.333 24.9997 16.333H17.6663Z" fill="white" />
</svg>;

