import { Form, FormInstance, InputRef } from 'antd';
import clsx from 'clsx';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import { NumberPadDecimalV2, NumberPadIntV2 } from 'components/NumberKeyBoard/Currency/NumberPadV2';
import { GiftCardNumberContainer } from 'features/cashier/components/CenterMainBody/ExtendFeatures/GiftCard/v2/styles';
import { get } from 'lodash';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import dollar_active_icon from './icons/dollar-active.svg';
import dollar_icon from './icons/dollar.svg';
import percent_active_icon from './icons/percent-active.svg';
import percent_icon from './icons/percent.svg';
import StaffOwnerPercent from './StaffOwnerPercent';
import PercentInput from 'components/CurrencyInputPrecision/PercentInput';
import { IShopAllSetting } from 'services/shop/types/setting';
import DollarAmountInput, {   useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';

export enum DISCOUNT_FORM_TYPE {
  PERCENT = 'PERCENT',
  DOLLAR = 'MONEY',
}
type Ref = {
  onActive: () => void;
};
export const salonDiscountV2TabRef = React.createRef<Ref>();
type Props = {
  active: boolean;
  form: FormInstance<any>;
  allSetting?: IShopAllSetting | null;
};
const SalonDiscountTab = forwardRef<Ref, Props>(({ allSetting, form }, ref) => {
  const inputDiscountValueRef = useRef<InputRef>(null);
  const dollarRef = useDollarAmountInputRef();
  const inputStaffRef = useRef<InputRef>(null);
  const inputOwnerRef = useRef<InputRef>(null);
  const [keyboardTargetFor, setKeyboardTargetFor] = useState<'PERCENT' | 'DOLLAR' | 'STAFF_PERCENT' | 'OWNER_PERCENT'>('PERCENT');
  const onTabChange = (tab: DISCOUNT_FORM_TYPE) => {
    if (tab === DISCOUNT_FORM_TYPE.DOLLAR) return setKeyboardTargetFor('DOLLAR');
    setKeyboardTargetFor('PERCENT');
  };
  useImperativeHandle(ref, () => ({
    onActive: () => {
      form.resetFields(['discountValue']);
      setTimeout(() => {
        inputDiscountValueRef.current?.focus();
        dollarRef.current?.init();
      }, 100);
    },
  }));
  return (
    <Container>
      <ContainerDiscount>
        <ControlGroup style={{ marginTop: 0, flex: 1 }}>
          <div className="input-selector-container" style={{ height: 'unset' }}>
            <Form.Item noStyle shouldUpdate>
              {({ getFieldValue }) => {
                const tab = getFieldValue('discountType');
                return (
                  <Form.Item noStyle name={'discountValue'} rules={[{
                    validator(_, value) {
                      if (!value) return Promise.reject('Please enter discount value');
                      return Promise.resolve();
                    },
                  }]}>
                    {tab === DISCOUNT_FORM_TYPE.PERCENT ?
                      <PercentInput
                        height={64}
                        inputRef={inputDiscountValueRef}
                        inputMode='none' className='gift-card-number-input fs-big fs-medium' size='small'
                        onChangeDebounce={() => form.validateFields(['discountValue'])}
                        maxValue={100}
                        onFocus={() => setKeyboardTargetFor('PERCENT')}
                      /> :
                      !allSetting?.activeFormatMoney ?
                        <CurrencyInputPrecision
                          height={64}
                          inputRef={inputDiscountValueRef}
                          inputMode='none' className='gift-card-number-input fs-big fs-medium' size='small'
                          onChangeDebounce={() => form.validateFields(['discountValue'])}
                          onFocus={() => setKeyboardTargetFor('DOLLAR')}
                        />
                        :
                        <DollarInputDiscountStyled
                          height={64}
                          ref={dollarRef}
                          inputMode='none' className='gift-card-number-input fs-big fs-medium'
                          onChange={() => form.validateFields(['discountValue'])}
                          onFocus={() => setKeyboardTargetFor('DOLLAR')}
                        />
                    }
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        </ControlGroup>
        <Form.Item noStyle name={'discountType'}>
          <DiscountType onChange={onTabChange} />
        </Form.Item>
      </ContainerDiscount>
      <StaffOwnerPercent
        inputOwnerRef={inputOwnerRef}
        inputStaffRef={inputStaffRef}
        setKeyboardTargetFor={setKeyboardTargetFor}
      />
      <Form.Item hidden={keyboardTargetFor !== 'DOLLAR'} noStyle name={'discountValue'}>
        {!allSetting?.activeFormatMoney ?
          <NumberPadDecimalV2
            currency={keyboardTargetFor === 'DOLLAR'}
            controlRef={inputDiscountValueRef}
            onChange={() => form.validateFields(['discountValue'])}
          /> :
          <DollarAmountKeyboard
            controlRef={dollarRef}
            onChange={() => form.validateFields(['discountValue'])} />
        }
      </Form.Item>
      <Form.Item hidden={keyboardTargetFor !== 'PERCENT'} noStyle name={'discountValue'}>
        <NumberPadIntV2
          controlRef={inputDiscountValueRef}
          max={100}
          onChange={() => form.validateFields(['discountValue'])}
        />
      </Form.Item>
      <Form.Item hidden={keyboardTargetFor !== 'STAFF_PERCENT'} noStyle name={'staffPaidPercent'}>
        <NumberPadIntV2
          controlRef={inputStaffRef}
          max={100}
          onChange={(staff) => {
            form.validateFields(['staffPaidPercent']);
            const owner = 100 - staff;
            form.setFieldValue('ownerPaidPercent', owner);
          }}
        />
      </Form.Item>
      <Form.Item hidden={keyboardTargetFor !== 'OWNER_PERCENT'} noStyle name={'ownerPaidPercent'}>
        <NumberPadIntV2
          controlRef={inputOwnerRef}
          max={100}
          onChange={(owner) => {
            form.validateFields(['ownerPaidPercent']);
            const staff = 100 - owner;
            form.setFieldValue('staffPaidPercent', staff);
          }}
        />
      </Form.Item>
    </Container>
  );
});

SalonDiscountTab.displayName = 'SalonDiscountTab';

export default SalonDiscountTab;
const ContainerDiscount = styled.div`
display: flex;
align-self: stretch;
align-items: center;
margin: 0 1rem;
margin-bottom: 0rem;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
border: 1px solid rgba(44, 35, 66, 0.40);
border-radius: 6px;
overflow: hidden;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 32px;
  background: #fff;
  .NumberPadStyled {
    background: #f5f5f5;
  }
`;

const ControlGroup = styled(GiftCardNumberContainer)`
  .gift-card-number-input {
     background: transparent;
     &.fs-medium {
      font-size: 36px;
     }
  }
  margin-bottom: 0;
`;
const DiscountTypeContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
align-self: stretch;
height: 78px;
.discount-type-item {
  position: relative;
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  background: #E5E6EB;
  .discount-icon {
    width: 32px;
    height: 32px;
  }
  .discount-label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .active-checkbox {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 3px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    right: 12px;
    top: 11.5px;
    border-radius: 100px;
    border: 1px solid #FFF;
    background: #FFF;
  }

  &:hover {
    background: #eee;
  }

  &.active {
    background: #2D6772;
    .discount-label {
      color: #fff;
    }
  }
}
`;
const DiscountType = ({ onChange: setTab = () => undefined, value: tab }: { value?: DISCOUNT_FORM_TYPE, onChange?: (value: DISCOUNT_FORM_TYPE) => void }) => {
  return (
    <DiscountTypeContainer>
      <DiscountTypeItem onClick={() => setTab(DISCOUNT_FORM_TYPE.PERCENT)} active={tab === DISCOUNT_FORM_TYPE.PERCENT} type={DISCOUNT_FORM_TYPE.PERCENT} />
      <DiscountTypeItem onClick={() => setTab(DISCOUNT_FORM_TYPE.DOLLAR)} active={tab === DISCOUNT_FORM_TYPE.DOLLAR} type={DISCOUNT_FORM_TYPE.DOLLAR} />
    </DiscountTypeContainer>
  );
};

const configs = {
  [DISCOUNT_FORM_TYPE.PERCENT]: {
    icon: percent_icon,
    iconActive: percent_active_icon,
    label: 'Percent',
  },
  [DISCOUNT_FORM_TYPE.DOLLAR]: {
    icon: dollar_icon,
    iconActive: dollar_active_icon,
    label: 'Dollar',
  }
};
type DiscountTypeItemProps = {
  active?: boolean;
  type: DISCOUNT_FORM_TYPE;
  onClick: () => void;
};
const DiscountTypeItem = ({ type, active, onClick }: DiscountTypeItemProps) => {
  const config = get(configs, type);
  return (
    <button type='button' className={clsx('discount-type-item', active && 'active')} onClick={onClick}>
      <div className="discount-label">{config.label}</div>
      <img src={active ? config?.iconActive : config?.icon} alt={type} className='discount-icon' />
    </button>
  );
};

const DollarInputDiscountStyled = styled(DollarAmountInput)`
  justify-content: center;
  flex: 1;
  &.custom-input-focus input, input {
    border: none !important;
    box-shadow: none !important;
    text-align: center;
    outline: none !important;
  }
  .fake-labels, input, .mock-cursor  {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    align-self: stretch;
    font-size: 36px;
    height: unset;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .fake-labels {
    top: 50%;
    transform: translateY(-50%);
  }
`;
