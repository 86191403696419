import { Row } from 'antd';
import clsx from 'clsx';
import Empty from 'components/Empty';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Spin from 'components/Spin';
import Text from 'components/Text';
import userActions from 'features/user/services/actions';
import userSelectors from 'features/user/services/selectors';
import { IWaitingListResItem } from 'features/user/services/types/waiting';
import { useNavigate } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import storage from 'utils/sessionStorage';
import ButtonRemoveTicket from '../Ticket/ButtonRemoveTicket';
import WalkInItem from './Item';
import { userUIActions } from 'features/user/services/reducers';

type Props = {
  mode: 'staff' | 'ticket' | 'default';
  v2?: boolean;
};
const WalkInWaitingList = ({ v2 }: Props) => {
  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const data = userSelectors.getListWalkIn();
  const loading = userSelectors.getWaitingListLoading();
  const isClockIn = userSelectors.staff.isClockIn();
  const activeRemoveWaiting = userSelectors.activeRemoveWaiting();
  const navigate = useNavigate();
  const allSetting = shopSelectors.data.allSetting();
  const enableChooseEmployeeQueue = userSelectors.enableChooseEmployeeQueue();

  const onToggle = () => {
    dispatch(userActions.activeRemoveWaiting(!activeRemoveWaiting));
  };

  const onRemoveTicket = (record: IWaitingListResItem) => () => {
    modalConfirm.current?.open(`Do you like to delete the ticket #${record.ticketNumber}?`, () => {
      dispatch(userActions.removeWaitingTicket(record.checkInId));
      if (v2)
        dispatch(userActions.activeRemoveTicket(false));
    });
  };

  const onItemClick = (o: IWaitingListResItem) => () => {
    if (allSetting?.chooseEmployeeQueue) {
      if (!o.checkInId) return;
      dispatch(userUIActions.enableChooseEmployeeQueue(o.checkInId));
      return;
    }
    navigate(`/store/${storage.shop_id.get()}/ticket/edit/${o.checkInId}`);
  };

  const RowData = () => {
    if (loading) return <Spin />;

    return (
      data.length > 0 ? <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(160px, 1fr))', gap: '8px' }}>
        {data.map(o => (
          <WalkInItem key={o.checkInId} data={o} viewOnly={!isClockIn}
            removeMode={activeRemoveWaiting} onRemoveTicket={onRemoveTicket(o)}
            active={enableChooseEmployeeQueue === o.checkInId}
            onClick={onItemClick(o)}
          />
        ))}
      </div> : v2 ? null : <Empty description={'Empty walk in ticket'} centerFloating />
    );
  };

  return (
    <Styled className={clsx('ticket-label-section', v2 && 'v2')}>
      <Row className='top-title' align={'middle'} style={{ gap: '8px', marginBottom: '0.5rem', }}>
        <Text className='label-text' variant='H7' mr={1} style={v2 ? { color: '#2D6772', fontWeight: '700' } : {}} >{v2 ? 'Still Waiting...' : 'In The Queue...'}</Text>
        <Text style={{ flex: 1 }} />
        {data.length > 0 ? <ButtonRemoveTicket active={activeRemoveWaiting} onToggle={onToggle} /> : <div style={{ height: '2.5rem' }}></div>}
      </Row>
      <div className='box-div-scroll'>
        <RowData />
      </div>
      <ModalConfirm ref={modalConfirm} />
    </Styled>
  );
};

export default WalkInWaitingList;
const Styled = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
  background: #8defab4d;
  .box-div-scroll {
    flex: 1;
    margin: 0 -1rem;
    padding: 0 1rem;
    background:#fff;
    padding-top: 1rem;
    overflow-y: scroll;
    scrollbar-width: none; /* Firefox 64 */
    border-right: 1px solid #86909C;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  &.v2.ticket-label-section {
    padding-top: 0;
    background: #F5F5F5;
    .top-title {
      background: #fff;
      height: 64px;
      min-height: 64px;
      max-height: 64px;
      overflow: hidden;
      margin: 0 -1rem;
      padding: 0 1rem;
      .label-text {
        font-size: 22px;
        text-transform: uppercase;
      }
    }
  }
  `;