import { Avatar, Rate, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Text from 'components/Text';
import reportActions from 'features/report/services/actions';
import reportApis from 'features/report/services/apis';
import reportSelectors from 'features/report/services/selectors';
import { IEditReviewReportPayload, IReviewReportItem } from 'features/report/services/types/review';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import ModalEditNote, { ModalEditNoteProps, useModalEditNoteRef } from './ModalEditNote';
import { tableGreenStrStyle } from 'features/report/components/Component';
type Props = {
  v2?: boolean;
};
const ReviewTable = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const reviewState = reportSelectors.getReviewReport();
  const modalEditNoteRef = useModalEditNoteRef();
  const setLoadingPage = useSetLoadingPage();

  const columns: ColumnsType<IReviewReportItem> = [
    {
      title: 'DATE & TIME',
      dataIndex: 'strCreatedDate',
      key: 'strCreatedDate',
      width: 150,
      render: (date) => (
        <Text variant="BODY_1" color="text_3" className='none-transform'>
          {date}
        </Text>
      ),
    },
    {
      title: 'TICKET',
      width: 100,
      dataIndex: 'ticketNumber',
      key: 'ticketNumber',
      render: (data) => (<Text variant="CONTENT_2" color="text_3">#{data}</Text>),
      align: 'center'
    },
    {
      title: 'CUSTOMER',
      dataIndex: 'customerName',
      key: 'customerName',
      render: (_, data) => (
        <div>
          <Text textAlign='left' variant="BODY_1" color="text_3" className='none-transform'>{data?.customerName || 'Unknown Customer'}</Text>
          <Text textAlign='left' variant="CONTENT_1" color="text_2">{formatPhone(data?.customerPhone || '')}</Text>
        </div>
      ),
    },
    {
      title: 'STAFF',
      dataIndex: 'staffs',
      key: 'staffs',
      render: (value: IReviewReportItem['staffs']) => {
        return (
          <div>
            {(value.map(o => {
              const staffName = o?.staffName || '';
              return (
                <Row key={o.staffId} align={'middle'} style={{ gap: '8px' }}>
                  <Avatar src={o.avatar} size={'small'}>{staffName?.[0]}</Avatar>
                  <Text variant='BODY_1' color='text_3' className='none-transform'>{staffName}</Text>
                </Row>
              );
            }))}
          </div>
        );
      }
    },
    {
      title: 'SERVICES',
      dataIndex: 'serviceNames',
      key: 'serviceNames',
      render: (services: IReviewReportItem['serviceNames']) => {
        return (
          <div>
            {services?.map((o, i) => (<Text key={i} variant="CONTENT_1" color="text_3" className='none-transform'>{o}</Text>))}
          </div>
        );
      }
    },
    {
      title: 'REVIEW STAR',
      dataIndex: 'stars',
      key: 'stars',
      render: (value) => {
        return (
          <Row align={'middle'} style={{ gap: '8px' }}>
            <Rate value={value} disabled />
          </Row>
        );
      },
    },
    {
      title: 'NOTE',
      dataIndex: 'reason',
      key: 'reason',
      render: (value: string, record) => {
        if (value) return <Text variant="CONTENT_1" color="text_3" className='none-transform'>{value}</Text>;

        return <button type='button' onClick={() => modalEditNoteRef.current?.open(record)}><Text className='please-note prevent-select'>Please Note</Text></button>;
      }
    },
  ];

  const handleEditNote: ModalEditNoteProps['onSubmit'] = async (_detail, _data) => {
    setLoadingPage(true);
    const body: IEditReviewReportPayload = {
      billId: _detail.billId,
      message: _data.review,
      stars: _data.stars
    };
    try {
      const res: IResponseDataBody<boolean> = await reportApis.editReviewReviewReport(body);
      if (res.data.data) {
        dispatch(reportActions.setReviewReportParams({}));
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const handleChangePage = (page: number) => {
    const params = {
      page: page,
    };
    dispatch(reportActions.setReviewReportParams(params));
  };

  return (
    <TableStyled className={v2 ? 'v2' : ''}>
      <Table
        columns={columns}
        dataSource={reviewState.data}
        style={v2 ? { width: '100%' } : { width: '100%', padding: '1rem' }}
        rowClassName={'row-table'}
        pagination={{
          total: reviewState?.pagination?.totalElements ?? 0,
          pageSize: reviewState?.pagination?.pageSize ?? 0,
          current: reviewState?.pagination?.pageNumber || 1,
          showSizeChanger: false,
          onChange: handleChangePage,
        }}
        scroll={{ y: 500 }}
      />
      <ModalEditNote v2={v2} ref={modalEditNoteRef} onSubmit={handleEditNote} />
    </TableStyled>
  );
};

export default ReviewTable;

const TableStyled = styled(Row)`
    flex: 1;
    border-radius: 5px;
    border: 1px solid #86909C;
    background: #FFF;

    /* box */
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .none-transform {
    text-transform: none;
  }

  .please-note {
    overflow: hidden;
    color: #008BFF;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: none;
    &:hover {
      cursor: pointer;
    }
  }
    ${tableGreenStrStyle}


&.v2 {
  flex: 1;
  box-shadow: none;
  border: none;
  .ant-table-wrapper {
    padding: 0;
  }
  .ant-table-wrapper .ant-table {
    .ant-table-thead th.ant-table-cell {
        height: 48px;
        padding: 0px 8px;
        gap: 10px;
        align-self: stretch;
        border-bottom: 1px solid #CCD4DC;
        background: #6FABB6;
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: capitalize;
        text-align: left !important;
        &::before {
        width: 1.5px !important;
        height: 100% !important;
        }
    }

    .ant-table-tbody td.ant-table-cell {
        .BODY_1, .CAPTION_1, .CONTENT_1 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
        background: #E4F4F6;
        border-bottom: 1px solid #E4F4F6;
    }
  }

  .please-note {
    overflow: hidden;
    color: #008BFF;
    text-overflow: ellipsis;
    font-family: Poppins;
    line-height: normal;
    text-transform: none;
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 4px;
    background: #C6E9EF;
    &:hover {
      background: #E4F4F6;
      cursor: pointer;
    }
  }
}
`;
