import { Form, Spin, Tabs } from 'antd';
import Modal from 'components/Modal';
import turnActions, { turnManualAPIActions } from 'features/turn/services/actions';
import turnApis from 'features/turn/services/apis';
import { turnBoxColorDataDefault } from 'features/turn/services/constants';
import { turnManualSelectors } from 'features/turn/services/selectors';
import { ITurnServiceItem } from 'features/turn/services/types/service';
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ModalServiceTurnAddNew, { useModalServiceTurnAddNewRef } from '../ModalServiceTurnAddNew';
import ModalSortServiceTurn from '../ModalSortServiceTurn';
import TurnServiceList from './TurnServiceList';
import TurnSettingColors from './TurnSettingColors';
import { ITurnManualSettingColorItem } from 'features/turn/services/types/turn';
import storage from 'utils/sessionStorage';
import TurnManagementTeam from './TurnManagementTeam';
import { IStaffAvailableItem } from 'services/shop/types/staff';
type Props = {};
type Ref = {
  open: () => void;
  close: () => void;
};


export const turnSettingRef = React.createRef<Ref>();
const ModalTurnSetting = forwardRef<Ref, Props>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState<string>('FULL');
  const addNewRef = useModalServiceTurnAddNewRef();
  const [fullForm] = Form.useForm();
  const [halfForm] = Form.useForm();
  const [colorForm] = Form.useForm();
  const [managementTeamForm] = Form.useForm();
  const [open, setOpen] = useState(false);
  const turnsFull = turnManualSelectors.fullTurnDataServices();
  const turnsHalf = turnManualSelectors.halfTurnDataServices();
  const settingColors = turnManualSelectors.settingColors();
  const inActiveTurnStaffs = turnManualSelectors.inActiveTurnStaffs();
  const [loading, setLoading] = useState(false);
  const handleOpen: Ref['open'] = () => {
    setOpen(true);
    fullForm.setFieldValue('turns', turnsFull);
    halfForm.setFieldValue('turns', turnsHalf);
    colorForm.setFieldValue('colors', settingColors);
    managementTeamForm.setFieldValue('staffs', inActiveTurnStaffs);
  };

  useEffect(() => {
    fullForm.setFieldValue('turns', turnsFull);
    halfForm.setFieldValue('turns', turnsHalf);
  }, [turnsFull, turnsHalf]);

  useEffect(() => {
    colorForm.setFieldValue('colors', settingColors);
  }, [settingColors]);

  const handleClose: Ref['close'] = () => {
    setOpen(false);
    halfForm.resetFields();
    fullForm.resetFields();
  };

  const handleUpdateTurns = async (values: any) => {
    const data = values as { turns: ITurnServiceItem[] };
    const list = data.turns;
    setLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await turnApis.updateManualTurnServiceItem(list);
      if (res.data.data) {
        dispatch(turnManualAPIActions.getTurnConfigServices.fetch());
        handleClose();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateColors = async (values: any) => {
    const data = values as { colors: ITurnManualSettingColorItem[] };
    const body = data.colors.map(o => ({
      ...o,
      shopId: storage.shop_id.get(),
    }));
    setLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await turnApis.updateTurnManualColorSetting(body);
      if (res.data.data) {
        dispatch(turnManualAPIActions.getTurnSettingColors.fetch());
        handleClose();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleManagementTeam = async (values: any) => {
    const data = values as { staffs: IStaffAvailableItem[] };
    const body = data.staffs.map(o => o.staffId);
    setLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await turnApis.addManagementTurn(body);
      if (res.data.data) {
        dispatch(turnActions.getTurnStaffsAvailable.fetch({}));
        handleClose();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onAddNew = () => addNewRef.current?.open(activeTab == 'FULL' ? 1 : .5);

  const onResetDefaultColors = () => {
    const colors = turnBoxColorDataDefault.map(o => {
      const item = settingColors.find(s => s.turnStatus === o.turnStatus);
      return ({
        ...item || {},
        ...o,
      });
    });
    handleUpdateColors({ colors });
  };

  const handleAddNew = (item: { itemName: string, turn: number }) => {
    if (activeTab === 'SETTING_COLOR') return;
    const form = activeTab == 'FULL' ? fullForm : halfForm;
    const list: ITurnServiceItem[] = form.getFieldValue('turns') || [];
    const data = [...list];
    data.unshift({
      color: null,
      itemId: '',
      itemName: item.itemName,
      turn: item.turn,
    });
    form.setFieldValue('turns', data);
  };


  const items = useMemo(() => (
    [
      {
        key: 'FULL',
        label: 'FULL TURN',
        children: <FormContainer form={fullForm} onFinish={handleUpdateTurns} className='scroll-div'>
          <Form.Item noStyle name={'turns'} />
          <TurnServiceList onClose={handleClose} />
        </FormContainer>,
      },
      {
        key: 'HALF',
        label: 'HALF TURN',
        children: <FormContainer form={halfForm} onFinish={handleUpdateTurns} className='scroll-div'>
          <Form.Item noStyle name={'turns'} />
          <TurnServiceList onClose={handleClose} />
        </FormContainer>,
      },
      {
        key: 'SETTING_COLOR',
        label: 'SETTING COLORS',
        children: <FormContainer form={colorForm} onFinish={handleUpdateColors} className='scroll-div' initialValues={{ colors: turnBoxColorDataDefault }}>
          <Form.Item noStyle name={'colors'} />
          <TurnSettingColors onClose={handleClose} />
        </FormContainer>,
      },
      {
        key: 'MANAGEMENT_TEAM',
        label: 'MANAGEMENT TEAM',
        children: <FormContainer form={managementTeamForm} onFinish={handleManagementTeam} className='scroll-div'>
          <Form.Item noStyle name={'staffs'} />
          <TurnManagementTeam onClose={handleClose} />
        </FormContainer>,
      },
    ]
  ), [halfForm, fullForm]);

  const TabActions = () => {
    if (activeTab === 'MANAGEMENT_TEAM') return null;
    if (activeTab === 'SETTING_COLOR')
      return (
        <Actions>
          <button type='button' className="btn btn-add" onClick={onResetDefaultColors}>
            <div className="icon">{IconReset}</div>
            <span>Reset Default</span>
          </button>
        </Actions>
      );

    return (
      <Actions>
        <ModalSortServiceTurn turnsFull={turnsFull} turnsHalf={turnsHalf} />
        <button type='button' className="btn btn-add" onClick={onAddNew}>
          <div className="icon">{IconAdd}</div>
          <span>ADD ITEM</span>
        </button>
      </Actions>
    );

  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      width={1380}
      visible={open} onClose={handleClose}
      modalTitle='SETTING'
      headerBgColor='#FFEAF6'
      noneBodyStyle noneFooterStyle
      footer={null}
    >
      <Spin spinning={loading}>
        <Container>
          <TabActions />
          <Content>
            <Tabs defaultActiveKey="FULL" items={items} onChange={setActiveTab} />
          </Content>
        </Container>
      </Spin>
      <ModalServiceTurnAddNew onAddNew={handleAddNew} ref={addNewRef} />
    </Modal >
  );
});
ModalTurnSetting.displayName = 'ModalTurnSetting';
export default ModalTurnSetting;
const FormContainer = styled(Form)`
display: flex;
flex-direction: column;
`;
const Container = styled.div`
display: flex;
flex-direction: column;
border-radius: 5px;
align-items: flex-start;
gap: 0px;
align-self: stretch;
background: #FFF;
height: 70vh;
position: relative;
`;

const Actions = styled.div`
display: flex;
align-items: center;
gap: 24px;
position: absolute;
right: 24px;
top: 10px;
z-index: 2;
padding-left: 48px;
padding-bottom: 2px;
background: #fff;
button.btn {
display: flex;
height: 48px;
padding: 12px 16px;
justify-content: center;
align-items: center;
gap: 8px;
border-radius: 5px;
border: 1px solid #86909C;
background: #E5E6EB;
span {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 166.667% */
  text-transform: uppercase;

}
  &.btn-add {
    border-radius: 5px;
    background: transparent;
    border: 1px solid #2D6772;
    span {
      color: #2D6772;
    }
  }
}
`;
const Content = styled.div`
flex: 1;
display: flex;
padding: 0px 24px;
flex-direction: column;
align-items: center;
align-self: stretch;
.ant-tabs {
  flex: 1;
  align-self: stretch;
  .ant-tabs-nav {
    margin: 0;
  }
  .ant-tabs-content-holder {
    flex: 1;
    display: flex;
    flex-direction: column;
    .ant-tabs-content {
      flex: 1;
      .ant-tabs-tabpane {
        height: 100%;
        position: relative;
        margin: 0 -24px;
        .scroll-div {
          position: absolute;
          inset: 0;
          overflow: auto;
          top: 8px;
          scrollbar-width: none; /* Firefox 64 */
          &::-webkit-scrollbar {
            width: 0px;
            height: 8px;
            background-color: #F5F5F5;
          }
        
          &::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background-color: rgba(0,0,0,0.5);
            &:hover {
              background-color: rgba(0,0,0,0.6);
            }
          }



        }
      }
    }
  }
  .ant-tabs-nav:before {
    border-bottom: 1px solid #86909C;
  }

  .ant-tabs-ink-bar {
    background: #C52884;
  }
  .ant-tabs-tab {
    .ant-tabs-tab-btn {
      color: #505050;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    &.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #C52884;
    }
  }
}
  
`;

const IconAdd = <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fillRule="evenodd" clipRule="evenodd" d="M12.6499 5C13.0641 5 13.3999 5.33579 13.3999 5.75V19.25C13.3999 19.6642 13.0641 20 12.6499 20C12.2357 20 11.8999 19.6642 11.8999 19.25V5.75C11.8999 5.33579 12.2357 5 12.6499 5Z" fill="#2D6772" />
  <path fillRule="evenodd" clipRule="evenodd" d="M11.3999 5.75C11.3999 5.05964 11.9595 4.5 12.6499 4.5C13.3403 4.5 13.8999 5.05964 13.8999 5.75V19.25C13.8999 19.9404 13.3403 20.5 12.6499 20.5C11.9595 20.5 11.3999 19.9404 11.3999 19.25V5.75ZM12.6499 5.5C12.5118 5.5 12.3999 5.61193 12.3999 5.75V19.25C12.3999 19.3881 12.5118 19.5 12.6499 19.5C12.788 19.5 12.8999 19.3881 12.8999 19.25V5.75C12.8999 5.61193 12.788 5.5 12.6499 5.5Z" fill="#2D6772" />
  <path fillRule="evenodd" clipRule="evenodd" d="M5.1499 12.5C5.1499 12.0858 5.48569 11.75 5.8999 11.75H19.3999C19.8141 11.75 20.1499 12.0858 20.1499 12.5C20.1499 12.9142 19.8141 13.25 19.3999 13.25H5.8999C5.48569 13.25 5.1499 12.9142 5.1499 12.5Z" fill="#2D6772" />
  <path fillRule="evenodd" clipRule="evenodd" d="M4.6499 12.5C4.6499 11.8096 5.20955 11.25 5.8999 11.25H19.3999C20.0903 11.25 20.6499 11.8096 20.6499 12.5C20.6499 13.1904 20.0903 13.75 19.3999 13.75H5.8999C5.20955 13.75 4.6499 13.1904 4.6499 12.5ZM5.8999 12.25C5.76183 12.25 5.6499 12.3619 5.6499 12.5C5.6499 12.6381 5.76183 12.75 5.8999 12.75H19.3999C19.538 12.75 19.6499 12.6381 19.6499 12.5C19.6499 12.3619 19.538 12.25 19.3999 12.25H5.8999Z" fill="#2D6772" />
</svg>
  ;
const IconReset = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M6.69816 6.69538C7.81664 5.57691 9.19776 4.8867 10.6424 4.62205L10.282 2.65479C8.45093 2.99023 6.69852 3.8666 5.28395 5.28117C1.57397 8.99115 1.57397 15.0062 5.28395 18.7162L3.57684 20.4233H8.40527V15.5949L6.69816 17.302C3.76923 14.3731 3.76923 9.62431 6.69816 6.69538ZM20.4243 3.57583H15.5959V8.40426L17.303 6.69715C20.2319 9.62609 20.2319 14.3748 17.303 17.3038C16.1845 18.4222 14.8034 19.1124 13.3587 19.3771L13.7191 21.3444C15.5502 21.0089 17.3026 20.1325 18.7172 18.718C22.4272 15.008 22.4272 8.99292 18.7172 5.28294L20.4243 3.57583Z" fill="#2D6772" />
</svg>
  ;