import { Row } from 'antd';
import Icon from 'assets/Icons';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Text from 'components/Text';
import appointmentActions from 'features/appointment/services/actions';
import { ICalendarHeader, ICalendarItemData } from 'features/appointment/services/types/calendar';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { first, get, maxBy, minBy } from 'lodash';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
type IBreakStaffProps = {
  data: ICalendarItemData[];
  isMonthView?: boolean;
  dateOfMonth?: string;
  headerData?: ICalendarHeader;
};
const BreakStaff: React.FC<IBreakStaffProps> = ({ data = [], isMonthView, dateOfMonth, headerData }) => {
  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const startTime = useMemo(() => {
    const item = minBy(data, o => momentTimezone(o.startTime).valueOf());
    if (!item?.startTime) return '';
    return momentTimezone(item?.startTime).format(FORMAT_TIME);
  }, [data]);

  const endTime = useMemo(() => {
    const item = maxBy(data, o => momentTimezone(o.endTime).valueOf());
    if (!item?.endTime) return '';
    return momentTimezone(item?.endTime).format(FORMAT_TIME);
  }, [data]);

  const addMoreTime: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation();
    dispatch(appointmentActions.addMoreBreakTime(get(data, [0, 'id'], '')));
  };
  const extraData = useMemo(() => first(data)?.data, [data]);

  const remove = () => {
    const _data = first(data);
    if (!_data?.id) return;
    modalConfirm.current?.open('Do you like to remove this items?', () => dispatch(appointmentActions.removeBreakBlockHour(_data?.id)));
  };

  return (
    <>
      <BreakStaffStyled>
        <div className='box-content'>
          <div>
            {dateOfMonth && <Text variant='BODY_1' color='text_2' mb={1} p={0.25} textAlign='left'>{dateOfMonth}</Text>}
            <Text variant='CAPTION_3' style={{ fontWeight: '600' }} color='text_3'>Staff Break</Text>
            <Text variant='CAPTION_3' color='text_3'>{startTime} - {endTime}</Text>
          </div>
          {extraData?.reason && <Row align={'middle'}>
            <Text variant='CAPTION_2' color='text_3' mr={0.25}>{'Reason: '}</Text>
            <Text variant='CAPTION_3' color='text_3' style={{ textAlign: 'left' }}>{extraData?.reason}</Text>
          </Row>}
        </div>
       {!headerData?.modal &&  <>
        <ButtonRemove onClick={remove}>
          <div style={{ width: '20px', height: '20px' }}><Icon type='closeModal' /></div>
        </ButtonRemove>
        {!isMonthView && <Row className='bottom' align={'middle'} justify={'center'} ><button onClick={addMoreTime}><Icon type='addAppointment' /></button></Row>}
        </>}
      </BreakStaffStyled>
      <ModalConfirm ref={modalConfirm} />
    </>
  );
};

export default BreakStaff;
type BreakStaffStyledProps = {}
const BreakStaffStyled = styled.div<BreakStaffStyledProps>`
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;

  .box-content {
    border-radius: 8px;
    border: 2px solid var(--info-infor-2, #FFDC7C);
    background: var(--yellow-headline, #FEEDBF);

    display: flex;
    flex-direction: column;
    padding: 4px 8px;
    flex: 1;
    .flex-grow {
      flex:1;
    }
  }
  .bottom {
    position: absolute;
    bottom:0;
    width:100%;
    transform: translateY(12px);
    button {
      border-radius: 100%;
    }
  }

`;

const ButtonRemove = styled.button`
  position: absolute;
  right: 4px;
  top: 4px;
`;
