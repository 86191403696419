import { Avatar, Row, Select, Table, TableColumnsType } from 'antd';
import { AxiosResponse } from 'axios';
import Box from 'components/Box';
import Button from 'components/Button';
import Empty from 'components/Empty';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import reportActions from 'features/report/services/actions';
import reportApis from 'features/report/services/apis';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailOneDay, IPrintSalaryStaffDetailRangeDate, ISalaryStaffInfo, ITableSalaryStaffDetail } from 'features/report/services/types/reportStaff';
import useDebounce from 'hooks/useDebounce';
import { first } from 'lodash';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import AppointmentDatePicker from '../../../components/DatePicker';
import SwitchCalendarType from '../../../components/SwitchCalendarType';
import { ReportType } from '../../../constants';
import SearchBox from '../components/Search';
import { StaffSalaryDateRangeReportPrintBodyUI } from './DateRangePrint';
import { StaffSalaryReportPrintBodyUI } from './Print';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
interface IEmployeeSalaryStaffDetail { }

interface DataType extends ITableSalaryStaffDetail {
    key?: string,
}
const ALL_PAYMENT = 'ALL_PAYMENT';
const paymentTypes = [
    { value: ALL_PAYMENT, label: 'All Payments' },
    { value: PAYMENT_TYPE.CASH, label: 'Cash' },
    { value: PAYMENT_TYPE.CREDIT_CARD, label: 'Credit Card' },
    { value: PAYMENT_TYPE.CHECK, label: 'Check' },
    { value: PAYMENT_TYPE.GIFT_CARD, label: 'Gift Card' },
    { value: PAYMENT_TYPE.LOYALTY_POINT, label: 'Loyalty Point' },
];

const columns: TableColumnsType<DataType> = [
    {
        title: <div>Time</div>,
        dataIndex: 'time',
        key: 'time',
        render: (_, record) => (
            <Text variant='CONTENT_1'>{record.createDated}</Text>
        ),
    },
    {
        title: 'Tickets',
        dataIndex: 'tickets',
        key: 'tickets',
        width: 100,
        render: (_, record) => (
            <Text variant="CONTENT_2" color="text_3">
                #{record.ticketNumber}
            </Text>
        ),
    },
    {
        title: 'Services',
        dataIndex: 'services',
        key: 'services',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {record.itemName}
            </Text>
        ),
    },
    {
        title: 'Sales',
        dataIndex: 'sell_price',
        key: 'sell_price',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.sellPrice)}
            </Text>
        ),
    },
    {
        title: 'Supply',
        dataIndex: 'supplyFee',
        key: 'supplyFee',
        render: (value) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(value)}
            </Text>
        ),
    },
    {
        title: 'Comm',
        dataIndex: 'commission',
        key: 'commission',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.commission)}
            </Text>
        ),
    },
    {
        title: 'Disc',
        dataIndex: 'discount',
        key: 'discount',
        render: (_, record) => (
            <Text variant="CONTENT_1" color="text_3">
                {formatCurrency(record.discount)}
            </Text>
        ),
    },
    {
        title: 'Tips',
        dataIndex: 'tips',
        key: 'tips',
        render: (_, record) => (
            <div style={{ display: 'flex' }}>
                <Text variant="CONTENT_1" color="text_3">
                    {formatCurrency(record.tip)}
                </Text>
            </div>
        ),
    },
];

const EmployeeSalaryStaffDetailV2: React.FC<IEmployeeSalaryStaffDetail> = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();
    const salaryStaffDetail = reportSelectors.getSalaryStaffDetail();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    const salaryType = reportSelectors.getFilterTypeSalaryStaff();
    const viewType = reportSelectors.getViewType()?.[ReportType.STAFF_SALARY_DETAIL];
    const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody);
    const allSetting = shopSelectors.data.allSetting();
    const v2 = allSetting?.versionUI === 'V2';
    const cashLabel = allSetting?.labelSalaryCashOrBonus || 'Cash';
    const tableSummaryStaff = reportSelectors.getSummarySalaryStaff();
    const setLoadingPage = useSetLoadingPage();
    const rangePrintRef = useRef<HTMLDivElement>(null);
    const [paymentType, setPaymentType] = useState<string>(ALL_PAYMENT);
    const onRangePrint = useReactToPrint({
        content: () => rangePrintRef.current,
        copyStyles: true,
    });

    const printRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => printRef.current,
        copyStyles: true,
    });

    const [staffSelected, setStaffSelected] = useState<ISalaryStaffInfo | null>(() => {
        return tableSummaryStaff.find(o => o.staffId === params.staff_id) || null;
    });
    const staffInfo = useMemo(() => staffData.find(o => o.id === staffSelected?.staffId), [staffSelected, staffData]);

    const dataDashboard = staffSelected ? salaryStaffDetail : null;
    const dataTable = staffSelected ? salaryStaffDetail.details : [];

    useEffect(() => {
        dispatch(reportActions.setParams({
            [ReportType.STAFF_SALARY_DETAIL]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
        params.staff_id && dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: params.staff_id,
            startDate: dataAppointment?.startTime || moment().format(DATE_FORMAT),
            endDate: dataAppointment?.endTime || moment().format(DATE_FORMAT),
            paymentType: paymentType === ALL_PAYMENT ? undefined : paymentType,
        }));
    }, []);

    useEffect(() => {
        if (!dataAppointment) return;
        const getStaff = () => {
            const exist = tableSummaryStaff.find(o => o.staffId === staffSelected?.staffId);
            return exist || first(tableSummaryStaff) || null;
        };
        const staff = getStaff();

        if (!staff) {
            // setEMpty
            setStaffSelected(null);
            return;
        }

        setStaffSelected(staff);

        dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: staff.staffId,
            startDate: dataAppointment?.startTime,
            endDate: dataAppointment?.endTime
        }));
    }, [tableSummaryStaff]);

    const handlePreview = () => {
        if (!staffSelected) return;
        if (viewType && viewType != CALENDAR_VIEW_TYPE.DAY_VIEW) {
            return navigate(`/store/${storage.shop_id.get()}/report/print/staff-salary-date-range/${staffSelected.staffId}`);
        }
        navigate(`/store/${storage.shop_id.get()}/report/print/staff-salary/${staffSelected.staffId}`);
    };

    const handlePrintOneDay = async (params: { startDate: string; endDate: string; staffId: string; }) => {
        if (!dataAppointment) return;
        if (!staffSelected) return;
        setLoadingPage(true);
        try {
            const resData: AxiosResponse<{ data: IPrintSalaryStaffDetailOneDay }> = await reportApis.getPrintSalaryStaffDetailOneDay(params);
            if (resData?.data?.data) {
                dispatch(reportActions.getPrintSalaryStaffDetailOneDay.success(resData.data.data));
                setTimeout(() => onPrint(), 100);
            }
        } catch (error) {
        } finally {
            setLoadingPage(false);
        }
    };

    const handlePrintRangeDate = async (params: { startDate: string; endDate: string; staffId: string; }) => {
        if (!dataAppointment) return;
        if (!staffSelected) return;
        setLoadingPage(true);
        try {
            const resData: AxiosResponse<{ data: IPrintSalaryStaffDetailRangeDate }> = await reportApis.getPrintSalaryStaffDetailRangeDate(params);
            if (resData?.data?.data) {
                dispatch(reportActions.getPrintSalaryStaffDetailRangeDate.success(resData.data.data));
                setTimeout(() => onRangePrint(), 100);
            }
        } catch (error) { }
        finally {
            setLoadingPage(false);
        }
    };

    const handlePrint = async () => {
        if (!dataAppointment) return;
        if (!staffSelected) return;
        const params = {
            startDate: dataAppointment.startTime, endDate: dataAppointment.endTime, staffId: staffSelected?.staffId,
        };
        if (viewType && viewType != CALENDAR_VIEW_TYPE.DAY_VIEW) {
            return handlePrintRangeDate(params);
        }
        return handlePrintOneDay(params);
    };

    const handleBack = () => navigate(-1);

    const onClickStaff = (_staff: ISalaryStaffInfo) => {
        if (!dataAppointment) return;
        setStaffSelected(_staff);
        setPaymentType(ALL_PAYMENT);
        dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: _staff.staffId,
            startDate: dataAppointment?.startTime,
            endDate: dataAppointment?.endTime
        }));
    };

    const handleChangePaymentType = (id: PAYMENT_TYPE) => {
        setPaymentType(id);
        if (!staffSelected?.staffId || !dataAppointment) return;
        dispatch(reportActions.getSalaryStaffDetail.fetch({
            staffId: staffSelected?.staffId,
            startDate: dataAppointment?.startTime,
            endDate: dataAppointment?.endTime,
            // @ts-ignore
            paymentType: id === ALL_PAYMENT ? undefined : id
        }));
    };

    const onChangeFilter = (data: { startTime: string, endTime: string }) => {
        const params = {
            startDate: data.startTime,
            endDate: data.endTime,
            salaryType: salaryType,
        };
        dispatch(reportActions.getSummarySalaryStaff.fetch(params));
    };

    return (
        <>
            <StyledEmployeeSalaryStaffDetail className={v2 ? 'v2' : ''}>
                <div style={{ background: '#FFF', display: 'flex' }}>
                    <Sidebar />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, maxWidth: 'calc(100% - 104px)' }}>
                    <HeaderBox className={v2 ? 'v2' : ''}>
                        <Text className='page-title'>PAYROLL REPORTS</Text>
                        <PaymentTypeSelect className={v2 ? 'v2' : ''}>
                            <Select
                                className='paymentTypeSelect'
                                placeholder='All payment type'
                                onChange={handleChangePaymentType}
                                options={paymentTypes}
                                value={paymentType as PAYMENT_TYPE}
                            />
                        </PaymentTypeSelect>
                        {v2 ? <>
                            <SwitchCalendarType v2 onChangeValue={onChangeFilter} twoWeeks actions={reportActions} selectors={reportSelectors} type={ReportType.STAFF_SALARY_DETAIL} />
                            <AppointmentDatePicker v2 onChangeValue={onChangeFilter} size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.STAFF_SALARY_DETAIL} />
                            <Button cardCashier ntype='SECONDARY' className='btn-print' icon="back" onClick={handleBack}>
                                Back
                            </Button>
                        </> : <>
                            <AppointmentDatePicker onChangeValue={onChangeFilter} size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.STAFF_SALARY_DETAIL} />
                            <SwitchCalendarType onChangeValue={onChangeFilter} twoWeeks actions={reportActions} selectors={reportSelectors} type={ReportType.STAFF_SALARY_DETAIL} />
                            <Button icon="back" onClick={handleBack}>
                                Back
                            </Button>
                        </>}
                    </HeaderBox>
                    <Content className={v2 ? 'v2' : ''} style={{ flex: 1, paddingBottom: '8px' }}>
                        <StaffList v2={v2} onClickStaff={onClickStaff} data={tableSummaryStaff} staffSelectedId={staffSelected?.staffId || ''} />
                        <ContainerContent style={{ flex: 1 }} className={v2 ? 'v2' : ''}>
                            <HeaderContent>
                                <div className="staff-content">
                                    <Avatar className='staff-avatar' src={staffInfo?.urlImage || 'fail'} >{staffInfo?.firstName?.[0]}</Avatar>
                                    <div>
                                        <Text className="staff-name">{staffInfo?.firstName} {staffInfo?.lastName}</Text>
                                        <Text className='staff-phone'>{formatPhone(staffInfo?.phone)}</Text>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }} />
                                <Button cardCashier={v2} disabled={dataTable?.length == 0} icon="print" onClick={handlePreview}>
                                    Preview
                                </Button>
                                <Button cardCashier={v2} disabled={dataTable?.length == 0} icon="print" onClick={handlePrint}>
                                    Print
                                </Button>
                            </HeaderContent>
                            <BottomContent>
                                <ContainerSummary className={v2 ? 'v2' : ''}>
                                    <Discount>
                                        <Box style={{ flex: 1 }} className="space-between  item-report">
                                            <Text printMode variant='CAPTION_2' >TOTAL SALES</Text>
                                            <Text printMode variant='CAPTION_2' >{formatCurrency(dataDashboard?.totalSales)}</Text>
                                        </Box>
                                    </Discount>
                                    <Discount>
                                        <Box style={{ flex: 1 }} className="space-between  item-report">
                                            <Text printMode variant='CAPTION_2' >SUPPLY FEES</Text>
                                            <Text printMode variant='CAPTION_2' >{formatCurrency(dataDashboard?.supplyFee)}</Text>
                                        </Box>
                                    </Discount>
                                    <Discount>
                                        <Box style={{ flex: 1 }} className="space-between  item-report" bgColor='primary_button'>
                                            <Text printMode variant='CAPTION_2' >TOTAL</Text>
                                            <Text variant='CAPTION_2' color='text_4' style={{ fontSize: 20 }} >{formatCurrency((dataDashboard?.totalSales || 0) - (dataDashboard?.supplyFee || 0))}</Text>
                                        </Box>
                                    </Discount>
                                    <Discount>
                                        <Box style={{ flex: 1 }} className="space-between  item-report" bgColor='info_bg_3'>
                                            <Text printMode variant='CAPTION_2' >COMMISSION ({dataDashboard?.commissionPercent || 0}%)</Text>
                                            <Text printMode variant='CAPTION_2' >{formatCurrency(dataDashboard?.totalCommission)}</Text>
                                        </Box>
                                    </Discount>
                                    {!!dataDashboard?.staffDiscount && <Discount>
                                        <Box style={{ flex: 1 }} className="space-between  item-report">
                                            <Text printMode variant='CAPTION_2' >DISCOUNT</Text>
                                            <Text printMode variant='CAPTION_2' >{formatCurrency(dataDashboard?.staffDiscount)}</Text>
                                        </Box>
                                    </Discount>}
                                    <>
                                        {!!dataDashboard?.tipCollected && <Discount>
                                            <Box style={{ flex: 1 }} className="space-between item-report">
                                                <Text printMode variant='CAPTION_2' >COLLECTED TIPS</Text>
                                                <Text printMode variant='CAPTION_2' >{formatCurrency(dataDashboard?.tipCollected)}</Text>
                                            </Box>
                                        </Discount>}
                                        {!!dataDashboard?.tipFee && <>
                                            <Discount>
                                                <Box style={{ flex: 1 }} className="space-between item-report">
                                                    <Text printMode variant='CAPTION_2' >TIP FEE {(dataDashboard?.tipFeeType === 'PERCENT' && `(${dataDashboard?.tipFeeValue || 0}%)`)}</Text>
                                                    <Text printMode variant='CAPTION_2' >
                                                        {formatCurrency(dataDashboard?.tipFee) + ' '}
                                                    </Text>
                                                </Box>
                                            </Discount>
                                            <Discount style={{ marginBottom: 8 }}>
                                                <Box style={{ flex: 1 }} className="space-between item-report" bgColor='info_bg_2'>
                                                    <Text printMode variant='CAPTION_2' >TOTAL TIP</Text>
                                                    <Text printMode variant='CAPTION_2' >{formatCurrency(dataDashboard?.totalTip)}</Text>
                                                </Box>
                                            </Discount>
                                        </>}
                                        <Box style={{ marginBottom: 8 }}>
                                            <Box className="space-between item-report" highlight>
                                                <Text printMode variant="CAPTION_3" className='title-highlight' >
                                                    PAYMENT TYPES
                                                </Text>
                                                <Text printMode variant="CAPTION_3" className='title-highlight' >
                                                </Text>
                                            </Box>

                                            <Discount>
                                                <BoxInfo className='item-report' style={{ padding: 0, flex: 1 }}>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        Cash
                                                    </Text>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        {formatCurrency(dataDashboard?.cashAmount)}
                                                    </Text>
                                                </BoxInfo>
                                            </Discount>
                                            <Discount>
                                                <BoxInfo className='item-report' style={{ padding: 0, flex: 1 }}>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        Credit Card
                                                    </Text>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        {formatCurrency(dataDashboard?.creditAmount)}
                                                    </Text>
                                                </BoxInfo>
                                            </Discount>
                                            <Discount>
                                                <BoxInfo className='item-report' style={{ padding: 0, flex: 1 }}>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        Gift Card
                                                    </Text>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        {formatCurrency(dataDashboard?.giftCardAmount)}
                                                    </Text>
                                                </BoxInfo>
                                            </Discount>
                                            {!!dataDashboard?.loyaltyAmount &&
                                                <Discount>
                                                    <BoxInfo className='item-report' style={{ padding: 0, flex: 1 }}>
                                                        <Text printMode variant='CAPTION_2' color="text_3">
                                                            Loyalty
                                                        </Text>
                                                        <Text printMode variant='CAPTION_2' color="text_3">
                                                            {formatCurrency(dataDashboard?.loyaltyAmount)}
                                                        </Text>
                                                    </BoxInfo>
                                                </Discount>}
                                            {!!dataDashboard?.checkAmount &&
                                                <Discount>
                                                    <BoxInfo className='item-report' style={{ padding: 0, flex: 1 }}>
                                                        <Text printMode variant='CAPTION_2' color="text_3">
                                                            Check
                                                        </Text>
                                                        <Text printMode variant='CAPTION_2' color="text_3">
                                                            {formatCurrency(dataDashboard?.checkAmount)}
                                                        </Text>
                                                    </BoxInfo>
                                                </Discount>}
                                            <Discount>
                                                <BoxInfo className='item-report' style={{ padding: 0, flex: 1 }}>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        Other
                                                    </Text>
                                                    <Text printMode variant='CAPTION_2' color="text_3">
                                                        {formatCurrency(dataDashboard?.otherAmount)}
                                                    </Text>
                                                </BoxInfo>
                                            </Discount>
                                        </Box>
                                    </>
                                    <Box className="space-between item-report" highlight>
                                        <Text printMode variant="CAPTION_2" className='title-highlight' >
                                            TOTAL PAID OUT
                                        </Text>
                                        <Text printMode variant="CAPTION_2" className='title-highlight' >
                                            {formatCurrency(dataDashboard?.totalSalary)}
                                        </Text>
                                    </Box>
                                    <BoxInfo bgColor='info_bg_3' className='item-report'>
                                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                                            {cashLabel} ({dataDashboard?.cashPercent}%)
                                        </Text>
                                        <Text printMode variant='CAPTION_3' color="text_3" >
                                            {formatCurrency(dataDashboard?.cashValue)}
                                        </Text>
                                    </BoxInfo>
                                    <BoxInfo bgColor='info_bg_3' className='item-report'>
                                        <Text printMode variant='CAPTION_3' color="text_3" >
                                            Check ({dataDashboard?.checkPercent}%)
                                        </Text>
                                        <Text printMode variant='CAPTION_3' color="text_3" >
                                            {formatCurrency(dataDashboard?.checkValue)}
                                        </Text>
                                    </BoxInfo>
                                    {!!dataDashboard?.tipToCashValue &&
                                        <BoxInfo bgColor='info_bg_2' className='item-report'>
                                            <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                                                Tip to {cashLabel} {dataDashboard?.tipCashPercent ? ` (${dataDashboard?.tipCashPercent}%)` : ''}
                                            </Text>
                                            <Text printMode variant='CAPTION_3' color="text_3" >
                                                {formatCurrency(dataDashboard?.tipToCashValue)}
                                            </Text>
                                        </BoxInfo>}
                                    <BoxInfo bgColor='info_bg_2' className='item-report'>
                                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                                            Tip to Check {dataDashboard?.tipCheckPercent ? ` (${dataDashboard?.tipCheckPercent}%)` : ''}
                                        </Text>
                                        <Text printMode variant='CAPTION_3' color="text_3" >
                                            {formatCurrency(dataDashboard?.tipToCheckValue)}
                                        </Text>
                                    </BoxInfo>
                                    <Box style={{ marginTop: 8 }} bb='print_line'></Box>
                                    <Box >
                                        <BoxInfo className='item-report' style={{ padding: 0 }}>
                                            <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                                                Total {cashLabel}
                                            </Text>
                                            <Text printMode variant='CAPTION_2' color="text_3" >
                                                {formatCurrency(dataDashboard?.totalCash)}
                                            </Text>
                                        </BoxInfo>
                                        <BoxInfo className='item-report' style={{ padding: 0 }}>
                                            <Text printMode variant='CAPTION_2' color="text_3">
                                                Total Check
                                            </Text>
                                            <Text printMode variant='CAPTION_2' color="text_3">
                                                {formatCurrency(dataDashboard?.totalCheck)}
                                            </Text>
                                        </BoxInfo>
                                    </Box>
                                </ContainerSummary>
                                <TableBox className={v2 ? 'v2' : ''} style={{ flex: 1, position: 'relative', zIndex: 1 }}>
                                    <div className="overflow-scroll">
                                        <Table
                                            loading={false}
                                            dataSource={dataTable}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </div>
                                </TableBox>
                            </BottomContent>
                        </ContainerContent>
                    </Content>
                </div>
            </StyledEmployeeSalaryStaffDetail>
            {
                <div style={{ display: 'none' }}>
                    <div ref={rangePrintRef}>
                        <StaffSalaryDateRangeReportPrintBodyUI />
                    </div>
                </div>
            }
            {
                <div style={{ display: 'none' }}>
                    <div ref={printRef}>
                        <StaffSalaryReportPrintBodyUI />
                    </div>
                </div>
            }
        </>
    );
};
export default EmployeeSalaryStaffDetailV2;

const StaffList = ({ v2, data: list = [], staffSelectedId, onClickStaff }: { v2?: boolean, data: ISalaryStaffInfo[], staffSelectedId: string, onClickStaff: (staff: ISalaryStaffInfo) => void }) => {
    const [filterText, setFilterText] = useState('');

    const data = useMemo(() => {
        const str = filterText.toLocaleLowerCase();
        return list.filter(o => o.staffName?.toLocaleLowerCase()?.includes(str));
    }, [filterText, list]);

    return (
        <ContainerStaffList className={v2 ? 'v2' : ''}>
            <BoxSearch value={filterText} onChange={setFilterText} />
            <div className='top-label'>
                <span>STAFF</span>
                <span>TOTAL PAID OUT</span>
            </div>
            <div className='container-staff-list'>
                <div className='staff-list'>
                    {!data.length ? <Empty /> : null}
                    {data.map(o => (
                        <button onClick={() => onClickStaff(o)} className={`staff-item ${staffSelectedId === o.staffId ? 'active' : ''}`} key={o.staffId}>
                            <Avatar className='staff-avatar' src={o.avatar || 'fail'} >{o?.staffName?.[0]}</Avatar>
                            <div style={{ flex: 1 }}>
                                <Text textAlign='left' className="staff-name">{o?.staffName || '--'}</Text>
                            </div>
                            <div >
                                <Text className='paid-out'>{formatCurrency(o.salaryAmount)}</Text>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </ContainerStaffList>
    );
};

const BoxSearch = ({ value, onChange }: { value: string, onChange: (value: string) => void }) => {
    const { text, setText } = useDebounce({ value, onDebounce: onChange });

    return (
        <div className='search-box'>
            <SearchBox
                size='large'
                value={text}

                onChange={(event) => setText(event.target.value)} />
        </div>
    );
};

const ContainerStaffList = styled.div`
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    background: #F4F4F5;
    border: 1px solid #C9CDD4;
    .search-box {
        padding: 16px 12px;
    }
    .top-label {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        margin-bottom: 12px;
        span {
            color: #767676;
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    .container-staff-list {
        position: relative;
        flex: 1;
    }
    .staff-list {
        position: absolute;
        inset: 0;
        overflow: auto;
        display: flex;
        flex-direction: column;
        padding-bottom: 48px;
        .staff-item {
            display: flex;
            padding: 8px 12px;
            height: unset;
            border-radius: 0;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            border-bottom: 1px solid #C9CDD4;

            .ant-avatar.staff-avatar {
                width: 40px; height: 40px;
            }

            .staff-name {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                color: #1D2129;
                text-overflow: ellipsis;
                font-family: "Open Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            .staff-phone {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                align-self: stretch;
                overflow: hidden;
                color: #1D2129;
                text-overflow: ellipsis;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .paid-out {
                color: #1D2129;
                text-align: right;
                font-family: Roboto;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 30px; /* 166.667% */
                text-transform: capitalize;
            }

            &.active {
                background: #FF8890;
                .staff-name, .paid-out {
                    color: #fff;
                }
            }

        }
    }

    &.v2 {
        background: transparent;
        border: none;
        zoom: 1.2;

        .search-box {
            padding: 0;
            margin-bottom: 16px;
        }
    }
`;

const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #fff;

    &.v2 {
        .staff-content {
            .staff-name {
                overflow: hidden;
                color: #1D2129;
                text-overflow: ellipsis;
                font-family: Poppins;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                text-transform: uppercase;
            }
            .staff-phone {
                overflow: hidden;
                color: #505050;
                text-overflow: ellipsis;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }
        }   
    }
`;
const ContainerSummary = styled.div`
border-radius: 20px;
min-width: 20.5rem;
height: fit-content;
display: flex;
flex-direction: column;
padding: 1rem;
border-radius: 5px;
background: #E5E6EB;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
.item-report {
    padding: 6px 0px;
}
    .CAPTION_2 {
        font-size: 18px;
    }
    .CAPTION_3 {
        font-size: 18px;
    }

    .title-highlight {
        font-size: 24px;
    }

&.v2 {
    zoom: 1.1;
}
`;

const StyledEmployeeSalaryStaffDetail = styled.div`
    display: flex;
    background-color: ${colorTheme.fill_3};
    height: 100vh;
    flex:1;
    width: 100vw;
    .search-staff {
        flex:1;
        input {
            height: 38px;
        }
        .ant-input-suffix {
            height: 38px;
        }
    }

   &.v2 {
        background: #F5F5F5;
        min-height: unset;
    }
`;

const HeaderBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    margin: 20px;
    padding-left: 20px;
    flex-wrap: wrap;
    .page-title {
        flex:1;
        color: #1D2129;
        font-family: Roboto;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    &.v2 {
        .page-title {
            color: #1D2129;
            font-family: Poppins;
            font-size: 32px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .ant-select.paymentTypeSelect .ant-select-selector .ant-select-selection-item {
            color: #1D2129;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
`;

const Content = styled.div`
    display: flex;
    flex-direction: flex;
    padding: 0px 40px;
    padding-right: 24px;
    &.v2 {
        padding-left: 16px;
        gap: 16px;
    }
`;

const TableBox = styled.div`
.overflow-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
    .ant-table-tbody {
        .ant-table-row:nth-child(even) td {
            background: #EAF5E8;
            &:hover {
                background: #EAF5E8;
            }
        }
    }
}
  .ant-table-thead > tr > th {
    background: #adadad;
    color: white; /* White text */
    position: sticky;
    top: 0;
    z-index: 99;
  }

&.v2 {
  flex: 1;

  .ant-table-wrapper .ant-table {
    .ant-table-thead th.ant-table-cell {
      height: 48px;
      padding: 0px 8px;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #CCD4DC;
      background: #6FABB6;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: left !important;
      &::before {
        width: 1.5px !important;
        height: 100% !important;
      }
    }

    .ant-table-tbody td.ant-table-cell {
      .BODY_1 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
      background: #E4F4F6;
      border-bottom: 1px solid #E4F4F6;
    }
  }
}
`;

const Header = styled(Row)`
  border-top:1px solid black;
  border-bottom: 1px dashed black;
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const BoxInfo = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 16px;
  border-bottom: 1px solid #C9CDD4;
  .staff-content {
    display: flex;
    align-items: center;
    gap: 8px;
    .ant-avatar.staff-avatar {
        width: 72px; height: 72px;
    }
    .staff-name {
        color: #1D2129;
        font-family: Roboto;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px; /* 140% */
        margin-bottom: 8px;
    }
    .staff-phone {
        color: #767676;
        font-family: Roboto;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
  }

`;
const BottomContent = styled.div`
    display: flex;
    gap: 16px;
    padding: 0 16px;
    flex: 1;
    padding-bottom: 8px;
`;
const PaymentTypeSelect = styled.div`
  width: 13rem;
  .paymentTypeSelect {
    width: 100%;
  }
`;