import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import AppointmentDetailModalV2, { useAppointmentDetailModalV2Ref } from '.';
import { Form } from 'antd';
import { IAppointmentItemData, IAppointmentServiceItem, IDraftAppointmentData } from 'features/appointment/services/types/appointment';
import { momentTimezone } from 'utils/time';
import moment from 'moment';
import shopSelectors from 'services/shop/selectors';
import uuid from 'utils/uuid';
import { PeriodValues } from 'features/appointment/components/InputPeriod';
import { formatCurrency } from 'utils/formatCurrency';
type Ref = {
  open: (data: IDraftAppointmentData) => void;
};
export const useModalAppointmentDetailRef = () => useRef<Ref>(null);
const ModalAppointmentDetailRef = forwardRef<Ref>(({ }, ref) => {
  const staffs = shopSelectors.data.staffs();
  const [draffData, setDraftData] = useState<IDraftAppointmentData | null>(null);
  const [visible, setVisible] = useState(false);
  const v2Controls = useAppointmentDetailModalV2Ref();
  const [form] = Form.useForm();
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();
  const calcMoneyPoint = (point: number) => {
    if (!loyaltySetting) return 0;
    return (point || 0) / loyaltySetting?.oneDollarToPoint;
  };
  const onClose = () => setVisible(false);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setDraftData(data);
      setVisible(true);
      const extra = data.data?.data as IAppointmentItemData;
      if (!extra) return;
      const date = momentTimezone(extra.startTime);
      const now = moment();
      const anyone = staffs.find(o => o.anyBody);
      const staffData = staffs.find(o => o.id === extra.staffId);
      const services: IAppointmentServiceItem[] = extra.services.map(o => ({
        ...o,
        uuid_local: uuid(),
        staffId: extra.staffId,
      }));

      form.setFieldsValue({
        staff: staffData || anyone || null,
        customer: {
          name: extra.customerName,
          phone: extra.customerPhone,
          point: `${extra.customerPoint || 0} points = ${formatCurrency(calcMoneyPoint(extra?.customerPoint || 0))}`,
        },
        repeat: PeriodValues.NO_REPEAT,
        services,
        sourceType: extra.sourceType,
        note: extra.note,
        group: 1,
        date: date.clone(),
        aptDate: date,
        startTime: date.isSameOrAfter(now, 'day') ? date?.format('hh:mm A') : '',
        status: extra.status,
      });

      setTimeout(() => v2Controls.current?.init(), 10);
    },
  }));

  return (
    <Modal 
      visible={visible} onClose={onClose} hiddenHeader hiddenFooter
      noneBodyStyle
      width={'1600px'}
      footer={<></>}
      >
      <AppointmentDetailModalV2 ref={v2Controls} form={form} data={draffData} onClose={onClose} visible={!!draffData} />
    </Modal>
  );
});
ModalAppointmentDetailRef.displayName = 'ModalAppointmentDetailRef';
export default ModalAppointmentDetailRef;
