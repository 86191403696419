import { APPOINTMENT_STATUS } from 'features/appointment/services/constants';
import React from 'react';
import styled from 'styled-components';
type Props = {
  status: APPOINTMENT_STATUS;
  onClose: () => void;
  onDelete: () => void;
  onNoShow: () => void;
  onDecline: () => void;
  onConfirm: () => void;
  onUpdate: () => void;
  disabledUpdate: boolean;
};
const Footer = ({ disabledUpdate, status, onClose, onDelete, onNoShow, onDecline, onConfirm, onUpdate }: Props) => {
  const CLOSE = <button type='button' onClick={onClose} className="btn btn-close">
    <span>Close</span>
  </button>;
  const DELETE = <button type='button' onClick={onDelete} className="btn btn-delete">
    <span>Delete</span>
  </button>;
  const NO_SHOW = <button type='button' onClick={onNoShow} className="btn btn-no-show">
    <span>No Show</span>
  </button>;
  const DECLINE = <button type='button' onClick={onDecline} className="btn btn-decline">
    <span>Decline</span>
  </button>;
  const DECLINED = <button disabled type='button' className="btn btn-decline">
    <span>Declined</span>
  </button>;
  const CONFIRM = <button type='button' onClick={onConfirm} className="btn btn-confirm">
    <span>Confirm</span>
  </button>;
  const CONFIRMED = <button disabled type='button' className="btn btn-confirm">
    <span>Confirmed</span>
  </button>;
  const UPDATE = <button type='button' disabled={disabledUpdate} onClick={onUpdate} className="btn btn-update">
    <span>Update</span>
  </button>;

  switch (status) {
    case APPOINTMENT_STATUS.NEW:
      return <Container>{CLOSE}{DELETE}{NO_SHOW}{DECLINE}{CONFIRM}{UPDATE}</Container>;
    case APPOINTMENT_STATUS.CONFIRM:
      return <Container>{CLOSE}{DELETE}{CONFIRMED}{UPDATE}</Container>;
    case APPOINTMENT_STATUS.CANCEL:
      return <Container>{CLOSE}{DELETE}{DECLINED}</Container>;
    case APPOINTMENT_STATUS.NO_SHOW:
      return <Container>{CLOSE}{DELETE}</Container>;
    default:
      return null;
  }
};

export default Footer;
const Container = styled.div`
display: flex;
padding: 16px 16px 24px 16px;
align-items: flex-end;
gap: 16px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.15);

button.btn {
  display: flex;
  height: 64px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  background: #E5E6EB;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.btn-delete {
    background: #FFE5E7;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FFB7BC inset;
  }
  &.btn-no-show {
    background: #E5E6EB;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  }
  &.btn-decline {
    background: #FF8890;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
    span {
      color: #FFF;
    }
  }
  &.btn-confirm {
    background: #0DA472;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #03704C inset;
    span {
      color: #FFF;
    }
  }
  &.btn-update {
    border-radius: 4px;
    background: #6FABB6;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset;
    span {
      color: #FFF;
    }
  }

  &:disabled {
    opacity: 0.5;
  }
}
`;