import React from 'react';
import { Input, Row, Select } from 'antd';
import Text from 'components/Text';
import SelectMerge from 'features/cashier/components/SelectMerge';
import styled from 'styled-components';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import { useAppDispatch } from 'store/hooks';
import settingStaffSelectors from 'features/settingStaff/services/selectors';
import settingStaffActions from 'features/settingStaff/services/actions';
import { debounce } from 'lodash';
import { STAFF_POSITION, STAFF_STATUS } from 'features/settingStaff/services/constants';
import Box from 'components/Box';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import ArrangeStaffModal from './ArrangeStaffModal';

const { Option } = Select;
type IComponentProps = {
  isStaffCarousel?: boolean;
};

const STAFF_POSITION_OPTIONS = [
  { value: '', label: 'Position: All' },
  { value: 'ADMIN', label: 'Admin' },
  { value: 'TECHNICAL', label: 'Technician' },
  { value: 'CASHIER', label: 'Cashier' },
  { value: 'MANAGER', label: 'Manager' },
];
const STAFF_STATUS_OPTIONS = [
  { value: 'ACTIVE', label: 'ACTIVE' },
  { value: 'INACTIVE', label: 'INACTIVE' },
  { value: 'DELETE', label: 'DELETE' },
];

const TableControl: React.FC<IComponentProps> = ({
  isStaffCarousel = undefined,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const total = settingStaffSelectors.getTotal();
  const params = settingStaffSelectors.staffsTableData.params();
  const handleChangeStaffType = (value: STAFF_POSITION) => {
    const params = {
      staffType: value,
    };
    dispatch(settingStaffActions.setParams(params));
  };
  const handleChangeStaffStatus = (value: STAFF_STATUS) => {
    const params = {
      status: value,
    };
    dispatch(settingStaffActions.setParams(params));
  };

  const debouncedHandleChange = debounce((value: string) => {
    const params = {
      keySearch: String(value).trim(),
    };
    dispatch(settingStaffActions.setParams(params));
  }, 500); // 500ms delay

  const renderOptions = STAFF_POSITION_OPTIONS.map((item) => {
    return (
      <Option key={item.value} value={item.value}>
        {item.label}
      </Option>
    );
  });

  const handleAddStaff = () => {
    navigate(`/store/${storage.shop_id.get()}/setting-staff/add-new`);
  };

  return (
    <TableControlStyled>
      <Row align={'middle'}>
        <Text mr={1} variant="H5">{`Staffs(${total})`}</Text>
        <ArrangeStaffModal />
      </Row>

      <Box className="center" gap="3" flexWrap="wrap">
        {!isStaffCarousel && (
          <SelectMerge
          style={{width:'10rem'}}
            onChange={(value: STAFF_STATUS) => {
              handleChangeStaffStatus(value);
            }}
            defaultValue={''}
            size="large"
            value={params.status}
          >
            {STAFF_STATUS_OPTIONS.map((item) => {
              return (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </SelectMerge>
        )}
        {!isStaffCarousel && (
          <SelectMerge
            onChange={(value: STAFF_POSITION) => {
              handleChangeStaffType(value);
            }}
            defaultValue={''}
            size="large"
          >
            {renderOptions}
          </SelectMerge>
        )}
        <Input
          autoComplete="off"
          name="keySearch"
          defaultValue=""
          onChange={(event) => {
            debouncedHandleChange(event.target.value);
          }}
          placeholder="Staff name"
          suffix={<SearchIcon />}
        />
        <Button ntype="PRIMARY" icon="addPlus" onClick={handleAddStaff}>
          ADD STAFF
        </Button>
      </Box>
    </TableControlStyled>
  );
};

export default TableControl;
const TableControlStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  flex-wrap: wrap;

  .ant-select {
    height: 3.5rem;
    width: 20rem;
  }

  .ant-input-affix-wrapper {
    width: 20rem;
  }

  .ant-input {
    height: 3rem;
  }
`;
