import { Col, QRCode, Row } from 'antd';
import Text from 'components/Text';
import { IInfoPrinterWaitingResData } from 'features/payment/services/types/ticket';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';

type Props = {
  divRef?: React.RefObject<HTMLDivElement>;
  data: IInfoPrinterWaitingResData | null;
};

const ReceiptUI = ({ divRef, data }: Props) => {
  const allSetting = shopSelectors.data.allSetting();
  const shopInfo = shopSelectors.data.shopInfo();
  return (
    <TicketBox className="ticket-detail-box" ref={divRef}>
      <Row justify={'space-between'} style={{ marginTop: 5 }}>
        <TicketCodeBox>
            #{data?.ticketNumber}
          </TicketCodeBox>
        <TicketCodeBox style={{ fontSize: 30, padding: '0 16px' }}>
          {data?.appointment ? 'APPOINTMENT' : 'WALK-IN'}
        </TicketCodeBox>
        <Col>
          {data?.checkInTime && <Text style={{ wordSpacing: '0px', fontSize: '30px', fontWeight: 'bold' }}>{data?.checkInTime}</Text>}
        </Col>
      </Row>
      <Row justify={'center'} style={{ marginBottom: 8, marginTop: 10 }}>
        <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '1.5rem' }}>{shopInfo?.shopName || ''}</ShopText>
      </Row>
      <Text mb={0.5} />
      {data?.qrCode && <Row align={'middle'} justify={'center'} className='container'>
        <QRCode size={200} value={data.qrCode} />
      </Row>}
      <Text mb={0.5} />
      <Row align={'middle'} className='container'>
        <Text style={{ fontSize: '18px', color: 'black' }} mr={0.5}>Customer: </Text>
        <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '18px' }}>{data?.customerName} - {maskPhone(data?.phone || '')}</ShopText>
      </Row>
      <Text mb={0.5} />
      <div className='container'>
        {data?.items?.map((item) => (
          !item.staffId && !item.services.length ? null :
            <div key={item.staffId + data?.ticketNumber}>
              <StaffHeaderBox>{item.staffName || 'Next Available'}</StaffHeaderBox>
              <ServiceBoxStyled style={{ marginBottom: item.services?.length === 0 ? '48px' : 0 }}>
                {item.services.map((service) => (
                  <div key={service.itemId}>
                    <SpaceBetweenBox>
                      <Text printMode variant="CONTENT_2">
                        SER: {service.itemName}
                      </Text>
                      <PriceBoxStyled style={{ opacity: allSetting?.enablePriceService ? 1 : 0 }}>
                        <Text printMode variant="CONTENT_2">
                          {formatCurrency(service.price)}
                        </Text>
                      </PriceBoxStyled>
                    </SpaceBetweenBox>
                  </div>
                ))}
              </ServiceBoxStyled>
            </div>
        ))}
      </div>
      <Text mt={4} style={{ background: 'black', height: 1.5 }} />
      <Row align={'middle'} justify={'center'} className='container' style={{marginTop: '15px'}}>
        <TicketCodeBox>
          POS Stations #{storage.station_number.get()}
        </TicketCodeBox>
      </Row>
      <Text style={{ height: '1rem', width: '100%' }} />
    </TicketBox>
  );
};

export default ReceiptUI;

const TicketBox = styled.div`
  background: #fff;
  width: 25.5rem;
  height: fit-content;
`;
const TicketCodeBox = styled.div`
  border-radius: 5px;
  background: black;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const ShopText = styled(Text)`
  color: black;
  font-style: normal;
  font-family: 'Roboto', serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: right;

  &.bold {
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

const StaffHeaderBox = styled.div`
  color: black;
  font-family: 'Roboto', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;

const ServiceBoxStyled = styled.div`
  padding: 0.5rem 0;
`;

const SpaceBetweenBox = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;