import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import bannerCustomerInfo from '../../../images/bannerCustomerInfo.png';
import Text from 'components/Text';
import checkInSelectors from 'features/checkIn/services/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import { TYPE_WALK_IN, useModalChooseGroupRef } from './ModalChooseGroup';
import { Row } from 'antd';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { IAppointmentCustomerDetail } from 'features/checkIn/services/types/customer';
import checkInApis from 'features/checkIn/services/apis';
import { useAppDispatch } from 'store/hooks';
import checkInActions from 'features/checkIn/services/actions';
import { useNavigate, useParams } from 'react-router-dom';
import toast from 'utils/toast';
import ActionBtn from 'components/ActionBtn';
import { IWalkInData } from 'features/checkIn/services/types/demoData';
import ModalGroup from './ModalGroup';
import shopSelectors from 'services/shop/selectors';
const wrapActionIcon = { height: '32px', width: '32px' };
type IMainProps = {};
const Main: React.FC<IMainProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customerData = checkInSelectors.getCheckedInData();
  const modalChooseGroupRef = useModalChooseGroupRef();
  const { shop_id } = useParams();
  const setLoadingPage = useSetLoadingPage();
  const allSetting = shopSelectors.data.allSetting();

  const checkInAppointment = async () => {
    const customerId = customerData.data?.id;
    if (!customerId) return;
    setLoadingPage(true);
    try {
      const resData: IResponseDataBody<IAppointmentCustomerDetail> = await checkInApis.getAppointmentDetail(customerId);
      const detail = resData?.data?.data;
      if (detail) {
        dispatch(checkInActions.getAppointmentDetail.success(detail));
        if (!detail?.items?.length) {
          throw 'fail';
        } else {
          navigate(`/store/${shop_id}/check-in/appointments`);
        }
      } else {
        throw 'fail';
      }
    } catch (error) {
      toast.info('not available appointment, please select services!');
      navigate(`/store/${shop_id}/check-in/services`);
    } finally {
      setLoadingPage(false);
    }
  };
  const onSkip = () => {
    navigate(`/store/${shop_id}/check-in/sign-in`);
  };

  const handleSubmitTypeWalkIn = () => {
    const walkInData: IWalkInData = {
      type: TYPE_WALK_IN.Individual,
      numOfMembers: 0,
    };

    dispatch(checkInActions.setWalkInData(walkInData));
    navigate(`/store/${shop_id}/check-in/services`);
  };

  return (
    <MainStyled>
      <img src={bannerCustomerInfo} className="image-banner" />
      <div className="body">
        <div className="divider"></div>
        <Text variant="H3" color="text_3">
          {customerData.data?.name ?? 'Unknow Name'}
        </Text>
        {!!allSetting?.showPointCheckIn && <div className='customer-point-box'>
          <Text variant="H3" color="text_1">
            {`${customerData.data?.point ?? 0} Points (${formatCurrency(customerData.data?.balance)})`}
          </Text>
        </div>}
        <div className="divider"></div>
        {allSetting?.skipWaitingAndPending ?
          <Row justify={'center'} className='actions'>
            <ActionBtn onClick={onSkip} wrapIconStyle={wrapActionIcon} icon='userCircleCheckIn' className='button-actions' label={'DONE'} ntype='PRIMARY' labelStyle={{ color: '#fff' }}></ActionBtn>
          </Row>
          : <>
            {customerData.data?.appointment ?
              <Row justify={'center'} className='actions'>
                <ButtonDone onDone={checkInAppointment} />
              </Row> :
              <Row justify={'center'} className='actions'>
                <ActionBtn onClick={() => modalChooseGroupRef.current?.open()} wrapIconStyle={wrapActionIcon} icon='userCheckInGroup' className='button-actions' label='GROUP' ntype='SECONDARY'></ActionBtn>
                <Text color='text_2' style={{ flex: 1, }} variant='CONTENT_2' ><p>OR</p></Text>
                <ActionBtn onClick={handleSubmitTypeWalkIn} wrapIconStyle={wrapActionIcon} icon='userCheckIn' className='button-actions' label='INDIVIDUAL' ntype='PRIMARY' labelStyle={{ color: '#fff' }} ></ActionBtn>
              </Row>}
          </>
        }
      </div>
      <ModalGroup ref={modalChooseGroupRef} />
    </MainStyled>
  );
};

export default Main;
const MainStyled = styled.div`
  .image-banner {
    width:100%;
  }
  .body {
    text-align: center;
    transform: translateY(-24px);
  }
  .divider {
    width: 50rem;
    margin: 24px auto;
    height: 1px;
    background: #86909C;
  }
  .actions {
    width: 50rem;
    margin: 0 auto;
    padding: 0 32px;
    align-items: center;
    .button-actions {
      height: 6rem !important;
      width: 15rem !important;
    }
    .CONTENT_2 p {
      font-size: 1.2rem;
      text-align: center;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
    }
  }
  .customer-point-box {
    background-color: #1D2129;
    display: inline-flex;
    align-items: center;
    margin-bottom: 1rem;
    margin-top: 1rem;
    border-radius: 4px;
    padding: 8px 16px;
  }
`;
const CHECK_IN_COUNTDOWN = 5;
const ButtonDone = ({ onDone }: { onDone: () => void }) => {
  const [count, setCount] = useState(CHECK_IN_COUNTDOWN);
  useEffect(() => {
    if (count === 0) {
      onDone();
    } else {
      setTimeout(() => {
        setCount(previous => previous - 1);
      }, 1000);
    }
  }, [count]);

  return (
    <ActionBtn onClick={onDone} wrapIconStyle={wrapActionIcon} icon='userCircleCheckIn' className='button-actions' label={`DONE (${count <= 0 ? 0 : count}s)`} ntype='PRIMARY' labelStyle={{ color: '#fff' }}></ActionBtn>
  );
};