import React from 'react';
import styled from 'styled-components';
import TurnTable from './TurnTable';
import TurnFooter from './TurnFooter';
import ModalTurnAddNew, { turnAddNewRef } from './Modals/ModalTurnAddNew';
import ModalTurnDetail, { turnDetailRef } from './Modals/ModalTurnDetail';
import ModalTurnSetting, { turnSettingRef } from './Modals/ModalTurnSetting';
import StaffTurns from './StaffTurns';
import Box from 'components/Box';
import AlarmClockUI, { Today } from 'components/AlarmClockUI';
import TurnSort from './TurnTable/TurnSort';

const TurnManualUI = () => {
  return (
    <Container>
      <div className="wrapper-content">
        <Box display='flex' flexDirection='column' className="content-container">
          <Box display='flex' alignItems='center' style={{ height: '64px' }}>
            <TurnSort />
            <Box display='flex' justifyContent='center' alignItems='center' style={{ flex: 1 }}>
              <Today style={{ marginRight: 24 }} format='ddd. MMM D, YYYY' />
              <AlarmClockUI />
            </Box>
          </Box>
          <div className="content-container">
            <div className="div-scroll">
              <TurnTable />
            </div>
          </div>
          <TurnFooter />
        </Box>
        <StaffTurns />
      </div>

      <ModalTurnAddNew ref={turnAddNewRef} />
      <ModalTurnDetail ref={turnDetailRef} />
      <ModalTurnSetting ref={turnSettingRef} />
    </Container>
  );
};

export default TurnManualUI;

const Container = styled.div`
display: flex;
flex-direction: column;
position: fixed;
inset: 0;
background: #F0F0F0;
.hour, .digital :last-child {
  font-weight: bold;
  color: #1D2129;
}

@keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }

.wrapper-content {
  flex: 1;
  display: flex;
}
.content-container {
  flex: 1;
  position: relative;
  align-self: stretch;
  .div-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,0.5);
      &:hover {
        background-color: rgba(0,0,0,0.6);
      }
    }
  }
}

@media only screen and (max-width: 1500px) {
  .staff-top-row-sticky {
    font-size: 12px;
  }
  .TurnHeadItem .box-item {
    with: 55px;
    .box-turn {
      width: 35px;
      height: 35px;
    }
  }

  .TurnStaffTopRow {
    .ant-avatar {
      width: 50px !important;
      height: 50px !important;
      line-height: 50px !important;
      font-size: 18px !important;
    }

    &.timeAppointment .ant-avatar {
      width: 40px !important;
      height: 40px !important;
      line-height: 40px !important;
      font-size: 18px !important;
    }
    .time-container span {
        font-size: 12px;
    }
    .staff-name {
      font-size: 14px;
    }
  }

  .TurnBodyItem .box-item{
    width: 55px;
    height: 55px;
    .service-text {
      font-size: 18px;
    }
    .container-half .half-bg {
      inset: -6px;
      transform: scale(1);
    }
  }

  .TurnFooter {
    padding: 8px 16px;
    

    .TurnActions {
      margin-right: 12px;
      padding-right: 12px;
      .button-configs .div-action .btn{
        padding: 8px;
        height: 45px;
        span {
          font-size: 14px;
        }
      }
    }

    .TurnTypes {
      padding-right: 12px;
      margin-right: 12px;
      gap: 8px;
      .box-item {
        width: 45px;
        height: 45px;
        .label-type {
          font-size: 13px;
        }
      }
    }

    .TurnServices {
      .container-services {
        gap: 8px;
      }
      .TurnServiceItem .box-item{
        width: 45px;
        height: 45px;
        .service-text {
          font-size: 14px;
        }

        .container-half .half-bg {
          transform: scale(0.8);
          inset: -10px;
        }
      }
    }

  }


}
`;
