import React from 'react';
import styled from 'styled-components';
import dollar_icon from '../icons/dollar.svg';
import redeem_icon from '../icons/redeem.svg';
import balance_icon from '../icons/balance.svg';
import expire_icon from '../icons/expire.svg';
import { IGiftCardDetail } from 'features/giftCards/services/types/reducer';
import { formatCurrency } from 'utils/formatCurrency';
import moment from 'moment';
import { DATE_FORMAT } from 'features/appointment/utils/format';
type Props = {
  detail?: IGiftCardDetail | null;
};
const GiftCardStatistics = ({ detail }: Props) => {
  return (
    <Container>
      <StatisticItem alt='dollar_icon' iconSrc={dollar_icon} label='Amount' value={formatCurrency(detail?.giftCard?.amount)} />
      <StatisticItem alt='redeem_icon' iconSrc={redeem_icon} label='Redeemed' value={formatCurrency(detail?.giftCard?.usedAmount)} />
      <StatisticItem alt='balance_icon' iconSrc={balance_icon} label='Balance' value={formatCurrency((detail?.giftCard?.amount || 0) - (detail?.giftCard?.usedAmount || 0))} />
      <StatisticItem alt='expire_icon' iconSrc={expire_icon} label='Expiration' value={detail?.giftCard?.expireDate ? moment(detail?.giftCard?.expireDate).format(DATE_FORMAT) : '--'} />
    </Container>
  );
};

export default GiftCardStatistics;

const Container = styled.div`
display: flex;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
`;
type StatisticItemProps = {
  iconSrc: any;
  alt: string;
  label: string;
  value: string;
};
const StatisticItem = ({ iconSrc, alt, label, value }: StatisticItemProps) => {
  return (
    <StatisticItemStyled>
      <img src={iconSrc} alt={alt} style={{ width: 76, height: 76 }} />
      <div className="content-container">
        <span className="label">{label}</span>
        <span className="value">{value}</span>
      </div>
    </StatisticItemStyled>
  );
};
const StatisticItemStyled = styled.div`
display: flex;
padding: 24px 48px 24px 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 16px;
flex: 1 0 0;
align-self: stretch;
background: rgba(122, 122, 122, 0.10);
.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  span.label {
    color: #CBD5E1;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
  span.value {
    color: #FF8890;
    font-family: Poppins;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;
