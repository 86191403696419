import { Avatar, DatePicker, Form, Input, InputRef, Select, Spin, Switch } from 'antd';
import { StoreValue } from 'antd/es/form/interface';
import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { IGiftCardDetail } from 'features/cashier/services/types/giftCard';
import { PAYMENT_TYPE, PAYMENT_TYPE_LABEL } from 'features/payment/services/constants';
import moment from 'moment';

import Icon from 'assets/Icons';
import dayjs from 'dayjs';
import { first, sum, sumBy } from 'lodash';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { ICustomerItemData } from 'services/shop/types/customer';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import CustomerSearchInputForm from 'widgets/Customer/CustomerSearchInputForm';
import ModalChooseStaffRef, { useModalChooseStaffRef } from 'widgets/Staff/ModalChooseStaffRef';
import shopSelectors from 'services/shop/selectors';
import { InputFormV2Styled } from 'components/DollarAmount/Input';
import StaffListChooseUI from './StaffListChooseUI';
import { IStaffItemData } from 'services/shop/types/staff';
const disabledDate = (date: dayjs.Dayjs) => {
  if (date.endOf('d').valueOf() < moment().add(1, 'day').valueOf()) {
    return false;
  }
  return true;
};

type IStaffItemValue = {
  id: string | null;
  avatar: string | null;
  name: string | null;
  subTotal?: number;
  supplyFee?: number;
  discount?: number;
  tip?: number;
};

export interface IAddCloseTicketFormValue {
  customer: ICustomerItemData | null | undefined;
  date: dayjs.Dayjs;
  paymentType: string;
  discount?: number;
  staffs: IStaffItemValue[];
  last4Digit: string;
  cardFee: number;
  enableCardFee: boolean;
}


const paymentTypeOpts = [
  {
    label: PAYMENT_TYPE_LABEL[PAYMENT_TYPE.CREDIT_CARD],
    value: PAYMENT_TYPE.CREDIT_CARD,
  },
  {
    label: PAYMENT_TYPE_LABEL[PAYMENT_TYPE.CASH],
    value: PAYMENT_TYPE.CASH,
  },
  {
    label: PAYMENT_TYPE_LABEL[PAYMENT_TYPE.GIFT_CARD],
    value: PAYMENT_TYPE.GIFT_CARD,
  },
];

type Ref = {
  open: () => void;
  close: () => void;
};
type Props = {
  onSubmit: (values: IAddCloseTicketFormValue, done?: boolean) => Promise<boolean>;
  v2?: boolean;
};

export const useModalAddCloseTicket = () => useRef<Ref>(null);
const ModalAddCloseTicket = forwardRef<Ref, Props>(({ onSubmit, v2 }, ref) => {
  const _staffList = shopSelectors.data.staffs();
  const allSetting = shopSelectors.data.allSetting();
  const [advance, setAdvance] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const addRef = useModalChooseStaffRef();
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleOpen = () => {
    form.resetFields();
    setAdvance(false);
    if (v2) {
      const staff = first(_staffList.filter(o => !o.anyBody));
      if (!staff) return;
      form.setFieldValue('staffs', []);
      setOpen(true);
      setTimeout(() => {
        inputRef.current?.focus();
      }, 500);
    } else {
      addRef.current?.open((staff) => {
        form.setFieldValue('staffs', [{
          id: staff.id,
          avatar: staff.urlImage,
          name: staff.firstName,
          subTotal: 0,
          supplyFee: 0,
          discount: 0,
          tip: 0,
        }]);
        setOpen(true);
        setTimeout(() => {
          inputRef.current?.focus();
        }, 500);
      });
    }
  };

  const handleClose = () => setOpen(false);

  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const close: boolean = await onSubmit(values as IAddCloseTicketFormValue);
      if (close) return handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const validate = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleDone = async () => {
    const valid = await validate();
    if (!valid) return;
    const values: IAddCloseTicketFormValue = form.getFieldsValue();
    setLoading(true);
    try {
      const close: boolean = await onSubmit(values as IAddCloseTicketFormValue, true);
      if (close) return handleClose();
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleAddStaff = (add: (defaultValue?: StoreValue, insertIndex?: number) => void) => () => {
    const staffs = form.getFieldValue('staffs');
    addRef.current?.open((staff) => {
      add({
        id: staff.id,
        avatar: staff.urlImage,
        name: staff.firstName,
        subTotal: 0,
        supplyFee: 0,
        discount: 0,
        tip: 0,
      });
    }, staffs?.map((o: IStaffItemValue) => o.id));
  };

  const onChangeStaff = (value?: IStaffItemValue) => {
    if (!value) return;
    const staffs: IStaffItemValue[] = form.getFieldValue('staffs');
    addRef.current?.open((staff) => {
      const staffs: IStaffItemValue[] = form.getFieldValue('staffs');
      const idx = staffs.findIndex(o => o.id === value.id);
      if (idx === -1) return;
      staffs[idx].id = staff.id;
      staffs[idx].avatar = staff.urlImage;
      staffs[idx].name = staff.firstName;

      form.setFieldValue('staffs', staffs);

    }, staffs?.map((o) => o.id || ''));
  };
  const onPickStaff = (staff: IStaffItemData) => {
    const staffs: IStaffItemValue[] = form.getFieldValue('staffs');
    const idx = staffs.findIndex(s => s.id === staff.id);
    if (idx !== -1) return;
    staffs.push({
      id: staff.id,
      avatar: staff.urlImage,
      name: staff.firstName,
      subTotal: 0,
      supplyFee: 0,
      discount: 0,
      tip: 0,
    });
    form.setFieldValue('staffs', staffs);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose
  }));

  return (
    <Modal v2={v2} width={v2 ? (advance ? '1800px' : '1600px') : (advance ? '1600px' : '900px')} footer={null} containerPadding={0} noneBodyStyle noneFooterStyle visible={open} modalTitle='KEY-IN SYSTEM' onClose={handleClose}>
      <Spin spinning={loading}>
        <Container className={v2 ? 'ContainerV2' : ''} initialValues={{ paymentType: PAYMENT_TYPE.CREDIT_CARD, date: dayjs(), enableCardFee: true }} layout='vertical' form={form} onFinish={handleFinish}>
          <Form.Item name={'staffs'} noStyle rules={[{
            validator(_, value) {
              if (!value?.length) return Promise.reject('Please enter staff');
              return Promise.resolve();
            },
          }]} />
          <Box display='flex' style={{ alignSelf: 'stretch' }}>
            {v2 && <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const items: IStaffItemValue[] = getFieldValue('staffs') || [];
                const staffIds = items?.map(o => o?.id?.toString() || '') || [];
                return <StaffListChooseUI onPickStaff={onPickStaff} selectedStaffs={staffIds} />;
              }}
            </Form.Item>}
            {!advance ? (
              <SimpleContainer style={{ flex: 1 }}>
                <div className="information-box">
                  <div className="information-form">
                    <Form.Item label='Payment Type' name={'paymentType'}>
                      <Select options={paymentTypeOpts} disabled />
                    </Form.Item>
                    <Form.Item label='Last 4 Credit Card #' name={'last4Digit'}>
                      <Input size='middle' style={{ height: v2 ? '4rem' : '3.5rem' }}
                        maxLength={4}
                        ref={inputRef}
                        // @ts-ignore
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        max={4}
                      />
                    </Form.Item>
                    <Form.Item label={<div style={{ opacity: 0 }}>cardFee</div>}>
                      <Box display='flex' alignItems='center' gap='2' height={v2 ? '4rem' : '3.5rem'}>
                        <Form.Item noStyle name={'cardFee'}>
                          <CardFeeEnable />
                        </Form.Item>
                      </Box>
                    </Form.Item>
                  </div>
                </div>
                <Form.List name={'staffs'}>
                  {(fields, { add, remove }) => (
                    <div className='information-box'>
                      <Box alignSelf='stretch' display='flex' alignItems='center' justifyContent='space-between'>
                        <p className="title-section">Staff</p>
                      </Box>
                      <Box mb='1' alignSelf='stretch' style={{ display: 'grid', gridTemplateColumns: '1fr', rowGap: '12px', columnGap: '24px', }}>
                        {fields.map(({ name, key }) => (
                          <Form.Item key={key} name={name} noStyle>
                            <StaffItemSimple activeFormatMoney={allSetting?.activeFormatMoney} v2={v2} disabledRemove={fields?.length === 1} name={name} onChangeStaff={onChangeStaff} onRemoveStaff={() => remove(name)} />
                          </Form.Item>
                        ))}
                      </Box>
                      <Button cardCashier={v2} className='btn-add-staff' mb={1} block height={v2 ? 4 : 2.5} ntype='LIGHT_BLUE' size={v2 ? 'large' : 'small'} icon='addPlus' onClick={handleAddStaff(add)} ><Text variant='CONTENT_2'>Add Staff</Text></Button>
                    </div>
                  )}
                </Form.List>
              </SimpleContainer>
            ) : (
              <InformationContainer style={{ flex: 1 }}>
                <div className="information-box">
                  <p className="title-section">Ticket Detail</p>
                  <div className="information-form">
                    <Form.Item label='Customer' name={'customer'}>
                      {/* @ts-ignore */}
                      <CustomerSearchInputForm />
                    </Form.Item>
                    <Form.Item label='Date' name={'date'}>
                      <DatePicker disabledDate={disabledDate} />
                    </Form.Item>
                    <Form.Item label='Payment Type' name={'paymentType'}>
                      <Select options={paymentTypeOpts} disabled />
                    </Form.Item>
                    <Form.Item label='Discount' name={'discount'}>
                      {allSetting?.activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} height={v2 ? '4rem' : '3.5rem'} /> : <CurrencyInputPrecision height={v2 ? '4rem' : '3.5rem'} />}
                    </Form.Item>
                    <Form.Item shouldUpdate noStyle>
                      {({ getFieldValue }) => {
                        const paymentType = getFieldValue('paymentType');

                        switch (paymentType) {
                          case PAYMENT_TYPE.CREDIT_CARD:
                            return (
                              <>
                                <Form.Item label='Last 4 Credit Card #' name={'last4Digit'}>
                                  <Input size='middle' style={{ height: v2 ? '4rem' : '3.5rem' }}
                                    maxLength={4}
                                    // @ts-ignore
                                    onKeyPress={(event) => {
                                      if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    max={4}
                                  />
                                </Form.Item>
                                <Form.Item label={<div style={{ opacity: 0 }}>cardFee</div>}>
                                  <Box display='flex' alignItems='center' gap='2' height={v2 ? '4rem' : '3.5rem'}>
                                    <Form.Item noStyle name={'cardFee'}>
                                      <CardFeeEnable />
                                    </Form.Item>
                                  </Box>
                                </Form.Item>
                              </>
                            );
                          case PAYMENT_TYPE.GIFT_CARD:
                            return (
                              <>
                                <Form.Item label='Gift Card Number' name={'giftCardNumber'}>
                                  <Input />
                                </Form.Item>
                                <Form.Item label='Gift Card Information' name={'giftCardDetail'}>
                                  <GiftCardDetail />
                                </Form.Item>
                              </>
                            );
                          default:
                            return null;
                        }
                      }}
                    </Form.Item>

                  </div>
                </div>

                <Form.List name={'staffs'}>
                  {(fields, { add, remove }) => (
                    <div className=" payment-box">
                      <Box display='flex' alignItems='center' justifyContent='space-between' mb='1'>
                        <p className="title-section">Staff</p>
                        <Button cardCashier={v2} className='btn-add-staff' height={v2 ? 4 : 3} ntype='LIGHT_BLUE' size={v2 ? 'large' : 'small'} icon='addPlus' onClick={handleAddStaff(add)} ><Text variant='CONTENT_2'>Add Staff</Text></Button>
                      </Box>
                      <Box display='flex' flexDirection='column' style={{ gap: 8 }}>
                        {fields.map(({ key, name }) => (
                          <Form.Item key={key} name={name} noStyle>
                            <StaffItem activeFormatMoney={allSetting?.activeFormatMoney} v2={v2} name={name} disabledRemove={fields?.length === 1} onChangeStaff={onChangeStaff} onRemoveStaff={() => remove(name)} />
                          </Form.Item>

                        ))}
                      </Box>
                    </div>

                  )}

                </Form.List>
              </InformationContainer>
            )}
          </Box>
          <FooterContainer className='FooterContainer'>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldsValue }) => {
                const values = getFieldsValue();
                const discountTicket = values.discount || 0;
                const cardFee = values.cardFee || 0;

                const itemSubTotal = sumBy(values.staffs, (staff: IStaffItemValue) => {
                  return sum([staff.subTotal || 0, -(staff.discount || 0), staff.tip || 0]);
                });
                const total = sum([-discountTicket, cardFee, itemSubTotal]);
                return (
                  <div className="total-pay-container">
                    <Text variant='H4'>Total Paid</Text>
                    <Text variant='H2'>{formatCurrency(total)}</Text>
                  </div>
                );
              }}
            </Form.Item>
            <div className="button-container">
              <Button cardCashier={v2} height={v2 ? 4 : 3.5} className='btn-footer' block onClick={handleClose}>Close</Button>
              <Button cardCashier={v2} height={v2 ? 4 : 3.5} className='btn-footer' ntype='ORANGE' block onClick={() => setAdvance(!advance)}>{advance ? 'Simple' : 'Advance'}</Button>
              <Button cardCashier={v2} height={v2 ? 4 : 3.5} className='btn-footer' block ntype='PRIMARY' onClick={() => form.submit()} >Next</Button>
              <Button cardCashier={v2} height={v2 ? 4 : 3.5} className='btn-footer' block ntype='LIGHT_BLUE' onClick={handleDone} >Done</Button>
            </div>
          </FooterContainer>
        </Container>
      </Spin>
      <ModalChooseStaffRef v2={v2} ref={addRef} />
    </Modal>
  );
});
ModalAddCloseTicket.displayName = 'ModalAddCloseTicket';
export default ModalAddCloseTicket;
const FooterContainer = styled.div`
position: sticky;
bottom: 0;
display: flex;
padding: 8px 24px 16px 24px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
z-index: 99;
background: #FFF;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.25);
.total-pay-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.button-container {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
`;
const SimpleContainer = styled.div`
display: flex;
padding-top: 16px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
background: #FFF;
.title-section {
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; 
  text-transform: capitalize;
}
.staff-container {
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border: 1px solid #C9CDD4;
  background: #FFF;
}
.information-box {
  display: flex;
  padding: 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}

.view-select-staff {
  position: relative;
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 5px;
  border: 1px solid #6FABB6;
  background: #C6E9EF;
}
.information-form {
  column-gap: 12px;
  align-self: stretch;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  }
.ant-form-item {
  margin-bottom: 8px;
}
`;
const InformationContainer = styled.div`
display: flex;
padding-top: 16px;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
  .title-section {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; 
      text-transform: capitalize;
    }
  .information-box {
    display: flex;
    padding: 0px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
  }

  .information-form {
    column-gap: 12px;
    align-self: stretch;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    &.staff-detail-form {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .payment-box {
    background: #F6F7FC;
    display: flex;
    padding: 0px 24px;
    padding-top: 16px;
    margin-bottom: 24px;
    align-self: stretch;
    flex-direction: column;
    .staff-container {
      display: flex;
      padding: 16px;
      flex-direction: column;
      align-items: flex-start;
      gap: 12px;
      align-self: stretch;
      border: 1px solid #C9CDD4;
      background: #FFF;
    }
    .view-select-staff {
      position: relative;
      display: flex;
      padding: 4px 16px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      align-self: stretch;

      border-radius: 5px;
      border: 1px solid #6FABB6;
      background: #C6E9EF;
    }
  }
`;

const Container = styled(Form)`
display: flex;
padding-top: 0;
flex-direction: column;
align-items: flex-start;
gap: 10px;
align-self: stretch;
border-radius: 0px 0px 5px 5px;
background: #FFF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset;
&.ContainerV2 {
zoom: 0.9;
  .title-section {
    color: #1D2129;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-form-item .ant-form-item-label label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-input-password, .ant-input-number, .ant-input, .ant-picker, .ant-select {
    height: 4rem;
  }
  .ant-select-selector .ant-select-selection-item, .ant-input, .ant-picker .ant-picker-input input {
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .CustomerSearchInputForm {
    height: 4rem;
    .ant-select .ant-select-selector .ant-input {
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .ButtonAddCustomer {
      height: unset !important;
      width: 64px;
      svg {
        transform: scale(1.5);
      } 
    }
  }

  .CardFeeEnable {
    gap: 16px;
    padding-left: 16px;
    .ant-switch {
      transform: scale(1.5);
    }
    .switch-label {
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .btn-add-staff {
    svg {
      transform: scale(1.5);
    }

    .CONTENT_2 {
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .StaffItem {
    .CONTENT_2.staff-name {
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .ant-btn.btn-action {
      svg {
        transform: scale(1.5);
      }
    }
  }

  .FooterContainer {
    .total-pay-container {
      .H4, .H2 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .H2 {
        font-size: 40px;
      }
    }

    .button-container .btn-footer .CONTENT_2 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
  }
}
`;

const GiftCardDetail = ({ value: giftCardData }: { value?: IGiftCardDetail }) => {
  const resultGiftCard = useMemo(() => {
    const { amount = 0, usedAmount = 0, expireDate } = giftCardData || {};
    const remaining = amount - usedAmount;
    return ({
      available: remaining || 0,
      amount: amount || 0,
      used: usedAmount || 0,
      expireDate: expireDate ? moment(expireDate).format('MM-DD-YYYY') : '--/--/----',
    });
  }, [giftCardData]);
  return (
    <GiftCardDetailContainer>
      <div className="gift-card-info-item">
        <span className="label">Available:</span>
        <span className="value">{resultGiftCard.available}</span>
      </div>
      <div className="gift-card-info-item">
        <span className="label">Amount:</span>
        <span className="value">{resultGiftCard.amount}</span>
      </div>
      <div className="gift-card-info-item">
        <span className="label">Expires:</span>
        <span className="value">{resultGiftCard.expireDate}</span>
      </div>
    </GiftCardDetailContainer>
  );
};

const GiftCardDetailContainer = styled.div`
display: flex;
height: 56px;
padding: 6px 0px;
align-items: center;
gap: 10px;
flex-shrink: 0;
align-self: stretch;
.gift-card-info-item {
  flex: 1 0 0;
  span.label {
    color: #505050;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  span.value {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
  }
}
`;
type StaffItemProps = {
  activeFormatMoney?: boolean;
  v2?: boolean;
  name?: any;
  value?: IStaffItemValue;
  disabledRemove?: boolean;
  onChangeStaff?: (value?: IStaffItemValue) => void;
  onRemoveStaff?: (value?: IStaffItemValue) => void;
};

const StaffItemSimple = ({ name, value, onChangeStaff = () => undefined, onRemoveStaff = () => undefined, disabledRemove, v2, activeFormatMoney }: StaffItemProps) => {
  return (
    <Box className='StaffItem' display='flex' alignItems='center' justifyContent='space-between' style={{ backgroundColor: '#F6F7FC', gap: '12px', border: '1px solid #eee', padding: '4px 8px', borderRadius: 4 }}>
      <Form.Item label=" " style={{ flex: 1 }} >
        <Box display='flex' alignItems='center' gap='2' height={'48px'}>
          {value?.id ? <Avatar size={v2 ? 68 : 40} src={value?.avatar}>{value?.name?.[0] || 'A'}</Avatar> : avatarEmpty}
          <Text className='staff-name' variant='CONTENT_2' style={{ color: '#2D6772', fontSize: v2 ? '24px' : '18px' }}>{value?.name || 'Select Staff'}</Text>
        </Box>
      </Form.Item>
      <div className="information-form staff-detail-form" style={{ flex: 1, gridTemplateColumns: '1fr 1fr 1fr' }}>
        <Form.Item label="Amount" name={[name, 'subTotal']}>
          {activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} height={v2 ? '4rem' : 48} /> : <CurrencyInputPrecision height={v2 ? '4rem' : 48} />}
        </Form.Item>
        <Form.Item label='Tip' name={[name, 'tip']}>
          {activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} height={v2 ? '4rem' : 48} /> : <CurrencyInputPrecision height={v2 ? '4rem' : 48} />}
        </Form.Item>
        <Form.Item label='Supply Fee' name={[name, 'supplyFee']}>
          {activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} height={v2 ? '4rem' : 48} /> : <CurrencyInputPrecision height={v2 ? '4rem' : 48} />}
        </Form.Item>
      </div>
      <Form.Item label={<div style={{ opacity: 0 }}>staff</div>}>
        <Box display='flex' alignItems='center' gap='2' height={'48px'}>
          <Button className='btn-action' height={v2 ? 4 : 2.5} icon='change' size='small' width={v2 ? '64px' : '40px'} onClick={() => onChangeStaff(value)} />
          <Button className='btn-action' height={v2 ? 4 : 2.5} disabled={disabledRemove} size='small' width={v2 ? '64px' : '40px'} onClick={() => onRemoveStaff(value)} ><Icon type='deleteIconCashier' fill='#1D2129' /></Button>
        </Box>
      </Form.Item>
    </Box>
  );
};

const StaffItem = ({ value, name, onChangeStaff = () => undefined, onRemoveStaff = () => undefined, disabledRemove, v2, activeFormatMoney }: StaffItemProps) => {
  return (
    <div className="staff-container StaffItem">
      <div className="view-select-staff">
        {value?.id ? <Avatar size={v2 ? 68 : 48} src={value?.avatar}>{value?.name?.[0] || 'A'}</Avatar> : avatarEmpty} <Text className='staff-name' variant='CONTENT_2' style={{ color: '#2D6772' }}>{value?.name || 'Select Staff'}</Text>
        {value?.id ? <>
          <Box display='flex' alignItems='center' gap='2' style={{ position: 'absolute', right: '16px' }}>
            <Button className='btn-action' height={v2 ? 4 : 2.5} icon='change' size='small' width={v2 ? '64px' : '40px'} onClick={() => onChangeStaff(value)} />
            {!disabledRemove && <Button className='btn-action' height={v2 ? 4 : 2.5} size='small' width={v2 ? '64px' : '40px'} onClick={() => onRemoveStaff(value)} ><Icon type='deleteIconCashier' fill='#1D2129' /></Button>}
          </Box>
        </> : null}
      </div>
      <div className="information-form staff-detail-form">
        <Form.Item label="Amount" name={[name, 'subTotal']}>
          {activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} /> : <CurrencyInputPrecision />}
        </Form.Item>
        <Form.Item label='Supply Fee' name={[name, 'supplyFee']}>
          {activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} /> : <CurrencyInputPrecision />}
        </Form.Item>
        <Form.Item label='Discount item' name={[name, 'discount']}>
          {activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} /> : <CurrencyInputPrecision />}
        </Form.Item>
        <Form.Item label='Tip' name={[name, 'tip']}>
          {activeFormatMoney ? <InputFormV2Styled simpleV1={!v2} /> : <CurrencyInputPrecision />}
        </Form.Item>
      </div>

    </div>
  );
};
const avatarEmpty = <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="24" cy="24" r="24" fill="white" />
  <rect width="24" height="24" transform="translate(12 12)" fill="white" />
  <path fillRule="evenodd" clipRule="evenodd" d="M16.4645 27.4645C17.4021 26.5268 18.6739 26 20 26H28C29.3261 26 30.5979 26.5268 31.5355 27.4645C32.4732 28.4021 33 29.6739 33 31V33C33 33.5523 32.5523 34 32 34C31.4477 34 31 33.5523 31 33V31C31 30.2044 30.6839 29.4413 30.1213 28.8787C29.5587 28.3161 28.7956 28 28 28H20C19.2044 28 18.4413 28.3161 17.8787 28.8787C17.3161 29.4413 17 30.2044 17 31V33C17 33.5523 16.5523 34 16 34C15.4477 34 15 33.5523 15 33V31C15 29.6739 15.5268 28.4021 16.4645 27.4645Z" fill="#2D6772" />
  <path fillRule="evenodd" clipRule="evenodd" d="M24 16C22.3431 16 21 17.3431 21 19C21 20.6569 22.3431 22 24 22C25.6569 22 27 20.6569 27 19C27 17.3431 25.6569 16 24 16ZM19 19C19 16.2386 21.2386 14 24 14C26.7614 14 29 16.2386 29 19C29 21.7614 26.7614 24 24 24C21.2386 24 19 21.7614 19 19Z" fill="#2D6772" />
</svg>
  ;
type CardFeeEnableProps = {
  value?: number;
  onChange?: (value: number) => void;
};
const CardFeeEnable = ({ onChange = () => undefined }: CardFeeEnableProps) => {
  const form = Form.useFormInstance();
  const staffs = Form.useWatch('staffs', form);
  const enableCardFee = Form.useWatch('enableCardFee', form);
  const feeCreditCard = shopSelectors.data.feeCreditCard();

  const calcFeeValue = useMemo(() => {
    const subtotal = sumBy(staffs, (o: IStaffItemValue) => o.subTotal || 0);
    if (feeCreditCard?.feeType === 'PERCENT') {
      return subtotal * (feeCreditCard?.feeValue / 100);
    }
    return feeCreditCard?.feeValue || 0;
  }, [staffs, feeCreditCard]);

  const labelFee = useCallback(() => {
    if (!feeCreditCard) return '';
    if (feeCreditCard?.feeType === 'PERCENT')
      return `${feeCreditCard?.feeValue}% ${formatCurrency(calcFeeValue)}`;
    if (feeCreditCard?.feeType === 'MONEY')
      return formatCurrency(feeCreditCard?.feeValue);
  }, [calcFeeValue, feeCreditCard]);

  useEffect(() => {
    if (enableCardFee) {
      onChange(calcFeeValue);
    } else {
      onChange(0);
    }
  }, [enableCardFee, staffs]);

  return (
    <Box className='CardFeeEnable' display='flex' alignItems='center' style={{ flex: 1, alignSelf: 'stretch' }}>
      <Form.Item noStyle name={'enableCardFee'} valuePropName='checked'><Switch /></Form.Item>
      <Text className='switch-label' ml={0.5} variant="H6" color="text_3" style={{ fontWeight: 'bolder' }}>
        Card Fee: {labelFee() || ''}
      </Text>
    </Box>
  );
};