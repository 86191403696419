import cashierActions from 'features/cashier/services/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import CashierActionButton from '../CashierActionBtn';
import useUpdateTicket from '../useUpdateTicket';
type Props = {
  v2?: boolean;
};
const ButtonSave = ({ v2 }: Props) => {
  const { id = '' } = useParams();
  const updateTicket = useUpdateTicket();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleEditTicket = async () => {
    try {
      const res = await updateTicket();
      if (!res || id === res?.billId) {
        dispatch(cashierActions.getDetail.fetch(id));
        return;
      }
      navigate('/store/' + storage.shop_id.get() + '/ticket/edit/' + res?.billId, { replace: true });
    } catch (error) { }
  };

  return (
    <CashierActionButton
      label='Save'
      onClick={handleEditTicket}
      ntype='LIGHT_BLUE'
      v2UI={v2}
      feature='SAVE'
    />
  );
};

export default ButtonSave;