import { AutoComplete, Avatar, Col, Input, InputRef, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import Text from 'components/Text';
import cashierSelectors from 'features/cashier/services/selectors';
import { IStaff } from 'features/cashier/services/types/api';
import { get } from 'lodash';
import { useMemo, useState } from 'react';

type Props = {
  onPickStaff: (option: IStaff) => void;
  inputRef: React.RefObject<InputRef>;
  v2?: boolean;
};
const StaffSearchInput = ({ onPickStaff, inputRef, v2 }: Props) => {
  const staffListStore = cashierSelectors.getAvailableStaff();
  const initialOptions = useMemo(() => {
    return staffListStore.map(o => {
      return ({
        value: o.staffId,
        name: o.staffName,
        keywords: [o.staffName?.toLocaleLowerCase()],
        label: <Row align={'middle'} gutter={[8, 8]}>
          <Col><Avatar src={o.staffAvatar}>{get(o.staffName, [0], '')}</Avatar></Col>
          <Col><Text>{o.staffName}</Text></Col>
        </Row>,
      });
    });
  }, [staffListStore]);

  const [searchText, setSearchText] = useState<string>('');

  const options = useMemo(() => {
    if (!searchText) return initialOptions;
    return initialOptions.filter((item) => item.keywords.some(kw => kw?.includes(searchText?.toLocaleLowerCase())));
  }, [initialOptions, searchText]);

  const handleSwitchCustomer = (data: string) => {
    setSearchText('');
    inputRef.current?.blur();
    const result = staffListStore?.find((item) => item.staffId === data);
    if (!result) return;
    onPickStaff(result);
  };


  return (
    <AutoComplete
      className='StaffSearchInput'
      style={{ width: '100%' }}
      options={options}
      onSelect={handleSwitchCustomer}
      value={searchText}
      onChange={setSearchText}
    >
      <Input
        ref={inputRef}
        autoFocus
        suffix={<SearchIcon fill={v2 ? '#fff' : undefined} />}
        placeholder={v2 ? 'Search by Staff’s Name' : 'Staff name'}
      />
    </AutoComplete>
  );
};

export default StaffSearchInput;
