import Icon, { typeIcon } from 'assets/Icons';
import Button, { ButtonProps } from 'components/Button';
import { CSSProperties, useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import { CashierButtonPaymentUI } from 'services/shop/types/setting';
import { styled } from 'styled-components';
interface Props {
	v2UI?: boolean,
	label: string,
	labelStyle?: CSSProperties,
	normal?: boolean;
	ntype?: ButtonProps['ntype'];
	feature: 'CALC' | 'CLEAR' | 'DISC' | 'TIP' | 'SAVE' | 'VOID' | 'CARD' | 'CASH' | 'MULTI-PAYS' | 'MULTI-CARDS' | 'CHECK' | 'GIFT-CARD' | 'LOYALTY';
	onClick?: () => void;
	disabled?: boolean;
}
function CashierActionButton({ feature, ...props }: Props) {
	const allSetting = shopSelectors.data.allSetting();
	const icon: typeIcon | undefined = useMemo(() => {
		if (props.v2UI) {
			if (allSetting?.uiButtonPayment === CashierButtonPaymentUI.BACKGROUND) {
				switch (feature) {
					case 'CALC': return 'calculatorBtnV2';
					case 'CLEAR': return 'backSpaceBtnV2';
					case 'DISC': return 'discountBtnV2';
					case 'TIP': return 'tipBtnV2';
					case 'SAVE': return 'saveBtnV2';
					case 'VOID': return 'voidedBtnV2';
					case 'CARD': return 'cardBtnV2';
					case 'CASH': return 'cashBtnV2';
					case 'MULTI-PAYS': return 'multiPayBtnV2';
					case 'MULTI-CARDS': return 'multiCardBtnV2';
					case 'CHECK': return 'ePayBtnV2';
					case 'GIFT-CARD': return 'giftCardBtnV2';
					case 'LOYALTY': return 'loyaltyPointBtnV2';
				}
			} else {
				switch (feature) {
					case 'CALC': return 'calculatorBtnNormal';
					case 'CLEAR': return 'backSpaceBtnNormal';
					case 'DISC': return 'discountBtnNormal';
					case 'TIP': return 'tipBtnNormal';
					case 'SAVE': return 'saveBtnNormal';
					case 'VOID': return 'voidedBtnNormal';
					case 'CARD': return 'cardBtnNormal';
					case 'CASH': return 'cashBtnNormal';
					case 'MULTI-PAYS': return 'multiPayBtnNormal';
					case 'MULTI-CARDS': return 'multiCardBtnNormal';
					case 'CHECK': return 'ePayBtnNormal';
					case 'GIFT-CARD': return 'giftCardBtnNormal';
					case 'LOYALTY': return 'loyaltyPointBtnNormal';
				}
			}

		}
		switch (feature) {
			case 'CALC': return 'calculatorBtn';
			case 'CLEAR': return 'backSpace';
			case 'DISC': return 'discount';
			case 'TIP': return 'tip';
			case 'SAVE': return 'save';
			case 'VOID': return 'voided';
			case 'CARD': return 'cardBtn';
			case 'CASH': return 'cashBtn';
			case 'MULTI-PAYS': return 'multiPay';
			case 'MULTI-CARDS': return 'multiCards';
			case 'CHECK': return 'ePayIcon';
			case 'GIFT-CARD': return 'giftCardBtn';
			case 'LOYALTY': return 'loyaltyPointBtn';
		}
		return undefined;
	}, [feature, props.v2UI, allSetting]);


	if (props.v2UI) {
		if (allSetting?.uiButtonPayment === CashierButtonPaymentUI.NORMAL) {
			return (
				<ActionBtnStyled
					{...props}
					block
					vertical
					icon={undefined}
					iconElement={(icon && <div style={{ height: 80, width: 80 }}><Icon type={icon} /></div>)}
					cardCashier
					cardCashierNormal
				>
					<p style={props.labelStyle}>{props.label}</p>
				</ActionBtnStyled>
			);
		}
		return (
			<ActionBtnStyled
				{...props}
				block
				vertical
				icon={undefined}
				iconElement={(icon && <div style={{ height: 80, width: 80 }}><Icon type={icon} /></div>)}
				cardCashier
			>
				<p style={props.labelStyle}>{props.label}</p>
			</ActionBtnStyled>
		);
	}

	return (
		<ActionBtnStyled {...props} icon={icon} block vertical>
			<p style={props.labelStyle}>{props.label}</p>
		</ActionBtnStyled>
	);
}

export default CashierActionButton;
const ActionBtnStyled = styled(Button) <{ v2UI?: boolean }>`
    width: 6.2rem !important;
    p {
        white-space: pre;
      color: #1D2129;
      text-align: center;
      font-family: Open Sans;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    ${({ v2UI }) => v2UI && `
        display: flex;
        width: 120px !important;
        padding: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch !important;
        height: auto !important;
        gap: 0px;
        align-self: stretch;
        p {
            color: #1D2129;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    `}
`;