import { ButtonProps } from 'antd';
import { typeIcon } from 'assets/Icons';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { SortableList } from 'features/settingService/components/SortableList';
import settingApis from 'features/settingService/services/apis';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import toast from 'utils/toast';

interface Props extends Omit<ButtonProps, 'icon'> {
  icon?: typeIcon,
  title?: string;
  height?: number;
}

const ArrangeCateModal = ({ icon, title, height, ...btnProps }: Props) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const categories = shopSelectors.data.categories();
  const setLoading = useSetLoadingPage();
  const [items, setItems] = useState<{ id: string, name: string, totalServices: number }[]>([]);
  const handleOpen = () => {
    setItems(categories.map(o => ({ id: o.id, name: o.categoryName, totalServices: o.services.length })));
    setOpen(true);
  };

  const handleAccept = async () => {
    setOpen(false);

    setLoading(true);
    const ids = items.map(o => o.id);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.sortCategories(ids);
      if (res.data.data) {
        toast.success('Reorder successful');
        dispatch(shopActions.getNewCategories.fetch());
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button size='small' icon={icon || 'arrange'} ntype='LIGHT_BLUE' height={height} {...btnProps} onClick={handleOpen}>{title || 'Reorder'}</Button>
      <Modal
        visible={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAccept}
        modalTitle={title || 'Reorder'}
      >
        <SortableList
          items={items}
          onChange={setItems}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              <CateWrap>
                <Text variant="H7" color="text_3">
                  {item.name}
                </Text>
                <Text variant="CONTENT_1" color="text_2">
                  {item.totalServices} Services
                </Text>
              </CateWrap>
            </SortableList.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default ArrangeCateModal;

const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .label {
    font-size: 18px;
    font-weight: 600;
  }
`;