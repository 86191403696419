import React from 'react';
import styled from 'styled-components';
type Props = {
  value?: number;
  onChange?: (value: number) => void;
};
const Durations = ({ value, onChange = () => undefined }: Props) => {
  return (
    <Container className='Durations'>
      <CheckBox onClick={() => onChange(30)} checked={value == 30} label='30 minutes' />
      <CheckBox onClick={() => onChange(45)} checked={value == 45} label='45 minutes' />
      <CheckBox onClick={() => onChange(60)} checked={value == 60} label='1 hour' />
      <CheckBox onClick={() => onChange(90)} checked={value == 90} label='1.5 hour' />
      <CheckBox onClick={() => onChange(120)} checked={value == 120} label='2 hour' />
    </Container>
  );
};

export default Durations;

const Container = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
.duration-item {
  
}

`;

const CheckBox = ({ checked, label, onClick }: { onClick: () => void, checked?: boolean, label?: string }) => {
  return <CheckboxStyled type='button' onClick={onClick}>
    {checked ? <div className="checked-box">{checkedBox}</div> : <div className="check-box-blank"></div>}
    <div className="label">{label}</div>
  </CheckboxStyled>;
};
const CheckboxStyled = styled.button`
display: flex;
align-items: center;
gap: 4px;
.checked-box {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #2D6772;
  background: #2D6772;
}
.check-box-blank {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #86909C;
  background: #F0F0F0;
}
  .label {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const checkedBox = <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
  <path d="M17.8845 5.82737C17.558 5.50169 17.1157 5.31885 16.6546 5.31885C16.1934 5.31885 15.751 5.50169 15.4245 5.82737L7.92452 13.3274L4.64286 10.0474C4.48255 9.87985 4.29043 9.74589 4.07767 9.65356C3.86492 9.56124 3.63586 9.51234 3.40395 9.50972C3.17204 9.50711 2.94195 9.5509 2.72717 9.63841C2.51239 9.72592 2.31718 9.85543 2.15313 10.0194C1.98908 10.1833 1.85942 10.3784 1.77177 10.5931C1.68411 10.8078 1.64022 11.0379 1.64268 11.2698C1.64513 11.5017 1.69393 11.7308 1.78611 11.9436C1.87829 12.1564 2.0121 12.3487 2.17958 12.5091L6.69456 17.0224C7.02162 17.3483 7.46454 17.5313 7.92625 17.5313C8.38795 17.5313 8.83077 17.3483 9.15783 17.0224L17.8945 8.28912C18.0577 8.1275 18.1872 7.9351 18.2756 7.72312C18.364 7.51115 18.4095 7.28374 18.4095 7.05408C18.4095 6.82441 18.364 6.59701 18.2756 6.38503C18.1872 6.17305 18.0577 5.98076 17.8945 5.81913L17.8845 5.82737Z" fill="white" />
</svg>;