import { Layout, Row } from 'antd';
import Button from 'components/Button';
import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import userActions from 'features/user/services/actions';
import React, { useEffect, useState } from 'react';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import turnActions, { turnManualAPIActions } from '../services/actions';
import StaffCarousel from './components/Carousel';
import TurnTable from './components/TurnTable';
import patentPending from './patent-pending.png';
import StatusBarItem from './components/StatusBarItem';
import shopSelectors from 'services/shop/selectors';
import TurnManualUI from './TurnManualUI';

type IPageProps = any;
const TurnOrigin = () => {
    const dispatch = useAppDispatch();
    const [visiblePW, setVisiblePW] = useState(false);
    const [searchText] = useState<string>('');
    const handleClearTurn = () => setVisiblePW(true);
    const handleClose = () => setVisiblePW(false);

    const onVerifyAdminSuccess = () => {
        handleClose();
        dispatch(turnActions.clearAllTurn());
    };

    return (
        <PageStyled id='page_turn'>
            <Sidebar />
            <Container>
                <Content>
                    <StaffCarousel />
                    <TurnTable search={searchText} />
                </Content>
                <HeaderBox>
                    <StatusSearchBox>
                        <Row align={'middle'} justify={'end'}>
                            <StatusBar>
                                <StatusBarItem type='WALK_IN' />
                                <StatusBarItem type='APPOINTMENT' />
                                <StatusBarItem type='BONUS' />
                                <StatusBarItem type='REQUEST' />
                                <StatusBarItem type='IN_PROGRESS' />
                                <StatusBarItem type='FINISH_TURN' />
                                <StatusBarItem type='DONE' />
                                <StatusBarItem type='VOIDED' />
                            </StatusBar>
                            <Row align={'middle'} style={{ gap: '1rem' }}>
                                <Button onClick={handleClearTurn} icon='clearTurn' ntype='YELLOW'>Clear Turn</Button>
                            </Row>
                        </Row>
                    </StatusSearchBox>
                </HeaderBox>
            </Container>
            {visiblePW && (
                <RequestAdminLocalPW
                    onlyAdmin
                    visible
                    onCorrect={onVerifyAdminSuccess}
                    onClose={handleClose}
                />
            )}
            <div className='patent-pending zoom-in-zoom-out' />
        </PageStyled>
    );
};
const TurnPage: React.FC<IPageProps> = () => {
    const allSetting = shopSelectors.data.allSetting();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(turnActions.getTurnStaffsAvailable.fetch({}));
        dispatch(shopActions.get.staffs.fetch());
        dispatch(turnActions.getTurn.fetch({}));
        dispatch(userActions.getWaitingList.fetch());
        // @ts-ignore
        document.body.style.zoom = '100%';
        dispatch(turnManualAPIActions.init());
    }, []);

    if (allSetting?.uiTurnManual) return <TurnManualUI />;
    return <TurnOrigin />;
};

export default TurnPage;

const PageStyled = styled(Layout)`
.zoom-in-zoom-out {
    margin: 24px;
    width: 50px;
    height: 50px;
    background: green;
    animation: zoom-in-zoom-out 2s ease-out infinite;
  }
  
  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1.5, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }
    .patent-pending {
        background: url(${patentPending});
        width: 5rem;
        height: 5rem;
        position: fixed;
        bottom: 60px;
        right: 10px;
        z-index: 99;
        background-size: contain;
        background-repeat: no-repeat;
    }
`;
const Container = styled(Layout)`
    background-color: ${colorTheme.fill_3};
    
    .tb-turn-status--walkin {
    background: #000;
    }
    .tb-turn-status--appointment {
    background: var(--fill-fill-4, #1d2129);
    }
    .tb-turn-status--request {
    background: var(--info-infor-2, #ffdc7c);
    }
    .tb-turn-status--bonus {
    background: var(--orange, #ff7d00);
    }
    .tb-turn-block--in-progress {
    cursor: pointer;
    background: var(--primary-button, #ffe5e7);
    border: 1px solid var(--line-line-3, #f5767f);
    }
    .tb-turn-block--finish {
    cursor: pointer;
    background: var(--blue-headline, #F8D9BB);
    border: 1px solid var(--info-infor-3, #FF7D00);
    }
    .tb-turn-block--voided {
    cursor: pointer;
    background: #FEEDBF;
    border: 1px solid #FDCC43;
    }
    .tb-turn-block--done {
    cursor: pointer;
    background: var(--blue-headline, #c6e9ef);
    border: 1px solid var(--info-infor-3, #6fabb6);
    }

    .tb-turn-block--late {
    background: var(--blue-headline, #86909C);
    border: 1px solid var(--info-infor-3, #adadad);
    }
`;
const HeaderBox = styled.div`
    background: #fff;
    box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);
    flex-wrap: wrap;
    padding: 0.5rem 1.5rem;
    gap: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const Content = styled(Layout.Content)`
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  background: #EFEFEF;
`;

const StatusSearchBox = styled.div`
    display: flex;
    flex:1;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
    >div:last-child{
        width: 100%;
        align-items: center;
        display: flex;
    }
    .square {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    }
    .circle {
    width: 20px;
    height: 20px;
    border-radius: 100px;
    margin-right: 10px;
    }
    
`;

const StatusBar = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
`;
