import PrintBody from 'features/payment/pages/PrintPage/components/PrintBody';
import paymentActions from 'features/payment/services/actions';
import paymentApis from 'features/payment/services/apis';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { debounce } from 'lodash';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopApis from 'services/shop/apis';
import { SHOP_DEVICE_TYPE } from 'services/shop/constants';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import TicketWrapperContext from './TicketWrapperContext';
import CheckInPrinter from './CheckInPrinter';
import CheckInGroupPrinter from './CheckInGroupPrinter';
import VoidTicketPrinter from 'features/ticketList/components/VoidTicketPrint/Printer';
import ReceiptMultipleCardsPayment from 'features/payment/multipleCard/components/Receipt';
import multiCardApis from 'features/payment/multipleCard/services/apis';
import multiCardActions from 'features/payment/multipleCard/services/actions';
import { ICouponPrintData } from 'services/shop/types/coupon';
import { uiLoadingBasicRef } from 'services/UI/LoadingPage/UILoadingRef';
import { IMultiCardResDataItem } from 'features/payment/multipleCard/services/types/card';
import WarningCustomerBanPopup from './WarningCustomerBanPopup';
import { PAYMENT_TYPE } from 'features/payment/services/constants';

function ShopWrapperLayout() {
  const { shop_id = '' } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allSetting = shopSelectors.data.allSetting();
  const setLoadingPage = useSetLoadingPage();
  const divRef = useRef<HTMLDivElement>(null);
  const divOwnerRef = useRef<HTMLDivElement>(null);
  const multiCardReceiptRef = useRef<HTMLDivElement>(null);

  const socket = useSocketContext();
  const initConnectSocket = debounce(() => socket.connect(), 100);
  useEffect(() => {
    dispatch(shopActions.get.shopInfo.fetch(shop_id));

    const isCheckInRoute = location.pathname?.includes(`store/${shop_id}/check-in`) || location.pathname.includes('ticket/qr-payment/customer-side');
    storage.device_type.set(isCheckInRoute ? SHOP_DEVICE_TYPE.CHECK_IN : SHOP_DEVICE_TYPE.POS);

    initConnectSocket();
    return () => {
      socket.unsubscribeAll();
    };
  }, []);

  const doPrint = useReactToPrint({
    content: () => divRef.current,
  });

  const doPrintDoubleReceipt = useReactToPrint({
    content: () => divOwnerRef.current,
    onAfterPrint: () => doPrint(),
  });

  const doMultiCardPrint = useReactToPrint({
    content: () => multiCardReceiptRef.current,
    onAfterPrint: () => {
      dispatch(multiCardActions.setPrintData({ detail: null, coupon: null, cards: [] }));
    }
  });

  const handlePrint = async (billId: string) => {
    setLoadingPage(true);
    try {
      const response = await Promise.all([
        shopApis.getCouponPrint(billId),
        paymentApis.getBillDetail(billId),
      ]);
      const couponPrint: IResponseDataBody<number> = response[0];
      if (couponPrint.data.data) {
        dispatch(shopActions.get.couponPrint.success(couponPrint.data.data));
      }
      const res: IResponseDataBody<IBillDetailData> = response[1];
      if (res.data.data) {
        dispatch(paymentActions.setPrintBillDetailData(res.data.data));
        setTimeout(() => doPrint(), 100);
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const handlePrintWithoutCoupon = async (billId: string) => {
    dispatch(shopActions.resetCouponBillPrint());
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<IBillDetailData> = await paymentApis.getBillDetail(billId);
      if (res.data.data) {
        dispatch(paymentActions.setPrintBillDetailData(res.data.data));
        setTimeout(() => doPrint(), 100);
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const handleCompletedTicket = async (billId: string | null, useLoading?: boolean) => {
    if (!billId) return;

    if (useLoading) setLoadingPage(true);
    try {
      const response = await Promise.all([
        shopApis.getCouponPrint(billId),
        paymentApis.getBillDetail(billId),
      ]);

      const couponPrint: IResponseDataBody<number> = response[0];
      if (couponPrint.data.data) {
        dispatch(shopActions.get.couponPrint.success(couponPrint.data.data));
      }

      const res: IResponseDataBody<IBillDetailData> = response[1];

      if (res.data.data) {
        dispatch(paymentActions.getPrintBillDetail.success(res.data.data));
        setTimeout(() => {
          if (allSetting?.printMultiplePrint) {
            const isCreditCard = res.data.data.payments?.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
            if (isCreditCard && !res.data.data.signature) return doPrintDoubleReceipt();
          }
          doPrint();
        }, 100);

        if (allSetting?.confirmSendSmsOrEmail)
          navigate(`/store/${shop_id}/ticket/pay/${billId}/completed`, { replace: true });
        else {
          navigate(`/store/${shop_id}`);
        }
      }
    } catch (error) { } finally {
      if (useLoading) setLoadingPage(false);
    }
  };

  const handlePrintMultiCards = async (billId: string) => {
    if (!billId) return;
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const response = await Promise.all([
        multiCardApis.getTransactionDetail(billId),
        multiCardApis.getListMultipleCards(billId),
      ]);

      const res: IResponseDataBody<IBillDetailData> = response[0];
      const resCards: IResponseDataBody<IMultiCardResDataItem[]> = response[1];

      dispatch(multiCardActions.setPrintData({ detail: res.data.data || null, coupon: null, cards: resCards.data.data || [] }));

      if (res.data.data) {
        setTimeout(() => doMultiCardPrint(), 100);
      }
    } catch (error) { } finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };

  const handleCompletedMultiCardsPayment = async (billId: string | null) => {
    if (!billId) return;
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const response = await Promise.all([
        shopApis.getCouponPrint(billId),
        multiCardApis.getTransactionDetail(billId),
        multiCardApis.getListMultipleCards(billId),
      ]);

      const couponPrint: IResponseDataBody<ICouponPrintData> = response[0];
      const res: IResponseDataBody<IBillDetailData> = response[1];
      const resCards: IResponseDataBody<IMultiCardResDataItem[]> = response[2];

      dispatch(multiCardActions.setPrintData({ detail: res.data.data || null, coupon: couponPrint.data.data || null, cards: resCards.data.data || [] }));

      if (res.data.data) {
        setTimeout(() => doMultiCardPrint(), 100);
        navigate(`/store/${shop_id}`);
      }
    } catch (error) { } finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };

  return (
    <TicketWrapperContext value={{
      print: handlePrint,
      printWithoutCoupon: handlePrintWithoutCoupon,
      completedTicket: handleCompletedTicket,
      printMultiCards: handlePrintMultiCards,
      completedMultiCards: handleCompletedMultiCardsPayment,
    }}>
      <Outlet />
      <div style={{ display: 'none' }}><PrintBody billRef={divRef} isPrinter /></div>
      <div style={{ display: 'none' }}><PrintBody billRef={divOwnerRef} isPrinter owner /></div>
      <div style={{ display: 'none' }}><ReceiptMultipleCardsPayment billRef={multiCardReceiptRef} isPrinter /></div>
      <TimeOutRefresh />
      <CheckInPrinter />
      <CheckInGroupPrinter />
      <VoidTicketPrinter />
      <WarningCustomerBanPopup />
    </TicketWrapperContext>
  );
}

export default ShopWrapperLayout;

const TimeOutRefresh = () => {
  const handleReload = () => {
    const now = moment();
    const startTime = moment().set({ hour: 9, minute: 0, second: 0, });
    const endTime = moment().set({ hour: 21, minute: 0, second: 0, });

    if (now.isBetween(startTime, endTime)) return;

    if (storage.device_type.get() === SHOP_DEVICE_TYPE.CHECK_IN) {
      window.location.href = `${location.origin}/store/${storage.shop_id.get()}/check-in/sign-in`;
    } else {
      window.location.href = `${location.origin}/store/${storage.shop_id.get()}`;
    }
  };

  useEffect(() => {
    const time = setInterval(() => {
      handleReload();
    }, 7200000);
    return () => {
      clearInterval(time);
    };
  }, []);

  return null;
};