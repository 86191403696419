import { Form } from 'antd';
import { ICalendarItemData } from 'features/appointment/services/types/calendar';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import CustomerInfo from '../AppointmentDetail/v2/CustomerInfo';
import ServicesStaff from '../AppointmentDetail/v2/ServicesStaff';
import { IAppointmentServiceItem } from 'features/appointment/services/types/appointment';
import next_icon from './next.svg';

type Props = {
  value?: {
    data: ICalendarItemData,
    customer: {
      name: string | null;
      phone: string | null;
      point: string;
    },
    services: IAppointmentServiceItem[];
    detailData: ICalendarItemData;
  };
  staffs: IStaffItemData[];
  name: any;
  onDetail: (detailData: ICalendarItemData) => void;
};
const Appointment = ({ name, onDetail, value }: Props) => {
  return (
    <Container>
      <Form.Item name={[name, 'customer']} noStyle><CustomerInfo nameValue={name} /></Form.Item>
      <ServicesStaff viewOnly name={[name, 'services']} onAddService={() => undefined} onChangeStaff={() => undefined} />
      <ButtonShowDetailStyled>
        <button type='button' onClick={() => value?.detailData && onDetail(value?.detailData)}>
          <span>Show All</span>
          <img src={next_icon} alt="next_icon" style={{ width: 32, height: 32 }} />
        </button>
      </ButtonShowDetailStyled>
    </Container>
  );
};

export default Appointment;
const Container = styled.div`
  align-self: stretch;
  background: #FFF;
  box-shadow: -4px 0px 8px 0px rgba(0, 0, 0, 0.15);

  .CustomerInfo {
    border-bottom: 1px solid #86909C;
    background: #EEF4FF;
  }
`;

const ButtonShowDetailStyled = styled.div`
display: flex;
padding: 16px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
button {
  display: flex;
  height: 48px;
  padding: 16px 24px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex: 1 0 0;
  border-radius: 4px;
  span {
    color: #FF6A30;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
`;