import { Button, Col, Row } from 'antd';
import Empty from 'components/Empty';
import Text from 'components/Text';
import { useState } from 'react';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import Categories from './Categories';
type Props = {
  selected: IServiceItemData[];
  onAddService: (sle: IServiceItemData) => void;
  onRemoveService: (sle: IServiceItemData) => void;
  single?: boolean;
};
const ServicePicker = ({ selected, onAddService, onRemoveService }: Props) => {
  const [activeCategory, setActiveCategory] = useState<ICategoryItemData | null>(null);
  return (
    <ServicesTabStyled gutter={[24, 24]} wrap={false}>
      <Col span={8}>
        <Categories selectedServices={selected} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />
      </Col>
      <Col span={16} >
        <Text mb={1} className='section-label'>Services</Text>
        <div className='services'>
          <Row gutter={[16, 16]} style={{ marginLeft: 0, marginRight: 0 }}>
            {activeCategory?.services.length ? activeCategory?.services.map(o => (
              <Col key={o.id} span={8}>
                <ServiceItem
                  amount={selected.filter(s => s.id === o.id)?.length || 0}
                  onMinus={() => onRemoveService(o)}
                  onPlus={() => onAddService(o)}
                  name={o.serviceName}
                  price={o.priceSell}
                />
              </Col>
            )) : <Empty />}
          </Row>
        </div>
      </Col>
    </ServicesTabStyled>
  );
};

export default ServicePicker;

type ServicesTabStyledProps = {}
const ServicesTabStyled = styled(Row) <ServicesTabStyledProps>`
  .arrow-btn {
    width: 2.5rem;
    height: 2.5rem;
  }
  .services {
    max-height: 25rem;
    min-height: 25rem;
    overflow-y: auto;
    margin-right: -8px;
    margin-left: -8px;
  }
`;
type ServiceItemProps = {
  amount?: number;
  name?: string;
  price?: number;
  onPlus: () => void;
  onMinus: () => void;
};
const ServiceItem = ({ amount, price = 0, name = '', onPlus, onMinus }: ServiceItemProps) => {
  return (
    <ServiceItemStyled onClick={onPlus}>
      <div className='content'>
        <Text variant='BODY_1' className='service-name text-overflow'>{name}</Text>
        <Row justify={'space-between'} style={{ width: '100%', margin: 0 }}>
          <Text variant='BODY_1' className='service-price' style={{ fontWeight: '600' }}>{formatCurrency(price)}</Text>
          <Row align={'middle'} className='amount-box'>
            <Button size='middle' onClick={e => [e.stopPropagation(), e.preventDefault(), onMinus()]}>-</Button>
            <Text variant='BODY_1' className='service-price service-amount' style={{ fontWeight: '600' }}>{amount}</Text>
            <Button size='middle' onClick={e => [e.stopPropagation(), e.preventDefault(), onPlus()]}>+</Button>
          </Row>
        </Row>
      </div>
    </ServiceItemStyled>
  );
};

const ServiceItemStyled = styled(Button)`
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-3, #E5E5E5);
  padding: 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
  text-align: left !important;
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100%;
    .service-name {
      font-size: 1.25rem;
    }
    .service-price {
      font-size: 1.25rem;
    }
    .amount-box {
      button {
        width: 35px;
        padding: 0;
        height: 32px;
        font-size: 20px;
        line-height: 1;
        font-weight: 600;
        display: flex;
        justify-content: center;
        align-items: center;
        span {
          display: flex;
        }
      }
    }
    .service-amount {
      width: 35px;
      display: flex;
      justify-content: center;
    }
  }
`;
