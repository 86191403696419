import clsx from 'clsx';
import Box from 'components/Box';
import Text from 'components/Text';
import { IBillDetailData, IBillItemData } from 'features/payment/services/types/bill';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
type Props = {
  data: IBillDetailData | null
  v2?: boolean;
};
const DetailItems = ({ data, v2 }: Props) => {
  const Items = () => {
    return <>
      {data?.items.map(o => (
        <StaffItem key={o.staffId} data={o} />
      ))}
    </>;
  };
  return (
    <Container display="flex" flexDirection="column" gap="1" px="4" pt="4" className={clsx(v2 && 'v2')}>
      <div className='ticket-info'>
        <div className="ticket-number"><p>#{data?.ticketNumber}</p></div>
        <div>
          <p className='bold-date'>{momentTimezone(data?.startTime)?.format('MM-DD-YYYY')}</p>
          <p className='bold-date'>{momentTimezone(data?.startTime)?.format('LT')}</p>
        </div>
      </div>
      <Items />
    </Container>
  );
};

export default DetailItems;

const Container = styled(Box)`
.ticket-info {
  display:flex;
  gap: 8px;
  white-space: nowrap;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  .bold-date {
    font-weight: 600;
    font-size: 18px;
  }
}
.ticket-number {
  white-space: nowrap;
  border-radius: 5px;
  background: #1D2129;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0 0.5rem;
  margin-bottom: 1rem;

  p {
    color: var(--text-text-1, #FFF);
    text-align: center;
    /* Title/H5 - 24 semibold */
    font-family: Open Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

&.v2 {
  max-height: 175px;
  overflow: auto;
  .ticket-info {
    display: none;
  }
  .StaffItemStyled {
    .staff-name {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .ServiceItemStyled {
      .label, .value {
        font-size: 18px;
      }
    }
  }
}
`;

const StaffItem = ({ data }: { data: IBillItemData }) => {
  return (
    <StaffItemStyled className='StaffItemStyled'>
      <Text mb={0.25} className='staff-name' style={{ fontWeight: 600 }} >{data.staffName}</Text>
      <Text mb={0.5} className="divider" />
      {data.services.map((o, i) => (
        <ServiceItemStyled key={i} className='ServiceItemStyled'>
          <div className="label">{o.itemName}</div>
          {!!o.discount && <div className="value discount">{ }</div>}
          <div className="value">{formatCurrency(o.price)}</div>
        </ServiceItemStyled>
      ))}
    </StaffItemStyled>
  );
};
const StaffItemStyled = styled.div`
.staff-name {
  color: var(--text-text-3, #1D2129);
  font-family: Open Sans;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;

const ServiceItemStyled = styled.div`
  display:flex;
  margin-bottom: 0.25rem;
  .label {
    flex:1;
    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .value {
    color: var(--text-text-3, #1D2129);
    text-align: right;
    font-family: Open Sans;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    &.discount {
      text-decoration-line: strikethrough;
    }
  }
`;