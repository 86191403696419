import { Input, InputProps } from 'antd';
import styled from 'styled-components';
interface Props extends InputProps { }
const InputPin = (props: Props) => {
  return (
    <InputStyled>
      <Input
        {...props}
        placeholder="Password"
        maxLength={4}
        // @ts-ignore
        onKeyPress={(event) => {
          if (!/[0-9]/.test(event.key)) {
            event.preventDefault();
          }
        }}
        max={4}
      />
    </InputStyled>
  );
};

export default InputPin;

const InputStyled = styled.div`
input.ant-input {
  height: 3rem;
}
  
`;