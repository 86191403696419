import React from 'react';
import styled from 'styled-components';
import Button from 'components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import IconLogoAuthLogin from 'assets/Icons/logoAuthLogin';
type IHeaderProps = {};
const Header: React.FC<IHeaderProps> = () => {
  const navigate = useNavigate();
  const { shop_id = '' } = useParams();
  
  const goBack = () => {
    navigate(`/store/${shop_id}/check-in/sign-in`);
  };

  return (
    <HeaderStyled className="ant-layout-header-cus">
      <IconLogoAuthLogin />
      {/* <Button ntype="DEFAULT" onClick={goCheckIn}>{`Done (${count}s)`}</Button> */}
      <Button ntype="DEFAULT" onClick={goBack}>{'Back'}</Button>
    </HeaderStyled>
  );
};

export default Header;
const HeaderStyled = styled.div`
  position: fixed;
  top:0;
  left:0;
  right:0;
  padding: 1.5rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .logo {
    height:80%;
    width:auto;
  }
`;
