import { DatePicker as AntDatePickerUI } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { RangePickerDateProps } from 'antd/es/date-picker/generatePicker';
import Text from 'components/Text';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import isEqual from 'lodash/isEqual';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import calendar_icon from '../../../icons/calendar.svg';

const AntDatePicker = AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);
const { RangePicker } = AntDatePicker;
type IRangePickerProps = RangePickerDateProps<Moment> & {
    actions: any,
    selectors: any,
    type: string
    size?: SizeType
};
const WeeklyPicker = ({ onSubmit, defaultValue, v2 }: { onSubmit: (values: RangeValue<Moment>) => void, defaultValue?: RangeValue<Moment>, v2?: boolean; }) => {
    const [values, setValues] = useState<RangeValue<Moment>>([defaultValue?.[0] || moment().startOf('weeks'), defaultValue?.[1] || moment().endOf('weeks')]);
    const [isOpen, setIsOpen] = useState(false);
    const preValues = useRef<RangeValue<Moment>>([moment().startOf('weeks'), moment().endOf('weeks')]);
    useEffect(() => {
        if (!isOpen && values && values[0] && values[1]) {
            if (!isEqual(values[0].format(DATE_FORMAT), preValues.current?.[0]?.format(DATE_FORMAT))
                || !isEqual(values[1].format(DATE_FORMAT), preValues.current?.[1]?.format(DATE_FORMAT))) {
                preValues.current = values;
                onSubmit(values);
            }
        }
    }, [values, isOpen]);

    const onChange: IRangePickerProps['onChange'] = (values) => {
        setValues(values);
    };

    const onOpenChange = (open: boolean) => {
        setIsOpen(open);
    };

    return (
        <Row className={v2 ? 'v2' : ''}>
            {!v2 && <Label variant='CONTENT_2' color='text_2' >Time Period:</Label>}
            <RangePicker
                panelRender={panelRender}
                onChange={onChange}
                value={values}
                onOpenChange={onOpenChange}
                format={DATE_FORMAT}
                bordered={false}
                popupClassName={v2 ? 'zoom-big-v2' : ''}
                separator={v2 ? <>-</> : undefined}
                clearIcon={v2 ? null : undefined}
                suffixIcon={v2 ? <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} /> : undefined}
            />
        </Row>
    );
};

export default WeeklyPicker;
const Label = styled(Text)`
    display: flex;
    flex:1;
    align-items: center;
    padding-left: 1rem;
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-right: 0.5rem;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    box-sizing: border-box;
    margin: 0;
    background: #ffffff;
    border: 1px solid #86909C;
    border-radius: 5px;
    transition: border 0.2s,box-shadow 0.2s;
    .ant-picker{
        width: unset !important;
        padding: 2px 10px 2px 0px;
    }
    .ant-picker .ant-picker-input {
    position: unset !important;
    display: unset !important;
    align-items: unset !important;
    width: unset !important;
    }
    .ant-picker .ant-picker-input input {
        color: #1D2129 ;
        text-align: right;
        width: 6.5rem;
        font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .ant-picker-focused .ant-picker-active-bar {
        display: none;
    }
    .ant-picker-focused .ant-picker-input-placeholder input {
        color: #acacac !important;
    }
    .ant-picker .ant-picker-suffix {
        padding-left: 1rem;
    }

    &.v2 {
        min-width: unset;
        border: none;
        background: transparent;
        .ant-picker-suffix {
            margin: 0;
            padding: 0;
        }
        .ant-picker-range-separator {
            color: #1D2129;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;
        }
        .ant-picker-input input {
            width: 10rem;
            color: #1D2129;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-align: center;
        }
    }
`;
//@ts-ignore
const panelRender = (panelNode) => (
    <StyleWrapperDatePicker>
        {panelNode}
    </StyleWrapperDatePicker>
);
const StyleWrapperDatePicker = styled.div`
    .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }
  
  .ant-picker-panels > *:last-child {
    display: none;
  }
  
  .ant-picker-panel-container, .ant-picker-footer {
    width: 280px !important;
  }
  
  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important; 
  }
  `;
