import { IModalConfirmRef } from 'components/Modal/ModalConfirm';
import { IAddNewResData } from 'features/cashier/pages/AddTicketPage';
import cashierApis from 'features/cashier/services/apis';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopSelectors from 'services/shop/selectors';
import storage from 'utils/sessionStorage';

const useAddNewTicket = (modal: React.RefObject<IModalConfirmRef>) => {
  const allSetting = shopSelectors.data.allSetting();
  const setLoadingPage = useSetLoadingPage();
  const navigate = useNavigate();
  const generateTicket = async () => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<IAddNewResData> = await cashierApis.addTicket();
      const data = res.data.data;
      if (data) {
        navigate(`/store/${storage.shop_id.get()}/ticket/edit/${data.ticketInfo.billId}`);
      } else {
        throw 'fail';
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return async () => {
    if (allSetting?.skipConfirmNewTicket) return generateTicket();

    setLoadingPage(true);
    try {
      const res: IResponseDataBody<number> = await cashierApis.getNumberTicket();
      const data = res.data.data;
      if (data) {
        modal.current?.open(`Do you like to create the ticket #${data}`, () => generateTicket());
      } else {
        throw 'fail';
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };
};

export default useAddNewTicket;
