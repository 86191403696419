import { IStaffPayload } from 'features/checkIn/services/types/demoData';
import { createAction, createAsyncAction } from 'services/actionConfigs';
import { IStaffItemData } from 'services/shop/types/staff';
import { PREFIX_ACTIONS } from './constants';
import {
  IServiceItem,
  IStaffDayOffParams,
  IStaffsParams,
  IStaffUpdatePinBodyType,
  IUpdateStaffAvatar,
} from './types/api';
import { ISalaryConfig } from './types/staff';

const cateSelected = createAction<string>(PREFIX_ACTIONS + 'cateSelected');
const clickService = createAction<IServiceItem>(
  PREFIX_ACTIONS + 'clickService'
);
const getSelectedStaffInfo = createAsyncAction<string>(
  PREFIX_ACTIONS + 'getSelectedStaffInfo'
);
const getSelectedStaffSalary = createAsyncAction<string>(
  PREFIX_ACTIONS + 'getSelectedStaffSalary'
);
const getSelectedStaffServices = createAsyncAction<string>(
  PREFIX_ACTIONS + 'getSelectedStaffServices'
);
const createOrUpdateStaff = createAsyncAction<IStaffPayload>(
  PREFIX_ACTIONS + 'selectedStaff'
);
const getStaffList = createAsyncAction<IStaffsParams>(
  PREFIX_ACTIONS + 'getStaffList'
);
const staffUpdatePin = createAsyncAction<IStaffUpdatePinBodyType>(
  PREFIX_ACTIONS + 'staffUpdatePin'
);
const deleteStaff = createAction<string>(
  PREFIX_ACTIONS + 'deleteStaff'
);
const staffUpdateSalary = createAsyncAction<ISalaryConfig>(
  PREFIX_ACTIONS + 'staffUpdateSalary'
);
const staffUpdateMappingService = createAsyncAction<IStaffUpdatePinBodyType>(
  PREFIX_ACTIONS + 'staffUpdateMappingService'
);
const staffScheduler = createAsyncAction<string>(
  PREFIX_ACTIONS + 'staffScheduler'
);
const getStaffDayOff = createAsyncAction<IStaffDayOffParams>(
  PREFIX_ACTIONS + 'getStaffDayOff'
);
const createStaffDayOff = createAsyncAction<IStaffDayOffParams>(
  PREFIX_ACTIONS + 'createStaffDayOff'
);
const deleteStaffDayOff = createAsyncAction<string>(
  PREFIX_ACTIONS + 'deleteStaffDayOff'
);
const setStaffDayOffParams = createAction<IStaffDayOffParams>(
  PREFIX_ACTIONS + 'setStaffDayOffParams'
);
const updateStaffScheduler = createAsyncAction<IStaffDayOffParams>(
  PREFIX_ACTIONS + 'updateStaffScheduler'
);
const updateStaffAvatar = createAsyncAction<IUpdateStaffAvatar>(
  PREFIX_ACTIONS + 'updateStaffAvatar'
);

const setParams = createAction<IStaffsParams>(PREFIX_ACTIONS + 'setParams');
const searchStaff = createAction<IStaffItemData[]>(
  PREFIX_ACTIONS + 'searchStaff'
);

const doInit = createAction(PREFIX_ACTIONS + 'init');

const settingStaffActions = {
  doInit,
  cateSelected,
  deleteStaff,
  clickService,
  getSelectedStaffInfo,
  createOrUpdateStaff,
  getStaffList,
  setParams,
  getSelectedStaffSalary,
  staffUpdatePin,
  staffUpdateSalary,
  staffScheduler,
  getSelectedStaffServices,
  staffUpdateMappingService,
  getStaffDayOff,
  createStaffDayOff,
  deleteStaffDayOff,
  setStaffDayOffParams,
  updateStaffScheduler,
  updateStaffAvatar,
  searchStaff,
};

export default settingStaffActions;
