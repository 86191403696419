import { colorSetCodes, TURN_SORT_BY, turnCount } from 'features/turn/services/constants';
import { turnManualSelectors } from 'features/turn/services/selectors';
import { get, sortBy } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { getStaffNextTurn } from '../StaffTurns/helpers';
import { IStaffTurnItem } from './TurnStaffTopRow';
import TurnTableData from './TurnTableData';
import { ITurnManualSettingColorItem } from 'features/turn/services/types/turn';

const TurnTable = () => {
  const mappingStaffs = turnManualSelectors.mappingStaffs();
  const sort = turnManualSelectors.sort();

  const staffTurnData: IStaffTurnItem[] = useMemo(() => {
    if (sort === TURN_SORT_BY.CLOCK_IN) return sortBy(mappingStaffs, 'indexClockIn');
    return sortBy(mappingStaffs, 'totalTurn');
  }, [sort, mappingStaffs]);

  const staffNextTurn = useMemo(() => getStaffNextTurn(mappingStaffs), [mappingStaffs]);
  const turnSettingColors = turnManualSelectors.settingColors();

  return (
    <Container turnSettingColors={turnSettingColors}>
      <table>
        <tbody>
          <tr>
            <td className='staff-top-row-sticky' style={{ padding: '0', background: '#fff' }}></td>
            <td colSpan={turnCount + 1} style={{ padding: '4px 0', background: '#fff' }}></td>
          </tr>
          <TurnTableData staffs={staffTurnData} nextStaffId={staffNextTurn?.staffId || ''} />
        </tbody>
      </table>
    </Container>
  );
};

export default TurnTable;

// const TurnNumbers = () => <>{Array.from(Array(turnCount).keys()).map(o => (<TurnHeadItem key={o} number={o + 1} />))}</>;

const Container = styled.div<{ turnSettingColors: ITurnManualSettingColorItem[] }>`
@keyframes zoomNextStaff {
  0% {
    transform: scale(.8);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}

  table {
    border-collapse: collapse;
    width: 100%;
    thead {
      th {
        background: #F0F0F0;
        position: sticky;
        top: 0px;
        padding-top: 16px;
        z-index: 3;
        &.staff-top-row-sticky {
          z-index: 4;
          top: 0px;
          left: 0px;
        }
        &.top-header-row-sticky {
          z-index: 4;
          top: 0px;
          left: 240px;
        }
      }
    }

    tbody {
      .TurnBodyItem .box-item {
        ${
          ({turnSettingColors})=>turnSettingColors.map(o=>{
            const theme = get(colorSetCodes, o?.color || '');
            if(o.turnStatus === 'NEXT')
              return `&.${o.turnStatus} {
                background: ${theme.bg};
                border: 1px solid ${theme.stroke};
                box-shadow: 4px 4px 0px 0px ${theme.shadow};
                .container-full {
                  background: ${theme.bg};
                }
              
              }`;
            return `&.${o.turnStatus} {
              background: ${theme.bg};
              border: 1px solid ${theme.stroke};
              box-shadow: 4px 4px 0px 0px ${theme.shadow};
            }`;
          })
        }
      }
      td {
        background: #fff;

      }
      td.staff-top-row-sticky {
        position: sticky;
        left: 0px;
        background: #fff;
        z-index: 2;
        transform: translateX(-2px);

        &.active {
          background: #C6E9EF;
        }
      }
    }
  }
`;
