
import { QRCode } from 'antd';
import Text from 'components/Text';
import { CUSTOMER_VIEW_DOMAIN } from 'services/request';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';

const QRCheckInOnline = () => {
  const checkInLink = shopSelectors.data.checkInOnlineLink();

  return (
    <ModelBoxStyled>
      <div className="border-qr">
        <QRCode
          size={300}
          value={checkInLink || CUSTOMER_VIEW_DOMAIN}
          bordered={false}
        />
      </div>
      <Text variant='CONTENT_2' textAlign='center' mt={1} style={{ fontSize: '18px' }}>MOBILE CHECK IN</Text>
    </ModelBoxStyled>
  );
};

export default QRCheckInOnline;

const ModelBoxStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .border-qr {
    border: 5px solid #3F8DBF;
    border-radius: 10px;
  }
`;
