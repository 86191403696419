import { createAction, createAsyncAction } from 'services/actionConfigs';
import { EMainLayout, PREFIX_ACTIONS } from './constants';
import { IApiHistoryClockInOut, IFilterDataParams } from './types/api';

const doFilterData = createAction<IFilterDataParams>(PREFIX_ACTIONS + 'doFilterData');

const getWaitingList = createAsyncAction(PREFIX_ACTIONS + 'getWaitingList');

const getSummaryData = createAsyncAction<IFilterDataParams>(PREFIX_ACTIONS + 'getSummaryData');

const getBillWaitingTicket = createAsyncAction(PREFIX_ACTIONS + 'getBillWaitingTicket');

const doClockIn = createAsyncAction<string | number>(PREFIX_ACTIONS + 'doClockIn');

const doClockOut = createAsyncAction<string | number>(PREFIX_ACTIONS + 'doClockOut');

const getListStaffAvailable = createAsyncAction(PREFIX_ACTIONS + 'getListStaffAvailable');

const getHistoryClockInOut = createAsyncAction<IApiHistoryClockInOut>(PREFIX_ACTIONS + 'getHistoryClockInOut');
const setHistoryClockInOutParams = createAction<IApiHistoryClockInOut>(PREFIX_ACTIONS + 'setHistoryClockInOutParams');

const activeRemoveTicket = createAction<boolean>(PREFIX_ACTIONS + 'activeRemoveTicket');

const activeRemoveWaiting = createAction<boolean>(PREFIX_ACTIONS + 'activeRemoveWaiting');

const removePendingTicket = createAction<string>(PREFIX_ACTIONS + 'removePendingTicket');

const removeWaitingTicket = createAction<string>(PREFIX_ACTIONS + 'removeWaitingTicket');

const getListAvailableWorking = createAsyncAction<boolean | undefined>(PREFIX_ACTIONS + 'getListAvailableWorking');

const changeHomeLayout = createAction<EMainLayout>(PREFIX_ACTIONS + 'changeHomeLayout');

const refreshMergeTicket = createAction(PREFIX_ACTIONS + 'refreshMergeTicket');
const activeMergeTicket = createAction<boolean>(PREFIX_ACTIONS + 'enableMergeTicket');
const selectMergeTicket = createAction<string>(PREFIX_ACTIONS + 'selectMergeTicket');

const syncWaitingToTicket = createAction<{ staffId: string, checkInId: string }>(PREFIX_ACTIONS + 'syncWaitingToTicket');

const getAppointmentToday = createAsyncAction(PREFIX_ACTIONS + 'getAppointmentToday');

const userActions = {
  doFilterData,
  getWaitingList,
  getSummaryData,
  getBillWaitingTicket,
  doClockIn,
  doClockOut,
  getListStaffAvailable,
  getHistoryClockInOut,
  setHistoryClockInOutParams,
  activeRemoveTicket,
  activeRemoveWaiting,
  removePendingTicket,
  removeWaitingTicket,
  getListAvailableWorking,
  changeHomeLayout,
  refreshMergeTicket,
  activeMergeTicket,
  selectMergeTicket,
  syncWaitingToTicket,
  getAppointmentToday,
};

export default userActions;

