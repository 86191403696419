import { Col, Row } from 'antd';
import Icon from 'assets/Icons';
import CategoryItem from 'components/Category';
import Spin from 'components/Spin';
import { ICategory } from 'features/cashier/services/types/api';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const Categories = () => {
  const dispatch = useAppDispatch();
  const loading = shopSelectors.loading.categories();
  const categories = checkInSelectors.categories();

  const activeCategory = checkInSelectors.getActiveCategory();
  const selectedServices = checkInSelectors.getSelectedServices();
  const handleClick = (item: ICategory) => {
    if (activeCategory?.id === item.id) return;
    dispatch(checkInActions.setActiveCategory(item));
  };

  if (loading) {
    return <Spin />;
  }

  return (
    <Wrapper gutter={[16, 16]}>
      <SimpleMenu />
      {categories.map(item => (
        <Col key={item.id} span={12}>
          <CategoryItem
            onClick={() => handleClick(item)}
            selected={!!item.services.some(s => !!selectedServices.find(o => o.catId !== 'simple-menu' && o.id === s.id))}
            isActive={item.id === activeCategory?.id}
          >
            {item.categoryName}
          </CategoryItem>
        </Col>
      ))}
    </Wrapper>

  );
};

export default Categories;

const Wrapper = styled(Row)`
  max-height: 100vh;
`;
const SimpleMenu = () => {
  const dispatch = useAppDispatch();
  const enableCheckIn = shopSelectors.data.simpleMenuConfigCheckIn();
  const activeSimpleMenu = checkInSelectors.activeSimpleMenu();

  const onClickSimpleMenu = () => {
    dispatch(checkInActions.activeSimpleMenu(!activeSimpleMenu));
  };

  if (!enableCheckIn) return null;

  return (
    <Col span={24}>
      <SimpleMenuStyled onClick={onClickSimpleMenu} className={activeSimpleMenu ? 'active' : ''} >
        <span>Simple menu</span>
        {activeSimpleMenu && (
          <IconBadge><Icon type="tick" /></IconBadge>
        )}
      </SimpleMenuStyled>
    </Col>
  );
};
const SimpleMenuStyled = styled.div`
display: flex;
height: 123px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 5px;
border: 1px solid #6FABB6;
background: #C6E9EF;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset;
&:hover {
  cursor: pointer;
}
  span {
    color: #1D2129;
    text-align: center;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
  }

  &.active {
    border-radius: 5px;
    border: 1px solid #6FABB6;
    background: #6FABB6;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset;
    span {
      color: #fff;
    }
  }
`;

const IconBadge = styled.div`
  position: absolute;
  top: -1rem;
  right: -0.25rem;
`;