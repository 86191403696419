import { Form } from 'antd';
import React from 'react';
import styled from 'styled-components';
import TurnServiceItem from './TurnServiceItem';
import ModalEditTurnServiceName, { useModalEditTurnServiceNameRef } from './ModalEditName';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import { ITurnServiceItem } from 'features/turn/services/types/service';
import { useAppDispatch } from 'store/hooks';
import { turnManualAPIActions } from 'features/turn/services/actions';
type Props = {
  onClose: () => void;
};
const TurnServiceList = ({ onClose }: Props) => {
  const dispatch = useAppDispatch();
  const modalConfirm = useModalConfirmRef();
  const editNameRef = useModalEditTurnServiceNameRef();
  const handleEditName = (serviceName: string, onChange: (value: string) => void) => {
    editNameRef.current?.open(serviceName, onChange);
  };
  const handleDelete = (name: any, remove: (name: any) => void) => (value?: ITurnServiceItem) => {
    if (!value?.itemId) {
      remove(name);
      return;
    }
    modalConfirm.current?.open('Do you like to remove this item?', () => dispatch(turnManualAPIActions.deleteTurnServiceItem(value?.itemId || '')));
  };

  return (
    <>
      <Wrapper>
        <ContentContainer>
          <div className="scroll-div">
            <Form.List name={'turns'}>
              {(fields, { remove }) => <Container>
                {fields.map((o) => <Form.Item noStyle name={o.name} key={o.key}>
                  <TurnServiceItem onEditName={handleEditName} onRemove={handleDelete(o.name, remove)} name={o.name} />
                </Form.Item>)}
              </Container>}
            </Form.List>
          </div>
        </ContentContainer>
        <Footer>
          <button onClick={onClose} type='button' className="btn btn-close"><span>Close</span></button>
          <button type='submit' className="btn btn-submit"><span>Update</span></button>
        </Footer>
      </Wrapper>
      <ModalEditTurnServiceName ref={editNameRef} />
      <ModalConfirm ref={modalConfirm} />
    </>
  );
};

export default TurnServiceList;
const ContentContainer = styled.div`
  flex: 1;
  position: relative;
`;
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 24px;
  padding: 16px 24px;
`;

const Footer = styled.div`
display: flex;
padding: 24px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.25);
button.btn {
  display: flex;
  height: 56px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #86909C;
  background: #E5E6EB;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: uppercase;
  }

  &.btn-delete {
    border: 1px solid #F53F3F;
    background: #FFF;
    span {
      color: #F53F3F;
    }
  }
  &.btn-add-turn {
    border: 1px solid #2D6772;
    background: #FFF;
    span {
      color: #2D6772;
    }
  }
  &.btn-submit {
    border-radius: 5px;
    background: #C52884;
    border: 1px solid #C52884;
    span {
      color: #fff;
    }
  }
}
`;
