import React from 'react';

const CallStaff = () => {
  return (
    <div className='CallStaffIcon' style={{ width: 60, height: 60, border: '2px solid #6FABB6', borderRadius: 60 }}>
      <svg style={{ width: '100%', height: '100%' }} width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10703_3613)">
          <path d="M18.2876 10.75C19.0201 10.8929 19.6934 11.2512 20.2211 11.779C20.7489 12.3067 21.1071 12.9799 21.2501 13.7125M18.2876 7.75C19.8095 7.91908 21.2288 8.60063 22.3123 9.68276C23.3957 10.7649 24.0791 12.1833 24.2501 13.705M23.5001 19.69V21.94C23.5009 22.1489 23.4581 22.3556 23.3745 22.547C23.2908 22.7384 23.168 22.9102 23.0141 23.0514C22.8602 23.1926 22.6785 23.3001 22.4806 23.367C22.2828 23.4339 22.0731 23.4588 21.8651 23.44C19.5572 23.1892 17.3403 22.4006 15.3926 21.1375C13.5804 19.986 12.0441 18.4496 10.8926 16.6375C9.62506 14.6809 8.83625 12.4532 8.59007 10.135C8.57133 9.9276 8.59598 9.71857 8.66245 9.52122C8.72892 9.32387 8.83575 9.14252 8.97615 8.98872C9.11654 8.83491 9.28743 8.71203 9.47792 8.62789C9.6684 8.54375 9.87433 8.5002 10.0826 8.5H12.3326C12.6966 8.49642 13.0494 8.62531 13.3254 8.86265C13.6014 9.09999 13.7816 9.42959 13.8326 9.79C13.9275 10.51 14.1037 11.217 14.3576 11.8975C14.4585 12.1659 14.4803 12.4577 14.4205 12.7382C14.3607 13.0186 14.2217 13.2761 14.0201 13.48L13.0676 14.4325C14.1352 16.3102 15.6899 17.8648 17.5676 18.9325L18.5201 17.98C18.724 17.7784 18.9814 17.6394 19.2619 17.5796C19.5424 17.5198 19.8341 17.5416 20.1026 17.6425C20.783 17.8964 21.49 18.0725 22.2101 18.1675C22.5744 18.2189 22.9071 18.4024 23.145 18.6831C23.3828 18.9638 23.5092 19.3222 23.5001 19.69Z" stroke="#6FABB6" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </g>
        <defs>
          <clipPath id="clip0_10703_3613">
            <rect width="18" height="18" fill="white" transform="translate(7 7)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default CallStaff;
