import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { IEditStaffPayload } from 'features/ticketList/services/types/api';
import React, { useState } from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import SwitchStaff from '../SwitchStaff';

type IEditStaffButtonProps = {
  staffId: string;
};
const EditStaffButton: React.FC<IEditStaffButtonProps> = ({ staffId }) => {
  const details = ticketListSelectors.ticketDetailsData.data();
  const dispatch = useAppDispatch();
  const [pinCode, setPinCode] = useState<string>('');
  const [visiblePW, setVisiblePW] = useState(false);
  const [openStaff, setOpenStaff] = useState(false);

  const handleShowModal = () => {
    setVisiblePW(true);
  };

  const handleClose = () => setVisiblePW(false);

  const onVerifyAdminSuccess = (pinCode?: string) => {
    setPinCode(pinCode || '');
    setVisiblePW(false);
    setOpenStaff(true);
  };

  const handleCloseStaffModal = () => {
    setPinCode('');
    setOpenStaff(false);
  };

  const handleSwitchStaff = (data: IStaffItemData) => {
    const payload: IEditStaffPayload = {
      billId: details?.billId,
      existStaffId: staffId || '',
      newStaff: data,
      pinCode: pinCode,
    };
    dispatch(ticketListActions.editStaff.fetch(payload));
    handleCloseStaffModal();
  };

  return (
    <EditStaffButtonStyled>
      <button onClick={handleShowModal}>
        <Icon type='edit' fill='#6FABB6' />
      </button>
      {visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={handleClose}
        />
      )}
      {openStaff && (
        <Modal
          noneBodyStyle={true}
          visible={openStaff}
          // cancelTitle="Cancel"
          onClose={handleCloseStaffModal}
          modalTitle="EMPLOYEE LIST"
          hiddenOkButton={true}
          width={'70%'}
          footerPadding={1.5}
          destroyOnClose={true}
        >
          <SwitchStaff onPickStaff={handleSwitchStaff} />
        </Modal>
      )}
    </EditStaffButtonStyled>
  );
};

export default EditStaffButton;
const EditStaffButtonStyled = styled.div``;
