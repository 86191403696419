import { InputRef } from 'antd';
import cashierApis from 'features/cashier/services/apis';
import { IGiftCardDetail } from 'features/cashier/services/types/giftCard';
import { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';

const useGetInfoGiftCard = () => {
  const inputRef = useRef<InputRef>(null);
  const [code, setCode] = useState<string>('');
  const [msg, setMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [giftCardData, setGifCardData] = useState<IGiftCardDetail | null>(null);

  const fetchData = async (code: string) => {
    setMsg('');
    setGifCardData(null);
    if (code?.length < 4) return;
    setLoading(true);
    try {
      const res: IResponseDataBody<IGiftCardDetail> = await cashierApis.getGiftCardInfo(code);
      if (res.data.data) {
        setGifCardData(res.data.data);
      } else {
        setMsg('Gift number not exist, please try again');
      }
    } catch (error) {
      setMsg('Gift number not exist, please try again');
    }
    finally {
      setLoading(false);
    }
  };
  const fetchStr = async (code: string) => {
    setMsg('');
    setGifCardData(null);
    if (code?.length < 4) return '';
    setLoading(true);
    try {
      const res: IResponseDataBody<IGiftCardDetail> = await cashierApis.getGiftCardInfo(code);
      if (res.data.data) return 'Gift number exist, please try again';
      return '';
    } catch (error) {
      return '';
    } finally {
      setLoading(false);
    }
  };

  const fetchDebounce = fetchStr;

  const _debounce = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    _debounce(code);
  }, [code]);

  const onScan = () => undefined;

  const onScanBtn: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    reset();
    e.preventDefault();
    inputRef.current?.focus();
  };

  const resultGiftCard = useMemo(() => {
    const { amount = 0, usedAmount = 0, expireDate } = giftCardData || {};
    const remaining = amount - usedAmount;
    return ({
      available: remaining || 0,
      amount: amount || 0,
      used: usedAmount || 0,
      expireDate: expireDate ? moment(expireDate).format('MM-DD-YYYY') : '--/--/----',
      expireDateV2: expireDate ? moment(expireDate).format('MM-DD-YYYY') : '__/__/____',
    });
  }, [giftCardData]);

  const reset = () => {
    setCode('');
    setMsg('');
    setLoading(false);
    setGifCardData(null);
  };

  return ({
    code, setCode,
    msg,
    loading,
    resultGiftCard,
    onScan,
    data: giftCardData,
    reset,
    fetchDebounce,
    inputRef, onScanBtn,
    fetchData,
  });
};
export type IUseGetInfoGiftCardType = ReturnType<typeof useGetInfoGiftCard>;
export default useGetInfoGiftCard;
