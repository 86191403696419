import Loading from 'components/Loading';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { LoadingPageStyled } from 'services/UI/LoadingPage';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import CategoryPart from './CategoryPart';
import ServicePart from './ServicePart';
import useSettingService from './useSettingService';
import { useMemo } from 'react';

const ServiceSettingV2 = () => {
  const categories = shopSelectors.data.categoriesIncludeAddon();
  const activeCateId = settingServiceSelectors.serviceTab.activeCate();
  const loading = shopSelectors.loading.categories();
  const loadingAddon = shopSelectors.loading.addonServices();
  const settingService = useSettingService();

  const activeCate = useMemo(() => {
    return categories.find(o => o.id === activeCateId) || null;
  }, [activeCateId, categories]);

  const handleDeleteServices = settingService.deleteServices;
  const handleApplySupplyFee = settingService.applySupplyFee;
  const handleEditNameCate = settingService.editNameCate;
  const handleEditNameService = settingService.editNameService;
  const handleEditNameServiceAddOn = settingService.editNameServiceAddOn;
  const handleUpdateInfoService = settingService.updateInfoServices;
  const handleUpdateInfoServicesAddOn = settingService.updateInfoServicesAddOn;
  const handleDeleteCategory = settingService.deleteCategory;
  const handlePreOrderServices = settingService.preOrderServices;
  const handlePreOrderServicesAddOn = settingService.preOrderServicesAddOn;
  const handleAddCategory = settingService.addCategory;
  const handleAddMoreServiceForCate = settingService.addMoreServiceForCate;
  const handleAddMoreServiceForAddOn = settingService.addMoreServiceForAddOn;
  const handleApplySupplyFeeAddOn = settingService.applySupplyFeeAddon;
  const handleDeleteServicesAddOn = settingService.deleteServicesAddOn;
  const handleApplyDiscountItemsCate = settingService.applyDiscountItemsCategory;
  const handleApplyDiscountItemsCateAddon = settingService.applyDiscountItemsCateAddon;


  if (loading && categories.length <= 1) return (
    <Container><Loading /></Container>
  );

  return (
    <>
      <Container>
        <CategoryPart
          data={categories}
          activeCate={activeCate}
          onChooseCate={settingService.setActiveCate}
          onAddCategory={handleAddCategory}
        />
        <ServicePart
          data={activeCate}
          onDeleteServices={handleDeleteServices}
          onDeleteServicesAddOn={handleDeleteServicesAddOn}
          onApplySupplyFee={handleApplySupplyFee}
          onApplySupplyFeeAddOn={handleApplySupplyFeeAddOn}
          onEditNameCate={handleEditNameCate}
          onEditNameService={handleEditNameService}
          onEditNameServiceAddOn={handleEditNameServiceAddOn}
          onUpdateInfoService={handleUpdateInfoService}
          onUpdateInfoServicesAddOn={handleUpdateInfoServicesAddOn}
          onDeleteCategory={handleDeleteCategory}
          onPreOrderServices={handlePreOrderServices}
          onPreOrderServicesAddOn={handlePreOrderServicesAddOn}
          onAddMoreServiceForCate={handleAddMoreServiceForCate}
          onAddMoreServiceForAddOn={handleAddMoreServiceForAddOn}
          onApplyDiscountItemsCate={handleApplyDiscountItemsCate}
          onApplyDiscountItemsCateAddon={handleApplyDiscountItemsCateAddon}
        />
      </Container>
      {(loading || loadingAddon) && <LoadingPageStyled>
        <div className="box"><Loading /></div>
      </LoadingPageStyled>}
    </>
  );
};

export default ServiceSettingV2;

const Container = styled.div`
  display: flex;
  justify-content: center;
  background: #E5E5E5;
  height: calc(100% - 30px);
  gap: 24px;
  padding: 24px 0 0 24px;
`;
