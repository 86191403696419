import { TURN_ITEM_TYPE } from 'features/turn/services/constants';
import React from 'react';
import styled from 'styled-components';
import clsx from 'clsx';
import Box from 'components/Box';
import skipIcon from './skip.svg';
import { Player } from '@lottiefiles/react-lottie-player';
import progressingLoading from './progressing.json';
export const Progressing = <Player autoplay={true} loop={true} controls={true} src={progressingLoading} />;

type Props = {
  turnId?: string;
  half?: boolean;
  full?: boolean;
  completed?: boolean;
  type?: keyof typeof TURN_ITEM_TYPE;
  serName?: string;
  onClickNew?: () => void;
  onClickEdit?: () => void;
  visibleItemName?: boolean;
  active?: boolean;
  minus?: boolean;
  work?: boolean;
  next?: boolean;
};
const TurnBodyItem = ({ visibleItemName, next, minus, active, turnId, onClickEdit = () => undefined, onClickNew = () => undefined, serName, type, completed = false, full = false, half = false, work = false }: Props) => {
  const handleOnClick = () => {
    if (!turnId) return onClickNew();
    onClickEdit();
  };

  if (work) {
    return (
      <Container className={clsx('TurnBodyItem', active && 'active', visibleItemName ? 'visibleItemName' : 'hideItemName')}>
        <button onClick={handleOnClick} className={clsx('box-item', 'FULL_TURN', 'IN_PROGRESS')}>
          <div className="container-half container-full">
            {Progressing}
          </div>
        </button>
      </Container>
    );
  }
  if (next) {
    return (
      <Container className={clsx('TurnBodyItem', active && 'active')}>
        <button onClick={handleOnClick} className={clsx('box-item', type, half && 'HALF_TURN', full && 'FULL_TURN', 'NEXT')}>
          <Box className="container-half container-full" display='flex' alignItems='center' justifyContent='center'>
            <img src={skipIcon} alt="skipIcon" style={{ width: 45, height: 45 }} />
          </Box>
        </button>
      </Container>
    );
  }
  if (minus) {
    return (
      <Container className={clsx('TurnBodyItem', active && 'active')}>
        <button onClick={handleOnClick} className={clsx('box-item', type, 'FULL_TURN', 'MINUS')}>
          <div className="container-half container-full">
            <div className="service-text">X</div>
          </div>
        </button>
      </Container>
    );
  }

  return (
    <Container className={clsx('TurnBodyItem', active && 'active', visibleItemName ? 'visibleItemName' : 'hideItemName')}>
      <button onClick={handleOnClick} className={clsx('box-item', type, half && 'HALF_TURN', full && 'FULL_TURN')}>
        {half && <>
          <div className="container-half">
            <div className="half-bg">
              <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                  <path d="M0.5 60V0H80.5L40.5 30L0.5 60Z" fill="#1D2129" />
                </svg>
              </div>
            </div>
            <div className="service-text">{serName?.slice(0, 4)}</div>
          </div>
        </>}
        {full && <>
          <div className="container-half container-full">
            <div className="service-text">{serName?.slice(0, 4)}</div>
          </div>
        </>}
        {completed && <div className='icon-completed'>{iconCheck}</div>}
      </button>
    </Container>
  );
};

export default TurnBodyItem;

const Container = styled.td`

padding-right: 8px;

.box-item {
  z-index: 1;
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #CCD4DC;
  background: #F0F0F0;
  position: relative;
  display: flex;
  flex-direction: column;
  .icon-completed {
    position: absolute;
    bottom: 2px;
    right: 4px;
  }


  .half-bg {
    position: absolute;
    inset: 0px;
  }

  .container-half {
    flex: 1;
    height: 40px;
    .half-bg {
      top: 10px;
      transform: scale(1.5);
    }
  }

  .service-text {
    white-space: nowrap;
    position: relative;
    color: #FFF;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  
  &.HALF_TURN {
   .service-text {
      position: absolute;
      top: 2px;
      white-space: nowrap;
      left: 4px;
    }
  }
  &.FULL_TURN {
    .service-text {
      text-align: center;
    }
  }
  &.MINUS {
    .label-type, .service-text {
      color: #1D2129;
    }
  }
  &.NEXT {
    position: relative;
    overflow: unset;
    &::before,&::after {
      content: "";
      position: absolute;
      border: 2px solid #F5B945;
      background: #FBD2778c;
      border-radius: 5px;
      z-index: 2;
      left: -5px;
      right: -5px;
      top: -5px;
      bottom: -5px;
      animation: zoomNextStaff 1.4s linear infinite;
    }
    .container-full {
      border: 1px solid #F5B945;
      position: relative;
      z-index: 3;
      border-radius: 5px;
      overflow: hidden;
      .service-text {
        color: #1D2129;
        font-size: 20px;
      }
    }
    &::after {
      animation-delay: .4s;
    }
  }
}
.container-full {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  position: relative;
  z-index: 99;
}
&.active {
  .box-item {
    box-shadow: 0px 0px 0px 4px red !important;
  }
}

&.visibleItemName .service-text {
  opacity: 1;
}
&.hideItemName .service-text {
  opacity: 0;
}

`;

const iconCheck = <Box display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 20, width: 20, height: 20, background: '#00AD45' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6.48494 12C6.2715 12 6.06873 11.9107 5.91932 11.7544L2.89911 8.59574C2.58962 8.27206 2.58962 7.73631 2.89911 7.41263C3.20859 7.08894 3.72085 7.08894 4.03033 7.41263L6.48494 9.97977L11.9703 4.24276C12.2798 3.91908 12.7921 3.91908 13.1015 4.24276C13.411 4.56645 13.411 5.1022 13.1015 5.42588L7.05055 11.7544C6.90114 11.9107 6.69838 12 6.48494 12Z" fill="white" />
  </svg>
</Box>;
