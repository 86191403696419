import { Rate } from 'antd';
import { IReviewReportSummary } from 'features/report/services/types/review';
import React from 'react';
import styled from 'styled-components';
import { formatNumber } from 'utils/formatCurrency';
type Props = {
  data: IReviewReportSummary | null;
};
const ReviewDashboardV2 = ({ data }: Props) => {
  return (
    <Container>
      <div className="star-container">
        <div className="count">{(data?.averageStars || 0).toFixed(1)}</div>
        <Rate style={{ fontSize: 32, color: '#FF7D00', lineHeight: '22px' }} value={data?.averageStars || 4} disabled />
      </div>
      <div className="statistic-container">
        <StatisticItem label='Total Review' value={formatNumber(data?.total || 0)} />
        <StatisticItem label='5 STARS' value={formatNumber(data?.details?.[5] || 0)} />
        <StatisticItem label='4 STARS' value={formatNumber(data?.details?.[4] || 0)} />
        <StatisticItem label='3 STARS' value={formatNumber(data?.details?.[3] || 0)} />
        <StatisticItem label='2 STARS' value={formatNumber(data?.details?.[2] || 0)} />
        <StatisticItem label='1 STARS' value={formatNumber(data?.details?.[1] || 0)} />
      </div>
    </Container>
  );
};

export default ReviewDashboardV2;
const Container = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
align-self: stretch;
background: #FFF;
height: 120px;
.star-container {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  background: #232F3E;
  .count {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.statistic-container {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  background: #E5E6EB;
}
`;
type StatisticItemProps = {
  label: string;
  value: string;
};
const StatisticItem = ({ label, value }: StatisticItemProps) => {
  return <StatisticItemStyled>
    <div className="content-container">
      <div className="amount-container">
        <span className="value">{value}</span>
        <span className="label">{label}</span>
      </div>
    </div>
  </StatisticItemStyled>;
};
const StatisticItemStyled = styled.div`
  display: flex;
  padding: 16px;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  border-right: 1px solid #CCD4DC;
  .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex: 1 0 0;
    .amount-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2px;
      align-self: stretch;
      span.value {
        color: #1D2129;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      span.label {
        color: #505050;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: uppercase;
      }
    }
  }
`;