import Modal from 'components/Modal';
import AddStaffModalChildren from './AddStaffForm';

import { InputRef } from 'antd';
import ActionBtn from 'components/ActionBtn';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { IStaff } from 'features/cashier/services/types/api';
import ModalClockInOut from 'features/user/pages/clockInClockOut/components/ModalClockInOut';
import { cloneDeep } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch } from 'store/hooks';

const ButtonAddStaff = () => {
  const dispatch = useAppDispatch();

  const [visible, setVisible] = useState(false);

  const handleClickAddStaff = () => {

    setVisible(true);
  };
  const handleCloseAddStaffModal = () => {
    setVisible(false);
  };
  const onPickStaff = (staff: IStaff) => {
    dispatch(cashierActions.addStaffItem(cloneDeep(staff)));
    setVisible(false);
  };


  return (
    <>
      <ActionBtn
        ntype="DANGER"
        icon="addPlus"
        label='Add Staff'
        onClick={handleClickAddStaff}
      />
      <ModalAddStaff
        visible={visible}
        onPickStaff={onPickStaff}
        onClose={handleCloseAddStaffModal}
      />
    </>
  );
};

export default ButtonAddStaff;
type ModalAddStaffProps = {
  visible?: boolean;
  onClose?: () => void;
  onPickStaff: (staff: IStaff) => void;
  v2?: boolean;
};
export const ModalAddStaff = ({ v2, onPickStaff, onClose = () => undefined, visible = false }: ModalAddStaffProps) => {
  const inputRef = useRef<InputRef>(null);
  const staffAvailable = cashierSelectors.getAvailableStaff();

  useEffect(() => {
    if (visible) {
      setTimeout(() => inputRef.current?.focus(), 10);
    }
  }, [visible]);


  if (visible && staffAvailable.length === 0) {
    return (
      <ModalClockInOut isClockIn onPickStaff={onPickStaff} onClose={onClose} />
    );
  }
  if (v2) {
    return (
      <Modal
        v2
        visible={!!visible}
        modalTitle='EMPLOYEE LIST'
        noneBodyStyle
        onClose={onClose}
        hiddenOkButton
        width={1600}
      >
        <AddStaffModalChildren
          v2={v2}
          inputRef={inputRef}
          onPickStaff={onPickStaff}
        />
      </Modal>
    );
  }

  return (
    <Modal
      visible={!!visible}
      onClose={onClose}
      destroyOnClose={false}
      okTitle={'Done'}
      modalTitle={'EMPLOYEE LIST'}
      noneBodyStyle
      hiddenOkButton
      containerPadding={1.5}
      width={'60rem'}
      footer={<></>}
      noneFooterStyle={true}
      footerPadding={0.5}
      showClose
    >
      <AddStaffModalChildren
        inputRef={inputRef}
        onPickStaff={onPickStaff}
      />
    </Modal>
  );
};
