import { Col, Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import Signature, { ISignatureRef } from 'features/payment/components/CustomerSignature';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import useSMSEmailSignature from 'features/payment/hooks/useSMSEmailSignature';
import paymentApis from 'features/payment/services/apis';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useSocketContext } from 'hooks/useSocket';
import { set } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { FLOW_ADD_TIP } from 'services/shop/constants';
import shopSelectors from 'services/shop/selectors';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import multiplePaymentActions from '../../services/actions';
import multiplePaymentSelectors from '../../services/selectors';
import { ICustomerPaymentDetail } from '../../services/types/customer';
import { ICustomerReceiveConfigs, PaymentSocketData, TypePaymentActors, TypePaymentPassData } from '../../services/types/socketPayment';
import AddATip, { ModalAddTipRef } from './AddATip';
import Check, { CheckRef } from './Check';
import IconClock from './IconClock';
import imgBox from './bg.png';
import CreditCardLoading, { ICreditPaymentLoadingRef } from 'features/payment/components/PaymentLoading/CreditCardLoading';
import { parseDecimal } from 'features/cashier/services/constants';
import SimpleQRModal, { useSimpleQRModalRef } from 'features/payment/pages/QRPaymentPage/SimpleQRModal';
type IPaymentDetailProps = {
  detailTicket: IBillDetailData | null;
  receiveConfigs: ICustomerReceiveConfigs | null;
  total?: number;
};
const PaymentDetail: React.FC<IPaymentDetailProps> = ({ total = 0, detailTicket, receiveConfigs }) => {
  const modalRef = useSimpleQRModalRef();
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const creditLoadingRef = useRef<ICreditPaymentLoadingRef>(null);
  const checkRef = useRef<CheckRef>(null);
  const tipRef = useRef<ModalAddTipRef>(null);
  const tipBeforeRef = useRef<ModalAddTipRef>(null);
  const signatureRef = useRef<ISignatureRef>(null);
  const { id = '' } = useParams();

  const dispatch = useAppDispatch();
  const socketContext = useSocketContext();
  const setLoadingPage = useSetLoadingPage();
  const allSetting = shopSelectors.data.allSetting();
  const navigate = useNavigate();
  const [specificTotal, setSpecificTotal] = useState(0);

  const totalValue = specificTotal || total;

  const customerMessage = multiplePaymentSelectors.customerSide.getMessage();

  const SMSEmailSignature = useSMSEmailSignature();


  const [paymentInfo, setPaymentInfo] = useState<ICustomerPaymentDetail>({
    remaining: 0,
    chanceCash: 0,
    loyalty: 0,
    giftCard: 0,
    checkOthers: 0,
    cash: 0,
    creditCard: 0,
  });

  const sendingData = (type: TypePaymentPassData, data?: any) => {
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CUSTOMER,
      data,
    };
    switch (type) {
      case TypePaymentPassData.COMPLETED_PAYMENT: {
        set(body, 'action', TypePaymentPassData.COMPLETED_PAYMENT);
        dispatch(multiplePaymentActions.customerSide.setCustomerMsg.fetch(null));
        dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(null));
        break;
      }
      case TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT: {
        set(body, 'action', TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT);
        dispatch(multiplePaymentActions.customerSide.setCustomerMsg.fetch(null));
        dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(null));
        break;
      }
      case TypePaymentPassData.SEND_INFO_SIGNATURE_CHECK: {
        set(body, 'action', TypePaymentPassData.SEND_INFO_SIGNATURE_CHECK);
        break;
      }
      case TypePaymentPassData.ON_CUSTOMER_SIGNATURE_CHANGE: {
        set(body, 'action', TypePaymentPassData.ON_CUSTOMER_SIGNATURE_CHANGE);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE: {
        set(body, 'action', TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING: {
        set(body, 'action', TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING);
        break;
      }
      case TypePaymentPassData.TIP_PAYMENT_SEND_TIP_AFTER_TYPING: {
        set(body, 'action', TypePaymentPassData.TIP_PAYMENT_SEND_TIP_AFTER_TYPING);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP: {
        set(body, 'action', TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_CANCEL: {
        set(body, 'action', TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_CANCEL);
        break;
      }
      case TypePaymentPassData.REQUEST_SIGNATURE_ONLY: {
        set(body, 'action', TypePaymentPassData.REQUEST_SIGNATURE_ONLY);
        break;
      }
      default:
        break;
    }

    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData = JSON.parse(message.body);
    if (!payment) return;

    if (payment.shopId !== storage.shop_id.get()) return;

    if (payment.actor !== TypePaymentActors.CASHIER) return;

    switch (payment.action) {
      case TypePaymentPassData.PASS_DATA: {
        const data = payment.data as ICustomerPaymentDetail;
        setPaymentInfo(data);
        break;
      }
      case TypePaymentPassData.REQUEST_SIGNATURE: {
        creditLoadingRef.current?.close();
        setSpecificTotal(payment.data?.totalCC || 0);
        tipRef.current?.open(payment.data?.totalCC || 0);
        break;
      }
      case TypePaymentPassData.REQUEST_SIGNATURE_ONLY: {
        tipRef.current?.close();
        signatureRef.current?.open();
        break;
      }
      case TypePaymentPassData.REQUEST_SIGNATURE_CHECK: {
        checkRef.current?.open(payment.data);
        break;
      }
      case TypePaymentPassData.CASHIER_FORCE_CLOSE_SIGNATURE_CHECK: {
        checkRef.current?.close();
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING: {
        tipBeforeRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case TypePaymentPassData.TIP_PAYMENT_SEND_TIP_AFTER_TYPING: {
        tipRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE: {
        tipBeforeRef.current?.close();
        creditLoadingRef.current?.open(parseDecimal(totalValue + (payment.data?.tip || 0)), 120000);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP: {
        setSpecificTotal(payment.data?.totalCC || 0);
        tipBeforeRef.current?.open(payment.data?.totalCC || 0);
        break;
      }
      case TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_SIGNATURE: {
        tipBeforeRef.current?.close();
        creditLoadingRef.current?.setVisible(false);
        signatureRef.current?.open();
        break;
      }
      case TypePaymentPassData.CREDIT_CARD_PAYMENT_PROCESSING: {
        creditLoadingRef.current?.open(parseDecimal(payment.data?.totalCC), 120000);
        break;
      }
      case TypePaymentPassData.CREDIT_CARD_PAYMENT_DONE: {
        creditLoadingRef.current?.close();
        break;
      }
      case TypePaymentPassData.QR_MULTIPLE_PAYMENT_REQUEST_VISIBLE: {
        modalRef.current?.open(payment.data?.type || '', payment.data?.amount || 0);
        break;
      }
      case TypePaymentPassData.QR_MULTIPLE_PAYMENT_DONE: {
        modalRef.current?.close();
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (!customerMessage) return;
    listeningData(customerMessage);
  }, [customerMessage]);

  useEffect(() => {
    dispatch(multiplePaymentActions.customerSide.setBillId(id));
  }, [id]);

  const paymentDetail = [
    { label: 'Loyalty', value: paymentInfo.loyalty, },
    { label: 'Gift Card', value: paymentInfo.giftCard, },
    { label: 'Check / Other(s)', value: paymentInfo.checkOthers, },
    { label: 'Cash', value: paymentInfo.cash, },
    { label: 'Credit Card', value: paymentInfo.creditCard, }
  ];

  const onSignatureDone = async (signatureValue: string) => {
    signatureRef.current?.close();
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.updateSignature(detailTicket?.billId || '', removeBase64Prefix(signatureValue));
      if (res.data.data) {
        sendingData(TypePaymentPassData.COMPLETED_PAYMENT);
        dispatch(multiplePaymentActions.customerSide.setCustomerMsg.fetch(null));
        dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(null));
        return;
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const handleCheck = (val: string) => {
    sendingData(TypePaymentPassData.SEND_INFO_SIGNATURE_CHECK, val);
  };

  const handleAddATip = async (val: number) => {
    if (val && detailTicket?.billId) {
      setLoadingPage(true);
      try {
        await paymentApis.addTipCreditCard(detailTicket?.billId, val);
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
    }
    signatureRef.current?.open();
    sendingData(TypePaymentPassData.REQUEST_SIGNATURE_ONLY);
  };

  const onSkipAddATip = () => {
    signatureRef.current?.open();
  };

  const handleChangeTip = (target: 'before' | 'after') => (val: number) => {
    if (target === 'before')
      return sendingData(TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING, { tip: val });
    sendingData(TypePaymentPassData.TIP_PAYMENT_SEND_TIP_AFTER_TYPING, { tip: val });
  };

  const handleAddATipBefore = async (val: number) => {
    if (!detailTicket?.billId) return;
    sendingData(TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_VALUE, { tip: val });
    creditLoadingRef.current?.open(parseDecimal(totalValue + val), 60000);
  };

  const onSkipAddATipBefore = (totalCCValue?: number) => {
    if (!detailTicket?.billId) return;
    creditLoadingRef.current?.open(parseDecimal(totalCCValue || totalValue), 60000);
  };

  const handleShowTipBeforePayment = () => {
    sendingData(TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP);
    tipBeforeRef.current?.open();
  };

  const handleCancelTipBefore = () => {
    sendingData(TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_CANCEL);
    navigate(`/store/${storage.shop_id.get()}/check-in/sign-in`);
  };

  const onSignatureChange = (val: string) => {
    sendingData(TypePaymentPassData.ON_CUSTOMER_SIGNATURE_CHANGE, val);
  };

  return (
    <>
      <PaymentDetailStyled>
        <div className="box-total">
          <Text variant='H3' mb={1.5}>Total:</Text>
          <Text variant='H1'>{formatCurrency(totalValue)}</Text>
        </div>
        <div className="container">
          <Row className="box-money">
            <div className="icon-clock">
              <IconClock />
            </div>
            <Row justify={'space-between'} className='row-info'>
              <Col>
                <Text mb={1} variant='H4' color='text_2'>Remain:</Text>
                <Text variant='H5'>{paymentInfo.remaining >= 0 ? formatCurrency(paymentInfo.remaining) : 0}</Text>
              </Col>
              <div className="vertical-divide" />
              <Col>
                <Text mb={1} variant='H4' color='text_2'>Chance:</Text>
                <Text variant='H5'>{paymentInfo.chanceCash < 0 ? formatCurrency(paymentInfo.chanceCash) : 0}</Text>
              </Col>
            </Row>
          </Row>
        </div>
        <div className='container payment-detail'>
          {paymentDetail.map((o, i) => (
            <React.Fragment key={i}>
              <Row align={'middle'} justify={'space-between'}>
                <Text variant='H5'>{o.label}</Text>
                <Text variant='H5'>{formatCurrency(o.value)}</Text>
              </Row>
              {i !== (paymentDetail.length - 1) && <Text my={1} className="border-dotted" />}
            </React.Fragment>
          ))}
          {allSetting?.flowAddTip === FLOW_ADD_TIP.TIP_BEFORE_SALE && receiveConfigs?.enableTipBeforeMode && <Row justify={'center'} align={'middle'}>
            <Button ntype='PRIMARY' onClick={handleShowTipBeforePayment}>CONFIRM</Button>
          </Row>}
        </div>
      </PaymentDetailStyled>
      <PaymentLoading ref={loadingRef} />
      <CreditCardLoading ref={creditLoadingRef} />
      <Signature
        ref={signatureRef}
        onAgree={onSignatureDone}
        onNoReceipt={SMSEmailSignature.onNoReceipt(detailTicket?.billId || '', () => sendingData(TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT))}
        onEmail={SMSEmailSignature.onEmail(detailTicket?.billId || '', () => sendingData(TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT))}
        onSMS={SMSEmailSignature.onSMS(detailTicket?.billId || '', () => sendingData(TypePaymentPassData.COMPLETED_PAYMENT_WITHOUT_RECEIPT))}
        defaultSMSPhone={detailTicket?.customerPhone}
        onSignatureChange={onSignatureChange}
      />
      <Check ref={checkRef} onRedeem={handleCheck} />
      <AddATip total={totalValue} ref={tipRef} onAddATip={handleAddATip} onChangeTip={handleChangeTip('after')} onSkipAddATip={onSkipAddATip} />
      <AddATip total={totalValue} isCustomerSide isTipBefore={receiveConfigs?.enableTipBeforeMode} ref={tipBeforeRef} onAddATip={handleAddATipBefore} onChangeTip={handleChangeTip('before')} onCancel={handleCancelTipBefore} onSkipAddATip={onSkipAddATipBefore} />
      <SimpleQRModal ref={modalRef} isCustomer />
    </>
  );
};

export default PaymentDetail;
type PaymentDetailStyledProps = {}
const PaymentDetailStyled = styled.div<PaymentDetailStyledProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 45.75rem;
  border-radius: 10px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-5, #E5E6EB);

  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  .box-total {
    background: url(${imgBox});
    height: 19rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .box-money {
    height: 8.5rem;
    transform: translateY(-4.25rem);
    border-radius: 10px;
    background: #FFF;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    align-items: center;
    padding:0 1.5rem;
    .vertical-divide {
      background: #C9CDD4;
      width: 1px;
    }
    .row-info {
      flex:1;
      text-align: left;
      padding-right: 1.5rem;
    }
  }
  .icon-clock {
    border-radius: 20px;
    background: var(--fill-fill-5, #E5E6EB);
    height:6.8rem;
    width:6.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.5rem;
  }
  .payment-detail {
    transform: translateY(-2rem);
  }
  .border-dotted {
    border: 1px dotted #86909C;
  }
`;
