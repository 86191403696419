import { Input, Spin } from 'antd';
import SearchIcon from 'assets/Icons/search';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { debounce } from 'lodash';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ButtonAddCustomer from 'widgets/Customer/AddCustomer';
import { maskPhone } from 'widgets/Customer/CustomerSearchInput';
import back_icon from '../icons/back.svg';

type ILeftCustomerDetailsProps = {
  v2?: boolean;
};
const LeftCustomerDetails: React.FC<ILeftCustomerDetailsProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const customers = customerSelectors.customersActiveData.data();
  const loading = customerSelectors.customersActiveData.loading();
  const customerDetails = customerSelectors.getCustomerDetails();
  const [inputValue, setInputValue] = useState<string>('');

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/store/${storage.shop_id.get()}/customers`);
  };

  const handleClickCustomer = (id: string) => {
    if (id === customerDetails?.id) return;
    dispatch(customerActions.getCustomerDetails.fetch(id));
    dispatch(customerActions.getSummaryReport.fetch(id));
    navigate(`/store/${storage.shop_id.get()}/customers/${id}`);
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const value = e.target.value;
    if (value.length <= 4) {
      setInputValue(value);
    }
  };

  const debouncedHandle = debounce((text: string) => {
    const params = {
      lastPhoneNumber: text ? Number(text) : '',
    };
    dispatch(customerActions.getCustomersActive.fetch(params));
  }, 500);

  useEffect(() => {
    debouncedHandle(inputValue);
  }, [inputValue]);

  useEffect(() => {
    dispatch(customerActions.getCustomersActive.fetch({}));
  }, []);

  return (
    <LeftCustomerDetailsStyled className={v2 ? 'v2' : ''}>
      <Box p="4" display="flex" gap="3" justifyContent={v2 ? 'space-between' : undefined}>
        {v2 ? <Box display='flex' alignItems='center' gap='2'>
          <button type='button' onClick={handleBack}>
            <img src={back_icon} alt='back_icon' style={{ width: 40, height: 40 }} />
          </button>
          <Text variant='CONTENT_2' className='customers-title'>Customers</Text>
        </Box>
          :
          <Button icon="back" ntype="SECONDARY" onClick={handleBack}>
            Back
          </Button>}

        <ButtonAddCustomer v2={v2} model="new-ticket" />
      </Box>
      {!v2 && <Box px="4" py="3">
        <Text variant="H5" color="text_3">
          CUSTOMER DETAIL
        </Text>
      </Box>}

      <Box display="flex" flexDirection="column" gap="3" px="4" pt="3">
        <Input
          suffix={<SearchIcon />}
          placeholder="Last 4 number"
          value={inputValue}
          onChange={handleInputChange}
          type="number"
          maxLength={4}
          className='input-search'
        />
      </Box>
      <ListStyled>
        {loading ? (
          <Box className="center">
            <Spin />
          </Box>
        ) : (
          <Box pb="3">
            {customers.length > 0 ? (
              customers.map((item) => (
                <ItemStyled
                  v2={v2}
                  active={item.id === customerDetails?.id}
                  key={item.id}
                  onClick={() => handleClickCustomer(item?.id)}
                >
                  <Box>
                    <Text variant="CONTENT_2" color="text_3">
                      {item.name || '--'}
                    </Text>
                    <Text mt={0.5} variant="BODY_1" color="text_2">
                      {item.point} point
                    </Text>
                  </Box>
                  <Text variant="BODY_1" color="text_3" className='phone'>
                    {item.phone ? maskPhone(item.phone) : '--'}
                  </Text>
                </ItemStyled>
              ))
            ) : (
              <Box
                className="center"
                height={'3rem'}
                bgColor="fill_1"
                borderRadius="5px"
              >
                <Text variant="CONTENT_2" color="text_3">
                  No data
                </Text>
              </Box>
            )}
          </Box>
        )}
      </ListStyled>
    </LeftCustomerDetailsStyled>
  );
};

export default LeftCustomerDetails;
const LeftCustomerDetailsStyled = styled.div`
  min-width: 30rem;
  background: var(--fill-fill-3, #e5e5e5);
  border-right: 1px solid ${colorTheme.line_3};

  &.v2 {
    background: #f5f5f5;
    min-height: 100vh;
    .customers-title {
      color: #505050;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .ant-input-affix-wrapper.input-search .ant-input {
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &::placeholder {
        color: #64748B;
        opacity: 1;
      }

      &::-ms-input-placeholder {
        color: #64748B;
      }
    }
  }
`;
const ListStyled = styled.div`
  margin-top: 1rem;
  padding: 0 24px;
  overflow: auto;
  height: calc(100vh - 18rem);
`;

interface ItemStyledProps {
  active?: boolean;
  v2?: boolean;
}

const ItemStyled = styled.div.withConfig({
  shouldForwardProp: (prop) => !['active'].includes(prop),
}) <ItemStyledProps>`
  padding: 0.5rem 0.75rem;
  height: 3.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => (props.active ? '#FFE5E7' : '#f6f7fb')};
  border-bottom: 1px solid var(--line-line-2, #c9cdd4);
  cursor: pointer;
  &:first-child {
    border-radius: 5px 5px 0 0;
  }

  &:last-child {
    border-radius: 0 0 5px 5px;
  }

  ${({ v2, active }) => v2 && `
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #CCD4DC;
    background: #FFF;
    .CONTENT_2 {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: uppercase;
    }

    .BODY_1 {
      color: #64748B;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      &.phone {
        color: #1D2129;
        text-align: right;
        font-family: Poppins;
        font-size: 18px;
      }
    }
    ${active && `
      background: #F5767F;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #E7646D inset;
      .CONTENT_2, .BODY_1, .BODY_1.phone {
        color: #fff;
      }
    `}
  `}
`;
