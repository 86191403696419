import { Player } from '@lottiefiles/react-lottie-player';
import { Input, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import paymentApis from 'features/payment/services/apis';
import React, { forwardRef, useImperativeHandle, useRef, useState, useTransition } from 'react';
import styled from 'styled-components';
import animationQR from './animationQR.json';
import animationSuccess from './animationSuccess.json';
import { IResponseDataBody } from 'services/response';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import toast from 'utils/toast';
import { STAFF_CLOCK_IN_OUT_ACTIONS } from 'features/user/services/constants';
import userActions from 'features/user/services/actions';
import { useAppDispatch } from 'store/hooks';
import Button from 'components/Button';
type ButtonScanQRIRef = {
  scan: () => void;
};

export const useButtonScanQRRef = () => useRef<ButtonScanQRIRef>(null);

type Props = { hook?: boolean, onlyTicket?: boolean; };
const ButtonScanQR = forwardRef<ButtonScanQRIRef, Props>(({ hook = false, onlyTicket = false }, ref) => {
  const [text, setText] = useState('');
  const [isFocus, setIsFocus] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const [, startTransition] = useTransition();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState(false);

  const onScan = () => {
    startTransition(() => {
      setIsFocus(true);
      setText('');
      setIsSuccess(false);
    });
    setTimeout(() => inputRef.current?.focus(), 100);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleClose = () => {
    setIsFocus(false);
    setText('');
    inputRef.current?.blur();
  };

  const handleFindingTicket = async () => {
    try {
      const res: IResponseDataBody<string> = await paymentApis.getBillInfoWithQRCode(text);
      const bill_id = res.data.data;
      if (bill_id) {
        setTimeout(() => {
          navigate(`/store/${storage.shop_id.get()}/ticket/edit/${bill_id}`);
        }, 10);
        return;
      }
      throw 'fail';
    } catch (error) {
      toast.error('Data not found');
    } finally {
      handleClose();
    }
  };

  const handleClockInOut = () => {
    handleClose();
    const pin = text.replace(/[^0-9]/g, '');
    if (pin.length < 4) return;
    if (text.includes(STAFF_CLOCK_IN_OUT_ACTIONS.CLOCK_IN)) {
      dispatch(userActions.doClockIn.fetch(pin));
      return;
    }

    if (text.includes(STAFF_CLOCK_IN_OUT_ACTIONS.CLOCK_OUT)) {
      dispatch(userActions.doClockOut.fetch(pin));
      return;
    }
  };

  const onEnter = async () => {
    if (onlyTicket) return handleFindingTicket();

    if (text.includes(STAFF_CLOCK_IN_OUT_ACTIONS.CLOCK_IN)
      || text.includes(STAFF_CLOCK_IN_OUT_ACTIONS.CLOCK_OUT)) {
      return handleClockInOut();
    }

    handleFindingTicket();
  };

  useImperativeHandle(ref, () => ({
    scan: () => onScan()
  }));

  return (
    <>

      {!hook && (
        <Button onClick={onScan} mr={0.5} iconElement={<div style={{ display: 'flex', height: 32, width: 32 }}><Icon style={{ width: '100%', height: '100%' }} type={'QR'} /></div>} />
      )}
      <Modal width={'300px'} showClose={!isSuccess} destroyOnClose visible={isFocus} hiddenHeader okTitle={'close'} hiddenFooter onClose={handleClose}>
        <Row style={{ height: '220px', width: '220px', }} align={'middle'} justify={'center'}>
          {
            isSuccess ?
              <Player
                autoplay={true}
                loop={false}
                keepLastFrame
                controls={true}
                speed={1.5}
                src={animationSuccess}
                style={{ height: '150px', width: '150px' }}
              ></Player>
              :
              <Player
                autoplay={true}
                loop={true}
                controls={true}
                src={animationQR}
                style={{ height: '200px', width: '200px' }}
              ></Player>}
        </Row>
        <div style={{ opacity: 0 }}>
          <Input
            style={{ height: 0, width: 0 }}
            ref={inputRef}
            className='hiddenInput'
            value={text}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                onEnter();
                return;
              }
            }}
            onChange={onChange}
            placeholder='0'
          />
        </div>
      </Modal>
    </>
  );
});

ButtonScanQR.displayName = 'ButtonScanQR';

export default ButtonScanQR;

export const LoadingPageStyled = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 132px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 6px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363565;
    }
  }
`;
