import React from 'react';

const CardAddPriceV2 = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M26.25 18.3108C24.0662 18.9079 22.75 20.5836 22.75 22.1667C22.75 23.7498 24.0662 25.4254 26.25 26.0225V18.3108Z" fill="#293A80" />
      <path d="M29.75 29.9775V37.6892C31.9338 37.0921 33.25 35.4165 33.25 33.8333C33.25 32.2502 31.9338 30.5746 29.75 29.9775Z" fill="#293A80" />
      <path fillRule="evenodd" clipRule="evenodd" d="M51.3332 28C51.3332 40.8865 40.8864 51.3333 27.9998 51.3333C15.1132 51.3333 4.6665 40.8865 4.6665 28C4.6665 15.1134 15.1132 4.66667 27.9998 4.66667C40.8864 4.66667 51.3332 15.1134 51.3332 28ZM27.9998 12.25C28.9663 12.25 29.7498 13.0335 29.7498 14V14.739C33.5541 15.4202 36.7498 18.2784 36.7498 22.1667C36.7498 23.1332 35.9663 23.9167 34.9998 23.9167C34.0334 23.9167 33.2498 23.1332 33.2498 22.1667C33.2498 20.5836 31.9336 18.9079 29.7498 18.3108V26.4056C33.5541 27.087 36.7498 29.9451 36.7498 33.8333C36.7498 37.7216 33.5541 40.5797 29.7498 41.261V42C29.7498 42.9665 28.9663 43.75 27.9998 43.75C27.0334 43.75 26.2498 42.9665 26.2498 42V41.261C22.4455 40.5797 19.2498 37.7216 19.2498 33.8333C19.2498 32.8669 20.0333 32.0833 20.9998 32.0833C21.9663 32.0833 22.7498 32.8669 22.7498 33.8333C22.7498 35.4165 24.0661 37.0921 26.2498 37.6892V29.5944C22.4455 28.913 19.2498 26.0549 19.2498 22.1667C19.2498 18.2784 22.4455 15.4202 26.2498 14.739V14C26.2498 13.0335 27.0334 12.25 27.9998 12.25Z" fill="#293A80" />
    </svg>
  );
};

export default CardAddPriceV2;
