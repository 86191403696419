import Button from 'components/Button';
import Modal from 'components/Modal';
import CurrencyKeyBoardTip, { CurrencyKeyBoardTipRef, ICurrencyKeyBoardTip } from 'components/NumberKeyBoard/Currency/TIP';
import Text from 'components/Text';
import { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { Row } from 'antd';
import multiCardSelectors from '../services/selectors';
import { useAppDispatch } from 'store/hooks';
import multiCardActions from '../services/actions';
import shopSelectors from 'services/shop/selectors';
import Box from 'components/Box';

export interface IAddTipResult {
  billId: string;
  tip: number;
  items: null;
}

type Props = {
  onCancel?: () => void;
  isTipBefore?: boolean;
  onAddATip: (val: number) => void;
  isCustomerSide?: boolean;
  onChangeTip?: (val: number) => void;
  total?: number;
};
type ModalAddTipRef = {
  open: () => void;
  close: () => void;
  setAmount: (val: number) => void;
  setSoftAmount: (val: number) => void;
};

export const useAddATipMultiCardRef = () => useRef<ModalAddTipRef>(null);

const AddATipMultiCard = forwardRef<ModalAddTipRef, Props>(({ total = 0, onAddATip, onChangeTip, onCancel, isTipBefore = false, isCustomerSide }, ref) => {
  const dispatch = useAppDispatch();
  const currencyRef = useRef<CurrencyKeyBoardTipRef>(null);
  const detailTicket = multiCardSelectors.detail();
  const optionTips = shopSelectors.data.optionTips();

  const _total = total || (detailTicket?.total || 0);

  const [amount, setAmount] = useState<number | null>(0);

  // const [visible, setVisible] = useState(false);
  const visible = multiCardSelectors.visibleTip();
  const setVisible = (value: boolean) => dispatch(multiCardActions.setVisibleTip(value));

  const onClose = () => { setVisible(false); };

  const suggestions = useMemo(() => {
    if (!optionTips?.length) return null;
    return optionTips.map((o: number) => o / 100);
  }, [optionTips]);

  useEffect(() => {
    if (!visible) return;
    currencyRef.current?.setValue((detailTicket?.tip || 0).toFixed(2));
  }, [visible, detailTicket]);

  useImperativeHandle(ref, () => ({
    open: () => {
      setVisible(true);
      setTimeout(() => currencyRef.current?.setValue((0).toFixed(2)), 500);
    },
    close: () => setVisible(false),
    setAmount: (val: number) => {
      currencyRef.current?.setValue((val)?.toFixed(2) || '');
    },
    setSoftAmount: (val: number) => {
      setAmount(val);
      currencyRef.current?.setSoftValue((val)?.toFixed(2) || '');
    }
  }));

  const handleNoTip = () => {
    onAddATip(0);
    setVisible(false);
  };
  const handleSubmit = () => {
    onAddATip(+(amount || ''));
    setVisible(false);
  };


  const setAmountValue: ICurrencyKeyBoardTip['onChange'] = (value: ICurrencyKeyBoardTip['value']) => {
    setAmount(value || null);
    if (onChangeTip) onChangeTip(+(value || ''));
  };

  if (!visible) return null;

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      onSubmit={onClose}
      width={'100vw'}
      footer={<></>}
      noneBodyStyle
      noneFooterStyle
      hiddenHeader
      rootClassName={'modal-add-tip'}
      className='modal-overflow-unset modal-max-height-unset'
    >
      {!suggestions?.length ? <>
        <AddATipStyled style={{ justifyContent: 'center', backgroundColor: '#37413D' }}>
          <div className='keyboard-div' style={{ width: '55vw', flexGrow: 'unset', backgroundColor: '#fff' }}>
            <Text className='title-keyboard-div'>Enter Tip Amount</Text>
            <CurrencyKeyBoardTip
              ref={currencyRef}
              value={amount || 0}
              onChange={setAmountValue}
              initialAmount={_total}
              modeTip
              hint={false}
              maxValue={1000}
            />
            <Box display='flex' width={'100%'} mt='2' justifyContent='center' >
              <Box width={'55%'}>
                <Box display='flex' alignItems='center' justifyContent='space-between' style={{ padding: '0 30px' }}>
                  {isTipBefore && !isCustomerSide &&
                    <div className="button-submit round" >
                      <Button ntype='DEFAULT' onClick={onCancel}>Cancel</Button>
                    </div>
                  }
                  <div className="button-submit round">
                    <Button ntype='SECONDARY' onClick={handleNoTip}>No Tip</Button>
                  </div>
                  <div className="button-submit round" >
                    <Button ntype='PRIMARY' disabled={!amount} onClick={handleSubmit} >Done</Button>
                  </div>
                </Box>
              </Box>
            </Box>
          </div>
        </AddATipStyled>
      </> : <>
        <AddATipStyled>
          <div className='keyboard-div' style={{ width: '50%' }}>
            <Text className='title-keyboard-div'>CUSTOM TIP</Text>
            <CurrencyKeyBoardTip
              ref={currencyRef}
              value={amount || 0}
              onChange={setAmountValue}
              initialAmount={_total}
              modeTip
              maxValue={1000}
            />
            <Row align={'middle'} justify={'center'}>
              <div className="button-submit" >
                <Button ntype='PRIMARY' disabled={!amount} onClick={handleSubmit} >OK</Button>
              </div>
            </Row>
          </div>
          <div className="suggestions" style={{ width: '50%' }}>
            <div className="head-tip-content">
              <Text className='text-your-bill'>Amount Due: {formatCurrency(_total)}</Text>
              <Text className='text-tip'>TIP: {formatCurrency(amount)}</Text>
            </div>
            <div className='wrap-suggestion'>
              {suggestions.map((o, i) => {
                const value = ((_total) * o)?.toFixed(2);
                return (
                  <button className="suggestion-item" key={i} onClick={() => currencyRef.current?.setValue(value)}>
                    <div className="box">
                      <Text className='text-tip-percent'>{o * 100}% </Text>
                      <Text className='text-tip-value'>({formatCurrency(value)})</Text>
                    </div>
                  </button>
                );
              })}
              <button className="suggestion-item no-tip-box" onClick={handleNoTip}>
                <div className="box">
                  <div className="label">No Tip</div>
                </div>
              </button>
            </div>
            {isTipBefore && !isCustomerSide &&
              <div>
                <Row align={'middle'} justify={'center'} >
                  <div>
                    <Button width='8rem' onClick={onCancel} >Cancel</Button>
                  </div>
                </Row>
              </div>
            }
            <div className=""></div>
          </div>
        </AddATipStyled>
      </>}
    </Modal >
  );
});
AddATipMultiCard.displayName = 'AddATipMultiCard';
export default AddATipMultiCard;
const AddATipStyled = styled.div`
  display: flex;
  height: 100vh;
  .keyboard-div {
    padding: 1.5rem 2rem;    
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
  }
  .modal-title {
    color: var(--text-text-2, #767676);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .title-keyboard-div {
    color: var(--text-text-2, #767676);
    text-align: center;
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  } 
  .text-your-bill {
    color: var(--text-text-1, #FFF);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  .text-tip {
    color: var(--yellow-headline, #FEEDBF);
    text-align: center;
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .head-tip-content {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  .suggestions {
    padding: 1.5rem 0;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    opacity: 0.9;
    background: #37413D;
    .wrap-suggestion {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 16px;
      .suggestion-item {
        flex: 1;
      }
    }
  }
  .suggestion-item {
    border-radius: 5px;
    background: transparent;
    border: 1px solid #fff;
    padding: 8px 9px;
    display: flex;
    max-height: 145px;
    opacity: 1;
    min-width: 25vw;
    width: 50%;
    margin: 0 auto;
    &:hover {
      opacity: 0.8;
    }
    .box {
      border-radius: 5px;
      background: transparent;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-self: stretch;
      gap:4px;
      justify-content: center;
      align-items: center;
      .text-tip-percent {
        color: #fff;
        text-align: center;
        font-family: Open Sans;
        
        font-size: 2.5rem;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
      }

      .text-tip-value {
        color: #fff;
        font-family: Open Sans;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
  .no-tip-box {
    border-radius: 5px;
    background: transparent;
    border: 1px solid #fff;
    height: 15vh;
    display: flex;
    width: 50%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    padding: 8px 9px;
    &:hover {
      opacity: 0.8;
    }
    .label {
      color: #fff;
      text-align: center;
      font-family: Open Sans;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .button-submit {
    width: 6.5rem;
    border-radius: 100%;
    overflow: hidden;
    button {
      width:100%;
      height: 6rem !important;
      .CONTENT_2 {
        font-size: 1.5rem;
      }
    }

    &.round {
      border-radius: unset;
      overflow: unset;
      button {
        border-radius: 100%;
      }
    }
  }
`;