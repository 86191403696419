import React from 'react';
import anybodyImg from 'assets/icon-anybody.png';

const AnyBodyImage = ({ large }: { large?: boolean }) => {
  if (large) return (
    <img className='AnyBodyImage' src={anybodyImg} style={{ width: '5.5rem', height: '5.5rem', borderRadius: '100%' }} />
  );
  return (
    <img className='AnyBodyImage' src={anybodyImg} style={{ width: '24px', height: '24px', borderRadius: 24 }} />
  );
};

export default AnyBodyImage;
