import { FLOW_ADD_TIP } from 'services/shop/constants';
import shopSelectors from 'services/shop/selectors';
import CreditCardDefaultPayment from './DefaultPayment';
import CreditCardTipBeforePayment from './TipBeforePayment';
type Props = {
  v2?: boolean;
};
const ButtonCreditCard = ({ v2 }: Props) => {
  const allSetting = shopSelectors.data.allSetting();

  if (allSetting?.flowAddTip === FLOW_ADD_TIP.TIP_BEFORE_SALE) {
    return <CreditCardTipBeforePayment v2={v2} />;
  }

  return <CreditCardDefaultPayment v2={v2} />;
};

export default ButtonCreditCard;
