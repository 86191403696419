import React from 'react';

const EditService = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.4272 3.60512C20.1655 3.503 20.9341 3.63254 21.5938 3.98712C21.9651 4.17812 22.2796 4.45847 22.6131 4.70715C22.9779 4.99365 23.3724 5.25179 23.6716 5.61393C24.1889 6.21246 24.5099 6.98402 24.5625 7.77922C24.6244 8.65621 24.3638 9.55448 23.839 10.2547C20.8229 14.217 17.8021 18.1755 14.785 22.1368C14.7069 22.2456 14.6017 22.3335 14.4775 22.3827C12.416 23.2374 10.3564 24.0974 8.29493 24.9527C8.01392 25.076 7.6636 24.9479 7.51332 24.6803C7.38631 24.4761 7.43702 24.226 7.44679 23.9995C7.56543 21.8848 7.685 19.7706 7.80317 17.6559C7.81899 17.4157 7.79758 17.1458 7.96274 16.9505C10.9091 13.0762 13.8564 9.20132 16.8028 5.32648C16.9796 5.09813 17.1438 4.85797 17.3503 4.65515C17.9031 4.08404 18.6465 3.7115 19.4272 3.60512ZM18.7079 5.19978C18.1375 5.52883 17.8244 6.1387 17.4113 6.63086C18.9996 7.86621 20.5968 9.0888 22.1879 10.3199C22.3949 10.0447 22.6043 9.77195 22.8127 9.49775C23.1426 9.06469 23.3198 8.51391 23.3026 7.96644C23.2873 7.25492 22.9309 6.56467 22.3735 6.13681C21.999 5.85031 21.6278 5.56003 21.2509 5.27779C20.513 4.75159 19.477 4.72559 18.7079 5.19978ZM9.33101 17.2611C10.9268 18.4757 12.5235 19.6893 14.1151 20.909C16.5455 17.7169 18.9833 14.5304 21.4086 11.334C19.8161 10.109 18.2255 8.88125 16.6367 7.65205C14.2021 10.8555 11.7638 14.0567 9.33101 17.2611ZM8.75319 23.3712C10.1508 22.7888 11.5493 22.2073 12.9478 21.6267C11.6381 20.6273 10.3266 19.6297 9.01698 18.6303C8.93091 20.2108 8.83739 21.7908 8.75319 23.3712Z" fill="#1D2129" />
      <path d="M4.40762 27.5985C4.54756 27.5631 4.69848 27.5728 4.84482 27.5718C12.3586 27.5728 19.8724 27.5712 27.3862 27.5728C27.7091 27.5696 27.999 27.7657 27.999 27.9872C28.0205 28.2294 27.6931 28.445 27.3418 28.4274C19.7828 28.4299 12.2237 28.4271 4.66464 28.4274C4.38979 28.438 4.11814 28.3169 4.03445 28.1359C3.92057 27.9251 4.1003 27.6719 4.40762 27.5985Z" fill="#1D2129" />
    </svg>
  );
};

export default EditService;
