import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import customerActions from 'features/customer/services/actions';
// import customerSelectors from 'features/customer/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CustomersBodyDisplay from './components/CustomersBodyDisplay';
// import CustomersBodyWindowDisplay from './components/CustomersBodyWindowDisplay';
import CustomersHeader from './components/CustomersHeader';
import ListCustomerPageStyled from './styles';
import shopSelectors from 'services/shop/selectors';
import CustomerOverviews from './components/Overviews';
type IListCustomerPageProps = any;
const ListCustomerPage: React.FC<IListCustomerPageProps> = () => {
  const allSetting = shopSelectors.data.allSetting();
  const v2 = allSetting?.versionUI === 'V2';
  // const isWindow = customerSelectors.getIsWindowDisplay();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(customerActions.getCustomerSummary.fetch());
    dispatch(customerActions.doInit());
    const params = {
      page: 1,
      size: 20,
    };
    dispatch(customerActions.setCustomerParam(params));
  }, []);

  if (v2) return (
    <ListCustomerPageStyled>
      <Sidebar />
      <BodyStyled className={'v2'}>
        <CustomerOverviews />
        <CustomerTableContainer>
          <CustomersHeader v2 />
          <CustomersBodyDisplay v2 />
        </CustomerTableContainer>
      </BodyStyled>
    </ListCustomerPageStyled>
  );

  return (
    <ListCustomerPageStyled>
      <Sidebar />
      <BodyStyled>
        <CustomersHeader />
        <CustomersBodyDisplay />
      </BodyStyled>
    </ListCustomerPageStyled>
  );
};

export default ListCustomerPage;

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  &.v2 {
    background-color: #f5f5f5;
    padding: 16px;  
    height: unset;
    min-height: calc(100vh - 32px);
    padding-bottom: 0;
    gap: 16px;
    display: flex;
    flex-direction: column;
  }
`;

const CustomerTableContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #CCD4DC;
  background: #FFF;
`;