import { InputRef, Popover, Row } from 'antd';
import Button from 'components/Button';
import { CurrencyInputRef } from 'components/CurrencyInputMasked';
import Form, { FormItem, useForm } from 'components/Form';
import Modal from 'components/Modal';
import CardBox from 'features/cashier/components/CardBox';
import { OTHER_PAYMENT } from 'features/payment/services/constants';
import { ICheckPayment, IStateDetailPayment } from 'features/payment/services/types/payment';
import { useSocketContext } from 'hooks/useSocket';
import moment from 'moment';
import { useEffect, useRef, useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import multiplePaymentActions from '../../../services/actions';
import multiplePaymentSelectors from '../../../services/selectors';
import { IFormCheckInfoValues, IFormCheckSubmitValues } from '../../../services/types/check';
import { PaymentSocketData, TypePaymentActors, TypePaymentPassData } from '../../../services/types/socketPayment';
import { PassDataPaymentRef } from '../../PassDataPayment';
import CheckUI from './CheckPayment/CheckUI';
import InfoForm from './CheckPayment/InfoForm';
import PaymentMethods from './PaymentMethods';
import SimpleQRModal, { useSimpleQRModalRef } from 'features/payment/pages/QRPaymentPage/SimpleQRModal';
import { IOtherPaymentMethodItem } from 'services/shop/types/payment';
import { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import toast from 'utils/toast';
import CardBoxV2 from '../CardBoxV2';
import clsx from 'clsx';
export type ICheckAOtherPaymentRedeem = (paymentInfo: ICheckPayment) => void;
type Props = {
  keyboardRef: React.RefObject<CurrencyKeyBoardRef>;
  active?: boolean;
  disabled?: boolean;
  amountKB?: number;
  onRedeem: (_detailPayment: IStateDetailPayment, ignorePrint?: boolean) => Promise<void>;
  customerRef: React.RefObject<PassDataPaymentRef>;
  v2?: boolean;
  remaining?: number;
};
const CheckOthers = ({ active, disabled, amountKB = 0, customerRef, keyboardRef, v2, onRedeem, remaining = 0 }: Props) => {
  const modalRef = useSimpleQRModalRef();
  const [visiblePopover, setVisiblePopover] = useState(false);
  const currencyInputRef = useRef<CurrencyInputRef>(null);
  const inputPayForRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const [formInput] = useForm();
  const [formCheck] = useForm();
  const visibleCheck = multiplePaymentSelectors.getVisibleModalFormCheck();
  const setVisibleCheck = (val: boolean) => dispatch(multiplePaymentActions.setVisibleModalFormCheck(val));
  const socket = useSocketContext();
  const detailPayment = multiplePaymentSelectors.getDetailPayment();

  const draftDetailFormCheckSignature = multiplePaymentSelectors.getDraftDetailFormCheckSignature();
  const draftFormCheckInfo = multiplePaymentSelectors.getDraftFormCheckInfo();

  const [, startTransition] = useTransition();
  const noReceipt = useRef(false);

  useEffect(() => {
    if (draftFormCheckInfo) formCheck.setFieldsValue(draftFormCheckInfo);
  }, [draftFormCheckInfo]);

  useEffect(() => {
    if (draftDetailFormCheckSignature) {
      formCheck.setFieldValue('signImg', draftDetailFormCheckSignature);
    }
  }, [draftDetailFormCheckSignature]);

  const visibleFormCheck = multiplePaymentSelectors.getVisibleCheckPayment();
  const detailTicket = multiplePaymentSelectors.getDetail();

  const setVisibleFormCheck = (val: boolean) => {
    dispatch(multiplePaymentActions.setVisibleCheckFormInputs(val));
  };
  const onCheck = () => {
    dispatch(multiplePaymentActions.setVisibleCheckFormInputs(true));
  };

  useEffect(() => {
    if (!visibleFormCheck) return;
    formInput.resetFields();
    formInput.setFieldsValue({
      name: detailTicket?.customerName,
      phone: detailTicket?.customerPhone,
      amount: amountKB,
    });
    setTimeout(() => {
      currencyInputRef.current?.setValue((amountKB)?.toFixed(2), true);
      inputPayForRef.current?.focus();
    }, 100);
  }, [visibleFormCheck, detailTicket]);

  const handleClose = () => {
    startTransition(() => {
      setVisibleCheck(false);
      setVisibleFormCheck(false);
      formInput.resetFields();
      formCheck.resetFields();
      dispatch(multiplePaymentActions.setDraftCheckSignature.success(null));
      dispatch(multiplePaymentActions.setDraftCheckInfo(null));
    });

    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.CASHIER_FORCE_CLOSE_SIGNATURE_CHECK,
    };
    socket.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const formInfoSubmitValidation = async () => {
    try {
      await formInput.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };
  const onFormInfoSubmit = async () => await formInfoSubmitValidation() && formInput.submit();
  const handleFormInfoSubmit = (values: IFormCheckInfoValues) => {
    setVisibleFormCheck(false);
    const data: IFormCheckSubmitValues = {
      name: values.name,
      address: values.address,
      phone: values.phone,
      dlInformation: values.dlInformation,
      no: values.no,
      currentDate: moment().format('MM/DD/YYYY'),
      companyName: values.companyName,
      amount: values.amount,
      bankName: values.bankName,
      bankFor: values.bankFor,
      bankNum: values.no + ',' + values.routingNumber + ',' + values.accountNumber,
      signImg: '',
    };
    formCheck.setFieldsValue(data);
    customerRef.current?.requestCheckInfoSignature(data);
    setVisibleCheck(true);
  };

  const handleSubmitFormCheck = async (values: IFormCheckSubmitValues) => {
    const checkValue = {
      amount: +values.amount || 0,
      otherMethod: OTHER_PAYMENT.CHECK,
      otherCheckInfo: {
        dlInformation: values.dlInformation,
        address: values.address,
        bankName: values.bankName,
        payTo: values.companyName,
      },
    };
    dispatch(multiplePaymentActions.setCheckAOtherPayment(checkValue));
    handleClose();

    /**
    * AUTO COMPLETE PAYMENT
   */
    if ((remaining - checkValue.amount) <= 0)
      onRedeem({
        ...detailPayment,
        CHECK: checkValue,
      }, noReceipt.current);
  };

  const handleCancel = () => handleClose();

  const handleCancelQRPayment = () => {
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.QR_MULTIPLE_PAYMENT_DONE,
    };
    socket.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const handleDoneQRPayment = (otherMethod: string) => {
    const checkValue = {
      amount: amountKB || 0,
      otherMethod,
    };

    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.QR_MULTIPLE_PAYMENT_DONE,
    };
    socket.send(PAYMENT_PASS_DATA_TOPIC, body);
    dispatch(multiplePaymentActions.setCheckAOtherPayment(checkValue));
    /**
      * AUTO COMPLETE PAYMENT
     */
    if ((remaining - checkValue.amount) <= 0)
      onRedeem({
        ...detailPayment,
        [otherMethod]: checkValue,
      }, noReceipt.current);
  };

  const handleClick = (payment: IOtherPaymentMethodItem) => {
    setVisiblePopover(false);
    if (!amountKB) {
      toast.info('Please enter amount');
      return keyboardRef.current?.setValue(0?.toFixed(2) || '');
    }

    if (payment.methodType === 'CHECK') {
      onCheck();
      return;
    }

    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detailTicket?.billId || '',
      actor: TypePaymentActors.CASHIER,
      action: TypePaymentPassData.QR_MULTIPLE_PAYMENT_REQUEST_VISIBLE,
      data: { type: payment.methodType, amount: amountKB },
    };
    socket.send(PAYMENT_PASS_DATA_TOPIC, body);
    modalRef.current?.open(payment.methodType, amountKB);
  };


  return (
    <>
      <Popover
        placement='right'
        title=""
        content={<PaymentMethods onClick={handleClick} />}
        trigger="click"
        arrow={false}
        open={visiblePopover}
        onOpenChange={setVisiblePopover}
      >
        {v2 ? <CardBoxV2 type='E_PAY' disabled={disabled} /> : <CardBox
          type={active ? 'ACTIVE' : 'DEFAUL'}
          icon="epay"
          disabled={disabled}
        >
          E-Pays
        </CardBox>}
      </Popover>
      <Modal
        visible={visibleFormCheck}
        modalTitle='CHECK'
        onClose={handleClose}
        onSubmit={onFormInfoSubmit}
        className={clsx('modal-form-input-check', v2 && 'modal-non-opacity modal-bg-v2')}
        width={'80vw'}
        containerPadding={v2 ? 1 : undefined}
        noneBodyStyle={v2}
        noneFooterStyle={v2}
        v2={v2}
      >
        <Form form={formInput} onFinish={handleFormInfoSubmit}>
          <InfoForm v2={v2} inputRef={inputPayForRef} currencyRef={currencyInputRef} />
        </Form>
      </Modal>
      <Modal
        visible={visibleCheck}
        onClose={handleClose}
        footer={<></>}
        noneBodyStyle
        noneFooterStyle
        hiddenHeader
        className='modal-check'
        width={'auto'}
      >
        <BoxContainer>
          <Form form={formCheck} onFinish={handleSubmitFormCheck} >
            <CheckUI isCashier />
            <FormItem noStyle shouldUpdate>
              {() => {
                return (
                  <Buttons align={'middle'} justify={'center'}>
                    <Button ntype='SECONDARY' onClick={handleCancel}>Cancel</Button>
                    <Button ntype='PRIMARY' onClick={() => {
                      noReceipt.current = false;
                      formCheck.submit();
                    }}>Done</Button>
                  </Buttons>
                );
              }}
            </FormItem>
          </Form>
        </BoxContainer>
      </Modal>
      <SimpleQRModal ref={modalRef} onDone={handleDoneQRPayment} onCancel={handleCancelQRPayment} />
    </>
  );
};

export default CheckOthers;

const BoxContainer = styled.div`
  background-color: #E3F1E0;
  max-width: 85.5rem;
  width: 90vw;
  padding: 1rem;
`;

const Buttons = styled(Row)`
margin-top: 1.5rem;
margin-bottom: 0.5rem;
gap: 1.5rem;
button {
  width: 8.25rem;
}
`;

