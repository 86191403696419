import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IServiceItemData } from 'services/shop/types/categories';
import { ISimpleMenuItem, ISimpleMenuServiceItem } from 'services/shop/types/simpleMenu';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CategoryItem from './CategoryItem';
type Props = {
  className?: string;
  large?: boolean;
};
const ActiveSimpleMenu = ({ className, large }: Props) => {
  const dispatch = useAppDispatch();
  const simpleMenu = shopSelectors.data.simpleMenu();
  const selectedServices = checkInSelectors.getSelectedServices();

  const serviceIdsSelected = selectedServices.map(o => o.id);

  const data = useMemo(() => {
    const max = 3;
    const arr: ISimpleMenuItem[][] = Array.from(Array(max), () => ([]));
    let count = 0;
    simpleMenu.map((o) => {
      arr[count].push(o);
      if (count === max - 1) {
        count = 0;
        return;
      }
      count++;
    });

    return arr;
  }, [simpleMenu]);

  const handler = (action: 'addService' | 'removeService') => (o: ISimpleMenuServiceItem) => {
    const data: IServiceItemData = {
      catId: 'simple-menu',
      commission: 0,
      priceCost: o.price,
      duration: 0,
      id: o.id,
      priceSell: o.price,
      serviceName: o.serviceName,
    };
    dispatch(checkInActions[action](data));
  };

  return (
    <div className={className}>
      <ActiveSimpleMenuStyled className={`${large ? 'large' : ''}`}>
        {data.map((o, i) => (
          <div className='categories' key={i}>
            {o.map(o => (<div key={o.categoryId} className='col-item'>
              <CategoryItem
                data={o}
                serviceIdsSelected={serviceIdsSelected}
                onClickService={handler('addService')}
                onRemoveService={handler('removeService')}
              /></div>))}
          </div>
        ))}
      </ActiveSimpleMenuStyled>
    </div>
  );
};

export default ActiveSimpleMenu;

const ActiveSimpleMenuStyled = styled.div`
  flex:1;
  display: grid;
  gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;
  &.large {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 24px;
  }
  background: #fff;
  .categories {
    display: flex;
    flex-direction: column;
    gap: 0px;
    background: #fff;
  }
`;
