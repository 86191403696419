import React, { CSSProperties } from 'react';
import Box from 'components/Box';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import IconAndPrice, { IIconAndPriceProps } from './IconAndPrice';
export interface IListTotalProcessReport {
  totalSaleService: number
  totalSaleProduct: number
  totalSaleVIP: number
  totalSaleGiftCard: number
  totalCash: number
  totalCreditCard: number
  totalCheckOther: number
  totalGiftCard: number
  totalRewardPoint: number
  totalRedeemPoint: number
}

const labelStyle: CSSProperties = {
  fontSize: '1.5rem',
};

type IProcessReportProps = {
  list: IIconAndPriceProps[];
  listTotal: IListTotalProcessReport;
};
const ProcessReportV2: React.FC<IProcessReportProps> = ({ list, listTotal }) => {
  return (
    <ContainerStyled className={'v2'}>
      <ProcessReportStyled>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
          {list.map((item, index) => (
            <div key={item.title + index} className="item-ticket-price">
              <IconAndPrice
                progress={item.progress}
                isHideCurrency={item?.isHideCurrency}
                price={item.price}
                title={item.title}
                labelStyle={labelStyle}
                trailColor={'#505050'}
              />
            </div>
          ))}
        </div>
      </ProcessReportStyled>

      <TotalBox className='TotalBox' style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr' }}>
        <Box justifyContent='center' alignItems='center' style={{ minWidth: '100px' }}>
          <Text variant="H7" color="text_3">
            Cash:
          </Text>
          <TextPrice className='TotalBox_TextPrice' variant="H9" color="text_3" isIncrease={listTotal?.totalCash >= 0}>
            {formatCurrency(listTotal?.totalCash, true)}
          </TextPrice>
        </Box>
        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            Credit Cards:
          </Text>
          <TextPrice className='TotalBox_TextPrice' variant="H9" color="text_3" isIncrease={listTotal?.totalCreditCard >= 0}>
            {formatCurrency(listTotal?.totalCreditCard, true)}
          </TextPrice>
        </Box>
        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            Redeemed Gift Cards:
          </Text>
          <TextPrice className='TotalBox_TextPrice' variant="H9" color="text_3" isIncrease={listTotal?.totalGiftCard >= 0}>
            {formatCurrency(listTotal?.totalGiftCard, true)}
          </TextPrice>
        </Box>
        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            REDEEMED LOYALTY POINTS:
          </Text>
          <TextPrice className='TotalBox_TextPrice' variant="H9" color="text_3" isIncrease={listTotal?.totalRedeemPoint >= 0}>
            {formatCurrency(listTotal?.totalRedeemPoint, true)}
          </TextPrice>
        </Box>
        <Box justifyContent='center' alignItems='center'>
          <Text variant="H7" color="text_3">
            Check / Other(s):
          </Text>
          <TextPrice className='TotalBox_TextPrice' variant="H9" color="text_3" isIncrease={listTotal?.totalCheckOther >= 0}>
            {formatCurrency(listTotal?.totalCheckOther, true)}
          </TextPrice>
        </Box>
      </TotalBox>
    </ContainerStyled>
  );
};

export default ProcessReportV2;
const ContainerStyled = styled.div`
  overflow: auto;
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: ${colorTheme.fill_5};
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  &.v2 {
    background: #fff;
    border: 1px solid #CCD4DC;
    box-shadow: none;
    .TotalBox {
      border-top: 1px solid #CCD4DC;
    }

    .StyledTitle {
      align-self: stretch;
      color: #505050;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
    }
    .PriceBoxText {
      align-self: stretch;
      color: #1D2129;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .TotalBox_TextPrice {
      align-self: stretch;
      color: #505050;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px;
      text-transform: capitalize;
    }
  }
`;
const ProcessReportStyled = styled.div`
  // display: flex;
  gap: 1rem;
  padding: 1rem;
  .item-ticket-price {
  }
  .item-ticket-price:last-child {
    border-right: none;
  }
`;
const TotalBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid ${colorTheme.line_3};
  gap: 1.5rem;
  flex-wrap: wrap;
`;

const TextPrice = styled(Text) <{ isIncrease?: boolean; }>`
  color: ${props => props?.isIncrease ? '#6FABB6' : '#F53F3F'};
`;
