import { Col, Row } from 'antd';
import BoundingBox from 'components/BoundingBox';
import Button from 'components/Button';
import CheckInServiceItem from 'components/ServiceItem/CheckIn';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { IServiceCategory } from 'features/cashier/services/types/api';
import { ButtonGroupFooterStyled } from 'features/checkIn/components/ButtonGroupFooter';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { ECheckInType, IConfirmCheckInParams } from 'features/checkIn/services/types/api';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import ActiveSimpleMenu from './ActiveSimpleMenu';
import Box from 'components/Box';

interface IServicePartProps {
  color?: keyof typeof colorTheme;
  isModelFullSimpleMenu?: boolean;
}
const ServicePart: React.FC<IServicePartProps> = ({ isModelFullSimpleMenu }) => {
  const dispatch = useAppDispatch();
  const category = checkInSelectors.getActiveCategory();
  const selectedServices = checkInSelectors.getSelectedServices();

  const checkInData = checkInSelectors.getCheckedInData();
  const walkInData = checkInSelectors.getWalkInData();
  const selectedStaff = checkInSelectors.getSelectedStaff();
  const confirmedWalkIn = checkInSelectors.getConfirmedCheckIn();
  const allSetting = shopSelectors.data.allSetting();
  const activeSimpleMenu = checkInSelectors.activeSimpleMenu();
  const enableCheckIn = shopSelectors.data.simpleMenuConfigCheckIn();

  const navigate = useNavigate();
  const { shop_id = '' } = useParams();

  const clickDone = () => {
    const confirmCheckIn: IConfirmCheckInParams = {
      customerId: checkInData.data?.id ?? '',
      checkInType: ECheckInType.WALK_IN,
      staffId: selectedStaff?.staffId ?? '',
      group: walkInData.numOfMembers,
      serviceIds: selectedServices.map(o => o.id),
    };
    dispatch(checkInActions.confirmWalkIn.fetch(confirmCheckIn));
  };

  const clickBack = () => {
    navigate(-1);
  };

  const clickNext = () => {
    navigate(`/store/${shop_id}/check-in/staff`);
  };

  const handle = (addOnItem: IServiceCategory, action: 'addService' | 'removeService') => () => {
    dispatch(checkInActions[action](addOnItem));
  };

  useEffect(() => {
    if (confirmedWalkIn) {
      navigate(`/store/${storage.shop_id.get()}/check-in/ticket-detail`);
    }
  }, [confirmedWalkIn]);

  if (isModelFullSimpleMenu) return (
    <IServicePartStyled className='full-style'>
      <BoundingBox className="title-bounding-box" color="info_bg_3">
        <Text variant="H7">SERVICES</Text>
      </BoundingBox>

      <div className="row-box container-services">
        <ActiveSimpleMenu large className='ActiveSimpleMenu' />
      </div>
      <ButtonGroupFooterStyled className='ButtonGroupFooterStyled'>
        <Button onClick={clickBack} className="btn" icon="back">
          Back
        </Button>
        {allSetting?.skipChooseStaffCheckIn ?
          <Button
            onClick={clickDone}
            className="btn reverse skip"
            icon="rightDoubleArrow"
            ntype="PRIMARY"
          >
            Done
          </Button> :
          <Button
            onClick={clickNext}
            className="btn reverse"
            icon="next"
            ntype='PRIMARY'
          >
            Next
          </Button>
        }

      </ButtonGroupFooterStyled>
    </IServicePartStyled>
  );

  return (
    <IServicePartStyled>
      <BoundingBox className="title-bounding-box" color="info_bg_3">
        <Text variant="H7">SERVICES</Text>
      </BoundingBox>

      <div className="row-box container-services">
        {enableCheckIn && <ActiveSimpleMenu className={`service-grid ${activeSimpleMenu ? 'show-block' : 'hide'}`} />}
        <Box display='flex' alignItems='start' justifyContent='center'>
          <Row gutter={[24, 16]} className={`service-grid ${enableCheckIn ? !activeSimpleMenu ? 'show' : 'hide' : ''}`}>
            {category?.services?.map((item) => {
              return (
                <Col key={item.id} span={12}>
                  <CheckInServiceItem
                    size="LARGE"
                    price={item.priceSell}
                    service={item.serviceName}
                    onClick={handle(item, 'addService')}
                    onPlus={handle(item, 'addService')}
                    onMinus={handle(item, 'removeService')}
                    amount={selectedServices.filter(o => o.catId !== 'simple-menu' && o.id === item.id)?.length || 0}
                    enablePriceService={allSetting?.enablePriceService}
                  />
                </Col>
              );
            })}
          </Row>
        </Box>
      </div>
      <ButtonGroupFooterStyled className='ButtonGroupFooterStyled'>
        <Button onClick={clickBack} className="btn" icon="back">
          Back
        </Button>
        {allSetting?.skipChooseStaffCheckIn ?
          <Button
            onClick={clickDone}
            className="btn reverse skip"
            icon="rightDoubleArrow"
            ntype="PRIMARY"
          >
            Done
          </Button> :
          <Button
            onClick={clickNext}
            className="btn reverse"
            icon="next"
            ntype='PRIMARY'
          >
            Next
          </Button>
        }

      </ButtonGroupFooterStyled>
    </IServicePartStyled>
  );
};

interface IServicePartStyled {
  color?: keyof typeof colorTheme;
}
const IServicePartStyled = styled.div<IServicePartStyled>`
  height: 100vh;
  width: 65vw;
  display: flex;
  flex-direction: column;
  &.full-style {
    height: 100vh;
    width: 100%;
    padding: 0;
    .container-services {
      flex: 1;
      background: #fff;
      width: 100%;
      .ActiveSimpleMenu {
        width: 80%;
        .large {
          width: 100%;
        }
      }
    }
  }
  
  .title-bounding-box {
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }
  .title-bounding-box .H7 {
    margin-left: 2.5rem;
  }

  .row-box {
    width: 100%;
    max-height: 72%;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
  }
  .service-grid {
    margin: 2rem 2rem;
    width: 98%;
  }

  .container-services {
    flex: 1;
    max-height: unset;
  }
  .ButtonGroupFooterStyled {
    padding: 16px;
    padding-bottom: 24px;
    margin: 0px;
  }

  .show {
    display: flex;
  }
  .show-block {
    display: block;
  }
  .hide {
    display: none;
  }
`;
export default ServicePart;
