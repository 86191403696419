import React from 'react';

const VoidedBtnV2 = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12930_1332)">
        <path d="M40 10.5007C31.4401 10.5007 23.7258 14.1231 18.2551 19.8932C18.2163 19.9266 18.1678 19.9385 18.1312 19.9751C18.073 20.0333 18.0526 20.1108 17.9987 20.1733C13.0515 25.5255 10 32.655 10 40.5007C10 57.0421 23.4565 70.4997 39.9989 70.4997C56.5392 70.4997 69.9978 57.0421 69.9978 40.5007C69.9989 23.9583 56.5403 10.5007 40 10.5007ZM14.8352 40.4997C14.8352 34.7748 16.7794 29.5055 20.0118 25.2713L55.224 60.4867C50.991 63.7192 45.7249 65.6634 39.9989 65.6634C26.1234 65.6634 14.8352 54.3741 14.8352 40.4997ZM58.7742 57.1961L23.3014 21.7265C27.7499 17.7648 33.589 15.3349 40 15.3349C53.8744 15.3349 65.1637 26.6231 65.1637 40.4997C65.1626 46.9107 62.7337 52.7508 58.7742 57.1961Z" fill="#1D2129" />
      </g>
      <defs>
        <clipPath id="clip0_12930_1332">
          <rect width="60" height="60" fill="white" transform="translate(10 10.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const VoidedBtnNormal = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12984_3504)">
        <path d="M40 10.5007C31.4401 10.5007 23.7258 14.1231 18.2551 19.8932C18.2163 19.9266 18.1678 19.9385 18.1312 19.9751C18.073 20.0332 18.0526 20.1108 17.9987 20.1733C13.0515 25.5255 10 32.655 10 40.5007C10 57.0421 23.4565 70.4996 39.9989 70.4996C56.5392 70.4996 69.9978 57.0421 69.9978 40.5007C69.9989 23.9583 56.5403 10.5007 40 10.5007ZM14.8352 40.4996C14.8352 34.7747 16.7794 29.5055 20.0118 25.2713L55.224 60.4867C50.991 63.7192 45.7249 65.6634 39.9989 65.6634C26.1234 65.6634 14.8352 54.374 14.8352 40.4996ZM58.7742 57.1961L23.3014 21.7265C27.7499 17.7648 33.589 15.3348 40 15.3348C53.8744 15.3348 65.1637 26.6231 65.1637 40.4996C65.1626 46.9107 62.7337 52.7508 58.7742 57.1961Z" fill="#B67650" />
      </g>
      <defs>
        <clipPath id="clip0_12984_3504">
          <rect width="60" height="60" fill="white" transform="translate(10 10.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default VoidedBtnV2;
