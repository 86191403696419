import { Select } from 'antd';
import Icon from 'assets/Icons';
import ActionBtn from 'components/ActionBtn';
import Box from 'components/Box';
import Modal from 'components/Modal';
import Text from 'components/Text';
import cashierApis from 'features/cashier/services/apis';
import cashierSelectors from 'features/cashier/services/selectors';
import userApis from 'features/user/services/apis';
import { IBillWaitingTicketResItem } from 'features/user/services/types/ticket';
import useRefreshScreen from 'hooks/useRefreshPage';
import React, { useMemo, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import SelectMerge from '../SelectMerge';
import MergeTicketModalV2 from './MergeTicketModalV2';
import mergeIcon from './merge.svg';
const { Option } = Select;
type IMergeTicketButtonProps = {
  v2?: boolean;
};

const MergeTicketButton: React.FC<IMergeTicketButtonProps> = ({ v2 }) => {
  const setPageLoading = useSetLoadingPage();
  const ticketDetail = cashierSelectors.getInfoBillTicketDetail();
  const ticketDetailStore = cashierSelectors.getTicketDetails();

  const [open, setOpen] = useState(false);
  const refreshScreen = useRefreshScreen();

  const [selected, setSelected] = useState<IBillWaitingTicketResItem[]>([]);
  const [waitingList, setWaitingList] = useState<IBillWaitingTicketResItem[]>([]);
  const [loading, setLoading] = useState(false);

  const waitingTickets = useMemo(() => {
    if (v2) return waitingList;
    return waitingList.filter(ticket => {
      if (ticket.billId === ticketDetail?.billId) return false;

      const exist = selected.find(o => o.billId === ticket.billId);
      if (exist) return false;

      return true;
    });
  }, [waitingList, selected, ticketDetail, v2]);

  const handleChooseTicket = (id: string) => {
    const exist = selected.find(o => o.billId === id);
    if (exist) return;
    const ticketData = waitingList.find(o => o.billId === id);
    if (!ticketData) return;
    const tickets = [...selected];
    tickets.push(ticketData);
    setSelected(tickets);
  };

  const handleDelete = (id: string) => {
    const tickets = [...selected].filter(o => o.billId !== id);
    setSelected(tickets);
  };

  const handleShowModal = () => {
    getList();
    setOpen(true);
  };
  const handleCloseModal = () => {
    setSelected([]);
    setWaitingList([]);
    setOpen(false);
  };
  const handleSubmitModal = async () => {
    setPageLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await cashierApis.mergeTickets({
        currentBillId: ticketDetail?.billId || '',
        lstBillMerge: selected.filter(o => o.billId !== ticketDetail?.billId).map(o => o.billId)
      });
      if (res.data.data) refreshScreen();
    } catch (error) { } finally {
      setPageLoading(false);
      setOpen(false);
    }
  };

  const getList = async () => {
    setLoading(true);
    try {
      const res: IResponseDataBody<IBillWaitingTicketResItem[]> = await userApis.getBillWaitingTicket();
      if (res.data.data) {
        setWaitingList(res.data.data);
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      {!v2 ? <ActionBtn
        ntype="SECONDARY"
        icon="merge"
        label='Merge Tickets'
        onClick={handleShowModal}
        disabled={!!ticketDetailStore?.checkInId || !ticketDetailStore?.items?.length}
      /> : <ButtonMergeTicketV2Styled type='button' onClick={handleShowModal}
        disabled={!!ticketDetailStore?.checkInId || !ticketDetailStore?.items?.length}
      >
        <img src={mergeIcon} alt="mergeIcon" className="button-icon" />
        <span>Merge Tickets</span>
      </ButtonMergeTicketV2Styled>}
      {v2 ? <MergeTicketModalV2
        loading={loading}
        onChooseTicket={handleChooseTicket}
        selected={selected}
        ticketDetail={ticketDetail}
        tickets={waitingTickets}
        onClose={handleCloseModal}
        onSubmit={handleSubmitModal}
        open={open}
        onDelete={handleDelete}
      /> :
        <Modal
          modalTitle="MERGE TICKETS"
          visible={open}
          onClose={handleCloseModal}
          onSubmit={handleSubmitModal}
          okTitle="Done"
          noneBodyStyle
          containerPadding={1.5}
          disableOk={selected.length === 0}
        >
          <Box>
            <Box mb="1" px="11">
              <Text variant="CONTENT_1" color="text_3">
                Waiting List
              </Text>
            </Box>
            <Box mb="3" px="11">
              <SelectMerge
                placeholder="Ticket number/Name/Phone"
                allowClear={false}
                size="large"
                defaultActiveFirstOption={false}
                value={''}
                onChange={(val) => handleChooseTicket(val)}
              >
                {waitingTickets.map((item) => (
                  <Option
                    key={item?.billId}
                    value={item?.billId}
                  >
                    <Box
                      className="space-between box-option"
                      py="2"
                      px="3"
                      height={'2.5rem'}
                    >
                      <Text>#{item?.ticketNumber}-{item?.customerName || 'unknown'}</Text>
                      <Text>{maskPhone(item?.customerPhone || '')}</Text>
                    </Box>
                  </Option>
                ))}
              </SelectMerge>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="5"
              height={'21rem'}
              overflow="auto"
              px="11"
            >
              <TicketBox item={ticketDetail} />
              {selected
                .map((item) => (
                  <TicketBox key={item.billId} item={item} onDelete={() => handleDelete(item.billId)} />
                ))}
            </Box>
          </Box>
        </Modal>
      }
    </>
  );
};

export default MergeTicketButton;
export const ButtonMergeTicketV2Styled = styled.button`
display: flex;
padding: 0px 24px;
justify-content: flex-start;
align-items: center;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
border-radius: 4px;
background: #FCFCFD;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
img.button-icon {
  height: 35px;
  width: 35px;
}
span {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  white-space: nowrap;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;
const TicketBox = ({ item, onDelete }: { item: IBillWaitingTicketResItem, onDelete?: () => void; }) => (
  <Box
    py="2"
    px="3"
    display="flex"
    flexDirection="column"
    gap="2"
    bgColor="fill_3"
    borderRadius="5px"
    position="relative"
    className='prevent-select'
  >
    {!!onDelete && (
      <Box
        position="absolute"
        top="-1rem"
        right="-1rem"
      >
        <button onClick={onDelete}>
          <Icon type="circleClose" />
        </button>
      </Box>
    )}
    <Box className="space-between">
      <Text variant="H9" color="text_3">
        {item?.ticketNumber} - {item.customerName || 'Unknown'}
      </Text>
      <Text variant="H9" color="text_3">
        {formatCurrency(item?.totalPrice)}
      </Text>
    </Box>
    {item.staffs?.map((staff) => (
      <Box key={staff?.staffId}>
        <Box bb="line_3">
          <Text variant="CONTENT_2" color="text_3">
            {staff?.staffName}
          </Text>
        </Box>
        <Box py="2" display="flex" flexDirection="column" gap="2">
          {staff?.services?.map((service: any) => (
            <Box className="space-between" key={service.itemId}>
              <Text variant="BODY_1" color="text_3">
                {service.itemName}
              </Text>
              <Text variant="BODY_1" color="text_3">
                {formatCurrency(service.price)}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    ))}
  </Box>
);
