import appointmentActions from 'features/appointment/services/actions';
import appointmentSelectors from 'features/appointment/services/selectors';
import { ICalendarItemData } from 'features/appointment/services/types/calendar';
import { useAppDispatch } from 'store/hooks';

const useAnybodyVisible = () => {
  const dispatch = useAppDispatch();
  const visible = appointmentSelectors.anybodyBox.open();
  const setVisible = (value: boolean) => {
    dispatch(appointmentActions.anybodyBox.setOpen(value));
  };
  const setData = (data: ICalendarItemData[]) => {
    dispatch(appointmentActions.anybodyBox.setAnybodyAppointmentList(data));
  };

  const open = () => {
    setVisible(true);
  };
  const close = () => {
    setVisible(false);
  };

  return { visible, open, close, setVisible, setData };
};

export default useAnybodyVisible;
