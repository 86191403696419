import { DATE_FORMAT } from 'features/appointment/utils/format';
import reportActions from 'features/report/services/actions';
import { IParamsReviewReport } from 'features/report/services/types/review';
import { CALENDAR_PICKER_TYPE } from 'features/settingStaff/services/constants';
import { isArray } from 'lodash';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';

const useDateFilter = () => {
  const dispatch = useAppDispatch();
  const [viewType, setViewType] = useState<string | number>(
    CALENDAR_PICKER_TYPE.DATE
  );
  const convertStartEndDate = (date: moment.Moment, type: string | number) => {
    switch (type) {
      case 'week': {
        const startDate = date.startOf('week').format(DATE_FORMAT);
        const endDate = date.endOf('week').format(DATE_FORMAT);
        return {
          startDate,
          endDate,
        };
      }

      case 'month': {
        const startDate = date.startOf('month').format(DATE_FORMAT);
        const endDate = date.endOf('month').format(DATE_FORMAT);
        return {
          startDate,
          endDate,
        };
      }

      default: {
        const startDate = moment(date).format(DATE_FORMAT);
        const endDate = moment(date).format(DATE_FORMAT);
        return {
          startDate,
          endDate,
        };
      }
    }
  };

  const handleChangeDate = (date: Moment | RangeValue<Moment>) => {
    if (viewType == CALENDAR_PICKER_TYPE.WEEK && isArray(date) || isArray(date)) {
      const params: IParamsReviewReport = {
        startDate: moment(date?.[0]).format(DATE_FORMAT),
        endDate: moment(date?.[1]).format(DATE_FORMAT),
      };
      dispatch(reportActions.setReviewReportParams(params));
    } else {
      if (date) {
        const dateConvert = convertStartEndDate(date, viewType);
        const params: IParamsReviewReport = {
          startDate: dateConvert.startDate,
          endDate: dateConvert.endDate,
        };
        dispatch(reportActions.setReviewReportParams(params));
      }
    }
  };

  const handleChangeTypeView = (type: string | number) => {
    const date = moment();
    setViewType(type);
    const params: IParamsReviewReport = {
      startDate: convertStartEndDate(date, type).startDate,
      endDate: convertStartEndDate(date, type).endDate,
    };
    dispatch(reportActions.setReviewReportParams(params));
  };

  return ({
    viewType,
    handleChangeTypeView,
    handleChangeDate,
  });
};

export default useDateFilter;
