import { ColumnsType } from 'antd/es/table';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Table from 'components/Table';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { Bill, StaffItem } from 'features/ticketList/services/types/api';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
const typeToIcon = (type: PAYMENT_TYPE, last4digit?: string) => {
  switch (type) {
    case PAYMENT_TYPE.CREDIT_CARD:
      return (
        <Box className="center" gap="1">
          <Icon type="card" />
          <Text variant="CONTENT_2" color="text_3">
            {last4digit || '--'}
          </Text>
        </Box>
      );

    case PAYMENT_TYPE.LOYALTY_POINT:
      return <Icon type="loyaltyPoint" />;

    case PAYMENT_TYPE.GIFT_CARD:
      return <Icon type="giftCardDefault" />;

    case PAYMENT_TYPE.CHECK:
      return <Icon type="epay" />;

    default:
      return <Icon type="cash" />;
  }
};
type ITransactionsTabProps = {};
const TransactionsTab: React.FC<ITransactionsTabProps> = () => {
  const dispatch = useAppDispatch();
  const customerDetails = customerSelectors.getCustomerDetails();
  const data = customerSelectors.transactionTableData.data();
  const loading = customerSelectors.transactionTableData.loading();
  const params = customerSelectors.transactionTableData.params();
  const totalElement = customerSelectors.transactionTableData.totalElement();

  const baseColumns: ColumnsType<Bill> = [
    {
      title: 'Date',
      dataIndex: 'startTime',
      align: 'left',
      render(startTime: string) {
        return (
          <Text variant="BODY_1" color="text_3">
            {startTime ? momentTimezone(startTime).format('MM-DD-YYYY') : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Ticket Number',
      dataIndex: 'ticketNumber',
      align: 'left',
      width: '10rem',
      render(text) {
        return (
          <Text variant="BODY_1" color="text_3">
            {text ? `#${text}` : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Services',
      dataIndex: 'items',
      align: 'left',
      render(items: StaffItem[]) {
        const allItemNames: string[] = items.flatMap((item) =>
          item.services.map((service) => service.itemName)
        );
        const concatenatedString: string = allItemNames.join(', ');
        return (
          <Text style={{ width: '10rem' }} variant="BODY_1" color="text_3" textAlign='left'>
            {concatenatedString || '--'}
          </Text>
        );
      },
    },
    {
      title: 'Staff',
      dataIndex: 'items',
      align: 'left',
      render(items: StaffItem[]) {
        const allStaffNames: string[] = items.map((item) => item.staffName);
        const concatenatedStaffNames: string = allStaffNames.join(', ');
        return (
          <BoxMarginStyled width={'5rem'}>
            <Text variant="BODY_1" color="text_3">
              {concatenatedStaffNames || '--'}
            </Text>
          </BoxMarginStyled>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'checkInType',
      align: 'left',
      render(value) {
        return (
          <Text variant="BODY_1" color="text_3">
            {value}
          </Text>
        );
      },
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      align: 'left',
      render(total: number) {
        return (
          <Text variant="BODY_1" color="text_3">
            {formatCurrency(total)}
          </Text>
        );
      },
    },
    {
      title: 'Payments',
      dataIndex: '',
      align: 'left',
      render(_: any, record: Bill) {
        const payments = record.payments;
        return (
          <Box>
            {payments?.map((paymentItem) => {
              return (
                <div
                  key={paymentItem.paymentId}
                  style={{ display: 'flex', alignItems: 'center', height: 30 }}
                >
                  {typeToIcon(paymentItem.paymentType, paymentItem.last4)}
                </div>
              );
            })}
          </Box>
        );
      },
    },
  ];

  const handlePageChange = async (page?: number) => {
    const params = {
      page: page,
    };
    dispatch(customerActions.setTransactionsParams(params));
  };

  useEffect(() => {
    if (customerDetails?.id) {
      const params = {
        customerId: customerDetails?.id || '',
      };
      dispatch(customerActions.setTransactionsParams(params));
    }
  }, [customerDetails?.id]);

  return (
    <TransactionsTabStyled>
      <Table
        loading={loading}
        columns={baseColumns}
        dataSource={data}
        rowKey={'billId'}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
          showSizeChanger: false,
        }}
        handleChangePage={handlePageChange}
      />
    </TransactionsTabStyled>
  );
};

export default TransactionsTab;
const TransactionsTabStyled = styled.div``;

const BoxMarginStyled = styled.div<{ width: string }>`
  margin: auto;
  width: ${({ width }) => width};
`;
