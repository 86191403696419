import { periodOptions, PeriodValues } from 'features/appointment/components/InputPeriod';
import React from 'react';
import styled from 'styled-components';
import tick_icon from './check.svg';
import clsx from 'clsx';

type Props = {
  value?: PeriodValues;
  onChange?: (value: PeriodValues) => void;
};
const Repeat = ({ value, onChange = () => undefined }: Props) => {
  return (
    <Container>
      <div className="form-label">Repeat</div>
      <RepeatItems>
        {periodOptions.map(o => <RepeatItem checked={o.value === value} onClick={() => onChange(o.value)} key={o.value} label={o.label} />)}
      </RepeatItems>

    </Container>
  );
};

export default Repeat;
const Container = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
align-self: stretch;
border-bottom: 1px solid #86909C;
.form-label {
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
`;
const RepeatItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  .btn-repeat {
    display: flex;
    padding: 4px 8px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    background: #E5E6EB;
    gap: 8px;
    span {
      color: #505050;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      white-space: nowrap;
    }

    .checkbox {
      display: flex;
      width: 32px;
      height: 32px;
      padding: 3px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 100px;
      border: 1px solid #86909C;
      background: #F5F5F5;
      img.icon {
        width: 100%;
        height: 100%;
      }
      &.checked {
        border: 1px solid #2D6772;
        background: #2D6772;
      }
    }
  }
`;
type RepeatItemProps = {
  onClick: () => void;
  label: string;
  checked?: boolean;
};
const RepeatItem = ({ label, onClick, checked }: RepeatItemProps) => {
  return <button type='button' className='btn-repeat' onClick={onClick}>
    <span>{label}</span>
    <div className={clsx('checkbox', checked && 'checked')}>
      <img style={{ opacity: checked ? 1 : 0 }} src={tick_icon} alt="tick_icon" className='icon' />
    </div>
  </button>;
};
