import { Col, Row } from 'antd';
import AvatarBox from 'components/AvatarBox';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import StaffSearchInput from '../ChooseStaff/StaffSearchInput';
import { ButtonActions } from './helpers';
type IStaffTabProps = {
  staff: IStaffItemData | null;
  onPickStaff: (val: IStaffItemData) => void;
  next: () => void;
  back: () => void;
};
const StaffTab: React.FC<IStaffTabProps> = ({ staff, next, back, onPickStaff }) => {
  const staffs = shopSelectors.data.staffs();
  const handleBack = () => back();
  const handleNext = () => next();

  const handlePickStaff = (val: IStaffItemData) => {
    onPickStaff(val);
    handleNext();
  };
  return (
    <>
      <StaffTabStyled>
        <StaffSearchInput onPickStaff={onPickStaff} />
        <StaffContainerStyled justify="start" gutter={[16, 16]}>
          {staffs.map(o => (
            <Col
              key={o.id}
              span={4}
              onClick={() => handlePickStaff(o)}
              className='staff-item'
            >
              <AvatarBox
                anybody={o.anyBody}
                src={o.urlImage}
                name={o.firstName}
                selected={staff?.id === o.id}
              />
            </Col>
          ))}
        </StaffContainerStyled>
      </StaffTabStyled>
      <ButtonActions onBack={handleBack} onOk={handleNext} disabledOk={!staff} />
    </>
  );
};

export default StaffTab;
type StaffTabStyledProps = {}
const StaffTabStyled = styled.div<StaffTabStyledProps>``;
const StaffContainerStyled = styled(Row)`
  overflow-y: auto;
  max-height: 40vh;
  padding-top: 1rem;
  .staff-item {
    display: flex;
    justify-content: center;
  }
`;
