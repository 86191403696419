import { Avatar, Button, Dropdown, Input, MenuProps, Rate } from 'antd';
import SearchIcon from 'assets/Icons/search';
import Empty from 'components/Empty';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import userSelectors from 'features/user/services/selectors';
import useAddNewTicket from 'hooks/useAddNewTicket';
import useDebounce from 'hooks/useDebounce';
import { get, sortBy } from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import Summary from './Summary';
import { EMainLayout } from 'features/user/services/constants';
import { HOME_SORT_BY } from 'services/shop/constants';
import { userUIActions } from 'features/user/services/reducers';
import Box from 'components/Box';
import Icon from 'assets/Icons';
import shopSelectors from 'services/shop/selectors';
import { IShopAllSetting } from 'services/shop/types/setting';
import userApis from 'features/user/services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import EmptyTicketV2 from 'features/user/widgets/WaitingList/EmptyTicket';
import moment from 'moment';
import { momentTimezone } from 'utils/time';
import userActions from 'features/user/services/actions';
import { aptUIActions } from 'features/appointment/services/reducers';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import { cashierUIActions } from 'features/cashier/services/reducers';

type IStaffAvailableProps = {
  mode: 'staff' | 'ticket' | 'default';
  v2?: boolean;
};

const items: MenuProps['items'] = [
  {
    label: 'Sort by staff available',
    key: HOME_SORT_BY.AVAILABLE,
  },
  {
    label: 'Sort by clock time',
    key: HOME_SORT_BY.CLOCK_IN,
  },
  {
    label: 'Sort by turn',
    key: HOME_SORT_BY.TURN,
  },
  {
    label: 'Sort by appointment',
    key: HOME_SORT_BY.APPOINTMENT,
  },
  {
    label: 'Sort by amount',
    key: HOME_SORT_BY.AMOUNT,
  },
];

const StaffAvailable: React.FC<IStaffAvailableProps> = ({ v2 }) => {
  const modal = useModalConfirmRef();
  const _staffListStore = userSelectors.listAvailableWorking();
  const dispatch = useAppDispatch();
  const getNumberTicket = useAddNewTicket(modal);
  const containerRef = useRef<HTMLDivElement>(null);
  const sort = userSelectors.homeSort();
  const setSort = (value: string) => dispatch(userUIActions.setHomeSort(value as HOME_SORT_BY));
  const [searchStr, setSearchStr] = useState('');
  const homeLayout = userSelectors.homeLayout();
  const [staffListPureStore, setStaffListPureStore] = useState<IStaffAvailableItem[]>(_staffListStore);
  const allSetting = shopSelectors.data.allSetting();
  const setLoading = useSetLoadingPage();
  const enableChooseEmployeeQueue = userSelectors.enableChooseEmployeeQueue();

  const onVoiceCall = (staff: IStaffAvailableItem) => {
    modal.current?.open(`Do you want to call ${staff.staffName}?`, async () => {
      setLoading(true);
      try {
        await userApis.callVoiceNotification(staff.staffId);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    setStaffListPureStore([]);
    setTimeout(() => {
      setStaffListPureStore(_staffListStore);
    }, 10);
  }, [homeLayout]);

  useEffect(() => {
    setStaffListPureStore(_staffListStore);
  }, [_staffListStore]);

  const onSearchStaff = (str: string) => {
    setSearchStr(str?.trim());
  };

  const staffList = useMemo(() => {
    return sortBy(staffListPureStore, o => {
      switch (sort) {
        case HOME_SORT_BY.AVAILABLE:
          return o.isBusy;
        case HOME_SORT_BY.APPOINTMENT:
          return o.totalAppointment;
        case HOME_SORT_BY.TURN:
          return o.turn;
        case HOME_SORT_BY.AMOUNT:
          return o.totalAmount;
        case HOME_SORT_BY.CLOCK_IN:
        default:
          return o.indexClockIn;
      }
    });
  }, [sort, staffListPureStore]);

  const _staffList = useMemo(() => {
    const searchText = searchStr?.toLocaleLowerCase();
    if (!searchText) return staffList;
    return staffList.filter(staff => {
      if (staff.staffName?.toLocaleLowerCase()?.includes(searchText)) return true;
      return false;
    });
  }, [sort, _staffListStore, searchStr, staffList, staffListPureStore]);

  const staffClick = (item: IStaffAvailableItem) => () => {
    if (enableChooseEmployeeQueue) {
      dispatch(userActions.syncWaitingToTicket({ staffId: item.staffId, checkInId: enableChooseEmployeeQueue }));
      return;
    }
    if (v2) {
      dispatch(cashierUIActions.setDraftStaffForNewTicketV2(item));
    } else {
      dispatch(cashierActions.setDraftStaffForNewTicket(item));
    }
    getNumberTicket();
  };

  return (
    <Container ref={containerRef} className={v2 ? 'v2' : ''}>
      {homeLayout === EMainLayout.FULL_STAFFS ? <>
        <div style={{ display: 'flex', gap: '0.5rem' }}>
          <div style={{
            flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between',
          }}>
            {!v2 ? <NoteList /> : null}
            <Box display={v2 ? 'flex' : undefined} style={v2 ? { alignSelf: 'stretch', background: '#fff', marginRight: '-16px' } : {}}>
              <div className='header-filter'>
                <Dropdown overlayClassName='sort-available-list' menu={{
                  items, activeKey: sort || '',
                  onClick: e => setSort(e.key),
                }} trigger={['click']}>
                  <Button onClick={e => e.preventDefault()} className={`btn-sort ${sort ? 'active' : ''}`}><SortBy /></Button>
                </Dropdown>
                <InputSearchStaff v2={v2} onChangeText={onSearchStaff} />
              </div>
              {v2 && <AppointmentCarousel />}
            </Box>
          </div>
          <div style={{ width: '60rem' }}>
            <Summary />
          </div>
        </div>
      </> : <>
        {!v2 ? <NoteList /> : null}
        <Box display={v2 ? 'flex' : undefined} style={v2 ? { alignSelf: 'stretch', background: '#fff', marginRight: '-16px' } : {}}>
          <div className='header-filter'>
            <Dropdown overlayClassName='sort-available-list' menu={{
              items, activeKey: sort || '',
              onClick: e => setSort(e.key),
            }} trigger={['click']}>
              <Button onClick={e => e.preventDefault()} className={`btn-sort ${sort ? 'active' : ''}`}><SortBy /></Button>
            </Dropdown>
            <InputSearchStaff v2={v2} onChangeText={onSearchStaff} />
          </div>
          {v2 && <AppointmentCarousel />}
        </Box>
      </>
      }
      <Text mb={1} />
      <StaffAvailableStyled className='StaffAvailableStyled'>
        {_staffList?.length === 0 ? (v2 ? <EmptyTicketV2 title='No data' /> : <Empty />) : <></>}
        <div className='staff-list' style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))' }}>
          <StaffList enableChooseEmployeeQueue={!!enableChooseEmployeeQueue} onVoiceCall={onVoiceCall} allSetting={allSetting} data={_staffList} staffClick={staffClick} />
        </div>
      </StaffAvailableStyled>
      <ModalConfirm ref={modal} okTitle='Yes' cancelTitle='No' />
    </Container >
  );
};

export default StaffAvailable;

const StaffList = ({ data, staffClick, allSetting, onVoiceCall, enableChooseEmployeeQueue }: { onVoiceCall: (staff: IStaffAvailableItem) => void, allSetting: IShopAllSetting | null, data: IStaffAvailableItem[], staffClick: (item: IStaffAvailableItem) => () => void, enableChooseEmployeeQueue?: boolean }) => {
  return (
    <>{data.map((o) => (<div key={o.staffId} className='staff-list-item' onClick={staffClick(o)} ><Staff enableChooseEmployeeQueue={enableChooseEmployeeQueue} onVoiceCall={() => onVoiceCall(o)} voiceCall={allSetting?.voiceCallStaff} data={o} /></div>))}</>
  );
};

const SortBy = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="M7.1539 11.2891H2.78784V9.28906H7.1539V11.2891ZM29.2123 11.2891H17.5302V9.28906H29.2123V11.2891Z" fill="#7B7B7B"></path><path d="M24.8461 20.7109L29.2122 20.7109L29.2122 22.7109L24.8461 22.7109L24.8461 20.7109ZM2.78768 20.7109L14.4698 20.7109L14.4698 22.7109L2.78768 22.7109L2.78768 20.7109Z" fill="#7B7B7B"></path><path d="M13.0605 13.224C14.6832 13.224 15.9986 11.9085 15.9986 10.2858C15.9986 8.66311 14.6832 7.34766 13.0605 7.34766C11.4378 7.34766 10.1223 8.66311 10.1223 10.2858C10.1223 11.9085 11.4378 13.224 13.0605 13.224ZM13.0605 15.224C15.7877 15.224 17.9986 13.0131 17.9986 10.2858C17.9986 7.55854 15.7877 5.34766 13.0605 5.34766C10.3332 5.34766 8.12231 7.55854 8.12231 10.2858C8.12231 13.0131 10.3332 15.224 13.0605 15.224Z" fill="#7B7B7B"></path><path d="M18.9395 18.7721C17.3168 18.7721 16.0014 20.0876 16.0014 21.7103C16.0014 23.333 17.3168 24.6484 18.9395 24.6484C20.5622 24.6484 21.8777 23.333 21.8777 21.7103C21.8777 20.0876 20.5622 18.7721 18.9395 18.7721ZM18.9395 16.7721C16.2123 16.7721 14.0014 18.983 14.0014 21.7103C14.0014 24.4376 16.2123 26.6484 18.9395 26.6484C21.6668 26.6484 23.8777 24.4376 23.8777 21.7103C23.8777 18.983 21.6668 16.7721 18.9395 16.7721Z" fill="#7B7B7B"></path></svg>
);

type InputSearchStaffProps = {
  onChangeText: (val: string) => void;
  v2?: boolean;
};
const InputSearchStaff = ({ v2, onChangeText }: InputSearchStaffProps) => {
  const { text, setText } = useDebounce({ onDebounce: onChangeText });
  if (v2) {
    return (
      <WrapperInputSearch>
        <Input
          value={text}
          onChange={e => setText(e.target.value)}
          suffix={<SearchIcon fill={'#fff'} />}
          placeholder='Search by Staff’s Name'
          className='search-input'
        />
      </WrapperInputSearch>
    );
  }
  return (
    <Input
      value={text}
      onChange={e => setText(e.target.value)}
      suffix={<SearchIcon />}
      placeholder='Search by Staff’s Name'
    />
  );
};
const WrapperInputSearch = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  .search-input.ant-input-affix-wrapper {
    border-radius: 100px;
    padding: 0px;
    .ant-input-suffix {
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0px 100px 100px 0px;
      background: #2D6772;
    }
    input {
      border-radius: 100px; 
      padding: 24px;
      height: 56px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      align-self: center;
    }
    border: 3px solid #2D6772;
    background: #fff;
  }
`;
const NoteList = () => {
  return (<NoteListStyled>
    <div className="box-note"><span>A: Appointment</span></div>
    -
    <div className="box-note"><span>T: Turn</span></div>
    -
    <div className="box-note"><span>C : Clock Time</span></div>
  </NoteListStyled>);
};
const NoteListStyled = styled.div`
  display: flex;
  gap: 8px;
  padding-left: 4px;
  justify-content: end;
  .box-note {
    span {
      overflow: hidden;
      color: #1D2129;
      text-overflow: ellipsis;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }
  }
`;
const Container = styled.div`
  position: absolute;
  inset: 0;
  padding: 1.125rem;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  .header-filter {
    display: flex;
    align-items: center;
    gap: 8px;
    .btn-sort {
      align-self: stretch;
      height: unset;
      &.active {
        border: 1px solid #F5767F;
      }
    }
  }
  .ant-dropdown-menu {
    .ant-dropdown-menu-item {
      padding: 1rem;
    }
  }

  &.v2 {
    padding-top: 0;
    .header-filter {
      min-width: 565px;
      background: #fff;
      margin: 0 -1rem;
      padding: 4px 16px;
      padding-right: 3px;
      height: 64px;
      min-height: 64px;
      max-height: 64px;
      overflow: hidden;
      position: relative;
      z-index: 4;
      .ant-input-affix-wrapper {
      }
    }
  }
`;
type StaffAvailableStyledProps = {}
const StaffAvailableStyled = styled.div<StaffAvailableStyledProps>`
  padding: 0px;
  flex: 1;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox 64 */
  &::-webkit-scrollbar {
    width: 0px;
  }
  .staff-list {
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr 1fr;
   
    .staff-list-item {
      &:hover {
        cursor: pointer;
      }
    }
  }
`;
type StaffProps = {
  voiceCall?: boolean;
  enableChooseEmployeeQueue?: boolean;
  data: IStaffAvailableItem;
  onVoiceCall: () => void;
};
const getStaffName = (str: string) => {
  if (!str) return '';
  if (str?.length < 8) return str;
  return str.slice(0, 6) + '...';
};
const Staff = ({ voiceCall, data, onVoiceCall, enableChooseEmployeeQueue }: StaffProps) => {
  if (voiceCall) {
    return (
      <StaffStyled className={data.isBusy ? 'busy-staff' : ''}>
        <div>
          <Avatar className="circle-avatar" src={data?.staffAvatar || 'fail'} style={{ backgroundColor: 'rgb(245 106 0 / 30%)', border: '1px solid #f56a00' }}>{get(data?.staffName, [0], '')}</Avatar>
          <Rate allowHalf disabled style={{ fontSize: 16 }} value={data?.stars || 0} />
        </div>
        <div style={{ flex: 1 }}>
          <Box display='flex' justifyContent='space-between'>
            <Box flexDirection='column' display='flex' >
              <div className="info-name-div">
                <Box display='flex'>
                  <p className="staff-name">{getStaffName(data?.staffName)}</p>
                </Box>
              </div>
              <div className="info-clock-in-time-div">
                <Text className='clock-in-time'>L: {data?.lastTimeService?.trim() ? data.lastTimeService : '--'}</Text>
              </div>
              <div className="info-c-time-div">
                <Text className='c-time-text'>C: {data?.timeClockIn?.trim() ? data.timeClockIn : '--'}</Text>
              </div>
            </Box>
            {enableChooseEmployeeQueue ?
              <button className='btn-call-staff' ><div className="icon-check-box-empty"></div></button>
              :
              <button onClick={e => [e.stopPropagation(), onVoiceCall()]} className='btn-call-staff' ><Icon type='callStaff' /></button>
            }
          </Box>
          <div className="text-overflow info-div">
            <div className="info-turn-apt">
              <div className="info-turn-apt-box appointment"><span>A: {data?.totalAppointment || 0}</span></div>
              <div className="info-turn-apt-box turn"><span>T: {data?.turn || 0}</span></div>
            </div>
          </div>
        </div>
      </StaffStyled>
    );
  }

  return (
    <StaffStyled className={data.isBusy ? 'busy-staff' : ''}>
      <div>
        <Avatar className="circle-avatar" src={data?.staffAvatar || 'fail'} style={{
          backgroundColor: 'rgb(245 106 0 / 30%)', border: '1px solid #f56a00'
        }}>{get(data?.staffName, [0], '')}</Avatar>
        <Rate allowHalf disabled style={{ fontSize: 16 }
        } value={data?.stars || 0} />
      </div>
      <div className="text-overflow info-div">
        <div className="info-name-div">
          <Box display='flex'>
            <p className="staff-name">{data?.staffName}</p>
          </Box>
          <div className="staff-index"><span>{data?.indexClockIn || 0}</span></div>
        </div>
        <div className="info-clock-in-time-div">
          <IconClock />
          <Text className='clock-in-time'>Lst: {data?.lastTimeService?.trim() ? data.lastTimeService : '--'}</Text>
        </div>
        <div className="info-c-time-div">
          <Text className='c-time-text'>C: {data?.timeClockIn?.trim() ? data.timeClockIn : '--'}</Text>
        </div>
        <div className="info-turn-apt">
          <div className="info-turn-apt-box appointment"><span>A: {data?.totalAppointment || 0}</span></div>
          <div className="info-turn-apt-box turn"><span>T: {data?.turn || 0}</span></div>
        </div>
      </div>
    </StaffStyled>
  );
};
const StaffStyled = styled.div`
  padding: 8px;
  display: flex;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #CECECE;
  gap: 4px;
  .ant-rate .ant-rate-star:not(:last-child) {
    margin-inline-end: 0;
  }
  .circle-avatar {
    height: 75px;
    width: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    .ant-avatar-string {
      color: #fff;
      font-family: Poppins;
      font-size: 30px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  &.busy-staff {
    background: #FFEFEB;
  }
  .info-div {
    flex: 1;
  }

  .info-name-div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .staff-name {
      overflow: hidden;
      color: #1D2129;
      text-overflow: ellipsis;
      font-size: 1rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
    .staff-index {
      border-radius: 100px;
      background: #1D2129;
      display: flex;
      width: 24px;
      height: 24px;
      padding: 0px 6px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      span {
        color: #fff;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .info-clock-in-time-div {
      display: flex;
      align-items: center;
      gap: 4px;
      .clock-in-time {
        color: #148CF1;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .info-c-time-div {
      display: flex;
      align-items: center;
      gap: 4px;
      .c-time-text {
        color: #7B7B7B;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        white-space: nowrap;
      }
    }

    .info-turn-apt {
      display: flex;
      align-items: center;
      align-self: stretch;
      .info-turn-apt-box {
        display: flex;
        padding: 4px 16px 4px 8px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        border-radius: 5px 0px 0px 5px;
        border-right: 1px solid #CECECE;
        background: #EEE;
        &.appointment {}
        &.turn {
          border-radius: 0px 5px 5px 0px;
          background: #8DEFAB;
        }
        span {
          overflow: hidden;
          color: #1D2129;
          text-overflow: ellipsis;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 137.5%; /* 24.75px */
        }
      }
    }


  .btn-call-staff {
    display: inline-flex;
    justify-content: flex-end;
    width: 65px;
    height: 75px;
    &:active .CallStaffIcon {
      background: #eee;
    }

    .icon-check-box-empty {
      border: 2px solid #86909C;
      background: #F5F5F5;
      border-radius: 8px;
      width: 45px;
      height: 45px;
    }
  }
`;
const IconClock = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M9.67344 0H10.2574C11.7613 0.04375 13.2586 0.414844 14.5941 1.11211C16.6289 2.16523 18.2848 3.93555 19.1887 6.04258C19.6949 7.20937 19.9605 8.47422 20 9.74375V10.257C19.9551 11.7625 19.5832 13.2602 18.8855 14.5969C17.7266 16.8387 15.693 18.6164 13.3059 19.443C12.327 19.7879 11.2934 19.9648 10.257 20H9.74531C8.23164 19.9555 6.725 19.5793 5.38242 18.8738C3.3125 17.7973 1.63867 15.9758 0.751562 13.8168C0.2875 12.7016 0.0441406 11.5004 0 10.2949V9.70938C0.0480469 8.29492 0.38125 6.8875 1.00625 5.61602C2.08008 3.41289 3.98867 1.62773 6.26602 0.71875C7.34805 0.278906 8.5082 0.0484375 9.67344 0ZM9.73984 1.31055C8.17305 1.35234 6.62109 1.83438 5.30508 2.68594C3.55117 3.80859 2.22148 5.5793 1.64805 7.58281C1.30664 8.75469 1.22148 9.99883 1.38945 11.207C1.59453 12.6957 2.20039 14.1254 3.12227 15.3117C3.90352 16.3223 4.90938 17.1594 6.04688 17.741C7.7293 18.6098 9.70195 18.8965 11.5633 18.5523C13.0711 18.282 14.4988 17.5941 15.659 16.5949C16.825 15.5945 17.7223 14.282 18.2211 12.8277C18.8516 11.0113 18.8512 8.98281 18.2195 7.1668C17.4625 4.94922 15.7648 3.07773 13.6352 2.10234C12.4227 1.53867 11.0762 1.26875 9.73984 1.31055Z" fill="#1D2129" />
    <path d="M9.34814 4.03671C9.78291 4.03632 10.2173 4.03632 10.652 4.03632C10.654 5.94374 10.6493 7.85077 10.6544 9.75819C11.761 11.0129 12.8634 12.2707 13.9685 13.5269C13.6427 13.8148 13.3157 14.1012 12.9892 14.3883C11.7884 13.0238 10.5907 11.657 9.38916 10.2937C9.34853 10.259 9.34463 10.2051 9.34697 10.1558C9.34931 8.116 9.34736 6.07616 9.34814 4.03671Z" fill="#1D2129" />
  </svg>
);



const AppointmentCarousel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const appointments = userSelectors.appointmentToday();
  const data = useMemo(() => {
    const now = moment().subtract(10, 'minute');
    return appointments.filter(o => {
      const startTime = momentTimezone(o.startTime);
      return startTime >= now;
    }).map(o => ({
      time: `${momentTimezone(o.startTime).format('hh:mm A')} - ${momentTimezone(o.endTime).format('hh:mm A')}`,
      staffName: o.staffName,
      avatar: o.avatar,
      id: o.appointmentId,
      staffId: o.staffId,
      startTime: o.startTime,
    })).reverse();
  }, [appointments]);

  const onClickAppointment = (staffId: string, startTime: string) => () => {
    dispatch(aptUIActions.syncStaffPage(staffId));
    dispatch(aptUIActions.syncTimeItem(startTime));
    navigate(`/store/${storage.shop_id.get()}/appointments`);
  };

  return <ContainerCarousel>
    <div className="carousel" style={{ animation: `moveRightForever ${data.length * 10}s linear infinite` }}>
      {data.map(o => (
        <button type='button' className='item' key={o.id} onClick={onClickAppointment(o.staffId, o.startTime)}>
          <div className="staff-container">
            <Avatar size={48} src={o.avatar}>{o.staffName?.[0] || 'A'}</Avatar>
            <Text variant='CONTENT_2'>{o.staffName}</Text>
          </div>
          <div className="time-container">
            <span>{o.time}</span>
          </div>
        </button>
      ))}
    </div>
  </ContainerCarousel>;
};
const ContainerCarousel = styled.div`
  flex: 1;
  background-color: transparent;
  position: relative;
  display: flex;
  transition: 0.1s all;
  overflow: hidden;
  .carousel {
    width: fit-content;
    display: flex;
    align-items: center;
    background-color: transparent;
    position: relative;
    animation: moveRightForever 20s linear infinite;
  }
  .item {
    height: 50px;
    color: white;
    background: #eed202;
    padding: 4px 8px;
    border-radius: 8px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    gap: 16px;
    .staff-container {
      display: flex; 
      align-items: center;
      gap: 8px;
      .CONTENT_2 {
        white-space: nowrap;
        color: #1D2129;
        white-space: nowrap;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }

    .time-container {
      background: #fff;
      padding: 4px 8px;
      border-radius: 4px;
      span {
        color: #1D2129;
        white-space: nowrap;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }


@keyframes moveRightForever {
  0% {
    transform: translateX(0);               
  }

  100% {
    transform: translateX(-100%);               
  }
}
`;
