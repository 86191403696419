import { Col, Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import { ISubmitModalDiscount } from 'features/cashier/components/Discount/DiscountMethodModal';
import paymentApis from 'features/payment/services/apis';
import { IBodyApiUpdateDiscountPayment } from 'features/payment/services/types/api';
import { useSocketContext } from 'hooks/useSocket';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import multiplePaymentActions from '../../services/actions';
import multiplePaymentSelectors from '../../services/selectors';
import DiscountButton from './DiscountButton';
import TipButton from './TipButton';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';

function HeadPayment() {
  const dispatch = useAppDispatch();
  const socketContext = useSocketContext();
  const navigate = useNavigate();

  const detailTicket = multiplePaymentSelectors.getDetail();
  const setLoadingPage = useSetLoadingPage();
  const ticket = useTicketWrapper();

  const handleDiscount: ISubmitModalDiscount = async (discount) => {
    setLoadingPage(true);
    try {
      const payload: IBodyApiUpdateDiscountPayment = {
        billId: detailTicket?.billId || '',
        discount: discount.discount || 0,
        discountSetting: discount?.discountSetting || null,
      };
      const res: IResponseDataBody<{ billId: string }> = await paymentApis.updatePaymentDiscountTicket(payload);
      if (res?.data?.data) dispatch(multiplePaymentActions.syncDetailData());
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const handleChangeTip = async (val: number) => {
    if (!detailTicket?.billId) return true;
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.updatePaymentTip({
        billId: detailTicket?.billId,
        tip: val,
      });
      if (res?.data?.data) dispatch(multiplePaymentActions.syncDetailData());
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
    return true;
  };

  const onBack = () => {
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/check-in/sign-in`);
    navigate(`/store/${storage.shop_id.get()}`);
  };

  const handlePrint = () => ticket.print(detailTicket?.billId || '');

  return (
    <HeadStyled wrap={false}>
      <Col flex={'auto'} className="box-ticket-info">
        {detailTicket && <>
          <Text mr={1} className='ticket-number'>#{detailTicket?.ticketNumber}</Text>
          <Text mr={0.5} className='customer-name' variant='H7'>{detailTicket?.customerName}</Text>
          <Text mr={0.5} className='create-date' variant='CONTENT_1' color='text_2'>{detailTicket?.startTime ? moment(detailTicket?.startTime).format('MM-DD-YYYY HH:mm A') : null}</Text>
        </>}
      </Col>
      <Col>
        <HeadActions>
          <DiscountButton detailTicket={detailTicket} onSubmit={handleDiscount} />
          <TipButton detailTicket={detailTicket} onSubmit={handleChangeTip} />
          <Button ntype='SECONDARY' icon='print' onClick={handlePrint}>
            Print
          </Button>
          <Button ntype='DEFAULT' icon='back' onClick={onBack} >
            Back
          </Button>
        </HeadActions>
      </Col>
    </HeadStyled>
  );
}

export default HeadPayment;

const HeadStyled = styled(Row)`
    padding: 2rem;
    padding-bottom: 0;
    .box-ticket-info {
        .ticket-number {
            border-radius: 5px;
            background: var(--fill-fill-2, #ADADAD);
            display: flex;
            width: 134.638px;
            padding: 0px 4px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: var(--text-text-1, #FFF);
            font-family: Open Sans;
            font-size: 36px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        display: flex;
        align-items: flex-end;

        .customer-name {
            font-size: 20px;
        }
        .create-date {
            font-size: 16px;
        }
    }
`;

const HeadActions = styled(Row)`
    gap: 24px;
`;

