import { Popover } from 'antd';
import { useMemo } from 'react';
import styled from 'styled-components';
import settingIcon from '../icons/setting.svg';
import SettingMenuItem from './MenuItem';
import { SideBarSettingConfigKeys } from '../helper';

type Props = {
  onClick: (type: SideBarSettingConfigKeys) => void;
  currentPath?: string;
};

const SideBarSetting = ({ onClick, currentPath }: Props) => {
  const content = useMemo(() =>
    <SettingPopoverContainer>
      <SettingMenuItem type='CUSTOMER' active={currentPath?.includes('/customers')} onClick={() => onClick('CUSTOMER')} />
      <SettingMenuItem type='STAFF' active={currentPath?.includes('/setting-staff')} onClick={() => onClick('STAFF')} />
      <SettingMenuItem type='SETTING' active={currentPath?.includes('/setting-service')} onClick={() => onClick('SETTING')} />
      <SettingMenuItem type='LOGOUT' onClick={() => onClick('LOGOUT')} />
    </SettingPopoverContainer>, [onClick, currentPath]);
  return (
    <Popover
      trigger="click"
      placement="rightBottom"
      title={''} content={content}
      overlayClassName='side-bar-setting-overlay'
      align={{ offset: [15, 10] }}
    >
      <Container>
        <img src={settingIcon} alt="settingIcon" className="icon" />
        <p className="setting-text">Setting</p>
      </Container>
    </Popover>
  );
};

export default SideBarSetting;
const SettingPopoverContainer = styled.div`
display: flex;
padding: 8px;
flex-direction: column;
align-items: flex-start;
align-self: stretch;
border-bottom: 1px solid #E5E6EB;
background: #CBD5E1;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
`;

const Container = styled.button`
display: flex;
width: 110px;
height: 89px;
padding: 16px 10px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
position: absolute;
left: 16px;
bottom: 16px;
border-radius: 10px;
background: #CBD5E1;
img.icon {
  width: 38px;
  height: 38px;
  flex-shrink: 0;
}
p.setting-text {
color: #1D2129;
text-align: center;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
`;
