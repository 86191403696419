import { Spin } from 'antd';
import Modal from 'components/Modal';
import { IBillWaitingTicketResItem } from 'features/user/services/types/ticket';
import TicketItem from 'features/user/widgets/WaitingList/Ticket/Item';
import React, { useMemo } from 'react';
import styled from 'styled-components';
type Props = {
  loading?: boolean;
  open?: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
  selected: IBillWaitingTicketResItem[];
  onChooseTicket: (id: string) => void;
  onDelete: (id: string) => void;
  tickets: IBillWaitingTicketResItem[];
  ticketDetail: IBillWaitingTicketResItem;
};

const MergeTicketModalV2: React.FC<Props> = ({ loading, ticketDetail, tickets, open, onClose, onSubmit, selected, onChooseTicket, onDelete }) => {
  const mergeTicketsList = useMemo(() => selected.map(o => o.billId), [selected]);
  const onSelectMergeTicket = (o: IBillWaitingTicketResItem) => () => {
    const exist = mergeTicketsList.includes(o.billId);
    if (!exist) return onChooseTicket(o.billId);
    onDelete(o.billId);
  };
  return (
    <>
      <Modal
        modalTitle="MERGE TICKETS"
        visible={open}
        okTitle="Done"
        disableOk={selected.length === 0}
        noneBodyStyle
        noneFooterStyle
        footer={null}
        hiddenHeader
        width={'1680px'}
      >
        <Spin spinning={loading}>
          <Container>
            <div className="header-container">
              <span>MERGE TICKETS</span>
            </div>
            <div className="container-content">
              <div className="ticket-list">
                <TicketItem
                  key={ticketDetail.billId}
                  data={ticketDetail}
                  cashier
                  mergeTicketsList={[ticketDetail.billId]} enableMergeTicket
                />
                {tickets.map(o => (
                  o.billId === ticketDetail.billId ? null :
                    <TicketItem
                      key={o.billId}
                      data={o}
                      cashier
                      mergeTicketsList={mergeTicketsList} enableMergeTicket
                      onSelectMergeTicket={onSelectMergeTicket(o)}
                    />
                ))}
              </div>
            </div>
            <div className="footer-container">
              <button onClick={onClose} type='button' className="btn btn-close"><span>Close</span></button>
              <button onClick={onSubmit} type='button' className="btn btn-done"><span>Done</span></button>
            </div>
          </Container>
        </Spin>
      </Modal>
    </>
  );
};

export default MergeTicketModalV2;

const Container = styled.div`
display: flex;
flex-direction: column;

.header-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #232F3E;
  span {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.modal-label {
  color: #64748B;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ServiceName {
  padding-top: 24px;
}
.ant-input.service-name-input {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
  border: none;
  outline: none;
}

.CurrencyInput {
  color: #D2464F;
  font-size: 50px;
}
.container-content {
  max-height: 700px;
  min-height: 700px;
  overflow: auto;
  padding: 16px;
}
.ticket-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 24px;
}

.footer-container {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-bottom: 40px;
  button.btn {
    display: flex;
    height: 70px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: #E5E6EB;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.btn-done {
      border-radius: 4px;
      background: #FF8890;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
    }
  }
}
`;
