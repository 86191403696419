import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
type IPrintHeaderProps = {
  onBack: () => void;
  onPrint: () => void;
  onPrintSummary: () => void;
  onPrintSummaryPaymentType: () => void;
  v2?: boolean;
};
const IncomePrintHeader: React.FC<IPrintHeaderProps> = ({ v2, onBack, onPrint, onPrintSummary, onPrintSummaryPaymentType }) => {
  return (
    <PrintHeaderStyled>
      <Text printMode variant="H5" color="text_3">
        PRINT PREVIEW
      </Text>
      <Box display="flex" gap="3">
        <Button cardCashier={v2} ntype="DEFAULT" icon="back" onClick={onBack}>Back</Button>
        <Button cardCashier={v2} ntype="DEFAULT" icon="print" onClick={onPrint}>Print Overview</Button>
        <Button cardCashier={v2} ntype="DEFAULT" icon="print" onClick={onPrintSummary}>Print Summary</Button>
        <Button cardCashier={v2} ntype="DEFAULT" icon="print" onClick={onPrintSummaryPaymentType}>Print Summary Payment Type</Button>
      </Box>
    </PrintHeaderStyled>
  );
};

export default IncomePrintHeader;
const PrintHeaderStyled = styled.div`
  width: calc(100% - 2rem);
  padding: 1rem;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
