import Box from 'components/Box';
import Text from 'components/Text';
import { formatCurrency } from 'utils/formatCurrency';
import { IMultiCardResDataItem } from '../../services/types/card';
import Icon from 'assets/Icons';
import styled from 'styled-components';
import clsx from 'clsx';
type Props = {
  remaining: number;
  cards: IMultiCardResDataItem[];
  v2?: boolean;
};
const DetailPayment = ({ remaining, cards, v2 }: Props) => {
  return (
    <Container className={clsx(v2 && 'v2')}>
      <div className='cards'>
        {!!cards?.length && (
          <Box
            px="4"
            py="3"
            display="flex"
            flexDirection="column"
            gap="2"
            bStyle="dotted"
            bb="line_3"
          >
            {cards.map((item) => {
              return (
                <Box className="space-between" key={item.id}>
                  <Box className="center" gap="1">
                    <Icon type="card" />
                    <Text variant="CONTENT_2" color="text_3" className='card-label'>
                      {item.last4 || '--'}
                    </Text>
                  </Box>
                  <Text variant="CONTENT_2" color="text_3" className='card-value'>
                    {formatCurrency(item.amount)}
                  </Text>
                </Box>
              );
            })}
          </Box>
        )}
      </div>
      <BottomStyled>
        <Box px='4' className="space-between box remaining-box" bgColor="yellow_headline">
          <Text variant="CONTENT_2" color="text_3" className='remaining-label'>
            Remaining
          </Text>
          <Text variant="H5" color="text_3" className='remaining-value'>
            {remaining >= 0 ? formatCurrency(remaining) : 0}
          </Text>
        </Box>
      </BottomStyled>
    </Container>
  );
};

export default DetailPayment;

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .cards {
    flex: 1;
  }
&.v2 {
  .cards {
    .card-label, .card-value {
      font-size: 20px;
    }
  }
  .remaining-box {
    background: #FEEDBF;
    .remaining-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .remaining-value {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
`;

const BottomStyled = styled(Box)`
  padding: 1rem 0;
  .box {
    height: 3.5rem;
  }
`;
