import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { SortableList } from 'features/settingService/components/SortableList';
import { useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
type Props = {
  onSubmit?: (ids: string[]) => void;
};
const ButtonReOrderCategories = ({ onSubmit = () => undefined }: Props) => {
  const [open, setOpen] = useState(false);
  const simpleMenu = shopSelectors.data.simpleMenu();
  const [items, setItems] = useState<{ id: string, name: string, totalServices: number }[]>([]);
  const handleOpen = () => {
    setItems(simpleMenu.map(o => ({ id: o.categoryId, name: o.categoryName, totalServices: o.services.length })));
    setOpen(true);
  };

  const handleAccept = async () => {
    setOpen(false);
    const ids = items.map(o => o.id);
    onSubmit(ids);
  };

  return (
    <>
      <Button onClick={handleOpen} className='custom-button' height={3} icon='preOrder' ntype='LIGHT_BLUE'>Pre-Order</Button>
      <Modal
        visible={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAccept}
        modalTitle='Reorder'
      >
        <SortableList
          items={items}
          onChange={setItems}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              <CateWrap>
                <Text variant="H7" color="text_3">
                  {item.name}
                </Text>
                <Text variant="CONTENT_1" color="text_2">
                  {item.totalServices} Services
                </Text>
              </CateWrap>
            </SortableList.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default ButtonReOrderCategories;

const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .label {
    font-size: 18px;
    font-weight: 600;
  }
`;