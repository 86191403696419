import { get } from 'lodash';
import { MutableRefObject, forwardRef, useImperativeHandle, useRef, useState } from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import { styled } from 'styled-components';
import { DollarAmountInputRef, dollarAmountNumberMask, parseInputValue, parseOutputValue, parseStrInputValue } from './helpers';

const parseLabels = (text?: string) => {
  const [int = '', decimal = ''] = text?.split('.') || [];
  const decimalText = decimal?.padEnd(2, '*');
  const decimal1 = decimalText.slice(0, 1);
  const decimal2 = decimalText.slice(1, 2);
  return ({
    int: int.replace('$', ''),
    intFalse: !int || int === '$0',
    dot: text?.includes('.'),
    decimal1: decimal1?.replace('*', ''),
    decimal1False: decimal2 === '*' && decimal1 === '*',
    decimal2: decimal2?.replace('*', ''),
    decimal2False: decimal2 === '*',
  });
};

// @ts-ignore
interface Props extends Omit<MaskedInputProps, 'onChange'> {
  mask?: any;
  value?: number | null;
  onChange?: (e: number) => void;
  maxValue?: number;
  color?: string;
  simpleV1?: boolean;
}
export const useDollarAmountInputRef = () => useRef<DollarAmountInputRef>(null);
const DollarAmountInput = forwardRef<DollarAmountInputRef, Props>(({ simpleV1, maxValue, color = '#1D2129', value: valueProp, onChange = () => undefined, onFocus = () => undefined, ...topLevelProps }, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [text, setText] = useState(parseInputValue(valueProp));
  const [isFocus, setIsFocus] = useState(false);

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const numericValue = parseOutputValue(e.target.value);
    if (maxValue && numericValue > maxValue) return e.preventDefault();
    onChange(numericValue);
    setText(e.target.value);
  };

  useImperativeHandle(ref, () => ({
    focus: (_value) => {
      const _text = _value || parseInputValue(0);
      setText(_text);
      inputRef.current?.focus();
    },
    init: (_value, unFocus = false) => {
      const _text = parseStrInputValue(_value) || parseInputValue(0);
      const numericValue = parseOutputValue(_text);
      if (maxValue && numericValue > maxValue) return;
      setText(_text);
      onChange(numericValue);
      if (!unFocus) inputRef.current?.focus();
    },
    clear: () => {
      setText(parseInputValue(0));
      onChange(0);
    },
    getText: () => text === '$0' ? '$' : text,
  }));
  return <MaskedInput
    render={(textMaskRef: any, props: any) => {
      const value = get(props, 'defaultValue', '');
      const className = get(props, 'className', '');
      const labels = parseLabels(value);
      return (
        <Container className={`custom-dollar-input ${simpleV1 ? 'simpleV1' : ''} ${topLevelProps.className} ${isFocus ? ' custom-input-focus' : ''}`}>
          <input
            {...props}
            className={`ant-input ${className}`}
            style={{ color: 'transparent' }}
            onFocus={(e) => {
              onFocus(e);
              setIsFocus(true);
            }}
            value={text}
            onBlur={() => setIsFocus(false)}
            ref={(node) => {
              if (node) {
                textMaskRef(node);
                (inputRef as MutableRefObject<HTMLInputElement>).current = node;
              }
            }}
          />
          <div className='fake-labels prevent-select'>
            <span style={{ color }}>$</span>
            <span style={{ color: labels.intFalse ? '#C9CDD4' : color }}>{labels.int || '0'}</span>
            <LinDot visible={!labels.dot} />
            <span style={{ color: !labels.dot ? '#C9CDD4' : color }}>.</span>
            <LinDot visible={labels.dot && labels.decimal1False && labels.decimal2False} />
            <span style={{ color: labels.decimal1False ? '#C9CDD4' : color }}>{labels.decimal1 || '0'}</span>
            <LinDot visible={labels.dot && !labels.decimal1False && labels.decimal2False} />
            <span style={{ color: labels.decimal2False ? '#C9CDD4' : color }}>{labels.decimal2 || '0'}</span>
            <LinDot visible={labels.dot && !labels.decimal1False && !labels.decimal2False} />
          </div>
        </Container>
      );
    }}
    {...topLevelProps}
    value={text}
    mask={dollarAmountNumberMask}
    onChange={handleOnChange}
  />;
});
DollarAmountInput.displayName = 'DollarAmountInput';
export default DollarAmountInput;
const LinDot = ({ visible }: { visible?: boolean }) => {
  if (!visible) return null;
  return <span className='mock-cursor'>|</span>;
};
const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  input {
    position: relative;
    z-index: 2;
    padding: 0 8px;
    background: transparent;
    align-self: stretch;
    flex: 1;
    box-sizing: border-box;
    margin: 0;
    padding: 3px 11px;
    color: #1D2129;
    font-size: 16px;
    line-height: 1.5;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #86909C;
    border-radius: 5px;
    transition: all 0.2s;
  }
  .mock-cursor {
    color: #1D2129;
    -webkit-animation: blink 1s infinite;
    animation: blink 1s infinite;
    font-style: normal;
    width: 1px;
    height: 100%;
    align-self: stretch;
    display: none;
    transform: translateY(-4px) translateX(-3px) scaleY(1.4);
    font-weight: 400 !important;
  }
  .fake-labels, input, .mock-cursor {
    color: #1D2129;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .fake-labels {
    position: absolute;
    z-index: 1;
    padding: 0 8px;
    display: flex;
    align-items: flex-end;
  }
  &.custom-input-focus {
    input {
      outline: none;
      border-color: #ffb0b3;
      box-shadow: 0 0 0 2px rgba(255, 22, 105, 0.06);
      border-inline-end-width: 1px;
    }
    .mock-cursor {
      display: inline-flex;
    }
  }
 
@-webkit-keyframes blink {
  0%, 49.9%, 100%   { opacity: 0; }
  50%, 99.9% { opacity: 1; }
}
`;

export const InputWithKeyboardStyled = styled(DollarAmountInput)`
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  &.custom-input-focus input, input {
    border: none !important;
    box-shadow: none !important;
    text-align: center;
    outline: none !important;
  }
  .fake-labels, input, .mock-cursor  {
    font-size: 60px;
    font-weight: 600;
  }
`;

export const InputFormV2Styled = styled(DollarAmountInput)`
  .fake-labels, .mock-cursor, input  {
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .fake-labels {
    padding: 3px 16px;
  }

  &.custom-dollar-input {
    background:#fff;
  }
  &.simpleV1 {
    .fake-labels, .mock-cursor, input  {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
      input {
        height: 3.5rem;
      }
  }
  &.custom-dollar-input input {
    box-sizing: border-box;
    margin: 0;
    padding: 3px 11px;
    color: transparent;
    font-size: 16px;
    line-height: 1.5;
    list-style: none;
    width: 100%;
    min-width: 0;
    border-width: 1px;
    border-style: solid;
    border-color: #86909C;
    border-radius: 5px; 
    line-height: normal;
    outline: none;
    &:hover, &:focus {
      border-color: #ffb0b3;
      border-inline-end-width: 1px;
    }
  }
`;