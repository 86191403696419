import { Drawer, Form } from 'antd';
import { aptUIActions } from 'features/appointment/services/reducers';
import appointmentSelectors from 'features/appointment/services/selectors';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import StaffInfo from '../AppointmentDetail/v2/StaffInfo';
import shopSelectors from 'services/shop/selectors';
import { first } from 'lodash';
import Appointment from './Appointment';
import { IAppointmentItemData, IAppointmentServiceItem } from 'features/appointment/services/types/appointment';
import uuid from 'utils/uuid';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
import ModalAppointmentDetailRef, { useModalAppointmentDetailRef } from '../AppointmentDetail/v2/ModalRef';
import { ICalendarItemData } from 'features/appointment/services/types/calendar';

const bodyStyle = { padding: 0 };
const MultipleAptDetailV2 = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const staffs = shopSelectors.data.staffs();
  const modalRef = useModalAppointmentDetailRef();
  const multipleDetail = appointmentSelectors.multipleDetail();
  const onClose = () => dispatch(aptUIActions.setDraftMultiAppointmentsDetail(null));
  const loyaltySetting = shopSelectors.data.getLoyaltySetting();
  const calcMoneyPoint = (point: number) => {
    if (!loyaltySetting) return 0;
    return (point || 0) / loyaltySetting?.oneDollarToPoint;
  };
  useEffect(() => {
    if (!multipleDetail) return;
    const staffId = first(multipleDetail.data)?.staffId;
    const anyone = staffs.find(o => o.anyBody);
    const staffData = staffs.find(o => o.id === staffId);

    form.setFieldsValue({
      staff: staffData || anyone || null,
      appointments: multipleDetail.data.map(o => {
        const extra = o?.data as IAppointmentItemData;
        const services: IAppointmentServiceItem[] = extra.services.map(s => ({
          ...s,
          uuid_local: uuid(),
          staffId: extra.staffId,
        }));
        return ({
          customer: {
            name: extra.customerName,
            phone: extra.customerPhone,
            point: `${extra.customerPoint || 0} points = ${formatCurrency(calcMoneyPoint(extra?.customerPoint || 0))}`,
          },
          services,
          aptDate: momentTimezone(extra.startTime),
          sourceType: extra.sourceType,
          detailData: o,
        });
      }),
    });
  }, [multipleDetail]);

  const onDetail = (detailData: ICalendarItemData) => modalRef.current?.open({
    data: detailData,
    headerData: multipleDetail?.headerData || null,
  });

  return (
    <Drawer
      onClose={onClose} open={!!multipleDetail}
      title={<></>}
      headerStyle={{ display: 'none' }}
      width={'800px'}
      bodyStyle={bodyStyle}
    >
      <Container form={form}>
        <HeaderModal>
          <Form.Item name={'staff'} noStyle><StaffInfo /></Form.Item>
          <Form.Item shouldUpdate noStyle>{({ getFieldValue }) => (<div className="modal-title">Multiple booking ({getFieldValue('appointments')?.length || 0})</div>)}</Form.Item>
        </HeaderModal>
        <Form.List name={'appointments'}>
          {(fields) => {
            return (
              <BodyAptDetail>
                {fields.map(o => (
                  <Form.Item noStyle key={o.key} name={o.name}>
                    <Appointment onDetail={onDetail} name={o.name} staffs={staffs} />
                  </Form.Item>
                ))}
              </BodyAptDetail>
            );
          }}
        </Form.List>
        <FooterModal>
          <button type='button' onClick={onClose} className="btn btn-close">
            <span>Close</span>
          </button>
        </FooterModal>
      </Container>
      <ModalAppointmentDetailRef ref={modalRef} />
    </Drawer>
  );
};

export default MultipleAptDetailV2;

const Container = styled(Form)`
display: flex;
flex-direction: column;
height: 100%;
`;
const BodyAptDetail = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
background: #FFF;
gap: 16px;
`;
const HeaderModal = styled.div`
  border-radius: 5px 5px 0px 0px;
  position: sticky;
  top:0;
  z-index: 99;
  background: #232F3E;
  padding: 12px 16px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .modal-title {
    color: #FFF;
    background: #232F3E;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;

const FooterModal = styled.div`
position: sticky;
bottom:0;
z-index: 99;
display: flex;
padding: 16px 16px 24px 16px;
align-items: flex-end;
gap: 16px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -4px 8px 0px rgba(0, 0, 0, 0.15);
button.btn {
  display: flex;
  height: 64px;
  padding: 16px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  flex: 1 0 0;
  border-radius: 4px;
  background: #E5E6EB;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
 
}
`;
