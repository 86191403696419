import { styled } from 'styled-components';

export const CenterMainStyled = styled.div`
  height: calc(100vh - 6.375rem);
  background-color: #f2f1f1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .hide {
    display: none;
    opacity: 0;
  }
  .show {
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: 1;
  }
`;
export const ContainerStyled = styled.div`
  padding: 1.5rem 1rem;
  padding-bottom: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const ButtonContainerStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;
  margin-top: 1rem;
`;
export const CarouserContainerStyled = styled.div`
  margin-top: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
