import { createAction } from '@reduxjs/toolkit';
import { createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { IConfirmCheckInParams, IApiGetDemoDataParam, IInputItem, ISignUpForm, IStaffQueryParams } from './types/api';
import { IWalkInData } from './types/demoData';
import { ICategoryItemData, IServiceItemData } from 'services/shop/types/categories';
import { IState } from './types/reducer';
import { IGoogleReviewResData } from './types/review';
import { IDeviceThemeCheckInResData } from './types/theme';

const getDemoData = createAsyncAction<IApiGetDemoDataParam>(PREFIX_ACTIONS + 'getDemoData');

const checkIn = createAsyncAction<string>(PREFIX_ACTIONS + 'checkIn');

const signUp = createAsyncAction<ISignUpForm>(PREFIX_ACTIONS + 'signUp');

const getStaffList = createAsyncAction(PREFIX_ACTIONS + 'getStaffList');

const getStaffListCondition = createAsyncAction<IStaffQueryParams>(PREFIX_ACTIONS + 'getStaffListCondition');

const getAppointmentDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'getAppointmentDetail');

const confirmWalkIn = createAsyncAction<IConfirmCheckInParams>(PREFIX_ACTIONS + 'setWalkIn');

const setPhoneNumber = createAction<string>(PREFIX_ACTIONS + 'setPhoneNumber');

const setInitCheckIn = createAction(PREFIX_ACTIONS + 'setInitCheckIn');

const setInputValue = createAction<IInputItem>(PREFIX_ACTIONS + 'setInputValue');

const setEmailDomain = createAction<string>(PREFIX_ACTIONS + 'setEmailDomain');

const setSelectedStaff = createAction<string>(PREFIX_ACTIONS + 'setSelectedStaff');

const setSelectedStaffInListStaffCondition = createAction<string>(PREFIX_ACTIONS + 'setSelectedStaffInListStaffCondition');

const setTextSearch = createAction<string>(PREFIX_ACTIONS + 'setTextSearch');

const setSelectedStaffFromSearch = createAction<string>(PREFIX_ACTIONS + 'setSelectedStaffFromSearch');

const setWalkInData = createAction<IWalkInData>(PREFIX_ACTIONS + 'setWalkInData');

const initConfirmWalk = createAction(PREFIX_ACTIONS + 'initConfirmWalk');

const setActiveCategory = createAction<ICategoryItemData | null>(PREFIX_ACTIONS + 'setActiveCategory');

const chooseService = createAction<IServiceItemData>(PREFIX_ACTIONS + 'chooseService');

const addService = createAction<IServiceItemData>(PREFIX_ACTIONS + 'addService');

const removeService = createAction<IServiceItemData>(PREFIX_ACTIONS + 'removeService');

const resetForm = createAction<'services' | 'staff' | 'all'>(PREFIX_ACTIONS + 'resetForm');

const chooseStaff = createAction<IState['selectedStaff']>(PREFIX_ACTIONS + 'selectedStaff');

const activeSimpleMenu = createAction<boolean>(PREFIX_ACTIONS + 'activeSimpleMenu');

const setWarningBanCustomerMessage = createAction<string | null>(PREFIX_ACTIONS + 'setWarningBanCustomerMessage');

const getGoogleReview = createAsyncAction<undefined, IGoogleReviewResData>(PREFIX_ACTIONS + 'getGoogleReview');

const getHomeTheme = createAsyncAction<undefined, IDeviceThemeCheckInResData>(PREFIX_ACTIONS + 'getHomeTheme');

const checkInActions = {
  getDemoData,
  checkIn,
  setPhoneNumber,
  setInitCheckIn,
  setInputValue,
  setEmailDomain,
  getStaffList,
  setSelectedStaff,
  setTextSearch,
  getStaffListCondition,
  setSelectedStaffFromSearch,
  setWalkInData,
  signUp,
  getAppointmentDetail,
  confirmWalkIn,
  initConfirmWalk,
  setSelectedStaffInListStaffCondition,
  setActiveCategory,
  chooseService,
  resetForm,
  chooseStaff,
  removeService,
  addService,
  activeSimpleMenu,
  setWarningBanCustomerMessage,
  getGoogleReview,
  getHomeTheme,
};

export default checkInActions;

