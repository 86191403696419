import React from 'react';
import styled from 'styled-components';
import back_icon from './icons/back.svg';
import { useNavigate } from 'react-router-dom';
import GiftCardDetailInfo from './GiftCardDetailInfo';
import GiftCardHistory from './GiftCardHistory';
import { IGiftCardDetail } from 'features/giftCards/services/types/reducer';
type Props = {
  detail?: IGiftCardDetail | null;
  onReset: () => void;
};
const GiftCardDetailV2 = ({ detail, onReset }: Props) => {
  const navigate = useNavigate();
  return (
    <Container>
      <Header>
        <button onClick={() => navigate(-1)} className='btn-back' type='button'><img src={back_icon} alt="back_icon" style={{ width: 40, height: 40 }} /></button>
        <div className="header-title">Gift Card</div>
      </Header>
      <GiftCardDetailInfo onReset={onReset} detail={detail} />
      <GiftCardHistory detail={detail} />
    </Container>
  );
};

export default GiftCardDetailV2;

const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
padding: 24px;
align-self: stretch;
flex: 1;
`;

const Header = styled.div`
display: flex;
height: 56px;
align-items: center;
.btn-back {
  padding-right: 24px;
}
.header-title {
  color: #505050;
  font-family: Poppins;
  font-size: 24px;
  zoom:1.3;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`;
