import React from 'react';
import styled from 'styled-components';
import TurnActions from './Actions';
import TurnServices from './TurnServices';
import TurnFeatures from './TurnFeatures';

const TurnFooter = () => {
  return (
    <Container className='TurnFooter'>
      <TurnActions />
      <TurnFeatures />
      <TurnServices />
    </Container>
  );
};

export default TurnFooter;
const Container = styled.div`
  display: flex;
  padding: 8px 24px;
  align-items: center;
  align-self: stretch;
  background: #FFF;
  box-shadow: 0px -8px 16px -8px rgba(0, 0, 0, 0.15);
`;