import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import styled from 'styled-components';
import CategoryGroup from '../LeftMainBody/CategoryGroup';
import { ActiveProductsV2 } from './ActiveProducts';
import { ActiveServicesV2 } from './ActiveServices';
import ActiveSimpleMenu from './ActiveSimpleMenu';
import ExtendFeatures from './ExtendFeatures';
import ModalWarningEmptyService from './ModalWarningEmptyService';

const CenterMainBodyV2 = () => {
  return (
    <Container>
      <CenterMainStyled>
        <ExtendFeatures v2 />
        <CarouserContainerStyled>
          <CategoryGroup />
          <div className="service-group-container">
            <ActiveFeatureProduct />
            <ActiveQuickMenu />
          </div>
        </CarouserContainerStyled>
        <ModalWarningEmptyService />
      </CenterMainStyled>
    </Container>
  );
};

export default CenterMainBodyV2;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #F5F5F5;
`;

const CenterMainStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  .hide {
    display: none;
    opacity: 0;
  }
  .show {
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: 1;
  }
`;
const CarouserContainerStyled = styled.div`
  margin-top: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  .service-group-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

const ActiveFeatureProduct = () => {
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  const activeQuickMenu = cashierSelectors.getActiveQuickMenu();
  return (
    <div className={!activeQuickMenu ? 'show' : 'hide'}>
      <div className={featureProductMode === FEATURE_PRODUCT_MODE.SERVICE ? 'show' : 'hide'}><ActiveServicesV2 /></div>
      <div className={featureProductMode === FEATURE_PRODUCT_MODE.PRODUCT ? 'show' : 'hide'}><ActiveProductsV2 /></div>
    </div>
  );
};
const ActiveQuickMenu = () => {
  const activeQuickMenu = cashierSelectors.getActiveQuickMenu();
  return (
    <div className={activeQuickMenu ? 'show' : 'hide'}><ActiveSimpleMenu /></div>
  );
}; 
