import { Form, Input, InputNumber, InputRef } from 'antd';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Modal from 'components/Modal';
import { IBodyCreateSimpleMenu, IBodyCreateSimpleMenuServiceItem } from 'features/settingService/services/types/simpleMenu';
import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
const MAXIMUM_SUB_CATEGORY = 10;
type IEditDetailServiceItem = {
  data: IBodyCreateSimpleMenuServiceItem,
  name: any;
};
type Props = {
  onSubmit?: (body: IBodyCreateSimpleMenu) => void;
};
const ButtonNewCategory = ({ onSubmit = () => undefined }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const inputNameRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [formEditDetail] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [editDetailService, setEditDetailService] = useState<IEditDetailServiceItem | null>(null);
  const subCategories = Form.useWatch('subCategories', form);

  const handleFinish = (values: any) => {
    const newValues = { ...values };
    delete newValues.subCategories;
    delete newValues.validateServices;
    onSubmit(newValues);
    setVisible(false);
  };

  React.useEffect(() => {
    if ((subCategories < MAXIMUM_SUB_CATEGORY + 1) && subCategories > 0) {
      const newService: IBodyCreateSimpleMenuServiceItem[] = Array.from({
        length: subCategories,
      }).map(() => ({
        serviceName: '',
        priceSell: 0,
        duration: 15,
        commission: 0,
        supplyFee: form.getFieldValue('supplyFee'),
        orderNumber: 0,
        addOn: true
      }));

      form.setFieldsValue({ services: newService });
    } else {
      form.setFieldsValue({ services: [] });
    }
  }, [subCategories]);

  const onOpenEditDetailService = (name: any, data: IBodyCreateSimpleMenuServiceItem | undefined) => {
    if (!data) return;
    setEditDetailService({ name, data });
    formEditDetail.resetFields();
    formEditDetail.setFieldsValue(data);
    setTimeout(() => inputNameRef.current?.focus(), 100);
  };
  const onCloseEditDetailService = () => setEditDetailService(null);
  const onFinishEditDetailService = (values: any) => {
    if (!editDetailService?.data) return;
    const serviceData: IBodyCreateSimpleMenuServiceItem = { ...editDetailService.data, ...values };
    form.setFieldValue(['services', editDetailService.name], serviceData);
    setEditDetailService(null);
  };

  const onOpen = () => {
    setVisible(true);
    form.resetFields();
    setTimeout(() => inputRef.current?.focus(), 100);
  };

  const onClose = () => setVisible(false);
  return (
    <>
      <Button onClick={onOpen} className='custom-button' height={3} icon='addPlus' ntype='LIGHT_PRIMARY'>New Category</Button>
      <Modal
        visible={visible}
        modalTitle='ADD NEW CATEGORY'
        width={'950px'}
        onSubmit={() => form.submit()}
        onClose={onClose}
        noneBodyStyle
        noneFooterStyle
        containerPadding={1}
      >
        <FormStyled layout='vertical' form={form} onFinish={handleFinish} >
          <RowContent>
            <Form.Item label="Category Name" name={'cateName'} rules={[{
              required: true, message: 'Please enter category name',
            }]}>
              <Input ref={inputRef} size='middle' />
            </Form.Item>
            <Form.Item label="Supply Fee" name={'supplyFee'}>
              <CurrencyInputPrecision height={'3rem'} />
            </Form.Item>
            <Form.Item label="Sub-Catagories" name={'subCategories'} rules={[
              { required: true, message: 'Please enter a number!' },
              () => ({
                validator(_, value) {
                  if (!value || value <= MAXIMUM_SUB_CATEGORY) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`The maximum value is ${MAXIMUM_SUB_CATEGORY}`)
                  );
                },
              }),
            ]}>
              <InputNumber />
            </Form.Item>
          </RowContent>
          <Form.List name={'services'}>
            {(fields) => (
              <ServiceContainer>
                {
                  fields.map(({ key, name }) => (
                    <React.Fragment key={key}>
                      <Form.Item name={name} noStyle>
                        <ServiceItem onClick={(data) => onOpenEditDetailService(name, data)} />
                      </Form.Item>
                    </React.Fragment>
                  ))
                }
              </ServiceContainer>
            )}
          </Form.List>
          <Form.Item className='valid-service' name={'validateServices'} rules={[
            () => ({
              validator() {
                const services: IBodyCreateSimpleMenuServiceItem[] = form.getFieldValue('services');
                const isValid = services.every(ser => !!ser.serviceName);
                if (isValid) return Promise.resolve();
                return Promise.reject(new Error('Please fill in the full service name'));
              },
            }),
          ]} />
        </FormStyled>
        <Modal
          modalTitle='NEW SERVICE'
          visible={!!editDetailService}
          onClose={onCloseEditDetailService}
          onSubmit={() => formEditDetail.submit()}
        >
          <FormStyled layout='vertical' form={formEditDetail} onFinish={onFinishEditDetailService}>
            <Form.Item label="Service Name" name={'serviceName'} rules={[{
              required: true, message: 'Please enter service name',
            }]}>
              <Input ref={inputNameRef} size='middle' />
            </Form.Item>
            <Form.Item label="Sell Price" name={'priceSell'} rules={[{
              required: true, message: 'Please enter sell price',
            }]}>
              <CurrencyInputPrecision height={'3rem'} />
            </Form.Item>
            <Form.Item label="Duration (Minutes)" name={'duration'}>
              <InputNumber />
            </Form.Item>
            <Form.Item label="Supply Fee" name={'supplyFee'}>
              <CurrencyInputPrecision height={'3rem'} />
            </Form.Item>
          </FormStyled>
        </Modal>
      </Modal>
    </>
  );
};

export default ButtonNewCategory;

const FormStyled = styled(Form)`
  .ant-input-number {
    height: 3rem;
    width: 100%;
    .ant-input-number-input-wrap, .ant-input-number-input {
      height: 100%;
    }
  }
  .valid-service {
    .ant-form-item-control-input {
      min-height: 1px;
    }
  }
`;

const RowContent = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-gap: 24px;
`;

const ServiceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 24px;
`;
type ServiceItemProps = {
  value?: IBodyCreateSimpleMenuServiceItem;
  onClick: (value?: IBodyCreateSimpleMenuServiceItem) => void;
};
const ServiceItem = ({ value, onClick }: ServiceItemProps) => {
  return (
    <ServiceItemStyled onClick={() => onClick(value)}>
      <div className="box-content">
        <p className="service-name">{value?.serviceName || 'Name'}</p>
        <div className="item-content">
          Duration: <span>{value?.duration || 0}m</span>
        </div>
        <div className="item-content">
          Supply Fee: <span>{formatCurrency(value?.supplyFee || 0)}</span>
        </div>
        <div style={{ flex: 1 }} />
        <div className="item-content">
          Sell price: <span>{formatCurrency(value?.priceSell || 0)}</span>
        </div>
      </div>
    </ServiceItemStyled>
  );
};

const ServiceItemStyled = styled.div`
  border-radius: 5px;
  background: #E5E5E5;
  padding: 4px;
  display: flex;
  height: 160px;
  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
  .box-content {
    flex:1;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px dashed #86909C;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .service-name {
      color: #1D2129;
      text-align: center;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
    }
    .item-content {
      color: #1D2129;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      span {
        font-size: 18px;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        text-transform: capitalize;
      }
    }
  }
`;