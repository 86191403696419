
export const NAME_REDUCER = 'user';

export const PREFIX_ACTIONS = 'user_feature_';

export const PATH_LOADING = {
  getHistoryClockInOut: `loading.${NAME_REDUCER}.getHistoryClockInOut`,
  getWaitingList: `loading.${NAME_REDUCER}.getWaitingList`,
  getBillWaitingTicket: `loading.${NAME_REDUCER}.getBillWaitingTicket`,
  getSummaryData: `loading.${NAME_REDUCER}.getSummaryData`,
  getListStaffAvailable: `loading.${NAME_REDUCER}.getListStaffAvailable`,
};

export const CHECK_IN_TYPES = {
  walkIn: 'WALK_IN',
  appointment: 'APPOINTMENT',
};

export const STAFF_CLOCK_IN_OUT_ACTIONS = {
  CLOCK_IN: 'CLOCK_IN',
  CLOCK_OUT: 'CLOCK_OUT',
};

export enum EMainLayout {
  FULL_STAFFS = 'FULL_STAFFS',
  GENERAL = 'GENERAL',
}