import { Input } from 'antd';
import React from 'react';
import styled from 'styled-components';
type Props = {
  value?: number;
  onChange?: (value: number) => void;
};
const InputGroup = ({ value, onChange = () => undefined }: Props) => {
  return (

    <Container>
      <div className="form-label">Group</div>
      <Input onKeyPress={(event) => {
        if (!/[0-9]/.test(event.key)) {
          event.preventDefault();
        }
      }}
        value={value}
        onChange={e => onChange(+e.target.value)}
      />
    </Container>


  );
};

export default InputGroup;

const Container = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
align-self: stretch;
padding-bottom: 0;
.form-label {
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.ant-input {
  display: flex;
  padding: 7px 13px;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #CCD4DC;
  background: #FFF;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
`;
