import Button from 'components/Button';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef, ICurrencyKeyBoard, useCurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useEffect, useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import { ModalAddStaff } from '../RightMainBody/GroupActions/ButtonAddStaff';
import { cloneDeep, first } from 'lodash';
import { IStaff } from 'features/cashier/services/types/api';
import { Input } from 'antd';
import Text from 'components/Text';
import styled from 'styled-components';
import { QUICK_PAY_NAME } from 'features/cashier/services/constants';
import CardTicketItem from './ExtendFeatures/CardTicketItem';
import colorTheme from 'constants/color';
import { cashierUIActions } from 'features/cashier/services/reducers';
import { issueGiftCardV2Ref } from './ExtendFeatures/GiftCard/v2/IssueGiftCard';
import DollarAmountInput, { useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import { DollarAmountInputRef } from 'components/DollarAmount/helpers';
import shopSelectors from 'services/shop/selectors';
type Props = {
  v2?: boolean;
};
const ButtonQuickPay = ({ v2 }: Props) => {
  const inputRef = useCurrencyKeyBoardRef();
  const inputRefV2 = useDollarAmountInputRef();
  const allSetting = shopSelectors.data.allSetting();
  const dispatch = useAppDispatch();
  const selectedItems = cashierSelectors.getSelectedItems();
  const [visibleChooseStaff, setVisibleChooseStaff] = useState(false);
  const visible = cashierSelectors.getVisibleQuickPay();
  const setVisible = (value: boolean) => dispatch(cashierUIActions.setVisibleQuickPay(value));
  const [amount, setAmount] = useState<number | null>();
  const [serviceName, setServiceName] = useState<string>('');
  const [, startTransition] = useTransition();
  const clearText = () => {
    inputRef.current?.setValue('');
    inputRefV2.current?.focus('');
    setAmount(undefined);
  };
  const handleClose = () => startTransition(() => {
    setVisible(false);
    setAmount(undefined);
  });

  const handleSubmit = () => {
    setTimeout(() => {
      dispatch(cashierActions.addQuickPay({
        name: serviceName,
        price: amount || 0,
      }));
    }, 200);
    handleClose();
  };

  const handleOpen = () => {
    if (selectedItems.length === 0 || !first(selectedItems)?.staffId) {
      setVisibleChooseStaff(true);
      return;
    }
    startTransition(() => {
      setVisible(true);
      setServiceName(QUICK_PAY_NAME);
    });
    setTimeout(() => clearText(), 100);
  };

  const onClear = () => clearText();

  const onIssueGiftCard = () => {
    handleClose();
    dispatch(cashierUIActions.setVisibleIssueGiftCard(true));
    setTimeout(() => {
      issueGiftCardV2Ref.current?.onActive();
    }, 100);
  };

  useEffect(() => {
    if (!v2) return;
    if (!visible) return;
    setServiceName(QUICK_PAY_NAME);
    setTimeout(() => clearText(), 100);
  }, [visible]);

  const onPickStaff = (staff: IStaff) => {
    dispatch(cashierActions.addStaffItem(cloneDeep(staff)));
    setVisibleChooseStaff(false);
    setVisible(true);
    setTimeout(() => clearText(), 100);
  };

  return (
    <>
      {v2 ?
        <CardTicketItem
          icon={'cardAddPriceV2'}
          content={'Add Price'}
          nbgcolor={'#CFFFCA'}
          v2
          textColor={colorTheme.text_3}
          border_color={'#00AD45'}
          border_color2={'#00AD45'}
          onClick={handleOpen}
        />
        : <Button block ntype="DANGER" onClick={handleOpen}>
          Add Price
        </Button>}
      {v2 ? <QuickPayV2
        visible={visible}
        onClose={handleClose}
        amount={amount}
        activeFormatMoney={allSetting?.activeFormatMoney}
        setAmount={setAmount}
        currencyKeyboardRef={inputRef}
        inputRef={inputRefV2}
        onSubmit={handleSubmit}
        setServiceName={setServiceName}
        serviceName={serviceName}
        onClear={onClear}
        onIssueGiftCard={onIssueGiftCard}
      /> :
        <Modal
          visible={visible}
          onClose={handleClose}
          modalTitle='ADD PRICE'
          okTitle="Done"
          onSubmit={handleSubmit}
        >
          <ServiceName>
            <Text style={{ fontSize: '1.2rem' }} mb={0.5}>
              Service Name:
            </Text>
            <Input size='small' value={serviceName} onChange={e => setServiceName(e.target.value)} />
          </ServiceName>
          <CurrencyKeyBoard smallInput ref={inputRef} onChange={setAmount} value={amount} />
        </Modal>
      }

      {visibleChooseStaff && <ModalAddStaff
        v2={v2}
        visible={visibleChooseStaff}
        onPickStaff={onPickStaff}
        onClose={() => setVisibleChooseStaff(false)}
      />}
    </>
  );
};

export default ButtonQuickPay;

const ServiceName = styled.div`
  margin-bottom: 1rem;
`;
type QuickPayV2Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
  serviceName?: string;
  setServiceName: (serviceName: string) => void;
  inputRef: React.RefObject<DollarAmountInputRef>;
  currencyKeyboardRef: React.RefObject<CurrencyKeyBoardRef>;
  setAmount: (value: ICurrencyKeyBoard['value']) => void;
  amount: number | null | undefined;
  onIssueGiftCard?: () => void;
  activeFormatMoney?: boolean;
  onClear?: () => void;
};
const QuickPayV2 = ({ onClear, activeFormatMoney, visible, onIssueGiftCard, onClose, onSubmit, serviceName, setServiceName, inputRef, currencyKeyboardRef, amount, setAmount }: QuickPayV2Props) => {
  return (
    <Modal
      visible={visible}
      modalTitle='ADD PRICE'
      okTitle="Ok"
      noneBodyStyle
      noneFooterStyle
      footer={null}
      hiddenHeader
      width={'1200px'}
    >
      <Container>
        <div className="header-container">
          <span>ADD PRICE</span>
        </div>
        <ServiceName className='ServiceName'>
          <Text className='modal-label' mb={0.5}>
            Service Name:
          </Text>
          <Input className='service-name-input' size='small' value={serviceName} onChange={e => setServiceName(e.target.value)} />
        </ServiceName>
        {!activeFormatMoney ? <>
          <CurrencyKeyBoard v2 smallInput ref={currencyKeyboardRef} onChange={setAmount} value={amount} />
        </> : <>
          <Text className='modal-label' mb={0.5}>
            Enter Amount:
          </Text>
          <DollarAmountInput
            ref={inputRef}
            className='CurrencyInput'
            value={amount}
            onChange={setAmount}
            inputMode='none'
            autoFocus
            color='#D2464F'
            maxValue={1000}
          />
          <DollarAmountKeyboard
            controlRef={inputRef}
            value={amount}
            onChange={setAmount}
            maxValue={1000}
          />
        </>}
        <div className="footer-container">
          <button onClick={onIssueGiftCard} type='button' className="btn btn-issue-gift-card"><span>Issue Gift Card</span></button>
          <button onClick={onClear} type='button' className="btn btn-clear"><span>Clear</span></button>
          <button onClick={onClose} type='button' className="btn btn-close"><span>Close</span></button>
          <button onClick={onSubmit} type='button' className="btn btn-done"><span>Ok</span></button>
        </div>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
.header-container {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background: #232F3E;
  span {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.modal-label {
  color: #64748B;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ServiceName {
  padding-top: 24px;
}
.ant-input.service-name-input {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  text-transform: capitalize;
  border: none;
  outline: none;
}

.CurrencyInput {
  justify-content: center;
  margin-bottom: 16px;
  input {
    border: none;
    box-shadow: none;
    text-align: center;
  }
  .fake-labels, input, .mock-cursor  {
    font-size: 50px;
    font-weight: 600;
  }
}

.footer-container {
  display: flex;
  padding: 0px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  padding-bottom: 40px;
  button.btn {
    display: flex;
    height: 70px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 4px;
    background: #E5E6EB;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.btn-issue-gift-card {
      border-radius: 4px;
      background: #C6E9EF;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
    }
    &.btn-clear {
      border-radius: 4px;
      background: #C6E9EF;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
    }

    &.btn-clear {
      border-radius: 4px;
      background: #FCFCFD;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    }

    &.btn-done {
      border-radius: 4px;
      background: #FF8890;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
    }
  }
}
`;
