import reportActions from 'features/report/services/actions';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import ReviewDashboard from './Dashboard';
import ReviewTable from './Table';
import styled from 'styled-components';
import colorTheme from 'constants/color';
import ReviewTableFilter from './Filter';
import shopSelectors from 'services/shop/selectors';

const ReviewReport = () => {
  const dispatch = useAppDispatch();
  const versionUI = shopSelectors.data.versionUI();
  const v2 = versionUI === 'V2';
  useEffect(() => {
    dispatch(reportActions.initReviewReportParams());
  }, []);

  return (
    <ClockReportStyled className={v2 ? 'v2' : ''}>
      <ReviewTableFilter v2={v2} />
      <ReviewDashboard v2={v2} />
      <ReviewTable v2={v2} />
    </ClockReportStyled>
  );
};

export default ReviewReport;

const ClockReportStyled = styled.div`
  background-color: ${colorTheme.fill_3};
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  &.v2 {
    gap: 16px;
    background: #F5F5F5;
    padding: 16px;
    min-height: unset;
  }
`;
