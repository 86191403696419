import Button from 'components/Button';
import DiscountMethodModal, { ISubmitModalDiscount } from 'features/cashier/components/Discount/DiscountMethodModal';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { useState } from 'react';
type Props = {
  detailTicket?: IBillDetailData | null,
  onSubmit?: ISubmitModalDiscount;
};
const DiscountButton = ({ detailTicket, onSubmit }: Props) => {
  const [visible, setVisible] = useState(false);
  const open = () => setVisible(true);
  const close = () => setVisible(false);

  return (
    <>
      <Button ntype='YELLOW' icon='discount' onClick={open}>
        Discount
      </Button>
      <DiscountMethodModal
        visible={visible}
        onClose={close}
        onSubmit={onSubmit}
        initialAmount={detailTicket?.subTotal ?? 0}
      />
    </>
  );
};

export default DiscountButton;
