import React, { CSSProperties, FC, useMemo } from 'react';
import ButtonAnthUI, { ButtonProps as ButtonAntProps } from 'antd/es/button';
import styled from 'styled-components';
import Text from 'components/Text';
import Icon, { typeIcon } from 'assets/Icons';
import { BUTTON_SIZES } from 'constants/size';
import { ISpacing, generateCssSpacing } from 'styles/spacing';
import clsx from 'clsx';

export enum BUTTON_VARIANT_TYPES {
  PRIMARY = 'PRIMARY',
  OUTLINE_PRIMARY = 'OUTLINE_PRIMARY',
  SECONDARY = 'SECONDARY',
  DEFAULT = 'DEFAULT',
  DANGER = 'DANGER',
  YELLOW = 'YELLOW',
  LIGHT_BLUE = 'LIGHT_BLUE',
  WHITE_BLUE = 'WHITE_BLUE',
  DARK_BLUE = 'DARK_BLUE',
  LIGHT_PRIMARY = 'LIGHT_PRIMARY',
  TEXT = 'TEXT',
  CIRCLE = 'CIRCLE',
  SIDE_BAR = 'SIDE_BAR',
  ORANGE = 'ORANGE',
  ORANGE_LIGHT = 'ORANGE_LIGHT',
  DARK = 'DARK',
  GREY = 'GREY',
  GHOST = 'GHOST',
}

// enum BUTTON_SIZES {
//   LARGE:
// };
type Props = ButtonAntProps & ISpacing;
export interface ButtonProps extends Props {
  ntype?: keyof typeof BUTTON_VARIANT_TYPES;
  icon?: typeIcon;
  iconElement?: any,
  wrapIconStyle?: CSSProperties,
  isSelected?: boolean;
  textWidth?: string;
  width?: string; // if just isModal, it will be default modal
  vertical?: boolean;
  height?: number;
  cardCashier?: boolean;
  cardCashierNormal?: boolean;
}

const BUTTON_VARIANT_CSS = {
  [BUTTON_VARIANT_TYPES.DEFAULT]: `
  background: #ffffff;
  border: 1px solid #86909C;
  &:hover {
    border: 1px solid #86909C !important;
    background-color: #E5E6EB !important;
  }
  &:disabled {
    background-color: #E5E6EB !important;
  }
  &.cardCashier {
      border: none  !important;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    &:hover {
      border: none !important;
    }
    &:disabled {
        box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    }
  }
    `,
  [BUTTON_VARIANT_TYPES.DANGER]: `
    background: #FFE5E7;
    border: 1px solid #F5767F;
    &:hover {
      border: 1px solid #F5767F !important;
      background-color: #FF8890 !important;
    }
    &:disabled {
      background-color: #FF8890 !important;
    }

    &.cardCashier {
       border: none  !important;
       box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FF8890 inset;
      &:hover {
        border: none !important;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FF8890 inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.SECONDARY]: `
    background: #E5E6EB;
    border: 1px solid #86909C;
    &:hover {
      border: 1px solid #86909C !important;
      background-color: #ADADAD !important;
    }
    &:disabled {
      background-color: #ADADAD !important;
    }
    &.cardCashier {
       border: none  !important;
       box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
      &:hover {
        border: none !important;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.PRIMARY]: `
    background: #FF8890 !important;
    border: 1px solid #F5767F !important;
    &:hover {
      border: 1px solid #F5767F !important;
      background-color: #F5767F !important;
    }
    &:disabled {
      background-color: #F5767F !important;
    }
    &.cardCashier {
      border: none  !important;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
      &:hover {
        border: none !important;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.OUTLINE_PRIMARY]: `
    background: #fff !important;
    border: 1.5px solid #F5767F !important;
    &:hover {
      border: 1.5px solid #F5767F !important;
      background-color: #E5E6EB !important;
    }
    &:disabled {
      background-color: #ADADAD !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.YELLOW]: `
    background: #FEEDBF !important;
    border: 1px solid #FDCC43 !important;
    &:hover {
      border: 1px solid #FDCC43 !important;
      background-color: #FDCC43 !important;
    }
    &:disabled {
      background-color: #FEEDBF !important;
    }

    &.cardCashier {
       border: none  !important;
       box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FDCC43 inset;
      &:hover {
        border: none !important;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FDCC43 inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.LIGHT_BLUE]: `
    background: #C6E9EF  !important;
    border: 1px solid #6FABB6  !important;
    &:hover {
      border: 1px solid #6FABB6 !important;
      background-color: #6FABB6 !important;
    }
    &:disabled {
      background-color: #C6E9EF !important;
    }

    &.cardCashier {
       border: none  !important;
       box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
      &:hover {
        border: none !important;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #6FABB6 inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.WHITE_BLUE]: `
    background: #FFF  !important;
    border: 1px solid #4aa5b6  !important;
    &:hover {
      border: 1px solid #4aa5b6 !important;
      background-color: #E4F4F6 !important;
    }
    &:disabled {
      background-color: #C6E9EF !important;
    }

    &.cardCashier {
       border: 1.5px solid #144B55  !important;
       box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #144B55 inset;
      &:hover {
        border: 1.5px solid #144B55  !important;
        background-color: #E4F4F6 !important;
      }
      .CONTENT_2 {
        color: #2D6772; 
        font-size: 18px;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #144B55 inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.DARK_BLUE]: `
    background: #6FABB6  !important;
    border: 1px solid #6FABB6  !important;
    &:hover {
      border: 1px solid #33a2b6 !important;
      background-color: #33a2b6 !important;
    }
    &:disabled {
      background-color: #6fabb6b3 !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.ORANGE]: `
    border: 1px solid var(--primary-primary-hover, #FF7D00)  !important;
    background: var(--orange, #F8D9BB ) !important;
    &:hover {
      border: 1px solid #FF7D00 !important;
      background-color: #FF7D00 !important;
    }
    &:disabled {
      background-color: #F8D9BB !important;
    }

    &.cardCashier {
       border: none  !important;
       box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FF7D00 inset;
      &:hover {
        border: none !important;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FF7D00 inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.ORANGE_LIGHT]: `
    border: 1px solid #ff7d008a  !important;
    background: #FFEEE4 !important;
    &:hover {
      border: 1px solid #ff7d008a !important;
      background-color: #ff7d008a !important;
    }
    &:disabled {
      background-color: #FFEEE4 !important;
    }

    &.cardCashier {
       border: none  !important;
       box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #ff7d008a inset;
      &:hover {
        border: none !important;
      }
      &:disabled {
          box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #ff7d008a inset;
      }
    }
    `,
  [BUTTON_VARIANT_TYPES.TEXT]: `
    background: #f6f7fc;
    border: 0px;
    box-shadow: none;
    &:hover {
      border: 0px solid #ffffff !important;
      background-color: #E5E6EB !important;
    }
    &:disabled {
      background-color: #E5E6EB !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.CIRCLE]: `
    fill: var(--fill-fill-0, #FFF);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin: auto;
    border: none;
    `,
  [BUTTON_VARIANT_TYPES.SIDE_BAR]: `
      background: #FFF;
      border: #fff;
      box-shadow: none;
      &:hover {
        border: 1px solid #F5767F !important;
        background-color: #FFE5E7 !important;
      }
      &:disabled {
        background-color: #FEEDBF !important;
      }
    `,
  [BUTTON_VARIANT_TYPES.DARK]: `
    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-2, #ADADAD);

    &:hover {
      border: 1px solid var(--line-line-3, #86909C);
      background: var(--fill-fill-2, #ADADAD);
    }
    &:disabled {
      background-color: #ADADAD !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.LIGHT_PRIMARY]: `
    border-radius: 5px;
    border: 1px solid #FF8890;
    background: #FFE5E7;

    &:hover {
      border: 1px solid #FF8890;
      background: #ff88909c;
    }
    &:disabled {
      background-color: #FFE5E7 !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.GREY]: `
    border-radius: 5px;
    border: 1px solid #86909C;
    background: #F6F7FC;

    &:hover {
      border: 1px solid #86909C;
      background: #E5E6EB;
    }
    &:disabled {
      background-color: #F6F7FC !important;
    }
    `,
  [BUTTON_VARIANT_TYPES.GHOST]: `
    border-radius: 5px;
    border: 1px solid transparent;
    background: transparent;
    box-shadow: none;

    &:hover {
      border: 1px solid transparent;
      background: transparent;
      opacity: 0.8;
    }
    &:disabled {
      background-color: transparent !important;
      opacity: 0.8;
    }
    `,
};

const ButtonStyled = styled(ButtonAnthUI) <ButtonProps>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 12px 16px;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  &:disabled {
    border: transparent !important;
    opacity: 0.5;
  }
  ${({ width }) => width && `width: ${width};`}

  &.${BUTTON_VARIANT_TYPES.SIDE_BAR}.selected {
    background: #ff8890;
    border: 1px solid #f5767f;
    &:hover {
      border: 1px solid #f5767f !important;
      background-color: #f5767f !important;
    }
  }

  ${Object.entries(BUTTON_VARIANT_CSS).map(
  ([key, css]) => `
      &.${key} {
        ${css}
      }
    `
)}

  ${({ vertical }) => vertical ? `
    flex-direction: column;
      svg {
        height: 100%;
        width: 100%;
      }
    gap: 4px;
  ` : ''}


  &.cardCashierNormal {
    background: #FFF !important;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -6px 0px 0px rgba(28, 52, 43, 0.10) inset  !important;
    &:hover {
      background: #E5E6EB !important;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -6px 0px 0px rgba(28, 52, 43, 0.10) inset  !important;
    }
    &:disabled {
      background: #FFF !important;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -6px 0px 0px rgba(28, 52, 43, 0.10) inset  !important;
    }
  }
`;

const Button: FC<ButtonProps> = ({
  icon,
  iconElement,
  ntype = BUTTON_VARIANT_TYPES.DEFAULT,
  wrapIconStyle,
  children,
  isSelected,
  width,
  textWidth,
  height,
  cardCashier,
  cardCashierNormal,
  ...props
}) => {
  const styles: React.CSSProperties = useMemo(() => {
    const shape =
      ntype !== BUTTON_VARIANT_TYPES.CIRCLE
        ? {
          ...props.style,
          height: height ? `${height}rem` : (props.size ? BUTTON_SIZES[props.size] : BUTTON_SIZES.middle),
        }
        : {};

    return {
      ...generateCssSpacing(props),
      ...shape,
    };
  }, [props]);

  return (
    <ButtonStyled
      {...props}
      width={width}
      // isSelected={isSelected}
      className={clsx(ntype, props.className,
        isSelected && ntype === BUTTON_VARIANT_TYPES.SIDE_BAR ? 'selected' : '',
        cardCashier && 'cardCashier',
        cardCashierNormal && 'cardCashierNormal',
      )}
      style={styles}
    >
      {iconElement ? iconElement : null}
      {icon ? (
        props.vertical ? <div style={wrapIconStyle ? wrapIconStyle : { width: '20px', height: '20px' }}> <Icon type={icon} /></div> : <Icon type={icon} />
      ) : null}
      {children && (
        <Text color={props.disabled ? 'fill_4' : undefined} variant="CONTENT_2" style={{ width: textWidth }}>
          {children}
        </Text>
      )}
    </ButtonStyled>
  );
};

export default Button;
