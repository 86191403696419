import { Col, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { styled } from 'styled-components';
import { IKeyboardType, TYPE_KEYBOARD } from '../helper';
import clsx from 'clsx';
import { toCurrency } from 'components/CurrencyInputMasked/currency-conversion';
import { useMemo } from 'react';
type NumberPadProps = {
  type: IKeyboardType;
  value?: string;
  onChange?: (val: string) => void;
  onKeyChange?: (val: string, keyType?: string) => void;
  hint?: boolean;
  modeTip?: boolean;
  controlRef?: React.RefObject<InputRef>;
  buttonNames?: NumberPadItem[];
  hidden?: boolean;
};
const NumberPadV2 = ({ hidden, buttonNames: buttonNamesProps, controlRef, type, value, onChange: onChangeProps = () => undefined, onKeyChange = () => undefined, hint = false, modeTip = false }: NumberPadProps) => {
  const _buttonNames: NumberPadItem[] = useMemo(() => buttonNamesProps ? buttonNamesProps : buttonNames, [buttonNamesProps]);
  const onChange = (val: string, keyType?: string) => {
    controlRef?.current?.focus();
    onChangeProps(val);
    onKeyChange(val, keyType);
  };
  return (
    <NumberPadStyled hidden={hidden} modeTip={modeTip} className='NumberPadStyled'>
      <Row gutter={[8, 12]} className='WRAPPER-KEYBOARD'>
        {_buttonNames.filter(o => {
          if (o.acceptAll) return true;
          return o.accept?.includes(type as any);
        }).map((item) => (
          <Col key={item.key} span={8}>
            <ButtonNumberPad
              onClick={() => onChange(item.get(value?.toString() || ''), item.key)}
              className={clsx('NUMBER-PAD-BUTTON', item.type || '')}
            >
              {typeof item.value === 'string' ? (
                <div className='NUMBER-PAD'>{item.value}</div>
              ) : (item.value)}
            </ButtonNumberPad>
          </Col>
        ))}
      </Row>
      {hint && <>
        <Text mt={0.5} />
        <Row gutter={[16, 16]}>
          <Col span={8}><Text className='hint'>CANCEL</Text></Col>
          <Col span={8} />
          <Col span={8}><Text className='hint'>CLEAR</Text></Col>
        </Row>
      </>
      }
    </NumberPadStyled>
  );
};

export default NumberPadV2;

export const NumberPadIntV2 = ({ controlRef, value, onChange = () => undefined, max }: { max?: number, value?: string, onChange?: (value: number) => void; currency?: boolean, controlRef?: React.RefObject<InputRef> }) => {
  const displayValue = `${parseInt(value || '')}`;
  const handleInputChange = (e: string) => {
    controlRef?.current?.focus();
    const rawValue = e?.replace(/[^0-9]/g, ''); // Strip non-numeric characters
    let numericValue = 0.0;

    if (rawValue) {
      const intValue = parseInt(rawValue);
      numericValue = intValue;
    }
    if (max && numericValue > max) return;
    onChange(numericValue);
  };
  return (
    <NumberPadV2 type={'NUMBER'} value={displayValue} onChange={handleInputChange} />
  );
};

export const NumberPadDecimalV2 = ({ controlRef, currency, value, onChange = () => undefined, max }: { max?: number, value?: string, onChange?: (value: number) => void; currency?: boolean, controlRef?: React.RefObject<InputRef> }) => {
  const displayValue = currency ? `${toCurrency(parseFloat(value || '').toFixed(2))}` : `${parseFloat(value || '').toFixed(2)}`;
  const handleInputChange = (e: string) => {
    controlRef?.current?.focus();
    const rawValue = e?.replace(/[^0-9]/g, ''); // Strip non-numeric characters
    let numericValue = 0.0;

    if (rawValue) {
      const intValue = parseInt(rawValue);
      numericValue = intValue / 100;
    }
    if (max && numericValue > max) return;
    onChange(numericValue);
  };
  return (
    <NumberPadV2 type={'NUMBER'} value={displayValue} onChange={handleInputChange} />
  );
};
export const ButtonNumberPad = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90px;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 6px;
  background: #FCFCFD;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  border: none;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  
  .NUMBER-PAD {
    color: #1D2129;
    font-family: Poppins;
    font-size: 35px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &.clear, &.backspace {
    background: #E5E6EB;
  }

  &:hover {
    cursor: pointer;
  }
  &:focus-visible{
    outline: 4px solid #fff0f0;
    outline-offset: 1px;
    transition: outline-offset 0s,outline 0s;
  }
`;

const NumberPadStyled = styled.div<{ modeTip?: boolean }> `
  padding: 16px;
  .hint {
    color: var(--text-text-2, #767676);
    text-align: center;
    font-family: Open Sans;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  ${({ modeTip }) => modeTip ? `
  width: 55%;
    .NUMBER-PAD-BUTTON {
      box-shadow: none;
      border: 1px solid #37413D;
      height: 8rem !important;
      width: 8rem !important;
      .NUMBER-PAD {
        font-weight: unset;
        font-size: 3.5rem !important;
      }
    }
  `: ''}
`;
export type NumberPadItem = {
  key: string;
  value: JSX.Element | string;
  get: (value: string) => string;
  accept?: TYPE_KEYBOARD[];
  type?: string;
  acceptAll?: boolean;
};
const buttonNames: NumberPadItem[] = [
  {
    key: '1',
    value: '1',
    get: (text: string) => text + '1',
    acceptAll: true,
  },
  {
    key: '2',
    value: '2',
    get: (text: string) => text + '2',
    acceptAll: true,
  },
  {
    key: '3',
    value: '3',
    get: (text: string) => text + '3',
    acceptAll: true,
  },
  {
    key: '4',
    value: '4',
    get: (text: string) => text + '4',
    acceptAll: true,
  },
  {
    key: '5',
    value: '5',
    get: (text: string) => text + '5',
    acceptAll: true,
  },
  {
    key: '6',
    value: '6',
    get: (text: string) => text + '6',
    acceptAll: true,
  },
  {
    key: '7',
    value: '7',
    get: (text: string) => text + '7',
    acceptAll: true,
  },
  {
    key: '8',
    value: '8',
    get: (text: string) => text + '8',
    acceptAll: true,
  },
  {
    key: '9',
    value: '9',
    get: (text: string) => text + '9',
    acceptAll: true,
  },
  {
    key: 'removeAll',
    value: <Icon type="removeAll"> </Icon>,
    get: () => '',
    accept: [TYPE_KEYBOARD.PHONE, TYPE_KEYBOARD.PIN_PASSWORD, TYPE_KEYBOARD.NUMBER],
    type: 'clear',
  },
  {
    key: 'dot',
    value: '.',
    get: (text: string) => {
      if (!text) return '0.';
      return text.includes('.') ? text : text + '.';
    },
    accept: [TYPE_KEYBOARD.CURRENCY],
  },
  {
    key: '0',
    value: '0',
    get: (text: string) => text + '0',
    acceptAll: true,
  },
  {
    key: 'backSpace',
    value: (
      <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40" fill="none">
        <path d="M12.3551 10H37.0834V30H12.3551L4.58337 20L12.3551 10Z" fill="#86909C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.2693 14.1418C29.7575 14.6299 29.7575 15.4214 29.2693 15.9095L19.1123 26.0665C18.6241 26.5547 17.8327 26.5547 17.3445 26.0665C16.8564 25.5784 16.8564 24.7869 17.3445 24.2988L27.5015 14.1418C27.9897 13.6536 28.7812 13.6536 29.2693 14.1418Z" fill="#86909C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3445 14.1418C17.8327 13.6536 18.6241 13.6536 19.1123 14.1418L29.2693 24.2988C29.7575 24.7869 29.7575 25.5784 29.2693 26.0665C28.7812 26.5547 27.9897 26.5547 27.5015 26.0665L17.3445 15.9095C16.8564 15.4214 16.8564 14.6299 17.3445 14.1418Z" fill="#86909C" />
        <path fillRule="evenodd" clipRule="evenodd" d="M29.2693 14.1418C29.7575 14.6299 29.7575 15.4214 29.2693 15.9095L19.1123 26.0665C18.6241 26.5547 17.8327 26.5547 17.3445 26.0665C16.8564 25.5784 16.8564 24.7869 17.3445 24.2988L27.5015 14.1418C27.9897 13.6536 28.7812 13.6536 29.2693 14.1418Z" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M17.3445 14.1418C17.8327 13.6536 18.6241 13.6536 19.1123 14.1418L29.2693 24.2988C29.7575 24.7869 29.7575 25.5784 29.2693 26.0665C28.7812 26.5547 27.9897 26.5547 27.5015 26.0665L17.3445 15.9095C16.8564 15.4214 16.8564 14.6299 17.3445 14.1418Z" fill="white" />
      </svg>
    ),
    get: (text: string) => text.slice(0, text.length - 1),
    acceptAll: true,
    type: 'backspace',
  },
];
