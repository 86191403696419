import { Button, Form, Input, Spin } from 'antd';
import { FormInstance } from 'antd/lib';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
import Text from 'components/Text';
import useGetInfoGiftCard from 'features/payment/pages/PaymentPage/CashierSide/components/GiftCard/useGetInfoGiftCard';
import moment from 'moment';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import qrScanIcon from './QR-scan.svg';
import giftCardBgIcon from './gift-card-bg-icon.svg';
import { ContainerKeyboard, FooterActions, GiftCardDetailContainer, GiftCardNumberContainer, GiftCardTabs, HeaderModal } from './styles';
import GiftCardTab, { GIFT_CARD_RADIO_TYPE } from './Tab';
import React, { forwardRef, useImperativeHandle } from 'react';
import { issueGiftCardV2Ref } from './IssueGiftCard';

type Props = {
  form: FormInstance<{ giftNumber: string }>
  onClose: () => void;
  setRadioType: (val: GIFT_CARD_RADIO_TYPE) => void;
};
type Ref = {
  onActive: () => void;
};
export const checkGiftCardV2Ref = React.createRef<Ref>();
const CheckGiftCardV2 = forwardRef<Ref, Props>(({ form, onClose, setRadioType }, ref) => {
  const giftCard = useGetInfoGiftCard();

  useImperativeHandle(ref, () => ({
    onActive: () => {
      form.resetFields();
      giftCard.inputRef.current?.focus();
      giftCard.reset();
    },
  }));
  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const code = form.getFieldValue('giftNumber');
      await giftCard.fetchData(code);
      setTimeout(() => giftCard.inputRef?.current?.blur(), 100);
      return;
    }
  };
  return (
    <ContainerModal form={form} onValuesChange={(e: any) => {
      if (e.giftNumber) {
        giftCard.inputRef.current?.focus();
        giftCard.setCode(e.giftNumber);
      } else {
        giftCard.reset();
      }
    }}>
      <Spin spinning={giftCard.loading}>
        <ContainerKeyboard>
          <HeaderModal>
            <div className="modal-title">gift cards</div>
          </HeaderModal>
          <GiftCardTabs>
            <GiftCardTab type={GIFT_CARD_RADIO_TYPE.ISSUED} onClick={() => {
              setRadioType(GIFT_CARD_RADIO_TYPE.ISSUED);
              issueGiftCardV2Ref.current?.onActive();
            }} />
            <GiftCardTab active type={GIFT_CARD_RADIO_TYPE.BALANCE} />
          </GiftCardTabs>
          <GiftCardNumberContainer>
            <Text className='modal-label' mb={0.5}>
              Gift Card Number:
            </Text>
            <div className="input-selector-container">
              <Form.Item noStyle name={'giftNumber'}>
                <Input autoFocus ref={giftCard.inputRef} inputMode='none' className='gift-card-number-input fs-big' size='small'
                  onKeyDown={handleKeyDown}
                />
              </Form.Item>
              <button type='button' className='btn-scan' onClick={giftCard.onScanBtn}><img src={qrScanIcon} alt="qrScanIcon" className='qr-scan-icon' /></button>
            </div>
            {giftCard.msg && <div className="modal-warning">{giftCard.msg}</div>}
          </GiftCardNumberContainer>
          <Form.Item noStyle name={'giftNumber'}>
            <NumberPadV2 type={'NUMBER'} />
          </Form.Item>
          <FooterActions>
            <Button onClick={onClose} htmlType='button' type='default'><span>Close</span></Button>
          </FooterActions>
        </ContainerKeyboard>
      </Spin>

      <GiftCardDetailContainer>
        <img src={giftCardBgIcon} alt="giftCardBgIcon" className='gift-card-bg-icon' />
        <div className="detail-item">
          <div className="detail-item-label">Gift Card Number</div>
          <div className="detail-item-amount">
            <div className="detail-item-amount-text">
              {giftCard.data?.giftCode || '--'}
            </div>
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-item-label">Balance</div>
          <div className="detail-item-amount">
            <div className="detail-item-amount-text">
              {formatCurrency(giftCard.resultGiftCard.available)}
            </div>
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-item-label">Available</div>
          <div className="detail-item-amount">
            <div className="detail-item-amount-text">
              {formatCurrency(giftCard.resultGiftCard.available)}
            </div>
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-item-label">Used</div>
          <div className="detail-item-amount">
            <div className="detail-item-amount-text">
              {formatCurrency(giftCard.resultGiftCard.used)}
            </div>
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-item-label">Amount</div>
          <div className="detail-item-amount">
            <div className="gift-amount-box">
              <div className="detail-item-amount-text">
                {formatCurrency(giftCard.resultGiftCard.amount)}
              </div>
            </div>
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-item-label">Last Used Date</div>
          <div className="detail-item-amount">
            <div className="detail-item-amount-text">
              {giftCard.data?.updatedDate ? moment(giftCard.data?.updatedDate).format('MM-DD-YYYY') : '__/__/____'}
            </div>
          </div>
        </div>
        <div className="detail-item">
          <div className="detail-item-label">Expiration</div>
          <div className="detail-item-amount">
            <div className="detail-item-amount-text">
              {giftCard.resultGiftCard.expireDateV2}
            </div>
          </div>
        </div>
      </GiftCardDetailContainer>
    </ContainerModal>

  );
});
CheckGiftCardV2.displayName = 'CheckGiftCardV2';
export default CheckGiftCardV2;

const ContainerModal = styled(Form)`
  display: flex;
`;
