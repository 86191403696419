import { Col, Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import { IBillDetailData } from 'features/payment/services/types/bill';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import { ICalcDetailTicketView } from '../../services/types/card';
type ITicketDetailProps = {
  serviceCharge?: number;
  detailTicket: IBillDetailData | null
  calc: ICalcDetailTicketView;
};
const TicketDetail: React.FC<ITicketDetailProps> = ({ detailTicket, calc }) => {
  const navigate = useNavigate();
  const handleBack = () => navigate(`/store/${storage.shop_id.get()}/check-in/sign-in`);

  return (
    <TicketDetailStyled>
      <Button icon='back' size='middle' className='btn-back' onClick={handleBack}>Back</Button>
      <Text variant='H5' mt={1} style={{ marginBottom: '3vw' }}>Payment Details</Text>
      <div className="box-info">
        <Text variant='H5' mb={0.5}>#{detailTicket?.ticketNumber}</Text>
        <Text variant='H6' mb={0.25} >{detailTicket?.customerName}</Text>
        <Text variant='H8' color='text_2'>{detailTicket?.startTime ? momentTimezone(detailTicket?.startTime).format('MM-DD-YYYY LT') : null}</Text>
      </div>
      <div className="items">
        {detailTicket?.items.map(o => (
          <div key={o.staffId} className='item'>
            <Text variant='H9'>{o.staffName}</Text>
            <Text className='border-dotted' mb={0.5} />
            {o.services.map((s, i) => (
              <Row key={i} align={'middle'} justify={'space-between'} className='service'>
                <Text variant='CONTENT_1'>{s.itemName}</Text>
                <Text variant='CONTENT_2'>{formatCurrency(s.price || 0)}</Text>
              </Row>
            ))}
          </div>
        ))}
      </div>
      <Text className="border" mb={2} />
      <Row className="group-info">
        <Col flex={'auto'}>
          <div className="item">
            <Text variant='H8'>Processing Fee:</Text>
            <Text variant='H7'>{formatCurrency(calc.serviceCharge)}</Text>
          </div>
          <div className="item">
            <Text variant='H8'>Disc. By Item:</Text>
            <Text variant='H7'>{formatCurrency(calc.discByItem)}</Text>
          </div>
          <div className="item">
            <Text variant='H8'>Disc. By Ticket:</Text>
            <Text variant='H7'>{formatCurrency(calc.discByTicket)}</Text>
          </div>
          <div className="item">
            <Text variant='H8'>Subtotal:</Text>
            <Text variant='H7'>{formatCurrency(calc.subTotal)}</Text>
          </div>

        </Col>
        <Col flex={'auto'} />
        <Col flex={'auto'}>
          <div className="item">
            <Text variant='H8'>Balance:</Text>
            <Text variant='H7'>{formatCurrency(calc.balance)}</Text>
          </div>
          <div className="item">
            <Text variant='H8'>Sale Tax:</Text>
            <Text variant='H7'>{formatCurrency(calc.saleTax)}</Text>
          </div>
          <div className="item">
            <Text variant='H8'>Use Tax:</Text>
            <Text variant='H7'>{formatCurrency(calc.useTax)}</Text>
          </div>
          <div className="item">
            <Text variant='H8'>Tip:</Text>
            <Text variant='H7'>{formatCurrency(calc.tip)}</Text>
          </div>
        </Col>
      </Row>
      <Text className="border" mt={1} mb={2} />
      <Row justify={'end'} align={'middle'}>
        <Text variant='H5' mr={2}>Total:</Text>
        <Text variant='H3' color='primary_active'>{formatCurrency(calc.total)}</Text>
      </Row>
    </TicketDetailStyled>
  );
};

export default TicketDetail;
type TicketDetailStyledProps = {}
const TicketDetailStyled = styled.div<TicketDetailStyledProps>`
.btn-back {
  position: fixed;
  top: 0.5rem;
  right: 2.5rem;
}
  padding-right: 5vw;
  .items {
    max-height: 35vh;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
    }
    .border-dotted {
      border: 1px dotted #86909C;
    }
    .item {
      margin-bottom: 1rem;
    }
    .service {
      margin-bottom: 0.5rem;
    }
  }
  .border {
    border: 0.75px solid #86909C;
  }
  .box-info {
    margin-bottom: 2rem;
  }
  .group-info {
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
  }
`;
