import { DatePicker as AntDatePickerUI, Row } from 'antd';
import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import Text from 'components/Text';
import appointmentActions from 'features/appointment/services/actions';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { DATE_FORMAT, DATE_FORMAT_VIEW } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
import calendar_icon from '../../v2/icons/calendar.svg';

const AntDatePicker = AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);
const getStartDate = (date: Moment | null | undefined, v2?: boolean) => {
  if (!v2) return date?.format(DATE_FORMAT_VIEW);
  const now = moment();
  if (date?.isSame(now, 'date')) {
    return `Today, ${date?.format('MM-DD-YYYY')}`;
  }
  if (date?.isSame(now.subtract(1, 'day'), 'date')) {
    return `Yesterday, ${date?.format('MM-DD-YYYY')}`;
  }
  return date?.format('ddd, MM-DD-YYYY');
};

type IDatePickerProps = PickerDateProps<Moment> & {
  viewType: CALENDAR_VIEW_TYPE;
  v2?: boolean;
};
const AppointmentDatePicker: React.FC<IDatePickerProps> = ({ viewType, v2 }) => {
  const dispatch = useAppDispatch();
  const params = appointmentSelectors.table.params();


  const startDate = useMemo(() => {
    return params?.startTime ? momentTimezone(params?.startTime) : null;
  }, [params]);

  const endDate = useMemo(() => {
    return params?.endTime ? momentTimezone(params?.endTime) : null;
  }, [params]);


  if (viewType === CALENDAR_VIEW_TYPE.DAY_VIEW) {
    const onChange: IDatePickerProps['onChange'] = (date) => {
      if (!date) return;
      dispatch(appointmentActions.setTableParams({
        startTime: date?.format(DATE_FORMAT),
        endTime: date?.format(DATE_FORMAT),
        page: 1,
      }));
    };

    return (
      <Row>
        <DatePickerStyled
          onChange={onChange}
          size='small'
          inputReadOnly
          clearIcon={false}
          value={startDate}
          suffixIcon={v2 ? false : undefined}
          className={v2 ? 'v2' : ''}
          inputRender={(props: any) => (
            <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
              {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
              <Text variant='CONTENT_2' color='text_3' >{getStartDate(startDate, v2)}</Text>
              {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
            </InputUI>
          )}
        />
      </Row>
    );
  }

  if (viewType === CALENDAR_VIEW_TYPE.WEEK_VIEW) {
    const onChange: IDatePickerProps['onChange'] = (date) => {
      if (!date) return;
      dispatch(appointmentActions.setTableParams({
        startTime: date?.startOf('week').format(DATE_FORMAT),
        endTime: date?.endOf('week').format(DATE_FORMAT),
        page: 1,
      }));
    };

    return (
      <Row>
        <DatePickerStyled
          onChange={onChange}
          picker="week"
          size='small'
          clearIcon={false}
          inputReadOnly
          value={startDate}
          suffixIcon={v2 ? false : undefined}
          className={v2 ? 'v2' : ''}
          inputRender={(props: any) => (
            <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
              {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
              <Text variant='CONTENT_2' color='text_3' >{startDate?.format(DATE_FORMAT_VIEW)} - {endDate?.format(DATE_FORMAT_VIEW)}</Text>
              {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
            </InputUI>
          )}
        />
      </Row>
    );
  }

  if (viewType === CALENDAR_VIEW_TYPE.MONTH_VIEW) {
    const onChange: IDatePickerProps['onChange'] = (date) => {
      if (!date) return;
      dispatch(appointmentActions.setTableParams({
        startTime: date?.startOf('month').format(DATE_FORMAT),
        endTime: date?.endOf('month').format(DATE_FORMAT),
        page: 1,
      }));
    };
    return (
      <Row style={{ alignSelf: v2 ? 'stretch' : undefined }}>
        <DatePickerStyled
          clearIcon={false}
          inputReadOnly
          value={startDate}
          onChange={onChange}
          picker="month"
          suffixIcon={v2 ? false : undefined}
          className={v2 ? 'v2' : ''}
          inputRender={(props: any) => (
            <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
              {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
              <Text variant='CONTENT_2' color='text_3' >{startDate?.format('MM/YYYY')}</Text>
              {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
            </InputUI>
          )}
          size='small'
        />
      </Row>
    );
  }

  return null;
};

export default AppointmentDatePicker;
type DatePickerStyledProps = {}
const DatePickerStyled = styled(AntDatePicker) <DatePickerStyledProps>`
  min-width: 20rem;
  &.v2 {
   width:100%;
    min-width: 320px;
    height: 100%;
    background: transparent;
    border: 1px solid transparent;
    .InputUI {
      justify-content: center;
      gap: 16px;
      .CONTENT_2 {
        color: #505050;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
`;

const InputUI = styled(Row)`
flex: 1;
padding: 0.5rem;
margin-right: 0.5rem;
-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;