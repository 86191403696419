import Box from 'components/Box';
import Button from 'components/Button';
import Loading from 'components/Loading';
import Text from 'components/Text';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';


export type ICreditPaymentLoadingRef = {
  setVisible: (val: boolean) => void;
  openTimeout: (time: number) => void;
  open: (amount: number, time: number) => void;
  close: () => void
};
type Props = {
  total?: number;
  onCancel?: () => void;
};
const CreditCardLoading = forwardRef<ICreditPaymentLoadingRef, Props>(({ total: totalProp = 0, onCancel }, ref) => {
  const [open, setOpen] = useState(false);
  const [totalValue, setTotalValue] = useState(0);
  const total = totalProp || totalValue;
  useImperativeHandle(ref, () => ({
    setVisible: val => setOpen(val),
    openTimeout: (time) => {
      setOpen(true);
      setTimeout(() => setOpen(false), time);
    },
    open(amount, time) {
      setTotalValue(amount);
      setOpen(true);
      setTimeout(() => setOpen(false), time);
    },
    close: () => {
      setTotalValue(0);
      setOpen(false);
    }
  }));

  if (!open) return null;

  return (
    <LoadingButtonStyled>
      <Container>
        <Box>
          <p className="title">Transaction Amount</p>
          <p className="total-ticket">{formatCurrency(total)}</p>
        </Box>
        <Box display='flex' justifyContent='center'>
          <div className="card-list">
            <div className="card-item">
              <img src={require('./CreditCardTap.png')} />
              <p>TAP</p>
            </div>
            <div className="card-item">
              <img src={require('./CreditCardInsert.png')} />
              <p>INSERT</p>
            </div>
            <div className="card-item">
              <img src={require('./CreditCardSwipe.png')} />
              <p>SWIPE</p>
            </div>
          </div>
        </Box>
        <Box>
          <div className="loading-container">
            <div className="loading">
              <Loading />
            </div>
          </div>
          <Box flexDirection='column' className='center' gap='4'>
            <Text className='payment-processing' variant="H7" color="primary_active">
              Payment Processing...
            </Text>
            {onCancel && <Button style={{ padding: '8px 32px' }} size='middle' className='btn-back' onClick={onCancel}><Text variant='CONTENT_2' style={{ fontSize: 18 }}>Cancel</Text></Button>}
          </Box>
        </Box>
      </Container>
    </LoadingButtonStyled>
  );
});
CreditCardLoading.displayName = 'CreditCardLoading';
export default CreditCardLoading;

const LoadingButtonStyled = styled.div`
  position: fixed;
  inset: 0;
  z-index: 9999;
  background: #fff;
`;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;
  flex-direction: column;

  .card-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2.5rem;
    .card-item {
      width: 267px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 24px;
      img {
        width: 100%;
      }
      p {
        color: #767676;
        text-align: center;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }

  .loading-container {
    position: relative;
    height: 62px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    .loading {
     position: absolute;
    }
  }

  .title {
    color: #767676;
    text-align: center;
    font-family: Roboto;
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .total-ticket {
    color: #1D2129;
    text-align: center;
    font-family: Roboto;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .payment-processing {
    color: #FC6A00;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
