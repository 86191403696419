import { Menu, MenuProps } from 'antd';
import Box from 'components/Box';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Turn from '../../Turn';
import LateForWork from '../../Turn/LateForWork';
import QuickPayTurnSetting from './QuickPayTurnSetting';
import { useAppDispatch } from 'store/hooks';
import settingServiceActions from 'features/settingService/services/actions';
type MenuItem = Required<MenuProps>['items'][number];
type ITurnTabProps = {};
enum MenuKeys {
  TURN = 'turn',
  LATE_FOR_WORK = 'late_for_work',
  QUICK_PAY = 'quick_pay',
}

const items: MenuItem[] = [
  getItem('Turn', MenuKeys.TURN),
  getItem('Quick Pay', MenuKeys.QUICK_PAY),
  getItem('Late For Work', MenuKeys.LATE_FOR_WORK),
];
const TurnTab: React.FC<ITurnTabProps> = () => {
  const dispatch = useAppDispatch();
  const [selectedKey, setSelectedKey] = useState<MenuKeys>(MenuKeys.TURN);

  const handleMenuSelect = (info: { key: string }) => {
    setSelectedKey(info.key as MenuKeys);
  };

  useEffect(() => {
    dispatch(settingServiceActions.getQuickPayTurnList.fetch());
  }, []);

  return (
    <TurnTabStyled>
      <BoxStyled display="flex" p="4" gap="4" alignItems="stretch">
        <LeftBoxStyled>
          <MenuBoxStyled>
            <Menu
              style={{ width: '100%' }}
              defaultSelectedKeys={[MenuKeys.TURN]}
              items={items}
              onSelect={handleMenuSelect}
            />
          </MenuBoxStyled>
        </LeftBoxStyled>
        {selectedKey === MenuKeys.TURN && <Turn />}
        {selectedKey === MenuKeys.LATE_FOR_WORK && <LateForWork />}
        {selectedKey === MenuKeys.QUICK_PAY && <QuickPayTurnSetting />}
      </BoxStyled>
    </TurnTabStyled>
  );
};

export default TurnTab;
const TurnTabStyled = styled.div`
  display: flex;
  flex-direction: column;
  background: var(--fill-fill-3, #e5e5e5);
  height: calc(100vh - 8.75rem);
  min-width: 50rem;
  overflow: auto;
`;

const BoxStyled = styled(Box)`
  display: flex;
  flex-grow: 1;
  align-items: stretch;
`;

const LeftBoxStyled = styled.div`
  border-radius: 5px;
  min-width: 16.5rem;
  background-color: white;
  gap: 1.5rem;
`;

const MenuBoxStyled = styled.div`
  padding: 0.25rem 0;
  .ant-menu-item {
    margin: 0;
    width: 100%;
    border-radius: 0;
  }
`;

function getItem(
  label: React.ReactNode,
  key?: React.Key | null,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}
