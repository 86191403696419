import { Col, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalarySummaryStaffRangeDate_Item, IPrintSalarySummaryStaffRangeDate_Item_Detail } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../components/PrintHeader';
import { ReportType } from '../constants';
import { renderTime } from '../utils';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import shopSelectors from 'services/shop/selectors';

interface IEmployeeSalaryDateRangeReportPrintPage { }

const rowStyle = { padding: '2px 0px' };
const RowItem = (item: IPrintSalarySummaryStaffRangeDate_Item_Detail) => {
    return (
        <StyledRow style={rowStyle}>
            <ColHeader span={10}>
                <Text printMode variant='CAPTION_3' color="text_3">{moment(item?.createdDate).format(DATE_FORMAT)}</Text>
            </ColHeader>
            <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(item?.price)}</Text></ColHeader>
            <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(item?.tip)}</Text></ColHeader>
        </StyledRow>
    );
};
const UserDetail = (data: IPrintSalarySummaryStaffRangeDate_Item, cashLabel = 'Cash') => {
    return (
        <Box px='3' mb='1'>
            <Box className="center" highlight>
                <Text printMode variant="CAPTION_2" style={{ paddingTop: '2px' }} className='title-highlight'>
                    {data?.staffName}
                </Text>
            </Box>
            <Box>
                <Header>
                    <ColHeader span={10}><Text printMode variant='CAPTION_3' color='text_2'>DATE</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color='text_2'>PRICE</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color='text_2'>TIPS</Text></ColHeader>
                </Header>
                {/* @ts-ignore */}
                {data?.details?.map(item => RowItem(item))}
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' color="text_3" >TOTAL SALES</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalSaleService)}</Text></ColHeader>
                </Discount>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' color="text_3" >SUPPLY FEES</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.supplyFee)}</Text></ColHeader>
                </Discount>
                <Box className="space-between" bb="print_line" bStyle='dashed' highlight>
                    <Text printMode variant='CAPTION_2' color="text_3" className='title-highlight' >
                        TOTAL
                    </Text>
                    <Text printMode variant='CAPTION_2' color="text_3" className='title-highlight'  >
                        {formatCurrency((data?.totalSaleService || 0) - (data?.supplyFee || 0))}
                    </Text>
                </Box>
                <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' color="text_3" >COMMISSION ({data?.commissionPercent || 0}%)</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalCommission)}</Text></ColHeader>
                </Discount>
                {!!data?.staffDiscount && <Discount>
                    <ColHeader span={17}><Text printMode variant='CAPTION_2' color="text_3" >DISCOUNT</Text></ColHeader>
                    <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.staffDiscount)}</Text></ColHeader>
                </Discount>}
                <>
                    {!!data?.tipCollected && <Discount>
                        <ColHeader flex={1}><Text printMode variant='CAPTION_2' color="text_3" >COLLECTED TIPS</Text></ColHeader>
                        <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.tipCollected)}</Text></ColHeader>
                    </Discount>}
                    {!!data?.tipFee && <>
                        <Discount>
                            <ColHeader flex={1}><Text printMode variant='CAPTION_2' color="text_3" >TIP FEE</Text></ColHeader>
                            <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">
                                {formatCurrency(data?.tipFee) + ' '}
                                {(data?.tipFeeType === 'PERCENT' && `(${data?.tipFeeValue || 0}%)`)}
                            </Text></ColHeader>
                        </Discount>
                        <Discount>
                            <ColHeader flex={1}><Text printMode variant='CAPTION_2' color="text_3" >TOTAL TIP</Text></ColHeader>
                            <ColHeader span={7} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalTip)}</Text></ColHeader>
                        </Discount>
                    </>}
                </>
                <Box className="space-between" bb="print_line" bStyle='dashed' highlight>
                    <Text printMode variant='CAPTION_2' color="text_3" className='title-highlight' >
                        Paid Out
                    </Text>
                    <Text printMode variant='CAPTION_2' color="text_3" className='title-highlight'  >
                        {formatCurrency(data?.salaryAmount)}
                    </Text>
                </Box>
                <BoxInfo>
                    <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                        {cashLabel} {data?.cashPercent}%
                    </Text>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        {formatCurrency(data?.totalPayCash)}
                    </Text>
                </BoxInfo>
                <BoxInfo>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        Check {data?.checkPercent}%
                    </Text>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        {formatCurrency(data?.totalPayCheck)}
                    </Text>
                </BoxInfo>
                {!!data?.tipToCash &&
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                            Tip to {cashLabel} {data?.tipCashPercent ? ` (${data?.tipCashPercent}%)` : ''}
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.tipToCash)}
                        </Text>
                    </BoxInfo>}
                <BoxInfo>
                    <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                        Tip to Check {data?.tipCheckPercent ? ` (${data?.tipCheckPercent}%)` : ''}
                    </Text>
                    <Text printMode variant='CAPTION_3' color="text_3" >
                        {formatCurrency(data?.tipToCheck)}
                    </Text>
                </BoxInfo>
                <Box bt='print_line'>
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                            Total {cashLabel}
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3" >
                            {formatCurrency(data?.totalCash)}
                        </Text>
                    </BoxInfo>
                    <BoxInfo>
                        <Text printMode variant='CAPTION_2' color="text_3">
                            Total Check
                        </Text>
                        <Text printMode variant='CAPTION_2' color="text_3">
                            {formatCurrency(data?.totalCheck)}
                        </Text>
                    </BoxInfo>
                </Box>
            </Box>
        </Box>
    );
};
const EmployeeSalaryDateRangeReportPrintPage: React.FC<IEmployeeSalaryDateRangeReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dataPrintSalaryStaffRangeDate = reportSelectors.getPrintSalarySummaryStaffRangeDate();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.EMPLOYEE_SALARY];
    const filterTypeSalaryStaff = reportSelectors.getFilterTypeSalaryStaff();
    const allSetting = shopSelectors.data.allSetting();
    const cashLabel = allSetting?.labelSalaryCashOrBonus || 'Cash';
    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalarySummaryStaffRangeDate.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            salaryType: filterTypeSalaryStaff
        }));
    }, [dataAppointment, filterTypeSalaryStaff]);

    const onBack = () => {
        navigate(-1);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });
    return (
        <Flex>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <Box className="center" overflow="auto">
                        <TicketBox>
                            <Box p='3' style={{ paddingTop: 0 }}>
                                <LogoBox>
                                    <IconLogo />
                                </LogoBox>
                                <Box className="center">
                                    <Text printMode variant="CONTENT_2" color="text_2">
                                        {renderTime(dataAppointment)}
                                    </Text>
                                </Box>
                            </Box>
                            <Box px='3'>
                                <BoxInfo bb="print_line" highlight>
                                    <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight'>
                                        Employee Salaries
                                    </Text>
                                    <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight'>
                                        {formatCurrency(dataPrintSalaryStaffRangeDate?.totalSalary)}
                                    </Text>
                                </BoxInfo>
                                <BoxInfo py='1'>
                                    <Text textTransform='capitalize' printMode variant="CAPTION_3" color="text_3" >
                                        Total {cashLabel}
                                    </Text>
                                    <Text printMode variant="CAPTION_3" color="text_3" >
                                        {formatCurrency(dataPrintSalaryStaffRangeDate?.totalCash)}
                                    </Text>
                                </BoxInfo>
                                <BoxInfo>
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        Total Check
                                    </Text>
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        {formatCurrency(dataPrintSalaryStaffRangeDate?.totalCheck)}
                                    </Text>
                                </BoxInfo>
                            </Box>
                            {!!allSetting?.autoCutPrintReport && <DividerSlit className='page-break' />}
                            {dataPrintSalaryStaffRangeDate?.items?.map((m, i) => {
                                const isLast = (dataPrintSalaryStaffRangeDate?.items?.length - 1) === i;
                                return (
                                    <div key={i}>
                                        {UserDetail(m, cashLabel)}
                                        {!isLast && <DividerSlit className={allSetting?.autoCutPrintReport ? 'page-break' : ''} />}
                                    </div>
                                );
                            })}
                        </TicketBox>
                    </Box>
                </div>
            </ContainerStyled>
        </Flex>
    );
};

export default EmployeeSalaryDateRangeReportPrintPage;

const Flex = styled.div`
 display: flex;
`;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 20.5rem;
  height: fit-content;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Header = styled(Row)`
  border-top:1px solid black;
  padding: 1px 0px;
  border-bottom: 1px dashed black;
`;

const ColHeader = styled(Col)`
    color:  black;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Roboto';
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const StyledRow = styled(Row)`
    font-family: 'Roboto';
`;

const BoxInfo = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;
const DividerSlit = styled.div`
margin: 1rem 0;
margin-bottom: 0;
`;