import NumberKeyBoard, { NumberKeyBoardRef } from 'components/NumberKeyBoard';
import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { PHONE_MASKED } from 'utils/mask';
interface ISignInFormProps { }
const SignInForm: React.FC<ISignInFormProps> = () => {
  const dispatch = useAppDispatch();
  const setLoading = useSetLoadingPage();
  const [text, setText] = useState('');
  const checkInData = checkInSelectors.getCheckedInData();
  const navigate = useNavigate();
  const { shop_id = '' } = useParams();
  const numberRef = useRef<NumberKeyBoardRef>(null);

  useEffect(() => {
    dispatch(multiplePaymentActions.customerSide.setCustomerMsg.fetch(null));
    dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(null));

    if (text && checkInData?.data) {
      navigate(`/store/${shop_id}/check-in/customer-info`);
    }
    if (text && !checkInData?.data) {
      dispatch(
        checkInActions.setInputValue({
          label: 'phone',
          value: text,
        })
      );
      navigate(`/store/${shop_id}/check-in/sign-up`);
    }
    return () => {
      setLoading(false);
    };
  }, [checkInData]);

  const handleSignIn = (val: string) => {
    PHONE_MASKED.resolve(val);
    const phone = PHONE_MASKED.unmaskedValue;
    if (phone.length === 10) {
      setLoading(true);
      dispatch(checkInActions.checkIn.fetch(phone));
    }
  };
  const _debounce = useCallback(debounce((inputValue: string) => handleSignIn(inputValue), 1000), []);

  useEffect(() => {
    if (text.length > 9) _debounce(text);
  }, [text]);

  return (
    <SignInFormStyled>
      <Text variant="H1">YOUR PHONE NUMBER</Text>
      <NumberKeyBoardStyled>
        <NumberKeyBoard
          ref={numberRef}
          inputClassName="custom-number-input-keyboard"
          value={text}
          onChange={setText}
          spacingKeyboard={2.5}
          inputViewProps={{
            trailing: {
              icon: 'QR',
              onClick: () => undefined,
            },
          }}
        ></NumberKeyBoard>
        <Text mb={1.5}></Text>
        {/* <Button onClick={() => handleSignIn(text)} ntype="PRIMARY" className="sign-in-btn">
          Sign In
        </Button> */}
      </NumberKeyBoardStyled>
    </SignInFormStyled>
  );
};

const SignInFormStyled = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    width: 45.5vw;
    height: 100vh;
    border-radius: 70px 0px 0px 70px;
    background: #FFF;
    box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.10) inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.25);

    .sign-in-btn {
        width: 100%
    }

    span.phone-number-input {
        width: 58.47%;
        height: 3.5rem;
        padding: 0;
    }
    span.phone-number-input .ant-input-suffix{
        width: 3.5rem;
    }

    span.phone-number-input .ant-input{
        margin-left: 28.2%
    }

    span.phone-number-input .ant-input::-webkit-input-placeholder { /* Edge */
        color: #1D2129;
        font-family: Open Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    span.phone-number-input .ant-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #1D2129;
        font-family: Open Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    span.phone-number-input .ant-input::placeholder {
        color: #1D2129;
        font-family: Open Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .custom-number-input-keyboard {
      font-size: 1.875rem;
      .input-number-pad {
        font-size: 1.875rem;
        text-align: center;
        color: var(--text-text-3, #1d2129);
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
      }
    }
`;

const NumberKeyBoardStyled = styled.div`
  max-width: 26rem;
  .number-pad-btn {
    width: 6rem;
    height: 6rem;
    .H2 {
      font-size: 2.5rem;
    }
  }
`;
export default SignInForm;
