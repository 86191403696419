import { get } from 'lodash';
import home from './icons/home.svg';
import home_active from './icons/home-active.svg';
import ticket from './icons/ticket.svg';
import ticket_active from './icons/ticket-active.svg';
import quick_view from './icons/quick-view.svg';
import quick_view_active from './icons/quick-view-active.svg';
import scan_qr from './icons/scan.svg';
import scan_qr_active from './icons/scan-active.svg';
import credit_card from './icons/credit-card.png';
import credit_card_active from './icons/credit-card-active.png';
import turn from './icons/turn.svg';
import turn_active from './icons/turn-active.svg';
import appointment from './icons/appointment.svg';
import appointment_active from './icons/appointment-active.svg';
import report from './icons/report.svg';
import report_active from './icons/report-active.svg';
import refresh from './icons/refresh.svg';
import refresh_active from './icons/refresh-active.svg';
import gift_card from './icons/gift-card.png';
import gift_card_active from './icons/gift-card-active.png';
import customer from './icons/customer.png';
import customer_active from './icons/customer-active.png';
import staff from './icons/staff.png';
import staff_active from './icons/staff-active.png';
import control_panel from './icons/control-panel.svg';
import control_panel_active from './icons/control-panel-active.svg';
import logout from './icons/logout.png';

const SIDE_BAR_CONFIG = {
  HOME: 'HOME',
  TICKET: 'TICKET',
  QUICK_VIEW: 'QUICK_VIEW',
  SCAN_QR: 'SCAN_QR',
  CREDIT_CARD: 'CREDIT_CARD',
  TURN: 'TURN',
  APPOINTMENT: 'APPOINTMENT',
  REPORT: 'REPORT',
  GIFT_CARD: 'GIFT_CARD',
  REFRESH: 'REFRESH',
};
export type SideBarConfigKeys = keyof typeof SIDE_BAR_CONFIG;
const sideBarConfigs = {
  [SIDE_BAR_CONFIG.HOME]: {
    label: 'Home',
    icon: home,
    iconActive: home_active,
  },
  [SIDE_BAR_CONFIG.TICKET]: {
    label: 'Tickets',
    icon: ticket,
    iconActive: ticket_active,
  },
  [SIDE_BAR_CONFIG.QUICK_VIEW]: {
    label: 'Quick View',
    icon: quick_view,
    iconActive: quick_view_active,
  },
  [SIDE_BAR_CONFIG.SCAN_QR]: {
    label: 'Scan',
    icon: scan_qr,
    iconActive: scan_qr_active,
  },
  [SIDE_BAR_CONFIG.CREDIT_CARD]: {
    label: 'Credit Cards',
    icon: credit_card,
    iconActive: credit_card_active,
  },
  [SIDE_BAR_CONFIG.TURN]: {
    label: 'Turn System',
    icon: turn,
    iconActive: turn_active,
  },
  [SIDE_BAR_CONFIG.APPOINTMENT]: {
    label: 'Appointment',
    icon: appointment,
    iconActive: appointment_active,
  },
  [SIDE_BAR_CONFIG.REPORT]: {
    label: 'Report',
    icon: report,
    iconActive: report_active,
  },
  [SIDE_BAR_CONFIG.GIFT_CARD]: {
    label: 'Gift Report',
    icon: gift_card,
    iconActive: gift_card_active,
  },
  [SIDE_BAR_CONFIG.REFRESH]: {
    label: 'Refresh',
    icon: refresh,
    iconActive: refresh_active,
  },
};
export const getSideBarConfig = (key: SideBarConfigKeys) => get(sideBarConfigs, SIDE_BAR_CONFIG[key]);

const SIDE_BAR_SETTING_CONFIG = {
  CUSTOMER: 'CUSTOMER',
  STAFF: 'STAFF',
  SETTING: 'SETTING',
  LOGOUT: 'LOGOUT',
};
const sideBarSettingConfigs = {
  [SIDE_BAR_SETTING_CONFIG.CUSTOMER]: {
    label: 'Customers',
    icon: customer,
    iconActive: customer_active,
  },
  [SIDE_BAR_SETTING_CONFIG.STAFF]: {
    label: 'Staffs',
    icon: staff,
    iconActive: staff_active,
  },
  [SIDE_BAR_SETTING_CONFIG.SETTING]: {
    label: 'Control Panel',
    icon: control_panel,
    iconActive: control_panel_active,
  },
  [SIDE_BAR_SETTING_CONFIG.LOGOUT]: {
    label: 'Log Out',
    icon: logout,
    iconActive: logout,
  },
};
export type SideBarSettingConfigKeys = keyof typeof SIDE_BAR_SETTING_CONFIG;
export const getSideBarSettingConfig = (key: SideBarSettingConfigKeys) => get(sideBarSettingConfigs, SIDE_BAR_SETTING_CONFIG[key]);
