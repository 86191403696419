import { DatePicker as AntDatePickerUI, Row } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import Text from 'components/Text';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT, DATE_FORMAT_VIEW } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import calendar_icon from '../../../icons/calendar.svg';
import WeeklyPicker from './WeeklyPicker';

const AntDatePicker = AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);
const getStartDate = (date: Moment | null | undefined, v2?: boolean) => {
    if (!v2) return date?.format(DATE_FORMAT_VIEW);
    const now = moment();
    if (date?.isSame(now, 'date')) {
        return `Today, ${date?.format('MM-DD-YYYY')}`;
    }
    if (date?.isSame(now.subtract(1, 'day'), 'date')) {
        return `Yesterday, ${date?.format('MM-DD-YYYY')}`;
    }
    return date?.format('ddd, MM-DD-YYYY');
};
type IDatePickerProps = {
    size?: SizeType,
    onChangeValue?: (data: {
        startTime: string,
        endTime: string,
    }) => void;
    v2?: boolean;
    viewType?: CALENDAR_VIEW_TYPE;
    value?: { startTime: string, endTime?: string; }
};
const DatePicker: React.FC<IDatePickerProps> = ({
    viewType,
    size = 'small',
    onChangeValue = () => undefined,
    v2,
    value,
}) => {
    const startDate = useMemo(() => {
        return value?.startTime ? moment(value?.startTime, DATE_FORMAT) : null;
    }, [value]);

    const endDate = useMemo(() => {
        return value?.endTime ? moment(value?.endTime, DATE_FORMAT) : null;
    }, [value]);


    if (viewType === CALENDAR_VIEW_TYPE.DAY_VIEW) {
        const onChange = (date: any) => {
            if (!date) return;

            onChangeValue({
                startTime: date?.format(DATE_FORMAT),
                endTime: date?.format(DATE_FORMAT),
            });
        };

        return (
            <DatePickerStyled
                onChange={onChange}
                size={size}
                inputReadOnly
                clearIcon={false}
                value={startDate}
                suffixIcon={v2 ? false : undefined}
                className={v2 ? 'v2' : ''}
                popupClassName={v2 ? 'zoom-big-v2' : ''}
                inputRender={(props: any) => (
                    <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
                        {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
                        <Text variant='CONTENT_2' color='text_3' >{getStartDate(startDate, v2)}</Text>
                        {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
                    </InputUI>
                )}
            />
        );
    }

    if (viewType === CALENDAR_VIEW_TYPE.TWO_WEEKS || viewType === CALENDAR_VIEW_TYPE.WEEK_VIEW) {

        const onChange = (values: RangeValue<Moment>) => {
            if (!values) return;
            onChangeValue({
                startTime: values?.[0]?.format(DATE_FORMAT) || '',
                endTime: values?.[1]?.format(DATE_FORMAT) || '',
            });
        };

        return (
            <WeeklyPicker v2={v2} key={viewType} onSubmit={onChange} defaultValue={[startDate, endDate]} />
        );
    }

    if (viewType === CALENDAR_VIEW_TYPE.MONTH_VIEW) {
        const onChange = (date: any) => {
            if (!date) return;
            onChangeValue({
                startTime: date?.startOf('month').format(DATE_FORMAT) || '',
                endTime: date?.endOf('month').format(DATE_FORMAT) || '',
            });
        };
        return (
            <DatePickerStyled
                clearIcon={false}
                inputReadOnly
                value={startDate}
                onChange={onChange}
                picker="month"
                suffixIcon={v2 ? false : undefined}
                className={v2 ? 'v2' : ''}
                popupClassName={v2 ? 'zoom-big-v2' : ''}
                inputRender={(props: any) => (
                    <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
                        {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
                        <Text variant='CONTENT_2' color='text_3' >{startDate?.format(v2 ? 'MMMM, YYYY' : 'MM/YYYY')}</Text>
                        {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
                    </InputUI>
                )}
                size={v2 ? 'small' : size}
            />
        );
    }

    return null;
};

export default DatePicker;
type DatePickerStyledProps = {}
const DatePickerStyled = styled(AntDatePicker) <DatePickerStyledProps>`
  min-width: 20rem;

  &.v2 {
    min-width: unset;
    border: none;
    background: transparent;
    width: auto;
    .InputUI {
        gap: 16px;
        flex-wrap: nowrap;
        .CONTENT_2 {
            color: #1D2129;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            white-space: nowrap;
        }
    }
  }
`;

const InputUI = styled(Row)`
flex: 1;
padding: 0.5rem;
margin-right: 0.5rem;
-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
