import { Col, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailOneDay } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../components/PrintHeader';
import { ReportType } from '../constants';
import EmployeeTickets from 'features/report/components/EmployeeTickets';
import shopSelectors from 'services/shop/selectors';
interface IEmployeeSalaryReportPrintPage { }

const EmployeeSalaryReportPrintPage: React.FC<IEmployeeSalaryReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const dataPrintSalaryStaffOnDay = reportSelectors.getPrintSalarySummaryStaffOneDay();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.EMPLOYEE_SALARY];
    const filterTypeSalaryStaff = reportSelectors.getFilterTypeSalaryStaff();

    const allSetting = shopSelectors.data.allSetting();
    const cashLabel = allSetting?.labelSalaryCashOrBonus || 'Cash';

    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalarySummaryStaffOneDay.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            salaryType: filterTypeSalaryStaff
        }));
    }, [dataAppointment, filterTypeSalaryStaff]);

    const UserDetail = (data: IPrintSalaryStaffDetailOneDay) => {
        return (
            <Box mt='1' px='3'>
                <Box className="center" highlight>
                    <Text printMode variant="CAPTION_2" className='title-highlight'>
                        {data?.staffName || '----'}
                    </Text>
                </Box>
                <Box>
                    <EmployeeTickets data={data.details} />
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">TOTAL SALES</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalSales)}</Text></ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">SUPPLY FEE</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.supplyFee)}</Text></ColHeader>
                    </Discount>
                    <Box display='flex' justifyContent='space-between' highlight alignItems='center'>
                        <Text printMode variant='CAPTION_2' className='title-highlight' color="text_3">TOTAL</Text>
                        <Text printMode variant='CAPTION_3' className='title-highlight' color="text_3">{formatCurrency((data?.totalSales || 0) - (data?.supplyFee || 0))}</Text>
                    </Box>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">COMMISSION</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalCommission)}</Text></ColHeader>
                    </Discount>
                    {!!data?.staffDiscount && <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' color="text_3">DISCOUNT</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.staffDiscount)}</Text></ColHeader>
                    </Discount>}
                    <>
                        {!!data?.tipCollected && <Discount>
                            <ColHeader flex={1}><Text printMode variant='CAPTION_2' color="text_3">COLLECTED TIPS</Text></ColHeader>
                            <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.tipCollected)}</Text></ColHeader>
                        </Discount>}
                        {!!data?.tipFee && <>
                            <Discount>
                                <ColHeader flex={1}><Text printMode variant='CAPTION_2' color="text_3">TIP FEE</Text></ColHeader>
                                <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">
                                    {formatCurrency(data?.tipFee)} {(data?.tipFeeType === 'PERCENT' && `(${data?.tipFeeValue || 0}%)`)}
                                </Text></ColHeader>
                            </Discount>
                            <Discount>
                                <ColHeader flex={1}><Text printMode variant='CAPTION_2' color="text_3">TOTAL TIP</Text></ColHeader>
                                <ColHeader span={8} style={{ textAlign: 'right' }}><Text printMode variant='CAPTION_3' color="text_3">{formatCurrency(data?.totalTip)}</Text></ColHeader>
                            </Discount>
                        </>}
                    </>
                    <Box display='flex' justifyContent='space-between' highlight alignItems='center'>
                        <Text printMode variant='CAPTION_2' className='title-highlight' color="text_3">PAYROLL</Text>
                        <Text printMode variant='CAPTION_3' className='title-highlight' color="text_3">{formatCurrency(data?.totalSalary)}</Text>
                    </Box>
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                            {cashLabel} {data?.cashPercent}%
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.totalPayCash)}
                        </Text>
                    </BoxInfo>
                    <BoxInfo>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            Check {data?.checkPercent}%
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.totalPayCheck)}
                        </Text>
                    </BoxInfo>
                    {!!data?.tipToCash &&
                        <BoxInfo>
                            <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                                Tip to {cashLabel} {data?.tipCashPercent ? ` (${data?.tipCashPercent}%)` : ''}
                            </Text>
                            <Text printMode variant='CAPTION_3' color="text_3" >
                                {formatCurrency(data?.tipToCash)}
                            </Text>
                        </BoxInfo>}
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                            Tip to Check {data?.tipCheckPercent ? ` (${data?.tipCheckPercent}%)` : ''}
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.tipToCheck)}
                        </Text>
                    </BoxInfo>
                    <Box bt='print_line'>
                        <BoxInfo>
                            <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                                Total {cashLabel}
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3" >
                                {formatCurrency(data?.totalCash)}
                            </Text>
                        </BoxInfo>
                        <BoxInfo>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                Total Check
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                {formatCurrency(data?.totalCheck)}
                            </Text>
                        </BoxInfo>
                    </Box>
                </Box>
            </Box>
        );
    };
    const divRef = useRef<HTMLDivElement>(null);

    const onBack = () => {
        navigate(-1);
    };
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });

    return (
        <Flex>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <Box className="center" overflow="auto" >
                        <TicketBox>
                            <Box px='3' py='1'>
                                <LogoBox>
                                    <IconLogo />
                                </LogoBox>
                                <Box className="center" pt='1'>
                                    <Text printMode variant="CONTENT_2" color="text_2">
                                        {moment(dataPrintSalaryStaffOnDay?.createdDate).format('MM/DD/YYYY')}
                                    </Text>
                                </Box>
                            </Box>
                            <Box px='3'>
                                <Box className="space-between" highlight>
                                    <Text printMode variant="CAPTION_2" color="text_3" className='title-highlight'>
                                        Employee Salaries
                                    </Text>
                                    <Text printMode variant="CAPTION_2" color="text_3" className='text-highlight'>
                                        {formatCurrency(dataPrintSalaryStaffOnDay?.totalSalary)}
                                    </Text>
                                </Box>
                                <Text mb={0.25} />
                                <Box className="space-between" >
                                    <Text textTransform='capitalize' printMode variant="CAPTION_3" color="text_3">
                                        Total {cashLabel}
                                    </Text>
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        {formatCurrency(dataPrintSalaryStaffOnDay?.totalPayCash)}
                                    </Text>
                                </Box>
                                <Box className="space-between">
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        Total Check
                                    </Text>
                                    <Text printMode variant="CAPTION_3" color="text_3">
                                        {formatCurrency(dataPrintSalaryStaffOnDay?.totalPayCheck)}
                                    </Text>
                                </Box>
                                <Text mb={0.5} />
                            </Box>
                            {dataPrintSalaryStaffOnDay?.reportStaff?.map((m, i) => {
                                const isLast = (dataPrintSalaryStaffOnDay?.reportStaff.length - 1) === i;
                                return (
                                    <div key={i}>
                                        {UserDetail(m)}
                                        {!isLast && <DividerSlit />}
                                    </div>
                                );
                            })}
                        </TicketBox>
                    </Box>
                </div>
            </ContainerStyled>
        </Flex>
    );
};

export default EmployeeSalaryReportPrintPage;

const Flex = styled.div`
 display: flex;
`;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 20.5rem;
  height: fit-content;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Header = styled(Row)`
  border-top:1px solid black;
  padding: 2.5px 0px;
  border-bottom: 1px dashed black;
  .th {
    color: #767676 !important;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ColHeader = styled(Col)`
    font-family: 'Roboto';
    color:  black;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const DividerSlit = styled.div`
border-bottom: 1px dashed black;
margin: 1rem 0;
`;
const BoxInfo = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;