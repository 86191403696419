import { MultipleCardTypePassData, TypePaymentActors } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import { useSocketContext } from 'hooks/useSocket';
import { useAppDispatch } from 'store/hooks';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import multiCardActions from '../services/actions';
import multiCardApis from '../services/apis';
import { uiLoadingBasicRef } from 'services/UI/LoadingPage/UILoadingRef';

const useUpdateTip = ({ actor }: { actor: TypePaymentActors }) => {
  const socketContext = useSocketContext();
  const dispatch = useAppDispatch();
  const onTip = async (billId: string, tip: number) => {
    try {
      await multiCardApis.updatePaymentTip({ billId, tip: tip || 0 });
      socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
        billId: billId || '',
        actor,
        action: MultipleCardTypePassData.REFRESH_DETAIL,
      });
      dispatch(multiCardActions.refreshDetail.fetch(billId));
    } catch (error) { }
    finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };
  return (billId: string, tip: number) => {
    socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
      billId: billId || '',
      actor,
      action: MultipleCardTypePassData.ON_TIP,
    });
    uiLoadingBasicRef.current?.setVisible(true);
    setTimeout(() => onTip(billId, tip), 1000);
  };
};
export default useUpdateTip;
