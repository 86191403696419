import { Avatar, Form } from 'antd';
import Text from 'components/Text';
import turnSelectors from 'features/turn/services/selectors';
import shopSelectors from 'services/shop/selectors';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import styled from 'styled-components';
import ModalChooseStaffRef, { useModalChooseStaffRef } from 'widgets/Staff/ModalChooseStaffRef';
import emptyTeamImg from './empty.gif';
import removeIcon from './icons/delete.svg';
type Props = {
  onClose: () => void;
};
const TurnManagementTeam = ({ onClose }: Props) => {
  const addRef = useModalChooseStaffRef();
  const staffStores = shopSelectors.data.staffs();
  const staffAvailable = turnSelectors.getTurnStaffsAvailable();

  return (
    <>
      <Wrapper>
        <Form.List name={'staffs'}>
          {(fields, { add, remove }) => (
            <>
              <div className="header-actions">
                <div className="header-title">List of staff not eligible for turn counting</div>
                {fields.length > 0 && <button type='button' className="btn-add-staff" onClick={() => addRef.current?.open(staff => {
                  const item = staffAvailable.find(o => o.staffId === staff.id);
                  if (!item) return;
                  add(item);
                })}>
                  <IconAdd />
                  <span>add staff to list</span>
                </button>}
              </div>
              <ContentContainer>
                {fields.length ?
                  <div className="scroll-div">
                    <Container>
                      {fields.map((o) => <Form.Item noStyle name={o.name} key={o.key}>
                        <StaffItem onRemove={() => remove(o.name)} />
                      </Form.Item>)}
                    </Container>
                  </div>
                  : <EmptyContainer>
                    <img src={emptyTeamImg} alt="emptyTeamImg" className='banner' />
                    <button type='button' className="btn-add-staff" onClick={() => addRef.current?.open(staff => {
                      const item = staffAvailable.find(o => o.staffId === staff.id);
                      if (!item) return;
                      add(item);
                    })}>
                      <IconAdd />
                      <span>add staff to list</span>
                    </button>
                  </EmptyContainer>}
              </ContentContainer>
            </>
          )}
        </Form.List>
        <Footer>
          <button onClick={onClose} type='button' className="btn btn-close"><span>Close</span></button>
          <button type='submit' className="btn btn-submit"><span>Update</span></button>
        </Footer>
      </Wrapper>
      <Form.Item shouldUpdate noStyle>
        {({ getFieldValue }) => {
          const _staffs = (getFieldValue('staffs') as IStaffAvailableItem[]).map(o => o.staffId);
          return (
            <ModalChooseStaffRef ref={addRef} staffs={staffStores.filter(o => !_staffs.includes(o.id))} />
          );
        }}
      </Form.Item>
    </>
  );
};

export default TurnManagementTeam;

const ContentContainer = styled.div`
  flex: 1;
  position: relative;
`;
const EmptyContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
flex-direction: column;
padding: 24px;
gap: 16px;
.banner {
  height: 240px;
  width: 240px;
}
button.btn-add-staff {
  border: none;
}
`;
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  .header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    padding: 0px 24px;
    .header-title {
      color: #64748B;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .btn-add-staff {
    display: flex;
    height: 48px;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 5px;
    border: 1px solid #C52884;
    background: #FFF;
    span {
      color: #C52884;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: uppercase;
    }
  }
`;

const Container = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  row-gap: 0px;
  padding: 16px 24px;
`;

const Footer = styled.div`
display: flex;
padding: 24px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.25);
button.btn {
  display: flex;
  height: 56px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #86909C;
  background: #E5E6EB;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: uppercase;
  }

  &.btn-delete {
    border: 1px solid #F53F3F;
    background: #FFF;
    span {
      color: #F53F3F;
    }
  }
  &.btn-add-turn {
    border: 1px solid #2D6772;
    background: #FFF;
    span {
      color: #2D6772;
    }
  }
  &.btn-submit {
    border-radius: 5px;
    background: #C52884;
    border: 1px solid #C52884;
    span {
      color: #fff;
    }
  }
}
`;

const StaffItem = ({ value, onRemove }: { value?: IStaffAvailableItem, onRemove?: () => void; }) => {
  return (
    <StaffItemStyled>
      <Avatar size={90} src={value?.staffAvatar} >{value?.staffName?.[0] || 'A'}</Avatar>
      <Text variant='CONTENT_2'>{value?.staffName || '--'}</Text>
      <button type='button' className="btn btn-remove" onClick={onRemove}>
        <img src={removeIcon} alt="removeIcon" className='icon' />
      </button>
    </StaffItemStyled>
  );
};

const StaffItemStyled = styled.div`
display: flex;
padding: 16px 16px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
border: 1px solid #DDD;
background: #FFF;
position: relative;
padding-top: 20px;
.CONTENT_2 {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
}
.btn-remove {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  .icon {
    height: 35px;
    width: 35px;
  }
}
`;
const IconAdd = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 4.5C12.4142 4.5 12.75 4.83579 12.75 5.25V18.75C12.75 19.1642 12.4142 19.5 12 19.5C11.5858 19.5 11.25 19.1642 11.25 18.75V5.25C11.25 4.83579 11.5858 4.5 12 4.5Z" fill="#C52884" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.75 5.25C10.75 4.55964 11.3096 4 12 4C12.6904 4 13.25 4.55964 13.25 5.25V18.75C13.25 19.4404 12.6904 20 12 20C11.3096 20 10.75 19.4404 10.75 18.75V5.25ZM12 5C11.8619 5 11.75 5.11193 11.75 5.25V18.75C11.75 18.8881 11.8619 19 12 19C12.1381 19 12.25 18.8881 12.25 18.75V5.25C12.25 5.11193 12.1381 5 12 5Z" fill="#C52884" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.5 12C4.5 11.5858 4.83579 11.25 5.25 11.25H18.75C19.1642 11.25 19.5 11.5858 19.5 12C19.5 12.4142 19.1642 12.75 18.75 12.75H5.25C4.83579 12.75 4.5 12.4142 4.5 12Z" fill="#C52884" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4 12C4 11.3096 4.55964 10.75 5.25 10.75H18.75C19.4404 10.75 20 11.3096 20 12C20 12.6904 19.4404 13.25 18.75 13.25H5.25C4.55964 13.25 4 12.6904 4 12ZM5.25 11.75C5.11193 11.75 5 11.8619 5 12C5 12.1381 5.11193 12.25 5.25 12.25H18.75C18.8881 12.25 19 12.1381 19 12C19 11.8619 18.8881 11.75 18.75 11.75H5.25Z" fill="#C52884" />
  </svg>
);
