import React from 'react';

const DeleteService = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.6688 2.62631C10.6694 1.93069 11.3 1.32006 11.9938 1.33444C14.6819 1.33381 17.37 1.33069 20.0575 1.33631C20.7344 1.34694 21.3281 1.94944 21.3306 2.62631C21.3375 3.52881 21.33 4.43131 21.3344 5.33381C23.1 5.33319 24.8656 5.33381 26.6306 5.33319C27.0775 5.32006 27.5213 5.54569 27.7694 5.91819C28.055 6.32944 28.075 6.90444 27.8194 7.33506C27.5825 7.75319 27.1069 8.01506 26.6269 8.00006C19.5425 8.00006 12.4575 8.00006 5.37313 8.00006C4.98001 8.01069 4.58751 7.84194 4.33063 7.54381C4.01126 7.18694 3.91313 6.65194 4.08313 6.20506C4.22876 5.80131 4.58251 5.48131 4.99876 5.37631C5.20251 5.32069 5.41563 5.33444 5.62501 5.33319C7.30501 5.33381 8.98563 5.33319 10.6656 5.33381C10.6694 4.43131 10.6625 3.52881 10.6688 2.62631ZM13.3331 4.00069C13.3356 4.44569 13.3294 4.89006 13.3363 5.33444C15.1131 5.33194 16.8894 5.33381 18.6656 5.33381C18.6681 4.88944 18.6656 4.44506 18.6669 4.00131C16.8888 3.99944 15.1113 3.99944 13.3331 4.00069Z" fill="#F53F3F" />
      <path d="M6.2106 9.41324C6.8406 9.16824 7.61498 9.49511 7.8806 10.1157C8.01373 10.3901 8.0031 10.7007 7.99998 10.9982C7.99998 15.8332 7.99998 20.6689 7.99998 25.5039C8.00873 26.0957 8.23748 26.6807 8.62873 27.1245C9.09373 27.6595 9.78873 27.9914 10.5 27.9995C14.1456 28.0007 17.7912 27.9995 21.4369 28.0007C22.1094 28.007 22.7781 27.7301 23.2519 27.2532C23.7131 26.7945 23.9906 26.1545 23.9994 25.5032C24.0006 20.5639 23.9994 15.6239 24.0006 10.6845C23.9912 10.2364 24.2256 9.79386 24.6031 9.55199C24.9719 9.30449 25.4669 9.26511 25.8712 9.44699C26.3 9.63136 26.6119 10.0576 26.6575 10.522C26.6725 10.702 26.6656 10.8826 26.6669 11.0632C26.6669 15.7714 26.6662 20.4795 26.6669 25.1882C26.7475 26.8345 26.0081 28.4964 24.7119 29.5182C23.8106 30.2482 22.6581 30.6595 21.4981 30.6664C17.8312 30.667 14.1644 30.667 10.4969 30.6664C9.22185 30.6595 7.9606 30.1564 7.02435 29.292C5.96998 28.3339 5.33748 26.9289 5.33373 25.5026C5.3331 20.5632 5.3331 15.6232 5.33373 10.6832C5.3181 10.1295 5.68935 9.59761 6.2106 9.41324Z" fill="#F53F3F" />
      <path d="M13.0256 12.0364C13.4431 11.9352 13.9056 12.0514 14.2231 12.342C14.5131 12.5977 14.6756 12.9839 14.6662 13.3702C14.6668 16.457 14.6668 19.5439 14.6668 22.6308C14.68 23.0933 14.4362 23.5527 14.0406 23.7952C13.5987 24.0808 12.9868 24.0639 12.5618 23.7533C12.2018 23.5027 11.9868 23.0664 12 22.6289C12.0012 19.522 11.9975 16.4152 12.0018 13.3089C12 12.7158 12.4481 12.1639 13.0256 12.0364Z" fill="#F53F3F" />
      <path d="M18.3425 12.0402C18.7588 11.9339 19.2238 12.0445 19.5444 12.3308C19.8425 12.587 20.0094 12.9795 20 13.3708C20 16.4545 20 19.5383 20 22.622C20.0132 23.047 19.8144 23.472 19.4732 23.727C19.1325 23.9908 18.6607 24.0695 18.2525 23.9333C17.8269 23.797 17.4832 23.4327 17.3763 22.9989C17.3207 22.7952 17.3344 22.582 17.3332 22.3733C17.335 19.352 17.3313 16.3314 17.335 13.3102C17.3332 12.7227 17.7719 12.1739 18.3425 12.0402Z" fill="#F53F3F" />
    </svg>
  );
};

export default DeleteService;
