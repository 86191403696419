import Button from 'components/Button';
import Text from 'components/Text';
import { ICategoryItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import ArrangeCateModal from '../../ArrangeCateModal';
import AddCateButton from './AddCateButton';
import ConfigCategories from './ConfigCategories';
import { IEditCateBodyType } from 'features/settingService/services/types/api';
type Props = {
  data: ICategoryItemData[];
  activeCate: ICategoryItemData | null;
  onChooseCate: (activeCate: ICategoryItemData) => void;
  onAddCategory: (body: IEditCateBodyType) => void;
};
const CategoryPart = ({ activeCate, data = [], onChooseCate, onAddCategory }: Props) => {
  return (
    <Container>
      <div className="title-div">
        <ConfigCategories />
        <ArrangeCateModal block height={3} icon='sortService' title='Pre-Order' />
      </div>
      <CategoriesListStyled>
        <AddCateButton onAddCategory={onAddCategory} />
        <CategoriesListOverflow>
          <div className="content-scroll">
            {data.map(o => (
              <Button
                key={o.id}
                ntype={activeCate?.id === o?.id ? 'LIGHT_PRIMARY' : 'GREY'} className='category-item'
                onClick={() => onChooseCate(o)}
              >
                <div className='category-item-wrap'>
                  <Text className='cate-name'>{o.categoryName || ''}</Text>
                  <Text className='cate-services'>{o.services.length || 0} items</Text>
                </div>
              </Button>
            ))}
          </div>
        </CategoriesListOverflow>
      </CategoriesListStyled>
    </Container>
  );
};

export default CategoryPart;

const CategoriesListOverflow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  position: relative;
  z-index: 1;
  .content-scroll {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
  }
`;

const CategoriesListStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex: 1;
  .category-item {
    box-shadow: none;
    
    .CONTENT_2 {
      flex: 1;
    }
    .category-item-wrap {
      display: flex;
      flex: 1;
      align-items: center;
      .cate-name {
        flex: 1;
        text-align: left;
        color: #1D2129;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 166.667% */
        text-transform: uppercase;
      }

      .cate-services {
        color: #767676;
        font-family: "Open Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
`;

const Container = styled.div`
  width: 21rem;
  display: flex;
  flex-direction: column;
  .title-div {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    gap: 16px;
    .title-text {
      flex: 1;
      color: #1D2129;
      font-family: "Open Sans";
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
`;