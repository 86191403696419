import { Input, InputRef } from 'antd';
import Box from 'components/Box';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { useCurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import React, { useEffect, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const UpdateQuickPay = ({ v2 }: { v2?: boolean }) => {
  const inputRef = useCurrencyKeyBoardRef();
  const dollarRef = useDollarAmountInputRef();
  const inputNameRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const allSetting = shopSelectors.data.allSetting();
  const draffQuickPay = cashierSelectors.getDraftQuickPay();
  const [amount, setAmount] = useState<number | null>();
  const [serviceName, setServiceName] = useState<string>('');
  const handleClose = () => dispatch(cashierActions.setDraftQuickPay(null));

  useEffect(() => {
    if (!draffQuickPay) return;
    setServiceName(draffQuickPay.itemName);
    setTimeout(() => {
      if (draffQuickPay.typeDraft === 'PRICE') {
        inputRef.current?.setValue(0?.toFixed(2));
        dollarRef.current?.init('');
      }
      else {
        inputNameRef.current?.focus();
        inputRef.current?.setValue(draffQuickPay.value?.toFixed(2), true);
        dollarRef.current?.init(draffQuickPay.value?.toString(), true);
      }
    }, 100);
  }, [draffQuickPay]);

  const handleSubmit = () => {
    if (
      !draffQuickPay ||
      (amount === draffQuickPay?.value && draffQuickPay?.itemName === serviceName)) {
      handleClose();
      return;
    }

    dispatch(cashierActions.updateQuickPayItem({
      staffId: draffQuickPay.staffId || '',
      itemId: draffQuickPay.itemId || '',
      value: amount || 0,
      itemName: serviceName || draffQuickPay.itemName || '',
      typeDraft: draffQuickPay.typeDraft,
    }));
  };
  if (v2) {
    return (
      <Modal
        v2
        visible={!!draffQuickPay}
        onClose={handleClose}
        modalTitle={draffQuickPay?.itemName || 'UPDATE SERVICE'}
        okTitle="Done"
        noneBodyStyle
        noneFooterStyle
        width={'1000px'}
        onSubmit={handleSubmit}
        disableOk={!amount}
      >
        <ContainerV2>
          <ServiceName className='ServiceName'>
            <Text className='modal-label' mb={0.5}>
              Service Name:
            </Text>
            <Input ref={inputNameRef} className='service-name-input' size='small' value={serviceName} onChange={e => setServiceName(e.target.value)} />
          </ServiceName>
          {
            allSetting?.activeFormatMoney ? <Box display='flex' flexDirection='column' gap='2'>
              <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
                Enter Amount:
              </Text>
              <InputWithKeyboardStyled
                ref={dollarRef}
                value={amount}
                onChange={setAmount}
                inputMode='none'
                autoFocus
              />
              <DollarAmountKeyboard
                controlRef={dollarRef}
                value={amount}
                onChange={setAmount}
              />
            </Box> : <>
              <CurrencyKeyBoard v2 smallInput ref={inputRef} onChange={setAmount} value={amount} />
            </>
          }
        </ContainerV2>
      </Modal>
    );
  }
  return (
    <Modal
      visible={!!draffQuickPay}
      onClose={handleClose}
      modalTitle={draffQuickPay?.itemName || 'UPDATE SERVICE'}
      okTitle="Done"
      onSubmit={handleSubmit}
      disableOk={!amount}
    >
      <ServiceName>
        <Text style={{ fontSize: '1.2rem' }} mb={0.5}>
          Service Name:
        </Text>
        <Input ref={inputNameRef} size='small' value={serviceName} onChange={e => setServiceName(e.target.value)} />
      </ServiceName>
      <CurrencyKeyBoard smallInput ref={inputRef} onChange={setAmount} value={amount} />
    </Modal>
  );
};

export default UpdateQuickPay;

const ContainerV2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding-top: 16px;
`;
const ServiceName = styled.div`
  margin-bottom: 1rem;
  .modal-label {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-input.service-name-input {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
    border: none;
    outline: none;
    background-color: transparent;
    box-shadow: none;
  }
`;