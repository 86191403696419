import { Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Button from 'components/Button';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Table from 'components/Table';
import Text from 'components/Text';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import { TableGreenEvenStyled } from 'features/report/components/Component';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { Bill, StaffItem } from 'features/ticketList/services/types/api';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { momentTimezone } from 'utils/time';
import ButtonEmail from 'widgets/Receipt/ButtonEmail';
import ButtonSMS from 'widgets/Receipt/ButtonSMS';
type ITicketTableProps = {
  isCreditPage?: boolean;
  v2?: boolean;
};

const TicketTable: React.FC<ITicketTableProps> = ({ isCreditPage, v2 }) => {
  const dispatch = useAppDispatch();
  const visibleCusPhone = ticketListSelectors.getVisibleCusPhone();
  const modalConfirm = useModalConfirmRef();
  const ticketContext = useTicketWrapper();

  const totalElement = ticketListSelectors.ticketsTableData.totalSize();
  const params = ticketListSelectors.ticketsTableData.params();
  const data = ticketListSelectors.ticketsTableData.data();
  const loading = ticketListSelectors.ticketsTableData.loading();
  const columns: ColumnsType<Bill> = useMemo(
    () => [
      {
        title: 'Tickets',
        dataIndex: 'ticketNumber',
        width: '10rem',
        align: 'center',
        render(text) {
          return (
            <Text variant="CONTENT_2" color="text_3">
              {text ? `#${text}` : '--'}
            </Text>
          );
        },
      },
      {
        title: 'Date',
        dataIndex: 'startTime',
        align: 'left',
        width: '8rem',
        render(startTime: string) {
          return (
            <Text variant="CONTENT_2" color="text_3" style={{ whiteSpace: 'nowrap' }}>
              {startTime
                ? momentTimezone(startTime).format('MM-DD-YYYY')
                : '--'}
            </Text>
          );
        },
      },
      {
        title: 'Time',
        dataIndex: 'timePaymentCompleted',
        width: '8rem',
        align: 'left',
        render(timePaymentCompleted) {
          return (
            <Text variant="CONTENT_2" color="text_3" style={{ whiteSpace: 'nowrap' }}>
              {timePaymentCompleted ? `${timePaymentCompleted}` : '--'}
            </Text>
          );
        },
      },
      {
        title: 'Staffs',
        dataIndex: 'items',
        align: 'center',
        width: '15rem',
        render(items: StaffItem[]) {
          const allStaffNames: string[] = items?.map((item) => item.staffName);
          const concatenatedStaffNames: string = allStaffNames.join(', ');
          return (
            <div>
              <Text variant="CONTENT_2" color="text_3" textAlign="left">
                {concatenatedStaffNames || '--'}
              </Text>
            </div>
          );
        },
      },
      {
        title: 'Services',
        dataIndex: 'items',
        align: 'left',
        render(items: StaffItem[]) {
          const allItemNames: string[] = items?.flatMap((item) =>
            item.services?.map((service) => service.itemName)
          );
          const concatenatedString: string = allItemNames.join(', ');
          return (
            <div>
              <Text variant="CONTENT_2" color="text_3" textAlign="left" >
                {concatenatedString || '--'}
              </Text>
            </div>
          );
        },
      },

      {
        title: 'Total',
        dataIndex: 'total',
        align: 'center',
        width: '10rem',
        render(total: number) {
          return (
            <Text variant="CONTENT_2" color="info_4">
              {formatCurrency(total)}
            </Text>
          );
        },
      },
      {
        title: '',
        dataIndex: 'actions',
        align: 'center',
        width: '25rem',
        render(_, record) {
          return (
            <Row justify={'center'} wrap={false} style={{ gap: '8px' }}>
              <Button cardCashier={v2} size='small' ntype='DEFAULT' icon='print' onClick={() => ticketContext.printWithoutCoupon(record.billId)}>REPRINT</Button>
              <ButtonEmail v2={v2} small billId={record?.billId} />
              <ButtonSMS v2={v2} small billId={record?.billId} defaultSMSPhone={record?.customerPhone} />
            </Row>
          );
        },
      },
    ],
    [visibleCusPhone]
  );

  const handleChangePage = (page: number) => {
    dispatch(
      ticketListActions.setTicketsParams({
        page: page,
      })
    );
  };

  const rowClassName = (record: Bill) => isCreditPage && record?.billStatus;

  return (
    <TicketTableStyled className={v2 ? 'v2' : ''}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={'billId'}
        rowClassName={rowClassName}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
          showSizeChanger: false,
        }}
        handleChangePage={handleChangePage}
      />
      <ModalConfirm ref={modalConfirm} />
    </TicketTableStyled>
  );
};

export default TicketTable;
const TicketTableStyled = styled(TableGreenEvenStyled)`
 .ant-table-cell {
    padding: 14px 0.5rem !important;
  }


&.v2 {
  .ant-table-wrapper .ant-table .ant-table-content{
    .ant-table-thead th.ant-table-cell, .ant-table-thead td.ant-table-cell {
      height: 48px;
      padding: 0px 8px;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #CCD4DC;
      background: #6FABB6;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: left !important;
      border-right: 1.5px solid #CCD4DC;
      &::before {
        width: 1.5px !important;
        height: 100%  !important;
      }
    }

    .ant-table-tbody td.ant-table-cell {
      .BODY_1, .CONTENT_2 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
      background: #E4F4F6;
      border-bottom: 1px solid #E4F4F6;
    }
  }
}
`;
