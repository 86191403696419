import React from 'react';

const DeleteCategory = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="32" height="32" rx="5" fill="#F53F3F" />
      <path d="M11.6684 5.63382C11.6689 5.06862 12.1813 4.57249 12.7449 4.58417C14.929 4.58366 17.1131 4.58112 19.2967 4.58569C19.8467 4.59432 20.3291 5.08386 20.3312 5.63382C20.3367 6.3671 20.3306 7.10038 20.3342 7.83366C21.7688 7.83315 23.2033 7.83366 24.6374 7.83315C25.0005 7.82249 25.361 8.00581 25.5626 8.30847C25.7947 8.64261 25.811 9.10979 25.6033 9.45968C25.4108 9.7994 25.0244 10.0122 24.6344 9.99999C18.8783 9.99999 13.1217 9.99999 7.36568 9.99999C7.04627 10.0086 6.72736 9.87151 6.51865 9.62929C6.25916 9.33932 6.17943 8.90464 6.31756 8.54155C6.43588 8.2135 6.7233 7.9535 7.0615 7.86819C7.22705 7.823 7.40022 7.83417 7.57033 7.83315C8.93533 7.83366 10.3008 7.83315 11.6658 7.83366C11.6689 7.10038 11.6633 6.3671 11.6684 5.63382ZM13.8332 6.7505C13.8352 7.11206 13.8301 7.47311 13.8357 7.83417C15.2794 7.83214 16.7226 7.83366 18.1658 7.83366C18.1679 7.47261 18.1658 7.11155 18.1669 6.751C16.7221 6.74948 15.2779 6.74948 13.8332 6.7505Z" fill="white" />
      <path d="M8.04616 11.1479C8.55804 10.9489 9.18721 11.2144 9.40304 11.7187C9.5112 11.9416 9.50257 12.194 9.50003 12.4357C9.50003 16.3642 9.50003 20.2931 9.50003 24.2216C9.50714 24.7025 9.693 25.1778 10.0109 25.5383C10.3887 25.973 10.9534 26.2426 11.5313 26.2492C14.4933 26.2503 17.4554 26.2492 20.4175 26.2503C20.9639 26.2553 21.5073 26.0304 21.8922 25.6429C22.2669 25.2702 22.4924 24.7502 22.4995 24.221C22.5005 20.2078 22.4995 16.1941 22.5005 12.1808C22.4929 11.8167 22.6833 11.4572 22.9901 11.2607C23.2897 11.0596 23.6919 11.0276 24.0204 11.1753C24.3688 11.3251 24.6222 11.6715 24.6592 12.0488C24.6714 12.195 24.6658 12.3418 24.6669 12.4885C24.6669 16.3139 24.6664 20.1392 24.6669 23.9651C24.7324 25.3027 24.1316 26.653 23.0784 27.4832C22.3462 28.0764 21.4098 28.4105 20.4673 28.4161C17.4879 28.4166 14.5086 28.4166 11.5287 28.4161C10.4928 28.4105 9.46804 28.0017 8.70733 27.2994C7.85065 26.5209 7.33675 25.3794 7.3337 24.2205C7.33319 20.2073 7.33319 16.1935 7.3337 12.1798C7.321 11.7299 7.62264 11.2977 8.04616 11.1479Z" fill="white" />
      <path d="M13.5834 13.2797C13.9226 13.1974 14.2984 13.2919 14.5563 13.528C14.7919 13.7357 14.924 14.0495 14.9164 14.3633C14.9169 16.8714 14.9169 19.3795 14.9169 21.8876C14.9275 22.2634 14.7295 22.6366 14.408 22.8337C14.049 23.0657 13.5519 23.052 13.2066 22.7996C12.9141 22.596 12.7394 22.2415 12.75 21.8861C12.7511 19.3617 12.748 16.8374 12.7516 14.3136C12.75 13.8317 13.1141 13.3833 13.5834 13.2797Z" fill="white" />
      <path d="M17.9033 13.2827C18.2415 13.1964 18.6193 13.2863 18.8798 13.5189C19.122 13.7271 19.2576 14.046 19.25 14.3639C19.25 16.8694 19.25 19.3749 19.25 21.8805C19.2607 22.2258 19.0992 22.5711 18.8219 22.7783C18.5452 22.9926 18.1618 23.0566 17.8302 22.9459C17.4843 22.8352 17.205 22.5391 17.1182 22.1867C17.073 22.0212 17.0842 21.848 17.0832 21.6784C17.0847 19.2236 17.0816 16.7694 17.0847 14.3146C17.0832 13.8373 17.4397 13.3914 17.9033 13.2827Z" fill="white" />
    </svg>
  );
};

export default DeleteCategory;
