import React from 'react';
import styled from 'styled-components';
import Alarm from './Alarm';
import storage from 'utils/sessionStorage';

const CashierStationAlarm = () => {
  return (
    <LeftBox className='CashierStationAlarm'>
      <div className="station-box"><span>Station {storage.station_number.get()}</span></div>
      <Alarm />
    </LeftBox>
  );
};

export default CashierStationAlarm;

const LeftBox = styled.div`
display: flex;
background: #232F3E;
align-items: center;
justify-content: space-between;
padding: 0 24px;
gap: 16px;
margin-bottom: 8px;
max-height: 126px;
height: 126px;
.station-box {
display: flex;
height: 48px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
background: #FFF;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}

.today-label {
  display: flex;
  .digital {
    display: inline-flex;
    align-items: center;
  }
  .digital p {
    color: #FBE696;
    font-size: 40px;
  }
}
  .clock .hour {
    line-height: unset;
  }
  .clock .hour .ampm,
  .clock .hour .digital :last-child {
    color: #FBE696;
    font-weight: 600;
  }
`;