import Alarm from 'features/user/pages/clockInClockOut/components/MainV2/Alarm';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import { useAppDispatch } from 'store/hooks';
import appointmentSelectors from 'features/appointment/services/selectors';
import { APPOINTMENT_LAYOUT } from 'features/appointment/services/constants';
import appointmentActions from 'features/appointment/services/actions';
import home_icon from './icons/home.svg';
import gallery_icon from './icons/gallery.svg';
import gallery_active_icon from './icons/gallery-active.svg';
import list_icon from './icons/list.svg';
import list_active_icon from './icons/list-active.svg';
import date_off_icon from './icons/date-off.svg';
import block_hour_icon from './icons/block-hour.svg';
import break_icon from './icons/break.svg';
import quick_booking_icon from './icons/quick-booking.svg';
import new_appt_icon from './icons/new-appt.svg';
import zoom_in_icon from './icons/zoom-in.svg';
import zoom_out_icon from './icons/zoom-out.svg';
import { ModalDateOffs } from '../../StaffsDayOff';
import { calcColTime } from '../../Actions';
import { quickBookingRef } from '../TableView/Body/Modal/QuickBooking';
import { useZoomConfig } from '../TableView/ZoomConfig';

const AppointmentCalendarHeaderV2 = () => {
  return (
    <Container>
      <LeftBox>
        <div className="station-box"><span>Station {storage.station_number.get()}</span></div>
        <Alarm />
      </LeftBox>
      <RightBox>
        <ButtonNavPin required={false} title='Home' src={home_icon} alt='home_icon' path='/' />
        <ButtonLayouts />
        <ButtonDateOffs />
        <ButtonBlockHour />
        <ButtonBreakTime />
        <ButtonQuickBooking />
        <ButtonNewAppointment />
        <ButtonZoomInOut />
      </RightBox>
    </Container>
  );
};

export default AppointmentCalendarHeaderV2;
const ButtonZoomInOut = () => {
  const { calendar, handleZoomIn, handleZoomOut } = useZoomConfig();
  if (!calendar) return null;
  return (
    <>
      <ButtonUI
        title={'Zoom In'}
        src={zoom_in_icon}
        alt={'zoom_in_icon'}
        onClick={handleZoomIn}
      />
      <ButtonUI
        title={'Zoom Out'}
        src={zoom_out_icon}
        alt={'zoom_out_icon'}
        onClick={handleZoomOut}
      />
    </>
  );
};
const ButtonNewAppointment = () => {
  const colTime = calcColTime();
  const dispatch = useAppDispatch();
  const newAppointment = () => {
    dispatch(appointmentActions.setNewAppointmentDraftData({
      colTime: colTime(),
      newApt: true,
    }));
  };


  return <ButtonUI
    title={'New Appt'}
    src={new_appt_icon}
    alt={'new_appt_icon'}
    onClick={newAppointment}
  />;
};

const ButtonQuickBooking = () => {
  const colTime = calcColTime();

  const newQuickBooking = () => {
    quickBookingRef.current?.open(colTime() || '');
  };

  return <ButtonUI
    title={'Quick booking'}
    src={quick_booking_icon}
    alt={'quick_booking_icon'}
    onClick={newQuickBooking}
  />;
};

const ButtonBreakTime = () => {
  const colTime = calcColTime();
  const dispatch = useAppDispatch();

  const newBreakTime = () => {
    dispatch(appointmentActions.setNewBreakTimeDraftData({
      colTime: colTime(),
    }));
  };

  return <ButtonUI
    title={'On Break'}
    src={break_icon}
    alt={'break_icon'}
    onClick={newBreakTime}
  />;
};

const ButtonBlockHour = () => {
  const colTime = calcColTime();
  const dispatch = useAppDispatch();
  const newBlockHour = () => dispatch(appointmentActions.setNewBlockHourDraftData({ colTime: colTime() }));

  return <ButtonUI
    title={'Block Hours'}
    src={block_hour_icon}
    alt={'block_hour_icon'}
    onClick={newBlockHour}
  />;
};


const ButtonDateOffs = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const params = appointmentSelectors.getParams();
  const handleOpen = () => {
    setOpen(true);
    dispatch(appointmentActions.getListStaffsOff.fetch({ startTime: params?.startTime || '', endTime: params?.endTime || '' }));
  };
  return (
    <>
      <ButtonUI title={'Date Off'} src={date_off_icon} alt={'date_off_icon'} onClick={handleOpen} />
      <ModalDateOffs v2 setOpen={setOpen} open={open} />
    </>
  );
};

const ButtonLayouts = () => {
  const dispatch = useAppDispatch();
  const value = appointmentSelectors.getAppointmentLayout();
  const setValue = (val: APPOINTMENT_LAYOUT) => () => {
    dispatch(appointmentActions.setAppointmentLayout(val));
  };
  return (
    <>
      <ButtonUI title={'Gallery View'} src={value === APPOINTMENT_LAYOUT.CALENDAR ? gallery_active_icon : gallery_icon} alt={'gallery_icon'}
        className={value === APPOINTMENT_LAYOUT.CALENDAR ? 'active' : ''}
        onClick={setValue(APPOINTMENT_LAYOUT.CALENDAR)} />
      <ButtonUI title={'List View'} src={value === APPOINTMENT_LAYOUT.LIST ? list_active_icon : list_icon} alt={'list_icon'}
        className={value === APPOINTMENT_LAYOUT.LIST ? 'active' : ''}
        onClick={setValue(APPOINTMENT_LAYOUT.LIST)} />
    </>
  );
};

const ButtonNavPin = ({ src, alt, path, required = true, title }: { title: string, src: any, alt: string, path: string, required?: boolean }) => {
  const navigate = useNavigate();
  const [visiblePW, setVisiblePW] = React.useState(false);

  const onOpen = () => {
    if (required) return setVisiblePW(true);
    navigate(`/store/${storage.shop_id.get()}${path}`);
  };

  const onVerifyAdminSuccess = () => {
    if (visiblePW) navigate(`/store/${storage.shop_id.get()}${path}`);
    setVisiblePW(false);
  };

  return (
    <>
      <ButtonUI title={title} src={src} alt={alt} onClick={onOpen} />
      {!!visiblePW && (
        <RequestAdminLocalPW
          v2
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setVisiblePW(false)}
        />
      )}
    </>
  );
};
const ButtonUI = ({ className = '', onClick, src, alt, title }: { className?: string, title?: string, src?: any, alt?: string, onClick?: () => void }) => {
  return (
    <button className={'btn ' + className} onClick={onClick}>
      <img src={src} alt={alt} className="icon-action" />
      <span>{title}</span>
    </button>
  );
};
const Container = styled.div`
display: flex;
align-self: stretch;
background: #fff;
`;

const LeftBox = styled.div`
display: flex;
background: #232F3E;
align-items: center;
justify-content: space-between;
padding: 24px;
gap: 24px;
margin-bottom: 8px;
min-width: 800px;
.station-box {
display: flex;
height: 48px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
background: #FFF;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}

.today-label {
  display: flex;
  .digital {
    display: inline-flex;
    align-items: center;
  }
  .digital p {
    color: #FBE696;
    font-size: 55px;
  }
}
  .clock .hour {
    line-height: unset;
  }
  .clock .hour .ampm,
  .clock .hour .digital :last-child {
    color: #FBE696;
    font-weight: 600;
  }
`;

const RightBox = styled.div`
display: flex;
padding: 0;
padding-left: 8px;
padding-bottom: 8px;
align-items: center;
flex-shrink: 0;
flex: 1;
background: #fff;
button.btn {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
  background: #FCFCFD;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  img.icon-action {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
  }
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &:hover {
    background: #eee;
  }

  &.active {
    background: #C84B31;
    span {
      color: #fff;
    }
  }
}
`;