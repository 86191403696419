import { Row, Switch } from 'antd';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import { cashierUIActions } from 'features/cashier/services/reducers';
import cashierSelectors from 'features/cashier/services/selectors';
import { useCallback } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

const TotalLabel = () => {
  const dispatch = useAppDispatch();
  const paymentTicket = cashierSelectors.getTicketTotalInfo();
  const feeCreditCard = shopSelectors.data.feeCreditCard();
  const enableCardFee = cashierSelectors.getEnableCardFee();
  const calcFee = cashierSelectors.getCalcFee();
  const discountSetting = shopSelectors.data.discountSetting();
  const enableCashIncentive = cashierSelectors.enableCashIncentive();

  const handleEnableCashIncentive = (checked: boolean) => {
    dispatch(cashierUIActions.enableCashIncentive(checked));
  };
  const handleChangeSwitch = (checked: boolean) => {
    dispatch(cashierActions.setEnableCardFee(checked));
  };

  const labelFee = useCallback(() => {
    if (!feeCreditCard) return '';
    if (feeCreditCard?.feeType === 'PERCENT')
      return `${feeCreditCard?.feeValue}% ${calcFee}`;
    if (feeCreditCard?.feeType === 'MONEY')
      return formatCurrency(feeCreditCard?.feeValue);
  }, [calcFee, feeCreditCard]);

  const labelCashDiscount = useCallback(() => {
    if (!discountSetting) return '';
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      const amount = Math.round(paymentTicket.subtotal * (discountValue / 100));
      return `${discountValue}% ${amount ? `(${formatCurrency(amount)})` : ''}`;
    }
    if (discountType === 'MONEY')
      return formatCurrency(discountValue);
  }, [discountSetting, paymentTicket]);

  return (
    <TotalTextStyled style={discountSetting?.enableCashIncentive ? { alignItems: 'center' } : {}}>
      {discountSetting?.enableCashIncentive ?
        <Row style={{ flex: 1, gap: 4 }} align={'middle'} justify={'start'}>
          {feeCreditCard?.enable && <Row align={'middle'}>
            <Switch checked={enableCardFee} onChange={handleChangeSwitch} />
            <Text ml={0.5} mr={0.5} variant="H6" color="text_3" style={{ fontWeight: 'bolder', }}>
              Card Fee: {labelFee() || ''}
            </Text>
          </Row>}
          <Row align={'middle'}>
            <Switch checked={enableCashIncentive} onChange={handleEnableCashIncentive} />
            <Text ml={0.5} variant="H6" color="text_3" style={{ fontWeight: 'bolder', }}>
              Cash/Check Incentive: {labelCashDiscount()}
            </Text>
          </Row>
        </Row> :
        <>
          {feeCreditCard?.enable && <Row align={'middle'} style={{ flex: 1, alignSelf: 'stretch' }}>
            <Switch checked={enableCardFee} onChange={handleChangeSwitch} />
            <Text ml={0.5} variant="H6" color="text_3" pb={0.25} style={{ fontWeight: 'bolder' }}>
              Card Fee: {labelFee() || ''}
            </Text>
          </Row>}
        </>
      }
      <Row align={'middle'}>
        <Text variant="H6" color="text_3" mr={1.5} style={{ fontWeight: 'bolder' }}>
          Total
        </Text>
        <Text variant="H1" color="primary_active">
          {formatCurrency(paymentTicket?.total >= 0 ? paymentTicket?.total : 0)}

        </Text>
      </Row>
    </TotalTextStyled>
  );
};

export default TotalLabel;

const TotalTextStyled = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: end;
  border-radius: 5px;
  background: var(--primary-button, #FFE5E7);
  padding: 0.5rem 1.5rem;
  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled), .ant-switch.ant-switch-checked {
    background: #6FABB6;
  }
`;
