import Modal from 'components/Modal';
import CheckGiftCardV2, { checkGiftCardV2Ref } from './CheckGiftCard';
import IssueGiftCardV2, { issueGiftCardV2Ref } from './IssueGiftCard';
import { GIFT_CARD_RADIO_TYPE } from './Tab';
import { FormInstance } from 'antd/lib';

type Props = {
  visible?: boolean;
  onClose: () => void;
  radioType: GIFT_CARD_RADIO_TYPE;
  setRadioType: (radioType: GIFT_CARD_RADIO_TYPE) => void;
  checkExistGiftCard: (code: string) => Promise<void>;
  formCheck: FormInstance<{ giftNumber: string }>;
  formIssue: FormInstance<{ giftNumber: string, amount: string, expiration: number }>;
  onSubmit?: (value: any) => void;
};
const ModalAddGiftCardV2 = ({ onSubmit, formCheck, formIssue, visible, onClose, radioType, setRadioType, checkExistGiftCard }: Props) => {
  return (
    <Modal
      visible={visible}
      onClose={onClose}
      width={'auto'} footer={null} className='modal-overflow-unset modal-non-opacity'
      hiddenHeader
      noneBodyStyle
      okTitle='Issue'
    >
      <div style={{ display: radioType === GIFT_CARD_RADIO_TYPE.BALANCE ? 'block' : 'none' }}>
        <CheckGiftCardV2
          ref={checkGiftCardV2Ref}
          form={formCheck}
          onClose={onClose}
          setRadioType={setRadioType}
        />
      </div>
      <div style={{ display: radioType !== GIFT_CARD_RADIO_TYPE.BALANCE ? 'block' : 'none' }}>
        <IssueGiftCardV2
          ref={issueGiftCardV2Ref}
          form={formIssue}
          onClose={onClose}
          setRadioType={setRadioType}
          checkExistGiftCard={checkExistGiftCard}
          onSubmit={onSubmit}
          visible={visible}
        />
      </div>
    </Modal>
  );
};

export default ModalAddGiftCardV2;
