const PreOrder = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_10055_61959)">
        <path d="M22.2825 16.2825L19.2825 19.2825C19.2128 19.3528 19.1298 19.4086 19.0384 19.4467C18.947 19.4847 18.849 19.5043 18.75 19.5043C18.651 19.5043 18.553 19.4847 18.4616 19.4467C18.3702 19.4086 18.2872 19.3528 18.2175 19.2825L15.2175 16.2825C15.0763 16.1413 14.9969 15.9497 14.9969 15.75C14.9969 15.5503 15.0763 15.3587 15.2175 15.2175C15.3587 15.0763 15.5503 14.9969 15.75 14.9969C15.9497 14.9969 16.1413 15.0763 16.2825 15.2175L18 16.9425V8.25C18 8.05109 18.079 7.86032 18.2197 7.71967C18.3603 7.57902 18.5511 7.5 18.75 7.5C18.9489 7.5 19.1397 7.57902 19.2803 7.71967C19.421 7.86032 19.5 8.05109 19.5 8.25V16.9425L21.2175 15.2175C21.3587 15.0763 21.5503 14.9969 21.75 14.9969C21.9497 14.9969 22.1413 15.0763 22.2825 15.2175C22.4237 15.3587 22.5031 15.5503 22.5031 15.75C22.5031 15.9497 22.4237 16.1413 22.2825 16.2825ZM5.25 6H18.75C18.9489 6 19.1397 5.92098 19.2803 5.78033C19.421 5.63968 19.5 5.44891 19.5 5.25C19.5 5.05109 19.421 4.86032 19.2803 4.71967C19.1397 4.57902 18.9489 4.5 18.75 4.5H5.25C5.05109 4.5 4.86032 4.57902 4.71967 4.71967C4.57902 4.86032 4.5 5.05109 4.5 5.25C4.5 5.44891 4.57902 5.63968 4.71967 5.78033C4.86032 5.92098 5.05109 6 5.25 6ZM5.25 10.5H15.75C15.9489 10.5 16.1397 10.421 16.2803 10.2803C16.421 10.1397 16.5 9.94891 16.5 9.75C16.5 9.55109 16.421 9.36032 16.2803 9.21967C16.1397 9.07902 15.9489 9 15.75 9H5.25C5.05109 9 4.86032 9.07902 4.71967 9.21967C4.57902 9.36032 4.5 9.55109 4.5 9.75C4.5 9.94891 4.57902 10.1397 4.71967 10.2803C4.86032 10.421 5.05109 10.5 5.25 10.5ZM12.75 13.5H5.25C5.05109 13.5 4.86032 13.579 4.71967 13.7197C4.57902 13.8603 4.5 14.0511 4.5 14.25C4.5 14.4489 4.57902 14.6397 4.71967 14.7803C4.86032 14.921 5.05109 15 5.25 15H12.75C12.9489 15 13.1397 14.921 13.2803 14.7803C13.421 14.6397 13.5 14.4489 13.5 14.25C13.5 14.0511 13.421 13.8603 13.2803 13.7197C13.1397 13.579 12.9489 13.5 12.75 13.5Z" fill="#1D2129" />
      </g>
      <defs>
        <clipPath id="clip0_10055_61959">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PreOrder;
