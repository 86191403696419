import { IDashboardTimeClockReport } from 'features/report/services/types/reportStaff';
import React from 'react';
import payroll_icon from '../icons/payroll.svg';
import paid_out_icon from '../icons/paid-out.svg';
import tip_icon from '../icons/tip.png';
import discount_icon from '../icons/discount.png';
import styled from 'styled-components';
import { get } from 'lodash';
import clsx from 'clsx';
import { formatCurrency } from 'utils/formatCurrency';

const TYPE = {
  PAYROLL: 'PAYROLL',
  PAY_OUT: 'PAY_OUT',
  TIPS: 'TIPS',
  DISCOUNT: 'DISCOUNT',
};

const configs = {
  [TYPE.PAYROLL]: {
    label: 'Hour Payroll',
    icon: payroll_icon,
    bg: '#808836',
  },
  [TYPE.PAY_OUT]: {
    label: 'Hour Pay Out',
    icon: paid_out_icon,
    bg: '#4F1787',
  },
  [TYPE.TIPS]: {
    label: 'Tips',
    icon: tip_icon,
    bg: '#C84B31',
  },
  [TYPE.DISCOUNT]: {
    label: 'Discount',
    icon: discount_icon,
    bg: '#346751',
  },
};
interface Props {
  data: IDashboardTimeClockReport;
}
const DashboardV2 = ({ data }: Props) => {
  return (
    <Container>
      <DashboardV2Item type='PAYROLL' value={data.hourlyPayroll + ' hour'} />
      <DashboardV2Item type='PAY_OUT' value={formatCurrency(data.hourlyPayOut)} />
      <DashboardV2Item type='TIPS' value={formatCurrency(data.totalTip)} />
      <DashboardV2Item type='DISCOUNT' value={formatCurrency(data.totalDiscount)} />
    </Container>
  );
};

export default DashboardV2;

const Container = styled.div`
display: flex;
padding: 0px;
align-items: center;
gap: 16px;
align-self: stretch;
`;

type DashboardV2ItemProps = {
  type: keyof typeof TYPE,
  value?: string;
  increase?: boolean;
  decrease?: boolean;
  percent?: number;
};
const DashboardV2Item = ({ type, value = '$0.00', decrease, increase, percent }: DashboardV2ItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return <DashboardV2ItemStyled>
    <div className="content-container">
      <div className="amount-container">
        <p className="value">{value}</p>
        <p className="label">{config.label}</p>
      </div>
      <div className={clsx('percent-container', increase && 'increase', decrease && 'decrease')}>
        <span>{decrease ? '-' : '+'}{percent}%</span>
      </div>
      <div className="icon-button">
        <img src={config.icon} alt={config.label} />
      </div>
    </div>
  </DashboardV2ItemStyled>;
};
const DashboardV2ItemStyled = styled.div`
display: flex;
padding: 16px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid #CCD4DC;
background: #FFF;
position: relative;
.content-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  .amount-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    p.value {
      color: #1D2129;
      font-family: Poppins;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: lowercase;
    }

    p.label {
      color: #505050;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
    }
  }
}


.percent-container {
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: #E5E6EB;

  span {
    color: #64748B;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;

    &.increase {
        color: #2D6772;
    }
    &.decrease {
        color: #D2464F;
    }
  }
}

.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 16px;
  border-radius: 100px;
  border: 1px solid #FFF;
  img {
    height: 72px;
    width: 72px;
  }
}
`;