import { Col, Input, Radio, Row } from 'antd';
import Form, { FormItem, useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
type Props = {
  v2?: boolean;
};
type Ref = {
  open: (callback: (reason: string) => void) => void;
};
export const useModalInputReasonRef = () => useRef<Ref>(null);
const ModalInputReason = forwardRef<Ref, Props>(({ v2 }, ref) => {
  const callbackRef = useRef<(reason: string) => void>(() => undefined);
  const [visible, setVisible] = useState(false);
  const [form] = useForm();
  const [reason, setReason] = useState<string>('');
  const onClose = () => {
    setVisible(false);
  };
  const handleClose = () => {
    onClose();
    setReason('');
    form.resetFields();
  };

  const handleSetReason = (val: string) => {
    form.setFieldValue('reason', val);
    setReason(val);
    form.validateFields();
  };

  const handleVoid = () => {
    callbackRef.current(reason);
    handleClose();
  };
  useImperativeHandle(ref, () => ({
    open(callback) {
      callbackRef.current = callback;
      setVisible(true);
    },
  }));
  if (v2) {
    return (
      <Modal
        visible={visible}
        modalTitle='REASON'
        showClose
        okTitle={'Accept'}
        noneFooterStyle
        containerPadding={1.5}
        disableOk={!reason.trim()}
        onClose={handleClose}
        onSubmit={() => form.submit()}
        v2
        noneBodyStyle
        width={'900px'}
      >
        <Wrapper className='v2' form={form} validateTrigger={['onSubmit']} onFinish={handleVoid}>
          <Text textAlign='center' variant='CONTENT_2' >Please select or enter the reason <br /> you want to cancel this appointment</Text>
          <Reasons v2={v2} value={reason} onChangeText={handleSetReason} />
          <ReasonInputWrap name={'reason'} rules={[{ required: true }]}>
            <Input.TextArea
              className='TextArea'
              rows={5}
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder='Type your reason here'
            />
          </ReasonInputWrap>
        </Wrapper>
      </Modal>
    );
  }

  return (
    <Modal
      visible={visible}
      modalTitle='Select'
      showClose
      hiddenCloseButton
      okTitle={'Accept'}
      noneBodyStyle
      noneFooterStyle
      containerPadding={1.5}
      disableOk={!reason.trim()}
      onClose={handleClose}
      onSubmit={() => form.submit()}
    >
      <Wrapper form={form} validateTrigger={['onSubmit']} onFinish={handleVoid}>
        <Text textAlign='center' variant='CONTENT_2' style={{ fontSize: '1.2rem' }}>Please choose or enter the reason for voiding this ticket</Text>
        <Reasons value={reason} onChangeText={handleSetReason} />
        <ReasonInputWrap name={'reason'} rules={[{ required: true }]}>
          <Input.TextArea
            rows={5}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder='Type your reason here'
          />
        </ReasonInputWrap>
      </Wrapper>
    </Modal>
  );
});
ModalInputReason.displayName = 'ModalInputReason';
export default ModalInputReason;

const Wrapper = styled(Form)`
  height: 40vh;  
  display: flex;
  flex-direction: column;
  gap: 1rem;


&.v2 {
  height: auto;
    .CONTENT_2 {
      color: #64748B;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }


  textarea {
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
`;
type ReasonProps = {
  value: string;
  onChangeText: (val: string) => void;
  v2?: boolean;
};

const REASONS = {
  BUSY: 'Busy',
  SPAM: 'Spam',
  CHANGE_TIME_APT: 'Change time appointment',
};

const Reasons = ({ value, onChangeText, v2 }: ReasonProps) => v2 ?
  <div style={{ display: 'flex', justifyContent: 'space-between', width: '90%', margin: 'auto' }}>
    <button type='button' onClick={() => onChangeText(REASONS.BUSY)}><Reason v2 checked={value === REASONS.BUSY} message={REASONS.BUSY} /></button>
    <button type='button' onClick={() => onChangeText(REASONS.SPAM)}><Reason v2 checked={value === REASONS.SPAM} message={REASONS.SPAM} /></button>
    <button type='button' onClick={() => onChangeText(REASONS.CHANGE_TIME_APT)}><Reason v2 checked={value === REASONS.CHANGE_TIME_APT} message={REASONS.CHANGE_TIME_APT} /></button>
  </div> : (
    <Row>
      <Col span={24} onClick={() => onChangeText(REASONS.BUSY)}><Reason checked={value === REASONS.BUSY} message={REASONS.BUSY} /></Col>
      <Col span={24} onClick={() => onChangeText(REASONS.SPAM)}><Reason checked={value === REASONS.SPAM} message={REASONS.SPAM} /></Col>
      <Col span={24} onClick={() => onChangeText(REASONS.CHANGE_TIME_APT)}><Reason checked={value === REASONS.CHANGE_TIME_APT} message={REASONS.CHANGE_TIME_APT} /></Col>
    </Row>
  );
const Reason = ({ message, checked, v2 }: { checked?: boolean, message?: string, v2?: boolean }) => v2 ? (
  <ReasonStyled className='v2' type='button'>
    {checked ? <div className='checked-checkbox'>
      <svg xmlns="http://www.w3.org/2000/svg" width="31" height="30" viewBox="0 0 31 30" fill="none">
        <path d="M12.6586 22.5C12.2584 22.5 11.8783 22.3326 11.5981 22.0396L5.93522 16.117C5.35493 15.5101 5.35493 14.5056 5.93522 13.8987C6.5155 13.2918 7.47598 13.2918 8.05627 13.8987L12.6586 18.7121L22.9437 7.95518C23.524 7.34827 24.4845 7.34827 25.0648 7.95518C25.6451 8.56209 25.6451 9.56662 25.0648 10.1735L13.7192 22.0396C13.439 22.3326 13.0588 22.5 12.6586 22.5Z" fill="white" />
      </svg>
    </div> : <div className='blank-checkbox' />}
    <Text variant='CONTENT_2' className='reason-text'>{message}</Text>
  </ReasonStyled>
) : (
  <ReasonStyled type='button'>
    <Radio checked={checked} />
    <Text variant='CONTENT_2' className='reason-text'>{message}</Text>
  </ReasonStyled>
);
const ReasonStyled = styled.button`
display: flex;
align-items: center;
width: 100%;
margin-bottom: 0.5rem;
padding: 4px 0;
border-radius: 5px;
  .reason-text {
    font-size: 1rem;
  }
  &:hover {
    background-color: #FFE5E7 !important;
  }


&.v2 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;

  .reason-text {
    color: #1D2129;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .blank-checkbox {
    display: flex;
    width: 40px;
    height: 40px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #86909C;
    background: #F5F5F5;
  }
  .checked-checkbox {
    display: flex;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #2D6772;
    background: #2D6772;
  }
}
`;

const ReasonInputWrap = styled(FormItem)``;
