import appointmentServiceSagas from 'features/appointment/services/sagas';
import authServiceSagas from 'features/auth/services/sagas';
import checkInServiceSagas from 'features/checkIn/services/sagas';
import customerServiceSagas from 'features/customer/services/sagas';
import cashierServiceSagas from 'features/cashier/services/sagas';
import paymentServiceSagas from 'features/payment/services/sagas';
import reportServiceSagas from 'features/report/services/sagas';
import settingServiceSagas from 'features/settingService/services/sagas';
import settingStaffSagas from 'features/settingStaff/services/sagas';
import ticketListServiceSagas from 'features/ticketList/services/sagas';
import turnServiceSagas from 'features/turn/services/sagas';
import userServiceSagas from 'features/user/services/sagas';
import { all } from 'redux-saga/effects';
import shopServiceSagas from 'services/shop/sagas';
import multiplePaymentServiceSagas from 'features/payment/pages/PaymentPage/services/sagas';
import giftCardsSagas from 'features/giftCards/services/sagas';
import multiCardServiceSagas from 'features/payment/multipleCard/services/sagas';
export default function* rootSaga() {
  yield all([
    checkInServiceSagas(),
    userServiceSagas(),
    cashierServiceSagas(),
    paymentServiceSagas(),
    appointmentServiceSagas(),
    settingServiceSagas(),
    ticketListServiceSagas(),
    customerServiceSagas(),
    reportServiceSagas(),
    authServiceSagas(),
    shopServiceSagas(),
    settingStaffSagas(),
    turnServiceSagas(),
    multiplePaymentServiceSagas(),
    giftCardsSagas(),
    multiCardServiceSagas(),
  ]);
}
