import React from 'react';
import styled from 'styled-components';
import reviewUsImage from './reviewUs.png';
import AlarmClockUI from '../../CheckInInputPage/components/AlarmClockUI';

const LeftContent = () => {
  return (
    <Styled>
      <AlarmClockUI />
      <img src={reviewUsImage} alt="review-us" className="review-us" />
    </Styled>
  );
};

export default LeftContent;
const Styled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .review-us {
    width: 465px;
    height: 465px;
  }
`;
