import { turnManualAPIActions } from 'features/turn/services/actions';
import { colorSetCodes, TURN_ITEM_STATUS, TURN_ITEM_TYPE } from 'features/turn/services/constants';
import { turnManualSelectors } from 'features/turn/services/selectors';
import { ITurnManualSettingColorItem } from 'features/turn/services/types/turn';
import { get } from 'lodash';
import React from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { Progressing } from '../TurnTable/TurnBodyItem';
import Box from 'components/Box';
import clsx from 'clsx';

const TurnFeatures = () => {
  const turnSettingColors = turnManualSelectors.settingColors();
  const dispatch = useAppDispatch();
  const onChangeStatusType = (type: TURN_ITEM_STATUS) => () => dispatch(turnManualAPIActions.changeStatusTypeTurnManual(type));
  const removeTurnManual = () => dispatch(turnManualAPIActions.removeTurnManual());
  const onChangeType = (type: TURN_ITEM_TYPE) => () => dispatch(turnManualAPIActions.changeTypeTurnManual(type));

  const onChooseServiceTurn = (half?: boolean) => () => {
    dispatch(turnManualAPIActions.handleTurnWithService({
      itemId: '',
      itemName: '',
      color: null,
      turn: half ? 0.5 : 1,
    }));
  };

  return (
    <Container className='TurnTypes' turnSettingColors={turnSettingColors}>
      <button onClick={onChangeType(TURN_ITEM_TYPE.WALK_IN)} type='button' className="btn WALK_IN">
        <div className="box-item WALK_IN"></div>
        <div className="label-type">WALK-IN</div>
      </button>
      <button onClick={onChangeType(TURN_ITEM_TYPE.APPOINTMENT)} type='button' className="btn APPOINTMENT">
        <div className="box-item APPOINTMENT"></div>
        <div className="label-type">APPT.</div>
      </button>
      <button onClick={onChangeStatusType(TURN_ITEM_STATUS.IN_PROGRESS)} type='button' className="btn IN_PROGRESS">
        <div className="box-item IN_PROGRESS">{Progressing}</div>
        <div className="label-type">Active</div>
      </button>
      <button onClick={onChangeStatusType(TURN_ITEM_STATUS.MINUS_TURN)} type='button' className="btn MINUS">
        <div className="box-item MINUS"><div className="label-type">X</div></div>
        <div className="label-type">Sub-turn</div>
      </button>
      <ButtonTurn onClick={onChooseServiceTurn(false)} />
      <button onClick={onChangeType(TURN_ITEM_TYPE.BONUS)} type='button' className="btn BONUS">
        <div className="box-item BONUS"></div>
        <div className="label-type">Bonus</div>
      </button>
      <button onClick={onChangeType(TURN_ITEM_TYPE.REQUEST)} type='button' className="btn REQUEST">
        <div className="box-item REQUEST"></div>
        <div className="label-type">REQUEST</div>
      </button>
      <button onClick={removeTurnManual} type='button' className="btn CLEAR">
        <div className="box-item CLEAR"></div>
        <div className="label-type">Clear</div>
      </button>
      <button onClick={onChangeStatusType(TURN_ITEM_STATUS.DONE)} type='button' className="btn CLEAR">
        <div className="box-item CLEAR">
          <div className='icon-completed'>{iconCheck}</div>
        </div>
        <div className="label-type">Done</div>
      </button>
      <ButtonTurn half onClick={onChooseServiceTurn(true)} />
    </Container>
  );
};

export default TurnFeatures;

const Container = styled.div<{ turnSettingColors: ITurnManualSettingColorItem[] }>`
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
margin-right: 8px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
gap: 8px;
row-gap: 15px;
align-self: stretch;
.btn, .btn-summary {
  height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid #CCD4DC;
  border-radius: 5px;
  gap: 8px;
  padding:0 8px;
  padding-bottom: 4px;
  ${({ turnSettingColors }) => turnSettingColors.map(o => {
  const theme = get(colorSetCodes, o?.color || '');
  return `&.${o.turnStatus} {
        border: 1.5px solid #1D2129;
        .box-item {
          background: ${theme.bg};
          border: 1px solid ${theme.stroke};
          box-shadow: 4px 4px 0px 0px ${theme.shadow};
        }
      }`;
})
  }
  &.CLEAR {
    border: 1.5px solid #1D2129;
    .box-item {
      background: #fff;
      border: 1px solid #CCD4DC;
      box-shadow: 4px 4px 0px 0px #CCD4DC;
    }
  }
}
.box-item {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  overflow: hidden;
  background: #F0F0F0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .icon-completed {
    position: absolute;
    bottom: 2px;
    right: 4px;
  }
}
.btn-summary {
  border: 1.5px solid #1D2129;
}
.btn-summary .box-item {
  background: #F76C82;
  border: 1px solid #D94452;
  box-shadow: 4px 4px 0px 0px #D94452;
  width: 45px;
  height: 45px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &.half {
    border: 2px solid #CCD4DC;
    background: #F0F0F0;
    box-shadow: none;
  }
}

.label-type {
  color: #1D2129;
  text-align: center;
  text-transform: uppercase;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;

const iconCheck = <Box display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 15, width: 15, height: 15, background: '#00AD45' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6.48494 12C6.2715 12 6.06873 11.9107 5.91932 11.7544L2.89911 8.59574C2.58962 8.27206 2.58962 7.73631 2.89911 7.41263C3.20859 7.08894 3.72085 7.08894 4.03033 7.41263L6.48494 9.97977L11.9703 4.24276C12.2798 3.91908 12.7921 3.91908 13.1015 4.24276C13.411 4.56645 13.411 5.1022 13.1015 5.42588L7.05055 11.7544C6.90114 11.9107 6.69838 12 6.48494 12Z" fill="white" />
  </svg>
</Box>;

const ButtonTurn = ({ half, onClick }: { half?: boolean, onClick?: () => void; }) => {
  return (
    <button type='button' className="btn-summary" onClick={onClick}>
      <div className={clsx('box-item', half && 'half')}>
        {half &&
          <div className="half-bg">
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="61" height="60" viewBox="0 0 61 60" fill="none">
                <path d="M0.5 60V0H80.5L40.5 30L0.5 60Z" fill="#1D2129" />
              </svg>
            </div>
          </div>}
      </div>
      <div className="label-type">{half ? 'Half-Turn' : 'Full-Turn'}</div>
    </button>
  );
};