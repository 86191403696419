/* eslint-disable no-constant-condition */
import { Layout } from 'antd';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import authActions from 'features/auth/services/actions';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import storage from 'utils/sessionStorage';
import ButtonScanQR, { useButtonScanQRRef } from 'widgets/ButtonScanQR';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import { SideBarSettingConfigKeys } from './helper';
import logo from './logo.svg';
import SideBarMenuItem from './MenuItem';
import SideBarSetting from './SideBarSetting';
import cashierActions from 'features/cashier/services/actions';
import { FeatureVerify, OTPVerificationRef } from 'widgets/OTPVerification';

const SidebarV2 = () => {
  const dispatch = useAppDispatch();
  const modal = useModalConfirmRef();
  const modalConfirm = useModalConfirmRef();
  const qrScanRef = useButtonScanQRRef();
  const divRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  const allSetting = shopSelectors.data.allSetting();
  const [visiblePW, setVisiblePW] = useState<string | null>(null);

  const onVerifyAdminSuccess = () => {
    if (visiblePW) navigate(visiblePW);
    setVisiblePW(null);
  };

  const onNavTo = (path: string, requirePW?: boolean) => () => {
    dispatch(cashierActions.checkCashierWillUnmount());
    const href = '/store/' + storage.shop_id.get() + path;
    if (path === '/report' && OTPVerificationRef.current) {
      return OTPVerificationRef.current.openActiveSalon((valid) => {
        if (!valid) return setVisiblePW(href);
        return navigate(href);
      }, FeatureVerify.REPORT_SALARY);
    }
    if (!requirePW)
      return navigate(href);
    setVisiblePW(href);
  };

  const onScanQR = () => {
    dispatch(cashierActions.checkCashierWillUnmount());
    qrScanRef.current?.scan();
  };
  const onRefresh = () => {
    dispatch(cashierActions.checkCashierWillUnmount());
    window.location.href = `${location.origin}/store/${storage.shop_id.get()}`;
  };

  const currentPath = useMemo(() => {
    const masterPath = location?.origin + '/store/' + storage.shop_id.get() + '';
    return location.href?.replace(masterPath, '') || '/';
  }, [location.href]);

  const onSettingClick = (type: SideBarSettingConfigKeys) => {
    switch (type) {
      case 'CUSTOMER':
        onNavTo('/customers', true)();
        break;
      case 'STAFF':
        onNavTo('/setting-staff', true)();
        break;
      case 'SETTING':
        onNavTo('/setting-service', true)();
        break;
      case 'LOGOUT': {
        dispatch(cashierActions.checkCashierWillUnmount());
        modal.current?.open('Are you about to logout the system?', () => {
          dispatch(authActions.ownerLogout());
          navigate('/sign-in');
        });
        break;
      }

      default:
        break;
    }
  };

  return (
    <>
      <LayoutSider className='LayoutSider' theme="light" collapsed collapsedWidth={'142px'}>
        <SidebarStyled className='SidebarStyled'>
          <MenuStyled className='MenuStyled'>
            <img src={logo} alt="logo" className="logo" />
            <div className='wrapper-menu'>
              <div className='menu-scroll-container'>
                <MenuContainerStyled className='MenuContainerStyled'>
                  <SideBarMenuItem type='HOME' active={currentPath === '/'} onClick={onNavTo('/')} />
                  <SideBarMenuItem type='CREDIT_CARD' active={currentPath.includes('/credit-cards')} onClick={onNavTo('/credit-cards', !allSetting?.ignorePassAdminDiscount)} />
                  <SideBarMenuItem type='TICKET' active={currentPath.includes('/ticket')} onClick={onNavTo('/tickets', true)} />
                  <SideBarMenuItem type='REPORT' active={currentPath.includes('/report')} onClick={onNavTo('/report', true)} />
                  <SideBarMenuItem type='QUICK_VIEW' active={currentPath.includes('/quick-view-tickets')} onClick={onNavTo('/quick-view-tickets')} />
                  <SideBarMenuItem type='SCAN_QR' onClick={onScanQR} />
                  <SideBarMenuItem type='TURN' active={currentPath.includes('/turn')} onClick={onNavTo('/turn')} />
                  <SideBarMenuItem type='APPOINTMENT' active={currentPath.includes('/appointments')} onClick={onNavTo('/appointments')} />
                  <SideBarMenuItem type='GIFT_CARD' active={currentPath.includes('/gift-cards')} onClick={onNavTo('/gift-cards', true)} />
                  <SideBarMenuItem type='REFRESH' onClick={onRefresh} />
                </MenuContainerStyled>
              </div>
            </div>
          </MenuStyled>
          <SideBarSetting onClick={onSettingClick} currentPath={currentPath} />
        </SidebarStyled>
      </LayoutSider>
      <div ref={divRef} style={{ height: '50px', width: '100%', display: 'none' }} />
      <ModalConfirm ref={modal} okTitle='Yes' cancelTitle='No' />
      {!!visiblePW && (
        <RequestAdminLocalPW
          onlyAdmin
          v2
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setVisiblePW(null)}
        />
      )}
      <ButtonScanQR ref={qrScanRef} hook />
      <ModalConfirm ref={modalConfirm} />
    </>
  );
};

export default SidebarV2;

const LayoutSider = styled(Layout.Sider)`
.ant-layout-sider-children {
  height: 100%;
  display: flex;
}

  
@media (min-resolution: 0.8dppx) {
  &.LayoutSider {
    .SidebarStyled {
      padding-top: 32px;
      .MenuStyled {
        gap: 32px;
      }
    }
  }
}
`;
const MenuContainerStyled = styled.div`
display: flex;
flex-direction: column;
align-items: center;
align-self: stretch;
padding-bottom: 150px;
`;
const MenuStyled = styled.div`
flex: 1;
display: flex;
flex-direction: column;
align-items: center;
gap: 37px;
align-self: stretch;
img.logo {
  display: flex;
  width: 116.133px;
  height: 39.893px;
  align-items: center;
  gap: 5.371px;
}

.wrapper-menu {
  flex: 1;
  position: relative;
  align-self: stretch;
  .menu-scroll-container {
    position: absolute;
    inset: 0;
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
}
`;

const SidebarStyled = styled.div`
flex: 1;
display: flex;
padding-top: 56px;
flex-direction: column;
align-items: center;
gap: 32px;
background: #FFF;
box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
`;
