import type {
  UniqueIdentifier
} from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { CSSProperties, PropsWithChildren } from 'react';

import styled from 'styled-components';

interface Props {
  id: UniqueIdentifier;
}

function SortableItem({ children, id }: PropsWithChildren<Props>) {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition
  } = useSortable({ id });

  const style: CSSProperties = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition
  };

  return (
    <WrapStyled>
      <div className="DragHandle" {...attributes} {...listeners} ref={setActivatorNodeRef}>
        <div className="SortableItem" ref={setNodeRef} style={style}>
          <svg viewBox="0 0 20 20" width="12" style={{ marginRight: '1rem' }}>
            <path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
          </svg>

          {children}

        </div>
      </div>
    </WrapStyled>
  );
}

export default SortableItem;

const WrapStyled = styled.div`
.SortableItem {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
  padding: 18px 20px;
  box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
    0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
  border-radius: calc(4px / var(--scale-x, 1));
  box-sizing: border-box;
  list-style: none;
  color: #333;
  font-weight: 400;
  font-size: 1rem;
  font-family: sans-serif;
}

.DragHandle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  touch-action: none;
  cursor: var(--cursor, pointer);
  border-radius: 5px;
  border: none;
  outline: none;
  appearance: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

.DragHandle:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.DragHandle:focus-visible {
  box-shadow: 0 0px 0px 2px #4c9ffe;
}

.DragHandle svg {
  flex: 0 0 auto;
  margin: auto;
  height: 100%;
  overflow: visible;
  fill: #919eab;
}

`;