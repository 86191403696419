import { SettingOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import React from 'react';
import { ICategoryItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import SwitchBtn from '../SwitchBtn';
import shopSelectors from 'services/shop/selectors';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import settingApis from 'features/settingService/services/apis';
import { IBodyUpdateEnableBookingCheckInItem } from 'features/settingService/services/types/api';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import shopActions from 'services/shop/actions';

const ConfigCategories = () => {
  const dispatch = useAppDispatch();
  const categories = shopSelectors.data.categories();
  const [visible, setVisible] = React.useState(false);
  const [form] = Form.useForm();
  const setLoadingPage = useSetLoadingPage();
  const handleClose = () => setVisible(false);
  const handleOpen = () => {
    form.resetFields();
    form.setFieldValue('categories', categories);
    setVisible(true);
  };

  const handleFinish = async (values: any) => {
    setVisible(false);
    setLoadingPage(true);
    const body: IBodyUpdateEnableBookingCheckInItem[] = values?.categories?.map((o: ICategoryItemData) => ({
      id: o.id,
      enableBooking: o?.enableBooking || false,
      enableCheckIn: o?.enableCheckIn || false,
    }) as IBodyUpdateEnableBookingCheckInItem);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.updateEnableBookingCheckIn(body);
      if (res.data.data) dispatch(shopActions.getNewCategories.fetch());
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return (
    <>
      <Styled block size='small' ntype='LIGHT_BLUE' height={3} onClick={handleOpen}>
        <SettingOutlined style={{ fontSize: 18 }} />
        <Text className='label'>Configuration</Text>
      </Styled>
      <Modal
        visible={visible}
        onClose={handleClose}
        modalTitle='Configuration Category'
        noneBodyStyle
        noneFooterStyle
        containerPadding={1}
        okTitle='Save'
        onSubmit={() => form.submit()}
      >
        <Form form={form} onFinish={handleFinish}>
          <Form.List name={'categories'}>
            {(fields) => {
              return <Container>
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: '15rem' }}></th>
                      <th>Booking</th>
                      <th>Check In</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ key, name }) => (
                      <tr key={key}>
                        <Form.Item name={name} noStyle>
                          <CategoryItem name={name} />
                        </Form.Item>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Container>;
            }}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default ConfigCategories;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  table {
    thead {
      th {
        text-align: right;
        padding-right: 22px;
      }
    }
    tbody {
      td {
        text-align: right;
        &.category-name {
          text-align: left;
          span {
            color: #1D2129;
            font-family: "Open Sans";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            text-transform: uppercase;
          }
        }
        .switch-row {
          display: flex;
          justify-content: right;
        }
      }
    }
  }
`;
const Styled = styled(Button)`
  .CONTENT_2 {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  .label {
    color: #1D2129;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
type CategoryItemProps = {
  value?: ICategoryItemData;
  name?: any;
};
const CategoryItem = ({ value, name }: CategoryItemProps) => {
  return (
    <>
      <td className='category-name'>
        <span>{value?.categoryName || '--'}</span>
      </td>
      <td >
        <div className='switch-row'>
          <Form.Item noStyle name={[name, 'enableBooking']}>
            <SwitchBtn />
          </Form.Item>
        </div>
      </td>
      <td>
        <div className='switch-row'>
          <Form.Item noStyle name={[name, 'enableCheckIn']}>
            <SwitchBtn />
          </Form.Item>
        </div>
      </td>
    </>
  );
};
