import { Col, Row } from 'antd';
import Text from 'components/Text';
import CouponDiscount from 'features/payment/pages/PrintPage/components/coupon/Discount';
import CouponGiftCard from 'features/payment/pages/PrintPage/components/coupon/GiftCard';
import CouponLoyaltyPoint from 'features/payment/pages/PrintPage/components/coupon/LoyaltyPoint';
import IconLogo from 'features/payment/pages/PrintPage/components/logo';
import React, { useCallback, useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { PHONE_MASKED } from 'utils/mask';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import multiCardSelectors from '../services/selectors';
import Box from 'components/Box';

type IPrintBodyProps = {
  isPrinter?: boolean;
  billRef?: React.RefObject<HTMLDivElement>
};
const ReceiptMultipleCardsPayment: React.FC<IPrintBodyProps> = ({ billRef, isPrinter }) => {
  const couponPrint = multiCardSelectors.couponPrint();
  const data = multiCardSelectors.detailPrint();
  const cardsPrint = multiCardSelectors.cardsPrint();
  const shopInfo = shopSelectors.data.shopInfo();
  const allSetting = shopSelectors.data.allSetting();

  const describePayment = useMemo(() => {

    return [
      { name: 'Sale Tax', price: data?.saleTax },
      { name: 'Use Tax', price: data?.useTax },
      { name: 'Disc. By Item', price: data?.discountItem },
      { name: 'Disc. By Ticket', price: data?.discountTicket },
    ].filter(o => !!o);
  }, [data]);


  const creditCards = useMemo(() => {
    return cardsPrint.map(creditData => ({
      id: creditData.id,
      endCode: creditData.last4,
      cardType: creditData.cardType,
      authCode: creditData.authCode,
      trans: creditData.transactionNumber,
      entryType: creditData.entryType,
      amount: creditData?.amount,
      feeCreditCard: creditData.feeCredit,
      signature: creditData.signature || '',
    }));
  }, [cardsPrint]);

  const Coupons = useCallback(() => {
    return <>
      <pre />
      <div>
        {couponPrint?.DISCOUNT && (<CouponDiscount data={couponPrint.DISCOUNT} />)}
        {couponPrint?.GIFT_CARD && (<CouponGiftCard data={couponPrint.GIFT_CARD} />)}
        {couponPrint?.LOYALTY_POINT && (<CouponLoyaltyPoint data={couponPrint.LOYALTY_POINT} />)}
      </div>
    </>;
  }, [couponPrint]);

  const TipTotalSection = () => {
    if (allSetting?.showModalTip === false) return null;
    if (data?.tip) {
      return <>
        <SpaceBetweenBox style={{ height: 'auto', alignItems: 'unset', padding: '0 1rem', }}>
          <Text printMode variant="CONTENT_2">
            Tip
          </Text>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
            <Text printMode variant="H7">
              {formatCurrency(data?.tip)}
            </Text>
          </div>
        </SpaceBetweenBox>
        <TotalBox>
          <SpaceBetweenBox style={{ height: 'auto', alignItems: 'unset', paddingBottom: '1rem' }}>
            <Text printMode variant="CONTENT_2">
              Total
            </Text>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
              <Text printMode variant="H7">
                {formatCurrency(data?.total)}
              </Text>
            </div>
          </SpaceBetweenBox>
        </TotalBox>
      </>;
    }

    return <>
      <SpaceBetweenBox style={{ height: '4rem', alignItems: 'unset', padding: '0 1rem', }}>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
          <Text printMode variant="H7">
            {formatCurrency(data?.total)}
          </Text>
          <div style={{ display: 'flex', gap: '4px', transform: 'translateY(0px)', width: '100%', justifyContent: 'flex-end', }}>
            <Text printMode variant="CONTENT_2" style={{ fontSize: 20, transform: 'translateY(2px)' }}>
              Tip
            </Text>
            <LineDot />
          </div>
        </div>
      </SpaceBetweenBox>
      <TotalBox style={{ borderBottom: 'none' }}>
        <SpaceBetweenBox style={{ height: '5rem', alignItems: 'unset', paddingBottom: '1rem' }}>
          <Text printMode variant="CONTENT_2"></Text>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
            <Text printMode variant="H7"></Text>
            <div style={{ display: 'flex', gap: '4px', transform: 'translateY(0px)', width: '100%', justifyContent: 'flex-end', }}>
              <Text printMode variant="CONTENT_2" style={{ fontSize: 20, transform: 'translateY(2px)' }}>
                Total
              </Text>
              <LineDot />
            </div>
          </div>
        </SpaceBetweenBox>
      </TotalBox>
    </>;
  };

  if (!data) return <PrintBodyStyled />;

  return (
    <>
      <PrintBodyStyled id='receipt' >
        <TicketBox ref={billRef} >
          <CountingBox>
            {allSetting?.showHeaderStaffTicket &&
              <Box highlight mb='2'>
                <Text printMode variant="CAPTION_2" className='title-highlight' textAlign='center' style={{ fontSize: 24 }}>
                  {data?.items.map(o => o.staffName).join(' / ')}
                </Text>
              </Box>}
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <IconLogo />
                <TicketCodeBox>
                  #{data.ticketNumber}
                </TicketCodeBox>
              </Col>
              <Col>
                <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '1.5rem' }}>{shopInfo?.shopName || ''}</ShopText>
                <ShopText printMode variant="CONTENT_2" className={isPrinter ? 'bold' : ''} style={{ fontSize: '0.75rem' }}>
                  {shopInfo?.address || ''}{', ' + shopInfo?.city || ''}
                </ShopText>
                <ShopText printMode variant="CONTENT_2" style={{ fontSize: '0.75rem' }}>
                  {PHONE_MASKED.resolve(shopInfo?.phone || '')}
                </ShopText>
              </Col>
            </Row>
            <Text printMode mb={0} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2" className='customer_name'>{data.customerName || 'Unknown customer'}</Text>
              <Text printMode variant="CONTENT_1" color="text_2">
                {data?.customerPoint || 0} Point{(data?.customerPoint || 0) > 1 ? 's' : ''}
              </Text>
            </SpaceBetweenBox>
            <Text printMode variant="CONTENT_1" color="text_2">
              {momentTimezone(data?.startTime).format('MM-DD-YYYY LT')} - {momentTimezone(data?.endTime).format('LT')}
            </Text>
            <Text printMode mb={0} />
            {data?.items?.map((item, i) => (
              <div key={item.staffId + data?.billId}>
                <Box display='flex'>
                  <StaffHeaderBox highlight px='1'>
                    <Text printMode variant="CONTENT_2" style={{ fontSize: '1.25rem' }} className='title-highlight'>
                      {item.staffName}
                    </Text>
                  </StaffHeaderBox>
                </Box>
                <ServiceBoxStyled>
                  {item.services.map((service) => (
                    <div key={service.itemId}>
                      <SpaceBetweenBox>
                        <Text printMode variant="CONTENT_2">
                          {service.itemName}
                        </Text>
                        <PriceBoxStyled>
                          <Text printMode variant="CONTENT_2">
                            {formatCurrency(service.price)}
                          </Text>
                        </PriceBoxStyled>
                      </SpaceBetweenBox>
                    </div>
                  ))}
                </ServiceBoxStyled>
                {(i === data?.items.length - 1) ? null : <StyledDivider mb={.3} />}
              </div>
            ))}
            <StyledDivider my={.3} />
            <PaddingBox>
              {describePayment.map((item) => (
                <SpaceBetweenBox key={item?.name}>
                  <Text printMode variant="CONTENT_2">
                    {item?.name}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(item?.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </PaddingBox>
            <StyledDivider />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2">
                Subtotal
              </Text>

              <Text printMode variant="CONTENT_2">
                {formatCurrency(data?.subTotal)}
              </Text>
            </SpaceBetweenBox>
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2">
                Card Fee
              </Text>
              <Text printMode variant="CONTENT_2">
                {formatCurrency(data.cardFee)}
              </Text>
            </SpaceBetweenBox>
          </CountingBox>

          <TipTotalSection />

          {creditCards.map((creditCard, i) => (
            <React.Fragment key={creditCard.id}>
              <CardBox>
                <SignatureBox>
                  {!creditCard?.signature && <div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                      <line x1="0.353553" y1="0.446447" x2="11.5536" y2="11.6464" stroke="black" />
                      <line x1="11.5537" y1="0.353553" x2="0.353749" y2="11.5536" stroke="black" />
                    </svg>
                  </div>}
                  {creditCard?.signature ?
                    <img className='img' src={creditCard?.signature?.includes('http') ? creditCard?.signature : 'data:image/png;base64,' + creditCard?.signature} />
                    :
                    <div className='blank' />}
                </SignatureBox>
                <Text printMode mt={0.5} variant="CONTENT_2" textAlign='center'>
                  Signature
                </Text>
              </CardBox>
              <CardBox>
                <PaddingBox>
                  <SpaceBetweenBox>
                    <Box display='flex' alignItems='center' gap='1'>
                      <Text printMode variant="CONTENT_2">
                        Credit Card
                      </Text>
                      <Box highlight><Text printMode variant='CONTENT_2' className='title-highlight'>{creditCard?.endCode || ''}</Text></Box>
                    </Box>
                    <Text printMode variant="H7">
                      {formatCurrency(creditCard?.amount)}
                    </Text>
                  </SpaceBetweenBox>
                  <Text printMode variant="CONTENT_2">
                    Card Type: {creditCard?.cardType}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    AuthCode: {creditCard?.authCode}
                  </Text>
                  <Box display='flex' alignItems='center' gap='1'>
                    <Text printMode variant="CONTENT_2">
                      Trans Number #:
                    </Text>
                    <Box highlight><Text printMode variant='CONTENT_2' className='title-highlight'>{creditCard?.trans}</Text></Box>
                  </Box>
                  <Text printMode variant="CONTENT_2">
                    Entry Type: {creditCard?.entryType}
                  </Text>
                </PaddingBox>
              </CardBox>
              <Divider mb={i === creditCards.length - 1 ? 3 : 5} />
            </React.Fragment>
          ))}

          <FinalBox border={false}>
            <Text printMode variant='CONTENT_2'>CUSTOMER COPY</Text>
            <Text printMode variant="CONTENT_2" >Thank you for using our service</Text>
          </FinalBox>
          {/* ----------------Coupon */}
          <Row align={'middle'} justify={'center'} style={{ marginBottom: '1rem' }}>
            <TicketCodeBox>
              POS Stations #{storage.station_number.get()}
            </TicketCodeBox>
          </Row>
          <Coupons />
        </TicketBox>
      </PrintBodyStyled>
    </>
  );
};

export default ReceiptMultipleCardsPayment;
const PrintBodyStyled = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  // justify-content: center;
  flex-grow: 1;
  .shop_name {
    color: black;
    text-align: right;
    font-family: 'Roboto', serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
  }
  .shop_address, .shop_phone {
    color: black;
    font-family: 'Roboto', serif;
    text-align: right;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .customer_name {
    color: black;
    font-family: 'Roboto', serif;
    font-size: 1.25rem;
  }
`;
const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 25.5rem;
  height: fit-content;
`;

const CountingBox = styled.div`
  padding: 1rem;
  border-bottom: 1px solid black;
`;

const TicketCodeBox = styled.div`
  border-radius: 5px;
  background: black;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const SpaceBetweenBox = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StaffHeaderBox = styled(Box)`
  border-bottom: 1px solid black;
  color: black;
  font-family: 'Roboto', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
const ShopText = styled(Text)`
  color: black;
  font-style: normal;
  font-family: 'Roboto', serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: right;

  &.bold {
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const ServiceBoxStyled = styled.div`
  padding: 0;
`;
const StyledDivider = styled(Text)`
  border-bottom: 1px solid black;
`;
const LineDot = styled(Text)`
border-bottom: 2px solid black;
/* border-bottom-style: dotted; */
width:50%;
`;

const PaddingBox = styled.div<{ borderDot?: boolean }>`
  padding: 0 0;
  ${({ borderDot }) => borderDot && `
    border-bottom: 1px solid black;
    // border-bottom-style: dotted;
  `}
`;
const TotalBox = styled.div`
  padding: 0 1rem;
  /* border-bottom-style: dotted; */
`;
const Divider = styled(Text)`
  border-bottom: 1px solid black;
  /* border-bottom-style: dotted; */
`;
const CardBox = styled.div`
  padding: 0 1rem;
`;

const SignatureBox = styled.div`
  padding: 0.5rem 0;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: center;
  .img {
    width: 100%;
    height: 140px;
    margin: auto;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    background: transparent;
    margin-top: -16px;
  }
  .blank {
    width: 100%;
    height: 4.5rem;
  }
  align-items: end;
`;

const FinalBox = styled.div<{ border?: boolean }>`
  padding: 0.5rem 0 2rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: 400;
  .bold {
    font-weight: 600;
  }
  ${({ border }) => border && `
    margin-top: 1rem;
    padding-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    border-top: 1px solid black;
  `}
`;
