import moment from 'moment';
import React from 'react';
import { styled } from 'styled-components';

interface ITicketHeaderProps {
  ticketId: string;
}
const TicketHeader: React.FC<ITicketHeaderProps> = ({ ticketId }) => {
  const now = moment();
  return (
    <TicketHeaderStyled>
      <div className="date-time">
        <div>{now.format('MM-DD-YYYY')}</div>
        <div>{now.format('LT')}</div>
      </div>
      {ticketId && <div className="ticket-id">{`#${ticketId}`}</div>}
    </TicketHeaderStyled>
  );
};
export default TicketHeader;

const TicketHeaderStyled = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .date-time {
        color: #1D2129;
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal; 
        font-weight: 400;
        line-height: normal;
        display: flex;
        flex-direction: column;
    }
    .ticket-id {
        border-radius: 5px;
        background: #1D2129;
        padding: 0 0.5rem;
        color: var(--text-text-1, #FFF);
        font-family: Open Sans;
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
`;
