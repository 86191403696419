import Icon, { typeIcon } from 'assets/Icons';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import React from 'react';
import styled from 'styled-components';
type IComponentProps = {
  icon: typeIcon;
  content: string;
  nbgcolor: string
  textColor?: string
  border_color: string
  border_color2?: string
  onClick?: () => void;
  active?: boolean;
  v2?: boolean;
};
type ComponentStyledProps = {
  nbgcolor: string;
  border_color: string;
  active?: boolean;
  border_color2?: string;
};
const CardTicketItem: React.FC<IComponentProps> = ({
  icon,
  content,
  textColor,
  onClick,
  v2,
  ...props
}) => {

  if (v2) {
    return (
      <ComponentV2Styled {...props} onClick={onClick}>
        <div className="box-container">
          <Icon type={icon} />
          <Text variant="H8" className='text-content' style={{ fontSize: '1.125rem', color: props.active ? '#fff' : '#1D2129' }}>
            {content}
          </Text>
        </div>
      </ComponentV2Styled>
    );
  }

  return (
    <ComponentStyled {...props} onClick={onClick}>
      <Icon type={icon} />
      <Text variant="H8" className='text-content' style={{ fontSize: '1.125rem' }} color={textColor as keyof typeof colorTheme}>
        {content}
      </Text>
    </ComponentStyled>
  );
};

export default CardTicketItem;
const ComponentStyled = styled.button.withConfig({
  shouldForwardProp: (propName) => !['nbgcolor', ['border_color']].includes(propName)
}) <ComponentStyledProps>`
  height: 6.75rem;
  width: 100%;
  border-radius: 10px;

  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: 1.5px solid ${({ border_color }) => border_color};
  background: ${({ nbgcolor }) => nbgcolor};
  position: relative;
  ${({ active, border_color }) => active && `
    background:${border_color};
    &::after {
      border-radius: 10px;
      position: absolute;
      inset: -7px;
      bottom: -9px;
      z-index: 99;
      content: '';
      border: 1.5px solid ${border_color};
    }
  `}
  .text-content {
    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

const ComponentV2Styled = styled.button<ComponentStyledProps>`
display: flex;
height: 110px;
flex: 1;
padding: 4px;
justify-content: space-between;
align-items: center;
border-radius: 5px;
border: 1.5px solid transparent;
.box-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 5px;
  border: 1.5px solid #1D2129;
  background: #FFF;
  .text-content {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

${({ active, border_color, nbgcolor, border_color2 }) => active && `
    border: 1.5px solid ${border_color2};
    .box-container {
      background: ${nbgcolor};  
      border: 1.5px solid ${border_color};
    }
  `}
`;
