import React from 'react';
import styled from 'styled-components';
import TurnBox from './TurnBox';
import { TurnStatus } from '../../constanst';
import { TURN_ITEM_TYPE } from 'features/turn/services/constants';
type Props = {
  value?: string;
  onChange?: (value: string) => void;
  type?: keyof typeof TURN_ITEM_TYPE;
};
const TurnStatusUI = ({ type, value, onChange = () => undefined }: Props) => {
  const handleOnClick = (_value: string) => () => {
    onChange(_value);
  };
  return (
    <Container>
      <TurnBox type={type} onClick={handleOnClick(TurnStatus.IN_PROGRESS)} active={value === TurnStatus.IN_PROGRESS} statusBox label='IN - PROGRESS' />
      <TurnBox type={type} onClick={handleOnClick(TurnStatus.DONE)} active={value === TurnStatus.DONE} statusBox label='DONE' />
      <div style={{ flex: 1 }}></div>
      <div style={{ flex: 1 }}></div>
    </Container>
  );
};

export default TurnStatusUI;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;
