import { AxiosResponse } from 'axios';
import { IStaffPayload } from 'features/checkIn/services/types/demoData';
import { call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading, setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import {
  IStaffDayOffParams,
  IStaffsParams,
  IStaffUpdatePinBodyType,
  IUpdateStaffAvatar,
} from './types/api';
import { getStaffDayOffParams, getStaffsFilterParams } from './selectors';
import {
  ISalaryConfig,
  IStaffInfo,
  IStaffOffDay,
  IStaffScheduler,
  IStaffServices,
} from './types/staff';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import toast from 'utils/toast';
import shopActions from 'services/shop/actions';

const staffDetails: ISagaFunc<string> = function* ({ payload }) {
  const staffId: string = payload;
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resStaff: AxiosResponse<{
      data: {
        staffInfo: IStaffInfo;
        salaryConfig: ISalaryConfig;
        mappingService: IStaffServices[];
      };
    }> = yield call(apis.getDetailsStaff, staffId);
    if (resStaff?.data?.data?.staffInfo) {
      yield put(
        actions.getSelectedStaffInfo.success(resStaff.data.data.staffInfo)
      );
      yield put(
        actions.getSelectedStaffSalary.success(resStaff.data.data.salaryConfig)
      );
      yield put(
        actions.getSelectedStaffServices.success(
          resStaff.data.data.mappingService
        )
      );
    }
  } catch (error) {
    yield put(actions.getSelectedStaffInfo.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const cateSelected: ISagaFunc<string> = function* ({ payload }) {
  yield put(actions.cateSelected(payload));
};

const createOrUpdate: ISagaFunc<IStaffPayload> = function* ({ payload }) {
  yield setLoadingPage(true);
  try {
    const resData: AxiosResponse<{ data: IStaffInfo }> = yield call(
      apis.createOrUpdateStaff,
      payload
    );
    if (resData) {
      yield put(actions.createOrUpdateStaff.success(resData.data.data));
      toast.success('Successful');
    }
  } catch (error) {
    yield put(actions.createOrUpdateStaff.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const getStaffList: ISagaFunc<IStaffsParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getStaffList, true);
  yield delay(100);
  try {
    const resData: AxiosResponse<any> = yield call(apis.getStaffs, payload);
    if (resData?.data.data) {
      yield put(actions.getStaffList.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getStaffList.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getStaffList, false);
  }
};

const getStaffDayOff: ISagaFunc<IStaffDayOffParams> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<{ data: IStaffOffDay[] }> = yield call(
      apis.getStaffDayOff,
      payload
    );
    if (resData?.data.data) {
      yield put(actions.getStaffDayOff.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getStaffDayOff.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const createStaffDayOff: ISagaFunc<IStaffDayOffParams> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<any> = yield call(
      apis.createStaffDayOff,
      payload
    );
    if (resData?.data.data) {
      yield put(actions.createStaffDayOff.success(resData.data.data));
      const storeParams = yield select(getStaffDayOffParams);
      yield put(actions.getStaffDayOff.fetch(storeParams));
      toast.success('Successful');
    }
  } catch (error) {
    yield put(actions.createStaffDayOff.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const deleteStaffDayOff: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<any> = yield call(
      apis.deleteStaffDayOff,
      payload
    );
    if (resData?.data.data) {
      yield put(actions.deleteStaffDayOff.success(resData.data.data));
      const storeParams = yield select(getStaffDayOffParams);
      yield put(actions.getStaffDayOff.fetch(storeParams));
      toast.success('Successful');
    }
  } catch (error) {
    yield put(actions.deleteStaffDayOff.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const setStaffDayOffParams: ISagaFunc<IStaffDayOffParams> = function* ({
  payload,
}) {
  const storeParams = yield select(getStaffDayOffParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getStaffDayOff.fetch(_params));
};

const staffUpdatePin: ISagaFunc<IStaffUpdatePinBodyType> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<any> = yield call(
      apis.staffUpdatePin,
      payload
    );
    if (resData?.data.data) {
      yield put(actions.staffUpdatePin.success(resData.data.data));
      toast.success('Successful');
    }
  } catch (error) {
    yield put(actions.staffUpdatePin.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const staffUpdateMappingService: ISagaFunc<IStaffUpdatePinBodyType> =
  function* ({ payload }) {
    yield setLoadingPage(true);
    yield delay(100);
    try {
      const resData: AxiosResponse<any> = yield call(
        apis.staffUpdateMappingService,
        payload
      );
      if (resData?.data.data) {
        yield put(actions.staffUpdateMappingService.success(resData.data.data));
      }
    } catch (error) {
      yield put(actions.staffUpdateMappingService.fail({}));
    } finally {
      yield setLoadingPage(false);
    }
  };
const staffUpdateSalary: ISagaFunc<ISalaryConfig> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<any> = yield call(
      apis.staffUpdateSalary,
      payload
    );
    if (resData?.data.data) {
      yield put(actions.staffUpdateSalary.success(resData.data.data));
      toast.success('Successful');
    }
  } catch (error) {
    yield put(actions.staffUpdateSalary.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const deleteStaff: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<any> = yield call(
      apis.deleteStaff,
      payload
    );
    if (resData?.data.data) {
      toast.success('Deleted successful');
      yield put(shopActions.get.newStaffs());
      const params = {
        page: 1,
        size: 20,
        ignoreAnyBody: true,
      };
      yield put(actions.setParams(params));
    }
  } catch (error) {
  } finally {
    yield setLoadingPage(false);
  }
};
const staffScheduler: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<{ data: IStaffScheduler }> = yield call(
      apis.staffScheduler,
      payload
    );
    if (resData?.data.data) {
      yield put(actions.staffScheduler.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.staffScheduler.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const updateStaffSchedule: ISagaFunc<IStaffScheduler> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.updateStaffSchedule,
      payload
    );
    if (resData?.data.data) {
      yield put(actions.updateStaffScheduler.success(resData.data.data));
      toast.success('Successful');
    }
  } catch (error) {
    yield put(actions.updateStaffScheduler.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const updateStaffAvatar: ISagaFunc<IUpdateStaffAvatar> = function* ({
  payload,
}) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const base64Url = removeBase64Prefix(payload.avatar);
    const convertUrlPayload = { ...payload, avatar: base64Url };
    const resData: AxiosResponse<{ data: any }> = yield call(
      apis.updateStaffAvatar,
      convertUrlPayload
    );
    if (resData?.data.data) {
      yield put(actions.updateStaffAvatar.success(payload));
      toast.success('Successful');
    }
  } catch (error) {
    yield put(actions.updateStaffAvatar.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const setStaffsParams: ISagaFunc<IStaffsParams> = function* ({ payload }) {
  const storeParams = yield select(getStaffsFilterParams);
  const _params = {
    ...(storeParams ?? {}),
    ...(payload ?? {}),
  };
  yield put(actions.getStaffList.fetch(_params));
};

export default function* settingStaffSagas() {
  yield takeLatest(actions.getSelectedStaffInfo.fetch, staffDetails);
  yield takeLatest(actions.cateSelected, cateSelected);
  yield takeLatest(actions.deleteStaff, deleteStaff);
  yield takeLatest(actions.createOrUpdateStaff.fetch, createOrUpdate);
  yield takeLatest(actions.getStaffList.fetch, getStaffList);
  yield takeLatest(actions.setParams, setStaffsParams);
  yield takeLatest(actions.staffUpdatePin.fetch, staffUpdatePin);
  yield takeLatest(actions.staffUpdateSalary.fetch, staffUpdateSalary);
  yield takeLatest(actions.staffScheduler.fetch, staffScheduler);
  yield takeLatest(actions.getStaffDayOff.fetch, getStaffDayOff);
  yield takeLatest(actions.createStaffDayOff.fetch, createStaffDayOff);
  yield takeLatest(actions.deleteStaffDayOff.fetch, deleteStaffDayOff);
  yield takeLatest(actions.setStaffDayOffParams, setStaffDayOffParams);
  yield takeLatest(actions.updateStaffScheduler.fetch, updateStaffSchedule);
  yield takeLatest(actions.updateStaffAvatar.fetch, updateStaffAvatar);
  yield takeLatest(
    actions.staffUpdateMappingService.fetch,
    staffUpdateMappingService
  );
}
