import React, { useState } from 'react';
import zoomIn from './zoom-in.svg';
import zoomOut from './zoom-out.svg';
import styled from 'styled-components';
import { get } from 'lodash';
const zoomLevel = {
  1: 100,
  2: 90,
  3: 80,
  4: 75,
  5: 67,
};

const ZoomInOut = () => {
  const [zoomValue, setZoomValue] = useState(1);
  const zoom = (type: 'in' | 'out') => () => {
    let nextZoomValue = zoomValue;
    if (type === 'out') {
      nextZoomValue = zoomValue - 1;
      if (nextZoomValue < 1) return;
    } else {
      nextZoomValue = zoomValue + 1;
      if (nextZoomValue > 5) return;
    }
    const value = get(zoomLevel, [nextZoomValue], 100) ;
    setZoomValue(nextZoomValue);
    // @ts-ignore
    document.body.style.zoom = `${value}%`;
  };

  return (
    <Container>
      <button onClick={zoom('in')} type='button' className="btn btn-zoom"><img src={zoomOut} alt="zoomOut" /></button>
      <button onClick={zoom('out')} type='button' className="btn btn-zoom"><img src={zoomIn} alt="zoomIn" /></button>
    </Container>
  );
};

export default ZoomInOut;
const Container = styled.div`
display: flex;
align-items: center;
gap: 8px;
.btn.btn-zoom {
  height: 50px;
  width: 50px;
  border-radius: 40px;
  border: 2px solid #CCD4DC;
  background: #fff;
  img{
    height: 100%;
    width: 100%;
  }
}
`;
