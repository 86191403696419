import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import { renderTime } from '../utils';
import { IParamItemType } from 'features/report/services/types/reportStaff';
import { formatCurrency } from 'utils/formatCurrency';
import reportSelectors from 'features/report/services/selectors';
import shopSelectors from 'services/shop/selectors';
type Props = {
  divRef: React.RefObject<HTMLDivElement>;
  dataAppointment: IParamItemType | undefined;
};
const PaymentTypesUIPrinter = ({ divRef, dataAppointment }: Props) => {
  const salon = shopSelectors.data.shopInfo();
  const data = reportSelectors.getDataPrintSummaryReportPaymentTypes();
  return (
    <div ref={divRef}>
      <Box className="center" overflow="auto">
        <TicketBox>
          <LogoBox>
            <IconLogo />
          </LogoBox>
          <Box className="center">
            <Text printMode variant="CONTENT_2" color="text_2">
              {salon?.shopName || '--'}
            </Text>
          </Box>
          <Box className="center">
            <Text printMode variant="CONTENT_2" color="text_2">
              {renderTime(dataAppointment)}
            </Text>
          </Box>
          <CrossSales>
            {data.map((o, i) => (
              <div key={i}>
                <Box bb="print_line" highlight>
                  <Text textAlign='center' printMode variant="CAPTION_2" color="text_3" className='title-highlight' style={{ fontSize: 16 }}>
                    {o.staffName}
                  </Text>
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box className="space-between">
                    <Text printMode variant="CAPTION_2" color="text_3">
                      Cash
                    </Text>
                    <Text printMode variant="CAPTION_2" color="text_3">
                      {formatCurrency(o?.cashAmount)}
                    </Text>
                  </Box>
                  <Box className="space-between">
                    <Text printMode variant="CAPTION_2" color="text_3">
                      Credit Card
                    </Text>
                    <Text printMode variant="CAPTION_2" color="text_3">
                      {formatCurrency(o?.creditAmount)}
                    </Text>
                  </Box>
                  <Box className="space-between">
                    <Text printMode variant="CAPTION_2" color="text_3">
                      Gift Card
                    </Text>
                    <Text printMode variant="CAPTION_2" color="text_3">
                      {formatCurrency(o?.giftCardAmount)}
                    </Text>
                  </Box>
                  <Box className="space-between">
                    <Text printMode variant="CAPTION_2" color="text_3">
                      Checks
                    </Text>
                    <Text printMode variant="CAPTION_2" color="text_3">
                      {formatCurrency(o?.checkAmount)}
                    </Text>
                  </Box>
                  <Box display="flex" flexDirection="column">
                    <Box className="space-between">
                      <Text printMode variant="CAPTION_2" color="text_3">
                        Other(s)
                      </Text>
                      <Text printMode variant="CAPTION_2" color="text_3">
                        {formatCurrency(o?.otherAmount)}
                      </Text>
                    </Box>
                  </Box>
                </Box>
                <div className="dashed-line"></div>
              </div>
            ))}

          </CrossSales>
        </TicketBox>
      </Box>
    </div>
  );
};

export default PaymentTypesUIPrinter;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 19.5rem;
  height: fit-content;
  padding: 1.5rem 0 2rem;
  .dashed-line {
  margin-top: 8px;
    border-top: 2px dashed #000;
    padding-bottom: 8px;
  }
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const CrossSales = styled.div`
/* 5px 4px <-- Chỉnh khoảng cách viền bill ở đây  */
  padding: 5px 4px; 
`;