import Modal from 'components/Modal';
import { colorSetCodes } from 'features/turn/services/constants';
import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
type Props = {};
type Ref = {
  open: (content: any, cb: (value: string) => void) => void;
  close: () => void;
};

export const useModalChangeColorRef = () => useRef<Ref>(null);
const ModalChangeColor = forwardRef<Ref, Props>(({ }, ref) => {
  const [open, setOpen] = useState(false);
  const contents = useRef<any>(null);
  const cbRef = useRef<(value: string) => void>(() => undefined);

  const handleOpen: Ref['open'] = (content, cb) => {
    setOpen(true);
    cbRef.current = cb;
    contents.current = content;
  };

  const handleClose: Ref['close'] = () => {
    setOpen(false);
  };

  const handleChooseColor = (value: string) => () => {
    cbRef.current(value);
    handleClose();
  };

  const mappingColors = useMemo(() => {
    return Object.entries(colorSetCodes).map(([key, theme]) => ({
      value: key,
      theme,
    }));
  }, [colorSetCodes]);

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      width={'auto'}
      visible={open} onClose={handleClose}
      modalTitle='COLORS'
      headerBgColor='#FFEAF6'
      noneBodyStyle noneFooterStyle
      footer={null}
      showClose
    >
      <Container>
        <div className="content-container">
          {mappingColors.map(o => <div
            key={o.value}
            onClick={handleChooseColor(o.value)}
            className='box-item' style={{
              background: o.theme?.bg,
              boxShadow: `4px 4px 0px 0px ${o.theme?.shadow}`,
              border: `1px solid ${o.theme?.stroke}`,
            }} >{contents.current}</div>)}
        </div>
      </Container>
    </Modal>
  );
});

ModalChangeColor.displayName = 'ModalChangeColor';
export default ModalChangeColor;

const Container = styled.div`
display: flex;
padding-top: 12px;
flex-direction: column;
align-items: flex-start;
  padding: 16px;

align-self: stretch;
background: #FFF;

.content-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  gap: 16px;
}
  
.box-item {
    height: 120px;
    width: 120px;
    border-radius: 8px;
    border: 1px solid #CCD4DC;
    background: #F0F0F0;
    display: flex;
    align-items: center;
    justify-content: center;
    .service-text {
      white-space: nowrap;
      position: relative;
      color: #1D2129;
      font-family: Poppins;
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    
  } 
`;

