import { Avatar, Form, Spin } from 'antd';
import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import TurnAmount from './TurnAmount';
import TurnType from './TurnType';
import ModalChooseStaffRef, { useModalChooseStaffRef } from 'widgets/Staff/ModalChooseStaffRef';
import { ITurnManualItem } from 'features/turn/services/types/turn';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { TurnStatus } from '../../constanst';
import { IResponseDataBody } from 'services/response';
import turnApis from 'features/turn/services/apis';
import { IApiUpdateManualTurnBody } from 'features/turn/services/types/api';
import { turnManualAPIActions } from 'features/turn/services/actions';
import { useAppDispatch } from 'store/hooks';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import { useNavigate, useParams } from 'react-router-dom';

export interface IFormValues {
  turnId: string;
  staffId: string;
  staffAvatar: string;
  staffName: string;
  turn: number;
  type: string;
  status: string;
}

type Props = {};
type Ref = {
  open: (staff: IStaffAvailableItem, existTurn: ITurnManualItem) => void;
  close: () => void;
};
export const turnDetailRef = React.createRef<Ref>();
const ModalTurnDetail = forwardRef<Ref, Props>(({ }, ref) => {
  const { shop_id = '' } = useParams();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const staffRef = useModalChooseStaffRef();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const modalConfirm = useModalConfirmRef();
  const navigate = useNavigate();

  const handleOpen: Ref['open'] = (staff, existTurn) => {
    if (!existTurn) return;
    setOpen(true);
    form.setFieldsValue({
      billId: existTurn.billId,
      turnId: existTurn.turnId,
      staffId: existTurn.staffId,
      staffAvatar: staff.staffAvatar,
      staffName: staff.staffName,
      done: existTurn.status === TurnStatus.DONE,
      status: existTurn.status,
      turn: existTurn.turn,
      type: existTurn.type.replace('-', '_'),
    });
  };

  const handleClose: Ref['close'] = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleChangeStaff = () => staffRef.current?.open((staff) => {
    form.setFieldsValue({
      staffId: staff.id,
      staffAvatar: staff.urlImage,
      staffName: staff.firstName,
    });
  });

  const getTurnAmountString = (turn: number) => {
    if (turn == 1) return 'FULL';
    if (turn == 0.5) return 'HALF';
    if (turn == 0) return 'NO_TURN';
    return '';
  };

  const putAction = async (body: IApiUpdateManualTurnBody) => {
    setLoading(true);
    try {
      const res: IResponseDataBody<any> = await turnApis.updateManualTurn(body);
      if (res.data.data) {
        handleClose();
        dispatch(turnManualAPIActions.getTurns.fetch());
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = async (values: any) => {
    const data = values as IFormValues;
    const body: IApiUpdateManualTurnBody = {
      staffId: data.staffId,
      turn: getTurnAmountString(data.turn),
      turnId: data.turnId,
      type: data.type,
      turnStatus: data.status,
    };
    await putAction(body);
  };

  const handleDoneTicket = async () => {
    const data = form.getFieldsValue() as IFormValues;
    const body: IApiUpdateManualTurnBody = {
      staffId: data.staffId,
      turn: getTurnAmountString(data.turn),
      turnId: data.turnId,
      type: data.type,
      turnStatus: TurnStatus.DONE,
    };
    await putAction(body);
  };

  const handleDeleteTicket = () => {
    const data = form.getFieldsValue() as IFormValues;
    if (!data.turnId) return;
    modalConfirm.current?.open('Are you about to delete this turn?', async () => {
      setLoading(true);
      try {
        const res: IResponseDataBody<boolean> = await turnApis.deleteManualTurn(data.turnId);
        if (res.data.data) {
          handleClose();
          dispatch(turnManualAPIActions.getTurns.fetch());
        }
      } catch (error) { }
      finally {
        setLoading(false);
      }
    });
  };

  const handlePay = () => {
    const billId = form.getFieldValue('billId') as string;
    if (!billId) return;
    navigate(`/store/${shop_id}/ticket/edit/${billId}`);
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      width={900}
      visible={open} onClose={handleClose}
      hiddenHeader
      noneBodyStyle noneFooterStyle
      footer={null}
    >
      <Spin spinning={loading}>
        <Container form={form} onFinish={handleUpdate}>
          <Header>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const staffId = getFieldValue('staffId');
                const staffAvatar = getFieldValue('staffAvatar');
                const staffName = getFieldValue('staffName');
                const done = getFieldValue('done');
                return (
                  <>
                    <div className="staff-container" key={staffId}>
                      <Avatar src={staffAvatar} size={56}>{staffName?.[0] || 'A'}</Avatar>
                      <span>{staffName}</span>
                    </div>
                    {!done && <button type='button' className="btn btn-change" onClick={handleChangeStaff}>
                      {iconChange}
                    </button>}
                  </>
                );
              }}
            </Form.Item>
          </Header>
          <Content>
            <Form.Item noStyle name={'billId'}><input hidden /></Form.Item>
            <Form.Item noStyle name={'turnId'}><input hidden /></Form.Item>
            <Form.Item noStyle name={'staffId'}><input hidden /></Form.Item>
            <Form.Item noStyle name={'staffAvatar'}><input hidden /></Form.Item>
            <Form.Item noStyle name={'staffName'}><input hidden /></Form.Item>
            <Form.Item noStyle name={'status'}><input hidden /></Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const done = getFieldValue('done');
                return done ? <div className="status-title DONE">Done</div> : <div className="status-title IN_PROGRESS">Working In Progress ...</div>;
              }}
            </Form.Item>
            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => {
                const done = getFieldValue('done');
                return <>
                  <div className="content-item">
                    <div className="title-section">TURN</div>
                    <Form.Item noStyle name={'turn'}>
                      <TurnAmount disabled={done} />
                    </Form.Item>
                  </div>
                  <div className="content-item">
                    <div className="title-section">SELECT TURN TYPE</div>
                    <Form.Item noStyle name={'type'}>
                      <TurnType disabled={done} />
                    </Form.Item>
                  </div>
                </>;
              }}
            </Form.Item>

          </Content>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const done = getFieldValue('done');
              return (done ?
                <Footer>
                  <button onClick={handleClose} type='button' className="btn btn-close"><span>Close</span></button>
                  <button onClick={handleDeleteTicket} type='button' className="btn btn-delete"><span>Delete</span></button>
                  <button onClick={handlePay} type='button' className="btn btn-submit"><span>Pay</span></button>
                </Footer>
                :
                <Footer>
                  <button onClick={handleClose} type='button' className="btn btn-close"><span>Close</span></button>
                  <button type='button' onClick={handleDeleteTicket} className="btn btn-delete"><span>Delete</span></button>
                  <button type='button' onClick={() => form.submit()} className="btn btn-update"><span>Update</span></button>
                  <button type='button' onClick={handleDoneTicket} className="btn btn-submit"><span>Done Turn</span></button>
                </Footer>
              );
            }}
          </Form.Item>

        </Container>
      </Spin>
      <ModalConfirm ref={modalConfirm} />
      <ModalChooseStaffRef ref={staffRef} />
    </Modal>
  );
});
ModalTurnDetail.displayName = 'ModalTurnDetail';
export default ModalTurnDetail;

const Container = styled(Form)`
display: flex;
flex-direction: column;
border-radius: 5px;
align-items: flex-start;
gap: 0px;
align-self: stretch;
background: #FFF;
`;

const Header = styled.div`
display: flex;
height: 67px;
align-items: center;
gap: 16px;
align-self: stretch;
justify-content: center;
border-radius: 5px 5px 0px 0px;
background: #FFEAF6;
.staff-container {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
.btn.btn-change {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
`;

const Content = styled.div`
display: flex;
padding: 0px 24px;
flex-direction: column;
align-items: center;
align-self: stretch;
padding-top: 16px;
margin-bottom: 20px;

.status-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  color: #FF7D00;
  text-align: center;
  text-overflow: ellipsis;

  /* Title/H5 - 24 semibold */
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.IN_PROGRESS {
    color: #FF7D00;
  }
  &.DONE {
    color: #00AD45;
  }
}

.content-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 12px;
  margin-bottom: 32px;
  .title-section {
    color: #505050;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
}
`;

const Footer = styled.div`
display: flex;
padding: 24px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.25);
button.btn {
  display: flex;
  height: 56px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #86909C;
  background: #E5E6EB;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: uppercase;
  }

  &.btn-delete {
    border: 1px solid #F53F3F;
    background: #FFF;
    span {
      color: #F53F3F;
    }
  }
  &.btn-update {
    border: 1px solid #2D6772;
    background: #FFF;
    span {
      color: #2D6772;
    }
  }
  &.btn-submit {
    border-radius: 5px;
    background: #00AD45;
    border: 1px solid #00AD45;
    span {
      color: #fff;
    }
  }
}
`;

const iconChange = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26.6156 14.8638C26.5564 14.3789 26.1306 14.036 25.6339 14.0833C25.1489 14.1424 24.7941 14.58 24.8532 15.0649C25.1489 17.6667 24.25 20.2093 22.393 22.066C19.294 25.1645 14.3972 25.3892 11.038 22.7401H12.1498C12.6348 22.7401 13.0369 22.338 13.0369 21.8532C13.0369 21.3683 12.6348 20.9662 12.1498 20.9662H8.80247C8.31752 20.9662 7.91536 21.3683 7.91536 21.8532V25.2C7.91536 25.6849 8.31752 26.087 8.80247 26.087C9.28742 26.087 9.68958 25.6849 9.68958 25.2V23.9346C11.6057 25.543 13.9595 26.3472 16.3251 26.3472C18.9746 26.3472 21.6241 25.3419 23.6467 23.3196C25.8822 21.0845 26.9586 17.9978 26.6156 14.8638ZM10.2692 9.93225C13.3681 6.83375 18.2649 6.60906 21.6241 9.25815H20.5123C20.0273 9.25815 19.6252 9.66024 19.6252 10.1451C19.6252 10.63 20.0273 11.0321 20.5123 11.0321H23.8596C24.3446 11.0321 24.7467 10.63 24.7467 10.1451V6.79828C24.7467 6.3134 24.3446 5.9113 23.8596 5.9113C23.3747 5.9113 22.9725 6.3134 22.9725 6.79828V8.06369C18.9155 4.65771 12.8359 4.85876 9.01538 8.67866C6.77987 10.9138 5.70351 14.0005 6.04653 17.1345C6.09384 17.5839 6.48416 17.9268 6.9218 17.9268C6.95729 17.9268 6.99277 17.9268 7.01643 17.9268C7.50138 17.8677 7.85622 17.4301 7.79708 16.9452C7.50138 14.3435 8.40032 11.8008 10.2573 9.94407L10.2692 9.93225Z" fill="#1D2129" />
</svg>
  ;
