import { DatePicker as AntDatePickerUI, Row } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { PickerDateProps } from 'antd/es/date-picker/generatePicker';
import Text from 'components/Text';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT, DATE_FORMAT_VIEW } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import WeeklyPicker from './WeeklyPicker';
import calendar_icon from './calendar.svg';
import clsx from 'clsx';

const AntDatePicker = AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);
const getStartDate = (date: Moment | null | undefined, v2?: boolean) => {
    if (!v2) return date?.format(DATE_FORMAT_VIEW);
    const now = moment();
    if (date?.isSame(now, 'date')) {
        return `Today, ${date?.format('MM-DD-YYYY')}`;
    }
    if (date?.isSame(now.subtract(1, 'day'), 'date')) {
        return `Yesterday, ${date?.format('MM-DD-YYYY')}`;
    }
    return date?.format('ddd, MM-DD-YYYY');
};
type IDatePickerProps = PickerDateProps<Moment> & {
    actions: any,
    selectors: any,
    type: string
    size?: SizeType,
    onChangeValue?: (data: {
        startTime: string,
        endTime: string,
    }) => void;
    popupClassName?: string;
    v2?: boolean;
};
const AppointmentDatePicker: React.FC<IDatePickerProps> = ({
    actions,
    selectors,
    type,
    size = 'small',
    onChangeValue = () => undefined,
    popupClassName,
    v2,
}) => {
    const dispatch = useAppDispatch();
    const viewType = selectors.getViewType()?.[type] || CALENDAR_VIEW_TYPE.DAY_VIEW;
    const params = selectors.getParams()?.[type];

    const startDate = useMemo(() => {
        return params?.startTime ? moment(params?.startTime, DATE_FORMAT) : null;
    }, [params]);

    const endDate = useMemo(() => {
        return params?.endTime ? moment(params?.endTime, DATE_FORMAT) : null;
    }, [params]);


    if (viewType === CALENDAR_VIEW_TYPE.DAY_VIEW) {
        const onChange: IDatePickerProps['onChange'] = (date) => {
            if (!date) return;
            dispatch(actions.setParams({
                [type]: {
                    startTime: date?.format(DATE_FORMAT),
                    endTime: date?.format(DATE_FORMAT),
                }
            }));
            onChangeValue({
                startTime: date?.format(DATE_FORMAT),
                endTime: date?.format(DATE_FORMAT),
            });
        };

        return (
            <Row>
                <DatePickerStyled
                    onChange={onChange}
                    size={size}
                    inputReadOnly
                    clearIcon={false}
                    value={startDate}
                    suffixIcon={v2 ? false : undefined}
                    className={v2 ? 'v2' : ''}
                    popupClassName={clsx(popupClassName, v2 && 'zoom-big-v2')}
                    inputRender={(props: any) => (
                        <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
                            {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
                            <Text variant='CONTENT_2' color='text_3' >{getStartDate(startDate, v2)}</Text>
                            {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
                        </InputUI>
                    )}
                />
            </Row>
        );
    }

    if (viewType === CALENDAR_VIEW_TYPE.TWO_WEEKS || viewType === CALENDAR_VIEW_TYPE.WEEK_VIEW) {

        const onChange = (values: RangeValue<Moment>) => {
            if (!values) return;
            dispatch(actions.setParams({
                [type]: {
                    startTime: values?.[0]?.format(DATE_FORMAT),
                    endTime: values?.[1]?.format(DATE_FORMAT),
                }
            }));
            onChangeValue({
                startTime: values?.[0]?.format(DATE_FORMAT) || '',
                endTime: values?.[1]?.format(DATE_FORMAT) || '',
            });
        };

        return (
            <WeeklyPicker v2={v2} popupClassName={popupClassName} key={viewType} onSubmit={onChange} defaultValue={[startDate, endDate]} />
        );
    }

    if (viewType === CALENDAR_VIEW_TYPE.MONTH_VIEW) {
        const onChange: IDatePickerProps['onChange'] = (date) => {
            if (!date) return;
            dispatch(actions.setParams({
                [type]: {
                    startTime: date?.startOf('month').format(DATE_FORMAT),
                    endTime: date?.endOf('month').format(DATE_FORMAT),
                }
            }));
            onChangeValue({
                startTime: date?.startOf('month').format(DATE_FORMAT) || '',
                endTime: date?.endOf('month').format(DATE_FORMAT) || '',
            });
        };
        return (
            <Row>
                <DatePickerStyled
                    clearIcon={false}
                    inputReadOnly
                    value={startDate}
                    onChange={onChange}
                    picker="month"
                    suffixIcon={v2 ? false : undefined}
                    className={v2 ? 'v2' : ''}
                    popupClassName={clsx(popupClassName, v2 && 'zoom-big-v2')}
                    inputRender={(props: any) => (
                        <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
                            {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
                            <Text variant='CONTENT_2' color='text_3' >{startDate?.format(v2 ? 'MMMM, YYYY' : 'MM/YYYY')}</Text>
                            {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
                        </InputUI>
                    )}
                    size={v2 ? 'small' : size}
                />
            </Row>
        );
    }

    return null;
};

export default AppointmentDatePicker;
type DatePickerStyledProps = {}
const DatePickerStyled = styled(AntDatePicker) <DatePickerStyledProps>`
  min-width: 20rem;

  &.v2 {
    min-width: unset;
    border: none;
    background: transparent;
    .InputUI {
        gap: 16px;
        .CONTENT_2 {
            color: #1D2129;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
  }
`;

const InputUI = styled(Row)`
flex: 1;
padding: 0.5rem;
margin-right: 0.5rem;
-webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
