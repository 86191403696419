import React from 'react';
import styled from 'styled-components';
import googleLogo from './googleLogo.png';
import nfcLogo from './nfc.png';
import { QRCode } from 'antd';
import checkInSelectors from 'features/checkIn/services/selectors';

const RightContent = () => {
  const googleReview = checkInSelectors.googleReview();

  return (
    <RightContainer>
      <img src={nfcLogo} alt="nfc-logo" className="nfc-logo" />
      <img src={googleLogo} alt="google-logo" className="google-logo" />
      <QRCode
        size={300}
        value={googleReview?.urlReview || 'https://fozito.com/'}
      />
    </RightContainer>
  );
};

export default RightContent;
const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    align-items: center;
    justify-content: center;
    width: 45.5vw;
    height: 100vh;
    border-radius: 70px 0px 0px 70px;
    background: #FFF;
    box-shadow: -4px 4px 10px 0px rgba(0, 0, 0, 0.10) inset, 0px 10px 20px 0px rgba(0, 0, 0, 0.25);

    .nfc-logo {
        width: 148px;
      height: 148px;
      margin-bottom: 32px;
    }
    .google-logo {
      width: 325px;
      height: 110px;
    }
`;
