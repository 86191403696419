import { Avatar, DatePicker, Form, Input, Radio, Row } from 'antd';
import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import appointmentActions from 'features/appointment/services/actions';
import appointmentApis from 'features/appointment/services/apis';
import { CALENDAR_TIME_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { IBodyApiMakeLockTime } from 'features/appointment/services/types/api';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { get, set } from 'lodash';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { RangeValue } from 'rc-picker/lib/interface';
import { memo, useCallback, useEffect, useMemo, useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ModalChooseStaff from '../ChooseStaff';
import DatePickerLabel from '../DatePickerLabel';
import { ListIcon, StaffIcon } from '../Icons';
import TimePickerLabel from '../TimePickerLabel';
import BlockHourFormV2 from './FormV2';
import { ITimelineItem, setTime } from 'features/appointment/utils/getTimeLines';
const DatePickerAnt = DatePicker.generatePicker<Moment>(momentGenerateConfig);

const disabledDate = (date: Moment) => {
  if (date.endOf('d').valueOf() < moment().valueOf()) {
    return true;
  }
  return false;
};

export interface IFormBlockHourValues {
  blockHourType: string;
  staffId: string;
  date: Moment;
  dateOffs?: { startTime: Moment, endTime: Moment | null };
  startTime: ITimelineItem;
  endTime: ITimelineItem;
  note?: string;
}

enum TYPE_BLOCK_RADIO {
  BLOCK_HOURS = 'BLOCK_HOURS',
  DAY_OFF = 'DAY_OFF'
}
const BlockHours = ({ v2 }: { v2?: boolean }) => {
  const data = appointmentSelectors.getNewBlockHourDraftData();
  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const [timeStartStr, setTimeStartStr] = useState<string | undefined | null>();
  const [timeEndStr, setTimeEndStr] = useState<string | undefined | null>();
  const [staff, setStaff] = useState<IStaffItemData>();
  const [note, setNote] = useState('');
  const [typeBlock, setTypeBlock] = useState<TYPE_BLOCK_RADIO>(TYPE_BLOCK_RADIO.BLOCK_HOURS);
  const [daysOff, setDaysOff] = useState<RangeValue<Moment> | null>(null);
  const [, startTransition] = useTransition();
  const [form] = Form.useForm();
  useEffect(() => {
    if (data) {
      if (!timeStartStr) setTimeStartStr(data?.colTime);
      const date = moment(data.colTime);
      const startTime = setTime(date.get('hour'), date.get('minute'));
      const endTime = startTime.clone().add(15, 'minute');

      form.setFieldsValue({
        staffId: data.staff?.id || '',
        date,
        startTime: {
          id: startTime.format('hh:mm A'),
          value: startTime,
        },
        endTime: {
          id: endTime.format('hh:mm A'),
          value: endTime,
        },
        blockHourType: TYPE_BLOCK_RADIO.BLOCK_HOURS,
      });
    } else {
      form.resetFields();
    }
  }, [data]);

  const reset = () => {
    startTransition(() => {
      setTimeStartStr(null);
      setStaff(undefined);
      setNote('');
      setTypeBlock(TYPE_BLOCK_RADIO.BLOCK_HOURS);
      setDaysOff(null);
    });
  };


  const onClose = () => {
    reset();
    dispatch(appointmentActions.setNewBlockHourDraftData(null));
  };

  useEffect(() => {
    const time = moment(timeStartStr).add(15, 'minute').format();
    setTimeEndStr(time);
  }, [timeStartStr]);

  const timeStart = useMemo(() => {
    return timeStartStr ? moment(timeStartStr) : null;
  }, [timeStartStr]);

  const timeEnd = useMemo(() => {
    return timeEndStr ? moment(timeEndStr) : null;
  }, [timeEndStr]);

  const DateRangeInputs = useCallback(() => {
    return (
      <div className='row-item row-time' style={{ height: '3rem', }}>
        <Icon type='time' />
        <div className="row-grow">
          <RangerContainerStyled>
            <DatePickerAnt.RangePicker
              style={{ height: '3rem' }}
              renderExtraFooter={() => 'extra footer'}
              disabledDate={disabledDate}
              placement="bottomLeft"
              size='middle'
              suffixIcon={null}
              inputReadOnly
              bordered={false}
              onChange={setDaysOff}
              value={daysOff}
              format={DATE_FORMAT}
              clearIcon={false}
            />
          </RangerContainerStyled>
        </div>
      </div>
    );
  }, [daysOff]);

  const TimeInfo = useCallback(() => {
    return (
      <div className='row-item row-time' style={{ height: '3rem', }}>
        <Icon type='time' />
        <div className="row-grow">
          <DatePickerLabel value={timeStartStr} onChange={e => {
            const newDate = moment(e).set({
              hour: timeStart?.get('hour'),
              minute: timeStart?.get('minute'),
              second: 0
            });
            setTimeStartStr(newDate?.format());
          }} />
          <Text mx={1.5} />
          <TimePickerLabel value={timeStartStr} onChange={setTimeStartStr} />
          <Text variant='H8' color='text_3' mx={1}>-</Text>
          <TimePickerLabel minTime={timeStartStr} value={timeEndStr} onChange={setTimeEndStr} />
        </div>
      </div>
    );
  }, [timeEndStr, timeStartStr]);

  const StaffInfo = useCallback(() => {
    if (!data?.staff) return (
      <div className="row-item">
        <StaffIcon />
        <div className="row-grow">
          <div className="row-staff">
            <ModalChooseStaff v2={v2} staff={staff} onAddStaff={setStaff} />
          </div>
        </div>
      </div>
    );

    const staffName = data?.staff?.name;
    return (
      <div className="row-item">
        <StaffIcon />
        <div className="row-grow">
          <div className="row-staff">
            <Avatar size={'small'}>{get(staffName, [0], '')}</Avatar>
            <Text variant='H9' color='text_3'>{staffName}</Text>
          </div>
        </div>
      </div>
    );
  }, [data, staff, v2]);

  const getTime = () => {
    if (typeBlock === TYPE_BLOCK_RADIO.BLOCK_HOURS) {
      return { timeStart, timeEnd };
    }
    if (typeBlock === TYPE_BLOCK_RADIO.DAY_OFF) {
      return {
        timeStart: daysOff?.[0]?.set({ hour: 0, minute: 0, second: 0 }),
        timeEnd: daysOff?.[1],
      };
    }
    return null;
  };

  const onSubmit = async () => {
    const time = getTime();
    setLoadingPage(true);
    const payload: IBodyApiMakeLockTime = {
      staffId: staff ? staff.id : data?.staff?.id || '',
      startTime: time?.timeStart?.format('MM-DD-YYYY HH:mm:ss') || '',
      endTime: time?.timeEnd?.format('MM-DD-YYYY HH:mm:ss') || '',
      note,
      type: typeBlock === TYPE_BLOCK_RADIO.DAY_OFF ? 'OFF' : CALENDAR_TIME_TYPE.BLOCK,
    };
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.makeLockTime(payload);
      if (res?.data?.data) {
        dispatch(appointmentActions.setParams());
        onClose();
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const handleFinishV2 = async (values: any) => {
    const formValues = values as IFormBlockHourValues;
    const typeBlock = formValues.blockHourType;
    const staffId = formValues.staffId;
    const payload: IBodyApiMakeLockTime = {
      staffId: staffId ? staffId : data?.staff?.id || '',
      startTime: '',
      endTime: '',
      note: formValues.note || '',
      type: typeBlock === TYPE_BLOCK_RADIO.DAY_OFF ? 'OFF' : CALENDAR_TIME_TYPE.BLOCK,
    };

    if (typeBlock === TYPE_BLOCK_RADIO.DAY_OFF) {
      set(payload, 'startTime', formValues.dateOffs?.startTime.set({ hour: 8, minute: 0, second: 0 })?.format('MM-DD-YYYY HH:mm:ss'));
      set(payload, 'endTime', formValues.dateOffs?.endTime?.set({ hour: 22, minute: 0, second: 0 })?.format('MM-DD-YYYY HH:mm:ss'));
    } else {
      set(payload, 'startTime', formValues.startTime?.value?.format('MM-DD-YYYY HH:mm:ss'));
      set(payload, 'endTime', formValues.endTime?.value?.format('MM-DD-YYYY HH:mm:ss'));
    }
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.makeLockTime(payload);
      if (res?.data?.data) {
        dispatch(appointmentActions.setParams());
        onClose();
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const onChangeTab = (e: any) => {
    setTypeBlock(e.target.value);
    if (e.target.value === TYPE_BLOCK_RADIO.DAY_OFF) {
      setDaysOff([moment(), moment()]);
    }
  };

  const getValid = () => {
    if (typeBlock === TYPE_BLOCK_RADIO.DAY_OFF) {
      if (!daysOff) return false;
    }

    if (!(staff ? staff.id : data?.staff?.id || '')) return false;

    return true;
  };

  if (v2) {
    return (
      <Modal
        modalTitle="BLOCK HOURS"
        v2
        visible={!!data}
        onClose={onClose}
        noneBodyStyle
        containerPadding={0}
        okTitle={'Add Block Hour'}
        onSubmit={() => form.submit()}
        className={'modal-add-new-appointment modal-non-opacity'}
        width={'80vw'}
        showClose={false}
      >
        <Form layout='vertical' form={form} initialValues={{ blockHourType: TYPE_BLOCK_RADIO.BLOCK_HOURS, date: moment(), dateOffs: { startTime: setTime(8, 0, 0), endTime: setTime(22, 0, 0), } }} onFinish={handleFinishV2}>
          <BlockHourFormV2 />
        </Form>
      </Modal>
    );
  }

  if (!data) return null;

  return (
    <>
      <Modal
        modalTitle="BLOCK HOURS"
        visible={!!data}
        onClose={onClose}
        noneBodyStyle
        noneFooterStyle
        containerPadding={1.5}
        headerBgColor={colorTheme.info_bg_3}
        okTitle={okTitle}
        onSubmit={onSubmit}
        disableOk={!getValid()}
      >
        <ModalBlockHoursStyled>
          <RadioGroupStyled onChange={onChangeTab} value={typeBlock}>
            <Radio value={TYPE_BLOCK_RADIO.BLOCK_HOURS}>Block Hours</Radio>
            <Radio value={TYPE_BLOCK_RADIO.DAY_OFF}>Day Offs</Radio>
          </RadioGroupStyled>
          {typeBlock === TYPE_BLOCK_RADIO.DAY_OFF ? <DateRangeInputs /> : <TimeInfo />}
          <StaffInfo />
          <div className="row-item" >
            <ListIcon />
            <div className='w-100'>
              <Input.TextArea
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
                placeholder='Reason ...'
              />
            </div>
          </div>
        </ModalBlockHoursStyled>
      </Modal>
    </>
  );
};

export default memo(BlockHours);

const okTitle = (
  <Row align={'middle'} justify={'center'} className='prevent-select'>
    <Icon type='addPlus' />
    <Text variant='CONTENT_2' color='text_3' ml={0.5}>Add Block Hour</Text>
  </Row>
);
const RangerContainerStyled = styled.div`
.ant-picker-active-bar {
  background: #6FABB6;
}
.ant-picker-input {
  input {
    color: #6FABB6;
    font-family: Open Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration-line: underline;
    text-decoration-thickness: from-font;
    &::placeholder {
      color: #6FABB6;
    }
  }
  
}
  
`;

const RadioGroupStyled = styled(Radio.Group)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .ant-radio-wrapper {
    flex: 1;
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: #6FABB6;
        background-color: #6FABB6;
      }
    }
    .ant-radio-inner {
      border-color: #E5E6EB;
      border-width: 5px; 
    }
  }
`;

type ModalBlockHoursStyledProps = {}
const ModalBlockHoursStyled = styled.div<ModalBlockHoursStyledProps>`
  display: flex;
  flex-direction: column;

  .row-item {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
  .row-grow {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }
  .row-staff {
    gap: 11px;
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
  }
  .row-time {
    margin-bottom: 0.5rem;
  }
  .w-100 {
    width: 100%;
  }

  &.v2 {
    zoom: 1.5;
  }
`;