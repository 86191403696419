import Modal from 'components/Modal';
import styled from 'styled-components';
import CalendarTableView from '../Calendar/TableView';
import useAnybodyVisible from './useAnybodyVisible';
import { DndContext, DragEndEvent, MouseSensor, TouchSensor, closestCenter, useSensor, useSensors } from '@dnd-kit/core';
import { ICalendarDndData, ICalendarItemData } from 'features/appointment/services/types/calendar';
import { momentTimezone } from 'utils/time';
import moment from 'moment';
import { IBodyApiUpdateAppointmentWithDrop } from 'features/appointment/services/types/api';
import { set } from 'lodash';
import appointmentActions from 'features/appointment/services/actions';
import { useAppDispatch } from 'store/hooks';
import appointmentSelectors from 'features/appointment/services/selectors';
import AnybodyList from './AnybodyList';
import HeadContent from './HeadContent';
import { useEffect, useRef, useState } from 'react';
import DraggableOverlay from './DraggableOverlay';

const DATE_VALUE_FORMAT = 'MM-DD-YYYY HH:mm:ss';
const dateRex = /(^0[1-9]|[12][0-9]|3[01])-(0[1-9]|1[0-2])-(\d{4}$)/;
const ModalAnybody = () => {
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);
  const anybodyBox = useAnybodyVisible();
  const anybodyList = appointmentSelectors.anybodyBox.data();
  const [dataList, setDataList] = useState(anybodyList);
  useEffect(() => {
    setDataList([...anybodyList]);
  }, [anybodyList]);

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 500,
      tolerance: 8,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor,);

  const onDragEnd = (event: DragEndEvent) => {

    const appointmentItem = (event.active?.data?.current?.data) as ICalendarItemData;
    if (!appointmentItem) return;
    const [timeStr, droppable] = String(event.over?.id || '').split('/');
    const overData = event.over?.data.current as ICalendarDndData;

    if (overData?.blockDrop) return;

    let timeStart = momentTimezone(appointmentItem.startTime);
    const timeEnd = momentTimezone(appointmentItem.endTime);
    const distance = timeEnd.diff(timeStart?.clone(), 'minutes');

    if (dateRex.test(droppable)) {
      timeStart = moment(droppable, 'DD-MM-YYYY');
    }

    const time = moment(timeStr, 'HH:mm');
    timeStart.set({
      hour: time.get('hour'),
      minute: time.get('minute'),
      second: 0,
    });
    const payload: IBodyApiUpdateAppointmentWithDrop = {
      appointmentId: appointmentItem.id,
      startTime: timeStart.format(DATE_VALUE_FORMAT),
      distance,
    };

    if (!dateRex.test(droppable)) {
      set(payload, 'staffId', droppable);
    }
    setDataList([...dataList].filter(o => o.id !== payload.appointmentId));
    dispatch(appointmentActions.updateAppointmentWithDrop(payload));
  };

  return (
    <Modal
      visible={anybodyBox.visible}
      onClose={anybodyBox.close}
      onSubmit={anybodyBox.close}
      width={'100vw'}
      footer={<></>}
      noneBodyStyle
      noneFooterStyle
      hiddenHeader
      rootClassName={'modal-add-tip'}
      className='modal-overflow-unset modal-max-height-unset'
    >
      <Container ref={containerRef}>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onDragEnd}
        >
          <HeadContent data={dataList} />
          <AnybodyList anybodyList={dataList} />
          <CalendarTableView modal />
          <DraggableOverlay containerRef={containerRef} />
        </DndContext>
      </Container>
    </Modal>
  );
};

export default ModalAnybody;
const Container = styled.div`
  height: 80vh;
  position: relative;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
`;