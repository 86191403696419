import { Form } from 'antd';
import Button from 'components/Button';
import ModalEmail, { IModalEmailFuncFinish, useModalEmailRef } from 'components/ModalEmail';
import paymentApis from 'features/payment/services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import toast from 'utils/toast';

type Props = {
  billId?: string;
  defaultEmail?: string;
  small?: boolean;
  v2?: boolean;
};
const ButtonEmail = ({ v2, billId, defaultEmail, small }: Props) => {
  const setLoadingPage = useSetLoadingPage();
  const [emailForm] = Form.useForm();
  const emailRef = useModalEmailRef();

  const onFormEmailFinish: IModalEmailFuncFinish = async (values) => {
    if (!billId) return;
    emailRef.current?.close();
    setLoadingPage(true);
    try {
      const res = await paymentApis.sendSMSEmailReceipt(billId, 'EMAIL', values.email);
      if (res.data.data) {
        toast.success(res.data?.message || 'Success');
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };
  return (
    <>
      <Button cardCashier={v2} size={small ? 'small' : undefined} icon='email' ntype='LIGHT_BLUE' onClick={() => emailRef.current?.open(defaultEmail || '')} >Email</Button>
      <ModalEmail ref={emailRef} form={emailForm} onFinish={onFormEmailFinish} />
    </>
  );
};

export default ButtonEmail;
