import React from 'react';
import styled from 'styled-components';
import TurnBox from './TurnBox';
import { TURN_ITEM_TYPE } from 'features/turn/services/constants';
type Props = {
  value?: string;
  onChange?: (value: string) => void;
  disabled?: boolean;
};
const TurnType = ({ value, onChange = () => undefined, disabled }: Props) => {
  const handleOnClick = (_value: string) => () => {
    onChange(_value);
  };
  return (
    <Container>
      <TurnBox disabled={disabled} onClick={handleOnClick(TURN_ITEM_TYPE.WALK_IN)} active={value === TURN_ITEM_TYPE.WALK_IN} label='WALK-IN' type={'WALK_IN'} />
      <TurnBox disabled={disabled} onClick={handleOnClick(TURN_ITEM_TYPE.APPOINTMENT)} active={value === TURN_ITEM_TYPE.APPOINTMENT} label='APPOINTMENT' type='APPOINTMENT' />
      <TurnBox disabled={disabled} onClick={handleOnClick(TURN_ITEM_TYPE.BONUS)} active={value === TURN_ITEM_TYPE.BONUS} label='BONUS' type='BONUS' />
      <TurnBox disabled={disabled} onClick={handleOnClick(TURN_ITEM_TYPE.REQUEST)} active={value === TURN_ITEM_TYPE.REQUEST} label='REQUEST' type='REQUEST' />
    </Container>
  );
};

export default TurnType;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;