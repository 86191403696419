import { createSlice } from '@reduxjs/toolkit';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { set } from 'lodash';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  detail: null,
  customerInfo: null,
  visibleModalPoint: false,
  visibleModalGiftCard: false,
  visibleCheckFormInputs: false,
  visibleModalCheckInfo: false,
  activePayment: null,
  multiPayment: {
    [PAYMENT_TYPE.LOYALTY_POINT]: null,
    [PAYMENT_TYPE.GIFT_CARD]: null,
    [PAYMENT_TYPE.CHECK]: null,
    [PAYMENT_TYPE.CASH]: null,
    [PAYMENT_TYPE.CREDIT_CARD]: null,
    [PAYMENT_TYPE.VENMO]: null,
    [PAYMENT_TYPE.CASH_APPS]: null,
    [PAYMENT_TYPE.PAYPAL]: null,
    [PAYMENT_TYPE.ZELLE]: null,
  },
  draftCheckSignature: null,
  customerSide: {
    visibleAddATip: false,
    billId: null,
    message: null,
    receiveConfigs: null,
  },
  cashierSocketMsg: null,
  draftMultiPayment: null,
  draftCheckInfo: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.clearPaymentInfo, (state) => {
        state.multiPayment = {
          [PAYMENT_TYPE.LOYALTY_POINT]: null,
          [PAYMENT_TYPE.GIFT_CARD]: null,
          [PAYMENT_TYPE.CHECK]: null,
          [PAYMENT_TYPE.CASH]: null,
          [PAYMENT_TYPE.CREDIT_CARD]: null,
          [PAYMENT_TYPE.VENMO]: null,
          [PAYMENT_TYPE.CASH_APPS]: null,
          [PAYMENT_TYPE.PAYPAL]: null,
          [PAYMENT_TYPE.ZELLE]: null,
        };
      })
      .addCase(actions.setDraftMultiPayment, (state, { payload }) => {
        state.draftMultiPayment = payload;
      })
      .addCase(actions.customerSide.setCustomerMsg.success, (state, { payload }) => {
        state.customerSide.message = payload;
      })
      .addCase(actions.customerSide.setReceiveConfigs, (state, { payload }) => {
        state.customerSide.receiveConfigs = payload;
      })
      .addCase(actions.setCashierMsg.success, (state, { payload }) => {
        state.cashierSocketMsg = payload;
      })
      .addCase(actions.customerSide.setBillId, (state, { payload }) => {
        state.customerSide.billId = payload;
      })
      .addCase(actions.customerSide.setVisibleAddATip, (state, { payload }) => {
        state.customerSide.visibleAddATip = payload;
      })
      .addCase(actions.customerSide.resetCacheCustomer, (state) => {
        state.customerSide.receiveConfigs = null;
      })
      .addCase(actions.setDraftCheckInfo, (state, { payload }) => {
        state.draftCheckInfo = payload;
      })
      .addCase(actions.setDraftCheckSignature.success, (state, { payload }) => {
        state.draftCheckSignature = payload;
        state.visibleModalCheckInfo = payload ? true : false;
      })
      .addCase(actions.setVisibleModalFormCheck, (state, { payload }) => {
        state.visibleModalCheckInfo = payload;
      })
      .addCase(actions.setVisibleCheckFormInputs, (state, { payload }) => {
        state.visibleCheckFormInputs = payload;
        state.activePayment = PAYMENT_TYPE.CHECK;
      })
      .addCase(actions.setVisibleModalGiftCard, (state, { payload }) => {
        state.visibleModalGiftCard = payload;
        state.activePayment = PAYMENT_TYPE.GIFT_CARD;
      })
      .addCase(actions.setActivePayment, (state, { payload }) => {
        if (!payload) return;
        state.activePayment = payload;
      })
      .addCase(actions.setCheckAOtherPayment, (state, { payload }) => {
        if (!payload) return;
        if (payload?.otherMethod === PAYMENT_TYPE.CHECK) {
          set(state.multiPayment, [PAYMENT_TYPE.CHECK, 'amount'], payload?.amount);
          set(state.multiPayment, [PAYMENT_TYPE.CHECK, 'otherCheckInfo'], payload?.otherCheckInfo);
          set(state.multiPayment, [PAYMENT_TYPE.CHECK, 'otherMethod'], payload?.otherMethod);
        } else {
          set(state.multiPayment, [payload?.otherMethod, 'amount'], payload?.amount);
          set(state.multiPayment, [payload?.otherMethod, 'otherMethod'], payload?.otherMethod);
        }
      })
      .addCase(actions.setLoyaltyPointPayment, (state, { payload }) => {
        if (!payload) return;

        set(state.multiPayment, [PAYMENT_TYPE.LOYALTY_POINT, 'amount'], payload?.amount);
        set(state.multiPayment, [PAYMENT_TYPE.LOYALTY_POINT, 'point'], payload?.point);
      })
      .addCase(actions.setGiftCardPayment, (state, { payload }) => {
        if (!payload) return;

        set(state.multiPayment, [PAYMENT_TYPE.GIFT_CARD, 'amount'], payload?.amount);
        set(state.multiPayment, [PAYMENT_TYPE.GIFT_CARD, 'giftCardId'], payload?.giftCardId);
      })
      .addCase(actions.setCardPayment, (state, { payload }) => {
        set(state.multiPayment, [PAYMENT_TYPE.CREDIT_CARD, 'amount'], payload);
      })
      .addCase(actions.setCashPayment, (state, { payload }) => {
        set(state.multiPayment, [PAYMENT_TYPE.CASH, 'amount'], payload);
        const chance = (state.detail?.total ?? 0) - (payload ?? 0);
        set(state.multiPayment, [PAYMENT_TYPE.CASH, 'chance'], chance < 0 ? chance : 0);
      })
      .addCase(actions.getCustomerInfo.success, (state, { payload }) => {
        state.customerInfo = payload;
      })
      .addCase(actions.getTransactionDetail.success, (state, { payload }) => {
        if (state.draftMultiPayment) {
          state.multiPayment = {
            ...state.multiPayment,
            ...state.draftMultiPayment
          };
        }
        state.draftMultiPayment = null;

        state.detail = payload;

        if (state.draftCheckInfo) {
          set(state.multiPayment, [PAYMENT_TYPE.CHECK, 'amount'], state.draftCheckInfo?.amount);
          set(state.multiPayment, [PAYMENT_TYPE.CHECK, 'otherCheckInfo'], {
            dlInformation: state.draftCheckInfo?.dlInformation,
            address: state.draftCheckInfo?.address,
            bankName: state.draftCheckInfo?.bankName,
            payTo: state.draftCheckInfo?.companyName,
          });

          set(state.multiPayment, [PAYMENT_TYPE.CHECK, 'otherMethod'], payload?.otherMethod);
          state.visibleModalCheckInfo = true;
        }
        state.draftCheckInfo = null;
      })
      .addCase(actions.setVisibleModalPoint, (state, { payload }) => {
        state.visibleModalPoint = payload;
        state.activePayment = PAYMENT_TYPE.LOYALTY_POINT;
      })
      .addCase(actions.syncDetailData, (state) => {
        state.multiPayment = {
          [PAYMENT_TYPE.LOYALTY_POINT]: null,
          [PAYMENT_TYPE.GIFT_CARD]: null,
          [PAYMENT_TYPE.CHECK]: null,
          [PAYMENT_TYPE.CASH]: null,
          [PAYMENT_TYPE.CREDIT_CARD]: null,
          [PAYMENT_TYPE.VENMO]: null,
          [PAYMENT_TYPE.CASH_APPS]: null,
          [PAYMENT_TYPE.PAYPAL]: null,
          [PAYMENT_TYPE.ZELLE]: null,
        };
        state.visibleModalCheckInfo = false;
      })
      .addCase(actions.getTransactionDetail.fetch, (state) => {
        state.detail = null;
        state.customerInfo = null;
        state.multiPayment = {
          [PAYMENT_TYPE.LOYALTY_POINT]: null,
          [PAYMENT_TYPE.GIFT_CARD]: null,
          [PAYMENT_TYPE.CHECK]: null,
          [PAYMENT_TYPE.CASH]: null,
          [PAYMENT_TYPE.CREDIT_CARD]: null,
          [PAYMENT_TYPE.VENMO]: null,
          [PAYMENT_TYPE.CASH_APPS]: null,
          [PAYMENT_TYPE.PAYPAL]: null,
          [PAYMENT_TYPE.ZELLE]: null,
        };
        state.visibleModalCheckInfo = false;
      })
      ;
  },
});

const multiplePaymentServiceReducer = Slice.reducer;
export default multiplePaymentServiceReducer;
