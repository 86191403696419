import { Col, Row } from 'antd';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import TicketDetail from './components/TicketDetail';
import PaymentDetail from './components/PaymentDetail';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import multiplePaymentActions from '../services/actions';
import multiplePaymentSelectors from '../services/selectors';
import { parseDecimal } from 'features/cashier/services/constants';
import shopSelectors from 'services/shop/selectors';

const CustomerSide = () => {
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const detailTicket = multiplePaymentSelectors.getDetail();
  const receiveConfigs = multiplePaymentSelectors.customerSide.getReceiveConfigs();
  const discountSetting = shopSelectors.data.discountSetting();

  const serviceCharge = useMemo(() => {
    if (!receiveConfigs?.creditCard) return 0;
    return detailTicket?.cardFee || 0;
  }, [receiveConfigs, detailTicket]);

  const getDiscount = (subTotal: number) => {
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return Math.round(subTotal * (discountValue / 100));
    }
    if (discountType === 'MONEY')
      return parseDecimal(discountValue);
    return 0;
  };

  const total = useMemo(() => {
    if (!detailTicket) return 0;
    const _total = (detailTicket?.total || 0) + (serviceCharge || 0);
    if (!receiveConfigs?.enableCashIncentive) return _total;
    return parseDecimal(_total - getDiscount(detailTicket?.subTotal));
  }, [receiveConfigs, detailTicket]);

  const discountTicket = useMemo(() => {
    if (!detailTicket) return 0;
    if (!receiveConfigs?.enableCashIncentive) return detailTicket?.discountTicket || 0;
    return detailTicket?.discountTicket + getDiscount(detailTicket?.subTotal);
  }, [receiveConfigs, detailTicket]);

  useEffect(() => {
    dispatch(multiplePaymentActions.getTransactionDetail.fetch(id));
  }, []);

  return (
    <CustomerSidePage>
      <Col span={13}>
        <TicketDetail
          detailTicket={detailTicket}
          serviceCharge={serviceCharge}
          total={total}
          discountTicket={discountTicket}
        />
      </Col>
      <Col span={11}>
        <PaymentDetail
          total={total}
          detailTicket={detailTicket}
          receiveConfigs={receiveConfigs}
        />
      </Col>
    </CustomerSidePage>
  );
};

export default CustomerSide;

const CustomerSidePage = styled(Row)`
  background: var(--fill-fill-3, #E5E5E5);
  min-height: 100vh;
  padding: 5vw;
`;
