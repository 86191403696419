import { Col, Form, Input, InputRef, Row } from 'antd';
import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
type Props = {};

type IModalEditTurnServiceNameRef = {
  open: (name: string, callback: (newValue: string) => void) => void;
  close: () => void;
};

export const useModalEditTurnServiceNameRef = () => useRef<IModalEditTurnServiceNameRef>(null);

const ModalEditTurnServiceName = forwardRef<IModalEditTurnServiceNameRef, Props>(({ }, ref) => {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const [visible, setVisible] = useState(false);
  const callbackRef = useRef<(value: string) => void>(() => undefined);
  const handleClose = () => {
    setVisible(false);
    form.resetFields();
  };

  const handleFinish = (values: any) => {
    if (!values?.name) return;
    callbackRef.current(values?.name);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    open: (_data, callback) => {
      setVisible(true);
      callbackRef.current = callback;
      form.resetFields();
      form.setFieldValue('name', _data || '');
      setTimeout(() => { inputRef.current?.focus(); }, 100);
    },
    close: () => handleClose(),
  }));

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      modalTitle='Edit Name'
      onSubmit={() => form.submit()}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleFinish}
      >
        <Row gutter={[12, 12]}>
          <Col span="24">
            <Form.Item
              label=""
              name="name"
              noStyle
              rules={[{ required: true, message: 'Missing name' }]}
            >
              <Input ref={inputRef} placeholder='waxing' style={{ height: 48 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
ModalEditTurnServiceName.displayName = 'ModalEditTurnServiceName';
export default ModalEditTurnServiceName;
