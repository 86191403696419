import { Form } from 'antd';
import Button from 'components/Button';
import Empty from 'components/Empty';
import Loading from 'components/Loading';
import { IBodyUpdateSimpleMenuServiceItem } from 'features/settingService/services/types/simpleMenu';
import { useEffect } from 'react';
import shopSelectors from 'services/shop/selectors';
import { ISimpleMenuItem } from 'services/shop/types/simpleMenu';
import styled from 'styled-components';
import HeaderActions from './HeaderActions';
import SimpleMenuList from './List';
import ModalEditCategoryName, { useModalEditCategoryNameRef } from './ModalEditCategoryName';
import useSettingSimpleMenu from './useSettingSimpleMenu';
// setting-service/simple-menu
const SettingSimpleMenu = () => {
  const simpleMenu = shopSelectors.data.simpleMenu();
  const loading = shopSelectors.loading.simpleMenu();
  const [form] = Form.useForm();
  const modalEditNameRef = useModalEditCategoryNameRef();
  const settingSimpleMenu = useSettingSimpleMenu();

  useEffect(() => {
    form.setFieldValue('simpleMenu', simpleMenu);
  }, [simpleMenu]);

  const handleUpdateInfoService = (values: any) => {
    const body: IBodyUpdateSimpleMenuServiceItem[] = [];
    values?.simpleMenu?.map((o: ISimpleMenuItem) => {
      o.services.map(ser => {
        body.push({ id: ser.id, price: ser.price, supplyFee: ser.supplyFee, backgroundColor: ser.backgroundColor });
      });
    });
    settingSimpleMenu.updateInfoService(body);
  };

  const handleEditNameCategory = settingSimpleMenu.editNameCategory;
  const handleApplySupplyFee = settingSimpleMenu.applySupplyFee;
  const handleCreateNewCate = settingSimpleMenu.createNewCate;
  const handleEnableCheckIn = settingSimpleMenu.enableCheckIn;
  const handleReOrderCategories = settingSimpleMenu.reorderCategories;
  const handleDeleteCategory = settingSimpleMenu.deleteCategory;

  const onSave = () => form.submit();
  const onResetForm = () => {
    form.resetFields();
    form.setFieldValue('simpleMenu', simpleMenu);
  };
  const onOpenEditName = (cate: ISimpleMenuItem | null) => {
    if (!cate) return;
    modalEditNameRef.current?.open(cate);
  };

  if (loading) return (
    <SettingSimpleMenuStyled>
      <div className="container-content">
        <HeaderActions loading />
        <Loading />
      </div>
    </SettingSimpleMenuStyled>
  );

  if (!simpleMenu.length) return (
    <SettingSimpleMenuStyled>
      <div className="container-content">
        <HeaderActions empty onCreateNewCate={handleCreateNewCate} />
        <div className='empty-container'>
          <Empty description='Empty service!' />
        </div>
      </div>
    </SettingSimpleMenuStyled>
  );

  return (
    <>
      <SettingSimpleMenuStyled>
        <div className="container-content">
          <HeaderActions
            onApplySupplyFee={handleApplySupplyFee}
            onCreateNewCate={handleCreateNewCate}
            onEnableCheckIn={handleEnableCheckIn}
            onReOrderCate={handleReOrderCategories}
          />
          <Form style={{ flex: 1, display: 'flex' }} form={form} onFinish={handleUpdateInfoService}>
            <SimpleMenuList onDeleteCategory={handleDeleteCategory} onOpenEditName={onOpenEditName} />
          </Form>
        </div>
        <div className="footer-actions">
          <Button height={3} width='168px' ntype='SECONDARY' onClick={onResetForm}>Reset</Button>
          <Button height={3} width='168px' ntype='PRIMARY' onClick={onSave}>Save</Button>
        </div>
      </SettingSimpleMenuStyled>
      <ModalEditCategoryName ref={modalEditNameRef} onSubmit={handleEditNameCategory} />
    </>
  );
};

export default SettingSimpleMenu;

const SettingSimpleMenuStyled = styled.div`
  background: #E5E5E5;
  height: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  padding-top: 24px;
  .container-content {
    flex: 1;
    background: #E5E5E5;
    display: flex;
    flex-direction: column;
  }
  .empty-container {
    flex: 1;
    background: #fff;
    display: flex;
    justify-content: center;
    padding-top: 15vh;
  }
  .footer-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    display: flex;
    padding: 16px;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: #FFF;
    border-top: 0.5px solid rgba(0, 0, 0, 0.20);
  }
`;
