import { Input } from 'antd';
import Box from 'components/Box';
import { InputWithKeyboardStyled, useDollarAmountInputRef } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { useCurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import React, { useEffect, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
type Props = {
  v2?: boolean;
};
const SupplyFeeItemModal = ({ v2 }: Props) => {
  const inputRef = useCurrencyKeyBoardRef();
  const dollarRef = useDollarAmountInputRef();
  const dispatch = useAppDispatch();
  const draftData = cashierSelectors.getDraftSupplyFeeItemModal();
  const allSetting = shopSelectors.data.allSetting();
  const [amount, setAmount] = useState<number | null>();

  const handleClose = () => {
    dispatch(cashierActions.setDraftSupplyFeeItemModal(null));
  };


  useEffect(() => {
    if (!draftData) return;
    setTimeout(() => {
      inputRef.current?.setValue((draftData?.supplyFee || 0).toFixed(2));
      dollarRef.current?.init(draftData?.supplyFee?.toString());
    }, 200);
  }, [draftData]);

  const handleSubmit = () => {
    if (!draftData) return;
    dispatch(cashierActions.updateSupplyFeeItem({
      ...draftData,
      supplyFee: amount || 0,
    }));
    handleClose();
  };

  if (v2) {
    return (
      <Modal
        v2
        visible={!!draftData}
        onClose={handleClose}
        modalTitle='Supply Fee'
        okTitle="Done"
        noneBodyStyle
        noneFooterStyle
        width={'1000px'}
        onSubmit={handleSubmit}
      >
        <ContainerV2>
          <ServiceName className='ServiceName'>
            <Text className='modal-label' mb={0.5}>
              Service Name:
            </Text>
            <Input className='service-name-input' size='small' value={draftData?.itemName} disabled />
          </ServiceName>
          {
            allSetting?.activeFormatMoney ?
              <Box display='flex' flexDirection='column' gap='2'>
                <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
                  Enter Amount:
                </Text>
                <InputWithKeyboardStyled
                  ref={dollarRef}
                  value={amount}
                  onChange={setAmount}
                  inputMode='none'
                  autoFocus
                />
                <DollarAmountKeyboard
                  controlRef={dollarRef}
                  value={amount}
                  onChange={setAmount}
                />
              </Box>
              : <>
                <CurrencyKeyBoard v2 smallInput ref={inputRef} onChange={setAmount} value={amount} />
              </>
          }

        </ContainerV2>
      </Modal>
    );
  }

  return (
    <Modal
      visible={!!draftData}
      onClose={handleClose}
      modalTitle='Supply Fee'
      okTitle="Done"
      showClose
      hiddenCloseButton
      onSubmit={handleSubmit}
    >
      <ServiceName>
        <Text style={{ fontSize: '1.2rem' }} mb={0.5}>
          Service: {draftData?.itemName || '--'}
        </Text>
      </ServiceName>
      <CurrencyKeyBoard smallInput ref={inputRef} onChange={setAmount} value={amount} />
    </Modal>
  );
};

export default SupplyFeeItemModal;
const ContainerV2 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
  padding-top: 16px;
`;
const ServiceName = styled.div`
  margin-bottom: 1rem;
  .modal-label {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .ant-input.service-name-input {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
    border: none;
    outline: none;
    background-color: transparent;
  }
`;