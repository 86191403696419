import React from 'react';

const CardProductV2 = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0233333 12.8333C6.79 9.31 13.5567 5.83333 20.3233 2.33333C22.82 4.17666 25.3167 6.04333 27.8133 7.91C30.4267 6.04333 33.0167 4.2 35.5833 2.33333C42.3967 5.85666 49.1867 9.42666 56 12.9733C53.6667 15.12 51.3567 17.29 49.0233 19.4367C51.3333 22.12 53.69 24.8033 56 27.4867C49.6767 30.9633 43.3767 34.4867 37.0533 37.9633L36.9367 37.94C34.3467 35.21 31.7567 32.48 29.19 29.75C34.9533 26.6933 40.6933 23.6133 46.4567 20.5333C40.3433 17.0567 34.16 13.7433 28.0233 10.3133C21.84 13.6967 15.68 17.08 9.52 20.4867C15.3067 23.5667 21.0933 26.6233 26.8567 29.7033C24.29 32.5267 21.7 35.3733 19.1333 38.1967C12.7633 34.51 6.37 30.8233 0 27.1367V26.8567L0.07 27.09C2.33333 24.5 4.69 22.0033 7 19.4367C4.66667 17.2433 2.33333 15.05 0 12.8333V12.6L0.0233333 12.8333Z" fill="#4F1787" />
      <path d="M29.1898 33.1333C31.6398 35.7233 34.0898 38.3367 36.5632 40.9267C40.6932 38.6167 44.8465 36.33 48.9998 34.02C48.9998 37.0533 49.0232 40.0867 48.9765 43.12C42.3732 46.5967 35.7698 50.1667 29.1665 53.6667C29.1898 46.83 29.1432 39.97 29.1898 33.1333Z" fill="#4F1787" />
      <path d="M19.6 41.1367C22.0034 38.5233 24.3834 35.84 26.8334 33.25C26.8567 40.04 26.8334 46.83 26.8567 53.62C20.2067 50.19 13.65 46.5733 7.02336 43.0967C7.00003 40.0167 7.00003 36.9367 7.02336 33.8567C11.2 36.3067 15.4 38.71 19.6 41.1367Z" fill="#4F1787" />
    </svg>
  );
};

export const CardProductV2Active = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0233333 12.8333C6.79 9.31 13.5567 5.83333 20.3233 2.33333C22.82 4.17667 25.3167 6.04333 27.8133 7.91C30.4267 6.04333 33.0167 4.2 35.5833 2.33333C42.3967 5.85667 49.1867 9.42667 56 12.9733C53.6667 15.12 51.3567 17.29 49.0233 19.4367C51.3333 22.12 53.69 24.8033 56 27.4867C49.6767 30.9633 43.3767 34.4867 37.0533 37.9633L36.9367 37.94C34.3467 35.21 31.7567 32.48 29.19 29.75C34.9533 26.6933 40.6933 23.6133 46.4567 20.5333C40.3433 17.0567 34.16 13.7433 28.0233 10.3133C21.84 13.6967 15.68 17.08 9.52 20.4867C15.3067 23.5667 21.0933 26.6233 26.8567 29.7033C24.29 32.5267 21.7 35.3733 19.1333 38.1967C12.7633 34.51 6.37 30.8233 0 27.1367V26.8567L0.07 27.09C2.33333 24.5 4.69 22.0033 7 19.4367C4.66667 17.2433 2.33333 15.05 0 12.8333V12.6L0.0233333 12.8333Z" fill="white" />
      <path d="M29.1898 33.1333C31.6398 35.7233 34.0898 38.3367 36.5632 40.9267C40.6932 38.6167 44.8465 36.33 48.9998 34.02C48.9998 37.0533 49.0232 40.0867 48.9765 43.12C42.3732 46.5967 35.7698 50.1667 29.1665 53.6667C29.1898 46.83 29.1432 39.97 29.1898 33.1333Z" fill="white" />
      <path d="M19.6 41.1367C22.0034 38.5233 24.3834 35.84 26.8334 33.25C26.8567 40.04 26.8334 46.83 26.8567 53.62C20.2067 50.19 13.65 46.5733 7.02336 43.0967C7.00003 40.0167 7.00003 36.9367 7.02336 33.8567C11.2 36.3067 15.4 38.71 19.6 41.1367Z" fill="white" />
    </svg>
  );
};

export default CardProductV2;
