import { Form } from 'antd';
import Button from 'components/Button';
import ModalSMS, { IModalSMSFuncFinish, useModalSMSRef } from 'components/ModalSMS';
import paymentApis from 'features/payment/services/apis';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import toast from 'utils/toast';

type Props = {
  billId?: string;
  defaultSMSPhone?: string;
  small?: boolean;
  v2?: boolean;
};
const ButtonSMS = ({ v2, billId, defaultSMSPhone, small }: Props) => {
  const setLoadingPage = useSetLoadingPage();
  const [smsForm] = Form.useForm();
  const smsRef = useModalSMSRef();
  const onFormSMSFinish: IModalSMSFuncFinish = async (values) => {
    if (!billId) return;
    smsRef.current?.close();
    setLoadingPage(true);
    try {
      const res = await paymentApis.sendSMSEmailReceipt(billId, 'SMS', values.phone?.replace(/[^0-9]/g, ''));
      if (res.data.data) {
        toast.success(res.data?.message || 'Success');
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };
  return (
    <>
      <Button cardCashier={v2} size={small ? 'small' : undefined} icon='sendSms' ntype='YELLOW' onClick={() => smsRef.current?.open(defaultSMSPhone || '')} >SMS</Button>
      <ModalSMS ref={smsRef} form={smsForm} onFinish={onFormSMSFinish} />
    </>
  );
};

export default ButtonSMS;