import { Switch } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';

type SwitchBtnProps = {
  label?: string;
  onChange?: (val: boolean) => void;
  value?: boolean
};

const SwitchBtn = ({ label = '', onChange = () => undefined, value = false }: SwitchBtnProps) => (
  <Styled size='small' ntype='GHOST' className="btn-switch" onClick={() => onChange(!value)}>
    <Switch checked={value} onChange={onChange} />
    <Text className='label'>{label}</Text>
  </Styled>

);
export default SwitchBtn;

const Styled = styled(Button)`
&.btn-switch {
  .ant-switch {
    height: 32px;
    width: 54px;
    &.ant-switch.ant-switch-checked {
      background: #6FABB6;
    }
    .ant-switch-handle {
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .CONTENT_2 {
    display: flex;
    align-items: center;
    gap: 12px;
    .label {
      color: #1D2129;
      text-align: right;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
`;