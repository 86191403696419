import { InputRef } from 'antd';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import { useRef } from 'react';
import { ICustomerItemData } from 'services/shop/types/customer';
import { useAppDispatch } from 'store/hooks';
import CustomerSearchInput from 'widgets/Customer/CustomerSearchInput';
import ButtonAddCustomer, { useButtonAddCustomerRef } from './AddCustomer';
import ModalChooseCustomer from './ModalChooseCustomer';
import ModalWarningVIPPackage from './ModalWarningVIPPackage';
import { ContentBoxStyled, CustomerStyled } from './styles';
import { formatPhone } from 'utils/formatPhone';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
type Props = {
  v2?: boolean;
};
const Customer = ({ v2 }: Props) => {
  const inputRef = useRef<InputRef>(null);
  const dispatch = useAppDispatch();
  const buttonAddRef = useButtonAddCustomerRef();
  const allSetting = shopSelectors.data.allSetting();

  const onPickCustomer = (cus: ICustomerItemData) => {
    dispatch(cashierActions.switchSelectedCustomer(cus));
  };

  const onOpenAddNew = ({ phone, name }: { phone?: string, name?: string }) => {
    buttonAddRef.current?.open({ phone: phone ? formatPhone(phone) : '', name });
  };
  if (v2) {
    return (
      <CustomerStyledV2>
        <CustomerSearchInput v2 inputRef={inputRef} onSelect={onPickCustomer} onOpenAddNew={onOpenAddNew} />
        <ModalChooseCustomer />
        <ModalWarningVIPPackage />
      </CustomerStyledV2>
    );
  }
  return (
    <CustomerStyled>
      <ContentBoxStyled>
        <Text variant="H6" color="text_2">CUSTOMER</Text>
        <ButtonAddCustomer ref={buttonAddRef} short={allSetting?.showUIStaffCashier} />
      </ContentBoxStyled>
      <CustomerSearchInput inputRef={inputRef} onSelect={onPickCustomer} onOpenAddNew={onOpenAddNew} />
      <ModalChooseCustomer />
      <ModalWarningVIPPackage />
    </CustomerStyled>
  );
};

export default Customer;

const CustomerStyledV2 = styled.div`
display: flex;
height: 58px;
padding: 16px;
flex-direction: column;
justify-content: center;
align-items: center;
flex-shrink: 0;
align-self: stretch;
background: #C6E9EF;
.CustomerSearchInput .ant-input-affix-wrapper {
  border-radius: 100px;
  padding: 0px;
  .ant-input-suffix {
    display: flex;
    width: 56px;
    height: 56px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 0px 100px 100px 0px;
    background: #2D6772;
  }
  input {
    border-radius: 100px; 
    padding: 24px;
    height: 56px;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
    align-self: center;
  }
  border: 3px solid #2D6772;
  background: #FFF;
}
`;
