import React from 'react';

const EditCategory = () => {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.5" width="32" height="32" rx="5" fill="#6FABB6" />
      <path d="M19.4272 4.10512C20.1655 4.003 20.9341 4.13254 21.5938 4.48712C21.9651 4.67812 22.2796 4.95847 22.6131 5.20715C22.9779 5.49365 23.3724 5.75179 23.6716 6.11393C24.1889 6.71246 24.5099 7.48402 24.5625 8.27922C24.6244 9.15621 24.3638 10.0545 23.839 10.7547C20.8229 14.717 17.8021 18.6755 14.785 22.6368C14.7069 22.7456 14.6017 22.8335 14.4775 22.8827C12.416 23.7374 10.3564 24.5974 8.29493 25.4527C8.01392 25.576 7.6636 25.4479 7.51332 25.1803C7.38631 24.9761 7.43702 24.726 7.44679 24.4995C7.56543 22.3848 7.685 20.2706 7.80317 18.1559C7.81899 17.9157 7.79758 17.6458 7.96274 17.4505C10.9091 13.5762 13.8564 9.70132 16.8028 5.82648C16.9796 5.59813 17.1438 5.35797 17.3503 5.15515C17.9031 4.58404 18.6465 4.2115 19.4272 4.10512ZM18.7079 5.69978C18.1375 6.02883 17.8244 6.6387 17.4113 7.13086C18.9996 8.36621 20.5968 9.5888 22.1879 10.8199C22.3949 10.5447 22.6043 10.272 22.8127 9.99775C23.1426 9.56469 23.3198 9.01391 23.3026 8.46644C23.2873 7.75492 22.9309 7.06467 22.3735 6.63681C21.999 6.35031 21.6278 6.06003 21.2509 5.77779C20.513 5.25159 19.477 5.22559 18.7079 5.69978ZM9.33101 17.7611C10.9268 18.9757 12.5235 20.1893 14.1151 21.409C16.5455 18.2169 18.9833 15.0304 21.4086 11.834C19.8161 10.609 18.2255 9.38125 16.6367 8.15205C14.2021 11.3555 11.7638 14.5567 9.33101 17.7611ZM8.75319 23.8712C10.1508 23.2888 11.5493 22.7073 12.9478 22.1267C11.6381 21.1273 10.3266 20.1297 9.01698 19.1303C8.93091 20.7108 8.83739 22.2908 8.75319 23.8712Z" fill="white" />
      <path d="M4.40762 28.0985C4.54756 28.0631 4.69848 28.0728 4.84482 28.0718C12.3586 28.0728 19.8724 28.0712 27.3862 28.0728C27.7091 28.0696 27.999 28.2657 27.999 28.4872C28.0205 28.7294 27.6931 28.945 27.3418 28.9274C19.7828 28.9299 12.2237 28.9271 4.66464 28.9274C4.38979 28.938 4.11814 28.8169 4.03445 28.6359C3.92057 28.4251 4.1003 28.1719 4.40762 28.0985Z" fill="white" />
    </svg>
  );
};

export default EditCategory;
