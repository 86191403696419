import { Col, Form, Input, InputRef, Row } from 'antd';
import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { ISimpleMenuItem } from 'services/shop/types/simpleMenu';
type Props = {
  onSubmit?: (data: ISimpleMenuItem, newValue: string) => void;
};
type IModalEditCategoryNameRef = {
  open: (data: ISimpleMenuItem) => void;
  close: () => void;
};

export const useModalEditCategoryNameRef = () => useRef<IModalEditCategoryNameRef>(null);

const ModalEditCategoryName = forwardRef<IModalEditCategoryNameRef, Props>(({ onSubmit = () => undefined }, ref) => {
  const [data, setData] = useState<ISimpleMenuItem | null>(null);
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const [visible, setVisible] = useState(false);
  const handleClose = () => {
    setVisible(false);
    setData(null);
  };

  const handleFinish = (values: any) => {
    if (!data) return;
    if (!values?.name) return;
    onSubmit(data, values.name);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    open: (_data) => {
      setVisible(true);
      setData(_data);
      form.setFieldValue('name', _data?.categoryName || '');
      setTimeout(() => { inputRef.current?.focus(); }, 100);
    },
    close: () => handleClose(),
  }));

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      modalTitle='Edit Name'
      onSubmit={() => form.submit()}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleFinish}
      >
        <Row gutter={[12, 12]}>
          <Col span="24">
            <Form.Item
              label=""
              name="name"
              noStyle
              rules={[{ required: true, message: 'Missing category name' }]}
            >
              <Input ref={inputRef} placeholder='waxing' style={{ height: 48 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
ModalEditCategoryName.displayName = 'ModalEditCategoryNameSimpleMenu';
export default ModalEditCategoryName;
