import React from 'react';
import styled from 'styled-components';
import { ITurnServiceItem } from 'features/turn/services/types/service';
import { turnManualSelectors } from 'features/turn/services/selectors';
import { useAppDispatch } from 'store/hooks';
import { turnManualAPIActions } from 'features/turn/services/actions';
import ServiceList from './ServiceList';

const TurnServices = () => {
  const dispatch = useAppDispatch();
  const fullTurnData = turnManualSelectors.fullTurnDataServices();
  const halfTurnData = turnManualSelectors.halfTurnDataServices();

  const onChooseService = (o: ITurnServiceItem) => {
    dispatch(turnManualAPIActions.handleTurnWithService(o));
  };

  return (
    <Container className='TurnServices'>
      <div className="scroll-services">
        <div className="container-services">
          <ServiceList data={fullTurnData} onChooseService={onChooseService} type='WALK_IN' full />
          <ServiceList data={halfTurnData} onChooseService={onChooseService} half />
        </div>
      </div>
    </Container>
  );
};

export default TurnServices;
const Container = styled.div`
flex: 1;
align-self: stretch;
position: relative;
display: flex;
flex-direction: column;
margin-right: -24px;
.scroll-services {
  position: absolute;
  inset: 0;
  overflow: auto;
  display: flex;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0px;
    height: 8px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0,0,0,0.5);
    &:hover {
      background-color: rgba(0,0,0,0.6);
    }
  }

}
.container-services {
  display: flex;
  padding-right: 24px;
  align-self: stretch;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
}

@keyframes zoomArrow {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}
.service-half-turn {
  display: flex;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex: 1;
}
`;