import { Col, Form, Input, InputRef, Row } from 'antd';
import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
type Props = {
  onSubmit?: (newValue: string, extraData?: any) => void;
};
type IModalEditCategoryNameRef = {
  open: (name: string, extraData?: any) => void;
  close: () => void;
};

export const useModalEditNameRef = () => useRef<IModalEditCategoryNameRef>(null);

const ModalEditName = forwardRef<IModalEditCategoryNameRef, Props>(({ onSubmit = () => undefined }, ref) => {
  const [form] = Form.useForm();
  const [extraData, setExtraData] = useState<any>(null);
  const inputRef = useRef<InputRef>(null);
  const [visible, setVisible] = useState(false);
  const handleClose = () => {
    setVisible(false);
    setExtraData(null);
  };

  const handleFinish = (values: any) => {
    if (!values?.name) return;
    onSubmit(values.name, extraData);
    handleClose();
  };

  useImperativeHandle(ref, () => ({
    open: (_data, _extraData) => {
      setVisible(true);
      setExtraData(_extraData || null);
      form.resetFields();
      form.setFieldValue('name', _data || '');
      setTimeout(() => { inputRef.current?.focus(); }, 100);
    },
    close: () => handleClose(),
  }));

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      modalTitle='Edit Name'
      onSubmit={() => form.submit()}
    >
      <Form
        form={form}
        layout={'vertical'}
        onFinish={handleFinish}
      >
        <Row gutter={[12, 12]}>
          <Col span="24">
            <Form.Item
              label=""
              name="name"
              noStyle
              rules={[{ required: true, message: 'Missing category name' }]}
            >
              <Input ref={inputRef} placeholder='waxing' style={{ height: 48 }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
ModalEditName.displayName = 'ModalEditNameSimpleMenu';
export default ModalEditName;
