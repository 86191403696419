import React, { useCallback, useEffect, useState } from 'react';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import DiscountMethodModal, { ISubmitModalDiscount } from './DiscountMethodModal';
import shopSelectors from 'services/shop/selectors';
type Props = {
  visible: boolean;
  onClose: () => void;
  onSubmit: ISubmitModalDiscount;
  initialAmount: number;
  v2?: boolean;
};
const DiscountTicket = ({ visible, onClose, onSubmit, initialAmount = 0, v2 }: Props) => {
  const allSetting = shopSelectors.data.allSetting();
  const [visibleAdmin, setVisibleAdmin] = useState(true);

  useEffect(() => {
    if (visible) setVisibleAdmin(true);
  }, [visible]);

  const onVerifyAdminSuccess = useCallback(() => {
    setVisibleAdmin(false);
  }, []);

  if (!visible) return null;
  if (visibleAdmin && !allSetting?.ignorePassAdminDiscount) return <RequestAdminLocalPW v2={v2} visible onCorrect={onVerifyAdminSuccess} onClose={onClose} />;

  return <DiscountMethodModal
    visible
    v2={v2}
    onClose={onClose}
    onSubmit={onSubmit}
    initialAmount={initialAmount}
  />;
};

export default DiscountTicket;
