import { createAsyncAction, createAction } from 'services/actionConfigs';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import { PREFIX_ACTIONS } from './constants';
import {
  IEditCateBodyType,
  ICreateAddOnServiceBodyType,
  IUpdateCateBodyForProduct,
  IGetProductsByCategoryId,
  IChangeCommissionPrice,
  IDeleteProduct,
  IDeleteVIPs,
  IgetVIPParams,
  IUpdateQuickPayBody,
  IDayOffParams,
} from './types/api';
import { IBodyItemNotificationPut } from './types/generalSetting';
import {
  ITaxParams,
  IShopDiscountParams,
  IPaymentMethodParams,
  ILoyaltyDataBody,
} from './types/paymentSetting';
import { IGiftCard, IProduct, IVIPs } from './types/reducer';
import { IUpdateQuickPayTurnItem } from './types/turn';

const clickCate = createAction<ICategoryItemData>(PREFIX_ACTIONS + 'clickCate');
const clickAddOnService = createAction<IServiceItemData>(
  PREFIX_ACTIONS + 'clickAddOnService'
);

const editCategory = createAsyncAction<IEditCateBodyType>(
  PREFIX_ACTIONS + 'editCategory'
);
const createCategory = createAsyncAction<IEditCateBodyType>(
  PREFIX_ACTIONS + 'createCategory'
);

const createOrUpdateServiceAddOn =
  createAsyncAction<ICreateAddOnServiceBodyType>(
    PREFIX_ACTIONS + 'createOrUpdateServiceAddOn'
  );
const editServiceAddOn = createAsyncAction<any>(
  PREFIX_ACTIONS + 'editServiceAddOn'
);
const deleteAddOnService = createAsyncAction<{ id: string; pinCode?: string }>(
  PREFIX_ACTIONS + 'deleteAddOnService'
);

const addMoreServiceByCate = createAsyncAction<any>(
  PREFIX_ACTIONS + 'addMoreServiceByCate'
);
const getListCategoriesForProduct = createAsyncAction<any>(
  PREFIX_ACTIONS + 'getListCategoriesForProduct'
);
const createCategoryForProduct = createAsyncAction<IUpdateCateBodyForProduct>(
  PREFIX_ACTIONS + 'createCategoryForProduct'
);
const updateNameCategoryForProduct =
  createAsyncAction<IUpdateCateBodyForProduct>(
    PREFIX_ACTIONS + 'updateNameCategoryForProduct'
  );
const deleteCategoryForProduct = createAsyncAction<{
  id: string;
  pinCode?: string;
}>(PREFIX_ACTIONS + 'deleteCategoryForProduct');

const getProductsByCategoryId = createAsyncAction<IGetProductsByCategoryId>(
  PREFIX_ACTIONS + 'getProductsByCategoryId'
);

const getProductsPageable = createAsyncAction<any>(
  PREFIX_ACTIONS + 'getProductsPageable'
);

const cateForProductSelected = createAction<any>(
  PREFIX_ACTIONS + 'cateForProductSelected'
);

const updateProduct = createAsyncAction<any>(PREFIX_ACTIONS + 'updateProduct');

const createProduct = createAsyncAction<IProduct>(
  PREFIX_ACTIONS + 'createProduct'
);

const deleteProduct = createAsyncAction<IDeleteProduct>(
  PREFIX_ACTIONS + 'deleteProduct'
);

const changeCommissionPrice = createAsyncAction<IChangeCommissionPrice>(
  PREFIX_ACTIONS + 'changeCommissionPrice'
);

const reloadProduct = createAction<any>(PREFIX_ACTIONS + 'reloadProduct');
const getVIP = createAsyncAction<IgetVIPParams>(PREFIX_ACTIONS + 'getVIP');
const createVIP = createAsyncAction<IVIPs>(PREFIX_ACTIONS + 'createVIP');
const updateVIP = createAsyncAction<IVIPs[]>(PREFIX_ACTIONS + 'updateVIP');
const deleteVIP = createAsyncAction<IDeleteVIPs>(PREFIX_ACTIONS + 'deleteVIP');
const reloadVIPs = createAction<any>(PREFIX_ACTIONS + 'reloadVIPs');
const reloadCategory = createAction<any>(PREFIX_ACTIONS + 'reloadCategory');
const getTurnSetting = createAsyncAction<any>(
  PREFIX_ACTIONS + 'getTurnSetting'
);
const reloadTurnSetting = createAction<any>(
  PREFIX_ACTIONS + 'reloadTurnSetting'
);
const updateTurnSettingTiket = createAsyncAction<any>(
  PREFIX_ACTIONS + 'updateTurnSettingTiket'
);
const updateTurnSettingService = createAsyncAction<any>(
  PREFIX_ACTIONS + 'updateTurnSettingService'
);
const deleteTurnSettingService = createAsyncAction<any>(
  PREFIX_ACTIONS + 'deleteTurnSettingService'
);
const getLateForWork = createAsyncAction<any>(
  PREFIX_ACTIONS + 'getLateForWork'
);
const updateLateForWork = createAsyncAction<any>(
  PREFIX_ACTIONS + 'updateLateForWork'
);
const reloadLateForWork = createAction<any>(
  PREFIX_ACTIONS + 'reloadLateForWork'
);
const getQuickPayList = createAsyncAction(PREFIX_ACTIONS + 'getQuickPayList');
const updateQuickPayList = createAsyncAction<IUpdateQuickPayBody>(
  PREFIX_ACTIONS + 'updateQuickPayList'
);

// start setting general all action
const getSettingGeneral = createAsyncAction<any>(
  PREFIX_ACTIONS + 'getSettingGeneral'
);
// end setting general all action

// services
const deleteCategoriesTabService = createAsyncAction<{
  id: string;
  pinCode: string;
}>(PREFIX_ACTIONS + 'deleteCategoriesTabService');
// end services

// start setting Payment actions
const getTax = createAsyncAction(PREFIX_ACTIONS + 'getTax');
const getDiscount = createAsyncAction(PREFIX_ACTIONS + 'getDiscount');
const getSpecialDiscount = createAsyncAction(
  PREFIX_ACTIONS + 'getSpecialDiscount'
);
const getLoyaltyReward = createAsyncAction(PREFIX_ACTIONS + 'getLoyaltyReward');
const getOtherPaymentMethod = createAsyncAction(
  PREFIX_ACTIONS + 'getOtherPaymentMethod'
);
const getPolicyTerm = createAsyncAction(PREFIX_ACTIONS + 'getPolicyTerm');
const getGiftCard = createAsyncAction(PREFIX_ACTIONS + 'getGiftCard');
const postTax = createAsyncAction<ITaxParams>(PREFIX_ACTIONS + 'postTax');
const postDiscount = createAsyncAction<IShopDiscountParams>(
  PREFIX_ACTIONS + 'postDiscount'
);
const postSpecialDiscount = createAsyncAction<any>(
  PREFIX_ACTIONS + 'postSpecialDiscount'
);
const postLoyalty = createAsyncAction<ILoyaltyDataBody[]>(
  PREFIX_ACTIONS + 'postLoyalty'
);
const postOtherPaymentMethod = createAsyncAction<IPaymentMethodParams[]>(
  PREFIX_ACTIONS + 'postOtherPaymentMethod'
);
const postGiftCard = createAsyncAction<IGiftCard>(
  PREFIX_ACTIONS + 'postGiftCard'
);
const getNotification = createAsyncAction(PREFIX_ACTIONS + 'getNotification');
const postNotification = createAsyncAction<IBodyItemNotificationPut[]>(
  PREFIX_ACTIONS + 'postNotification'
);
const getScheduler = createAsyncAction(PREFIX_ACTIONS + 'getScheduler');
const postScheduler = createAsyncAction<any>(PREFIX_ACTIONS + 'postScheduler');

// end setting Payment actions

// start action off day
const getDayOffList = createAsyncAction(PREFIX_ACTIONS + 'getDayOffList');
const postDayOffList = createAsyncAction<IDayOffParams>(
  PREFIX_ACTIONS + 'postDayOffList'
);
const deleteDayOffList = createAsyncAction<string>(
  PREFIX_ACTIONS + 'deleteDayOffList'
);
const deleteMultiple = createAsyncAction<string[]>(
  PREFIX_ACTIONS + 'deleteMultiple'
);
// end action off day

const resetServiceSetting = createAction(
  PREFIX_ACTIONS + 'resetServiceSetting'
);

const serviceActs = {
  activeCate: createAction<string | null>(PREFIX_ACTIONS + 'serviceActs' + 'activeCate'),
  selectedServiceId: createAction<string>(PREFIX_ACTIONS + 'serviceActs' + 'selectedServiceId'),
};

const getVIPProductList = createAsyncAction(PREFIX_ACTIONS + '_VIP_' + 'getVIPProductList');

const getQuickPayTurnList = createAsyncAction(PREFIX_ACTIONS + 'getQuickPayTurnList');

const updateQuickPayTurnList = createAsyncAction<IUpdateQuickPayTurnItem[]>(
  PREFIX_ACTIONS + 'updateQuickPayTurnList'
);

const settingServiceActions = {
  serviceActs,
  clickCate,
  clickAddOnService,
  editCategory,
  createCategory,
  createOrUpdateServiceAddOn,
  editServiceAddOn,
  addMoreServiceByCate,
  getListCategoriesForProduct,
  createCategoryForProduct,
  deleteCategoryForProduct,
  updateNameCategoryForProduct,
  getProductsByCategoryId,
  getProductsPageable,
  createProduct,
  cateForProductSelected,
  updateProduct,
  reloadProduct,
  deleteProduct,
  getVIP,
  reloadVIPs,
  createVIP,
  updateVIP,
  deleteVIP,
  changeCommissionPrice,
  reloadCategory,
  getTurnSetting,
  updateTurnSettingTiket,
  reloadTurnSetting,
  updateTurnSettingService,
  deleteTurnSettingService,
  getLateForWork,
  updateLateForWork,
  reloadLateForWork,
  getQuickPayList,
  updateQuickPayList,
  getSettingGeneral,
  deleteCategoriesTabService,
  getTax,
  getDiscount,
  getSpecialDiscount,
  getLoyaltyReward,
  getOtherPaymentMethod,
  getPolicyTerm,
  getGiftCard,
  postTax,
  postDiscount,
  postSpecialDiscount,
  postLoyalty,
  postOtherPaymentMethod,
  postGiftCard,
  getNotification,
  postNotification,
  getScheduler,
  postScheduler,
  deleteAddOnService,
  resetServiceSetting,
  getDayOffList,
  postDayOffList,
  deleteDayOffList,
  deleteMultiple,
  getVIPProductList,
  getQuickPayTurnList,
  updateQuickPayTurnList,
};

export default settingServiceActions;
