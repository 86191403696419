import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import { IGiftCardPaymentValue } from 'features/cashier/services/types/giftCard';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import ModalPaymentGiftCard, { ModalPaymentGiftCardRefs } from 'features/payment/pages/PaymentPage/CashierSide/components/GiftCard/ModalPaymentGiftCard';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { useSocketContext } from 'hooks/useSocket';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import CashierActionButton from '../CashierActionBtn';
import useGetDetailTicket from '../useGetDetailTicket';
import useUpdateTicket from '../useUpdateTicket';
type Props = {
  v2?: boolean;
};
const ButtonGiftCard = ({ v2 }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const updateTicket = useUpdateTicket();
  const ticketContext = useTicketWrapper();
  const socketContext = useSocketContext();
  const completedTicketModalRef = useConfirmTipTicketRef();

  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const refModal = useRef<ModalPaymentGiftCardRefs>(null);

  const [detailTicket, getDetailTicket] = useGetDetailTicket();

  const [visible, setVisible] = useState(false);

  const onLoyalty = async () => {
    try {
      const rest: { billId: string } | null = await updateTicket();
      if (!rest) return;

      const billId = rest.billId;
      const detail = await getDetailTicket(billId);
      if (!detail) return;
      socketContext.switchCusPayment(billId);
      setVisible(true);
      setTimeout(() => refModal.current?.setAmount(0), 100);
    } catch (error) { }
  };

  const handleRedeem = async (val: IGiftCardPaymentValue) => {
    if (!detailTicket) return;
    if (val.amount < (detailTicket.total)) {
      dispatch(multiplePaymentActions.setDraftMultiPayment({ [PAYMENT_TYPE.GIFT_CARD]: val }));
      dispatch(multiplePaymentActions.setActivePayment(PAYMENT_TYPE.CASH));
      navigate(`/store/${storage.shop_id.get()}/ticket/payment/cashier-side/${detailTicket.billId}`);
      return;
    }

    const body: IBodyAPIPayment = {
      billId: detailTicket.billId,
      paymentInfo: [
        {
          paymentType: PAYMENT_TYPE.GIFT_CARD,
          amount: val.amount,
          giftCardId: val.giftCardId,
        },
      ]
    };

    loadingRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        loadingRef.current?.setVisible(false);
        socketContext.switchCusRating(detailTicket.billId);
        const callback = () => ticketContext.completedTicket(detailTicket?.billId, true);
        if (!completedTicketModalRef.current) callback();
        else completedTicketModalRef.current.open(detailTicket.billId, callback);
      } else {
        loadingRef.current?.setVisible(false);
      }
    } catch (error) {
      loadingRef.current?.setVisible(false);
    }
  };

  const handleClose = () => {
    refModal.current?.reset();
    setVisible(false);
    socketContext.backToCheckIn();
  };

  return (
    <div style={{ alignSelf: 'stretch', display: 'flex' }}>
      <CashierActionButton v2UI={v2} feature='GIFT-CARD' onClick={onLoyalty} ntype='YELLOW' label='Gift Card' />
      <ModalPaymentGiftCard
        v2={v2}
        ref={refModal}
        visible={visible}
        onClose={handleClose}
        onSubmit={handleRedeem}
        total={detailTicket?.total || 0}
      />
      <PaymentLoading ref={loadingRef} />
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </div>
  );
};

export default ButtonGiftCard;
