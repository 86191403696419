import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep, first } from 'lodash';
import shopActions from 'services/shop/actions';
import { ICategoryItemData } from 'services/shop/types/categories';
import actions from './actions';
import { NAME_REDUCER, ThemeHomeCheckIn } from './constants';
import { IStaff } from './types/demoData';
import { IState } from './types/reducer';
import storage from 'utils/sessionStorage';

const initialState: IState = {
  checkedIn: {
    status: 200,
    message: 'Success',
    pageable: null,
    data: null
  },
  phoneNumber: '',
  formSignUp: {
    name: '',
    phone: '',
    birthday: '',
    email: '',
  },
  staffList: [],
  staffListCondition: [],
  walkIn: {
    type: '',
    numOfMembers: 0
  },
  signedUp: {
    status: 500,
    message: 'Fail',
    pageable: null,
    data: null,
  },
  isConfirmWalkIn: false,
  appointmentDetail: null,
  activeCategory: null,
  firstCategory: null,
  selectedServices: [],
  selectedStaff: null,
  activeSimpleMenu: false,
  isModelFullSimpleMenu: false,
  warningBanCustomerMessage: null,
  googleReview: null,
  homeTheme: storage.check_in_theme.get() as ThemeHomeCheckIn,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getHomeTheme.success, (state, { payload }) => {
        state.homeTheme = payload.themeTemplate;
      })
      .addCase(actions.getGoogleReview.success, (state, { payload }) => {
        state.googleReview = payload;
      })
      .addCase(actions.setWarningBanCustomerMessage, (state, { payload }) => {
        state.warningBanCustomerMessage = payload;
      })
      .addCase(actions.activeSimpleMenu, (state, { payload }) => {
        state.activeSimpleMenu = payload;
      })
      .addCase(actions.chooseStaff, (state, { payload }) => {
        if (state.selectedStaff?.staffId === payload?.staffId)
          state.selectedStaff = null;
        else {
          state.selectedStaff = payload;
        }
      })
      .addCase(actions.resetForm, (state, { payload }) => {
        switch (payload) {
          case 'services':
            state.selectedServices = [];
            state.activeSimpleMenu = false;
            break;
          case 'all': {
            state.selectedServices = [];
            state.selectedStaff = null;
            state.activeCategory = cloneDeep(state.firstCategory);
            state.isConfirmWalkIn = false;
            state.activeSimpleMenu = false;
            state.checkedIn = {
              status: 200,
              message: 'Success',
              pageable: null,
              data: null
            };
            state.formSignUp = {
              name: '',
              phone: '',
              birthday: '',
              email: '',
            };
            state.walkIn = {
              type: '',
              numOfMembers: 0
            };
            break;
          }
          default:
            break;
        }
      })
      .addCase(actions.removeService, (state, { payload }) => {
        const idx = state.selectedServices.findIndex(o => o.id === payload.id);
        if (idx !== -1) state.selectedServices.splice(idx, 1);
      })
      .addCase(actions.addService, (state, { payload }) => {
        state.selectedServices.push(payload);
      })
      .addCase(actions.setActiveCategory, (state, { payload }) => {
        state.activeCategory = payload;
        state.activeSimpleMenu = false;
      })
      .addCase(shopActions.get.categories.success, (state, { payload }) => {
        const dataList = (payload as ICategoryItemData[]).filter(o => !!o.enableCheckIn);
        const firstItem = first(dataList) || null;
        state.firstCategory = firstItem;
        state.activeCategory = firstItem;

        state.isModelFullSimpleMenu = !dataList?.length;
      })
      .addCase(actions.checkIn.success, (state, { payload }) => {
        state.checkedIn = payload;
        state.signedUp = {
          status: 500,
          message: 'Fail',
          pageable: null,
          data: null,
        };
      })
      .addCase(actions.setPhoneNumber, (state, { payload }) => {
        state.phoneNumber = payload;
      })
      .addCase(actions.setInitCheckIn, (state) => {
        state.checkedIn = cloneDeep(initialState['checkedIn']);
        state.formSignUp = cloneDeep(initialState['formSignUp']);
      })
      .addCase(actions.setInputValue, (state, { payload }) => {
        state.formSignUp[payload.label] = payload.value;
      })
      .addCase(actions.getStaffList.success, (state, { payload }) => {
        const data = payload as IStaff[] ?? [];
        const target: IStaff[] = [];
        data.forEach(e => {
          const item = state.staffList.find(staff => staff.id === e.id);
          if (item) {
            target.push(item);
          } else {
            target.push(e);
          }
        });
        state.staffList = target;
      })
      .addCase(actions.getStaffListCondition.success, (state, { payload }) => {
        state.staffListCondition = payload?.data ?? [];
      })
      .addCase(actions.setWalkInData, (state, { payload }) => {
        state.walkIn = payload;
      })
      .addCase(actions.signUp.success, (state, { payload }) => {
        state.signedUp = payload;
      })
      .addCase(actions.signUp.fail, (state, { payload }) => {
        state.signedUp = payload;
      })
      .addCase(actions.confirmWalkIn.success, (state, { payload }) => {
        state.isConfirmWalkIn = payload;
      })
      .addCase(actions.confirmWalkIn.fail, (state, { payload }) => {
        state.isConfirmWalkIn = payload;
      })
      .addCase(actions.getAppointmentDetail.success, (state, { payload }) => {
        state.appointmentDetail = payload;
      })
      .addCase(actions.getAppointmentDetail.fail, (state, { payload }) => {
        state.appointmentDetail = payload;
      })
      .addCase(actions.initConfirmWalk, (state) => {
        state.isConfirmWalkIn = false;
      })
      ;
  },
});

const checkInServiceReducer = Slice.reducer;
export default checkInServiceReducer;
