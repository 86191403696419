import Box from 'components/Box';
import DatePickerPeriodPure from 'components/DatePickerPeriod/DatePickerPeriodPure';
import GroupButtons from 'components/GroupButtons';
import InputSearch from 'components/InputSearch';
import MultipleSelect from 'components/MultipleSelect';
import StaffSelector from 'features/report/components/StaffSelector';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { CALENDAR_PICKER_TYPE } from 'features/settingStaff/services/constants';
import { useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import useDateFilter from './useDateFilter';
const options = [
  {
    label: 'Daily',
    value: CALENDAR_PICKER_TYPE.DATE,
  },
  {
    label: 'Weekly',
    value: CALENDAR_PICKER_TYPE.WEEK,
  },
  {
    label: 'Monthly',
    value: CALENDAR_PICKER_TYPE.MONTH,
  },
];
const starOptions = [
  { label: '1 Star', value: '1' },
  { label: '2 Star', value: '2' },
  { label: '3 Star', value: '3' },
  { label: '4 Star', value: '4' },
  { label: '5 Star', value: '5' },
];

const allStar = starOptions.map(o => o.value);
type Props = {
  v2?: boolean;
};
const ReviewTableFilter = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const params = reportSelectors.getReviewReportParams();
  const [stars, setStars] = useState<string[]>(allStar);
  const staffData = shopSelectors.data.staffs()?.filter(f => !f.anyBody);
  const [selectedStaff, setSelectedStaff] = useState<IStaffItemData | null>(null);
  const dateFilter = useDateFilter();

  const handleSearchText = (val: string) => {
    dispatch(reportActions.setReviewReportParams({ customerName: val ? val : undefined }));
  };

  const onChangeStaffSelected = (value: IStaffItemData | null) => {
    setSelectedStaff(value);
    dispatch(reportActions.setReviewReportParams({ staffId: value ? value?.id : undefined }));
  };

  const handleChangeStar = (val: string[]) => {
    setStars(val);
    const isAll = val?.length === allStar?.length;

    dispatch(reportActions.setReviewReportParams({ stars: isAll ? undefined : val.map(o => +o) }));
  };

  return (
    <Wrapper className={v2 ? 'v2' : ''}>
      <InputSearch
        style={{ flex: 1 }}
        onChangeText={handleSearchText}
        placeholder='Customer name/phone' />
      <StaffSelector
        size='middle'
        data={staffData}
        selected={selectedStaff}
        onChange={onChangeStaffSelected} />
      <MultipleSelect
        value={stars}
        width='300px'
        height={'3.5rem'}
        elementName='star'
        onChange={handleChangeStar}
        options={starOptions}
      />
      {v2 ?
        <Box display='flex' gap='2'>
          <GroupButtons
            v2
            options={options}
            onChange={dateFilter.handleChangeTypeView}
            value={dateFilter.viewType?.toString()}
          />
          <DatePickerPeriodPure
            v2
            onChange={dateFilter.handleChangeDate}
            picker={dateFilter.viewType as CALENDAR_PICKER_TYPE}
            data={{ startDate: params?.startDate || '', endDate: params?.endDate || '' }}
          />
        </Box>
        :
        <Box display='flex' gap='2'>
          <DatePickerPeriodPure
            onChange={dateFilter.handleChangeDate}
            picker={dateFilter.viewType as CALENDAR_PICKER_TYPE}
            data={{ startDate: params?.startDate || '', endDate: params?.endDate || '' }}
          />
          <GroupButtons
            options={options}
            onChange={dateFilter.handleChangeTypeView}
            value={dateFilter.viewType?.toString()}
          />
        </Box>
      }
    </Wrapper>
  );
};

export default ReviewTableFilter;

const Wrapper = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

&.v2 {
  .ant-input {
    color: #1D2129;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .ant-select .ant-select-selector {
    .ant-select-selection-item {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      height: auto;
    }
  }
}
`;