import React from 'react';
import styled from 'styled-components';
import Alarm from './Alarm';
import sort_check_in_icon from './icons/sort-check-in.svg';
import quick_booking_icon from './icons/quick-booking.svg';
import qr_scan_icon from './icons/qr-scan.svg';
import merge_icon from './icons/merge.svg';
import delete_icon from './icons/delete.svg';
import clock_in_icon from './icons/clock-in.svg';
import clock_out_icon from './icons/clock-out.svg';
import key_in_icon from './icons/key-in.svg';
import ticket_icon from './icons/ticket.png';
import quick_view_icon from './icons/quick-view.png';
import { useReOrderCheckInModal } from '../ReOrderClockIn';
import ButtonQuickBooking from '../Buttons/ButtonQuickBooking';
import ButtonScanQR, { useButtonScanQRRef } from 'widgets/ButtonScanQR';
import { useHomeMergeTicket } from 'features/user/widgets/WaitingList/Ticket/ButtonMergeTicket';
import userActions from 'features/user/services/actions';
import { useAppDispatch } from 'store/hooks';
import userSelectors from 'features/user/services/selectors';
import clsx from 'clsx';
import ButtonClockIn from '../Buttons/ButtonClockIn';
import { useNavigate } from 'react-router-dom';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import storage from 'utils/sessionStorage';
import { useAddCloseTicket } from 'features/ticketList/widgets/AddCloseTicket';
import ModalAddCloseTicket from 'features/ticketList/widgets/AddCloseTicket/ModalAddCloseTicket';

const HomeV2Header = () => {
  return (
    <Container>
      <LeftBox>
        <div className="station-box"><span>Station {storage.station_number.get()}</span></div>
        <Alarm />
      </LeftBox>
      <RightBox>
        <ButtonKeyIn />
        <ButtonNavPin required title='Tickets' src={ticket_icon} alt='ticket_icon' path='/tickets' />
        <ButtonNavPin required={false} title='Quick View' src={quick_view_icon} alt='quick_view_icon' path='/quick-view-tickets' />
        <ButtonScanTicketV2 />
        <ButtonReOrderV2 />
        <ButtonQuickBookingV2 />
        <ButtonMergeTicketV2 />
        <ButtonDeleteV2 />
        <ButtonClockInOutV2 isClockIn />
        <ButtonClockInOutV2 isClockIn={false} />
      </RightBox>
    </Container>
  );
};

export default HomeV2Header;
const ButtonKeyIn = () => {
  const { onAdd, modal, onOpen } = useAddCloseTicket({});
  return (
    <>
      <button className="btn" onClick={onOpen}>
        <img src={key_in_icon} alt={'key_in_icon'} className="icon-action" />
        <span>Key-In</span>
      </button>
      <ModalAddCloseTicket v2 ref={modal} onSubmit={onAdd} />
    </>
  );
};
const ButtonNavPin = ({ src, alt, path, required = true, title }: { title: string, src: any, alt: string, path: string, required?: boolean }) => {
  const navigate = useNavigate();
  const [visiblePW, setVisiblePW] = React.useState(false);

  const onOpen = () => {
    if (required) return setVisiblePW(true);
    navigate(`/store/${storage.shop_id.get()}${path}`);
  };

  const onVerifyAdminSuccess = () => {
    if (visiblePW) navigate(`/store/${storage.shop_id.get()}${path}`);
    setVisiblePW(false);
  };

  return (
    <>
      <button className="btn" onClick={onOpen}>
        <img src={src} alt={alt} className="icon-action" />
        <span>{title}</span>
      </button>
      {!!visiblePW && (
        <RequestAdminLocalPW
          v2
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setVisiblePW(false)}
        />
      )}
    </>
  );
};

const ButtonClockInOutV2 = ({ isClockIn }: { isClockIn: boolean }) => {
  return <ButtonClockIn v2 isClockIn={isClockIn} render={(onPress) => (
    <button className="btn" onClick={onPress}>
      <img src={isClockIn ? clock_in_icon : clock_out_icon} alt={isClockIn ? 'clock_in_icon' : 'clock_out_icon'} className="icon-action" />
      <span>{isClockIn ? 'Clock-In' : 'Clock-Out'}</span>
    </button>
  )} />;
};

const ButtonDeleteV2 = () => {
  const activeRemoveTicket = userSelectors.activeRemoveTicket();
  const dispatch = useAppDispatch();
  const onToggle = () => {
    dispatch(userActions.activeRemoveTicket(!activeRemoveTicket));
    dispatch(userActions.activeMergeTicket(false));
    dispatch(userActions.activeRemoveWaiting(!activeRemoveTicket));
  };
  return (
    <button className={clsx('btn', !!activeRemoveTicket && 'active')} onClick={onToggle}>
      <img src={delete_icon} alt="delete_icon" className={'icon-action'} />
      <span>Delete Ticket</span>
    </button>
  );
};

const ButtonMergeTicketV2 = () => {
  const { activeMergeTicket, enableMergeTicket, refreshMergeTicket } = useHomeMergeTicket();
  return (
    <button className={clsx('btn', !!enableMergeTicket && 'active')} onClick={() => enableMergeTicket ? refreshMergeTicket() : activeMergeTicket(true)}>
      <img src={merge_icon} alt="merge_icon" className="icon-action" />
      <span>Merge Ticket</span>
    </button>
  );
};

const ButtonScanTicketV2 = () => {
  const qrScanRef = useButtonScanQRRef();
  return (
    <>
      <button className="btn" onClick={() => qrScanRef.current?.scan()}>
        <img src={qr_scan_icon} alt="qr_scan_icon" className="icon-action" />
        <span>Scan Ticket</span>
      </button>
      <ButtonScanQR ref={qrScanRef} hook />
    </>
  );
};

const ButtonReOrderV2 = () => {
  const [handleOpen, context] = useReOrderCheckInModal(true);
  return (
    <>
      <button className="btn" onClick={handleOpen}>
        <img src={sort_check_in_icon} alt="sort_check_in_icon" className="icon-action" />
        <span>Sort Check-in</span>
      </button>
      {context}
    </>
  );
};

const ButtonQuickBookingV2 = () => {
  return (
    <>
      <ButtonQuickBooking render={(onPress) =>
        <button className="btn" onClick={onPress}>
          <img src={quick_booking_icon} alt="quick_booking_icon" className="icon-action" />
          <span>Quick booking</span>
        </button>} />
    </>
  );
};

const Container = styled.div`
display: flex;
align-self: stretch;
background: #fff;
`;
const LeftBox = styled.div`
display: flex;
background: #232F3E;
align-items: center;
justify-content: space-between;
padding: 24px;
gap: 24px;
margin-bottom: 8px;
min-width: 800px;
.station-box {
display: flex;
height: 48px;
padding: 0px 16px;
justify-content: center;
align-items: center;
gap: 10px;
border-radius: 5px;
background: #FFF;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}

.today-label {
  display: flex;
  .digital {
    display: inline-flex;
    align-items: center;
  }
  .digital p {
    color: #FBE696;
    font-size: 55px;
  }
}
  .clock .hour {
    line-height: unset;
  }
  .clock .hour .ampm,
  .clock .hour .digital :last-child {
    color: #FBE696;
    font-weight: 600;
  }
`;
const RightBox = styled.div`
display: flex;
padding: 0;
padding-left: 8px;
padding-bottom: 8px;
align-items: center;
flex-shrink: 0;
flex: 1;
background: #fff;
button.btn {
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2px;
  flex: 1 0 0;
  align-self: stretch;
  background: #FCFCFD;
  box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
  img.icon-action {
    display: flex;
    width: 80px;
    height: 80px;
    justify-content: center;
    align-items: center;
  }
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  &:hover {
    background: #eee;
  }

  &.active {
    background: #FFE5E7;
  }
}
`;