import { Input, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import customerActions from 'features/customer/services/actions';
import customerApis from 'features/customer/services/apis';
import customerSelectors from 'features/customer/services/selectors';
import React, { useRef, useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';

const CustomerNote = () => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<InputRef>(null);
  const [open, setOpen] = useState(false);
  const customerDetails = customerSelectors.getCustomerDetails();
  const [note, setNote] = useState('');
  const setLoadingPage = useSetLoadingPage();

  const handleClose = () => {
    setNote('');
    setOpen(false);
  };

  const onModal = () => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 10);
    setOpen(true);
    setNote(customerDetails?.note || '');
  };

  const handleSubmit = async () => {
    handleClose();
    if (!customerDetails) return;
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await customerApis.editCustomerNote(customerDetails.id, note);
      if (res.data.data) {
        dispatch(customerActions.getCustomerDetails.fetch(customerDetails.id));
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const NoteComponent = () => {
    if (!customerDetails?.note) {
      return (
        <div style={{ margin: '0.5rem 0' }}>
          <Row align={'middle'} style={{ gap: '4px', marginBottom: '4px' }}>
            <Text variant='CONTENT_2' style={{ fontSize: '18px' }} mr={0.5}>Note </Text>
            <button onClick={e => [e.preventDefault(), onModal()]} style={{ padding: '0 4px', marginTop: '2px' }}>
              <Icon type={'edit'} fill='#6FABB6' />
            </button>
          </Row>
        </div>
      );
    }

    return (
      <div style={{ margin: '0.5rem 0' }}>
        <Row align={'middle'} style={{ gap: '4px', marginBottom: '4px' }}>
          <Text variant='CONTENT_2' style={{ fontSize: '18px' }} mr={0.5}>Note </Text>
          <button onClick={e => [e.preventDefault(), onModal()]}>
            <Icon type='edit' fill='#6FABB6' />
          </button>
        </Row>
        <Text style={{ backgroundColor: '#C6E9EF', padding: '0.5rem 0.5rem', minHeight: '40px' }} variant='CONTENT_2'>{customerDetails?.note} </Text>
      </div>
    );
  };

  return (
    <>
      <NoteComponent />
      <Modal
        headerBgColor={colorTheme.info_bg_3}
        visible={open}
        noneBodyStyle
        noneFooterStyle
        containerPadding={1.5}
        modalTitle='NOTE'
        className='modal-add-new-appointment modal-max-height-unset'
        width={680}
        onClose={handleClose}
        onSubmit={handleSubmit}
        showClose
      >
        <Input.TextArea
          ref={inputRef}
          rows={3}
          placeholder='Enter text ...'
          value={note}
          onChange={e => setNote(e.target?.value)}
        />
      </Modal>
    </>
  );
};

export default CustomerNote;
