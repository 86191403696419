import { get } from 'lodash';
import uiSelector from 'services/UI/selectors';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { CHECK_IN_TYPES, PATH_LOADING } from './constants';
import { IFilterDataParams } from './types/api';
import { IWaitingListResItem } from './types/waiting';
import { IClockInOutResData, IHistoryClockInOutContentItem } from './types/clock-in-out';
import shopSelectors from 'services/shop/selectors';

type MyState = RootState['user'];
const getCurrentState = (state: RootState): MyState => state['user'];

export const getPureFilterParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.filterParams ?? {};
};

export const getPureHistoryFilterParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.history.params ?? {};
};

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getWaitingListData = () => selector('waitingListData') as IWaitingListResItem[];

const getListWalkIn = () => {
  const waitingList = getWaitingListData();
  return waitingList?.filter(o => o.checkInType === CHECK_IN_TYPES.walkIn);
};

const getListAppointment = () => {
  const waitingList = getWaitingListData();
  return waitingList?.filter(o => o.checkInType !== CHECK_IN_TYPES.walkIn);
};

const getSummaryData = () => selector('summaryData') as MyState['summaryData'];

const getBillWaitingTickets = () => selector('billWaitingTickets') as MyState['billWaitingTickets'];

const getFilterParams = () => selector('filterParams') as MyState['filterParams'];

const getFilterParamValue = (key: keyof IFilterDataParams) => selector(`filterParams.${key}`) as IFilterDataParams[typeof key];

const getWaitingListLoading = () => uiSelector.getLoading(PATH_LOADING.getWaitingList) as boolean;
const getBillWaitingTicketLoading = () => uiSelector.getLoading(PATH_LOADING.getBillWaitingTicket) as boolean;
const getSummaryDataLoading = () => uiSelector.getLoading(PATH_LOADING.getSummaryData) as boolean;

const historyClockInOut = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getHistoryClockInOut) as boolean,
  params: () => selector('history.params') as { page: number; size: number; },
  data: () => selector('history.data') as IHistoryClockInOutContentItem[],
  totalSize: () => selector('history.total') as number | null,
};

const staff = {
  isClockIn: () => selector('staff.clockIn') as boolean,
  detail: () => selector('staff.detail') as IClockInOutResData,
  availableStaffs: () => shopSelectors.data.staffsAvailable(),
};

const activeRemoveTicket = () => selector('activeRemoveTicket') as boolean;
const activeRemoveWaiting = () => selector('activeRemoveWaiting') as boolean;

const listAvailableWorking = () => selector('listAvailableWorking') as MyState['listAvailableWorking'];

const homeLayout = () => selector('homeLayout') as MyState['homeLayout'];

const enableMergeTicket = () => selector('enableMergeTicket') as MyState['enableMergeTicket'];

const mergeTicketsList = () => selector('mergeTicketsList') as MyState['mergeTicketsList'];

const homeSort = () => selector('homeSort') as MyState['homeSort'];

const appointmentToday = () => selector('appointmentToday') as MyState['appointmentToday'];

const enableChooseEmployeeQueue = () => selector('enableChooseEmployeeQueue') as MyState['enableChooseEmployeeQueue'];

const userSelectors = {
  activeRemoveTicket,
  activeRemoveWaiting,
  getWaitingListData,
  getListWalkIn,
  getListAppointment,
  getSummaryData,
  getBillWaitingTickets,
  getFilterParams,
  getFilterParamValue,
  staff,
  getWaitingListLoading,
  getBillWaitingTicketLoading,
  getSummaryDataLoading,
  historyClockInOut,
  listAvailableWorking,
  homeLayout,
  enableMergeTicket,
  mergeTicketsList,
  homeSort,
  appointmentToday,
  enableChooseEmployeeQueue,
};
export default userSelectors;
