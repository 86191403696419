import MultipleSelect from 'components/MultipleSelect';
import appointmentActions from 'features/appointment/services/actions';
import { APPOINTMENT_STATUS, appointmentStatusItemTypes } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import React from 'react';
import { useAppDispatch } from 'store/hooks';

type ITypeItemPickerProps = any;
const AptStatusFilter: React.FC<ITypeItemPickerProps> = () => {
  const dispatch = useAppDispatch();
  const selectedOptions = appointmentSelectors.getAppointmentStatusFilter();

  const setSelectedOptions = (val: any[]) => {
    dispatch(appointmentActions.setAppointmentStatusFilter(val as APPOINTMENT_STATUS[]));
  };

  const handleChange = (value: any[]) => {
    setSelectedOptions(value);
  };

  return (
    // @ts-ignore
    <MultipleSelect
      className='AptStatusFilter'
      value={selectedOptions}
      onChange={handleChange}
      options={appointmentStatusItemTypes}
    />
  );
};

export default AptStatusFilter;
