import { Avatar, Row } from 'antd';
import clsx from 'clsx';
import AnyBodyImage from 'components/AnyBodyImage';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import paymentActions from 'features/payment/services/actions';
import { IBillWaitingTicketResItem } from 'features/user/services/types/ticket';
import { first } from 'lodash';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
type ITicketItemProps = {
  data: IBillWaitingTicketResItem;
  viewOnly?: boolean;
  removeMode?: boolean;
  onRemoveTicket?: () => void;
  enableMergeTicket?: boolean;
  mergeTicketsList?: string[];
  onSelectMergeTicket?: () => void;
  cashier?: boolean;
};
const TicketItem: React.FC<ITicketItemProps> = ({ cashier, data, viewOnly, removeMode, onRemoveTicket = () => undefined, enableMergeTicket, mergeTicketsList = [], onSelectMergeTicket = () => undefined }) => {
  const navigate = useNavigate();
  const enablePrintCheckIn = true;
  const dispatch = useAppDispatch();

  const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    if (enableMergeTicket) {
      e.preventDefault();
      onSelectMergeTicket();
      return;
    }
    if (viewOnly) return;
    if (!data) return;
    navigate(`/store/${storage.shop_id.get()}/ticket/edit/${data.billId}`);
  };

  const StaffAvatar = ({ cashier }: { cashier?: boolean }) => {
    const staff = first(data.staffs);
    if (!staff) return <AnyBodyImage />;
    const length = data.staffs?.length || 0;
    let width = cashier ? '65px' : '55px';
    if (length === 2) {
      width = cashier ? '85px' : '65px';
    } else if (length > 2) {
      width = cashier ? '110px' : '75px';
    }
    return (
      <AvatarGroup style={{ width }} className={cashier ? 'cashier' : ''}>
        <div className="avatar-group-list">
          {data.staffs.slice(0, 3)?.map(o => <Avatar key={o.staffId} size={cashier ? 65 : 55} src={o.avatar} >{o.staffName?.[0] || 'A'}</Avatar>)}
        </div>
      </AvatarGroup>
    );
  };

  const handlePrint = (e: any) => {
    e.stopPropagation();
    // @ts-ignore
    dispatch(paymentActions.getInfoPrintWaiting.fetch({ body: JSON.stringify({ id: data.billId, type: 'PENDING_TICKET' }) }));
  };

  if (!data) return null;
  return (
    <TicketItemStyled onClick={onClick} appointment={data.isAppointment}>
      <Box display='flex'>
        <Box display='flex' flexDirection='column' alignItems='start' style={{ flex: 1 }} className='text-overflow'>
          <TicketNumber variant='BODY_2' color='text_3' style={{ fontSize: cashier ? '2rem' : '1.7rem' }}>#{data.ticketNumber}</TicketNumber>
          <div style={{ maxWidth: '100%' }}>
            <Text variant='BODY_2' color='text_3' className='customer-name text-overflow' style={cashier ? { fontSize: 22 } : undefined}>{data.customerName || 'Unknown'}</Text>
          </div>
        </Box>
        <div>
          <StaffAvatar cashier={cashier} />
        </div>
      </Box>
      <Box display='flex'>
        <Box display='flex' flexDirection='column' alignItems='start' style={{ flex: 1 }} className='text-overflow'>
          <div style={{ maxWidth: '100%' }}>
            <Text variant='BODY_2' color='text_3' className='staff-name text-overflow' style={cashier ? { fontSize: 18 } : undefined}>{data.staffs?.map(o => o.staffName).join(', ')}</Text>
          </div>
          <Text variant='BODY_2' color='text_3' className='ticket-price' style={cashier ? { fontSize: 18 } : undefined}>{formatCurrency(data.totalPrice)}</Text>
        </Box>
        <Box alignSelf='flex-end'>
          {
            enableMergeTicket ? (
              <Row align={'middle'} justify={'center'} className={clsx('circle-status', cashier && 'cashier')}>
                {mergeTicketsList.includes(data.billId) ? <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                  <path d="M6.35192 11.7369C6.03176 11.7369 5.72761 11.6088 5.5035 11.3847L0.973172 6.85445C0.508943 6.39022 0.508943 5.62184 0.973172 5.15761C1.4374 4.69338 2.20578 4.69338 2.67001 5.15761L6.35192 8.83943L14.58 0.611356C15.0442 0.147126 15.8126 0.147126 16.2768 0.611356C16.7411 1.07559 16.7411 1.84397 16.2768 2.30819L7.20034 11.3847C6.97622 11.6088 6.67207 11.7369 6.35192 11.7369Z" fill="#1D2129" />
                </svg> : null}
              </Row>
            ) :
              <>
                {removeMode ?
                  <Row justify={'center'} className='remove-row'>
                    <Button icon='deleteIconCashier' size='small' height={2} style={{ height: '2rem', padding: '0 4px' }} onClick={e => [e.stopPropagation(), e.preventDefault(), onRemoveTicket()]} />
                  </Row>
                  :
                  enablePrintCheckIn ?
                    <Row justify={'center'}>
                      <Button icon='print' size='small' height={2} style={{ height: '2rem', padding: '4px' }} onClick={handlePrint} />
                    </Row>
                    : null
                }
              </>
          }
        </Box>
      </Box>
    </TicketItemStyled>
  );
};

export default TicketItem;
type TicketItemStyledProps = {
  appointment?: boolean;
}
const TicketItemStyled = styled.button<TicketItemStyledProps>`
  height:100%;
  width:100%;
  border-radius: 5px;
  border: 1px solid #FDCC43;
  background: #feedbfab;
  padding: 3px;
  
  .ticket-header {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    margin-bottom: 0.5rem;
  }
  .ticket-body {
    text-align: center;
  }
  .remove-row {
    svg {
      width: 24px;
      height: 24px;
    }    
  }

  ${({ appointment }) => appointment && `
    background: #C6E9EF;
    border: 1px solid #6FABB6;
  `}

  .circle-status {
    background: #fff;
    border-radius: 4px;
    height: 32px;
    width: 32px;
    border:1px solid #C9CDD4;
    &.cashier {
      height: 48px;
      width: 48px;
      svg {
        transform: scale(1.5);
      }
    }
  }
  .staff-name {
    font-size: 16px;
    text-align: left;
    color: #F53F3F;
  }
  .ticket-price {
    font-size: 16px;
  }
`;
const TicketNumber = styled(Text)`
  display: inline-flex;
  padding: 1px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #1D2129;
  color: white;
`;

const AvatarGroup = styled.div`
  position: relative;
  max-width: 74px;
  min-width: 48px;
  height: 55px;
  .avatar-group-list {
    position: absolute;
    display:flex;
    align-items: center;
    flex-direction: row-reverse;  
  }
  .ant-avatar {
    margin-right: -45px;
    border: 2px solid #FF7D00;
    background-color: rgba(245, 106, 0, 0.3);
  }

  &.cashier {
    height: 75px;
    max-width: 110px;
    min-width: 65px;
  }
`;