import { Card, Form, InputRef, Row, Select } from 'antd';
import { UploadFile } from 'antd/lib';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import Text from 'components/Text';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';
import { IVipProductItem } from 'features/settingService/services/types/VIP';
import { IVIPs } from 'features/settingService/services/types/reducer';
import { useRef } from 'react';
import styled from 'styled-components';
import SwitchBtn from '../../SettingPage/components/ServicesSettingV2/SwitchBtn';
export interface IVIPsUpdate extends IVIPs {
  fileList: File[]
}
interface IVIPCard {
  value?: IVipProductItem;
  name?: any;
}

interface File extends UploadFile {
}

const VIPCard = ({ value, name }: IVIPCard) => {
  const inputPerRef = useRef<InputRef>(null);
  const inputDolRef = useRef<InputRef>(null);
  const handleChangeType = (e: DISCOUNT_VALUE_TYPE) => {
    setTimeout(() => {
      if (e === DISCOUNT_VALUE_TYPE.DOLLAR) {
        inputDolRef.current?.focus();
      } else {
        inputPerRef.current?.focus();
      }
    }, 100);
  };

  return (
    <CardStyled>
      <RowCenter style={{ marginBottom: '16px' }}>
        <ImagePreviewBox>
          <ImagePreview alt="preview" src={value?.urlImage} />
        </ImagePreviewBox>
      </RowCenter>
      <RowBetween>
        <Text variant='CONTENT_1' color='text_2'>Enable</Text>
        <Form.Item noStyle name={[name, 'active']}>
          <SwitchBtn />
        </Form.Item>
      </RowBetween>
      <RowBetween>
        <Text variant='CONTENT_1' color='text_2'>Price</Text>
        <Form.Item noStyle name={[name, 'yearlyPrice']}>
          <CurrencyInputPrecision width={'200px'} height={'40px'} />
        </Form.Item>
      </RowBetween>
      <RowBetween>
        <Text variant='CONTENT_1' color='text_2'>Discount</Text>
        <DiscountWrapper >
          <Form.Item noStyle name={[name, 'discountType']}>
            <Select placeholder='%' options={[
              { label: '%', value: DISCOUNT_VALUE_TYPE.PERCENT },
              { label: '$', value: DISCOUNT_VALUE_TYPE.DOLLAR },
            ]}
              onChange={handleChangeType}
            />
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => {
              const discountType = getFieldValue(['listVIP', name, 'discountType']);
              return (
                <Form.Item noStyle name={[name, 'discountValue']}>
                  {(discountType === DISCOUNT_VALUE_TYPE.PERCENT) ?
                    <PercentDecimalInputPrecision inputRef={inputPerRef} width={'150px'} height={'38px'} />
                    : <CurrencyInputPrecision inputRef={inputDolRef} width={'150px'} height={'38px'} />}
                </Form.Item>
              );
            }}
          </Form.Item>
        </DiscountWrapper>
      </RowBetween>
      <RowBetween>
        <Text variant='CONTENT_1' color='text_2'>Expires Date</Text>
        <DiscountWrapper>
          <div style={{ width: '6rem' }}></div>
          <Form.Item noStyle name={[name, 'expiresDate']}>
            <Select style={{ width: '160px' }} options={items} />
          </Form.Item>
        </DiscountWrapper>
      </RowBetween>
    </CardStyled>
  );
};

export default VIPCard;

const items = [
  {
    label: '30 days',
    value: 30,
  },
  {
    label: '60 days',
    value: 60,
  },
  {
    label: '90 days',
    value: 90,
  },
  {
    label: '180 days',
    value: 180,
  },
  {
    label: '365 days',
    value: 365,
  },
  {
    label: 'Unlimit',
    value: -1,
  },
];
const CardStyled = styled(Card)`
    width: 400px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    border-radius: 5px;
    background: var(--fill-fill-1, #F6F7FC);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    .ant-card-body {
        padding: 0px !important;
        padding-top: 10px !important;
    }

    span {
      color: #1D2129;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
  }
  .ant-input-number {
      .ant-input-number-handler-wrap {
      display: none;
      }
      .ant-input-number-input-wrap {
      height: 100%;
      input {height: 100%;}
      }
  }
  input, .ant-input-suffix {
      color: #1D2129;
      text-align: right;
      font-family: "Open Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
  }
  .ant-select {
    height: 38px;
    width: calc(200px - 6rem);
    span.ant-select-selection-item {
      color: #1D2129;
      font-family: "Open Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
    }
  }
`;

const DiscountWrapper = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
width: 200px;
.ant-select {
  height: 38px;
  width: 6rem;
}
`;

const RowCenter = styled(Row)`
  justify-content: center;
  div{
    margin: 0 !important;
  }
`;

const ImagePreviewBox = styled.div`
    padding: 0 16px;
    border-radius: 8px;
    background: #000;
`;
const ImagePreview = styled.img`
    width: 100%;
    height: 300px;
    border-radius: 8px;
    object-fit: contain;
    object-position: center;
`;
const RowBetween = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 8px 15px;
    .ant-input-number-input-wrap {
        input{ 
            text-align: left !important;
        }
    }

    .btn-switch {
      padding: 0;
      .CONTENT_2 {
        gap: 0px;
      } 
    }
`;
