import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { SortableList } from 'features/settingService/components/SortableList';
import { useState } from 'react';
import { IServiceItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IServiceItemData[];
  onPreOrder: (ids: string[]) => void;
};
const ArrangeServices = ({ data = [], onPreOrder }: Props) => {
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState<{ id: string, name: string, price: number }[]>([]);

  const handleOpen = () => {
    setItems(data.map(o => ({ id: o.id, name: o.serviceName, price: o.priceSell })));
    setOpen(true);
  };

  const handleAccept = () => {
    setOpen(false);
    onPreOrder(items.map(o => o.id));
  };

  return (
    <>
      <Button size='small' height={3} px={1} icon='sortService' ntype='LIGHT_BLUE' onClick={handleOpen}>Pre-Order</Button>
      <Modal
        visible={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAccept}
        modalTitle='Pre-Order'
      >
        <SortableList
          items={items}
          onChange={setItems}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              <CateWrap>
                <Text variant="H7" color="text_3">
                  {item.name}
                </Text>
                <Text variant="CONTENT_1" color="text_2">
                  {formatCurrency(item.price)}
                </Text>
              </CateWrap>
            </SortableList.Item>
          )}
        />
      </Modal>
    </>
  );
};

export default ArrangeServices;

const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  .label {
    font-size: 18px;
    font-weight: 600;
  }
`;