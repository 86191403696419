import cashierApis from 'features/cashier/services/apis';
import cashierSelectors from 'features/cashier/services/selectors';
import ModalInputReasonVoid from 'features/ticketList/components/VoidButton/ModalReason';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import storage from 'utils/sessionStorage';
import CashierActionButton from '../CashierActionBtn';
type Props = {
  v2?: boolean;
};
const ButtonVoidTicket = ({ v2 }: Props) => {
  const [visible, setVisible] = useState(false);
  const onClose = () => setVisible(false);
  const navigate = useNavigate();
  const setLoading = useSetLoadingPage();
  const ticketDetailStore = cashierSelectors.getTicketDetails();

  const onVoidTicket = async () => {
    setVisible(true);
  };

  const handleVoidTicket = async (reason: string) => {
    if (!ticketDetailStore?.ticketInfo.billId) return;
    setLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await cashierApis.voidTicketOpen({
        billId: ticketDetailStore?.ticketInfo.billId,
        reason,
      });
      if (res.data.data) {
        navigate(`/store/${storage.shop_id.get()}`);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <CashierActionButton
        label='Void'
        onClick={onVoidTicket}
        ntype='ORANGE'
        v2UI={v2}
        feature='VOID'
        disabled={!!ticketDetailStore?.checkInId || !ticketDetailStore?.ticketInfo.billId}
      />
      <ModalInputReasonVoid v2={v2} visible={visible} onClose={onClose} onVoid={handleVoidTicket} />
    </>
  );
};

export default ButtonVoidTicket;
