import { Avatar, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Button from 'components/Button';
import Text from 'components/Text';
import { IApiCreateUpdateClockInOut, IApiDeleteClockInOut } from 'features/report/services/types/api';
import { ITimeClockReportHistory } from 'features/report/services/types/reportStaff';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import ModalDeleteTimeInOut from './ModalDeleteTimeInOut';
import ModalEditTimeInOut from './ModalEditTimeInOut';
import { formatCurrency } from 'utils/formatCurrency';
import { useAppDispatch } from 'store/hooks';
import reportSelectors from 'features/report/services/selectors';
import { ReportType } from '../../constants';
import reportActions from 'features/report/services/actions';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import { tableGreenStrStyle } from 'features/report/components/Component';

interface IStaffTableClock {
    data: ITimeClockReportHistory[],
    onEdit: (payload: IApiCreateUpdateClockInOut) => void,
    onDelete: (payload: IApiDeleteClockInOut) => void;
    v2?: boolean;
}
interface DataType extends ITimeClockReportHistory {
    id: string,
    staffName: string,
    createDated: any,
    workTimeHour: number,
    clockInTime: string,
    clockOutTime: string,
    urlImage: any
}
const StaffTableClock: React.FC<IStaffTableClock> = ({ data, onEdit, onDelete, v2, }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.CLOCK_TIME];
    const handlePreviewWithStaff = (o: DataType) => {
        if (!dataAppointment || !o?.staffId) return;
        dispatch(reportActions.getDashboardTimeClockReport.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: o?.staffId
        }));
        dispatch(reportActions.getTimeClockReportHistory.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: o?.staffId
        }));
        navigate(`/store/${storage.shop_id.get()}/report/print/time-clock/${o?.staffId}`);
    };

    const columns: ColumnsType<DataType> = [
        {
            title: 'STAFF',
            dataIndex: 'staffName',
            key: '1',
            width: 100,
            render: (_, record) => (
                <NameBox>
                    <ImageContainer>
                        <Avatar
                            src={record.urlImage}
                        >{record?.staffName?.charAt(0)}</Avatar>
                    </ImageContainer>

                    <Text variant="CONTENT_1" color="text_3">
                        {record.staffName}
                    </Text>
                </NameBox>
            ),
            align: 'center'
        },
        {
            title: 'DATE',
            dataIndex: 'createDated',
            key: '2',
            width: 100,
            render: (_, record) => <Text variant="CONTENT_1" color="text_3">{record?.createDated ? moment(record.createDated).format('MM-DD-YYYY') : '--/--/--'}</Text>,
            align: 'center'
        },
        {
            title: 'CLOCK IN',
            dataIndex: 'strClockInTime',
            key: '2',
            width: 100,
            render: (strClockInTime) => (
                <Text variant="CONTENT_1" color="text_3">{strClockInTime}</Text>),
            align: 'center'
        },
        {
            title: 'CLOCK OUT',
            dataIndex: 'strClockOutTime',
            key: '3',
            width: 100,
            render: (strClockOutTime) => (
                <Text variant="CONTENT_1" color="text_3">{strClockOutTime}</Text>),
            align: 'center'
        },
        {
            title: 'WORK TIME',
            dataIndex: 'workTimeHour',
            key: '4',
            width: 110,
            render: (_, record) => <Text variant="CONTENT_1" color="text_3">{record.workTimeHour.toString()}H</Text>,
            align: 'center'
        },
        {
            title: 'SALARY',
            dataIndex: 'commission',
            key: 'commission',
            width: 100,
            render: (value) => <Text variant="CONTENT_1" color="text_3">{formatCurrency(value)}</Text>,
            align: 'center'
        },
        {
            title: 'TIP',
            dataIndex: 'tip',
            key: 'tip',
            width: 100,
            render: (value) => <Text variant="CONTENT_1" color="text_3">{formatCurrency(value)}</Text>,
            align: 'center'
        },
        {
            title: 'DISCOUNT',
            dataIndex: 'discount',
            key: 'discount',
            width: 100,
            render: (value) => <Text variant="CONTENT_1" color="text_3">{formatCurrency(value)}</Text>,
            align: 'center'
        },
        {
            title: 'ACTIONS',
            width: 210,
            fixed: 'right',
            render: (_, record) => {
                return (
                    <Row style={{ gap: '12px' }}>
                        <Button ntype="LIGHT_BLUE" onClick={() => {
                            !dataEditModal && setDataEditModal(record);
                        }} icon='edit' style={{ width: 60, height: 50 }} />
                        <Button ntype="SECONDARY" onClick={() => {
                            !dataDeleteModal && setDataDeleteModal(record);
                        }} icon='deleteIcon' style={{ width: 60, height: 50 }} />
                        <Button ntype="SECONDARY" onClick={() => handlePreviewWithStaff(record)} >Preview</Button>

                    </Row>
                );
            },
        }
    ];

    const [dataEditModal, setDataEditModal] = useState<ITimeClockReportHistory>();
    const [dataDeleteModal, setDataDeleteModal] = useState<ITimeClockReportHistory>();
    const [dataTable, setDataTable] = useState<DataType[]>([]);
    useEffect(() => {
        const _mapData = data.map(m => {
            return {
                ...m,
                key: m.id,
                urlImage: m.avatar
            };
        });
        setDataTable(_mapData);
    }, [data]);
    return (
        <StyledStaffTableClock className={v2 ? 'v2' : ''}>
            <Table
                columns={columns}
                dataSource={dataTable}
                style={v2 ? { width: '100%' } : { width: '100%', padding: '1rem' }}
                rowClassName={'row-table'}
                pagination={{ pageSize: 50 }}
                scroll={{ y: 500 }}
            />
            <ModalEditTimeInOut
                data={dataEditModal}
                key={Math.random()}
                isShown={dataEditModal ? true : false}
                onClose={() => setDataEditModal(undefined)}
                onSubmit={onEdit}
                onDelete={onDelete}
            />
            <ModalDeleteTimeInOut
                isShown={dataDeleteModal ? true : false}
                onClose={() => setDataDeleteModal(undefined)}
                onSubmit={onDelete}
                data={dataDeleteModal}
            />
        </StyledStaffTableClock>
    );
};
export default StaffTableClock;

const StyledStaffTableClock = styled(Row)`
    flex: 1;
    border-radius: 5px;
    border: 1px solid #86909C;
    background: #FFF;

    /* box */
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    ${tableGreenStrStyle}

&.v2 {
  flex: 1;
  box-shadow: none;
  border: none;
  .ant-table-wrapper {
    padding: 0;
  }
  .ant-table-wrapper .ant-table {
    .ant-table-thead th.ant-table-cell {
      height: 48px;
      padding: 0px 8px;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #CCD4DC;
      background: #6FABB6;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: left !important;
      &::before {
        width: 1.5px !important;
        height: 100% !important;
      }
    }

    .ant-table-tbody td.ant-table-cell {
      .BODY_1, .CONTENT_1 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
      background: #E4F4F6;
      border-bottom: 1px solid #E4F4F6;
    }
  }
}
`;

const ImageContainer = styled.div`
  min-width: 40px;
  margin-right: 0.5rem;
`;

const NameBox = styled(Row)`
  align-items: center;
  flex-wrap: nowrap;
  text-align: start;
`;
