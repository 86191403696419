import { Avatar } from 'antd';
import Icon from 'assets/Icons';
import clsx from 'clsx';
import Box from 'components/Box';
import Text from 'components/Text';
import { ITurnManualAppointmentItem, ITurnManualItem } from 'features/turn/services/types/turn';
import { minBy } from 'lodash';
import moment from 'moment';
import { useMemo } from 'react';
import uiSelector from 'services/UI/selectors';
import styled from 'styled-components';

export type IStaffTurnItem = {
  staffId: string;
  staffAvatar: string;
  staffName: string;
  turns: ITurnManualItem[];
  totalTurn: number;
  totalInprogress: number;
  appointments: ITurnManualAppointmentItem[];
  indexClockIn: number;
};

type Props = {
  data: IStaffTurnItem;
  appointments: ITurnManualAppointmentItem[];
  onClickStaff: () => void;
  onVoiceCall?: () => void;
  voiceCallStaff?: boolean;
};
const TurnStaffTopRow = ({ data, onClickStaff, onVoiceCall = () => undefined, voiceCallStaff, appointments = [] }: Props) => {
  const currentTime = uiSelector.getCurrentTime();
  const timeAppointment = useMemo(() => {
    return minBy(appointments.map(o => {
      return ({
        time: moment(o.startTime, 'hh:mm A').diff(moment(currentTime), 'minutes'),
        startTime: o.startTime,
        endTime: o.endTime,
      });
    }).filter(o => o.time >= 0), 'time');
  }, [appointments, currentTime]);
  return (
    <NameColumn onClick={onClickStaff} type='button' className={clsx('TurnStaffTopRow', !!timeAppointment && 'timeAppointment', voiceCallStaff && 'voiceCallStaff')}>
      <Box display='flex' alignItems='center' pr={voiceCallStaff ? '2' : undefined}>
        <ImageContainer>
          <Avatar
            src={data.staffAvatar || 'fail'}
            size={timeAppointment ? 45 : 60}
          >{data.staffName?.charAt(0)}</Avatar>
        </ImageContainer>
        <Text variant='CONTENT_2' className='text-overflow staff-name' style={{ textAlign: 'left', maxWidth: 80, minWidth: 80 }}>{data.staffName}</Text>
        {!!voiceCallStaff && <button onClick={e => [e.stopPropagation(), onVoiceCall()]} className='btn-call-staff' ><Icon type='callStaff' /></button>}
      </Box>
      {!!timeAppointment && <div className='time-container'>
        <span>{timeAppointment.startTime} - {timeAppointment.endTime}</span>
      </div>}
    </NameColumn>
  );
};

export default TurnStaffTopRow;
const NameColumn = styled.button`
    min-width: 165px;
    max-width: 165px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .circle-avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  .time-container {
    margin-top: 3px;
    background: #eed202;
    display: flex;
    flex-direction: column;
    padding: 3px 4px;
    border-radius: 4px;
    border: 1.5px solid #1D2129;
    span {
     font-size: 15px;
     font-weight: 700;
     color: #1D2129;
    }
  }

  &.voiceCallStaff {
    min-width: 240px;
    max-width: 240px;
  }
`;
const ImageContainer = styled.div`
    min-width: 40px;
    padding: 0 10px;
    span {
        width: 40px;
        height: 40px;
        line-height: 38px;
    }
`;
