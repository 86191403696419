import { Col, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import { IPrintSalaryStaffDetailOneDay } from 'features/report/services/types/reportStaff';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../../../components/PrintHeader';
import { ReportType } from '../../../constants';
import { renderTime } from '../../../utils';
import EmployeeTickets from 'features/report/components/EmployeeTickets';
import shopSelectors from 'services/shop/selectors';

interface IEmployeeSalaryReportPrintPage { }

const StaffSalaryReportPrintPage: React.FC<IEmployeeSalaryReportPrintPage> = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { staff_id = '' } = useParams();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    useEffect(() => {
        if (!dataAppointment) return;
        dispatch(reportActions.getPrintSalaryStaffDetailOneDay.fetch({
            startDate: dataAppointment.startTime,
            endDate: dataAppointment.endTime,
            staffId: staff_id
        }));
    }, [dataAppointment, staff_id]);

    const onBack = () => {
        navigate(-1);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });
    return (
        <div style={{ display: 'flex' }}>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <StaffSalaryReportPrintBodyUI />
                </div>
            </ContainerStyled>
        </div>
    );
};

export const StaffSalaryReportPrintBodyUI = () => {
    const dataPrintSalaryStaffDetailOneDay = reportSelectors.getPrintSalaryStaffDetailOneDay();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.STAFF_SALARY_DETAIL];
    const allSetting = shopSelectors.data.allSetting();
    const cashLabel = allSetting?.labelSalaryCashOrBonus || 'Cash';

    const UserDetail = (data: IPrintSalaryStaffDetailOneDay) => {
        return (
            <Box px='4' pb='1'>
                <Box className="center" highlight>
                    <Text printMode variant="CAPTION_2" className='title-highlight'>
                        {data?.staffName || '_____'}
                    </Text>
                </Box>
                <Box>
                    <EmployeeTickets data={data?.details} />
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' >TOTAL SALES</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.totalSales)}</Text></ColHeader>
                    </Discount>
                    <Discount>
                        <ColHeader span={16}><Text printMode variant='CAPTION_2' >SUPPLY FEE</Text></ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_2' >{formatCurrency(data?.supplyFee)}</Text></ColHeader>
                    </Discount>
                    <Box display='flex' justifyContent='space-between' style={{ borderBottom: 'unset' }} highlight>
                        <Text printMode variant='CAPTION_2' className='title-highlight' >TOTAL</Text>
                        <Text printMode variant='CAPTION_2' className='title-highlight'>{formatCurrency((data?.totalSales || 0) - (data?.supplyFee || 0))}</Text>
                    </Box>
                    <Discount>
                        <ColHeader span={16}>
                            <Text variant="CAPTION_2" printMode>
                                COMMISSION ({data?.commissionPercent}%)
                            </Text>
                        </ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}>
                            <Text variant="CAPTION_2" printMode>
                                {formatCurrency(data.totalCommission)}
                            </Text>
                        </ColHeader>
                    </Discount>
                    {!!data.staffDiscount && <Discount>
                        <ColHeader span={16}>
                            <Text variant="CAPTION_2" printMode>
                                DISCOUNT
                            </Text>
                        </ColHeader>
                        <ColHeader span={8} style={{ textAlign: 'end' }}>
                            <Text variant="CAPTION_2" printMode>
                                {formatCurrency(data.staffDiscount)}
                            </Text>
                        </ColHeader>
                    </Discount>}
                    <>
                        {!!data?.tipCollected && <Discount>
                            <ColHeader flex={1}>
                                <Text variant="CAPTION_2" printMode>
                                    COLLECTED TIPS
                                </Text>
                            </ColHeader>
                            <ColHeader span={6} style={{ textAlign: 'end' }}>
                                <Text variant="CAPTION_2" printMode>
                                    {formatCurrency(data?.tipCollected)}
                                </Text>
                            </ColHeader>
                        </Discount>}
                        {!!data?.tipFee && <>
                            <Discount>
                                <ColHeader flex={1}>
                                    <Text variant="CAPTION_2" printMode>
                                        TIP FEE {(data?.tipFeeType === 'PERCENT' && `(${data?.tipFeeValue || 0}%)`)}
                                    </Text>
                                </ColHeader>
                                <ColHeader span={6} style={{ textAlign: 'end' }}>
                                    <Text variant="CAPTION_2" printMode>
                                        {formatCurrency(data.tipFee)}
                                    </Text>
                                </ColHeader>
                            </Discount>
                            <Discount>
                                <ColHeader flex={1}>
                                    <Text variant="CAPTION_2" printMode>
                                        TOTAL TIP
                                    </Text>
                                </ColHeader>
                                <ColHeader span={6} style={{ textAlign: 'end' }}>
                                    <Text variant="CAPTION_2" printMode>
                                        {formatCurrency(data?.totalTip)}
                                    </Text>
                                </ColHeader>
                            </Discount>
                        </>}
                        <Box display='flex' justifyContent='space-between' style={{ borderBottom: 'unset' }} highlight>
                            <Text printMode variant='CAPTION_2' className='title-highlight' >PAYMENT TYPES</Text>
                            <Text printMode variant='CAPTION_2' className='title-highlight'></Text>
                        </Box>
                        <BoxInfo className='bb'>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                Cash
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                {formatCurrency(data?.cashAmount)}
                            </Text>
                        </BoxInfo>
                        <BoxInfo className='bb'>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                Credit Card
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                {formatCurrency(data?.creditAmount)}
                            </Text>
                        </BoxInfo>
                        <BoxInfo className='bb'>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                Gift Card
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                {formatCurrency(data?.giftCardAmount)}
                            </Text>
                        </BoxInfo>
                        {!!data?.loyaltyAmount &&
                            <BoxInfo className='bb'>
                                <Text printMode variant='CAPTION_2' color="text_3">
                                    Loyalty
                                </Text>
                                <Text printMode variant='CAPTION_2' color="text_3">
                                    {formatCurrency(data?.loyaltyAmount)}
                                </Text>
                            </BoxInfo>}
                        {!!data?.checkAmount &&
                            <BoxInfo className='bb'>
                                <Text printMode variant='CAPTION_2' color="text_3">
                                    Check
                                </Text>
                                <Text printMode variant='CAPTION_2' color="text_3">
                                    {formatCurrency(data?.checkAmount)}
                                </Text>
                            </BoxInfo>}
                        <BoxInfo className='bb'>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                Other
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                {formatCurrency(data?.otherAmount)}
                            </Text>
                        </BoxInfo>
                    </>

                    <Box display='flex' justifyContent='space-between' style={{ borderBottom: 'unset' }} highlight>
                        <Text printMode variant='CAPTION_2' className='title-highlight' >PAID OUT</Text>
                        <Text printMode variant='CAPTION_2' className='title-highlight'>{formatCurrency(data?.totalSalary)}</Text>
                    </Box>
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                            {cashLabel} ({data?.cashPercent}%)
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.totalPayCash)}
                        </Text>
                    </BoxInfo>
                    <BoxInfo>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            Check ({data?.checkPercent}%)
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.totalPayCheck)}
                        </Text>
                    </BoxInfo>
                    {!!data?.tipToCash &&
                        <BoxInfo>
                            <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                                Tip to {cashLabel} {data?.tipCashPercent ? ` (${data?.tipCashPercent}%)` : ''}
                            </Text>
                            <Text printMode variant='CAPTION_3' color="text_3" >
                                {formatCurrency(data?.tipToCash)}
                            </Text>
                        </BoxInfo>}
                    <BoxInfo>
                        <Text textTransform='capitalize' printMode variant='CAPTION_3' color="text_3" >
                            Tip to Check {data?.tipCheckPercent ? ` (${data?.tipCheckPercent}%)` : ''}
                        </Text>
                        <Text printMode variant='CAPTION_3' color="text_3" >
                            {formatCurrency(data?.tipToCheck)}
                        </Text>
                    </BoxInfo>
                    <Box bt='print_line'>
                        <BoxInfo>
                            <Text textTransform='capitalize' printMode variant='CAPTION_2' color="text_3" >
                                Total {cashLabel}
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3" >
                                {formatCurrency(data?.totalCash)}
                            </Text>
                        </BoxInfo>
                        <BoxInfo>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                Total Check
                            </Text>
                            <Text printMode variant='CAPTION_2' color="text_3">
                                {formatCurrency(data?.totalCheck)}
                            </Text>
                        </BoxInfo>
                    </Box>
                </Box>
            </Box>
        );
    };

    return (
        <Box className="center" overflow="auto">
            <TicketBox>
                <Box px='4'>
                    <LogoBox>
                        <IconLogo />
                    </LogoBox>
                    <Box className="center">
                        <Text printMode variant="CONTENT_2" color="text_2">
                            {renderTime(dataAppointment)}
                        </Text>
                    </Box>
                </Box>
                {UserDetail(dataPrintSalaryStaffDetailOneDay)}
            </TicketBox>
        </Box>
    );
};

export default StaffSalaryReportPrintPage;

const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 20.5rem;
  height: fit-content;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;

const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;

const Header = styled(Row)`
  border-top:1px solid black;
  border-bottom: 1px dashed black;
`;

const ColHeader = styled(Col)`
    color: #767676;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

const Discount = styled(Header)`
  border-top: unset;
`;

const BoxInfo = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.bb {
        border-bottom: 1px dashed black;
    }
`;