import IconLogo from 'assets/Icons/logo';
import React from 'react';
import styled from 'styled-components';
import fozitoLogo from './fozitoLogo.png';
type IComponentProps = {
  lg?: boolean;
};
const LogoBox: React.FC<IComponentProps> = ({ lg }) => {
  return (
    <BackgroundStyled>
      {lg ? <img src={fozitoLogo} className='lg-icon' /> : <IconLogo />}
    </BackgroundStyled>
  );
};

export default LogoBox;
const BackgroundStyled = styled.div`
    position: absolute;
    top: 1.6785rem;
    left: 2.5rem;
    display: flex;
    align-items: center;
    z-index: 2;
    .lg-icon {
      height: 45px;
      width: 131px;
    }
`;
