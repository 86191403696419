import AvatarBox from 'components/AvatarBox';
import Button from 'components/Button';
import cashierActions from 'features/cashier/services/actions';
import { cashierUIActions } from 'features/cashier/services/reducers';
import cashierSelectors from 'features/cashier/services/selectors';
import { IStaff } from 'features/cashier/services/types/api';
import { cloneDeep, debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CashierStationAlarm from '../CashierStationAlarm';
import Box from 'components/Box';
import { Input } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';

const StaffListChooseUI = () => {
  const [searchText, setSearchText] = useState('');
  const dispatch = useAppDispatch();

  const _staffList = shopSelectors.data.staffsAvailable();

  const staffList = useMemo(() => {
    const _searchStr = searchText.trim().toLocaleLowerCase();
    if (!_searchStr) return _staffList;
    return _staffList.filter(o => o.staffName?.toLocaleLowerCase().includes(_searchStr));
  }, [searchText, _staffList]);

  const selectedItems = cashierSelectors.getSelectedItems();
  const countStaffInRowUI = cashierSelectors.countStaffInRowUI();
  const gridTemplateColumns = React.useMemo(() => {
    return Array.from(Array(countStaffInRowUI).keys()).map(() => '1fr').join(' ');
  }, [countStaffInRowUI]);
  const countStaffInRowCss = useMemo(() => {
    if (countStaffInRowUI === 5) return 'row-5';
    return 'row-4';
  }, [countStaffInRowUI]);

  const onPickStaff = (staff: IStaff) => {
    dispatch(cashierUIActions.setDraftStaffForNewTicketV2(staff));
    dispatch(cashierActions.addStaffItem(cloneDeep(staff)));
  };

  const handleZoom = (action: 'in' | 'out') => {
    if (action === 'out') {
      const nextValue = countStaffInRowUI + 1;
      if (nextValue > 5) return;
      dispatch(cashierUIActions.setCountStaffInRowUI(nextValue));
    } else {
      const nextValue = countStaffInRowUI - 1;
      if (nextValue < 4) return;
      dispatch(cashierUIActions.setCountStaffInRowUI(nextValue));
    }
  };

  return (
    <Container>
      <CashierStationAlarm />
      <Box display='flex'>
        <InputSearch onSearch={setSearchText} />
        <div className="group-zoom">
          <IconButton ntype='GHOST' className='arrow-btn' onClick={() => handleZoom('out')}><IconZoomIn /></IconButton>
          <IconButton ntype='GHOST' className='arrow-btn' onClick={() => handleZoom('in')}><IconZoomOut /></IconButton>
        </div>
      </Box>
      <div className="scroll-wrapper">
        <div className={'scroll-container'} >
          <div className="staff-list-container" style={{ gridTemplateColumns }}>
            {staffList.map((item) => {
              const selected = !!selectedItems.find(o => o.staffId === item.staffId);
              return (
                <AvatarBox
                  key={item.staffId}
                  onClick={() => !selected && onPickStaff(item)}
                  src={item.staffAvatar}
                  name={item.staffName}
                  selected={selected}
                  cashier
                  className={`cashier-staff-box ${countStaffInRowCss}`}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StaffListChooseUI;
type InputSearchProps = {
  onSearch: (value: string) => void;
};
const InputSearch = ({ onSearch }: InputSearchProps) => {
  const [text, setText] = useState('');
  const _debounce = useCallback(debounce((_text: string) => onSearch(_text.trim()), 200), []);
  const onChange = (e: any) => {
    const searchText = e.target.value.trim();
    setText(searchText);
    _debounce(searchText);
  };
  return (
    <WrapperInputSearch>
      <Input className='search-input' placeholder="Search by Staff's Name" value={text} onChange={onChange}
        suffix={<SearchIcon fill={'#fff'} />}
      />
    </WrapperInputSearch>
  );
};
const WrapperInputSearch = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding-left: 16px;
  .search-input.ant-input-affix-wrapper {
    border-radius: 100px;
    padding: 0px;
    .ant-input-suffix {
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0px 100px 100px 0px;
      background: #2D6772;
    }
    input {
      border-radius: 100px; 
      padding: 24px;
      height: 56px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      align-self: center;
    }
    border: 3px solid #2D6772;
    background: #FFF;
  }
`;
const Container = styled.div`
    flex: 1;
    background-color: #F5F5F5;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    margin-left: 1px;
    .group-zoom {
      display: flex;
      align-self: stretch;
      gap: 16px;
      justify-content: flex-end;
      padding: 8px 16px;
      padding-right: 0;
    }
    .scroll-wrapper {
      flex: 1;
      position: relative;
      .scroll-container {
        position: absolute;
        inset: 0;
        overflow: auto;
        scrollbar-width: none; /* Firefox 64 */
        &::-webkit-scrollbar {
          width: 0px;
          height: 8px;
          background-color: #F5F5F5;
        }
      }
    }
    .staff-list-container {
      padding: 24px 16px;
      padding-top: 16px;
      display: grid;
      gap: 16px;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      .cashier-staff-box {
        width: 100%;
        padding: 0.5rem 0;
        &.row-5 {
          .circle-avatar {
            width: 7rem;
            min-height: 7rem;
          }  
        }
      }
    }
`;

const IconButton = styled(Button)`
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;

const IconZoomIn = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M29.3333 10C18.6558 10 10 18.6558 10 29.3333C10 40.0108 18.6558 48.6667 29.3333 48.6667C34.5616 48.6667 39.3052 46.5913 42.7852 43.2195C42.8451 43.1378 42.912 43.0596 42.9858 42.9858C43.0596 42.912 43.1378 42.8451 43.2195 42.7852C46.5913 39.3052 48.6667 34.5616 48.6667 29.3333C48.6667 18.6558 40.0108 10 29.3333 10ZM47.1864 44.3579C50.6061 40.2986 52.6667 35.0566 52.6667 29.3333C52.6667 16.4467 42.22 6 29.3333 6C16.4467 6 6 16.4467 6 29.3333C6 42.22 16.4467 52.6667 29.3333 52.6667C35.0566 52.6667 40.2986 50.6061 44.3579 47.1864L54.5858 57.4142C55.3668 58.1953 56.6332 58.1953 57.4142 57.4142C58.1953 56.6332 58.1953 55.3668 57.4142 54.5858L47.1864 44.3579ZM19.3333 29.3333C19.3333 28.2288 20.2288 27.3333 21.3333 27.3333H37.3333C38.4379 27.3333 39.3333 28.2288 39.3333 29.3333C39.3333 30.4379 38.4379 31.3333 37.3333 31.3333H21.3333C20.2288 31.3333 19.3333 30.4379 19.3333 29.3333Z" fill="#777D7C" />
  </svg>
);

const IconZoomOut = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M29.3333 9.77778C18.533 9.77778 9.77772 18.5331 9.77772 29.3333C9.77772 40.1336 18.533 48.8889 29.3333 48.8889C34.6287 48.8889 39.4326 46.7841 42.9539 43.3654C43.0098 43.2875 43.0728 43.213 43.1429 43.1429C43.2129 43.0729 43.2874 43.0099 43.3654 42.9539C46.784 39.4327 48.8888 34.6288 48.8888 29.3333C48.8888 18.5331 40.1335 9.77778 29.3333 9.77778ZM46.8843 44.3702C50.3503 40.3283 52.4444 35.0754 52.4444 29.3333C52.4444 16.5694 42.0972 6.22223 29.3333 6.22223C16.5694 6.22223 6.22217 16.5694 6.22217 29.3333C6.22217 42.0973 16.5694 52.4445 29.3333 52.4445C35.0753 52.4445 40.3283 50.3504 44.3701 46.8843L54.7429 57.2571C55.4371 57.9514 56.5628 57.9514 57.257 57.2571C57.9513 56.5628 57.9513 55.4372 57.257 54.7429L46.8843 44.3702ZM29.3333 19.5556C30.3151 19.5556 31.1111 20.3515 31.1111 21.3333V27.5556H37.3333C38.3151 27.5556 39.1111 28.3515 39.1111 29.3333C39.1111 30.3152 38.3151 31.1111 37.3333 31.1111H31.1111V37.3333C31.1111 38.3152 30.3151 39.1111 29.3333 39.1111C28.3514 39.1111 27.5555 38.3152 27.5555 37.3333V31.1111H21.3333C20.3514 31.1111 19.5555 30.3152 19.5555 29.3333C19.5555 28.3515 20.3514 27.5556 21.3333 27.5556H27.5555V21.3333C27.5555 20.3515 28.3514 19.5556 29.3333 19.5556Z" fill="#777D7C" />
  </svg>
);
