import { Button as AntButton, Avatar, Empty, Form, RefSelectProps, Select } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { first, get, remove } from 'lodash';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IQuickBookingServiceItem } from 'services/shop/types/categories';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import uuid from 'utils/uuid';
type Props = {
  value?: string;
  onChange?: (value: string) => void;
  services: IQuickBookingServiceItem[];
  name: any;
  staffs: IStaffItemData[];
  onChangeStaff: (staffId: string, onChange: (value: string) => void) => void;
  onRemoveStaff: () => void;
  disabledRemove: boolean;
};
const StaffQuickBookingSection = ({ staffs = [], name, services = [], value = '', onChange = () => undefined, disabledRemove, onChangeStaff, onRemoveStaff }: Props) => {
  const staff = useMemo(() => staffs.find(o => o.id === value), [staffs, value]);
  const options = useMemo(() => services.map(o => ({
    label: o.serviceName,
    value: o.id,
    id: o.id,
    optionDetails: o.optionDetails,
  })), [services]);
  const StaffInfo = useCallback(() => {
    const staffName = staff?.firstName;
    return (
      <Box display='flex' alignItems='center' gap='2'>
        <Avatar src={staff?.urlImage || 'Fail'} size={60}>{get(staffName, [0], '') || 'A'}</Avatar>
        <Text variant='H9' color='text_3' className='text-lg' style={{ fontSize: '30px', color: '#2D6772' }}>{staffName || 'Anybody'}</Text>
      </Box>
    );
  }, [staff]);

  return (
    <ServicesContainer>
      <Box display='flex' justifyContent='space-between'>
        <StaffInfo />
        <Box display='flex' alignItems='center' gap='4' height={'48px'} pr='2'>
          <Button ntype='GHOST' icon='change' size='small' width='40px' style={{ transform: 'scale(1.5)' }} onClick={() => onChangeStaff(value, onChange)} />
          <Button ntype='GHOST' disabled={disabledRemove} size='small' width='40px' style={{ transform: 'scale(1.5)' }} onClick={onRemoveStaff} ><Icon type='deleteIconCashier' fill='#F53F3F' /></Button>
        </Box>
      </Box>
      <div style={{ flex: 1 }} >
        <Form.Item noStyle name={[name, 'services']} rules={[{ required: true, message: 'Please choose services' }]}>
          <ServiceHandler options={options} />
        </Form.Item>
      </div>
    </ServicesContainer>
  );
};

export default StaffQuickBookingSection;

export const ServiceHandler = ({ options = [], value = [], onChange = () => undefined, }: {
  options: {
    label: string;
    value: string;
    id: string;
    optionDetails: string[];
  }[], value?: any, onChange?: (val: any) => void,
}) => {
  const [lastedOption, setLastedOption] = useState('');

  useEffect(() => { setLastedOption(first(options)?.id || ''); }, [options]);
  const [open, setOpen] = useState(false);
  const selectRef = useRef<RefSelectProps>(null);

  const optionDetails = useMemo(() => options.find(o => o.id === lastedOption)?.optionDetails || [], [lastedOption, options]);

  const onSelectOption = (_value: any) => {
    setLastedOption(_value);
  };

  const onTypingOption = (_value: any) => {
    const exist = options.find(o => o.value === _value);
    const newItem = {
      label: exist?.label || _value,
      value: uuid(),
      id: exist?.id || '',
    };
    const temp = [...value];
    temp.push(newItem);
    setLastedOption(newItem.id);
    selectRef.current?.blur();
    onChange(temp);
  };

  const onSelectSubOption = (subName: string) => {
    const exist = options.find(o => o.value === lastedOption);
    const newItem = {
      label: exist?.label + ' + ' + subName,
      value: uuid(),
      id: exist?.id || '',
    };
    const temp = [...value];
    temp.push(newItem);
    onChange(temp);
  };

  const onRemove = (_value: string): React.MouseEventHandler<HTMLElement> => (e) => {
    e.preventDefault(); e.stopPropagation();
    const temp = [...value];
    remove(temp, o => o.value === _value);
    onChange(temp);
  };


  return (
    <>
      <Select
        // mode="multiple"
        mode="tags"
        style={{ width: '100%' }}
        placeholder="Enter your services"
        options={options}
        open={open}
        ref={selectRef}
        value={value}
        onFocus={() => setOpen(true)}
        onBlur={() => setOpen(false)}
        notFoundContent={<Empty />}
        onSelect={onTypingOption}
        tagRender={item => (<AntButton className='tag-render-item' onClick={onRemove(item.value)}><span>{item.label}</span><IconClose /></AntButton>)}
      />
      {/* <Form.Item shouldUpdate noStyle>{({ getFieldError, getFieldValue }) => {
        const error = getFieldError([name, 'services']);
        console.log('error', error);

        if (!error) return null;
        return <Text color='error_6' variant='CONTENT_1'>Please enter services</Text>;
      }}</Form.Item> */}
      <Box mt='1' display='flex' gap='4' flexWrap='wrap' className='tag-suggestions categories' style={{ gap: '0px', margin: '0 0.75px' }}>
        {options.map(o => (
          <button type='button' onClick={() => onSelectOption(o.id)} key={o.value} className={`tag-item category ${lastedOption === o.id ? 'active' : ''}`}>
            <span>{o.label}</span>
          </button>
        ))}
      </Box>
      <Box mt='1' display='flex' gap='4' flexWrap='wrap' className='tag-suggestions services'>
        {optionDetails.map(o => (
          <button type='button' onClick={() => onSelectSubOption(o)} key={o} className={'tag-item sub-tag'}>
            <span color="#C5DDF8">{o}</span>
          </button>
        ))}
      </Box>
    </>
  );
};

const ServicesContainer = styled.div`
display: flex;
gap: 16px;
flex-direction: column;

background-color: #F0F0F0;
gap: 12px;
border: 1px solid #F0F0F0;
padding:8px;
border-radius: 4px;

.tag-close {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  background: #FFF;
}

.ant-select-multiple {
  min-height: 64px;
  width: 100%;
  height: auto;

  .ant-select-arrow {
    display: none;
  }

  .ant-select-selector {
    padding: 12px;
    .ant-select-selection-search {
    width: 150px !important;
    }
    .ant-select-selection-search-input {
      color: #1D2129;
      font-family: Poppins;
      font-size: 22px;
      font-weight: 400;
      line-height: normal;
    }
    .ant-select-selection-overflow {
      gap: 12px;

      .ant-select-selection-overflow-item {
        .tag-render-item {
          display: flex;
          padding: 0;
          border: 1px solid #CECECE;
          padding: 2px 8px;
          height: 42px;
          gap: 8px;
          align-items: center;

          span {
            overflow: hidden;
            color: #1D2129;
            text-overflow: ellipsis;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}

&.ant-form-large .ant-form-item .ant-form-item-label>label {
  height: 24px;
}

.tag-suggestions {
  gap: 12px;
  &.categories {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(120px, 1fr));
  }
  &.services {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .tag-item {
    border-radius: 100px;
    background: #fff;
    display: flex;
    padding: 12px 18px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    align-self: stretch;

    span {
      text-align: center;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
    }

    &.category {
      border-radius: 0;
      margin:0 -0.75px;
      position: relative;
      z-index: 1;
      background: rgb(252, 252, 253);
      box-shadow: rgba(44, 35, 66, 0.4) 0px 2px 4px 0px, rgba(45, 35, 66, 0.3) 0px 4px 8px -3px, rgb(214, 214, 231) 0px -3px 0px 0px inset;
      display: flex;
      height: 60px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding: 0px 16px;
      span {
        color: rgb(80, 80, 80);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: center;
      }
    }

    &.sub-tag {
      background: rgb(246, 247, 252);
      border: 1px solid rgb(134, 144, 156);
      display: flex;
      height: 60px;
      justify-content: space-between;
      align-items: center;
      flex: 1 0 0px;
      padding: 0px 16px;
      border-radius: 4px;
      position: relative;
      box-shadow: rgba(0, 0, 0, 0.25) 0px -4px 4px 0px inset, rgba(255, 255, 255, 0.25) 0px 8px 4px 3px inset;
      span {
        color: rgb(29, 33, 41);
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }
    }

    &.active {
       &.category {
        z-index: 2;
        background: rgb(45, 103, 114);
        box-shadow: rgba(44, 35, 66, 0.4) 0px 2px 4px 0px, rgba(45, 35, 66, 0.3) 0px 4px 8px -3px, rgb(18, 63, 71) 0px -3px 0px 0px inset;
        span {
          color: #fff;
        }
      }
    }
  }
}
`;

const IconClose = () => (
  <div className='tag-close'>
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M14.8186 1.18114C15.1952 1.55773 15.1952 2.16829 14.8186 2.54488L2.54491 14.8186C2.16833 15.1951 1.55776 15.1951 1.18117 14.8186C0.804584 14.442 0.804585 13.8314 1.18117 13.4548L13.4548 1.18114C13.8314 0.804552 14.442 0.804552 14.8186 1.18114Z" fill="#F05326" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.18141 1.18114C1.558 0.804552 2.16857 0.804552 2.54515 1.18114L14.8188 13.4548C15.1954 13.8314 15.1954 14.442 14.8188 14.8186C14.4422 15.1951 13.8317 15.1951 13.4551 14.8186L1.18141 2.54488C0.804823 2.16829 0.804823 1.55773 1.18141 1.18114Z" fill="#F05326" />
    </svg>
  </div>
);
