import { Radio, Spin } from 'antd';
import Form, { useForm } from 'components/Form';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import cashierActions from 'features/cashier/services/actions';
import { IGiftCardDetail, IGiftCardResult } from 'features/cashier/services/types/giftCard';
import moment from 'moment';
import { useState, useTransition } from 'react';
import { useAppDispatch } from 'store/hooks';
import { styled } from 'styled-components';
import CardTicketItem from '../CardTicketItem';
import CheckGiftCard from './CheckGiftCard';
import IssueGiftCard, { EPeriodDates, IssueFormValues, useIssueGiftCardRef } from './IssueGiftCard';
import { IResponseDataBody } from 'services/response';
import cashierApis from 'features/cashier/services/apis';
import ModalAddGiftCardV2 from './v2';
import { GIFT_CARD_RADIO_TYPE } from './v2/Tab';
import cashierSelectors from 'features/cashier/services/selectors';
import { cashierUIActions } from 'features/cashier/services/reducers';

type Props = {
  v2?: boolean;
};
const GiftCard = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const visible = cashierSelectors.getVisibleIssueGiftCard();
  const setVisible = (value: boolean) => dispatch(cashierUIActions.setVisibleIssueGiftCard(value));
  const [, startTransition] = useTransition();
  const issueFormRef = useIssueGiftCardRef();
  const [radioType, setRadioType] = useState<GIFT_CARD_RADIO_TYPE>(GIFT_CARD_RADIO_TYPE.ISSUED);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = useForm();
  const [formCheck] = useForm();

  const reset = () => {
    setRadioType(GIFT_CARD_RADIO_TYPE.ISSUED);
    form.resetFields();
  };
  const handleClose = () => startTransition(() => {
    setVisible(false);
    reset();
  });

  const isNotExist = async (code: string) => {
    if (code?.length < 4) return;
    try {
      const res: IResponseDataBody<IGiftCardDetail> = await cashierApis.getGiftCardInfo(code);
      if (res.data.data) {
        issueFormRef.current?.setMsg('Gift number exist, please try again', 'warning');
        return false;
      }
      issueFormRef.current?.setMsg('Gift number valid', 'success');
      return true;
    } catch (error) {
      issueFormRef.current?.setMsg('Gift number valid', 'success');
      return true;
    }
  };
  const handleCheckExistGiftCard = async (code: string) => {
    setLoading(true);
    await isNotExist(code);
    setLoading(false);
  };

  const handleFinish = async (values: IssueFormValues) => {
    setLoading(true);
    if (await isNotExist(values.giftNumber)) {
      const payload: IGiftCardResult = {
        amount: values.amount || 0,
        code: values.giftNumber,
        expireDate: moment(values.expiration).format('MM-DD-YYYY'),
      };
      dispatch(cashierActions.addGiftCard(payload));
      handleClose();
    }
    setLoading(false);
  };

  const onFinishWithByPassValid = async (values: IssueFormValues) => {
    const payload: IGiftCardResult = {
      amount: values.amount || 0,
      code: values.giftNumber,
      expireDate: moment(values.expiration).format('MM-DD-YYYY'),
    };
    dispatch(cashierActions.addGiftCard(payload));
    handleClose();
  };

  const validation = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleSubmit = async () => {
    if (!await validation()) return;
    form.submit();
  };

  const handleOpen = () => {
    setVisible(true);
  };

  return (
    <>
      {v2 ? <CardTicketItem
        icon={'cardGiftCardV2'}
        content={'Gift Cards'}
        nbgcolor={'#346751'}
        v2
        textColor={colorTheme.text_3}
        border_color={'#346751'}
        border_color2={'#346751'}
        onClick={handleOpen}
      />
        : <CardTicketItem
          icon={'cardGiftCard'}
          content={'Gift Card'}
          nbgcolor={colorTheme.info_bg_3}
          textColor={colorTheme.text_3}
          border_color={colorTheme.line_3}
          onClick={handleOpen}
        />}
      {v2 ? <ModalAddGiftCardV2
        visible={visible}
        onClose={handleClose}
        radioType={radioType}
        setRadioType={setRadioType}
        checkExistGiftCard={handleCheckExistGiftCard}
        formCheck={formCheck}
        formIssue={form}
        onSubmit={onFinishWithByPassValid}
      /> : <Modal
        visible={visible}
        onClose={handleClose}
        okTitle={'Issue'}
        onSubmit={handleSubmit}
        modalTitle='GIFT CARDS'
        width={650}
        disableOk={loading}
        hiddenOkButton={radioType === GIFT_CARD_RADIO_TYPE.BALANCE}
      >
        <Spin spinning={loading}>
          <Text variant='CONTENT_1' color='text_3' mb={1}>Status</Text>
          <RadioGroupStyled onChange={(e: any) => {
            form.resetFields();
            setRadioType(e.target.value);
          }} value={radioType}>
            <Radio value={GIFT_CARD_RADIO_TYPE.ISSUED}>Issued Gift Cards</Radio>
            <Radio value={GIFT_CARD_RADIO_TYPE.BALANCE}>Gift Card Balance</Radio>
          </RadioGroupStyled>
          <Text mb={2} />
          <Form form={form} onFinish={handleFinish} initialValues={{ expiration: EPeriodDates.MONTH_12 }}>
            {visible && <>
              {radioType === GIFT_CARD_RADIO_TYPE.ISSUED && <IssueGiftCard ref={issueFormRef} checkExistGiftCard={handleCheckExistGiftCard} />}
              {radioType === GIFT_CARD_RADIO_TYPE.BALANCE && <CheckGiftCard />}
            </>}
          </Form>
        </Spin>
      </Modal>}
    </>
  );
};

export default GiftCard;

const RadioGroupStyled = styled(Radio.Group)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  .ant-radio-wrapper {
    .ant-radio-checked {
      .ant-radio-inner {
        border-color: #6FABB6;
        background-color: #6FABB6;
      }
    }
    .ant-radio-inner {
      border-color: #E5E6EB;
      border-width: 5px; 
    }
  }
`;