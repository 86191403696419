import { Col, Input, Layout, Row } from 'antd';
import Icon from 'assets/Icons';
import { PeriodTimeIcon } from 'assets/Icons/AppointmentIcons';

import Button from 'components/Button';
import Text from 'components/Text';
import InputPeriod, { PeriodValues } from 'features/appointment/components/InputPeriod';
import appointmentActions from 'features/appointment/services/actions';
import appointmentApis from 'features/appointment/services/apis';
import { APPOINTMENT_STATUS } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { IBodyApiUpdateAppointmentDetail } from 'features/appointment/services/types/api';
import { IAppointmentServiceItem } from 'features/appointment/services/types/appointment';
import { DATE_FORMAT, FORMAT_TIME } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import React, { memo, useEffect, useMemo, useState, useTransition } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IBodyApiUpdateStatusApt, IResponseDataBody } from 'services/response';
import { IServiceItemData } from 'services/shop/types/categories';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone, maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import RequestAdminLocalPW from 'widgets/RequestAdminLocalPW';
import ModalChooseStaff from 'widgets/Staff/DropDown/ModalChooseStaff';
import { MakeNoteIcon } from '../../AppointmentsPage/components/Calendar/TableView/Body/Modal/Icons';
import ModalChooseService from './ModalChooseService';
import ModalChooseTime from './ModalChooseTime';
import ModalInputReason from './ModalReason';
import ModalWarningExistService, { useWarningModalRef } from './ModalWarningExistService';
import ServiceItem from './ServiceItem';
import { EyeClose, EyeOpen } from 'assets/Icons/eye';
import { useTimeSlotPickerRef } from './TimeSlotPicker';
import { aptUIActions } from 'features/appointment/services/reducers';

type IAppointmentContentProps = {};
const AppointmentContent: React.FC<IAppointmentContentProps> = () => {
  const modalWarningExistServiceRef = useWarningModalRef();
  const data = appointmentSelectors.getAppointmentDetailData();
  const timePickerRef = useTimeSlotPickerRef();
  const navigate = useNavigate();
  const setLoadingPage = useSetLoadingPage();
  const dispatch = useAppDispatch();
  const [repeat, setRepeat] = useState(PeriodValues.NO_REPEAT);
  const [note, setNote] = useState('');
  const [, startTransition] = useTransition();

  const [visibleTimePicker, setVisibleTimePicker] = useState(false);

  const [timeStart, setTimeStart] = useState<Moment | null>(null);

  const services = appointmentSelectors.getAppointmentDetailServices();

  const [group, setGroup] = useState('1');

  const [visibleChooseStaff, setVisibleChooseStaff] = useState(false);

  const [draftStaff, setDraftStaff] = useState<IStaffItemData | null>(null);

  const [visiblePW, setVisiblePW] = useState(false);

  const [visibleModalReason, setVisibleModalReason] = useState<'no-show' | 'cancel' | null>(null);

  const [showModalVerify, setShowModalVerify] = useState(false);

  const [isMaskPhone, setMaskPhone] = useState(true);

  const handleToggleVerify = () => {
    const nextToggle = !isMaskPhone;
    if (!nextToggle) return setShowModalVerify(true);
    setMaskPhone(true);
  };

  const onVerifyAdminSuccess = () => {
    setMaskPhone(false);
    setShowModalVerify(false);
  };

  useEffect(() => {
    if (!data) return;
    startTransition(() => {
      setTimeStart(momentTimezone(data?.startTime));
      setNote(data.note || '');
    });
  }, [data]);


  const timeEnd = useMemo(() => {
    const time = timeStart?.clone();
    if (services.length === 0) {
      time?.add(15, 'minute');
    } else {
      services.forEach(o => {
        time?.add(o.duration, 'minute');
      });
    }
    return time;
  }, [timeStart, services]);


  const handleDelete = async () => {
    const appointmentId = data?.appointmentId;
    if (!appointmentId) return;

    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.deleteAppointment(appointmentId);
      if (res.data.data) {
        dispatch(appointmentActions.deleteAppointment.success(res.data.data));
        navigate(-1);
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const onPickStaff = (o: IAppointmentServiceItem) => (staff: IStaffItemData) => {
    dispatch(aptUIActions.detailChangeStaff({ id: o.uuid_local || '', staff }));
  };

  const handleRemove = (o: IAppointmentServiceItem) => () => {
    dispatch(aptUIActions.detailRemoveService(o));
  };

  const handleUpdateAppointment = async () => {
    const appointmentId = data?.appointmentId;
    if (!appointmentId) return;
    const _group = +group;
    const body: IBodyApiUpdateAppointmentDetail = {
      appointmentId,
      repeat,
      startTime: timeStart?.format('MM/DD/YYYY HH:mm:ss') || data?.startTime,
      // @ts-ignore
      staffServices: services.map(o => ({
        appointmentDetailId: o.appointmentDetailId,
        serviceId: o.serviceId,
        staffId: o.staffId || '',
      })),
      group: (_group > 0) ? _group : null,
      note,
    };

    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.updateDetailAppointment(body);
      if (res.data.data) {
        dispatch(appointmentActions.deleteAppointment.success(res.data.data));
        navigate(-1);
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const customerPhone = useMemo(() => {
    if (!data?.customerPhone) return null;

    if (!isMaskPhone) {
      return formatPhone(data.customerPhone);
    }
    return maskPhone(data.customerPhone);
  }, [isMaskPhone, data]);

  const handleOpenTimeStartPicker = () => {
    timePickerRef.current?.setDate(timeStart?.clone() || moment());
    setVisibleTimePicker(true);
  };
  const handleCloseTimeStartPicker = () => setVisibleTimePicker(false);

  const handleChangeTimeStart = (date: Moment) => {
    setTimeStart(date);
    setVisibleTimePicker(false);
  };

  const handleChooseStaff = (staff: IStaffItemData) => {
    setVisibleChooseStaff(false);
    setDraftStaff(staff);
  };

  const handleChooseService = (val: IServiceItemData) => {
    if (!draftStaff) return;
    setDraftStaff(null);
    dispatch(aptUIActions.detailAddNewService({ data: val, staffId: draftStaff.id }));
  };

  const onDeleteVerifyAdminSuccess = () => {
    setVisiblePW(false);
    handleDelete();
  };

  const updateStatus = async (status: APPOINTMENT_STATUS, reason?: string) => {
    const appointmentId = data?.appointmentId;
    if (!appointmentId) return;
    setLoadingPage(true);
    const body: IBodyApiUpdateStatusApt = {
      appointmentId,
      reason: reason || '',
      status,
    };
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.updateStatusApt(body);
      if (res.data.data) {
        dispatch(appointmentActions.deleteAppointment.success(res.data.data));
        navigate(-1);
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const handleAcceptReason = (reason: string) => {
    if (visibleModalReason === 'no-show') {
      updateStatus(APPOINTMENT_STATUS.NO_SHOW, reason);
    }
    if (visibleModalReason === 'cancel') {
      updateStatus(APPOINTMENT_STATUS.CANCEL, reason);
    }
  };

  const Actions = () => {
    const CONFIRM = <Col><ButtonApt aType='confirm' onClick={() => updateStatus(APPOINTMENT_STATUS.CONFIRM)} >Confirm</ButtonApt></Col>;
    const NO_SHOW = <Col><ButtonApt aType='no-show' onClick={() => setVisibleModalReason('no-show')}>No show</ButtonApt></Col>;
    const CONFIRMED = <Col><ButtonApt aType='confirm' disabled >Confirmed</ButtonApt></Col>;
    const DECLINE = <Col><ButtonApt aType='cancel' onClick={() => setVisibleModalReason('cancel')} >Decline</ButtonApt></Col>;
    const DECLINED = <Col><ButtonApt aType='cancel' disabled >Declined</ButtonApt></Col>;
    const DELETE = <Col><ButtonApt aType='delete' onClick={() => setVisiblePW(true)}>Delete</ButtonApt></Col>;
    const UPDATE = <Col><ButtonApt aType='update' onClick={handleUpdateAppointment} disabled={!services?.length} >Update</ButtonApt></Col>;

    const status = data?.status;

    switch (status) {
      case APPOINTMENT_STATUS.NEW:
        return <>{DELETE}{NO_SHOW}{DECLINE}{CONFIRM}{UPDATE}</>;
      case APPOINTMENT_STATUS.CONFIRM:
        return <>{DELETE}{CONFIRMED}{UPDATE}</>;
      case APPOINTMENT_STATUS.CANCEL:
        return <>{DELETE}{DECLINED}</>;
      case APPOINTMENT_STATUS.NO_SHOW:
        return <>{DELETE}</>;
      default:
        return null;
    }
  };

  if (!data) return <Navigate to={`/store/${storage.shop_id.get()}/appointments`} replace />;

  return (
    <>
      <AppointmentContentStyled>
        <div className="box">
          <div className="title-box">
            <Text color='text_3' variant='H5'>DETAIL APPOINTMENT</Text>
          </div>
          <div className="body">
            <div className="head-content">
              <Row align={'middle'} style={{ position: 'relative' }}>
                <Text variant='H5' color='text_3'>{data?.customerName} - {customerPhone}</Text>
                <button style={{ position: 'absolute', right: '-30px', top: '2px', width: '25px', height: '25px' }} onClick={handleToggleVerify}>{isMaskPhone ? <EyeOpen /> : <EyeClose />}</button>
              </Row>
            </div>
            <Text mb={1} />
            <div className="divider" />
            <Text mb={1.5} />
            <div className='row-item row-time'>
              <Icon type='time' />
              <div className="row-grow">
                <button className='btn-link' onClick={handleOpenTimeStartPicker}>{timeStart?.format(DATE_FORMAT)}</button>
                <Text variant='H8' color='text_3' mr={4} />
                <button className='btn-link' onClick={handleOpenTimeStartPicker} >{timeStart?.format(FORMAT_TIME)}</button>

                <Text variant='H8' color='text_3' mx={1}>-</Text>
                <Text variant='H8' color='text_3'>{timeEnd?.format(FORMAT_TIME)}</Text>
              </div>
            </div>
            <Text mb={1.5} />
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="row-item">
                  <PeriodTimeIcon />
                  <div className="row-grow">
                    <div className="w-100">
                      <InputPeriod value={repeat} onChange={setRepeat} />
                    </div>
                  </div>
                </div>
              </Col>
              <Col span={12}>
                <div className="row-item">
                  <IconGroup />
                  <div className="row-grow">
                    <div className="w-100">
                      <Input
                        value={group}
                        style={{ height: '3.5rem' }}
                        onChange={e => setGroup(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Text mb={1} />
            <Row gutter={[16, 16]}>
              <Col span={12}>
                <div className="row-item" style={{ alignItems: 'start' }}>
                  <div style={{ width: '18px', marginTop: '4px' }}><MakeNoteIcon /></div>
                  <div className="row-grow">
                    <div className="w-100">
                      <Input.TextArea
                        rows={3}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        placeholder='Note ...'
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Text mb={1.5} />
            <div className='row-item list-services'>
              <Row gutter={[16, 16]} style={{ width: '100%', flex: 1, }}>
                {services.map(o => {
                  return (
                    <Col
                      span={12}
                      key={o.uuid_local}
                    >
                      <ServiceItem staffId={o.staffId} data={o} onPickStaff={onPickStaff(o)} onRemove={handleRemove(o)} />
                    </Col>
                  );
                })}
                <Col
                  span={24}
                >
                  <Button
                    ntype='LIGHT_BLUE'
                    icon='addPlus'
                    onClick={() => setVisibleChooseStaff(true)}
                  >Add service</Button>
                </Col>
              </Row>

            </div>
            <div className="row-item">
              <Row align={'middle'} gutter={[16, 16]} justify={'end'} className="row-grow actions">
                <Col>
                  <Button icon='back' ntype='SECONDARY' onClick={() => navigate(-1)}  >
                    Back
                  </Button>
                </Col>
                <Actions />
              </Row>
            </div>
          </div>
        </div>
      </AppointmentContentStyled>
      <ModalChooseTime
        visible={visibleTimePicker}
        setTimeSlot={handleChangeTimeStart}
        staffId={data.staffId}
        timeSlot={timeStart}
        timePickerRef={timePickerRef}
        onClose={handleCloseTimeStartPicker}
      />
      <ModalChooseStaff
        visible={visibleChooseStaff}
        onChooseStaff={handleChooseStaff}
        onClose={() => setVisibleChooseStaff(false)}
      />
      <ModalChooseService
        visible={!!draftStaff}
        chooseService={handleChooseService}
        onClose={() => setDraftStaff(null)}
      />
      <ModalWarningExistService
        ref={modalWarningExistServiceRef}
      />
      {visiblePW && <RequestAdminLocalPW onlyAdmin visible onCorrect={onDeleteVerifyAdminSuccess} onClose={() => setVisiblePW(false)} />}
      <ModalInputReason
        visible={!!visibleModalReason}
        onAccept={handleAcceptReason}
        onClose={() => setVisibleModalReason(null)} />
      {!!showModalVerify && (
        <RequestAdminLocalPW
          onlyAdmin
          visible
          onCorrect={onVerifyAdminSuccess}
          onClose={() => setShowModalVerify(false)}
        />
      )}
    </>
  );
};

export default memo(AppointmentContent);

const ButtonApt = styled(Button) <{ aType?: 'cancel' | 'confirm' | 'no-show' | 'update' | 'delete' }>`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  padding: 12px 16px;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 1rem;
  font-style: normal;
  line-height: normal;
  height: 3.5rem;
  font-weight: 500;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    border: transparent !important;
    opacity: 0.5;
  }

  &.ant-btn {
    ${({ aType }) => {
      switch (aType) {
        case 'confirm':
          return `
            background-color: #87D68D !important;
            &:hover, &:disabled {
              background-color: #BCEBCB !important;
            }
          `;
        case 'update':
          return `
            background-color: #6FABB6 !important;
            &:hover, &:disabled {
              background-color: #C6E9EF !important;
            }
          `;
        case 'cancel':
          return `
            background-color: #FF8890 !important;
            &:hover, &:disabled {
              background-color: #FFE5E7 !important;
            }
          `;
        case 'no-show':
          return `
            background-color: #E5E5E5 !important;
            &:hover, &:disabled {
              background-color: #F6F7FC !important;
            }
          `;
        case 'delete':
          return `
          background-color: #FDCC43 !important;
          &:hover, &:disabled {
            background-color: #FEEDBF !important;
          }
          `;
        default:
          break;
      }
  
    }}

  }

`;

const AppointmentContentStyled = styled(Layout.Content)`
  width: 100%;
  height: 100%;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  .head-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-link {
    color: var(--info-infor-3, #6FABB6);
    font-family: Open Sans;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-decoration-line: underline;
    text-transform: capitalize;
  }

  .row-item {
    gap: 1rem;
    display: flex;
    align-items: center;
  }
  .row-grow {
    flex-grow: 1;
    display: flex;
    align-items: center;
  }

  .box {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #FFF;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    flex-grow: 1;
    .body {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 1rem 3rem;
    }
  }
  .list-services {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
  }
  .title-box {
    border-radius: 5px 5px 0px 0px;
    background: var(--blue-headline, #C6E9EF);
    padding: 1rem;
    text-align:center;
  }
  .divider {
    background: #86909C;
    height: 1px;
    width: 100%;
  }

  
  .row-time {
    margin-bottom: 0.5rem;
  }
  .w-100 {
    width: 100%;
  }
  .actions {
    button {
      width: 8rem;
    }
  }
`;

const IconGroup = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g clipPath="url(#clip0_6410_7860)">
      <path d="M11.8171 4.44332C12.8429 4.38241 13.8829 4.78328 14.6081 5.5103C15.2981 6.19073 15.7076 7.15177 15.6987 8.12269C15.7133 8.96035 15.4179 9.79592 14.8946 10.4488C14.3238 11.1675 13.4781 11.6631 12.5694 11.7976C11.5576 11.9619 10.4825 11.6758 9.686 11.03C8.93789 10.4347 8.43447 9.53952 8.3254 8.5889C8.29104 8.37415 8.30354 8.15601 8.30328 7.93944C8.3431 7.09294 8.69295 6.26986 9.26223 5.64331C9.91324 4.93009 10.8498 4.4795 11.8171 4.44332Z" fill="#030708" />
      <path d="M4.8553 4.62813C5.71482 4.5162 6.61052 4.95663 7.06058 5.6951C7.51689 6.40703 7.5247 7.37484 7.08401 8.09587C6.81928 8.53761 6.4041 8.88927 5.92046 9.06888C5.19577 9.34819 4.33339 9.22532 3.7183 8.75001C3.24351 8.39496 2.91709 7.84728 2.83171 7.26056C2.79787 7.09006 2.81297 6.9154 2.81114 6.74282C2.84264 6.3128 2.99726 5.89163 3.25548 5.54569C3.62979 5.0355 4.22485 4.69112 4.8553 4.62813Z" fill="#030708" />
      <path d="M18.677 4.62279C19.1346 4.57724 19.6071 4.67797 20.009 4.90157C20.6938 5.2725 21.1616 6.01463 21.1897 6.79371C21.1874 6.94937 21.1999 7.10711 21.1684 7.26069C21.0632 8.00021 20.5684 8.66841 19.8937 8.9878C19.4824 9.18745 19.0107 9.26112 18.5591 9.19187C17.6191 9.07005 16.8052 8.30086 16.6373 7.36742C16.5337 6.86633 16.6047 6.33141 16.8364 5.8751C17.1829 5.1801 17.9016 4.6863 18.677 4.62279Z" fill="#030708" />
      <path d="M0.845378 10.555C1.43028 9.99695 2.17474 9.63487 2.94211 9.40112C3.34142 9.75279 3.82922 10.0014 4.34644 10.1245C4.76397 10.2232 5.20102 10.2357 5.62479 10.1727C6.20735 10.0818 6.76231 9.82828 7.2155 9.45188C7.23892 9.43809 7.25428 9.40164 7.28448 9.40529C7.3545 9.42299 7.42218 9.44928 7.49116 9.47062C7.63016 9.93188 7.83762 10.3723 8.10651 10.7721C7.61246 10.9231 7.13741 11.1314 6.67407 11.3589C5.79216 11.8071 4.96987 12.3938 4.32224 13.1464C4.03668 13.4803 3.78315 13.8434 3.58844 14.2381C3.57699 14.288 3.52155 14.2628 3.48771 14.2644C2.54906 14.141 1.61405 13.9275 0.746464 13.5418C0.454404 13.4244 0.211542 13.1867 0.0936253 12.8946C-0.028977 12.6041 -0.0112764 12.2782 0.0334956 11.9729C0.126423 11.4224 0.446335 10.9351 0.845378 10.555Z" fill="#030708" />
      <path d="M16.5107 9.46932C16.5839 9.4498 16.6539 9.4157 16.7294 9.40477C17.0545 9.68668 17.4348 9.90481 17.8424 10.0422C18.6478 10.3124 19.5578 10.2593 20.3226 9.88737C20.5876 9.7593 20.8369 9.59687 21.0572 9.40112C21.7277 9.6039 22.3758 9.90611 22.9227 10.3491C23.2861 10.6477 23.6073 11.0111 23.8023 11.4427C23.9392 11.7386 24.0025 12.0661 23.9991 12.3915C24.0184 12.8605 23.739 13.3273 23.3077 13.5175C22.4339 13.915 21.4893 14.1334 20.541 14.2612C20.5024 14.2599 20.4564 14.2821 20.4228 14.2594C20.1349 13.6818 19.7353 13.1628 19.2699 12.7174C18.747 12.2087 18.1397 11.7928 17.498 11.4484C16.9839 11.1777 16.4482 10.9463 15.8938 10.7716C16.164 10.3718 16.3694 9.93032 16.5107 9.46932Z" fill="#030708" />
      <path d="M5.77686 13.116C6.66423 12.3937 7.7234 11.9027 8.82057 11.5945C9.56322 12.2927 10.5474 12.7263 11.5618 12.8172C12.868 12.9465 14.2242 12.4957 15.1811 11.5938C16.0583 11.841 16.9069 12.2049 17.669 12.7076C18.2084 13.0668 18.7045 13.4992 19.0997 14.0146C19.4724 14.5016 19.7512 15.0688 19.8553 15.6761C19.9035 15.9752 19.9186 16.2805 19.8988 16.583C19.8587 17.1835 19.5307 17.7569 19.0333 18.0964C18.8433 18.2343 18.6215 18.3163 18.4083 18.4108C17.2898 18.8734 16.1075 19.1688 14.9119 19.3474C13.5016 19.5632 12.0684 19.6061 10.6448 19.5223C8.82057 19.3898 6.98831 19.0493 5.30753 18.3067C4.63387 18.0183 4.14867 17.3381 4.10416 16.6056C4.07917 16.1811 4.10103 15.7482 4.22129 15.3379C4.47196 14.4477 5.0717 13.696 5.77686 13.116Z" fill="#030708" />
    </g>
    <defs>
      <clipPath id="clip0_6410_7860">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
