import { Input, InputRef, Select } from 'antd';
import Icon from 'assets/Icons';
import CurrencyInput, { CurrencyInputFormStyled } from 'components/CurrencyInputMasked';
import { FormItem, useFormInstance } from 'components/Form';
import { debounce } from 'lodash';
import moment from 'moment';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { styled } from 'styled-components';
export type IssueFormValues = {
  'staff': IStaffItemData,
  'giftNumber': string,
  'amount': number,
  'expiration': number,
}
type IssueGiftCardRef = {
  setMsg: (text: string, type: 'warning' | 'success') => void;
};
export const useIssueGiftCardRef = () => useRef<IssueGiftCardRef>(null);
export const EPeriodDates = {
  MONTH_1: moment().add('month', 1).valueOf(),
  MONTH_3: moment().add('month', 3).valueOf(),
  MONTH_6: moment().add('month', 6).valueOf(),
  MONTH_12: moment().add('month', 6).valueOf(),
  NO_LIMIT: moment('9999-12-12').valueOf(),
};
const periodDates = [
  { label: '1 month', value: EPeriodDates.MONTH_1, },
  { label: '3 month', value: EPeriodDates.MONTH_3 },
  { label: '6 month', value: EPeriodDates.MONTH_6 },
  { label: '12 month', value: EPeriodDates.MONTH_12 },
  { label: 'No limit', value: EPeriodDates.NO_LIMIT },
];
type Props = {
  checkExistGiftCard: (code: string) => Promise<void>;
};
const IssueGiftCard = forwardRef<IssueGiftCardRef, Props>(({ checkExistGiftCard }, ref) => {
  const form = useFormInstance();
  const inputRef = useRef<InputRef>(null);
  const [msg, setMsg] = useState('');
  const [type, setType] = useState<'warning' | 'success'>('warning');
  const [code, setCode] = useState<string>('');

  const onScan: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    inputRef.current?.focus();
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      const code = form.getFieldValue('giftNumber');
      await checkExistGiftCard(code);
      setTimeout(() => inputRef.current?.blur(), 100);
      return;
    }
  };



  useImperativeHandle(ref, () => ({
    setMsg: (text, type) => {
      setType(type);
      setMsg(text);
    },
  }));
  const _debounce = useCallback(debounce(checkExistGiftCard, 1000), [checkExistGiftCard]);
  useEffect(() => {
    setMsg('');
    setType('warning');
    _debounce(code);
  }, [code]);

  return (
    <IssueGiftCardStyled>
      <FormItem
        label={'Gift Number'}
        name={'giftNumber'} rules={[{ required: true }]}
        validateStatus={msg ? type : undefined}
        help={msg}
      >
        <Input
          ref={inputRef}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          placeholder='0'
          addonAfter={
            <button onClick={onScan}>
              {<Icon type={'QR'} />}
            </button>
          }
          value={code}
          onChange={e => setCode(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </FormItem>
      <FormItem label='Amount' name={'amount'} rules={[{ required: true }]}>
        <CurrencyInputFormStyled>
          <CurrencyInput onChange={val => form.setFieldValue('amount', val)} />
        </CurrencyInputFormStyled>
      </FormItem>
      <FormItem label={'Expiration'} name={'expiration'} rules={[{ required: true }]}>
        <Select options={periodDates} />
      </FormItem>
    </IssueGiftCardStyled>
  );
});
IssueGiftCard.displayName = 'IssueGiftCard';
export default IssueGiftCard;
const IssueGiftCardStyled = styled.div`
.ant-input-number {
  width: 100%;
}
.ant-input-number-handler-wrap {
  display: none;
}
.ant-input-number-input-wrap {
  height: 100%;
  input {
    height: 100%;
    color: var(--text-text-3, #1D2129);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
.ant-input {
  height: 3.5rem;
}
.ant-form-item-explain-success {
  color: #00B42A;
}
`;