import Button from 'components/Button';
import Modal from 'components/Modal';
import { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';
import { useRef, useState } from 'react';
import AmountDiscountKeyboard from './AmountDiscountKeyboard';

type Props = {
  onSubmit?: (type: DISCOUNT_VALUE_TYPE, value: number) => void;
};

const ButtonApplyDiscountItem = ({ onSubmit = () => undefined }: Props) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<undefined | number | null>(null);
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const [discountValueType, setDiscountValueType] = useState(DISCOUNT_VALUE_TYPE.PERCENT);
  const handleClose = () => setVisible(false);

  const _onSubmit = () => {
    onSubmit(discountValueType, value || 0);
    handleClose();
  };

  const onOpen = () => {
    setVisible(true);
    setValue(null);
    setDiscountValueType(DISCOUNT_VALUE_TYPE.PERCENT);
    setTimeout(() => {
      currencyRef.current?.setValue('');
    }, 100);
  };

  return (
    <>
      <Button className='custom-button' height={3} ntype='ORANGE' onClick={onOpen}>Apply Discount Items</Button>
      <Modal
        modalTitle='Apply Discount Items' okTitle='Apply'
        visible={visible}
        onClose={handleClose} onSubmit={_onSubmit}
      >
        <AmountDiscountKeyboard
          ref={currencyRef} value={value} onChange={setValue}
          discountValueType={discountValueType}
          setDiscountValueType={setDiscountValueType}
        />
      </Modal>
    </>
  );
};

export default ButtonApplyDiscountItem;
