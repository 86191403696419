import Box from 'components/Box';
import { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import { useTicketWrapper } from 'features/ShopWrapperLayout/TicketWrapperContext';
import CardBox from 'features/cashier/components/CardBox';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import paymentApis from 'features/payment/services/apis';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IBodyAPIPayment } from 'features/payment/services/types/api';
import { IStateDetailPayment } from 'features/payment/services/types/payment';
import { useSocketContext } from 'hooks/useSocket';
import { get, set, sumBy } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setLoadingPage } from 'services/UI/sagas';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import AddATip, { ModalAddTipRef } from '../../../CustomerSide/components/AddATip';
import multiplePaymentActions from '../../../services/actions';
import multiplePaymentSelectors from '../../../services/selectors';
import PassDataPayment, { PassDataPaymentRef } from '../../PassDataPayment';
import CheckOthers from '../CheckOthers';
import CreditCard from '../CreditCard';
import ButtonGiftCard from '../GiftCard';
import LoyaltyPoint from '../LoyaltyPoint';
import DescribePayment from './DescribePayment';
import DetailItems from './DetailItems';
import DetailPayment from './DetailPayment';
import PaymentKeyboard from './PaymentKeyboard';
import shopSelectors from 'services/shop/selectors';
import { FLOW_ADD_TIP } from 'services/shop/constants';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import { TypePaymentActors, TypePaymentPassData } from '../../../services/types/socketPayment';
import Signature, { ISignatureRef } from 'features/payment/components/CustomerSignature';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import useSMSEmailSignature from 'features/payment/hooks/useSMSEmailSignature';
import ConfirmTipTicket, { useConfirmTipTicketRef } from 'features/payment/components/ConfirmTipTicket';
import CreditCardLoading, { ICreditPaymentLoadingRef } from 'features/payment/components/PaymentLoading/CreditCardLoading';
import CardBoxV2 from '../CardBoxV2';
import CardFeeDiscount from './CardFeeDiscount';
import { useDollarAmountInputRef } from 'components/DollarAmount/Input';
type Props = {
  billId?: string,
  onClose?: () => void;
  v2?: boolean,
  discountIncentive?: boolean,
  enableCardFee?: boolean,
};
const PaymentInfoBox = ({ billId: id = '', onClose, v2, enableCardFee: enableCardFeeProp, discountIncentive: discountIncentiveProp }: Props) => {
  const tipRef = useRef<ModalAddTipRef>(null);
  const tipBeforeRef = useRef<ModalAddTipRef>(null);
  const bodyPaymentRef = useRef<IBodyAPIPayment | null>(null);
  const loadingSignatureRef = useRef<IPaymentLoadingRef>(null);
  const signatureRef = useRef<ISignatureRef>(null);
  const completedTicketModalRef = useConfirmTipTicketRef();

  const { state } = useLocation();

  const _enableDiscountIncentive = state?.discountIncentive === undefined ? true : !!state?.discountIncentive;
  const [enableDiscountIncentive, setEnableDiscountIncentive] = useState(discountIncentiveProp ?? _enableDiscountIncentive);
  const _enableCardFee = state?.enableCardFee === undefined ? true : !!state?.enableCardFee;
  const [enableCardFee, setEnableCardFee] = useState(enableCardFeeProp ?? _enableCardFee);

  const customerRef = useRef<PassDataPaymentRef>(null);
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const creditCardLoadingRef = useRef<ICreditPaymentLoadingRef>(null);
  const dispatch = useAppDispatch();
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);
  const dollarRef = useDollarAmountInputRef();
  const triggerClear = useRef(false);
  const allSetting = shopSelectors.data.allSetting();
  const SMSEmailSignature = useSMSEmailSignature();

  const [amountKB, setAmountKB] = useState<number | null>();

  const ticketContext = useTicketWrapper();
  const navigate = useNavigate();
  const socketContext = useSocketContext();

  const detailPayment = multiplePaymentSelectors.getDetailPayment();
  const detailTicket = multiplePaymentSelectors.getDetail();

  const onBack = () => {
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/check-in/sign-in`);
    if (onClose) return onClose();
    navigate(`/store/${storage.shop_id.get()}`);
  };

  const remaining = useMemo(() => {
    const totalPayment = sumBy(Object.values(detailPayment), o => (o?.amount ?? 0));
    const value = (detailTicket?.total ?? 0) - +(totalPayment?.toFixed(2));
    return value > 0 ? value : 0;
  }, [detailTicket, detailPayment]);

  const handleClear = () => {
    triggerClear.current = true;
    currencyRef.current?.clear();
    dispatch(multiplePaymentActions.clearPaymentInfo());
    dollarRef.current?.focus('');
  };


  useEffect(() => {
    if (triggerClear.current) {
      triggerClear.current = false;
      return;
    }
    if (allSetting?.enableAutoFillAmountMultiPay) {
      const amountStr = remaining?.toFixed(2) || '';
      currencyRef.current?.setValue(amountStr);
      dollarRef.current?.init(remaining ? amountStr : '');
    } else {
      currencyRef.current?.setValue(0?.toFixed(2) || '');
      dollarRef.current?.init('');
    }
  }, [detailPayment, detailTicket]);

  const onCompleted = async (ignorePrint?: boolean) => {
    if (!detailTicket?.billId) return;
    signatureRef.current?.close();
    dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${id}`);
    const callback = () => {
      if (ignorePrint) {
        navigate(`/store/${storage.shop_id.get()}`);
        return;
      }
      ticketContext.completedTicket(detailTicket?.billId, true);
    };
    if (!completedTicketModalRef.current) return callback();
    completedTicketModalRef.current.open(id, callback);

  };

  // open tip-modal-before here
  const onCreditCardBeforePayment = (body: IBodyAPIPayment) => {
    const cardFee = detailTicket?.cardFee || 0;
    const totalCC = (body.paymentInfo.find(o => o.paymentType === 'CREDIT_CARD')?.amount || 0) + cardFee;

    setTimeout(() => {
      tipBeforeRef.current?.openWithSpecificTotal(totalCC);
      socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
        billId: body?.billId || '',
        actor: TypePaymentActors.CASHIER,
        action: TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_TIP_MULTI_PAY,
        data: { totalCC }
      });
    }, 10);
  };

  // open tip-modal-after here
  const onPostPaymentWithCreditCard = (totalCC: number) => {
    tipRef.current?.openWithSpecificTotal(totalCC);
    customerRef.current?.requestSignature({ totalCC });
  };

  const handlePostPayment = async (_detailPayment: IStateDetailPayment, ignorePrint?: boolean) => {
    const totalPayment = sumBy(Object.values(_detailPayment), o => (o?.amount ?? 0));
    const value = (detailTicket?.total ?? 0) - (+totalPayment?.toFixed(2));
    const remaining = value > 0 ? value : 0;
    if (remaining > 0) return;

    const paymentItems = Object.entries(_detailPayment).map(([key, value]) => {
      if (key === PAYMENT_TYPE.CASH) {
        const item = {
          paymentType: key as PAYMENT_TYPE,
          ...value,
          chance: Math.abs(get(value, 'chance', 0) || 0),
          amount: value?.amount ?? 0,
        };
        if (enableDiscountIncentive) {
          set(item, 'cashIncentiveDiscount', true);
        }
        return item;
      }
      const item = {
        paymentType: key as PAYMENT_TYPE,
        ...value,
        amount: value?.amount ?? 0,
      };
      if (key === PAYMENT_TYPE.CHECK) {
        if (enableDiscountIncentive)
          set(item, 'cashIncentiveDiscount', true);
      }
      if ([PAYMENT_TYPE.VENMO, PAYMENT_TYPE.CASH_APPS, PAYMENT_TYPE.ZELLE, PAYMENT_TYPE.PAYPAL].includes(key as PAYMENT_TYPE)) {
        item.paymentType = PAYMENT_TYPE.CHECK;
      }

      if (key === PAYMENT_TYPE.CREDIT_CARD) {
        if (enableCardFee)
          set(item, 'chargeFee', enableCardFee);
      }
      return item;
    }).filter(o => !!o.amount);

    const body: IBodyAPIPayment = {
      billId: detailTicket?.billId || '',
      paymentInfo: paymentItems,
    };

    if (paymentItems?.find(o => o.paymentType == PAYMENT_TYPE.CREDIT_CARD)) {
      if (allSetting?.flowAddTip === FLOW_ADD_TIP.TIP_BEFORE_SALE) {
        bodyPaymentRef.current = body;
        return onCreditCardBeforePayment(body);
      }
    }
    const cardFee = detailTicket?.cardFee || 0;
    if (detailPayment.CREDIT_CARD?.amount) {
      const totalCC = detailPayment.CREDIT_CARD?.amount + cardFee;
      sendingData(TypePaymentPassData.CREDIT_CARD_PAYMENT_PROCESSING, { totalCC });
      creditCardLoadingRef.current?.open(totalCC, 120000);
    } else {
      loadingRef.current?.setVisible(true);
    }

    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        if (detailPayment.CREDIT_CARD?.amount) {
          onPostPaymentWithCreditCard(detailPayment.CREDIT_CARD?.amount + cardFee);
        } else {
          onCompleted(ignorePrint);
        }
      } else {
        throw 'fail';
      }
    } catch (error) {
      if (detailPayment.CREDIT_CARD?.amount) {
        sendingData(TypePaymentPassData.CREDIT_CARD_PAYMENT_DONE, {});
      }
    }
    finally {
      loadingRef.current?.setVisible(false);
      creditCardLoadingRef.current?.close();
    }
  };

  const handleAddATip = async (val: number) => {
    const billId = detailTicket?.billId;
    if (val && billId) {
      setLoadingPage(true);
      try {
        await paymentApis.addTipCreditCard(billId, val);
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
    }
    signatureRef.current?.open();
    sendingData(TypePaymentPassData.REQUEST_SIGNATURE_ONLY, {});
  };

  const onSkipAddATip = () => signatureRef.current?.open();

  const handleAddATipBefore = async (val: number) => {
    tipBeforeRef.current?.close();
    if (!bodyPaymentRef.current) return;
    bodyPaymentRef.current.paymentInfo = bodyPaymentRef.current.paymentInfo.map(o => {
      if (o.paymentType === PAYMENT_TYPE.CREDIT_CARD) {
        if (val) {
          return ({
            ...o,
            tip: val,
          });
        }
      }
      return o;
    });

    // loadingRef.current?.setVisible(true);
    const creditValue = (detailPayment.CREDIT_CARD?.amount || 0) + val;
    const cardFee = detailTicket?.cardFee || 0;
    const totalCC = creditValue + cardFee;
    creditCardLoadingRef.current?.open(totalCC, 120000);

    const body: IBodyAPIPayment = bodyPaymentRef.current;
    try {
      const res: IResponseDataBody<true> = await paymentApis.payment(body);
      if (res?.data?.data) {
        if (detailPayment.CREDIT_CARD?.amount) {
          signatureRef.current?.open();
          socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
            billId: detailTicket?.billId || '',
            actor: TypePaymentActors.CASHIER,
            action: TypePaymentPassData.TIP_BEFORE_PAYMENT_REQUEST_SIGNATURE
          });
        }
      } else {
        throw 'fail';
      }
    } catch (error) { }
    finally {
      // loadingRef.current?.setVisible(false);
      creditCardLoadingRef.current?.close();
    }
  };

  const onSkipAddATipBefore = () => handleAddATipBefore(0);

  const onPostPayment = () => handlePostPayment(detailPayment);

  const handleApplyKeyboard = (paymentType: PAYMENT_TYPE) => () => {
    const _amount = amountKB || 0;

    let paymentAmount = 0;

    switch (paymentType) {
      case PAYMENT_TYPE.CASH: {
        const cash = get(detailPayment, [PAYMENT_TYPE.CASH, 'amount'], 0) || 0;
        paymentAmount = cash + _amount;
        dispatch(multiplePaymentActions.setCashPayment(paymentAmount));
        break;
      }
      case PAYMENT_TYPE.CREDIT_CARD: {
        const card = get(detailPayment, [PAYMENT_TYPE.CREDIT_CARD, 'amount'], 0) || 0;
        paymentAmount = card + _amount;
        dispatch(multiplePaymentActions.setCardPayment(paymentAmount));
        break;
      }
    }

    /**
     * AUTO COMPLETE PAYMENT
     */
    // const newRemaining = remaining - paymentAmount;
    // if (newRemaining <= 0) {
    //   const _detailPayment = cloneDeep(detailPayment);
    //   set(_detailPayment, [paymentType, 'amount'], paymentAmount);
    //   handlePostPayment(_detailPayment);
    // }
  };

  const sendingData = (action: TypePaymentPassData, data: any) => socketContext.send(PAYMENT_PASS_DATA_TOPIC, {
    billId: detailTicket?.billId || '',
    actor: TypePaymentActors.CASHIER,
    action,
    data
  });
  const handleChangeTip = (target: 'before' | 'after') => (val: number) => {
    if (target === 'before')
      return sendingData(TypePaymentPassData.TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING, { tip: val });
    sendingData(TypePaymentPassData.TIP_PAYMENT_SEND_TIP_AFTER_TYPING, { tip: val });
  };


  const onSignatureDone = async (signatureValue: string) => {
    signatureRef.current?.close();
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.updateSignature(detailTicket?.billId || '', removeBase64Prefix(signatureValue));
      if (res.data.data) {
        handleDoneSignature();
        return;
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const handleDoneSignature = (ignorePrint?: boolean) => {
    if (!detailTicket?.billId) return;
    signatureRef.current?.close();
    dispatch(multiplePaymentActions.setCashierMsg.fetch(null));
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${id}`);
    const callback = () => {
      if (ignorePrint) {
        navigate(`/store/${storage.shop_id.get()}`);
        return;
      }
      ticketContext.completedTicket(detailTicket?.billId, true);
    };
    if (!completedTicketModalRef.current) return callback();
    completedTicketModalRef.current.open(id, callback);
  };

  return (
    <>
      <PaymentInfoBoxStyled>
        <Box display={'flex'} className='box-payment'>
          <Box width={v2 ? '850px' : '45rem'} overflow="auto" display='flex' flexDirection='column'>
            {v2 ?
              <Box style={{ background: '#232F3E' }} className="center" height={'4rem'}>
                <Text variant="H5" style={{ color: '#FFF', fontSize: 30 }}>
                  PAYMENTS
                </Text>
              </Box>
              :
              <Box bgColor="primary_button" className="center" height={'3.5rem'}>
                <Text variant="H5" color="text_3">
                  PAYMENTS
                </Text>
              </Box>}
            <PaymentKeyboard
              v2={v2}
              keyboardRef={currencyRef}
              inputRefV2={dollarRef}
              onBack={onBack}
              onDone={onPostPayment}
              onClear={handleClear}
              value={amountKB}
              remaining={remaining}
              onChange={setAmountKB}
              allSetting={allSetting}
            />
            <CardFeeDiscount
              v2={v2}
              data={detailTicket}
              enableDiscountIncentive={enableDiscountIncentive}
              enableCardFee={enableCardFee}
              onChangeSwitchCardFee={setEnableCardFee}
              onEnableCashIncentive={setEnableDiscountIncentive}
            />
          </Box>
          <Box style={v2 ? { display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', background: '#F5F5F5' } : undefined} width={v2 ? '250px' : '13rem'} bl="line_2" br="line_2" px="2" py="4">
            <Box px="1" display="flex" flexDirection="column" style={v2 ? { flexGrow: 1 } : undefined} gap={!v2 ? '4' : undefined}>
              <LoyaltyPoint
                v2={v2}
                amountKB={amountKB || 0}
                onRedeem={handlePostPayment}
                disabled={remaining === 0}
              />
              <ButtonGiftCard
                v2={v2}
                amountKB={amountKB || 0}
                onRedeem={handlePostPayment}
                disabled={remaining === 0}
              />
              <CheckOthers
                v2={v2}
                keyboardRef={currencyRef}
                amountKB={amountKB || 0}
                customerRef={customerRef}
                onRedeem={handlePostPayment}
                disabled={remaining === 0}
                remaining={remaining}
              />
              {v2
                ? <CardBoxV2
                  type='CASH'
                  onClick={handleApplyKeyboard(PAYMENT_TYPE.CASH)}
                  disabled={remaining === 0}
                /> :
                <CardBox
                  icon="cash"
                  type={'DEFAUL'}
                  onClick={handleApplyKeyboard(PAYMENT_TYPE.CASH)}
                  disabled={remaining === 0}
                >
                  Cash
                </CardBox>
              }

              <CreditCard
                v2={v2}
                onRedeem={handleApplyKeyboard(PAYMENT_TYPE.CREDIT_CARD)}
                disabled={remaining === 0}
              />
            </Box>
          </Box>
          <Box width={v2 ? '400px' : '19rem'} overflow="auto" style={v2 ? { background: '#f5f5f5', borderLeft: '1px solid #CCD4DC' } : undefined}>
            {!v2 && <DetailItems v2={v2} data={detailTicket} />}
            <DescribePayment
              v2={v2}
              data={detailTicket}
              enableDiscountIncentive={enableDiscountIncentive}
              enableCardFee={enableCardFee}
            />
            <DetailPayment v2={v2} data={detailPayment} remaining={remaining} />
          </Box>
        </Box>
      </PaymentInfoBoxStyled>
      <PaymentLoading ref={loadingRef} />
      <PaymentLoading ref={loadingSignatureRef} />
      <CreditCardLoading onCancel={onBack} ref={creditCardLoadingRef} />
      <PassDataPayment
        ref={customerRef}
        onDone={onCompleted}
        remaining={remaining}
        onTipBeforePayment={handleAddATipBefore}
        tipRef={tipRef}
        tipBeforeRef={tipBeforeRef}
        signatureRef={signatureRef}
        enableDiscountIncentive={enableDiscountIncentive}
        enableCardFee={enableCardFee}
      />
      <AddATip
        ref={tipRef}
        onAddATip={handleAddATip}
        onChangeTip={handleChangeTip('after')}
        onSkipAddATip={onSkipAddATip}
      />
      <AddATip
        ref={tipBeforeRef}
        onAddATip={handleAddATipBefore}
        onChangeTip={handleChangeTip('before')}
        onSkipAddATip={onSkipAddATipBefore}
      />
      <Signature
        ref={signatureRef}
        onAgree={onSignatureDone}
        onNoReceipt={SMSEmailSignature.onNoReceipt(detailTicket?.billId || '', () => handleDoneSignature(true))}
        onEmail={SMSEmailSignature.onEmail(detailTicket?.billId || '', () => handleDoneSignature(true))}
        onSMS={SMSEmailSignature.onSMS(detailTicket?.billId || '', () => handleDoneSignature(true))}
        defaultSMSPhone={detailTicket?.customerPhone}
      />
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </>
  );
};

export default PaymentInfoBox;

const PaymentInfoBoxStyled = styled.div`
  .box-payment {
    background: #fff;
    border-radius: 5px;
    opacity: 0.9;
    background: var(--fill-fill-0, #FFF);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  display: flex;
  .divider {
    background: #86909C;
    width: 100%;
    height: 1px;
  }
`;