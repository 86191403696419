import { Col, Row } from 'antd';
import IconLogo from 'assets/Icons/logo';
import Box from 'components/Box';
import Text from 'components/Text';
import Sidebar from 'components/layout/Sidebar';
import reportSelectors from 'features/report/services/selectors';
import { ITimeClockReportHistory } from 'features/report/services/types/reportStaff';
import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import PrintHeader from '../components/PrintHeader';
import { ReportType } from '../constants';
import { renderTime } from '../utils';

type ITimeClockReportPrintPageProps = any;
const TimeClockReportPrintPage: React.FC<ITimeClockReportPrintPageProps> = () => {
    const navigate = useNavigate();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.CLOCK_TIME];
    const dataDashboardTimeClock = reportSelectors.getDashboardTimeClockReport();
    const dataTimeClockHistory = reportSelectors.getTimeClockReportHistory();

    const RowItem = (item: ITimeClockReportHistory) => {
        return (
            <Row style={{ padding: '2px 0px' }}>
                <ColHeader span={6}>
                    <Text printMode variant='CAPTION_3' color='text_3'>{item.staffName}</Text>
                </ColHeader>
                <ColHeader span={6} style={{ textAlign: 'left', paddingRight: 5 }}>
                    <Text printMode variant='CAPTION_3' color='text_3'>{item.workTimeHour + ' H'}</Text>
                </ColHeader>
                <ColHeader span={6} style={{ textAlign: 'start', }}><Text printMode variant='CAPTION_3' color='text_3'>{item.strClockInTime}</Text></ColHeader>
                <ColHeader span={6} style={{ textAlign: 'end' }}><Text printMode variant='CAPTION_3' color='text_3'>{item.strClockOutTime}</Text></ColHeader>
            </Row>
        );
    };
    const RowTimeClockItem = (title: string, value: string) => {
        return (
            <Box className="space-between">
                <Text printMode variant="CAPTION_3" color="text_3">
                    {title}
                </Text>
                <Text printMode variant="CAPTION_3" color="text_3">
                    {value}
                </Text>
            </Box>
        );
    };
    const onBack = () => {
        navigate(-1);
    };
    const divRef = useRef<HTMLDivElement>(null);
    const onPrint = useReactToPrint({
        content: () => divRef.current,
        copyStyles: true,
    });

    return (
        <ITimeClockReportPrintPage>
            <Sidebar />
            <ContainerStyled>
                <PrintHeader onBack={onBack} onPrint={onPrint} />
                <div ref={divRef}>
                    <Box className="center" overflow="auto">
                        <TicketBox>
                            <LogoBox>
                                <IconLogo />
                            </LogoBox>
                            <Box className="center" pt='1'>
                                <Text printMode variant="CONTENT_2" color="text_2">
                                    {renderTime(dataAppointment)}
                                </Text>
                            </Box>
                            <Box>
                                <CrossSales>
                                    <Box className="space-between" bb="print_line">
                                        <Text printMode variant="CAPTION_2" color="text_3">
                                            Time Clock
                                        </Text>
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                    >
                                        {/* {RowTimeClockItem('Break Time','5 hours')}
                                        {RowTimeClockItem('Block TIme','1 hours')} */}
                                        {RowTimeClockItem('Hourly Payroll', `${dataDashboardTimeClock.hourlyPayroll} hours`)}
                                        {RowTimeClockItem('Hourly Pay Out',`${dataDashboardTimeClock.hourlyPayOut} hours`)}
                                        {RowTimeClockItem('Tips', formatCurrency(dataDashboardTimeClock.totalTip))}
                                    </Box>
                                    <Header>
                                        <ColHeader span={6}>  <Text printMode variant="CAPTION_3" color="text_2" className='fs-sm'>STAFF</Text></ColHeader>
                                        <ColHeader span={6} style={{ textAlign: 'center' }}>  <Text printMode variant="CAPTION_3" color="text_2" className='fs-sm'>WORK TIME</Text></ColHeader>
                                        <ColHeader span={6} style={{ textAlign: 'end', paddingRight: 6 }}>  <Text printMode variant="CAPTION_3" color="text_2" className='fs-sm'>CLOCK IN</Text></ColHeader>
                                        <ColHeader span={6} style={{ textAlign: 'end' }}>  <Text printMode variant="CAPTION_3" color="text_2" className='fs-sm'>CLOCK OUT</Text></ColHeader>
                                    </Header>
                                    {dataTimeClockHistory.map(m => RowItem(m))}
                                </CrossSales>
                            </Box>
                        </TicketBox>
                    </Box>
                </div>
            </ContainerStyled>
        </ITimeClockReportPrintPage>
    );
};
const ITimeClockReportPrintPage = styled.div`
  display: flex;
`;
const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 72mm;
  height: fit-content;
  padding: 1.5rem 0 2rem;
`;

const ContainerStyled = styled.div`
  background-color: #e5e5e5;
  height: 100vh;
  position: relative;
  flex-grow: 1;
  width: 100%;
  overflow: auto;
`;
const LogoBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
`;
const CrossSales = styled.div`
  padding: 5px 1.5rem;
`;
const Header = styled(Row)`
  border-top:1px solid black;
  padding: 2px 0px;
  border-bottom: 1px dashed black;
  font-family: 'Open Sans';
`;

const ColHeader = styled(Col)`
    color:  black;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Open Sans';
    .fs-sm {
        font-size: 10px;
    }
`;
export default TimeClockReportPrintPage;
