import { Form } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import Box from 'components/Box';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { PeriodValues } from 'features/appointment/components/InputPeriod';
import appointmentActions from 'features/appointment/services/actions';
import appointmentApis from 'features/appointment/services/apis';
import { APPOINTMENT_SOURCE_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { IBodyApiMakeAppointment } from 'features/appointment/services/types/api';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import { useEffect, useMemo } from 'react';
import { IResponseDataBody } from 'services/response';
import { ICustomerItemData } from 'services/shop/types/customer';
import { IStaffItemData } from 'services/shop/types/staff';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CalendarPicker from '../../QuickBooking/CalendarPicker';
import CustomerSearchInput from '../../QuickBooking/CustomerSearchInput';
import StartTimes, { useStartTimesRef } from '../../QuickBooking/StartTimes';
import Repeat from './Repeat';
import StaffChoose from './StaffChoose';
import StaffHandler from './StaffHandler';
import BlockHourStaffs from '../../QuickBooking/BlockHourStaffs';
type IFormValues = {
  staff: IStaffItemData | null;
  customer: ICustomerItemData | null;
  repeat: number;
  note: string;
  services: { label: string, value: string, id: string }[];
  date: Moment;
  startTime: string;
};
const NewAppointmentVersionV2 = () => {
  const dispatch = useAppDispatch();
  const data = appointmentSelectors.getNewAppointmentDraftData();
  const staffList = appointmentSelectors.getStaffList();
  const [form] = Form.useForm();
  const startTimesRef = useStartTimesRef();
  const setLoadingPage = useSetLoadingPage();
  const blockHours = appointmentSelectors.getLockBreakTimesForQuickBooking();
  const staffs = useMemo(() => staffList.filter(o => !o.anyBody), [staffList]);

  const reset = () => form.resetFields();

  useEffect(() => {
    if (!data) return;
    const anyone = staffList.find(o => o.anyBody);
    const staffData = staffList.find(o => o.id === data.staff?.id);
    form.setFieldValue('staff', staffData || anyone || null);
    const date = data?.colTime ? moment(data.colTime) : moment();
    form.setFieldValue('date', date);
    form.setFieldValue('startTime', date.format('hh:mm A'));
    setTimeout(() => onPickDate(date), 10);
  }, [data]);

  const onPickDate = async (value: Moment) => {
    if (!value) return;
    const startDate: string = form.getFieldValue('startTime');
    form.setFieldValue('time-slots', []);

    dispatch(appointmentActions.getLockBreakTimesForQuickBooking.fetch({ startTime: value?.format(DATE_FORMAT), endTime: value?.format(DATE_FORMAT) }));

    const staffId = form.getFieldValue('staff')?.id || '';
    startTimesRef.current?.setLoading(true);
    try {
      const resData: IResponseDataBody<string[]> = await appointmentApis.getStaffTimeSlot(staffId || '', value?.format(DATE_FORMAT) || '');
      const list = resData.data.data;

      if (list) {
        const data: string[] = list.map(d => moment(d, 'HH:mm').format('hh:mm A'));
        form.setFieldValue('time-slots', data);
        if (startDate) {
          form.setFieldValue('startTime', data.includes(startDate) ? startDate : '');
        }
      } else {
        form.setFieldValue('time-slots', []);
      }
    } catch (error) { }
    finally {
      startTimesRef.current?.setLoading(false);
    }
  };

  const onPickStaff = () => {
    setTimeout(() => onPickDate(form.getFieldValue('date')), 100);
  };

  const handleDoneConfirm = () => {
    reset();
    dispatch(appointmentActions.setNewAppointmentDraftData(null));
  };

  const onFinishForm = async (values: any) => {
    const body = values as IFormValues;
    setLoadingPage(true);
    const timeStart = moment(`${body.date.format('MM-DD-YYYY')} ${body.startTime}`, 'MM-DD-YYYY hh:mm A');
    const payload: IBodyApiMakeAppointment = {
      bookingType: APPOINTMENT_SOURCE_TYPE.POS,
      customerId: body.customer?.id || '',
      serviceId: body.services.map(o => o.id),
      staffId: body.staff ? body.staff.id : '',
      startTime: timeStart?.format('MM/DD/YYYY HH:mm:ss'),
      repeat: body.repeat,
      note: body.note,
    };
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.makeNewAppointment(payload);
      if (res?.data?.data) {
        dispatch(appointmentActions.setParams());
        dispatch(appointmentActions.setTableParams());
        handleDoneConfirm();
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return (
    <Modal
      visible={!!data}
      v2 noneBodyStyle
      modalTitle='new appointment'
      okTitle={'Book'}
      width={'auto'}
      onClose={handleDoneConfirm}
      className={'modal-add-new-appointment modal-max-height-unset modal-non-opacity'}
      hiddenHeader
      onSubmit={() => form.submit()}
    >
      <FormStyled onFinish={onFinishForm} initialValues={{ repeat: PeriodValues.NO_REPEAT, services: [] }} form={form} layout='vertical'>
        <Box display='flex' alignItems='center'>
          <Box display='flex' alignSelf='stretch'>
            <Form.Item noStyle name='staff'>
              <StaffChoose onPickStaff={onPickStaff} />
            </Form.Item>
          </Box>
          <Container>
            <HeaderModal><span>NEW APPOINTMENT</span></HeaderModal>
            <Content >
              <DetailContainer>
                <div className='section-box'>
                  <Form.Item label={'Customer'} name={'customer'} rules={[{ required: true, message: 'Please choose customer' }]}>
                    <CustomerSearchInput v2NewApt />
                  </Form.Item>
                </div>
                <div className='section-box'>
                  <Form.Item label={'Repeat'} name={'repeat'}>
                    <Repeat />
                  </Form.Item>
                </div>
                <Form.Item noStyle name={'staff'}>
                  <StaffHandler />
                </Form.Item>
                <div className="section-box" style={{ border: 'none', padding: '16px' }}>
                  <Form.Item noStyle name={'note'}>
                    <TextArea rows={5} placeholder='Add an appointment note' />
                  </Form.Item>
                </div>
                <Form.Item noStyle name={'services'} />
              </DetailContainer>
              <TimeContainer>
                <Form.Item noStyle name={'date'} rules={[{ required: true }]}>
                  <CalendarPicker onPickDate={onPickDate} />
                </Form.Item>
                <Text variant='H9' color='text_3' className='text-lg'>Start Time</Text>
                <Form.Item noStyle name={'startTime'} rules={[{ required: true }]}>
                  <StartTimes ref={startTimesRef} />
                </Form.Item>
                <Text mb={1} />
                <Form.Item shouldUpdate noStyle>
                  {({ getFieldValue }) => {
                    const staff: IStaffItemData | null = getFieldValue('staff') || [];
                    const staffBlockHours = blockHours.filter(o => staff?.id?.toString() === o.staffId?.toString());
                    const date = getFieldValue('date');
                    return <BlockHourStaffs date={date?.format('MM/DD/YYYY')} staffs={staffs} staffBlockHours={staffBlockHours} />;
                  }}
                </Form.Item>
              </TimeContainer>
            </Content>
          </Container>
        </Box>
      </FormStyled>
    </Modal>
  );
};

export default NewAppointmentVersionV2;
const Container = styled.div`
min-height: 75vh;
display: flex;
flex-direction: column;
`;
const FormStyled = styled(Form)`
display: flex;
flex-direction: column;
zoom: 1;
@media (min-resolution: 0.8dppx) {
  zoom: 0.85;
}
`;
const Content = styled.div`
  flex: 1;
  display: flex;
  .divider {
    width: 1px;
    align-self: stretch;
    background: #CCD4DC;
  }
`;
const HeaderModal = styled.div`
background: rgb(35, 47, 62);
height: 4rem;
display: flex;
align-items: center;
justify-content: center;
span {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 137.5%;
}
`;
const DetailContainer = styled.div`
flex: 1;
display: flex;
width: 1200px;
flex-direction: column;
border-right: 1px solid #86909C;
  .section-box {
    padding: 16px;
    border-bottom: 1px solid #86909C;
    background: #FFF;
    padding-bottom: 0px;
    .ant-form-item-label label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .CustomerSearchInput {
    .ant-input-affix-wrapper {
      border-radius: 4px;
      border: 1px solid #CCD4DC;
      background: #F6F7FC;
      .ant-input-suffix svg {
        transform: scale(1.2);
      }
      input {
        background: transparent;
        color: #1D2129;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .ButtonAddCustomer {
      display: flex;
      width: 56px;
      height: 56px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #D2464F !important;
      border: 1px solid #D2464F !important;
    }
  }

  .ant-input {
    border: 1px solid #CCD4DC;
    background: #F6F7FC;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
const TimeContainer = styled.div`
width: 800px;
align-self: stretch;
display: flex;
flex-direction: column;
padding: 16px;
`;

