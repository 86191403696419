import { Switch } from 'antd';
import { turnManualActions } from 'features/turn/services/reducers';
import { turnManualSelectors } from 'features/turn/services/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const SwitchVisibleItemName = () => {
  const visibleItemName = turnManualSelectors.visibleItemName();
  const dispatch = useAppDispatch();
  const onChange = (val: boolean) => dispatch(turnManualActions.setVisibleItemName(val));
  return (
    <Container>
      <Switch checked={visibleItemName} onChange={onChange} />
    </Container>
  );
};

export default SwitchVisibleItemName;
const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 16px;
padding-left: 16px;
margin-right: 16px;
.ant-switch {
  transform: scale(1.7);
}
.CONTENT_2 {
  position: relative;
  z-index: 3;
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;