import React from 'react';

const CardGiftCardV2 = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.8789 7.88606C12.4128 4.77695 16.7953 3.60646 19.6074 5.65482C21.3604 7.0082 22.9307 8.58104 24.5376 10.0807C25.4507 9.42233 26.4367 8.69077 27.6419 8.6542C29.0662 8.47131 30.4175 9.16629 31.4401 10.0807C33.047 8.69077 34.5808 7.15451 36.2243 5.80113C38.5981 3.93566 42.2502 4.37459 44.2223 6.60584C46.0118 8.50789 46.2674 11.5073 44.9892 13.7385C46.304 13.7385 47.5822 13.6654 48.8969 13.7751C50.2117 13.958 51.1977 15.1285 51.4169 16.4087V21.7491C51.0882 22.444 50.4308 22.9927 49.6273 22.883C35.2382 22.883 20.8491 22.883 6.45992 22.883C5.65647 22.9927 4.9991 22.444 4.67041 21.7491V16.4087C4.88953 15.1285 5.87559 13.9214 7.19034 13.7751C8.46856 13.6288 9.7833 13.7385 11.0615 13.7385C10.0389 11.9462 9.92939 9.67838 10.8789 7.88606ZM13.4354 9.71495C12.8145 11.4341 14.0927 13.519 15.9553 13.6288C17.8909 13.7385 19.863 13.6288 21.7986 13.6654C22.4195 13.5922 23.1864 13.8848 23.6246 13.3362C21.8351 11.5804 19.9726 9.93442 18.0735 8.25184C16.5761 7.00819 13.9832 7.84948 13.4354 9.71495ZM37.3199 8.80051C35.7495 10.3002 34.033 11.6536 32.5357 13.263L32.5722 13.6654C34.946 13.6654 37.3199 13.7019 39.7302 13.6654C40.8989 13.6288 42.0676 12.8972 42.5058 11.7999C43.1267 10.4099 42.4693 8.61762 41.045 7.95922C39.8033 7.30082 38.2694 7.84948 37.3199 8.80051Z" fill="#346751" />
      <path d="M7.70151 25.919C13.4718 25.919 19.2055 25.919 24.9758 25.919C24.9758 34.405 24.9758 42.8911 24.9758 51.4137H24.8662C20.082 51.2674 15.3343 51.4503 10.5501 51.3406C8.94322 51.304 7.62847 49.7677 7.73803 48.1583C7.66499 40.733 7.70151 33.3077 7.70151 25.919Z" fill="#346751" />
      <path d="M31.0381 25.919C36.8084 25.919 42.5421 25.919 48.3124 25.919C48.3124 33.3443 48.3124 40.733 48.3124 48.1583C48.4219 49.7311 47.1072 51.2674 45.5003 51.3406C40.7161 51.4503 35.9684 51.304 31.1842 51.4137H31.0381C31.0381 42.8911 31.0746 34.405 31.0381 25.919Z" fill="#346751" />
    </svg>
  );
};

export default CardGiftCardV2;
