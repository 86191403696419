import React from 'react';

const CardBtnV2 = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M71.4286 30.024H8.57162V35.2621H71.4286V30.024ZM76.6666 35.2621V40.5001L76.6666 55.8792C76.6666 60.5516 76.1795 62.2435 75.2681 63.9459C74.3566 65.6587 73.0157 66.9997 71.3081 67.9111C69.6005 68.8225 67.9033 69.3096 63.2415 69.3096H16.7587C12.0864 69.3096 10.3945 68.8225 8.6921 67.9111C7.0029 67.0189 5.62125 65.6391 4.72688 63.9511C3.81545 62.2435 3.32831 60.5463 3.32831 55.8845V25.116C3.32831 20.4436 3.81545 18.7517 4.72688 17.0493C5.6383 15.3365 6.97925 13.9955 8.68686 13.0841C10.3945 12.1727 12.0916 11.6855 16.7535 11.6855H63.231C67.9033 11.6855 69.5952 12.1727 71.2976 13.0841C73.0105 13.9955 74.3514 15.3365 75.2628 17.0441C76.1528 18.7115 76.6382 20.3688 76.6606 24.7859H76.6666L76.6666 25.1212L76.6666 30.024V35.2621ZM71.4279 24.7859C71.4147 21.5838 71.2053 20.5599 70.6481 19.5165C70.2422 18.7435 69.6098 18.113 68.8357 17.7093C67.7619 17.1331 66.7038 16.9289 63.2362 16.9289H16.764C13.2964 16.9289 12.2383 17.1331 11.1592 17.7093C10.3863 18.1152 9.75577 18.7476 9.3521 19.5217C8.79491 20.5601 8.5855 21.5838 8.5723 24.7859H71.4279ZM71.4286 40.5001H8.57162V55.8792C8.57162 59.3468 8.77591 60.4049 9.3521 61.484C9.758 62.2569 10.3903 62.8874 11.1645 63.2911C12.2383 63.8673 13.2964 64.0716 16.764 64.0716H63.2362C66.7038 64.0716 67.7619 63.8673 68.841 63.2911C69.6139 62.8852 70.2444 62.2529 70.6481 61.4787C71.2243 60.4049 71.4286 59.3468 71.4286 55.8792V40.5001ZM34.762 50.9763H13.8097V56.2144H34.762V50.9763Z" fill="#1D2129" />
    </svg>
  );
};

export const CardBtnNormal = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M71.4286 30.0239H8.57169V35.262H71.4286V30.0239ZM76.6667 35.262V40.5001L76.6667 55.8792C76.6667 60.5515 76.1796 62.2434 75.2681 63.9458C74.3567 65.6586 73.0158 66.9996 71.3082 67.911C69.6005 68.8224 67.9034 69.3096 63.2415 69.3096H16.7588C12.0864 69.3096 10.3945 68.8224 8.69216 67.911C7.00296 67.0189 5.62131 65.6391 4.72694 63.951C3.81551 62.2434 3.32837 60.5463 3.32837 55.8844V25.1159C3.32837 20.4436 3.81551 18.7517 4.72694 17.0493C5.63836 15.3364 6.97931 13.9955 8.68692 13.0841C10.3945 12.1726 12.0917 11.6855 16.7536 11.6855H63.231C67.9034 11.6855 69.5953 12.1726 71.2977 13.0841C73.0105 13.9955 74.3515 15.3364 75.2629 17.044C76.1528 18.7114 76.6383 20.3688 76.6607 24.7858H76.6667L76.6667 25.1212L76.6667 30.0239V35.262ZM71.428 24.7858C71.4147 21.5837 71.2053 20.5599 70.6482 19.5164C70.2422 18.7435 69.6099 18.1129 68.8358 17.7093C67.762 17.1331 66.7039 16.9288 63.2363 16.9288H16.764C13.2964 16.9288 12.2383 17.1331 11.1593 17.7093C10.3863 18.1152 9.75583 18.7475 9.35216 19.5217C8.79497 20.56 8.58556 21.5837 8.57236 24.7858H71.428ZM71.4286 40.5001H8.57169V55.8792C8.57169 59.3468 8.77597 60.4049 9.35216 61.4839C9.75807 62.2569 10.3904 62.8874 11.1645 63.291C12.2383 63.8672 13.2964 64.0715 16.764 64.0715H63.2363C66.7039 64.0715 67.762 63.8672 68.841 63.291C69.614 62.8851 70.2445 62.2528 70.6482 61.4787C71.2243 60.4049 71.4286 59.3468 71.4286 55.8792V40.5001ZM34.7621 50.9762H13.8097V56.2143H34.7621V50.9762Z" fill="#B67650" />
    </svg>
  );
};

export default CardBtnV2;
