import React from 'react';
import StaffListChooseUI from '../../QuickBooking/StaffListChooseUI';
import { IStaffItemData } from 'services/shop/types/staff';
type Props = {
  value?: IStaffItemData;
  onChange?: (value: IStaffItemData) => void;
  onPickStaff?: (value: IStaffItemData) => void;
};
const StaffChoose = ({ value, onChange = () => undefined, onPickStaff = () => undefined }: Props) => {
  const _onPickStaff = (value: IStaffItemData) => {
    onChange(value);
    onPickStaff(value);
  };
  return (
    <StaffListChooseUI anybody onPickStaff={_onPickStaff} selectedStaffs={value ? [value?.id] : []} />
  );
};

export default StaffChoose;
