import React from 'react';

const EditName = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.4273 3.60524C20.1656 3.50313 20.9342 3.63266 21.5939 3.98724C21.9652 4.17824 22.2797 4.4586 22.6133 4.70727C22.978 4.99377 23.3725 5.25191 23.6717 5.61405C24.189 6.21258 24.51 6.98414 24.5626 7.77934C24.6245 8.65634 24.364 9.5546 23.8392 10.2548C20.823 14.2171 17.8022 18.1756 14.7851 22.1369C14.707 22.2457 14.6018 22.3336 14.4776 22.3828C12.4161 23.2376 10.3565 24.0975 8.29505 24.9528C8.01404 25.0762 7.66372 24.948 7.51345 24.6805C7.38643 24.4762 7.43715 24.2261 7.44692 23.9997C7.56555 21.885 7.68512 19.7707 7.80329 17.656C7.81911 17.4158 7.79771 17.1459 7.96287 16.9506C10.9092 13.0763 13.8565 9.20144 16.8029 5.3266C16.9797 5.09826 17.1439 4.85809 17.3505 4.65527C17.9032 4.08416 18.6466 3.71162 19.4273 3.60524ZM18.708 5.1999C18.1377 5.52895 17.8245 6.13883 17.4114 6.63098C18.9997 7.86633 20.5969 9.08892 22.188 10.32C22.3951 10.0449 22.6044 9.77208 22.8128 9.49787C23.1427 9.06481 23.32 8.51403 23.3027 7.96656C23.2874 7.25504 22.931 6.56479 22.3737 6.13694C21.9991 5.85044 21.6279 5.56015 21.251 5.27791C20.5132 4.75171 19.4771 4.72571 18.708 5.1999ZM9.33114 17.2613C10.9269 18.4758 12.5236 19.6894 14.1152 20.9092C16.5456 17.717 18.9835 14.5305 21.4088 11.3341C19.8162 10.1092 18.2256 8.88137 16.6368 7.65217C14.2022 10.8557 11.7639 14.0568 9.33114 17.2613ZM8.75331 23.3714C10.1509 22.7889 11.5494 22.2074 12.9479 21.6268C11.6383 20.6274 10.3267 19.6298 9.0171 18.6304C8.93103 20.2109 8.83752 21.7909 8.75331 23.3714Z" fill="#1D2129" />
      <path d="M4.40762 27.5986C4.54756 27.5632 4.69848 27.5729 4.84482 27.572C12.3586 27.5729 19.8724 27.5713 27.3862 27.5729C27.7091 27.5698 27.999 27.7658 27.999 27.9873C28.0205 28.2295 27.6931 28.4451 27.3418 28.4275C19.7828 28.43 12.2237 28.4272 4.66464 28.4275C4.38979 28.4382 4.11814 28.3171 4.03445 28.136C3.92057 27.9252 4.1003 27.672 4.40762 27.5986Z" fill="#1D2129" />
    </svg>
  );
};

export default EditName;
