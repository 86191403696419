import Icon from 'assets/Icons';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import React from 'react';
import styled from 'styled-components';
import staffAvatar from '../images/staffAvailableIcon.png';
import { Avatar } from 'antd';
import { get } from 'lodash';
import AnyBodyImage from 'components/AnyBodyImage';
interface IComponentProps {
  url?: string;
  username: String;
  isSelected: boolean;
  onClick?: () => void;
  isBusy?: boolean;
  isStaffNextAvailable?: boolean;
  anybody?: boolean;
}
const AvatarBox: React.FC<IComponentProps> = ({
  username,
  isSelected,
  onClick,
  isBusy,
  url,
  isStaffNextAvailable,
  anybody,
}) => {
  return (
    <AvatarBoxStyled
      className={isBusy ? 'busy-staff' : ''}
      onClick={onClick}
      selected={isSelected}
    >
      {isSelected && (
        <IconBadge>
          <Icon type="tick" />
        </IconBadge>
      )}
      {anybody ? <AnyBodyImage large /> :
        <Avatar className="circle-avatar" src={isStaffNextAvailable ? staffAvatar : url} alt="staff-avatar" >{get(username, [0], '')}</Avatar>}
      <Text variant="H7" className='text-overflow' wrapWidth={'95%'} >{username}</Text>
    </AvatarBoxStyled>
  );
};

export default AvatarBox;

const IconBadge = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;

interface IAvatarBoxStyled {
  selected: boolean;
  // busy?: boolean;
  color?: keyof typeof colorTheme;
}
// const busyStaffCSS = `&.busy-staff {
//       background: #E5E6EB;
//       pointer-events: none;
//     }`;

const AvatarBoxStyled = styled.button<IAvatarBoxStyled>`
    cursor: pointer;
    row-gap: 0.8rem;
    height: 13.5rem;
    width: 10.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    border-radius: 16.896px;
    background: ${({ selected }) =>
    selected ? colorTheme['info_bg_3'] : '#FFF'};
    box-shadow: -1.6896352767944336px 1.6896352767944336px 6.758541107177734px 0px rgba(0, 0, 0, 0.10) inset, 0px 6.758541107177734px 6.758541107177734px 0px rgba(0, 0, 0, 0.25);

    .circle-avatar {
        width: 8rem;
        height: 8rem;
        border-radius: 50%;
        font-size: 2rem;
        display: flex;
        align-items: center;
    }

    &.busy-staff {
      background: #E5E6EB;
      pointer-events: none;
    }
`;
