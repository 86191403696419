import React from 'react';
import AptStatusFilter from './AptStatusFilter';
import AppointmentDatePicker from './DatePicker';
import SwitchCalendarType from './SwitchCalendarType';
import Arrows from '../TableView/Arrows';
import StatusBar from '../TableView/StatusBar';
type ICalendarFilterProps = {
  v2?: boolean;
};
const CalendarFilter: React.FC<ICalendarFilterProps> = ({ v2 }) => {
  if (v2) return (
    <>
      <AptStatusFilter />
      <SwitchCalendarType />
      <Arrows v2 />
      <AppointmentDatePicker v2 />
      <div style={{ flex: 1 }} />
      <StatusBar />
    </>
  );

  return (
    <>
      <AptStatusFilter />
      <AppointmentDatePicker />
      <SwitchCalendarType />
    </>
  );
};

export default CalendarFilter;
