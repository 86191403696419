export const DISCOUNT_TYPE = { PERCENT: 'PERCENT' } as const;
export const DEPEND_ON_TYPE = { CATEGORY: 'CATEGORY', ADDON: 'ADDON' } as const;

export const STAFF_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
} as const;
export const STAFF_TYPE = {
  ADMIN: 'ADMIN',
  OTHER: 'OTHER',
} as const;
export const SERVICE_ITEM_TYPE = {
  SERVICE: 'SERVICE',
  SIMPLE_MENU: 'SIMPLE_MENU',
  PRODUCTION: 'PRODUCTION',
  PRODUCT: 'PRODUCT',
  GIFT_CARD: 'GIFT_CARD',
  VIP: 'VIP',
} as const;

export const waitingListDemo = [
  {
    checkInId: '',
    customerInfo: {
      customerId: '0b26d509-c00d-471b-80ee-25d11711ab6a',
      customerName: 'Turner',
      point: 10,
    },
    ticketInfo: {
      billId: '901ec82e-27b5-4bc7-9294-c978a65aeffe',
      discount: 0,
      tip: 0,
      tax: 0,
      total: 10,
      subTotal: 10,
      createdDate: '2023-08-12T07:41:56.037+00:00',
      ticketNumber: 12134213,
    },
    items: [
      {
        staffName: 'Stephen Anderson',
        staffId: '1dbc404b-5165-4e65-8360-9eb298dfc946',
        bookingTime: null,
        tip: null,
        services: [
          {
            itemId: 'f262ca2c-0b10-4291-bd34-3012b9baea49',
            itemName: 'Test',
            itemType: 'SERVICE',
            price: 10,
            duration: 0,
            discount: 0,
            commission: null,
            discountConfig: null,
          },
        ],
      },
    ],
    isSelected: false
  },
  {
    checkInId: '',
    customerInfo: {
      customerId: '1a37d608-d00e-572c-90fe-35d12812bc7b',
      customerName: 'Smith',
      point: 20,
    },
    ticketInfo: {
      billId: '901ec82e-27b5-4bc7-9294-c978a65aeffe',
      discount: 0,
      tip: 0,
      tax: 0,
      total: 10,
      subTotal: 10,
      createdDate: '2023-08-12T07:41:56.037+00:00',
      ticketNumber: 2342523,
    },
    items: [
      {
        staffName: 'Stephen Anderson',
        staffId: '1dbc404b-5165-4e65-8360-9eb298dfc946',
        bookingTime: null,
        tip: null,
        services: [
          {
            itemId: 'f262ca2c-0b10-4291-bd34-3012b9baea49',
            itemName: 'Test',
            itemType: 'SERVICE',
            price: 10,
            duration: 0,
            discount: 0,
            commission: null,
            discountConfig: null,
          },
          {
            itemId: 'f262ca2a-0b10-4291-bd34-3012b9baea49',
            itemName: 'Test',
            itemType: 'SERVICEagsd',
            price: 10,
            duration: 0,
            discount: 0,
            commission: null,
            discountConfig: null,
          },
        ],
      },
    ],
    isSelected: false
  },
  {
    checkInId: '',
    customerInfo: {
      customerId: '2c48f709-e11f-683d-91gf-45e23913cd8c',
      customerName: 'Johnson',
      point: 30,
    },
    ticketInfo: {
      billId: '901ec82e-27b5-4bc7-9294-c978a65aeffe',
      discount: 0,
      tip: 0,
      tax: 0,
      total: 10,
      subTotal: 10,
      createdDate: '2023-08-12T07:41:56.037+00:00',
      ticketNumber: 32134,
    },
    items: [
      {
        staffName: 'Stephen Anderson',
        staffId: '1dbc404b-5165-4e65-8360-9eb298dfc946',
        bookingTime: null,
        tip: null,
        services: [
          {
            itemId: 'f262ca2c-0b10-4291-bd34-3012b9baea49',
            itemName: 'Test',
            itemType: 'SERVICE',
            price: 10,
            duration: 0,
            discount: 0,
            commission: null,
            discountConfig: null,
          },
        ],
      },
    ],
    isSelected: false
  },
];
