import { Input, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import CouponTicket from 'components/CouponTicket';
import Form, { FormItem } from 'components/Form';
import InputKeyboardView, { InputViewProps } from 'components/NumberKeyBoard/InputKeyboardView';
import NumberPad from 'components/NumberKeyBoard/NumberPad';
import PercentDecimalInputPrecision from 'components/PercentDecimalInputPrecision';
import Spin from 'components/Spin';
import Text from 'components/Text';
import { debounce, get } from 'lodash';
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useState, useTransition } from 'react';
import { IResponseDataBody } from 'services/response';
import shopApis from 'services/shop/apis';
import shopSelectors from 'services/shop/selectors';
import { ICouponItemData } from 'services/shop/types/coupon';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';

const MIN_LENGTH = 8;

export interface ICouponKeyboard {
  value: ICouponItemData | null;
  onChange: (value: ICouponItemData | null) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
  ownerPercent: number;
  staffPercent: number;
  setOwnerPercent: (val: number) => void;
  setStaffPercent: (val: number) => void;
}

export type CouponKeyboardRef = {
  showError: (text: string) => void,
  hideError: () => void,
  clear: () => void,
  setValue: (val: string) => void,
};
const inputPlaceholder = '$0.00';
const CouponKeyboard = forwardRef<CouponKeyboardRef, ICouponKeyboard>(({
  value,
  disableInput,
  onChange,
  inputViewProps = {},
  inputClassName = '',
  ownerPercent,
  staffPercent,
  setOwnerPercent,
  setStaffPercent
}, ref) => {
  const discountSetting = shopSelectors.data.discountSetting();
  const [text, setText] = useState<string>();
  const [error, setError] = useState<string | undefined>();
  const [, startTransition] = useTransition();

  const [loading, setLoading] = useState(false);

  const handleSetStaffPercent = (val: number) => {
    startTransition(() => {
      setStaffPercent(val);
      const owner = 100 - val;
      setOwnerPercent(owner > 0 ? owner : 0);
    });
  };

  const handleSetOwnerPercent = (val: number) => {
    startTransition(() => {
      setOwnerPercent(val);
      const staff = 100 - val;
      setStaffPercent(staff > 0 ? staff : 0);
    });
  };


  useImperativeHandle(ref, () => ({
    showError: (text: string) => setError(text),
    hideError: () => setError(''),
    clear: () => setText(undefined),
    setValue: (_text: string) => setText(_text),
  }));

  const _debounce = useCallback(debounce(async (inputValue: string) => {
    setLoading(false);
    if ((inputValue?.length ?? 0) < MIN_LENGTH) return;
    try {
      const resData: IResponseDataBody<ICouponItemData> = await shopApis.getCouponByCode(inputValue);
      const _result = resData.data.data;
      if (_result) {
        startTransition(() => {
          onChange(_result);
          setStaffPercent(_result.staffDiscountPercent || discountSetting?.discountStaff || 0);
          setOwnerPercent(_result.ownerDiscountPercent || discountSetting?.discountOwner || 0);
        });
      } else {
        setError('No corresponding coupon found!');
      }
    } catch (error) {
      const msg = get(error, 'response.data.message');
      setError(msg || 'No corresponding coupon found!');
    }
  }, 1000), [discountSetting]);

  useEffect(() => {
    if ((text?.length ?? 0) >= MIN_LENGTH) {
      setLoading(true);
    }
    _debounce(text ?? '');
    setError(undefined);
    onChange(null);
  }, [text]);

  const onNumberPadChange = (val: string) => {
    setText(val);
  };

  const InputView = useCallback(() => {
    if (disableInput) return null;
    if (isMobileBrowser()) return (
      <InputKeyboardView
        className={`${inputClassName ?? ''}`}
        value={text}
        {...inputViewProps}
        type={'CURRENCY'}
        placeholder={inputPlaceholder}
      />
    );

    const { trailing } = inputViewProps;

    return (
      <div className='wrap-input-view'>
        <Input
          autoFocus
          value={text}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onChange={e => setText(e.target.value?.toString())}
          addonAfter={
            <button onClick={trailing?.onClick}>
              {<Icon type={'QR'} />}
            </button>
          }
        />
      </div>
    );
  }, [text]);

  return (
    <CouponKeyboardStyled>
      <Form>
        <InputWrapStyled>
          <Box style={{ flex: 1 }}>
            <FormItem label={'Coupon Code (Optional)'} style={{ marginBottom: 0 }}>
              <InputView />
            </FormItem>
          </Box>
          <Box width={'40%'}>
            <Box display='flex' gap='4'>
              <FormItem label='Staff' style={{ marginBottom: 0 }}>
                <PercentDecimalInputPrecision integer maxValue={100} decimal={false} value={staffPercent?.toString()} onChange={handleSetStaffPercent} />
              </FormItem>
              <FormItem label='Company' style={{ marginBottom: 0 }}>
                <PercentDecimalInputPrecision integer maxValue={100} decimal={false} value={ownerPercent?.toString()} onChange={handleSetOwnerPercent} />
              </FormItem>
            </Box>
          </Box>
        </InputWrapStyled>
      </Form>
      {loading && <Spin />}
      {error && <Text textAlign='center' style={{ color: '#f53f3f' }}>{error}</Text>}
      <Text mb={1} />
      {
        !value ?
          <NumberPad
            value={text}
            onChange={onNumberPadChange}
            type={'PIN-PASSWORD'}
          />
          :
          <Row justify={'center'}>
            <div style={{ width: '25.5rem' }}>
              <CouponTicket data={value} />
            </div>
          </Row>
      }

    </CouponKeyboardStyled >
  );
});
CouponKeyboard.displayName = 'CouponKeyboard';
export default memo(CouponKeyboard);

const CouponKeyboardStyled = styled.div`
  display: flex;
  flex-direction: column;
`;


const InputWrapStyled = styled.div`
  display: flex;
  gap: 24px;
  width: 100%;
  
  .ant-input-number-group-wrapper {
    width: 100%;
  }
  .wrap-input-view {
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    overflow: hidden;
    border-radius: 5px;
    .ant-input-group-addon {
      // border-radius: 0px;
    }
  }
  input {
    color: var(--text-text-3, #1D2129);
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    height: 3.5rem;
    &:focus, &:hover, &:focus-visible {
      border-color: #86909C;
      outline: none;
      box-shadow:none;
    }
  }
  .ant-input-number-input-wrap {
    height: 100%;
    input {
      height: 100%;
    }
  }
`;