import { Form, InputRef } from 'antd';
import PercentInput from 'components/CurrencyInputPrecision/PercentInput';
import Text from 'components/Text';
import { GiftCardNumberContainer } from 'features/cashier/components/CenterMainBody/ExtendFeatures/GiftCard/v2/styles';
import React from 'react';
import styled from 'styled-components';
type Props = {
  inputStaffRef: React.RefObject<InputRef>;
  inputOwnerRef: React.RefObject<InputRef>;
  setKeyboardTargetFor: (value: any) => void;
};
const StaffOwnerPercent = ({ setKeyboardTargetFor, inputStaffRef, inputOwnerRef }: Props) => {
  const form = Form.useFormInstance();
  return (
    <StaffOwnerPercentGroups>
      <ControlGroup style={{ marginTop: 0 }}>
        <Text className='modal-label' mt={1} mb={0.5}>
          Staff
        </Text>
        <div className="input-selector-container" style={{ height: 'unset' }}>
          <Form.Item noStyle name={'staffPaidPercent'}>
            <PercentInput
              height={64}
              inputRef={inputStaffRef}
              maxValue={100}
              inputMode='none' className='gift-card-number-input fs-medium' size='small'
              onChangeDebounce={() => form.validateFields(['staffPaidPercent'])}
              onChange={(staff) => {
                const owner = 100 - staff;
                form.setFieldValue('ownerPaidPercent', owner);
              }}
              onFocus={() => setKeyboardTargetFor('STAFF_PERCENT')}
            />
          </Form.Item>
        </div>
      </ControlGroup>
      <ControlGroup style={{ marginTop: 0 }}>
        <Text className='modal-label' mt={1} mb={0.5}>
          Company
        </Text>
        <div className="input-selector-container" style={{ height: 'unset' }}>
          <Form.Item noStyle name={'ownerPaidPercent'}>
            <PercentInput
              height={64}
              inputRef={inputOwnerRef}
              maxValue={100}
              inputMode='none' className='gift-card-number-input fs-medium' size='small'
              onChangeDebounce={() => form.validateFields(['ownerPaidPercent'])}
              onChange={(owner) => {
                const staff = 100 - owner;
                form.setFieldValue('staffPaidPercent', staff);
              }}
              onFocus={() => setKeyboardTargetFor('OWNER_PERCENT')}
            />
          </Form.Item>
        </div>
      </ControlGroup>
    </StaffOwnerPercentGroups>
  );
};

export default StaffOwnerPercent;
const StaffOwnerPercentGroups = styled.div`
  margin: 16px;
  margin-top: 0;
  gap: 16px;
  display: flex;
  .modal-label {
    text-align: left;
  }
  .input-selector-container {
    width: 150px;
    margin: 0;
    input {
      padding: 0;
  
    }
  }
  .gift-card-number-input.fs-medium {
    font-size: 30px;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    border: 1px solid rgba(44, 35, 66, 0.40);
    border-radius: 6px;
    overflow: hidden;
  }
`;
const ControlGroup = styled(GiftCardNumberContainer)`
  .gift-card-number-input {
     background: transparent;
  }
  margin-bottom: 0;
`;
