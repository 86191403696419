import { get } from 'lodash';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import uiSelector from 'services/UI/selectors';
import { PATH_LOADING } from './constants';
import { Bill, IAdjustTip, IApiGetTicketsParam } from './types/api';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['ticketList']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['ticketList']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) =>
  useAppSelector((state) => get(getCurrentState(state), key, defaultValue));

const ticketsTableData = {
  loading: () => uiSelector.getLoading(PATH_LOADING.getTicketsData) as boolean,
  params: () => selector('params') as IApiGetTicketsParam,
  data: () => selector('ticketList') as Bill[],
  totalSize: () => selector('total') as number | null,
};
const ticketDetailsData = {
  loading: () =>
    uiSelector.getLoading(PATH_LOADING.getTicketDetailsData) as boolean,
  data: () => selector('ticketDetailsById') as Bill,
};

const adjustTip = () => selector('adjustTipList') as IAdjustTip[];

export const getTicketsFilterParams = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.params ?? {};
};

export const getStoreAdjustTips = (rootState: RootState) => {
  const state = getCurrentState(rootState);
  return state.adjustTipList ?? [];
};

const getVisibleCusPhone = () => selector('visibleCusPhone') as MyState['visibleCusPhone'];

const printVoidTicketData = () => selector('printVoidTicketData') as MyState['printVoidTicketData'];

const summaryCreditCards = () => selector('summaryCreditCards') as MyState['summaryCreditCards'];

const summaryTickets = () => selector('summaryTickets') as MyState['summaryTickets'];

const isVerifyKeyIn = () => selector('isVerifyKeyIn') as MyState['isVerifyKeyIn'];

const ticketListSelectors = {
  ticketsTableData,
  ticketDetailsData,
  adjustTip,
  getVisibleCusPhone,
  printVoidTicketData,
  summaryCreditCards,
  summaryTickets,
  isVerifyKeyIn,
};
export default ticketListSelectors;
