import styled from 'styled-components';
import AlarmClockUI from '../../CheckInInputPage/components/AlarmClockUI';
import Reviews from './Reviews';

const LeftContent = () => {
  return (
    <Styled>
      <AlarmClockUI />
      <Reviews />
    </Styled>
  );
};

export default LeftContent;
const Styled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`;
