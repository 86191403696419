import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IEmailFuncType, ISMSFuncType, ISignatureActionFunc } from '../components/CustomerSignature';
import paymentApis from '../services/apis';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';

const useSMSEmailSignature = () => {
  const setLoadingPage = useSetLoadingPage();
  const onNoReceipt: (billId: string, cb: () => void) => ISignatureActionFunc = (billId, cb) => async (signatureValue, args) => {
    args.close();
    if (signatureValue) {
      setLoadingPage(true);
      try {
        await paymentApis.updateSignature(billId || '', removeBase64Prefix(signatureValue));
      } catch (error) { }
      finally {
        setLoadingPage(false);
      }
    }
    cb();
  };

  const onSMS: (billId: string, cb: () => void) => ISMSFuncType = (billId, cb) => async ({ signature, phone }, args) => {
    if (!signature || !phone) return;
    const signatureValue = removeBase64Prefix(signature);
    setLoadingPage(true);
    try {
      await paymentApis.updateSignature(billId || '', signatureValue);
      await paymentApis.sendSMSEmailReceipt(billId || '', 'SMS', phone?.replace(/[^0-9]/g, ''));
      args.close();
      cb();
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const onEmail: (billId: string, cb: () => void) => IEmailFuncType = (billId, cb) => async ({ signature, email }, args) => {
    if (!signature || !email) return;
    const signatureValue = removeBase64Prefix(signature);
    setLoadingPage(true);
    try {
      await paymentApis.updateSignature(billId || '', signatureValue);
      await paymentApis.sendSMSEmailReceipt(billId || '', 'EMAIL', email);
      args.close();
      cb();
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };
  return ({
    onNoReceipt,
    onSMS,
    onEmail,
  });
};

export default useSMSEmailSignature;
