import appointmentActions from 'features/appointment/services/actions';
import checkInActions from 'features/checkIn/services/actions';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import { ICustomerReceiveConfigs, PaymentSocketData, TypePaymentPassData } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import paymentActions from 'features/payment/services/actions';
import turnActions from 'features/turn/services/actions';
import userActions from 'features/user/services/actions';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import shopActions from 'services/shop/actions';
import { SHOP_DEVICE_TYPE } from 'services/shop/constants';
import { Client, Message, Subscription } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import { APPOINTMENT_TOPIC, CUSTOMER_CHECK_IN_TOPIC, WARNING_BAN_CUSTOMER, PAYMENT_PASS_DATA_TOPIC, PRINT_CHECK_IN, STAFF_CLOCK_IN_OUT_TOPIC, SWITCH_SCREEN_TOPIC, SYNC_TURN_TOPIC, WAITING_TICKET_TOPIC, initSocket } from 'utils/socket';

const getTopic = (topic: string, disableDeviceId?: boolean) => {
  if (disableDeviceId) {
    return `${topic}/${storage.shop_id.get()}`;
  }
  return `${topic}/${storage.shop_id.get()}/${storage.device_id.get()}`;
};

type SocketContextProps = {
  socket: Client | null,
  send: (topic: string, data: Record<string, any>) => void;
  switchCustomerScreen: (screenPath: string) => void;
  subscribeSwitchScreen: () => void;
  connect: () => void;
  unsubscribeAll: () => void;
  unsubscribePassData: () => void;
  subscribe: (topic: string, listeningData: (message: Message) => void, disableDeviceId?: boolean) => () => void;
  isConnect: () => boolean,
  switchCusPayment: (billId: string) => void,
  switchCusRating: (billId: string) => void,
  backToCheckIn: () => void,
};
export const SocketContext = React.createContext<SocketContextProps>({
  socket: null,
  send: () => undefined,
  switchCustomerScreen: () => undefined,
  subscribeSwitchScreen: () => undefined,
  subscribe: () => () => undefined,
  connect: () => undefined,
  unsubscribeAll: () => undefined,
  unsubscribePassData: () => undefined,
  isConnect: () => false,
  backToCheckIn: () => undefined,
  switchCusPayment: () => undefined,
  switchCusRating: () => undefined,
});

export const useSocketContext = () => React.useContext(SocketContext);

const useInitSocket = (): SocketContextProps => {
  const [socket, setSocket] = useState<Client | null>(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const unSubArrays = useRef<Subscription[]>([]);
  const unSubPassDataArrays = useRef<Subscription[]>([]);
  const waitingSubscribeArrays = useRef<{
    topic: string, listeningData: (message: Message) => void, disableDeviceId?: boolean;
  }[]>([]);
  const countingReconnect = useRef<number>(0);

  const subscribeSwitchScreen = () => {
    if (!socket || !socket.connected) return;

    unSubArrays.current.push(
      socket.subscribe(getTopic(SWITCH_SCREEN_TOPIC), (res: Message) => {
        if (!res.body) return;
        const data: { billId: string, screen: string, shopId: string, device_target: string } = JSON.parse(res.body);
        const shop_id = storage.shop_id.get();
        const device_type = storage.device_type.get();
        if (data.device_target !== device_type) return;
        if (data.shopId !== shop_id) return;
        if (data.screen) navigate(data.screen);
      })
    );
  };

  const callBackReConnectSuccess = (_socket: Client) => {
    countingReconnect.current = 0;
    if (!_socket) return;
    if (waitingSubscribeArrays.current.length) {
      waitingSubscribeArrays.current.map(waitItem => {
        unSubPassDataArrays.current.push(
          _socket.subscribe(getTopic(waitItem.topic, waitItem.disableDeviceId), waitItem.listeningData)
        );
      });
      waitingSubscribeArrays.current = [];
    }

    unSubArrays.current.push(
      _socket.subscribe(getTopic(STAFF_CLOCK_IN_OUT_TOPIC, true), () => {
        dispatch(shopActions.get.staffsAvailable.fetch());
        dispatch(userActions.getListAvailableWorking.fetch());
      })
    );

    if (storage.device_type.get() === SHOP_DEVICE_TYPE.CHECK_IN) {
      unSubArrays.current.push(
        _socket.subscribe(getTopic(SWITCH_SCREEN_TOPIC), (res: Message) => {
          if (!res.body) return;
          const data: { billId: string, screen: string, shopId: string, device_target: string } = JSON.parse(res.body);
          const shop_id = storage.shop_id.get();
          const device_type = storage.device_type.get();
          if (data.device_target !== device_type) return;
          if (data.shopId !== shop_id) return;
          if (data.screen.includes('check-in/sign-in')) dispatch(multiplePaymentActions.customerSide.resetCacheCustomer());
          if (data.screen) navigate(data.screen);
        })
      );

      unSubArrays.current.push(
        _socket.subscribe(getTopic(PAYMENT_PASS_DATA_TOPIC), (message: Message) => {
          const payment: PaymentSocketData = JSON.parse(message?.body || '');
          if (payment?.action === TypePaymentPassData.CUSTOMER_RECEIVE_CONFIGS) {
            dispatch(multiplePaymentActions.customerSide.setReceiveConfigs(payment.data as ICustomerReceiveConfigs));
          } else {
            dispatch(multiplePaymentActions.customerSide.setCustomerMsg.fetch(message));
          }
        })
      );

    } else {
      unSubArrays.current.push(
        _socket.subscribe(getTopic(SYNC_TURN_TOPIC, true), () => dispatch(turnActions.syncTurn()))
      );
      unSubArrays.current.push(
        _socket.subscribe(getTopic(WAITING_TICKET_TOPIC, true), () => dispatch(userActions.getBillWaitingTicket.fetch()))
      );
      unSubArrays.current.push(
        _socket.subscribe(getTopic(CUSTOMER_CHECK_IN_TOPIC, true), () => dispatch(userActions.getWaitingList.fetch()))
      );
      unSubArrays.current.push(
        _socket.subscribe(getTopic(APPOINTMENT_TOPIC, true), () => dispatch(appointmentActions.setParams()))
      );
      unSubArrays.current.push(
        _socket.subscribe(getTopic(PAYMENT_PASS_DATA_TOPIC), (message: Message) => dispatch(multiplePaymentActions.setCashierMsg.fetch(message)))
      );
      unSubArrays.current.push(
        _socket.subscribe(getTopic(PRINT_CHECK_IN), (message: Message) => {
          dispatch(paymentActions.getInfoPrintGroupWaiting.fetch(message));
        })
      );
      unSubArrays.current.push(
        _socket.subscribe(getTopic(WARNING_BAN_CUSTOMER, true), (message: Message) => {
          dispatch(checkInActions.setWarningBanCustomerMessage(message.body));
        })
      );

    }
  };


  const reconnect = () => {
    if (countingReconnect.current > 2) return;
    countingReconnect.current += 1;

    setTimeout(() => {
      const _socket = initSocket();
      setSocket(_socket);
      _socket.connect({}, () => callBackReConnectSuccess(_socket), reconnect);
    }, 120000);
  };

  const unsubscribePassData = () => {
    unSubPassDataArrays.current.map(o => o.unsubscribe());
    unSubPassDataArrays.current = [];
  };

  const unsubscribeAll = () => {
    unSubArrays.current.map(o => o.unsubscribe());
    unSubArrays.current = [];
    unsubscribePassData();
  };


  const connect = () => {
    if (socket) {
      callBackReConnectSuccess(socket);
      return;
    }
    const _socket = initSocket();
    setSocket(_socket);
    _socket.connect({}, () => callBackReConnectSuccess(_socket), reconnect);
  };
  useEffect(() => {
    return () => socket?.disconnect(() => undefined);
  }, []);


  const send = (topic: string, data: Record<string, any>) => {
    if (!socket || !socket.connected) return;

    const body = {
      ...data ?? {},
      shopId: data?.shopId || storage.shop_id.get(),
      stationNumber: data?.stationNumber || storage.station_number.get(),
      deviceId: data?.device_id || storage.device_id.get(),
    };

    socket.send('/socket' + topic, {}, JSON.stringify(body));
  };

  const switchCustomerScreen = (screenPath: string) => {
    if (!socket || !socket.connected) return;

    const body = {
      screen: screenPath,
      shopId: storage.shop_id.get(),
      deviceId: storage.device_id.get(),
      stationNumber: storage.station_number.get(),
      device_target: SHOP_DEVICE_TYPE.CHECK_IN,
    };

    socket.send('/socket' + SWITCH_SCREEN_TOPIC, {}, JSON.stringify(body));
  };

  const subscribe = (topic: string, listeningData: (message: Message) => void, disableDeviceId?: boolean) => {
    if (!socket || !socket.connected) {
      waitingSubscribeArrays.current.push({
        topic,
        listeningData,
        disableDeviceId,
      });

      return () => undefined;
    }
    const unSub = socket.subscribe(getTopic(topic, disableDeviceId), listeningData);
    unSubArrays.current.push(unSub);

    if (topic === PAYMENT_PASS_DATA_TOPIC) {
      unSubPassDataArrays.current.push(unSub);
    }

    return () => unSub.unsubscribe();
  };

  const switchCusPayment = (billId: string) => {
    const screen = `/store/${storage.shop_id.get()}/ticket/payment/customer-side/${billId}`;
    switchCustomerScreen(screen);
  };

  const switchCusRating = (billId: string) => {
    const screen = `/store/${storage.shop_id.get()}/ticket/payment/customer-side/rating/${billId}`;
    switchCustomerScreen(screen);
  };

  const backToCheckIn = () => {
    switchCustomerScreen(`/store/${storage.shop_id.get()}/check-in/sign-in`);
  };

  return ({
    socket,
    send,
    switchCustomerScreen,
    subscribeSwitchScreen,
    unsubscribeAll,
    unsubscribePassData,
    subscribe,
    connect,
    isConnect: () => (!socket || !socket.connected) ? false : true,
    switchCusPayment,
    backToCheckIn,
    switchCusRating,
  });
};

export default useInitSocket;
