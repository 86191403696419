import Box from 'components/Box';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import ticketListSelectors from 'features/ticketList/services/selectors';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import FakeVisa from '../FakeVisa';
import { momentTimezone } from 'utils/time';
import { InfoCircleOutlined } from '@ant-design/icons';
import { IPaymentMultipleCardItem } from 'features/ticketList/services/types/api';

type ICreditCardProps = {
  credit_card?: IPaymentMultipleCardItem;
};
const CreditCard: React.FC<ICreditCardProps> = ({ credit_card }) => {
  const details = ticketListSelectors.ticketDetailsData.data();

  const [open, setOpen] = useState(false);
  const handleShowModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleFinish = () => {
    handleCloseModal();
  };

  return (
    <CreditCardStyled>
      <CreditButton style={{ gap: '4px' }} onClick={handleShowModal}>
        <InfoCircleOutlined style={{ fontSize: 20 }} />
        <p>
          Credit Card <span style={{ color: '#6FABB6' }}>{credit_card?.last4 || ''}</span>
        </p>
      </CreditButton>
      {!!open && <Modal
        modalTitle="Credit Card"
        visible={open}
        destroyOnClose={true}
        hiddenFooter={true}
        noneBodyStyle={true}
      >
        <Box px="11" py="4" height={'35rem'}>
          <FakeVisa
            number={'XXXX XXXX XXXX ' + credit_card?.last4 || ''}
            cardHolderName={
              credit_card?.cardHolder ? credit_card?.cardHolder : '----'
            }
            expires={
              credit_card?.expireDate
                ? moment(credit_card?.expireDate).format('MM/YY')
                : '--/--'
            }
          />
          <Box display="flex" flexDirection="column" gap="2" my="2">
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Ticket Number:
              </Text>
              <Text variant="H9" color="text_3">
                {details?.ticketNumber ? '#' + details?.ticketNumber : '--'}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Time:
              </Text>
              <Text variant="H9" color="text_3">
                {credit_card?.createDated
                  ? momentTimezone(credit_card?.createDated).format(
                    'DD/MM/YYYY hh:mm A'
                  )
                  : '--'}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Card Number:
              </Text>
              <Text variant="H9" color="text_3">
                {credit_card?.cardNumber || '--'}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Total:
              </Text>
              <Text variant="H9" color="text_3">
                {formatCurrency(credit_card?.amount || 0)}
              </Text>
            </Box>
            <Box className="space-between">
              <Text variant="CONTENT_1" color="text_3">
                Card Type:
              </Text>
              <Text variant="H9" color="text_3">
                {credit_card?.cardType || '--'}
              </Text>
            </Box>
          </Box>
          <Button width="100%" ntype="PRIMARY" onClick={handleFinish}>
            OK
          </Button>
        </Box>
      </Modal>}
    </CreditCardStyled>
  );
};

export default CreditCard;
const CreditCardStyled = styled.div``;
const CreditButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    font-family: Open Sans;
    font-size: 18px;
    font-weight: 700;
    font-style: normal;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
  
  &:hover {
    color: var(--info-infor-3, #6fabb6);
    text-decoration: underline;
  }
  
`;
