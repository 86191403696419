import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';
import { Form, Spin } from 'antd';
import TurnAmount from './TurnAmount';
import TurnType from './TurnType';
import TurnStatus from './TurnStatus';
import { turnManualSelectors } from 'features/turn/services/selectors';
import { TURN_ITEM_TYPE } from 'features/turn/services/constants';
import { IApiAddManualTurnBody } from 'features/turn/services/types/api';
import { IResponseDataBody } from 'services/response';
import turnApis from 'features/turn/services/apis';
import { useAppDispatch } from 'store/hooks';
import { turnManualAPIActions } from 'features/turn/services/actions';
type Props = {};
type Ref = {
  open: (staffId: string) => void;
  close: () => void;
};
export const turnAddNewRef = React.createRef<Ref>();
const ModalTurnAddNew = forwardRef<Ref, Props>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const selectedService = turnManualSelectors.serviceSelected();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const handleOpen: Ref['open'] = (staffId) => {
    if (!selectedService) return;
    setOpen(true);
    form.setFieldsValue({
      staffId,
      itemId: selectedService.itemId,
      itemName: selectedService.itemName,
      turn: selectedService.turn,
    });
  };

  const handleClose: Ref['close'] = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleFinish = async (values: any) => {
    setLoading(true);
    try {
      const res: IResponseDataBody<boolean> = await turnApis.addManualTurn(values as IApiAddManualTurnBody);
      if (res.data.data) {
        handleClose();
        dispatch(turnManualAPIActions.getTurns.fetch());
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useImperativeHandle(ref, () => ({
    open: handleOpen,
    close: handleClose,
  }));

  return (
    <Modal
      width={900}
      visible={open} onClose={handleClose}
      modalTitle='ADD TURN'
      headerBgColor='#FFEAF6'
      noneBodyStyle noneFooterStyle
      footer={null}
    >
      <Spin spinning={loading}>
        <Container form={form} initialValues={{ type: TURN_ITEM_TYPE.WALK_IN, turn: 1, status: 'IN_PROGRESS' }} onFinish={handleFinish}>
          <Content>
            <Form.Item name={'staffId'} noStyle><input hidden /></Form.Item>
            <Form.Item name={'itemId'} noStyle><input hidden /></Form.Item>
            <Form.Item name={'itemName'} noStyle><input hidden /></Form.Item>
            <div className="content-item">
              <div className="title-section">TURN</div>
              <Form.Item noStyle name={'turn'}>
                <TurnAmount />
              </Form.Item>
            </div>
            <div className="content-item">
              <div className="title-section">SELECT TURN TYPE</div>
              <Form.Item noStyle name={'type'}>
                <TurnType />
              </Form.Item>
            </div>
            <div className="content-item">
              <div className="title-section">STATUS</div>
              <Form.Item shouldUpdate noStyle>
                {({ getFieldValue }) => <Form.Item noStyle name={'status'}>
                  <TurnStatus type={getFieldValue('type')} />
                </Form.Item>}
              </Form.Item>
            </div>
          </Content>
          <Footer>
            <button onClick={handleClose} type='button' className="btn"><span>Close</span></button>
            <button onClick={() => form.submit()} type='button' className="btn btn-submit"><span>Save</span></button>
          </Footer>
        </Container>
      </Spin>
    </Modal>
  );
});
ModalTurnAddNew.displayName = 'ModalTurnAddNew';
export default ModalTurnAddNew;

const Container = styled(Form)`
display: flex;
padding-top: 12px;
flex-direction: column;
align-items: flex-start;
gap: 40px;
align-self: stretch;
background: #FFF;

`;

const Content = styled.div`
display: flex;
padding: 0px 24px;
flex-direction: column;
align-items: center;
gap: 32px;
align-self: stretch;
.content-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  gap: 12px;
  .title-section {
    color: #505050;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: capitalize;
  }
}
`;

const Footer = styled.div`
display: flex;
padding: 24px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
background: #FFF;
box-shadow: 0px -4px 8px -4px rgba(0, 0, 0, 0.25);
button.btn {
  display: flex;
  height: 56px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  border-radius: 5px;
  border: 1px solid #86909C;
  background: #E5E6EB;
  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 166.667% */
    text-transform: uppercase;
  }

  &.btn-submit {
    border-radius: 5px;
    background: #C52884;
    border: 1px solid #C52884;
    span {
      color: #fff;
    }
  }
}
`;
