import clsx from 'clsx';
import Modal from 'components/Modal';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import SalonDiscountTab, { DISCOUNT_FORM_TYPE, salonDiscountV2TabRef } from './SalonDiscountTab';
import CouponDiscountTab, { couponDiscountV2TabRef } from './CouponDiscountTab';
import { Form } from 'antd';
import { IDiscountSettingData } from 'services/shop/types/discount';
import { IDiscountTicketFormValue } from 'features/cashier/services/types/discount';
import { ICouponItemData } from 'services/shop/types/coupon';
import checkbox_white_icon from './icons/checkbox-white.svg';
import { IShopAllSetting } from 'services/shop/types/setting';
export interface IFormDiscountValues {
  discountType: string;
  staffPaidPercent: number;
  ownerPaidPercent: number;
  discountValue: number;
  couponId: string;
  couponCode: string;
  coupon: ICouponItemData | null;
}

export enum EDiscountType {
  COUPON = 'COUPON',
  SALON_DISCOUNT = 'SALON_DISCOUNT',
}

type Props = {
  visible?: boolean;
  onClose?: () => void;
  tab: EDiscountType;
  setTab: (val: EDiscountType) => void;
  discountSetting: IDiscountSettingData | null;
  onSubmit: (value: IDiscountTicketFormValue) => void;
  isItemDiscount?: boolean;
  allSetting?: IShopAllSetting | null;
};
const DiscountModalV2 = ({ allSetting, isItemDiscount, discountSetting, tab, setTab, visible, onClose = () => undefined, onSubmit }: Props) => {
  const [form] = Form.useForm();
  const handleFinish = (values: any) => {
    const data = values as IFormDiscountValues;
    onSubmit({
      discount: data.discountValue,
      discountSetting: {
        ownerPaidPercent: data.ownerPaidPercent,
        staffPaidPercent: data.staffPaidPercent,
      },
      discountType: data.discountType,
      couponId: data.couponId || null,
      reason: null,
    });
    onClose();
  };

  useEffect(() => {
    if (!discountSetting) return;
    if (!visible) return;
    const { discountOwner, discountStaff } = discountSetting;
    form.resetFields();
    form.setFieldsValue({
      staffPaidPercent: discountStaff,
      ownerPaidPercent: discountOwner,
    });
    setTab(EDiscountType.SALON_DISCOUNT);
    salonDiscountV2TabRef.current?.onActive();
  }, [discountSetting, visible]);

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      modalTitle={isItemDiscount ? 'DISCOUNT BY ITEM' : 'DISCOUNT BY TICKET'}
      okTitle={'Ok'}
      onSubmit={() => form.submit()}
      noneFooterStyle
      className='modal-overflow-unset modal-non-opacity modal-bg-v2'
      v2
      noneBodyStyle
      width={1000}
    >
      <Container form={form} initialValues={{ discountType: DISCOUNT_FORM_TYPE.PERCENT, discountValue: 0 }} onFinish={handleFinish}>
        {!isItemDiscount && <Menu>
          <button onClick={() => {
            setTab(EDiscountType.SALON_DISCOUNT);
            salonDiscountV2TabRef.current?.onActive();
          }} type='button' className={clsx('menu-item', tab === EDiscountType.SALON_DISCOUNT && 'active')}>
            {tab === EDiscountType.SALON_DISCOUNT ? <div className="checkbox active"><img src={checkbox_white_icon} alt="checkbox_white_icon" style={{ width: 40, height: 40 }} /></div> : <div className="checkbox"></div>}
            <span>Company Discount</span>
          </button>
          <button onClick={() => {
            setTab(EDiscountType.COUPON);
            couponDiscountV2TabRef.current?.onActive();
          }} type='button' className={clsx('menu-item', tab === EDiscountType.COUPON && 'active')}>
            {tab !== EDiscountType.SALON_DISCOUNT ? <div className="checkbox active"><img src={checkbox_white_icon} alt="checkbox_white_icon" style={{ width: 40, height: 40 }} /></div> : <div className="checkbox"></div>}
            <span>Coupon Discount</span>
          </button>
        </Menu>}
        <ContentContainer>
          <div style={{ display: tab === EDiscountType.SALON_DISCOUNT ? 'block' : 'none' }}>
            <SalonDiscountTab allSetting={allSetting} form={form} ref={salonDiscountV2TabRef} active={tab === EDiscountType.SALON_DISCOUNT} />
          </div>
          <div style={{ display: tab !== EDiscountType.SALON_DISCOUNT ? 'block' : 'none' }}>
            <CouponDiscountTab form={form} ref={couponDiscountV2TabRef} discountSetting={discountSetting} active={tab === EDiscountType.COUPON} />
          </div>
        </ContentContainer>
      </Container>
    </Modal>
  );
};

export default DiscountModalV2;

const Container = styled(Form)``;
const ContentContainer = styled.div``;
const Menu = styled.div`
display: flex;
height: 72px;
align-items: center;
align-self: stretch;
border-bottom: 1px solid #CCD4DC;
.menu-item {
  display: flex;
  padding: 8px 16px;
  justify-content: start;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  background: #FFF;
  border-bottom: 2px solid #fff;
  span {
    color: #505050;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .checkbox {
    border: 1.5px solid #2D6772;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    &.active {
      background: #2D6772;
    }
  }

  &.active {
    span {
      color: #2D6772;
    }
  }
}
`;