import React from 'react';
import styled from 'styled-components';
import TicketTable from './TicketTable';

type ITabTicketByParamsProps = {
  tableKey: string;
  isCreditPage?: boolean;
  v2?: boolean;
};
const TabTicketByParams: React.FC<ITabTicketByParamsProps> = ({ tableKey, isCreditPage, v2 }) => {

  return (
    <TabTicketByParamsStyled>
      <TicketTable v2={v2} key={tableKey} isCreditPage={isCreditPage} />
    </TabTicketByParamsStyled>
  );
};

export default TabTicketByParams;
const TabTicketByParamsStyled = styled.div`
  position: relative;
`;
