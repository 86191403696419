import { Divider, Modal, Radio, RadioChangeEvent } from 'antd';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import GroupButtonFooterModal, { BtnProps } from 'components/GroupButtonFooterModal';
import Text from 'components/Text';
import paymentApis from 'features/payment/services/apis';
import { IBodyApiSplitTipPayment, StaffWorkItem } from 'features/payment/services/types/api';
import { Bill } from 'features/ticketList/services/types/api';
import { cloneDeep, last, set, sumBy } from 'lodash';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

interface IModalTipMethodSystem {
  visible?: boolean;
  onClose?: () => void;
  onCompleted?: () => void;
}

const enum ETipMethod {
  percent = 'Percent',
  even = 'Even',
  manual = 'Manual',
}

type IReAdjustTipModalRef = {
  open: (data: Bill | null) => void;
}
export const useReAdjustTipModalRef = () => useRef<IReAdjustTipModalRef>(null);
const ReAdjustTipModal = forwardRef<IReAdjustTipModalRef, IModalTipMethodSystem>(({ visible, onClose = () => undefined, onCompleted = () => undefined }, ref) => {
  const [data, setData] = useState<Bill | null>(null);
  const setPageLoading = useSetLoadingPage();
  const [staffWorkList, setListStaffWork] = useState<StaffWorkItem[]>([]);
  const [primevalStaffWorkList, setListPrimevalStaffWork] = useState<StaffWorkItem[]>([]);
  const [method, setMethod] = useState<ETipMethod>(ETipMethod.percent);
  const totalTip = data?.tip ?? 0;

  useImperativeHandle(ref, () => ({
    open(data) {
      setData(data);
      const dataList = (data?.items || []).map(o => ({
        staffId: o.staffId,
        name: o.staffName,
        price: sumBy(o.services, a => a.price),
        tip: 0,
        isInput: false,
      }) as StaffWorkItem);
      setListStaffWork(dataList);
      setListPrimevalStaffWork(dataList);
    },
  }));

  const handleClose = () => {
    onClose();
    setMethod(ETipMethod.percent);
    setListStaffWork([]);
    setListPrimevalStaffWork([]);
  };

  const handleApplyMethod = async () => {
    handleClose();
    setPageLoading(true);
    const payload: IBodyApiSplitTipPayment = {
      billId: data?.billId || '',
      items: staffWorkList.map(o => ({
        staffId: o.staffId,
        tip: o.tip
      }))
    };
    try {
      const res: IResponseDataBody<boolean> = await paymentApis.splitTip(payload);
      if (res.data.data) {
        onCompleted();
      }
    } catch (error) { }
    finally {
      setPageLoading(false);
    }
  };

  const btnProps: BtnProps[] = [
    {
      buttonName: 'Close',
      onClick: () => handleClose(),
      type: 'DEFAULT',
    },
    {
      buttonName: 'Split',
      onClick: handleApplyMethod,
      type: 'PRIMARY',
      disabled: sumBy(staffWorkList, o => (o.tip || 0)) > totalTip,
    },
  ];

  const sumPrice = useMemo(() => {
    return staffWorkList.reduce(
      (previousSum, item) => item.price + previousSum,
      0
    );
  }, [staffWorkList]);

  const isDisableInput = useMemo(() => {
    return method !== ETipMethod.manual;
  }, [method]);

  const computeTip = useCallback(() => {
    let target: StaffWorkItem[] = [];
    switch (method) {
      case ETipMethod.even: {
        let remainingTip = totalTip;

        target = staffWorkList.map((item) => {
          let staffTip = +(totalTip / staffWorkList.length).toFixed(2);

          if (remainingTip - staffTip <= 0) {
            staffTip = remainingTip;
          }

          remainingTip = +(remainingTip - staffTip).toFixed(2);

          return {
            ...item,
            tip: staffTip,
            isInput: false,
          };
        });

        if (remainingTip > 0) {
          const lastItem = last(target);
          if (lastItem)
            set(lastItem, 'tip', lastItem.tip + remainingTip);
        }

        break;
      }

      case ETipMethod.manual:
        target = staffWorkList.map((item) => {
          return {
            ...item,
            tip: 0
          };
        });
        break;
      case ETipMethod.percent: {
        let remainingTip = totalTip;
        target = staffWorkList.map((item) => {
          let staffTip = +((item.price / sumPrice) * totalTip).toFixed(2);

          if (remainingTip - staffTip <= 0) {
            staffTip = remainingTip;
          }

          remainingTip = +(remainingTip - staffTip).toFixed(2);
          return {
            ...item,
            tip: staffTip,
            isInput: false,
          };
        });
        if (remainingTip > 0) {
          const lastItem = last(target);
          if (lastItem)
            set(lastItem, 'tip', lastItem.tip + remainingTip);
        }
      }
    }
    setListStaffWork(target);
  }, [method, primevalStaffWorkList]);

  const handleChangeValue = (staffId: string, value: number) => {
    const cloneStaffWorkList = cloneDeep(staffWorkList);

    if (
      cloneStaffWorkList.filter((item) => item.isInput).length ===
      cloneStaffWorkList.length
    ) {
      for (let i = 0; i < cloneStaffWorkList.length; i++) {
        cloneStaffWorkList[i].isInput = false;
      }
    }
    const targetChange = cloneStaffWorkList.find(
      (item) => item.staffId === staffId
    );

    if (targetChange) {
      targetChange.tip = +value;
      targetChange.isInput = true;
      const sumAllInput: number = cloneStaffWorkList
        .filter((item) => item.isInput)
        .reduce((sum, item) => sum + item.tip, 0);
      const theRestTip = totalTip - sumAllInput;

      const sumPriceWithoutInput: number = cloneStaffWorkList
        .filter((item) => !item.isInput)
        .reduce((sum, item) => sum + item.price, 0);

      setListStaffWork(
        cloneStaffWorkList.map((item) => {
          if (item.staffId !== staffId && !item.isInput) {
            const tipValue = ((item.price / sumPriceWithoutInput) * theRestTip);
            return {
              ...item,
              tip: tipValue > 0 ? tipValue : 0,
            };
          }
          return item;
        })
      );
    }
  };

  useEffect(() => {
    computeTip();
  }, [method, primevalStaffWorkList]);

  const handleChangeMethod = (e: RadioChangeEvent) => {
    setMethod(e.target.value);
  };
  const shareTipItem = (item: StaffWorkItem, index: number) => {
    return (
      <div className="share-tip-item-container" key={item.staffId}>
        <div className="share-tip-item">
          <div className="name-price-box">
            <Text variant="H9" className='fs-md'>{item.name}</Text>
            <Text variant="CONTENT_1" className='fs-md'>{`${formatCurrency(item.price)}`}</Text>
          </div>
          <CurrencyInputPrecision
            onChange={val => {
              handleChangeValue(item.staffId, val);
            }}
            value={item.tip?.toFixed(2)}
            disabled={isDisableInput}
          />
        </div>
        {index != staffWorkList.length - 1 && <Divider></Divider>}
      </div>
    );
  };

  const renderItem = useMemo(
    () => staffWorkList.map((item, index) => shareTipItem(item, index)),
    [staffWorkList]
  );


  return (
    <>
      <TipMethodSysModalStyled
        footer={<GroupButtonFooterModal buttonSequence={btnProps} />}
        title="TIP METHOD SYSTEM"
        closable={false}
        open={visible}
        onCancel={handleClose}
        width={'50vw'}
        centered
      >
        <div className="tip-method-container">
          <div id="total-tip">
            <Text className="alignment fs-lg" variant="BODY_1">
              Total Tip:
            </Text>
            <Text variant="H9" className='fs-lg'>{`${formatCurrency(totalTip)}`}</Text>
          </div>
          <Radio.Group onChange={handleChangeMethod} value={method}>
            <Radio className='radio-text' value={ETipMethod.percent}>Percent</Radio>
            <Radio className='radio-text' value={ETipMethod.even}>Even</Radio>
            <Radio className='radio-text' value={ETipMethod.manual}>Manual</Radio>
          </Radio.Group>
          {renderItem}
        </div>
      </TipMethodSysModalStyled>
    </>
  );
});
ReAdjustTipModal.displayName = 'ReAdjustTipModal';
export default ReAdjustTipModal;

const TipMethodSysModalStyled = styled(Modal)`
    #status {
        width: 100%;
        display: flex;
        justify-content: flex-start
        flex-direction: row;
    }
  #total-tip {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    column-gap: 0.75rem;
    margin-bottom: 16px;
  }
  .alignment {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .tip-method-container {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 0.5rem;
  }
  .share-tip-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .share-tip-item .name-price-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 0.25rem;
    margin-right: 1rem;
  }

  .share-tip-item .ant-input {
    width: 8.625rem;
    color: var(--text-text-3, #1D2129);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    &.ant-input-disabled {
      color: var(--text-text-3, #1D2129);
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .share-tip-item-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .share-tip-item-container .ant-divider {
    margin: 1rem 0 0.5rem 0;
  }
  
  .tip-method-container .ant-radio-group {
    width: 100%;
    justify-content: space-between;
    display: flex;
  }

  .radio-text {
    font-size: 1.5rem;
  }

  .fs-lg {
    font-size: 2rem;
  }

  .fs-md {
    font-size: 1.5rem;
  }
  .share-tip-item .ant-input {
    font-size: 1.5rem !important;
  }
`;
