import { Avatar, Col, Row, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import close from 'assets/close-circle-fill.png';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import appointmentActions from 'features/appointment/services/actions';
import appointmentSelectors from 'features/appointment/services/selectors';
import { IStaffOffItem } from 'features/appointment/services/types/staff';
import { get } from 'lodash';
import { useState } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const columns: ColumnsType<IStaffOffItem> = [
  {
    title: 'No',
    align: 'center',
    render(value, record, index) {
      return index + 1;
    },
  },
  {
    title: 'Staff',
    dataIndex: 'staffName',
    align: 'center',
    render(value, record) {
      return (
        <Row align={'middle'} gutter={8} justify={'center'}>
          <Col>
            <Avatar src={record.avatar}>
              {get(record.firstName, [0], '')}
            </Avatar>
          </Col>
          <Col>
            <Text variant="CONTENT_1" color="text_3">
              {record.firstName ?? ''}
            </Text>
          </Col>
        </Row>
      );
    },
  },
  {
    title: 'Date Off',
    dataIndex: 'dateOff',
    align: 'center',
    render: text => {
      return <Text variant="CONTENT_1" color="text_3">{text}</Text>;
    },
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
    align: 'center',
    render: (value) => <Text variant="CONTENT_1" color="text_3">{value}</Text>,
  },
];

const StaffsDayOff = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const params = appointmentSelectors.getParams();
  const handleOpen = () => {
    setOpen(true);
    dispatch(appointmentActions.getListStaffsOff.fetch({ startTime: params?.startTime || '', endTime: params?.endTime || '' }));
  };
  return (
    <>
      <Button ntype="DEFAULT" size='middle' icon='checkIn' onClick={handleOpen} >Date Off</Button>
      <ModalDateOffs setOpen={setOpen} open={open} />
    </>
  );
};

export default StaffsDayOff;

export const ModalDateOffs = ({ open, setOpen, v2 = false }: { v2?: boolean, open?: boolean, setOpen: (value: boolean) => void, }) => {
  const loading = appointmentSelectors.loadingListStaffsOff();
  const dataSource = appointmentSelectors.listStaffsOff();
  if (v2) {
    return (
      <Modal v2
        hiddenOkButton
        noneBodyStyle
        width={'1000px'}
        visible={open}
        onClose={() => setOpen(false)}
      >
        <TableStyled className='v2'>
          <Table
            loading={loading}
            columns={columns}
            dataSource={dataSource}
            rowKey={'id'}
            pagination={false}
          />
        </TableStyled>

      </Modal>
    );
  }
  return (
    <Modal
      modalTitle="DATE OFF"
      noneFooterStyle
      noneBodyStyle
      containerPadding={1}
      visible={open}
      hiddenOkButton
      footer={null}
      closeIcon={<IconClose />}
      closable
      showClose
      onClose={() => setOpen(false)}
      width={'50vw'}
    >
      <TableStyled>
        <Table
          loading={loading}
          columns={columns}
          dataSource={dataSource}
          rowKey={'id'}
        />
      </TableStyled>
    </Modal>
  );
};

const TableStyled = styled.div`
  .ant-table-wrapper {
    .ant-table {
      .ant-table-container {
        .ant-table-content {
          max-height: 70vh;
          overflow: auto;
          border-radius: 5px;
          border: 1px solid #86909c;
          .ant-table-thead {
            .ant-table-cell {
              position: sticky;
              top: 0;
              z-index: 99;
              border-start-start-radius: 0;
              border-start-end-radius: 0;
              background: var(--fill-fill-2, #adadad);
              color: var(--text-text-1, #fff);
              text-align: center;
              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
              &::before {
                content: none;
              }
            }
          }
          .ant-table-tbody {
            .ant-table-cell {
              border-bottom: 1px solid #86909c;
              color: var(--text-text-3, #1d2129);
              text-align: right;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .ant-table-row:last-child {
              .ant-table-cell {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
    .ant-table-pagination {
      .ant-pagination-item,
      .ant-pagination-next,
      .ant-pagination-prev {
        border-radius: 3.333px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        a {
          color: var(--text-text-3, #1d2129);
          text-align: center;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          text-transform: capitalize;
        }
        &.ant-pagination-item-active {
          background: var(--primary-primary-active, #ff8890);
        }
      }
    }
  }

&.v2 {
  padding: 16px;
  .ant-table-wrapper .ant-table .ant-table-container .ant-table-content .ant-table-thead .ant-table-cell {
    font-size: 24px;
  }
  .ant-table-wrapper .ant-table .ant-table-container .ant-table-content .ant-table-tbody .ant-table-row:last-child .ant-table-cell {
    font-size: 20px;
    .CONTENT_1 {
    font-size: 20px;
    }
  }
}
`;
const IconClose = () => (
  <Row>
    <img src={close} style={{ height: '100%', width: '100%' }} />
  </Row>
);
