import { Avatar, Carousel, Rate } from 'antd';
import checkInSelectors from 'features/checkIn/services/selectors';
import { IGoogleReviewItem } from 'features/checkIn/services/types/review';
import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import googleLogo from './google_logo.png';
import google_sm from './google_sm.png';
const numColumns = 3;

const Reviews = () => {
  const googleReview = checkInSelectors.googleReview();
  const reviews: IGoogleReviewItem[][] = useMemo(() => {
    const data = googleReview?.reviews;
    if (!data?.length) return [];
    return _.chunk(data, numColumns);
  }, [googleReview]);

  return (
    <Styled>
      <div className="top-content">
        <div className="group-google-logo">
          <img src={googleLogo} alt="google-logo" className="google-logo" />
          <p className="review-text">Review</p>
        </div>
        <div className="divider" />
        <div>
          <div className="rating">
            <Rate
              disabled
              value={googleReview?.rating}
              count={5}
              character={({ value = 0, index = 0 }) => (index + 1) <= value ? <StarFill /> : <Star />}
            />
            <span>{googleReview?.rating || 0}</span>
          </div>
          <p className="total-review">
            {googleReview?.totalReviews || 0} reviewers
          </p>
        </div>
      </div>
      <div className='carousel-container'>
        <Carousel className='wrap-carousel' infinite autoplay autoplaySpeed={3000} swipeToSlide dots={{ className: 'dots-container' }} >
          {reviews.map((o, i) => (
            <div key={i}>
              <div className='carousel-item'>
                {o.map(r => (
                  <ReviewItem key={r.id} className='review-item'>
                    <div className='author-info'>
                      <Avatar src={r.profilePhotoUrl} >{r.authorName?.[0]}</Avatar>
                      <p className="author-name">{r.authorName}</p>
                      <p className="author-date">{r.relativeTimeDescription}</p>
                    </div>
                    <div className="comment-info">
                      <Rate
                        disabled
                        value={r.rating}
                        count={5}
                        className='rating'
                        character={({ value = 0, index = 0 }) => (index + 1) <= value ? <StarFillSM /> : <StarSM />}
                      />
                      <p className="comment-text">
                        {r.content}
                      </p>
                      <img src={google_sm} alt="google_sm" className="google_sm" />
                    </div>
                  </ReviewItem>
                ))}
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </Styled>
  );
};

export default Reviews;

const ReviewItem = styled.div`
  display: flex;
  padding: 16px 8px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 20px;
  background: #E5E6EB;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  .author-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .author-name {
    color: #1D2129;
    text-align: center;
    font-family: "Open Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-top: 4px;
  }
  .author-date {
    color: #767676;
    text-align: center;
    font-family: "Open Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .comment-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    .ant-rate.rating {
      gap: 4px;
      justify-content: center;
      .ant-rate-star {
        margin-inline-end: 0;
      }
    }
    .comment-text {
      min-height: 58px;
      overflow: hidden;
      color: #1D2129;
      text-align: center;
      text-overflow: ellipsis;
      font-family: "Open Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      align-self: stretch;
    }
    .google_sm {
      height: 24px;
      width: 24px;
    }
  }
`;

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  .top-content {
    display: flex;
    align-items: center;
    align-self: center;
    gap: 24px;
    padding: 16px 24px;
    .divider {
      background-color: #C9CDD4;
      height: 43px;
      width: 1px;
    }
  }

  .carousel-container {
    flex: 1;
    min-height: 260px;
    position: relative; 
    .wrap-carousel {
      position: absolute;
      inset: 4px;
      .carousel-item {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        padding: 0 24px;
      }
      .dots-container {
        bottom: -40px;
        li {
          button {
            background: #E5E6EB;
            opacity: 1;
            height: 6px;
            border-radius: 4px;
          }
          &.slick-active {
            button {
              background: #1D2129;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .group-google-logo {
    display: flex;
    align-items: center;
    height: 43px;
    gap: 8px;
    .google-logo {
      width: 127px;
      height: 43px;
    }
    .review-text {
      color: #1D2129;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  .rating {
    display: flex;
    align-items: center;
    gap: 8px;
    span {
      color: #1D2129;
      text-align: right;
      font-family: "Open Sans";
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .total-review {
    color: #767676;
    text-align: right;
    font-family: "Open Sans";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;
const StarFill = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="31" height="28" viewBox="0 0 31 28" fill="none">
    <path d="M15.4528 0.0471191L18.9222 10.7248H30.1493L21.0664 17.3239L24.5358 28.0016L15.4528 21.4024L6.36988 28.0016L9.83926 17.3239L0.756315 10.7248H11.9835L15.4528 0.0471191Z" fill="#FF7D00" />
  </svg>
);
const Star = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="28" viewBox="0 0 30 28" fill="none">
    <path d="M15.0759 0.0471191L18.5453 10.7248H29.7724L20.6894 17.3239L24.1588 28.0016L15.0759 21.4024L5.99293 28.0016L9.46231 17.3239L0.379362 10.7248H11.6065L15.0759 0.0471191Z" fill="#E5E5E5" />
  </svg>
);

const StarFillSM = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
    <path d="M9.75977 0L12.0049 6.90983H19.2703L13.3925 11.1803L15.6376 18.0902L9.75977 13.8197L3.88191 18.0902L6.12705 11.1803L0.249201 6.90983H7.51463L9.75977 0Z" fill="#FF7D00" />
  </svg>
);

const StarSM = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
    <path d="M10.29 0L12.5352 6.90983H19.8006L13.9228 11.1803L16.1679 18.0902L10.29 13.8197L4.41219 18.0902L6.65733 11.1803L0.779474 6.90983H8.0449L10.29 0Z" fill="#ADADAD" />
  </svg>
);