import { Col, Divider, Row } from 'antd';
import colorTheme from 'constants/color';
import React, { CSSProperties } from 'react';
import styled from 'styled-components';
import { ISummarySalon, ISummaryTicket } from '../services/types/reportStaff';
import IconAndPrice from './IconAndPrice';

type IIconAndPriceContainerProps = {
  dataSale: ISummarySalon;
  dataTicket: ISummaryTicket;
  isVerticalBorder?: boolean;
};
const labelStyle: CSSProperties = {
  fontSize: '1.5rem',
};
const IconAndPriceContainer: React.FC<IIconAndPriceContainerProps> = ({
  dataSale,
  dataTicket,
  isVerticalBorder,
}) => {
  return (
    <IconAndPriceContainerStyled>
      <Row gutter={[24, 24]} align={'middle'}>
        <Col xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 14 }}>
          <Row gutter={[24, 24]} align={'middle'}>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div
                className={`item-ticket-price padding-vertical ${isVerticalBorder && isVerticalBorder
                  }`}
              >
                <IconAndPrice
                  increase={dataSale.crossSalesPercent > 0}
                  decrease={dataSale.crossSalesPercent < 0}
                  percent={dataSale.crossSalesPercent !== 0 ? dataSale.crossSalesPercent : undefined}
                  price={dataSale.crossSales}
                  title={'GROSS SALES'}
                  icon={'crossSale'}
                  labelStyle={labelStyle}
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div
                className={`item-ticket-price padding-vertical ${isVerticalBorder && isVerticalBorder
                  }`}
              >
                <IconAndPrice
                  price={dataSale.deductions}
                  title={'Deductions'}
                  icon={'moneyExchange'}
                  labelStyle={labelStyle}
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div
                className={`item-ticket-price padding-vertical ${isVerticalBorder && isVerticalBorder
                  }`}
              >
                <IconAndPrice
                  increase={dataSale.netSalesPercent > 0}
                  decrease={dataSale.netSalesPercent < 0}
                  percent={dataSale.netSalesPercent !== 0 ? dataSale.netSalesPercent : undefined}
                  price={dataSale.netSales}
                  title={'NET SALES'}
                  icon={'moneyExchange'}
                  labelStyle={labelStyle}
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div
                className={`item-ticket-price padding-vertical ${isVerticalBorder && isVerticalBorder
                  }`}
              >
                <IconAndPrice
                  price={dataSale.paidOut}
                  title={'PAID OUT'}
                  icon={'moneyExchange'}
                  labelStyle={labelStyle}
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 10 }}
          style={{ borderLeft: '1px solid #86909C', paddingTop: '1rem', paddingBottom: '1rem', paddingRight: '1rem', paddingLeft: '1rem', background: '#F6F7FC' }}
        >
          <Row wrap={false}>
            <Col
              flex={1}
            >
              <div
                className={`item-ticket-price ${isVerticalBorder && isVerticalBorder
                  }`}
              >
                <IconAndPrice
                  increase={dataTicket.percentWalkIn > 0}
                  decrease={dataTicket.percentWalkIn < 0}
                  percent={dataTicket.percentWalkIn !== 0 ? dataTicket.percentWalkIn : undefined}
                  price={dataTicket.totalWalkIn}
                  title={'WALK-IN'}
                  icon={'walkIn'}
                  isHideCurrency
                  labelStyle={labelStyle}
                />
              </div>
            </Col>
            <Col span={2}>
              <Divider type='vertical' style={{ height: '100%' }} />
            </Col>
            <Col
              flex={1}
            >
              <div
                className={`item-ticket-price ${isVerticalBorder && isVerticalBorder
                  }`}
              >
                <IconAndPrice
                  increase={dataTicket.percentAppointment > 0}
                  decrease={dataTicket.percentAppointment < 0}
                  percent={dataTicket.percentAppointment !== 0 ? dataTicket.percentAppointment : undefined}
                  price={dataTicket.totalAppointment}
                  title={'APPOINTMENTS'}
                  icon={'appointments'}
                  isHideCurrency
                  labelStyle={labelStyle}
                />
              </div>
            </Col>
            <Col span={2}>
              <Divider type='vertical' style={{ height: '100%' }} />
            </Col>
            <Col flex={1}>
              <div>ACTIVE TICKETS: {dataTicket.closeTicket ?? 0}</div>
              <div>VOID TICKETS: {dataTicket.voidTicket ?? 0}</div>
              <div>AVERAGE TICKETS:  {dataTicket.averageTicket ?? 0}</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </IconAndPriceContainerStyled>
  );
};

export default IconAndPriceContainer;
const IconAndPriceContainerStyled = styled.div`
  padding: 0rem 1rem;
  gap: 1rem;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid ${colorTheme.line_3};
  background: white;
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .padding-vertical {
    padding: 15px 0px;
  }
  .item-ticket-price {
    border-right: 1px solid #c9cdd4;
  }
  .item-ticket-price:last-child {
    border-right: none;
  }
  .isVerticalBorder {
    border-right: none;
  }
`;
