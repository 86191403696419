import { Checkbox, Col, Form, Input, InputRef, Row } from 'antd';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import CurrencyInputPrecision from 'components/CurrencyInputPrecision';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { IEditCateBodyType } from 'features/settingService/services/types/api';
import { debounce } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import {
  ICategoryItemData,
  IServiceItemData,
} from 'services/shop/types/categories';
import styled from 'styled-components';
import AddServiceButton from '../../ButtonModal/AddServiceButton';

const MAXIMUM_SUB_CATEGORY = 10;

type IAddCateButtonProps = {
  onAddCategory: (body: IEditCateBodyType) => void;
};
const AddCateButton: React.FC<IAddCateButtonProps> = ({ onAddCategory }) => {
  const [form] = Form.useForm<ICategoryItemData>();
  const [open, setOpen] = useState(false);
  const categories = shopSelectors.data.categories();
  const applyFee = Form.useWatch('applyFee', form);
  const supplyFee = Form.useWatch('supplyFee', form);
  const sub_catagories = Form.useWatch('sub_catagories', form);
  const [errorIndex, setErrorIndex] = useState<string[]>([]);
  const inputRef = useRef<InputRef>(null);

  const handleShowModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleFinish = (values: ICategoryItemData) => {
    const body: IEditCateBodyType = {
      categoryId: values.id,
      cateName: values.categoryName,
      cateType: values.cateType || '',
      supplyFee: values.supplyFee || 0,
      orderNumber: values.orderNumber || 0,
      services: values.services.map((item) => ({
        ...item,
        serviceId: item.id,
        categoryId: item.catId,
        orderNumber: item.orderNumber || 0,
        supplyFee: item.supplyFee || 0,
        addOn: false,
      })),
    };
    onAddCategory(body);
    handleCloseModal();
  };

  const focusInput = debounce(() => {
    inputRef.current?.focus();
  }, 100);

  const clearErrorByIndex = (index: string) => {
    const newErrors = errorIndex.filter((item) => item !== index);
    setErrorIndex(newErrors);
  };

  useEffect(() => {
    if (open && inputRef.current) {
      focusInput();
    }
  }, [open, inputRef]);

  useEffect(() => {
    if ((sub_catagories < MAXIMUM_SUB_CATEGORY + 1) && sub_catagories > 0) {
      const newService: IServiceItemData[] = Array.from({
        length: sub_catagories,
      }).map((_item, index) => ({
        orderNumber: index + 1,
        id: '',
        catId: '',
        serviceName: '',
        duration: 15,
        priceSell: 0,
        priceCost: 0,
        commission: 0,
        supplyFee: supplyFee || 0,
      }));

      form.setFieldsValue({ services: newService });
    } else {
      form.setFieldsValue({ services: [] });
    }
  }, [sub_catagories]);

  useEffect(() => {
    const currentServices: IServiceItemData[] = form.getFieldValue('services');
    if (currentServices) {
      const newServices = currentServices.map((item) => ({
        ...item,
        supplyFee: supplyFee,
      }));
      form.setFieldsValue({ services: newServices });
    }
  }, [supplyFee]);

  useEffect(() => {
    form.setFieldsValue({ orderNumber: categories.length + 1 });
  }, []);

  return (
    <>
      <AddCateButtonStyled ntype='SECONDARY' className='wrap-btn-add' height={4} onClick={handleShowModal}>
        <div className="btn-add">
          <Icon type='addPlus' />
          <span>ADD NEW CATEGORY</span>
        </div>
      </AddCateButtonStyled>
      <Modal
        width={'62rem'}
        modalTitle="ADD NEW CATAGORY"
        visible={open}
        onClose={handleCloseModal}
        onSubmit={() => form.submit()}
        destroyOnClose={true}
        okTitle="Save"
      >
        <Form
          form={form}
          onFinish={handleFinish}
          layout="vertical"
          initialValues={{
            cateType: 'SERVICE',
            orderNumber: categories.length + 1,
          }}
        >
          <Form.Item name="cateType" hidden>
            <Input />
          </Form.Item>
          <Box p="4">
            <Row gutter={[24, 24]}>
              <Col span={12}>
                <Form.Item
                  label="Ordinal Number"
                  name="orderNumber"
                  rules={[{ required: true, message: 'Missing orderNumber' }]}
                >
                  <Input type={'number'} />
                </Form.Item>
              </Col>
              <ColCenter span={12}>
                <Box className="center" width={'100%'}>
                  <Box width={'100%'}>
                    <FormItemNoMargin name="applyFee" valuePropName="checked">
                      <Checkbox>Apply Supply Fee</Checkbox>
                    </FormItemNoMargin>
                  </Box>
                  <Box width={'100%'}>
                    {applyFee && (
                      <FormItemNoMargin
                        name="supplyFee"
                        rules={[
                          { required: true, message: 'Missing supplyFee' },
                        ]}
                      >
                        <CurrencyInputPrecision />
                      </FormItemNoMargin>
                    )}
                  </Box>
                </Box>
              </ColCenter>
              <Col span={12}>
                <Form.Item
                  label="Category Name"
                  name="categoryName"
                  rules={[{ required: true, message: 'Missing categoryName' }]}
                >
                  <Input placeholder="Waxing" ref={inputRef} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="Sub-Catagories"
                  name="sub_catagories"
                  rules={[
                    { required: true, message: 'Please enter a number!' },
                    () => ({
                      validator(_, value) {
                        if (!value || value <= MAXIMUM_SUB_CATEGORY) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(`The maximum value is ${MAXIMUM_SUB_CATEGORY}`)
                        );
                      },
                    }),
                  ]}
                >
                  <Input type={'number'} placeholder="0" />
                </Form.Item>
              </Col>
            </Row>
            <Form.List name="services">
              {(fields) => {
                return (
                  <Row gutter={[12, 12]}>
                    {fields.map(({ key, name, ...restField }, index) => {
                      const isHaveError = errorIndex.some(
                        (item) => Number(item) === index
                      );
                      return (
                        <Col span={6} key={key}>
                          <AddServiceButton
                            nameField="services"
                            name={name}
                            restField={restField}
                            index={index}
                            clearErrorByIndex={clearErrorByIndex}
                          />
                          {isHaveError && (
                            <Text variant="CAPTION_1" color="error_4">
                              Check Validate
                            </Text>
                          )}
                        </Col>
                      );
                    })}
                  </Row>
                );
              }}
            </Form.List>
          </Box>
        </Form>
      </Modal>
    </>
  );
};

export default AddCateButton;
const AddCateButtonStyled = styled(Button)`
&.wrap-btn-add {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25), -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset;
  .btn-add {
    display: flex;
    flex:1;
    position: absolute;
    inset: 4px;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px dashed #86909C;
    font-size: 18px;
  }
}
`;
const ColCenter = styled(Col)`
  display: flex;
  align-items: center;
  
`;

const FormItemNoMargin = styled(Form.Item)`
  margin: 0 !important;
`;
