import React from 'react';
import styled from 'styled-components';
import { formatNumber } from 'utils/formatCurrency';
import total_gift_card_icon from '../icons/total-gift-card.svg';
import total_amount_icon from '../icons/total-amount.svg';
import total_redeem_icon from '../icons/total-redeem.png';
import in_store_icon from '../icons/in-store.png';
import online_icon from '../icons/online.png';
import giftCardsSelectors from 'features/giftCards/services/selectors';
import { get } from 'lodash';

const DashboardSalaryTypes = {
  TOTAL_GIFT_CARD: 'TOTAL_GIFT_CARD',
  TOTAL_AMOUNT: 'TOTAL_AMOUNT',
  TOTAL_REDEEM: 'TOTAL_REDEEM',
  IN_STORE: 'IN_STORE',
  ONLINE: 'ONLINE',
};
const configs = {
  [DashboardSalaryTypes.TOTAL_GIFT_CARD]: {
    label: 'Total Gift Cards',
    icon: total_gift_card_icon,
  },
  [DashboardSalaryTypes.TOTAL_AMOUNT]: {
    label: 'Total Amount',
    icon: total_amount_icon,
  },
  [DashboardSalaryTypes.TOTAL_REDEEM]: {
    label: 'Total Redeemed',
    icon: total_redeem_icon,
  },

  [DashboardSalaryTypes.IN_STORE]: {
    label: 'Gift Cards In Store',
    icon: in_store_icon,
  },
  [DashboardSalaryTypes.ONLINE]: {
    label: 'Gift Cards Online',
    icon: online_icon,
  },
};

const GiftCardOverviews = () => {
  const data = giftCardsSelectors.getGiftCardSummary();
  return (
    <Container>
      <div className="overview-label">Overview</div>
      <ContainerStatistic>
        <DashboardItem type='TOTAL_GIFT_CARD' value={formatNumber(data?.totalGiftCard)} />
        <DashboardItem type='TOTAL_AMOUNT' value={formatNumber(data?.totalAmount)} />
        <DashboardItem type='TOTAL_REDEEM' value={formatNumber(data?.totalRedeem)} />
        <DashboardItem type='IN_STORE' value={formatNumber(data?.totalLocal)} />
        <DashboardItem type='ONLINE' value={formatNumber(data?.totalOnline)} />
      </ContainerStatistic>
    </Container>
  );
};

export default GiftCardOverviews;
const Container = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
gap: 16px;
align-self: stretch;
.overview-label {
  color: #1D2129;
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
`;
const ContainerStatistic = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  gap: 16px;
  align-self: stretch;
`;

type DashboardItemProps = {
  type: keyof typeof DashboardSalaryTypes;
  value: string;
};
const DashboardItem = ({ type, value }: DashboardItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return <DashboardItemStyled>
    <div className="content">
      <p className="label">{config.label}</p>
      <p className="value">{value}</p>
    </div>

    <div className="icon-button">
      <img src={config.icon} alt={config.label} />
    </div>

  </DashboardItemStyled>;
};
const DashboardItemStyled = styled.div`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid #CCD4DC;
background: #FFF;
position: relative;

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  p.label {
    align-self: stretch;
    color: #505050;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: capitalize;
  }
  p.value {
    align-self: stretch;
    color: #1D2129;
    font-family: Poppins;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}

.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 16px;
  border-radius: 100px;
  border: 1px solid #FFF;
  img {
    height: 72px;
    width: 72px;
  }
}
`;
