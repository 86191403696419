import React from 'react';
import styled from 'styled-components';
import CategoryItem from './CategoryItem';
import { Form, FormListFieldData } from 'antd';
import { ISimpleMenuItem } from 'services/shop/types/simpleMenu';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
type Props = {
  onOpenEditName?: (cate: ISimpleMenuItem | null) => void
  onDeleteCategory: (id: string) => void;
};
const SimpleMenuList = ({ onOpenEditName = () => undefined, onDeleteCategory }: Props) => {
  const modalConfirmRef = useModalConfirmRef();
  const handleDeleteCategory = (value: ISimpleMenuItem | null) => {
    if (!value) return;
    modalConfirmRef.current?.open(`Do you like to remove ${value.categoryName}?`, () => onDeleteCategory(value.categoryId));
  };
  return (
    <SimpleMenuListStyled>
      <div className="scroll-div">
        <Form.List name={'simpleMenu'}>
          {(fields) => {
            const leftCategories: FormListFieldData[] = [];
            const rightCategories: FormListFieldData[] = [];
            fields.map((o, i) => {
              if (i % 2 == 0) return leftCategories.push(o);
              rightCategories.push(o);
            });
            return (
              <div className="list-container">
                <div className="categories left-categories">
                  {leftCategories.map(({ key, name }) => (
                    <React.Fragment key={key}>
                      <Form.Item name={name}><CategoryItem onDeleteCategory={handleDeleteCategory} onOpenEditName={onOpenEditName} name={name} /></Form.Item>
                    </React.Fragment>
                  ))}
                </div>
                <div className="categories right-categories">
                  {rightCategories.map(({ key, name }) => (
                    <React.Fragment key={key}>
                      <Form.Item name={name}><CategoryItem onDeleteCategory={handleDeleteCategory} onOpenEditName={onOpenEditName} name={name} /></Form.Item>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            );
          }}
        </Form.List>
      </div>
      <ModalConfirm ref={modalConfirmRef} />
    </SimpleMenuListStyled>
  );
};

export default SimpleMenuList;
const SimpleMenuListStyled = styled.div`
  flex: 1;
  background: #fff;
  position: relative;
  .categories {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .left-categories {}

  .list-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    grid-row-gap: 16px;
    padding: 24px;
    padding-right: 8px;
  }

  .scroll-div {
    position: absolute;
    inset: 0;
    right: 8px;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      background-color: #F6F7FC;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #ADADAD;
      &:hover {
        background-color: #ADADAD;
      }
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #F6F7FC;
    }
  }
`;
