import { Input, InputProps } from 'antd';
import useDebounce from 'hooks/useDebounce';
import React, { CSSProperties } from 'react';

type Props = {
  text?: string;
  onChangeText?: (text: string) => void;
  placeholder?: string;
  style?: CSSProperties;
  suffixIcon?: boolean;
  size?: InputProps['size'];
  className?: string;
};

const InputSearch = (props: Props) => {
  const { text, setText } = useDebounce({ value: props?.text, onDebounce: props?.onChangeText });

  return (
    <Input
      style={props.style}
      prefix={!props.suffixIcon && <SearchIcon />}
      value={text}
      size={props.size}
      suffix={props.suffixIcon && <SearchIcon />}
      onChange={e => setText(e.target.value)}
      placeholder={props.placeholder || 'Enter ...'}
      className={props.className}
    />
  );
};

export default InputSearch;

const SearchIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5259_15416)">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 16.5563 7.44365 20.25 12 20.25C16.5563 20.25 20.25 16.5563 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM2.25 12C2.25 6.61522 6.61522 2.25 12 2.25C17.3848 2.25 21.75 6.61522 21.75 12C21.75 17.3848 17.3848 21.75 12 21.75C6.61522 21.75 2.25 17.3848 2.25 12Z" fill="#8E9BA9" />
      <path fillRule="evenodd" clipRule="evenodd" d="M17.4875 17.9555C17.78 17.6622 18.2549 17.6616 18.5482 17.9541L22.0722 21.469C22.3655 21.7615 22.3661 22.2363 22.0736 22.5296C21.7811 22.8229 21.3062 22.8235 21.0129 22.531L17.4889 19.0161C17.1956 18.7236 17.195 18.2487 17.4875 17.9555Z" fill="#8E9BA9" />
    </g>
    <defs>
      <clipPath id="clip0_5259_15416">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
