import React from 'react';

const CardVipV2 = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.5637 8.2103C25.9487 7.40163 26.7559 6.80959 27.6445 6.70184C28.4913 6.58275 29.3821 6.89692 29.9658 7.52526C30.6703 8.25681 30.9041 9.40346 30.5371 10.3528C30.2278 11.2057 29.4668 11.842 28.5985 12.0597C32.3131 16.6362 36.0277 21.2126 39.7445 25.7867C43.5314 23.8859 47.3103 21.9702 51.0938 20.0648C50.8149 19.6213 50.5936 19.1234 50.5868 18.5915C50.536 17.6478 51.0215 16.6997 51.8152 16.1927C52.5954 15.671 53.6499 15.6074 54.4877 16.0305C55.3254 16.4354 55.9159 17.3031 55.9882 18.2342C56.0751 19.1302 55.6766 20.0512 54.972 20.6058C54.2845 21.1649 53.318 21.3362 52.478 21.0583C49.7242 28.054 47.0021 35.0632 44.2585 42.0634C33.4207 42.0645 22.5818 42.0634 11.7441 42.0645C9.00273 35.0621 6.28623 28.0506 3.53135 21.0549C2.69133 21.3373 1.72261 21.1695 1.03389 20.6069C0.259357 20.0092 -0.132424 18.9714 0.0403213 18.0074C0.226615 16.8222 1.28002 15.8456 2.47343 15.7617C3.53925 15.6528 4.62653 16.2528 5.11089 17.2112C5.59187 18.1027 5.49025 19.2402 4.90879 20.0625C8.68998 22.0326 12.4802 23.9845 16.2591 25.958C19.9805 21.3294 23.6883 16.6895 27.4153 12.0654C26.7345 11.8851 26.1067 11.479 25.7274 10.8756C25.2114 10.0976 25.1538 9.04733 25.5637 8.2103Z" fill="#C84B31" />
      <path d="M11.744 43.8787C22.5828 43.8753 33.4217 43.8798 44.2606 43.8764C44.2572 45.6911 44.2629 47.5058 44.2584 49.3205C33.4195 49.3239 22.5806 49.3194 11.7417 49.3228C11.7451 47.5081 11.7394 45.6934 11.744 43.8787Z" fill="#C84B31" />
    </svg>
  );
};

export default CardVipV2;
