import { IBodyApiUpdateTipPayment } from 'features/payment/services/types/api';
import fetch from 'services/request';
import { IApiBodyPayMultipleCard } from './types/api';

const getTransactionDetail = (id: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/bill/transaction-multiple-card-detail/' + id,
  });
};

const updatePaymentTip = (body: IBodyApiUpdateTipPayment) => {
  return fetch({
    method: 'post',
    url: 'api/v1/bill/update-tip',
    body,
  });
};

const payMultipleCard = (body: IApiBodyPayMultipleCard) => {
  return fetch({
    method: 'post',
    url: '/api/v1/payment/multiple-card',
    body,
    configs: {
      timeout: 120000, // 2*60*1000= 120.000ms
    }
  });
};

const updateSignatureForCreditCard = (id: string, signature: string) => {
  return fetch({
    method: 'post',
    url: '/api/v1/payment/update-signature-multiple-card',
    body: {
      billId: id,
      signature,
    },
  });
};

const getListMultipleCards = (billId: string) => {
  return fetch({
    method: 'get',
    url: `/api/v1/payment/multiple-card/${billId}`,
  });
};

const multiCardApis = {
  getTransactionDetail,
  updatePaymentTip,
  payMultipleCard,
  updateSignatureForCreditCard,
  getListMultipleCards,
};

export default multiCardApis;
