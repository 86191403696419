import ActionBtn from 'components/ActionBtn';
import { useSocketContext } from 'hooks/useSocket';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import useUpdateTicket from '../useUpdateTicket';
import shopSelectors from 'services/shop/selectors';
import { useState } from 'react';
import Modal from 'components/Modal';
import CashierMultipleCard from 'features/payment/multipleCard/pages/CashierMultipleCard';
import CashierActionButton from '../CashierActionBtn';

type Props = {
  v2?: boolean;
};
const ButtonMultipleCard = ({ v2 }: Props) => {
  const updateTicket = useUpdateTicket();
  const navigate = useNavigate();
  const socketContext = useSocketContext();
  const allSetting = shopSelectors.data.allSetting();

  const onMultiCard = async () => {
    const rest: { billId: string } | null = await updateTicket();
    if (!rest) return;

    const billId = rest.billId;
    navigate(`/store/${storage.shop_id.get()}/ticket/payment/multiple-card/cashier/${billId}`);
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/multiple-card/customer/${billId}`);
  };

  if (!allSetting?.enableMultipleCards) return null;

  return (
    <ActionBtn v2UI={v2} icon={v2 ? 'multiCardBtnV2' : 'multiCards'} ntype='YELLOW' small label='Multi-Cards' onClick={onMultiCard} />
  );
};

export const ButtonMultipleCardV2 = () => {
  const updateTicket = useUpdateTicket();
  const [billId, setBillId] = useState('');
  const socketContext = useSocketContext();
  const allSetting = shopSelectors.data.allSetting();
  const onClose = () => setBillId('');
  const onMultiCard = async () => {
    const rest: { billId: string } | null = await updateTicket();
    if (!rest) return;

    const billId = rest.billId;
    socketContext.switchCustomerScreen(`/store/${storage.shop_id.get()}/ticket/payment/multiple-card/customer/${billId}`);
    setBillId(billId);
  };

  if (!allSetting?.enableMultipleCards) return null;

  return (
    <>
      <CashierActionButton v2UI feature='MULTI-CARDS' ntype='YELLOW' label='Multi-Cards' onClick={onMultiCard} />
      {!!billId &&
        <Modal visible
          onClose={onClose}
          width={'auto'} footer={null} className='modal-overflow-unset modal-non-opacity'
          hiddenHeader
          noneBodyStyle
        >
          <CashierMultipleCard billId={billId} onClose={onClose} v2 />
        </Modal>}
    </>
  );
};
export default ButtonMultipleCard;
