import Icon from 'assets/Icons';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceSelectors from 'features/settingService/services/selectors';
import { ICategoryItemData } from 'services/shop/types/categories';
import styled from 'styled-components';
import RequestAdminPWCallback, { useRequestAdminPWCallbackRef } from 'widgets/RequestAdminLocalPW/Callback';
import ButtonApplySupplyPrice from '../../SettingSimpleMenu/ButtonApplySupplyPrice';
import ModalEditName, { useModalEditNameRef } from '../ModalEditName';
import AddServiceCatesButton from './AddServiceCatesButton';
import ArrangeServices from './ArrangeServiceModal';
import { ADD_ON_CATE_ID } from 'services/shop/constants';
import { ICreateMultipleAddOnItem, IEditCateBodyType } from 'features/settingService/services/types/api';
import ButtonApplyDiscountItem from './ButtonApplyDiscountItem';
import { DISCOUNT_VALUE_TYPE } from 'features/settingService/services/constants';

type Props = {
  className?: string;
  activeCate: ICategoryItemData | null;
  onDeleteServices: (ids: string[]) => void;
  onDeleteServicesAddOn: (ids: string[]) => void;
  onApplySupplyFee: (cateId: string, value: number) => void;
  onEditNameCate: (cateId: string, value: string) => void;
  onDeleteCategory: (cateId: string) => void;
  onPreOrderServices: (ids: string[]) => void;
  onPreOrderServicesAddOn: (ids: string[]) => void;
  onAddMoreServiceForCate: (body: IEditCateBodyType) => void;
  onAddMoreServiceForAddOn: (body: ICreateMultipleAddOnItem[]) => void;
  onApplySupplyFeeAddOn: (value: number) => void;
  onApplyDiscountItemsCate: (cateId: string, type: DISCOUNT_VALUE_TYPE, value: number) => void;
  onApplyDiscountItemsCateAddon: (type: DISCOUNT_VALUE_TYPE, value: number) => void;
};
const CategoryDetailHeader = ({
  className, activeCate,
  onDeleteServices, onApplySupplyFee, onEditNameCate,
  onDeleteCategory, onPreOrderServices, onAddMoreServiceForCate,
  onApplySupplyFeeAddOn, onDeleteServicesAddOn, onPreOrderServicesAddOn,
  onAddMoreServiceForAddOn, onApplyDiscountItemsCate, onApplyDiscountItemsCateAddon
}: Props) => {
  const modalEditNameRef = useModalEditNameRef();
  const pwRef = useRequestAdminPWCallbackRef();
  const selectedServiceIds = settingServiceSelectors.serviceTab.selectedServiceIds();

  const isAddOnCate = activeCate?.id === ADD_ON_CATE_ID;

  const switchCase = (normal: () => void, addOn: () => void) => {
    if (activeCate?.id === ADD_ON_CATE_ID) return addOn();
    normal();
  };

  const handleDeleteServices = () => pwRef.current?.open(() => switchCase(
    () => onDeleteServices(selectedServiceIds),
    () => onDeleteServicesAddOn(selectedServiceIds)
  ));

  const handleEditNameCate = () => {
    modalEditNameRef.current?.open(activeCate?.categoryName || '');
  };

  const handleDeleteCategory = () => {
    if (!activeCate) return;
    pwRef.current?.open(() => onDeleteCategory(activeCate.id));
  };

  const handleApplySupplyFee = (value: number) => switchCase(
    () => onApplySupplyFee(activeCate?.id || '', value),
    () => onApplySupplyFeeAddOn(value)
  );

  const handlePreOrderServices = (ids: string[]) => switchCase(
    () => onPreOrderServices(ids),
    () => onPreOrderServicesAddOn(ids)
  );

  const handleAddMoreService = (body: IEditCateBodyType) => switchCase(
    () => onAddMoreServiceForCate(body),
    () => onAddMoreServiceForAddOn(body.services.map((o) => ({
      addOn: true,
      duration: o.duration,
      priceSell: o.priceSell,
      supplyFee: o.supplyFee,
      serviceName: o.serviceName,
    }) as ICreateMultipleAddOnItem))
  );

  const handleApplyDiscountItems = (type: DISCOUNT_VALUE_TYPE, value: number) => switchCase(
    () => onApplyDiscountItemsCate(activeCate?.id || '', type, value),
    () => onApplyDiscountItemsCateAddon(type, value)
  );

  if (!activeCate?.services?.length) return (
    <Styled className={className}>
      <Text className="category-name">{activeCate?.categoryName || 'Unknown'}</Text>
      {!isAddOnCate && <div className='btn-actions'>
        <Button onClick={handleEditNameCate} ntype='GHOST' size='small' className="btn-action"><Icon type='editCategory' /></Button>
        <Button onClick={handleDeleteCategory} ntype='GHOST' size='small' className="btn-action"><Icon type='deleteCategory' /></Button>
      </div>}
      <div style={{ flex: 1 }} />
      <Button style={{ opacity: 0, transition: 'none' }} ntype='DEFAULT' size='middle' height={3}>Apply Supply Price</Button>
      <div className="divider" />
      <div className='btn-actions'>
        <AddServiceCatesButton cateSelected={activeCate} onAddMoreServiceForCate={handleAddMoreService} />
      </div>
    </Styled>
  );

  return (
    <>
      <Styled className={className}>
        <Text className="category-name">{activeCate?.categoryName || 'Unknown'}</Text>
        {!isAddOnCate && <div className='btn-actions'>
          <Button onClick={handleEditNameCate} ntype='GHOST' size='small' className="btn-action"><Icon type='editCategory' /></Button>
          <Button onClick={handleDeleteCategory} ntype='GHOST' size='small' className="btn-action"><Icon type='deleteCategory' /></Button>
        </div>}
        <div style={{ flex: 1 }} />
        <ButtonApplyDiscountItem onSubmit={handleApplyDiscountItems} />
        <ButtonApplySupplyPrice onSubmit={handleApplySupplyFee} />
        <Button onClick={handleDeleteServices} disabled={!selectedServiceIds.length} ntype='LIGHT_PRIMARY' size='middle' height={3} icon='deleteService'>Delete Services</Button>
        <ArrangeServices data={activeCate.services || []} onPreOrder={handlePreOrderServices} />
        <div className="divider" />
        <div className='btn-actions'>
          <AddServiceCatesButton cateSelected={activeCate} onAddMoreServiceForCate={handleAddMoreService} />
        </div>
      </Styled>
      <RequestAdminPWCallback ref={pwRef} onlyAdmin />
      <ModalEditName ref={modalEditNameRef} onSubmit={(value) => onEditNameCate(activeCate.id, value)} />
    </>
  );
};

export default CategoryDetailHeader;

const Styled = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 24px;
  border-bottom: 1px solid #C9CDD4;
  margin-bottom: 4px;
  gap: 24px;
  .category-name {
    color: #1D2129;
    font-family: "Open Sans";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .btn-actions {
    display: flex;
    gap: 20px;
    align-items: center;
    .btn-action {
      padding: 0px;
    }
  }
  .divider {
    background: #C9CDD4;
    width: 1px;
    align-self: stretch;
    height: 90%;
  }
  button {
    box-shadow: none;
  }
`;