import { createSlice } from '@reduxjs/toolkit';
import actions, { turnManualAPIActions } from './actions';
import { getTurnManualColorSettings, NAME_REDUCER, TURN_SORT_BY, turnBoxColorDataDefault } from './constants';
import { IState } from './types/reducer';
import { ITurnServiceItem } from './types/service';
import { ITurnManualItem } from './types/turn';

const initialState: IState = {
  turn: [],
  turnLoading: false,
  turnDetail: null,
  showModalTurnDetail: false,
  turnStaffsAvailable: [],
  selectWaitingListResItem: undefined,
  turnManual: {
    serviceSelected: null,
    fullTurnData: [],
    halfTurnData: [],
    turns: [],
    staffActiveId: '',
    activeTurnManual: null,
    visibleItemName: true,
    sort: TURN_SORT_BY.CLOCK_IN,
    settingColors: turnBoxColorDataDefault,
  }
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {
    turnManualSetSort: (state, { payload }: { payload: TURN_SORT_BY }) => {
      state.turnManual.sort = payload;
    },
    turnManualSetVisibleItemName: (state, { payload }: { payload: boolean }) => {
      state.turnManual.visibleItemName = payload;
    },
    turnManualSetActiveTurnManual: (state, { payload }: { payload: ITurnManualItem | null }) => {
      if (typeof state.turnManual.activeTurnManual === 'object' && state.turnManual.activeTurnManual?.turnId === payload?.turnId) {
        state.turnManual.activeTurnManual = null;
        return;
      }
      state.turnManual.activeTurnManual = payload;
    },
    reUpdateActiveTurnManual: (state, { payload }: { payload: ITurnManualItem }) => {
      state.turnManual.activeTurnManual = payload;
    },
    turnManualSetActiveAddNewStaffKey: (state, { payload }: { payload: string }) => {
      if (state.turnManual.activeTurnManual === payload) {
        state.turnManual.activeTurnManual = null;
        return;
      }
      state.turnManual.activeTurnManual = payload;
    },
    turnManualSetStaffActive: (state, { payload }: { payload: string }) => {
      if (state.turnManual.staffActiveId === payload) {
        state.turnManual.staffActiveId = '';
        return;
      }
      state.turnManual.staffActiveId = payload;
    },
    turnManualChooseService: (state, { payload }: { payload: ITurnServiceItem }) => {
      if (state.turnManual.serviceSelected?.itemId === payload.itemId)
        state.turnManual.serviceSelected = null;
      state.turnManual.serviceSelected = payload;
    },
    turnManualResetChooseService: (state) => {
      state.turnManual.serviceSelected = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(turnManualAPIActions.getTurns.success, (state, { payload }) => {
        state.turnManual.turns = payload;
      })
      .addCase(turnManualAPIActions.getTurnConfigServices.success, (state, { payload }) => {
        state.turnManual.fullTurnData = payload.filter(o => o.turn === 1);
        state.turnManual.halfTurnData = payload.filter(o => o.turn == 0.5);
        state.turnManual.serviceSelected = null;
      })
      .addCase(turnManualAPIActions.getTurnSettingColors.success, (state, { payload }) => {
        state.turnManual.settingColors = getTurnManualColorSettings(payload);
      })
      .addCase(actions.getTurn.success, (state, { payload }) => {
        state.turn = payload;
      })
      .addCase(actions.setTurnLoading, (state, { payload }) => {
        state.turnLoading = payload;
      })
      .addCase(actions.getTurnDetail.success, (state, { payload }) => {
        state.turnDetail = payload;
        state.showModalTurnDetail = true;
      })
      .addCase(actions.setShowModalTurnDetail, (state) => {
        state.turnDetail = null;
        state.showModalTurnDetail = false;
      })
      .addCase(actions.getTurnStaffsAvailable.success, (state, { payload }) => {
        state.turnStaffsAvailable = payload;
      })
      .addCase(actions.selectWaitingListResItem, (state, { payload }) => {
        state.selectWaitingListResItem = payload;
      })
      ;
  },
});

export const turnManualActions = {
  chooseService: Slice.actions.turnManualChooseService,
  resetChooseService: Slice.actions.turnManualResetChooseService,
  setStaffActive: Slice.actions.turnManualSetStaffActive,
  setStaffKeyActive: Slice.actions.turnManualSetActiveAddNewStaffKey,
  setActiveTurnManual: Slice.actions.turnManualSetActiveTurnManual,
  setVisibleItemName: Slice.actions.turnManualSetVisibleItemName,
  setSort: Slice.actions.turnManualSetSort,
  updateActiveTurnManual: Slice.actions.reUpdateActiveTurnManual,
};
const turnServiceReducer = Slice.reducer;
export default turnServiceReducer;
