import React from 'react';
import styled from 'styled-components';
import HomeV2Footer from './Footer';
import { Col, Layout, Row } from 'antd';
import StaffAvailable from '../StaffAvailable';
import GroupLayoutButton from '../GroupLayoutButton';
import WalkInWaitingList from 'features/user/widgets/WaitingList/WalkIn';
import TicketWaitingList from 'features/user/widgets/WaitingList/Ticket';
import HomeV2Header from './Header';

type IMainProps = {
  model: 'staff' | 'ticket' | 'default';
  setModel: (mode: 'staff' | 'ticket' | 'default') => void;
};
const HomeMainV2: React.FC<IMainProps> = ({ model, setModel }) => {
  return (
    <MainStyled>
      <HomeV2Header />
      <Layout.Content>
        <Layout hasSider className='container' style={{ overflow: 'hidden' }}>
          <Layout.Sider translate='no' collapsed={model === 'ticket'} reverseArrow width={model === 'default' ? '50%' : '100%'} collapsedWidth={0} className='ant-content-overflow sider' style={{ background: '#F5F5F5', borderRight:'1px solid #CCD4DC' }}>
            <StaffAvailable v2 mode={model} />
          </Layout.Sider>
          {model === 'default' && <GroupLayoutButton v2 mode={model} setModel={setModel} />}
          <Layout.Sider collapsed={model === 'staff'} translate='yes' reverseArrow={false} width={model === 'default' ? '50%' : '100%'} collapsedWidth={-1} className='sider'>
            <div className='wrap-waiting-ticket'>
              <Row className='wrap-tickets-container' style={{ margin: 0 }}>
                <Col span={model === 'ticket' ? 12 : 10} className='waiting-ticket'>
                  <WalkInWaitingList v2 mode={model} />
                </Col>
                <Col span={model === 'ticket' ? 12 : 14} className='waiting-ticket'>
                  <TicketWaitingList v2 mode={model} />
                </Col>
              </Row>
            </div>
          </Layout.Sider>
        </Layout>
      </Layout.Content>
      {model !== 'default' && <GroupLayoutButton v2 mode={model} setModel={setModel} />}
      <HomeV2Footer />
    </MainStyled>
  );
};

export default HomeMainV2;

const MainStyled = styled(Layout)`
border-left: 1px solid #CCD4DC;
  .container {
    height:100%;
    background: #fff;
  }
  .ant-content-overflow {
    max-height: 100%;
  }
  .children-container {
    padding: 1.125rem;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .sider {
    background:#fff;
    min-height: 40vw;
    transition: all 0.5s, background 0s;
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  .footer {
    position: sticky;
    bottom: 0;
    background:red;
  }
  .wrap-waiting-ticket {
    flex: 1;
    display: flex;
    .wrap-tickets-container {
      flex: 1;
    }
  }
  .waiting-ticket {
    display: flex;
    flex:1;
    min-height:50vh;
    gap:14px;
    position: relative;
    .divider {
      width:1px;
      background: #CCD4DC;
    }
  }

  .ticket-label-section {
    background: transparent;
  }

  .box-div-scroll {
    background: #F5F5F5 !important;
    border-color: #CCD4DC;
    padding-top: 0 !important;
  }
`;
