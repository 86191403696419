import { Form, Spin } from 'antd';
import Box from 'components/Box';
import Empty from 'components/Empty';
import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';

type Ref = {
  setLoading: (value: boolean) => void;
};
type Props = {
  value?: string;
  onChange?: (value?: string) => void;
  detailApt?: boolean;
};
export const useStartTimesRef = () => useRef<Ref>(null);
const StartTimes = forwardRef<Ref, Props>(({ value, onChange = () => undefined, detailApt }, ref) => {
  const [loading, setLoading] = useState(false);

  useImperativeHandle(ref, () => ({
    setLoading,
  }));

  return (
    <Spin spinning={loading} >
      <Form.Item name={'time-slots'} noStyle>
        <TimeSlots detailApt={detailApt} loading={loading} dateValue={value} onChangeDate={onChange} />
      </Form.Item>
    </Spin>
  );
});
StartTimes.displayName = 'StartTimes';
export default StartTimes;
const TimeSlots = ({ detailApt, loading, value: timeLines = [], dateValue, onChangeDate }: { value?: string[], dateValue?: string, onChangeDate: (value: string) => void; loading?: boolean, detailApt?: boolean }) => {
  if (!loading && !timeLines.length) return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <Empty description='No data' />
    </Box>
  );
  return (
    <Container className={detailApt ? 'detailApt' : ''}>
      {timeLines.map(o => (
        <button type='button' onClick={() => onChangeDate(o)} key={o} className={`start-time-item ${dateValue === o ? 'active' : ''}`}>
          <span>{o}</span>
        </button>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill,minmax(140px, 1fr));
  gap: 8px;
  .start-time-item {
    display: flex;
    padding: 16px 8px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 5px;
    border: 1px solid #CCD4DC;
    background: #F6F7FC;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      letter-spacing: 0.4px;
    }

    &.active {
      border: 1px solid #2D6772;
      background: #C6E9EF;
    }
  }

  &.detailApt {
    .start-time-item {
      display: flex;
      padding: 16px 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex: 1 0 0;
      border: 1px solid #CCD4DC;
      background: #FFF;
      span {
        color: #505050;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &.active {
        border: 1px solid #2D6772;
        background: #2D6772;
        span {
          color: #FFF;
        }
      }
    }
  }
`;