import { Row } from 'antd';
import Icon from 'assets/Icons';
import Button from 'components/Button';
import Text from 'components/Text';
import { ICalendarItemData } from 'features/appointment/services/types/calendar';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { maxBy, minBy } from 'lodash';
import React, { memo, useMemo } from 'react';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
import useAnybodyVisible from './useAnybodyVisible';
 
type IHeadContentProps = {
  modal?: boolean;
  data: ICalendarItemData[];
};
const HeadContent: React.FC<IHeadContentProps> = ({ data }) => {
  const anybodyBox = useAnybodyVisible();
  const startTime = useMemo(() => {
    const item = minBy(data, o => momentTimezone(o.startTime).valueOf());
    if (!item?.startTime) return null;
    return momentTimezone(item?.startTime);
  }, [data]);

  const endTime = useMemo(() => {
    const item = maxBy(data, o => momentTimezone(o.endTime).valueOf());
    if (!item?.endTime) return '';
    return momentTimezone(item?.endTime).format(FORMAT_TIME);
  }, [data]);

  return (
    <HeadContentStyled>
      <Row align={'middle'} justify={'space-between'}>
        <Row >
          <Text variant='H5' color='text_3'>MULTIPLE APPOINTMENT ANYBODY ({data.length})</Text>
        </Row>
        <Text variant='H5' color='text_3'> {startTime?.format('MM-DD-YYYY')} {startTime?.format(FORMAT_TIME)} - {endTime}</Text>
        <IconButton className='arrow-btn' onClick={() => anybodyBox.close()}>
          <Icon type="close" width="32" height="32"></Icon>
        </IconButton>
      </Row>
    </HeadContentStyled>
  );
};

export default memo(HeadContent);
type HeadContentStyledProps = {}
const HeadContentStyled = styled.div<HeadContentStyledProps>`
  display:flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 0 1rem;
  .list-type {
    gap:1rem;
  }
  .percent-bar {
    .progress {
      height: 0.5rem;
      .stroke {
        height: 100%;
        width: 100%;
      }
    }
  }
`;

const IconButton = styled(Button)`
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  width: 3rem;
  height: 3rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
