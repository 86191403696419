import { Row } from 'antd';
import appointmentActions from 'features/appointment/services/actions';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import StaffSearchInput from 'widgets/Staff/StaffSearchInput';
type Props = {
  v2?: boolean;
};
const StaffPicker = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const handlePickStaff = (o: IStaffItemData) => {
    dispatch(appointmentActions.setTableParams({ staffId: o.id, page: 1, }));
  };
  const handleClear = () => {
    dispatch(appointmentActions.setTableParams({ staffId: undefined, page: 1, }));
  };

  return (
    <StaffPickerStyled className={v2 ? 'v2' : ''}>
      <StaffSearchInput onPickAll={handleClear} onPickStaff={handlePickStaff} />
    </StaffPickerStyled>
  );
};

export default StaffPicker;
const StaffPickerStyled = styled(Row)`
width: 18rem;
  .ant-select {
    .ant-select-selector {
      height: 2.5rem !important;
      .ant-select-selection-search {
        height: 100%;
        .ant-input-affix-wrapper {
          height: 100%;
          &::before {
            content: none;
          }
          input {
            height: 100%;
          }
          .ant-input-suffix {
            height: unset;
          }
        }
  
      }

    }
  }

&.v2 {
  flex: 1;
  width: unset;
  align-self: stretch;
  .ant-select .ant-select-selector {
    height: 100% !important;
    .ant-input-affix-wrapper {
      background: #E5E6EB;
      border: 1px solid #E5E6EB;
      input {
        background-color: transparent;
        color: #1D2129;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }
  }
}
`;
