import ActionBtn from 'components/ActionBtn';
import cashierSelectors from 'features/cashier/services/selectors';
import multiplePaymentActions from 'features/payment/pages/PaymentPage/services/actions';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { useNavigate } from 'react-router-dom';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import useUpdateTicket from '../useUpdateTicket';
import { useState } from 'react';
import PaymentInfoBox from 'features/payment/pages/PaymentPage/CashierSide/components/PaymentInfoBox';
import styled from 'styled-components';
import Modal from 'components/Modal';
import CashierActionButton from '../CashierActionBtn';

type Props = {
  v2?: boolean;
};
const useMultiplePayment = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const updateTicket = useUpdateTicket();
  const enableCashIncentive = cashierSelectors.enableCashIncentive();
  const discountSetting = shopSelectors.data.discountSetting();
  const enableCardFee = cashierSelectors.getEnableCardFee();

  const checkCashIncentiveValid = (): boolean => {
    if (!discountSetting) return false;
    if (!discountSetting?.enableCashIncentive) return false;
    if (!enableCashIncentive) return false;
    return true;
  };

  const onCash = async () => {
    const rest: { billId: string } | null = await updateTicket();
    if (!rest) return;
    const billId = rest.billId;
    dispatch(multiplePaymentActions.setActivePayment(PAYMENT_TYPE.CASH));
    navigate(`/store/${storage.shop_id.get()}/ticket/payment/cashier-side/${billId}`, { state: { discountIncentive: checkCashIncentiveValid(), enableCardFee } });
  };
  return ({
    onCash,
    checkCashIncentiveValid,
    enableCardFee,
  });
};
const ButtonMultiplePayment = ({ v2 }: Props) => {
  const { onCash } = useMultiplePayment();
  return (
    <ActionBtn v2UI={v2} icon={v2 ? 'multiPayBtnV2' : 'multiPay'} ntype='YELLOW' small label={'Multi-Pays'} onClick={onCash} />
  );
};

export const ButtonMultiplePaymentModal = ({ v2 }: Props) => {
  const dispatch = useAppDispatch();
  const [billId, setBillId] = useState('');
  const updateTicket = useUpdateTicket();
  const onClose = () => setBillId('');
  const { checkCashIncentiveValid, enableCardFee } = useMultiplePayment();
  const onCash = async () => {
    setBillId('');
    const rest: { billId: string } | null = await updateTicket();
    if (!rest) return;

    const billId = rest.billId;
    dispatch(multiplePaymentActions.getTransactionDetail.fetch(billId));
    dispatch(multiplePaymentActions.setActivePayment(PAYMENT_TYPE.CASH));
    setBillId(billId);
  };

  return (
    <>
      <CashierActionButton v2UI={v2} feature='MULTI-PAYS' ntype='YELLOW' label={'Multi-Pays'} onClick={onCash} />
      {!!billId &&
        <Modal visible
          onClose={onClose}
          width={'auto'} footer={null} className='modal-overflow-unset modal-non-opacity'
          hiddenHeader
          noneBodyStyle
        >
          <Container>
            <PaymentInfoBox billId={billId} onClose={onClose} v2 discountIncentive={checkCashIncentiveValid()} enableCardFee={enableCardFee} />
          </Container>
        </Modal>}
    </>
  );
};

export default ButtonMultiplePayment;

const Container = styled.div``;