import settingApis from 'features/settingService/services/apis';
import { IBodyCreateSimpleMenu, IBodyUpdateSimpleMenuServiceItem } from 'features/settingService/services/types/simpleMenu';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import { ISimpleMenuItem } from 'services/shop/types/simpleMenu';
import { useAppDispatch } from 'store/hooks';

const useSettingSimpleMenu = () => {
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const updateInfoService = async (body: IBodyUpdateSimpleMenuServiceItem[]) => {
    setLoadingPage(true);
    try {
      await settingApis.simpleMenu.updateInfoService(body);
    } catch (error) { }
    finally {
      setLoadingPage(false);
      dispatch(shopActions.get.simpleMenu.fetch(true));
    }
  };

  const editNameCategory = async (data: ISimpleMenuItem, newValue: string) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.simpleMenu.updateNameCategory(data.categoryId, newValue);
      if (res.data.data) dispatch(shopActions.get.simpleMenu.fetch(true));
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const applySupplyFee = async (value: number) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.simpleMenu.applySuppleFee(value);
      if (res.data.data) dispatch(shopActions.get.simpleMenu.fetch(true));
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };
  const createNewCate = async (body: IBodyCreateSimpleMenu) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.simpleMenu.create(body);
      if (res.data.data) dispatch(shopActions.get.simpleMenu.fetch(true));
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const enableCheckIn = async (value: boolean) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.simpleMenu.enableCheckIn(value);
      if (res.data.data) dispatch(shopActions.get.simpleMenuConfigCheckIn.fetch());
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const reorderCategories = async (ids: string[]) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.simpleMenu.reorderCategories(ids);
      if (res.data.data) dispatch(shopActions.get.simpleMenu.fetch(true));
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  const deleteCategory = async (id: string) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.simpleMenu.deleteCategory(id);
      if (res.data.data) dispatch(shopActions.get.simpleMenu.fetch(true));
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return ({
    updateInfoService,
    editNameCategory,
    applySupplyFee,
    createNewCate,
    enableCheckIn,
    reorderCategories,
    deleteCategory,
  });
};

export default useSettingSimpleMenu;
