import { Col, Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import CategoriesChart from './components/CategoriesChart';
import TopServicesSale from './components/TopServicesSale';
import reportActions from 'features/report/services/actions';
import reportSelectors from 'features/report/services/selectors';
import AppointmentDatePicker from '../components/DatePicker';
import SwitchCalendarType from '../components/SwitchCalendarType';
import { useAppDispatch } from 'store/hooks';
import moment from 'moment';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import { ReportType } from '../constants';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import shopSelectors from 'services/shop/selectors';

type IServicesReportProps = {};
const ServicesReport: React.FC<IServicesReportProps> = () => {
    const allSetting = shopSelectors.data.allSetting();
    const v2 = allSetting?.versionUI === 'V2';
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const dataAppointment = reportSelectors.getParams()?.[ReportType.SERVICES];
    useEffect(() => {
        dispatch(reportActions.setParams({
            [ReportType.SERVICES]: {
                startTime: dataAppointment?.startTime || moment().format(DATE_FORMAT),
                endTime: dataAppointment?.endTime || moment().format(DATE_FORMAT)
            }
        }));
    }, []);

    const onPrint = () => {
        navigate(`/store/${storage.shop_id.get()}/report/print/services-report`);
    };
    return (
        <ServicesReportStyled className={v2 ? 'v2' : ''}>
            {/* header */}
            <HeaderBox>
                <Box>
                    <Text className='title-page' variant="H5">SERVICES REPORTS</Text>
                </Box>
                <HeaderBox className={v2 ? 'v2' : ''}>
                    {v2 ? <>
                        <SwitchCalendarType v2 actions={reportActions} selectors={reportSelectors} type={ReportType.SERVICES} />
                        <AppointmentDatePicker v2 size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.SERVICES} />
                        <Button width='9rem' cardCashier ntype='SECONDARY' className='btn-print' icon="print" onClick={onPrint}>
                            Preview
                        </Button>
                    </> : <>
                        <AppointmentDatePicker size='middle' actions={reportActions} selectors={reportSelectors} type={ReportType.SERVICES} />
                        <SwitchCalendarType actions={reportActions} selectors={reportSelectors} type={ReportType.SERVICES} />
                        <Button icon="print" onClick={onPrint}>
                            Preview
                        </Button>
                    </>}
                </HeaderBox>
            </HeaderBox>
            {v2 ?
                <ContainerContent>
                    <TopServicesSale v2 />
                    <CategoriesChart v2 />
                </ContainerContent>
                : <>
                    {/* container */}
                    <BodyBox gutter={[24, 24]}>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={16}
                            xxl={16}
                        >
                            <CategoriesChart />
                        </Col>
                        <Col
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={8}
                            xxl={8}
                        >
                            <TopServicesSale />
                        </Col>
                    </BodyBox>
                </>}
        </ServicesReportStyled>
    );
};

export default ServicesReport;
const ServicesReportStyled = styled.div`
    min-height: 100vh;
    background-color: ${colorTheme.fill_3};
    padding: 1.5rem;
    direction: flex;
    flex-direction: column;
    gap: 1.5rem;
    &.v2 {
        gap: 16px;
        background: #F5F5F5;
        padding: 16px;
        min-height: unset;
        display: flex;
        flex-direction: column;
    }
`;

const HeaderBox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;

    &.v2 {
        .btn-print {
            .CONTENT_2 {
                color: #1D2129;
                text-align: center;
                font-family: Poppins;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }
        }
    }
`;

const BodyBox = styled(Row)`
    margin: 1rem 0;
`;

const ContainerContent = styled.div`
display: flex;
align-items: flex-start;
align-self: stretch;
gap: 16px;
background: #fff;
`;
