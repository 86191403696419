import { Typography } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Text from 'components/Text';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { IHistoryTracking } from '../services/types/reducer';
import { maskPhone } from 'utils/formatPhone';
import { Table as AntTable } from 'antd/lib';
import Table from 'components/Table';
const columns: ColumnsType<any> = [
    {
        title: 'Ticket Number',
        dataIndex: 'billNumber',
        align: 'center',
        render(billNumber) {
            return (
                <>
                    <EllipsisText ellipsis={{ tooltip: billNumber }}>
                        {billNumber || '--'}
                    </EllipsisText>
                </>
            );
        },
    },
    {
        title: 'Customer Name',
        dataIndex: 'customerName',
        align: 'center',
        render(customerName) {
            return (
                <EllipsisText ellipsis={{ tooltip: customerName }}>
                    {customerName || '--'}
                </EllipsisText>
            );
        },
    },
    {
        title: 'Customer Phone',
        dataIndex: 'customerPhone',
        align: 'center',
        render(customerPhone) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {customerPhone ? maskPhone(customerPhone) : '--'}
                </Text>
            );
        },
    },
    {
        title: 'Amount',
        dataIndex: 'usedAmount',
        align: 'center',
        render(usedAmount) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {formatCurrency(usedAmount)}
                </Text>
            );
        },
    },
    {
        title: 'Action',
        dataIndex: 'action',
        align: 'center',
        render(action) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {action}
                </Text>
            );
        },
    },
    {
        title: 'Date Used',
        dataIndex: 'createdDate',
        align: 'center',
        render(createdDate) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {createdDate ? moment(createdDate).format('MM-DD-YYYY') : ''}
                </Text>
            );
        },
    }
];
const columnsV2: ColumnsType<any> = [
    {
        title: 'Ticket Number',
        dataIndex: 'billNumber',
        align: 'left',
        render(billNumber) {
            return (
                <>
                    <EllipsisText className='card-number' ellipsis={{ tooltip: billNumber }}>
                        {billNumber ? '#' + billNumber : '--'}
                    </EllipsisText>
                </>
            );
        },
    },
    {
        title: 'Customer Name',
        dataIndex: 'customerName',
        align: 'left',
        render(customerName) {
            return (
                <EllipsisText className='card-number' ellipsis={{ tooltip: customerName }}>
                    {customerName || '--'}
                </EllipsisText>
            );
        },
    },
    {
        title: 'Customer Phone',
        dataIndex: 'customerPhone',
        align: 'left',
        render(customerPhone) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {customerPhone ? maskPhone(customerPhone) : '--'}
                </Text>
            );
        },
    },
    {
        title: 'Amount',
        dataIndex: 'usedAmount',
        align: 'left',
        render(usedAmount) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {formatCurrency(usedAmount)}
                </Text>
            );
        },
    },
    {
        title: 'Action',
        dataIndex: 'action',
        align: 'left',
        render(action) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {action}
                </Text>
            );
        },
    },
    {
        title: 'Date Used',
        dataIndex: 'createdDate',
        align: 'left',
        render(createdDate) {
            return (
                <Text variant="BODY_1" color="text_3">
                    {createdDate ? moment(createdDate).format('MM-DD-YYYY') : ''}
                </Text>
            );
        },
    }
];
type ITableGiftCardDetailProps = {
    data: IHistoryTracking[];
    className?: string;
    v2?: boolean;
};
const TableGiftCardDetail: React.FC<ITableGiftCardDetailProps> = ({ data, className, v2 }) => {
    if (v2) return (
        <TableGiftCardDetailStyled className={className}>
            <AntTable
                columns={columnsV2}
                dataSource={data}
                rowKey={'id'}
                pagination={false}
            />
        </TableGiftCardDetailStyled>
    );

    return (
        <TableGiftCardDetailStyled>
            <Table
                columns={columns}
                dataSource={data}
                rowKey={'id'}
            />
        </TableGiftCardDetailStyled>
    );
};

export default TableGiftCardDetail;
const TableGiftCardDetailStyled = styled.div`
  padding: 0 1.5rem;
  margin-top: 1rem;
`;
const EllipsisText = styled(Typography.Text)`
  width: 6rem;
  color: var(--text-text-3, #1d2129);
  text-align: center;
  font-family: Open Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
