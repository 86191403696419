import { Row, Tabs } from 'antd';
import Modal from 'components/Modal';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import appointmentActions from 'features/appointment/services/actions';
import appointmentSelectors from 'features/appointment/services/selectors';
import moment, { Moment } from 'moment';
import { useEffect, useMemo, useState, useTransition } from 'react';
import { ICustomerItemData } from 'services/shop/types/customer';
import { IStaffItemData } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import NewAppointmentConfirm from './ConfirmModal';
import CustomerTab from './CustomerTab';
import ServicesTab from './ServicesTab';
import StaffTab from './StaffTab';
import TimeTab from './TimeTab';
import { useTimeSlotRef } from './TimeTab/TimeSlotPicker';
import { APPOINTMENT_TABS } from './helpers';

const NewAppointmentV2 = () => {
  const timeSlotRef = useTimeSlotRef();
  const dispatch = useAppDispatch();
  const data = appointmentSelectors.getNewAppointmentDraftData();

  const [visibleConfirm, setVisibleConfirm] = useState(false);
  const [tab, setTab] = useState<APPOINTMENT_TABS>(APPOINTMENT_TABS.CUSTOMER);

  const [timeSlot, setTimeSlot] = useState<Moment | null>(null);
  const [staff, setStaff] = useState<IStaffItemData | null>(null);
  const services = appointmentSelectors.newAptState.services();
  const [customer, setCustomer] = useState<ICustomerItemData | null>(null);

  const [, startTransition] = useTransition();

  const staffList = appointmentSelectors.getStaffList();

  useEffect(() => {
    if (!data) return;
    startTransition(() => {
      if (data.newApt) {
        timeSlotRef.current?.getSlots(data?.colTime ? moment(data.colTime) : null);
      } else {
        setTimeSlot(data?.colTime ? moment(data.colTime) : null);
      }
      const staffData = staffList.find(o => o.id === data.staff?.id);
      setStaff(staffData || null);
      setTab(APPOINTMENT_TABS.CUSTOMER);
      dispatch(appointmentActions.newApt.reset());
    });
  }, [data]);

  const reset = () => {
    startTransition(() => {
      setCustomer(null);
      setStaff(null);
      setTimeSlot(null);
      setVisibleConfirm(false);
      setTab(APPOINTMENT_TABS.STAFF);
    });
  };

  const handleDoneConfirm = () => {
    reset();
    dispatch(appointmentActions.setNewAppointmentDraftData(null));
  };
  const tabs = useMemo(() => {


    const handleBack = (currentTab: APPOINTMENT_TABS) => () => {
      switch (currentTab) {
        case APPOINTMENT_TABS.SERVICES:
          return setTab(APPOINTMENT_TABS.CUSTOMER);
        case APPOINTMENT_TABS.STAFF:
          return setTab(APPOINTMENT_TABS.SERVICES);
        case APPOINTMENT_TABS.TIME:
          return setTab(APPOINTMENT_TABS.STAFF);
        default:
          break;
      }
    };
    const handleNext = (currentTab: APPOINTMENT_TABS) => () => {
      switch (currentTab) {
        case APPOINTMENT_TABS.CUSTOMER:
          return setTab(APPOINTMENT_TABS.SERVICES);
        case APPOINTMENT_TABS.SERVICES: {
          if (services?.length === 0) return;
          if (staff && timeSlot)
            return setVisibleConfirm(true);
          if (staff)
            return setTab(APPOINTMENT_TABS.TIME);
          return setTab(APPOINTMENT_TABS.STAFF);
        }
        case APPOINTMENT_TABS.STAFF: {
          return setTab(APPOINTMENT_TABS.TIME);
        }
        case APPOINTMENT_TABS.TIME: {
          return setVisibleConfirm(true);
        }
        default:
          break;
      }
    };

    return (
      [
        {
          label: <LabelTab label='Customer' valid={!!customer} />,
          key: APPOINTMENT_TABS.CUSTOMER,
          children: (
            <CustomerTab
              customer={customer}
              setCustomer={setCustomer}
              next={handleNext(APPOINTMENT_TABS.CUSTOMER)}
              onClose={handleDoneConfirm}
            />
          ),
        },
        {
          label: <LabelTab label='Services' valid={!!services.length} />,
          key: APPOINTMENT_TABS.SERVICES,
          children: (
            <ServicesTab
              back={handleBack(APPOINTMENT_TABS.SERVICES)}
              next={handleNext(APPOINTMENT_TABS.SERVICES)}
            />
          ),
          disabled: !staff || !customer,
        },
        {
          label: <LabelTab label='Staff' valid={!!staff} />,
          key: APPOINTMENT_TABS.STAFF,
          children: <StaffTab
            staff={staff}
            onPickStaff={(_staff) => {
              setStaff(_staff);
              setTimeSlot(null);
            }}
            back={handleBack(APPOINTMENT_TABS.STAFF)}
            next={handleNext(APPOINTMENT_TABS.STAFF)}
          />,
          disabled: !customer
        },

        {
          label: <LabelTab label='Time' valid={!!timeSlot} />,
          key: APPOINTMENT_TABS.TIME,
          children: <TimeTab
            timeSlotRef={timeSlotRef}
            staffId={staff?.id || ''}
            timeSlot={timeSlot}
            setTimeSlot={setTimeSlot}
            next={handleNext(APPOINTMENT_TABS.TIME)}
            back={handleBack(APPOINTMENT_TABS.TIME)}
          />,
          disabled: !staff || !customer || !services.length,
        },
      ]
    );
  }, [staff, timeSlot, services, customer, data]);

  return (
    <>
      {!!data && <Modal
        headerBgColor={colorTheme.info_bg_3}
        visible={!!data && !visibleConfirm}
        noneBodyStyle
        noneFooterStyle
        containerPadding={1.5}
        modalTitle='NEW APPOINTMENT'
        className='modal-add-new-appointment modal-max-height-unset'
        width={'70vw'}
        footer={<></>}
        hiddenFooter
        onClose={handleDoneConfirm}
        showClose
      >
        <NewAppointmentV2Styled>
          <Tabs
            activeKey={tab}
            onChange={val => setTab(val as APPOINTMENT_TABS)}
            type="card"
            // @ts-ignore
            items={tabs}
          />
        </NewAppointmentV2Styled>
      </Modal>}
      {
        timeSlot && customer && staff && (
          <NewAppointmentConfirm
            visible={visibleConfirm}
            timeStart={timeSlot}
            customer={customer}
            services={services}
            onClose={() => setVisibleConfirm(false)}
            staff={staff}
            onDone={handleDoneConfirm}
          />
        )
      }
    </>
  );
};

export default NewAppointmentV2;
const NewAppointmentV2Styled = styled.div`

  .section-label {
    color: var(--text-text-2, #767676);
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .ant-tabs-nav-list {
    width: 100%;
    border-radius: 5px;
    border: 0.5px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-3, #E5E5E5);
    gap: 8px;
    padding: 8px;
    
    .ant-tabs-tab {
      flex:1;
      border: none;
      .ant-tabs-tab-btn {
        width: 100%;
        text-align: center;
        color: var(--text-text-3, #1D2129);
        font-family: Open Sans;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        -webkit-user-select: none;
        /* Safari */
        -ms-user-select: none;
        /* IE 10 and IE 11 */
        user-select: none;
        /* Standard syntax */
        .row-label-tab {
          gap: 4px;
          justify-content: center;
        }
        .label-tab {
          color: var(--text-text-3, #1D2129);
          font-family: Open Sans;
          font-size: 1.125rem;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
           &.valid {
            color: var(--info-infor-3, #6FABB6);
           }
        }
      }
      &.ant-tabs-tab-active {
        border-radius: 5px;
        background: var(--fill-fill-2, #ADADAD);
        .tick-valid-icon {
          path {
            fill: #fff;  
          }
        }
        .ant-tabs-tab-btn {
          color: #fff;
          .label-tab {
            &.valid {
              color: #fff;
             }
          }
        }
      }
    }
  }
`;

const LabelTab = ({ label, valid = false }: { label: string, valid?: boolean }) => {
  if (!valid) {
    return (
      <Row align={'middle'} className='row-label-tab'>
        <Text className='label-tab'>{label}</Text>
      </Row>
    );
  }

  return (
    <Row align={'middle'} className='row-label-tab'>
      <Text className='label-tab valid'>{label}</Text>
      <TickValidIcon />
    </Row>
  );
};

const TickValidIcon = () => (
  <svg className='tick-valid-icon' xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10" fill="none">
    <path d="M5.38605 10C5.10591 10 4.83978 9.88839 4.64368 9.69306L0.679651 5.74468C0.27345 5.34008 0.27345 4.67039 0.679651 4.26578C1.08585 3.86118 1.75819 3.86118 2.16439 4.26578L5.38605 7.47471L12.5856 0.303453C12.9918 -0.101151 13.6641 -0.101151 14.0703 0.303453C14.4766 0.708057 14.4766 1.37775 14.0703 1.78235L6.12842 9.69306C5.93232 9.88839 5.66619 10 5.38605 10Z" fill="#6FABB6" />
  </svg>
);