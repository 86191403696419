import clsx from 'clsx';
import Box from 'components/Box';
import { TURN_ITEM_TYPE, turnTypeColorSets } from 'features/turn/services/constants';
import React from 'react';
import styled from 'styled-components';
type Props = {
  label?: string;
  statusBox?: boolean;
  active?: boolean;
  type?: keyof typeof TURN_ITEM_TYPE;
  onClick?: () => void;
  disabled?: boolean;
};
const TurnBox = ({ disabled, label, statusBox, type, active, onClick }: Props) => {
  return (
    <Container type='button' disabled={disabled} onClick={onClick} className={clsx(type, statusBox && 'statusBox', active && 'active')}>
      <div className='box'>
        <div className='child-box'>
          <span>{label}</span>
        </div>
      </div>
      {active ? <div className='icon-active'>{iconCheck}</div> : null}
    </Container>
  );
};

export default TurnBox;
const Container = styled.button`
  flex: 1;
  display: flex;
  height: 64px;
  border-radius: 5px;
  background: #fff;
  padding: 1px;
  border: 1px solid transparent;
  position: relative;
  &:not(.active):disabled {
    opacity: 0.5;
  }
  .icon-active {
    position: absolute;
    top: -8px;
    right: -8px;
  }

  span {
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    text-transform: uppercase;
  }

  .box {
    flex: 1;
    background: #fff;
    border-radius: 5px;
    align-self: stretch;
    display: flex;
    padding: 2px;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
  }
  .child-box {
    flex: 1;
    border-radius: 5px;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  ${Object.entries(turnTypeColorSets).map(([key, { bg }]) => `&.${key} {
      border: 1px solid ${bg};
      background: ${bg};
      .box {
        border: 2px solid ${bg};
        background: ${bg};
      }
      .box .child-box {
        background: ${bg};
      }
    }`)
  }
  &.statusBox {
    .box .child-box {
      background: #C52884;
      span {
        color: #fff;
      }
    }
  }

  ${Object.entries(turnTypeColorSets).map(([key, { stroke }]) => `&.statusBox.${key} {
      border: 1px solid ${stroke};
      background: ${stroke};
      .box {
        border: 2px solid ${stroke};
        background: ${stroke};
      }
      .box .child-box {
        background: ${stroke};
      }
    }`)
  }
  ${Object.entries(turnTypeColorSets).map(([key, { bg, stroke }]) => `&.active.${key} {
      border: 1px solid ${stroke};
      background: #fff;
      .box {
        border: 2px solid ${stroke};
        background: #fff;
      }
      .box .child-box {
        background: ${bg};
      }
      &.statusBox .box .child-box {
        background: ${stroke};
      }
    }`)
  }
`;
const iconCheck = <Box display='flex' alignItems='center' justifyContent='center' style={{ borderRadius: 25, width: 25, height: 25, background: '#00AD45' }}>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M6.48494 12C6.2715 12 6.06873 11.9107 5.91932 11.7544L2.89911 8.59574C2.58962 8.27206 2.58962 7.73631 2.89911 7.41263C3.20859 7.08894 3.72085 7.08894 4.03033 7.41263L6.48494 9.97977L11.9703 4.24276C12.2798 3.91908 12.7921 3.91908 13.1015 4.24276C13.411 4.56645 13.411 5.1022 13.1015 5.42588L7.05055 11.7544C6.90114 11.9107 6.69838 12 6.48494 12Z" fill="white" />
  </svg>
</Box>;
