import { get } from 'lodash';
import { Message } from 'stompjs';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ICustomerReceiveConfigs } from './types/socketPayment';

export type MyState = RootState['multiplePayment'];
export const getCurrentState = (state: RootState): MyState => state['multiplePayment'];

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getVisibleModalPoint = () => selector('visibleModalPoint') as MyState['visibleModalPoint'];
const getVisibleModalGiftCard = () => selector('visibleModalGiftCard') as MyState['visibleModalGiftCard'];
const getVisibleCheckPayment = () => selector('visibleCheckFormInputs') as MyState['visibleCheckFormInputs'];
const getVisibleModalFormCheck = () => selector('visibleModalCheckInfo') as MyState['visibleModalCheckInfo'];

const getDetail = () => selector('detail') as MyState['detail'];

const getCustomerInfo = () => selector('customerInfo') as MyState['customerInfo'];

const getDetailPayment = () => selector('multiPayment') as MyState['multiPayment'];

const getActivePayment = () => selector('activePayment') as MyState['activePayment'];

const getDraftDetailFormCheckSignature = () => selector('draftCheckSignature') as MyState['draftCheckSignature'];
const getDraftFormCheckInfo = () => selector('draftCheckInfo') as MyState['draftCheckInfo'];

const getCashierSocketMsg = () => selector('cashierSocketMsg') as Message | null;

const customerSide = {
  getBillId: () => selector('customerSide.billId') as string | null,
  getVisibleAddATip: () => selector('customerSide.visibleAddATip') as boolean,
  getMessage: () => selector('customerSide.message') as Message | null,
  getReceiveConfigs: () => selector('customerSide.receiveConfigs') as ICustomerReceiveConfigs | null,
};

const multiplePaymentSelectors = {
  getVisibleModalPoint,
  getVisibleModalGiftCard,
  getVisibleCheckPayment,
  getVisibleModalFormCheck,
  getDetail,
  getCustomerInfo,
  getDetailPayment,
  getActivePayment,
  getDraftDetailFormCheckSignature,
  getDraftFormCheckInfo,
  customerSide,
  getCashierSocketMsg,
};
export default multiplePaymentSelectors;
