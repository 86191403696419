import clsx from 'clsx';
import Box from 'components/Box';
import Text from 'components/Text';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IBillDetailData | null
  v2?: boolean;
};
const DescribePayment = ({ data, v2 }: Props) => {
  const describePayment = useMemo(() => {
    // const creditPayment = find(data?.payments, o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD);
    return [
      { name: 'Credit Fee', price: data?.cardFee || 0 },
      { name: 'Sale Tax:', price: data?.saleTax || 0 },
      { name: 'Use Tax:', price: data?.useTax || 0 },
      { name: 'Disc. By Item:', price: (data?.discountItem || 0) },
      { name: 'Disc. By Ticket:', price: (data?.discountTicket || 0) },
      { name: 'Tip:', price: data?.tip },
    ].filter(o => !!o);
  }, [data]);

  return (
    <Container className={clsx(v2 && 'v2')}>
      <Box display="flex" flexDirection="column" px="4" mb='1'>
        {!v2 ? <Text mt={0.5} mb={0.5} className="divider" /> : <Text mt={0.5} mb={0.5} />}
        <Box className="space-between sub-total">
          <Text variant="BODY_1" color="text_3" className='sub-total-label'>
            Subtotal:
          </Text>
          <Text variant="BODY_1" color="text_3" style={{ fontWeight: '600' }} className='sub-total-value'>
            {formatCurrency(data?.subTotal)}
          </Text>
        </Box>
        <Text mt={0.5} mb={0.5} className="divider" />
        {describePayment.map((item) => {
          return (
            <Box className="space-between describePayment" key={item?.name}>
              <Text variant="BODY_1" color="text_3" mb={0.5} className='label'>
                {item?.name}
              </Text>
              <Text variant="BODY_1" color="text_3" mb={0.5} className='value`'>
                {formatCurrency(item?.price)}
              </Text>
            </Box>
          );
        })}
      </Box>
      <Box className='total'>
        <Box p="2" px='4' className="space-between container-total" bgColor="fill_3">
          <Text variant="H6" color="text_3" className='total-label'>
            Total
          </Text>
          <Text variant="H5" color="text_3" className='total-value'>
            {formatCurrency(data?.total)}
          </Text>
        </Box>
      </Box>
    </Container>

  );
};

export default memo(DescribePayment);

const Container = styled.div`
&.v2 {
  .sub-total {
    .sub-total-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .sub-total-value {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .describePayment {
    .label, .value {
    font-size: 20px;
    }
  }
  .total {
    .container-total {
      background: #fff;
    }
    .total-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .total-value {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
`;
