import { Result } from 'antd';
import Button from 'components/Button';
import React from 'react';
import styled from 'styled-components';

const NetworkDetect = () => {
  const [status, setStatus] = React.useState(true);

  const handleRefresh = () => {
    window.location.reload();
  };

  React.useEffect(() => {
    function changeStatus() {
      setStatus(navigator.onLine);
    }

    window.addEventListener('offline', changeStatus);
    return () => {
      window.removeEventListener('offline', changeStatus);
    };
  }, []);

  if (status) return null;

  return (
    <NetworkMessageStyled>
      <Result
        status="warning"
        title="Connect to the internet"
        subTitle="Your internet currently offline!"
        extra={
          <Button type="primary" size='small' key="console" onClick={handleRefresh}>
            RE-CONNECT
          </Button>
        }
      />
    </NetworkMessageStyled>
  );
};

export default NetworkDetect;

const NetworkMessageStyled = styled.div`
    position: fixed;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    box-shadow: 0px 0px 8px 0px #ff7d00bf;
    border-radius: 8px;
    width: 25rem;
    .ant-result {
      padding: 1rem;
      .ant-result-subtitle  {
        color: #ff7d00;
        font-size: 20px;
        line-height: 1.5;
        text-align: center;
      }
    }
    .ant-result-icon {
      margin-bottom: 0;
    }
    .ant-result .ant-result-icon>.anticon {
      font-size: 60px;
    }
    .ant-result-title {
      margin-bottom: 0;
      font-size: 18px;
    }
    .ant-result-extra {
      margin: 0;
      margin-top: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        width: unset;
      }
    }
`;