import NumberKeyBoardButton from 'components/NumberKeyBoardButton';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { IShopAllSetting } from 'services/shop/types/setting';
import { useAppDispatch } from 'store/hooks';
type Props = {
  v2?: boolean;
  allSetting: IShopAllSetting | null;
};
const ButtonTip = ({ v2, allSetting }: Props) => {
  const dispatch = useAppDispatch();
  const detailPayment = cashierSelectors.getPaymentInfo();
  const onChange = async (val: number) => {
    dispatch(cashierActions.setTip(val));
    return true;
  };

  return (
    <>
      <NumberKeyBoardButton
        btnTitle="Tip"
        activeFormatMoney={allSetting?.activeFormatMoney}
        ntype="LIGHT_BLUE"
        icon={v2 ? 'tipBtnV2' : 'tip'}
        modalTitle="TIP"
        onSubmit={onChange}
        defaultValue={detailPayment.tip}
        verticalButton
        v2={v2}
      />
    </>
  );
};

export default ButtonTip;
