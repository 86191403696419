import React, { useEffect } from 'react';
import CheckInInputPageStyled from './styles';
import BackgroundLeft from './components/BackgroundLeft';
import SignInForm from './components/SignInFrom';
import checkInActions from 'features/checkIn/services/actions';
import { useAppDispatch } from 'store/hooks';

type ICheckInInputPageProps = any;
const CheckInInputPage: React.FC<ICheckInInputPageProps> = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(checkInActions.setInitCheckIn());
  }, []);
  return (
    <CheckInInputPageStyled>
      <BackgroundLeft></BackgroundLeft>
      <SignInForm></SignInForm>
    </CheckInInputPageStyled>
  );
};

export default CheckInInputPage;
