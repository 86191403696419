import BoundingBox from 'components/BoundingBox';
import Text from 'components/Text';
import React from 'react';
import { styled } from 'styled-components';
import Categories from './Categories';

interface IServicePartProps { }
const CategoryPart: React.FC<IServicePartProps> = () => {
  return (
    <ServicePartStyled className="container">
      <BoundingBox className="category-bounding-box" color="primary_bg">
        <Text variant="H7">CATEGORIES</Text>
      </BoundingBox>
      <div className="cate-container">
        <div className="scrollDiv">
          <Categories />
        </div>
      </div>
    </ServicePartStyled>
  );
};

export default CategoryPart;

const ServicePartStyled = styled.div`
  &.container {
    width: 35vw;
    position: relative;
  }

  .cate-container {
    display: flex;
    flex-direction: column;
    row-gap: 2rem;
    position: absolute;
    bottom: 0;
    top: 4.5rem;
    right: 0;
    left: 0;
    padding: 5px 0px;
    .scrollDiv {
      padding: 2.5rem;
      overflow: auto;
      max-height: 87%;
    }
  }

  .category-bounding-box {
    width: 100%;
    height: 4.5rem;
    display: flex;
    align-items: center;
  }
  .category-bounding-box .H7 {
    margin-left: 2.5rem;
  }
`;
