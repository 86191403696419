import React from 'react';
import point_icon from './point.png';
import gift_card_icon from './gift-card.png';
import e_pay_icon from './e-pay.png';
import cash_icon from './cash.png';
import credit_card_icon from './credit-card.png';
import { get } from 'lodash';
import styled from 'styled-components';
const PAYMENT_KEYS = {
  POINT: 'POINT',
  GIFT_CARD: 'GIFT_CARD',
  E_PAY: 'E_PAY',
  CASH: 'CASH',
  CREDIT_CARD: 'CREDIT_CARD',
};
export type MULTIPLE_PAYMENT_KEYS = keyof typeof PAYMENT_KEYS;
const configs = {
  [PAYMENT_KEYS.POINT]: {
    label: 'Point',
    icon: point_icon,
  },
  [PAYMENT_KEYS.GIFT_CARD]: {
    label: 'Gift Card',
    icon: gift_card_icon,
  },
  [PAYMENT_KEYS.E_PAY]: {
    label: 'E-Pays',
    icon: e_pay_icon,
  },
  [PAYMENT_KEYS.CASH]: {
    label: 'Cash',
    icon: cash_icon,
  },
  [PAYMENT_KEYS.CREDIT_CARD]: {
    label: 'Card',
    icon: credit_card_icon,
  },
};

type Props = {
  type: MULTIPLE_PAYMENT_KEYS;
  disabled?: boolean;
  onClick?: () => void;
};
const CardBoxV2 = ({ type, disabled, onClick }: Props) => {
  const config = get(configs, PAYMENT_KEYS[type]);

  return (
    <Container type='button' disabled={disabled} onClick={onClick}>
      <img src={config?.icon} alt={type} className="payment-icon" />
      <p className="payment-text">{config?.label || ''}</p>
    </Container>
  );
};

export default CardBoxV2;

const Container = styled.button`
display: flex;
padding: 16px 24px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 4px;
flex: 1 0 0;
background: #fff;
box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
img.payment-icon {
  display: flex;
  width: 60px;
  height: 60px;
  justify-content: center;
  align-items: center;
}
p.payment-text {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 166.667% */
  text-transform: capitalize;
}

&:disabled {
  opacity: 0.7;
  background: #e5e6eb;
}
`;
