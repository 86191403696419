import { DatePicker as AntDatePickerUI, Row } from 'antd';
import Text from 'components/Text';
import { DATE_FORMAT_VIEW } from 'features/appointment/utils/format';
import WeeklyPicker from 'features/report/pages/ReportPage/components/WeeklyPicker';
import moment, { Moment } from 'moment';
import momentGenerateConfig from 'rc-picker/lib/generate/moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React from 'react';
import styled from 'styled-components';
import calendar_icon from './calendar.svg';

type IDatePickerPeriodProps = {
  onChange: (date: Moment | null | RangeValue<Moment>) => void;
  size?: 'middle' | 'large' | 'small';
  picker?: 'date' | 'week' | 'month';
  data?: { startDate: string, endDate: string };
  v2?: boolean;
};
type DatePickerStyledProps = {};
const getStartDate = (date: Moment | null | undefined, v2?: boolean) => {
  if (!v2) return date?.format(DATE_FORMAT_VIEW);
  const now = moment();
  if (date?.isSame(now, 'date')) {
    return `Today, ${date?.format('MM-DD-YYYY')}`;
  }
  if (date?.isSame(now.subtract(1, 'day'), 'date')) {
    return `Yesterday, ${date?.format('MM-DD-YYYY')}`;
  }
  return date?.format('ddd, MM-DD-YYYY');
};
const AntDatePicker =
  AntDatePickerUI.generatePicker<Moment>(momentGenerateConfig);

const DatePickerPeriodPure: React.FC<IDatePickerPeriodProps> = ({
  onChange,
  size = 'middle',
  picker = 'date',
  data,
  v2,
}) => {
  const date = moment(data?.startDate);
  const getDisplayValue = () => {
    if (!date || !date.isValid()) {
      return '';
    }

    if (picker === 'week') {
      return `${data?.startDate} - ${data?.endDate}`;
    }

    if (picker === 'month') {
      return date.format(v2 ? 'MMMM, YYYY' : 'MM-YYYY');
    }

    return getStartDate(date, v2);
  };

  return (
    <DatePickerPeriodStyled>
      {picker == 'week' ? (
        <WeeklyPicker
          onSubmit={onChange}
          v2={v2}
          defaultValue={[
            moment(data?.startDate),
            moment(data?.endDate),
          ]}
        />
      ) : (
        <DatePickerStyled
          size={size}
          picker={picker}
          value={date && date.isValid() ? date : null}
          onChange={onChange}
          inputReadOnly
          clearIcon={false}
          suffixIcon={v2 ? false : undefined}
          className={v2 ? 'v2' : ''}
          popupClassName={v2 ? 'zoom-big-v2' : ''}
          inputRender={(props: any) => (
            <InputUI {...props} className={`${props.className} InputUI`} justify={'space-between'} align={'middle'} >
              {!v2 && <Text variant='CONTENT_2' color='text_2' mr={2}>Time Period:</Text>}
              <Text variant='CONTENT_2' color='text_3' >{getDisplayValue()}</Text>
              {v2 && <img src={calendar_icon} className='calendar_icon' style={{ width: 32, height: 32 }} />}
            </InputUI>
          )}
        />
      )}
    </DatePickerPeriodStyled>
  );
};

export default DatePickerPeriodPure;

const DatePickerPeriodStyled = styled.div``;

const DatePickerStyled = styled(AntDatePicker) <DatePickerStyledProps>`
  // min-width: 20rem;
  width: 100%;


  &.v2 {
    min-width: unset;
    border: none;
    background: transparent;
    .InputUI {
        gap: 16px;
        .CONTENT_2 {
            color: #1D2129;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            text-transform: capitalize;
        }
    }
  }
`;
const InputUI = styled(Row)`
  flex: 1;
  padding: 0.5rem;
  margin-right: 0.5rem;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;
