import { createSlice } from '@reduxjs/toolkit';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment from 'moment';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';

const initialState: IState = {
  viewType: {
    DEFAULT: CALENDAR_VIEW_TYPE.DAY_VIEW
  },
  params: {
    DEFAULT: {
      startTime: moment().format(DATE_FORMAT),
      endTime: moment().format(DATE_FORMAT),
    }
  },
  listGiftCard: [],
  currentPage: 0,
  totalPages: 0,
  totalElements: 0,
  giftCardDetail: {
    giftCard: {
      updatedDate: '',
      updatedBy: '',
      createdDate: '',
      createdBy: '',
      id: '',
      shopId: '',
      customerId: '',
      customerName: '',
      customerPhone: '',
      staffId: '',
      staffName: '',
      giftCode: '',
      amount: 0,
      usedAmount: 0,
      sellDate: '',
      status: false,
      numberReused: 0,
      buyOnline: false,
      urlImageQrCode: '',
      expireDate: ''
    },
    historyTracking: []
  },
  resetTimeGiftCardStatus: false,
  giftCardSummary: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(actions.getSummaryGiftCards.success, (state, { payload }) => {
        state.giftCardSummary = payload;
      })
      .addCase(actions.getSummaryGiftCards.fail, state => {
        state.giftCardSummary = null;
      })
      .addCase(actions.setParams, (state, { payload }) => {
        const _params = {
          ...state.params,
          ...payload
        };
        state.params = _params;
      })
      .addCase(actions.setCalendarViewType, (state, { payload }) => {
        state.viewType = {
          ...state.viewType,
          ...payload
        };
      })
      .addCase(actions.getListGiftCard.success, (state, { payload }) => {
        state.listGiftCard = payload['content'];
        state.currentPage = payload['number'];
        state.totalPages = payload['totalPages'];
        state.totalElements = payload['totalElements'];
      })
      .addCase(actions.getGiftCardDetail.success, (state, { payload }) => {
        state.giftCardDetail = payload;
      })
      .addCase(actions.resetGiftCardDetail.success, (state, { payload }) => {
        state.resetTimeGiftCardStatus = true;
        if (state?.giftCardDetail?.giftCard?.numberReused) {
          state.giftCardDetail.giftCard.numberReused = (state.giftCardDetail.giftCard.numberReused ?? 0) + 1;
        }
        state.listGiftCard = [...(state.listGiftCard.map(m => {
          if (payload.some((s: string) => s == m.id)) {
            return { ...m, numberReused: (m.numberReused ?? 0) + 1 };
          } else {
            return m;
          }
        }
        ))];
      })
      .addCase(actions.resetGiftCardDetail.fail, (state) => {
        state.resetTimeGiftCardStatus = false;
      })
      ;
  },
});

/** TODO:
 * please replace giftCards => your feature name
 * ex:
 *      my feature is booking 
 *  name sagas like
 *      giftCardsServiceReducer => bookingServiceReducers
 * 
 * // TODO:
 * Move to rootReducer (src/store/root/reducers.ts) and import your feature reducer
 */

const giftCardsServiceReducer = Slice.reducer;
export default giftCardsServiceReducer;
