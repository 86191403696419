import { Form } from 'antd';
import { FormInstance } from 'antd/lib';
import Text from 'components/Text';
import { PeriodValues } from 'features/appointment/components/InputPeriod';
import appointmentActions from 'features/appointment/services/actions';
import appointmentApis from 'features/appointment/services/apis';
import { APPOINTMENT_STATUS } from 'features/appointment/services/constants';
import { IBodyApiUpdateAppointmentDetail } from 'features/appointment/services/types/api';
import { IAppointmentItemData, IAppointmentServiceItem, IDraftAppointmentData } from 'features/appointment/services/types/appointment';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment, { Moment } from 'moment';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { IBodyApiUpdateStatusApt, IResponseDataBody } from 'services/response';
import { IStaffItemData } from 'services/shop/types/staff';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
import uuid from 'utils/uuid';
import RequestAdminPWCallback, { useRequestAdminPWCallbackRef } from 'widgets/RequestAdminLocalPW/Callback';
import ModalChooseStaffRef, { useModalChooseStaffRef } from 'widgets/Staff/ModalChooseStaffRef';
import ModalChooseService, { useModalChooseServiceRef } from '../../ModalChooseService';
import CalendarPicker from '../../QuickBooking/CalendarPicker';
import StartTimes, { useStartTimesRef } from '../../QuickBooking/StartTimes';
import CustomerInfo from './CustomerInfo';
import Footer from './Footer';
import InputGroup from './InputGroup';
import InputNote from './InputNote';
import ModalInputReason, { useModalInputReasonRef } from './ModalReason';
import Repeat from './Repeat';
import ServicesStaff from './ServicesStaff';
import StaffInfo from './StaffInfo';

export interface IFormAppointmentUpdateDetailValues {
  staff: IStaffItemData | null;
  sourceType: string;
  customer: {
    name: string | null;
    phone: string | null;
    point: string;
  };
  repeat: PeriodValues;
  services: IAppointmentServiceItem[];
  group: number;
  note: string;
  date: Moment;
  'time-slots': string[];
  startTime: string;
}
type Props = {
  visible?: boolean;
  onClose?: () => void;
  data?: IDraftAppointmentData | null;
  form: FormInstance<any>;
};

type Ref = { init: () => void };

export const useAppointmentDetailModalV2Ref = () => useRef<Ref>(null);

const AppointmentDetailModalV2 = forwardRef<Ref, Props>(({ form, onClose = () => undefined, data }, ref) => {
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const addRef = useModalChooseStaffRef();
  const serviceRef = useModalChooseServiceRef();
  const startTimesRef = useStartTimesRef();
  const pinCodeRef = useRequestAdminPWCallbackRef();
  const reasonRef = useModalInputReasonRef();

  const onPickDate = async (value: Moment) => {
    if (!value) return;
    const startDate: string = form.getFieldValue('startTime');
    form.setFieldValue('time-slots', []);
    const staffId = form.getFieldValue('staff')?.id || '';
    startTimesRef.current?.setLoading(true);
    try {
      const resData: IResponseDataBody<string[]> = await appointmentApis.getStaffTimeSlot(staffId || '', value?.format(DATE_FORMAT) || '');
      const list = resData.data.data;

      if (list) {
        const data: string[] = list.map(d => moment(d, 'HH:mm').format('hh:mm A'));
        form.setFieldValue('time-slots', data);
        if (startDate) {
          form.setFieldValue('startTime', data.includes(startDate) ? startDate : '');
        }
      } else {
        form.setFieldValue('time-slots', []);
      }
    } catch (error) { }
    finally {
      startTimesRef.current?.setLoading(false);
    }
  };

  const onChangeStaff = (currentStaffId: string, onChange: (staffId: string) => void) => {
    addRef.current?.open((staff) => {
      onChange(staff.id);
    }, [currentStaffId]);
  };

  const onAddService = (add: (value?: IAppointmentServiceItem) => void) => addRef.current?.open((staff) => serviceRef.current?.chooseService((service) => add({
    appointmentDetailId: '',
    duration: service.duration,
    price: service.priceSell || 0,
    serviceId: service.id,
    serviceName: service.serviceName,
    staffId: staff.id,
    uuid_local: uuid(),
  })));

  const onDelete = () => pinCodeRef.current?.open(async () => {
    const extra = data?.data?.data as IAppointmentItemData;
    if (!extra?.appointmentId) return;

    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.deleteAppointment(extra?.appointmentId);
      if (res.data.data) {
        dispatch(appointmentActions.deleteAppointment.success(res.data.data));
        onClose();
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  });

  const updateStatus = async (status: APPOINTMENT_STATUS, reason?: string) => {
    const extra = data?.data?.data as IAppointmentItemData;
    const appointmentId = extra?.appointmentId;
    if (!appointmentId) return;

    setLoadingPage(true);
    const body: IBodyApiUpdateStatusApt = {
      appointmentId,
      reason: reason || '',
      status,
    };
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.updateStatusApt(body);
      if (res.data.data) {
        dispatch(appointmentActions.deleteAppointment.success(res.data.data));
        onClose();
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const onNoShow = () => reasonRef.current?.open((reason) => updateStatus(APPOINTMENT_STATUS.NO_SHOW, reason));

  const onDecline = () => reasonRef.current?.open((reason) => updateStatus(APPOINTMENT_STATUS.CANCEL, reason));

  const onConfirm = () => updateStatus(APPOINTMENT_STATUS.CONFIRM);

  const onUpdate = () => form.submit();

  const handleUpdateAppointment = async (values: any) => {
    const extra = data?.data?.data as IAppointmentItemData;
    const appointmentId = extra?.appointmentId;
    if (!appointmentId) return;
    const formValues = values as IFormAppointmentUpdateDetailValues;
    if (!formValues) return;
    const _group = +formValues.group;
    const startTime = formValues.startTime ? moment(`${formValues.date.format('MM/DD/YYYY')} ${formValues.startTime}`, 'MM/DD/YYYY HH:mm A').format('MM/DD/YYYY HH:mm:ss') : momentTimezone(extra?.startTime).format('MM/DD/YYYY HH:mm:ss');
    const body: IBodyApiUpdateAppointmentDetail = {
      appointmentId,
      repeat: formValues.repeat,
      startTime,
      staffServices: formValues.services.map(o => ({
        appointmentDetailId: o.appointmentDetailId,
        serviceId: o.serviceId,
        staffId: o.staffId || '',
      })),
      group: (_group > 0) ? _group : null,
      note: formValues.note,
    };

    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await appointmentApis.updateDetailAppointment(body);
      if (res.data.data) {
        dispatch(appointmentActions.deleteAppointment.success(res.data.data));
        onClose();
      }
    } catch (error) { } finally {
      setLoadingPage(false);
    }
  };

  const StatusBox = () => {
    const extra = data?.data?.data as IAppointmentItemData;
    switch (extra?.status) {
      case APPOINTMENT_STATUS.NO_SHOW:
        return (
          <div className="status-box no-show">
            <div className="circle-shape" />
            <span>No Show</span>
          </div>
        );
      case APPOINTMENT_STATUS.DELETE:
      case APPOINTMENT_STATUS.CANCEL:
        return (
          <div className="status-box cancel">
            <div className="circle-shape" />
            <span>Cancel</span>
          </div>
        );
      case APPOINTMENT_STATUS.CONFIRM:
        return (
          <div className="status-box confirm">
            <div className="circle-shape" />
            <span>Confirm</span>
          </div>
        );

      default:
        return (
          <div className="status-box">
            <div className="circle-shape" />
            <span>Appointment</span>
          </div>
        );
    }
  };

  useImperativeHandle(ref, () => ({
    init: () => {
      form.resetFields(['visibleCusPhone']);
      onPickDate(form.getFieldValue('date'));
    },
  }));

  return (
    <>
      <Container form={form} initialValues={{ repeat: PeriodValues.NO_REPEAT, visibleCusPhone: false }} onFinish={handleUpdateAppointment}>
        <HeaderModal>
          <Form.Item name={'staff'} noStyle><StaffInfo /></Form.Item>
          <div className="modal-title">Appointment</div>
          <div className="status-container">
            <StatusBox />
          </div>
        </HeaderModal>
        <BodyModal>
          <BodyAptDetail>
            <Form.Item name={'customer'} noStyle><CustomerInfo /></Form.Item>
            <Form.Item noStyle name={'repeat'}>
              <Repeat />
            </Form.Item>
            <ServicesStaff
              onChangeStaff={onChangeStaff}
              onAddService={onAddService}
            />
            <Form.Item noStyle name={'group'}>
              <InputGroup />
            </Form.Item>
            <Form.Item noStyle name={'note'}>
              <InputNote />
            </Form.Item>
          </BodyAptDetail>
          <TimeContainer>
            <Form.Item noStyle name={'date'}>
              <CalendarPicker onPickDate={onPickDate} />
            </Form.Item>
            <Text variant='H9' color='text_3' className='text-lg'>Start Time</Text>
            <Form.Item noStyle name={'startTime'}>
              <StartTimes detailApt ref={startTimesRef} />
            </Form.Item>
          </TimeContainer>
        </BodyModal>
        <FooterModal>
          <Form.Item shouldUpdate noStyle>{({ getFieldValue }) => {
            const status = getFieldValue('status');
            const services = getFieldValue('services');
            return <Footer
              status={status}
              onClose={onClose}
              onDelete={onDelete}
              onNoShow={onNoShow}
              onDecline={onDecline}
              onConfirm={onConfirm}
              onUpdate={onUpdate}
              disabledUpdate={!services?.length}
            />;
          }}</Form.Item>
        </FooterModal>
      </Container>
      <ModalChooseStaffRef v2 ref={addRef} />
      <ModalChooseService v2 ref={serviceRef} />
      <RequestAdminPWCallback onlyAdmin v2 ref={pinCodeRef} />
      <ModalInputReason v2 ref={reasonRef} />
    </>
  );
});
AppointmentDetailModalV2.displayName = 'AppointmentDetailModalV2';
export default AppointmentDetailModalV2;


const Container = styled(Form)`
display: flex;
flex-direction: column;
height: 100%;
`;
const FooterModal = styled.div`
position: sticky;
bottom:0;
z-index: 99;
`;
const BodyModal = styled.div`
  display: flex;
  align-items: flex-start;
  flex: 1 0 0;
  align-self: stretch;
  background: #F6F7FC;
  .current-time {
    display: flex;
    padding: 16px 8px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    flex: 1 0 0;
    border-radius: 5px;
    border: 1px solid #CCD4DC;
    background: #F6F7FC;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px; /* 100% */
      letter-spacing: 0.4px;
    }

    border: 1px solid #2D6772;
    background: #C6E9EF;
  }
`;
const BodyAptDetail = styled.div`
display: flex;
flex-direction: column;
align-items: flex-start;
flex: 1 0 0;
align-self: stretch;
background: #FFF;
`;
const TimeContainer = styled.div`
width: 700px;
align-self: stretch;
display: flex;
flex-direction: column;
padding: 16px;
.ant-picker-calendar, .ant-picker-calendar .ant-picker-panel {
  background: transparent;
  .ant-picker-cell-disabled {
    opacity: 0.4;
  }
  .ant-picker-cell-disabled::before {
    background: transparent;
  }
}
`;
const HeaderModal = styled.div`
  border-radius: 5px 5px 0px 0px;
  position: sticky;
  top:0;
  z-index: 99;
  background: #232F3E;
  padding: 12px 16px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  .modal-title {
    color: #FFF;
    background: #232F3E;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  .status-container {
    .status-box {
      display: flex;
      align-items: center;
      gap: 8px;
      .circle-shape {
        display: flex;
        width: 32px;
        height: 32px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #6FABB6;
      }
      span {
        color: #6FABB6;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &.confirm {
        .circle-shape {
          background: #6FE7BF;
        }
        span {
          color: #6FE7BF;
        }
      }
      &.cancel {
        .circle-shape {
          background: #F5767F;
        }
        span {
          color: #F5767F;
        }
      }
      &.no-show {
        .circle-shape {
          background: #E5E6EB;
        }
        span {
          color: #E5E6EB;
        }
      }
    }
  }
`;
