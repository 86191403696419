import React from 'react';
import Header from './components/Header';
import Main from './components/Main';
import ChooseCheckInTypePageStyled from './styles';
type IChooseCheckInTypePageProps = {};
const ChooseCheckInTypePage: React.FC<IChooseCheckInTypePageProps> = () => {
  return (
    <ChooseCheckInTypePageStyled>
      <Header />
      <Main />
    </ChooseCheckInTypePageStyled>
  );
};

export default ChooseCheckInTypePage;
