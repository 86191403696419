import React, { useState } from 'react';
import ItemsDroppable from './Droppable';
import styled from 'styled-components';
import { IService, IStaffItem } from 'features/cashier/services/types/api';
import { IStaffItemData } from 'services/shop/types/staff';
import { Avatar, Col, Row } from 'antd';
import Text from 'components/Text';
import Icon from 'assets/Icons';
import arrowDown from '../ItemsDnD/Icon/down.svg';
import clsx from 'clsx';
import { DiscountType, ITEM_HANDLE_MODE } from 'features/cashier/services/constants';
import ItemsDraggable from './Draggable';
import { IDraftQuickPayItem } from 'features/cashier/services/types/quickpay';
import { IShopAllSetting } from 'services/shop/types/setting';
import { useAppDispatch } from 'store/hooks';
import cashierActions from 'features/cashier/services/actions';
import { formatCurrency } from 'utils/formatCurrency';
export type IPropertyDraftQuickPay = {
  staffId: string, itemId: string, value: number, itemName: string;
}
type Props = {
  v2?: boolean;
  active?: boolean;
  activeDrag?: boolean;
  data: IStaffItem;
  staff: IStaffItemData | undefined;
  onActiveStaff: (data: string) => void;
  setDraffStaffId: (staffId: string) => void;
  onRemoveStaff: (staffId: string) => void;
  draftServiceSwitchStaff: string | null;
  highlightItemId: string | null | undefined;
  enableSwitchStaff: boolean;
  setDraftServiceSwitchStaff: (staffId: string, itemId: string) => void;
  handleUpdateQuickPay: (type: IDraftQuickPayItem['typeDraft'], draft: IPropertyDraftQuickPay) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  itemMode: ITEM_HANDLE_MODE | null;
  allSetting: IShopAllSetting | null;
  onHandleDiscount: (e: React.MouseEvent, staff: IStaffItem, service: IService) => void;
  onSwitchStaff: (staffId: string) => void;
};
const ItemStaffTicket = ({ onSwitchStaff, activeDrag, onHandleDiscount, allSetting, data, active, staff, onActiveStaff, setDraffStaffId, onRemoveStaff, draftServiceSwitchStaff, highlightItemId, enableSwitchStaff, setDraftServiceSwitchStaff, handleUpdateQuickPay, itemMode }: Props) => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(true);
  const visibleSwitchStaff = !!(
    activeDrag &&
    draftServiceSwitchStaff &&
    !draftServiceSwitchStaff.includes(data.staffId)
  );
  return (
    <ItemsDroppable
      id={data.staffId} key={data.staffId}
      className='ItemStaffTicket'
    >
      <BillPanelItemHeader className={active ? 'active-staff' : ''} onClick={(e: any) => [e.stopPropagation(), onActiveStaff(data.staffId)]}>
        <Row align={'middle'} >
          <Avatar size={44} src={staff?.urlImage} alt='avatar' >{data.staffName?.[0] || 'A'}</Avatar>
          <Text ml={0.5} variant="H9" color="text_3" style={{ fontSize: 30, color: '#6FABB6' }}>
            {data.staffName || ''}
          </Text>
          <ButtonSwitchStaffItem onClick={() => setDraffStaffId(data.staffId)} />
        </Row>
        <div style={{ flex: 1 }} />
        {visibleSwitchStaff && <ButtonSwitchStaff onClick={() => onSwitchStaff(data.staffId)} />}
        <ButtonRemoveStaff onClick={() => onRemoveStaff(data.staffId)} />
        <ButtonArrowDown active={activeTab} onClick={() => setActiveTab(!activeTab)} />
      </BillPanelItemHeader>
      <div
        style={!activeTab ? { display: 'none' } : { display: 'flex', flexDirection: 'column', gap: '0.5rem', padding: '0 0.5rem', paddingBottom: '16px' }}
      >
        {data.services.length > 0 ? (
          data.services.map((serviceItem) => {
            const draftSwitchStaffActive = serviceItem?.uuid_local
              ? draftServiceSwitchStaff?.includes(serviceItem?.uuid_local)
              : false;

            const discount = serviceItem.discountType === DiscountType.PERCENT ? ((serviceItem.price ?? 0) * serviceItem.discount / 100) : serviceItem.discount ?? 0;
            const afterDiscountPrice = serviceItem.price - discount;
            const isHighLight = (serviceItem?.uuid_local === highlightItemId) && !draftSwitchStaffActive;

            const draftPropertyDraftQuickPay: IPropertyDraftQuickPay = {
              staffId: data.staffId,
              itemId: serviceItem?.uuid_local ?? '',
              itemName: serviceItem.itemName,
              value: serviceItem.price,
            };

            const keyDnd = serviceItem.uuid_local + '/' + data.staffId;

            return (
              <>
                <ItemCollapseStyled
                  active={draftSwitchStaffActive}
                  enableSwitchStaff={enableSwitchStaff}
                  highlightItemId={isHighLight}
                  key={keyDnd} data={{ uuid: serviceItem.uuid_local, staffId: data.staffId }} id={keyDnd}
                  enableDrag={activeDrag}
                  className='ItemCollapseStyled'
                >
                  <button style={{ width: '100%', display: 'flex', alignSelf: 'stretch', alignItems: 'center' }} onClick={() =>
                    setDraftServiceSwitchStaff(
                      data.staffId,
                      serviceItem?.uuid_local ?? ''
                    )
                  }>
                    <Col>
                      <Row style={{ gap: '8px', alignItems: 'center' }}>
                        <ButtonEditQuickPay onClick={handleUpdateQuickPay('NAME', draftPropertyDraftQuickPay)} style={{ marginRight: '2.5rem' }}>
                          <Text variant="CONTENT_1" color={isHighLight ? 'fill_1' : 'text_3'}>{serviceItem.itemType === 'VIP' ? (serviceItem.itemName || 'VIP') : serviceItem.itemName}</Text>
                        </ButtonEditQuickPay>
                        {allSetting?.showSupplyFeeCashier || itemMode === ITEM_HANDLE_MODE.SUPPLY_FEE ? (
                          <button type='button' onClick={() => {
                            dispatch(
                              cashierActions.setDraftSupplyFeeItemModal({
                                staffId: data.staffId,
                                service_uuid_local: serviceItem.uuid_local || '',
                                supplyFee: serviceItem.supplyFee || 0,
                                itemName: serviceItem.itemName,
                              })
                            );
                          }}>
                            <Text variant="CONTENT_1" color={'info_3'} style={{ fontStyle: 'italic', fontWeight: '600' }}>(Supply Fees: {formatCurrency(serviceItem.supplyFee)})</Text>
                          </button>
                        ) : null}
                      </Row>
                    </Col>
                    <Col flex={'auto'} />
                    <Col>
                      <PriceBoxContainerStyled>
                        <PriceBoxStyled>
                          <CenterBox>

                            {!!serviceItem?.discount && <Text
                              textDecorate="line-through"
                              variant="H9"
                              color={isHighLight ? 'fill_1' : 'primary_active'}
                              mr={0.5}
                            >
                              {formatCurrency(serviceItem.price)}
                            </Text>}
                            <ButtonEditQuickPay onClick={handleUpdateQuickPay('PRICE', draftPropertyDraftQuickPay)}>
                              <Text variant="H9" color={isHighLight ? 'fill_1' : 'text_3'} mr={0.5}>
                                {formatCurrency(afterDiscountPrice)}
                              </Text>
                            </ButtonEditQuickPay>
                          </CenterBox>
                          <ActionItem onHandleDiscount={(event: React.MouseEvent) => onHandleDiscount(event, data, serviceItem)} active={draftSwitchStaffActive} staff={data} service={serviceItem} itemMode={itemMode} isHighLight={isHighLight} />
                        </PriceBoxStyled>
                      </PriceBoxContainerStyled>
                    </Col>

                  </button>
                </ItemCollapseStyled>
              </>
            );
          })
        ) : (
          <ItemCollapseNoneStyled>
            <Text variant="H8" color="text_2">
              Select services
            </Text>
          </ItemCollapseNoneStyled>
        )}
      </div >
    </ItemsDroppable>
  );
};

export default ItemStaffTicket;
const BillPanelItemHeader = styled.button`
  padding: 6px 16px;
  padding-right: 8px;
  border-bottom: 1px solid #86909c;
  background: #fff;
  margin-bottom: 4px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  &:hover {
    background: #ffe5e782;
  };
  display: flex;
  align-items: center;
  &.active-staff {
    background: #FFE5E7;
    border-bottom: 1.5px solid rgb(245, 118, 127);
  }
`;

const ButtonSwitchStaffItem = ({ onClick }: { onClick: () => void }) => {
  return (
    <ButtonSwitchStaffStyled className='changeStaff' onClick={(e: any) => {
      e.stopPropagation();
      onClick();
    }}><Icon type="changeCustomer" fill='#6FABB6' /></ButtonSwitchStaffStyled>
  );
};
const ButtonSwitchStaffStyled = styled.button`
  align-self: stretch;
  width: 45px;
  padding:0 0.5rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba(255,255,255);
  }
  &.changeStaff {
    padding: 0;
  }
`;

const ButtonRemoveStaff = ({ onClick }: { onClick: () => void }) => {
  return (
    <ButtonSwitchStaffStyled
      onClick={(e: any) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Row align={'middle'} justify={'center'}>
        <Icon type='deleteIconCashier' />
      </Row>
    </ButtonSwitchStaffStyled>
  );
};


const ButtonArrowDown = ({ onClick, active }: { onClick: () => void, active?: boolean }) => {
  return <ButtonArrowDownStyled className={clsx(active && 'active')} onClick={(e: any) => [e.stopPropagation(), onClick()]}>
    <img src={arrowDown} alt="arrowDown" />
  </ButtonArrowDownStyled>;
};
const ButtonArrowDownStyled = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  align-self: stretch;
  border-radius: 5px;
  &:hover {
    cursor: pointer !important;
    background: #fff;
  }
  img {
  
    width: 28px;
    height: 28px;
    transition-property: all;
    transition-delay: .1s;
    transition-duration: .1s;
    transition-timing-function: ease-in;
    transform: rotate(-90deg);
  }
  &.active img {
    transform: rotate(0deg);
  }
`;
const ItemCollapseStyled = styled(ItemsDraggable).withConfig({
  shouldForwardProp: (prop) => !['enableSwitchStaff'].includes(prop),
}) <{ active?: boolean; enableSwitchStaff?: boolean, highlightItemId?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.25rem 0.5rem;
  align-self: stretch;
  border-radius: 5px;
  background: transparent;
  &:hover {
    background: #e5e6eb;
  }
  ${({ active }) =>
    active
      ? `
    background: var(--blue-headline, #C6E9EF);
    &:hover {
      background: var(--blue-headline, #C6E9EF);
    }
  `
      : ''}
   
  ${({ highlightItemId }) =>
    highlightItemId
      ? `
    background: black;
    &:hover {
      background: black;
    }
`
      : ''}


  &.transform {
    .CONTENT_1, .H9 {
      color: #fff !important; 
    }
  }
`;

const ButtonEditQuickPay = styled.button``;
const PriceBoxContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
`;

const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  button {
    display: flex;
  }
`;

const CenterBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ActionItem = ({ staff, onHandleDiscount, service, itemMode, isHighLight, active = false }: { onHandleDiscount: (event: React.MouseEvent) => void, staff: IStaffItem, service: IService, itemMode: ITEM_HANDLE_MODE | null, isHighLight?: boolean, active?: boolean; }) => {
  const dispatch = useAppDispatch();

  const handleClickCloseService = () => {
    dispatch(
      cashierActions.removeServiceOfItem({
        staffId: staff.staffId,
        serviceId: service.uuid_local,
      })
    );
  };

  const handleClickSupplyFeeItem = () => {
    dispatch(
      cashierActions.setDraftSupplyFeeItemModal({
        staffId: staff.staffId,
        service_uuid_local: service.uuid_local || '',
        supplyFee: service.supplyFee || 0,
        itemName: service.itemName,
      })
    );
  };

  const handleClickDiscountItem = (event: React.MouseEvent) => {
    if (onHandleDiscount) return onHandleDiscount(event);
    dispatch(
      cashierActions.setDraftDiscountItemModal({
        staffId: staff.staffId,
        service_uuid_local: service.uuid_local || '',
        amount: service.price,
        discount: service.discount,
      })
    );
  };

  if (active) {
    return <>
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickDiscountItem(e),
        ]}
      >
        <Icon type="discount" fill={isHighLight ? '#fff' : '#000'} />
      </ActionItemStyled>
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickSupplyFeeItem(),
        ]}
      >
        <Icon type="moneyExchange" fill='#6FABB6' />
      </ActionItemStyled>
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickCloseService(),
        ]}
      >
        <Icon type="circleClose" />
      </ActionItemStyled>
    </>;
  }

  if (itemMode === ITEM_HANDLE_MODE.SUPPLY_FEE) {
    return (
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickSupplyFeeItem(),
        ]}
      >
        <Icon type="moneyExchange" fill='#6FABB6' />
      </ActionItemStyled>
    );
  }

  if (itemMode === ITEM_HANDLE_MODE.DELETE_ITEM) {
    return (
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickCloseService(),
        ]}
      >
        <Icon type="circleClose" />
      </ActionItemStyled>
    );
  }

  if (itemMode === ITEM_HANDLE_MODE.DISCOUNT_ITEM) {
    return (
      <ActionItemStyled
        onClick={(e: any) => [
          e.stopPropagation(),
          handleClickDiscountItem(e),
        ]}
      >
        <Icon type="discount" fill={isHighLight ? '#fff' : '#000'} />
      </ActionItemStyled>
    );
  }

  return <></>;
};

const ActionItemStyled = styled.button`
  height: 2rem;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ItemCollapseNoneStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ButtonSwitchStaff = ({ onClick }: { onClick: () => void }) => {
  return (
    <ButtonSwitchStaffStyled
      style={{ width: 'auto' }}
      onClick={(e: any) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Row align={'middle'} justify={'center'}>
        <Icon type="switch" />
        <Text ml={0.25} variant="H9" color="info_3">
          Switch
        </Text>
      </Row>
    </ButtonSwitchStaffStyled>
  );
};