import { Avatar, Select, Space } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { styled } from 'styled-components';
interface IStaffSelector {
    onChange?: (value: IStaffItemData | null, option?: any) => void;
    data: IStaffItemData[],
    selected: IStaffItemData | undefined | null,
    size?: SizeType;
    v2?: boolean;
}
const StaffSelector: React.FC<IStaffSelector> = ({ v2, onChange = () => undefined, data, size = 'small' }) => {
    const { Option } = Select;
    const _onChange = (value: any, option: any) => {
        const item = data.find(o => o.id === value);
        onChange(item || null, option);

    };
    return (
        <SelectStyled
            style={v2 ? { minWidth: 300 } : { flex: 1, minWidth: 300 }}
            placeholder="All Staff"
            defaultValue={'ALL_ITEMS'}
            onChange={_onChange}
            optionLabelProp="label"
            size={size}
            allowClear
            className={v2 ? 'v2' : ''}
        >
            <Option value={'ALL_ITEMS'} label={'All Staff'}>
                <Space>
                    <ImageContainer>
                        <Avatar>{'All'}</Avatar>
                    </ImageContainer>
                    All Staff
                </Space>
            </Option>
            {data.map(item => {
                return (
                    <Option key={item.id} value={item.id} label={item.firstName + ' ' + item.lastName}>
                        <Space>
                            <ImageContainer>
                                <Avatar
                                    src={item.urlImage}
                                >{item?.firstName?.charAt(0)}</Avatar>
                            </ImageContainer>
                            {item.firstName + ' ' + item.lastName}
                        </Space>
                    </Option>
                );
            })}
        </SelectStyled>
    );
};

export default StaffSelector;

const ImageContainer = styled.div`
  min-width: 20px;
  margin-right: 0.5rem;
`;
const SelectStyled = styled(Select)`
&.v2 {
    .ant-select-selector {
        .ant-select-selection-item {
            color: #1D2129;
            font-family: Poppins;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}
`;