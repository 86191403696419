import Button from 'components/Button';
import userSelectors from 'features/user/services/selectors';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
type Props = {
  type?: 'ticket' | 'waiting';
  active?: boolean;
  onToggle: () => void;
};
const ButtonRemoveTicket = ({ type, active, onToggle }: Props) => {
  const versionUI = shopSelectors.data.versionUI();
  const enableMergeTicket = userSelectors.enableMergeTicket();
  if (versionUI === 'V2') return <div style={{ height: '2.5rem' }}></div>;
  if (enableMergeTicket && type === 'ticket') return null;
  return (
    <Wrapper>
      <Button
        size='small'
        style={{ display: 'flex', flexDirection: 'row' }}
        height={2.5}
        vertical
        icon='closeModal'
        ntype={active ? 'PRIMARY' : 'SECONDARY'}
        onClick={e => [e.preventDefault(), onToggle()]}
      >
        Delete
      </Button>
    </Wrapper>
  );
};

export default ButtonRemoveTicket;

const Wrapper = styled.div`
.CONTENT_2 {
  font-size: 14px;
}
`;