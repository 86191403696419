import { AutoComplete, Col, Input, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import AvatarBox from 'components/AvatarBox';
import Modal from 'components/Modal';
import { memo, useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
type Props = {
  filter?: (staffs: IStaffItemData[]) => IStaffItemData[];
  visible?: boolean;
  onClose?: () => void;
  onChooseStaff: (val: IStaffItemData) => void;
};
const ModalChooseStaffFilter = ({ filter, visible, onClose = () => undefined, onChooseStaff }: Props) => {
  const staffsStore = shopSelectors.data.staffs();
  const [textSearch, setTextSearch] = useState('');

  const staffs: IStaffItemData[] = useMemo(() => {
    return filter ? filter(staffsStore) : staffsStore;
  }, [staffsStore, filter]);

  const staffOpts = useMemo(() => {
    return staffs.map(o => ({
      label: o.firstName,
      value: o.id,
    }));
  }, [staffs, filter]);

  const onSelect = (value: string) => {
    const staffItem = staffs.find((item) => item.id === value);
    if (!staffItem) return;
    onChooseStaff(staffItem);
    onClose();
    setTextSearch('');
  };


  const staffFilter = useMemo(() => {
    return staffOpts.filter(o => o.label?.toLocaleLowerCase().includes(textSearch.toLocaleLowerCase()));
  }, [textSearch, staffOpts]);

  return (
    <Modal
      width={'70vw'}
      className='modal-add-new-appointment modal-max-height-unset'
      modalTitle='SELECT STAFF'
      visible={visible}
      hiddenOkButton
      noneBodyStyle
      containerPadding={1.5}
      onClose={onClose}
      showClose
      footer={<></>}
      hiddenFooter
    >
      <AutoComplete
        style={{ width: '100%' }}
        options={staffFilter}
        onSelect={onSelect}
        value={textSearch}
        onChange={setTextSearch}
      >
        <Input
          placeholder='Staff name'
          suffix={<SearchIcon />}
        />
      </AutoComplete>
      <ListContainer>
        <Row gutter={[16, 16]} justify="start">
          {staffs.map((item) => {
            return (
              <Col
                key={item.id}
                span={4}
                onClick={() => onSelect(item.id)}
              >
                <AvatarBox
                  src={item.urlImage}
                  name={item.firstName}
                />
              </Col>
            );
          })}
        </Row>
      </ListContainer>
    </Modal>
  );
};

export default memo(ModalChooseStaffFilter);

const ListContainer = styled.div`
  padding: 1.5rem 1rem;
  height: 50vh;
  overflow: auto;
  .staff-item {
    display:flex;
    flex-direction: column;
  }
`;
