import Button from 'components/Button';
import React from 'react';
import useModalModifyTipCash from './ModalModifyTipCash';

const ButtonModifyTipCash = () => {
  const [ref, context] = useModalModifyTipCash();
  const onOpen = () => ref.current?.open();
  return (
    <>
      <Button icon='cash' ntype='YELLOW' onClick={onOpen} >Modify Tip</Button>
      {context}
    </>
  );
};

export default ButtonModifyTipCash;
