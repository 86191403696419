import Icon from 'assets/Icons';
import Box from 'components/Box';
import Text from 'components/Text';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import { IOtherPaymentMethodItem } from 'services/shop/types/payment';

type ContentsProps = {
  onClick: (payment: IOtherPaymentMethodItem) => void;
};
const PaymentMethods = ({ onClick }: ContentsProps) => {
  const payments = shopSelectors.data.orderPaymentMethods();

  return (
    <Box className="center" gap="4" flexDirection="column">
      {payments &&
        payments?.map((item) => (
          <button key={item.id} disabled={!item.active} onClick={() => onClick(item)}>
            <Box
              className="space-between"
              gap="4"
              b="info_3"
              bgColor="info_bg_3"
              p="4"
              width="12rem"
              borderRadius="5px"
              opacity={!item.active ? 0.5 : 1}
            >
              <Text variant="CONTENT_1" color="text_3">
                {item?.methodName}
              </Text>
              <Icon type="arrowForwardOutline" />
            </Box>
          </button>
        ))}
    </Box>
  );
};

export default PaymentMethods;
