import {
  ICustomer,
  IResActiveCoupons,
  IResCustomers,
  IResSummaryReport,
  IResTransactions,
} from './types/api';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import actions from './actions';
import { NAME_REDUCER } from './constants';
import { IState } from './types/reducer';
import { IResAppointment } from './types/appointment';
import { IResReferrals } from './types/referral';
import { ICoupon } from './types/coupon';

const initialState: IState = {
  isWindow: false,
  params: {
    page: 1,
    size: 10,
  },
  paramsAppointment: {
    page: 1,
    size: 10,
  },
  paramsTransactions: {
    page: 1,
    size: 10,
  },
  paramsReferral: {
    page: 1,
    size: 10,
    couponType: '',
  },
  paramsCoupon: {
    page: 1,
    size: 10,
  },
  paramsActiveCoupon: {
    page: 1,
    size: 10,
  },

  totalElement: 0,
  totalAppointmentElement: 0,
  totalTransactionsElement: 0,
  totalReferralElement: 0,
  totalActiveCouponElement: 0,

  customers: [],
  customerDetails: undefined,
  summaryReport: {
    amountSpend: 0,
    point: 0,
    totalUsedCoupon: 0,
    totalAppointment: 0,
    totalWalkIn: 0,
  },
  appointmentList: [],
  transactionList: [],
  referralList: [],
  couponList: [],
  activeCouponList: [],
  customersActive: [],
  customerSummary: null,
};

export const Slice = createSlice({
  name: NAME_REDUCER,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(actions.getCustomerSummary.success, (state, { payload }) => {
        state.customerSummary = payload;
      })

      .addCase(
        actions.getCustomers.success,
        (state, { payload }: PayloadAction<IResCustomers>) => {
          state.customers = payload.content;
          state.totalElement = payload.totalElements;
        }
      )
      .addCase(actions.setCustomerParam, (state, { payload }) => {
        const _params = {
          ...(state.params ?? {}),
          ...payload,
        };
        state.params = _params;
      })
      .addCase(actions.handleChangeDisplayType, (state) => {
        state.isWindow = !state.isWindow;
      })
      .addCase(
        actions.getCustomerDetails.success,
        (state, { payload }: PayloadAction<ICustomer>) => {
          state.customerDetails = payload;
        }
      )
      .addCase(actions.setAppointmentParams, (state, { payload }) => {
        const _params = {
          ...(state.paramsAppointment ?? {}),
          ...payload,
        };
        state.paramsAppointment = _params;
      })
      .addCase(
        actions.getAppointmentList.success,
        (state, { payload }: PayloadAction<IResAppointment>) => {
          state.appointmentList = payload.content;
          state.totalAppointmentElement = payload.totalElements;
        }
      )
      .addCase(actions.setTransactionsParams, (state, { payload }) => {
        const _params = {
          ...(state.paramsTransactions ?? {}),
          ...payload,
        };
        state.paramsTransactions = _params;
      })
      .addCase(
        actions.getTransactionsList.success,
        (state, { payload }: PayloadAction<IResTransactions>) => {
          state.transactionList = payload.content;
          state.totalTransactionsElement = payload.totalElements;
        }
      )

      .addCase(actions.setReferralsParams, (state, { payload }) => {
        const _params = {
          ...(state.paramsReferral ?? {}),
          ...payload,
        };
        state.paramsReferral = _params;
      })
      .addCase(
        actions.getReferrals.success,
        (state, { payload }: PayloadAction<IResReferrals>) => {
          state.referralList = payload.content;
          state.totalReferralElement = payload.totalElements;
        }
      )
      .addCase(actions.setCouponsParams, (state, { payload }) => {
        const _params = {
          ...(state.paramsCoupon ?? {}),
          ...payload,
        };
        state.paramsCoupon = _params;
      })
      .addCase(actions.setActiveCouponsParams, (state, { payload }) => {
        const _params = {
          ...(state.paramsActiveCoupon ?? {}),
          ...payload,
        };
        state.paramsActiveCoupon = _params;
      })
      .addCase(
        actions.getCoupons.success,
        (state, { payload }: PayloadAction<ICoupon[]>) => {
          state.couponList = payload;
        }
      )
      .addCase(
        actions.getActiveCoupons.success,
        (state, { payload }: PayloadAction<IResActiveCoupons>) => {
          state.activeCouponList = payload.content;
          state.totalActiveCouponElement = payload.totalElements;
        }
      )

      .addCase(
        actions.getSummaryReport.success,
        (state, { payload }: PayloadAction<IResSummaryReport>) => {
          state.summaryReport = payload;
        }
      )
      .addCase(
        actions.getCustomersActive.success,
        (state, { payload }: PayloadAction<any>) => {
          state.customersActive = payload.content;
        }
      )
      .addCase(actions.deleteCustomer.success, (state) => {
        state.customerDetails = undefined;
        state.paramsActiveCoupon = {
          page: 1,
          size: 10,
        };
        state.paramsAppointment = {
          page: 1,
          size: 10,
        };
        state.paramsTransactions = {
          page: 1,
          size: 10,
        };
        state.paramsReferral = {
          page: 1,
          size: 10,
          couponType: '',
        };
        state.paramsCoupon = {
          page: 1,
          size: 10,
        };
        state.totalAppointmentElement = 0;
        state.totalTransactionsElement = 0;
        state.totalReferralElement = 0;
        state.totalActiveCouponElement = 0;
        state.customerDetails = undefined;
        state.summaryReport = {
          amountSpend: 0,
          point: 0,
          totalUsedCoupon: 0,
          totalAppointment: 0,
          totalWalkIn: 0,
        };
        state.appointmentList = [];
        state.transactionList = [];
        state.referralList = [];
        state.couponList = [];
        state.activeCouponList = [];
      })
      .addCase(actions.doInit, (state) => {

        state.isWindow = false;
        state.params = {
          page: 1,
          size: 10,
        };
        state.paramsAppointment = {
          page: 1,
          size: 10,
        };
        state.paramsTransactions = {
          page: 1,
          size: 10,
        };
        state.paramsReferral = {
          page: 1,
          size: 10,
          couponType: '',
        };
        state.paramsCoupon = {
          page: 1,
          size: 10,
        };

        state.paramsActiveCoupon = {
          page: 1,
          size: 10,
        };

        state.totalElement = 0;
        state.totalAppointmentElement = 0;
        state.totalTransactionsElement = 0;
        state.totalReferralElement = 0;
        state.totalActiveCouponElement = 0;
        state.customers = [];
        state.customerDetails = undefined;
        state.summaryReport = {
          amountSpend: 0,
          point: 0,
          totalUsedCoupon: 0,
          totalAppointment: 0,
          totalWalkIn: 0,
        };
        state.appointmentList = [];
        state.transactionList = [];
        state.referralList = [];
        state.couponList = [];
        state.customersActive = [];
        state.activeCouponList = [];

      })
      ;
  },
});

const customerServiceReducer = Slice.reducer;
export default customerServiceReducer;
