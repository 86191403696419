import { Col, Row } from 'antd';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import { ISimpleMenuItem, ISimpleMenuServiceItem } from 'services/shop/types/simpleMenu';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CategoryItem from './CategoryItem';

const ActiveSimpleMenu = () => {
  const dispatch = useAppDispatch();
  const simpleMenu = shopSelectors.data.simpleMenu();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();
  const data = useMemo(() => {
    const leftCategories: ISimpleMenuItem[] = [];
    const rightCategories: ISimpleMenuItem[] = [];
    simpleMenu.map((o, i) => {
      if (i % 2 == 0) return leftCategories.push(o);
      rightCategories.push(o);
    });
    return ({ leftCategories, rightCategories });
  }, [simpleMenu]);

  const handleClickService = (serviceItem: ISimpleMenuServiceItem) => {
    dispatch(cashierActions.selectSimpleMenuServiceItem(serviceItem));
  };

  return (
    <>
      <ComponentStyled className='container-scroll-div'>
        <ContentContainer className='ContentContainer'>
          <Row justify='space-between' align={'middle'} style={{ flex: 1 }}>
            <Col style={{ flex: 1 }}>
              <Text variant="H6" color="text_2">
                SERVICES
              </Text>
            </Col>
          </Row>
        </ContentContainer>
        <div className='scroll-div'>
          <div className='children-scroll'>
            <ActiveSimpleMenuStyled>
              <div className='categories'>
                {data.leftCategories.map(o => (<div key={o.categoryId} className='col-item'><CategoryItem serviceIdsSelected={serviceIdsSelected} onClickService={handleClickService} data={o} /></div>))}
              </div>
              <div className='categories'>
                {data.rightCategories.map(o => (<div key={o.categoryId} className='col-item'><CategoryItem serviceIdsSelected={serviceIdsSelected} onClickService={handleClickService} data={o} /></div>))}
              </div>
            </ActiveSimpleMenuStyled>
          </div>
        </div>
      </ComponentStyled>
    </>
  );
};

export default ActiveSimpleMenu;

const ActiveSimpleMenuStyled = styled.div`
  flex:1;
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr 1fr;
  .categories {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
`;

const ComponentStyled = styled.div`
  width:100%;
  &.container-scroll-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    .ContentContainer {
      margin-bottom: 4px;
    }
  }
  .scroll-div {
    flex: 1;
    position: relative;
  }
  .children-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    padding-right: 4px;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
  
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,0.5);
      &:hover {
        background-color: rgba(0,0,0,0.6);
      }
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 4px;
      background-color: #F5F5F5;
    }
  }
`;

const ContentContainer = styled.div`
  margin-bottom: 1rem;
`;