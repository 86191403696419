import { turnManualSelectors } from 'features/turn/services/selectors';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import Staff, { StaffManager } from './Staff';
import { getStaffNextTurn } from './helpers';
import { IStaffTurnItem } from '../TurnTable/TurnStaffTopRow';
import { sortBy } from 'lodash';
import SwitchVisibleItemName from './SwitchVisibleItemName';
import ZoomInOut from './ZoomInOut';
import Logo from 'assets/big-logo.svg';
import Text from 'components/Text';

const StaffTurns = () => {
  const _mappingStaffs = turnManualSelectors.mappingStaffs();
  const inActiveTurnStaffs = turnManualSelectors.inActiveTurnStaffs();
  const staffNextTurn = useMemo(() => getStaffNextTurn(_mappingStaffs), [_mappingStaffs]);

  const mappingStaffs = useMemo(() => {
    const staffs = sortBy(_mappingStaffs, 'totalInprogress', 'totalTurn');
    return staffs;
  }, [_mappingStaffs]);

  const staffs: IStaffTurnItem[] = useMemo(() => {
    return sortBy(mappingStaffs, o => o.staffId !== staffNextTurn?.staffId);
  }, [mappingStaffs, staffNextTurn]);

  return (
    <Container>
      <div className="switch-container">
        <SwitchVisibleItemName />
        <ZoomInOut />
      </div>
      <div className="box-container">
        <div className="staff-turn-list-container">
          <div className="staff-turn-list">
            {staffs.map((o, i) => {
              return <Staff highlight={staffNextTurn?.staffId === o.staffId} index={i + 1} data={o} key={o.staffId} />;
            })}
          </div>
        </div>
      </div>
      <MangersStyled>
        <div className="header-section">
          <Text variant='CONTENT_2'>Management Team</Text>
        </div>
        {inActiveTurnStaffs.map((o) => {
          return <StaffManager data={o} key={o.staffId} />;
        })}
      </MangersStyled>
      <div className="fozito-icon-container">
        <div className="fozito-icon">
          <img src={Logo} alt="Logo" />
        </div>
      </div>
    </Container>
  );
};

export default StaffTurns;
const MangersStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px;
  .header-section {
    padding: 8px 16px;
    background: #FFF1F6;
    box-shadow: 0px 2px 0px 0px #FF8890;
    .CONTENT_2 {
      color: #D2464F;
      text-align: center;
    }
  }
`;

const Container = styled.div`
display: flex;
align-self: stretch;
padding-left: 16px;
flex-direction: column;
background: #F6F7FC;
.switch-container {
  padding: 0 8px;
  height: 64px;
  display: flex;
  align-items: center;
  gap: 8px;
  box-shadow: 0px -8px 16px -8px rgba(0, 0, 0, 0.15);
  border-left: 2px solid #CCD4DC;
  justify-content: center;
}
.box-container {
  flex: 1;
  box-shadow: 0px -8px 16px -8px rgba(0, 0, 0, 0.15);
  border-left: 2px solid #CCD4DC;
  position: relative;
  .staff-turn-list-container {
    inset: 0;
    position: absolute;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox 64 */
    &::-webkit-scrollbar {
      width: 0px;
      height: 8px;
      background-color: #F5F5F5;
    }
  }
  .staff-turn-list {
    padding: 24px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: stretch;
    gap: 8px;
  }
}
.fozito-icon-container {
  background: #fff;
  border: 1px solid #CCD4DC;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fozito-icon {
  width: 180px;
  height: 100px;
  padding: 4px;
  img {
    width: 100%;
    height: 100%;
  }
  
}
@keyframes zoom {
  0% {
    transform: scale(.8);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1.2);
  }
}
@keyframes zoomSmall {
  0% {
    transform: scale(.95);
  }
  100% {
    transform: scale(1);
  }
}
`;
