import fetch from 'services/request';
import { SHOP_DEVICE_TYPE } from './constants';
import { ICreateCustomerBody } from './types/customer';
import { IBodyApiAddDeviceWithStation } from './types/station';
import { IBodyShortLink } from './types/links';

export const getCategories = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/category/get-all-by-category',
  });
};

export const getAddOnList = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/service/list-add-on',
  });
};

export const getStaffs = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/get-all-by-shop',
    params: {
      size: 1000,
    }
  });
};

export const getStaffsAvailable = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/list-available-clock-in',
  });
};

export const getCustomers = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/customer/list-active',
  });
};

export const getCustomersFilter = (lastPhoneNumber: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/customer',
    params: { lastPhoneNumber },
  });
};

export const getShopInfo = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/getInfoDetail',
  });
};


export const addNewCustomer = (body: ICreateCustomerBody) => {
  return fetch({
    method: 'post',
    url: 'api/v1/customer/create',
    body,
  });
};


const getListCoupon = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/coupon/list',
  });
};

const getCouponByCode = (code: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/coupon/get-by-code',
    params: { code }
  });
};

const getCouponPrint = (billId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/coupon/list-print/' + billId,
  });
};

const getSpecialDiscountShop = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/special-discount/list',
  });
};

const getDiscountSetting = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/setting/discount-by-shop',
  });
};

const getOtherPaymentMethod = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/setting/other-payment-method',
  });
};

const getShopAllSetting = (shopId?: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/all-setting',
    shopId,
  });
};

const getProductCategories = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/product/get-all-by-category',
  });
};

const addDevice = (shopId: string, deviceId: string, method: SHOP_DEVICE_TYPE) => {

  return fetch({
    method: 'post',
    url: 'api/v1/device',
    body: { deviceId, method, },
    shopId
  });
};

const getProductsVIP = () => {
  return fetch({
    method: 'post',
    url: 'api/v1/vip/get',
  });
};

const getCustomersRecent = () => {
  return fetch({
    url: 'api/v1/customer/list-recent',
    method: 'get',
    params: { size: 6 },
  });
};

const addCustomersRecent = (customerId: string) => {
  return fetch({
    url: 'api/v1/customer/add-recent',
    method: 'post',
    body: {
      customerId,
    }
  });
};

const addDeviceWithStation = (shopId: string, body: IBodyApiAddDeviceWithStation) => {
  return fetch({
    url: 'api/v1/device/manager',
    method: 'post',
    body,
    shopId,
  });
};

const getShopDevices = (shopId: string) => {
  return fetch({
    url: 'api/v1/device/manager',
    method: 'get',
    shopId
  });
};

const getShopTotalStation = (shopId: string) => {
  return fetch({
    method: 'get',
    url: 'api/v1/device/manager/total-station',
    shopId,
  });
};

const getShopScheduleBooking = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/shop/scheduler-booking',
  });
};

const getFeeCreditCard = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/payment/fee-credit-card',
  });
};

// /api/v1/staff/verify-pin-admin/{pinCode}
const verifyPinAdmin = (pinCode: string) => {
  return fetch({
    method: 'post',
    url: `api/v1/staff/verify-pin-admin/${pinCode}`,
  });
};

const getTaxConfig = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/setting/tax-config'
  });
};

const getShortLink = (body: IBodyShortLink) => {
  return fetch({
    method: 'post',
    url: 'api/v1/url/short-link',
    body,
  });
};

const getSimpleMenu = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/simple-menu/all'
  });
};

const getSimpleMenuConfigCheckIn = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/simple-menu/config-check-in',
  });
};


const getOptionTips = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/setting/option-tips',
  });
};

const getOptionTipsAll = () => {
  return fetch({
    method: 'get',
    url: 'api/v1/setting/option-tips-all',
  });
};

const getQuickBookingService = () => {
  return fetch({
    method: 'get', url: '/api/v1/service/quick-booking',
  });
};

const getStaffAvailableBooking = () => {
  return fetch({
    method: 'get', url: '/api/v1/staff/available-booking-online',
  });
};

const checkConfigVerifyOtp = (type: string, shopId: string) => {
  return fetch({
    method: 'get',
    url: '/api/v1/shop/check-config-verify-otp',
    params: { type },
    shopId,
  });
};

const verifyOTP2Factor = (shopId: string, body: { otpToken: string, otpValue: string }) => {
  return fetch({
    method: 'post',
    url: '/api/v1/shop/verify-otp',
    body,
    shopId,
  });
};

const shopApis = {
  getCategories,
  getAddOnList,
  getStaffs,
  getCustomers,
  getStaffsAvailable,
  getShopInfo,
  addNewCustomer,
  getListCoupon,
  getCouponPrint,
  getSpecialDiscountShop,
  getDiscountSetting,
  getOtherPaymentMethod,
  getShopAllSetting,
  addDevice,
  getProductCategories,
  getProductsVIP,
  getCustomersRecent,
  addCustomersRecent,
  addDeviceWithStation,
  getShopDevices,
  getShopTotalStation,
  verifyPinAdmin,
  getShopScheduleBooking,
  getCouponByCode,
  getFeeCreditCard,
  getTaxConfig,
  getShortLink,
  getSimpleMenu,
  getSimpleMenuConfigCheckIn,
  getOptionTips,
  getOptionTipsAll,
  getQuickBookingService,
  checkConfigVerifyOtp,
  verifyOTP2Factor,
  getStaffAvailableBooking,
};

export default shopApis;
