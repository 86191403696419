import { Avatar } from 'antd';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';

const StaffInfo = ({ value: staff, isItem }: { value?: IStaffItemData | null, isItem?: boolean }) => {
  return (
    <Container className={isItem ? 'isItem' : ''}>
      <Avatar size={64} src={staff?.urlImage || 'fail'} >{staff?.firstName?.[0] || 'A'}</Avatar>
      <span>{staff?.firstName || 'Anyone'}</span>
    </Container>
  );
};

export default StaffInfo;
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  span {
    color: #FFF;
    background: #232F3E;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  &.isItem span {
    overflow: hidden;
    color: #1D2129;
    background: transparent;
    text-align: center;
    text-overflow: ellipsis;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;
