import { Form, Row } from 'antd';
import Button from 'components/Button';
import Text from 'components/Text';
import settingServiceActions from 'features/settingService/services/actions';
import settingApis from 'features/settingService/services/apis';
import settingServiceSelectors from 'features/settingService/services/selectors';
import React, { FC, useEffect, useRef } from 'react';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import VIPCard from './Component/VIPCard';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import toast from 'utils/toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
interface IVIPSetting {

}
const VIPSetting: FC<IVIPSetting> = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const listVIP = settingServiceSelectors.getVIPProductList();
  const scrollRef = useRef();
  const setLoadingPage = useSetLoadingPage();
  const location = useLocation();
  const navigate = useNavigate();
  const previousPath = get(location.state, 'path', '');

  useEffect(() => {
    dispatch(settingServiceActions.getVIPProductList.fetch());
  }, []);

  useEffect(() => {
    form.setFieldValue('listVIP', listVIP);
  }, [listVIP]);

  const handleFinish = async (values: any) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await settingApis.updateVIPList(values.listVIP);
      if (res.data.data) {
        toast.success('Update Successful!');
        dispatch(shopActions.get.productsVIP.fetch());
        dispatch(settingServiceActions.getVIPProductList.fetch());
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return (
    <Form form={form} onFinish={handleFinish}>
      <VIPSettingStyled>
        <Header>
          <Text variant="H5">VIP Card</Text>
        </Header>

        <Content>
          {/* @ts-ignore */}
          <ScrollStyled ref={scrollRef}>
            <Form.List name={'listVIP'}>
              {(fields) => {
                return <>
                  {fields.map(({ key, name }) => {
                    return (
                      <React.Fragment key={key}>
                        <Form.Item name={name}>
                          <VIPCard name={name} />
                        </Form.Item>
                      </React.Fragment>
                    );
                  })}
                </>;
              }}
            </Form.List>
          </ScrollStyled>
        </Content>
        <ButtonGroup>
          {previousPath ? <Button onClick={() => navigate(previousPath)}>Back</Button> : null}
          <Button ntype="SECONDARY" onClick={() => {
            form.resetFields();
            form.setFieldValue('listVIP', listVIP);
          }}>Reset</Button>
          <Button ntype="PRIMARY" onClick={() => form.submit()}>Save</Button>
        </ButtonGroup>
      </VIPSettingStyled >
    </Form>
  );
};
export default VIPSetting;

const VIPSettingStyled = styled.div`
    padding: 20px;
    background-color: '#E5E5E5';
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
const ButtonGroup = styled(Row)`
    justify-content: center;
    button {
      width: 10rem;
      margin: 0px 6px;
    }
`;
const Content = styled.div`
    margin: 10px 0px;
    display: flex;
    flex-direction: row;

`;
const ScrollStyled = styled.div`
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    > div {
        margin: 10px;
    }
`;
