import { ITurnServiceItem } from 'features/turn/services/types/service';
import React, { useState } from 'react';
import styled from 'styled-components';
import TurnServiceItem from './ServiceItem';
import arrowRight from './arrow-right.svg';
import arrowLeft from './arrow-left.svg';

type Props = {
  data: ITurnServiceItem[];
  onChooseService: (o: ITurnServiceItem) => void;
  type?: 'WALK_IN' | 'APPOINTMENT' | 'BONUS' | 'REQUEST';
  full?: boolean;
  half?: boolean;
};
const ServiceList = ({ data, onChooseService, type, full, half }: Props) => {
  const [expand, setExpand] = useState(false);
  const handleChoose = (o: ITurnServiceItem) => () => {
    setExpand(false);
    onChooseService(o);
  };
  return (
    <Container>
      {!expand ? <>
        <button onClick={() => setExpand(!expand)} className="btn btn-arrow"><img src={arrowRight} alt="arrowRight" className='arrow-box' /></button>
      </> : <>
        {data.map(o => <TurnServiceItem onClick={handleChoose(o)} type={type} key={o.itemId} full={full} half={half} serName={o?.itemName || ''} />)}
        <button onClick={() => setExpand(!expand)} className="btn btn-arrow"><img src={arrowLeft} alt="arrowLeft" className='arrow-box' /></button>
      </>}
    </Container>
  );
};

export default ServiceList;
const Container = styled.div`
display: flex;
align-items: center;
gap: 16px;
align-self: stretch;
flex: 1;

.btn.btn-arrow {
  width: 58px;
  height: 58px;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  border-radius: 100px;
  border: 1px solid #86909C;
  background: #E5E6EB;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
  padding: 2px;
  &.reverse {
    transform: rotate(180deg);
  }
}
.arrow-box {
  width: 100%;
  height: 100%;
  animation: zoomArrow 1s linear infinite;
}
`;
