import { Col, Row } from 'antd';
import Text from 'components/Text';
import { OTHER_PAYMENT, PAYMENT_TYPE } from 'features/payment/services/constants';
import paymentSelectors from 'features/payment/services/selectors';
import { IPaymentCreditCardInfo } from 'features/payment/services/types/payment';
import React, { useCallback, useMemo } from 'react';
import shopSelectors from 'services/shop/selectors';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { PHONE_MASKED } from 'utils/mask';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import CouponDiscount from './coupon/Discount';
import CouponGiftCard from './coupon/GiftCard';
import CouponLoyaltyPoint from './coupon/LoyaltyPoint';
import IconLogo from './logo';
import Box from 'components/Box';

type IPrintBodyProps = {
  isPrinter?: boolean;
  billRef?: React.RefObject<HTMLDivElement>;
  owner?: boolean;
};
const PrintBody: React.FC<IPrintBodyProps> = ({ billRef, isPrinter, owner }) => {
  const couponPrint = shopSelectors.data.couponPrint();
  const data = paymentSelectors.getData();
  const shopInfo = shopSelectors.data.shopInfo();
  const feeCreditCard = shopSelectors.data.feeCreditCard();
  const allSetting = shopSelectors.data.allSetting();

  const describePayment = useMemo(() => {

    return [
      { name: 'Sale Tax', price: data?.saleTax },
      { name: 'Use Tax', price: data?.useTax },
      { name: 'Disc. By Item', price: data?.discountItem },
      { name: 'Disc. By Ticket', price: data?.discountTicket },
    ].filter(o => !!o);
  }, [data]);

  const havePayments = useMemo(() => {
    let loyalty = false;
    let giftCard = false;
    let creditCard = false;
    let check = false;
    data?.payments.map(o => {
      if (o.paymentType === PAYMENT_TYPE.LOYALTY_POINT) {
        loyalty = true;
      }
      if (o.paymentType === PAYMENT_TYPE.GIFT_CARD) {
        giftCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CREDIT_CARD) {
        creditCard = true;
      }
      if (o.paymentType === PAYMENT_TYPE.CHECK && o.otherMethod === OTHER_PAYMENT.CHECK) {
        check = true;
      }
    });

    return ({
      loyalty,
      giftCard,
      isHaveSignature: creditCard || check,
    });

  }, [data]);

  const moneyCard = useMemo(() => {
    return [
      { name: 'Loyalty Program', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.LOYALTY_POINT)?.amount },
      { name: 'Gift Card', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.GIFT_CARD)?.amount },
      { name: 'Cash', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CASH)?.amount },
      { name: 'Credit Card', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD)?.amount },
      { name: 'Change Amount', price: data?.chance ? -data?.chance : null },
      { name: 'Check', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CHECK)?.amount },
    ].filter(o => !!o.price);
  }, [data]);

  const otherPayments = useMemo(() => {
    return [{ name: 'Cash pay', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CASH_APPS)?.amount },
    { name: 'Zelle', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.ZELLE)?.amount },
    { name: 'Paypal', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.PAYPAL)?.amount },
    { name: 'Venmo', price: data?.payments.find(o => o.paymentType === PAYMENT_TYPE.VENMO)?.amount },].filter(o => !!o.price);
  }, [data]);

  const creditCard = useMemo(() => {
    const creditData = data?.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD) as (IPaymentCreditCardInfo | undefined);
    if (!creditData) return null;
    return {
      endCode: creditData.last4,
      cardType: creditData.cardType,
      authCode: creditData.authCode,
      trans: creditData.transNumber,
      entryType: creditData.entryType,
      amount: creditData?.amount,
      feeCreditCard: creditData.feeCreditCard,
    };
  }, [data]);

  const Coupons = useCallback(() => {
    return <>
      <pre />
      <div>
        {couponPrint?.DISCOUNT && (<CouponDiscount data={couponPrint.DISCOUNT} />)}
        {couponPrint?.GIFT_CARD && (<CouponGiftCard data={couponPrint.GIFT_CARD} />)}
        {couponPrint?.LOYALTY_POINT && (<CouponLoyaltyPoint data={couponPrint.LOYALTY_POINT} />)}
      </div>
    </>;
  }, [couponPrint]);

  const CardFee = () => {
    if (!creditCard) return null;
    return (
      <SpaceBetweenBox>
        <Text printMode variant="CONTENT_2">
          Card Fee
        </Text>
        <Text printMode variant="CONTENT_2">
          {formatCurrency(creditCard?.feeCreditCard)}
        </Text>
      </SpaceBetweenBox>
    );
  };

  const TipData = () => {
    if (allSetting?.showModalTip === false) return null;

    if (creditCard) {
      if (!data?.tip) return (
        <SpaceBetweenBox style={{ height: '4rem', alignItems: 'unset', paddingBottom: '0rem' }}>
          <Text printMode variant="CONTENT_2"></Text>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1, transform: 'translateY(-10px)', }}>
            <Text printMode variant="H7">
              {formatCurrency(data?.total)}
            </Text>
            <div style={{ display: 'flex', gap: '4px', transform: 'translateY(0px)', width: '100%', justifyContent: 'flex-end', }}>
              <Text printMode variant="CONTENT_2" style={{ fontSize: 20, transform: 'translateY(2px)' }}>
                Tip
              </Text>
              <LineDot />
            </div>
          </div>
        </SpaceBetweenBox>
      );
    }

    return (<SpaceBetweenBox>
      <Text printMode variant="CONTENT_2">Tip</Text>
      <Text printMode variant="H7">{formatCurrency(data?.tip)}</Text>
    </SpaceBetweenBox>);
  };

  const TotalData = () => {
    if (allSetting?.showModalTip === false) return null;

    if (creditCard) {
      if (!data?.tip) {
        return (
          <TotalBox style={{ borderBottom: 'none' }}>
            <SpaceBetweenBox style={{ height: '5rem', alignItems: 'unset', paddingBottom: '1rem' }}>
              <Text printMode variant="CONTENT_2"></Text>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'end', flex: 1 }}>
                <Text printMode variant="H7"></Text>
                <div style={{ display: 'flex', gap: '4px', transform: 'translateY(0px)', width: '100%', justifyContent: 'flex-end', }}>
                  <Text printMode variant="CONTENT_2" style={{ fontSize: 20, transform: 'translateY(2px)' }}>
                    Total
                  </Text>
                  <LineDot />
                </div>
              </div>
            </SpaceBetweenBox>
          </TotalBox>
        );
      }
      return (
        <TotalBox style={{ borderBottom: 'none' }}>
          <SpaceBetweenBox>
            <Text printMode variant="CONTENT_2">Total</Text>
            <Text printMode variant="H7">{formatCurrency(data?.total)}</Text>
          </SpaceBetweenBox>
        </TotalBox>
      );
    }
    return (
      <TotalBox>
        <SpaceBetweenBox>
          <Text printMode variant="CONTENT_2">Total</Text>
          <Text printMode variant="H7">{formatCurrency(data?.total)}</Text>
        </SpaceBetweenBox>
      </TotalBox>
    );
  };

  const CreditCardInfo = () => {
    if (!creditCard) return null;
    return (
      <PaddingBox style={{ padding: '0 1rem' }}>
        <SpaceBetweenBox>
          <Box display='flex' alignItems='center' gap='1'>
            <Text printMode variant="CONTENT_2">
              Credit Card
            </Text>
            <Box highlight><Text printMode variant='CONTENT_2' className='title-highlight'>{creditCard?.endCode || ''}</Text></Box>
          </Box>
        </SpaceBetweenBox>
        <Text printMode variant="CONTENT_2">
          Card Type: {creditCard?.cardType}
        </Text>
        <Text printMode variant="CONTENT_2">
          AuthCode: {creditCard?.authCode}
        </Text>
        <Box display='flex' alignItems='center' gap='1'>
          <Text printMode variant="CONTENT_2">
            Trans Number #:
          </Text>
          <Box highlight><Text printMode variant='CONTENT_2' className='title-highlight'>{creditCard?.trans}</Text></Box>
        </Box>
        <Text printMode variant="CONTENT_2">
          Entry Type: {creditCard?.entryType}
        </Text>
      </PaddingBox>
    );
  };

  const Signature = ({ divider = true }: { divider?: boolean }) => {
    if (!creditCard) return null;
    return (
      <>
        {divider && <Divider mb={1} />}
        <CardBox>
          <SignatureBox>
            {!data?.signature && <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                <line x1="0.353553" y1="0.446447" x2="11.5536" y2="11.6464" stroke="black" />
                <line x1="11.5537" y1="0.353553" x2="0.353749" y2="11.5536" stroke="black" />
              </svg>
            </div>}
            {data?.signature ?
              <img className='img' src={data?.signature?.includes('http') ? data.signature : 'data:image/png;base64,' + data.signature} />
              :
              <div className='blank' />}
          </SignatureBox>
          <Text printMode mt={0.5} variant="CONTENT_2" textAlign='center'>
            Signature
          </Text>
        </CardBox>
      </>
    );
  };

  const getChargeFeeCreditLabel = () => {
    if (!feeCreditCard) return '';
    if (feeCreditCard?.feeType === 'PERCENT')
      return `${feeCreditCard?.feeValue}%`;
    if (feeCreditCard?.feeType === 'MONEY')
      return formatCurrency(feeCreditCard?.feeValue);

  };

  const ThankYouData = () => {
    if (data?.isChargeFee) {
      return (
        <FinalBox border={!havePayments.isHaveSignature}>
          <Text printMode variant="CONTENT_2" >Service charge of {getChargeFeeCreditLabel()} will be applied <br />when using the Credit Card of Payment</Text>
          <Text printMode variant='CONTENT_2'>THANK YOU!</Text>
        </FinalBox>
      );
    }

    return (
      <FinalBox border={!havePayments.isHaveSignature}>
        <Text printMode variant='CONTENT_2'>CUSTOMER COPY</Text>
        <Text printMode variant="CONTENT_2" >Thank you for using our service</Text>
      </FinalBox>
    );
  };

  if (!data) return <PrintBodyStyled />;

  return (
    <>
      <PrintBodyStyled id='receipt' >
        <TicketBox ref={billRef} >
          <CountingBox style={(creditCard && !data?.tip) ? { borderBottom: 'none' } : {}}>
            {allSetting?.showHeaderStaffTicket &&
              <Box highlight mb='2'>
                <Text printMode variant="CAPTION_2" className='title-highlight' textAlign='center' style={{ fontSize: 24 }}>
                  {data?.items.map(o => o.staffName).join(' / ')}
                </Text>
              </Box>}
            <Row justify={'space-between'} align={'middle'}>
              <Col>
                <IconLogo />
                <TicketCodeBox>
                  #{data.ticketNumber}
                </TicketCodeBox>
              </Col>
              <Col>
                <ShopText printMode variant="CONTENT_2" className='bold' style={{ fontSize: '1.5rem' }}>{shopInfo?.shopName || ''}</ShopText>
                <ShopText printMode variant="CONTENT_2" className={isPrinter ? 'bold' : ''} style={{ fontSize: '0.75rem' }}>
                  {shopInfo?.address || ''}{', ' + shopInfo?.city || ''}
                </ShopText>
                <ShopText printMode variant="CONTENT_2" style={{ fontSize: '0.75rem' }}>
                  {PHONE_MASKED.resolve(shopInfo?.phone || '')}
                </ShopText>
              </Col>
            </Row>
            <Text printMode mb={0} />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2" className='customer_name'>{data.customerName || 'Unknown customer'}</Text>
              <Text printMode variant="CONTENT_1" color="text_2">
                {data?.customerPoint || 0} Point{(data?.customerPoint || 0) > 1 ? 's' : ''}
              </Text>
            </SpaceBetweenBox>
            <Text printMode variant="CONTENT_1" color="text_2">
              {momentTimezone(data?.startTime).format('MM-DD-YYYY LT')} - {momentTimezone(data?.endTime).format('LT')}
            </Text>
            <Text printMode mb={0} />

            {/* <Text printMode variant="CONTENT_1" color="text_2">
            POS 1
          </Text> */}

            {data?.items?.map((item) => (
              <div key={item.staffId + data?.billId}>
                <Box display='flex'>
                  <StaffHeaderBox highlight px='1'>
                    <Text printMode variant="CONTENT_2" style={{ fontSize: '1.25rem' }} className='title-highlight'>
                      {item.staffName}
                    </Text>
                  </StaffHeaderBox>
                </Box>
                <ServiceBoxStyled>
                  {item.services.map((service) => (
                    <div key={service.itemId}>
                      <SpaceBetweenBox>
                        <Text printMode variant="CONTENT_2">
                          {service.itemName}
                        </Text>
                        <PriceBoxStyled>
                          <Text printMode variant="CONTENT_2">
                            {formatCurrency(service.price)}
                          </Text>
                        </PriceBoxStyled>
                      </SpaceBetweenBox>
                    </div>
                  ))}
                </ServiceBoxStyled>
                <StyledDivider mb={.3} />
              </div>
            ))}
            <PaddingBox>
              {describePayment.map((item) => (
                <SpaceBetweenBox key={item?.name}>
                  <Text printMode variant="CONTENT_2">
                    {item?.name}
                  </Text>
                  <Text printMode variant="CONTENT_2">
                    {formatCurrency(item?.price)}
                  </Text>
                </SpaceBetweenBox>
              ))}
            </PaddingBox>
            <StyledDivider />
            <SpaceBetweenBox>
              <Text printMode variant="CONTENT_2">
                Subtotal
              </Text>

              <Text printMode variant="CONTENT_2">
                {formatCurrency(data?.subTotal)}
              </Text>
            </SpaceBetweenBox>
            <CardFee />
            <StyledDivider mb={0} />
          </CountingBox>
          <div style={{ padding: '0 1rem' }}>
            <TipData />
          </div>
          <TotalData />
          <CardBox>
            <MoneyBox style={{ margin: '4px 0', gap: '4px', display: 'flex', flexDirection: 'column' }}>
              {moneyCard.length > 0 && <>
                {moneyCard.map((item) => (
                  <SpaceBetweenBox key={item.name}>
                    <Box highlight display='inline' px='1'>
                      <Text printMode variant="CONTENT_2" className='title-highlight' style={{ fontSize: 24 }}>
                        {item.name}
                      </Text>
                    </Box>
                    <Text printMode variant="CONTENT_2" style={{ fontSize: 20 }}>
                      {formatCurrency(item.price)}
                    </Text>
                  </SpaceBetweenBox>
                ))}
              </>}
              {otherPayments.length > 0 && <>
                {otherPayments.map((item) => (
                  <SpaceBetweenBox key={item.name}>
                    <Box highlight px='1'>
                      <Text printMode variant="CONTENT_2" className='title-highlight' style={{ fontSize: 24 }}>
                        {item.name}
                      </Text>
                    </Box>
                    <Text printMode variant="CONTENT_2" style={{ fontSize: 20 }}>
                      {formatCurrency(item.price)}
                    </Text>
                  </SpaceBetweenBox>
                ))}
              </>}
            </MoneyBox>

            {(havePayments.loyalty || havePayments.giftCard) &&
              <>
                {havePayments.loyalty &&
                  <SpaceBetweenBox my={0.5}>
                    <Text printMode variant="CONTENT_2">
                      Available Loyalty Points
                    </Text>
                    <Text printMode variant="CONTENT_2">
                      {(data?.availableLoyaltyPoint)} point
                    </Text>
                  </SpaceBetweenBox>}

                {havePayments.giftCard &&
                  <SpaceBetweenBox mb={0.5}>
                    <Text printMode variant="CONTENT_2">
                      Gift Card Balance
                    </Text>

                    <Text printMode variant="CONTENT_2">
                      {formatCurrency(data?.availableGiftCard)}
                    </Text>
                  </SpaceBetweenBox>}
              </>}
          </CardBox>
          <Signature divider={false} />
          <CreditCardInfo />
          <Divider mb={1} />
          <ThankYouData />

          {/* ----------------Coupon */}
          <Row align={'middle'} justify={'center'} style={{ marginBottom: '1rem' }}>
            <TicketCodeBox>
              POS Stations #{storage.station_number.get()}
            </TicketCodeBox>
          </Row>
          {!owner && <Coupons />}
        </TicketBox>
      </PrintBodyStyled>
    </>
  );
};

export default PrintBody;
const PrintBodyStyled = styled.div`
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6rem;
  // justify-content: center;
  flex-grow: 1;
  .shop_name {
    color: black;
    text-align: right;
    font-family: 'Roboto', serif;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 166.667% */
  }
  .shop_address, .shop_phone {
    color: black;
    font-family: 'Roboto', serif;
    text-align: right;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
  .customer_name {
    color: black;
    font-family: 'Roboto', serif;
    font-size: 1.25rem;
  }
`;
const TicketBox = styled.div`
  border-radius: 20px;
  background: #fff;
  width: 25.5rem;
  height: fit-content;
`;
// const CouponBox = styled.div`
//   border-radius: 10px;
//   background: #e5e5e5;
//   width: 19.5rem;
//   height: fit-content;
// `;
const CountingBox = styled.div`
  padding: 1rem;
  border-bottom: 1px solid black;
`;

// const LogoBox = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 0.5rem;
// `;

const TicketCodeBox = styled.div`
  border-radius: 5px;
  background: black;
  display: flex;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #fff;

  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;
const SpaceBetweenBox = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const StaffHeaderBox = styled(Box)`
  border-bottom: 1px solid black;
  color: black;
  font-family: 'Roboto', serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
`;
const ShopText = styled(Text)`
  color: black;
  font-style: normal;
  font-family: 'Roboto', serif;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-align: right;

  &.bold {
    font-size: 0.8rem;
    font-weight: 700;
  }
`;

const PriceBoxStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
const ServiceBoxStyled = styled.div`
  padding: 0;
`;
const StyledDivider = styled(Text)`
  border-bottom: 1px solid black;
`;
const LineDot = styled(Text)`
border-bottom: 2px solid black;
/* border-bottom-style: dotted; */
width:50%;
`;

const PaddingBox = styled.div<{ borderDot?: boolean }>`
  padding: 0 0;
  ${({ borderDot }) => borderDot && `
    border-bottom: 2px solid black;
    // border-bottom-style: dotted;
  `}
`;
const TotalBox = styled.div`
  padding: 0 1rem;
  border-bottom: 1px solid black;
  /* border-bottom-style: dotted; */
`;
const Divider = styled(Text)`
  border-bottom: 1px solid black;
  /* border-bottom-style: dotted; */
`;
const CardBox = styled.div`
  padding: 0 1rem;
`;
const MoneyBox = styled.div`
  // border-bottom: 1px solid black;
  // border-bottom-style: dotted;
`;
const SignatureBox = styled.div`
  padding: 0.5rem 0;
  border-bottom: 2px solid black;
  display: flex;
  justify-content: center;
  .img {
    width: 100%;
    height: 190px;
    margin: auto;
    background-position-x: center;
    background-position-y: center;
    background-size: contain;
    background-repeat: no-repeat;
    background: transparent;
  }
  .blank {
    width: 100%;
    height: 4.5rem;
  }
  align-items: end;
`;

const FinalBox = styled.div<{ border?: boolean }>`
  padding: 0.5rem 0 2rem;
  padding-bottom: 0.5rem;
  text-align: center;
  font-weight: 400;
  .bold {
    font-weight: 600;
  }
  ${({ border }) => border && `
    margin-top: 1rem;
    padding-top: 0.5rem;
    margin-right: 1rem;
    margin-left: 1rem;
    border-top: 1px solid black;
  `}
`;
