import React, { useEffect } from 'react';
import { ColumnsType } from 'antd/es/table';
import Table from 'components/Table';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { IActiveCoupon } from 'features/customer/services/types/coupon';
import { formatCurrency } from 'utils/formatCurrency';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Button from 'components/Button';
import { IActiveCouponsParams } from 'features/customer/services/types/api';
import moment from 'moment';
type ICouponsTableProps = {};

const CouponActiveTable: React.FC<ICouponsTableProps> = () => {
  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const customerDetails = customerSelectors.getCustomerDetails();

  const data = customerSelectors.activeCouponTableData.data();
  const loading = customerSelectors.activeCouponTableData.loading();
  const params = customerSelectors.activeCouponTableData.params();
  const totalElement = customerSelectors.activeCouponTableData.totalElement();

  const handleDeleteCoupon = (coupon: IActiveCoupon) => {
    const cb = () => dispatch(customerActions.deleteActiveCoupon(coupon.id));
    modalConfirm.current?.open(`Do you want to remove ${coupon.couponName || ''}?`, cb);
  };

  const handlePageChange = async (page?: number) => {
    const params = {
      page: page,
    };
    dispatch(customerActions.setActiveCouponsParams(params));
  };

  useEffect(() => {
    if (customerDetails?.id) {
      const params: IActiveCouponsParams = {
        customerId: customerDetails?.id || '',
        page: 1,
      };
      dispatch(customerActions.setActiveCouponsParams(params));
    }
  }, [customerDetails?.id]);


  const columns: ColumnsType<IActiveCoupon> = [
    {
      title: 'Coupon Name',
      dataIndex: 'couponName',
      align: 'left',
      render(couponName) {
        return (
          <Text variant="BODY_1" color="text_3">
            {couponName || '--'}
          </Text>
        );
      },
    },
    {
      title: 'Coupon Code',
      dataIndex: 'couponCode',
      align: 'left',
      render(couponCode) {
        return (
          <Text variant="BODY_1" color="text_3">
            {couponCode || '--'}
          </Text>
        );
      },
    },
    {
      title: 'Discount',
      dataIndex: 'discountValue',
      align: 'left',
      render(_, { discountType, discountValue = 0 }) {

        if (discountType === 'MONEY') {
          return (
            <Text variant="BODY_1" color="text_3">{formatCurrency(discountValue)}</Text>
          );
        }
        return (
          <Text variant="BODY_1" color="text_3">{discountValue}%</Text>
        );
      },
    },
    {
      title: 'Create Date',
      dataIndex: 'createdDate',
      align: 'left',
      render(createdDate) {
        return (
          <Text variant="BODY_1" color="text_3">
            {createdDate ? moment(createdDate).format('MM-DD-YYYY') : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Expire Date',
      dataIndex: 'endTime',
      align: 'left',
      render(endTime) {
        return (
          <Text variant="BODY_1" color="text_3">
            {endTime ? moment(endTime).format('MM-DD-YYYY') : '--'}
          </Text>
        );
      },
    },
    {
      title: 'Source Type',
      dataIndex: 'sourceType',
      align: 'left',
      render(sourceType) {
        return (
          <Text variant="BODY_1" color="text_3">
            {sourceType || '--'}
          </Text>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      align: 'left',
      render(ticketNumber, record) {
        return (
          <Button cardCashier size='small' ntype="PRIMARY" onClick={() => handleDeleteCoupon(record)} ><Text variant='CONTENT_2'>Delete</Text></Button>
        );
      },
    },
  ];

  return (
    <>
      <CouponsTableStyled>
        <Table
          loading={loading}
          columns={columns}
          dataSource={data}
          rowKey={'couponCode'}
          pagination={{
            total: totalElement ?? 0,
            pageSize: params.size,
            current: params.page,
            showSizeChanger: false,
          }}
          handleChangePage={handlePageChange}
        />
      </CouponsTableStyled>
      <ModalConfirm ref={modalConfirm} />
    </>
  );
};

export default CouponActiveTable;
const CouponsTableStyled = styled.div``;
