import { Card, Col, Rate, Row } from 'antd';
import Text from 'components/Text';
import reportSelectors from 'features/report/services/selectors';
import styled from 'styled-components';
import ReviewDashboardV2 from './ReviewDashboardV2';
type Props = {
  v2?: boolean;
};
const ReviewDashboard = ({ v2 }: Props) => {
  const summary = reportSelectors.getReviewReportSummary();
  if (v2) return <ReviewDashboardV2 data={summary} />;
  return (
    <Row gutter={[8, 8]}>
      <Col
        md={{ span: 6 }}
      >
        <StyledCard>
          <StyledCardBody>
            <RateTextBox>
              <Text variant='CONTENT_1' color='text_2'>{'STAR'}</Text>
              <BottomRow>
                <Rate value={summary?.averageStars} disabled />
                <Text variant='CONTENT_1' color='text_2' className='text-large text-average'>{summary?.averageStars}</Text>
              </BottomRow>
            </RateTextBox>
          </StyledCardBody>
        </StyledCard>
      </Col>
      <SubDashboard label='TOTAL REVIEW' value={summary?.total || 0} />
      <SubDashboard label='5 STARS' value={summary?.details?.[5] || 0} />
      <SubDashboard label='4 STARS' value={summary?.details?.[4] || 0} />
      <SubDashboard label='3 STARS' value={summary?.details?.[3] || 0} />
      <SubDashboard label='2 STARS' value={summary?.details?.[2] || 0} />
      <SubDashboard label='1 STAR' value={summary?.details?.[1] || 0} />
    </Row>
  );
};

export default ReviewDashboard;

const SubDashboard = ({ label, value }: { label: string, value: number }) => (
  <Col md={{ span: 3 }}>
    <StyledCard>
      <StyledCardBody>
        <RateTextBox>
          <Text style={{ marginBottom: '12px' }} variant='CONTENT_1' color='text_2'>{label}</Text>
          <Text variant='CONTENT_2' color='text_2' className='text-large'>{value}</Text>
        </RateTextBox>
      </StyledCardBody>
    </StyledCard>
  </Col>
);

const StyledCard = styled(Card)`
    border-radius: 3.358px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 0.672px solid #86909C;
    background: #FFF;
    display:flex;
    align-items: center;
    height: 100%;
    overflow: hidden;
    .ant-card-body {
        flex: 1;
        padding: 0;
    }
`;

const BottomRow = styled.div`
    display: flex;
    gap: 16px;
    align-items: end;
`;

const StyledCardBody = styled(Row)`
    flex-wrap: nowrap;
    padding: 10px;
    justify-content: space-between;
`;

const RateTextBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    flex: 1;
    .ant-rate {
      font-size: 35px;
    }
    .CONTENT_1 {
      font-size: 20px;
    }

    .text-large {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 26px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .text-average {
      margin-bottom: 2px;
    }
`;