import Button from 'components/Button';
import Empty from 'components/Empty';
import React from 'react';
import styled from 'styled-components';
import { isNumberText } from 'utils/validation';
type Props = {
  searchText?: string;
  onOpenAddNew?: (value: { name?: string, phone?: string }) => void;
};
const NotFoundCustomerBox = ({ searchText, onOpenAddNew = () => undefined }: Props) => {
  const handleOpenAddNew = () => {
    let phone = '';
    let name = '';
    if (isNumberText(searchText)) {
      phone = searchText || '';
    } else {
      name = searchText || '';
    }
    onOpenAddNew({ phone, name });
  };

  return (
    <Container>
      <Empty description='' />
      <Button ntype='TEXT' size='small' icon='addPlus' onClick={handleOpenAddNew}>Add New Customer</Button>
    </Container>
  );
};

export default NotFoundCustomerBox;

const Container = styled.div`
  background: #fff;
  padding: 1rem;
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;
