import { ThemeHomeCheckIn } from '../services/constants';
import CheckInGoogleNoReviewPage from './CheckInGoogleNoReviewPage';
import CheckInGoogleReviewPage from './CheckInGoogleReviewPage';
import CheckInInputPage from './CheckInInputPage';
import { useMemo } from 'react';
import storage from 'utils/sessionStorage';
import checkInSelectors from '../services/selectors';

const HomeCheckInDetach = () => {
  const themeStore = checkInSelectors.homeTheme();
  const theme = useMemo(() => {
    const check_in_theme = storage.check_in_theme.get();

    return themeStore || check_in_theme;
  }, [themeStore]);

  switch (theme) {
    case ThemeHomeCheckIn.GOOGLE_SHOW_REVIEW:
      return <CheckInGoogleReviewPage />;
    case ThemeHomeCheckIn.GOOGLE_NOT_SHOW_REVIEW:
      return <CheckInGoogleNoReviewPage />;
    default:
      return <CheckInInputPage />;
  }
};

export default HomeCheckInDetach;
