
import { AxiosResponse } from 'axios';
import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import uiActions from 'services/UI/actions';
import { setLoading, setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';
import { getPureFilterParams, getPureHistoryFilterParams } from './selectors';
import { IApiHistoryClockInOut, IFilterDataParams } from './types/api';
import { IClockInOutResData, IHistoryClockInOutResData } from './types/clock-in-out';
import { IStaffAvailableResItem } from './types/staff';
import { IBillWaitingTicketResItem } from './types/ticket';
import { ISummaryResData, IWaitingListServiceInfoResItem } from './types/waiting';
import userActions from './actions';
import turnActions from 'features/turn/services/actions';
import appointmentApis from 'features/appointment/services/apis';
import moment from 'moment';
import { DATE_FORMAT } from 'features/appointment/utils/format';

const getSummaryData: ISagaFunc<IFilterDataParams> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getSummaryData, true);
  yield delay(100);
  try {
    const res: AxiosResponse<{ data: ISummaryResData }> = yield call(apis.getSummaryData, payload);
    if (res?.data?.data) {
      yield put(actions.getSummaryData.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getSummaryData, false);
  }
};

const getWaitingList: ISagaFunc = function* () {
  yield setLoading(PATH_LOADING.getWaitingList, true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IWaitingListServiceInfoResItem[]> = yield call(apis.getWaitingList);
    if (res?.data?.data) {
      yield put(actions.getWaitingList.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getWaitingList, false);
  }
};

const getBillWaitingTicket = function* () {
  yield setLoading(PATH_LOADING.getBillWaitingTicket, true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IBillWaitingTicketResItem[]> = yield call(apis.getBillWaitingTicket);
    if (res?.data?.data) {
      yield put(actions.getBillWaitingTicket.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getBillWaitingTicket, false);
  }
};

const doFilterData: ISagaFunc<IFilterDataParams> = function* ({ payload }) {
  const storeParams = yield select(getPureFilterParams);
  const params = {
    ...storeParams ?? {},
    ...payload ?? {}
  };
  yield all([
    put(actions.getSummaryData.fetch(params)),
  ]);
};

const doClockIn: ISagaFunc<string | number> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IClockInOutResData> = yield call(apis.doClockIn, payload);
    if (res.data.data) {
      yield put(actions.doClockIn.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
    yield put(shopActions.get.staffsAvailable.fetch());
    yield put(userActions.getListAvailableWorking.fetch());
  }
};

const doClockOut: ISagaFunc<string | number> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IClockInOutResData> = yield call(apis.doClockOut, payload);
    if (res.data.data) {
      yield put(actions.doClockOut.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
    yield put(shopActions.get.staffsAvailable.fetch());
    yield put(userActions.getListAvailableWorking.fetch());
  }
};

const getListStaffAvailable = function* () {
  yield setLoading(PATH_LOADING.getListStaffAvailable, true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IStaffAvailableResItem[]> = yield call(apis.getStaffAvailable);
    if (res.data.data) {
      yield put(actions.getListStaffAvailable.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getListStaffAvailable, false);
  }
};

const getHistoryClockInOut: ISagaFunc<IApiHistoryClockInOut> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getHistoryClockInOut, true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IHistoryClockInOutResData> = yield call(apis.getHistoryClockInOut, payload);
    if (res.data.data) {
      yield put(actions.getHistoryClockInOut.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getHistoryClockInOut, false);
  }
};

const setHistoryClockInOutParams: ISagaFunc<IApiHistoryClockInOut> = function* ({ payload }) {
  const storeParams = yield select(getPureHistoryFilterParams);
  const _params = {
    ...storeParams ?? {},
    ...payload ?? {},
  };
  yield put(actions.getHistoryClockInOut.fetch(_params));
};

const removePendingTicket: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.removePendingTicket, payload);
    if (res.data.data) {
      yield put(actions.activeRemoveTicket(false));
      yield put(actions.getBillWaitingTicket.fetch());
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const removeWaitingTicket: ISagaFunc<string> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.removeWaitingTicket, payload);
    if (res.data.data) {
      yield put(actions.activeRemoveWaiting(false));
      yield put(actions.getWaitingList.fetch());
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const getListAvailableWorking = function* () {
  yield delay(200);
  try {
    const res: IResponseDataBody<IStaffAvailableItem[]> = yield call(apis.getListAvailableWorking);
    if (res.data.data) {
      yield put(actions.getListAvailableWorking.success(res.data.data));
      yield put(turnActions.getTurnStaffsAvailable.success(res.data.data));
    }
  } catch (error) { }
};

const waitingList = function* () {
  
  try {
    const res: IResponseDataBody<IWaitingListServiceInfoResItem[]> = yield call(apis.getWaitingList);
    if (res?.data?.data) {
      yield put(actions.getWaitingList.success(res.data.data));
    }
  } catch (error) { }
};

const availableList = function* () {
  try {
    const res: IResponseDataBody<IStaffAvailableItem[]> = yield call(apis.getListAvailableWorking);
    if (res.data.data) {
      yield put(actions.getListAvailableWorking.success(res.data.data));
      yield put(turnActions.getTurnStaffsAvailable.success(res.data.data));
    }
  } catch (error) { }
};

const pendingTicket = function* () {
  try {
    const res: IResponseDataBody<IBillWaitingTicketResItem[]> = yield call(apis.getBillWaitingTicket);
    if (res?.data?.data) {
      yield put(actions.getBillWaitingTicket.success(res.data.data));
    }
  } catch (error) { }
};

const syncWaitingToTicket: ISagaFunc<{ staffId: string, checkInId: string }> = function* ({ payload }) {
  yield put(uiActions.setLoadingPage(true));
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.syncWaitingToTicket, payload.staffId, payload.checkInId);
    if (res.data.data) {
      yield all([fork(waitingList), fork(availableList), fork(pendingTicket)]);
    }
  } catch (error) { }
  finally {
    yield put(uiActions.setLoadingPage(false));
  }
};

const getAppointmentToday = function* ({ }) {
  yield delay(200);
  const today = moment().format(DATE_FORMAT);
  try {
    const res: IResponseDataBody<boolean> = yield call(appointmentApis.getAppointments, { endTime: today, startTime: today });
    if (res.data.data) {
      yield put(actions.getAppointmentToday.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getAppointmentToday.fail({}));
  }
};

export default function* userServiceSagas() {
  yield takeLatest(actions.doFilterData, doFilterData);
  yield takeLatest(actions.getWaitingList.fetch, getWaitingList);
  yield takeLatest(actions.getSummaryData.fetch, getSummaryData);
  yield takeLatest(actions.getBillWaitingTicket.fetch, getBillWaitingTicket);
  yield takeLatest(actions.doClockIn.fetch, doClockIn);
  yield takeLatest(actions.doClockOut.fetch, doClockOut);
  yield takeLatest(actions.doClockIn.success, getHistoryClockInOut);
  yield takeLatest(actions.doClockOut.success, getHistoryClockInOut);
  yield takeLatest(actions.getListStaffAvailable.fetch, getListStaffAvailable);
  yield takeLatest(actions.getHistoryClockInOut.fetch, getHistoryClockInOut);
  yield takeLatest(actions.setHistoryClockInOutParams, setHistoryClockInOutParams);
  yield takeLatest(actions.removePendingTicket, removePendingTicket);
  yield takeLatest(actions.removeWaitingTicket, removeWaitingTicket);
  yield takeLatest(actions.getListAvailableWorking.fetch, getListAvailableWorking);
  yield takeLatest(actions.syncWaitingToTicket, syncWaitingToTicket);
  yield takeLatest(userActions.getAppointmentToday.fetch, getAppointmentToday);
}
