import { CurrencyInputRef, unmaskedCurrency } from 'components/CurrencyInputMasked';
import { toCurrency } from 'components/CurrencyInputMasked/currency-conversion';
import Text from 'components/Text';
import { debounce } from 'lodash';
import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';
import InputKeyboardView, { InputViewProps } from '../InputKeyboardView';
import NumberPad from '../NumberPad';
import CurrencyTipInput from 'components/CurrencyInputMasked/CurrencyTipInput';

const isMobile = isMobileBrowser();

export interface ICurrencyKeyBoardTip {
  value?: number | null;
  onChange?: (value: ICurrencyKeyBoardTip['value']) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
  initialAmount?: number;
  modeTip?: boolean;
  maxValue?: number;
  hint?: boolean;
}

export type CurrencyKeyBoardTipRef = {
  showError: (text: string) => void,
  hideError: () => void,
  clear: () => void,
  setValue: (val: string) => void,
  setSoftValue: (val: string) => void,
};
const inputPlaceholder = '$0.00';
const CurrencyKeyBoardTip = forwardRef<CurrencyKeyBoardTipRef, ICurrencyKeyBoardTip>(({
  value,
  disableInput,
  onChange,
  inputViewProps = {},
  inputClassName = '',
  modeTip,
  maxValue,
  hint = true,
}, ref) => {
  const inputRef = useRef<CurrencyInputRef>(null);
  const [text, setText] = useState<string>();
  const [error, setError] = useState<string | undefined>();

  useImperativeHandle(ref, () => ({
    showError: (text: string) => setError(text),
    hideError: () => setError(''),
    clear: () => setText(undefined),
    setValue: (_text: string) => onNumberPadChange(_text),
    setSoftValue: (_text: string) => {
      if (isMobile) {
        const _val = toCurrency(_text);
        const raw = unmaskedCurrency(_val);
        if (maxValue)
          if (raw > maxValue) return;
        setText(_val);
        return;
      }
      setText(_text);
      inputRef.current?.setSoftValue(_text);
    },
  }));

  const _debounce = useCallback(debounce((inputValue: ICurrencyKeyBoardTip['value']) => typeof onChange === 'function' && onChange(inputValue), 200), [onChange]);

  const onNumberPadChange = (val: string) => {
    if (isMobile) {
      const _val = toCurrency(val);
      const raw = unmaskedCurrency(_val);
      if (maxValue)
        if (raw > maxValue) return;
      setText(_val);
      _debounce(raw);
      return;
    }
    inputRef.current?.setValue(val);
  };

  // const onWebInputChange = (val: number | null | undefined, format: string) => {
  //   setText(format);
  //   if (typeof onChange === 'function') onChange(val || null);
  // };

  const _handleChangeWebInput = (format: string) => {
    setText(format);
    const val = unmaskedCurrency(format);
    _debounce(val || null);
  };

  return (
    <CurrencyKeyBoardTipStyled>
      {!disableInput &&
        <InputViewStyled>
          {
            isMobile ? <>
              <InputKeyboardView
                className={`input-mobile ${inputClassName ?? ''}`}
                value={text}
                {...inputViewProps}
                type={'CURRENCY'}
                placeholder={inputPlaceholder}
              />
            </> : <>
              <CurrencyTipInput
                ref={inputRef}
                defaultValue={value}
                setTextStr={_handleChangeWebInput}
                maxValue={maxValue}
              />
            </>}
        </InputViewStyled>}
      {error && <Text textAlign='center' mt={0.5} style={{ color: '#f53f3f' }}>{error}</Text>}
      <Text mb={1} />
      <NumberPad
        value={text}
        onChange={onNumberPadChange}
        type={'NUMBER'}
        hint={hint}
        modeTip={modeTip}
      />
    </CurrencyKeyBoardTipStyled >
  );
});
CurrencyKeyBoardTip.displayName = 'CurrencyKeyBoardTip';
export default CurrencyKeyBoardTip;

const CurrencyKeyBoardTipStyled = styled.div`
  display: flex;
  flex-direction: column;
  .text-percent {
    color: var(--text-text-2, #767676);
    text-align: center;
    font-family: Open Sans;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .NUMBER-PAD-BUTTON {
    height: 6rem;
    width: 6rem;
  }
  .NUMBER-PAD {
    font-size: 2.5rem;
  }
`;

const InputViewStyled = styled.div`
  width: 100%;
  input {
    width: 100%;
    border-radius: 2px;
    border: 1px solid #86909C;
    background: none;
    box-shadow: none;
    border: none;

    color: var(--text-text-3, #1D2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    // height: 4.5rem;

    &:focus, &:hover {
      outline: none;
      border: none;
    }

    color: var(--text-text-3, #1D2129);
    font-family: Open Sans;
    font-size: 3rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .input-mobile {
    background: none;
    box-shadow: none;
    border: none;
    margin-bottom: 0;
    .box {
      color: var(--text-text-3, #1D2129);
      &.placeholder {
        color: var(--text-text-3, #1D2129);
      }
      font-family: Open Sans;
      font-size: 3rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      &:after {
        font-size: 2rem;
      }
    }
  }
`;