import { Button, Input, InputRef, Spin } from 'antd';
import Modal from 'components/Modal';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Text from 'components/Text';
import React, { useState } from 'react';
import styled from 'styled-components';
import { IUseGetInfoGiftCardType } from './useGetInfoGiftCard';
import qrScanIcon from './QR-scan.svg';
import giftCardBgIcon from './gift-card-bg-icon.svg';
import { formatCurrency } from 'utils/formatCurrency';
import moment from 'moment';
import shopSelectors from 'services/shop/selectors';
import Box from 'components/Box';
import { InputWithKeyboardStyled } from 'components/DollarAmount/Input';
import DollarAmountKeyboard from 'components/DollarAmount/Keyboard';
import { DollarAmountInputRef } from 'components/DollarAmount/helpers';
import NumberPadV2 from 'components/NumberKeyBoard/Currency/NumberPadV2';
type Props = {
  visible?: boolean;
  onClose?: () => void;
  onSubmit: () => void;
  currencyRef: React.RefObject<CurrencyKeyBoardRef>;
  dollarRef: React.RefObject<DollarAmountInputRef>;
  amount: number | null;
  setAmount: (value: React.SetStateAction<number | null>) => void
  isValid?: boolean;
  giftCard: IUseGetInfoGiftCardType;
  inputCodeRef: React.RefObject<InputRef>;
  onScan: () => void;
  giftCardBalance: number;
};
const ModalGiftCardPaymentV2 = ({ giftCardBalance, onScan, inputCodeRef, giftCard, isValid, onSubmit, setAmount, amount, dollarRef, currencyRef, visible, onClose = () => undefined }: Props) => {
  const allSetting = shopSelectors.data.allSetting();
  const [isNumberCard, setIsNumberCard] = useState(false);
  const handleChangeGiftCardCode = (code: string) => {
    giftCard.setCode(code);
    setIsNumberCard(true);
  };

  const onKBFocus = (type: 'currency' | 'numberCard') => () => setIsNumberCard(type === 'numberCard');

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      width={'auto'} footer={null} className='modal-overflow-unset modal-non-opacity'
      hiddenHeader
      noneBodyStyle
      okTitle='Redeem'
      onSubmit={onSubmit}
      disableOk={!isValid}
    >
      <ContainerModal>
        <ContainerKeyboard>
          <HeaderModal>
            <div className="modal-title">gift cards</div>
          </HeaderModal>
          <Spin spinning={giftCard.loading}>
            <GiftCardNumberContainer>
              <Text className='modal-label' mb={0.5}>
                Gift Card Number:
              </Text>
              <div className="input-selector-container">
                <Input
                  ref={inputCodeRef}
                  className='gift-card-number-input' size='small' value={giftCard.code} onChange={e => giftCard.setCode(e.target.value)}
                  onFocus={onKBFocus('numberCard')}
                />
                <button type='button' className='btn-scan' onClick={onScan}><img src={qrScanIcon} alt="qrScanIcon" className='qr-scan-icon' /></button>
              </div>
              {giftCard.msg && <div className="modal-warning">{giftCard.msg}</div>}
            </GiftCardNumberContainer>
          </Spin>

          {!allSetting?.activeFormatMoney ? <>
            <CurrencyKeyBoard v2 onFocus={onKBFocus('currency')} isHiddenKB={isNumberCard} ref={currencyRef} value={amount} onChange={val => setAmount(val || 0)} />
            <div hidden={!isNumberCard}>
              <NumberPadV2
                type={'NUMBER'}
                value={giftCard.code}
                controlRef={inputCodeRef}
                onChange={handleChangeGiftCardCode}
              />
            </div>
          </> : <>
            <Box display='flex' flexDirection='column' gap='2'>
              <Text className='modal-keyboard-label' mt={1} mb={-0.5}>
                Enter Amount:
              </Text>
              <InputWithKeyboardStyled
                ref={dollarRef}
                value={amount}
                onChange={setAmount}
                inputMode='none'
                onFocus={onKBFocus('currency')}
              />
              <div hidden={!isNumberCard}>
                <NumberPadV2
                  type={'NUMBER'}
                  value={giftCard.code}
                  controlRef={inputCodeRef}
                  onChange={handleChangeGiftCardCode}
                />
              </div>
              <div hidden={isNumberCard}>
                <DollarAmountKeyboard
                  controlRef={dollarRef}
                  value={amount}
                  onChange={setAmount}
                />
              </div>
            </Box>
          </>}
          <FooterActions>
            <Button onClick={onClose} htmlType='button' type='default'><span>Close</span></Button>
            <Button disabled={!isValid} onClick={onSubmit} htmlType='button' type='primary'><span>Redeem</span></Button>
          </FooterActions>
        </ContainerKeyboard>
        <GiftCardDetailContainer>
          <img src={giftCardBgIcon} alt="giftCardBgIcon" className='gift-card-bg-icon' />
          <div className="detail-item">
            <div className="detail-item-label">Gift Card Number</div>
            <div className="detail-item-amount">
              <div className="detail-item-amount-text">
                {giftCard.data?.giftCode || '--'}
              </div>
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-item-label">Balance</div>
            <div className="detail-item-amount">
              <div className="detail-item-amount-text">
                {formatCurrency(giftCardBalance > 0 ? giftCardBalance : 0)}
              </div>
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-item-label">Available</div>
            <div className="detail-item-amount">
              <div className="detail-item-amount-text">
                {formatCurrency(giftCard.resultGiftCard.available)}
              </div>
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-item-label">Used</div>
            <div className="detail-item-amount">
              <div className="detail-item-amount-text">
                {formatCurrency(giftCard.resultGiftCard.used)}
              </div>
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-item-label">Amount</div>
            <div className="detail-item-amount">
              <div className="gift-amount-box">
                <div className="detail-item-amount-text">
                  {formatCurrency(giftCard.resultGiftCard.amount)}
                </div>
              </div>
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-item-label">Last Used Date</div>
            <div className="detail-item-amount">
              <div className="detail-item-amount-text">
                {giftCard.data?.updatedDate ? moment(giftCard.data?.updatedDate).format('MM-DD-YYYY') : '__/__/____'}
              </div>
            </div>
          </div>
          <div className="detail-item">
            <div className="detail-item-label">Expiration</div>
            <div className="detail-item-amount">
              <div className="detail-item-amount-text">
                {giftCard.resultGiftCard.expireDateV2}
              </div>
            </div>
          </div>
        </GiftCardDetailContainer>
      </ContainerModal>
    </Modal >
  );
};

export default ModalGiftCardPaymentV2;
const ContainerModal = styled.div`
  display: flex;
`;
const HeaderModal = styled.div`
 border-radius: 5px 5px 0px 0px;
  background: #232F3E;
  padding: 12px 0;
  height: auto;
  .modal-title {
    color: #FFF;
    background: #232F3E;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;
const GiftCardNumberContainer = styled.div`
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
flex-direction: column;  
.modal-label {
  color: #64748B;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modal-warning {
  color: #ff7d00;
  text-align: center;
  font-family: Poppins;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input-selector-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 64px;
  margin: 0 40px;
  
  .qr-scan-icon {
    width: 64px;
    height: 64px;
  }

  .btn-scan {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    background: #FFF1F6;
    position: absolute;
    right: 0;
  }
}
.gift-card-number-input {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  align-self: stretch;
  font-size: 30px;
  height: unset;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  outline: none;
  box-shadow: none;
}
`;
const FooterActions = styled.div`
  display: flex;
  padding: 16px !important;
  align-items: center;
  gap: 16px;
  button.ant-btn {
    color: #1D2129;
    border: 1px solid #86909C;
    display: flex;
    height: 70px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0;
    border-radius: 4px;
    background: #E5E6EB;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.ant-btn-primary {
      background-color: #ff8890;
      border: 1px solid #f5767f;
      color: #1d2129;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
`;
const ContainerKeyboard = styled.div`
  width: 980px;

`;
const GiftCardDetailContainer = styled.div`
  display: flex;
  width: 567px;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  position: relative;
  background: #FFF;
  overflow: hidden;
  .gift-card-bg-icon {
    display: flex;
    width: 450px;
    height: 450px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    bottom: -60px;
  }
  .detail-item {
    position: relative;
    display: flex;
    padding: 16px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1.5px solid #86909C;
    flex: 1;
    .detail-item-label {
      color: #505050;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .detail-item-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      .detail-item-amount-text {
        flex: 1 0 0;
        color: #2D6772;
        text-align: center;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }

      .gift-amount-box {
        display: flex;
        height: 56px;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #232F3E;
        .detail-item-amount-text {
          color: #FFF;
          text-align: center;
          font-family: Poppins;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
`;
