import { AutoScrollActivator, closestCenter, DndContext, DragEndEvent, DragOverlay, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import cashierActions from 'features/cashier/services/actions';
import { encodeDraftServiceSwitchStaffId } from 'features/cashier/services/constants';
import { cashierUIActions } from 'features/cashier/services/reducers';
import cashierSelectors from 'features/cashier/services/selectors';
import { IService, IStaff, IStaffItem } from 'features/cashier/services/types/api';
import { IDraftQuickPayItem } from 'features/cashier/services/types/quickpay';
import { first } from 'lodash';
import React, { useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import ItemStaffTicket, { IPropertyDraftQuickPay } from '../ItemsDnD/ItemStaffTicket';
import PaymentResult from '../PaymentResult';
import TotalLabel from '../TickerFooter/TotalLabel';
import { ModalAddStaff } from './GroupActions/ButtonAddStaff';
import clsx from 'clsx';
import RequestAdminPWCallback, { useRequestAdminPWCallbackRef } from 'widgets/RequestAdminLocalPW/Callback';
type IComponentProps = {
  staffAndService?: string;
  v2?: boolean;
};

const BillSelectedItems: React.FC<IComponentProps> = ({ v2 }) => {
  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 10,
    },
  });
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 500,
      tolerance: 8,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor,);

  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const itemMode = cashierSelectors.getItemHandleMode();
  const highlightItemId = cashierSelectors.getHighlightItemId();

  const [draffStaffId, setDraffStaffId] = useState<string | null>(null);

  const draftServiceSwitchStaff =
    cashierSelectors.getDraftServiceSwitchStaff();

  const selectedItems = cashierSelectors.getSelectedItems();
  const activeStaff = cashierSelectors.getActiveStaffId();
  const allSetting = shopSelectors.data.allSetting();
  const staffs = shopSelectors.data.staffs();
  const pwRef = useRequestAdminPWCallbackRef();

  const setActiveStaff = (staffId: string | string[]) => {
    const id = typeof staffId === 'string' ? staffId : first(staffId) || '';
    if (!id) return;
    dispatch(cashierActions.setActiveStaffId(id));
  };

  const handleRemoveStaff = (staffId: string) => {
    modalConfirm.current?.open('Do you like to remove this items?', () => dispatch(cashierActions.removeStaffItem(staffId)));
  };

  const setDraftServiceSwitchStaff = (staffId: string, itemId: string) => {
    dispatch(
      cashierActions.setDraftServiceSwitchStaff(
        encodeDraftServiceSwitchStaffId({ staffId, itemId })
      )
    );
  };

  const handleUpdateQuickPay = (type: IDraftQuickPayItem['typeDraft'], draft: IPropertyDraftQuickPay) => (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    dispatch(cashierActions.setDraftQuickPay({
      staffId: draft.staffId,
      itemId: draft.itemId,
      value: draft.value,
      itemName: draft.itemName,
      typeDraft: type
    }));
  };

  const onPickNewStaff = (newStaff: IStaff) => {
    if (!draffStaffId) return;
    dispatch(cashierActions.switchStaffNew({
      currStaffId: draffStaffId,
      newStaff,
    }));
    setDraffStaffId(null);
  };

  const handleSwitchStaff = (staffId: string) => dispatch(cashierActions.switchStaffItem(staffId));

  const onDragEnd = (event: DragEndEvent) => {
    const { staffId: dragStaffId, uuid: dragServiceId } = event.active.data.current as { staffId: string, uuid: string } || {};
    dispatch(cashierUIActions.switchService({
      dragServiceId,
      dragStaffId,
      targetStaffId: event.over?.id as string || '',
    }));
  };

  const onHandleDiscount = (e: React.MouseEvent, staff: IStaffItem, service: IService) => {
    e.stopPropagation();
    const callBack = () => dispatch(
      cashierActions.setDraftDiscountItemModal({
        staffId: staff.staffId,
        service_uuid_local: service.uuid_local || '',
        amount: service.price,
        discount: service.discount,
      })
    );
    if (allSetting?.verifyPassDiscountItem) {
      pwRef.current?.open(callBack);
    } else {
      callBack();
    }
  };
  
  return (
    <>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={onDragEnd}
        autoScroll={{ enabled: true, activator: AutoScrollActivator.Pointer }}
      >
        <WrapStyled className='box'>
          <ContainerItems>
            <div className="container-items-scroll">
              <BillSelectedItemsStyled className={clsx('flex-grow', selectedItems.length > 1 ? '' : 'singleItem', v2 && 'v2')}>
                {selectedItems?.map(item => {
                  const staff = staffs.find(o => o.id === item.staffId);
                  return (
                    <ItemStaffTicket
                      v2={v2}
                      key={item.staffId}
                      data={item}
                      staff={staff}
                      active={!!(selectedItems?.length > 1 && item.staffId === activeStaff)}
                      activeDrag={selectedItems?.length > 1}
                      onActiveStaff={setActiveStaff}
                      setDraffStaffId={setDraffStaffId}
                      onRemoveStaff={handleRemoveStaff}
                      draftServiceSwitchStaff={draftServiceSwitchStaff}
                      highlightItemId={highlightItemId}
                      enableSwitchStaff={selectedItems.length > 1}
                      setDraftServiceSwitchStaff={setDraftServiceSwitchStaff}
                      handleUpdateQuickPay={handleUpdateQuickPay}
                      itemMode={itemMode}
                      allSetting={allSetting}
                      onHandleDiscount={onHandleDiscount}
                      onSwitchStaff={handleSwitchStaff}
                    />
                  );
                })}
              </BillSelectedItemsStyled>
            </div>
          </ContainerItems>
          <BillSelectedItemsStyled>
            <PaymentResult />
            <TotalLabel />
          </BillSelectedItemsStyled>
        </WrapStyled>
        <DragOverlay />
      </DndContext>
      <ModalAddStaff
        v2={v2}
        visible={!!draffStaffId}
        onPickStaff={onPickNewStaff}
        onClose={() => setDraffStaffId(null)}
      />
      <ModalConfirm ref={modalConfirm} />
      <RequestAdminPWCallback v2={v2} ref={pwRef} onlyAdmin />
    </>
  );
};

export default BillSelectedItems;

const ContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  .container-items-scroll {
    position: absolute;
    inset: 0;
    overflow: auto;
     scrollbar-width: none; 
    &::-webkit-scrollbar {
      width: 0px;
    }
  }
`;

const WrapStyled = styled.div`
  max-height: 100%;
  overflow: auto;
  
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  .flex-grow {
    flex: 1;
    flex-grow: 1;
  }
  background-color: #f6f7fc;
  &.box {
    width: 100%;
    margin-top: 0.5rem;
    border-radius: 5px;
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
`;

const BillSelectedItemsStyled = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;

  &.v2 {
    .ItemStaffTicket .ItemCollapseStyled {
      .CONTENT_1 {
        font-size: 20px;
      }
    }
  }
`; 