import { Button, Col, Row } from 'antd';
import Modal from 'components/Modal';
import Text from 'components/Text';
import LogoBox from 'features/checkIn/components/LogoBox';
import checkInActions from 'features/checkIn/services/actions';
import checkInApis from 'features/checkIn/services/apis';
import { ThemeHomeCheckIn } from 'features/checkIn/services/constants';
import checkInSelectors from 'features/checkIn/services/selectors';
import { IDeviceThemeCheckInResData } from 'features/checkIn/services/types/theme';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import storage from 'utils/sessionStorage';
import checkInScreenshot from './checkin-screenshot.png';
import googleReview2Screenshot from './google-review-2-screenshot.png';
import googleReview1Screenshot from './google-review-screenshot.png';
import tickIcon from './tick.png';

enum ACTIONS {
  REFRESH = 'Refresh',
  BACK_TO_LOGIN = 'Back To Login',
  CHANGE_THEME = 'Change Themes',
}

const ModalHelpers = () => {
  const [visible, setVisible] = useState(false);
  const [visibleChooseTheme, setVisibleChooseTheme] = useState(false);
  const themeCheckIn = checkInSelectors.homeTheme();
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const handle = (action: ACTIONS) => {
    setVisible(false);
    switch (action) {
      case ACTIONS.CHANGE_THEME:
        setVisibleChooseTheme(true);
        break;
      case ACTIONS.REFRESH:
        window.location.reload();
        break;
      case ACTIONS.BACK_TO_LOGIN:
        window.location.href = `${location.origin}`;
        break;
      default:
        break;
    }
  };

  const handleChooseTheme = (theme: ThemeHomeCheckIn) => async () => {
    if (themeCheckIn === theme) return;
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await checkInApis.updateThemeHomeCheckIn(theme);
      if (res.data.data) {
        const resTheme: IResponseDataBody<IDeviceThemeCheckInResData> = await checkInApis.getHomeCheckInTheme(storage.device_id.get());
        if (resTheme.data.data)
          dispatch(checkInActions.getHomeTheme.success(resTheme.data.data));
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
    }
  };

  return (
    <>
      <button onClick={() => setVisible(true)}><LogoBox lg /></button>
      <Modal
        visible={!!visible}
        onClose={() => setVisible(false)}
        noneBodyStyle
        containerPadding={1.5}
        hiddenFooter
        modalTitle='Actions'
        className='modal-overflow-unset'
        cancelTitle='Close'
        width={'30.125rem'}
        showClose
      >
        <Buttons gutter={[16, 16]}>
          <Col span={24}>
            <ButtonStation onClick={() => handle(ACTIONS.CHANGE_THEME)}>
              <Text variant='H7' color='text_3' className='text'>{ACTIONS.CHANGE_THEME}</Text>
            </ButtonStation>
          </Col>
          <Col span={24}>
            <ButtonStation onClick={() => handle(ACTIONS.REFRESH)}>
              <Text variant='H7' color='text_3' className='text'>{ACTIONS.REFRESH}</Text>
            </ButtonStation>
          </Col>
          <Col span={24}>
            <ButtonStation onClick={() => handle(ACTIONS.BACK_TO_LOGIN)}>
              <Text variant='H7' color='text_3' className='text'>{ACTIONS.BACK_TO_LOGIN}</Text>
            </ButtonStation>
          </Col>
        </Buttons>
      </Modal>
      <Modal
        visible={visibleChooseTheme} onClose={() => setVisibleChooseTheme(false)}
        modalTitle='Choose Theme'
        className='modal-overflow-unset'
        cancelTitle='Close'
        width={'90vw'}
        containerPadding={1.5}
        noneBodyStyle
        noneFooterStyle
        showClose
        footer={<Text />}
      >
        <ThemeContainer>
          <Button onClick={handleChooseTheme(ThemeHomeCheckIn.CHECK_IN)} className="image-item" style={{ backgroundImage: `url(${checkInScreenshot})` }}>
            {themeCheckIn === ThemeHomeCheckIn.CHECK_IN && <TickIcon />}
          </Button>
          <Button onClick={handleChooseTheme(ThemeHomeCheckIn.GOOGLE_NOT_SHOW_REVIEW)} className="image-item" style={{ backgroundImage: `url(${googleReview1Screenshot})` }}>
            {themeCheckIn === ThemeHomeCheckIn.GOOGLE_NOT_SHOW_REVIEW && <TickIcon />}
          </Button>
          <Button onClick={handleChooseTheme(ThemeHomeCheckIn.GOOGLE_SHOW_REVIEW)} className="image-item" style={{ backgroundImage: `url(${googleReview2Screenshot})` }}>
            {themeCheckIn === ThemeHomeCheckIn.GOOGLE_SHOW_REVIEW && <TickIcon />}
          </Button>
        </ThemeContainer>
      </Modal>
    </>
  );
};

export default ModalHelpers;

const ThemeContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
  .image-item {
    height: 300px;
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: center;
    .tick-icon {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
`;

const Buttons = styled(Row)``;
const ButtonStation = styled.button`
  height: 4.25rem;
  width: 100%;
  border-radius: 5px;
  border: 1px solid var(--line-line-3, #86909C);
  background: var(--fill-fill-1, #F6F7FC);
  display: flex;
  align-items: center;
  padding: 1rem;
  .text {
    flex-grow: 1;
    padding: 0.5rem 0;
    text-align: center;
  }
  &:hover {
    border: 1px solid #86909C !important;
    background-color: #E5E6EB !important;
  }
  &:disabled {
    background-color: #E5E6EB !important;
  }
`;

const TickIcon = () => (
  <img className='tick-icon' src={tickIcon} style={{ width: 48, height: 48 }} />
);
