import clsx from 'clsx';
import Box from 'components/Box';
import Text from 'components/Text';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { IStateDetailPayment } from 'features/payment/services/types/payment';
import { get } from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
type Props = {
  data: IStateDetailPayment | null;
  remaining: number;
  v2?: boolean;
};
const DetailPayment = ({ data, remaining, v2 }: Props) => {
  const describeCard = useMemo(() => [
    { name: 'Points', price: get(data, [PAYMENT_TYPE.LOYALTY_POINT, 'amount'], 0), },
    { name: 'Gift Card', price: get(data, [PAYMENT_TYPE.GIFT_CARD, 'amount'], 0), },
    { name: 'Check', price: get(data, [PAYMENT_TYPE.CHECK, 'amount'], 0), },
    { name: 'VENMO', price: get(data, [PAYMENT_TYPE.VENMO, 'amount'], 0), dynamic: true, },
    { name: 'PAYPAL', price: get(data, [PAYMENT_TYPE.PAYPAL, 'amount'], 0), dynamic: true, },
    { name: 'CASH_APPS', price: get(data, [PAYMENT_TYPE.CASH_APPS, 'amount'], 0), dynamic: true, },
    { name: 'ZELLE', price: get(data, [PAYMENT_TYPE.ZELLE, 'amount'], 0), dynamic: true, },
    { name: 'Cash', price: get(data, [PAYMENT_TYPE.CASH, 'amount'], 0), },
    { name: 'Credit Card', price: get(data, [PAYMENT_TYPE.CREDIT_CARD, 'amount'], 0), },
  ], [data]);

  const chance = get(data, [PAYMENT_TYPE.CASH, 'chance'], 0);

  return (
    <Container className={clsx(v2 && 'v2')}>
      <Box
        px="4"
        py="3"
        display="flex"
        flexDirection="column"
        gap="2"
        bStyle="dotted"
        bb="line_3"
      >
        {describeCard.map((item) => {
          if (item?.dynamic) {
            if (item.price <= 0) return null;
          }
          return (
            <Box className="space-between describeCard" key={item.name}>
              <Text variant="BODY_1" color="text_3" className='label'>
                {item.name}
              </Text>
              <Text variant="CONTENT_2" color="text_3" className='value'>
                {formatCurrency(item.price)}
              </Text>
            </Box>
          );
        })}
      </Box>
      <Box p="2" px='4' className="space-between remaining-box" bgColor="yellow_headline">
        <Text variant="CONTENT_2" color="text_3" className='remaining-label'>
          Remaining
        </Text>
        <Text variant="H5" color="text_3" className='remaining-value'>
          {remaining >= 0 ? formatCurrency(remaining) : 0}
        </Text>
      </Box>
      <Box p="2" px='4' className="space-between" opacity={chance < 0 ? 1 : 0}>
        <Text variant="CONTENT_2" color="text_3">
          Chance
        </Text>
        <Text variant="H5" color="text_3">
          {formatCurrency(chance)}
        </Text>
      </Box>
    </Container>
  );
};

export default DetailPayment;

const Container = styled.div`
&.v2 {
  .describeCard {
    .label, .value {
      font-size: 20px;
    }
  }
  .remaining-box {
    background: #FEEDBF;
    .remaining-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .remaining-value {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
`;