import clsx from 'clsx';
import Box from 'components/Box';
import Text from 'components/Text';
import { parseDecimal } from 'features/cashier/services/constants';
import { IBillDetailData } from 'features/payment/services/types/bill';
import { memo, useMemo } from 'react';
import styled from 'styled-components';
import shopSelectors from 'services/shop/selectors';
import { formatCurrency } from 'utils/formatCurrency';
import multiplePaymentSelectors from '../../../services/selectors';
type Props = {
  data: IBillDetailData | null;
  enableDiscountIncentive?: boolean;
  enableCardFee?: boolean;
  v2?: boolean;
};
const DescribePayment = ({ data, enableDiscountIncentive, enableCardFee, v2 }: Props) => {
  const discountSetting = shopSelectors.data.discountSetting();
  const detailPayment = multiplePaymentSelectors.getDetailPayment();
  const feeCreditCard = shopSelectors.data.feeCreditCard();

  const getFeeCreditValue = (subTotal: number) => {
    if (!enableCardFee) return 0;
    if (!subTotal) return 0;
    if (!feeCreditCard) return 0;
    if (feeCreditCard?.feeType === 'PERCENT')
      return Math.round(subTotal * (feeCreditCard?.feeValue / 100));
    if (feeCreditCard?.feeType === 'MONEY')
      return feeCreditCard?.feeValue || 0;
    return 0;
  };
  const getCashIncentiveDiscount = (subTotal: number) => {
    if (!enableDiscountIncentive) return 0;
    if (!subTotal) return 0;
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return Math.round(subTotal * (discountValue / 100));
    }
    if (discountType === 'MONEY')
      return parseDecimal(discountValue);
    return 0;
  };

  const describePayment = useMemo(() => {
    return [
      { name: 'Credit Fee', price: getFeeCreditValue(detailPayment.CREDIT_CARD?.amount || 0) },
      { name: 'Sale Tax:', price: data?.saleTax },
      { name: 'Use Tax:', price: data?.useTax },
      { name: 'Disc. By Item:', price: -(data?.discountItem || 0) },
      { name: 'Disc. By Ticket:', price: -((data?.discountTicket || 0) + getCashIncentiveDiscount((detailPayment.CASH?.amount || 0) + (detailPayment.CHECK?.amount || 0))) },
    ].filter(o => !!o);
  }, [data, detailPayment, discountSetting, feeCreditCard, enableDiscountIncentive, enableCardFee]);

  const total = useMemo(() => {
    if (!data) return 0;
    const _total = (data?.total || 0) + getFeeCreditValue(detailPayment.CREDIT_CARD?.amount || 0);

    if (enableDiscountIncentive) {
      const discount = (data?.discountTicket || 0) + getCashIncentiveDiscount((detailPayment.CASH?.amount || 0) + (detailPayment.CHECK?.amount || 0));
      return parseDecimal(_total - discount);
    }
    return _total;
  }, [data, detailPayment, discountSetting, feeCreditCard, enableDiscountIncentive]);
  return (
    <Container className={clsx(v2 && 'v2')}>
      <Box display="flex" flexDirection="column" px="4" mb='1'>
        {!v2 ? <Text mt={0.5} mb={0.5} className="divider" /> : <Text mt={0.5} mb={0.5} />}
        <Box className="space-between sub-total">
          <Text variant="BODY_1" color="text_3" className='sub-total-label'>
            Subtotal:
          </Text>
          <Text variant="BODY_1" color="text_3" style={{ fontWeight: '600' }} className='sub-total-value'>
            {formatCurrency(data?.subTotal)}
          </Text>
        </Box>
        <Text mt={0.5} mb={0.5} className="divider" />
        {describePayment.map((item) => {
          return (
            <Box className="space-between describePayment" key={item?.name}>
              <Text variant="BODY_1" color="text_3" mb={0.5} className='label'>
                {item?.name}
              </Text>
              <Text variant="BODY_1" color="text_3" mb={0.5} className='value'>
                {formatCurrency(item?.price)}
              </Text>
            </Box>
          );
        })}
      </Box>
      <Box className='total'>
        <Box p="2" px='4' className="space-between container-total" bgColor="fill_3">
          <Text variant="H6" color="text_3" className='total-label'>
            Total
          </Text>
          <Text variant="H5" color="text_3" className='total-value'>
            {formatCurrency(total)}
          </Text>
        </Box>
      </Box>
    </Container>

  );
};

export default memo(DescribePayment);

const Container = styled.div`
&.v2 {
  .sub-total {
    .sub-total-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .sub-total-value {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
  .describePayment {
    .label, .value {
    font-size: 20px;
    }
  }
  .total {
    .container-total {
      background: #fff;
    }
    .total-label {
      color: #1D2129;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .total-value {
      color: #1D2129;
      text-align: right;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
`;
