import { flatten, get } from 'lodash';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { RootState } from 'store';
import { useAppSelector } from 'store/hooks';
import { ITurnDetail, ITurnTable } from './types';
import { IStaffTurnManualItem, ITurnManualAppointmentItem, ITurnManualItem } from './types/turn';
import { IStaffTurnItem } from '../pages/TurnManualUI/TurnTable/TurnStaffTopRow';
import { TURN_ITEM_STATUS } from './constants';
/**
 * please change reducer name
 */
// @ts-ignore
type MyState = RootState['turn']; // ex: RootState['booking']
// @ts-ignore
const getCurrentState = (state: RootState): MyState => state['turn']; // ex: state['booking']

const selector = <T = MyState>(key: keyof T, defaultValue?: any) => useAppSelector(state => get(getCurrentState(state), key, defaultValue));

const getTurn = () => selector('turn') as ITurnTable;
const getTurnLoading = () => selector('turnLoading') as MyState['turnLoading'];
const getTurnDetail = () => selector('turnDetail') as ITurnDetail;
const getShowModalTurnDetail = () => selector('showModalTurnDetail') as boolean;
const getTurnStaffsAvailable = () => selector('turnStaffsAvailable') as IStaffAvailableItem[];
const selectWaitingListResItem = () => selector('selectWaitingListResItem') as MyState['selectWaitingListResItem'];

export const turnManualSelectors = {
  serviceSelected: () => selector('turnManual.serviceSelected') as MyState['turnManual']['serviceSelected'],
  fullTurnDataServices: () => selector('turnManual.fullTurnData') as MyState['turnManual']['fullTurnData'],
  halfTurnDataServices: () => selector('turnManual.halfTurnData') as MyState['turnManual']['halfTurnData'],
  turns: () => selector('turnManual.turns') as MyState['turnManual']['turns'],
  staffActiveId: () => selector('turnManual.staffActiveId') as MyState['turnManual']['staffActiveId'],
  activeTurnManual: () => selector('turnManual.activeTurnManual') as MyState['turnManual']['activeTurnManual'],
  mappingStaffs: () => {
    const { turnStaffsAvailable: _staffs, turnManual } = useAppSelector(state => state.turn);
    const turns = turnManual.turns || [];
    return _staffs.filter(o => o.activeTurn).map(staff => {
      const tickets: IStaffTurnManualItem[] = turns.filter(o => o.staffId === staff.staffId);
      const staffTurns: ITurnManualItem[] = flatten(tickets.map(o => o.turns));
      const appointments: ITurnManualAppointmentItem[] = flatten(tickets.map(o => o.appointments));
      return ({
        staffId: staff.staffId,
        staffAvatar: staff.staffAvatar,
        staffName: staff.staffName,
        indexClockIn: staff.indexClockIn,
        totalTurn: staffTurns.length || 0,
        totalInprogress: staffTurns.filter(o => o.status === TURN_ITEM_STATUS.IN_PROGRESS).length || 0,
        turns: staffTurns,
        appointments,
      } as IStaffTurnItem);
    });
  },
  inActiveTurnStaffs: () => {
    const _staffs = useAppSelector(state => state.turn.turnStaffsAvailable);
    return _staffs.filter(o => !o.activeTurn);
  },
  visibleItemName: () => selector('turnManual.visibleItemName') as MyState['turnManual']['visibleItemName'],
  sort: () => selector('turnManual.sort') as MyState['turnManual']['sort'],
  settingColors: () => selector('turnManual.settingColors') as MyState['turnManual']['settingColors'],
};

const turnSelectors = {
  getTurn,
  getTurnDetail,
  getShowModalTurnDetail,
  getTurnStaffsAvailable,
  selectWaitingListResItem,
  getTurnLoading
};
export default turnSelectors;
