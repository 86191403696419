import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import VoidPrintBody from './PrintBody';
import ticketListSelectors from 'features/ticketList/services/selectors';

const VoidTicketPrinter = () => {
  const divRef = useRef<HTMLDivElement>(null);
  const doPrint = useReactToPrint({ content: () => divRef.current, });

  const data = ticketListSelectors.printVoidTicketData();

  useEffect(() => {
    if (!data) return;
    setTimeout(() => doPrint(), 100);
  }, [data]);

  return (
    <div style={{ display: 'none' }}><VoidPrintBody data={data} billRef={divRef} isPrinter /></div>
  );
};

export default VoidTicketPrinter;