import { Avatar, Form, Switch } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { SortableList } from 'features/settingService/components/SortableList';
import settingStaffActions from 'features/settingStaff/services/actions';
import settingApis from 'features/settingStaff/services/apis';
import { ISortStaffV2 } from 'features/settingStaff/services/types/api';
import { find } from 'lodash';
import { useState } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import toast from 'utils/toast';

const ButtonSwitchOnline = ({ value, onChange = () => undefined }: { value?: boolean, onChange?: (value: boolean) => void }) => {
  return (
    <button type='button' className='switch-button' onClick={(e) => [e.stopPropagation(), onChange(!value)]}>
      <Switch checked={value} onChange={onChange} />
      <Text variant="BODY_1" color="text_3" style={{ whiteSpace: 'nowrap' }}>
        BOOKING ONLINE
      </Text>
    </button>
  );
};

type IListItemType = {
  id: string;
  name: string;
  avatar: string;
  type: string;
  activeBooking?: boolean;
};
type IListItemsProps = {
  value?: IListItemType[];
  onChange?: (value: IListItemType[]) => void;
};
const ListItems = ({ value: items = [], onChange: setItems = () => undefined }: IListItemsProps) => {
  return (
    <SortableList
      items={items}
      onChange={setItems}
      delayClick={100}
      renderItem={(item, i) => (
        <SortableList.Item id={item.id}>
          <CateWrap>
            <Avatar
              src={item.avatar}
            >
              {item.name?.[0] || 'A'}
            </Avatar>
            <Text style={{ flex: 1 }} variant="H7" color="text_3">
              {item.name}
              <Text variant="BODY_1" color="text_3">
                ({item.type})
              </Text>
            </Text>
          </CateWrap>
          <Text ml={1} />
          <Form.Item name={['items', i, 'activeBooking']} noStyle>
            <ButtonSwitchOnline />
          </Form.Item>
        </SortableList.Item>
      )}
    />
  );
};
const FormStyled = styled(Form)`
.switch-button {
  display: flex;
  align-items: center;
  gap: 4px;
}
`;
const ArrangeStaffModal = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const data = shopSelectors.data.staffs();
  const staffsAvailableBooking = shopSelectors.data.staffsAvailableBooking();

  const setLoading = useSetLoadingPage();
  const handleOpen = () => {
    form.setFieldValue('items', data.filter(o => !o.anyBody).map(o => ({
      id: o.id, name: o.firstName, avatar: o.urlImage, type: o.staffType,
      activeBooking: !!find(staffsAvailableBooking, s => s?.id?.toString() === o?.id?.toString()),
    })));
    setOpen(true);
  };

  const handleAccept = async (values: any) => {
    const items: IListItemType[] = values.items;
    setOpen(false);
    setLoading(true);
    const body: ISortStaffV2[] = items.map(o => ({
      enableBookingOnline: o.activeBooking || false,
      staffId: o.id,
    }));
    try {
      const res: IResponseDataBody<boolean> = await settingApis.sortStaffV2(body);
      if (res.data.data) {
        toast.success('Reorder successful');
        dispatch(shopActions.get.newStaffs());
        const params = {
          page: 1,
          size: 20,
          ignoreAnyBody: true,
        };
        dispatch(settingStaffActions.setParams(params));
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button size='small' icon='arrange' ntype='LIGHT_BLUE' onClick={handleOpen}>Reorder booking</Button>
      <Modal
        visible={open}
        onClose={() => setOpen(false)}
        onSubmit={() => form.submit()}
        modalTitle='Reorder'
        noneBodyStyle
        containerPadding={1}

      >
        <FormStyled form={form} onFinish={handleAccept}>
          <Form.Item noStyle name={'items'}>
            <ListItems />
          </Form.Item>
        </FormStyled>
      </Modal>
    </>
  );
};

export default ArrangeStaffModal;

const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  .label {
    font-size: 18px;
    font-weight: 600;
  }
`;