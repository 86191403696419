import clsx from 'clsx';
import Text from 'components/Text';
import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import React, { useState } from 'react';
import styled from 'styled-components';
import Customer from '../RightMainBody/Customer';
import { RegularCategories, SimpleCategories } from './Category';
import { ProductCategoryV2 } from './ProductCategory';
import regularIcon from './regular.svg';
import regularActiveIcon from './regularActive.svg';
import simpleIcon from './simple.svg';
import simpleActiveIcon from './simpleActive.svg';

type IComponentProps = {};
const CategoryGroup: React.FC<IComponentProps> = () => {
  const [isSimple, setIsSimple] = useState(false);
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  const isProductUI = featureProductMode === FEATURE_PRODUCT_MODE.PRODUCT;

  return (
    <LeftMainBodyStyled>
      <div className='container-carousel' style={{ display: 'none' }}>
        <Text mb={1.5} />
        <Customer />
      </div>
      {!isProductUI ? <div>
        <MenuServices >
          <button className={clsx('menu-item menu-item-1', !isSimple && 'active')} onClick={() => setIsSimple(false)}>
            <img src={!isSimple ? regularActiveIcon : regularIcon} alt="regularIcon" className="icon" />
            <div className="content-text">REGULAR</div>
          </button>
          <button className={clsx('menu-item menu-item-2', isSimple && 'active')} onClick={() => setIsSimple(true)}>
            <img src={isSimple ? simpleActiveIcon : simpleIcon} alt="simpleIcon" className="icon" />
            <div className="content-text">SIMPLE</div>
          </button>
        </MenuServices>
        {isSimple ? <SimpleCategories /> : <RegularCategories />}
      </div> : <>
        <Text />
        <ProductCategoryV2 />
      </>}
    </LeftMainBodyStyled>
  );
};

export default CategoryGroup;

const MenuServices = styled.div`
display: flex;
height: 72px;
align-items: center;
align-self: stretch;
.menu-item {
  display: flex;
  padding: 8px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  background: #FFF;
  border: 2px solid #86909C;
  img.icon {
    width: 36px;
    height: 36px;
  }
  .content-text {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  &.menu-item-1 {
    border-radius: 8px 24px 0px 0px;
  }
  &.menu-item-2 {
    border-radius: 24px 8px 0px 0px;
  }

  &.active {
    border: 2px solid #1D2129;
    .content-text {
      color: #1D2129;
    }
  }
}
`;

const LeftMainBodyStyled = styled.div`
padding-right: 24px;
  .container-carousel {
    padding: 0 1.5rem;
  }
  .scroll-box {
    flex: 1;
  }

  display: flex;
  gap: 1rem;
  flex-direction: column;
  .hide {
    display: none;
    opacity: 0;
  }
  .show {
    display: flex;
    opacity: 1;
  }
`;