import { Col, Row } from 'antd';
import Text from 'components/Text';
import GiftCard from './GiftCard';
import Product from './Product';
import Services from './Services';
import VIP from './VIP';
import styled from 'styled-components';
import ButtonQuickPay from '../ButtonQuickPay';
type Props = {
  v2?: boolean;
};
const ExtendFeatures = ({ v2 }: Props) => {
  if (v2) {
    return (
      <Container>
        <Services v2 />
        <GiftCard v2 />
        <VIP v2 />
        <ButtonQuickPay v2 />
        <Product v2 />
      </Container>
    );
  }
  return (
    <>
      <Row gutter={[12, 12]}>
        <Col span={6}>
          <Services />
        </Col>
        <Col span={6}>
          <GiftCard />
        </Col>
        <Col span={6}>
          <VIP />
        </Col>
        <Col span={6}>
          <Product />
        </Col>
      </Row>
      <Text mb={1.125} />
    </>
  );
};

export default ExtendFeatures;
const Container = styled.div`
  max-height: 126px;
  height: 126px;
  background: #fff;
  box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  padding: 0rem 1.125rem;
  align-self: stretch;
  gap: 8px;
`;