import React from 'react';
import styled from 'styled-components';
import check_icon from './check.svg';
import clsx from 'clsx';

export enum TYPE_BLOCK_RADIO {
  BLOCK_HOURS = 'BLOCK_HOURS',
  DAY_OFF = 'DAY_OFF'
}
type Props = {
  value?: TYPE_BLOCK_RADIO;
  onChange?: (value: TYPE_BLOCK_RADIO) => void;
};
const BlockHourType = ({ value, onChange = () => undefined }: Props) => {
  return (
    <Container>
      <div className="list-container">
        <BlockHourItem label='Block Hours' onClick={() => onChange(TYPE_BLOCK_RADIO.BLOCK_HOURS)} active={value === TYPE_BLOCK_RADIO.BLOCK_HOURS} />
        <BlockHourItem label='Day Offs' onClick={() => onChange(TYPE_BLOCK_RADIO.DAY_OFF)} active={value === TYPE_BLOCK_RADIO.DAY_OFF} />
      </div>
    </Container>
  );
};

export default BlockHourType;

const Container = styled.div`
display: flex;
padding: 16px;
align-items: center;
gap: 8px;
align-self: stretch;
border-bottom: 1px solid #86909C;
.list-container {
  display: flex;
  height: 48px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  flex: 1;
}
`;
type BlockHourItemProps = {
  label?: string;
  active?: boolean;
  onClick: () => void;
};
const BlockHourItem = ({ label, active, onClick }: BlockHourItemProps) => {
  return (
    <BlockHourStyled type='button' onClick={onClick}>
      <span>{label}</span>
      <div className={clsx('checkbox', active && 'active')}>
        {active && <img src={check_icon} alt="check_icon" />}
      </div>
    </BlockHourStyled>
  );
};

const BlockHourStyled = styled.button`
display: flex;
padding: 4px 8px;
justify-content: space-between;
align-items: center;
flex: 1 0 0;
align-self: stretch;
border-radius: 4px;
background: #E5E6EB;
span {
  color: #505050;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.checkbox {
  display: flex;
  width: 32px;
  height: 32px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 100px;
  border: 1px solid #86909C;
  background: #F5F5F5;
  &.active {
    border: 1px solid #2D6772;
    background: #2D6772;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
`;