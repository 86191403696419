import { Col, Row } from 'antd';
import Modal from 'components/Modal';
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import TicketDetail from './TicketDetail';
import { useAppDispatch } from 'store/hooks';
import multiplePaymentActions from '../../services/actions';
import PaymentDetail from './PaymentDetail';
import multiplePaymentSelectors from '../../services/selectors';
import shopSelectors from 'services/shop/selectors';
import { parseDecimal } from 'features/cashier/services/constants';
type Props = {
  onConfirm: () => void;
  onClose: () => void;
};
type BillPaymentConfirmModalRef = {
  open: (id: string) => void;
  close: () => void;
  getTotal: () => number;
};

export const useBillPaymentConfirmModalRef = () => useRef<BillPaymentConfirmModalRef>(null);

const BillPaymentConfirmModal = forwardRef<BillPaymentConfirmModalRef, Props>(({ onConfirm, onClose }, ref) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);

  const detailTicket = multiplePaymentSelectors.getDetail();
  const receiveConfigs = multiplePaymentSelectors.customerSide.getReceiveConfigs();
  const discountSetting = shopSelectors.data.discountSetting();

  const serviceCharge = useMemo(() => {
    if (!receiveConfigs?.creditCard) return 0;
    return detailTicket?.cardFee || 0;
  }, [receiveConfigs, detailTicket]);

  const getDiscount = (subTotal: number) => {
    if (!discountSetting) return 0;
    const { discountType, discountValue } = discountSetting;
    if (discountType === 'PERCENT') {
      return Math.round(subTotal * (discountValue / 100));
    }
    if (discountType === 'MONEY')
      return parseDecimal(discountValue);
    return 0;
  };

  const total = useMemo(() => {
    if (!detailTicket) return 0;
    const _total = (detailTicket?.total || 0) + (serviceCharge || 0);
    if (!receiveConfigs?.enableCashIncentive) return _total;
    return parseDecimal(_total - getDiscount(detailTicket?.subTotal));
  }, [receiveConfigs, detailTicket]);

  const discountTicket = useMemo(() => {
    if (!detailTicket) return 0;
    if (!receiveConfigs?.enableCashIncentive) return detailTicket?.discountTicket || 0;
    return detailTicket?.discountTicket + getDiscount(detailTicket?.subTotal);
  }, [receiveConfigs, detailTicket]);

  const handleOpen = (id: string) => {
    dispatch(multiplePaymentActions.getTransactionDetail.fetch(id));
    setVisible(true);
  };

  const handleClose = () => {
    setVisible(false);
    onClose();
  };

  useImperativeHandle(ref, () => ({
    open: (id: string) => handleOpen(id),
    close: () => handleClose(),
    getTotal: () => total,
  }));

  return (
    <Modal
      visible={visible}
      onClose={handleClose}
      onSubmit={handleClose}
      width={'100vw'}
      footer={<></>}
      noneBodyStyle
      noneFooterStyle
      hiddenHeader
      rootClassName={'modal-add-tip'}
      className='modal-overflow-unset modal-max-height-unset'
    >
      <Container>
        <Col span={13}>
          <TicketDetail
            onBack={handleClose}
            detailTicket={detailTicket}
            serviceCharge={serviceCharge}
            total={total}
            discountTicket={discountTicket}
          />
        </Col>
        <Col span={11}>
          <PaymentDetail
            onConfirm={onConfirm}
            receiveConfigs={receiveConfigs}
            total={total}
          />
        </Col>
      </Container>
    </Modal>
  );
});
BillPaymentConfirmModal.displayName = 'BillPaymentConfirmModal';
export default BillPaymentConfirmModal;

const Container = styled(Row)`
  background: var(--fill-fill-3, #E5E5E5);
  min-height: 100vh;
  padding: 5vw;
`;
