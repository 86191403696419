import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';
type Props = {
  value?: number;
  onChange?: (value: number) => void;
  disabled?: boolean;
};
const TurnAmount = ({ onChange = () => undefined, value, disabled }: Props) => {
  const onChangeAmount = (value: number) => () => onChange(value);
  return (
    <Container className='turn-checkbox-container'>
      <Checkbox disabled={disabled} onClick={onChangeAmount(1)} label='Full Turn' active={value === 1} />
      <Checkbox disabled={disabled} onClick={onChangeAmount(0.5)} label='Half Turn' active={value === 0.5} />
      {/* <Checkbox disabled={disabled} onClick={onChangeAmount(0)} label='No Turn' active={value === 0} /> */}
    </Container>
  );
};

export default TurnAmount;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
`;

const checkBoxActive = <div className='checkbox-active'>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M14.3075 3.87069C14.0463 3.61014 13.6925 3.46387 13.3236 3.46387C12.9546 3.46387 12.6007 3.61014 12.3395 3.87069L6.33952 9.87069L3.71419 7.24674C3.58594 7.11267 3.43224 7.0055 3.26204 6.93164C3.09184 6.85778 2.90859 6.81866 2.72306 6.81657C2.53753 6.81448 2.35346 6.84951 2.18164 6.91951C2.00981 6.98952 1.85365 7.09313 1.72241 7.22428C1.59117 7.35543 1.48744 7.51149 1.41731 7.68327C1.34719 7.85504 1.31208 8.03908 1.31404 8.22461C1.31601 8.41014 1.35504 8.59342 1.42879 8.76367C1.50253 8.93392 1.60958 9.08772 1.74357 9.21606L5.35555 12.8267C5.6172 13.0875 5.97153 13.2338 6.3409 13.2338C6.71027 13.2338 7.06452 13.0875 7.32617 12.8267L14.3155 5.84009C14.446 5.71079 14.5496 5.55687 14.6204 5.38729C14.6911 5.21771 14.7275 5.03578 14.7275 4.85205C14.7275 4.66832 14.6911 4.48639 14.6204 4.31681C14.5496 4.14723 14.446 3.9934 14.3155 3.8641L14.3075 3.87069Z" fill="white" />
  </svg>
</div>;
const checkboxBlank = <div className='checkbox-blank'></div>;

const Checkbox = ({ label = '', active, onClick, disabled }: { active?: boolean, label?: string, onClick: () => void; disabled?: boolean }) => {
  return <CheckBoxStyled disabled={disabled} type='button' onClick={onClick} className={clsx('turn-checkbox', active && 'active')}>
    {active ? checkBoxActive : checkboxBlank} <span className='label-checkbox'>{label}</span>
  </CheckBoxStyled>;
};

const CheckBoxStyled = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  &:not(.active):disabled {
    opacity: 0.5;
  }
  span {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
  }

  .checkbox-active {
    display: flex;
    width: 35px;
    height: 35px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #2D6772;
    background: #2D6772;
  }

  .checkbox-blank {
    display: flex;
    width: 35px;
    height: 35px;
    align-items: flex-start;
    gap: 10px;
    border-radius: 100px;
    border: 1px solid #86909C;
    background: #FFF;  
  }
`;
