import TimePickerAnt from 'components/TimePicker';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { styled } from 'styled-components';
type Props = {
  value?: string | null;
  onChange: (value: string | null) => void;
  minTime?: string | null;
};
const TimePickerLabel = ({ value, onChange = () => undefined, minTime }: Props) => {
  const [open, setOpen] = useState(false);
  const date = useMemo(() => value ? moment(value) : null, [value]);
  const min = minTime ? moment(minTime) : null;
  const disabledHours = () => {
    if (!min) return [];
    const hours = [];
    const currentHour = min.hour();
    for (let i = currentHour + 1; i <= 24; i++) {
      hours.push(i);
    }

    return Array.from({ length: currentHour - 1 }, (_, i) => i + 1);
    // return hours;
  };

  const disabledMinutes = (selectedHour: number) => {
    if (!min) return [];
    const currentMinute = min.minute();
    if (selectedHour === min.hour()) {
      return Array.from({ length: currentMinute + 1 }, (_, i) => i);
    }

    return [];
  };

  return (
    <TimePickerAnt
      style={{
        height: 'auto',
        width: 'auto',
        border: 'none',
        padding: 0,
      }}
      open={open}
      onOpenChange={setOpen}
      value={date}
      onOk={(e) => onChange(e.format())}
      showNow={false}
      disabledHours={disabledHours}
      disabledMinutes={disabledMinutes}
      suffixIcon={null}
      clearIcon={false}
      minuteStep={15}
      inputRender={() => (
        <TimePickerLabelStyled onClick={() => setOpen(true)}>
          {date?.format(FORMAT_TIME) ?? ''}
        </TimePickerLabelStyled>
      )
      }
    />
  );
};

export default TimePickerLabel;
const TimePickerLabelStyled = styled.button`
  color: var(--info-infor-3, #6FABB6);
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration-line: underline;
  text-decoration-thickness: from-font;
`;
