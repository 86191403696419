import { ISummaryDashboardSalaryStaff } from 'features/report/services/types/reportStaff';
import { get } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import commission_icon from './icons/commission.svg';
import discount_icon from './icons/discount.png';
import paid_out_icon from './icons/paid-out.png';
import supply_fee_icon from './icons/supply-fee.svg';
import tip_icon from './icons/tip.png';
import total_sales_icon from './icons/total-sales.svg';

const DashboardSalaryTypes = {
    TOTAL_SALES: 'TOTAL_SALES',
    SUPPLY_FEE: 'SUPPLY_FEE',
    COMMISSIONS: 'COMMISSIONS',
    DISCOUNT: 'DISCOUNT',
    TOTAL_TIP: 'TOTAL_TIP',
    TOTAL_PAID_OUT: 'TOTAL_PAID_OUT',
};
const configs = {
    [DashboardSalaryTypes.TOTAL_SALES]: {
        label: 'Total Sales',
        bg: '#808836',
        icon: total_sales_icon,
    },
    [DashboardSalaryTypes.SUPPLY_FEE]: {
        label: 'Supply Fees',
        bg: '#4F1787',
        icon: supply_fee_icon,
    },
    [DashboardSalaryTypes.COMMISSIONS]: {
        label: 'Commissions',
        bg: '#C84B31',
        icon: commission_icon,
    },
    [DashboardSalaryTypes.DISCOUNT]: {
        label: 'Discounts',
        bg: '#346751',
        icon: discount_icon,
    },
    [DashboardSalaryTypes.TOTAL_TIP]: {
        label: 'Total Tip',
        bg: '#293A80',
        icon: tip_icon,
    },
    [DashboardSalaryTypes.TOTAL_PAID_OUT]: {
        label: 'Total Paid Out',
        bg: '#C52884',
        icon: paid_out_icon,
    },
};

interface IDashboardRow {
    data: ISummaryDashboardSalaryStaff;
    paidOut?: number;
}

const DashboardRowV2: React.FC<IDashboardRow> = ({ data, paidOut }) => {
    return (
        <Container>
            <DashboardItem type='TOTAL_SALES' value={formatCurrency(data.crossSale)} />
            <DashboardItem type='SUPPLY_FEE' value={formatCurrency(data.totalSupplyFee)} />
            <DashboardItem type='COMMISSIONS' value={formatCurrency(data.employeeSalaries)} />
            <DashboardItem type='DISCOUNT' value={formatCurrency(data.totalDiscount)} />
            <DashboardItem type='TOTAL_TIP' value={formatCurrency(data.totalTip)} />
            <DashboardItem type='TOTAL_PAID_OUT' value={formatCurrency(paidOut)} />
        </Container>
    );

};

export default DashboardRowV2;
const Container = styled.div`
display: flex;
padding: 24px 16px;
justify-content: center;
align-items: center;
gap: 16px;
align-self: stretch;
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
`;
type DashboardItemProps = {
    type: keyof typeof DashboardSalaryTypes;
    value: string;
};
const DashboardItem = ({ type, value }: DashboardItemProps) => {
    const config = get(configs, type);
    if (!config) return null;
    return <DashboardItemStyled>
        <div className="content">
            <p className="label">{config.label}</p>
            <p className="value">{value}</p>
        </div>

        <div className="icon-button">
            <img src={config.icon} alt={config.label} />
        </div>

    </DashboardItemStyled>;
};

const DashboardItemStyled = styled.div`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid #CCD4DC;
background: #FFF;
position: relative;

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    p.label {
        align-self: stretch;
        color: #505050;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;
    }
    p.value {
        align-self: stretch;
        color: #1D2129;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
.icon-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    position: absolute;
    right: 16px;
    border-radius: 100px;
    border: 1px solid #FFF;
    img {
      height: 72px;
      width: 72px;
    }
}
`;