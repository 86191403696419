import React from 'react';

const CardServiceIconV2 = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_11264_2835)">
        <path d="M13.2071 4.64053C19.94 -0.376265 29.5215 -1.37962 37.2903 2.00671C44.2822 4.95408 49.4614 11.2878 51.3389 18.4367C53.4105 18.6249 55.6764 19.8791 55.9354 22.0739C56.1296 25.3975 56.0001 28.7212 56.0001 32.0448C56.0649 33.8634 54.5759 35.1176 53.2163 36.0582C52.2452 40.6361 49.7851 44.9003 45.9654 47.8477C41.7574 51.234 36.3839 52.9899 30.9458 53.4916C29.8452 56.1881 25.7666 57.1915 23.9539 54.6204C21.8175 52.3001 24.0834 48.4121 27.1909 48.6629C28.9389 48.5375 30.0394 49.9798 31.0105 51.1086C35.6718 50.5442 40.3331 49.1646 44.0233 46.3427C47.325 43.8343 49.7204 40.2598 50.562 36.2464C49.4614 35.7447 48.4256 35.1176 47.5192 34.3023C45.7065 39.3191 42.0163 44.0851 36.7724 46.0918C39.0383 43.8343 41.2394 41.2004 41.5631 38.0022C42.4695 31.2923 40.6568 23.8298 35.6718 18.8757C35.1539 21.8231 34.0533 25.3348 30.7516 26.3382C26.3493 27.2161 21.8822 27.9059 17.4799 28.8466C14.5019 29.5364 12.6891 32.86 13.5955 35.682C14.2429 39.9462 17.2857 43.2699 19.6811 46.7189C14.3724 44.7122 10.0996 40.3852 8.09262 35.1803C5.82672 37.626 1.10071 37.0616 0.25909 33.738C-0.194089 30.2262 0.0648705 26.7144 0.0648705 23.2027C0.0648705 21.1333 1.94233 19.8163 3.6903 19.1265C5.04984 13.4199 8.28684 8.08958 13.2071 4.64053ZM21.2348 4.70324C15.1493 6.64725 10.1643 11.4759 8.15736 17.3707C7.76892 18.5621 7.12152 19.8791 7.76892 21.0705C9.71111 16.869 12.6244 12.981 16.7678 10.598C23.436 6.58454 32.4995 6.77267 39.0383 11.0369C42.9874 13.4199 45.5123 17.3079 47.5192 21.2587C47.9724 17.8723 45.9654 14.6114 44.088 11.8522C39.103 5.01679 29.392 2.00671 21.2348 4.70324Z" fill="#808836" />
        <path d="M35.089 38.7548C34.7653 41.8275 31.593 44.1478 28.4855 44.1478C25.7664 44.2732 22.9826 42.7055 21.9468 40.2598C26.0901 42.3919 31.6578 41.953 35.089 38.7548Z" fill="#808836" />
      </g>
      <defs>
        <clipPath id="clip0_11264_2835">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export const CardServiceIconV2Active = () => {
  return (
    <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_12905_174874)">
        <path d="M13.2071 4.64054C19.94 -0.376261 29.5215 -1.37962 37.2903 2.00672C44.2822 4.95409 49.4614 11.2878 51.3389 18.4367C53.4105 18.6249 55.6764 19.8791 55.9354 22.0739C56.1296 25.3975 56.0001 28.7212 56.0001 32.0448C56.0649 33.8634 54.5759 35.1176 53.2163 36.0582C52.2452 40.6361 49.7851 44.9003 45.9654 47.8477C41.7574 51.234 36.3839 52.9899 30.9458 53.4916C29.8452 56.1881 25.7666 57.1915 23.9539 54.6204C21.8175 52.3001 24.0834 48.4121 27.1909 48.6629C28.9389 48.5375 30.0394 49.9798 31.0105 51.1086C35.6718 50.5442 40.3331 49.1646 44.0233 46.3427C47.325 43.8343 49.7204 40.2598 50.562 36.2464C49.4614 35.7447 48.4256 35.1176 47.5192 34.3023C45.7065 39.3191 42.0163 44.0851 36.7724 46.0918C39.0383 43.8343 41.2394 41.2004 41.5631 38.0022C42.4695 31.2923 40.6568 23.8298 35.6718 18.8757C35.1539 21.8231 34.0533 25.3348 30.7516 26.3382C26.3493 27.2161 21.8822 27.9059 17.4799 28.8466C14.5019 29.5364 12.6891 32.86 13.5955 35.682C14.2429 39.9462 17.2857 43.2699 19.6811 46.7189C14.3724 44.7122 10.0996 40.3852 8.09262 35.1803C5.82672 37.626 1.10071 37.0616 0.25909 33.738C-0.194089 30.2262 0.0648705 26.7144 0.0648705 23.2027C0.0648705 21.1333 1.94233 19.8163 3.6903 19.1265C5.04984 13.4199 8.28684 8.08958 13.2071 4.64054ZM21.2348 4.70325C15.1493 6.64725 10.1643 11.4759 8.15736 17.3707C7.76892 18.5621 7.12152 19.8791 7.76892 21.0705C9.71111 16.869 12.6244 12.981 16.7678 10.598C23.436 6.58454 32.4995 6.77268 39.0383 11.037C42.9874 13.4199 45.5123 17.3079 47.5192 21.2587C47.9724 17.8723 45.9654 14.6114 44.088 11.8522C39.103 5.0168 29.392 2.00672 21.2348 4.70325Z" fill="white" />
        <path d="M35.089 38.7548C34.7653 41.8275 31.593 44.1478 28.4855 44.1478C25.7664 44.2732 22.9826 42.7055 21.9468 40.2598C26.0901 42.3919 31.6578 41.953 35.089 38.7548Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_12905_174874">
          <rect width="56" height="56" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CardServiceIconV2;
