import { Col, Row } from 'antd';
import Modal from 'components/Modal';
import colorTheme from 'constants/color';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import shopSelectors from 'services/shop/selectors';
import { IProductVIPItemData } from 'services/shop/types/vip';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CardTicketItem from '../CardTicketItem';
import CardItem, { CardFirst } from './CardItem';
import close from './close-circle-fill.png';
import { useState } from 'react';
import Text from 'components/Text';
import RequestAdminPWCallback, { useRequestAdminPWCallbackRef } from 'widgets/RequestAdminLocalPW/Callback';
import { useLocation, useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';
import ModalVipV2 from './ModalVipV2';
type Props = {
  v2?: boolean;
};
function VIP({ v2 }: Props) {
  const pwRef = useRequestAdminPWCallbackRef();
  const dispatch = useAppDispatch();
  const visible = cashierSelectors.getVisibleVIP();
  const productsVIP = shopSelectors.data.productsVIP();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();
  const customer = cashierSelectors.getSelectedCustomer();
  const [visibleWarning, setVisibleWarning] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleCloseWarning = () => {
    setVisibleWarning(false);
  };
  const setVisible = (val: boolean) => {
    if (!productsVIP?.length) {
      return setVisibleWarning(true);
    }
    dispatch(cashierActions.setVisibleVIP(val));
  };

  const handleBuy = (o: IProductVIPItemData) => {
    dispatch(cashierActions.selectProductVIPItem(o));
    setVisible(false);
  };

  const onBuy = (o: IProductVIPItemData) => () => handleBuy(o);

  const handleVIP = () => {
    setVisible(true);
    if (!customer?.customerId) {
      dispatch(cashierActions.setVisibleChooseCustomer(true));
      return;
    }
  };

  const handleOkWarningConfig = () => {
    setVisibleWarning(false);
    pwRef.current?.open(() => navigate(`/store/${storage.shop_id.get()}/setting-service/VIP`, { state: { path: location.pathname } }));
  };

  return (
    <>
      {v2 ? <CardTicketItem
        icon={'cardVipV2'}
        content={'VIP'}
        v2
        nbgcolor={'#FFF1E3'}
        textColor={colorTheme.text_3}
        border_color={'#FF7D00'}
        border_color2='#FF7D00'
        onClick={handleVIP}
      /> :
        <CardTicketItem
          icon={'cardVIP'}
          content={'VIP'}
          nbgcolor={colorTheme.info_bg_2}
          textColor={colorTheme.text_3}
          border_color={colorTheme.hover_info}
          onClick={handleVIP}
        />}
      <RequestAdminPWCallback ref={pwRef} onlyAdmin />
      {visibleWarning && <Modal
        visible={visibleWarning}
        onClose={handleCloseWarning}
        hiddenHeader
        okTitle={'Go to setting'}
        onSubmit={handleOkWarningConfig}
      >
        <ModalStyled>
          <IconWarningBig />
          <Text variant='H7' mt={2} mb={1} >
            Please config enable VIP setting
          </Text>
        </ModalStyled>
      </Modal>}
      {customer?.customerId && (v2 ? <ModalVipV2
        visible={visible}
        data={productsVIP}
        onBuy={handleBuy}
        onClose={() => setVisible(false)}
      /> : <Modal
        visible={visible}
        modalTitle='VIP'
        footer={null}
        closeIcon={<IconClose />}
        closable
        noneBodyStyle
        noneFooterStyle
        className='modal-vip'
        showClose
        onClose={() => setVisible(false)}
        width={'120vh'}
      >

        <VIPBody gutter={[20, 20]} style={{ margin: '0' }} wrap={false}>
          <Col span={6} className='sticky'>
            <CardFirst />
          </Col>
          {productsVIP.map(o => (
            <Col span={6} key={o.id} className='item-box'>
              <CardItem
                data={o}
                onBuy={onBuy(o)}
                selected={!!serviceIdsSelected.find(s => s.id === o.id)}
              />
            </Col>
          ))}
        </VIPBody>
      </Modal>)}
    </>
  );
}

export default VIP;

const VIPBody = styled(Row)`
  height: 60vh;
  padding: 1.5rem 0rem;
  overflow-x: auto;
  margin: 0 10px;
  .sticky {
    position: sticky;
    left:0px;
    background:#fff;
    z-index:2;
  }
  .item-box {
    position: relative;
    z-index:1;
  }
`;

const IconClose = () => (
  <Row>
    <img src={close} style={{ height: '100%', width: '100%' }} />
  </Row>
);

const ModalStyled = styled.div`
  text-align: center;
`;

const IconWarningBig = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="97" height="96" viewBox="0 0 97 96" fill="none">
    <path d="M47.1016 0.0228096C55.1129 -0.22689 63.1789 1.57766 70.3131 5.23293C80.7502 10.506 89.0973 19.7575 93.2729 30.6813C96.9722 40.1783 97.5136 50.865 94.7963 60.6872C92.5469 68.9693 88.0082 76.6156 81.8203 82.5622C75.9639 88.2318 68.624 92.355 60.7385 94.4198C51.8584 96.7699 42.2985 96.4971 33.5758 93.6119C23.9194 90.4917 15.3351 84.1821 9.45146 75.9147C5.22546 70.0184 2.37175 63.1465 1.17361 55.9933C-0.943594 43.7496 1.96467 30.7359 9.10944 20.5716C14.9679 12.1091 23.6382 5.6421 33.4163 2.43168C37.8291 0.975446 42.4538 0.14661 47.1016 0.0228096ZM46.4909 13.7206C44.5878 14.1676 42.8441 15.2692 41.6312 16.803C40.2296 18.5363 39.556 20.8171 39.7428 23.0329C40.6178 34.8842 41.4991 46.7355 42.3741 58.5868C42.4223 59.0841 42.4013 59.5982 42.544 60.085C42.7161 60.5299 43.2155 60.7292 43.6687 60.6914C46.961 60.683 50.2532 60.6998 53.5434 60.683C54.1393 60.7124 54.5883 60.1459 54.5611 59.5793C55.4654 47.3294 56.3824 35.0794 57.2784 22.8294C57.4756 19.9694 56.1642 17.0632 53.8833 15.3237C51.827 13.7122 49.0299 13.1121 46.4909 13.7206ZM46.7218 68.2957C45.2278 68.6671 43.868 69.5316 42.8923 70.7193C40.7521 73.2268 40.6933 77.2031 42.7601 79.7714C44.7682 82.4594 48.7319 83.3679 51.7095 81.8215C54.9493 80.2939 56.6027 76.1812 55.2955 72.8386C54.133 69.433 50.1945 67.3389 46.7218 68.2957Z" fill="#FFDC7C" />
  </svg>
);
