
import { AxiosResponse } from 'axios';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoadingPage } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import actions, { turnManualAPIActions } from './actions';
import apis from './apis';
import { ITurnDetail, ITurnTable } from './types';
import { IApiAddManualTurnBody, IApiGetTurnDetailParam, IApiGetTurnParam, IApiGetTurnStaffsAvailable, IApiUpdateManualTurnBody, IApiUpdateTurnDetailParam, ISyncWaitngToTicket } from './types/api';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import userActions from 'features/user/services/actions';
import { IResponseDataBody } from 'services/response';
import { IStaffTurnManualItem, ITurnManualItem } from './types/turn';
import { ITurnServiceItem } from './types/service';
import { RootState } from 'store';
import { TurnStatus } from '../pages/constanst';
import { getTurnAmountString, TURN_ITEM_STATUS, TURN_ITEM_TYPE } from './constants';
import { turnManualActions } from './reducers';

const getTurn: ISagaFunc<IApiGetTurnParam> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield actions.setTurnLoading(true);
  yield delay(200);
  const params: IApiGetTurnParam = payload;
  try {
    const resData: AxiosResponse<{ data: ITurnTable }> = yield call(apis.getTurn, params);
    if (resData?.data?.data) {
      yield put(actions.getTurn.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTurn.fail({}));
  } finally {
    yield setLoadingPage(false);
    yield actions.setTurnLoading(false);
  }
};

const getTurnDetail: ISagaFunc<IApiGetTurnDetailParam> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: IApiGetTurnDetailParam = payload;
  try {
    const resData: AxiosResponse<{ data: ITurnDetail }> = yield call(apis.getTurnDetail, params);
    if (resData?.data?.data) {
      yield put(actions.getTurnDetail.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTurnDetail.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const getTurnStaffsAvailable: ISagaFunc<IApiGetTurnStaffsAvailable> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: IApiGetTurnStaffsAvailable = payload;
  try {
    const resData: AxiosResponse<{ data: IStaffAvailableItem[] }> = yield call(apis.getTurnStaffsAvailable, params);
    if (resData?.data?.data) {
      yield put(actions.getTurnStaffsAvailable.success(resData.data.data));
      yield put(userActions.getListAvailableWorking.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTurnStaffsAvailable.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};
const updateTurnDetail: ISagaFunc<IApiUpdateTurnDetailParam> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: IApiUpdateTurnDetailParam = payload;
  try {
    const resData: AxiosResponse<{ data: boolean }> = yield call(apis.updateTurnDetail, params);
    if (resData?.data?.data) {
      yield all([
        yield put(actions.updateTurnDetail.success({})),
        yield put(actions.setShowModalTurnDetail(false)),
        yield put(actions.getTurn.fetch({}))
      ]);
    }
  } catch (error) {
    yield put(actions.updateTurnDetail.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const syncWaitngToTicket: ISagaFunc<ISyncWaitngToTicket> = function* ({ payload }) {
  yield setLoadingPage(true);
  yield delay(200);
  const params: ISyncWaitngToTicket = payload;
  try {
    const resData: AxiosResponse<{ data: boolean }> = yield call(apis.syncWaitngToTicket, params);
    if (resData?.data?.data) {
      yield all([
        yield put(userActions.getWaitingList.fetch()),
        yield put(actions.selectWaitingListResItem(undefined)),
        yield put(actions.getTurn.fetch({})),
        yield put(actions.syncWaitngToTicket.success({})),
      ]);
    }
  } catch (error) {
    yield put(actions.syncWaitngToTicket.fail({}));
  } finally {
    yield setLoadingPage(false);
  }
};

const syncTurn = function* () {
  yield delay(200);
  yield all([
    put(actions.getTurn.fetch({})),
    put(userActions.getWaitingList.fetch()),
  ]);
};

const clearAllTurn = function* () {
  yield setLoadingPage(true);
  yield delay(200);

  try {
    const resData: AxiosResponse<{ data: boolean }> = yield call(apis.clearAllTurn);
    if (resData?.data?.data) {
      yield syncTurn();
    }
  } catch (error) {
    yield put(actions.syncWaitngToTicket.fail({}));
  } finally {
    yield setLoadingPage(false);
  }


};

const getTurnConfigServices = function* () {
  try {
    const res: IResponseDataBody<any> = yield call(apis.getTurnConfigServices);
    if (res.data.data) {
      yield put(turnManualAPIActions.getTurnConfigServices.success(res.data.data));
    }
  } catch (error) { }
};

const getTurnSettingColors = function* () {
  try {
    const res: IResponseDataBody<any> = yield call(apis.getTurnSettingColor);
    if (res.data.data) {
      yield put(turnManualAPIActions.getTurnSettingColors.success(res.data.data));
    }
  } catch (error) { }
};

const getManualTurns = function* () {
  try {
    const res: IResponseDataBody<IStaffTurnManualItem[]> = yield call(apis.getTurn, {});
    if (res.data.data) {
      yield put(turnManualAPIActions.getTurns.success(res.data.data));
    }
  } catch (error) { }
};

const deleteTurnServiceItem: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<IStaffTurnManualItem[]> = yield call(apis.deleteManualServicesTurn, payload);
    if (res.data.data) {
      yield getTurnConfigServices();
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

const addNewManualTurn = function* (body: IApiAddManualTurnBody) {
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<ITurnManualItem> = yield call(apis.addManualTurn, body);
    if (res.data.data) {
      yield getManualTurns();
      yield put(turnManualActions.updateActiveTurnManual(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

const editManualTurn = function* (body: IApiUpdateManualTurnBody) {
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<ITurnManualItem> = yield call(apis.updateManualTurn, body);
    if (res.data.data) {
      yield getManualTurns();
      yield put(turnManualActions.updateActiveTurnManual(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

const handleTurnWithService: ISagaFunc<ITurnServiceItem> = function* ({ payload }) {
  const activeTurnManual: string | ITurnManualItem | null = yield select((root: RootState) => root.turn.turnManual.activeTurnManual);
  if (!activeTurnManual) return;
  if (typeof activeTurnManual === 'string') {
    const [, staffId] = activeTurnManual.split('/');
    if (!staffId) return;
    const body: IApiAddManualTurnBody = {
      itemId: payload.itemId || null,
      itemName: payload.itemName || null,
      staffId,
      status: TurnStatus.DONE,
      turn: payload.turn,
      type: TURN_ITEM_TYPE.WALK_IN,
    };
    return yield addNewManualTurn(body);
  }

  const turnItem = activeTurnManual as ITurnManualItem;
  const body: IApiUpdateManualTurnBody = {
    staffId: turnItem.staffId,
    turn: getTurnAmountString(payload.turn),
    turnId: turnItem.turnId,
    type: turnItem.type?.replace('-', '_'),
    turnStatus: TurnStatus.DONE,
    itemId: payload.itemId || null,
    itemName: payload.itemName || null,
  };
  return yield editManualTurn(body);
};

const changeTypeTurnManual: ISagaFunc<TURN_ITEM_TYPE> = function* ({ payload }) {
  const activeTurnManual: string | ITurnManualItem | null = yield select((root: RootState) => root.turn.turnManual.activeTurnManual);
  if (!activeTurnManual) return;
  if (typeof activeTurnManual === 'string') {
    const [, staffId] = activeTurnManual.split('/');
    if (!staffId) return;
    const body: IApiAddManualTurnBody = {
      itemId: null,
      itemName: null,
      staffId,
      status: TurnStatus.DONE,
      turn: 1,
      type: payload,
    };
    return yield addNewManualTurn(body);
  }

  const body: IApiUpdateManualTurnBody = {
    staffId: activeTurnManual.staffId,
    turn: getTurnAmountString(activeTurnManual.turn),
    turnId: activeTurnManual.turnId,
    type: payload,
    turnStatus: TurnStatus.DONE,
  };
  yield editManualTurn(body);
};

const changeStatusTypeTurnManual: ISagaFunc<TURN_ITEM_STATUS> = function* ({ payload }) {
  const activeTurnManual: ITurnManualItem | string | null = yield select((root: RootState) => root.turn.turnManual.activeTurnManual);
  if (!activeTurnManual) return;
  if (typeof activeTurnManual === 'string') {
    const [, staffId] = activeTurnManual.split('/');
    if (!staffId) return;
    const body: IApiAddManualTurnBody = {
      itemId: null,
      itemName: null,
      staffId,
      status: payload,
      turn: 1,
      type: TURN_ITEM_TYPE.WALK_IN,
    };
    return yield addNewManualTurn(body);
  }
  const body: IApiUpdateManualTurnBody = {
    staffId: activeTurnManual.staffId,
    turn: getTurnAmountString(activeTurnManual.turn),
    turnId: activeTurnManual.turnId,
    type: activeTurnManual.type?.replace('-', '_'),
    turnStatus: payload,
  };
  yield editManualTurn(body);
};

const removeTurnManual = function* () {
  const activeTurnManual: ITurnManualItem = yield select((root: RootState) => root.turn.turnManual.activeTurnManual);
  if (!activeTurnManual) return;
  if (typeof activeTurnManual === 'string') return;
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.deleteManualTurn, activeTurnManual.turnId);
    if (res.data.data) {
      yield getManualTurns();
      yield put(turnManualActions.setActiveTurnManual(null));
    }
  } catch (error) {

  } finally {
    yield setLoadingPage(false);
  }
};

const logoutStaff: ISagaFunc<string> = function* ({ payload }) {
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<boolean> = yield call(apis.logoutStaff, payload);
    if (res.data.data) {
      yield put(actions.getTurnStaffsAvailable.fetch({}));
    }
  } catch (error) {

  } finally {
    yield setLoadingPage(true);
  }
};

const turnManualInit = function* () {
  yield delay(200);
  yield all([
    getTurnConfigServices(),
    getTurnSettingColors(),
    getManualTurns(),
  ]);

};

export default function* turnServiceSagas() {
  yield takeLatest(actions.syncTurn, syncTurn);
  yield takeLatest(actions.getTurn.fetch, getTurn);
  yield takeLatest(actions.getTurnDetail.fetch, getTurnDetail);
  yield takeLatest(actions.getTurnStaffsAvailable.fetch, getTurnStaffsAvailable);
  yield takeLatest(actions.updateTurnDetail.fetch, updateTurnDetail);
  yield takeLatest(actions.syncWaitngToTicket.fetch, syncWaitngToTicket);
  yield takeLatest(actions.clearAllTurn, clearAllTurn);
  yield takeLatest(turnManualAPIActions.init, turnManualInit);
  yield takeLatest(turnManualAPIActions.getTurnConfigServices.fetch, getTurnConfigServices);
  yield takeLatest(turnManualAPIActions.getTurnSettingColors.fetch, getTurnSettingColors);
  yield takeLatest(turnManualAPIActions.getTurns.fetch, getManualTurns);
  yield takeLatest(turnManualAPIActions.deleteTurnServiceItem, deleteTurnServiceItem);
  yield takeLatest(turnManualAPIActions.handleTurnWithService, handleTurnWithService);
  yield takeLatest(turnManualAPIActions.changeTypeTurnManual, changeTypeTurnManual);
  yield takeLatest(turnManualAPIActions.changeStatusTypeTurnManual, changeStatusTypeTurnManual);
  yield takeLatest(turnManualAPIActions.removeTurnManual, removeTurnManual);
  yield takeLatest(turnManualAPIActions.logoutStaff, logoutStaff);
}
