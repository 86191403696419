import { FEATURE_PRODUCT_MODE } from 'features/cashier/services/constants';
import cashierSelectors from 'features/cashier/services/selectors';
import ActiveProducts from './ActiveProducts';
import ActiveServices from './ActiveServices';
import ActiveSimpleMenu from './ActiveSimpleMenu';
import ButtonQuickPay from './ButtonQuickPay';
import ButtonQuickView from './ButtonQuickView';
import ExtendFeatures from './ExtendFeatures';
import ModalWarningEmptyService from './ModalWarningEmptyService';
import { ButtonContainerStyled, CarouserContainerStyled, CenterMainStyled, ContainerStyled } from './styles';

const CenterMainBody = () => {
  return (
    <CenterMainStyled>
      <ContainerStyled>
        <ExtendFeatures />
        <ButtonContainerStyled>
          <ButtonQuickView />
          <ButtonQuickPay />
        </ButtonContainerStyled>
        <CarouserContainerStyled>
          <ActiveFeatureProduct />
          <ActiveQuickMenu />
        </CarouserContainerStyled>
      </ContainerStyled>
      <ModalWarningEmptyService />
    </CenterMainStyled>
  );
};

export default CenterMainBody;

const ActiveFeatureProduct = () => {
  const featureProductMode = cashierSelectors.getFeatureProductMode();
  const activeQuickMenu = cashierSelectors.getActiveQuickMenu();
  return (
    <div className={!activeQuickMenu ? 'show' : 'hide'}>
      <div className={featureProductMode === FEATURE_PRODUCT_MODE.SERVICE ? 'show' : 'hide'}><ActiveServices /></div>
      <div className={featureProductMode === FEATURE_PRODUCT_MODE.PRODUCT ? 'show' : 'hide'}><ActiveProducts /></div>
    </div>
  );
};
const ActiveQuickMenu = () => {
  const activeQuickMenu = cashierSelectors.getActiveQuickMenu();
  return (
    <div className={activeQuickMenu ? 'show' : 'hide'}><ActiveSimpleMenu /></div>
  );
};