import Button from 'components/Button';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import cashierApis from 'features/cashier/services/apis';
import userActions from 'features/user/services/actions';
import userSelectors from 'features/user/services/selectors';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
export const useHomeMergeTicket = () => {
  const dispatch = useAppDispatch();
  const enableMergeTicket = userSelectors.enableMergeTicket();
  const mergeTicketsList = userSelectors.mergeTicketsList();
  const refreshMergeTicket = () => dispatch(userActions.refreshMergeTicket());
  const modalConfirm = useModalConfirmRef();
  const setPageLoading = useSetLoadingPage();

  const activeMergeTicket = (active: boolean) => {
    dispatch(userActions.activeMergeTicket(active));
    dispatch(userActions.activeRemoveTicket(false));
  };

  const makeMergeTickets = async () => {
    setPageLoading(true);
    const lstBillMerge = [...mergeTicketsList];
    const firstTicketID = lstBillMerge?.shift();
    if (!firstTicketID) return;
    try {
      const res: IResponseDataBody<boolean> = await cashierApis.mergeTickets({
        currentBillId: firstTicketID,
        lstBillMerge,
      });
      if (res.data.data) {
        dispatch(userActions.refreshMergeTicket());
        dispatch(userActions.getBillWaitingTicket.fetch());
      }
    } catch (error) { } finally {
      setPageLoading(false);
    }
  };

  const onDone = () => {
    if (mergeTicketsList.length <= 1) return;
    modalConfirm.current?.open('Do you like to merge these tickets?', () => makeMergeTickets());
  };

  return ({
    modalConfirm,
    mergeTicketsList,
    onDone,
    refreshMergeTicket,
    activeMergeTicket,
    enableMergeTicket
  });
};
const ButtonMergeTicket = ({ v2 }: { v2?: boolean }) => {
  const setting = shopSelectors.data.allSetting();
  const {
    modalConfirm, mergeTicketsList,
    onDone, refreshMergeTicket, activeMergeTicket, enableMergeTicket } = useHomeMergeTicket();
  if (enableMergeTicket) return (
    <ButtonMergeStyled className={v2 ? 'v2' : ''}>
      <Button
        size='small'
        style={{ display: 'flex', flexDirection: 'row' }}
        height={v2 ? 3 : 2.5}
        width='4.5rem'
        cardCashier={v2}
        ntype='SECONDARY' onClick={refreshMergeTicket}>Cancel</Button>
      <Button
        size='small'
        style={{ display: 'flex', flexDirection: 'row' }}
        height={v2 ? 3 : 2.5}
        width='4.5rem'
        cardCashier={v2}
        disabled={mergeTicketsList?.length <= 1}
        onClick={onDone}
        ntype='PRIMARY'>Done</Button>
      <ModalConfirm ref={modalConfirm} />
    </ButtonMergeStyled>
  );
  if (setting?.versionUI === 'V2') return null;
  return (
    <Button
      size='small'
      style={{ display: 'flex', flexDirection: 'row' }}
      height={2.5}
      vertical
      ntype='SECONDARY'
      icon='merge' onClick={() => activeMergeTicket(true)} >Merge Tickets</Button>
  );
};

export default ButtonMergeTicket;

const ButtonMergeStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  &.v2 {
    gap: 0px;
    align-self: stretch;
    padding-bottom: 4px;
    button.ant-btn {
      height: 100% !important; 
      width: 6rem;
      .CONTENT_2 {
        font-size: 18px;
      }
    }
  }
`;
