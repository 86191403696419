import TableGiftCardDetail from 'features/giftCards/components/TableGiftCardDetail';
import { IGiftCardDetail } from 'features/giftCards/services/types/reducer';
import React from 'react';
import styled from 'styled-components';
type Props = {
  detail?: IGiftCardDetail | null;
};
const GiftCardHistory = ({ detail }: Props) => {
  return (
    <Container>
      <HistoryFilterContainer>
        <div className="gift-card-history-title">History Gift Card</div>
        {/* <WeeklyPicker
          onSubmit={e => console.log('WeeklyPicker', e)}
          popupClassName='zoom-big-v2'
        /> */}
      </HistoryFilterContainer>
      <GiftCardTableStyled v2 data={detail?.historyTracking || []} className='v2' />
    </Container>
  );
};

export default GiftCardHistory;
const Container = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #CCD4DC;
  background: #FFF;
  zoom: 1.3;
`;

const GiftCardTableStyled = styled(TableGiftCardDetail)`
&.v2 {
  align-self: stretch;
  padding: 0;
  .ant-table-wrapper .ant-table .ant-table-container .ant-table-content {
   .card-number, .BODY_1 {
      color: #1D2129;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .ant-table-wrapper .ant-table .ant-table-container .ant-table-content .ant-table-thead>tr>th {
    background : #6FABB6;
    border-radius: 0;
    padding-left: 4px;
    padding-right: 4px;
    border-start-start-radius: 0 !important;
    border-start-end-radius: 0 !important;
    color: #1D2129;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: left !important;

    &::before {
      position: absolute;
      top: 50%;
      inset-inline-end: 0;
      width: 1px;
      background-color: #f0f0f0;
      transform: translateY(-50%);
      transition: background-color 0.2s;
      content: "";
      height: 100% !important;
      z-index: 1;
    }
  }
}
`;

const HistoryFilterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  .gift-card-history-title {
    color: #1D2129;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    flex: 1;
  }

`;