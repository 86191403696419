import Modal from 'components/Modal';
import NumberKeyBoard from 'components/NumberKeyBoard';
import Text from 'components/Text';
import checkInActions from 'features/checkIn/services/actions';
import { IWalkInData } from 'features/checkIn/services/types/demoData';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
export enum TYPE_WALK_IN {
  Individual = 'Individual',
  Group = 'Group',
}
type IModalChooseGroupRef = {
  open: () => void;
  close: () => void;
};

export const useModalChooseGroupRef = () => useRef<IModalChooseGroupRef>(null);

const ModalGroup = forwardRef<IModalChooseGroupRef>(({ }, ref) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const navigate = useNavigate();
  const { shop_id = '' } = useParams();

  const handleSubmitTypeWalkIn = () => {
    const walkInData: IWalkInData = {
      type: TYPE_WALK_IN.Group,
      numOfMembers: 0,
    };
    walkInData.numOfMembers = quantity;

    dispatch(checkInActions.setWalkInData(walkInData));
    navigate(`/store/${shop_id}/check-in/services`);
    setVisible(false);
  };
  const isDisableOk = quantity <= 0;

  useImperativeHandle(ref, () => ({
    open: () => setVisible(true),
    close: () => setVisible(false),
  }));

  return (
    <>
      <Modal
        modalTitle="Walk-in"
        okTitle={'Next'}
        visible={visible}
        onClose={() => setVisible(false)}
        onSubmit={() => {
          handleSubmitTypeWalkIn();
        }}
        disableOk={isDisableOk}
      >
        <Text mt={1.5} mb={0.5} variant='CONTENT_1' color='text_3'>Quantity</Text>
        <NumberKeyBoard type='NUMBER' onChange={e => setQuantity(+e)} value={quantity?.toString()} />
      </Modal>
    </>
  );
});

ModalGroup.displayName = 'ModalGroup';

export default ModalGroup;
