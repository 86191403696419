import clsx from 'clsx';
import React from 'react';
import styled from 'styled-components';
import issue_icon from './issue-icon.svg';
import issue_active_icon from './issue-active-icon.svg';
import gift_card_balance_icon from './gift-card-balance-icon.svg';
import gift_card_balance_active_icon from './gift-card-balance-active-icon.svg';
import { get } from 'lodash';

export enum GIFT_CARD_RADIO_TYPE {
  ISSUED = 'ISSUED',
  BALANCE = 'BALANCE',
}
const configs = {
  [GIFT_CARD_RADIO_TYPE.ISSUED]: {
    icon: issue_icon,
    iconActive: issue_active_icon,
    label: 'ISSUED GIFT CARDS',
  },
  [GIFT_CARD_RADIO_TYPE.BALANCE]: {
    icon: gift_card_balance_icon,
    iconActive: gift_card_balance_active_icon,
    label: 'GIFT CARD BALANCE',
  },
};
type Props = {
  active?: boolean;
  type: GIFT_CARD_RADIO_TYPE,
  onClick?: () => void;
};
const GiftCardTab = ({ active, type, onClick }: Props) => {
  const config = get(configs, type);
  return (
    <Container className={clsx(active && 'active')} type='button' onClick={onClick}>
      <div className={clsx('icon-wrapper', active && 'active')}>
        <img src={active ? config?.iconActive : config?.icon} className='icon-tab' />
      </div>
      <div className="tab-label">{config?.label || ''}</div>
    </Container>
  );
};

export default GiftCardTab;
const Container = styled.button`
display: flex;
padding: 8px 0px;
justify-content: center;
align-items: center;
gap: 8px;
flex: 1 0 0;
align-self: stretch;
background: #fff;
border-bottom: 2px solid transparent;

.icon-wrapper {
  display: flex;
  width: 48px;
  height: 48px;
  padding: 2.222px;
  justify-content: center;
  align-items: center;
  gap: 11.111px;
  border-radius: 111.111px;
  background: #F5F5F5;
  border: 1.111px solid #F5F5F5;

  img.icon-tab {
    height: 100%;
    width: 100%;
  }
  &.active {
    border: 1.111px solid #FFF;
    background: #232F3E;
    box-shadow: 4.444px 4.444px 8.889px 0px rgba(0, 0, 0, 0.15) inset;
  }
}

.tab-label {
  color: #505050;
  text-align: center;

  /* Title/H6 - 20 medium */
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

&.active {
  border-bottom: 2px solid #232F3E;
  background: transparent;
  .tab-label {
    color: #232F3E;
  }
}

`;
