import Loading from 'components/Loading';
import React, { createRef, forwardRef } from 'react';
import styled from 'styled-components';


type IUILoadingRef = {
  setVisible: (val: boolean) => void;
  openTimeout: (time: number) => void;
};
export const uiLoadingBasicRef = createRef<IUILoadingRef>();

const UILoadingPageRef = forwardRef<IUILoadingRef, {}>((props, ref) => {
  const [open, setOpen] = React.useState(false);
  React.useImperativeHandle(ref, () => ({
    setVisible: val => setOpen(val),
    openTimeout: (time) => {
      setOpen(true);
      setTimeout(() => setOpen(false), time);
    }
  }));

  if (!open) return null;

  return (
    <LoadingPageStyled>
      <div className="box">
        <Loading />
      </div>
    </LoadingPageStyled>
  );
});
UILoadingPageRef.displayName = 'UILoadingPageRef';
export default UILoadingPageRef;

export const LoadingPageStyled = styled.div`
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content:center;
  z-index: 9999;
  .box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 95px 132px;
    gap: 16px;
    background: #FFFFFF;
    border-radius: 6px;
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #363565;
    }
  }
`;
