import { Button, Row } from 'antd';
import { ColumnsType } from 'antd/es/table';
import Icon from 'assets/Icons';
import Box from 'components/Box';
import Table from 'components/Table';
import Text from 'components/Text';
import colorTheme from 'constants/color';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import { Bill, StaffItem } from 'features/ticketList/services/types/api';
import { get } from 'lodash';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { formatPhone, maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
import AdjustTipButton from './Tabs/AdjustTipButton';
import RefundModalButton from './Tabs/RefundModalButton';
import TipColumn from './Tabs/TipColumn';
import VoidModalButton from './Tabs/VoidModalButton';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import { TableGreenEvenStyled } from 'features/report/components/Component';
type ITicketTableProps = {
  isCreditPage?: boolean;
  v2?: boolean;
};

const TicketTable: React.FC<ITicketTableProps> = ({ isCreditPage, v2 }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const visibleCusPhone = ticketListSelectors.getVisibleCusPhone();
  const modalConfirm = useModalConfirmRef();

  const totalElement = ticketListSelectors.ticketsTableData.totalSize();
  const params = ticketListSelectors.ticketsTableData.params();
  const data = ticketListSelectors.ticketsTableData.data();
  const loading = ticketListSelectors.ticketsTableData.loading();
  const baseColumns: ColumnsType<Bill> = useMemo(
    () => [
      {
        title: 'Tickets',
        dataIndex: 'ticketNumber',
        // width: '10rem',
        align: 'left',
        render(text) {
          return (
            <Text variant="CONTENT_2" color="text_3">
              {text ? `#${text}` : '--'}
            </Text>
          );
        },
      },
      {
        title: 'Date',
        dataIndex: 'startTime',
        align: 'left',
        width: '8rem',
        render(startTime: string) {
          return (
            <Text variant="CONTENT_2" color="text_3" style={{ whiteSpace: 'nowrap' }}>
              {startTime
                ? momentTimezone(startTime).format('MM-DD-YYYY')
                : '--'}
            </Text>
          );
        },
      },
      {
        title: 'Time',
        dataIndex: 'timePaymentCompleted',
        width: '8rem',
        align: 'left',
        render(timePaymentCompleted) {
          return (
            <Text variant="CONTENT_2" color="text_3" style={{ whiteSpace: 'nowrap' }}>
              {timePaymentCompleted ? `${timePaymentCompleted}` : '--'}
            </Text>
          );
        },
      },
      {
        title: 'Services',
        dataIndex: 'items',
        align: 'left',
        width: '15rem',
        render(items: StaffItem[]) {
          const allItemNames: string[] = items?.flatMap((item) =>
            item.services?.map((service) => service.itemName)
          );
          const concatenatedString: string = allItemNames.join(', ');
          return (
            <div style={{ maxWidth: '10rem' }}>
              <Text variant="CONTENT_2" color="text_3" textAlign="left" className='text-overflow' >
                {concatenatedString || '--'}
              </Text>
            </div>
          );
        },
      },
      {
        title: 'Staffs',
        dataIndex: 'items',
        align: 'left',
        width: '15rem',
        render(items: StaffItem[]) {
          const allStaffNames: string[] = items?.map((item) => item.staffName);
          const concatenatedStaffNames: string = allStaffNames.join(', ');
          return (
            <div>
              <Text variant="CONTENT_2" color="text_3" textAlign="left">
                {concatenatedStaffNames || '--'}
              </Text>
            </div>
          );
        },
      },
    ],
    [visibleCusPhone]
  );

  const typeToIcon = (type: PAYMENT_TYPE, last4digit?: string) => {
    switch (type) {
      case PAYMENT_TYPE.CREDIT_CARD:
        return (
          <Box className="center" gap="1">
            <Icon type="card" />
            <Text variant="CONTENT_2" color="text_3">
              {last4digit || '--'}
            </Text>
          </Box>
        );

      case PAYMENT_TYPE.LOYALTY_POINT:
        return <Icon type="loyaltyPoint" />;

      case PAYMENT_TYPE.GIFT_CARD:
        return <Icon type="giftCardDefault" />;

      case PAYMENT_TYPE.CASH_APPS:
      case PAYMENT_TYPE.VENMO:
      case PAYMENT_TYPE.PAYPAL:
      case PAYMENT_TYPE.ZELLE:
        return <div style={{ zoom: 1.5 }}><Icon type='ePayIcon' /></div>;
      case PAYMENT_TYPE.CHECK:
        return <Icon type="epay" />;

      default:
        return <Icon type="cash" />;
    }
  };

  const columns = useMemo(() => {
    const additionalColumnsMap: Record<string, ColumnsType<Bill>> = {
      OPEN_TICKET: [
        {
          title: 'Customer',
          dataIndex: 'customerName',
          align: 'left',
          render(text) {
            return (
              <Text
                variant="CONTENT_2"
                color="text_3"
                style={{ whiteSpace: 'nowrap' }}
              >
                {text ? text : '--'}
              </Text>
            );
          },
        },
        {
          title: 'Phone',
          dataIndex: 'customerPhone',
          align: 'left',
          render(text) {
            if (visibleCusPhone) {
              return (
                <Text
                  variant="CONTENT_2"
                  color="text_3"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {text ? formatPhone(text) : '--'}
                </Text>
              );
            }

            return (
              <Text
                variant="CONTENT_2"
                color="text_3"
                style={{ whiteSpace: 'nowrap' }}
              >
                {text ? maskPhone(text) : '--'}
              </Text>
            );
          },
        },
        {
          title: 'Amount',
          dataIndex: 'total',
          align: 'left',
          render(total: number) {
            return (
              <Text variant="CONTENT_2" color="text_3">
                {formatCurrency(total)}
              </Text>
            );
          },
        },
        {
          title: '',
          dataIndex: '',
          align: 'left',
          width: 250,
          render(_: any, record: Bill) {
            const isBefore = momentTimezone(record.startTime).isBefore(
              moment(),
              'date'
            );
            if (isBefore) return null;

            return (
              <Row style={{ gap: 16 }}>
                <ActionButton
                  v2={v2}
                  onClick={() =>
                    navigate(
                      `/store/${storage.shop_id.get()}/ticket/edit/${record.billId
                      }`
                    )
                  }
                >
                  <Text variant="CONTENT_2" color="text_1">
                    Pay Now
                  </Text>
                </ActionButton>
                <ActionButton
                  v2={v2}
                  color={colorTheme.error_4}
                  bgColor={colorTheme.error_2}
                  onClick={() => modalConfirm.current?.open(`Do you like to delete the ticket #${record.ticketNumber}?`, () => {
                    dispatch(ticketListActions.removePendingTicket(record.billId));
                  })}
                >
                  <Text variant="CONTENT_2" color="error_4">
                    Delete
                  </Text>
                </ActionButton>
              </Row>
            );
          },
        },
      ],
      CLOSED: [
        {
          title: 'Sub-total',
          dataIndex: 'subTotal',
          align: 'left',
          width: '8rem',
          render(total: number) {
            return (
              <Box>
                <div
                  style={{ display: 'flex', alignItems: 'center', height: 30 }}
                >
                  <Text variant="CONTENT_2" color="text_3">
                    {formatCurrency(total)}
                  </Text>
                </div>
              </Box>
            );
          },
        },
        {
          title: 'Taxes',
          align: 'left',
          width: '8rem',
          render(total: number, record) {
            const tax = record.saleTax + record.useTax;
            return (
              <Text variant="CONTENT_2" color="text_3">
                {formatCurrency(tax)}
              </Text>
            );
          },
        },
        {
          title: 'Discounts',
          align: 'left',
          width: '8rem',
          render(total: number, record) {
            const discount = (record.discountItem || 0) + (record.discountTicket || 0);
            return (
              <Text variant="CONTENT_2" color="text_3">
                {formatCurrency(discount)}
              </Text>
            );
          },
        },
        {
          title: 'Tips',
          dataIndex: '',
          align: 'left',
          render(_: any, record: Bill) {
            const payment = record.payments?.find(
              (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
            );
            if (payment) {
              return <TipColumn record={record} />;
            }
            return (
              <Text variant="CONTENT_2" color="error_4">
                {formatCurrency(record.tip) || '--'}
              </Text>
            );
          },
        },
        {
          title: 'Fees',
          dataIndex: '',
          align: 'left' as any,
          render(_: any, record: Bill) {
            const paymentCreditCard = record.payments?.find(
              (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
            );
            return (
              <Text variant="CONTENT_2" color="error_4">
                {formatCurrency(paymentCreditCard?.feeCreditCard) || '--'}
              </Text>
            );
          },
        },
        {
          title: 'Total',
          dataIndex: 'total',
          align: 'left',
          render(total: number, record) {
            const _total = isCreditPage ? record.payments.find(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD)?.total : total;
            return (
              <Text variant="CONTENT_2" color="info_4">
                {formatCurrency(_total)}
              </Text>
            );
          },
        },
        {
          title: 'Payments',
          dataIndex: '',
          align: 'left',
          render(_: any, record: Bill) {
            const payments = isCreditPage ? record.payments.filter(o => o.paymentType === PAYMENT_TYPE.CREDIT_CARD) : record.payments;
            return (
              <Box>
                {payments?.map((paymentItem) => {
                  return (
                    <div
                      key={paymentItem.paymentId}
                      style={{ display: 'flex', alignItems: 'center', height: 30 }}
                    >
                      {typeToIcon(paymentItem.paymentType, paymentItem.last4)}
                    </div>
                  );
                })}
              </Box>
            );
          },
        },
        ...(params.paymentType === PAYMENT_TYPE.CREDIT_CARD
          ? [
            {
              title: 'Trans#',
              dataIndex: '',
              align: 'left' as any,
              render(_: any, record: Bill) {
                const paymentCreditCard = record.payments?.find(
                  (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
                );
                return (
                  <Text variant="CONTENT_2" color="info_4" style={{ fontWeight: 'bolder' }}>
                    #{paymentCreditCard?.transNumber || '--'}
                  </Text>
                );
              },
            },
          ]
          : []),
        {
          title: 'Actions',
          dataIndex: '',
          render(_: any, record: Bill) {
            const paymentCreditCard = record.payments?.find(
              (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
            );

            const isShowVoidButton = !!record?.void;
            const isShowRefund = !!record?.refund;

            return (
              <ActionBoxStyled>
                {paymentCreditCard && (
                  <>
                    <AdjustTipButton v2={v2} record={record} />
                    {isShowRefund && (
                      <RefundModalButton
                        v2={v2}
                        billId={record.billId}
                        maxAmount={record.total}
                      />
                    )}
                  </>
                )}
                {isShowVoidButton && (
                  <VoidModalButton v2={v2} billDetail={record} />
                )}
                <ActionButton
                  color={colorTheme.fill_1}
                  bgColor={colorTheme.info_3}
                  v2={v2}
                  onClick={() =>
                    navigate(`/store/${storage.shop_id.get()}/tickets/${record.billId}`)
                  }
                >
                  DETAILS
                </ActionButton>
              </ActionBoxStyled>
            );
          },
        },
      ],
      REFUND: [
        {
          title: 'Trans#',
          dataIndex: '',
          align: 'left' as any,
          render(_: any, record: Bill) {
            const paymentCreditCard = record.payments?.find(
              (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
            );
            return (
              <Text variant="CONTENT_2" color="info_4" style={{ fontWeight: 'bolder' }}>
                #{paymentCreditCard?.transNumber || '--'}
              </Text>
            );
          },
        },
        {
          title: 'Sub-total',
          dataIndex: 'subTotal',
          align: 'left',
          render(total: number) {
            return (
              <Text variant="CONTENT_2" color="text_3">
                {formatCurrency(total)}
              </Text>
            );
          },
        },
        {
          title: 'Tips',
          dataIndex: '',
          align: 'left',
          render(_: any, record: Bill) {
            return (
              <Text variant="CONTENT_2" color="error_4">
                {formatCurrency(record.tip) || '--'}
              </Text>
            );
          },
        },
        {
          title: 'Total',
          dataIndex: 'total',
          align: 'left',
          render(total: number) {
            return (
              <Text variant="CONTENT_2" color="info_4">
                {formatCurrency(total)}
              </Text>
            );
          },
        },
        {
          title: '',
          dataIndex: '',
          align: 'left',
          render(_: any, record: Bill) {
            return (
              <ButtonNavigateRefund
                v2={v2}
                onClick={() =>
                  navigate(`${location.pathname}/${record.billId}`)
                }
              >
                <Text variant="CONTENT_1" color="text_3">
                  Detail
                </Text>
              </ButtonNavigateRefund>
            );
          },
        },
      ],
      VOID: [
        {
          title: 'Trans#',
          dataIndex: '',
          align: 'left' as any,
          render(_: any, record: Bill) {
            const paymentCreditCard = record.payments?.find(
              (item) => item.paymentType === PAYMENT_TYPE.CREDIT_CARD
            );
            return (
              <Text variant="CONTENT_2" color="info_4" style={{ fontWeight: 'bolder' }}>
                #{paymentCreditCard?.transNumber || '--'}
              </Text>
            );
          },
        },
        {
          title: 'Sub-total',
          dataIndex: 'subTotal',
          align: 'left',
          render(total: number) {
            return (
              <Text variant="CONTENT_2" color="text_3">
                {formatCurrency(total)}
              </Text>
            );
          },
        },
        {
          title: 'Tips',
          dataIndex: '',
          align: 'left',
          render(_: any, record: Bill) {
            return (
              <Text variant="CONTENT_2" color="error_4">
                {formatCurrency(record.tip) || '--'}
              </Text>
            );
          },
        },
        {
          title: 'Total',
          dataIndex: 'total',
          align: 'left',
          render(total: number) {
            return (
              <Text variant="CONTENT_2" color="info_4">
                {formatCurrency(total)}
              </Text>
            );
          },
        },
        {
          title: 'Reason',
          dataIndex: 'reason',
          align: 'left',
          render(reason: string) {
            return (
              <div style={{ maxWidth: '5rem' }}>
                <Text variant="CONTENT_2" color="error_4" textAlign="left" className='text-overflow' >
                  {reason}
                </Text>
              </div>
            );
          },
        },
        {
          title: '',
          dataIndex: '',
          align: 'left',
          render(_: any, record: Bill) {
            return (
              <ButtonNavigateVoid
                v2={v2}
                onClick={() =>
                  navigate(`${location.pathname}/${record.billId}`)
                }
              >
                <Text variant="CONTENT_1" color="text_3">
                  Detail
                </Text>
              </ButtonNavigateVoid>
            );
          },
        },
      ],
      DELETE: [
        {
          title: 'Customer',
          dataIndex: 'customerName',
          align: 'left',
          render(text) {
            return (
              <Text
                variant="CONTENT_2"
                color="text_3"
                style={{ whiteSpace: 'nowrap' }}
              >
                {text ? text : '--'}
              </Text>
            );
          },
        },
        {
          title: 'Phone',
          dataIndex: 'customerPhone',
          align: 'left',
          render(text) {
            if (visibleCusPhone) {
              return (
                <Text
                  variant="CONTENT_2"
                  color="text_3"
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {text ? formatPhone(text) : '--'}
                </Text>
              );
            }

            return (
              <Text
                variant="CONTENT_2"
                color="text_3"
                style={{ whiteSpace: 'nowrap' }}
              >
                {text ? maskPhone(text) : '--'}
              </Text>
            );
          },
        },
        {
          title: 'Amount',
          dataIndex: 'total',
          align: 'left',
          render(total: number) {
            return (
              <Text variant="CONTENT_2" color="text_3">
                {formatCurrency(total)}
              </Text>
            );
          },
        },
      ],
    };

    const specificColumn = isCreditPage ? additionalColumnsMap['CLOSED'] : (additionalColumnsMap[params.status ? typeof params.status === 'string' ? params.status : get(params, ['status', 0], '') : 'OPEN_TICKET'] || []);

    return [
      ...baseColumns,
      ...specificColumn, // Spread the additional columns based on param.status
    ];
  }, [visibleCusPhone, params.status, params.paymentType]);

  const handleChangePage = (page: number) => {
    dispatch(
      ticketListActions.setTicketsParams({
        page: page,
      })
    );
  };

  const rowClassName = (record: Bill) => isCreditPage && `${record?.billStatus} CREDIT_PAGE`;

  return (
    <TicketTableStyled className={v2 ? 'v2' : ''}>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey={'billId'}
        rowClassName={rowClassName}
        pagination={{
          total: totalElement ?? 0,
          pageSize: params.size,
          current: params.page,
          showSizeChanger: false,
        }}
        handleChangePage={handleChangePage}
      />
      <ModalConfirm ref={modalConfirm} />
    </TicketTableStyled>
  );
};

export default TicketTable;
const TicketTableStyled = styled(TableGreenEvenStyled)`
  .ant-table-cell {
    padding: 0.5rem !important;
  }
  th.ant-table-cell {
    border-right: 1px solid #fff;
    text-align: center !important;
    &:nth-last-child(1) {
      border-right: none;
    }
    padding: 14px 0.5rem !important;
  }
  // tr.ant-table-row {
  //   &.VOID {
  //     background-color: #feedbf6e;
  //     &:hover {
  //       td {
  //         background-color: #feedbf6e;
  //       }
  //     }
  //   }
  //   &.REFUND {
  //     background-color: #acc3c854;
  //     &:hover {
  //       td {
  //         background-color: #acc3c854;
  //       }
  //     }
  //   }
  // }


&.v2 {
  .ant-table-wrapper .ant-table .ant-table-content{
    .ant-table-thead th.ant-table-cell, .ant-table-thead td.ant-table-cell {
      height: 48px;
      padding: 0px 8px;
      gap: 10px;
      align-self: stretch;
      border-bottom: 1px solid #CCD4DC;
      background: #6FABB6;
      color: #fff;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: capitalize;
      text-align: left !important;
      &::before {
        width: 1.5px;
        height: 100%;
      }
    }

    .ant-table-tbody td.ant-table-cell {
      .BODY_1, .CONTENT_2 {
        color: #1D2129;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
    }

    .ant-table-tbody .ant-table-row:nth-child(even) td {
      background: #E4F4F6;
      border-bottom: 1px solid #E4F4F6;
    }
  }
}
`;

const ButtonNavigate = styled.div`
  border-radius: 5px;
  background: var(--info-infor-3, #6fabb6);
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
  cursor: pointer;
`;
const ButtonNavigateVoid = styled(ButtonNavigate) <{ v2?: boolean }>`
  background: #ffdc7c;

  ${({ v2 }) => v2 && `
    display: flex;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 4px;
    border-radius: 4px;
    border-radius: 4px;
    background: #FEEDBF !important;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #FDCC43 inset;
    .CONTENT_1, span {
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

  `}

`;
const ButtonNavigateRefund = styled(ButtonNavigate) <{ v2?: boolean }>`
  background: #ff7d00;

  ${({ v2 }) => v2 && `
    display: flex;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 4px;
    border-radius: 4px;
    background: #FF7D00;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #DC5500 inset;
    .CONTENT_1, span {
      color: #fff;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

  `}

`;
const ActionButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['bgColor'].includes(prop),
}) <{ bgColor?: string; color?: string, v2?: boolean }>`
  display: flex;
  padding: 8px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background: ${(props) =>
    props.bgColor || 'var(--info-infor-3, #6fabb6)'} !important;
  flex-grow: 1;
  flex-basis: 1rem;
  cursor: pointer;
  color: ${(props) => props.color || 'var(--text-text-1, #fff)'} !important;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-color: ${(props) =>
    props.bgColor || 'var(--info-infor-3, #6fabb6)'} !important;
  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    opacity: 0.5;
  }

  ${({ v2, bgColor }) => v2 && `
    display: flex;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 4px;
    background: #6FABB6 !important;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #144B55 inset !important;
    .CONTENT_2, span {
      color: #fff !important;
      text-align: center;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    ${bgColor === colorTheme.error_2 && `
      border-radius: 4px;
      background: #FF8890 !important;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset !important;
    `}
    ${bgColor === colorTheme.info_3 && `
      border-radius: 4px;
      border: 1.5px solid #2D6772  !important;
      background: #FFF !important;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #144B55 inset !important;

      .CONTENT_2, span {
        color: #2D6772 !important;
      }

    `}

  `}
`;

const ActionBoxStyled = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
`;
