import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import { turnManualAPIActions } from 'features/turn/services/actions';
import turnApis from 'features/turn/services/apis';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { IResponseDataBody } from 'services/response';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { turnSettingRef } from '../../Modals/ModalTurnSetting';
import Icon from 'assets/Icons';
import { turnManualSelectors } from 'features/turn/services/selectors';
import ReOrderClockIn from './ReOrderClockIn';

const TurnActions = () => {
  const { shop_id = '' } = useParams();
  const navigate = useNavigate();
  const modalConfirm = useModalConfirmRef();
  const dispatch = useAppDispatch();
  const setLoadingPage = useSetLoadingPage();
  const staffActiveId = turnManualSelectors.staffActiveId();

  const handleClear = async () => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<boolean> = await turnApis.clearAllManualTurn();
      if (res.data.data) {
        dispatch(turnManualAPIActions.getTurns.fetch());
      }
    } catch (error) {
    } finally {
      setLoadingPage(false);
    }
  };

  const handleLogoutStaff = async () => {
    if (!staffActiveId) return;
    dispatch(turnManualAPIActions.logoutStaff(staffActiveId));
  };

  const actionClick = (type: 'home' | 'clear' | 'refresh' | 'setting' | 'logout') => () => {
    switch (type) {
      case 'home':
        navigate(`/store/${shop_id}`);
        break;
      case 'clear':
        modalConfirm.current?.open('Are you about to clear all turn?', handleClear);
        break;
      case 'refresh':
        dispatch(turnManualAPIActions.init());
        break;
      case 'setting':
        turnSettingRef.current?.open();
        break;
      case 'logout':
        modalConfirm.current?.open('Are you about to logout this staff?', handleLogoutStaff);
        break;
      default:
        break;
    }
  };

  return (
    <Container className='TurnActions'>
      <div className="button-configs">
        <ReOrderClockIn />
        <button className="btn btn-clear" onClick={actionClick('clear')}>
          <div className="icon">{IconClear}</div>
          <span>CLEAR</span>
        </button>
        <button className="btn btn-refresh" onClick={actionClick('refresh')}>
          <div className="icon">{IconRefresh}</div>
          <span>REFRESH</span>
        </button>
        <button className="btn btn-home" onClick={actionClick('home')}>
          <div className='icon'>{IconHome}</div>
          <span>HOME</span>
        </button>
        <button className="btn btn-setting" onClick={actionClick('setting')}>
          <div className="icon">{IconSetting}</div>
          <span>SETTING</span>
        </button>
        <button disabled={!staffActiveId} className="btn btn-logout" onClick={actionClick('logout')}>
          <div className="icon"><Icon type='signOut' /></div>
          <span>CLOCK-OUT</span>
        </button>
      </div>
      <ModalConfirm ref={modalConfirm} />
    </Container>
  );
};

export default TurnActions;
const Container = styled.div`
display: flex;
padding: 0px 8px 0px 0px;
align-items: flex-start;
gap: 16px;
align-self: stretch;
border-right: px solid #CCD4DC;
.div-action {
  flex: 1;
  display: flex;
}

.button-configs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;
  row-gap: 15px;
  align-self: stretch;
  button.btn {
    display: flex;
    padding: 0px 16px;
    height: 60px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 5px;
    border: 1.5px solid #1D2129;
    background: #fff;

    .icon {
      width: 32px;
    }

    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
`;

const IconHome = <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M8.25 13C8.66421 13 9 13.3531 9 13.7887V25.4225H12.75V19.507C12.75 18.9841 12.9475 18.4825 13.2992 18.1127C13.6508 17.743 14.1277 17.5352 14.625 17.5352H18.375C18.8723 17.5352 19.3492 17.743 19.7008 18.1127C20.0525 18.4825 20.25 18.9841 20.25 19.507V25.4225H24V13.7887C24 13.3531 24.3358 13 24.75 13C25.1642 13 25.5 13.3531 25.5 13.7887V25.4225C25.5 25.8409 25.342 26.2421 25.0607 26.538C24.7794 26.8338 24.3978 27 24 27H19.5C19.0858 27 18.75 26.6469 18.75 26.2113V19.507C18.75 19.4025 18.7105 19.3021 18.6402 19.2282C18.5698 19.1542 18.4745 19.1127 18.375 19.1127H14.625C14.5255 19.1127 14.4302 19.1542 14.3598 19.2282C14.2895 19.3021 14.25 19.4025 14.25 19.507V26.2113C14.25 26.6469 13.9142 27 13.5 27H9C8.60218 27 8.22065 26.8338 7.93934 26.538C7.65804 26.2421 7.5 25.8409 7.5 25.4225V13.7887C7.5 13.3531 7.83579 13 8.25 13Z" fill="#1D2129" />
  <path fillRule="evenodd" clipRule="evenodd" d="M17.6086 5.43168L20.5 8.19969V6.59924C20.5 6.15738 20.8582 5.79918 21.3 5.79918H23.7C24.1418 5.79918 24.5 6.15738 24.5 6.59924V12.029L28.2532 15.622C28.5724 15.9275 28.5834 16.434 28.2779 16.7532C27.9724 17.0724 27.466 17.0834 27.1468 16.7779L16.516 6.60081C16.5112 6.60038 16.5059 6.60013 16.5003 6.60012C16.4945 6.60011 16.489 6.60036 16.484 6.6008L5.8532 16.7779C5.53403 17.0834 5.02762 17.0724 4.7221 16.7532C4.41658 16.434 4.42764 15.9275 4.7468 15.622L15.3921 5.43102C15.7184 5.10015 16.1632 4.99939 16.5031 5C16.8417 5.00061 17.2848 5.10219 17.6086 5.43168ZM22.9 10.4973V7.3993H22.1V9.73141L22.9 10.4973Z" fill="#1D2129" />
</svg>;

const IconClear = <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M22.6326 4.03682C23.4726 3.28162 24.7894 3.12322 25.7798 3.67362C27.0934 4.33122 27.6486 6.10402 26.9414 7.39522C25.879 9.45922 24.7942 11.5136 23.7302 13.5776C24.4198 14.7008 24.6534 16.112 24.2406 17.376C23.063 20.832 21.6982 24.2736 19.6038 27.2896C19.0998 27.9952 18.1078 28.2576 17.3094 27.9296C15.2134 27.2032 13.2262 26.1824 11.3782 24.96C9.5142 23.7344 7.7062 22.4 6.135 20.808C5.8358 20.496 5.4982 20.1584 5.4598 19.7024C5.3478 18.9184 5.9734 18.1504 6.7478 18.048C7.9814 17.8304 9.1686 17.3904 10.2662 16.7856C12.159 15.784 13.5814 14.136 14.983 12.5584C15.9574 11.472 17.4134 10.8704 18.8678 10.8896C19.7878 9.12802 20.6966 7.36162 21.6102 5.59842C21.8982 5.04802 22.1478 4.44962 22.6326 4.03682ZM23.455 5.66082C22.4806 7.52482 21.5206 9.39842 20.5478 11.264C21.2118 11.5968 21.8806 11.9184 22.5174 12.2992C23.4854 10.4224 24.4726 8.55522 25.439 6.67842C25.7558 6.14722 25.5382 5.39362 24.9766 5.12802C24.4326 4.83202 23.7014 5.09762 23.455 5.66082ZM17.4646 12.8096C16.623 13.1184 16.055 13.8368 15.4966 14.4976C17.743 15.6768 19.9926 16.8528 22.2374 18.0368C22.647 17.064 23.0342 15.9408 22.5478 14.9184C22.1254 13.8016 20.9702 13.312 19.9686 12.8416C19.1878 12.4736 18.2566 12.4864 17.4646 12.8096ZM7.3158 19.6416C8.183 20.52 9.135 21.3056 10.1142 22.056C10.5462 21.8288 10.919 21.5088 11.3302 21.248C11.8182 20.984 12.495 21.3648 12.5206 21.9216C12.5734 22.5056 11.9734 22.792 11.5958 23.1072C12.4966 23.712 13.4086 24.3024 14.367 24.816C14.895 24.304 15.3782 23.7424 15.7558 23.1088C15.9638 22.7696 16.2038 22.3488 16.663 22.352C17.2406 22.2992 17.7366 22.976 17.4486 23.5008C17.0534 24.2704 16.495 24.944 15.927 25.5904C16.6374 25.8848 17.335 26.2288 18.0838 26.4096C18.287 26.3552 18.3638 26.1312 18.487 25.984C19.783 23.9888 20.8246 21.84 21.6598 19.616C19.2134 18.32 16.759 17.0384 14.3062 15.7504C12.431 17.7056 9.9734 19.0944 7.3158 19.6416Z" fill="#1D2129" />
  <path d="M7.72422 10.4466C8.57862 10.0434 9.61862 10.925 9.35942 11.8338C9.19942 12.6962 8.02022 13.0754 7.38822 12.4674C6.71782 11.9282 6.91782 10.7394 7.72422 10.4466Z" fill="#1D2129" />
  <path d="M4.53684 14.1167C5.37364 13.8079 6.30964 14.6543 6.08404 15.5167C5.93684 16.4175 4.68404 16.7999 4.06164 16.1295C3.41044 15.5471 3.69044 14.3439 4.53684 14.1167Z" fill="#1D2129" />
</svg>
  ;

const IconRefresh = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M26.2845 14.8638C26.2254 14.3789 25.7996 14.036 25.3028 14.0833C24.8178 14.1424 24.463 14.58 24.5221 15.0649C24.8178 17.6667 23.9189 20.2093 22.0619 22.066C18.9629 25.1645 14.0661 25.3892 10.7069 22.7401H11.8188C12.3037 22.7401 12.7059 22.338 12.7059 21.8532C12.7059 21.3683 12.3037 20.9662 11.8188 20.9662H8.47142C7.98647 20.9662 7.58431 21.3683 7.58431 21.8532V25.2C7.58431 25.6849 7.98647 26.087 8.47142 26.087C8.95637 26.087 9.35852 25.6849 9.35852 25.2V23.9346C11.2747 25.543 13.6285 26.3472 15.9941 26.3472C18.6436 26.3472 21.2931 25.3419 23.3157 23.3196C25.5512 21.0845 26.6275 17.9978 26.2845 14.8638ZM9.9381 9.93225C13.0371 6.83375 17.9339 6.60906 21.2931 9.25815H20.1812C19.6963 9.25815 19.2941 9.66024 19.2941 10.1451C19.2941 10.63 19.6963 11.0321 20.1812 11.0321H23.5286C24.0135 11.0321 24.4157 10.63 24.4157 10.1451V6.79828C24.4157 6.3134 24.0135 5.9113 23.5286 5.9113C23.0436 5.9113 22.6415 6.3134 22.6415 6.79828V8.06369C18.5844 4.65771 12.5048 4.85876 8.68432 8.67866C6.44881 10.9138 5.37246 14.0005 5.71547 17.1345C5.76278 17.5839 6.15311 17.9268 6.59075 17.9268C6.62623 17.9268 6.66172 17.9268 6.68537 17.9268C7.17033 17.8677 7.52517 17.4301 7.46603 16.9452C7.17033 14.3435 8.06926 11.8008 9.92627 9.94407L9.9381 9.93225Z" fill="#1D2129" />
</svg>
  ;

const IconSetting = <svg xmlns="http://www.w3.org/2000/svg" width="33" height="32" viewBox="0 0 33 32" fill="none">
  <path fillRule="evenodd" clipRule="evenodd" d="M11.7464 4.13122C11.9868 3.26558 12.775 2.6665 13.6734 2.6665H19.3266C20.225 2.6665 21.0132 3.26557 21.2536 4.13122L21.9354 6.58555L24.402 5.94875C25.2719 5.72417 26.1848 6.10721 26.634 6.88526L29.4606 11.7811C29.9098 12.5591 29.7851 13.5413 29.1556 14.1823L27.371 15.9998L29.1556 17.8174C29.7851 18.4584 29.9098 19.4405 29.4606 20.2186L26.634 25.1144C26.1848 25.8925 25.2719 26.2755 24.402 26.0509L21.9354 25.4141L21.2536 27.8685C21.0132 28.7341 20.225 29.3332 19.3266 29.3332H13.6734C12.775 29.3332 11.9868 28.7341 11.7464 27.8685L11.0646 25.4143L8.59857 26.0509C7.72868 26.2755 6.81578 25.8925 6.36657 25.1144L3.53996 20.2186C3.09076 19.4405 3.21549 18.4584 3.84492 17.8174L5.62951 15.9998L3.84493 14.1823C3.21549 13.5412 3.09076 12.5591 3.53997 11.7811L6.36658 6.88526C6.81578 6.10721 7.72868 5.72417 8.59857 5.94875L11.0646 6.58541L11.7464 4.13122ZM10.8894 24.7836L10.8897 24.7844ZM14.1801 5.33317L13.4588 7.92983C13.167 8.98036 12.0875 9.60359 11.0318 9.33105L8.42262 8.65743L6.10273 12.6756L7.9909 14.5986C8.75478 15.3766 8.75478 16.6231 7.9909 17.4011L6.10273 19.3241L8.42261 23.3423L11.0318 22.6686C12.0875 22.3961 13.167 23.0193 13.4588 24.0699L14.1801 26.6665H18.8199L19.5412 24.0697C19.833 23.0192 20.9125 22.396 21.9682 22.6685L24.5779 23.3423L26.8978 19.3241L25.0096 17.4011C24.2458 16.6231 24.2458 15.3766 25.0096 14.5986L26.8978 12.6756L24.5779 8.65743L21.9682 9.33118C20.9125 9.60373 19.833 8.98049 19.5412 7.92996L18.8199 5.33317H14.1801ZM16.5 13.3332C15.0272 13.3332 13.8333 14.5271 13.8333 15.9998C13.8333 17.4726 15.0272 18.6665 16.5 18.6665C17.9728 18.6665 19.1667 17.4726 19.1667 15.9998C19.1667 14.5271 17.9728 13.3332 16.5 13.3332ZM11.1667 15.9998C11.1667 13.0543 13.5545 10.6665 16.5 10.6665C19.4455 10.6665 21.8333 13.0543 21.8333 15.9998C21.8333 18.9454 19.4455 21.3332 16.5 21.3332C13.5545 21.3332 11.1667 18.9454 11.1667 15.9998Z" fill="#1D2129" />
</svg>;
