import React from 'react';
import TabTicketByParams from '../Tabs/TabTicketByParams';
import TicketFilterV2 from './Filter';
import styled from 'styled-components';

const DeletedTickets = () => {
  return (
    <Container>
      <TicketFilterV2 type='DELETE' />
      <div className="tickets">
        <TabTicketByParams v2 tableKey={'DELETE'} />
      </div>
    </Container>
  );
};

export default DeletedTickets;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  .tickets {
    flex: 1;
    position: relative;
  }
`;
