import multiplePaymentSelectors from 'features/payment/pages/PaymentPage/services/selectors';
import { MultipleCardTypePassData, PaymentSocketData, TypePaymentActors } from 'features/payment/pages/PaymentPage/services/types/socketPayment';
import React, { useEffect, useMemo, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Message } from 'stompjs';
import { useAppDispatch } from 'store/hooks';
import storage from 'utils/sessionStorage';
import AddATipMultiCard, { useAddATipMultiCardRef } from '../../components/AddATip';
import useUpdateTip from '../../hooks/useUpdateTip';
import multiCardActions from '../../services/actions';
import multiCardSelectors from '../../services/selectors';
import { uiLoadingBasicRef } from 'services/UI/LoadingPage/UILoadingRef';
import { set, sumBy } from 'lodash';
import { PAYMENT_PASS_DATA_TOPIC } from 'utils/socket';
import { useSocketContext } from 'hooks/useSocket';
import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';

import { IResponseDataBody } from 'services/response';
import multiCardApis from '../../services/apis';
import { removeBase64Prefix } from 'utils/removeBase64Prefix';
import Signature, { ISignatureRef } from '../../components/CustomerSignature';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import TicketDetail from './TicketDetail';
import PaymentDetail from './PaymentDetail';
import { ICalcDetailTicketView } from '../../services/types/card';
import CreditCardLoading, { ICreditPaymentLoadingRef } from 'features/payment/components/PaymentLoading/CreditCardLoading';

const CustomerMultipleCard = () => {
  const paymentLoadingRef = useRef<IPaymentLoadingRef>(null);
  const creditCardLoadingRef = useRef<ICreditPaymentLoadingRef>(null);
  const signatureRef = useRef<ISignatureRef>(null);
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();
  const tipRef = useAddATipMultiCardRef();
  const detail = multiCardSelectors.detail();
  const socketContext = useSocketContext();
  const customerMessage = multiplePaymentSelectors.customerSide.getMessage();
  const idCreditSignature = useRef<string>('');
  const isDoneRef = useRef<boolean>(false);
  const cards = multiCardSelectors.getCards();

  const remaining = useMemo(() => {
    const totalPayment = sumBy(Object.values(cards), o => (o?.amount ?? 0));
    const value = (detail?.total ?? 0) - totalPayment;
    return value > 0 ? value : 0;
  }, [cards, detail]);

  const calc: ICalcDetailTicketView = useMemo(() => {
    return ({
      serviceCharge: detail?.cardFee || 0,
      discByItem: (-(detail?.discountItem || 0)),
      discByTicket: (-(detail?.discountTicket || 0)),
      subTotal: ((detail?.subTotal || 0) - ((detail?.discountItem || 0) + (detail?.discountTicket || 0))),
      balance: (detail?.subTotal || 0),
      saleTax: (detail?.saleTax || 0),
      useTax: (detail?.useTax || 0),
      tip: (detail?.tip || 0),
      total: (detail?.total || 0),
    });
  }, [detail]);



  React.useEffect(() => {
    dispatch(multiCardActions.getTransactionDetail.fetch(id));
  }, []);

  const sendingData = (type: string, data?: any) => {
    const body: PaymentSocketData = {
      shopId: storage.shop_id.get(),
      billId: detail?.billId || '',
      actor: TypePaymentActors.CUSTOMER,
      data,
    };
    switch (type) {
      case MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING: {
        set(body, 'action', MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING);
        break;
      }
      case MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED: {
        set(body, 'action', MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED);
        break;
      }
      case MultipleCardTypePassData.PAYMENT_COMPLETED: {
        set(body, 'action', MultipleCardTypePassData.PAYMENT_COMPLETED);
        break;
      }
      case MultipleCardTypePassData.ON_CUSTOMER_SIGNATURE_CHANGE: {
        set(body, 'action', MultipleCardTypePassData.ON_CUSTOMER_SIGNATURE_CHANGE);
        break;
      }
      default:
        break;
    }

    socketContext.send(PAYMENT_PASS_DATA_TOPIC, body);
  };

  const listeningData = (message: Message) => {
    if (!message.body) return;
    const payment: PaymentSocketData<string> = JSON.parse(message.body);
    if (!payment) return;

    if (payment.shopId !== storage.shop_id.get()) return;

    if (payment.actor !== TypePaymentActors.CASHIER) return;

    switch (payment.action) {
      case MultipleCardTypePassData.REFRESH_DETAIL: {
        tipRef.current?.close();
        uiLoadingBasicRef.current?.setVisible(false);
        dispatch(multiCardActions.refreshDetail.fetch(detail?.billId || ''));
        break;
      }
      case MultipleCardTypePassData.ON_TIP: {
        tipRef.current?.close();
        uiLoadingBasicRef.current?.openTimeout(15000);
        break;
      }
      case MultipleCardTypePassData.REQUEST_TIP: {
        tipRef.current?.open();
        break;
      }
      case MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING: {
        tipRef.current?.setSoftAmount(payment.data?.tip || 0);
        break;
      }
      case MultipleCardTypePassData.PAYMENT_PROCESSING: {
        creditCardLoadingRef.current?.open(payment.data?.amount || 0, 120000);
        break;
      }
      case MultipleCardTypePassData.PAYMENT_PROCESSING_DONE: {
        creditCardLoadingRef.current?.close();
        break;
      }
      case MultipleCardTypePassData.REQUEST_SIGNATURE: {
        creditCardLoadingRef.current?.close();
        idCreditSignature.current = payment.data?.id || '';
        isDoneRef.current = payment.data?.isDone || false;
        signatureRef.current?.open();
        break;
      }
      case MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED: {
        signatureRef.current?.close();
        dispatch(multiCardActions.getListMultipleCards.fetch(detail?.billId || ''));
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    if (!customerMessage) return;
    listeningData(customerMessage);
  }, [customerMessage]);


  const updateTip = useUpdateTip({ actor: TypePaymentActors.CUSTOMER });
  const onAddATip = (val: number) => {
    if (!detail?.billId) return;
    tipRef.current?.close();
    updateTip(detail?.billId, val);
  };

  const onChangeTip = (val: number) => {
    sendingData(MultipleCardTypePassData.PASS_TIP_VALUE_ON_TYPING, { tip: val });
  };
  const onSignatureChange = (val: string) => {
    sendingData(MultipleCardTypePassData.ON_CUSTOMER_SIGNATURE_CHANGE, val);
  };
  const onSignatureDone = async (signatureValue: string) => {
    const idCreditCard = idCreditSignature.current;
    if (!idCreditCard) return;
    signatureRef.current?.close();
    uiLoadingBasicRef.current?.setVisible(true);
    try {
      const res: IResponseDataBody<boolean> = await multiCardApis.updateSignatureForCreditCard(idCreditCard || '', removeBase64Prefix(signatureValue));
      if (res.data.data) {
        if (!isDoneRef.current) {
          sendingData(MultipleCardTypePassData.UPDATE_SIGNATURE_COMPLETED, {});
          dispatch(multiCardActions.getListMultipleCards.fetch(detail?.billId || ''));
        }
        else {
          sendingData(MultipleCardTypePassData.PAYMENT_COMPLETED, {});
        }
        return;
      }
    } catch (error) { }
    finally {
      uiLoadingBasicRef.current?.setVisible(false);
    }
  };

  if (!detail) return null;

  return (
    <>
      <CustomerSidePage>
        <Col span={13}>
          <TicketDetail
            detailTicket={detail}
            calc={calc}
          />
        </Col>
        <Col span={11}>
          <PaymentDetail
            cards={cards}
            remaining={remaining}
            total={calc.total}
          />
        </Col>
      </CustomerSidePage>
      <AddATipMultiCard ref={tipRef} onAddATip={onAddATip} onChangeTip={onChangeTip} />
      <PaymentLoading ref={paymentLoadingRef} />
      <CreditCardLoading ref={creditCardLoadingRef} />
      <Signature
        ref={signatureRef}
        onAgree={onSignatureDone}
        onSignatureChange={onSignatureChange}
      />
    </>
  );
};

export default CustomerMultipleCard;


const CustomerSidePage = styled(Row)`
  background: var(--fill-fill-3, #E5E5E5);
  min-height: 100vh;
  padding: 5vw;
`;
