import AvatarBox from 'components/AvatarBox';
import Text from 'components/Text';
import React, { useMemo, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import StaffSearchInput from './StaffSearchInput';

export type IAddStaffFormProps = {
  staff?: IStaffItemData | undefined;
  onPickStaff: (option: IStaffItemData) => void;
};
const AddStaffFormV2: React.FC<IAddStaffFormProps> = ({ staff, onPickStaff }) => {
  const _staffList = shopSelectors.data.staffs();
  const staffList = useMemo(() => _staffList?.filter(o => !o.anyBody) || [], [_staffList]);
  const [searchText] = useState('');
  const initOptions = useMemo(() => {
    return staffList.map(o => ({
      ...o,
      label: o.firstName,
      value: o.id,
      keyword: o.firstName.toLowerCase(),
    }));
  }, [staffList]);

  const options = useMemo(() => {
    if (!searchText.trim()) return initOptions;

    return initOptions.filter((item) =>
      item.keyword.includes(searchText.toLowerCase())
    );
  }, [initOptions, searchText]);

  const handleClickItem = (data: IStaffItemData) => {
    onPickStaff(data);
  };

  return (
    <AddStaffFormStyled className={'v2'}>
      <ContainerStaffV2>
        <div className="input-search-container">
          <StaffSearchInput onPickAll={() => undefined} v2 onPickStaff={onPickStaff} />
        </div>
        <Text mb={.5} />
        <div className="staff-list">
          {options.map((item) => {
            return (
              <div
                className='staff-box'
                key={item.id}
                onClick={() => handleClickItem(item)}
              >
                <AvatarBox
                  className='AvatarBox'
                  src={item.urlImage}
                  name={item.firstName}
                  selected={item.id === staff?.id}
                />
              </div>
            );
          })}
        </div>
      </ContainerStaffV2>
    </AddStaffFormStyled>
  );
};

export default AddStaffFormV2;
const ContainerStaffV2 = styled.div`
  background: #F5F5F5;
  flex: 1;
  .input-search-container {
    background: #FFF;
    padding: 16px;
  }
  .staff-list {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(160px, 1fr));
    gap: 8px;
    padding: 0px 16px;
  }
  .staff-box {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 5px;
    background: #FFF;
    &:hover {
      background: #C6E9EF;
      .AvatarBox {
        background: #C6E9EF;
        border-color: #C6E9EF;
      }
    }
  }
  .CONTENT_2 {
    font-size: 24px;
  }

  .StaffSearchInput .ant-input-affix-wrapper {
    border-radius: 100px;
    padding: 0px;
    .ant-input-suffix {
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0px 100px 100px 0px;
      background: #2D6772;
    }
    input {
      border-radius: 100px; 
      padding: 24px;
      height: 56px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      align-self: center;
    }
    border: 3px solid #2D6772;
    background: #FFF;
  }
`;
const AddStaffFormStyled = styled.div`
  min-height: 40rem;
  &.v2 {
    display: flex;
  }
`;