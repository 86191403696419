
import { ICalendarItemData } from 'features/appointment/services/types/calendar';
import React, { useMemo } from 'react';
import DraggableItem from '../Calendar/TableView/Body/DnD/DraggableItem';
import styled from 'styled-components';
import { IAppointmentItemData } from 'features/appointment/services/types/appointment';
import Text from 'components/Text';
import { momentTimezone } from 'utils/time';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { Row } from 'antd';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import appointmentActions from 'features/appointment/services/actions';
type Props = {
  anybodyList: ICalendarItemData[];
};
const AnybodyList = ({ anybodyList }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onDoubleClick = (data: ICalendarItemData) => {
    navigate(`/store/${storage.shop_id.get()}/appointments/${data?.id || ''}`);
    dispatch(appointmentActions.setAppointmentDetail(data.data as IAppointmentItemData));
  };

  return (
    <Container wrap={false}>
      {anybodyList.map(o => <AnybodyTicketItem key={o.id} data={o} onDoubleClick={() => onDoubleClick(o)} />)}
    </Container>
  );
};

export default AnybodyList;

const Container = styled(Row)`
  overflow-x: scroll;
  gap: 8px;
  padding: 0rem 1rem;
  padding-bottom: 8px;
`;

type AnyTicketItemProps = {
  data: ICalendarItemData;
  onDoubleClick?: () => void;
};
export const AnybodyTicketItem = ({ data, onDoubleClick = () => undefined }: AnyTicketItemProps) => {
  const extraData = data.data as IAppointmentItemData;
  const time = useMemo(() => {
    const startTime = momentTimezone(data.startTime);
    const endTime = momentTimezone(data.endTime);
    return `${startTime.format(FORMAT_TIME)} - ${endTime.format(FORMAT_TIME)}`;
  }, [data]);
  return (
    <AnyTicketItemStyled onDoubleClick={onDoubleClick}>
      <DraggableItem
        key={data?.id}
        id={data?.id?.toString()}
        data={{ data }}
      >
        <div style={{ textAlign: 'left' }}>
          <Text variant='CONTENT_2' color='text_3' style={{ whiteSpace: 'nowrap' }}>{extraData.customerName || 'Customer name'} - {formatPhone(extraData?.customerPhone || '')}</Text>
          <Text variant='CAPTION_3' color='text_3'>{time}</Text>
        </div>
        {extraData.note && <Row align={'middle'}>
          <Text variant='CAPTION_2' color='text_3' mr={0.25}>{'Note: '}</Text>
          <Text variant='CAPTION_3' color='text_3' style={{ textAlign: 'left' }}>{extraData?.note}</Text>
        </Row>}
      </DraggableItem>
    </AnyTicketItemStyled>
  );
};

const AnyTicketItemStyled = styled.div`
  border-radius: 5px;
  border: 1.5px solid var(--info-infor-3, #6FABB6);
  background: var(--blue-headline, #C6E9EF);
  padding: 0.5rem 1rem;
`;