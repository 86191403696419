import { IFormCheckSubmitValues } from 'features/cashier/components/TickerFooter/ButtonCheckOthers';
import { IGiftCardPaymentValue } from 'features/cashier/services/types/giftCard';
import { PAYMENT_TYPE } from 'features/payment/services/constants';
import { ICheckPayment, IDraftStateDetailPayment, ILoyaltyPayment } from 'features/payment/services/types/payment';
import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS } from './constants';
import { Message } from 'stompjs';
import { ICustomerReceiveConfigs } from './types/socketPayment';

const setVisibleModalPoint = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleModalPoint');
const setVisibleModalGiftCard = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleModalGiftCard');
const setVisibleCheckFormInputs = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleCheckFormInputs');

const getTransactionDetail = createAsyncAction<string>(PREFIX_ACTIONS + 'getTransactionDetail');
const getCustomerInfo = createAsyncAction<string>(PREFIX_ACTIONS + 'getCustomerInfo');
const syncDetailData = createAction(PREFIX_ACTIONS + 'syncDetailData');

const setCashPayment = createAction<number>(PREFIX_ACTIONS + 'setCashPayment');
const setCardPayment = createAction<number>(PREFIX_ACTIONS + 'setCardPayment');
const setGiftCardPayment = createAction<IGiftCardPaymentValue | null>(PREFIX_ACTIONS + 'setGiftCardPayment');
const setLoyaltyPointPayment = createAction<ILoyaltyPayment | null>(PREFIX_ACTIONS + 'setLoyaltyPointPayment');
const setCheckAOtherPayment = createAction<ICheckPayment | null>(PREFIX_ACTIONS + 'setCheckAOtherPayment');

const setActivePayment = createAction<PAYMENT_TYPE>(PREFIX_ACTIONS + 'setActivePayment');

const setDraftCheckSignature = createAsyncAction<string | null>(PREFIX_ACTIONS + 'setDraftCheckSignature');
const setDraftCheckInfo = createAction<IFormCheckSubmitValues | null>(PREFIX_ACTIONS + 'setDraftCheckInfo');
const setVisibleModalFormCheck = createAction<boolean>(PREFIX_ACTIONS + 'setVisibleModalFormCheck');
const setDraftMultiPayment = createAction<IDraftStateDetailPayment>(PREFIX_ACTIONS + 'setDraftMultiPayment');

const clearPaymentInfo = createAction(PREFIX_ACTIONS + 'clearPaymentInfo');
const setCashierMsg = createAsyncAction<Message | null>(PREFIX_ACTIONS + 'CASHIER' + 'setCashierMsg');
const customerSide = {
  setBillId: createAction<string | null>(PREFIX_ACTIONS + 'CUSTOMER' + 'setBillId'),
  setVisibleAddATip: createAction<boolean>(PREFIX_ACTIONS + 'CUSTOMER' + 'setVisibleAddATip'),
  setCustomerMsg: createAsyncAction<Message | null>(PREFIX_ACTIONS + 'CUSTOMER' + 'setCustomerMsg'),
  setReceiveConfigs: createAction<ICustomerReceiveConfigs | null>(PREFIX_ACTIONS + 'CUSTOMER' + 'setReceiveConfigs'),
  resetCacheCustomer: createAction(PREFIX_ACTIONS + 'CUSTOMER' + 'resetCacheCustomer'),
};

const multiplePaymentActions = {
  setVisibleModalPoint,
  setVisibleModalGiftCard,
  getTransactionDetail,
  getCustomerInfo,
  syncDetailData,
  setCashPayment,
  setCardPayment,
  setGiftCardPayment,
  setLoyaltyPointPayment,
  setActivePayment,
  setVisibleCheckFormInputs,
  setCheckAOtherPayment,
  setDraftCheckSignature,
  setVisibleModalFormCheck,
  customerSide,
  setDraftMultiPayment,
  setDraftCheckInfo,
  clearPaymentInfo,
  setCashierMsg,
};

export default multiplePaymentActions;

