import { Button } from 'antd';
import Icon from 'assets/Icons';
import ticketListActions from 'features/ticketList/services/actions';
import ticketListSelectors from 'features/ticketList/services/selectors';
import React, { useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import TicketTable from '../TicketTable';

type ITabTicketByParamsProps = {
  tableKey: string;
  isCreditPage?: boolean;
  v2?: boolean;
};
const TabTicketByParams: React.FC<ITabTicketByParamsProps> = ({ v2, tableKey, isCreditPage }) => {
  const dispatch = useAppDispatch();
  // const [isWindows, setIsWindows] = useState(false);
  // const handleChangeDisplayType = (isWindow: boolean) => {
  //   setIsWindows(isWindow);
  // };
  const adjustTipStore = ticketListSelectors.adjustTip();

  const disableAdjustTip = useMemo(() => {
    return !adjustTipStore.some(o => !!o.tip);
  }, [adjustTipStore]);

  const handleParentButtonClick = () => {
    // call action in store list
    dispatch(ticketListActions.adjustTip.fetch({}));
  };

  return (
    <TabTicketByParamsStyled>
      {!isCreditPage && <DisplayTypeBox>
        {tableKey === 'CLOSED' && <AdjustStyled>
          <AdjustButtonStyled
            icon={<Icon type="adjustTipAll" />}
            onClick={handleParentButtonClick}
            disabled={disableAdjustTip}
          >
            Adjust Tip All
          </AdjustButtonStyled>
        </AdjustStyled>}
      </DisplayTypeBox>}
      <TicketTable v2={v2} key={tableKey} isCreditPage={isCreditPage} />
    </TabTicketByParamsStyled>
  );
};

export default TabTicketByParams;
const TabTicketByParamsStyled = styled.div`
  position: absolute;
  inset: 0;
  overflow: auto;

.ant-table-wrapper {
  .ant-table {
    .ant-table-container {
      .ant-table-content {
        max-height: unset;
        overflow: unset;
      }
    }
  }
}

  .ant-table-pagination {
    position: sticky;
    bottom: 0;
  }
`;
const DisplayTypeBox = styled.div`
  display: flex;
  position: absolute;
  top: -4rem;
  right: 0;
`;
// const ChangeDisplayTypeIcon = styled.div`
//   cursor: pointer;
//   height: fit-content;
// `;
const AdjustStyled = styled.div`
  margin-top: -1rem;
  // margin-right: 1.5rem;
`;
const AdjustButtonStyled = styled(Button)`
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.175rem;
  padding: 0 1rem;
  background-color: #6fabb6;
  border-color: #6fabb6 !important;
  box-shadow: -1px 1px 4px 0px #0000001a inset, 0px 4px 4px 0px #00000040;
  font-size: 1rem;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: white !important;

  &:hover {
    opacity: 0.7;
    transition: opacity 0.3s; // smooth transition for the hover effect
  }
  &:disabled {
    background-color: #6fabb6 !important;
    border-color: #6fabb6!important;
    opacity: 0.7;
  }
`;
