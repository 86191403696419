import PaymentLoading, { IPaymentLoadingRef } from 'features/payment/components/PaymentLoading';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import multiplePaymentActions from '../services/actions';
import HeadPayment from './components/HeadPayment';
import PaymentInfoBox from './components/PaymentInfoBox';
import { PageStyled, PaymentFormStyled } from './styles';

const MultiPaymentPage = () => {
  const loadingRef = useRef<IPaymentLoadingRef>(null);
  const dispatch = useAppDispatch();
  const { id = '' } = useParams();

  useEffect(() => {
    dispatch(multiplePaymentActions.getTransactionDetail.fetch(id));
  }, []);

  return (
    <PageStyled>
      <HeadPayment />
      <PaymentFormStyled>
        <PaymentInfoBox billId={id} />
      </PaymentFormStyled>
      <PaymentLoading ref={loadingRef} />
    </PageStyled>
  );
};

export default MultiPaymentPage;
