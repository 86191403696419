import React, { FC } from 'react';
import Icon from 'assets/Icons';
import styled from 'styled-components';

interface CategoryItemProps {
  isActive?: boolean;
  selected?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  isProduct?: boolean;
}


const CategoryItemWrapper = styled.button`
  width:100%;
  position: relative;
  cursor: pointer;
`;

const IconBadge = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;
interface CategoryItemStyledProps {
  selected?: boolean;
  active?: boolean;
  isProduct?: boolean;
}
const CategoryItemStyled = styled.div.withConfig({
  shouldForwardProp: (propName) => !['selected', 'active'].includes(propName)
}) <CategoryItemStyledProps>`
  box-sizing: border-box;
  background: ${({ selected, active }) => ((active || selected) ? '#ffe6e7' : '#ffffff')};
  border: 1px solid ${({ selected, active }) => {
    if (!active && !selected) {
      return '#86909c';
    } else return '#f5767f';
  }};

  ${({ isProduct, selected, active }) => isProduct && `
    background:${((active) ? '#F8D9BB' : selected ? '#F8D9BB' : '#ffffff')};
    border: 1px solid ${(active || selected) ? '#CE6500' : '#86909c'}
  `}

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
    inset -1px 1px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  height: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;

  //styleName: Title/H9 - 18 semibold;
  font-family: Open Sans;
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.5rem;
  letter-spacing: 0em;
  text-align: center;

`;

const CategoryItem: FC<CategoryItemProps> = ({
  isActive = false,
  selected = false,
  children,
  onClick,
  isProduct = false,
}) => {
  return (
    <CategoryItemWrapper onClick={onClick}>
      {selected && (
        <IconBadge>
          <Icon type="tick" />
        </IconBadge>
      )}
      <CategoryItemStyled isProduct={isProduct} active={isActive} selected={selected}>{children}</CategoryItemStyled>
    </CategoryItemWrapper>
  );
};

export default CategoryItem;
