import { Col, InputRef, Row } from 'antd';
import AvatarBox from 'components/AvatarBox';
import Text from 'components/Text';
import cashierSelectors from 'features/cashier/services/selectors';
import { IStaff } from 'features/cashier/services/types/api';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import StaffSearchInput from './StaffSearchInput';

export type IAddStaffFormProps = {
  v2?: boolean;
  staff?: IStaff | undefined;
  onPickStaff: (option: IStaff) => void;
  inputRef: React.RefObject<InputRef>;
};
const AddStaffForm: React.FC<IAddStaffFormProps> = ({ v2, staff, inputRef, onPickStaff }) => {
  const staffList = cashierSelectors.getAvailableStaff();
  const [searchText] = useState('');
  const initOptions = useMemo(() => {
    return staffList.map(o => ({
      ...o,
      label: o.staffName,
      value: o.staffId,
      keyword: o.staffName.toLowerCase(),
    }));
  }, [staffList]);

  const options = useMemo(() => {
    if (!searchText.trim()) return initOptions;

    return initOptions.filter((item) =>
      item.keyword.includes(searchText.toLowerCase())
    );
  }, [initOptions, searchText]);

  const handleClickItem = (data: IStaff) => {
    onPickStaff(data);
  };

  return (
    <AddStaffFormStyled className={v2 ? 'v2' : ''}>
      {v2 ? <ContainerStaffV2>
        <div className="input-search-container">
          <StaffSearchInput v2 inputRef={inputRef} onPickStaff={onPickStaff} />
        </div>
        <Text mb={.5} />
        <div className="staff-list">
          {options.map((item) => {
            return (
              <div
                className='staff-box'
                key={item.staffId}
                onClick={() => handleClickItem(item)}
              >
                <AvatarBox
                  className='AvatarBox'
                  src={item.staffAvatar}
                  name={item.staffName}
                  selected={item.staffId === staff?.staffId}
                />
              </div>
            );
          })}
        </div>
      </ContainerStaffV2> : <>
        <StaffSearchInput inputRef={inputRef} onPickStaff={onPickStaff} />
        <Text mb={.5} />
        <ListContainer>
          <Row gutter={[16, 16]} justify="start">
            {options.map((item) => {
              return (
                <Col
                  key={item.staffId}
                  onClick={() => handleClickItem(item)}
                >
                  <AvatarBox
                    src={item.staffAvatar}
                    name={item.staffName}
                    selected={item.staffId === staff?.staffId}
                  />
                </Col>
              );
            })}
          </Row>
        </ListContainer>
      </>
      }
    </AddStaffFormStyled>
  );
};

export default AddStaffForm;
const ContainerStaffV2 = styled.div`
  background: #F5F5F5;
  flex: 1;
  .input-search-container {
    background: #FFF;
    padding: 16px;
  }
  .staff-list {
    display: grid;
    grid-template-columns: repeat(auto-fill,minmax(160px, 1fr));
    gap: 8px;
    padding: 0px 16px;
  }
  .staff-box {
    display: flex;
    padding: 8px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0 0;
    border-radius: 5px;
    background: #FFF;
    &:hover {
      background: #C6E9EF;
      .AvatarBox {
        background: #C6E9EF;
        border-color: #C6E9EF;
      }
    }
  }
  .CONTENT_2 {
    font-size: 24px;
  }

  .StaffSearchInput .ant-input-affix-wrapper {
    border-radius: 100px;
    padding: 0px;
    .ant-input-suffix {
      display: flex;
      width: 56px;
      height: 56px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 0px 100px 100px 0px;
      background: #2D6772;
    }
    input {
      border-radius: 100px; 
      padding: 24px;
      height: 56px;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: 30px; /* 166.667% */
      text-transform: capitalize;
      align-self: center;
    }
    border: 3px solid #2D6772;
    background: #FFF;
  }
`;
const AddStaffFormStyled = styled.div`
  min-height: 40rem;
  &.v2 {
    display: flex;
  }
`;
const ListContainer = styled.div`
  padding: 1.5rem 1rem;
`;
