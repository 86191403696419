import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';
import storage from 'utils/sessionStorage';

const ButtonQuickView = () => {
  const navigate = useNavigate();
  return (
    <Button block ntype={'SECONDARY'} onClick={() => navigate(`/store/${storage.shop_id.get()}/quick-view-tickets`)}>
      <span style={{ color: '#1D2129' }}>Quick View</span>
    </Button>
  );
};

export default ButtonQuickView;
