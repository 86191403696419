import checkInSelectors from 'features/checkIn/services/selectors';
import React from 'react';
import CategoryPart from './components/CatergoryPart';
import ServicePart from './components/ServicePart';
import ServiceSelectionPageStyled from './styles';
// check-in/services
interface IServiceSelectionPageProps { }
const ServiceSelectionPage: React.FC<IServiceSelectionPageProps> = () => {
  const isModelFullSimpleMenu = checkInSelectors.isModelFullSimpleMenu();
  return (
    <ServiceSelectionPageStyled>
      {!isModelFullSimpleMenu && <CategoryPart />}
      <ServicePart isModelFullSimpleMenu={isModelFullSimpleMenu} />
    </ServiceSelectionPageStyled>
  );
};

export default ServiceSelectionPage;
