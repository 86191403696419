import GroupButtons from 'components/GroupButtons';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import moment from 'moment';
import React, { useMemo } from 'react';
import styled from 'styled-components';
const DAILY = { label: 'Daily', value: CALENDAR_VIEW_TYPE.DAY_VIEW };
const WEEKLY = { label: 'Weekly', value: CALENDAR_VIEW_TYPE.WEEK_VIEW };
const TWO_WEEKS = { label: '2 Weeks', value: CALENDAR_VIEW_TYPE.TWO_WEEKS };
const MONTHLY = { label: 'Monthly', value: CALENDAR_VIEW_TYPE.MONTH_VIEW };

type ISwitchCalendarTypeProps = {
    twoWeeks?: boolean;
    onChangeValue?: (data: {
        startTime: string,
        endTime: string,
    }) => void;
    v2?: boolean;
    viewType?: CALENDAR_VIEW_TYPE;
    onChangeViewType?: (value: CALENDAR_VIEW_TYPE) => void;
};
const DateType: React.FC<ISwitchCalendarTypeProps> = ({
    twoWeeks = false,
    onChangeValue = () => undefined,
    onChangeViewType = () => undefined,
    v2 = false, viewType,
}) => {
    const _options = useMemo(() => {
        if (!twoWeeks) return [DAILY, WEEKLY, MONTHLY];
        return [DAILY, WEEKLY, TWO_WEEKS, MONTHLY];
    }, [twoWeeks]);

    const onChange = (val: string) => {
        const now = moment();
        switch (val) {
            case CALENDAR_VIEW_TYPE.DAY_VIEW: {
                onChangeValue({ startTime: now.format(DATE_FORMAT), endTime: now.format(DATE_FORMAT) });
                break;
            }
            case CALENDAR_VIEW_TYPE.WEEK_VIEW: {
                const startTime = now.startOf('week').format(DATE_FORMAT);
                const endTime = now.endOf('week').format(DATE_FORMAT);
                onChangeValue({ startTime, endTime });
                break;
            }
            case CALENDAR_VIEW_TYPE.TWO_WEEKS: {
                const endTime = now.format(DATE_FORMAT);
                const startTime = now.clone().subtract(15, 'day').format(DATE_FORMAT);
                onChangeValue({ startTime, endTime });
                break;
            }
            case CALENDAR_VIEW_TYPE.MONTH_VIEW: {
                const startTime = now.startOf('month').format(DATE_FORMAT);
                const endTime = now.endOf('month').format(DATE_FORMAT);
                onChangeValue({ startTime, endTime });
                break;
            }
            default:
                break;
        }

        onChangeViewType(val as CALENDAR_VIEW_TYPE);
    };

    return (
        <SwitchCalendarTypeStyled className={v2 ? 'v2' : ''}>
            <GroupButtons
                className='GroupButtons'
                options={_options}
                value={viewType || ''}
                onChange={onChange}
            />
        </SwitchCalendarTypeStyled>
    );
};

export default DateType;
type SwitchCalendarTypeStyledProps = {}
const SwitchCalendarTypeStyled = styled.div<SwitchCalendarTypeStyledProps>`
&.v2 {
    .GroupButtons {
        border-radius: 4px;
        background: #E5E6EB;
        border: 1px solid #E5E6EB;
        flex-wrap: nowrap;
        button {
            width: 6rem;
            .BODY_1 {
                color: #1D2129;
                text-align: center;
                font-family: Poppins;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                text-transform: capitalize;
            }

            &.active {
                border-radius: 4px;
                background: #232F3E;
                .BODY_1 {
                    color: #fff;
                }
            }
        }
    }
}
`;
