import { Layout } from 'antd';
import Text from 'components/Text';
import React from 'react';
import styled from 'styled-components';
import AppointmentFilter from '../Filter';
import AppointmentLayout from '../Layout';
import AppointmentDetail from './TableView/Body/Modal/AppointmentDetail';
import BlockHours from './TableView/Body/Modal/BlockHours';
import BreakTime from './TableView/Body/Modal/BreakTime';
import NewAppointmentV2 from './TableView/Body/Modal/NewAppointmentV2';
import QuickBooking, { quickBookingRef } from './TableView/Body/Modal/QuickBooking';
type IAppointmentCalendarProps = any;
const AppointmentCalendar: React.FC<IAppointmentCalendarProps> = () => {
  return (
    <AppointmentCalendarStyled>
      <AppointmentFilter />
      <Text mb={0.5} />
      <AppointmentLayout />
      <NewAppointmentV2 />
      <BlockHours />
      <BreakTime />
      <QuickBooking ref={quickBookingRef} />
      <AppointmentDetail />
    </AppointmentCalendarStyled>
  );
};

export default AppointmentCalendar;
type AppointmentCalendarStyledProps = {}
const AppointmentCalendarStyled = styled(Layout.Content) <AppointmentCalendarStyledProps>`
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
`;
