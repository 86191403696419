import Button from 'components/Button';
import { useRef, useState } from 'react';
import CurrencyKeyBoard, { CurrencyKeyBoardRef } from 'components/NumberKeyBoard/Currency';
import Modal from 'components/Modal';

type Props = {
  onSubmit?: (value: number) => void;
};

const ButtonApplySupplyPrice = ({ onSubmit = () => undefined }: Props) => {
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState<undefined | number | null>(null);
  const currencyRef = useRef<CurrencyKeyBoardRef>(null);

  const handleClose = () => setVisible(false);

  const _onSubmit = () => {
    onSubmit(value || 0);
    handleClose();
  };

  const onOpen = () => {
    setVisible(true);
    setValue(null);
    setTimeout(() => {
      currencyRef.current?.setValue('0.00');
    }, 100);
  };
  return (
    <>
      <Button className='custom-button' height={3} ntype='DEFAULT' onClick={onOpen}>Apply Supply Price</Button>
      <Modal
        modalTitle='Apply Supply Price' okTitle='Apply'
        visible={visible}
        onClose={handleClose} onSubmit={_onSubmit}
      >
        <CurrencyKeyBoard ref={currencyRef} value={value} onChange={setValue} />
      </Modal>
    </>
  );
};

export default ButtonApplySupplyPrice;
