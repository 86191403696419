
import { AxiosResponse } from 'axios';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading } from 'services/UI/sagas';
import { ISagaFunc } from 'services/actionConfigs';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import actions from './actions';
import { default as apis, default as appointmentApis } from './apis';
import { CALENDAR_VIEW_TYPE, PATH_LOADING, PROLONGED_TIME_STATUS } from './constants';
import { getCurrentState, getPureParams, getPureTableParams } from './selectors';
import { IApiGetAppointments, IApiGetTableAppointment, IBodyApiUpdateAppointmentWithDrop } from './types/api';
import { IAppointmentItemData, IStaffAppointmentItemData } from './types/appointment';
import { ILockBreakTimeItem } from './types/lockBreakTime';
import moment from 'moment';
import { DATE_FORMAT } from '../utils/format';
import { RootState } from 'store';
import { aptUIActions } from './reducers';

const getAppointments: ISagaFunc<IApiGetAppointments> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getAppointments, true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: IStaffAppointmentItemData[] }> = yield call(apis.getAppointments, payload);
    if (resData?.data?.data) {
      yield put(actions.getAppointments.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getAppointments.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getAppointments, false);
  }
};

const getLockBreakTimes: ISagaFunc<IApiGetAppointments> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getLockBreakTimes, true);
  yield delay(200);
  try {
    const resData: AxiosResponse<{ data: ILockBreakTimeItem[] }> = yield call(apis.getLockBreakTimes, payload);
    if (resData?.data?.data) {
      yield put(actions.getLockBreakTimes.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getLockBreakTimes.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getLockBreakTimes, false);
  }
};

const getLockBreakTimesForQuickBooking: ISagaFunc<IApiGetAppointments> = function* ({ payload }) {
  try {
    const resData: AxiosResponse<{ data: ILockBreakTimeItem[] }> = yield call(apis.getLockBreakTimes, payload);
    if (resData?.data?.data) {
      yield put(actions.getLockBreakTimesForQuickBooking.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getLockBreakTimesForQuickBooking.fail({}));
  } 
};

const removeBreakBlockHour: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getLockBreakTimes, true);
  try {
    const resData: AxiosResponse<{ data: ILockBreakTimeItem[] }> = yield call(apis.removeLockBreakTimes, payload);
    if (resData?.data?.data) {
      const params: IApiGetAppointments = yield select(getPureParams);
      const ls: AxiosResponse<{ data: ILockBreakTimeItem[] }> = yield call(apis.getLockBreakTimes, params);
      if (ls?.data?.data) {
        yield put(actions.getLockBreakTimes.success(ls.data.data));
      }
    }
  } catch (error) {
    yield put(actions.getLockBreakTimes.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getLockBreakTimes, false);
  }
};

const setParams: ISagaFunc<IApiGetAppointments> = function* ({ payload }) {
  const storeParams: IApiGetAppointments = yield select(getPureParams);
  const state: RootState['appointment'] = yield select(getCurrentState);

  const params: IApiGetAppointments = {
    ...storeParams ?? {},
    ...payload ?? {}
  };

  if (state.viewType === CALENDAR_VIEW_TYPE.DAY_VIEW) {
    yield put(actions.getListStaffAvailableBooking.fetch(params.startTime));
  }

  yield all([
    put(actions.getAppointments.fetch(params)),
    put(actions.getLockBreakTimes.fetch(params)),

  ]);
};

const initData = function* () {
  yield delay(100);
  const storeData: IApiGetAppointments = yield select(getPureParams);
  const today = moment().format(DATE_FORMAT);
  const params = {
    ...storeData,
    startTime: today,
    endTime: today,
  };
  yield all([
    put(actions.setParams(params)),
    put(actions.setTableParams({ ...params, page: 1 })),
    put(shopActions.initShopData()),
    put(aptUIActions.syncTimeItem('')),
  ]);
};

const addMoreTimeAppointment: ISagaFunc<{ status: PROLONGED_TIME_STATUS, appointmentId: string }> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getAppointments, true);
  yield delay(200);
  try {
    const res: IResponseDataBody<boolean> = yield call(appointmentApis.addMoreTimeAppointment, payload);
    if (res.data.data) {
      const storeParams = yield select(getPureParams);
      yield put(actions.getAppointments.fetch(storeParams));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getAppointments, false);
  }
};

const updateAppointmentWithDrop: ISagaFunc<IBodyApiUpdateAppointmentWithDrop> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getAppointments, true);
  yield delay(200);
  const params = { ...payload };
  if (params.distance) {
    delete params.distance;
  }
  try {
    yield call(appointmentApis.updateAppointmentWithDrop, params);
  } catch (error) { }
  finally {
    const storeParams = yield select(getPureParams);
    yield put(actions.getAppointments.fetch(storeParams));
    yield setLoading(PATH_LOADING.getAppointments, false);
  }
};

const deleteAppointmentSuccess = function* () {
  yield delay(100);
  const storeParams: IApiGetAppointments = yield select(getPureParams);
  yield put(actions.getAppointments.fetch(storeParams));
};

const addMoreBreakTime: ISagaFunc<string> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getAppointments, true);
  yield delay(200);
  try {
    const res: IResponseDataBody<boolean> = yield call(appointmentApis.addMoreTimeClockTime, payload);
    if (res.data.data) {
      const storeParams = yield select(getPureParams);
      yield put(actions.getLockBreakTimes.fetch(storeParams));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.getAppointments, false);
  }
};

const setTableParams: ISagaFunc<IApiGetTableAppointment | undefined> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.getTableAppointments, true);
  yield delay(500);
  const storeParams = yield select(getPureTableParams);

  const params = {
    ...storeParams ?? {},
    ...payload ?? {}
  };
  if (params.staffId === undefined) yield delete params.staffId;

  yield put(actions.getTableAppointments.fetch(params));
};

const getTableAppointments: ISagaFunc<IApiGetTableAppointment> = function* ({ payload }) {
  yield delay(200);
  try {
    const resData: IResponseDataBody<{ content: IAppointmentItemData[] }> = yield call(apis.getTableAppointments, payload);
    if (resData?.data?.data) {
      yield put(actions.getTableAppointments.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getTableAppointments.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getTableAppointments, false);
  }
};

const loadingView = function* () {
  yield setLoading(PATH_LOADING.getAppointments, true);
  yield delay(1000);
  yield setLoading(PATH_LOADING.getAppointments, false);
};

const getListStaffAvailableBooking: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res = yield call(apis.getListStaffAvailableBooking, payload);
    if (res.data.data) {
      yield put(actions.getListStaffAvailableBooking.success(res.data.data));
    }
  } catch (error) { }
};

const getListStaffsOff: ISagaFunc<IApiGetAppointments> = function* ({ payload }) {
  yield setLoading(PATH_LOADING.listStaffsOffLoading, true);
  try {
    const res = yield call(apis.getListStaffsOff, payload.startTime, payload.endTime);
    if (res.data.data) {
      yield put(actions.getListStaffsOff.success(res.data.data));
    }
  } catch (error) { }
  finally {
    yield setLoading(PATH_LOADING.listStaffsOffLoading, false);
  }
};

export default function* appointmentServiceSagas() {
  yield takeLatest(actions.getAppointments.fetch, getAppointments);
  yield takeLatest(actions.getLockBreakTimes.fetch, getLockBreakTimes);
  yield takeLatest(actions.getLockBreakTimesForQuickBooking.fetch, getLockBreakTimesForQuickBooking);
  yield takeLatest(actions.removeBreakBlockHour, removeBreakBlockHour);
  yield takeLatest(actions.setParams, setParams);
  yield takeLatest(actions.initData, initData);
  yield takeLatest(actions.addMoreTimeAppointment, addMoreTimeAppointment);
  yield takeLatest(actions.updateAppointmentWithDrop, updateAppointmentWithDrop);
  yield takeLatest(actions.addMoreBreakTime, addMoreBreakTime);
  yield takeLatest(actions.deleteAppointment.success, deleteAppointmentSuccess);
  yield takeLatest(actions.setTableParams, setTableParams);
  yield takeLatest(actions.getTableAppointments.fetch, getTableAppointments);
  yield takeLatest(actions.setStaffLimit, loadingView);
  yield takeLatest(actions.decreaseStaffPage, loadingView);
  yield takeLatest(actions.increaseStaffPage, loadingView);
  yield takeLatest(actions.getListStaffAvailableBooking.fetch, getListStaffAvailableBooking);
  yield takeLatest(actions.getListStaffsOff.fetch, getListStaffsOff);
}
