import { Avatar, Select, Space } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import { styled } from 'styled-components';
interface IStaffSelector {
  onChange?: (value: IStaffItemData | null, option?: any) => void;
  data: IStaffItemData[],
  selected?: IStaffItemData | undefined | null,
  size?: SizeType,
  disabledAll?: boolean;
}
const StaffSelector: React.FC<IStaffSelector> = ({ disabledAll = false, onChange = () => undefined, data, selected, size = 'small' }) => {
  const { Option } = Select;
  const _onChange = (value: any, option: any) => {
    const item = data.find(o => o.id === value);
    onChange(item || null, option);

  };
  return (
    <Select
      style={{ flex: 1, minWidth: 300 }}
      placeholder="All Staff"
      defaultValue={disabledAll ? selected?.id : 'ALL_ITEMS'}
      onChange={_onChange}
      optionLabelProp="label"
      size={size}
      allowClear={!disabledAll}
    >
      {!disabledAll && <Option value={'ALL_ITEMS'} label={'All Staff'}>
        <Space>
          <ImageContainer>
            <Avatar>{'All'}</Avatar>
          </ImageContainer>
          All Staff
        </Space>
      </Option>}
      {data.map(item => {
        return (
          <Option key={item.id} value={item.id} label={item.firstName + ' ' + item.lastName}>
            <Space>
              <ImageContainer>
                <Avatar
                  src={item.urlImage}
                >{item?.firstName?.charAt(0)}</Avatar>
              </ImageContainer>
              {item.firstName + ' ' + item.lastName}
            </Space>
          </Option>
        );
      })}
    </Select>
  );
};

export default StaffSelector;

const ImageContainer = styled.div`
  min-width: 20px;
  margin-right: 0.5rem;
`;