import React, { memo, useEffect, useState } from 'react';
import { Number } from './Number';
import { Word } from './Word';
import styled from 'styled-components';
import moment from 'moment';

const AlarmClockUI = ({ h24 = false }: { h24?: boolean }) => {

  const [hour, setHour] = useState(0);
  const [minute, setMinute] = useState(0);
  const [pm, setPm] = useState(false);

  useEffect(() => {

    const update = () => {
      const date = new Date();
      let hour = date.getHours();
      if (!h24) {
        hour = (hour % 12) || 12;
      }
      setHour(hour);
      setMinute(date.getMinutes());
      setPm(date.getHours() >= 12);
    };

    update();

    const interval = setInterval(() => {
      update();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <WrapperAlarm>
      <div className='clock'>
        <div className='calendar'>
          <Word value={moment().format('ddd, MMM D, YYYY')} />
        </div>
        <div className='row'>
          <div className='hour'>
            <Number value={hour} />
            <Word value={':'} />
            <Number value={minute} />
            <div className="ampm">{pm ? 'PM' : 'AM'}</div>
          </div>
        </div>
      </div>
    </WrapperAlarm>
  );
};

export default memo(AlarmClockUI);

const WrapperAlarm = styled.div`
.clock {
  margin: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: start;
  overflow: hidden;
  padding: 20px 20px 5px 20px;
}

.calendar {
  font-size: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-self: center;
  gap: 33px;
  padding: 0 10px;
  padding-top: 10px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  .digital {
    p {
      font-weight: 800;
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.hour {
  flex: 1;
  font-size: 10rem;
  line-height: 10rem;
  margin: 0;
  padding: 0;
  top: 0;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.ampm {
  margin-left: 1rem;
  align-self: flex-end;
  font-size: 5rem;
  display: inline-block;
  gap: 10px;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.digital {
  display: inline-block;
  position: relative;
  font-family: 'Digital-7';
}

.digital :first-child {
  width: 100%;
  position: absolute;
  color: #fff;
}

.digital :last-child {
  position: relative;
  color: #1D2129;
  font-weight: 500;
}

.digital p {
  margin: 0;
}
 `;