import ticketListSelectors from 'features/ticketList/services/selectors';
import React from 'react';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import discount_icon from './discount.png';
import supply_fee_icon from './supply-fee.svg';
import card_fee_icon from './card-fee.svg';
import total_sales_icon from './total-sales.svg';
import tip_icon from './tip.png';
import total_trans_icon from './total-trans.svg';
import voided_ticket_icon from './voided-ticket.svg';
import refunded_ticket_icon from './refunded-ticket.svg';
import taxes_icon from './taxes.png';
import total_icon from './total.svg';
import { get } from 'lodash';

const DashboardSalaryTypes = {
  TOTAL_TRANS: 'TOTAL_TRANS',
  TOTAL_SALES: 'TOTAL_SALES',
  SALES: 'SALES',
  SUPPLY_FEE: 'SUPPLY_FEE',
  TAXES: 'TAXES',
  DISCOUNT: 'DISCOUNT',
  TIP: 'TIP',
  CARD_FEES: 'CARD_FEES',
  FEES: 'FEES',
  TOTAL: 'TOTAL',
  VOIDED_TRANS: 'VOIDED_TRANS',
  REFUNDED_TRANS: 'REFUNDED_TRANS',
};
const configs = {
  [DashboardSalaryTypes.TOTAL_TRANS]: {
    label: 'Total Transactions',
    bg: '#808836',
    icon: total_trans_icon,
  },
  [DashboardSalaryTypes.TOTAL_SALES]: {
    label: 'Total Sales',
    bg: '#808836',
    icon: total_sales_icon,
  },
  [DashboardSalaryTypes.SALES]: {
    label: 'Sales',
    bg: '#808836',
    icon: total_sales_icon,
  },
  [DashboardSalaryTypes.SUPPLY_FEE]: {
    label: 'Supply Fees',
    bg: '#4F1787',
    icon: supply_fee_icon,
  },
  [DashboardSalaryTypes.TAXES]: {
    label: 'Taxes',
    bg: '#C84B31',
    icon: taxes_icon,
  },
  [DashboardSalaryTypes.DISCOUNT]: {
    label: 'Discounts',
    bg: '#346751',
    icon: discount_icon,
  },

  [DashboardSalaryTypes.TIP]: {
    label: 'Tips',
    bg: '#C52884',
    icon: tip_icon,
  },
  [DashboardSalaryTypes.CARD_FEES]: {
    label: 'Card Fees',
    bg: '#293A80',
    icon: card_fee_icon,
  },
  [DashboardSalaryTypes.FEES]: {
    label: 'Fees',
    bg: '#293A80',
    icon: card_fee_icon,
  },
  [DashboardSalaryTypes.TOTAL]: {
    label: 'Total',
    bg: '#32a7e5',
    icon: total_icon,
  },
  [DashboardSalaryTypes.VOIDED_TRANS]: {
    label: 'Voided Transactions',
    bg: '#32a7e5',
    icon: voided_ticket_icon,
  },
  [DashboardSalaryTypes.REFUNDED_TRANS]: {
    label: 'Refunded Transactions',
    bg: '#32a7e5',
    icon: refunded_ticket_icon,
  },
};

const CloseTicketSummary = () => {
  const summaryData = ticketListSelectors.summaryTickets();
  return (
    <Container>
      <DashboardItem type='TOTAL_SALES' value={formatCurrency(summaryData?.subTotal)}  />
      <DashboardItem type='SUPPLY_FEE' value={formatCurrency(summaryData?.supply)}  />
      <DashboardItem type='TAXES' value={formatCurrency(summaryData?.totalTax)}  />
      <DashboardItem type='DISCOUNT' value={formatCurrency(summaryData?.discount)}  />
      <DashboardItem type='TIP' value={formatCurrency(summaryData?.tip)}  />
      <DashboardItem type='CARD_FEES' value={formatCurrency(summaryData?.feeCreditCard)}  />
      <DashboardItem type='TOTAL' value={formatCurrency(summaryData?.total)}  />
    </Container>
  );
};

export default CloseTicketSummary;
const Container = styled.div`
display: flex;
padding: 24px 16px;
justify-content: center;
align-items: center;
gap: 16px;
align-self: stretch;
box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
`;

type DashboardItemProps = {
  type: keyof typeof DashboardSalaryTypes;
  value: string;
};
export const DashboardItem = ({ type, value }: DashboardItemProps) => {
  const config = get(configs, type);
  if (!config) return null;
  return <DashboardItemStyled>
    <div className="content">
      <p className="label">{config.label}</p>
      <p className="value">{value}</p>
    </div>

    <div className="icon-button">
      <img src={config.icon} alt={config.label} />
    </div>

  </DashboardItemStyled>;
};
const DashboardItemStyled = styled.div`
display: flex;
padding: 24px;
flex-direction: column;
align-items: flex-start;
gap: 8px;
flex: 1 0 0;
border-radius: 8px;
border: 1px solid #CCD4DC;
background: #FFF;
position: relative;

.content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    p.label {
        align-self: stretch;
        color: #505050;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px;
        text-transform: capitalize;
    }
    p.value {
        align-self: stretch;
        color: #1D2129;
        font-family: Poppins;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}

.icon-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: absolute;
  right: 16px;
  border-radius: 100px;
  border: 1px solid #FFF;
  img {
    height: 72px;
    width: 72px;
  }
}
`;
