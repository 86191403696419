import React from 'react';
import { IStaffTurnItem } from '../TurnTable/TurnStaffTopRow';
import styled from 'styled-components';
import clsx from 'clsx';
import Text from 'components/Text';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { Avatar } from 'antd';
const getIndex = (index: number) => {
  switch (index) {
    case 1: return '1st';
    case 2: return '2nd';
    case 3: return '3rd';
    default:
      return `${index}th`;
  }
};
type Props = {
  index: number;
  data: IStaffTurnItem;
  highlight?: boolean;
};
const Staff = ({ data, index, highlight }: Props) => {
  return (
    <Container className={clsx(highlight && 'highlight')}>
      <div className='box-text'>
        <Text variant='CONTENT_2' className='text-overflow'>{getIndex(index)} - {data.staffName}</Text>
      </div>
    </Container>
  );
};
type StaffManagerProps = {
  data: IStaffAvailableItem;
};
export const StaffManager = ({ data }: StaffManagerProps) => {
  return (
    <Container>
      <div className='box-text' style={{ gap: '8px' }}>
        <Avatar size={40} src={data.staffAvatar} alt={data.staffName}>{data.staffName?.[0]}</Avatar>
        <Text variant='CONTENT_2' className='text-overflow'>{data.staffName}</Text>
      </div>
    </Container>
  );
};

export default Staff;
const Container = styled.div`
align-self: stretch;
position: relative;
.box-text {
  border: 1px solid #86909C;
  background: #F0F0F0;
  height: 32px;
  min-width: 200px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  position: relative;
  padding: 8px 12px;
  flex:1;
  align-self: stretch;
}
.CONTENT_2 {
  position: relative;
  z-index: 3;
  color: #1D2129;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
&.highlight {
  &::before,&::after {
     content: "";
      position: absolute;
      border: 2px solid #F5B945;
      background: #FBD2778c;
      border-radius: 5px;
      z-index: 2;
      left: -5px;
      right: -5px;
      top: -5px;
      bottom: -5px;
      animation: zoom 1.4s linear infinite;
  }
  &::after {
    animation-delay: .4s;
  }
  .box-text {
    position: relative;
    z-index: 3;
    background: #FBD277;
    border: 1px solid #F5B945;
  }

}
`;
