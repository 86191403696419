import React from 'react';

const BackSpaceBtnV2 = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.6809 17.1667L65.0415 17.1667L65.0521 17.1668C66.8038 17.175 68.4816 17.9052 69.7203 19.1986C70.959 20.492 71.6584 22.2439 71.6662 24.0731L71.6662 24.0841V59.5821L71.6662 59.5931C71.6584 61.4222 70.959 63.1741 69.7203 64.4676C68.4816 65.761 66.8038 66.4912 65.0521 66.4994L65.0415 66.4994H28.6821C27.7385 66.499 26.806 66.2878 25.947 65.8799C25.0881 65.472 24.3228 64.8769 23.7023 64.1346C23.6096 64.0236 23.5271 63.9049 23.4555 63.78L10.3934 43.1955C9.86941 42.3697 9.86935 41.2967 10.3933 40.4709L23.5142 19.7882C23.5726 19.6962 23.6368 19.6084 23.7065 19.5253C24.3271 18.7852 25.092 18.192 25.9498 17.7853C26.8076 17.3785 27.7387 17.1677 28.6809 17.1667ZM28.6844 22.1C28.4149 22.1004 28.1486 22.1608 27.9032 22.2771C27.6962 22.3753 27.5082 22.5113 27.3478 22.6785L15.1963 41.8329L27.3562 60.9956C27.5145 61.1592 27.6995 61.2926 27.903 61.3893C28.1483 61.5057 28.4146 61.5661 28.684 61.5662H65.035C65.5396 61.5627 66.0227 61.3519 66.3795 60.9792C66.7364 60.6066 66.9383 60.1022 66.9417 59.5753V24.0909C66.9383 23.564 66.7364 23.0596 66.3795 22.687C66.0227 22.3144 65.5397 22.1035 65.0352 22.1H28.6844Z" fill="#1D2129" />
      <path fillRule="evenodd" clipRule="evenodd" d="M58.6196 30.2135C59.5709 31.1648 59.5709 32.7072 58.6196 33.6586L38.8253 53.4528C37.874 54.4042 36.3316 54.4042 35.3802 53.4528C34.4289 52.5015 34.4289 50.9591 35.3802 50.0078L55.1745 30.2135C56.1259 29.2622 57.6683 29.2622 58.6196 30.2135Z" fill="#1D2129" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.3802 30.2135C36.3316 29.2622 37.874 29.2622 38.8253 30.2135L58.6196 50.0078C59.5709 50.9591 59.5709 52.5015 58.6196 53.4528C57.6683 54.4042 56.1259 54.4042 55.1745 53.4528L35.3802 33.6586C34.4289 32.7072 34.4289 31.1648 35.3802 30.2135Z" fill="#1D2129" />
    </svg>
  );
};
export const BackSpaceBtnNormal = () => {
  return (
    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M28.681 17.1667L65.0416 17.1667L65.0522 17.1667C66.8039 17.1749 68.4817 17.9051 69.7204 19.1985C70.9591 20.492 71.6585 22.2439 71.6663 24.073L71.6663 24.084V59.582L71.6663 59.593C71.6585 61.4222 70.9591 63.1741 69.7204 64.4675C68.4817 65.7609 66.8039 66.4912 65.0522 66.4993L65.0416 66.4994H28.6822C27.7387 66.499 26.8061 66.2877 25.9472 65.8798C25.0883 65.472 24.3229 64.8769 23.7025 64.1345C23.6097 64.0235 23.5272 63.9048 23.4556 63.78L10.3935 43.1954C9.86953 42.3697 9.86947 41.2967 10.3934 40.4708L23.5144 19.7881C23.5727 19.6961 23.637 19.6083 23.7066 19.5253C24.3273 18.7852 25.0921 18.1919 25.9499 17.7852C26.8078 17.3785 27.7389 17.1676 28.681 17.1667ZM28.6845 22.0999C28.415 22.1003 28.1487 22.1607 27.9033 22.2771C27.6963 22.3752 27.5083 22.5112 27.3479 22.6784L15.1964 41.8328L27.3563 60.9955C27.5146 61.1592 27.6997 61.2926 27.9031 61.3892C28.1484 61.5057 28.4147 61.566 28.6841 61.5661H65.0351C65.5397 61.5626 66.0228 61.3518 66.3797 60.9791C66.7365 60.6065 66.9385 60.1021 66.9418 59.5752V24.0908C66.9385 23.5639 66.7365 23.0595 66.3797 22.6869C66.0228 22.3143 65.5398 22.1035 65.0353 22.0999H28.6845Z" fill="#B67650" />
      <path fillRule="evenodd" clipRule="evenodd" d="M58.6196 30.2133C59.5709 31.1647 59.5709 32.7071 58.6196 33.6584L38.8253 53.4527C37.874 54.404 36.3316 54.404 35.3802 53.4527C34.4289 52.5014 34.4289 50.959 35.3802 50.0076L55.1745 30.2133C56.1259 29.262 57.6683 29.262 58.6196 30.2133Z" fill="#B67650" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.3802 30.2133C36.3316 29.262 37.874 29.262 38.8253 30.2133L58.6196 50.0076C59.5709 50.959 59.5709 52.5014 58.6196 53.4527C57.6683 54.404 56.1259 54.404 55.1745 53.4527L35.3802 33.6584C34.4289 32.7071 34.4289 31.1647 35.3802 30.2133Z" fill="#B67650" />
    </svg>
  );
};

export default BackSpaceBtnV2;
