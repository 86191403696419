import fetch from 'services/request';
import { IGetGiftCardDetail, IGetListGiftCard } from './types/api';

const baseURL = 'api/v1/gift-card';

export const getListGiftCard = (params: IGetListGiftCard) => {
  return fetch({
    method: 'get',
    url: `${baseURL}/list`,
    params
  });
};
export const getGiftCardDetail = (params: IGetGiftCardDetail) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/detail/${params.id}`,
  });
};

export const resetGiftCardDetail = (body: string[]) => {
  return fetch({
    method: 'post',
    url: `${baseURL}/reset`,
    body
  });
};

export const getSummaryGiftCards = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/gift-card/summary',
  });
};

/**
 * TODO:
 * please replace giftCards to your feature name
 * ex:
 * giftCardsApis => bookingApis
 */
const giftCardsApis = {
  getListGiftCard,
  getGiftCardDetail,
  resetGiftCardDetail,
  getSummaryGiftCards,
};

export default giftCardsApis;
