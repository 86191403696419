import styled from 'styled-components';

export const HeaderModal = styled.div`
 border-radius: 5px 5px 0px 0px;
  background: #232F3E;
  padding: 12px 0;
  height: auto;
  .modal-title {
    color: #FFF;
    background: #232F3E;
    text-align: center;
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
`;

export const GiftCardNumberContainer = styled.div`
margin-bottom: 1rem;
margin-top: 1rem;
display: flex;
flex-direction: column;  
.modal-label {
  color: #64748B;
  text-align: center;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.modal-warning {
  color: #ff7d00;
  text-align: center;
  font-family: Poppins;
  align-self: stretch;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.input-selector-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 64px;
  margin: 0 40px;
  
  .qr-scan-icon {
    width: 64px;
    height: 64px;
  }

  .btn-scan {
    display: flex;
    width: 80px;
    height: 80px;
    padding: 8px;
    justify-content: center;
    align-items: center;
    border-radius: 0px 4px 4px 0px;
    background: #FFF1F6;
    position: absolute;
    right: 0;
  }

  &.dollar-input {
    justify-content: center;
  }
}
.gift-card-number-input {
  color: #1D2129;
  text-align: center;
  font-family: Poppins;
  align-self: stretch;
  font-size: 30px;
  height: unset;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  outline: none;
  box-shadow: none;
  &.fs-big {
    font-size: 55px;
  }
  &.fs-medium {
    font-size: 45px;
  }
}
`;

export const FooterActions = styled.div`
  display: flex;
  padding: 16px !important;
  align-items: center;
  gap: 16px;
  button.ant-btn {
    color: #1D2129;
    border: 1px solid #86909C;
    display: flex;
    height: 70px;
    padding: 16px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
    flex: 1 0;
    border-radius: 4px;
    background: #E5E6EB;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -3px 0px 0px #D6D6E7 inset;
    span {
      color: #1D2129;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    &.ant-btn-primary {
      background-color: #ff8890;
      border: 1px solid transparent;
      color: #1d2129;
      box-shadow: 0px 2px 4px 0px rgba(44, 35, 66, 0.40), 0px 4px 8px -3px rgba(45, 35, 66, 0.30), 0px -4px 0px 0px #E7646D inset;
    }

    &:disabled {
      opacity: 0.7;
    }
  }
`;

export const ContainerKeyboard = styled.div`
  width: 1100px;

`;

export const GiftCardDetailContainer = styled.div`
  display: flex;
  width: 567px;
  padding: 0px 16px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  position: relative;
  background: #FFF;
  overflow: hidden;
  .gift-card-bg-icon {
    display: flex;
    width: 450px;
    height: 450px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 0px;
    bottom: -60px;
  }
  .detail-item {
    position: relative;
    display: flex;
    padding: 16px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    border-bottom: 1.5px solid #86909C;
    flex: 1;
    .detail-item-label {
      color: #505050;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .detail-item-amount {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      .detail-item-amount-text {
        flex: 1 0 0;
        color: #2D6772;
        text-align: center;
        font-family: Poppins;
        font-size: 26px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-transform: uppercase;
      }

      .gift-amount-box {
        display: flex;
        height: 56px;
        padding: 0px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        background: #232F3E;
        .detail-item-amount-text {
          color: #FFF;
          text-align: center;
          font-family: Poppins;
          font-size: 26px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }

  &.issues-tab {
  justify-content: center;
  gap: 32px;
    .detail-item {
      flex: unset;
    }
  }
`;

export const GiftCardTabs = styled.div`
display: flex;
height: 72px;
align-items: center;
align-self: stretch;
border-bottom: 1px solid #86909C;
background: #E5E6EB;
`;