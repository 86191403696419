import { Form, FormInstance, InputRef } from 'antd';
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import styled from 'styled-components';
import InputEmail from './InputEmail';
import Modal from './Modal';
import { isMobileBrowser } from 'utils/detachBehavior';

const isMobile = isMobileBrowser();
interface IModalRef {
  open: (defaultValue?: string) => void;
  close: () => void;
}
export const useModalEmailRef = () => useRef<IModalRef>(null);
type FormValue = { email: string };
export type IModalEmailFuncFinish = (values: FormValue) => void;
type Props = {
  form: FormInstance<FormValue>;
  onFinish: IModalEmailFuncFinish;
};
const ModalEmail = forwardRef<IModalRef, Props>(({ form, onFinish }, ref) => {
  const inputRef = useRef<InputRef>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  useImperativeHandle(ref, () => ({
    open: (defaultValue?: string) => {
      setIsOpenModal(true);
      form.setFieldValue('email', defaultValue || '');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    },
    close: () => setIsOpenModal(false),
  }));

  if (!isOpenModal) return null;

  return (
    <ModalEmailStyled
      visible
      modalTitle='Your Email'
      closable={false}
      onClose={() => setIsOpenModal(false)}
      noneBodyStyle
      okTitle='Send'
      centered={!isMobile}
      containerPadding={0}
      className='modal-signature'
      footerPadding={1.5}
      onSubmit={() => form.submit()}
    >
      <FormStyled>
        <Form form={form} onFinish={onFinish} validateTrigger={['onSubmit']}>
          <InputEmail
            name={'email'}
            inputRef={inputRef}
            rules={[{ required: true, type: 'email', message: 'Please enter your email!' }]}
          />
        </Form>
      </FormStyled>
    </ModalEmailStyled>
  );
});
ModalEmail.displayName = 'ModalEmail';
export default ModalEmail;

const ModalEmailStyled = styled(Modal)``;

const FormStyled = styled.div`
padding: 1rem 1.5rem;
  .ant-input {
    height: 5rem;
    font-weight: 600;
    font-size: 1.8rem;
    text-align: center;
  }
`;