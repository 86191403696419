import fetch from 'services/request';
import { IApiAddManualTurnBody, IApiGetTurnDetailParam, IApiGetTurnParam, IApiUpdateManualTurnBody, IApiUpdateTurnDetailParam, ISyncWaitngToTicket } from './types/api';
import { ITurnServiceItem } from './types/service';
import { ITurnManualSettingColorItem } from './types/turn';

export const getTurn = (params: IApiGetTurnParam) => {
  return fetch({
    method: 'get',
    url: '/api/v1/turn',
    params,
  });
};

export const getTurnDetail = (params: IApiGetTurnDetailParam) => {
  return fetch({
    method: 'get',
    url: `/api/v1/turn/${params.id}`,
  });
};

export const getTurnStaffsAvailable = (params: IApiGetTurnParam) => {
  return fetch({
    method: 'get',
    url: 'api/v1/staff/list-available-working',
    params
  });
};

export const updateTurnDetail = (body: IApiUpdateTurnDetailParam) => {
  return fetch({
    method: 'put',
    url: '/api/v1/turn',
    body,
    pinCode: body.pinCode
  });
};
export const syncWaitngToTicket = (body: ISyncWaitngToTicket) => {
  return fetch({
    method: 'post',
    url: '/api/v1/bill/sync-waiting-to-ticket',
    body
  });
};

export const clearAllTurn = () => {
  return fetch({
    method: 'put',
    url: '/api/v1/turn/clear-all',
  });
};

const getTurnConfigServices = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/turn/config-service',
  });
};

const getTurnSettingColor = () => {
  return fetch({
    method: 'get',
    url: '/api/v1/turn/setting-color',
  });
};

const addManualTurn = (body: IApiAddManualTurnBody) => {
  return fetch({
    method: 'post',
    url: '/api/v1/turn/add-manual-turn',
    body,
  });
};

const updateManualTurn = (body: IApiUpdateManualTurnBody) => {
  return fetch({
    method: 'put',
    url: '/api/v1/turn',
    body,
  });
};

const deleteManualTurn = (turnId: string) => {
  return fetch({
    method: 'delete',
    url: `/api/v1/turn/${turnId}`,
  });
};

const clearAllManualTurn = () => {
  return fetch({
    method: 'put',
    url: '/api/v1/turn/clear-all',
  });
};

const updateManualTurnServiceItem = (body: ITurnServiceItem[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/turn/update-item-service',
    body,
  });
};

const sortManualServicesTurn = (body: string[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/turn/sort-item-service',
    body,
  });
};

const deleteManualServicesTurn = (id: string) => {
  return fetch({
    method: 'delete',
    url: `/api/v1/turn/delete-item-service/${id}`,
  });
};
const logoutStaff = (staffId: string) => {
  return fetch({
    method: 'post',
    url: `api/v1/staff/clock-out/${staffId}`,
  });
};

const updateTurnManualColorSetting = (body: ITurnManualSettingColorItem[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/turn/update-color',
    body,
  });
};

const addManagementTurn = (body: string[]) => {
  return fetch({
    method: 'post',
    url: '/api/v1/staff/add-management-turn',
    body,
  });
};

const turnApis = {
  getTurn,
  getTurnDetail,
  getTurnStaffsAvailable,
  updateTurnDetail,
  syncWaitngToTicket,
  clearAllTurn,
  getTurnConfigServices,
  addManualTurn,
  updateManualTurn,
  deleteManualTurn,
  clearAllManualTurn,
  updateManualTurnServiceItem,
  sortManualServicesTurn,
  deleteManualServicesTurn,
  logoutStaff,
  getTurnSettingColor,
  updateTurnManualColorSetting,
  addManagementTurn,
};

export default turnApis;
