import { Button } from 'antd';
import Icon from 'assets/Icons';
import appointmentActions from 'features/appointment/services/actions';
import { CALENDAR_VIEW_TYPE } from 'features/appointment/services/constants';
import appointmentSelectors from 'features/appointment/services/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import useAnybodyVisible from '../../ModalAnybody/useAnybodyVisible';
import back_icon from '../v2/icons/back.svg';
import next_icon from '../v2/icons/next.svg';

type Props = {
  modal?: boolean;
  v2?: boolean;
};
const Arrows = ({ modal, v2 }: Props) => {
  const dispatch = useAppDispatch();
  const viewType = appointmentSelectors.getViewType();
  const staffListPage = appointmentSelectors.getStaffPage();
  const staffTotalPage = appointmentSelectors.getStaffTotalPage();
  const anybodyBox = useAnybodyVisible();
  const handleClickPrev = () => {
    const nextPage = staffListPage - 1;
    if (nextPage > 0) {
      dispatch(appointmentActions.decreaseStaffPage());
    }
  };

  const handleClickNext = () => {
    const nextPage = staffListPage + 1;
    if (nextPage > staffTotalPage) return;
    dispatch(appointmentActions.increaseStaffPage());
  };

  if (
    viewType === CALENDAR_VIEW_TYPE.MONTH_VIEW ||
    viewType === CALENDAR_VIEW_TYPE.WEEK_VIEW
  ) return null;


  if (v2) {
    return (
      <IconButtonContainer>
        <IconWrapper style={{ gap: 16 }}>
          <button type='button' className='arrow-btn' onClick={handleClickPrev}>
            <img src={back_icon} alt='back_icon' style={{ height: 48, width: 48 }} />
          </button>
          <button type='button' className='arrow-btn' onClick={handleClickNext}>
            <img src={next_icon} alt='next_icon' style={{ height: 48, width: 48 }} />
          </button>
        </IconWrapper>
      </IconButtonContainer>
    );
  }

  return (
    <IconButtonContainer>
      <IconWrapper>
        <IconButton className='arrow-btn' onClick={handleClickPrev}>
          <Icon type="back" width="32" height="32"></Icon>
        </IconButton>
        <IconButton className='arrow-btn' onClick={handleClickNext}>
          <Icon type="next" width="32" height="32"></Icon>
        </IconButton>
        {modal && <IconButton className='arrow-btn' onClick={() => anybodyBox.close()}>
          <Icon type="close" width="32" height="32"></Icon>
        </IconButton>}
      </IconWrapper>
    </IconButtonContainer>
  );
};

export default Arrows;


const IconButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; // Default for small screens
  @media (min-width: 1200px) {
    // This is typically the breakpoint for large screens, but you can adjust.
    justify-content: flex-end;
  }
`;
const IconWrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
const IconButton = styled(Button)`
  background-color: white;
  border-radius: 50%;
  border: 1px solid black;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
`;
