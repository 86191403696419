import CategoryItem from 'components/Category';
import Spin from 'components/Spin';
import Text from 'components/Text';
import cashierActions from 'features/cashier/services/actions';
import cashierSelectors from 'features/cashier/services/selectors';
import { useCallback } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IProductCategoryItem } from 'services/shop/types/product';
import { useAppDispatch } from 'store/hooks';
import { CategoriesListStyled, RegularContainer, RegularContainerItem, ScrollDivStyled } from './Category';
import clsx from 'clsx';
const useProductCategory = () => {
  const dispatch = useAppDispatch();
  const loading = shopSelectors.loading.productCategories();
  const categories = cashierSelectors.getProductCategories();
  const categoryActive = cashierSelectors.getActiveProductCategory();
  const serviceIdsSelected = cashierSelectors.getServiceIdsSelected();

  const handleClickCategory = (item: IProductCategoryItem) => {
    dispatch(cashierActions.selectProductCategory(item));
  };
  return ({
    loading, categories, categoryActive, serviceIdsSelected, handleClickCategory,
  });
};
const ProductCategory = () => {
  const { loading, categories, categoryActive, serviceIdsSelected, handleClickCategory } = useProductCategory();
  const renderItem = useCallback((item: IProductCategoryItem) => {
    return (
      <CategoryItem
        onClick={() => handleClickCategory(item)}
        selected={item.products.some(o => !!serviceIdsSelected.find(s => s.id === o.id))}
        isActive={categoryActive?.id === item.id}
        isProduct
      >
        <Text variant='CONTENT_2' color='text_3' textTransform='uppercase'>{item.categoryName}</Text>
      </CategoryItem>
    );
  }, [categories, categoryActive, serviceIdsSelected]);

  return (
    <ScrollDivStyled style={{ flexDirection: 'column' }}>
      <Text variant="H6" mt={1} color="text_2" px={1.5} pb={0.5}>
        CATEGORIES
      </Text>
      <div className="scroll-div">
        <div className="children-scroll">
          <CategoriesListStyled>{categories.map(renderItem)}</CategoriesListStyled>
        </div>
      </div>
      {loading && <Spin />}
    </ScrollDivStyled>
  );
};
export const ProductCategoryV2 = () => {
  const { categories, categoryActive, handleClickCategory } = useProductCategory();
  return (
    <RegularContainer>
      {categories.map(o => (
        <RegularContainerItem key={o.id} onClick={() => handleClickCategory(o)} className={clsx(categoryActive?.id === o.id && 'active')}>
          <span>{o.categoryName}</span>
        </RegularContainerItem>
      ))}
    </RegularContainer>
  );
};
export default ProductCategory;
