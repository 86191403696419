import { Input, InputRef, Spin } from 'antd';
import Icon from 'assets/Icons';
import { FormItem } from 'components/Form';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import cashierApis from 'features/cashier/services/apis';
import { IGiftCardDetail } from 'features/cashier/services/types/giftCard';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { styled } from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';

const CheckGiftCard = () => {
  const [giftCardData, setGifCardData] = useState<IGiftCardDetail | null>(null);
  const [code, setCode] = useState<string>('');
  const [msg, setMsg] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const inputRef = useRef<InputRef>(null);

  const onScan: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    setCode('');
    e.preventDefault();
    inputRef.current?.focus();
  };

  const values = useMemo(() => {
    const { amount = 0, usedAmount = 0, updatedDate, expireDate } = giftCardData || {};
    const remaining = amount - usedAmount;
    return [
      { label: 'Amount:', value: formatCurrency(amount || 0) },
      { label: 'Used:', value: formatCurrency(usedAmount || 0) },
      { label: 'Remain:', value: formatCurrency(remaining || 0) },
      { label: 'Last Used Date:', value: updatedDate ? moment(updatedDate).format('MM/DD/YYYY - ' + FORMAT_TIME) : null },
      { label: 'Expiration:', value: expireDate ? moment(expireDate).format('MM/DD/YYYY') : '--' },
    ];
  }, [giftCardData]);

  const fetchData = async (code: string) => {
    if (code?.length < 4) return;
    setLoading(true);
    try {
      const res: IResponseDataBody<IGiftCardDetail> = await cashierApis.getGiftCardInfo(code);
      if (res.data.data) {
        setGifCardData(res.data.data);
      } else {
        setMsg('Gift number not exist, please try again');
      }
    } catch (error) {
      setMsg('Gift number not exist, please try again');
    }
    finally {
      setLoading(false);
    }
  };

  const handleKeyDown = async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      await fetchData(code);
      setTimeout(() => inputRef.current?.blur(), 100);
      return;
    }
  };

  const _debounce = useCallback(debounce(fetchData, 1000), []);

  useEffect(() => {
    setMsg('');
    setGifCardData(null);
    _debounce(code);
  }, [code]);

  const Content = () => {
    return (
      <Spin spinning={loading}>
        <Contents>
          {values.map(({ label, value }, index) => (
            <div className="content-row" key={index}>
              <div className="label">{label}</div>
              <div className="value">{value || '-'}</div>
            </div>
          ))}
        </Contents>
      </Spin>
    );
  };

  return (
    <Wrapper>
      <FormItem label={'Gift Number'}
        validateStatus={msg ? 'warning' : undefined}
        help={msg}
      >
        <Input
          ref={inputRef}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          addonAfter={
            <button type='button' onClick={onScan}><Icon type={'QR'} /></button>
          }
          value={code}
          onChange={e => setCode(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </FormItem>
      <Content />
    </Wrapper>
  );
};

export default CheckGiftCard;
const Wrapper = styled.div`
.ant-input {
  height: 3.5rem;
}
`;
const Contents = styled.div`
  display: flex;
  flex-direction:column;
  gap: 1rem;
  
  .content-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .label {
      color: var(--text-text-3, #1D2129);
      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .value {
      color: var(--text-text-3, #1D2129);
      font-size: 1.125rem;
      font-style: normal;
      font-weight: 600;
      line-height: 166.667%;
      text-transform: capitalize;
    }
  }
`;