import { Avatar, Popover, Row } from 'antd';
import Icon from 'assets/Icons';
import Text from 'components/Text';
import { ICalendarHeader, ICalendarItemData } from 'features/appointment/services/types/calendar';
import { FORMAT_TIME } from 'features/appointment/utils/format';
import { first, get, maxBy, minBy, sortBy } from 'lodash';
import React, { CSSProperties, useMemo, useState } from 'react';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
import Appointment from './Appointment';
import useAnybodyVisible from '../../../ModalAnybody/useAnybodyVisible';
import { IAppointmentItemData } from 'features/appointment/services/types/appointment';
import { useAppDispatch } from 'store/hooks';
import { aptUIActions } from 'features/appointment/services/reducers';

const overlayInnerStyle: CSSProperties = { borderRadius: 0, boxShadow: 'none', padding: 0, background: 'transparent' };
type IMultiAppointmentProps = {
  data: ICalendarItemData[];
  headerData?: ICalendarHeader;
  isMonthView?: boolean;
  v2?: boolean;
  dateOfMonth?: string;
};
const MultiAppointment: React.FC<IMultiAppointmentProps> = ({
  data = [],
  headerData,
  isMonthView,
  dateOfMonth,
  v2,
}) => {
  const dispatch = useAppDispatch();
  const anybodyBox = useAnybodyVisible();
  const [open, setOpen] = useState(false);
  const startTime = useMemo(() => {
    const item = minBy(data, o => momentTimezone(o.startTime).valueOf());
    if (!item?.startTime) return '';
    return momentTimezone(item?.startTime).format(FORMAT_TIME);
  }, [data]);

  const endTime = useMemo(() => {
    const item = maxBy(data, o => momentTimezone(o.endTime).valueOf());
    if (!item?.endTime) return '';
    return momentTimezone(item?.endTime).format(FORMAT_TIME);
  }, [data]);

  const sortData = useMemo(() => sortBy(data, o => momentTimezone(o.startTime).valueOf()), [data]);

  const content = useMemo(() => {
    const time = get(first(data), 'data.startTime');

    return (
      <PopoverAppointmentStyled>
        <button className="head" onClick={() => setOpen(false)}>
          <Icon type='close' />
        </button>
        <div className="body overflow-div">
          <Text variant='H5' color='text_3' >Multi - Booking</Text>
          <div className="divider" />
          <div className='row-item'><Icon type='time' /> <Text variant='H9' color='text_3'>{time ? momentTimezone(time).format('MM-DD-YYYY HH:mm') : null}</Text></div>
          {headerData && headerData.type === 'staff' && <div className='row-item'>
            <Avatar size={'small'} style={{ backgroundColor: '#f56a00' }}>{headerData.label?.[0]}</Avatar> <Text variant='H9' color='text_3'>{headerData.label}</Text>
          </div>}
          <div className="list-item">
            {
              sortData.map(o => (
                <Appointment v2={v2} isMulti key={o.id} data={o} isMonthView={isMonthView} />
              ))
            }
          </div>
        </div>
      </PopoverAppointmentStyled>
    );
  }, [data, isMonthView]);

  const ticketList = useMemo(() => {
    return (
      <div className='container-tickets'>
        <Text className='text-overflow tickets-wrap' mt={0.5}>
          {sortData.map(o => (
            <Row key={o.id} align={'middle'} justify={'space-between'} className='text-overflow ticket-item'>
              <Text variant='CAPTION_3' style={{ fontWeight: '600', flex: 1, paddingRight: '10%' }} color='text_3' className='text-overflow'>{(o.data as IAppointmentItemData)?.customerName || 'Unknown'}</Text>
              <Row>
                <Text variant='CAPTION_3' style={{ fontWeight: '600', textAlign: 'left', flex: 1, }} color='text_3'>{momentTimezone(o.startTime).format('hh:mm A')}</Text>
                <Text variant='CAPTION_3' style={{ fontWeight: '600', width: '16px', textAlign: 'center' }} color='text_3'>-</Text>
                <Text variant='CAPTION_3' style={{ fontWeight: '600', textAlign: 'right', flex: 1, }} color='text_3'>{momentTimezone(o.endTime).format('hh:mm A')}</Text>
              </Row>
            </Row>
          ))}
        </Text>
        <Text className='overflow-div-bottom' variant='CONTENT_2' color='text_3' textAlign='center' style={{ fontSize: '1rem' }}>...</Text>
      </div>
    );
  }, [sortData]);

  if (headerData?.anybody) {
    return (
      <MultiAppointmentStyled
        onClick={((e) => {
          e.stopPropagation();
          anybodyBox.setData(sortData);
          anybodyBox.open();

        }) as React.MouseEventHandler<HTMLDivElement>}
      >
        <div className="top"><Icon type='exclamationCircle' /></div>
        <div className='appointment-box'>
          {dateOfMonth && <Text variant='BODY_1' color='text_2' mb={1} p={0.25} textAlign='left'>{dateOfMonth}</Text>}
          <Text mt={0.25} variant='CONTENT_2' color='text_3'>Multiple Bookings ({data.length})</Text>
          <Text variant='CAPTION_3' color='text_3'>{startTime} - {endTime}</Text>
          {ticketList}
        </div>
      </MultiAppointmentStyled>
    );
  }
  if (v2) {
    return (
      <MultiAppointmentStyled
        onClick={((e) => {
          e.stopPropagation();
          dispatch(aptUIActions.setDraftMultiAppointmentsDetail({ data, headerData }));
        }) as React.MouseEventHandler<HTMLDivElement>}
      >
        <div className="top"><Icon type='exclamationCircle' /></div>
        <div className='appointment-box'>
          {dateOfMonth && <Text variant='BODY_1' color='text_2' mb={1} p={0.25} textAlign='left'>{dateOfMonth}</Text>}
          <Text mt={0.25} variant='CONTENT_2' color='text_3'>Multiple Bookings ({data.length})</Text>
          <Text variant='CAPTION_3' color='text_3'>{startTime} - {endTime}</Text>
          {ticketList}
        </div>
      </MultiAppointmentStyled>
    );
  }

  return (
    <Popover
      arrow={false}
      placement="right"
      open={open}
      onOpenChange={setOpen}
      content={content}
      trigger="click"
      overlayInnerStyle={overlayInnerStyle}
    >
      <MultiAppointmentStyled
        onClick={((e) => {
          e.stopPropagation(); setOpen(true);
        }) as React.MouseEventHandler<HTMLDivElement>}
      >
        <div className="top"><Icon type='exclamationCircle' /></div>
        <div className='appointment-box'>
          {dateOfMonth && <Text variant='BODY_1' color='text_2' mb={1} p={0.25} textAlign='left'>{dateOfMonth}</Text>}
          <Text mt={0.25} variant='CONTENT_2' color='text_3'>Multiple Bookings ({data.length})</Text>
          <Text variant='CAPTION_3' color='text_3'>{startTime} - {endTime}</Text>
          {ticketList}
        </div>
      </MultiAppointmentStyled>
    </Popover>

  );
};

export default MultiAppointment;
const PopoverAppointmentStyled = styled.div`
    width: 50vh;
    max-width: 32rem;
    display: flex;
    flex-direction: column;
    padding:1.5rem;

    border-radius: 5px;
    border: 1px solid var(--line-line-3, #86909C);
    background: var(--fill-fill-0, #FFF);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .divider {
      background: #86909C;
      width: 100%;
      height: 1px;
    }

    .head {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 1.5rem;
    }

    .body {
      gap: 16px;
      display: flex;
      flex-direction: column;
    }
    .overflow-div {
      max-height: 60vh;
      overflow-y: auto;
    }
    .row-item {
      gap: 11px;
      display: flex;
      align-items: center;
    }
    .list-item {
      gap: 16px;
      display: flex;
      flex-direction: column;
    }
`;

type MultiAppointmentStyledProps = {}
const MultiAppointmentStyled = styled.div<MultiAppointmentStyledProps>`
  height: 100%;
  width: 100%;
  display: flex;

  .appointment-box {
    border-radius: 5px;
    border: 1.5px solid var(--primary-primary-hover, #F5767F);
    background: var(--primary-button, #FFE5E7);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.10) inset;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    display: flex;
    flex-direction: column;
    padding: 4px 8px;
    flex: 1;
    &:hover {
      cursor: pointer;
    }
    .container-tickets {
      position: relative;
      flex: 1;
      z-index:1;
      .tickets-wrap {
        position: absolute;
        inset: 0;
        z-index:1;
      }
      .overflow-div-bottom {
        background: rgb(255 229 231 / 70%);
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 2;
      }
    }
  }
  .top {
    position: absolute;
    top: -10px;
    right:-10px;
    z-index: 1;
  }
`;
