import Sidebar from 'components/layout/Sidebar';
import colorTheme from 'constants/color';
import { DATE_FORMAT } from 'features/appointment/utils/format';
import ticketListActions from 'features/ticketList/services/actions';
import { IApiGetTicketsParam } from 'features/ticketList/services/types/api';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import HeaderTickets from './components/HeaderTickets';
import SummaryTickets from './components/SummaryTickets';
import TicketOnTabs from './components/TicketOnTabs';
import ListTicketPageStyled from './styles';
import shopSelectors from 'services/shop/selectors';
import TabsV2 from './components/TabsV2';
type IListTicketPageProps = any;
const ListTicketPage: React.FC<IListTicketPageProps> = () => {
  const dispatch = useAppDispatch();
  const versionUI = shopSelectors.data.versionUI();
  const v2 = versionUI === 'V2';

  useEffect(() => {
    dispatch(ticketListActions.doInit());
    const params: IApiGetTicketsParam = {
      status: 'CLOSED',
      page: 1,
      size: 30,
      startDate: moment().format(DATE_FORMAT),
      endDate: moment().format(DATE_FORMAT),
      staffId: '',
    };
    dispatch(ticketListActions.setVisibleCusPhone(true));
    dispatch(ticketListActions.setTicketsParams(params));
  }, []);

  if (v2) {
    return (
      <ListTicketPageStyled>
        <Sidebar />
        <BodyStyled className='v2'>
          <TabsV2 />
        </BodyStyled>
      </ListTicketPageStyled>
    );
  }

  return (
    <ListTicketPageStyled>
      <Sidebar />
      <BodyStyled>
        <HeaderTickets />
        <div className='container-body'>
          <TicketOnTabs />
        </div>
        <SummaryTickets />
      </BodyStyled>
    </ListTicketPageStyled>
  );
};

const BodyStyled = styled.div`
  flex-grow: 1;
  height: 100vh;
  overflow: auto;
  background-color: ${colorTheme.fill_3};
  display: flex;
  flex-direction: column;
  .container-body {
    flex: 1;
    display: flex;
    overflow: auto;
    padding: 0 1.5rem;

    flex-direction: column;
  }

  &.v2 {
    background-color: #F5F5F5;
  }
`;

export default ListTicketPage;
