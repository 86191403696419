
export const NAME_REDUCER = 'checkIn';

export const PREFIX_ACTIONS = 'checkIn_feature_';


export const PATH_LOADING = {
  getData: `loading.${NAME_REDUCER}.getData`,
};

export const CHECK_IN_COUNTDOWN = 2;


export enum ThemeHomeCheckIn {
  CHECK_IN = 'CHECK_IN',
  GOOGLE_NOT_SHOW_REVIEW = 'GOOGLE_NOT_SHOW_REVIEW',
  GOOGLE_SHOW_REVIEW = 'GOOGLE_SHOW_REVIEW',
}
