
import { AxiosResponse } from 'axios';
import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import { setLoading, setLoadingPage } from 'services/UI/sagas';
import actions from './actions';
import apis from './apis';
import { PATH_LOADING } from './constants';

import { get } from 'lodash';
import { IResponseDataBody } from 'services/response';
import { MyState, getCurrentState } from './selectors';
import { ICategoryItemData, IServiceItemData } from './types/categories';
import { ICreateCustomerBody, ICustomerItemData, ICustomerResItem } from './types/customer';
import { IStaffAvailableItem, IStaffItemData } from './types/staff';
import { ISagaFunc } from 'services/actionConfigs';
import storage from 'utils/sessionStorage';
import { IShopItem } from 'features/auth/services/types/owner';
import { ISpecialDiscountItemData } from './types/discount';
import { ICouponItemData } from './types/coupon';
import { IOtherPaymentMethodItem } from './types/payment';
import userActions from 'features/user/services/actions';
import { IProductCategoryItem } from './types/product';
import { IProductVIPItemData } from './types/vip';
import shopApis from './apis';
import { IScheduleBookingResData } from './types/booking';
import { IFeeCreditCardConfig } from './types/feeCreditCard';
import { ITaxConfigResData } from './types/tax';
import { CUSTOMER_VIEW_DOMAIN } from 'services/request';
import { IBodyShortLink } from './types/links';
import settingServiceActions from 'features/settingService/services/actions';
import { IShopAllSetting } from './types/setting';
import { ISimpleMenuConfigCheckIn, ISimpleMenuItem } from './types/simpleMenu';
import checkInActions from 'features/checkIn/services/actions';
import { IOptionTipObjectItem } from './types/optionTip';
import { checkExistsCountStaffInRowUI } from 'features/cashier/services/sagas';
import { shopUIActions } from './reducers';
const checkExist = function* (key: keyof MyState) {
  const store: MyState = yield select(root => getCurrentState(root));
  return get(store, key);
};
const getCategories = function* () {
  const storeData: MyState['categories'] = yield checkExist('categories');
  if (storeData.length > 0) {
    yield put(actions.get.categories.success(storeData));
    return;
  }

  yield setLoading(PATH_LOADING.getCategories, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<ICategoryItemData[]> = yield call(
      apis.getCategories
    );
    if (resData?.data?.data) {
      yield put(actions.get.categories.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.categories.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCategories, false);
  }
};


const getNewCategories = function* () {
  yield setLoading(PATH_LOADING.getCategories, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<ICategoryItemData[]> = yield call(
      apis.getCategories
    );
    if (resData?.data?.data) {
      yield put(actions.get.categories.success(resData?.data?.data));
      yield put(actions.getNewCategories.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.categories.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCategories, false);
  }
};

const getCategoriesWithoutBE = function* () {
  yield setLoading(PATH_LOADING.getCategoriesWithoutBE, true);
  try {
    const resData: IResponseDataBody<ICategoryItemData[]> = yield call(
      apis.getCategories
    );
    if (resData?.data?.data) {
      yield put(actions.get.categories.success(resData?.data?.data));
      yield put(actions.getCategoriesWithoutBE.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.categories.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCategoriesWithoutBE, false);
  }
};

const getAddOnList = function* () {
  const storeData: MyState['addonServices'] = yield checkExist('addonServices');
  if (storeData.length > 0) return;

  yield setLoading(PATH_LOADING.getAddOnList, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<IServiceItemData[]> = yield call(
      apis.getAddOnList
    );
    if (resData?.data?.data) {
      yield put(actions.get.addOnServices.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.addOnServices.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getAddOnList, false);
  }
};
const getNewAddOnList = function* () {
  yield setLoading(PATH_LOADING.getAddOnList, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<IServiceItemData[]> = yield call(
      apis.getAddOnList
    );
    if (resData?.data?.data) {
      yield put(actions.get.addOnServices.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.addOnServices.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getAddOnList, false);
  }
};

const getStaffList = function* () {
  const storeData: MyState['staffs'] = yield checkExist('staffs');
  if (storeData.length > 0) return;

  yield setLoading(PATH_LOADING.getStaffList, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<{ content: IStaffItemData[] }> = yield call(
      apis.getStaffs
    );
    if (resData?.data?.data?.content) {
      yield put(actions.get.staffs.success(resData?.data?.data?.content));
    }
  } catch (error) {
    yield put(actions.get.staffs.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getStaffList, false);
  }
};
const getNewStaffList = function* () {
  yield setLoading(PATH_LOADING.getStaffList, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<{ content: IStaffItemData[] }> = yield call(
      apis.getStaffs
    );
    if (resData?.data?.data?.content) {
      yield put(actions.get.staffs.success(resData?.data?.data?.content));
    }
  } catch (error) {
    yield put(actions.get.staffs.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getStaffList, false);
  }
};

const getStaffsAvailable = function* () {
  yield setLoading(PATH_LOADING.getStaffAvailableList, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<IStaffAvailableItem[]> = yield call(
      apis.getStaffsAvailable
    );

    if (resData?.data?.data) {
      yield put(actions.get.staffsAvailable.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.staffsAvailable.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getStaffAvailableList, false);
  }
};

const getCustomerList = function* () {
  yield setLoading(PATH_LOADING.getCustomerList, true);
  yield delay(100);
  try {
    const resData: AxiosResponse<{ data: { content: ICustomerItemData[] } }> = yield call(
      apis.getCustomers
    );
    if (resData?.data?.data?.content) {
      yield put(actions.get.customers.success(resData?.data?.data?.content));
    }
  } catch (error) {
    yield put(actions.get.customers.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getCustomerList, false);
  }
};

const getShopInfo: ISagaFunc<string> = function* ({ payload }) {
  yield storage.shop_id.set(payload);
  yield delay(200);
  yield initShopData({});
  try {
    const res: IResponseDataBody<IShopItem> = yield call(apis.getShopInfo);
    if (res.data.data) {
      yield put(actions.get.shopInfo.success(res.data.data));
    }
  } catch (error) { }
};

const getSpecialDiscount = function* () {
  yield delay(200);
  const storeData: MyState['discountSetting'] = yield checkExist('discountSetting');
  if (storeData) return;

  try {
    const res: IResponseDataBody<ISpecialDiscountItemData[]> = yield call(apis.getSpecialDiscountShop);
    if (res.data.data) {
      yield put(actions.get.specialDiscount.success(res.data.data));
    }
  } catch (error) { }
};

const getListCoupon = function* () {
  yield delay(200);
  try {
    const res: IResponseDataBody<ICouponItemData[]> = yield call(apis.getListCoupon);
    if (res.data.data) {
      yield put(actions.get.coupons.success(res.data.data));
    }
  } catch (error) { }
};

const getDiscountSetting: ISagaFunc<boolean | undefined> = function* ({ payload }) {
  if (!payload) {
    yield delay(200);
    const storeData: MyState['discountSetting'] = yield checkExist('discountSetting');
    if (storeData) return;
  }

  try {
    const res: IResponseDataBody<ICouponItemData[]> = yield call(apis.getDiscountSetting);
    if (res.data.data) {
      yield put(actions.get.discountSetting.success(res.data.data));
    }
  } catch (error) { }
};

const getFeeCreditCard = function* () {
  yield delay(200);
  const storeData: MyState['feeCreditCard'] = yield checkExist('feeCreditCard');
  if (storeData) return;

  try {
    const res: IResponseDataBody<IFeeCreditCardConfig> = yield call(apis.getFeeCreditCard);
    if (res.data.data) {
      yield put(actions.get.feeCreditCard.success(res.data.data));
    }
  } catch (error) { }
};

const getOtherPaymentMethod = function* () {
  yield delay(200);
  const storeData: MyState['orderPaymentMethods'] = yield checkExist('orderPaymentMethods');
  if (storeData.length > 0) return;

  try {
    const res: IResponseDataBody<IOtherPaymentMethodItem[]> = yield call(apis.getOtherPaymentMethod);
    if (res.data.data) {
      yield put(actions.get.otherPaymentMethod.success(res.data.data));
    }
  } catch (error) { }
};

const addNewCustomer: ISagaFunc<ICreateCustomerBody> = function* ({ payload }) {
  yield setLoadingPage(true);
  try {
    const res: IResponseDataBody<ICustomerResItem | null> = yield call(apis.addNewCustomer, payload);
    if (res.data.data) {
      yield put(actions.get.customers.fetch());
    }
  } catch (error) { }
  finally {
    yield setLoadingPage(false);
  }
};

const getShopAllSetting: ISagaFunc<string | undefined> = function* ({ payload }) {
  if (payload) {
    const res: IResponseDataBody<IShopAllSetting> = yield call(apis.getShopAllSetting, payload);
    if (res.data.data) {
      yield put(actions.get.allSetting.success(res.data.data));
    }
  }
  yield delay(200);
  const storeData: MyState['allSetting'] = yield checkExist('allSetting');
  if (storeData) return;

  try {
    const res: IResponseDataBody<IShopAllSetting> = yield call(apis.getShopAllSetting);
    if (res.data.data) {
      yield put(actions.get.allSetting.success(res.data.data));
    }
  } catch (error) { }
};

const getProductCategories = function* (forceReload?: boolean) {
  if (!forceReload) {
    const storeData: MyState['productCategories'] = yield checkExist('productCategories');
    if (storeData.length > 0) {
      yield put(actions.get.productCategories.success(storeData));
      return;
    }
  }
  yield delay(100);
  yield setLoading(PATH_LOADING.getProductCategories, true);
  try {
    const resData: IResponseDataBody<IProductCategoryItem[]> = yield call(
      apis.getProductCategories
    );
    if (resData?.data?.data) {
      yield put(actions.get.productCategories.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.productCategories.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getProductCategories, false);
  }
};

const getProductsVIP = function* (forceReload?: boolean) {
  if (!forceReload) {
    const storeData: MyState['productsVIP'] = yield checkExist('productsVIP');
    if (storeData.length > 0) {
      yield put(actions.get.productsVIP.success(storeData));
      return;
    }
  }

  yield delay(100);
  yield setLoading(PATH_LOADING.getProductsVIP, true);
  try {
    const resData: IResponseDataBody<IProductVIPItemData[]> = yield call(
      apis.getProductsVIP
    );
    if (resData?.data?.data) {
      yield put(actions.get.productsVIP.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.productsVIP.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getProductsVIP, false);
  }
};

const getTotalStation: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<number> = yield call(shopApis.getShopTotalStation, payload);
    if (res.data.data) {
      yield put(actions.get.totalStation.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.totalStation.fail(error));
  }
};

const getCouponPrint: ISagaFunc<string> = function* ({ payload }) {
  try {
    const res: IResponseDataBody<number> = yield call(shopApis.getCouponPrint, payload);
    if (res.data.data) {
      yield put(actions.get.couponPrint.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.couponPrint.fail(error));
  }
};

const getOptionTips = function* () {
  try {
    const res: IResponseDataBody<number> = yield call(shopApis.getOptionTips);
    if (res.data.data) {
      yield put(actions.get.optionTips.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.optionTips.fail(error));
  }
};

const getOptionTipsAll = function* () {
  try {
    const res: IResponseDataBody<IOptionTipObjectItem[]> = yield call(shopApis.getOptionTipsAll);
    if (res.data.data) {
      yield put(actions.get.optionTipsAll.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.optionTipsAll.fail(error));
  }
};

const getRecentCustomerList = function* () {
  yield setLoading(PATH_LOADING.getRecentCustomerList, true);
  yield delay(100);
  try {
    const res: IResponseDataBody<ICustomerItemData[]> = yield call(
      apis.getCustomersRecent
    );
    if (res.data.data) {
      yield put(actions.get.recentCustomers.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.recentCustomers.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getRecentCustomerList, false);
  }
};

const getScheduleBooking = function* () {
  yield setLoading(PATH_LOADING.getScheduleBooking, true);
  yield delay(100);
  try {
    const res: IResponseDataBody<IScheduleBookingResData> = yield call(
      apis.getShopScheduleBooking
    );
    if (res.data.data) {
      yield put(actions.get.scheduleBooking.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.scheduleBooking.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getScheduleBooking, false);
  }
};

const getTaxConfig = function* () {
  try {
    const res: IResponseDataBody<ITaxConfigResData> = yield call(shopApis.getTaxConfig);
    if (res.data.data) {
      yield put(actions.get.taxConfig.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.taxConfig.fail(error));
  }
};

const getCheckInOnlineLink = function* () {
  const longUrl = `${CUSTOMER_VIEW_DOMAIN}/store/${storage.shop_id?.get()}/check-in/${storage.station_number?.get()}`;
  const body: IBodyShortLink = {
    longUrl,
    deviceId: '',
    jsonUserAgent: '',
  };
  try {
    const res: IResponseDataBody<ITaxConfigResData> = yield call(shopApis.getShortLink, body);
    if (res.data.data) {
      yield put(actions.get.checkInOnlineLink.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.get.checkInOnlineLink.fail(error));
  }
};


const getSimpleMenu: ISagaFunc<boolean | undefined> = function* ({ payload }) {
  if (!payload) {
    const storeData: MyState['simpleMenu'] = yield checkExist('simpleMenu');
    if (storeData?.length > 0) {
      yield put(actions.get.simpleMenu.success(storeData));
      return;
    }
  }

  yield setLoading(PATH_LOADING.getSimpleMenu, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<ISimpleMenuItem[]> = yield call(apis.getSimpleMenu);
    if (resData?.data?.data) {
      yield put(actions.get.simpleMenu.success(resData?.data?.data));
    }
  } catch (error) {
    yield put(actions.get.simpleMenu.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getSimpleMenu, false);
  }
};
const getSimpleMenuConfigCheckIn = function* () {
  yield setLoading(PATH_LOADING.getSimpleMenuConfigCheckIn, true);
  yield delay(100);
  try {
    const resData: IResponseDataBody<ISimpleMenuConfigCheckIn> = yield call(apis.getSimpleMenuConfigCheckIn);
    yield put(actions.get.simpleMenuConfigCheckIn.success(resData?.data?.data));
  } catch (error) {
    yield put(actions.get.simpleMenuConfigCheckIn.fail({}));
  } finally {
    yield setLoading(PATH_LOADING.getSimpleMenuConfigCheckIn, false);
  }
};
const getQuickBookingService = function* () {
  try {
    const resData: IResponseDataBody<ISimpleMenuConfigCheckIn> = yield call(apis.getQuickBookingService);
    yield put(actions.get.quickBookingService.success(resData?.data?.data));
  } catch (error) {
    yield put(actions.get.quickBookingService.fail({}));
  }
};

const getStaffAvailableBooking = function* () {
  try {
    const resData: IResponseDataBody<ISimpleMenuConfigCheckIn> = yield call(apis.getStaffAvailableBooking);
    yield put(actions.get.staffAvailableBooking.success(resData?.data?.data));
  } catch (error) {
    yield put(actions.get.staffAvailableBooking.fail({}));
  }
};

const initShopData = function* ({ }) {
  yield all([
    put(actions.get.categories.fetch()),
    put(actions.get.addOnServices.fetch()),
    put(actions.get.staffs.fetch()),
    put(actions.get.staffAvailableBooking.fetch()),
    put(actions.get.customers.fetch()),
    put(actions.get.allSetting.fetch()),
    put(actions.get.discountSetting.fetch()),
    put(actions.get.otherPaymentMethod.fetch()),
    put(actions.get.coupons.fetch()),
    put(actions.get.specialDiscount.fetch()),
    put(actions.get.productCategories.fetch()),
    put(actions.get.productsVIP.fetch()),
    put(actions.get.scheduleBooking.fetch()),
    put(actions.get.checkInOnlineLink.fetch()),
    put(actions.get.feeCreditCard.fetch()),
    put(actions.get.simpleMenu.fetch()),
    put(actions.get.optionTips.fetch()),
    put(actions.get.optionTipsAll.fetch()),
    put(actions.get.simpleMenuConfigCheckIn.fetch()),
    put(actions.get.quickBookingService.fetch()),
    put(checkInActions.getHomeTheme.fetch()),
    checkExistsCountStaffInRowUI(),
  ]);
};

const initCheckInShopData = function* ({ }) {
  yield all([
    put(actions.get.categories.fetch()),
    put(actions.get.addOnServices.fetch()),
    put(actions.get.staffsAvailable.fetch()),
    put(actions.get.simpleMenuConfigCheckIn.fetch()),
    put(checkInActions.getHomeTheme.fetch()),
  ]);
};

const initEditTicketShopData = function* ({ }) {
  yield all([
    put(actions.get.categories.fetch()),
    put(actions.get.addOnServices.fetch()),
    put(actions.get.customers.fetch()),
    put(actions.get.staffs.fetch()),
    put(actions.get.staffsAvailable.fetch()),
    put(actions.get.specialDiscount.fetch()),
    put(actions.get.discountSetting.fetch()),
    put(actions.get.otherPaymentMethod.fetch()),
    put(actions.get.coupons.fetch()),
    put(actions.get.feeCreditCard.fetch()),
    put(actions.get.taxConfig.fetch()),
    put(actions.get.simpleMenu.fetch()),
  ]);
};

const cacheVersionConfig: ISagaFunc<IShopAllSetting> = function* ({ payload }) {
  yield storage.versionUI.set(payload.versionUI);
  yield put(shopUIActions.setVersionUI(payload.versionUI));
};

export default function* shopServiceSagas() {
  yield takeLatest(actions.get.categories.fetch, getCategories);
  yield takeLatest(actions.getNewCategories.fetch, getNewCategories);
  yield takeLatest(actions.getCategoriesWithoutBE.fetch, getCategoriesWithoutBE);
  yield takeLatest(actions.get.addOnServices.fetch, getAddOnList);
  yield takeLatest(actions.getNewAddOnList.fetch, getNewAddOnList);
  yield takeLatest(actions.get.staffs.fetch, getStaffList);
  yield takeLatest(actions.get.newStaffs, getNewStaffList);
  yield takeLatest(actions.get.staffsAvailable.fetch, getStaffsAvailable);
  yield takeLatest(actions.get.customers.fetch, getCustomerList);
  yield takeLatest(actions.get.recentCustomers.fetch, getRecentCustomerList);
  yield takeLatest(actions.get.shopInfo.fetch, getShopInfo);
  yield takeLatest(actions.get.specialDiscount.fetch, getSpecialDiscount);
  yield takeLatest(actions.get.coupons.fetch, getListCoupon);
  yield takeLatest(actions.get.discountSetting.fetch, getDiscountSetting);
  yield takeLatest(actions.get.otherPaymentMethod.fetch, getOtherPaymentMethod);
  yield takeLatest(actions.get.productCategories.fetch, getProductCategories);
  yield takeLatest(settingServiceActions.reloadProduct, function* () {
    yield getProductCategories(true);
  });
  yield takeLatest(actions.get.productsVIP.fetch, getProductsVIP);
  yield takeLatest(settingServiceActions.reloadVIPs, function* () {
    yield getProductsVIP(true);
  });

  yield takeLatest(actions.get.scheduleBooking.fetch, getScheduleBooking);
  yield takeLatest(actions.get.feeCreditCard.fetch, getFeeCreditCard);
  yield takeLatest(actions.get.checkInOnlineLink.fetch, getCheckInOnlineLink);

  yield takeLatest(actions.initShopData, initShopData);
  yield takeLatest(actions.doInit.checkIn, initCheckInShopData);
  yield takeLatest(actions.doInit.editTicket, initEditTicketShopData);
  yield takeLatest(actions.doAdd.customer.fetch, addNewCustomer);
  yield takeLatest(userActions.doClockIn.success, getStaffsAvailable);
  yield takeLatest(userActions.doClockOut.success, getStaffsAvailable);
  yield takeLatest(actions.get.allSetting.fetch, getShopAllSetting);
  yield takeLatest(actions.get.allSetting.success, cacheVersionConfig);
  yield takeLatest(actions.get.totalStation.fetch, getTotalStation);
  yield takeLatest(actions.get.couponPrint.fetch, getCouponPrint);
  yield takeLatest(actions.get.taxConfig.fetch, getTaxConfig);
  yield takeLatest(actions.get.simpleMenu.fetch, getSimpleMenu);
  yield takeLatest(actions.get.optionTips.fetch, getOptionTips);
  yield takeLatest(actions.get.optionTipsAll.fetch, getOptionTipsAll);
  yield takeLatest(actions.get.simpleMenuConfigCheckIn.fetch, getSimpleMenuConfigCheckIn);
  yield takeLatest(actions.get.quickBookingService.fetch, getQuickBookingService);
  yield takeLatest(actions.get.staffAvailableBooking.fetch, getStaffAvailableBooking);
}
