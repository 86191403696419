import Icon from 'assets/Icons';
import Button, { ButtonProps } from 'components/Button';
import { CSSProperties } from 'react';
import { styled } from 'styled-components';
interface Props extends ButtonProps { v2UI?: boolean, label: string, small?: boolean, labelStyle?: CSSProperties, }
function ActionBtn(props: Props) {
    return (
        <ActionBtnStyled
            {...props}
            block
            vertical
            icon={!props.v2UI ? props.icon : undefined}
            iconElement={props.v2UI ? (props.icon && <div style={{ height: 80, width: 80 }}><Icon type={props.icon} /></div>) : undefined}
            cardCashier={props.v2UI}
        >
            <p style={props.labelStyle}>{props.label}</p>
        </ActionBtnStyled>
    );
}

export default ActionBtn;
const ActionBtnStyled = styled(Button) <Props>`
    width: 6.2rem !important;
    p {
        white-space: pre;
      color: #1D2129;
      text-align: center;
      font-family: Open Sans;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    ${({ v2UI }) => v2UI && `
        display: flex;
        width: 120px !important;
        padding: 0px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch !important;
        height: auto !important;
        gap: 0px;
        align-self: stretch;
        p {
            color: #1D2129;
            text-align: center;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    `}
`;