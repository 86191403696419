import { Layout } from 'antd';
import React from 'react';
import styled from 'styled-components';
import AppointmentLayout from '../../Layout';
import AppointmentDetail from '../TableView/Body/Modal/AppointmentDetail';
import BlockHours from '../TableView/Body/Modal/BlockHours';
import BreakTime from '../TableView/Body/Modal/BreakTime';
import QuickBooking, { quickBookingRef } from '../TableView/Body/Modal/QuickBooking';
import AppointmentCalendarHeaderV2 from './Header';
import AppointmentFilterV2 from './Filter';
import NewAppointmentVersionV2 from '../TableView/Body/Modal/NewAppointmentV2/v2';
import MultipleAptDetailV2 from '../TableView/Body/Modal/MultipleAptDetailV2';

type IAppointmentCalendarProps = any;
const AppointmentCalendarV2: React.FC<IAppointmentCalendarProps> = () => {
  return (
    <AppointmentCalendarStyled>
      <AppointmentCalendarHeaderV2 />
      <AppointmentFilterV2 />
      <AppointmentLayout v2 />
      <NewAppointmentVersionV2 />
      <BlockHours v2 />
      <BreakTime v2 />
      <QuickBooking ref={quickBookingRef} />
      <AppointmentDetail v2 />
      <MultipleAptDetailV2 />
    </AppointmentCalendarStyled>
  );
};

export default AppointmentCalendarV2;
type AppointmentCalendarStyledProps = {}
const AppointmentCalendarStyled = styled(Layout.Content) <AppointmentCalendarStyledProps>`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-top: 0;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
`;
