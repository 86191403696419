
export const NAME_REDUCER = 'shop';

export const PREFIX_ACTIONS = 'shop_feature_';

export const PATH_LOADING = {
  getDetail: `loading.${NAME_REDUCER}.getDetail`,
  getCategories: `loading.${NAME_REDUCER}.getCategories`,
  getCategoriesWithoutBE: `loading.${NAME_REDUCER}.getCategoriesWithoutBE`,
  getProductCategories: `loading.${NAME_REDUCER}.getProductCategories`,
  getProductsVIP: `loading.${NAME_REDUCER}.getProductsVIP`,
  getAddOnList: `loading.${NAME_REDUCER}.getAddOnList`,
  getStaffList: `loading.${NAME_REDUCER}.getStaffList`,
  getStaffAvailableList: `loading.${NAME_REDUCER}.getStaffAvailableList`,
  getCustomerList: `loading.${NAME_REDUCER}.getCustomerList`,
  getRecentCustomerList: `loading.${NAME_REDUCER}.getRecentCustomerList`,
  getScheduleBooking: `loading.${NAME_REDUCER}.getScheduleBooking`,
  getSimpleMenu: `loading.${NAME_REDUCER}.getSimpleMenu`,
  getSimpleMenuConfigCheckIn: `loading.${NAME_REDUCER}.getSimpleMenuConfigCheckIn`,
};

export enum SHOP_DEVICE_TYPE {
  CHECK_IN = 'CHECK_IN',
  POS = 'POS',
}
export enum CUSTOMER_TARGET_TYPE {
  VIP = 'VIP',
  LOYAL = 'LOYAL',
  NORMAL = 'NORMAL',
}

export enum FLOW_ADD_TIP {
  TIP_AFTER_SALE = 'TIP_AFTER_SALE', // đi theo luồng hiện tại
  TIP_BEFORE_SALE = 'TIP_BEFORE_SALE' // đi theo luồng mới
}

export const ADD_ON_CATE_ID = 'add-on';


export enum HOME_SORT_BY {
  CLOCK_IN = 'CLOCK_IN',
  TURN = 'TURN',
  APPOINTMENT = 'APPOINTMENT',
  AMOUNT = 'AMOUNT',
  AVAILABLE = 'AVAILABLE',
}
