
import { ISagaFunc } from 'services/actionConfigs';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import apis from './apis';
import actions from './actions';
import { AxiosResponse } from 'axios';
import uiActions from 'services/UI/actions';
import { PATH_LOADING } from './constants';
import { IGetGiftCardDetail, IGetListGiftCard } from './types/api';
import { IGetListGiftCardData, IGiftCardDetail, IGiftCardSummaryResData } from './types/reducer';
import toast from 'utils/toast';
import { IResponseDataBody } from 'services/response';

const getListGiftCard: ISagaFunc<IGetListGiftCard> = function* ({ payload }) {
  yield delay(200);
  yield put(uiActions.setLoading({ path: PATH_LOADING.getListGiftCard, result: true }));
  try {
    const resData: AxiosResponse<{ data: IGetListGiftCardData }> = yield call(apis.getListGiftCard, payload);
    if (resData?.data?.data) {
      yield put(actions.getListGiftCard.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getListGiftCard.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getListGiftCard, result: false }));
  }
};

const getGiftCardDetail: ISagaFunc<IGetGiftCardDetail> = function* ({ payload }) {
  yield delay(200);
  yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardDetail, result: true }));
  try {
    const resData: AxiosResponse<{ data: IGiftCardDetail }> = yield call(apis.getGiftCardDetail, payload);
    if (resData?.data?.data) {
      yield put(actions.getGiftCardDetail.success(resData.data.data));
    }
  } catch (error) {
    yield put(actions.getGiftCardDetail.fail({}));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.getGiftCardDetail, result: false }));
  }
};

const resetGiftCardDetail: ISagaFunc<string[]> = function* ({ payload }) {
  yield delay(200);
  yield put(
    uiActions.setLoadingPage(true)
  );
  yield put(uiActions.setLoading({ path: PATH_LOADING.resetGiftCardDetail, result: true }));
  try {
    const resData: AxiosResponse<{ data: any }> = yield call(apis.resetGiftCardDetail, payload);
    if (resData?.data?.data) {
      toast.success('Successful');
      yield put(actions.resetGiftCardDetail.success(payload));
    }
  } catch (error) {
    yield put(actions.resetGiftCardDetail.fail(false));
  } finally {
    yield put(uiActions.setLoading({ path: PATH_LOADING.resetGiftCardDetail, result: false }));
    yield put(
      uiActions.setLoadingPage(false)
    );
  }
};

const getSummaryGiftCards = function* () {
  yield delay(200);
  try {
    const res: IResponseDataBody<IGiftCardSummaryResData> = yield call(apis.getSummaryGiftCards);
    if (res.data.data) {
      yield put(actions.getSummaryGiftCards.success(res.data.data));
    }
  } catch (error) {
    yield put(actions.getSummaryGiftCards.fail({}));
  }
};

export default function* giftCardsServiceSagas() {
  yield takeLatest(actions.getListGiftCard.fetch, getListGiftCard);
  yield takeLatest(actions.getGiftCardDetail.fetch, getGiftCardDetail);
  yield takeLatest(actions.resetGiftCardDetail.fetch, resetGiftCardDetail);
  yield takeLatest(actions.getSummaryGiftCards.fetch, getSummaryGiftCards);
}
