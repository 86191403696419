import { Tabs, TabsProps } from 'antd';
import Icon, { typeIcon } from 'assets/Icons';
import Box from 'components/Box';
import Button from 'components/Button';
import ModalConfirm, { useModalConfirmRef } from 'components/Modal/ModalConfirm';
import Text from 'components/Text';
import customerActions from 'features/customer/services/actions';
import customerSelectors from 'features/customer/services/selectors';
import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import { maskPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import { StatusBoxContainerStyled } from '../../ListCustomerPage/components/StatusBox';
import CustomerNote from './CustomerNote';
import AppointmentTab from './tabs/AppointmentTab';
import CouponActiveTab from './tabs/CouponActiveTab';
import CouponTab from './tabs/CouponTab';
import ReferralTab from './tabs/ReferralTab';
import TransactionsTab from './tabs/TransactionsTab';
import CustomerDetailHeaderV2 from './CustomerDetailHeaderV2';
type IRightCustomerDetailsProps = {
  v2?: boolean;
};
type Item = {
  keyIndex: number;
  iconType: typeIcon;
  value: string | number;
  label: string;
};

const items: TabsProps['items'] = [
  {
    key: 'appointment',
    label: 'Appointment',
    children: <AppointmentTab />,
  },
  {
    key: 'transactions',
    label: 'Transactions',
    children: <TransactionsTab />,
  },
  {
    key: 'referral',
    label: 'Referral',
    children: <ReferralTab />,
  },
  {
    key: 'coupon-active',
    label: 'Coupon Active',
    children: <CouponActiveTab />,
  },
  {
    key: 'coupon',
    label: 'Coupon Redeem',
    children: <CouponTab />,
  },
];

const RightCustomerDetails: React.FC<IRightCustomerDetailsProps> = ({ v2 }) => {
  const dispatch = useAppDispatch();
  const modalConfirm = useModalConfirmRef();
  const navigate = useNavigate();
  const customerDetails = customerSelectors.getCustomerDetails();
  const summaryReportData = customerSelectors.summaryReportData.data();

  const handleDeleteCustomer = () => {
    const cb = () => dispatch(customerActions.deleteCustomer.fetch(customerDetails?.id));
    modalConfirm.current?.open(`Do you want to remove ${customerDetails.name || ''}?`, cb);
  };

  const itemsReview: Item[] = [
    {
      keyIndex: 1,
      iconType: 'tip',
      value: formatCurrency(summaryReportData.amountSpend || 0),
      label: 'Amount Spent',
    },
    {
      keyIndex: 2,
      iconType: 'loyaltyPoint',
      value: summaryReportData.point || 0,
      label: 'Point',
    },
    {
      keyIndex: 3,
      iconType: 'walkIn',
      value: summaryReportData.totalWalkIn || 0,
      label: 'Walk-In',
    },
    {
      keyIndex: 4,
      iconType: 'calendar',
      value: summaryReportData.totalAppointment || 0,
      label: 'Appointment',
    },
  ];

  const onChange = () => undefined;
  const handleEdit = () => {
    navigate(
      `/store/${storage.shop_id.get()}/customers/${customerDetails.id}/edit`
    );
  };

  return (
    <RightCustomerDetailsStyled className={v2 ? 'v2' : ''}>
      {v2 ? <>
        <CustomerDetailHeaderV2 summaryReportData={summaryReportData} data={customerDetails} onEdit={handleEdit} onDelete={handleDeleteCustomer} />
        <ContainerNoteV2>
          <CustomerNote />
        </ContainerNoteV2>
      </>
        : <>
          <Box display='flex' justifyContent='space-between' alignItems='end'>
            <div>
              <Box className="space-between">
                <Box display="flex" alignItems="end" gap="3" >
                  <div style={{ position: 'relative', marginRight: '1rem' }}>
                    <Text variant="H5" color="text_3">
                      {customerDetails?.name || ''}
                    </Text>
                    <StatusBoxContainerStyled style={{ position: 'absolute', right: 0, top: -15 }}>
                      <div className={customerDetails?.accountPackage} style={{ borderRadius: '5px' }}>
                        <Text variant="CONTENT_2" color="text_3" style={{ fontSize: '12px' }} mx={0.25}>
                          {customerDetails?.accountPackage || ''}
                        </Text>
                      </div>
                    </StatusBoxContainerStyled>
                  </div>
                  <Box display="flex" alignItems="center" gap="3">
                    <Button size='small' icon="deleteIcon" ntype="SECONDARY" onClick={handleDeleteCustomer}>
                    </Button>
                    <Button size='small' icon="edit" ntype="LIGHT_BLUE" onClick={handleEdit}>
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" mt="2">
                <Box br="line_2" pr="3" mr="3">
                  <Text variant="CONTENT_1" color="text_2">
                    {customerDetails?.phone ? maskPhone(customerDetails?.phone) : '--'}
                  </Text>
                  <Text variant="CONTENT_1" color="text_2">
                    Email: {customerDetails?.email || '--'}
                  </Text>
                  <Text variant="CONTENT_1" color="text_2">
                    DOB:{' '}
                    {customerDetails?.birthday
                      ? moment(customerDetails?.birthday).format('MM-DD-YYYY')
                      : '--'}
                  </Text>
                </Box>
                <Box>
                  <Text variant="CONTENT_1" color="text_2">
                    Created Date:{' '}
                    {customerDetails?.createdDate
                      ? moment(customerDetails?.createdDate).format('MM-DD-YYYY')
                      : '--'}
                  </Text>
                  <Text variant="CONTENT_1" color="text_2">
                    Last Visit Date:{' '}
                    {customerDetails?.lastVisitedDate
                      ? moment(customerDetails?.lastVisitedDate).format('MM-DD-YYYY')
                      : '--'}
                  </Text>
                  <Text variant="CONTENT_1" color="text_2">
                    Visited: {customerDetails?.numberVisit || '--'}
                  </Text>
                </Box>
              </Box>
            </div>
            <BoxIconStyled>
              {itemsReview.map((item: Item) => (
                <Box
                  display="flex"
                  alignItems="center"
                  gap="2"
                  className="item-icon"
                  key={item.keyIndex}
                >
                  <Box width="3rem" height="3rem">
                    <Icon type={item.iconType} className="icon-item" />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                  >
                    <Text variant="H9" color="text_3">
                      {item.value}
                    </Text>
                    <Text variant="CONTENT_1" color="text_2">
                      {item.label}
                    </Text>
                  </Box>
                </Box>))}
            </BoxIconStyled>
          </Box>
          <CustomerNote />
        </>}
      <Tabs items={items} defaultActiveKey="OPEN_TICKET" onChange={onChange} />
      <ModalConfirm ref={modalConfirm} />
    </RightCustomerDetailsStyled>
  );
};

export default RightCustomerDetails;

const RightCustomerDetailsStyled = styled.div`
  padding: 1.5rem;
  width: 100%;
  &.v2 {
    background: #F6F7FC;
    .ant-tabs .ant-tabs-tab {
      padding: 22px 16px;
      padding-top: 32px;
      margin: 0;
    }
    .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-ink-bar {
      height: 3px; 
      background: #2D6772;
    }
    .ant-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
      .ant-tabs-tab-btn {
        color: #64748B;
        text-align: center;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      &.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: #2D6772;
        font-weight: 600;
      }
    }

    .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
      .ant-table-wrapper .ant-table .ant-table-content{
        .ant-table-thead th.ant-table-cell, .ant-table-thead td.ant-table-cell {
          height: 48px;
          padding: 0px 8px;
          gap: 10px;
          align-self: stretch;
          border-bottom: 1px solid #CCD4DC;
          background: #6FABB6;
          color: #1D2129;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          text-transform: capitalize;
          text-align: left !important;
          &::before {
            width: 1.5px;
            height: 100%;
          }
        }

        .ant-table-tbody td.ant-table-cell {
          .BODY_1, .CONTENT_2 {
            color: #1D2129;
            font-family: Poppins;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }

        .ant-table-tbody .ant-table-row:nth-child(even) td {
          background: #E4F4F6;
          border-bottom: 1px solid #E4F4F6;
        }
      }
    }
  }
`;

const BoxIconStyled = styled.div`
  margin-bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0.5rem;
  grid-column-gap: 3rem;
  .item-icon {
    flex-grow: 1;
    flex-basis: 5rem;
  }
  svg {
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }
`;

const ContainerNoteV2 = styled.div`
margin-top: 8px;
display: flex;
padding: 0px 16px;
align-items: center;
align-self: stretch;
background: #FFF;
box-shadow: 0px 4px 8px -4px rgba(0, 0, 0, 0.25);
`;
