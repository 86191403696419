import { Avatar } from 'antd';
import Box from 'components/Box';
import Text from 'components/Text';
import { ILockBreakTimeItem } from 'features/appointment/services/types/lockBreakTime';
import React from 'react';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import { momentTimezone } from 'utils/time';
import warning_icon from './warning-icon.svg';
type Props = {
  staffs: IStaffItemData[];
  staffBlockHours: ILockBreakTimeItem[];
  date?: string;
};
const BlockHourStaffs = ({ staffBlockHours, staffs, date }: Props) => {
  const mappingList = React.useMemo(() => staffBlockHours.map(o => {
    const staff = staffs.find(s => s.id === o.staffId);
    return ({
      ...o,
      staff,
      keyword: staff?.firstName?.toLocaleLowerCase(),
    });
  }), [staffBlockHours, staffs]);
  if (!mappingList?.length) return null;
  return (
    <Container>
      <Box display='flex' alignItems='center' alignSelf='stretch' justifyContent='space-between'>
        <Box display='flex' alignItems='center' gap='1'>
          <img src={warning_icon} alt='warning_icon' style={{ width: '48px', height: '48px' }} />
          <Text variant='H9' color='text_3' className='text-lg' style={{ fontSize: 24 }}>Appointment Scheduling Display</Text>
        </Box>
        <Text variant='H9' color='text_3' className='text-lg' style={{ fontSize: 24 }}>Date: {date || '--'} </Text>
      </Box>
      <Box display='flex' flexDirection='column' gap='1'>
        {mappingList.map(o => {
          const staff = o.staff;
          return (
            <StaffItem key={o.id}>
              <Box display='flex' flexDirection='column' alignItems='center'>
                <Avatar size={50} src={staff?.urlImage}>{staff?.firstName || 'A'}</Avatar>
                <Text variant='H9' color='text_3' className='text-lg'>{staff?.firstName || '--'}</Text>
              </Box>
              <Box display='flex' flexDirection='column' alignItems='end'>
                <Text variant='H9' color='text_3' className='text-lg'>BLOCKED TIME: {momentTimezone(o?.startTime).format('hh:mm A')} - {momentTimezone(o?.endTime).format('hh:mm A')}</Text>
                <Text variant='H9' color='text_3' className='text-lg'>MESSAGE: {o.reason || '--'}</Text>
              </Box>
            </StaffItem>
          );
        })}
      </Box>
    </Container>
  );
};

export default BlockHourStaffs;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-top: 1px solid #86909C;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  .InputSearch .ant-input {
    font-size: 20px;
  }
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
`;
const StaffItem = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  background-color: #F0F0F0;
  border: 1px solid #F0F0F0;
  padding: 8px;
`;