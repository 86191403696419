import { createAction, createAsyncAction } from 'services/actionConfigs';
import { PREFIX_ACTIONS, TURN_ITEM_STATUS, TURN_ITEM_TYPE } from './constants';
import { IApiGetTurnDetailParam, IApiGetTurnParam, IApiGetTurnStaffsAvailable, IApiUpdateTurnDetailParam, ISyncWaitngToTicket } from './types/api';
import { IWaitingListResItem } from 'features/user/services/types/waiting';
import { ITurnServiceItem } from './types/service';
import { IStaffTurnManualItem, ITurnManualSettingColorItem } from './types/turn';

const getTurn = createAsyncAction<IApiGetTurnParam>(PREFIX_ACTIONS + 'getTurn');
const setTurnLoading = createAction<boolean>(PREFIX_ACTIONS + 'setTurnLoading');
const getTurnDetail = createAsyncAction<IApiGetTurnDetailParam>(PREFIX_ACTIONS + 'getTurnDetail');
const setShowModalTurnDetail = createAction<boolean>(PREFIX_ACTIONS + 'showModalTurnDetail');
const getTurnStaffsAvailable = createAsyncAction<IApiGetTurnStaffsAvailable>(PREFIX_ACTIONS + 'getTurnStaffsAvailable');
const updateTurnDetail = createAsyncAction<IApiUpdateTurnDetailParam>(PREFIX_ACTIONS + 'updateTurnDetail');
const syncWaitngToTicket = createAsyncAction<ISyncWaitngToTicket>(PREFIX_ACTIONS + 'syncWaitngToTicket');
const selectWaitingListResItem = createAction<IWaitingListResItem | undefined>(PREFIX_ACTIONS + 'selectWaitingListResItem');

const clearAllTurn = createAction(PREFIX_ACTIONS + 'clearAllTurn');

const syncTurn = createAction(PREFIX_ACTIONS + 'syncTurn');

export const turnManualAPIActions = {
  init: createAction(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'init'),
  getTurns: createAsyncAction<undefined, IStaffTurnManualItem[]>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'getTurns'),
  getTurnConfigServices: createAsyncAction<undefined, ITurnServiceItem[]>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'getTurnConfigServices'),
  getTurnSettingColors: createAsyncAction<undefined, ITurnManualSettingColorItem[]>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'getTurnSettingColors'),
  deleteTurnServiceItem: createAction<string>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'deleteTurnServiceItem'),
  handleTurnWithService: createAction<ITurnServiceItem>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'handleTurnWithService'),
  changeTypeTurnManual: createAction<TURN_ITEM_TYPE>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'changeTypeTurnManual'),
  changeStatusTypeTurnManual: createAction<TURN_ITEM_STATUS>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'changeStatusTypeTurnManual'),
  removeTurnManual: createAction(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'removeTurnManual'),
  logoutStaff: createAction<string>(PREFIX_ACTIONS + '_turnManualAPIActions_' + 'logoutStaff'),
};
const turnActions = {
  getTurn,
  getTurnDetail,
  setShowModalTurnDetail,
  getTurnStaffsAvailable,
  updateTurnDetail,
  syncTurn,
  selectWaitingListResItem,
  syncWaitngToTicket,
  setTurnLoading,
  clearAllTurn,
  turnManualAPIActions,
};

export default turnActions;
