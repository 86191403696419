
import { Avatar } from 'antd';
import Button from 'components/Button';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { SortableList } from 'features/settingService/components/SortableList';
import turnActions from 'features/turn/services/actions';
import userActions from 'features/user/services/actions';
import userApis from 'features/user/services/apis';
import userSelectors from 'features/user/services/selectors';
import { sortBy } from 'lodash';
import { useCallback, useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';

const ReOrderClockIn = () => {
  const [handleOpen, context] = useReOrderCheckInModal();
  return (
    <>
      <Button onClick={handleOpen} mr={0.5} icon='walkInClock' ntype='LIGHT_BLUE' />
      {context}
    </>
  );
};

export default ReOrderClockIn;

export const useReOrderCheckInModal = (v2?: boolean): [() => void, JSX.Element] => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const setLoading = useSetLoadingPage();
  const data = userSelectors.listAvailableWorking();
  const [items, setItems] = useState<{ id: string, name: string, avatar: string }[]>([]);
  const handleOpen = () => {
    setItems(sortBy(data, 'indexClockIn').map(o => ({ id: o.staffId, name: o.staffName, avatar: o.staffAvatar })));
    setOpen(true);
  };

  const handleAccept = async () => {
    setOpen(false);

    setLoading(true);
    const ids = items.map(o => o.id);
    try {
      const resSort: IResponseDataBody<boolean> = await userApis.sortCheckIn(ids);
      if (resSort.data.data) {
        const res: IResponseDataBody<IStaffAvailableItem[]> = await userApis.getListAvailableWorking();
        if (res.data.data) {
          dispatch(userActions.getListAvailableWorking.success(res.data.data));
          dispatch(turnActions.getTurnStaffsAvailable.success(res.data.data));
        }
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };
  const renderItem = useCallback((item: { id: string; name: string; avatar: string; }) => (
    <SortableList.Item id={item.id}>
      <CateWrap>
        <Avatar
          src={item.avatar}
          size={v2 ? 60 : 32}
        >
          {item.name?.[0] || 'A'}
        </Avatar>
        <Text style={{ flex: 1, fontSize: v2 ? '1.5rem' : '1.25rem' }} variant="H7" color="text_3">
          {item.name}
        </Text>

      </CateWrap>
    </SortableList.Item>
  ), []);
  const context = <Modal
    v2={v2}
    visible={open}
    onClose={() => setOpen(false)}
    onSubmit={handleAccept}
    modalTitle='Reorder Clock-in'
    width={1500}
    containerPadding={1}
    // footerPadding={1}
    noneBodyStyle
  >
    <SortableList
      useGrid
      items={items}
      onChange={setItems}
      renderItem={renderItem}
    />
  </Modal>;


  return [handleOpen, context];
};

const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 12px;
  .label {
    font-size: 18px;
    font-weight: 600;
  }
`;