import Box from 'components/Box';
import Loading from 'components/Loading';
import Modal from 'components/Modal';
import Text from 'components/Text';
import { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';


export type IPaymentLoadingRef = {
  setVisible: (val: boolean) => void;
  openTimeout: (time: number) => void;
};
const PaymentLoading = forwardRef<IPaymentLoadingRef, {}>((props, ref) => {
  const [open, setOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    setVisible: val => setOpen(val),
    openTimeout: (time) => {
      setOpen(true);
      setTimeout(() => setOpen(false), time);
    }
  }));

  return (
    <LoadingButtonStyled>
      <Modal
        width={'54vw'}
        visible={open}
        destroyOnClose
        footer=""
        modalTitle=""
        noneBodyStyle={true}
      >
        <Container>
          <Loading />
        </Container>
        <Box my="4" className='center'>
          <Text variant="H7" color="primary_active">
            Payment Processing...
          </Text>
        </Box>
      </Modal>
    </LoadingButtonStyled>
  );
});
PaymentLoading.displayName = 'PaymentLoading';
export default PaymentLoading;
const LoadingButtonStyled = styled.div``;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 433px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 242px;
  }
`;
