import { Col, Row } from 'antd';
import Box from 'components/Box';
import Button from 'components/Button';
import Text from 'components/Text';
import { IBillDetailData } from 'features/payment/services/types/bill';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatCurrency } from 'utils/formatCurrency';
import storage from 'utils/sessionStorage';
import { momentTimezone } from 'utils/time';
type ITicketDetailProps = {
  serviceCharge?: number;
  total?: number;
  discountTicket?: number;
  detailTicket: IBillDetailData | null
};
const TicketDetail: React.FC<ITicketDetailProps> = ({ discountTicket = 0, total = 0, detailTicket, serviceCharge = 0 }) => {
  const navigate = useNavigate();
  const handleBack = () => navigate(`/store/${storage.shop_id.get()}/check-in/sign-in`);

  const calc = useMemo(() => ({
    serviceCharge: formatCurrency(serviceCharge),
    discByItem: formatCurrency(-(detailTicket?.discountItem || 0)),
    discByTicket: formatCurrency(-(discountTicket)),
    subTotal: formatCurrency((detailTicket?.subTotal || 0) - ((detailTicket?.discountItem || 0) + (detailTicket?.discountTicket || 0))),
    balance: formatCurrency(detailTicket?.subTotal),
    saleTax: formatCurrency(detailTicket?.saleTax),
    useTax: formatCurrency(detailTicket?.useTax),
    tip: formatCurrency(detailTicket?.tip),
    total: formatCurrency(total),
  }), [detailTicket, serviceCharge]);

  return (
    <TicketDetailStyled>
      <Button icon='back' size='middle' className='btn-back' onClick={handleBack}>Back</Button>
      <div className="box-info">
        <div>
          <div className='ticket-number-box'>
            <Text variant='H5'>#{detailTicket?.ticketNumber}</Text>
          </div>
          <Text variant='H8' color='text_2'>{detailTicket?.startTime ? momentTimezone(detailTicket?.startTime).format('MM-DD-YYYY LT') : null}</Text>
        </div>
        <div className='customer-box'>
          <Text variant='H5' mb={0.25} >{detailTicket?.customerName || 'Unknown Customer'}</Text>
          <Text variant='H5' mb={0.25} >{detailTicket?.customerPoint || 0} Points</Text>
        </div>
      </div>
      <div className="items">
        {detailTicket?.items.map(o => (
          <div key={o.staffId} className='item'>
            <Text variant='H5'>{o.staffName}</Text>
            <Text className='border-dotted' mb={0.5} />
            {o.services.map((s, i) => (
              <Row key={i} align={'middle'} justify={'space-between'} className='service'>
                <Text variant='H5'>{s.itemName}</Text>
                <Text variant='H5'>{formatCurrency(s.price || 0)}</Text>
              </Row>
            ))}
          </div>
        ))}
      </div>
      <Text className="border" mb={0.25} />
      <Row>
        <Col span={14} style={{ display: 'flex' }}>
          <Box display='flex' flexDirection='column' pb='4' alignSelf='stretch' justifyContent='center' alignItems='center' style={{ flex: 1 }}>
            <Text variant='H3' color='info_3' style={{ color: '#0a5194' }}>Total Savings</Text>
            <Text variant='H3' color='info_3' style={{ fontSize: '2.5rem', fontWeight: 'bolder', color: '#0a5194' }}>{formatCurrency(-(detailTicket?.discountItem || 0) - (discountTicket))}</Text>
          </Box>
        </Col>
        <Col span={10}>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between' highlight>
            <Text variant='CONTENT_2' className='title-highlight'>Balance:</Text>
            <Text variant='H5' className='title-highlight'>{calc.balance}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Processing Fee:</Text>
            <Text variant='CONTENT_2'>{calc.serviceCharge}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Disc. By Item:</Text>
            <Text variant='CONTENT_2'>{calc.discByItem}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Disc. By Ticket:</Text>
            <Text variant='CONTENT_2'>{calc.discByTicket}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Subtotal:</Text>
            <Text variant='CONTENT_2'>{calc.subTotal}</Text>
          </Box>
          <Text className="border" />
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Sale Tax:</Text>
            <Text variant='CONTENT_2'>{calc.saleTax}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Use Tax:</Text>
            <Text variant='CONTENT_2'>{calc.useTax}</Text>
          </Box>
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='CONTENT_1'>Tip:</Text>
            <Text variant='CONTENT_2'>{calc.tip}</Text>
          </Box>
          <Text className="border" />
          <Box py='1' px='1' display='flex' alignItems='center' justifyContent='space-between'>
            <Text variant='H5' mr={2}>Total:</Text>
            <Text variant='H3' color='primary_active'>{calc.total}</Text>
          </Box>
        </Col>
      </Row>
    </TicketDetailStyled>
  );
};

export default TicketDetail;
type TicketDetailStyledProps = {}
const TicketDetailStyled = styled.div<TicketDetailStyledProps>`
.btn-back {
  position: fixed;
  top: 0.5rem;
  right: 2.5rem;
}
  padding-right: 5vw;
  .items {
    .border-dotted {
      border: 1px dotted #86909C;
    }
    .item {
      margin-bottom: 1rem;
    }
    .service {
      margin-bottom: 0.5rem;
    }
  }
  .border {
    border: 0.75px solid #86909C;
  }
  .box-info {
    margin-bottom: 2rem;
    display: flex;
    justify-content: space-between;
    .ticket-number-box {
      background: #1D2129;
      border-radius: 4px;
      display: inline-flex;
      padding: 4px 8px;
      .H5 {
        color: #fff;
      }
    }
    .customer-box {
      text-align: right;
    }
  }
  .group-info {
    .item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }
  }
`;
