import { IMask } from 'antd-mask-input';
import { ButtonSize } from 'antd/es/button';
import { typeIcon } from 'assets/Icons';
import Button from 'components/Button';
import { BUTTON_SIZES } from 'constants/size';
import { useLayoutEffect, useMemo, useRef } from 'react';
import { styled } from 'styled-components';
import { IKeyboardType } from './helper';
const FORMAT = '(000) 000-0000';

export const PIN_LENGTH = 4;
const masked = IMask.createMask({
    mask: FORMAT,
    // ...and other options
});

export type InputViewProps = {
    className?: string;
    size?: ButtonSize;
    leading?: {
        icon: typeIcon;
        onClick: () => void;
    };
    trailing?: {
        icon: typeIcon;
        onClick: () => void;
    };
    value?: string;
    type?: IKeyboardType;
    placeholder?: string;
};

const InputKeyboardView = (props: InputViewProps) => {
    const { trailing, value = '', leading, className, type, placeholder } = props;
    const boxInput = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
        boxInput.current?.scrollTo({ left: boxInput.current?.scrollWidth });
    }, [props.value]);

    const inputValueView = useMemo(() => {
        switch (type) {
            case 'PIN-PASSWORD':
                return value ? ''.padStart(value.length, '*') : placeholder;
            case 'CURRENCY':
                return `${value ? value : '$0.00'}`;
            case 'PHONE':
            default:
                return value ? masked.resolve(value) : FORMAT;
        }
    }, [value, type, placeholder]);

    return (
        <InputKeyboardViewStyled className={`${className ?? ''}`} {...props}>
            {leading && (
                <Button
                    ntype="SECONDARY"
                    icon={leading.icon}
                    className="suffixEnd"
                    onClick={leading.onClick}
                />
            )}
            <div className="wrap-box" ref={boxInput}>
                <div className={`box ${!value ? 'placeholder' : ''}`}>
                    {inputValueView}
                </div>
            </div>
            {trailing && (
                <Button
                    ntype="SECONDARY"
                    icon={trailing.icon}
                    className="suffixEnd"
                    onClick={trailing.onClick}
                />
            )}
        </InputKeyboardViewStyled>
    );
};
export default InputKeyboardView;

const InputKeyboardViewStyled = styled.div.withConfig({
    shouldForwardProp: (prop) => !['leading', 'trailing'].includes(prop),
}) <InputViewProps>`
  display: flex;
  width: 100%;
  height: ${(props) =>
        props.size ? BUTTON_SIZES[props.size] : BUTTON_SIZES.middle};
  overflow: hidden;
  align-items: center;
  margin-bottom: 1rem;
  gap: 16px;
  flex-shrink: 0;
  border-radius: 5px;
  border: 1px solid var(--primary-primary-hover, #f5767f);
  background: var(--fill-fill-0, #fff);
  box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
    0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  .wrap-box {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .box {
    margin: 8px 0;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @keyframes fadeInOut {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    &:after {
      opacity: 0;
      content: "";
      border-radius: 5px;
      border: 1px solid var(--text-text-3, #1d2129);
      animation: fadeInOut 1.25s infinite;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      align-self: stretch;
    }
    &.placeholder {
      opacity: 0.5;
      &:after {
        opacity: 0;
      }
    }

    color: var(--text-text-3, #1D2129);
    text-align: center;

    /* Title/H5 - 24 semibold */
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  button.suffixEnd {
    border: 1px solid #e5e6eb;
    background: var(--fill-fill-5, #e5e6eb);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100% !important;
    border-radius: 0px;
    gap: 0;
  }
`;