import { Layout } from 'antd';
import Sidebar from 'components/layout/Sidebar';
import appointmentActions from 'features/appointment/services/actions';
import React, { useEffect } from 'react';
import { useAppDispatch } from 'store/hooks';
import AppointmentCalendar from './components/Calendar';
import AppointmentsPageStyled from './styles';
import ModalAnybody from './components/ModalAnybody';
import shopSelectors from 'services/shop/selectors';
import AppointmentCalendarV2 from './components/Calendar/v2';

type IAppointmentsPageProps = any;
const AppointmentsPage: React.FC<IAppointmentsPageProps> = () => {
  const versionUI = shopSelectors.data.versionUI();
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(appointmentActions.initData());
  }, []);

  if (versionUI === 'V2') {
    return (
      <AppointmentsPageStyled>
        <Layout>
          <AppointmentCalendarV2 />
        </Layout>
        <ModalAnybody />
      </AppointmentsPageStyled>
    );
  }

  return (
    <AppointmentsPageStyled>
      <Sidebar />
      <Layout>
        <AppointmentCalendar />
      </Layout>
      <ModalAnybody />
    </AppointmentsPageStyled>
  );
};

export default AppointmentsPage;

