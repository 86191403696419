import React, { useState } from 'react';
import TabUI, { TabUIContainer } from './TabUI';
import styled from 'styled-components';
import { Tabs, TabsProps } from 'antd';
import { IApiGetTicketsParam, ParamsStatus } from 'features/ticketList/services/types/api';
import { useAppDispatch } from 'store/hooks';
import ticketListActions from 'features/ticketList/services/actions';
import OpenTickets from './OpenTickets';
import CloseTickets from './CloseTickets';
import VoidedTickets from './VoidedTickets';
import RefundTickets from './RefundTickets';
import DeletedTickets from './DeletedTickets';

const items: TabsProps['items'] = [
  {
    key: 'OPEN_TICKET',
    label: 'OPEN TICKETS',
    children: <OpenTickets />,
  },
  {
    key: 'CLOSED',
    label: 'CLOSED TICKETS',
    children: <CloseTickets />,
  },
  {
    key: 'VOID',
    label: 'VOIDED',
    children: <VoidedTickets />,
  },
  {
    key: 'REFUND',
    label: 'REFUNDED',
    children: <RefundTickets />,
  },
  {
    key: 'DELETE',
    label: 'DELETED',
    children: <DeletedTickets />,
  },
];

const TabsV2 = () => {
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState('CLOSED');
  const onChange = (key: string) => {
    setTab(key);
    const params: IApiGetTicketsParam = {
      status: key as keyof typeof ParamsStatus,
      page: 1,
      staffId: '',
    };
    dispatch(ticketListActions.setTicketsParams(params));
  };

  return (
    <>
      <TabUIContainer>
        <TabUI first zIndex={5} tab={tab} onChange={onChange} type='OPEN_TICKET' />
        <TabUI zIndex={4} tab={tab} onChange={onChange} type='CLOSED' />
        <TabUI zIndex={3} tab={tab} onChange={onChange} type='VOID' />
        <TabUI zIndex={2} tab={tab} onChange={onChange} type='REFUND' />
        <TabUI zIndex={1} tab={tab} onChange={onChange} type='DELETE' />
      </TabUIContainer>
      <TicketOnTabsStyled>
        <Tabs renderTabBar={() => <></>} items={items} activeKey={tab} defaultActiveKey="CLOSED" onChange={onChange} />
      </TicketOnTabsStyled>
    </>
  );
};

export default TabsV2;
const TicketOnTabsStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background: #f5f5f5;
  padding: 16px;
  .ant-tabs {
    flex: 1;
    .ant-tabs-content-holder {
      flex: 1;
      .ant-tabs-content {
        height: 100%;
        .ant-tabs-tabpane {
          height: 100%;
          position: relative;
        }
      }
    }
  }
`;
