import fetch from 'services/request';
import { IEditReviewReportPayload, IParamsReviewReport } from './types/review';

// const baseURL = 'api/v1/:merchant_code/demoData';

export const getDemoData = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ data: { data: [] } });
    }, 1000);
  });
  // return fetch({
  //   method: 'get',
  //   url: `${baseURL}/getDemoData`,
  //   params: params as any
  // });
};

export const getPrintSummarySalon = (params: { startDate: string, endDate: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/print-report/summary-salon',
    params
  });
};

const getPrintSummaryPaymentTypes = (params: { startDate: string, endDate: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/print-report/summary-payment-type',
    params
  });
};

export const getSummaryTicket = (params: { startDate: string, endDate: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/report/summary-ticket',
    params
  });
};

export const getSummarySalon = (params: { startDate: string, endDate: string }) => {
  return fetch({
    method: 'get',
    url: 'api/v1/report/summary-salon',
    params
  });
};

export const getSummaryServiceCategory = (params: { startDate: string, endDate: string }) => {
  return fetch({
    method: 'get',
    url: 'api/v1/report/summary-service-category',
    params
  });
};

export const getTopRankingService = (params: { startDate: string, endDate: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/report/top-ranking-service',
    params
  });
};

export const getSummarySalaryStaff = (params: { startDate: string, endDate: string, salaryType?: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/report/summary-salary-staff',
    params
  });
};

export const getSummaryDashboardSalaryStaff = (params: { startDate: string, endDate: string, salaryType?: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/report/summary-dashboard-salary-staff',
    params
  });
};

export const getSalaryStaffDetail = (params: { startDate: string, endDate: string, staffId: string, paymentType?: string }) => {
  return fetch({
    method: 'get',
    url: `/api/v1/report/salary-staff-detail/${params.staffId}`,
    params: {
      startDate: params.startDate,
      endDate: params.endDate,
      paymentType: params?.paymentType || undefined,
    }
  });
};

export const getPrintSummaryServiceCategory = (params: { startDate: string, endDate: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/print-report/summary-service-category',
    params
  });
};


export const getDashboardTimeClockReport = (params: { startDate: string, endDate: string, staffId?: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/report/dashboard-time-clock-report',
    params
  });
};

export const getTimeClockReportHistory = (params: { startDate: string, endDate: string, staffId?: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/report/time-clock-report-history',
    params
  });
};

export const getPrintSalaryStaffDetailOneDay = (params: { startDate: string, endDate: string, staffId: string }) => {
  return fetch({
    method: 'get',
    url: `/api/v1/print-report/salary-staff-detail-one-day/${params.staffId}`,
    params
  });
};

export const getPrintSalaryStaffDetailRangeDate = (params: { startDate: string, endDate: string, staffId: string }) => {
  return fetch({
    method: 'get',
    url: `/api/v1/print-report/salary-staff-detail-date-range/${params.staffId}`,
    params
  });
};

export const getPrintSalarySummaryStaffOneDay = (params: { startDate: string, endDate: string, salaryType?: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/print-report/salary-summary-staff-one-day',
    params
  });
};

export const getPrintSalarySummaryStaffRangeDate = (params: { startDate: string, endDate: string, salaryType?: string }) => {
  return fetch({
    method: 'get',
    url: '/api/v1/print-report/salary-summary-staff-range-date',
    params
  });
};

const getReviewReport = (params: IParamsReviewReport) => {
  return fetch({
    method: 'get',
    url: '/api/v1/review',
    params
  });
};

const editReviewReviewReport = (body: IEditReviewReportPayload) => {
  return fetch({
    method: 'post',
    url: '/api/v1/review',
    body,
    autoToast: true
  });
};

const getReviewReportSummary = (params: IParamsReviewReport) => {
  return fetch({
    method: 'get',
    url: '/api/v1/review/summary-rating',
    params
  });
};

const reportApis = {
  getDemoData,
  getPrintSummarySalon,
  getSummarySalon,
  getSummaryServiceCategory,
  getSummaryTicket,
  getTopRankingService,
  getSummarySalaryStaff,
  getSummaryDashboardSalaryStaff,
  getSalaryStaffDetail,
  getPrintSummaryServiceCategory,
  getDashboardTimeClockReport,
  getTimeClockReportHistory,
  getPrintSalaryStaffDetailOneDay,
  getPrintSalaryStaffDetailRangeDate,
  getPrintSalarySummaryStaffOneDay,
  getPrintSalarySummaryStaffRangeDate,
  getReviewReport,
  editReviewReviewReport,
  getReviewReportSummary,
  getPrintSummaryPaymentTypes,
};

export default reportApis;
