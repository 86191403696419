import CurrencyInput, {
  unmaskedCurrency,
  useCurrencyInputRef,
} from 'components/CurrencyInputMasked';
import { toCurrency } from 'components/CurrencyInputMasked/currency-conversion';
import Text from 'components/Text';
import { debounce } from 'lodash';
import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { styled } from 'styled-components';
import { isMobileBrowser } from 'utils/detachBehavior';
import InputKeyboardView, { InputViewProps } from '../InputKeyboardView';
import NumberPad from '../NumberPad';
import NumberPadV2 from './NumberPadV2';
import clsx from 'clsx';

const isMobile = isMobileBrowser();

export interface ICurrencyKeyBoard {
  value?: number | null;
  onChange?: (value: ICurrencyKeyBoard['value']) => void;
  disableInput?: boolean;
  inputViewProps?: InputViewProps;
  inputClassName?: string;
  smallInput?: boolean;
  v2?: boolean;
  tipV2?: boolean;
  FooterContent?: () => any;
  isHiddenKB?: boolean;
  onFocus?: () => void;
}

export const useCurrencyKeyBoardRef = () => useRef<CurrencyKeyBoardRef>(null);

export type CurrencyKeyBoardRef = {
  showError: (text: string) => void;
  hideError: () => void;
  clear: () => void;
  setValue: (val: string, unFocus?: boolean) => void;
};
const inputPlaceholder = '$0.00';
const CurrencyKeyBoard = forwardRef<CurrencyKeyBoardRef, ICurrencyKeyBoard>(
  (
    { value, disableInput, onChange, inputViewProps = {}, inputClassName = '', smallInput, v2, tipV2, FooterContent, isHiddenKB = false, onFocus },
    ref
  ) => {
    const currencyInputRef = useCurrencyInputRef();
    const [text, setText] = useState<string>();
    const [error, setError] = useState<string | undefined>();

    useImperativeHandle(ref, () => ({
      showError: (text: string) => setError(text),
      hideError: () => setError(''),
      clear: () => onNumberPadChange(''),
      setValue: (_text: string, unFocus?: boolean) => onNumberPadChange(_text, unFocus),
    }));

    const _debounce = useCallback(
      debounce(
        (inputValue: ICurrencyKeyBoard['value']) =>
          typeof onChange === 'function' && onChange(inputValue),
        200
      ),
      [onChange]
    );

    const onNumberPadChange = (val: string, unFocus?: boolean) => {
      if (isMobile) {
        const _val = toCurrency(val);
        setText(_val);
        _debounce(unmaskedCurrency(_val));
        return;
      }
      currencyInputRef.current?.setValue(val, unFocus);
    };

    const onWebInputChange = (
      val: number | null | undefined,
      format: string
    ) => {
      setText(format);
      if (typeof onChange === 'function') onChange(val || null);
    };

    return (
      <CurrencyKeyBoardStyled className={clsx(tipV2 && 'tipV2')}>
        {!disableInput && (
          v2 ? <InputViewV2Styled>
            <p className='enter-amount-text'>Enter amount</p>
            <CurrencyInput
              className={'CurrencyInput'}
              ref={currencyInputRef}
              defaultValue={value}
              onChangeDebounce={onWebInputChange}
              inputMode='none'
              onFocus={onFocus}
            />
            {FooterContent && <FooterContent />}
          </InputViewV2Styled> :
            <InputViewStyled smallInput={smallInput} className='InputViewStyled'>
              {isMobile ? (
                <>
                  <InputKeyboardView
                    className={`${inputClassName ?? ''}`}
                    value={text}
                    {...inputViewProps}
                    type={'CURRENCY'}
                    placeholder={inputPlaceholder}
                  />
                </>
              ) : (
                <>
                  <CurrencyInput
                    ref={currencyInputRef}
                    defaultValue={value}
                    onChangeDebounce={onWebInputChange}
                  />
                </>
              )}
            </InputViewStyled>
        )}
        {error && (
          <Text
            textAlign="center"
            variant="H5"
            mt={0.5}
            style={{ color: '#f53f3f' }}
          >
            {error}
          </Text>
        )}
        {!v2 && <Text mb={1} />}
        {v2 ? <NumberPadV2 hidden={isHiddenKB} value={text} onChange={onNumberPadChange} type={'NUMBER'} /> : <NumberPad value={text} onChange={onNumberPadChange} type={'NUMBER'} />}
      </CurrencyKeyBoardStyled>
    );
  }
);
CurrencyKeyBoard.displayName = 'CurrencyKeyBoard';
export default CurrencyKeyBoard;

const CurrencyKeyBoardStyled = styled.div`
  display: flex;
  flex-direction: column;

  &.tipV2 {
    .enter-amount-text {
      display: none;
    }

    .CurrencyInput {
      color: #D2464F;
    }
  }

`;
const InputViewV2Styled = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 16px 0;
  .enter-amount-text {
    color: #64748B;
    text-align: center;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  input {
    flex: 1;
    color: #1D2129;
    text-align: center;
    font-family: Poppins;
    font-size: 55px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    outline: none;
  }
`;
const InputViewStyled = styled.div<{ smallInput?: boolean }>`
  width: 100%;
  display: flex;
  input {
    flex: 1;
    border-radius: 2px;
    border: 1px solid #86909c;
    background: var(--fill-fill-0, #fff);
    box-shadow: -1px 1px 4px 0px rgba(0, 0, 0, 0.1) inset,
      0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    color: var(--text-text-3, #1d2129);
    text-align: center;
    font-family: Open Sans;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    height: 4.5rem;

    &:focus,
    &:hover {
      outline: none;
      border: 1px solid var(--primary-primary-hover, #f5767f);
    }

    ${({ smallInput }) => smallInput ? `
      height: 3rem;
    `: ''}
  }
`;
