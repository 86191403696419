import type { Active, UniqueIdentifier } from '@dnd-kit/core';
import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates
} from '@dnd-kit/sortable';
import type { ReactNode } from 'react';
import React, { useMemo, useState } from 'react';

import './SortableList.css';
import { SortableOverlay } from './SortableOverlay';
import SortableItem from './SortableItem';


interface BaseItem {
  id: UniqueIdentifier;
}

interface Props<T extends BaseItem> {
  items: T[];
  onChange(items: T[]): void;
  renderItem(item: T, i: number): ReactNode;
  useGrid?: boolean;
  delayClick?: number;
}

export function SortableList<T extends BaseItem>({
  items,
  onChange,
  renderItem,
  useGrid,
  delayClick,
}: Props<T>) {
  const [active, setActive] = useState<Active | null>(null);
  const activeItem = useMemo(
    () => items.find((item) => item.id === active?.id),
    [active, items]
  );

  const sensors = useSensors(
    !delayClick ? useSensor(PointerSensor) : useSensor(PointerSensor, { activationConstraint: { distance: delayClick } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  return (
    <DndContext
      sensors={sensors}
      onDragStart={({ active }) => {
        setActive(active);
      }}
      onDragEnd={({ active, over }) => {
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id);
          const overIndex = items.findIndex(({ id }) => id === over.id);

          onChange(arrayMove(items, activeIndex, overIndex));
        }
        setActive(null);
      }}
      onDragCancel={() => {
        setActive(null);
      }}
    >
      <SortableContext items={items}>
        {useGrid ? <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr', gap: 16 }}>
          {items.map((item, i) => (
            <React.Fragment key={item.id}>{renderItem(item, i)}</React.Fragment>
          ))}
        </div> :
          <ul className="SortableList" role="application">
            {items.map((item, i) => (
              <React.Fragment key={item.id}>{renderItem(item, i)}</React.Fragment>
            ))}
          </ul>
        }
      </SortableContext>
      <SortableOverlay>
        {activeItem ? renderItem(activeItem, 0) : null}
      </SortableOverlay>
    </DndContext >
  );
}

SortableList.Item = SortableItem;
