
export enum TypePaymentPassData {
    PASS_DATA = 'PASS_DATA',
    COMPLETED_PAYMENT = 'COMPLETED_PAYMENT',
    COMPLETED_PAYMENT_WITHOUT_RECEIPT = 'COMPLETED_PAYMENT_WITHOUT_RECEIPT',
    REQUEST_SIGNATURE = 'REQUEST_SIGNATURE',
    REQUEST_SIGNATURE_ONLY = 'REQUEST_SIGNATURE_ONLY',

    REQUEST_SIGNATURE_CHECK = 'REQUEST_SIGNATURE_CHECK',
    SEND_INFO_SIGNATURE_CHECK = 'SEND_INFO_SIGNATURE_CHECK',
    CASHIER_FORCE_CLOSE_SIGNATURE_CHECK = 'CASHIER_FORCE_CLOSE_SIGNATURE_CHECK',

    CUSTOMER_RECEIVE_CONFIGS = 'CUSTOMER_RECEIVE_CONFIGS',

    TIP_PAYMENT_SEND_TIP_AFTER_TYPING = 'TIP_PAYMENT_SEND_TIP_AFTER_TYPING',
    PAYMENT_QR_SEND_TIP_AFTER_TYPING = 'PAYMENT_QR_SEND_TIP_AFTER_TYPING',
    REFRESH_DETAIL_DATA_AFTER_UPDATE_TIP = 'REFRESH_DETAIL_DATA_AFTER_UPDATE_TIP',

    TIP_BEFORE_PAYMENT_REQUEST_TIP = 'TIP_BEFORE_PAYMENT_REQUEST_TIP',
    TIP_BEFORE_PAYMENT_REQUEST_TIP_MULTI_PAY = 'TIP_BEFORE_PAYMENT_REQUEST_TIP_MULTI_PAY',
    TIP_BEFORE_PAYMENT_SEND_TIP_VALUE = 'TIP_BEFORE_PAYMENT_SEND_TIP_VALUE',
    TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING = 'TIP_BEFORE_PAYMENT_SEND_TIP_AFTER_TYPING',
    TIP_BEFORE_PAYMENT_REQUEST_SIGNATURE = 'TIP_BEFORE_PAYMENT_REQUEST_SIGNATURE',
    TIP_BEFORE_PAYMENT_REQUEST_CANCEL = 'TIP_BEFORE_PAYMENT_REQUEST_CANCEL',

    ON_CUSTOMER_SIGNATURE_CHANGE = 'ON_CUSTOMER_SIGNATURE_CHANGE',

    CREDIT_CARD_PAYMENT_PROCESSING = 'CREDIT_CARD_PAYMENT_PROCESSING',
    CREDIT_CARD_PAYMENT_DONE = 'CREDIT_CARD_PAYMENT_DONE',

    QR_MULTIPLE_PAYMENT_REQUEST_VISIBLE = 'QR_MULTIPLE_PAYMENT_REQUEST_VISIBLE',
    QR_MULTIPLE_PAYMENT_DONE = 'QR_MULTIPLE_PAYMENT_DONE',
}

const multiCardPrefix = 'MULTI_CARD_PAYMENT_';
export const MultipleCardTypePassData = {
    // Multiple cards
    REFRESH_DETAIL: multiCardPrefix + 'REFRESH_DETAIL',
    ON_TIP: multiCardPrefix + 'ON_TIP',
    REQUEST_TIP: multiCardPrefix + 'REQUEST_TIP',
    PASS_TIP_VALUE_ON_TYPING: multiCardPrefix + 'PASS_TIP_VALUE_ON_TYPING',


    // Signature
    PAYMENT_PROCESSING: multiCardPrefix + 'PAYMENT_PROCESSING',
    PAYMENT_PROCESSING_DONE: multiCardPrefix + 'PAYMENT_PROCESSING_DONE',
    REQUEST_SIGNATURE: multiCardPrefix + 'REQUEST_SIGNATURE',
    UPDATE_SIGNATURE_COMPLETED: multiCardPrefix + 'UPDATE_SIGNATURE_COMPLETED',
    PAYMENT_COMPLETED: multiCardPrefix + 'PAYMENT_COMPLETED',
    
    ON_CUSTOMER_SIGNATURE_CHANGE: multiCardPrefix + 'ON_CUSTOMER_SIGNATURE_CHANGE',
};

export enum TypePaymentActors {
    CASHIER = 'CASHIER',
    CUSTOMER = 'CUSTOMER',
}

export type PaymentSocketData<Modal = TypePaymentPassData> = {
    shopId: string;
    billId: string;
    action?: Modal,
    actor: TypePaymentActors,
    data?: any;
};

export type ICustomerReceiveConfigs = {
    creditCard?: boolean;
    enableCardFee?: boolean;
    enableTipBeforeMode?: boolean;
    enableCashIncentive?: boolean;
}