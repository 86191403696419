import { first, minBy, some, sortBy } from 'lodash';
import { IStaffTurnItem } from '../TurnTable/TurnStaffTopRow';
import { ITurnManualItem } from 'features/turn/services/types/turn';
import { TURN_ITEM_STATUS } from 'features/turn/services/constants';

export const getStaffNextTurn = (_staffs: IStaffTurnItem[]): (IStaffTurnItem | null) => {
  const staffs = sortBy(_staffs, 'indexClockIn');
  const staffFilter = staffs.filter(o => !some(o.turns, (o: ITurnManualItem) => o.status === TURN_ITEM_STATUS.IN_PROGRESS));
  return minBy(staffFilter, 'totalTurn') || first(staffFilter) || null;
};
