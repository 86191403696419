import { Avatar } from 'antd';
import Box from 'components/Box';
import Modal from 'components/Modal';
import checkInActions from 'features/checkIn/services/actions';
import checkInSelectors from 'features/checkIn/services/selectors';
import { useEffect, useMemo, useRef } from 'react';
import { SHOP_DEVICE_TYPE } from 'services/shop/constants';
import { IBanCustomerData } from 'services/shop/types/ban';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import { formatPhone } from 'utils/formatPhone';
import storage from 'utils/sessionStorage';
import audioSound from './notification-sound.wav';

const WarningCustomerBanPopup = () => {
  const audioPlayer = useRef<HTMLAudioElement>(null);
  const dispatch = useAppDispatch();
  const warningCustomerBanPopup = checkInSelectors.warningBanCustomerMessage();
  const handleClose = () => dispatch(checkInActions.setWarningBanCustomerMessage(null));
  const data: IBanCustomerData = useMemo(() => {
    if (!warningCustomerBanPopup) return null;
    return JSON.parse(warningCustomerBanPopup);
  }, [warningCustomerBanPopup]);

  const countRef = useRef<number>(0);

  const playSound = async () => {
    countRef.current++;
    try {
      await audioPlayer?.current?.play();
    } catch (error) { }
  };

  const onSoundEnd = () => {
    if (countRef.current === 3) {
      countRef.current = 0;
      return;
    }
    playSound();
  };

  useEffect(() => {
    if (!warningCustomerBanPopup) return;
    playSound();
  }, [warningCustomerBanPopup]);

  const Images = () => {
    if (!data) return null;
    const images: string[] = data?.images || [];
    const length = images?.length || 0;
    if (length == 0) return null;

    switch (length) {
      case 1: {
        const item = images[0];
        return (
          <div
            className="detail-card-info-images" style={{ gridTemplateColumns: '1fr', height: '300px', width: '300px' }}>
            <div className="detail-card-info-image-item" style={{ width: '100%', height: '100%' }}>
              <img src={item} />
            </div>
          </div>
        );
      }
      case 2: {
        const item = images[0];
        const item2 = images[1];
        return (
          <div
            className="detail-card-info-images" style={{ gridTemplateColumns: '1fr', width: '300px', height: 'auto' }}>
            <div
              className="detail-card-info-image-item" style={{ width: '100%', height: '200px' }}>
              <img src={item} />
            </div>
            <div
              className="detail-card-info-image-item" style={{ width: '100%', height: '200px' }}>
              <img src={item2} />
            </div>
          </div>
        );
      }
      case 3: {
        const item = images[0];
        const item2 = images[1];
        const item3 = images[2];
        return (
          <div
            className="detail-card-info-images" style={{ gridTemplateColumns: '1fr 1fr', width: '400px', height: 'auto' }}>
            <div
              className="detail-card-info-image-item" style={{ width: '100%', height: '200px' }}>
              <img src={item} />
            </div>
            <div
              className="detail-card-info-image-item" style={{ width: '100%', height: '200px' }}>
              <img src={item2} />
            </div>
            <div
              className="detail-card-info-image-item" style={{ width: '100%', height: '200px' }}>
              <img src={item3} />
            </div>
          </div>
        );
      }
      default:
        return (
          <div className="detail-card-info-images"
            style={{ gridTemplateColumns: '1fr 1fr', width: '400px', height: 'auto' }}
          >
            {images?.slice(0, 4).map((o, i) => {
              return (
                <div
                  key={i} className="detail-card-info-image-item"
                  style={{ width: '100%', height: '200px' }}
                >
                  <img src={o} />
                  {(i === 3 && images?.length - 4 > 0) ? <div className='detail-card-info-image-overlay'><span>{images?.length - 4}+</span></div> : null}
                </div>
              );
            })}
          </div>
        );
    }
  };

  if (storage.device_type.get() !== SHOP_DEVICE_TYPE.POS) return null;

  if (!warningCustomerBanPopup) return null;

  return (
    <>
      <Modal visible
        noneBodyStyle
        noneFooterStyle
        hiddenFooter
        hiddenHeader
        showClose
        className='ant-modal-custom-warning modal-overflow-unset modal-max-height-unset'
        onClose={handleClose}
      >
        <Styled>
          <DetailCardHeader style={{ background: '#fff', padding: '1rem', justifyContent: 'center', borderBottom: '1px solid #C9CDD4' }}>
            <div className='account-info' style={{ flexDirection: 'row' }}>
              <span ><WarningIcon fill='#FF7D00' /> <span className='warning-title'>WARNING!</span></span>
              <div className='account-name' style={{ color: '#1D2129', textAlign: 'center', fontSize: '22px' }}>
                This is a Ban Customer</div>
            </div>
          </DetailCardHeader>
          <DetailCardStyled >
            {/* detail-card-header */}
            <DetailCardHeader style={{ flexDirection: 'column', alignItems: 'start' }}>
              <Box display='flex' width={'100%'}>
                <div className='title-section' style={{ flex: 1 }}>Reporter</div>
                <div className='detail-time-info'>
                  <div className="createdDate" style={{ fontSize: '20px' }}>{data?.createdDate}</div>
                </div>
              </Box>
              <div className='account-info'>
                <div>
                  <Avatar size={90} src={data?.avatar || 'fail'} >
                    <UserIcon />
                  </Avatar>
                </div>
                <div className='account-name'>{data?.userName ? data?.userName : '--'}</div>
              </div>

              <div className="business-box">
                <div className="business-name-row">
                  <IconBusiness />
                  <span>{data?.businessName || '--'}</span>
                </div>
                <div className="business-address">
                  {[data?.address, data?.city, data?.state, data?.zip].filter(o => !!o).join(', ')}
                </div>
              </div>
            </DetailCardHeader>

            {/* detail-card-info */}
            <div style={{ flex: 1, flexDirection: 'column', display: 'flex' }}>
              <div className='title-section'>Customer Ban:</div>
              <DetailCardInfo>
                <div className="detail-card-info-content">
                  <Box display='flex' alignItems='center' style={{ gap: 4 }}>
                    <div className="customerName">{data?.customerName}</div>
                    <div className="customerPhone">{formatPhone(data?.customerPhone)}</div>
                  </Box>
                  <div className="blog-content">
                    <WarningIcon />
                    <span>
                      Note: {data?.note}
                    </span>
                  </div>
                </div>
                <Images />
              </DetailCardInfo>
            </div>
          </DetailCardStyled>
        </Styled>
      </Modal>
      <audio ref={audioPlayer} src={audioSound} onEnded={onSoundEnd} />
    </>
  );
};

export default WarningCustomerBanPopup;

const Styled = styled.div`
  min-height: 400px;
  display: flex;
  flex-direction: column;
  padding-bottom: 1.5rem;
  .title-section {
    color: #1D2129;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
`;

const DetailCardStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 24px;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  .business-box {
    display: flex;
    padding: 6px 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
    border-radius: 5px;
    background: #E4E7FF;
    margin-bottom: 24px;
    .business-name-row {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 4px;
      align-self: stretch;
      span {
        color: #476788;
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
    .business-address {
      align-self: stretch;
      color: #476788;
      text-align: center;
      font-family: Inter;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
const DetailCardHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  .account-info {
    display: flex;
    padding: 4px 12px 4px 4px;
    align-items: center;
    flex-direction: column;
    align-self: center;
    gap: 8px;
    border-radius: 100px;
    min-width: 208px;
    .ant-avatar {
      background: rgb(227, 233, 237);
      display: flex;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      .ant-avatar-string {
      }
    }
    .account-name {
      color: #1D2129;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      white-space: nowrap;
    }
    .warning-title {
      color: #FF7D00;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      white-space: nowrap;
    }
  }

  .detail-time-info {
    .createdDate, .businessName {
      color: #1D2129;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .address {
      color: #767676;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;
const DetailCardInfo = styled.div`
  display: flex;
  border-radius: 4px;
  gap: 16px;
  flex: 1;

  .detail-card-info-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #F53F3F;
    padding: 16px;
    border-radius: 4px;
    .customerName {
      color: #fff;
      background: #F53F3F;
      font-family: Inter;
      font-size: 22px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-transform: uppercase;
    }
    .customerPhone { 
      color: #fff;
      background: #F53F3F;
      font-family: Inter;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
    .blog-content {
      background: #F53F3F;
      display: flex;
      flex: 1;
      gap: 4px;
      color: #fff;
      span {
        flex: 1;
        padding-top: 4px;
      }
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      min-height: 70px;
    }
  }
  .detail-card-info-images {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    height: 132px;
    width: 132px;
    border-radius: 4px;
    background: #fff;
    .detail-card-info-image-item {
      width: 64px;
      height: 64px;
      display: flex;
      position: relative;
      border-radius: 4px;
      border: 1px dashed #DDDDDD;
      overflow: hidden;
      &:hover {
        cursor: pointer;
        opacity: 0.9;
      }
      img {
        width: 100%;
        height: 100%;
        flex-shrink: 0;
      }
      .detail-card-info-image-overlay {
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.60);
        display: flex;
        align-items: center;
        justify-content: center;
        span {
          color: #FFF;
          text-align: center;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
    }
  }
`;

const WarningIcon = ({ fill }: { fill?: string }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="22" viewBox="0 0 24 22" fill="none">
    <path d="M23.6763 18.084L14.0986 1.49414C13.667 0.746768 12.8629 0.282471 11.9998 0.282471C11.1367 0.282471 10.3333 0.746768 9.90209 1.49414L0.323648 18.0833C-0.107883 18.8306 -0.107883 19.7588 0.323648 20.5062C0.75518 21.2536 1.55899 21.7176 2.42168 21.7176H21.5783C22.441 21.7176 23.2455 21.2536 23.6764 20.5062C24.1079 19.7588 24.1079 18.8306 23.6763 18.084ZM12.0002 18.9893C11.172 18.9893 10.4996 18.3175 10.4996 17.4891C10.4996 16.6609 11.172 15.9892 12.0002 15.9892C12.8283 15.9892 13.5 16.6609 13.5 17.4891C13.5 18.3176 12.8283 18.9893 12.0002 18.9893ZM12.9244 14.188C12.9244 14.6985 12.5103 15.1119 12.0002 15.1119C11.4893 15.1119 11.0759 14.6985 11.0759 14.188L10.4996 7.4041C10.4996 6.57553 11.172 5.90386 12.0002 5.90386C12.8283 5.90386 13.5 6.57558 13.5 7.4041L12.9244 14.188Z" fill={fill || '#fff'} />
  </svg>
);

const UserIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M7.45843 0.425293H8.54425C9.57704 0.5783 10.5394 1.17215 11.1117 2.04928C11.4377 2.52497 11.6188 3.08435 11.7082 3.65017C11.8191 4.52352 11.6222 5.4374 11.1431 6.17819C10.5485 7.12274 9.4941 7.76128 8.38177 7.84915C8.02766 7.84346 7.67052 7.87376 7.31981 7.81317C6.56917 7.68326 5.86171 7.31514 5.32883 6.77052C4.66303 6.09601 4.28241 5.15979 4.26423 4.21448C4.22635 2.93551 4.89367 1.65806 5.98063 0.977102C6.42601 0.68851 6.93616 0.509371 7.45843 0.425293Z" fill="#8E9BA9" />
    <path d="M2.52431 9.83568C3.27078 8.99262 4.22253 8.33061 5.27388 7.92688C5.81622 8.27228 6.41916 8.5283 7.0524 8.64533C7.30009 8.70706 7.55535 8.73585 7.81099 8.73357C8.07572 8.73092 8.34197 8.74342 8.60519 8.71009C9.29978 8.60329 9.98906 8.3912 10.5825 8.00679C10.6435 7.97725 10.7083 7.91362 10.7802 7.94695C12.2766 8.53398 13.5491 9.65048 14.3528 11.0393C14.7213 11.6407 14.9811 12.3027 15.1818 12.9765C15.3507 13.5616 15.4341 14.1657 15.5003 14.7698C14.9784 14.8811 14.4607 15.0091 13.9354 15.1042C13.6192 15.1383 13.312 15.2292 12.995 15.2564C12.6663 15.2814 12.3459 15.3682 12.016 15.3833C11.4725 15.4257 10.9336 15.5166 10.3875 15.5261C10.1512 15.5537 9.90953 15.5181 9.67661 15.5746H6.32448C6.09156 15.5185 5.84955 15.5534 5.61322 15.5261C5.02998 15.5147 4.45431 15.4155 3.87334 15.3735C3.5821 15.3507 3.29654 15.2841 3.00567 15.2564C2.6883 15.2299 2.38115 15.1367 2.06453 15.1042C1.54037 15.0053 1.02037 14.886 0.5 14.7698C0.523102 14.4687 0.581048 14.1721 0.61627 13.8726C0.647704 13.6302 0.726859 13.3972 0.770034 13.1571C1.0893 11.9357 1.67633 10.7757 2.52431 9.83568ZM11.5627 10.3504C11.5581 10.9302 11.5631 11.5104 11.5604 12.0903C10.9802 12.0929 10.4004 12.0884 9.82015 12.0921C9.81788 12.4194 9.81788 12.747 9.82015 13.0742C10.3996 13.0818 10.9791 13.07 11.5581 13.0799C11.5665 13.6608 11.5559 14.2418 11.5634 14.8228C11.902 14.8326 12.2414 14.8319 12.5803 14.8232C12.5856 14.2668 12.5788 13.7101 12.5826 13.1537C12.575 13.0931 12.6496 13.0704 12.6974 13.0761C13.2393 13.0742 13.7809 13.0799 14.3229 13.0742C14.3255 12.7466 14.3255 12.4194 14.3229 12.0921C13.743 12.0884 13.1628 12.0925 12.5826 12.0906C12.58 11.5104 12.5845 10.9302 12.5807 10.35C12.2414 10.3477 11.902 10.3477 11.5627 10.3504Z" fill="#8E9BA9" />
    <path d="M14.375 11.75L14.75 14L13.25 14.75L11.75 15.125L10.625 14.75L9.125 13.625L8.375 11.75L10.625 9.5L12.875 9.875L14.375 11.75Z" fill="#8E9BA9" />
  </svg>
);
const IconBusiness = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="18" viewBox="0 0 19 18" fill="none">
    <path d="M11.75 6.1875V0.5625H1.625V17.4375H6.125V14.0625H7.25V17.4375H17.375V6.1875H11.75ZM4.4375 15.1875H3.3125V14.0625H4.4375V15.1875ZM4.4375 12.375H3.3125V11.25H4.4375V12.375ZM4.4375 9.5625H3.3125V8.4375H4.4375V9.5625ZM4.4375 6.75H3.3125V5.625H4.4375V6.75ZM4.4375 3.9375H3.3125V2.8125H4.4375V3.9375ZM8.9375 2.8125H10.0625V3.9375H8.9375V2.8125ZM7.25 12.375H6.125V11.25H7.25V12.375ZM7.25 9.5625H6.125V8.4375H7.25V9.5625ZM7.25 6.75H6.125V5.625H7.25V6.75ZM7.25 3.9375H6.125V2.8125H7.25V3.9375ZM10.0625 15.1875H8.9375V14.0625H10.0625V15.1875ZM10.0625 12.375H8.9375V11.25H10.0625V12.375ZM10.0625 9.5625H8.9375V8.4375H10.0625V9.5625ZM10.0625 6.75H8.9375V5.625H10.0625V6.75ZM16.25 16.3125H11.75V15.1875H12.875V14.0625H11.75V12.375H12.875V11.25H11.75V9.5625H12.875V8.4375H11.75V7.3125H16.25V16.3125Z" fill="#476788" />
    <path d="M14 14.0625H15.125V15.1875H14V14.0625ZM14 11.25H15.125V12.375H14V11.25ZM14 8.4375H15.125V9.5625H14V8.4375Z" fill="black" />
  </svg>
);
