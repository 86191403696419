import Modal from 'components/Modal';
import Text from 'components/Text';
import { SortableList } from 'features/settingService/components/SortableList';
import { turnManualAPIActions } from 'features/turn/services/actions';
import turnApis from 'features/turn/services/apis';
import { ITurnServiceItem } from 'features/turn/services/types/service';
import { flatten } from 'lodash';
import React, { useState } from 'react';
import { IResponseDataBody } from 'services/response';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import toast from 'utils/toast';
type Props = {
  turnsFull: ITurnServiceItem[];
  turnsHalf: ITurnServiceItem[];
};
const ModalSortServiceTurn = ({ turnsFull = [], turnsHalf = [] }: Props) => {
  const [open, setOpen] = useState(false);
  const setLoading = useSetLoadingPage();
  const [items, setItems] = useState<{ id: string, name: string, turn: number }[]>([]);
  const dispatch = useAppDispatch();

  const handleOpen = () => {
    const list = flatten([turnsFull, turnsHalf]);
    setItems(list.map(o => ({ id: o.itemId, name: o.itemName, turn: o.turn })));
    setOpen(true);
  };

  const handleAccept = async () => {
    setOpen(false);

    setLoading(true);
    const ids = items.map(o => o.id);
    try {
      const res: IResponseDataBody<boolean> = await turnApis.sortManualServicesTurn(ids);
      if (res.data.data) {
        toast.success('Reorder successful');
        dispatch(turnManualAPIActions.getTurnConfigServices.fetch());
      }
    } catch (error) { }
    finally {
      setLoading(false);
    }
  };
  return (
    <>
      <button type='button' className="btn btn-re-order" onClick={handleOpen}>
        <div className="icon">{IconReOrder}</div>
        <span>re order</span>
      </button>
      <Modal
        visible={open}
        onClose={() => setOpen(false)}
        onSubmit={handleAccept}
        modalTitle={'Reorder'}
        width={'1600px'}
        containerPadding={1}
        noneBodyStyle className='modal-non-opacity'
      >
        <SortableList
          items={items}
          useGrid
          onChange={setItems}
          renderItem={(item) => (
            <SortableList.Item id={item.id}>
              <CateWrap>
                <Text variant="H7" color="text_3" className='label'>
                  {item.name}
                </Text>
                <Text variant="CONTENT_1" color="text_2" className='value'>
                  Turn: {item.turn}
                </Text>
              </CateWrap>
            </SortableList.Item>
          )}
        />
      </Modal>

    </>
  );
};

export default ModalSortServiceTurn;
const CateWrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  height: 40px;
  justify-content: space-between;
  .label {
    font-size: 24px;
    font-weight: 600;
  }
  .value {
    font-size: 20px;
    font-weight: 400;
  }
`;
const IconReOrder = <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M4.7677 1.50049H11.2288C11.4908 1.53486 11.7486 1.62077 11.9591 1.77972C12.3285 2.05037 12.5476 2.50574 12.539 2.9611C12.539 4.16396 12.5433 5.37112 12.5347 6.57398C12.5218 7.29569 11.886 7.93149 11.1643 7.94008C9.13236 7.94867 7.09609 7.94008 5.05982 7.94438C4.91805 7.94438 4.77199 7.94438 4.63023 7.9186C3.99443 7.80691 3.48321 7.21837 3.47462 6.57398C3.47033 5.37112 3.47462 4.16826 3.47033 2.9654C3.46173 2.54869 3.64216 2.13199 3.95577 1.86135C4.17915 1.65514 4.47128 1.53915 4.7677 1.50049Z" fill="#1D2129" />
  <path d="M15.2798 3.00038C15.4431 2.80707 15.7094 2.69967 15.9586 2.7684C16.2936 2.84143 16.517 3.21518 16.4268 3.54596C16.3796 3.77365 16.1819 3.91971 16.0316 4.07866C17.5309 4.07866 19.0344 4.07866 20.5337 4.07866C20.8731 4.07436 21.1781 4.37937 21.1738 4.71875C21.1781 9.83091 21.1738 14.9474 21.1738 20.0595C21.1738 20.2142 21.1867 20.3774 21.1223 20.5192C21.0278 20.7554 20.7829 20.9144 20.5294 20.9144C18.5533 20.9187 16.5772 20.9144 14.5968 20.9144C14.4163 20.923 14.2273 20.8972 14.0812 20.7812C13.8407 20.6051 13.7505 20.2571 13.8879 19.9908C13.991 19.7717 14.223 19.6213 14.4679 19.6256C16.2722 19.6256 18.0765 19.6256 19.8807 19.6256C19.8807 14.87 19.8807 10.1187 19.8807 5.36314C18.5963 5.36314 17.3118 5.36314 16.0273 5.36314C16.1734 5.5178 16.3581 5.65097 16.4139 5.86577C16.517 6.17937 16.328 6.55311 16.0144 6.65192C15.7867 6.73784 15.5161 6.6734 15.3485 6.50586C14.9018 6.06338 14.4593 5.6166 14.0125 5.16983C13.7676 4.93355 13.7676 4.49536 14.0168 4.25909C14.4335 3.84238 14.8588 3.42568 15.2798 3.00038Z" fill="#1D2129" />
  <path d="M4.72465 9.28888C4.80627 9.28029 4.89219 9.27599 4.97811 9.27599C7.01438 9.27599 9.04635 9.27599 11.0826 9.27599C11.422 9.2717 11.77 9.38769 12.0277 9.61108C12.3456 9.87313 12.5389 10.2812 12.5389 10.6937C12.5432 11.8836 12.5389 13.0693 12.5389 14.2593C12.5561 14.8134 12.221 15.3547 11.7184 15.5824C11.4907 15.6941 11.2373 15.7285 10.9881 15.7199C8.93895 15.7156 6.89409 15.7242 4.84494 15.7156C4.12322 15.707 3.48313 15.0712 3.47454 14.3452C3.47024 13.1166 3.47024 11.8793 3.47454 10.6507C3.48313 9.96764 4.05019 9.35762 4.72465 9.28888Z" fill="#1D2129" />
  <path d="M3.47464 18.4223C3.48323 17.6834 4.15339 17.039 4.888 17.0519C6.98011 17.0519 9.07223 17.0476 11.1643 17.0562C11.8861 17.0647 12.5219 17.7048 12.5304 18.4266C12.539 19.6294 12.5304 20.8366 12.5347 22.0394C12.5433 22.4432 12.3715 22.8471 12.0708 23.122C11.8431 23.3368 11.5424 23.4571 11.2331 23.5H4.7806C4.46699 23.4614 4.16628 23.3368 3.9343 23.122C3.63358 22.8514 3.46175 22.4432 3.47034 22.0394C3.47464 20.8323 3.46604 19.6251 3.47464 18.4223ZM6.572 19.6423C6.24551 19.6981 6.00064 20.0375 6.05649 20.3683C6.09515 20.6733 6.38298 20.9225 6.69228 20.9225C7.56865 20.9225 8.44502 20.9225 9.3171 20.9225C9.67795 20.9268 9.99156 20.5874 9.95289 20.2308C9.93141 19.9044 9.63929 19.6294 9.3128 19.638C8.50946 19.638 7.71042 19.638 6.90708 19.638C6.79539 19.6294 6.68369 19.6251 6.572 19.6423Z" fill="#1D2129" />
</svg>
  ;
