import { Col, Row } from 'antd';
import cashierActions from 'features/cashier/services/actions';
import ConfirmTipTicket from 'features/payment/components/ConfirmTipTicket';
import React from 'react';
import shopSelectors from 'services/shop/selectors';
import { useAppDispatch } from 'store/hooks';
import styled from 'styled-components';
import CashierActionButton from '../CashierActionBtn';
import ButtonCalculator from './ButtonCalculator';
import ButtonCard from './ButtonCard';
import ButtonCash from './ButtonCash';
import ButtonCheckOthers from './ButtonCheckOthers';
import ButtonDiscountTicket from './ButtonDiscountTicket';
import ButtonGiftCard from './ButtonGiftCard';
import ButtonLoyaltyPoint from './ButtonLoyaltyPoint';
import ButtonMultiplePayment, { ButtonMultiplePaymentModal } from './ButtonMultiPayment';
import ButtonMultipleCard, { ButtonMultipleCardV2 } from './ButtonMultipleCard';
import ButtonSave from './ButtonSave';
import ButtonTip from './ButtonTip';
import ButtonVoidTicket from './ButtonVoidTicket';
import { completedTicketModalRef } from './footerHelpers';

type IComponentProps = {};
const TickerFooter: React.FC<IComponentProps> = () => {
  const dispatch = useAppDispatch();
  const allSetting = shopSelectors.data.allSetting();
  const v2UI = !!allSetting?.showUIStaffCashier;

  const clearTicket = () => {
    dispatch(cashierActions.clearTicket());
  };

  return (
    <ComponentStyled v2={v2UI}>
      <Row align={'middle'} justify={'start'} className='payment-actions'>
        <ButtonCalculator v2={v2UI} />
        <CashierActionButton
          label='Clear Ticket'
          ntype="LIGHT_BLUE"
          feature='CLEAR'
          v2UI={v2UI}
          onClick={clearTicket}
        />
        <ButtonDiscountTicket v2={v2UI} />
        <ButtonTip v2={v2UI} allSetting={allSetting} />
        <ButtonSave v2={v2UI} />
        <ButtonVoidTicket v2={v2UI} />
      </Row>
      <Col flex={'auto'} />
      <Row align={'middle'} justify={'end'} className='payment-actions'>
        <ButtonCard v2={v2UI} />
        <ButtonCash v2={v2UI} />
        {v2UI ? <ButtonMultiplePaymentModal v2 /> : <ButtonMultiplePayment />}
        {v2UI ? <ButtonMultipleCardV2 /> : <ButtonMultipleCard />}
        <ButtonCheckOthers v2={v2UI} />
        <ButtonGiftCard v2={v2UI} />
        <ButtonLoyaltyPoint v2={v2UI} />
      </Row>
      <ConfirmTipTicket ref={completedTicketModalRef} />
    </ComponentStyled>
  );
};

export default TickerFooter;
const ComponentStyled = styled.div<{ v2?: boolean }>`
  height: 6.375rem;
  background-color: #fff;
  background: #FFF;
  box-shadow: 0px -4px 20px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  .payment-actions {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  ${({ v2 }) => v2 && `
    gap: 0px;
    padding: 0px 24px;
    height: 140px;
    .payment-actions {
      gap: 0px;
      align-self: stretch;
      padding: 8px 0;
    }
  `}
`;
