import { AutoComplete, Col, Input, Row } from 'antd';
import { SearchIcon } from 'assets/Icons/AppointmentIcons';
import AvatarBox from 'components/AvatarBox';
import Modal from 'components/Modal';

import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import shopSelectors from 'services/shop/selectors';
import { IStaffItemData } from 'services/shop/types/staff';
import styled from 'styled-components';
import AddStaffForm from './AddStaffFormV2';
type Ref = {
  open: (cb: (staff: IStaffItemData) => void, filterIds?: string[]) => void;
  close: () => void;
}
export const useModalChooseStaffRef = () => useRef<Ref>(null);
type Props = {
  title?: string;
  staffs?: IStaffItemData[];
  anybody?: boolean;
  v2?: boolean;
};
const ModalChooseStaffRef = forwardRef<Ref, Props>(({ v2, anybody = false, title = 'SELECT STAFF', staffs: staffsProps }, ref) => {
  const [open, setOpen] = useState(false);
  const staffsStore = shopSelectors.data.staffs();
  const [textSearch, setTextSearch] = useState('');
  const [filterIds, setFilterIds] = useState<string[]>([]);
  const callBack = useRef<(staff: IStaffItemData) => void>(() => undefined);

  const staffs: IStaffItemData[] = useMemo(() => {
    const _staffStore = staffsProps || staffsStore;
    const data = anybody ? _staffStore : _staffStore.filter(o => !o.anyBody);
    if (!filterIds) return data;

    return data.filter(o => {
      if (filterIds.includes(o.id)) return false;
      return true;
    });
  }, [staffsStore, filterIds, staffsProps, anybody]);

  const staffOpts = useMemo(() => {
    return staffs.map(o => ({
      label: o.firstName,
      value: o.id,
    }));
  }, [staffs, filterIds]);

  const onSelect = (value: string) => {
    const staffItem = staffs.find((item) => item.id === value);
    if (!staffItem) return;
    callBack.current(staffItem);
    setOpen(false);
    setTextSearch('');
  };


  const staffFilter = useMemo(() => {
    return staffOpts.filter(o => o.label?.toLocaleLowerCase().includes(textSearch.toLocaleLowerCase()));
  }, [textSearch, staffOpts]);

  useImperativeHandle(ref, () => ({
    open: (cb, _filterIds) => {
      callBack.current = cb;
      setOpen(true);
      setFilterIds(_filterIds || []);
    },
    close: () => setOpen(false),
  }));

  if (v2) {
    return (
      <Modal
        v2
        visible={!!open}
        modalTitle='SELECT STAFF'
        noneBodyStyle
        hiddenOkButton
        onClose={() => setOpen(false)}
        width={1600}
      >
        <AddStaffForm
          onPickStaff={staff => onSelect(staff.id)}
        />
      </Modal>
    );
  }

  return (
    <Modal
      width={'70vw'}
      className='modal-add-new-appointment modal-max-height-unset'
      modalTitle={title}
      visible={open}
      hiddenOkButton
      noneBodyStyle
      containerPadding={1.5}
      onClose={() => setOpen(false)}
      showClose
      footer={<></>}
      hiddenFooter
    >
      <AutoComplete
        style={{ width: '100%' }}
        options={staffFilter}
        onSelect={onSelect}
        value={textSearch}
        onChange={setTextSearch}
      >
        <Input
          placeholder='Staff name'
          suffix={<SearchIcon />}
        />
      </AutoComplete>
      <ListContainer>
        <Row gutter={[16, 16]} justify="start">
          {staffs.map((item) => {
            return (
              <Col
                key={item.id}
                span={4}
                onClick={() => onSelect(item.id)}
              >
                <AvatarBox
                  src={item.urlImage}
                  name={item.firstName}
                />
              </Col>
            );
          })}
        </Row>
      </ListContainer>
    </Modal>
  );
});
ModalChooseStaffRef.displayName = 'ModalChooseStaffFilterRef';
export default ModalChooseStaffRef;

const ListContainer = styled.div`
  padding: 1.5rem 1rem;
  height: 60vh;
  overflow: auto;
  .staff-item {
    display:flex;
    flex-direction: column;
  }
`;
