import { Row } from 'antd';
import logo from './logo.svg';

const IconLogo = () => (
  <Row justify={'center'}>
    <img src={logo} style={{ width: 76, height: 'auto' }} />
  </Row>
);

export default IconLogo;
