import Modal from 'components/Modal';
import NumberKeyBoard, { NumberKeyBoardRef } from 'components/NumberKeyBoard';
import userActions from 'features/user/services/actions';
import userApis from 'features/user/services/apis';
import { IClockInOutResData } from 'features/user/services/types/clock-in-out';
import debounce from 'lodash/debounce';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState, useTransition } from 'react';
import { useSetLoadingPage } from 'services/UI/LoadingPage';
import { IResponseDataBody } from 'services/response';
import shopActions from 'services/shop/actions';
import { IStaffAvailableItem } from 'services/shop/types/staff';
import { useAppDispatch } from 'store/hooks';
type Props = {
  isClockIn: boolean;
  onPickStaff: (staff: IStaffAvailableItem) => void;
  onClose: () => void;
};
export type ModalClockInOutRef = {
  open: () => void;
  close: () => void;
};
const ModalClockInOut = forwardRef<ModalClockInOutRef, Props>(({ isClockIn, onPickStaff, onClose }, ref) => {
  const [visible, setVisible] = useState(true);
  const open = () => setVisible(true);
  const [pin, setPin] = useState('');
  const dispatch = useAppDispatch();
  const kbRef = useRef<NumberKeyBoardRef>(null);
  const setLoadingPage = useSetLoadingPage();

  const [, startTransition] = useTransition();
  const handleClose = () => startTransition(() => {
    setPin('');
    kbRef.current?.reset();
    setVisible(false);
    onClose();
  });

  const doClockIn = debounce(async (_pin: string) => {
    setLoadingPage(true);
    try {
      const res: IResponseDataBody<IClockInOutResData> = await userApis.doClockIn(_pin);
      const resData = res.data.data;
      if (resData) {

        onPickStaff({
          staffAvatar: resData.urlImage,
          staffId: resData.id,
          staffName: `${resData.firstName} ${resData.lastName}`,
        });

        dispatch(userActions.doClockIn.success(resData));
      }
    } catch (error) { }
    finally {
      setLoadingPage(false);
      dispatch(shopActions.get.staffsAvailable.fetch());
      dispatch(userActions.getListAvailableWorking.fetch());
    }
  }, 100);

  const handle = useCallback((_pin: string) => {
    if (_pin.length < 4) return;
    handleClose();
    if (isClockIn) {
      doClockIn(_pin);
      return;
    }

    dispatch(userActions.doClockOut.fetch(_pin));
  }, [isClockIn]);

  const _debounce = useCallback(
    debounce((_pin) => {
      handle(_pin);
    }, 500),
    [isClockIn]
  );

  useEffect(() => _debounce(pin), [pin]);

  useImperativeHandle(ref, () => ({
    open,
    close: () => handleClose(),
  }));

  return (
    <>
      <Modal
        modalTitle='password'
        hiddenOkButton
        visible={visible} onClose={handleClose}>
        <NumberKeyBoard
          ref={kbRef}
          type='PIN-PASSWORD'
          value={pin} onChange={(val) => setPin(val || '')}
        />
      </Modal>
    </>
  );
});
ModalClockInOut.displayName = 'ModalClockInOut';
export default ModalClockInOut;
